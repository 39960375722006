import { action, observable } from "mobx";
import { IDashboardDataContributorEpic } from "@acumen/dashboard-common";
import BaseStore from "./base-store";
import apiContextProvider from "../../services/api-context-provider";
import { FetchLatestRequest } from "../../services/fetch-helpers";
import {
	DATA_CONTRIBUTORS_EPICS_ROUTE,
	DataContributorEpicsApiClient, IDashboardDataContributorEpicRequestParams
} from "../services/crud/data-contributor-epics-api-client";

export default class DataContributorEpicsStore extends BaseStore<{}> {
	@observable isLoadingEpicsData = false;
	@observable epicsData: IDashboardDataContributorEpic[] = [];
	fetchLatestAllLabels = new FetchLatestRequest<IDashboardDataContributorEpic[], null>(DATA_CONTRIBUTORS_EPICS_ROUTE);
	private readonly apiClient = new DataContributorEpicsApiClient(apiContextProvider);

	@action.bound
	async findAll(params: IDashboardDataContributorEpicRequestParams) {
		this.isLoadingEpicsData = true;
		const response = await this.fetchLatestAllLabels.fetchLatest(
			this.apiClient.findAll(params)
		);

		if (response) {
			this.epicsData = response.data;
		}

		this.isLoadingEpicsData = false;
	}
}
