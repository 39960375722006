import _ from "lodash";
import { action, observable } from "mobx";
import urlJoin from "url-join";
import BaseStore, { IBaseStore, ILoadable } from "./base-store";
import apiContextProvider from "../../services/api-context-provider";
import { TasksApiClient, TASKS_ROUTE, CYCLE_TIME_BREAKDOWN_SUB_ROUTE } from "../services/crud/tasks-api-client";
import {
	IDashboardDetailedTaskResponse,
	DashboardTaskSortOption,
	DashboardSortOrder,
	AcumenTaskStatus,
	AcumenTaskType,
	DashboardTaskStaticGroupingOption,
	DashboardTaskDynamicGroupingOption,
	DashboardTaskExpandOption,
	IDashboardTask,
	IDashboardEntityWorkIntervals,
	TaskEstimationMethod,
	DashboardProjectEntityType,
	IDashboardEvent,
	ITaskHighlight,
	IDashboardPullRequest,
	DashboardTaskWorkStatusGrouping,
	taskGroupToStatusMapping,
	IDashboardTaskDetails,
	DashboardTaskTimeContext,
	IDashboardTaskStatusChangeResponse,
	IDashboardCycleTimeBreakdown
} from "@acumen/dashboard-common";
import { ISelectOptionGroups, ISelectOptions } from "../components/form/option-select";
import { FetchLatestRequest } from "../../services/fetch-helpers";

const STATUS_GROUPING_TO_VALUE: Record<DashboardTaskWorkStatusGrouping, string> = {
	[DashboardTaskWorkStatusGrouping.InProgress]: "In the Works",
	[DashboardTaskWorkStatusGrouping.Backlog]: "Haven’t Started",
	[DashboardTaskWorkStatusGrouping.Done]: "Done",
};

export interface ITasksState {
	allTasks: ILoadable<IDashboardDetailedTaskResponse>;
	workIntervals: ILoadable<IDashboardEntityWorkIntervals>;
	dismissHighlights: ILoadable<void>;
}

interface IFindTasksOptions {
	sortField?: DashboardTaskSortOption;
	sortOrder?: DashboardSortOrder;
	filter: {
		teamId: string;
		dataContributorIds?: string[];
		labels?: string[];
		sprints?: string[];
		projectIds?: string[];
		statuses?: AcumenTaskStatus[];
		types?: AcumenTaskType[];
		startTime?: Date;
		timeContext?: DashboardTaskTimeContext;
		endTime?: Date;
	};
	staticGroupingLimit?: number;
	staticGrouping?: DashboardTaskStaticGroupingOption;
	dynamicGroupingLimit?: number;
	dynamicGrouping?: DashboardTaskDynamicGroupingOption;
	expand?: DashboardTaskExpandOption[];
}

export interface ITasksStore extends ITasksState, IBaseStore<ITasksState> {
	fetchAllTasks: (options: IFindTasksOptions) => Promise<IDashboardDetailedTaskResponse>;
	resetAllTasks: () => void;
	fetchWorkIntervals: (tasks: IDashboardTask) => Promise<IDashboardEntityWorkIntervals>;
	resetWorkIntervals: () => void;
	dismissTaskHighlights: (tasks: IDashboardTask) => Promise<boolean>;
	resetDismissTaskHighlights: () => void;
}

const defaults = {
	allTasks: BaseStore.initLoadable({}),
	workIntervals: BaseStore.initLoadable({}),
	dismissHighlights: BaseStore.initLoadable(undefined),
	statusDiff: BaseStore.initLoadable({}),
};

export default class TasksStore extends BaseStore<ITasksState> implements ITasksStore {
	private readonly apiClient: TasksApiClient = new TasksApiClient(apiContextProvider);

	@observable
	public allTasks: ILoadable<IDashboardDetailedTaskResponse> = defaults.allTasks;

	public isLoading: boolean = this.allTasks.loading;

	@observable
	public workIntervals: ILoadable<IDashboardEntityWorkIntervals> = defaults.workIntervals;

	@observable
	public dismissHighlights: ILoadable<void> = defaults.dismissHighlights;

	@observable
	public statusDiff: ILoadable<IDashboardTaskStatusChangeResponse> = defaults.statusDiff;

	fetchLatestAllTasks = new FetchLatestRequest<IDashboardDetailedTaskResponse, void>(TASKS_ROUTE);
	@action.bound
	public async fetchAllTasks(options: IFindTasksOptions) {
		this.allTasks.loading = true;
		const filter = options.filter;
		const jiraProjects = filter.projectIds?.map(pID => `${pID},${DashboardProjectEntityType.JIRA}`);
		const result = await this.fetchLatestAllTasks.fetchLatest(this.apiClient.fetchTasks(filter.teamId, filter.dataContributorIds,
			filter.labels, filter.sprints, jiraProjects, filter.startTime, filter.endTime, filter.timeContext,
			filter.statuses, filter.types, options.staticGroupingLimit, options.staticGrouping,
			options.dynamicGroupingLimit, options.dynamicGrouping, options.sortField, options.sortOrder, options.expand));

		if (result) {
			const { data } = result;

			this.allTasks = {
				data,
				metadata: null,
				loaded: true,
				loading: false
			};
			return data as IDashboardDetailedTaskResponse;
		}

		return {
			estimationMethod: TaskEstimationMethod.None,
			tasks: {},
			total: 0
		};
	}

	fetchLatestTasksByOptions = new FetchLatestRequest<IDashboardDetailedTaskResponse, void>(TASKS_ROUTE);
	@action.bound
	public async fetchTasksByOptions(options: IFindTasksOptions): Promise<IDashboardDetailedTaskResponse> {
		const filter = options.filter;
		const jiraProjects = filter.projectIds?.map(pID => `${pID},${DashboardProjectEntityType.JIRA}`);
		// This method is being used by several components, we can't use fetchLatest on it.
		const result = await this.apiClient.fetchTasks(filter.teamId, filter.dataContributorIds,
			filter.labels, filter.sprints, jiraProjects, filter.startTime, filter.endTime, filter.timeContext,
			filter.statuses, filter.types, options.staticGroupingLimit, options.staticGrouping,
			options.dynamicGroupingLimit, options.dynamicGrouping, options.sortField, options.sortOrder, options.expand);
		if (!result) {
			return {
				estimationMethod: TaskEstimationMethod.None,
				tasks: {},
				total: 0
			};
		}

		return result.data;
	}

	fetchLatestWorkIntervals = new FetchLatestRequest<IDashboardEntityWorkIntervals, any>(TASKS_ROUTE + "/WorkIntervals");
	@action.bound
	public async fetchWorkIntervals(task: IDashboardTask): Promise<IDashboardEntityWorkIntervals> {
		this.workIntervals.loading = true;

		const result = await this.fetchLatestWorkIntervals.fetchLatest(this.apiClient.fetchWorkIntervals(task));

		if (result) {
			const { data } = result;
			const intervals = data as IDashboardEntityWorkIntervals;
			this.workIntervals = {
				data: intervals,
				metadata: [],
				loaded: true,
				loading: false
			};
			return intervals;
		}

		return {
			entityId: task.entityId,
			entityType: task.entityType,
			totalWorkIntervalMs: 0,
			workIntervalsByDataContributor: []
		};
	}

	@action.bound
	public async dismissTaskHighlights(task: IDashboardTask): Promise<boolean> {
		this.dismissHighlights.loading = true;
		const result = await this.apiClient.dismissTaskHighlights(task).then(res => {
			return res;
		}).finally(() => {
			this.dismissHighlights.loading = false;
		});
		return result !== null ? true : false;
	}

	fetchLatestTaskEvents = new FetchLatestRequest<IDashboardEvent[], any>(TASKS_ROUTE + "/Events");
	@action.bound
	public async fetchTaskEvents(task: IDashboardTask): Promise<IDashboardEvent[] | []> {
		this.dismissHighlights.loading = true;
		return this.fetchLatestTaskEvents.fetchLatest(this.apiClient.fetchTaskEvents(task)).then(res => {
			return res?.data.sort((a: IDashboardEvent, b: IDashboardEvent) => {
				return a.eventTimeMs < b.eventTimeMs ? 1 : -1;
			}) ?? [];
		});
	}

	fetchLatestTaskDetails = new FetchLatestRequest<IDashboardTaskDetails, any>(TASKS_ROUTE + "/Details");
	@action.bound
	public async fetchTaskDetails(task: IDashboardTask): Promise<IDashboardTaskDetails | null> {
		return this.fetchLatestTaskDetails.fetchLatest(this.apiClient.fetchTaskDetails(task)).then(res => res?.data ?? null);
	}

	fetchStatusDiffs = new FetchLatestRequest<IDashboardTaskStatusChangeResponse, any>(TASKS_ROUTE + "/Details");
	@action.bound
	public async fetchStatusDiff(taskIds?: string[], startTime?: Date, endTime?: Date, expand: boolean = true): Promise<IDashboardTaskStatusChangeResponse | null> {
		this.statusDiff.loading = true;
		this.statusDiff.loaded = false;
		const result = await this.fetchStatusDiffs.fetchLatest(this.apiClient.fetchStatusDiff(taskIds, startTime, endTime, expand));

		if (result) {
			this.statusDiff = {
				data: result?.data,
				metadata: result?.metadata,
				loaded: true,
				loading: false
			};
		}

		return result?.data || null;
	}

	@action.bound
	public async fetchTasksStatusDiff(taskIds?: string[], startTime?: Date, endTime?: Date, expand: boolean = true): Promise<IDashboardTaskStatusChangeResponse | null> {
		const result = await this.apiClient.fetchStatusDiff(taskIds, startTime, endTime, expand);

		return result?.data || null;
	}

	@action.bound
	public resetAllTasks() {
		this.allTasks = defaults.allTasks;
	}

	@action.bound
	public resetWorkIntervals() {
		this.workIntervals = defaults.workIntervals;
	}
	@action.bound
	public resetDismissTaskHighlights() {
		this.dismissHighlights = defaults.dismissHighlights;
	}

	fetchLatestTaskHighlights = new FetchLatestRequest<{ highlights: ITaskHighlight[] }, any>(TASKS_ROUTE + "/Highlights");
	@action.bound
	public async getTasksHighlights(task: IDashboardTask): Promise<ITaskHighlight[] | undefined> {
		const result = await this.fetchLatestTaskHighlights.fetchLatest(this.apiClient.fetchTaskHighlights(task));

		return result?.data.highlights;
	}

	fetchLatestTaskRelatedPRs = new FetchLatestRequest<Record<string, IDashboardPullRequest[] | undefined>, any>(TASKS_ROUTE + "/RelatedPRs");
	@action.bound
	public async getTasksRelatedPrs(task: IDashboardTask): Promise<Record<string, IDashboardPullRequest[] | undefined>> {
		const result = await this.fetchLatestTaskRelatedPRs.fetchLatest(this.apiClient.fetchRelatedPrs(task));
		return result?.data!;
	}

	fetchLatestCycleTimeBreakdown = new FetchLatestRequest<IDashboardCycleTimeBreakdown[], any>(urlJoin(TASKS_ROUTE, CYCLE_TIME_BREAKDOWN_SUB_ROUTE));
	@action.bound
	public async getCycleTimeBreakdown(taskIds: string[]): Promise<IDashboardCycleTimeBreakdown[]> {
		const result = await this.fetchLatestCycleTimeBreakdown.fetchLatest(this.apiClient.fetchCycleTimeBreakdown(taskIds));
		return result?.data!;
	}

	public taskStatusesOptions: ISelectOptionGroups[] =
		Object.entries(STATUS_GROUPING_TO_VALUE).map(([sgType, sgLabel]) => ({
			label: sgLabel,
			options: _.sortBy(taskGroupToStatusMapping[sgType as DashboardTaskWorkStatusGrouping], s => s.toString())
				.map(status => ({
					key: status,
					value: status,
					label: status.toString()
				})).sort()
		}));

	public taskTypeOptions: ISelectOptions[] = Object.entries(_.sortBy(AcumenTaskType))
		.map(([, type]) => ({
			key: type,
			value: type,
			label: type
		}));
}
