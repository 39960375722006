import {
	IDashboardDetailedPullRequestResponse, DashboardSortOrder, AcumenPullRequestStatus,
	DashboardPullRequestStaticGroupingOption, DashboardPullRequestDynamicGroupingOption, DashboardPullRequestSortOption,
	DashboardPullRequestExpandOption, IDashboardEntityWorkIntervals, IDashboardEvent, IPullRequestHighlight, IDashboardTask,
	IDashboardPullRequestDetails, IDashboardPullRequestEntity
} from "@acumen/dashboard-common";
import { getData } from "../../../services/fetch-helpers";
import { BaseCustomerApiClient } from "./base-customer-api-client";
import urlJoin from "url-join";
import { qsStringify } from "../../../services/url-routes-helper";

export const PULL_REQUESTS_ROUTE = "pull-requests";
export const WORK_INTERVALS_SUB_ROUTE = "work-intervals";
export const HIGHLIGHTS_SUB_ROUTE = "highlights";
export const HIGHLIGHTS_DISMISS_SUB_ROUTE = "dismiss";
export const EVENTS_SUB_ROUTE = "events";
export const RELATED_TASKS_SUB_ROUTE = "related-tasks";
export const PR_DETAILS_SUB_ROUTE = "details";

export class PullRequestsApiClient extends BaseCustomerApiClient {
	public fetchPullRequests = async (teamId: string, dataContributorIds?: string[],
		gitRepositories?: string[], statuses?: AcumenPullRequestStatus[], excludeDraft?: boolean, prIds?: string[],
		staticGroupingLimit?: number, staticGrouping?: DashboardPullRequestStaticGroupingOption,
		dynamicGroupingLimit?: number, dynamicGrouping?: DashboardPullRequestDynamicGroupingOption,
		startTime?: Date, endTime?: Date, sortField?: DashboardPullRequestSortOption,
		sortOrder?: DashboardSortOrder, expand?: DashboardPullRequestExpandOption[]) => {
		const query: { [label: string]: string | string[] | number | Date | boolean } = {};
		query.teamId = teamId;

		if (dataContributorIds) {
			query["dataContributorIds[]"] = dataContributorIds;
		}
		if (gitRepositories) {
			query["gitRepositories[]"] = gitRepositories;
		}
		if (statuses) {
			query["statuses[]"] = statuses;
		}
		if (prIds) {
			query["prIds[]"] = prIds;
		}
		if (startTime) {
			query.startTime = startTime;
		}
		if (endTime) {
			query.endTime = endTime;
		}
		if (staticGrouping) {
			query.staticGrouping = staticGrouping;
		}
		if (dynamicGrouping) {
			query.dynamicGrouping = dynamicGrouping;
		}
		if (sortField) {
			query.sortField = sortField;
		}
		if (sortOrder) {
			query.sortOrder = sortOrder;
		}
		if (excludeDraft) {
			query.excludeDraft = excludeDraft;
		}
		if (expand) {
			query["expand[]"] = expand;
		}
		if (staticGroupingLimit !== undefined) {
			query.staticGroupingLimit = staticGroupingLimit;
		}
		if (dynamicGroupingLimit !== undefined) {
			query.dynamicGroupingLimit = dynamicGroupingLimit;
		}

		return getData<IDashboardDetailedPullRequestResponse, any>(
			this.createCustomerEntityRoute(`${PULL_REQUESTS_ROUTE}?${qsStringify(query)}`),
			this.token
		);
	}

	public fetchWorkIntervals = async (pr: IDashboardPullRequestEntity) => {
		return await getData<IDashboardEntityWorkIntervals, any>(
			this.createCustomerEntityRoute(urlJoin(PULL_REQUESTS_ROUTE, `${pr.entityType}`, pr.entityId, WORK_INTERVALS_SUB_ROUTE)),
			this.token
		);
	}

	public dismissPRHighlights = async (pr: IDashboardPullRequestEntity) => {
		return await getData<void, any>(
			this.createCustomerEntityRoute(urlJoin(PULL_REQUESTS_ROUTE, `${pr.entityType}`, pr.entityId, HIGHLIGHTS_SUB_ROUTE, HIGHLIGHTS_DISMISS_SUB_ROUTE)),
			this.token
		);
	}

	public fetchPREvents = async (pr: IDashboardPullRequestEntity) => {
		return await getData<IDashboardEvent[], any>(
			this.createCustomerEntityRoute(urlJoin(PULL_REQUESTS_ROUTE, `${pr.entityType}`, pr.entityId, EVENTS_SUB_ROUTE)),
			this.token
		);
	}

	public fetchPRHighlights = async (pr: IDashboardPullRequestEntity) => {
		return await getData<{ highlights: IPullRequestHighlight[] }, any>(
			this.createCustomerEntityRoute(urlJoin(PULL_REQUESTS_ROUTE, `${pr.entityType}`, pr.entityId, HIGHLIGHTS_SUB_ROUTE)),
			this.token
		);
	}

	public fetchRelatedTasks = async (pr: IDashboardPullRequestEntity) => {
		return await getData<IDashboardTask[], any>(
			this.createCustomerEntityRoute(urlJoin(PULL_REQUESTS_ROUTE, `${pr.entityType}`, pr.entityId, RELATED_TASKS_SUB_ROUTE)),
			this.token
		);
	}

	public fetchPRDetails = async (pr: IDashboardPullRequestEntity) => {
		return await getData<IDashboardPullRequestDetails, any>(
			this.createCustomerEntityRoute(urlJoin(PULL_REQUESTS_ROUTE, `${pr.entityType}`, pr.entityId, PR_DETAILS_SUB_ROUTE)),
			this.token
		);
	}
}
