import { ChartType, IChartResponse, MetricInterval, pullRequestDashboardStatuses } from "@acumen/dashboard-common";
import colorScheme from "../../pages/my-team/analytics/charts/chart-color-scheme";
import { v4 as generateUUID } from "uuid";
import * as Highcharts from "highcharts";

export const PR_CYCLE_TIME_DATA = {
	title: "Pull requests average cycle time",
	description: `Aggregates and displays the average cycle time of each pull request done in the given month.
	The graph is split between the different states of the pull request.`
};

export const CLOSED_AND_UNMERGED_PRS = {
	title: "Closed and unmerged PRs",
	description: `Total amount of pull requests that have been closed without being merged.`
};

interface ISeriesProps {
	zOrder: number;
	sortOrder: number;
	seriesName: string;
	seriesColorHex: string;
}

const CHART_PR_STATUS_GROUPS: ISeriesProps[] = [
	{
		zOrder: 400,
		sortOrder: 1,
		seriesName: pullRequestDashboardStatuses[0].clientName,
		seriesColorHex: colorScheme.prStatus.WIP,
	},
	{
		zOrder: 300,
		sortOrder: 2,
		seriesName: pullRequestDashboardStatuses[1].clientName,
		seriesColorHex: colorScheme.prStatus.reviewedRequested,
	},
	{
		zOrder: 200,
		sortOrder: 3,
		seriesName: pullRequestDashboardStatuses[2].clientName,
		seriesColorHex: colorScheme.prStatus.inReview,
	},
	{
		zOrder: 100,
		sortOrder: 4,
		seriesName: pullRequestDashboardStatuses[3].clientName,
		seriesColorHex: colorScheme.prStatus.pendingMerge,
	}
];

export function createHighChartOptionObject(chart: ChartType, data: IChartResponse, interval: MetricInterval) {
	switch (chart) {
		case ChartType.PRCycleTime: {
			const series = CHART_PR_STATUS_GROUPS.map(displayProperties => {

				const statusData = data[displayProperties.seriesName] ?? [];
				const seriesOptions: Highcharts.SeriesColumnOptions = {
					id: generateUUID(),
					name: displayProperties.seriesName,
					color: displayProperties.seriesColorHex,
					visible: true,
					index: displayProperties.zOrder,
					legendIndex: displayProperties.sortOrder,
					data: statusData.map(x => [x.category, x.value]),
					stack: "pr",
					stacking: "normal",
					type: "column",
				};

				return seriesOptions;
			});

			const options: Highcharts.Options = {
				chart: {
					type: "area",
					zoomType: "xy"
				},
				title: undefined,
				tooltip: {
					split: true,
					pointFormat: `<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:,.0f} hr</b><br/>`
				},
				xAxis: {
					gridLineWidth: 1,
					type: (interval === MetricInterval.SPRINT_DATE ? "category" : "datetime"),
					uniqueNames: false
				},
				yAxis: {
					min: 0,
					title: {
						text: "Calendar hours"
					},
					labels: {
						// tslint:disable-next-line: object-literal-shorthand
						formatter: function _formatter() {
							return `${this.value}h`;
						}
					}
				},
				plotOptions: {
					area: {
						stacking: "normal",
					}
				},
				credits: {
					enabled: false
				},
				series
			};

			return options;
		}

		case ChartType.ClosedUnMergedPrs: {
			const splineSeries: Highcharts.SeriesSplineOptions[] = [
				{
					id: generateUUID(),
					type: "spline",
					name: "# of pull requests",
					data: data.prCountSeries.map(x => [x.category, x.value]),
					visible: data.prCountSeries.length > 0,
					dashStyle: "Dash",
					color: "#000000",
					lineWidth: 1,
					marker: {
						symbol: "circle"
					},
				}
			];

			const options: Highcharts.Options = {
				chart: {
					type: "column",
					zoomType: "xy"
				},
				title: undefined,
				tooltip: {
					split: true,
				},
				xAxis: {
					gridLineWidth: 1,
					type: (interval === MetricInterval.SPRINT_DATE ? "category" : "datetime"),
					uniqueNames: false
				},
				yAxis: [
					{
						min: 0,
						title: {
							text: "# of pull requests"
						}
					}
				],
				credits: {
					enabled: false
				},
				series: [
					...splineSeries
				]
			};
			return options;
		}

		default:
			throw new Error(`Unsupported chart type ${chart}`);
	}
}
