import _ from "lodash";
import React, { useMemo } from "react";
import { Loader, Modal } from "semantic-ui-react";
import BarChart from "v2/components/charts/bar-chart";
import { STATUS_SUCCESS } from "v2/hooks/async-state";
import { DevStatColumn } from "../../dev-stat-columns";
import { useDeveloperSingleMetricState } from "../../hooks/fetchers";
import "./developer-metric-modal.scss";
import moment from "moment";
import { WorkforceHealthMetrics } from "@acumen/database-types";

export interface MetricModalProps {
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	selectedMetricColumn: DevStatColumn;
	dcId: string;
}

export const MetricModal = (props: MetricModalProps) => {
	const {
		setIsOpen: setOpen,
		isOpen,
		selectedMetricColumn,
		dcId
	} = props;

	const metricData = useDeveloperSingleMetricState(dcId, selectedMetricColumn);
	const dates = useMemo(() => metricData.data.dates?.map(date => {
		const startDate = moment(date).startOf("week");
		const endDate = moment(date).endOf("week");

		return startDate.month() === endDate.month()
			? `${startDate.format("DD")}-${endDate.format("DD")} ${startDate.format("MMM")}`
			: `${startDate.format("DD MMM")}-${endDate.format("DD MMM")}`;
	}), [metricData.data]);

	return (
		<Modal open={isOpen}
			className="dev-stats-metric-modal"
			onClose={() => setOpen(false)}
		>
			<Modal.Header>
				{selectedMetricColumn && `Weekly ${selectedMetricColumn.displayValues.title}`}
				<span className="dev-stats-metric-modal-header-disclaimer">
					Based on data from the past 90 day
				</span>
			</Modal.Header>
			<Modal.Content>
				<Modal.Description>
					{metricData.status === STATUS_SUCCESS &&
					metricData.data && selectedMetricColumn &&
					metricData.data.chartData[selectedMetricColumn.dashboardMetricName]? (
					<BarChart
						height={"400"}
						opacity={1}
						tooltip={{
							enabled: true,
							formatter() {
								return `${this.series.name}: ${this.y} ${selectedMetricColumn.displayValues.unit}`;
							}
						}}
						color={selectedMetricColumn && barColors[selectedMetricColumn.dashboardMetricName]}
						seriesName={selectedMetricColumn.displayValues.title}
						xAxis={{
							visible: true,
							categories: dates
						}}
						yAxis={{ visible: true, title: { text: `${selectedMetricColumn.displayValues.title} (total weekly)` } }}
						series={
							_.zip(dates,
								metricData.data.chartData[selectedMetricColumn.dashboardMetricName]!) as Array<[string | number, number]>}
					/>
					) : <Loader
							size="small"
							active={true}
							inline={true}
							inverted={true}
						/>
							}
				</Modal.Description>
			</Modal.Content>
		</Modal>
	);
};

const barColors: Partial<Record<WorkforceHealthMetrics, string>> = {
	[WorkforceHealthMetrics.Velocity]: "#9386AE",
	[WorkforceHealthMetrics.BugsVelocity]: "#8F72DF",
	[WorkforceHealthMetrics.MergedPRs]: "#D69BF1",
	[WorkforceHealthMetrics.MTTR]: "#D3C4F3",
	[WorkforceHealthMetrics.ResolvedBugs]: "#8D8BFF",
	[WorkforceHealthMetrics.ReviewedPRs]: "#80A484",
	[WorkforceHealthMetrics.AveragePRPendingReviewTime]: "#B1D296",
	[WorkforceHealthMetrics.CompletedHighPriorityTasks]: "#BABB7A",
	[WorkforceHealthMetrics.AveragePrComments]: "#A5D5A9",
	[WorkforceHealthMetrics.ActiveDays]: "#FB9AAB",
	[WorkforceHealthMetrics.OpenedPRs]: "#FCB87A",
	[WorkforceHealthMetrics.LinesOfCode]: "#F1E2AC",
	[WorkforceHealthMetrics.AveragePRSize]: "#5BB4F4",
	[WorkforceHealthMetrics.AveragePRCycleTime]: "#5796C5",
	[WorkforceHealthMetrics.AveragePRCodingTime]: "#B6D5EC",
	[WorkforceHealthMetrics.AveragePRCodingAndReviewTime]: "#B6E2EC",
	[WorkforceHealthMetrics.AveragePRPendingMergeTime]: "#88C8DC",
	[WorkforceHealthMetrics.ClosedUnmergedPRs]: "#889FDC",
	[WorkforceHealthMetrics.ResolvedTasks]: "#7B93BB",
};
