import apiContextProvider from "../../services/api-context-provider";
import { observable, action, computed } from "mobx";
import { FetchLatestRequest } from "../../services/fetch-helpers";
import { IDashboardStatus } from "@acumen/dashboard-common";
import moment from "moment";
import { ROUTE, TaskStatusesApiClient } from "../services/crud/statuses-api-client";
import _ from "lodash";

export default class TaskStatusStore {
	private readonly apiClient: TaskStatusesApiClient = new TaskStatusesApiClient(apiContextProvider);

	@observable
	statusIdToStatus: Record<string, IDashboardStatus> = {};

	fetchStatus: Date | null = null;

	private fetchStatuses = new FetchLatestRequest<IDashboardStatus[], any>(ROUTE);

	async fetchData(): Promise<IDashboardStatus[] | null> {
		const result = await this.fetchStatuses.fetchLatest(this.apiClient.fetchStatuses());
		return result?.data ?? null;
	}

	@action.bound
	public async invalidate() {
		if (this.fetchStatuses.count > 0) {
			return;
		}

		this.fetchStatus = null;

		await this.fetchData()
			.then(dashStatuses => {
				if (dashStatuses === null) {
					return;
				}

				this.fetchStatus = new Date();
				const statusIdToStatus: Record<string, IDashboardStatus> = {};

				for (const dStatus of dashStatuses) {
					statusIdToStatus[dStatus.statusId.toString()] = dStatus;
				}

				this.statusIdToStatus = statusIdToStatus;
			});
	}

	public isValid() {
		return this.statusIdToStatus !== null
			&& this.fetchStatus !== null
			&& moment().diff(this.fetchStatus, "minute") < 60;
	}

	@computed
	public get getStatus(): boolean {
		if (!this.isValid()) {
			// NOTE: dont care when return the computed will take care of the data renewal
			// tslint:disable-next-line: no-floating-promises
			this.invalidate();
		}
		return !_.isEmpty(this.statusIdToStatus);
	}
}
