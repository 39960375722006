import { IDashboardInsightRule, IDashboardInsightsCategory, CommonEndPoints } from "@acumen/dashboard-common";
import { APIContextProvider } from "../../../services/api-context-provider";
import { IdBasedCrudApiClient } from "./id-based-crud-api-client";
import urlJoin from "url-join";
import { getData } from "../../../services/fetch-helpers";

export const INSIGHT_RULES_ROUTE = "insight-rules";
export const INSIGHTS_CATEGORIES_ROUTE = urlJoin(INSIGHT_RULES_ROUTE, "categories");

export class InsightRulesApiClient extends IdBasedCrudApiClient<IDashboardInsightRule> {
	constructor(apiContextProvider: APIContextProvider) {
		super(INSIGHT_RULES_ROUTE, apiContextProvider);
	}

	public fetchCategories = async () => {
		return await getData<IDashboardInsightsCategory[], any>(
			this.createCustomerEntityRoute(urlJoin(INSIGHTS_CATEGORIES_ROUTE, CommonEndPoints.FIND_ALL)),
			this.token
		);
	}
}
