import React, { useMemo } from "react";
import {
	PRTableAggregatedDataCell
} from "./pr-table-aggregated-data-cell/pr-table-aggregated-data-cell";
import {
	PRTableAggregatedDataHeaderCell
} from "./pr-table-aggregated-data-header-cell/pr-table-aggregated-data-header-cell";
import { IPRData, PRColumns } from "../type";
import { CustomTableColumnProps } from "v2/components/custom-table/types";
import _ from "lodash";
import { round } from "@acumen/common";

export interface PRTableAggregatedDataRowProps {
	items: IPRData[];
	columnItems: Array<CustomTableColumnProps<IPRData>>;
	valueByColumn: (columnKey: PRColumns, pr: IPRData) => string | number | Date | null;
}

export const PRTableAggregatedDataRow = (props: PRTableAggregatedDataRowProps) => {
	const { items, columnItems, valueByColumn } = props;
	const currentColumnSet = useMemo(() => {
		const figureSet: Map<PRColumns, number> = new Map();
		if (items.length === 0) {
			return figureSet;
		}
		for (const ci of columnItems) {
			const currColumn = ci.key as PRColumns;
			if (ci.sticky) {
				continue;
			}
			const val = valueByColumn(currColumn, items[0]);
			if (_.isNumber(val)) {
				const total = _.sum(items.map(i => valueByColumn(currColumn, i)));
				figureSet.set(currColumn, round(total, 2));
			}
		}
		return figureSet;
	}, [items, columnItems]);

	return (
		<tr className="pr-table-aggregated-data-row">
			<PRTableAggregatedDataHeaderCell />
			{columnItems.filter(ci => !ci.sticky).map(column => (
				<PRTableAggregatedDataCell
					key={column.key}
					value={currentColumnSet.get(column.key as PRColumns)}
					unit={undefined}
				/>
			))}
		</tr>
	);
};
