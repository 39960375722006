import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ChartSeriesData } from "v2/helpers/charts";
export type StackedSeries = Array<{ name: string, data: ChartSeriesData, color?: string }>;

interface IBarChart {
	series?: ChartSeriesData | ChartSeriesData[];
	tooltip?: Highcharts.TooltipOptions;
	xAxis?: Highcharts.XAxisOptions | Highcharts.XAxisOptions[];
	yAxis?: Highcharts.YAxisOptions | Highcharts.YAxisOptions[];
	color?: string;
	height?: string;
	opacity?: number;
	stackedSeries?: StackedSeries;
	legend?: boolean;
	seriesName?: string;
}

// tslint:disable-next-line: variable-name
const BarChart = (props: IBarChart) => {
	const { series, seriesName, stackedSeries, tooltip, xAxis, yAxis, height, color, legend, opacity = 1 } = { ...props };

	const chartOptions: Highcharts.Options = {
		chart: {
			type: "column",
			height,
		},
		title: {
			text: undefined
		},
		subtitle: {
			text: undefined
		},
		tooltip: tooltip ?? {
			enabled: false
		},
		xAxis: xAxis ?? {
			visible: false
		},
		yAxis: yAxis ?? {
			visible: false,
		},
		legend: legend ? {
			enabled: true
		} : {
			enabled: false
		},
		plotOptions: {
			column: {
				stacking: "normal"
			}
		},
		series: stackedSeries ? stackedSeries?.map(dataEntry => {
			return {
				type: "column",
				data: dataEntry.data ?? [],
				visible: true,
				opacity,
				color: dataEntry.color,
				name: dataEntry.name,
				stack: "all stacked",
			};
		}) : [{
			type: "column",
			data: series,
			name: seriesName,
			visible: true,
			opacity,
			color,
		}],
		credits: {
			enabled: false
		},

	};
	return (
		<HighchartsReact
			highcharts={Highcharts}
			options={chartOptions}
			allowChartUpdate={true}
		/>
	);
};
export default BarChart;
