import React, { useState, useEffect } from "react";
import {
	Accordion, Icon, Form, DropdownProps, InputOnChangeData,
	Checkbox, Loader
} from "semantic-ui-react";
import { observer } from "mobx-react";
import moment from "moment-timezone";
import "./style.scss";
import { useStores } from "../../mobx-stores";
import _ from "lodash";
import {
	IDashboardSlackNotification, ILongSlackDigestConfig, IShortSlackDigestConfig, ScheduledTaskType,
	IDashboardAddSlackNotification, IDashboardUpdateSlackNotification, IDashboardTeam, IDashboardTeamMember,
	LongSlackDigestMode, IDashboardDetailedDataContributor, IntegrationType, DevStatsType, ReviewReportDisplayMode, IDashboardSlackDigestConfig, IDashboardReviewReportConfig
} from "@acumen/dashboard-common";
import * as cronParser from "cron-parser";
import ConfirmationModal from "../../components/modals/confirmation-modal";
import { toast } from "react-toastify";
import { GA_EVENT_ACTION, GA_EVENT_CATEGORY, clickEvent } from "../../analytics-events";
import { Select as SelectInput, SelectDay } from "../../components/select";
import TextInput from "../../components/input/text-input";
import PrimaryButton from "../../components/buttons/primary-button";
import MinimalButton from "../../components/buttons/minimal-buttons";
import PreviewNotification from "./preview-notifications";
import Header from "../my-account/header";
import TimeInput from "../../components/input/time-input";
import EmptyState from "../empty-state";

enum SupportedSectionsShortVersion {
	Highlights = "Highlights",
	WorkDigest = "WorkDigest"
}

enum SupportedSectionsLongVersion {
	Insights = "Insights",
	Tasks = "Tasks",
	TeamMemberPullRequests = "TeamMemberPullRequests",
	Branches = "Branches",
	GeneralPullRequests = "GeneralPullRequests",
	MotivationalQuote = "MotivationalQuote"
}

enum SupportedSectionsReviewReport {
	Tasks = "Tasks",
	PullRequests = "PullRequests",
	DevStats = "DevStats"
}

const SECTION_TYPE_SHORT_VERSION_OPTIONS_TO_LABEL = {
	[SupportedSectionsShortVersion.Highlights]: "Insights and alerts",
	[SupportedSectionsShortVersion.WorkDigest]: "Work summary"
};

const SECTION_TYPE_LONG_VERSION_OPTIONS_TO_LABEL = {
	[SupportedSectionsLongVersion.TeamMemberPullRequests]: "Pending PR reviews",
	[SupportedSectionsLongVersion.Insights]: "Insights and alerts",
	[SupportedSectionsLongVersion.Tasks]: "Upcoming tasks",
	[SupportedSectionsLongVersion.Branches]: "Active branches",
	[SupportedSectionsLongVersion.MotivationalQuote]: "Daily motivational quote"
};

const SECTION_TYPE_REVIEW_REPORT_OPTIONS_TO_LABEL = {
	[SupportedSectionsReviewReport.Tasks]: "Completed tasks",
	[SupportedSectionsReviewReport.PullRequests]: "Reviewed pull requests",
	[SupportedSectionsReviewReport.DevStats]: "Developer statistics summary"
};

enum SupportedDestination {
	Channel = "Channel",
	Recipient = "Recipient",
}

enum NotificationPassSteps {
	Notification = "Notification",
	Recipients = "Recipients",
	Frequency = "Frequency",
}

interface IVisualScheduledTask {
	id: string;
	isDirty: boolean;
	isSaving: boolean;
	isDeleting: boolean;
	inError: boolean;
	type?: ScheduledTaskType;
	longTypeSections?: SupportedSectionsLongVersion[];
	shortTypeSections?: SupportedSectionsShortVersion[];
	reviewReportTypeSections?: SupportedSectionsReviewReport[];
	teamId?: string;
	digestSubjectId?: string;
	destinationRecipientId?: string;
	destinationChannelId?: string;
	destinationType?: SupportedDestination;
	timezone: string;
	daysToSend: number[];
	timeToSendHour?: number;
	timeToSendMinute?: number;
	enabled: boolean;
	shouldBeOpened: boolean;
	title?: string;
	recapReportPeriodDays?: number;
}

const TASK_TYPE_OPTIONS_TO_LABEL: Partial<Record<ScheduledTaskType, string>> = {
	[ScheduledTaskType.LONG_SLACK_DIGEST_PUSH]: "Upcoming tasks for the team",
	[ScheduledTaskType.SHORT_SLACK_DIGEST_PUSH]: "Previous day's work summary",
	[ScheduledTaskType.SLACK_DEVELOPER_SUMMARY_PUSH]: "Recap of previous period's work for an individual developer"
};

const SEND_DESTINATION_TO_LABEL = {
	[SupportedDestination.Channel]: "Channel",
	[SupportedDestination.Recipient]: "Recipient"
};

const TIME_ZONE_OPTIONS = Object.values(moment.tz.names()).map(i => ({
	key: i,
	text: i,
	value: i
}));

const SLACK_CHANNEL_HASHTAG = "#";
const NEW_SLACK_NOTIFICATION_ID = "___";
function createNewNotification(currentTimezone: string): IVisualScheduledTask {
	return {
		id: NEW_SLACK_NOTIFICATION_ID,
		isDirty: true,
		isSaving: false,
		isDeleting: false,
		inError: false,
		daysToSend: [],
		enabled: true,
		shortTypeSections: [],
		longTypeSections: [],
		reviewReportTypeSections: [],
		timezone: currentTimezone,
		shouldBeOpened: true,
		recapReportPeriodDays: 7,
		title: "Draft Slack notification"
	};
}

function isEmptyOrWhitespace(input?: string) {
	return !input || !input.trim();
}

function isValidSlackChannelName(channelName?: string) {
	if (isEmptyOrWhitespace(channelName)) {
		return false;
	}
	// Channel names can only contain lowercase letters, numbers, hyphens, and underscores, and must be 80 characters or less
	return (channelName!.match(/^[a-z0-9-]{1}[a-z0-9-]{0,80}$/) !== null);
}

function createSendTimeDefaultValue(notification: IVisualScheduledTask): string | undefined {
	if (notification.timeToSendHour === undefined || notification.timeToSendMinute === undefined) {
		return undefined;
	}
	const padLeadingZeroIfNeeded = (value: number) => (value < 10 ? String(value).padStart(2, "0") : String(value));

	return `${padLeadingZeroIfNeeded(notification.timeToSendHour)}:${padLeadingZeroIfNeeded(notification.timeToSendMinute)}`;
}

function createTitle(notification: IVisualScheduledTask | undefined, teamMembersForAllTeams: IDashboardTeamMember[],
	allDataContributors?: IDashboardDetailedDataContributor[], teams?: IDashboardTeam[]) {
	if (!notification) {
		return "";
	}

	let destination: string | undefined;

	switch (notification.destinationType) {
		case SupportedDestination.Recipient: {
			destination = teamMembersForAllTeams
				.find(tm => tm.dataContributorId === notification.destinationRecipientId)
				?.displayName;
			if (!destination && allDataContributors) {
				destination = allDataContributors
					.find(tm => tm.id === notification.destinationRecipientId)
					?.primaryDisplayName ?? undefined;
			}
			if (destination) {
				destination = `recipient: ${destination}`;
			}
			break;
		}
		case SupportedDestination.Channel: {
			if (notification.destinationChannelId) {
				destination = notification.destinationChannelId;

				if (!destination.startsWith(SLACK_CHANNEL_HASHTAG)) {
					destination = `${SLACK_CHANNEL_HASHTAG}${destination}`;
				}
				destination = `channel: ${destination}`;
			}
			break;
		}
	}

	let messageType: string | undefined;
	if (notification.type) {
		messageType = TASK_TYPE_OPTIONS_TO_LABEL[notification.type];
	}

	let teamName: string | undefined;
	if (teams && teams.length > 0 && notification.teamId) {
		teamName = teams.find(t => t.id === notification.teamId)?.name;
	}

	return `${`Slack ${messageType ? `${messageType.toLowerCase()} message` : "message"}${` - Team: ${teamName ?? "N/A"}`}${destination ? `, ${destination}` : ""}`}`;
}

interface ISlackNotificationFormProps {
	index: number;
	notification: IVisualScheduledTask;
	allTeams: IDashboardTeam[];
	allTeamsFetchingData: boolean;
	teamMembersForAllTeams: IDashboardTeamMember[];
	teamMembersForAllTeamsFetchingData: boolean;
	allDataContributors: IDashboardDetailedDataContributor[];
	allDataContributorsFetchingData: boolean;
	onRefresh: (notification: IVisualScheduledTask) => void;
	onSaveNotification: (notification: IVisualScheduledTask) => void;
	onDeleteNotification: (notification: IVisualScheduledTask) => void;
	onDuplicateNotification: (notification: IVisualScheduledTask) => void;
}

const ALL_WEEKDAYS = [0, 1, 2, 3, 4, 5, 6];

enum WEEK_DAYS_TO_SEND {
	ALL_WORKING_DAYS = "ALL_WORKING_DAYS",
	LAST_WORKING_DAY = "LAST_WORKING_DAY"
}

interface IDaysToSendDefaults {
	weekDays: WEEK_DAYS_TO_SEND;
	daysToSendHours: number;
	daysToSendMinutes: number;
}

const DAYS_TO_SEND_DEFAULTS: Partial<Record<ScheduledTaskType, IDaysToSendDefaults>> = {
	[ScheduledTaskType.LONG_SLACK_DIGEST_PUSH]: {
		weekDays: WEEK_DAYS_TO_SEND.ALL_WORKING_DAYS,
		daysToSendHours: 9,
		daysToSendMinutes: 0,
	},
	[ScheduledTaskType.SHORT_SLACK_DIGEST_PUSH]: {
		weekDays: WEEK_DAYS_TO_SEND.ALL_WORKING_DAYS,
		daysToSendHours: 9,
		daysToSendMinutes: 0,
	},
	[ScheduledTaskType.SLACK_DEVELOPER_SUMMARY_PUSH]: {
		weekDays: WEEK_DAYS_TO_SEND.LAST_WORKING_DAY,
		daysToSendHours: 15,
		daysToSendMinutes: 30,
	},
};

const SEND_TIME_MINUTE_INTERVALS: number = 15;

// tslint:disable-next-line: variable-name
const SlackNotificationForm = observer((props: ISlackNotificationFormProps) => {
	const { authStore } = useStores();
	const nonWorkingDays = authStore.authUser.nonWorkingDays;
	const [isOpen, setIsOpen] = useState<boolean>((props.notification.shouldBeOpened));
	const [notification, setNotification] = useState<IVisualScheduledTask>((props.notification));
	const [passedSteps, setPassedSteps] = useState<NotificationPassSteps[]>([]);

	const updateNotification = (notificationToUpdate: IVisualScheduledTask, update: Omit<Partial<IVisualScheduledTask>, "id" | "isDirty">) => {
		const updatedValue = _.assign({}, notificationToUpdate, update);
		updatedValue.isDirty = true;
		setNotification(updatedValue);
		props.onRefresh(updatedValue);
	};

	const handleCheckNotificationStep = () => {
		return !!(!!notification.type && notification.teamId);
	};

	const handleCheckRecipientsStep = () => {
		return !!notification.destinationType?.length &&
			(!!notification.destinationChannelId?.length || !!notification.destinationRecipientId?.length);
	};

	const handleCheckFrequencyStep = () => {
		const condition = (notification.daysToSend && notification.daysToSend.length > 0) &&
			(notification.timeToSendHour !== undefined) &&
			(notification.timeToSendMinute !== undefined && notification.timeToSendMinute % SEND_TIME_MINUTE_INTERVALS === 0) &&
			!!notification.timezone;

		if (notification.type === ScheduledTaskType.SLACK_DEVELOPER_SUMMARY_PUSH) {
			return (condition && !!notification.recapReportPeriodDays && !!notification.digestSubjectId);
		} else {
			return condition;
		}
	};

	useEffect(() => {
		const updatedPassedSteps = new Set([...passedSteps]);
		if (handleCheckNotificationStep() &&
			((notification.type === ScheduledTaskType.LONG_SLACK_DIGEST_PUSH && !!notification.longTypeSections?.length) ||
			(notification.type === ScheduledTaskType.SHORT_SLACK_DIGEST_PUSH && !!notification.shortTypeSections?.length) ||
			(notification.type === ScheduledTaskType.SLACK_DEVELOPER_SUMMARY_PUSH && !!notification.reviewReportTypeSections?.length))) {
			updatedPassedSteps.add(NotificationPassSteps.Notification);
		} else {
			updatedPassedSteps.delete(NotificationPassSteps.Notification);
		}
		if (handleCheckRecipientsStep()) {
			updatedPassedSteps.add(NotificationPassSteps.Recipients);
		} else {
			updatedPassedSteps.delete(NotificationPassSteps.Recipients);
		}
		if (handleCheckFrequencyStep()) {
			updatedPassedSteps.add(NotificationPassSteps.Frequency);
		} else {
			updatedPassedSteps.delete(NotificationPassSteps.Frequency);
		}
		setPassedSteps(Array.from(updatedPassedSteps));
	}, [notification]);

	const teamMemberOptionsByTeamId = (teamId?: string) => {
		const allDCs = _.chain(props.allDataContributors || [])
			.map(({ id: value, primaryDisplayName }) => ({ key: `all-${value}`, value, text: primaryDisplayName ?? `Member ${value}` }))
			.orderBy(user => user.text.toLowerCase(), "asc")
			.value();

		if (!teamId) {
			return allDCs;
		}

		const allTeamsAndMembers = props.teamMembersForAllTeams;
		if (!allTeamsAndMembers || allTeamsAndMembers.length === 0) {
			return [];
		}
		const currentTeamMembers = allTeamsAndMembers.filter(m => m.teamId === teamId).map(m => {
			return {
				key: `team-${m.dataContributorId}`,
				value: m.dataContributorId,
				text: m.displayName ?? `Member ${m.dataContributorId}`
			};
		});
		return [
			{
				key: `header-current-team-${teamId}`,
				text: <span><strong>Current Team Members</strong></span>,
				disabled: true,
			},
			...currentTeamMembers,
			{
				key: `header-all-members`,
				text: <span><strong>All Members</strong></span>,
				disabled: true,
			},
			...allDCs
		];
	};

	const getTeamMembers = (teamId?: string): string[] => {
		const output: string[] = [];
		if (!teamId) {
			return output;
		}

		const allTeamsAndMembers = props.teamMembersForAllTeams;
		if (!allTeamsAndMembers || allTeamsAndMembers.length === 0) {
			return [];
		}
		const currentTeamMembers = allTeamsAndMembers.filter(m => m.teamId === teamId).map(m => {
			return m.displayName || "";
		});

		return currentTeamMembers;
	};

	const getPreviewNotificationHeader = () => {
		const developer = getSelectedContributor ? getSelectedContributor.text.toString() : undefined;

		if (notification.type === ScheduledTaskType.SLACK_DEVELOPER_SUMMARY_PUSH) {
			return `This is the Acumen recap report ${developer ? "for " + developer : ""}`;
		} else if (notification.type === ScheduledTaskType.SHORT_SLACK_DIGEST_PUSH) {
			return "Here is what you and your team got done";
		} else {
			return "Here is what you and your team have going on for today";
		}
	};

	const handleToCheckDefaults = () => {
		let workingDaysDefault = false;
		let sendMinutesDefault = false;
		let sendHoursDefault = false;

		if (!nonWorkingDays) {
			return {
				workingDaysDefault,
				sendMinutesDefault,
				sendHoursDefault
			};
		}

		const workingDays = ALL_WEEKDAYS.filter(day => !nonWorkingDays.includes(day));

		if (JSON.stringify(notification.daysToSend) === JSON.stringify(workingDays) ||
		JSON.stringify(notification.daysToSend) === JSON.stringify(workingDays.slice(workingDays.length - 1)) ||
			notification.daysToSend.length < 1) {
			workingDaysDefault = true;
		}

		if (!notification.timeToSendMinute ||
			notification.timeToSendMinute === DAYS_TO_SEND_DEFAULTS.LONG_SLACK_DIGEST_PUSH?.daysToSendMinutes ||
		notification.timeToSendMinute === DAYS_TO_SEND_DEFAULTS.SHORT_SLACK_DIGEST_PUSH?.daysToSendMinutes ||
		notification.timeToSendMinute === DAYS_TO_SEND_DEFAULTS.SLACK_DEVELOPER_SUMMARY_PUSH?.daysToSendMinutes) {
			sendMinutesDefault = true;
		}

		if (!notification.timeToSendHour ||
			notification.timeToSendHour === DAYS_TO_SEND_DEFAULTS.LONG_SLACK_DIGEST_PUSH?.daysToSendHours ||
		notification.timeToSendHour === DAYS_TO_SEND_DEFAULTS.SHORT_SLACK_DIGEST_PUSH?.daysToSendHours ||
		notification.timeToSendHour === DAYS_TO_SEND_DEFAULTS.SLACK_DEVELOPER_SUMMARY_PUSH?.daysToSendHours) {
			sendHoursDefault = true;
		}

		return {
			workingDaysDefault,
			sendMinutesDefault,
			sendHoursDefault
		};
	};

	useEffect(() => {
		if (!notification.type || !nonWorkingDays || notification.id !== NEW_SLACK_NOTIFICATION_ID) {
			return;
		}

		const sendDefaults = DAYS_TO_SEND_DEFAULTS[notification.type];
		const daysToSendHours = sendDefaults?.daysToSendHours;
		const daysToSendMinutes = sendDefaults?.daysToSendMinutes;
		let workingDays = ALL_WEEKDAYS.filter(day => !nonWorkingDays.includes(day));

		if (sendDefaults?.weekDays === WEEK_DAYS_TO_SEND.LAST_WORKING_DAY) {
			workingDays = workingDays.slice(workingDays.length - 1);
		}
		const { workingDaysDefault, sendHoursDefault, sendMinutesDefault } = handleToCheckDefaults();
		if (workingDaysDefault && sendHoursDefault && sendMinutesDefault) {
			updateNotification(notification, {daysToSend: workingDays, timeToSendHour: daysToSendHours, timeToSendMinute: daysToSendMinutes });
		}

	}, [notification.type]);

	const getSelectedContributor = teamMemberOptionsByTeamId(notification.teamId).find(item => item.key === `all-${notification.digestSubjectId}`);

	return (
		<div className="ui segment raised" key={`accordion-slack-notification-${props.notification.id}`}>
			<Accordion.Title
				active={isOpen}
				index={props.index}
				{...clickEvent(GA_EVENT_CATEGORY.SlackNotifications, GA_EVENT_ACTION.Select, "accordion")}
				onClick={() => {
					setIsOpen(!isOpen);
				}}>
				<div className="accordion-header">
					<div className="accordion-title">
						<span className="ui header">
							<Icon name={isOpen ? "chevron down": "chevron right"} />
							{(notification.title ? (notification.isDirty ? `${notification.title} *` : notification.title) : "")}
						</span>
					</div>
				</div>
			</Accordion.Title>
			<Accordion.Content active={isOpen}>
				<div className="slack-notification-wrapper">
					<div className="slack-notification-form">
						<Form key={`form-slack-notification-${props.notification.id}`}>
							<div className="section-separator dashed">
								<div className="section-title">
									<div className={`section-mark ${passedSteps.includes(NotificationPassSteps.Notification) ? "checked" : ""}`}>
										{passedSteps.includes(NotificationPassSteps.Notification) &&
										<i aria-hidden="true" className="check right icon tiny"/>}
									</div>
									{NotificationPassSteps.Notification}
								</div>
								<div className="section-row">
									<div className="section-row-item">
										<SelectInput
											label="Type"
											required={true}
											error={notification.inError && notification.type === undefined}
											searchable={true}
											options={Object.keys(TASK_TYPE_OPTIONS_TO_LABEL).map((key: string | number) => {
												const _key = key as keyof typeof TASK_TYPE_OPTIONS_TO_LABEL;
												return ({
													key: _key,
													text: TASK_TYPE_OPTIONS_TO_LABEL[_key],
													value: key,
												});
											})}
											value={notification.type}
											onChange={(data: DropdownProps | string | number) => {
												if (data === ScheduledTaskType.SLACK_DEVELOPER_SUMMARY_PUSH) {
													updateNotification(notification, {
														recapReportPeriodDays: notification.recapReportPeriodDays || 7
													});
												}
												updateNotification(notification, { type: data as ScheduledTaskType });
											}}
											placeholder="Notification type"
											{...clickEvent(GA_EVENT_CATEGORY.SlackNotifications, GA_EVENT_ACTION.Select, "section-type")}
										/>
									</div>
									<div className="section-row-item"/>
								</div>
								<div className="section-row">
									{(notification.type === ScheduledTaskType.LONG_SLACK_DIGEST_PUSH ||
									notification.type === ScheduledTaskType.SHORT_SLACK_DIGEST_PUSH ||
									notification.type === ScheduledTaskType.SLACK_DEVELOPER_SUMMARY_PUSH) && <div className="section-row-item">
										{notification.type === ScheduledTaskType.LONG_SLACK_DIGEST_PUSH && (
											<SelectInput
												label="Sections"
												placeholder="Sections"
												selected={notification.longTypeSections}
												searchable={true}
												multiselect={true}
												required={true}
												error={notification.inError && _.isEmpty(notification.longTypeSections)}
												onChange={(data: DropdownProps | string | number) => {
													updateNotification(notification, { longTypeSections: data as SupportedSectionsLongVersion[] });
												}}
												options={Object.keys(SECTION_TYPE_LONG_VERSION_OPTIONS_TO_LABEL).map((key: string | number) => {
													const _key = key as keyof typeof SECTION_TYPE_LONG_VERSION_OPTIONS_TO_LABEL;
													return ({
														key: _key,
														text: SECTION_TYPE_LONG_VERSION_OPTIONS_TO_LABEL[_key],
														value: key,
													});
												})}
												{...clickEvent(GA_EVENT_CATEGORY.SlackNotifications, GA_EVENT_ACTION.Select, "section-long-value")}
											/>
										)}
										{notification.type === ScheduledTaskType.SHORT_SLACK_DIGEST_PUSH && (
											<SelectInput
												label="Sections"
												required={true}
												error={notification.inError && _.isEmpty(notification.shortTypeSections)}
												multiselect={true}
												selected={notification.shortTypeSections}
												options={Object.keys(SECTION_TYPE_SHORT_VERSION_OPTIONS_TO_LABEL).map((key: string | number) => {
													const _key = key as keyof typeof SECTION_TYPE_SHORT_VERSION_OPTIONS_TO_LABEL;
													return ({
														key: _key,
														text: SECTION_TYPE_SHORT_VERSION_OPTIONS_TO_LABEL[_key],
														value: key,
													});
												})}
												onChange={(data: DropdownProps | string | number) => {
													updateNotification(notification, { shortTypeSections: data as SupportedSectionsShortVersion[] });
												}}
												placeholder="Sections"
												{...clickEvent(GA_EVENT_CATEGORY.SlackNotifications, GA_EVENT_ACTION.Select, "section-short-value")}
											/>
										)}
										{notification.type === ScheduledTaskType.SLACK_DEVELOPER_SUMMARY_PUSH && (
											<SelectInput
												label="Sections"
												required={true}
												error={notification.inError && _.isEmpty(notification.reviewReportTypeSections)}
												multiselect={true}
												selected={notification.reviewReportTypeSections}
												options={Object.keys(SECTION_TYPE_REVIEW_REPORT_OPTIONS_TO_LABEL).map((key: string | number) => {
													const _key = key as keyof typeof SECTION_TYPE_REVIEW_REPORT_OPTIONS_TO_LABEL;
													return ({
														key: _key,
														text: SECTION_TYPE_REVIEW_REPORT_OPTIONS_TO_LABEL[_key],
														value: key,
													});
												})}
												onChange={(data: DropdownProps | string | number) => {
													updateNotification(notification, { reviewReportTypeSections: data as SupportedSectionsReviewReport[] });
												}}
												placeholder="Sections"
												{...clickEvent(GA_EVENT_CATEGORY.SlackNotifications, GA_EVENT_ACTION.Select, "section-review-value")}
											/>
										)}
									</div>}
									<div className="section-row-item">
										<SelectInput
											options={props.allTeams.map(team => ({
												key: team.id,
												value: team.id,
												text: team.name
											}))}
											loading={props.allTeamsFetchingData}
											required={true}
											error={notification.inError && isEmptyOrWhitespace(notification.teamId)}
											label={"Team"}
											placeholder="Select a team"
											searchable={true}
											value={notification.teamId}
											onChange={(data: DropdownProps | string | number) => {
												const teamId = (data ? data as string : undefined);
												updateNotification(notification, {
													teamId
												});
											}}
											key={`form-select-control-team-${props.notification.id}`}
											// searchInput={{ id: `form-select-control-team-${props.notification.id}` }}
											{...clickEvent(GA_EVENT_CATEGORY.SlackNotifications, GA_EVENT_ACTION.Select, "team")}
										/>
									</div>
								</div>
							</div>
							<div className="section-separator dashed">
								<div className="section-title">
								<div className={`section-mark ${passedSteps.includes(NotificationPassSteps.Recipients) ? "checked" : ""}`}>
									{passedSteps.includes(NotificationPassSteps.Recipients) &&
									<i aria-hidden="true" className="check right icon tiny"/>}
								</div>
									{NotificationPassSteps.Recipients}
								</div>
								<div className="section-row">
									<div className="section-row-item">
										<SelectInput
											label="Send to"
											required={true}
											error={notification.inError && notification.destinationType === undefined}
											value={notification.destinationType}
											options={Object.keys(SEND_DESTINATION_TO_LABEL).map((key: string | number) => {
												const _key = key as keyof typeof SEND_DESTINATION_TO_LABEL;
												return ({
													key: _key,
													text: SEND_DESTINATION_TO_LABEL[_key],
													value: key,
												});
											})}
											onChange={(data: DropdownProps | string | number) => {
												updateNotification(notification, { destinationType: data as SupportedDestination });
											}}
											placeholder="Send to"
											{...clickEvent(GA_EVENT_CATEGORY.SlackNotifications, GA_EVENT_ACTION.Select, "send-to")}
										/>
									</div>
									<div className="section-row-item">
										{notification.destinationType === SupportedDestination.Recipient && (
											<SelectInput
												label="Recipient"
												required={true}
												searchable={true}
												error={notification.inError && isEmptyOrWhitespace(notification.destinationRecipientId)}
												loading={props.teamMembersForAllTeamsFetchingData || props.allDataContributorsFetchingData}
												value={notification.destinationRecipientId}
												options={teamMemberOptionsByTeamId(notification.teamId)}
												onChange={(data: DropdownProps | string | number) => {
													updateNotification(notification, { destinationRecipientId: data as string });
												}}
												placeholder="Recipient"
												{...clickEvent(GA_EVENT_CATEGORY.SlackNotifications, GA_EVENT_ACTION.Select, "send-to-recipient")}
											/>
										)}
										{notification.destinationType === SupportedDestination.Channel && (
											<TextInput
												label="Slack Channel"
												placeholder="slack-channel"
												iconLabel="#"
												required={true}
												error={notification.inError && !isValidSlackChannelName(notification.destinationChannelId)}
												value={notification.destinationChannelId}
												autoFocus={true}
												onChange={(data) => {
													let destinationChannelId = data as string;
													if (destinationChannelId && destinationChannelId.startsWith(SLACK_CHANNEL_HASHTAG)) {
														destinationChannelId = destinationChannelId.replace(SLACK_CHANNEL_HASHTAG, "");
													}
													updateNotification(notification, { destinationChannelId });
												}}
												{...clickEvent(GA_EVENT_CATEGORY.SlackNotifications, GA_EVENT_ACTION.Select, "send-to-channel")}
											/>
										)}
									</div>
								</div>
								{notification.destinationType === SupportedDestination.Channel && (
									<div className="acumen-tooltip">
										Add Acumen to the channel by mentioning @acumen in a channel wide message
									</div>
								)}
							</div>
							<div className="section-separator dashed">
								<div className="section-title">
								<div className={`section-mark ${passedSteps.includes(NotificationPassSteps.Frequency) ? "checked" : ""}`}>
									{passedSteps.includes(NotificationPassSteps.Frequency) &&
									<i aria-hidden="true" className="check right icon tiny"/>}
								</div>
									{NotificationPassSteps.Frequency}
								</div>
								<div className="section-row">
									<div className="section-row-item">
										<SelectDay
											label="Days"
											selected={notification.daysToSend}
											error={notification.inError && _.isEmpty(notification.daysToSend)}
											onChange={(data) => {
												updateNotification(notification, { daysToSend: data });
											}}
										/>
									</div>
									<div className="section-row-item">
										<TimeInput
											label="Send time"
											error={notification.inError && (notification.timeToSendHour === undefined || notification.timeToSendMinute === undefined)}
											value={createSendTimeDefaultValue(notification)}
											minuteIncrement={SEND_TIME_MINUTE_INTERVALS}
											onChange={(data: InputOnChangeData | string | number) => {
												const timeSplit = data.toString().split(":");
												updateNotification(notification, {
													timeToSendHour: Number.parseInt(timeSplit[0], 10),
													timeToSendMinute: Number.parseInt(timeSplit[1], 10)
												});
											}}
											{...clickEvent(GA_EVENT_CATEGORY.SlackNotifications, GA_EVENT_ACTION.Select, "send-time")}
										/>
									</div>
								</div>
								<div className="section-row">
									<div className="section-row-item">
										<SelectInput
											label="Timezone"
											required={true}
											searchable={true}
											error={notification.inError && isEmptyOrWhitespace(notification.timezone)}
											options={TIME_ZONE_OPTIONS}
											onChange={(data: DropdownProps | string | number) => {
												updateNotification(notification, { timezone: data as string });
											}}
											value={notification.timezone}
											placeholder="Timezone"
											{...clickEvent(GA_EVENT_CATEGORY.SlackNotifications, GA_EVENT_ACTION.Select, "timezone")}
										/>
									</div>
									<div className="section-row-item">
										{notification.type === ScheduledTaskType.SLACK_DEVELOPER_SUMMARY_PUSH && (
											<SelectInput
												label="Recap Period (days)"
												placeholder="Select day"
												required={true}
												error={notification.inError && _.isEmpty(notification.recapReportPeriodDays?.toString())}
												value={notification.recapReportPeriodDays}
												options={_.range(1, 31).map(i => ({
													key: i,
													text: i.toString(),
													value: i
												}))}
												onChange={(data: DropdownProps | string | number) => {
													updateNotification(notification, { recapReportPeriodDays: data as number });
												}}
											/>
										)}
									</div>
								</div>
								<div className="section-row">
									<div className="section-row-item">
										{notification.type === ScheduledTaskType.SLACK_DEVELOPER_SUMMARY_PUSH && (
											<SelectInput
												label="Digest for"
												required={true}
												searchable={true}
												error={notification.inError && isEmptyOrWhitespace(notification.digestSubjectId)}
												loading={props.teamMembersForAllTeamsFetchingData || props.allDataContributorsFetchingData}
												value={notification.digestSubjectId}
												options={teamMemberOptionsByTeamId(notification.teamId)}
												onChange={(data: DropdownProps | string | number) => {
													updateNotification(notification, { digestSubjectId: data as string });
												}}
												placeholder="Digest for"
												{...clickEvent(GA_EVENT_CATEGORY.SlackNotifications, GA_EVENT_ACTION.Select, "send-to-recipient")}
											/>
										)}
									</div>
									<div className="section-row-item" />
								</div>
							</div>
							<div className="section-separator">
								<div className="section-title">
								<div className={`section-mark ${notification.id !== NEW_SLACK_NOTIFICATION_ID ? "checked" : ""}`}>
									{notification.id !== NEW_SLACK_NOTIFICATION_ID &&
									<i aria-hidden="true" className="check right icon tiny"/>}
								</div>
									Save
								</div>
								<Form.Group className="form-footer">
								<PrimaryButton
									{...clickEvent(GA_EVENT_CATEGORY.SlackNotifications, GA_EVENT_ACTION.Save)}
									disabled={notification.isSaving || notification.isDeleting}
									loading={notification.isSaving}
									onClick={() => {
										props.onSaveNotification(notification);
									}}>
									Save
								</PrimaryButton>
								<div className="form-footer-center" />
								<div className="form-footer-actions">
									<div className="form-toggle">
										<span className="form-toggle-input">
											<Checkbox
												{...clickEvent(GA_EVENT_CATEGORY.SlackNotifications, GA_EVENT_ACTION.Select, "enabled-toggle")}
												toggle={true}
												checked={notification.enabled}
												disabled={notification.isSaving || notification.isDeleting}
												onClick={(e) => {
													e.stopPropagation();
													e.preventDefault();
													updateNotification(notification, { enabled: !notification.enabled });
												}} />
										</span>
										<span className="form-toggle-label">
											Enabled
										</span>
									</div>
									<div className="form-footer-separator" />
									{notification.id !== NEW_SLACK_NOTIFICATION_ID && <MinimalButton
										{...clickEvent(GA_EVENT_CATEGORY.SlackNotifications, GA_EVENT_ACTION.Duplicate)}
										disabled={notification.isSaving || notification.isDeleting}
										onClick={() => {
											props.onDuplicateNotification(notification);
										}}
									>
											<i className="copy icon" />
											Duplicate
									</MinimalButton>}
									<MinimalButton
										{...clickEvent(GA_EVENT_CATEGORY.SlackNotifications, GA_EVENT_ACTION.Delete)}
										disabled={notification.isSaving || notification.isDeleting}
										loading={notification.isDeleting}
										onClick={() => {
											props.onDeleteNotification(notification);
										}}
									>
										<i className="trash icon" />
										Delete
									</MinimalButton>
								</div>
							</Form.Group>
							</div>
						</Form>
					</div>
						<PreviewNotification
							title={getPreviewNotificationHeader()}
							teamMembers={getTeamMembers(notification.teamId).slice(0, 3)}
							developer={getSelectedContributor ? getSelectedContributor.text.toString() : undefined}
							showCompletedTasks={
								notification.reviewReportTypeSections?.includes(SupportedSectionsReviewReport.Tasks)
								&& notification.type === ScheduledTaskType.SLACK_DEVELOPER_SUMMARY_PUSH
							}
							showDeveloperStats={
								notification.reviewReportTypeSections?.includes(SupportedSectionsReviewReport.DevStats)
								&& notification.type === ScheduledTaskType.SLACK_DEVELOPER_SUMMARY_PUSH
							}
							showNeedsAttention={
								notification.longTypeSections?.includes(SupportedSectionsLongVersion.Insights)
								&& notification.type === ScheduledTaskType.LONG_SLACK_DIGEST_PUSH
							}
							showPrReviews={
								notification.longTypeSections?.includes(SupportedSectionsLongVersion.TeamMemberPullRequests)
								&& notification.type === ScheduledTaskType.LONG_SLACK_DIGEST_PUSH
							}
							showReviewedPRs={
								notification.reviewReportTypeSections?.includes(SupportedSectionsReviewReport.PullRequests)
								&& notification.type === ScheduledTaskType.SLACK_DEVELOPER_SUMMARY_PUSH
							}
							showUpcomingTasks={
								notification.longTypeSections?.includes(SupportedSectionsLongVersion.Tasks)
								&& notification.type === ScheduledTaskType.LONG_SLACK_DIGEST_PUSH
							}
							showTodayInsights={
								notification.shortTypeSections?.includes(SupportedSectionsShortVersion.Highlights)
								&& notification.type === ScheduledTaskType.SHORT_SLACK_DIGEST_PUSH
							}
							showTeamUpdate={
								notification.shortTypeSections?.includes(SupportedSectionsShortVersion.WorkDigest)
								&& notification.type === ScheduledTaskType.SHORT_SLACK_DIGEST_PUSH
							}
							showActiveBranches={
								notification.longTypeSections?.includes(SupportedSectionsLongVersion.Branches)
								&& notification.type === ScheduledTaskType.LONG_SLACK_DIGEST_PUSH
							}
						/>
					</div>
			</Accordion.Content>
		</div >
	);
});

function SlackNotificationsManagementPage() {
	const [availableNotifications, setAvailableNotifications] = useState<IVisualScheduledTask[] | undefined>(undefined);

	const {
		teamsStore,
		authStore,
		teamMembersStore,
		slackNotificationsStore,
		dataContributorsStore,
		integrationsStore: {
			hasActiveIntegrations,
			setIntegrationType
		}
	} = useStores();

	const { allTeams } = teamsStore!;
	const currentTimeZone = authStore.authUser.timezone;

	const [deleteNotification, toggleDeleteNotificationDialogAlert] = useState<IVisualScheduledTask | undefined>(undefined);
	useEffect(() => {
		async function retrieveAllTeams() {
			return !allTeams.loaded &&
				await teamsStore.fetchAllTeams();
		}
		async function retrieveMembers() {
			return !teamMembersStore.teamMembersForAllTeams.loaded &&
				await teamMembersStore.fetchTeamMembersForAllTeams();
		}
		async function retrieveAllDCs() {
			return !dataContributorsStore.allDataContributors.loaded &&
				await dataContributorsStore.fetchAllDataContributors({
					onlyHumanContributors: true,
					onlyActiveContributors: true,
					integrations: [IntegrationType.Acumen, IntegrationType.JIRA, IntegrationType.Slack]
				}, undefined, true);
		}
		// tslint:disable-next-line: no-floating-promises
		retrieveAllTeams();
		// tslint:disable-next-line: no-floating-promises
		retrieveMembers();
		// tslint:disable-next-line: no-floating-promises
		retrieveAllDCs();
	}, []);

	useEffect(() => {
		if (slackNotificationsStore.allSlackNotificationsByDC.loaded) {
			return;
		}
		if (_.isEmpty(dataContributorsStore.allDataContributors.data) || _.isEmpty(allTeams.data)) {
			setAvailableNotifications([]);
			return;
		}
		slackNotificationsStore.findAll().then(tasks => {
			const visualTasks = tasks.map((t: IDashboardSlackNotification) => {
				let longTypeSections: SupportedSectionsLongVersion[] | undefined;
				let shortTypeSections: SupportedSectionsShortVersion[] | undefined;
				let reviewReportTypeSections: SupportedSectionsReviewReport[] | undefined;
				let digestSubjectId: string | undefined;
				let recapReportPeriodHours: number | undefined;

				switch (t.type) {
					case ScheduledTaskType.SHORT_SLACK_DIGEST_PUSH: {
						const config = t.config.slackDigestConfig as IShortSlackDigestConfig;
						shortTypeSections = [];
						if (config.includeHighlights) {
							shortTypeSections.push(SupportedSectionsShortVersion.Highlights);
						}
						if (config.includeWorkDigest) {
							shortTypeSections.push(SupportedSectionsShortVersion.WorkDigest);
						}
						break;
					}
					case ScheduledTaskType.LONG_SLACK_DIGEST_PUSH: {
						const config = t.config.slackDigestConfig as ILongSlackDigestConfig;
						longTypeSections = [];
						if (config.includeInsights) {
							longTypeSections.push(SupportedSectionsLongVersion.Insights);
						}
						if (config.includeTeamMemberPullRequests) {
							longTypeSections.push(SupportedSectionsLongVersion.TeamMemberPullRequests);
						}
						if (config.includeTasks) {
							longTypeSections.push(SupportedSectionsLongVersion.Tasks);
						}
						if (config.includeBranches) {
							longTypeSections.push(SupportedSectionsLongVersion.Branches);
						}
						if (config.includeMotivationalQuote) {
							longTypeSections.push(SupportedSectionsLongVersion.MotivationalQuote);
						}
						break;
					}
					case ScheduledTaskType.SLACK_DEVELOPER_SUMMARY_PUSH: {
						const config = t.config.slackDigestConfig as IDashboardReviewReportConfig;
						reviewReportTypeSections = [];
						if (config.includePullRequests) {
							reviewReportTypeSections.push(SupportedSectionsReviewReport.PullRequests);
						}
						if (config.includeTasks) {
							reviewReportTypeSections.push(SupportedSectionsReviewReport.Tasks);
						}
						if (config.devStats && config.devStats.length > 0) {
							reviewReportTypeSections.push(SupportedSectionsReviewReport.DevStats);
						}
						if (config.subjectId) {
							digestSubjectId = config.subjectId;
						}
						if (config.recapReportPeriodHours) {
							recapReportPeriodHours = config.recapReportPeriodHours;
						}
						break;
					}
				}

				let destinationType: SupportedDestination | undefined;
				let destinationChannelId: string | undefined;
				let destinationRecipientId: string | undefined;

				if ("channel" in t.config) {
					destinationType = SupportedDestination.Channel;
					destinationChannelId = t.config.channel;
					if (destinationChannelId && destinationChannelId.startsWith(SLACK_CHANNEL_HASHTAG)) {
						destinationChannelId = destinationChannelId.replace(SLACK_CHANNEL_HASHTAG, "");
					}
				} else if ("recipientDC" in t.config) {
					destinationType = SupportedDestination.Recipient;
					destinationRecipientId = t.config.recipientDC.toString();
				}

				const interval = cronParser.parseExpression(t.cron);
				// parsing from immutable interface.
				const daysToSend: number[] = [];
				for (const parsedDay of interval.fields.dayOfWeek) {
					if (parsedDay) {
						daysToSend.push(parsedDay);
					}
				}

				// Right now the hour/minute field is supported only once. We do not support different time settings.
				const timeToSendHour = (interval.fields.hour.length > 0 ? interval.fields.hour[0] : undefined);
				const timeToSendMinute = (interval.fields.minute.length > 0 ? interval.fields.minute[0] : undefined);
				const visualTask: IVisualScheduledTask = {
					id: t.id.toString(),
					isDirty: false,
					isSaving: false,
					isDeleting: false,
					inError: false,
					type: t.type,
					longTypeSections,
					shortTypeSections,
					reviewReportTypeSections,
					teamId: t.config.teamId.toString(),
					destinationRecipientId,
					destinationChannelId,
					destinationType,
					timezone: t.timezone,
					daysToSend,
					timeToSendHour,
					timeToSendMinute,
					enabled: t.isOn,
					shouldBeOpened: false,
					digestSubjectId,
					recapReportPeriodDays: recapReportPeriodHours ? recapReportPeriodHours / 24 : 7
				};
				visualTask.title = createTitle(visualTask, teamMembersStore.allTeamMembers.data,
					dataContributorsStore.allDataContributors.data, teamsStore.allTeams.data);
				return visualTask;
			});
			setAvailableNotifications(visualTasks);
		}).catch();
		return function unmount() {
			slackNotificationsStore.resetAllSlackNotifications();
		};
	}, [dataContributorsStore.allDataContributors, allTeams.data]);

	function onDeleteNotification(notificationToDelete: IVisualScheduledTask) {
		const deleteToastTitle = `Notification ${notificationToDelete.title ? `"${notificationToDelete.title}" has been deleted` : "has been deleted"}`;

		if (notificationToDelete.id !== NEW_SLACK_NOTIFICATION_ID) {
			notificationToDelete.isDirty = false;
			notificationToDelete.isDeleting = true;

			slackNotificationsStore.delete(notificationToDelete.id).then(() => {
				notificationToDelete.isDeleting = false;
				setAvailableNotifications(availableNotifications?.filter(n => n.id !== notificationToDelete.id));

				toast.success(deleteToastTitle, {
					autoClose: 5000
				});
			}).catch();
		} else {
			setAvailableNotifications(availableNotifications?.filter(n => n.id !== notificationToDelete.id));
			toast.success(deleteToastTitle, {
				autoClose: 5000
			});
		}
	}

	function isNotificationValid(n: IVisualScheduledTask) {
		if (!n.type) {
			return false;
		}
		if (n.timeToSendMinute === undefined || n.timeToSendHour === undefined || _.isEmpty(n.daysToSend)
		|| n.timeToSendMinute % SEND_TIME_MINUTE_INTERVALS !== 0) {
			return false;
		}
		if (isEmptyOrWhitespace(n.timezone)) {
			return false;
		}
		if (isEmptyOrWhitespace(n.teamId)) {
			return false;
		}
		if (n.type === ScheduledTaskType.SHORT_SLACK_DIGEST_PUSH && _.isEmpty(n.shortTypeSections)) {
			return false;
		}
		if (n.type === ScheduledTaskType.LONG_SLACK_DIGEST_PUSH && _.isEmpty(n.longTypeSections)) {
			return false;
		}
		if (n.type === ScheduledTaskType.SLACK_DEVELOPER_SUMMARY_PUSH && (_.isEmpty(n.reviewReportTypeSections) || _.isEmpty(n.digestSubjectId))) {
			return false;
		}
		if (!n.destinationType) {
			return false;
		}

		switch (n.destinationType) {
			case SupportedDestination.Channel:
				if (isEmptyOrWhitespace(n.destinationChannelId)) {
					return false;
				}
				break;
			case SupportedDestination.Recipient:
				if (!isValidSlackChannelName(n.destinationRecipientId)) {
					return false;
				}
				break;
			default:
				return false;
		}

		return true;
	}

	function toCronString(task: IVisualScheduledTask): string {
		return `${task.timeToSendMinute} ${task.timeToSendHour} * * ${task.daysToSend.join(",")}`;
	}

	function toDigestConfig(task: IVisualScheduledTask): IDashboardSlackDigestConfig | undefined {
		if (task.type === ScheduledTaskType.SHORT_SLACK_DIGEST_PUSH && task.shortTypeSections) {
			const shortSection = task.shortTypeSections;
			const config: IShortSlackDigestConfig = {
				includeHighlights: (shortSection.indexOf(SupportedSectionsShortVersion.Highlights) !== -1),
				includeWorkDigest: (shortSection.indexOf(SupportedSectionsShortVersion.WorkDigest) !== -1),
			};

			return config;
		} else if (task.type === ScheduledTaskType.LONG_SLACK_DIGEST_PUSH && task.longTypeSections) {
			const longSection = task.longTypeSections;
			const config: ILongSlackDigestConfig = {
				includeInsights: (longSection.indexOf(SupportedSectionsLongVersion.Insights) !== -1),
				includeTasks: (longSection.indexOf(SupportedSectionsLongVersion.Tasks) !== -1),
				includeTeamMemberPullRequests: (longSection.indexOf(SupportedSectionsLongVersion.TeamMemberPullRequests) !== -1),
				includeBranches: (longSection.indexOf(SupportedSectionsLongVersion.Branches) !== -1),
				includeGeneralPullRequests: (longSection.indexOf(SupportedSectionsLongVersion.TeamMemberPullRequests) !== -1),
				includeMotivationalQuote: (longSection.indexOf(SupportedSectionsLongVersion.MotivationalQuote) !== -1),
				mode: LongSlackDigestMode.STANDARD
			};

			return config;
		} else if (task.type === ScheduledTaskType.SLACK_DEVELOPER_SUMMARY_PUSH && task.reviewReportTypeSections) {
			const reviewSection = task.reviewReportTypeSections;
			const devStats = [];
			if (reviewSection.indexOf(SupportedSectionsReviewReport.DevStats) !== -1) {
				devStats.push(DevStatsType.LOC_CHANGED);
			}
			const config: IDashboardReviewReportConfig = {
				includeTasks: (reviewSection.indexOf(SupportedSectionsReviewReport.Tasks) !== -1),
				includePullRequests: (reviewSection.indexOf(SupportedSectionsReviewReport.PullRequests) !== -1),
				devStats,
				displayMode: ReviewReportDisplayMode.STANDARD,
				recapReportPeriodHours: task.recapReportPeriodDays ? task.recapReportPeriodDays * 24 : 168,
				subjectId: task.digestSubjectId!
			};
			return config;
		}

		return undefined;
	}

	function convertToAddScheduledTask(task: IVisualScheduledTask): IDashboardAddSlackNotification | undefined {
		let destinationType: "channel" | "recipient" | undefined;
		let destinationChannelId: string | undefined;
		let destinationRecipientId: string | undefined;

		if (task.destinationType === SupportedDestination.Channel) {
			destinationType = "channel";
			destinationChannelId = task.destinationChannelId;
			if (destinationChannelId && !destinationChannelId.startsWith(SLACK_CHANNEL_HASHTAG)) {
				destinationChannelId = `${SLACK_CHANNEL_HASHTAG}${destinationChannelId}`;
			}
		} else if (task.destinationType === SupportedDestination.Recipient) {
			destinationType = "recipient";

			destinationRecipientId = task.destinationRecipientId;
		}
		if (!destinationType) {
			return undefined;
		}

		const config = toDigestConfig(task);
		if (!config) {
			return undefined;
		}
		const taskToSave: IDashboardAddSlackNotification = {
			type: task.type!,
			isOn: task.enabled,
			recipientType: destinationType,
			recipientId: destinationRecipientId,
			channel: destinationChannelId,
			teamId: task.teamId!,
			timezone: task.timezone,
			cron: toCronString(task),
			config,
		};
		return taskToSave;
	}

	function convertToUpdateScheduledTask(task: IVisualScheduledTask): IDashboardUpdateSlackNotification | undefined {
		let destinationType: "channel" | "recipient" | undefined;
		let destinationChannelId: string | undefined;
		let destinationRecipientId: string | undefined;

		if (task.destinationType === SupportedDestination.Channel) {
			destinationType = "channel";
			destinationChannelId = task.destinationChannelId;
			if (destinationChannelId && !destinationChannelId.startsWith(SLACK_CHANNEL_HASHTAG)) {
				destinationChannelId = `${SLACK_CHANNEL_HASHTAG}${destinationChannelId}`;
			}
		} else if (task.destinationType === SupportedDestination.Recipient) {
			destinationType = "recipient";
			destinationRecipientId = task.destinationRecipientId;
		}
		if (!destinationType) {
			return undefined;
		}

		const config = toDigestConfig(task);
		if (!config) {
			return undefined;
		}
		const taskToSave: IDashboardUpdateSlackNotification = {
			id: task.id,
			type: task.type!,
			isOn: task.enabled,
			recipientType: destinationType,
			recipientId: destinationRecipientId,
			channel: destinationChannelId,
			teamId: task.teamId!,
			timezone: task.timezone,
			cron: toCronString(task),
			config,
		};
		return taskToSave;
	}

	function onSaveNotification(notificationToSave: IVisualScheduledTask) {
		if (!availableNotifications || availableNotifications.length === 0) {
			return;
		}
		notificationToSave.inError = !isNotificationValid(notificationToSave);
		notificationToSave.shouldBeOpened = false;
		if (!notificationToSave.inError) {
			notificationToSave.title = createTitle(notificationToSave, teamMembersStore.allTeamMembers.data,
				dataContributorsStore.allDataContributors.data, teamsStore.allTeams.data);

			if (notificationToSave.id === NEW_SLACK_NOTIFICATION_ID) {
				const notificationToCreate = convertToAddScheduledTask(notificationToSave);
				if (notificationToCreate) {
					notificationToSave.isDirty = false;
					notificationToSave.isSaving = true;

					slackNotificationsStore.create(notificationToCreate).then(task => {
						notificationToSave.isSaving = false;
						if (!task) {
							notificationToSave.inError = true;
							const failedToSaveToastTitle = `${notificationToSave.title ? `"Failed to save notification ${notificationToSave.title}"` : "Failed to save notification"}`;
							toast.error(failedToSaveToastTitle, {
								autoClose: 5000
							});
						} else {
							notificationToSave.id = task.id;
							const savedToastTitle = `Notification ${notificationToSave.title ? `"${notificationToSave.title}" has been saved` : "has been saved"}`;
							toast.success(savedToastTitle, {
								autoClose: 5000
							});
						}

						setAvailableNotifications([...availableNotifications]);
					}).catch();
				} else {
					notificationToSave.inError = true;
				}
			} else {
				const notificationToUpdate = convertToUpdateScheduledTask(notificationToSave);
				if (notificationToUpdate) {
					notificationToSave.isDirty = false;
					notificationToSave.isSaving = true;

					slackNotificationsStore.update(notificationToUpdate).then(() => {
						notificationToSave.isSaving = false;
						const savedToastTitle = `Notification ${notificationToSave.title ? `"${notificationToSave.title}" has been updated` : "has been updated"}`;
						toast.success(savedToastTitle, {
							autoClose: 5000
						});
						setAvailableNotifications([...availableNotifications]);
					}).catch();
				} else {
					notificationToSave.inError = true;
				}
			}
		}

		setAvailableNotifications([...availableNotifications]);
	}

	function onDuplicateNotification(notificationToSave: IVisualScheduledTask) {
		if (!availableNotifications || availableNotifications.length === 0) {
			return;
		}
		const duplicateNotificationToSave = {...notificationToSave};
		duplicateNotificationToSave.inError = !isNotificationValid(duplicateNotificationToSave);
		duplicateNotificationToSave.shouldBeOpened = false;
		if (!duplicateNotificationToSave.inError) {
			duplicateNotificationToSave.title = createTitle(duplicateNotificationToSave, teamMembersStore.allTeamMembers.data,
				dataContributorsStore.allDataContributors.data, teamsStore.allTeams.data);
			setAvailableNotifications([...availableNotifications]);
			const notificationToCreate = convertToAddScheduledTask(duplicateNotificationToSave);
			if (notificationToCreate) {
				duplicateNotificationToSave.isDirty = false;
				duplicateNotificationToSave.isSaving = true;

				slackNotificationsStore.create(notificationToCreate).then(task => {
					duplicateNotificationToSave.isSaving = false;
					if (!task) {
						duplicateNotificationToSave.inError = true;
						const failedToSaveToastTitle = `${duplicateNotificationToSave.title ? `"Failed to save notification ${duplicateNotificationToSave.title}"` : "Failed to save notification"}`;
						toast.error(failedToSaveToastTitle, {
							autoClose: 5000
						});
					} else {
						duplicateNotificationToSave.id = task.id;

						const savedToastTitle = `Notification ${duplicateNotificationToSave.title ? `"${duplicateNotificationToSave.title}" has been duplicated` : "has been duplicated"}`;

						toast.success(savedToastTitle, {
							autoClose: 5000
						});
						setAvailableNotifications([{...duplicateNotificationToSave}, ...availableNotifications]);
					}
				}).catch();
			} else {
				notificationToSave.inError = true;
			}
		}
	}

	function onAddNotification() {
		const newNotification = createNewNotification(currentTimeZone);
		setAvailableNotifications([newNotification, ...availableNotifications ?? []]);
	}

	function canAddNotification() {
		if (slackNotificationsStore.allSlackNotificationsByDC.loading) {
			return false;
		}

		if (availableNotifications) {
			const hasNewNotification = availableNotifications.find(se => se.id === NEW_SLACK_NOTIFICATION_ID);
			if (hasNewNotification) {
				return false;
			}
		}
		return true;
	}
	const hasMandatoryDataFetched = (availableNotifications !== undefined && !dataContributorsStore.allDataContributors.loading && !teamMembersStore.teamMembersForAllTeams.loading);

	if (hasActiveIntegrations === false) {
		return (
			<><Header/>
			<div className="ui grid">
				<EmptyState
					onChoose={(type) => { setIntegrationType(type); } }
					isActive={authStore.isUserAdmin}
				/>
			</div></>
		);
	}

	return (
		<><Header />
		<div className="ui segment basic slack-notifications">
			<div className="form-header">
				<h1 className="form-title">
					Slack notifications
				</h1>
			</div>
			<div className="form-header">
				<PrimaryButton
					onClick={() => onAddNotification()}
					disabled={!canAddNotification()}
					loading={slackNotificationsStore.allSlackNotificationsByDC.loading}
					{...clickEvent(GA_EVENT_CATEGORY.SlackNotifications, GA_EVENT_ACTION.Add)}>
					add notification
				</PrimaryButton>
			</div>
			<ConfirmationModal
				className={"warning"}
				open={deleteNotification !== undefined}
				header={"Delete Slack notification?"}
				confirmButton={"Ok"}
				cancelButton={"Cancel"}
				content={`Delete ${(deleteNotification && deleteNotification.title) ? `"${deleteNotification.title}" notification` : "notification"} from Acumen?`}
				onCancel={() => toggleDeleteNotificationDialogAlert(undefined)}
				onConfirm={() => {
					if (deleteNotification !== undefined) {
						// tslint:disable-next-line: no-floating-promises
						onDeleteNotification(deleteNotification);
						toggleDeleteNotificationDialogAlert(undefined);
					}
				}}
			/>
			{!hasMandatoryDataFetched && <Loader size="large" active={true}>Loading...</Loader>}
			{hasMandatoryDataFetched && (
				<Accordion exclusive={false} fluid={true}>
					{(availableNotifications ? _.orderBy(availableNotifications, n => n.id, "desc") : []).map((notification, i) => {
						return (
							<SlackNotificationForm
								key={notification.id}
								index={i}
								allTeams={allTeams.data}
								allTeamsFetchingData={allTeams.loading}
								notification={notification}
								onDeleteNotification={toggleDeleteNotificationDialogAlert}
								onSaveNotification={onSaveNotification}
								onDuplicateNotification={onDuplicateNotification}
								onRefresh={(notificationToUpdate) => {
									if (!availableNotifications) {
										return;
									}
									const indexToUpdate = availableNotifications.findIndex(an => an.id === notificationToUpdate.id);
									availableNotifications[indexToUpdate] = notificationToUpdate;
									setAvailableNotifications(availableNotifications);
								}}
								teamMembersForAllTeams={teamMembersStore.teamMembersForAllTeams.data}
								teamMembersForAllTeamsFetchingData={teamMembersStore.teamMembersForAllTeams.loading}
								allDataContributors={dataContributorsStore.allDataContributors.data}
								allDataContributorsFetchingData={dataContributorsStore.allDataContributors.loading}
							/>);
					})}
				</Accordion>
			)}
		</div></>
	);
}
export default observer(SlackNotificationsManagementPage);
