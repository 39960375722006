
import { ChartType, DateRangeType, MetricInterval, parseKeyToObjectArray } from "@acumen/dashboard-common";
import PRCycleTimeChart from "../../pages/my-team/analytics/charts/pr-cycle-time";
import ClosedAndUnmergedPRsChart from "../../pages/my-team/analytics/charts/closed-and-unmerged-prs";

import React from "react";
import { useLocation } from "react-router-dom";

function getEnumValByName<T extends object>(enumType: T, name: string | undefined | null): T[keyof T] | undefined {
	if (!name) {
		return undefined;
	}

	return Object.values(enumType).find((type) => type.toString() === name);
}

function populateIfExists(source: string | string[] | null, target: Record<string, any>,
	targetKeyName: string, mutator: (x: string[] | string) => any = x => x) {
	if (source && source.length > 0) {
		target[targetKeyName] = mutator(source);
	}
}

function setUpChartProps(params: URLSearchParams) {
	const opts: any = {
		isFullScreen: false,
		filters: {}
	};

	const chart = getEnumValByName(ChartType, params.get("chart"));
	if (!chart) {
		return { chart: undefined, opts };
	}

	const timezone = params.get("timezone");
	if (!timezone) {
		return { chart, opts };
	}
	opts.filters.timezone = timezone;

	const interval = getEnumValByName(MetricInterval, params.get("interval"));
	if (!interval) {
		return { chart, opts };
	}

	opts.filters.interval = interval;

	const dateRange = getEnumValByName(DateRangeType, params.get("dateRange"));
	if (dateRange) {
		opts.filters.dateRange = dateRange;
	}

	populateIfExists(params.get("startTime"), opts.filters, "startTime", x => new Date(x as string));
	populateIfExists(params.get("endTime"), opts.filters, "endTime", x => new Date(x as string));

	const originalFilters = parseKeyToObjectArray(params.getAll("dimensions[]"));

	populateIfExists(originalFilters.team_id as string[], opts.filters, "teamId", x => x[0]);
	populateIfExists(originalFilters.data_contributor_id as string[], opts.filters, "dataContributorIds");
	populateIfExists(originalFilters.sprint_board_id as string[], opts.filters, "boardIds");
	populateIfExists(originalFilters.repository_id as string[], opts.filters, "gitRepositoryIds");
	populateIfExists(originalFilters.base_is_default_branch as string[], opts.filters, "baseIsDefaultBranch", x => true);
	populateIfExists(originalFilters.is_draft as string[], opts.filters, "excludeDraft", x => true);
	populateIfExists(originalFilters.issue_type_name as string[], opts.filters, "issueTypes", x => true);

	return { chart, opts };
}

function Chart() {
	const searchParams = new URLSearchParams(useLocation().search);

	const { chart, opts } = setUpChartProps(searchParams);

	if (!chart) {
		return (<div>Error while fetching chart</div>);
	}

	switch (chart) {
		case ChartType.PRCycleTime:
			return (<PRCycleTimeChart {...opts} />);

		case ChartType.ClosedUnMergedPrs:
			return (<ClosedAndUnmergedPRsChart {...opts} />);

		default:
			return (<div>Unsupported chart</div>);
	}
}

export default Chart;
