import React from "react";
import { IDashboardInsightRule } from "@acumen/dashboard-common";
import TableMenu, { IColumnDefinition, IColumnTypes } from "../../../../components/table-menu";
import { STRINGS } from "../../../../../localization";

interface IProps {
	insightRules: IDashboardInsightRule[];
	onInsightToggleActive: (insightId: string, isActive: boolean) => void;
	onInsightClicked: (insight: IDashboardInsightRule) => void;
}

export default function InsightRulesGrid(props: IProps) {

	const columnsDef: Array<IColumnDefinition<IDashboardInsightRule>> = [
		{
			key: "isActive",
			headerText: STRINGS.ACTIVE,
			type: IColumnTypes.checkboxInput,
			onChangeAction: props.onInsightToggleActive
		},
		{
			key: "name",
			headerText: STRINGS.NAME,
			type: IColumnTypes.text
		},
		{
			key: "description",
			headerText: STRINGS.DESCRIPTION,
			type: IColumnTypes.text
		},
	];

	return (
		<TableMenu
			data={props.insightRules}
			columnsDef={columnsDef}
			onRowClicked={props.onInsightClicked}
			rowIdKey="id"
		/>
	);
}
