import apiContextProvider from "../../services/api-context-provider";
import { GitRepositoriesApiClient, IGitRepositorySort, IGitRepositoryFilter, GIT_REPOSITORIES_ROUTE } from "../services/crud/git-repositories-api-client";
import {
	IDashboardGitRepository,
	ITeamGitRepositories
} from "@acumen/dashboard-common";
import { observable, action, computed } from "mobx";
import _ from "lodash";
import { ISelectOptionGroups, ISelectOptions } from "../components/form/option-select";
import { FetchLatestRequest } from "../../services/fetch-helpers";

export default class GitRepositoriesStore {

	private readonly apiClient: GitRepositoriesApiClient = new GitRepositoriesApiClient(apiContextProvider);

	@observable
	public gitRepositoriesData: ITeamGitRepositories = {
		external: [],
		ownable: []
	};

	@observable
	public isLoading = false;

	private fetchLatestGitRepositories = new FetchLatestRequest<ITeamGitRepositories, any>(GIT_REPOSITORIES_ROUTE);
	@action.bound
	async fetchData(filter?: IGitRepositoryFilter, sort?: IGitRepositorySort) {
		this.isLoading = true;
		const result = await this.fetchLatestGitRepositories.fetchLatest(this.apiClient.fetchGitRepositories(filter, sort));

		this.gitRepositoriesData = (result?.data ?? {
			external: [],
			ownable: []
		});
		this.isLoading = false;
	}

	@computed
	public get gitRepositoriesOptions(): ISelectOptionGroups[] {
		return Object.keys(this.gitRepositoriesData).map(repoCategory => {
			return {
				label: repoCategory,
				options:
					_
					.orderBy(this.gitRepositoriesData[repoCategory as keyof ITeamGitRepositories], r => r.name.toLowerCase(), "asc")
					.map((opt: IDashboardGitRepository) => {
						return {
							key: opt.entityId,
							value: `${opt.entityId},${opt.entityType}`,
							label: opt.name
						};
					})
			};
		});
	}

	@computed
	public get gitRepositoriesOptionsFlattened(): ISelectOptions[] {
		return _.orderBy(
				_.flatten(this.gitRepositoriesOptions.map(o => o.options)), r => r.label.toLowerCase(), "asc");
	}
}
