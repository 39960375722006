import React from "react";
import { Button } from "semantic-ui-react";
import "./style.scss";

interface IPrimaryButton {
	label?: string;
	children?: React.ReactNode;
	onClick?: () => void;
	loading?: boolean;
	disabled?: boolean;
}

// tslint:disable-next-line: variable-name
export const PrimaryButton = ({label, children, onClick, loading, disabled}: IPrimaryButton) => {
	return <Button className="primary-button acu-capitalize" label={label} onClick={onClick} loading={loading} disabled={disabled}>{children}</Button>;
};

export default PrimaryButton;
