import React from "react";
import { Popup, PopupContentProps, SemanticShorthandItem } from "semantic-ui-react";

interface IExceededBarChart {
	percents?: number;
	animate?: boolean;
	tooltip?: SemanticShorthandItem<PopupContentProps>;
}

const DEFAULT_BAR_WIDTH = 100;
const DEFAULT_SUF_BAR_WIDTH = 25;
const DEFAULT_BAR_HEIGHT = 9;
const TOTAL_WIDTH = DEFAULT_BAR_WIDTH + DEFAULT_SUF_BAR_WIDTH;
const EXCEEDS_FROM_PERCENTS = 10;

const DEFAULT_COLORS = {
	background: "transparent",
	primary: "#3E6FA7",
	suffix: "#FA7700",
	transparent: "transparent"
};

// tslint:disable-next-line: variable-name
const ExceededBarChart = (props: IExceededBarChart) => {
	const {
		percents = 0,
		animate = true,
		tooltip
	} = { ...props };
	const getBarBackground = (_percents: number) => {
		const primary = _percents >= 100 ? DEFAULT_BAR_WIDTH + "px" : _percents / 2 + "px";
		const suffix = _percents - 100;

		let backgroundStyle = `linear-gradient(to right,
			${DEFAULT_COLORS.primary} ${0}px,
			${DEFAULT_COLORS.primary} ${primary},
			${DEFAULT_COLORS.background} ${primary},
			${DEFAULT_COLORS.background} ${DEFAULT_BAR_WIDTH}px`;

		backgroundStyle += suffix > EXCEEDS_FROM_PERCENTS ?
			`, ${DEFAULT_COLORS.suffix} ${DEFAULT_BAR_WIDTH}px , ${DEFAULT_COLORS.suffix} ${TOTAL_WIDTH}px)` :
			`, ${DEFAULT_COLORS.transparent} ${DEFAULT_BAR_WIDTH}px , ${DEFAULT_COLORS.transparent} ${TOTAL_WIDTH}px)`;

		return backgroundStyle;
	};

	const barStyle = {
		width: TOTAL_WIDTH,
		height: DEFAULT_BAR_HEIGHT,
		background: getBarBackground(percents)
	};

	const labelStyle = {
		fontSize: `${DEFAULT_BAR_HEIGHT + 1}px`,
		lineHeight: `${DEFAULT_BAR_HEIGHT + 1}px`
	};

	const getBar = () => (
		<div className="bar-chart" style={{width: TOTAL_WIDTH}}>
			<div className="ghost-bar" style={{ width: DEFAULT_BAR_WIDTH }}>
				<div className={"bar" + (animate ? " animate" : "")} style={barStyle} />
			</div>
			{percents <= 0 &&
				<span className={"no-value"} style={labelStyle}>N/A</span>
			}
		</div>
	);

	return (
		tooltip ?
			(
				<Popup
					size="small"
					basic={true}
					content={tooltip}
					trigger={
						(getBar())
					}
				/>
			)
			:
			(getBar())
	);
};
export default ExceededBarChart;
