import classNames from "classnames";
import { CONNECT_BUTTON_TEXT, IIntegrationFlow, NEW_INTEGRATION_URL, StepsActionType } from "../../../../mobx-stores/new-integrations-store";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "semantic-ui-react";
import { flowStepsHeader } from ".";
import "./style.scss";

interface IIntegrationFlowStep {
	currentStepNumber: number;
	setCurrentStepNumber: (step: number) => void;
	overrideUserText?: string;
	overrideInstallLink?: string;
	onClose: () => void;
	flow: IIntegrationFlow;
	integrationId?: string;
	setIntegrationId: (id?: string) => void;
	clearState: () => void;
}

// tslint:disable-next-line: variable-name
const JiraIntegrationFlow = (props: IIntegrationFlowStep) => {
	const {
		currentStepNumber, setCurrentStepNumber, onClose, flow, integrationId, setIntegrationId, clearState
		} = { ...props };
	const [open, setOpen] = useState(true);
	const [isStepDone, setStepDone] = useState(false);
	const [isLoading, toggleLoading] = useState(false);
	const [message, setMessage] = useState<{ msg: string, isError: boolean } | undefined>(undefined);
	const [userText, setUserText] = useState<string | undefined>(undefined);
	const [autoVerifyLink, setAutoVerifyLink] = useState<boolean>(false);

	const [userInput, setUserInput] = useState<string | undefined>(undefined);
	const [installLink, setInstallLink] = useState<string | undefined>(undefined);

	const currentStep = flow.steps[currentStepNumber];
	const history = useHistory();

	useEffect(() => {
		if (currentStep && currentStep.actionType === StepsActionType.FetchInstallData && !isLoading) {
			toggleLoading(true);
			onNext();
		}
	}, [currentStepNumber]);

	useEffect(() => {
		if (autoVerifyLink) {
			jiraVerifyLink();
		}
	}, [autoVerifyLink]);

	const getInstallLinkOrOverride = () => {
		return installLink || props.overrideInstallLink;
	};
	const getUserTextOrOverride = () => {
		return userText || props.overrideUserText;
	};

	const fetchInstallData = () => {
		setMessage(undefined);
		if (integrationId === undefined) {
			currentStep.action({}).then((res: any) => {
				setIntegrationId("");
				if (res) {
					setIntegrationId(res.integrationId);
					setInstallLink(res.installLink);
					setCurrentStepNumber(currentStepNumber + 1);
				} else {
					setIntegrationId(undefined);
					setMessage({ msg: "Could not fetch install link", isError: true });
				}
			});
		}
	};

	// tslint:disable-next-line: no-shadowed-variable
	const userInputDo = (userInput?: string) => {
		setMessage(undefined);
		currentStep.action({ userInput, integrationId }).then((res: any) => {
			if (!res || res?.error) {
				setMessage({ msg: res ? res.error : "Something went wrong", isError: true });
				return;
			}
			const wasItInstallLink = res.integrationAuthUrl ?? res?.guide ? res?.guide?.appLinkInternalUrl : null;
			if (wasItInstallLink) {
				setInstallLink(wasItInstallLink);
			}
			setUserText(res.appLink);
			setIntegrationId(res.integrationId);
			if (res.moveStep) {
				setCurrentStepNumber(currentStepNumber + 1);
			}
		});
	};

	const jiraVerifyLink = () => {
		setUserText(undefined);
		currentStep.action({ integrationId }).then((res: any) => {
			setAutoVerifyLink(false);
			if (res && res.integrationAuthUrl) {
				setInstallLink(res.integrationAuthUrl);
				setMessage({ msg: `Check out new window or go to: <a target="_blank" rel="noopener noreferrer" href=${getInstallLinkOrOverride()}> ${getInstallLinkOrOverride()} </a>`, isError: false });
				setCurrentStepNumber(currentStepNumber + 1);
			} else {
				setMessage({ msg: `could not validate the link`, isError: true });
			}
			return res;
		});
	};

	const openRedirectWindow = () => {
		setMessage(undefined);
		const redirectUrl = getInstallLinkOrOverride();
		if (redirectUrl) {
			const newWin = window.open(redirectUrl, "installLink");
			const winInterval = setInterval(() => {
				if (newWin !== null && newWin.closed) {
					clearInterval(winInterval);
					if (!autoVerifyLink) {
						setAutoVerifyLink(true);
					}
				}
			}, 1000);
			setMessage({ msg: `We opened a <a target="_blank" rel="noopener noreferrer" href=${redirectUrl}> new window</a> for this integration`, isError: false });
			setCurrentStepNumber(currentStepNumber + 1);
		} else {
			setMessage({ msg: "Could not redirect to install link", isError: true });
		}
	};

	const getNextAction = () => {
		switch (currentStep?.actionType) {
			case StepsActionType.FetchInstallData:
				return fetchInstallData;
			case StepsActionType.UserInput:
				return userInputDo;
			case StepsActionType.GoToRedirect:
				return openRedirectWindow;
			case StepsActionType.VerifyLink:
				return jiraVerifyLink;
			case StepsActionType.Success:
				return () => { clearState(); history.push(`/${NEW_INTEGRATION_URL}/add`); };
			default:
				return () => { setCurrentStepNumber(0); };
		}
	};

	const getActionBox = () => {
		switch (currentStep?.actionType) {
			case StepsActionType.GoToRedirect:
				return <>
					{ (getUserTextOrOverride()) &&
						<div className="ui buttons copy-input">
						<input className="ui label break-word" value={getUserTextOrOverride()} disabled={true} width="200px" />
							<button className="ui primary button"
							onClick={() => navigator.clipboard.writeText(getUserTextOrOverride() || "")}>
								<i className="ui icon copy outline" aria-hidden="true" />
									Copy
								</button>
						</div>
					}
				</>;
			case StepsActionType.UserInput:
				const { placeholder, type } = { ...currentStep.userInput };
				return (
					<div className="flex">
						<form className="ui form">
							<div className="field">
								<div className="ui input">
									<input
										required={true}
										placeholder={placeholder}
										type={type}
										onChange={(e) => {
											toggleLoading(false);
											setUserInput(e.target.value ?? undefined);
										}}
									/>
									<span style={{ padding: "0px 10px" }} />
									<button
										className={classNames("ui button", { loading: isLoading })}
										onClick={(e) => { e.preventDefault(); toggleLoading(true); onNext(userInput); }}
										disabled={!userInput}
									>Go
									</button>
								</div>
								{message?.isError && <div className="ui pointing red label">{message?.msg}</div>}
							</div>
						</form>
					</div >
				);
			case StepsActionType.VerifyLink:
				return (
					<div className={"ui buttons big-button-style"}>
						<button className="ui primary button huge"
							onClick={(e) => { e.preventDefault(); e.stopPropagation(); onNext(userInput); }}
							disabled={autoVerifyLink}
						>
							Verify
						</button>
						{ autoVerifyLink && <button className={classNames({ loading: autoVerifyLink }, "ui button")} disabled={true} />}
					</div>
				);
		}
	};

	const getActionButton = () => {
		switch (currentStep?.actionType) {
			case StepsActionType.ChooseProvider:
				return <></>;
			case StepsActionType.FetchInstallData:
				return (
					<div className="ui buttons">
						<button className="ui button loading" disabled={true} />
						<button className="ui button" disabled={true}>{CONNECT_BUTTON_TEXT}</button>
					</div>
				);
			case StepsActionType.UserInput:
				return (
					<></>
				);
			case StepsActionType.GoToRedirect:
				return (
					<button className="ui primary button"
						onClick={() => {
							setStepDone(true);
							onNext();
						}}>
						{CONNECT_BUTTON_TEXT}
					</button>
				);
			case StepsActionType.GetToken:
				return (
					<div className="ui buttons">
						<button className="ui button loading" disabled={true} />
						<button className="ui button" disabled={true}>{CONNECT_BUTTON_TEXT}</button>
					</div>
				);
			case StepsActionType.Success:
				return (
					<button className="ui primary button"
						onClick={(e) => {
							e.preventDefault();
							setStepDone(true);
							onNext();
							history.push(`/${NEW_INTEGRATION_URL}/add`);
						}}>
						Done
					</button>
				);
		}
	};

	const getMessageBox = () => (
		currentStep?.actionType !== StepsActionType.UserInput ?
			<div className={classNames("ui icon message", { warning: message?.isError })} style={{ position: "relative", bottom: "10%" }}>
				<div className="content">
					<div className="header">
						{message?.isError ? "Something went wrong" : "Notice"}
					</div>
					{message && <p dangerouslySetInnerHTML={{ __html: message.msg }} />}
				</div>
			</div>
			: <></>
	);

	const onNext = getNextAction();
	return (
		<Modal
			onClose={() => {
				setOpen(false);
				onClose();
			}}
			onOpen={() => setOpen(true)}
			open={open}
			className={"integration-flow"}
		>
			<Modal.Header style={{ padding: "0px", fontWeight: "inherit" }}>
				{flowStepsHeader(flow, currentStepNumber, isStepDone)}
			</Modal.Header>
			{currentStep && <Modal.Content >
				<div className="flex-column description-box" style={{ minHeight: "450px" }}>
					<Modal.Description>
						<h2 className="ui header">{currentStep.name}</h2>
						<p>
							{currentStep.description}
						</p>
						<div className="step-action" style={{ margin: "auto", width: "initial", alignContent: "center" }}>
							{getActionBox()}
						</div>
					</Modal.Description>
					{message && message.isError && getMessageBox()}
				</div>
			</Modal.Content>}
			<Modal.Actions className="step-buttons flex-row">
				<div className="ui buttons">
					<button
						className="ui primary button link-button"
						value="Cancel"
						onClick={() => {
							onClose();
							setOpen(false);
							history.push(`/${NEW_INTEGRATION_URL}`);
						}}>
						Cancel</button>
				</div>
				{getActionButton()}
			</Modal.Actions>
		</Modal >
	);
};
export default JiraIntegrationFlow;
