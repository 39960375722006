import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

export type FilterFunction<T> = (item: T) => boolean;

interface FilterProviderProps<T> {
	isFilteringEnabled?: boolean;
	items: T[];
	initialFilters?: Array<FilterFunction<T>>;
	onFiltersChange: (filters: Array<FilterFunction<T>>) => unknown;
	children?: React.ReactNode;
}

interface FilterContextProps<T> {
	isFilteringEnabled: boolean;
	items: T[];
	filters: Array<FilterFunction<T>>;
	addFilter: (filter: FilterFunction<T>) => void;
	removeFilter: (filter: FilterFunction<T>) => void;
}

const FilterContext = React.createContext<FilterContextProps<any>>({
	isFilteringEnabled: false,
	items: [],
	filters: [],
	addFilter: () => void 0,
	removeFilter: () => void 0,
});

export function useFilterContext() {
	return useContext(FilterContext);
}

function FilterProvider<T>(props: FilterProviderProps<T>) {
	const { isFilteringEnabled = true, items, initialFilters = [], onFiltersChange, children } = props;
	const [filters, setFilters] = useState(initialFilters);
	const addFilter = useCallback(
		(filter: FilterFunction<T>) => setFilters(prevFilters => [...prevFilters, filter]),
		[]
	);
	const removeFilter = useCallback(
		(filter: FilterFunction<T>) => setFilters(prevFilters => prevFilters.filter(f => f !== filter)),
		[]
	);
	const filterContextValue = useMemo<FilterContextProps<T>>(() => ({
		isFilteringEnabled,
		items,
		filters,
		addFilter,
		removeFilter,
	}), [items, filters, addFilter, removeFilter]);

	useEffect(() => {
		onFiltersChange(filters);
	}, [filters, onFiltersChange]);

	return (
		<FilterContext.Provider value={filterContextValue}>
			{children}
		</FilterContext.Provider>
	);
}

export default FilterProvider;
