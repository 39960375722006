import React, { useState } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import DatePicker from "react-datepicker";

export interface IDatePickerProps {
	locale: string;
	className?: string;
	isRangeSelector: boolean;
	setStartDate: (data: Date | undefined) => void;
	setEndDate?: (data: Date | undefined) => void;
	startDate?: Date;
	endDate?: Date;
	earliestDayOfReport?: Date;
}

// tslint:disable-next-line: variable-name
const DatePickerSelector = observer((props: IDatePickerProps) => {
	const { isRangeSelector, setStartDate, startDate, setEndDate, endDate, earliestDayOfReport, locale} = { ...props };

	const onChange = (date: Date | null) => {
		if (!date) {
			return;
		}

		date = moment(date).startOf("day").toDate();

		if (!isRangeSelector) {
			setStartDate(date);
			return;
		}

		if (!setEndDate) {
			setStartDate(date);
			return;
		}

		if (endDate !== undefined || startDate === undefined) {
			setStartDate(date);
			setEndDate(undefined);
		} else {
			if (moment(date).isBefore(startDate)) {
				setStartDate(date);
				setEndDate(undefined);
			} else {
				setEndDate(moment(date).endOf("day").toDate());
			}
		}
	};

	const [displayYear, setDisplayYear] = useState(new Date().getFullYear());

	return (
		<div className={"datepicker-wrapper"}>
			<div className="datepicker-custom-year">
				{displayYear}
			</div>
			<DatePicker
				className={"form-control"}
				placeholderText="Click to select a date"
				selected={startDate}
				onChange={onChange}
				inline={true}
				shouldCloseOnSelect={true}
				startDate={startDate}
				endDate={endDate}
				isClearable={true}
				minDate={earliestDayOfReport}
				locale={moment.locale(locale)}
				maxDate={moment().toDate()}
				selectsEnd={endDate === undefined}
				allowSameDay={true}
				showPopperArrow={false}
				monthsShown={2}
				renderCustomHeader={({
					// @ts-ignore
					date, monthDate, decreaseMonth, customHeaderCount, nextMonthButtonDisabled, increaseMonth
				}) => {
					setDisplayYear(date.getFullYear());
					return (
						<div className={"datepicker-custom-header"}>
							<div className="custom-header-navigation-section">
								<button
									aria-label="Previous Month"
									className={
										"react-datepicker__navigation react-datepicker__navigation--previous"
									}
									style={customHeaderCount === 1 ? { visibility: "hidden" } : undefined}
									onClick={decreaseMonth}
								>
									<span
										className={
											"react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
										}
									>
									  <i aria-hidden="true" className="chevron right icon"/>
									</span>
								</button>
								{!nextMonthButtonDisabled && <button
									aria-label="Next Month"
									className={
										"react-datepicker__navigation react-datepicker__navigation--next"
									}
									style={customHeaderCount === 0 ? {visibility: "hidden"} : undefined}
									onClick={increaseMonth}
								>
									<span
										className={
											"react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
										}
									>
									  <i aria-hidden="true" className="chevron right icon"/>
									</span>
								</button>}
							</div>
							<div className="sub-header">
								{monthDate.toLocaleString("en-US", {
									month: "long",
								})}
							</div>
						</div>
					);
				}}
			/>
		</div>
	);
});
export default DatePickerSelector;
