import { DashboardIntegrationType } from "@acumen/dashboard-common";
import { useStores } from "../../../../mobx-stores";
import { integrationTypesInfo, IProviderData } from "../../../../mobx-stores/new-integrations-store";
import React from "react";
import { inflect } from "@acumen/common";
import { Card, Image } from "semantic-ui-react";

interface IChooseIntegration {
	onChoose: (integrationTypeId: DashboardIntegrationType) => void;
}

// tslint:disable-next-line: variable-name
const ChooseIntegration = (props: IChooseIntegration) => {
	const {
		integrationsStore: {
			sortedIntegrationByCreation,
		},
	} = useStores();

	const getCategoryComment = (integrationType: DashboardIntegrationType) => {
		const countOfMatchingIntegrations =
			sortedIntegrationByCreation?.filter(integration => integration.type === integrationType).length ?? 0;
		return inflect(countOfMatchingIntegrations, "integration", "integrations");
	};

	const getIntegrationCard = (providers: IProviderData[]) => {
		return providers.map((pro, i) => (
			<Card onClick={() => props.onChoose(pro.type)} key={i}>
				<Card.Content>
					<Image floated="right" size="mini" src={pro.logoSrc} />
					<Card.Header>{pro.displayName}</Card.Header>
					<Card.Meta>{pro.group}</Card.Meta>
					<Card.Description>
						{getCategoryComment(pro.type)}
					</Card.Description>
				</Card.Content>
				<Card.Content extra={true}>
					<button className="ui tiny button primary install-button">Connect</button>
				</Card.Content>
			</Card>
		));
	};
	return (
		<Card.Group className="centered">
			{getIntegrationCard(Object.values(integrationTypesInfo))}
		</Card.Group>
	);
};
export default ChooseIntegration;
