import React from "react";
import { observer } from "mobx-react";
import {
	Switch, Route, useRouteMatch, Redirect
} from "react-router-dom";
import AnalyticsPage from "./analytics";
import WorkTasksPage from "./work/task";
import TeamDashboard from "./dashboard";
import WorkPullRequestsPage from "./work/pr";
import Digest from "./digest";
import SprintRetro from "./sprint-retro";
import Header from "./header";
import "./style.scss";
import { useStores } from "../../mobx-stores";
import EmptyState from "../empty-state";

// tslint:disable-next-line: variable-name
const Page = observer(() => {
	const {
		integrationsStore: {
			hasActiveIntegrations,
			setIntegrationType
		},
		authStore: {
			isUserAdmin
		}
	} = useStores();
	const match = useRouteMatch()!;

	if (hasActiveIntegrations === false) {
		return (
			<><Header hideTeamSelector={true} />
			<div className="ui grid">
				<EmptyState
					onChoose={(type) => { setIntegrationType(type); }}
					isActive={isUserAdmin}
				/>
			</div></>
		);
	}

	return (
		<div className="my-team-page ui fluid container">
			<Header />
			<div className="ui grid">
				<div className="sixteen wide column">
					<Switch>
						<Route path={`${match.url}/views/tasks`} component={WorkTasksPage} />
						<Route path={`${match.url}/views/pull-requests`} component={WorkPullRequestsPage} />
						<Route path={`${match.url}/work-diff`} component={Digest} />
						<Route path={`${match.url}/iteration-review`} component={SprintRetro} />
						<Route path={`${match.url}/analytics`} component={AnalyticsPage} />
						<Route path={`${match.url}/dashboard`} component={TeamDashboard} />
						<Route>
							<Redirect to={`${match.url}/dashboard`} />
						</Route>
					</Switch>
				</div>
			</div>
		</div>
	);
});

export default Page;
