import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import Loader from "../../../components/loader/loader";
import { useStores } from "../../../mobx-stores";
import _ from "lodash";
import { Pagination } from "semantic-ui-react";
import { useRouteMatch, Link, useHistory } from "react-router-dom";
import { IDashboardTeam } from "@acumen/dashboard-common";
import ConfirmationModal from "../../../components/modals/confirmation-modal";
import { GA_EVENT_ACTION, GA_EVENT_CATEGORY, clickEvent } from "../../../analytics-events";
import classNames from "classnames";

const ROWS_PER_PAGE = 20;
export const NEW_TEAM_ROUTE = "new";

export default observer(() => {
	const match = useRouteMatch();
	const {
		authStore,
		teamsStore: { allTeams, fetchAllTeams, deleteTeam }
	} = useStores();
	const { loading, loaded } = allTeams;
	const [page, setPage] = useState<number>(0);
	const [rows, setRows] = useState<IDashboardTeam[]>([]);
	const [total, setTotal] = useState(0);
	const [isDeletingTeam, setIsDeletingTeam] = useState<boolean>(false);
	const [deletedTeamId, toggleDeletedTeamDialogAlert] = useState<string | undefined>(undefined);
	const history = useHistory();

	useEffect(() => {
		async function loadPageData() {
			// tslint:disable-next-line: no-floating-promises
			fetchAllTeams();
		}
		// tslint:disable-next-line: no-floating-promises
		loadPageData();
	}, [match]);

	useEffect(() => {
		if (!allTeams.loading) {
			setTotal(allTeams.data.length);
			setPage(1);
		}

		return () => {
			setPage(0);
			setTotal(0);
		};
	}, [allTeams]);

	useEffect(() => {
		if (total > 0) {
			const result = _.chain(allTeams.data)
				.orderBy(team => team.name.toLowerCase(), "asc")
				.drop(ROWS_PER_PAGE * (page - 1))
				.take(ROWS_PER_PAGE)
				.value();

			setRows(result);
		}

		return () => {
			setRows([]);
		};
	}, [total, page, allTeams.data]);

	return (
		<Loader	isActive={!loaded || loading}>
			<ConfirmationModal
				className={"warning"}
				open={deletedTeamId !== undefined}
				header={"Delete team?"}
				confirmButton={"Ok"}
				cancelButton={"Cancel"}
				content={`Delete "${rows.find(r => r.id === deletedTeamId)?.name}" from Acumen?`}
				onCancel={() => toggleDeletedTeamDialogAlert(undefined)}
				showLoading={isDeletingTeam}
				onConfirm={() => {
					setIsDeletingTeam(true);
					// tslint:disable-next-line: no-floating-promises
					deleteTeam(deletedTeamId ?? "");
					toggleDeletedTeamDialogAlert(undefined);
					setIsDeletingTeam(false);
				}}
			/>
			{authStore.isUserExecutiveOrAbove && (
				<Link to={`${match!.url}/edit/${NEW_TEAM_ROUTE}`}>
					<button className="ui button" {...clickEvent(GA_EVENT_CATEGORY.Teams, GA_EVENT_ACTION.Add)}>New team</button>
				</Link>
			)}
			<div className="ui segment raised">
				<table className="ui very compact single fixed line small table teams-table">
					<thead>
						<tr>
							<th className="ui four column wide">Name</th>
							<th className="ui four column wide">Description</th>
							<th className="ui four column wide">Members Count</th>
							<th className="two wide" />
						</tr>
					</thead>
					<tbody>
						{rows.map(({ id, name, description, membersCount, teamLeadContributorId }) => (
							<tr className={"hand-cursor-on-hover"} key={id}
								{...clickEvent(GA_EVENT_CATEGORY.Teams, GA_EVENT_ACTION.Edit)}
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									history.push({
										pathname: `${match!.url}/edit/${id}`,
										state: { hideLastPathnamePart: true }
									});
								}}
							>
								<td className="ellipsis">{name}</td>
								<td className="ellipsis">{description}</td>
								<td>{membersCount}</td>
								<td className="options">
									<span className="item" >
										<i className={classNames(["edit", "icon", { disabled: (!authStore.isUserExecutiveOrAbove &&
											!(authStore.isUserManagerOrAbove && authStore.authUser.dataContributorDetails.id === teamLeadContributorId)) }])} />
									</span>
									<span className="item">
										<i className={classNames(["trash", "alternate", "icon", { disabled: !authStore.isUserExecutiveOrAbove }])}
										{...clickEvent(GA_EVENT_CATEGORY.Teams, GA_EVENT_ACTION.Delete)} onClick={(_e) => {
											_e.stopPropagation();
											if (authStore.isUserExecutiveOrAbove) {
												toggleDeletedTeamDialogAlert(id);
											}
										}} />
									</span>
								</td>
							</tr>
						))}
					</tbody>
					{total > ROWS_PER_PAGE && (
						<tfoot>
							<tr>
								<td colSpan={4}>
									<div className="ui basic center aligned segment">
										<Pagination
											boundaryRange={0}
											defaultActivePage={page}
											ellipsisItem={null}
											firstItem={null}
											lastItem={null}
											siblingRange={1}
											totalPages={Math.ceil(total / ROWS_PER_PAGE)}
											onPageChange={(_e, { activePage }) => setPage(activePage as number ?? 1)}
										/>
									</div>
								</td>
							</tr>
						</tfoot>
					)}
				</table>
			</div>
		</Loader>
	);
});
