import React, { useEffect, useState } from "react";
import "./style.scss";
import { Radio } from "semantic-ui-react";
import classNames from "classnames";
import { IDashboardSprint } from "@acumen/dashboard-common";
import { ACUMEN_SUPPORT_IN_GO_RETRO_EMAIL } from "../../external-app";
import { useStores } from "../../mobx-stores";
import { DevelopmentMethodology } from "@acumen/dashboard-common";
import { observer } from "mobx-react";
import { useLocation, useHistory } from "react-router-dom";

const SELECTED_SPRINTS_QUERY_PARAM = "sprint";

const SprintSelector = observer((props: {
	sprints: IDashboardSprint[],
	onNext: (sprintIds: string[], defaultConfig: boolean, onFinally: () => void) => void
}) => {

	const {
		sprintsStore
	} = useStores();
	const history = useHistory();
	const location = useLocation();

	const [selectedSprints, setSelectedSprints] = useState<string[]>([]);
	const [defaultConfig, setDefaultConfig] = useState(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { sprints = [], onNext = () => { return; } } = props;

	useEffect(() => {
		if (!sprints || sprints.length === 0) {
			setIsLoading(true);
			// Note: currently we don't support dev cycles for go retro customers
			sprintsStore.fetchData(DevelopmentMethodology.Scrum).then(() => {
				setIsLoading(false);
			}).catch();
		}

		setSelectedSprints(extractSelectedSprintIdsFromQuery());
	}, []);

	const handleSelectSprint = (id: string) => {
		const updatedSelectedSprints = [...selectedSprints];

		const index = updatedSelectedSprints.indexOf(id);
		if (index > -1) {
			updatedSelectedSprints.splice(index, 1);
		} else {
			updatedSelectedSprints.push(id);
		}

		setSelectedSprints(updatedSelectedSprints);
		updateQueryString(updatedSelectedSprints);
	};

	const extractSelectedSprintIdsFromQuery = (): string[] => {
		const params = new URLSearchParams(location.search);
		const sprintIds = params.getAll(SELECTED_SPRINTS_QUERY_PARAM);
		if (!sprintIds) {
			return [];
		}
		return sprintIds;
	};

	const updateQueryString = (selectedSprintIds: string[]) => {
		const params = new URLSearchParams({});
		selectedSprintIds.forEach(e => params.append(SELECTED_SPRINTS_QUERY_PARAM, e));
		history.replace({ search: params.toString() });
	};

	const sprintsToShow = sprints && sprints.length > 0 ? sprints : sprintsStore.sprintData;

	return (
		<div className="external-page">
			<div className="sprint-selector-wrapper">
				<h1 className="selector-title">Select Sprints</h1>
				{sprintsToShow && sprintsToShow.length === 0 &&
					<>
						<p className="selector-description">We couldn't find any sprints from the last month.</p>
						<div className="selector-description">Please contact us in the chat or write an
							<a href={`mailto:${ACUMEN_SUPPORT_IN_GO_RETRO_EMAIL}`} target="_blank" rel="noopener noreferrer" className="link">
									{` email: `}
							</a>
							<div className="underline">{ACUMEN_SUPPORT_IN_GO_RETRO_EMAIL}</div>
						</div>
					</>
				}
				{sprintsToShow.length !== 0 &&
					<>
						<p className="selector-description">Select the sprints you would like to view:</p>
						<div className="sprints-container">
							{sprintsToShow.map(sprint => {
								return (
									<div
										key={sprint.id}
										onClick={() => handleSelectSprint(sprint.id)}
										className={classNames("sprints-range-item", selectedSprints.includes(sprint.id) && "selected")}
									>
										{sprint.name}
									</div>
								);
							})}
						</div>
						<div className="selection-section">
							<div className="radio-button-wrapper">
								<Radio
									label={"Default configuration (you can always change it later)"}
									name={"default"}
									value={1}
									key={1}
									onChange={() => setDefaultConfig(true)}
									checked={defaultConfig}
								/>
							</div>
							<div className="radio-button-wrapper">
								<Radio
									label={"Custom configuration"}
									name={"custom"}
									value={1}
									key={1}
									onChange={() => setDefaultConfig(false)}
									checked={!defaultConfig}
								/>
							</div>

							<div className="button-wrapper">
								<div className={classNames("ui button retro-button",
										{ loading: isLoading, disabled: selectedSprints.length === 0 })}
									onClick={() => {
										setIsLoading(true);
										onNext(selectedSprints, defaultConfig, () => setIsLoading(false));
									}}
								>
									{defaultConfig ? "Fetch Sprint Data" : "Set Configurations"}
								</div>
							</div>
						</div>
					</>
				}
			</div>
		</div>
	);
});

export default SprintSelector;
