import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";
import { useStores } from "../../../../mobx-stores";
import LoadingIndicator from "../../../../components/loader";
import { MetricInterval, IDashboardAcumenMetricDataResponse, IDashboardSprint, AcumenTaskType, metricStringDateToUnixTime } from "@acumen/dashboard-common";
import { IPullRequestTimeToMergeData } from "../../../../mobx-stores/metric-store";
import _ from "lodash";
import colorScheme from "./chart-color-scheme";
import {
	ChartSeriesData, isSameIntervalCategory, isValidChartData, metricKeyToSprintCategory,
	packSprintByIdIfNeeded, prepareGroupedCategorySeriesDataById, sortSprintIds, sortSprintIdsTuple, SprintByIdMap
} from "./charts";
import { v4 as generateUUID } from "uuid";
import { round } from "@acumen/common";

interface IPullRequestTimeToMergeProps {
	isFullScreen?: boolean;
	setDisableFullScreen?: (disable: boolean) => void;
	filters: {
		teamId?: string;
		dataContributorIds?: string[];
		startTime?: Date;
		endTime?: Date;
		repositoryId?: string;
		interval?: MetricInterval;
		projectIds?: string[];
		boardIds?: string[];
		gitRepositoryIds?: string[];
		baseIsDefaultBranch?: boolean;
		excludeDraft?: boolean;
		customerSprints?: IDashboardSprint[];
		issueTypes?: AcumenTaskType[];
		timezone: string;
	};
}

export const PULL_REQUEST_TIME_TO_MERGE_DATA = {
	title: "Pull request review-to-merge time",
	description: `Shows both average time from when a pull request review is requested until it is finally merged vs the number of pull requests split into buckets by time.`
};

function prepareAverageSeriesData(prCountsInDateSeriesData: ChartSeriesData,
	cycleOpenToMergeSumMetric: IDashboardAcumenMetricDataResponse, timezone: string, sprintById?: SprintByIdMap) {
	const cycleOpenToMergeSumMetricValues = Object.values(cycleOpenToMergeSumMetric.values);
	const dateToPRCount: Record<string | number, number> = prCountsInDateSeriesData.reduce((acc, val) =>
		Object.assign(acc, { [val[0]]: val[1] }), {});
	let keys = Object.keys(cycleOpenToMergeSumMetricValues[0]);
	if (sprintById) {
		keys = sortSprintIds(keys, sprintById);
	}
	return keys.map(valueKey => {
		let category: string | number | undefined;
		if (sprintById) {
			category = metricKeyToSprintCategory(valueKey, sprintById);
		} else {
			category = metricStringDateToUnixTime(valueKey, timezone);
		}
		const prCountInDate = dateToPRCount[category];
		let prToHoursRatio = 0;
		if (prCountInDate && prCountInDate > 0) {
			const openToMergeSecondsSumInDate = cycleOpenToMergeSumMetricValues[0][valueKey];

			if (openToMergeSecondsSumInDate && openToMergeSecondsSumInDate > 0) {
				prToHoursRatio = round((openToMergeSecondsSumInDate / 3600) / prCountInDate);
			}
		}
		return [category, prToHoursRatio];
	});
}

function sortSeriesByName(series: Highcharts.SeriesAreasplineOptions[]): Highcharts.SeriesAreasplineOptions[] {
	return series.sort((a, b) => {
		const aKeys = a.name?.split("-").filter(x => x !== "").map(Number);
		const bKeys = b.name?.split("-").filter(x => x !== "").map(Number);
		const firstKeyA = (aKeys !== undefined ? aKeys[0] : undefined);
		const firstKeyB = (bKeys !== undefined ? bKeys[0] : undefined);
		const isFirstKeyAValid = (firstKeyA !== undefined && !isNaN(firstKeyA));
		const isFirstKeyBValid = (firstKeyB !== undefined && !isNaN(firstKeyB));
		if (!isFirstKeyAValid || !isFirstKeyBValid) {
			if (!isFirstKeyAValid && isFirstKeyBValid) {
				return 1;
			}
			if (isFirstKeyAValid && !isFirstKeyBValid) {
				return -1;
			}

			return 0;
		}
		if (firstKeyA! < firstKeyB!) {
			return 1;
		}
		if (firstKeyA! > firstKeyB!) {
			return -1;
		}
		return 0;
	});
}

function sortSeriesData(data: Array<[string, number]>): Array<[string, number]> {
	return _.sortBy(data);
}

function mergeDataAddition(data: Array<[string, number]>): Array<[string, number]> {
	const merged: { [key: string]: number } = {};
	data.forEach((dataSlot) => {
		const key = dataSlot[0];
		const value: number = dataSlot[1];
		const currentValue = merged[key] || 0;
		merged[key] = currentValue + value;
	});

	return Object.keys(merged).map((key) => [key, merged[key]]);
}

function convertSeriesToLocal(seriesData: Array<[string, number]>, timezone: string, sprintById?: SprintByIdMap): ChartSeriesData {
	let sortedSeriesData = seriesData;
	if (sprintById) {
		sortedSeriesData = sortSprintIdsTuple(seriesData, sprintById);
	}
	return sortedSeriesData.map((dataSlot) => {
		const key = dataSlot[0];
		const value: number = dataSlot[1];
		let category: string | number | undefined;
		if (sprintById) {
			category = metricKeyToSprintCategory(key, sprintById);
		} else {
			category = metricStringDateToUnixTime(key, timezone);
		}
		return [category, value];
	});
}

function PullRequestTimeToMerge(props: IPullRequestTimeToMergeProps) {
	const { metricStore } = useStores();
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const { fetchData } = metricStore.prTimeToMerge();
	const { prTimeToMergeStored } = metricStore;
	const [chartData, setChartData] = useState<IPullRequestTimeToMergeData | undefined>(prTimeToMergeStored);

	useEffect(() => {
		if (props.isFullScreen) {
			return;
		}
		if (Object.keys(props.filters).length === 0) {
			return;
		}

		let isMounted = true;
		async function fetch() {
			setIsFetching(true);
			if (props.setDisableFullScreen) {
				props.setDisableFullScreen(true);
			}
			const data = await fetchData(props.filters.teamId,
				props.filters.dataContributorIds,
				props.filters.projectIds,
				props.filters.startTime,
				props.filters.endTime,
				props.filters.interval,
				props.filters.timezone,
				props.filters.gitRepositoryIds,
				props.filters.baseIsDefaultBranch,
				props.filters.excludeDraft,
				props.filters.boardIds,
				props.filters.issueTypes
			);
			if (isMounted && data) {
				setIsFetching(false);
				setChartData(data);
			}
			if (props.setDisableFullScreen) {
				props.setDisableFullScreen(false);
			}
		}

		// tslint:disable-next-line: no-floating-promises
		fetch();
		return () => { isMounted = false; };
	}, [props.filters]);

	let prTimeSeries: Highcharts.SeriesAreasplineOptions[] | undefined;
	let dataGroupingSeries: Highcharts.SeriesSplineOptions[] | undefined;

	if (isValidChartData<IPullRequestTimeToMergeData>(chartData) &&
		isSameIntervalCategory(chartData.pullRequestMergeHoursBucket.interval, props.filters.interval)) {
		const sprintById = packSprintByIdIfNeeded(props.filters.interval, props.filters.customerSprints);
		const seriesCount = Object.keys(chartData.pullRequestMergeHoursBucket.values).length;

		const seriesData = prepareGroupedCategorySeriesDataById(chartData.pullRequestMergeHoursBucket);
		let prCountSeries = _.flatten(Object.values(seriesData));

		prTimeSeries = Object
			.keys(chartData.pullRequestMergeHoursBucket.values)
			.map(key => {
				const seriesName = (seriesCount === 1 ? "Lines of code" : key);
				const data = convertSeriesToLocal(seriesData[key], props.filters.timezone, sprintById);
				const seriesOptions: Highcharts.SeriesAreasplineOptions = {
					id: generateUUID(),
					name: seriesName,
					data,
					stack: "pr",
					stacking: "normal",
					type: "areaspline"
				};
				return seriesOptions;
			});

		prTimeSeries = sortSeriesByName(prTimeSeries);
		const colorSize = colorScheme.sizePurple.color;
		const borderSize = colorScheme.sizePurple.border;
		const colorsBySizeOrder = [colorSize.xxl, colorSize.xl, colorSize.l, colorSize.m, colorSize.s];
		const colorsByBorderOrder = [borderSize.xxl, borderSize.xl, borderSize.l, borderSize.m, borderSize.s];

		for (let i = 0; i < prTimeSeries.length; i++) {
			if (i >= colorsBySizeOrder.length) {
				break;
			}
			prTimeSeries[i].color = colorsBySizeOrder[i];
			prTimeSeries[i].lineColor = colorsByBorderOrder[i];
			prTimeSeries[i].marker = {
				color: colorsByBorderOrder[i],
				fillColor: colorsByBorderOrder[i],
				symbol: "circle"
			};
			prTimeSeries[i].lineWidth = 1;
			prTimeSeries[i].legendIndex = prTimeSeries.length - i;
		}

		prCountSeries = sortSeriesData(mergeDataAddition(prCountSeries));
		const prCountSeriesUpdated = convertSeriesToLocal(prCountSeries, props.filters.timezone, sprintById);
		const averagePRTimeSeries = prepareAverageSeriesData(prCountSeriesUpdated, chartData.pullRequestCycleOpenToMergeSum, props.filters.timezone, sprintById);
		dataGroupingSeries = [
			{
				id: generateUUID(),
				type: "spline",
				tooltip: {
					pointFormat: `Average review: <b>{point.y:,.1f} hr</b><br/>`
				},
				name: "Average review (hours)",
				yAxis: 1,
				marker: {
					symbol: "circle"
				},
				data: averagePRTimeSeries,
				visible: averagePRTimeSeries.length > 0,
				dashStyle: "Dash",
				color: "#000000",
				lineWidth: 1,
			},
		];
	} else {
		prTimeSeries = [
			{
				id: generateUUID(),
				data: [],
				visible: false,
				name: "Lines of code",
				stack: "pr",
				marker: {
					symbol: "circle"
				},
				stacking: "normal",
				type: "areaspline",
			}
		];
		dataGroupingSeries = [
			{
				id: generateUUID(),
				type: "spline",
				dashStyle: "ShortDash",
				color: colorScheme.pr,
				name: "Average review (hours)",
				data: [],
				marker: {
					symbol: "circle"
				},
				visible: false
			},
		];
	}

	const options: Highcharts.Options = {
		chart: {
			type: "area",
			zoomType: "xy"
		},
		title: undefined,
		xAxis: {
			gridLineWidth: 1,
			type: (props.filters.interval === MetricInterval.SPRINT_DATE ? "category" : "datetime"),
			uniqueNames: false
		},
		yAxis: [
			{
				min: 0,
				title: {
					text: "# of pull requests"
				}
			},
			{
				min: 0,
				title: {
					text: "Hours",
				},
				opposite: true
			}
		],
		plotOptions: {
			area: {
				stacking: "normal",
			}
		},
		tooltip: {
			split: true,
			valueSuffix: " PRs"
		},
		credits: {
			enabled: false
		},
		series: [...prTimeSeries, ...dataGroupingSeries]
	};

	return (
		<div className="description">
			<LoadingIndicator local={true} isActive={!isValidChartData(chartData) || isFetching}>
				<HighchartsReact
					highcharts={Highcharts}
					options={options}
				/>
			</LoadingIndicator>
		</div>
	);
}

export default PullRequestTimeToMerge;
