import { useState } from "react";
import {
	MAP_DATE_RANGE_TO_VALUE,
	TIME_INTERVAL_OPTIONS_TO_LABEL
} from "../../components/filters-selector/sections/time-frames-selector";
import moment from "moment";
import { useStores } from "../../mobx-stores";
import { DateFormatters, DEFAULT_DATE_RANGE_IN_DAYS } from "../../components/filters-selector/date-formatters";
import {
	DateRangeType, getDatesFromTimeSpan, MetricInterval
} from "@acumen/dashboard-common";

interface IFilterSlice {
	selectedDateRange?: DateRangeType;
	selectedInterval: MetricInterval;
	timezone: string;
}

/* eslint-disable import/no-anonymous-default-export */
export default ({
		selectedDateRange,
		selectedInterval,
		timezone
	}: IFilterSlice) => {
	const { authStore } = useStores();
	const [endTime, setEndTime] = useState<Date | undefined>(moment().toDate());
	const [startTime, setStartTime] = useState<Date | undefined>(moment(endTime).subtract((DEFAULT_DATE_RANGE_IN_DAYS), "days").startOf("day").toDate());
	const [previousStartTime, setPreviousStartTime] = useState<Date>(startTime!);
	const [previousEndTime, setPreviousEndTime] = useState<Date>(endTime!);
	const isRangeSelector = true;
	const formatters = new DateFormatters(isRangeSelector, authStore.authUser.locale);

	const getStartAndEndDates = () => {
		let rangeStartTime: Date | undefined;
		let rangeEndTime: Date | undefined;

		if (!selectedDateRange) {
			return {
				rangeStartTime: startTime,
				rangeEndTime: endTime,
				isCustomDateRange: true
			};
		}

		const span = getDatesFromTimeSpan(selectedDateRange, timezone, selectedInterval);
		rangeStartTime = span.startTime;
		rangeEndTime = span.endTime;

		return {
			rangeStartTime,
			rangeEndTime,
			isCustomDateRange: false
		};
	};

	const getDateRangeDisplayName = () => {
		if (!selectedDateRange) {
			return "";
		}
		return MAP_DATE_RANGE_TO_VALUE[selectedDateRange];
	};

	const getIntervalDisplayName = () => {
		if (!selectedInterval) {
			return "";
		}
		return TIME_INTERVAL_OPTIONS_TO_LABEL[selectedInterval];
	};

	const getFilteredValueLabel = () => {
		const selectedCustomDateRange = getStartAndEndDates();

		if (!selectedDateRange &&
			!selectedCustomDateRange.rangeStartTime && !selectedCustomDateRange.rangeEndTime) {
			return "";
		}
		const customDateValue = formatters.dateRangeLabelFormat(selectedCustomDateRange.rangeStartTime, selectedCustomDateRange.rangeEndTime);
		const presetDateValue = getDateRangeDisplayName();

		let dateRangeValue = presetDateValue;
		if (selectedCustomDateRange.isCustomDateRange) {
			dateRangeValue = customDateValue;
		}

		return dateRangeValue ? dateRangeValue : "";
	};

	const filtersDropdownDisplayName = [getFilteredValueLabel(), getIntervalDisplayName()].filter(name => name.length > 0).join(" - ");
	const dateRangeDisplayName = getFilteredValueLabel() || "";

	const setEndDate = (t: Date | undefined, previousStart?: Date) => {
		if (t && startTime) {
			setPreviousStartTime(previousStart ?? startTime);
			setPreviousEndTime(t);
		}
		setEndTime(t);
	};

	return {
		getFilteredValueLabel,
		isRangeSelector,
		setStartTime,
		setEndDate,
		previousStartTime,
		previousEndTime,
		locale: authStore.authUser.locale,
		getStartAndEndDates,
		filtersDropdownDisplayName,
		dateRangeDisplayName
	};
};
