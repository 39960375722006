import React from "react";
import classNames from "classnames";
import "./team-metric-badge-filter-item.scss";

export interface ITeamMetricBadgeFilterItemProps {
	name: string;
	value: number;
	isSelected: boolean;
	onClick: () => void;
}

export const TeamMetricBadgeFilterItem = (props: ITeamMetricBadgeFilterItemProps) => {
	const { name, value, isSelected, onClick } = props;
	return (
		<div className={classNames("team-metric-badge-filter-item", { selected: isSelected })} onClick={(e) => {
			e.preventDefault();
			onClick();
		 }}>{name}
			<div className="team-metric-badge-filter-item-figure">{value}</div>
		</div>
	);
};
