import React from "react";
import {
	useState,
	ChangeEvent,
	FormEvent,
	useCallback,
	useEffect,
	useRef,
} from "react";

import classnames from "classnames";
import { useOnClickOutside } from "usehooks-ts";
import { IPlanningPokerDeck } from "@acumen/dashboard-common";

interface ICardItem {
	cardValue: string;
	isSelected: boolean;
}

const CardItem: React.FC<ICardItem> = ({ cardValue }) => {
	return (
		<span
			className={classnames(
				"border border-2 border-[#8791A2] w-10 h-14 text-base font-semibold flex justify-center items-center rounded transition delay-50 duration-200 hover:text-white hover:bg-[#399CFF] transition duration-300 delay-150 hover:delay-300 cursor-pointer"
			)}
		>
			{cardValue}
		</span>
	);
};

export interface CreateCustomDeckModalProps {
	onClose: () => void;
	createCustomDeck: (deckName: string, cardsDisplayValues: string[]) => Promise<IPlanningPokerDeck | null>;
}

export function CreateCustomDeckModal({ onClose, createCustomDeck }: CreateCustomDeckModalProps) {
	const [deckName, setDeckName] = useState<string | undefined>(undefined);
	const [triedToSave, setTriedToSave] = useState<boolean>(false);
	const [deckValueRaw, setDeckValueRaw] = useState<string | undefined>(
		undefined
	);
	const [deckCardValues, setDeckCardValues] = useState<string[]>([]);
	const ref = useRef(null);

	useOnClickOutside(ref, () => onClose());

	const onEscape = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === "Escape" && onClose) {
				onClose();
			}
		},
		[onClose]
	);

	useEffect(() => {
		if (!deckValueRaw) {
			setDeckCardValues([]);
			return;
		}
		setDeckCardValues(
			deckValueRaw
				.replace(/\s/g, "")
				.split(",")
				.filter((x) => x.length > 0)
				.map((x) => x.substring(0, 3))
		);
	}, [deckValueRaw]);
	useEffect(() => {
		document.addEventListener("keydown", onEscape, false);

		return () => {
			document.removeEventListener("keydown", onEscape, false);
		};
	}, [onEscape]);

	return (
		<div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 backdrop-blur-sm backdrop-brightness-50 bg-black/30">
			<div className="relative h-full w-full max-w-2xl p-4 md:h-auto">
				<div
					className="relative rounded-lg bg-[#FFFEFE]/95 w-3/4 h-5/6 border border-[#CED5DF] rounded-lg shadow"
					ref={ref}
				>
					<button
						type="button"
						className="absolute top-3 right-2.5 ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
						onClick={() => onClose()}
					>
						<svg
							aria-hidden="true"
							className="h-5 w-5"
							fill="#1F1440"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
								clipRule="evenodd"
							/>
						</svg>
					</button>
					<div className="py-6 px-6 lg:px-8">
						<h3 className="mb-2 text-2xl font-bold tracking-wider text-[#399CFF] capitalize">
							Create Custom Deck
						</h3>
						<div className="space-y-4">
							<div>
								<label
									htmlFor="title"
									className="block text-base text-[#8791A2]"
								>
									Deck Name
									<span className="text-xs text-[#8791A2] ml-1">
										(required)
									</span>
								</label>
								<input
									type="text"
									name="deckName"
									id="deckName"
									className={classnames(
										"block mt-1 w-full px-2 py-1 text-sm font-medium text-gray-700 placeholder-gray-400 bg-white shadow-sm border border-gray-300 rounded-md focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40",
										{
											"border-red-500":
												triedToSave &&
												(!deckName || deckName.trim().length === 0),
										}
									)}
									placeholder="Your very own estimation deck"
									required={true}
									onChange={(event: ChangeEvent<HTMLInputElement>) => {
										setTriedToSave(false);
										setDeckName(event.target.value);
									}}
								/>
							</div>
							<div>
								<label
									htmlFor="description"
									className="block text-base text-[#8791A2]"
								>
									Deck Values
								</label>
								<input
									name="deckValueRaw"
									id="deckValueRaw"
									className={classnames(
										"block mt-1 w-full px-2 py-1 leading-5 text-sm font-medium text-gray-700 placeholder-gray-400 bg-white shadow-sm border border-gray-300 rounded-md focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40",
										{
											"border-red-500":
												triedToSave && deckCardValues.length === 0,
										}
									)}
									placeholder="0, 1, 2, 3, 5, 8, 13, 21"
									required={false}
									onChange={(event: ChangeEvent<HTMLInputElement>) => {
										setTriedToSave(false);
										setDeckValueRaw(event.target.value);
									}}
								/>
								<label className="block text-xs text-[#8791A2] mt-2">
									Add up to 3 characters, separated by commas and ordered from
									smallest to largest.
								</label>
							</div>
							{deckCardValues.length > 0 && (
								<div>
									<label className="block text-base font-semibold text-[#8791A2]">
										Deck Preview
									</label>
									<label className="block text-xs text-[#8791A2]">
										This is how your custom deck will look like
									</label>
								</div>
							)}
							{deckCardValues.length === 0 && (
								<div>
									<label className="block text-base font-semibold text-[#8791A2]">
										Deck Preview
									</label>
									<label className="block text-xs text-[#8791A2]">
										Here you can see how your custom deck will look like
									</label>
								</div>
							)}
						</div>

						{deckCardValues.length > 0 && (
							<div className="mt-2 flex flex-wrap flex-row justify-center">
								{deckCardValues.map((card, i) => (
									<div key={`card-${i}`} className="m-2">
										<CardItem cardValue={card} isSelected={false} />
									</div>
								))}
							</div>
						)}
						{deckCardValues.length === 0 && (
							<div className="mt-2 flex flex-wrap flex-row  justify-center">
								{Array.from(Array(6).keys())
									.fill(0)
									.map((i) => (
										<div key={`card-${i}`} className="m-2">
											<CardItem cardValue={"?"} isSelected={false} />
										</div>
									))}
							</div>
						)}
						<div className="mt-8 flex flex-row justify-center space-x-4">
							<button
								type="button"
								className="w-36 py-3 border border-[#399CFF] text-xl rounded font-normal text-[#399CFF] hover:bg-gray-200 transition duration-300 ease-in-out"
								onClick={async (event: FormEvent) => {
									event.preventDefault();
									setTriedToSave(false);
									onClose();
								}}
							>
								Cancel
							</button>
							<button
								type="button"
								className="w-36 py-3 bg-[#399CFF] border border-[#399CFF] text-xl rounded font-normal text-white hover:border-[#338CE5] hover:bg-[#338CE5] transition duration-300 ease-in-out"
								onClick={async (event: FormEvent) => {
									setTriedToSave(true);
									if (!deckName || deckName.trim().length === 0) {
										return;
									}
									if (deckCardValues.length === 0) {
										return;
									}
									event.preventDefault();
									await createCustomDeck(deckName, deckCardValues);
									onClose();
								}}
							>
								Save Deck
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
