import { useEffect, useState } from "react";

export const useLeftPosition = (props: {
	elementRef: React.RefObject<HTMLElement | null>;
	targetRef: React.RefObject<HTMLElement | null>;
	preferredMinimumMargin: number;
	align?: "left" | "center" | "right";
}) => {
	const { elementRef, targetRef, preferredMinimumMargin, align = "center" } = props;
	const [left, setLeft] = useState<number | undefined>(undefined);

	useEffect(() => {
		if (!elementRef.current || !targetRef.current) {
			return undefined;
		}

		const element = elementRef.current!;
		const target = targetRef.current!;
		const targetOffsetLeft = target.getBoundingClientRect().left + window.scrollX;

		if (align === "left") {
			setLeft(targetOffsetLeft);
			return;
		} else if (align === "right") {
			setLeft((targetOffsetLeft + target.offsetWidth) - element.offsetWidth);
			return;
		}

		const offsetLeft = (targetOffsetLeft + (target.offsetWidth / 2)) - (element.offsetWidth / 2);
		const offsetRight = offsetLeft + element.offsetWidth;
		const spaceLeftBefore = offsetLeft - window.scrollX;
		const spaceLeftAfter = (window.scrollX + window.innerWidth) - offsetRight;

		if (spaceLeftBefore < 0 && spaceLeftAfter < 0) {
			// NOTE: If the screen is too small, adjust left & right positions evenly
			const average = (Math.abs(spaceLeftBefore) + Math.abs(spaceLeftAfter)) / 2;
			setLeft(offsetLeft - (average / 2));

		} else if (spaceLeftBefore < 0) {
			// NOTE: Make sure part of dropdown does not cut off on the left side
			setLeft(preferredMinimumMargin);

		} else if (spaceLeftAfter < 0 && spaceLeftBefore > 0) {
			// NOTE: Try to not cut off part of dropdown on the right if there is available space on the left
			setLeft(offsetLeft - Math.min(
				spaceLeftBefore + preferredMinimumMargin,
				Math.abs(spaceLeftAfter) + preferredMinimumMargin
			));

		} else {
			// NOTE: In all the other cases, place the dropdown in the center relative to its' target
			setLeft(offsetLeft);
		}
	}, [align]);

	return left;
};
