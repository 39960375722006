import * as React from "react";
const NextIssue = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 11 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M.97 16.47a.75.75 0 1 0 1.06 1.06L.97 16.47ZM9.5 9l.53.53a.75.75 0 0 0 0-1.06L9.5 9ZM2.03.47A.75.75 0 0 0 .97 1.53L2.03.47Zm0 17.06 8-8-1.06-1.06-8 8 1.06 1.06Zm8-9.06-8-8L.97 1.53l8 8 1.06-1.06Z"
			fill="#fff"
		/>
	</svg>
);
export default NextIssue;
