import _ from "lodash";
import React, { useMemo } from "react";
import { TeamViewModel } from "../../types";
import { PrCycleTimeForTeam } from "../pr-cycle-time-for-team/pr-cycle-time-for-team";
import "./pr-cycle-time-for-selected-teams.scss";

export interface PrCycleTimeForSelectedTeamsProps {
	teams: TeamViewModel[];
}

export const PrCycleTimeForSelectedTeams = (props: PrCycleTimeForSelectedTeamsProps) => {
	const { teams } = props;
	const longestCycleTimeInSeconds = useMemo(
		() => Math.max(
			...teams
				.map(team => team.cycleTimeBreakdown
					.reduce((sum, item) => sum + item.totalSeconds, 0))
		),
		[teams]
	);
	const sortedTeams = useMemo(() => {
		return _.orderBy(teams, t => _.sumBy(t.cycleTimeBreakdown, ctb => ctb.totalSeconds), "desc");
	}, [teams]);

	return (
		<div className="pr-cycle-time-for-selected-teams">
			<div className="pr-cycle-time-for-selected-teams-header">
				<div className="pr-cycle-time-for-selected-teams-header-title">
					PR cycle time for selected teams
				</div>
			</div>

			<div className="pr-cycle-time-for-selected-teams-content">
				{sortedTeams.map(team => (
					<PrCycleTimeForTeam
						key={team.info.id}
						team={team}
						longestCycleTimeInSeconds={longestCycleTimeInSeconds}
					/>
				))}
			</div>
		</div>
	);
};
