import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import classNames from "classnames";
import { Portal, Segment, Menu, Table, Popup } from "semantic-ui-react";
import {
	IDashboardTaskDetailed, ITaskHighlight, HighlightType, IDashboardEvent,
	IDashboardPullRequest, OriginatingEntityType, IDashboardTask, IHighlightInsight, InsightStatus, HighlightEntityType
} from "@acumen/dashboard-common";
import { dateFormatByLocale, DateFormat } from "@acumen/common";
import { useStores } from "../../mobx-stores";
import HighlightsFormatters from "./highlights-formatters";
import Linkify from "../../components/formatters/linkify";
import { ExternalImage } from "../../components/external-image";
import { Avatar } from "../../components/avatar";
import TriggerOnClickOutside from "../../components/ux-effects/trigger-on-click-outside";
import TaskStatus from "../../components/formatters/task-status-icon";
import { Formatters } from "./formatters";
import "./task-details-display-panel.scss";
import { GA_EVENT_ACTION, GA_EVENT_CATEGORY, clickEvent } from "../../analytics-events";
import { PullRequestStatus } from "../../components/formatters";
import { eventsFormatting } from "../../components/formatters/string";
import { TIMELINE_EVENTS_FILTER } from "../../components/formatters/events";
import NothingFoundIcon from "../svg-assets/no-content-icons/empty_state_icon_1.svg";
import { Loader } from "semantic-ui-react";

const createAttentionPoint = (dateTimeFormat: string, h: ITaskHighlight, idx: number) => {
	let title;
	switch (h.type) {
		case HighlightType.Event: {
			title = "EVENT";
			break;
		}
		case HighlightType.Insight: {
			title = "INSIGHT";
			break;
		}
		case HighlightType.PropertyCount:
		case HighlightType.Property: {
			title = "DATA POINT";
			break;
		}
	}

	return (
		<div className="event" key={`highlightEntityId-${idx}`}>
			<div className="content">
				<div className="summary">
					{title}
					{
						h.dateMs && (
							<div className="date">
								{moment(h.dateMs).format(dateTimeFormat)}
							</div>
						)
					}
					{(h.type === HighlightType.Insight && (h as IHighlightInsight<HighlightEntityType.Task>).insightStatus === InsightStatus.Canceled &&
					<span className="ui label insight-resolved">
						RESOLVED
					</span>)}
				</div>
				<div className="extra text">
					<Linkify linkMap={h.linkifyMatchToUrl} classNamePrefix="link">
						{HighlightsFormatters.highlightText(h)}
					</Linkify>
					<div className="ui divider" />
				</div>
			</div>
		</div>
	);
};

// tslint:disable-next-line: variable-name
const AttentionPointsTab = observer((props: { taskHighlights?: ITaskHighlight[] }) => {
	const {
		authStore
	} = useStores();
	const DATE_TIME_FORMAT = dateFormatByLocale(DateFormat.LongWithTime, authStore.authUser.locale);
	const filteredHighlights = props.taskHighlights || [];

	return (
		<div className="ui bottom attached active tab basic segment task-details-tab">
			{!props.taskHighlights && tabContentLoading()}
			{(props.taskHighlights && filteredHighlights.length === 0) && (
				noTabContent("No Attention Points")
			)}
			{filteredHighlights.length > 0 && (
				<div className="ui small feed scroller">
					{filteredHighlights.map((h, i) => createAttentionPoint(DATE_TIME_FORMAT, h, i))}
				</div>
			)}
		</div>
	);
});

// tslint:disable-next-line: variable-name
const RelatedPRsTab = (props: { taskRelatedPrs: IDashboardPullRequest[] | undefined }) => {
	const { taskRelatedPrs } = props;
	return (
		<div className="cards-container">
			{!taskRelatedPrs && tabContentLoading()}
			{(taskRelatedPrs && taskRelatedPrs.length > 0) ?
				taskRelatedPrs.map((pr: IDashboardPullRequest, i: number) => (
					<div className="ui grid raised info card" key={"rt" + i}>
						<div className="content">
							<p className="subheader">
								{pr.title}
							</p>
							<div className="level">
								<span className="left floated">
									<PullRequestStatus status={pr.status} />
								</span>
								<span className="right floated">
									<a href={pr.publicHtmlUrl ?? undefined} target="_blank" rel="noopener noreferrer" className={classNames("acu-link")}>
										{pr.repositoryName ?? ""}#{pr.publicIdentifier}
									</a>
									<Avatar dataContributor={pr.creator} />
								</span>
							</div>
						</div>
					</div>
				))
				:
				noTabContent("No Related Pull Requests")
			}
		</div >
	);
};

// tslint:disable-next-line: variable-name
const TimelineTab = observer((props: { task: IDashboardTask }) => {
	const [events, setEvents] = useState<IDashboardEvent[] | undefined>(undefined);
	const {
		tasksStore: {
			fetchTaskEvents
		},
		authStore
	} = useStores();

	const TIMELINE_EVENT_DATE_TIME_FORMAT = dateFormatByLocale(DateFormat.LongWithTime, authStore.authUser.locale);

	useEffect(() => {
		async function getTaskEvents() {
			return fetchTaskEvents(props.task).then(res => res);
		}
		setEvents(undefined);
		// tslint:disable-next-line: no-floating-promises
		getTaskEvents().then(res => {
			setEvents(res);
		});
	}, [props.task]);

	const eventCard = (event: IDashboardEvent, i: number) => {
		const formattingFunction = eventsFormatting[event.eventType];
		const relatedEventName: Record<OriginatingEntityType, (str: string) => string> = {
			[OriginatingEntityType.GitHubPullRequest]: (str: string) => `PR #${str}`,
			[OriginatingEntityType.GitHubCommit]: (str: string) => `${str.substring(0, 6)}`,
			[OriginatingEntityType.JiraIssue]: (str: string) => str
		};
		return (
			<div className="timeline-event-card" key={i}>
				<div className="float left avatar-container">
					<Avatar
						dataContributor={event.dataContributor}
						className={"big"}
					/>
				</div>
				<div className="float right content full-width" >
					<div className="flex-column">
						<span className="small-gray-text">
							{moment(event.eventTimeMs).format(TIMELINE_EVENT_DATE_TIME_FORMAT)}
						</span>
					</div>
					{formattingFunction && formattingFunction(event)}
				</div>
				{(props.task.entityId !== event.entity?.entityId) && event.entity && event.entity?.publicIdentifier &&
					<span className="ui gray label light-gray related-label">
						<a href={event.entity?.publicHtmlUrl ?? ""} target="_blank" rel="noopener noreferrer" className="link">
							{relatedEventName[event.entityType]((event.entity?.publicIdentifier).toString() ?? "")}
						</a>
					</span>}
			</div>
		);
	};
	return (
		<div className="timeline-events-tab acu-scroll">
			{!events && tabContentLoading()}
			{(events && events.length === 0) && noTabContent("No Events")}
			{(events && events.length > 0) &&
				events
					.filter((e: IDashboardEvent) => e &&
						TIMELINE_EVENTS_FILTER.find(displayedEventType => displayedEventType === e.eventType))
					.map(eventCard)}
		</div>
	);
});

// tslint:disable-next-line: variable-name
const TaskWorkTab = observer(({
	task
}: {
	task: IDashboardTask
}) => {
	const {
		tasksStore: {
			workIntervals: {
				loading,
				data: workIntervalsData
			},
			fetchWorkIntervals,
			resetWorkIntervals,
		}
	} = useStores();

	useEffect(() => {
		resetWorkIntervals();
		// tslint:disable-next-line: no-floating-promises
		fetchWorkIntervals(task);
	}, [`${task.entityId}-${task.entityType}`]);

	const taskWorkIntervals = workIntervalsData.workIntervalsByDataContributor;
	return (
		<div className="ui bottom attached active tab basic segment task-details-tab">
			{(loading) ? (
				tabContentLoading()
			) : (taskWorkIntervals && taskWorkIntervals.length > 0) ? (
				<div className="scroller">
					<Table celled={false} padded={false} textAlign="center" collapsing={true}>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>Assignee</Table.HeaderCell>
								<Popup
									size="small"
									basic={true}
									content="Work done on preparing the task itself, including discussions and comments"
									trigger={<Table.HeaderCell>Task Mgmt.</Table.HeaderCell>}
								/>
								<Popup
									size="small"
									basic={true}
									content="Amount of maker time spent towards coding and commits"
									trigger={<Table.HeaderCell>Coding</Table.HeaderCell>}
								/>
								<Popup
									size="small"
									basic={true}
									content="All pull request related maker time spent, including reviews and comments"
									trigger={<Table.HeaderCell>Review</Table.HeaderCell>}
								/>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{taskWorkIntervals.map(
								(w) => (
									<Table.Row key={w.dataContributor.id}>
										<Table.Cell verticalAlign={"middle"} textAlign="left">
											<div className="task-property-container">
												<Avatar
													dataContributor={w.dataContributor}
												/>
												<span className="assignee-name task-property-text">{w.dataContributor.primaryDisplayName} </span>
											</div>
										</Table.Cell>
										<Table.Cell>{Formatters.workIntervals(w.taskWorkInMs)}</Table.Cell>
										<Table.Cell>{Formatters.workIntervals(w.commitWorkInMs)}</Table.Cell>
										<Table.Cell>{Formatters.workIntervals(w.prWorkInMs)}</Table.Cell>
									</Table.Row>
								)
							)}
						</Table.Body>
					</Table>
				</div>
			) : noTabContent("No Work Was Logged")}
		</div>
	);
});

// tslint:disable-next-line: variable-name
const TaskDetails = ({ task, totalCalculatedWorkMs }: { task: IDashboardTask, totalCalculatedWorkMs?: number | null }) => {
	return (
		<div className="ui compact three column centered aligned grid">
			<div className="three column centered row vertical-spacer-x">
				<div className="column task-property-header">Type</div>
				<div className="column task-property-header">Priority</div>
				<div className="column task-property-header">Key</div>
			</div>
			<div className="three column centered row subtitle">
				<div className="column">
					<div className="task-property-container">
						<ExternalImage src={task.type.iconUrl ?? ""} className="ui micro image" alt="" />
						<span className="task-property-text">{task.type.internalType}</span>
					</div>
				</div>
				<div className="column">
					<div className="task-property-container">
						<ExternalImage src={task.priority.iconUrl ?? ""} className="ui micro image" alt="" />
						<span className="task-property-text">{(task.priority.internalPriority !== null && task.priority.internalPriority.trim().length > 0) ? task.priority.internalPriority : "N/A"}</span>
					</div>
				</div>
				<div className="column">
					{task.publicHtmlUrl
						? <a href={task.publicHtmlUrl} target="_blank" rel="noopener noreferrer" className="acu-link"> {task.publicIdentifier} </a>
						: task.publicIdentifier
					}
				</div>
			</div>
			<div className="three column centered row vertical-spacer-x">
				<div className="column task-property-header">Assignee</div>
				<div className="column task-property-header">Maker Time</div>
				<div className="column task-property-header">Status</div>
			</div>
			<div className="three column centered row subtitle">
				<div className="column">
					<div className="task-property-container">
						<Avatar
							dataContributor={task.assignee}
						/>
						<span className="assignee-name task-property-text">{task.assignee?.primaryDisplayName}</span>
					</div>
				</div>
				<div className="column full-height">
					<div className="task-property-container full-height">
						{totalCalculatedWorkMs === undefined
							? <Loader active={true} inline={true} inverted={true} size="small" />
							: Formatters.workIntervals(totalCalculatedWorkMs, false)
						}
					</div>
				</div>
				<div className="column full-height" style={{ verticalAlign: "text-top" }}>
					<div className="task-property-container" style={{ verticalAlign: "sub" }}>
						<TaskStatus taskStatus={task.status.status} internalStatus={task.status.internalStatus} hasTooltip={true} />
					</div>
				</div>
			</div>
		</div >
	);
};

const tabContentLoading = () => (
	<div className="ui placeholder" style={{ margin: "15px" }}>
		<div className="line" />
		<div className="line" />
		<div className="line" />
		<div className="line" />
		<div className="line" />
	</div>
);

const noTabContent = (text: string) => (
	<div className="ui placeholder segment noTabContent">
		<img className="status-icon" width={"68px"} height={"85px"} src={NothingFoundIcon} alt="" />
		<p>{text}</p>
	</div>
);

enum TAB_TYPES {
	AttentionPoints = "AttentionPoints",
	WorkIntervals = "WorkIntervals",
	RelatedPRs = "RelatedPRs",
	Timeline = "Timeline"
}

const isDetailedTask = (t: IDashboardTask | IDashboardTaskDetailed): t is IDashboardTaskDetailed => {
	return "isDashboardTaskDetailed" in t && t.isDashboardTaskDetailed;
};

function TaskDetailsDisplayPanel<T extends IDashboardTask | IDashboardTaskDetailed>({
	task,
	onDismiss,
	shouldShowNeedAttentionFlag = false,
	onDismissTaskHighlights,
	outsidePanelClassName,
}: {
	task: T;
	onDismiss: () => void;
	onDismissTaskHighlights?: (value: T) => void;
	shouldShowNeedAttentionFlag?: boolean;
	outsidePanelClassName?: string;
}) {
	const { tasksStore } = useStores();

	const relatedPRs = isDetailedTask(task) ? task.relatedPRs : undefined;
	const highlights = isDetailedTask(task) ? task.highlights : undefined;
	const totalCalculatedWorkMs = isDetailedTask(task) ? task.totalCalculatedWorkMs : undefined;

	const { getTasksRelatedPrs, getTasksHighlights, fetchTaskDetails } = tasksStore;
	const [selectedTab, setSelectedTab] = useState<TAB_TYPES>(TAB_TYPES.AttentionPoints);
	const [taskRelatedPrs, setTaskRelatedPrs] = useState<IDashboardPullRequest[] | undefined>(relatedPRs);
	const [taskHighlights, setTaskHighlights] = useState<ITaskHighlight[] | undefined>(highlights);
	const [taskTotalCalculatedWorkMs, setTaskTotalCalculatedWorkMs] = useState<number | null | undefined>(totalCalculatedWorkMs);

	useEffect(() => {
		if (highlights) {
			setTaskHighlights(highlights ?? []);
			return;
		}
		setTaskHighlights(undefined);
		// tslint:disable-next-line: no-floating-promises
		getTasksHighlights(task).then((results) => {
			setTaskHighlights(results ?? []);
		});
	}, [task]);

	useEffect(() => {
		if (relatedPRs) {
			setTaskRelatedPrs(relatedPRs ?? []);
			return;
		}

		setTaskRelatedPrs(undefined);
		// tslint:disable-next-line: no-floating-promises
		getTasksRelatedPrs(task).then((prsMapObject) => {
			if (!prsMapObject || !prsMapObject[task.entityId]) {
				setTaskRelatedPrs([]);
				return;
			}

			setTaskRelatedPrs(prsMapObject[task.entityId]);
		});
	}, [task]);

	useEffect(() => {
		if (totalCalculatedWorkMs !== undefined) {
			setTaskTotalCalculatedWorkMs(totalCalculatedWorkMs ?? null);
			return;
		}
		setTaskTotalCalculatedWorkMs(undefined);
		// tslint:disable-next-line: no-floating-promises
		fetchTaskDetails(task).then((taskDetails) => {
			if (taskDetails
				&& (
					task.entityId.toString() !== taskDetails.taskId
					|| task.entityType.toString() !== taskDetails.taskType
				)
			) {
				return;
			}

			if (!taskDetails || taskDetails.totalCalculatedWorkMs === null) {
				setTaskTotalCalculatedWorkMs(null);
				return;
			}

			setTaskTotalCalculatedWorkMs(taskDetails.totalCalculatedWorkMs);
		});
	}, [task]);

	return (
		<Portal open={true}>
			<TriggerOnClickOutside excludedClassName={outsidePanelClassName} onTrigger={onDismiss}>
				<Segment
					raised={true}
					style={{
						height: "100%",
						top: "0",
						right: "0",
						width: "480px",
						position: "fixed",
						padding: 0,
						margin: 0,
					}}
					className="task-details"
				>
					<div className="ui vertical stackable basic segments panel-container" >
						<div className="ui basic segment">
							<div className="details-header-container">
								<div className="details-header-title">
									Task Details
								</div>
								<i {...clickEvent(GA_EVENT_CATEGORY.TaskWork, GA_EVENT_ACTION.Close)} className="large close icon details-header-close-icon" onClick={(e) => { e.stopPropagation(); onDismiss(); }} />
							</div>
						</div>
						<div className="ui segment task-title-container">
							<div className={classNames("ui items panel-title", shouldShowNeedAttentionFlag && "with-icon")}>
								<div className="item">
									<div className="content">
										<div className="header">{task.title}</div>
										{shouldShowNeedAttentionFlag && (
											<div className="description">
												<i className="red flag icon" />
											Need Attention
											</div>
										)}
									</div>
								</div>
							</div>
							{shouldShowNeedAttentionFlag && (
								<Popup
									size="mini"
									basic={true}
									flowing={true}
									hoverable={true}
									on="click"
									hideOnScroll={true}
									position="bottom right"
									trigger={
										<i {...clickEvent(GA_EVENT_CATEGORY.TaskWork, GA_EVENT_ACTION.Delete, "NeedAttention")} className="ui icon outline ellipsis vertical task-title-settings-icon" />
									}
								>
									<div className={classNames("ui tiny header", outsidePanelClassName, "flex-row")}
										style={{ cursor: "pointer" }}
										onClick={(e) => {
											if (onDismissTaskHighlights) {
												e.stopPropagation();
												onDismissTaskHighlights(task);
												onDismiss();
											}
										}}
									>
										<i className="icon check check-icon-display" />
										<span>{"Acknowledge"}</span>
									</div>
								</Popup>
							)}
						</div>
						<div className="ui basic segment">
							<TaskDetails task={task} totalCalculatedWorkMs={taskTotalCalculatedWorkMs} />
						</div>

						<Menu className="tabs ui pointing secondary menu">
							<Menu.Item
								name="Attention points"
								{...clickEvent(GA_EVENT_CATEGORY.TaskWork, GA_EVENT_ACTION.Select, "AttentionPoints")}
								active={selectedTab === TAB_TYPES.AttentionPoints}
								onClick={() => setSelectedTab(TAB_TYPES.AttentionPoints)}
							/>
							<Menu.Item
								name="Maker Time"
								{...clickEvent(GA_EVENT_CATEGORY.TaskWork, GA_EVENT_ACTION.Select, "WorkIntervals")}
								active={selectedTab === TAB_TYPES.WorkIntervals}
								onClick={() => setSelectedTab(TAB_TYPES.WorkIntervals)}
							/>
							<Menu.Item
								name="RelatedPRs"
								{...clickEvent(GA_EVENT_CATEGORY.TaskWork, GA_EVENT_ACTION.Select, "RelatedPRs")}
								active={selectedTab === TAB_TYPES.RelatedPRs}
								onClick={() => setSelectedTab(TAB_TYPES.RelatedPRs)}
							>
								Related PRs
							</Menu.Item>
							<Menu.Item
								name="Timeline"
								{...clickEvent(GA_EVENT_CATEGORY.TaskWork, GA_EVENT_ACTION.Select, "Timeline")}
								active={selectedTab === TAB_TYPES.Timeline}
								onClick={() => setSelectedTab(TAB_TYPES.Timeline)}
							/>
						</Menu>
						{selectedTab === TAB_TYPES.AttentionPoints && <AttentionPointsTab taskHighlights={taskHighlights} />}
						{selectedTab === TAB_TYPES.WorkIntervals && <TaskWorkTab task={task} key="work-intervals-tab" />}
						{selectedTab === TAB_TYPES.RelatedPRs && <RelatedPRsTab taskRelatedPrs={taskRelatedPrs} />}
						{selectedTab === TAB_TYPES.Timeline && <TimelineTab task={task} />}
					</div>
				</Segment>
			</TriggerOnClickOutside>
		</Portal>
	);
}

export default observer(TaskDetailsDisplayPanel);
