import { observer } from "mobx-react";
import { useStores } from "../../../mobx-stores";
import React from "react";
import LoadingIndicator from "../../../components/loader";
import { IDashboardRetroHighlight, RetroHighlightChangeDirection, RetroHighlightValueType } from "@acumen/dashboard-common";
import NothingFoundIcon from "../../../components/svg-assets/no-content-icons/empty_state_icon_1.svg";
import classNames from "classnames";
import moment from "moment";
import { inflect, round } from "@acumen/common";
import { Popup } from "semantic-ui-react";
import { getSprintLabel } from ".";

// tslint:disable-next-line: variable-name
const RetroHighlights = observer((props: {
	pageError: boolean;
}) => {
	const {
		sprintRetroStore: {
			sprintRetroObstaclesData,
			isLoadingSprintRetroObstaclesData
		}
	} = useStores();

	const highlights = sprintRetroObstaclesData?.highlights;
	const previousSprint = sprintRetroObstaclesData?.previousSprint;

	const getHighlightCard = (highlight: IDashboardRetroHighlight, i: number) => {
		return (
			<div key={i}>
				<Popup
					hoverable={true}
					wide={true}
					disabled={highlight.description === null}
					position="right center"
					content={highlight.description ?? ""}
					trigger={
						<div className="retro-highlights">
							<p>
								<span className="highlight-value">{formatValue(highlight.value, highlight.type)}</span>
								{
									highlight.changePercentage !== null
										? (
											<span
												className={classNames("changePercentage", changeColorClass(highlight.changePercentage, highlight.desiredChangeDirection))}
												style={{ fontSize: "20px" }}
											>{highlight.changePercentage > 0 ? `+${highlight.changePercentage}` : highlight.changePercentage}%</span>
										) : null
								}
							</p>
							<p className="highlight-title flex-row">{highlight.title}</p>
						</div>
					}
				/>
				{highlights && i < (highlights?.length - 1) &&
					<div className="ui divider" />
				}
				{highlights
					&& i === (highlights?.length - 1)
					&& previousSprint !== undefined
					&& <p className="previous-sprint">{`Compared with ${getSprintLabel(previousSprint)}`}</p>
				}
			</div>
		);
	};

	return (
		<LoadingIndicator local={true} isActive={(isLoadingSprintRetroObstaclesData || highlights === undefined || props.pageError)}>
			{highlights && highlights.length > 0 ?
				highlights.map(getHighlightCard) : !props.pageError &&
				<div className="noContent flex-column">
					<img className="status-icon" src={NothingFoundIcon} alt="" />
					<p>No Highlights</p>
				</div>
			}
		</LoadingIndicator>
	);
});
export default RetroHighlights;

const formatValue = (value: number, type: RetroHighlightValueType): string => {
	switch (type) {
		case RetroHighlightValueType.TimeSeconds:
			// Make sure we round up the seconds to hours conversation so it will not explode
			return inflect(round(moment.duration(value, "seconds").asHours(), 1), "hour", "hours");
		case RetroHighlightValueType.Percentage:
			return `${value}%`;
		case RetroHighlightValueType.StoryPoints:
			return `${value}sp`;
		case RetroHighlightValueType.Task:
			return inflect(value, "Issue", "Issues");
		case RetroHighlightValueType.Bug:
			return inflect(value, "Bug", "Bugs");
	}
};

const changeColorClass = (changePercentage: number, desiredChangeDirection: RetroHighlightChangeDirection) => {
	if (changePercentage >= 0) {
		return desiredChangeDirection === RetroHighlightChangeDirection.Positive ? "primary" : "warning";
	}

	return desiredChangeDirection === RetroHighlightChangeDirection.Negative ? "primary" : "warning";
};
