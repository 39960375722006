import React, { useState } from "react";
import { useStores } from "../../../mobx-stores";
import { IChartScope } from "./chart-scope";
import { OrgMetricsCard } from "../components/org-metrics-card/org-metrics-card";
import { AcumenTaskStatusGroup } from "@acumen/database-types";
import _ from "lodash";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import LoadingIndicator from "../../../components/loader/loader";
import useDeepCompareEffect from "use-deep-compare-effect";
import { StackedChartData } from "v2/helpers/charts";

interface IProps {
	scope: IChartScope;
	chartHeight?: {
		mainChartHeight: number
	};
}

function JiraStatusCycleTimeChart(props: IProps) {
	const { statusMappingStore, metricOrgStore } = useStores();
	const [chartOptions, setChartOptions] = useState<Highcharts.Options | undefined>(undefined);
	const tooltip = "Shows the amount of time issues spent in each status by task type.";

	async function getStatusNames() {
		const statuses = await statusMappingStore.fetchData();
		return _.uniq(statuses?.filter(status => status.acumenTaskStatusGroup === AcumenTaskStatusGroup.InProgress).map(status => status.jiraStatusName));
	}

	async function fetchData() {
		const scope = props.scope;
		const statusNames = await getStatusNames();
		const data = await metricOrgStore.issueStatusTimeSpentByTypeCycleEnd(scope.startTime, scope.endTime, scope.timezone, scope.interval,
			statusNames, undefined, scope.projectIds, scope.dataContributorIds);

		return data;
	}

	function shouldFetchData(): boolean {
		return (!metricOrgStore.isLoadingIssueStatusTimeSpentByTypeCycleEndMetric);
	}

	function buildChartOptions(data: StackedChartData): Highcharts.Options {

		const categorySeries: Highcharts.SeriesColumnOptions[] = data.series.map(x => {
			const seriesOptions: Highcharts.SeriesColumnOptions = {
				type: "column",
				name: x.name,
				data: x.data,
				visible: _.sum(x.data) > 0,
				showInLegend: _.sum(x.data) > 0
			};
			return seriesOptions;
		});

		const options: Highcharts.Options = {
			chart: {
				plotBackgroundColor: undefined,
				plotBorderWidth: undefined,
				plotShadow: false,
				height: props.chartHeight?.mainChartHeight
			},
			title: undefined,
			xAxis: {
				showEmpty: false,
				type: "category",
				categories: data.categories
			},
			yAxis: [
				{
					min: 0,
					title: {
						text: "Hours"
					},
					stackLabels: {
						enabled: true
					}
				}
			],
			credits: {
				enabled: false
			},
			series: categorySeries,
			legend: {
				align: "left",
			},
			plotOptions: {
				column: {
					stacking: "normal"
				}
			},
		};

		return options;
	}

	useDeepCompareEffect(() => {
		let isMounted = true;
		async function fetchChartData() {
			const data = await fetchData();
			if (isMounted && data) {
				setChartOptions(buildChartOptions(data));
			}
		}

		if (shouldFetchData()) {
			setChartOptions(undefined);
			// tslint:disable-next-line: no-floating-promises
			fetchChartData().then(() => {
				isMounted = false;
			});
		}
	}, [props]);

	return (
		<OrgMetricsCard title="Jira status cycle time" tooltip={tooltip}>
			<div className="org-metrics-jira-status-cycle-time-container">
				<LoadingIndicator local={true} isActive={chartOptions === undefined}>
					{chartOptions && (
						<HighchartsReact
							highcharts={Highcharts}
							options={chartOptions}
						/>
					)}
				</LoadingIndicator>
			</div>
		</OrgMetricsCard>
	);
}

export default JiraStatusCycleTimeChart;
