import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";
import { useStores } from "../../../../mobx-stores";
import LoadingIndicator from "../../../../components/loader";
import { MetricInterval, AcumenTaskType, IDashboardSprint } from "@acumen/dashboard-common";
import { IIssuesDoneByTypeData } from "../../../../mobx-stores/metric-store";
import {
	CategoryChartSeriesData, isSameIntervalCategory, isValidChartData, packSprintByIdIfNeeded,
	prepareGroupedCategorySeriesDataByDate, prepareGroupedCategorySeriesDataBySprint
} from "./charts";
import { v4 as generateUUID } from "uuid";
import _ from "lodash";
import { seriesByTaskName } from "../../../../components/dashboard-task/task-type-series-prop";

interface IIssuesDoneByTypeProps {
	isFullScreen?: boolean;
	setDisableFullScreen?: (disable: boolean) => void;
	filters: {
		teamId?: string;
		dataContributorIds?: string[];
		startTime?: Date;
		endTime?: Date;
		interval?: MetricInterval;
		projectIds?: string[];
		boardIds?: string[];
		customerSprints?: IDashboardSprint[];
		issueTypes?: AcumenTaskType[];
		timezone: string;
	};
}

export const ISSUES_DONE_BY_TYPE_DATA = {
	title: "Work done by type",
	description: `Issues that have completed their cycle (done) in each period split by issue type.`
};

function IssuesDoneByType(props: IIssuesDoneByTypeProps) {
	const { metricStore } = useStores();
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const { fetchData } = metricStore.issuesDoneByTypeData();
	const { issuesDoneByTypeDataStored } = metricStore;
	const [chartData, setChartData] = useState<IIssuesDoneByTypeData | undefined>(issuesDoneByTypeDataStored);

	useEffect(() => {
		if (props.isFullScreen) {
			return;
		}
		if (Object.keys(props.filters).length === 0) {
			return;
		}

		let isMounted = true;
		async function fetch() {
			setIsFetching(true);
			if (props.setDisableFullScreen) {
				props.setDisableFullScreen(true);
			}
			const data = await fetchData(props.filters.teamId,
				props.filters.dataContributorIds,
				props.filters.projectIds,
				props.filters.startTime,
				props.filters.endTime,
				props.filters.interval,
				props.filters.timezone,
				props.filters.boardIds,
				props.filters.issueTypes);
			if (isMounted && data) {
				setIsFetching(false);
				setChartData(data);
			}
			if (props.setDisableFullScreen) {
				props.setDisableFullScreen(false);
			}
		}

		// tslint:disable-next-line: no-floating-promises
		fetch();
		return () => { isMounted = false; };
	}, [props.filters]);

	let series: Highcharts.SeriesColumnOptions[] | undefined;

	if (isValidChartData<IIssuesDoneByTypeData>(chartData) &&
		isSameIntervalCategory(chartData.issueCountByType.interval, props.filters.interval)) {
		let seriesData: CategoryChartSeriesData;
		const sprintById = packSprintByIdIfNeeded(props.filters.interval, props.filters.customerSprints);

		if (sprintById) {
			seriesData = prepareGroupedCategorySeriesDataBySprint(chartData.issueCountByType, sprintById);
		} else {
			seriesData = prepareGroupedCategorySeriesDataByDate(chartData.issueCountByType, props.filters.timezone);
		}

		series = Object
			.keys(chartData.issueCountByType.values)
			.map(key => {
				const displayProperties = seriesByTaskName(key);
				const seriesOptions: Highcharts.SeriesColumnOptions = {
					id: generateUUID(),
					name: displayProperties.seriesName,
					index: displayProperties.sortOrder,
					legendIndex: displayProperties.sortOrder,
					color: displayProperties.seriesColorHex,
					data: seriesData[key], visible: (seriesData[key].length > 0 && _.findIndex(seriesData[key], i => i[1] > 0) >= 0),
					type: "column",
					stack: "issue-type"
				};
				return seriesOptions;
			});
	} else {
		series = [
			{
				id: generateUUID(),
				data: [],
				name: "Task Type",
				type: "column",
				visible: false,
				stack: "issue-type"
			}
		];
	}

	const options: Highcharts.Options = {
		chart: {
			type: "column",
			zoomType: "xy"
		},
		title: undefined,
		tooltip: {
			headerFormat: '<span style="color:{point.color}">\u25CF</span><b> {series.name}</b>:<br>',
			pointFormat: `{point.y}<br/>`
		},
		xAxis: {
			gridLineWidth: 1,
			type: (props.filters.interval === MetricInterval.SPRINT_DATE ? "category" : "datetime"),
			uniqueNames: false
		},
		yAxis: [
			{
				min: 0,
				title: {
					text: "Amount"
				}
			}
		],
		plotOptions: {
			column: {
				stacking: "normal",
				dataLabels: {
					enabled: false
				}
			}
		},
		credits: {
			enabled: false
		},
		series
	};

	return (
		<div className="description">
			<LoadingIndicator local={true} isActive={!isValidChartData(chartData) || isFetching}>
				<HighchartsReact
					highcharts={Highcharts}
					options={options}
				/>
			</LoadingIndicator>
		</div>
	);
}

export default IssuesDoneByType;
