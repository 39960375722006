import React from "react";
import { IDashboardTeamReportMetric } from "@acumen/dashboard-common";
import { TrendIndicator } from "../trend-indicator/trend-indicator";
import { TrendTotal } from "../trend-total/trend-total";
import "./pr-cycle-time-summary.scss";

export interface PrCycleTimeSummaryProps {
	totalReportMetric: IDashboardTeamReportMetric;
	unit: string;
}

export const PrCycleTimeSummary = (props: PrCycleTimeSummaryProps) => {
	const { totalReportMetric, unit } = props;
	const { currentPeriodValue, changeFromPreviousPeriod, reaction } = totalReportMetric;

	return (
		<div className="pr-cycle-time-summary">
			<div className="pr-cycle-time-summary-title">
				PR cycle time
			</div>

			<div className="pr-cycle-time-summary-data">
				<TrendTotal
					size="large"
					currentPeriodValue={currentPeriodValue}
					unit={unit}
				/>

				<TrendIndicator
					changeFromPreviousPeriod={changeFromPreviousPeriod}
					reaction={reaction}
				/>
			</div>
		</div>
	);
};
