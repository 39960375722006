import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";
import { useStores } from "../../../../mobx-stores";
import LoadingIndicator from "../../../../components/loader";
import { AcumenTaskType, IDashboardSprint, MetricInterval } from "@acumen/dashboard-common";
import { IAbandonedWorkData } from "../../../../mobx-stores/metric-store";
import {
	isValidChartData, prepareCategorySeriesDataByDate,
	ChartSeriesData, prepareCategorySeriesDataBySprint,
	packSprintByIdIfNeeded, isSameIntervalCategory
} from "./charts";
import { v4 as generateUUID } from "uuid";

interface IAbandonedWorkProps {
	isFullScreen?: boolean;
	setDisableFullScreen?: (disable: boolean) => void;
	filters: {
		teamId?: string;
		dataContributorIds?: string[];
		startTime?: Date;
		endTime?: Date;
		interval?: MetricInterval;
		projectIds?: string[];
		boardIds?: string[];
		gitRepositoryIds?: string[];
		customerSprints?: IDashboardSprint[];
		issueTypes?: AcumenTaskType[];
		timezone: string;
	};
}

export const ABANDONED_WORK_DATA = {
	title: "Unmerged commits",
	description: `Shows the amount of abandoned commits (not merged to the default branch and under one year old)."`
};

function AbandonedWork(props: IAbandonedWorkProps) {
	const { metricStore } = useStores();
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const { fetchData } = metricStore.abandonedWorkData();
	const { abandonedWorkDataStored } = metricStore;
	const [chartData, setChartData] = useState<IAbandonedWorkData | undefined>(abandonedWorkDataStored);

	useEffect(() => {
		if (props.isFullScreen) {
			return;
		}
		if (Object.keys(props.filters).length === 0) {
			return;
		}

		let isMounted = true;
		async function fetch() {
			setIsFetching(true);
			if (props.setDisableFullScreen) {
				props.setDisableFullScreen(true);
			}
			const data = await fetchData(props.filters.teamId,
				props.filters.dataContributorIds,
				props.filters.projectIds,
				props.filters.startTime,
				props.filters.endTime,
				props.filters.interval,
				props.filters.timezone,
				props.filters.gitRepositoryIds,
				props.filters.boardIds,
				props.filters.issueTypes);
			if (isMounted && data) {
				setIsFetching(false);
				setChartData(data);
			}
			if (props.setDisableFullScreen) {
				props.setDisableFullScreen(false);
			}
		}

		// tslint:disable-next-line: no-floating-promises
		fetch().then(() => {
			isMounted = false;
		});
	}, [props.filters]);

	let numberOfCommitSeriesData: ChartSeriesData = [];

	if (isValidChartData<IAbandonedWorkData>(chartData) &&
		isSameIntervalCategory(chartData.commitCount.interval, props.filters.interval)) {
		const sprintById = packSprintByIdIfNeeded(props.filters.interval, props.filters.customerSprints);

		if (sprintById) {
			numberOfCommitSeriesData = prepareCategorySeriesDataBySprint(chartData.commitCount, sprintById);
		} else {
			numberOfCommitSeriesData = prepareCategorySeriesDataByDate(chartData.commitCount, props.filters.timezone);
		}
	}

	const splineSeries: Highcharts.SeriesSplineOptions[] = [
		{
			id: generateUUID(),
			type: "spline",
			name: "# of commits",
			data: numberOfCommitSeriesData,
			visible: numberOfCommitSeriesData.length > 0,
			dashStyle: "Dash",
			color: "#000000",
			lineWidth: 1,
			marker: {
				symbol: "circle"
			},
		}
	];

	const options: Highcharts.Options = {
		chart: {
			type: "column",
			zoomType: "xy"
		},
		title: undefined,
		tooltip: {
			split: true,
		},
		xAxis: {
			gridLineWidth: 1,
			type: (props.filters.interval === MetricInterval.SPRINT_DATE ? "category" : "datetime"),
			uniqueNames: false
		},
		yAxis: [
			{
				min: 0,
				title: {
					text: "# of commits"
				}
			}
		],
		credits: {
			enabled: false
		},
		series: splineSeries
	};

	return (
		<div className="description">
			<LoadingIndicator local={true} isActive={!isValidChartData(chartData) || isFetching}>
				<HighchartsReact
					highcharts={Highcharts}
					options={options}
				/>
			</LoadingIndicator>
		</div>
	);
}

export default AbandonedWork;
