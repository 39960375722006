import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useState } from "react";
import { useStores } from "../../../mobx-stores";
import { CategoryChartSeriesData } from "../../../pages/my-team/analytics/charts/charts";
import LoadingIndicator from "../../../components/loader";
import { IChartScope } from "./chart-scope";
import useDeepCompareEffect from "use-deep-compare-effect";
import _ from "lodash";
import { Grid, Popup } from "semantic-ui-react";
import colorScheme from "../../../pages/my-team/analytics/charts/chart-color-scheme";
import { round } from "@acumen/common";
import PieChart from "../../../components/highchart-chart-components/pie-chart";
import { ChartExternalLegend, ILegendList } from "../../../pages/org-analytics/particles";
import {
	IDashboardJiraPriority
} from "@acumen/dashboard-common";
import uniqolor from "../../../../utils/color";

interface IProps {
	scope: IChartScope;
	mainTitle?: { title: string, tooltip?: string };
	showExternalLegend?: boolean;
	showDataLabels?: boolean;
	chartHeight?: {
		mainChartHeight: number,
		summaryPieChartHeight: number
	};
}

interface ISeriesProps {
	sortOrder: number;
	seriesName: string;
	seriesColorHex: string;
}

const DEFAULT_DISPLAY_PROPERTIES: { [key: string]: ISeriesProps } = {
	0: {
		sortOrder: 0,
		seriesName: "Highest",
		seriesColorHex: colorScheme.issuePriority.highest
	},
	1: {
		sortOrder: 1,
		seriesName: "High",
		seriesColorHex: colorScheme.issuePriority.high
	},
	2: {
		sortOrder: 2,
		seriesName: "Medium",
		seriesColorHex: colorScheme.issuePriority.medium
	}
};

function priorityOverrideByOrder(zeroBasedOrderIndex: number, priorities?: IDashboardJiraPriority[]): ISeriesProps {
	const displayProperties = DEFAULT_DISPLAY_PROPERTIES[zeroBasedOrderIndex];
	let overridePriority: IDashboardJiraPriority | undefined;
	if (priorities && priorities.length > zeroBasedOrderIndex) {
		overridePriority = priorities[zeroBasedOrderIndex];
	}

	if (displayProperties) {
		return {
			sortOrder: displayProperties.sortOrder,
			seriesName: (overridePriority?.name ?? displayProperties.seriesName),
			seriesColorHex: displayProperties.seriesColorHex,
		};
	}
	return {
		seriesName: `${zeroBasedOrderIndex}`,
		seriesColorHex: uniqolor(`${zeroBasedOrderIndex}`),
		sortOrder: zeroBasedOrderIndex
	};
}
function MTTRByPriority(props: IProps) {
	const { teamsReportStore, priorityStore } = useStores();
	const [expandedChartOptions, setExpandedChartOptions] = useState<Highcharts.Options | undefined>(undefined);
	const [summarizedChartOptions, setSummarizedChartOptions] = useState<Highcharts.Options | undefined>(undefined);
	const [summarizedLegendList, setSummarizedLegendList] = useState<{ data: ILegendList[], title?: string } | undefined>(undefined);
	const [hoveredSummaryMetric, setHoveredSummaryMetric] = useState<undefined | string>(undefined);

	const { scope, mainTitle, showDataLabels, showExternalLegend, chartHeight } = { ...props };

	function buildExpandedChartOptions(data: {
		averageLeadTime: CategoryChartSeriesData,
		bugCountByPriority: CategoryChartSeriesData,
	}, topPriorities: IDashboardJiraPriority[] | undefined): Highcharts.Options {
		let series: Highcharts.SeriesColumnOptions[] | undefined;
		series = Object
			.keys(data.averageLeadTime)
			.filter(key => isNaN(parseInt(key, 10)) === false)
			.map(key => {
				const priorityOrder = parseInt(key, 10);
				const displayProperties = priorityOverrideByOrder(priorityOrder, topPriorities);
				const seriesOptions: Highcharts.SeriesColumnOptions = {
					name: (displayProperties !== undefined ? displayProperties.seriesName : key),
					index: displayProperties?.sortOrder,
					legendIndex: displayProperties?.sortOrder,
					color: displayProperties?.seriesColorHex,
					data: data.averageLeadTime[key],
					tooltip: {
						headerFormat: '<span style="color:{point.color}">\u25CF</span><b> {series.name}</b>:<br>',
						pointFormatter: function _pointFormatter() {
							const matchingCategory = data.bugCountByPriority[key];
							const matchingValue = matchingCategory.find(mc => mc[0] === this.x);
							const hours = this.y ? round(this.y) : 0;
							if (!matchingValue) {
								return `${hours} hours<br />`;
							}
							return `${hours} hours<br />${matchingValue[1] ?? 0} bugs`;
						},
						followPointer: true
					},
					showInLegend: showExternalLegend ? false : true,
					type: "column"
				};
				return seriesOptions;
			});
		const options: Highcharts.Options = {
			title: undefined,
			chart: chartHeight ? { height: chartHeight?.mainChartHeight } : {},
			xAxis: {
				gridLineWidth: 1,
				type: "datetime"
			},
			tooltip: {
				useHTML: true,
				style: {
					pointerEvents: "auto"
				}
			},
			yAxis: [
				{
					min: 0,
					title: {
						text: "Hours"
					},
					labels: {
						formatter: function _formatter() {
							return `${this.value} hrs`;
						}
					}
				}
			],
			credits: {
				enabled: false
			},
			series
		};

		return options;
	}

	type EnrichedPointOptions = Highcharts.PointOptionsObject & {
		avgHoursPerPriority: number;
	};

	function buildSummarizedChartOptions(data: {
		averageLeadTime: CategoryChartSeriesData,
		bugCountByPriority: CategoryChartSeriesData,
	}, topPriorities: IDashboardJiraPriority[] | undefined): Highcharts.Options {
		const piePoints = Object
			.keys(data.averageLeadTime)
			.filter(key => isNaN(parseInt(key, 10)) === false)
			.map(key => {
				const priorityOrder = parseInt(key, 10);
				const displayProperties = priorityOverrideByOrder(priorityOrder, topPriorities);

				const bugCountForPriorityCategorySeries = data.bugCountByPriority[key];
				let sumOfBugCountForPriority = 0;
				for (const avgLeadTimeCategory of data.averageLeadTime[key]) {
					const period = avgLeadTimeCategory[0];
					const avgLeadTimeCategoryInPeriod = avgLeadTimeCategory[1];
					const bugCountForPriorityInPeriod = bugCountForPriorityCategorySeries.find(mc => mc[0] === period);
					sumOfBugCountForPriority += ((bugCountForPriorityInPeriod ? bugCountForPriorityInPeriod[1] : 0) * avgLeadTimeCategoryInPeriod);
				}
				const totalValues = _.sumBy(bugCountForPriorityCategorySeries, d => d[1]);

				const seriesOptions: EnrichedPointOptions = {
					name: (displayProperties !== undefined ? displayProperties.seriesName : key),
					color: displayProperties?.seriesColorHex,
					y: totalValues,
					avgHoursPerPriority: (totalValues === 0 ? 0 : round(sumOfBugCountForPriority / totalValues, 0))
				};
				return seriesOptions;
			});
		setSummarizedLegendList({
			data: ((array: EnrichedPointOptions[]): ILegendList[] => {
				return array.map(point => {
					return {
						name: point.name?.toString() ?? "",
						value: point.avgHoursPerPriority,
						color: point.color?.toString(),
						valueSuffix: "hrs"
					};
				});
			})(piePoints),
			title: "Total amount of bugs vs average"
		});

		const pieSeries: Highcharts.SeriesPieOptions = {
			type: "pie",
			data: piePoints,
			innerSize: "80%",
			tooltip: {
				pointFormat: "<b>{point.percentage:.1f}%</b>"
			}
		};
		const options: Highcharts.Options = {
			chart: {
				plotBackgroundColor: undefined,
				plotBorderWidth: undefined,
				plotShadow: false
			},
			title: {
				text: `% out of total bugs`,
				verticalAlign: "middle",
				floating: true
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: "pointer",
					dataLabels: showDataLabels ? {
						enabled: true,
						format: "<b>{point.name}</b>: {point.y} hr"
					} : {
						enabled: false
					}
				}
			},
			credits: {
				enabled: false
			},
			series: [pieSeries]
		};

		return options;
	}

	async function fetchData(): Promise<{
		averageLeadTime: CategoryChartSeriesData,
		bugCountByPriority: CategoryChartSeriesData,
	} | undefined> {
		const data = await teamsReportStore.mttrByPriority(scope.startTime, scope.endTime, scope.timezone, scope.interval,
			scope.teamIds, scope.projectIds);
		return data;
	}

	function shouldFetchData(): boolean {
		return (!teamsReportStore.isLoadingMTTRByPriorityMetric);
	}

	useDeepCompareEffect(() => {
		let isMounted = true;
		async function fetchChartData() {

			let topPriorities = priorityStore.priorities;
			if (!topPriorities) {
				topPriorities = await priorityStore.fetchJiraPriorities(3);
			}

			const data = await fetchData();

			if (isMounted && data) {
				setExpandedChartOptions(buildExpandedChartOptions(data, topPriorities));
				setSummarizedChartOptions(buildSummarizedChartOptions(data, topPriorities));
			}
		}

		if (shouldFetchData()) {
			// tslint:disable-next-line: no-floating-promises
			fetchChartData();
		}
		return () => { isMounted = false; };
	}, [props]);

	return (
		<LoadingIndicator local={true} isActive={expandedChartOptions === undefined && summarizedChartOptions === undefined}>
			<Grid.Column width={10}>
				{mainTitle &&
					<h2 className="ui title acu-capitalize">{mainTitle.title}
						{mainTitle.tooltip && <Popup
							hoverable={true}
							wide={true}
							className={"tiny-text"}
							position="top center"
							content={mainTitle.tooltip}
							trigger={
								<i className="chart-tooltip info circle outline icon" />
							}
						/>}
					</h2>}
				{expandedChartOptions && <HighchartsReact
					highcharts={Highcharts}
					options={expandedChartOptions}
				/>}
			</Grid.Column>
			<Grid.Column width={6}>
				{mainTitle &&
					<h2 className="ui title acu-capitalize">{summarizedLegendList?.title}</h2>
				}
				{showExternalLegend &&
					<div className="ui grid full-height">
						<Grid.Column width={8}>
							{summarizedChartOptions &&
								<div className="pie-chart-container">
									<PieChart
										series={summarizedChartOptions?.series}
										triggerHover={hoveredSummaryMetric}
										title="% out of total bugs"
										height={chartHeight ? chartHeight?.summaryPieChartHeight : undefined}
									/>
								</div>
							}
						</Grid.Column>
						<Grid.Column width={8}>
							<div className="legend-container">
								{summarizedChartOptions?.series !== undefined &&
									<ChartExternalLegend
										legendList={summarizedLegendList?.data}
										hoveredEntry={hoveredSummaryMetric}
										setHoveredEntry={setHoveredSummaryMetric}
										legendTitle="Average per priority"
									/>}
							</div>
						</Grid.Column>
					</div>
				}
				{summarizedChartOptions && !showExternalLegend && <HighchartsReact
					highcharts={Highcharts}
					options={summarizedChartOptions}
				/>}
			</Grid.Column>
		</LoadingIndicator>
	);
}

export default MTTRByPriority;
