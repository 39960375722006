import React from "react";

interface EmptySprintStateProps {
	imageSrc?: string;
	text?: string;
}

export const EmptySprintState = (props: EmptySprintStateProps) => {
	const {
		imageSrc,
		text = "Looks like this particular sprint is currently empty",
	} = props;

	return (
		<div className="empty-state">
			<img src={imageSrc} alt={text} />
		</div>
	);
};

export default EmptySprintState;
