import React, { useCallback, useRef } from "react";
import { createPortal } from "react-dom";

import TriggerOnClickOutside from "../ux-effects/trigger-on-click-outside";
import FilterDropdownItem from "./filter-dropdown-item";
import {
	useFilteredOptions,
	useFilterEffect,
	useSearchState,
	useSelectionState,
} from "./hooks";
import { useTopPosition } from "../../../hooks/useTopPosition";
import { useLeftPosition } from "../../../hooks/useLeftPosition";
import { ResolvedFilterOption } from "./types";
import "./filter-dropdown.scss";
import { identity } from "lodash";

interface FilterDropdownProps {
	triggerRef: React.RefObject<HTMLElement | null>;
	placeholder?: string;
	options: ResolvedFilterOption[];
	presetSelection: Set<string | number> | null;
	selection: Set<string | number> | null;
	onSave: (selection: Set<string | number> | null) => void;
	onHide: () => void;
}

const DROPDOWN_Y_MARGIN_FROM_TARGET = 5;
const PREFERRED_DROPDOWN_MIN_X_MARGIN = 32;

const FilterDropdown = (props: FilterDropdownProps) => {
	const { triggerRef, placeholder, options, presetSelection, selection, onSave, onHide } = props;
	const dropdownRef = useRef<HTMLDivElement>(null);
	const [searchTerm, handleSearchInput] = useSearchState();
	const optionsFilteredBySearchTerm = useFilteredOptions({ options, searchTerm });
	const top = useTopPosition({
		elementRef: dropdownRef,
		targetRef: triggerRef,
		marginFromTarget: DROPDOWN_Y_MARGIN_FROM_TARGET,
	});
	const left = useLeftPosition({
		elementRef: dropdownRef,
		targetRef: triggerRef,
		preferredMinimumMargin: PREFERRED_DROPDOWN_MIN_X_MARGIN,
	});
	const { selection: uncommittedSelection, toggle, selectAll, clear } = useSelectionState({
		options,
		selection,
	});
	const handleHideAndSave = useCallback(() => {
		onSave(!uncommittedSelection || uncommittedSelection.size === 0 ? null : uncommittedSelection);
		onHide();
	}, [uncommittedSelection, onSave, onHide]);

	useFilterEffect({ mapItemToFilterOption: identity, selection: presetSelection });

	return createPortal((
		<div className="filter-dropdown-container" style={{ visibility: top && left ? "visible" : "hidden" }}>
			<TriggerOnClickOutside excludedElements={triggerRef.current} onTrigger={handleHideAndSave}>
				<div className="filter-dropdown ui segment raised clickable" style={{ top, left }} ref={dropdownRef}>
					<div className="filter-dropdown-content">
						<div className="items-search-field">
							<span className="search-icon"/>
							<input
								autoFocus={true}
								type="search"
								className="items-search-input"
								placeholder={placeholder ?? "Search..."}
								value={searchTerm}
								onChange={handleSearchInput}
							/>
						</div>
						<div className="action-buttons">
							<button className="action-button" onClick={selectAll}>Select all</button>
							<button className="action-button" onClick={clear}>Clear</button>
						</div>
						<ul className="items">
							{optionsFilteredBySearchTerm.map(option => (
								<FilterDropdownItem
									key={option.value}
									item={option}
									isSelected={!uncommittedSelection || uncommittedSelection.has(option.value)}
									onToggle={toggle}
								/>
							))}
						</ul>
					</div>
				</div>
			</TriggerOnClickOutside>
		</div>
	), document.body);
};

export default FilterDropdown;
