import * as React from "react";
import { round } from "@acumen/common";
import "./developer-stats-table-aggregated-data-cell.scss";

export interface DeveloperStatsTableAggregatedDataCellProps {
	value: number;
	unit: string;
}

export const DeveloperStatsTableAggregatedDataCell = (props: DeveloperStatsTableAggregatedDataCellProps) => {
	const { value, unit } = props;

	return (
		<td className="developer-stats-table-aggregated-data-cell">
			<div className="developer-stats-table-aggregated-data-cell-content">
				<span className="developer-stats-table-aggregated-data-cell-content-value">
					{round(value, 0)}
				</span>

				<span className="developer-stats-table-aggregated-data-cell-content-unit">
					{unit}
				</span>
			</div>
		</td>
	);
};
