import React from "react";
import { Helmet } from "react-helmet";

interface IMetaProps {
	title?: string;
	includeCustomFonts?: boolean;
}

const Meta = (props: IMetaProps) => {
	return (
		<>
			<Helmet>
				<title>
					{`${props.title ? props.title : "Acumen Dashboard"}`}
				</title>

				<meta charSet="UTF-8" key="charset" />

				<meta
					name="viewport"
					content="width=device-width,initial-scale=1 shrink-to-fit=no, user-scalable=0, minimal-ui"
					key="viewport" />

				<link
					rel="shortcut icon"
					href={`/favicon.ico`}
					key="favicon" />

				<link
					rel="stylesheet"
					type="text/css"
					href={(process.env.NODE_ENV === "production") ? `/assets/style/v1/semantic.min.css` : `/assets/style/v1/semantic.css`}
					key="semantic" />

				<link
					rel="stylesheet"
					type="text/css"
					href={`/assets/style/boostrap-isolated.min.css`}
					key="boostrap-isolated" />

				{props.includeCustomFonts === true &&
					<link rel="preconnect"
					href={`https://fonts.googleapis.com`} />
				}

				{props.includeCustomFonts === true &&
					<link rel="preconnect"
					href={`https://fonts.gstatic.com`} crossOrigin={"true"} />
				}

				{props.includeCustomFonts === true &&
					<link rel="stylesheet"
					href={`https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600&display=swap`} />
				}
			</Helmet>
		</>
	);
};

export { Meta };
