import { useMemo } from "react";
import { FilterFunction } from "../providers/filter-provider";

interface UseFilteredItemsProps<T> {
	items: T[];
	filters: Array<FilterFunction<T>>;
}

function useFilteredItems<T>({ items, filters }: UseFilteredItemsProps<T>) {
	return useMemo(
		() => items.filter(item => filters.every(filter => filter(item))),
		[items, filters]
	);
}

export default useFilteredItems;
