import React, { forwardRef } from "react";
import classNames from "classnames";
import "./custom-select-trigger.scss";

export interface CustomSelectTriggerProps {
	triggerClass?: string;
	labelClass?: string;
	arrowClass?: string;
	text: React.ReactNode;
}

export const CustomSelectTrigger = forwardRef((props: CustomSelectTriggerProps, ref: React.Ref<HTMLButtonElement>) => {
	const { text, triggerClass, labelClass, arrowClass } = props;

	return (
		<button ref={ref} type="button" className={classNames(triggerClass, "custom-select-trigger")}>
			<div className={classNames(labelClass, "custom-select-trigger-label")}>{text}</div>
			<div className={classNames(arrowClass, "custom-select-trigger-arrow")}/>
		</button>
	);
});
