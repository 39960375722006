import { getData } from "../../../services/fetch-helpers";
import { DashboardUserRoleType, IDashboardUser } from "@acumen/dashboard-common";
import { BaseCustomerApiClient } from "./base-customer-api-client";
import urlJoin from "url-join";
import { qsStringify } from "../../../services/url-routes-helper";

export const USERS_ROUTE = "users";

export class InviteUsersApiClient extends BaseCustomerApiClient {
	public inviteUser = async (dcId: string, email: string, role: DashboardUserRoleType) => {
		const query: Record<string, string> = {};
		query.dcId = dcId;
		query.email = email;
		query.role = role.toString();

		return getData<IDashboardUser, any>(
			this.createCustomerEntityRoute(`${urlJoin(USERS_ROUTE, "invite")}?${qsStringify(query)}`),
			this.token
		);
	}
}
