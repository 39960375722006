import { IDashboardUser, Status } from "@acumen/dashboard-common";
import { useStores } from "../../../mobx-stores";
import React, { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react";
import { Avatar } from "../../../components/avatar";
import { useForm, Controller } from "react-hook-form";
import CheckboxInput from "../../../components/form/checkbox-input";
import ReactSelect from "react-select";
import { useLocation, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { ROUTES } from "../../../routing/index";
import { format, STRINGS } from "../../../../localization";
import ConfirmationModal from "../../../components/modals/confirmation-modal";
import { GA_EVENT_ACTION, GA_EVENT_CATEGORY, clickEvent } from "../../../analytics-events";
import classNames from "classnames";
import StringInput from "../../../components/form/string-input";
import "./style.scss";
import _ from "lodash";
import { IUserRoleDropdownOption, mapUserRolesToDropdownOptions, ROLE_TYPE_DROPDOWN_OPTIONS } from "v2/mobx-stores/users-store";

enum EditUserFormFields {
	Email = "email",
	FirstName = "firstName",
	Surname = "surname",
	Title = "title",
	Roles = "roles",
	Status = "status",
}

const SAVED_USER_SUCCESS_TOAST_ID = "saved_user_details_successfully";
const SAVED_USER_MESSAGE = "Success: All your changes have been saved.";
const AUTO_DISMISS_TOAST_MS = 5000;

interface IEditSingleUserProps {
	userId: string;
}

const editSingleUser = observer((props?: IEditSingleUserProps) => {
	const isMountedRef = useRef(false);
	const searchParams = new URLSearchParams(useLocation().search);
	const userId = props && props.userId ? props.userId : (searchParams.get("id") ?? undefined);
	const [showConfirmAlert, toggleConfirmAlert] = useState(false);
	const [isFormDirty, setFormDirty] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [persistedUser, setPersistedUser] = useState<IDashboardUser | undefined>(undefined);
	const [isUserValid, setIsUserValid] = useState<boolean>(false);

	const {
		register,
		control,
		setValue,
		getValues
	} = useForm({
		defaultValues: {},
		reValidateMode: "onBlur"
	});

	const {
		usersStore: { fetchUser, updateUser },
		authStore: { isUserAdmin, authUser }
	} = useStores();

	useEffect(() => {
		isMountedRef.current = true;
		if (userId && isMountedRef.current) {
			// tslint:disable-next-line: no-floating-promises
			fetchUser(userId).then(user => {
				if (user) {
					setValue(EditUserFormFields.Email, user.email);
					setValue(EditUserFormFields.FirstName, user.firstName);
					setValue(EditUserFormFields.Surname, user.surname);
					setValue(EditUserFormFields.Title, user.title);
					setValue(EditUserFormFields.Status, user.status === Status.Enabled);
					setValue(EditUserFormFields.Roles, mapUserRolesToDropdownOptions(user.roles));
					setPersistedUser(user);
					isMountedRef.current = false;
				}
			});
			return () => {
				isMountedRef.current = false;
			};
		}
	}, []);

	const getFormValues = () => {
		const formValues = getValues();
		const getString = (value: any) => _.isString(value) ? value : undefined;
		return {
			[EditUserFormFields.Email]: getString(formValues[EditUserFormFields.Email]),
			[EditUserFormFields.FirstName]: getString(formValues[EditUserFormFields.FirstName]),
			[EditUserFormFields.Surname]: getString(formValues[EditUserFormFields.Surname]),
			[EditUserFormFields.Title]: getString(formValues[EditUserFormFields.Title]),
			[EditUserFormFields.Status]: getStatusFromValueOrUndefined(formValues[EditUserFormFields.Status]),
			[EditUserFormFields.Roles]: formValues[EditUserFormFields.Roles] as IUserRoleDropdownOption[],
		};
	};

	const getStatusFromValueOrUndefined = (statusValue: any) => {
		if (statusValue === undefined) {
			return statusValue;
		}

		if (_.isBoolean(statusValue)) {
			if (statusValue === true) {
				return Status.Enabled;
			}
			return Status.Disabled;
		}
		return undefined;
	};
	const updateUserData = async () => {
		const formValue = getFormValues();
		const rolesToSave = formValue.roles.map(r => r.value);
		const partialUserToSave: Partial<IDashboardUser> = {
			id: userId,
		};

		if (!_.isEqual(formValue.email, persistedUser?.email)) {
			partialUserToSave.email = formValue.email;
		}
		if (!_.isEqual(formValue.title, persistedUser?.title)) {
			partialUserToSave.title = formValue.title;
		}
		if (!_.isEqual(formValue.firstName, persistedUser?.firstName)) {
			partialUserToSave.firstName = formValue.firstName;
		}
		if (!_.isEqual(formValue.surname, persistedUser?.surname)) {
			partialUserToSave.surname = formValue.surname;
		}
		if (persistedUser?.status !== formValue.status) {
			partialUserToSave.status = formValue.status;
		}
		if (!_.isEqual(persistedUser?.roles, rolesToSave)) {
			partialUserToSave.roles = rolesToSave;
		}

		await updateUser(partialUserToSave);
	};

	const onError = (error: string) => {
		toast.error(error);
	};

	const onSuccess = (success: string, toastId?: string) => {
		toast.success(success, {
			toastId,
			autoClose: AUTO_DISMISS_TOAST_MS
		});
	};

	const promptUserStatus = (switchUserStatusToEnabled: boolean) => {
		if (switchUserStatusToEnabled) {
			setValue(EditUserFormFields.Status, true);
			setFormDirty(true);
			setIsUserValid(validateForm());
		} else {
			toggleConfirmAlert(true);
		}
	};

	const confirmDisableUser = () => {
		setValue(EditUserFormFields.Status, false);
		toggleConfirmAlert(false);
		setFormDirty(true);
		setIsUserValid(validateForm());
	};

	const validateForm = (fieldName?: EditUserFormFields) => {
		const formValues = getFormValues();
		const validateFormField = (field: EditUserFormFields) => {
			const value = formValues[field];
			if (!value) {
				return false;
			} else {
				switch (field) {
					case EditUserFormFields.Email:
					case EditUserFormFields.FirstName:
					case EditUserFormFields.Surname:
					case EditUserFormFields.Title:
						return _.isString(formValues[field]) ? (value as string).length > 0 : false;
					case EditUserFormFields.Roles:
						return formValues[field] && _.isArray(formValues[field]) && formValues[field].length > 0;
					default:
						return formValues[field] !== undefined;
				}
			}
		};
		if (fieldName) {
			return validateFormField(fieldName);
		}

		return [
			validateFormField(EditUserFormFields.Email),
			validateFormField(EditUserFormFields.FirstName),
			validateFormField(EditUserFormFields.Surname),
			validateFormField(EditUserFormFields.Title),
			validateFormField(EditUserFormFields.Roles),
		].reduce((isAllValid, isValid) => {
			return isValid && isAllValid;
		}, true) ?? false;
	};

	if (!userId) {
		return <Redirect to={ROUTES.MY_ACCOUNT_USERS} />;
	}
	return (
		<div className="ui grid" id="edit-user">
			<div className="six wide column user-form">
				<ConfirmationModal
					className={"warning"}
					open={showConfirmAlert}
					header={getFormValues().status ? "Disable Member" : "Enable Member"}
					confirmButton={getFormValues().status ? "Disable Member" : "Enable Member"}
					content={getFormValues().status ?
						format(STRINGS.USER_DISABLE_CONFIRM_MESSAGE, persistedUser?.firstName + " " + persistedUser?.surname) :
						format(STRINGS.USER_ENABLE_CONFIRM_MESSAGE, persistedUser?.firstName + " " + persistedUser?.surname)}
					onCancel={() => toggleConfirmAlert(false)}
					onConfirm={() => confirmDisableUser()}
				/>

				<div className={classNames("ui segment raised", { loading: (isLoading || !persistedUser) })}>
					<form className="ui form user-edit">
						<div className="ui grid">
							<div className="sixteen wide column">
								<Avatar
									className="user-avatar"
									user={{
										firstName: getValues(EditUserFormFields.FirstName),
										surname: getValues(EditUserFormFields.Surname),
										email: persistedUser?.email,
										pictureUrl: persistedUser?.pictureUrl
									}}
								/>
							</div>

							<div className="eight wide column">
								{<StringInput
									label={"First name"}
									name={EditUserFormFields.FirstName}
									defaultValue={getValues(EditUserFormFields.FirstName)}
									registerInput={register}
									required={true}
									onError={(str) => onError(str)}
									onSave={(value) => {
										setValue(EditUserFormFields.FirstName, value);
										setFormDirty(true);
										setIsUserValid(validateForm());
									}}
									showRequired={!validateForm(EditUserFormFields.FirstName)}
								/>}
							</div>

							<div className="eight wide column">
								{<StringInput
									label={"Surname"}
									name={EditUserFormFields.Surname}
									defaultValue={getValues(EditUserFormFields.Surname)}
									registerInput={register}
									required={true}
									onError={(str) => onError(str)}
									onSave={(value) => {
										setValue(EditUserFormFields.Surname, value);
										setFormDirty(true);
										setIsUserValid(validateForm());
									}}
									showRequired={!validateForm(EditUserFormFields.Surname)}
								/>}
							</div>

							<div className="sixteen wide column">
								{<StringInput
									label={"Email"}
									name={EditUserFormFields.Email}
									defaultValue={getValues(EditUserFormFields.Email)}
									registerInput={register}
									onError={(str) => onError(str)}
									disabled={true}
									required={true}
									onSave={() => { return; }}
									showRequired={!validateForm(EditUserFormFields.Email)}
								/>}
							</div>

							<div className="sixteen wide column">
								{<StringInput
									label={"Title"}
									name={EditUserFormFields.Title}
									defaultValue={getValues(EditUserFormFields.Title)}
									registerInput={register}
									required={true}
									onError={(str) => onError(str)}
									onSave={(value) => {
										setValue(EditUserFormFields.Title, value);
										setFormDirty(true);
										setIsUserValid(validateForm());
									}}
									showRequired={!validateForm(EditUserFormFields.Title)}
								/>}
							</div>

							<div className="sixteen wide column">
								<label>User roles</label>
								<Controller
									className={"clickable"}
									control={control}
									name={EditUserFormFields.Roles}
									label={EditUserFormFields.Roles}
									defaultValue={getValues(EditUserFormFields.Roles)}
									render={({ value }) => (
										<ReactSelect
											className={classNames("form-control is-valid clickable", { required: !validateForm(EditUserFormFields.Roles) })}
											isDisabled={!isUserAdmin}
											isClearable={false}
											multiValueRemove={true}
											isMulti={true}
											controlShouldRenderValue={true}
											options={ROLE_TYPE_DROPDOWN_OPTIONS}
											value={value}
											onChange={(newRoles?:
												IUserRoleDropdownOption
												| ReadonlyArray<IUserRoleDropdownOption>
												| null) => {
												if (newRoles && Array.isArray(newRoles)) {
													setValue(EditUserFormFields.Roles, newRoles);
													setFormDirty(true);
													setIsUserValid(validateForm());
												}
											}}
											styles={{
												control: (base) => ({
													...base,
													cursor: "pointer",
												}),
												option: (base) => ({
													...base,
													cursor: "pointer",
												})
											}}
											{...clickEvent(GA_EVENT_CATEGORY.UserEdit, GA_EVENT_ACTION.Select, "UserRole")}
										/>
									)}
								/>
							</div>

							<div className="ui divider sixteen wide column" style={{ paddingBottom: "0px", marginBottom: "0px" }} />

							<div className="sixteen wide column">
								<div className="order-container-row">
									<CheckboxInput
										name={EditUserFormFields.Status}
										label={"Status"}
										disabled={!isUserAdmin || authUser.id === userId}
										onChange={promptUserStatus}
										value={getFormValues().status === Status.Enabled}
										register={register}
										isToggle={true}
										{...clickEvent(GA_EVENT_CATEGORY.UserEdit, GA_EVENT_ACTION.Select, "UserStatus")}
									/>

									<button
										type="submit"
										className={classNames("ui button", { disabled: !isFormDirty || !isUserValid })}
										onClick={async () => {
											setIsLoading(true);
											await updateUserData();
											onSuccess(SAVED_USER_MESSAGE, SAVED_USER_SUCCESS_TOAST_ID);
											setFormDirty(false);
											setIsLoading(false);
										}}
										{...clickEvent(GA_EVENT_CATEGORY.UserEdit, GA_EVENT_ACTION.Edit, "Edit user")}
									>Save</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
});
export default editSingleUser;
