import {
	AcumenMetricGroupType,
	AcumenPullRequestStatusWithWorkStart,
	AcumenTaskType,
	ChartType,
	DashboardExportFormat,
	DashboardTeamReportMetric,
	DashboardTeamReportMetricChangeReaction,
	DateRangeType,
	GitRepositoryIntegrationType, IChartResponse, IDashboardAcumenMetricDataRequest, IDashboardAcumenMetricDataResponse,
	IDashboardResponse, IDashboardTeamReportMetric, IDashboardTeamsReport, MetricInterval, TUPLE_SEPARATOR
} from "@acumen/dashboard-common";
import { getData, getFile, postData } from "../../../services/fetch-helpers";
import { MetricApiClient, MULTI_METRICS_ROUTE } from "./metric-api-client";
import { qsStringify } from "../../../services/url-routes-helper";
import { CategoryChartSeriesData } from "v2/helpers/charts";
import moment from "moment-timezone";
import urlJoin from "url-join";
import _ from "lodash";

const CHARTS_ROUTE = "charts";
const TEAMS_REPORT_ROUTE = "reports/teams";

const SECONDS_TO_HOURS_MULTIPLIER = 3600;
const PR_CYCLE_WIP_STATUS = [AcumenPullRequestStatusWithWorkStart.Open, AcumenPullRequestStatusWithWorkStart.WorkStart];
const PR_CYCLE_AWAITING_REVIEW_STATUS = [AcumenPullRequestStatusWithWorkStart.AwaitingReview];
const PR_CYCLE_IN_REVIEW_STATUS = [AcumenPullRequestStatusWithWorkStart.InReview];
const PR_CYCLE_REVIEWED_STATUS = [AcumenPullRequestStatusWithWorkStart.Reviewed];

export class MetricOrgApiClient extends MetricApiClient {
	public fetchGitHubReportedDeploymentFrequency = async (interval: MetricInterval, startTime: Date, endTime: Date,
		timezone: string, teamIds?: string[], dataContributorIds?: string[], gitRepositoryIds?: string[], environments?: string[]) => {
		const query: { [label: string]: string | string[] | number } = {};

		query.interval = interval;
		query.timezone = timezone;
		query.startTime = moment.tz(startTime, timezone).toISOString(true);
		query.endTime = moment.tz(endTime, timezone).toISOString(true);
		if (teamIds && teamIds.length > 0) {
			query["teamIds[]"] = teamIds;
		}
		if (gitRepositoryIds) {
			query["repositoryIds[]"] = gitRepositoryIds.map(r => `${r},${GitRepositoryIntegrationType.GITHUB}`);
		}
		if (dataContributorIds) {
			query["dataContributorIds[]"] = dataContributorIds;
		}
		if (environments) {
			query["environments[]"] = environments;
		}

		return getData<CategoryChartSeriesData, any>(
			this.createCustomerEntityRoute(`${urlJoin(CHARTS_ROUTE, "github-reported-deployment-frequency")}?${qsStringify(query)}`),
			this.token
		);
	}

	public fetchPRCycleTime = async (interval: MetricInterval, dateRange: DateRangeType,
		timezone: string, teamIds?: string[], dataContributorIds?: string[], gitRepositoryIds?: string[],
		excludeDraftPrs?: boolean, baseIsDefaultBranch?: boolean, includeAggregatedPRs?: boolean) => {
		return this.fetchChartData(ChartType.PRCycleTime, interval, dateRange, timezone, teamIds,
			dataContributorIds, undefined, undefined,
			gitRepositoryIds, undefined, excludeDraftPrs, baseIsDefaultBranch, undefined, includeAggregatedPRs);
	}

	public fetchReport = async (startTime: Date, endTime: Date,
		teamIds?: string[], gitRepositoryIds?: string[], jiraProjectIds?: string[],
		metrics?: DashboardTeamReportMetric[]) => {
		const query: { [label: string]: string | string[] | number | Date } = {};

		query.startTime = startTime;
		query.endTime = endTime;
		if (teamIds) {
			query["teamIds[]"] = teamIds;
		}
		if (gitRepositoryIds) {
			query["repositories[]"] = gitRepositoryIds;
		}
		if (jiraProjectIds) {
			query["projects[]"] = jiraProjectIds;
		}
		if (metrics && metrics.length > 0) {
			query["metrics[]"] = metrics;
		}

		return getData<IDashboardTeamsReport, any>(
			this.createCustomerEntityRoute(`${TEAMS_REPORT_ROUTE}?${qsStringify(query)}`),
			this.token
		);
	}

	private generateQueryString = (type: ChartType, interval: MetricInterval, dateRange: DateRangeType,
		timezone: string, debug: boolean = false, teamIds: string[] = [], dataContributorIds: string[] = [],
		boardId: string[] = [], projectIds: string[] = [], repositoryIds: string[] = [],
		acumenTaskTypes: AcumenTaskType[] = [], excludeDraftPrs?: boolean, baseIsDefaultBranch?: boolean,
		includeAggregatedPRs?: boolean)
		: { [label: string]: string | string[] | number } => {
		const query: { [label: string]: string | string[] | number } = {};
		query.chart = type;
		query.interval = interval;
		query.timezone = timezone;
		query.dateRange = dateRange;

		if (debug ?? false) {
			query.debug = "true";
		}

		const dimensions = this.generateDimensionsMap(type, teamIds, dataContributorIds, boardId,
			projectIds, repositoryIds, acumenTaskTypes, excludeDraftPrs, baseIsDefaultBranch, includeAggregatedPRs);

		query["dimensions[]"] = this.cleanDimensionsData(dimensions);

		return query;
	}

	private generateDimensionsMap = (type: ChartType, teamIds: string[] = [], dataContributorIds: string[] = [],
		boardId: string[] = [], projectIds: string[] = [], repositoryIds: string[] = [],
		acumenTaskTypes: AcumenTaskType[] = [], excludeDraftPrs?: boolean, baseIsDefaultBranch?: boolean,
		includeAggregatedPRs?: boolean
	): Record<string, string[]> => {
		const dims: Record<string, string[]> = {};
		switch (type) {
			case ChartType.PRCycleTime: {
				dims[`team_id`] = teamIds;

				dims[`data_contributor_id`] = dataContributorIds;
				dims[`project_id`] = projectIds;
				dims[`repository_id`] = repositoryIds;
				dims[`sprint_board_id`] = boardId;
				dims[`acumen_task_type`] = acumenTaskTypes;

				if (excludeDraftPrs !== undefined) {
					dims[`is_draft`] = excludeDraftPrs ? [false.toString()] : [true.toString(), false.toString()];
				}

				if (baseIsDefaultBranch !== undefined) {
					dims[`base_is_default_branch`] = baseIsDefaultBranch ? [true.toString()] : [true.toString(), false.toString()];
				}

				if (includeAggregatedPRs !== undefined) {
					dims[`is_aggregated_pr`] = includeAggregatedPRs ? [true.toString(), false.toString()] : [false.toString()];
				}

				break;
			}
			default:
				throw new Error(`Unsupported chart ${type}`);

		}

		return dims;
	}

	private cleanDimensionsData = (dimensions: { [dim: string]: string[] }): string[] => {
		return _.chain(dimensions)
			.pickBy(x => x.length > 0)
			.map((val, key) => `${key}=${this.extractIds(val!).join(",")}`)
			.value();
	}

	private extractIds = (dimensionValueArray: string[]): string[] => {
		return dimensionValueArray.map(idAndTypeTuple => {
			const [id, ] = idAndTypeTuple.split(TUPLE_SEPARATOR);
			return id;
		});
	}

	private fetchChartData = async (type: ChartType, interval: MetricInterval, dateRange: DateRangeType,
		timezone: string, teamIds: string[] = [], dataContributorIds: string[] = [],
		boardId: string[] = [], projectIds: string[] = [], repositoryIds: string[] = [],
		acumenTaskTypes: AcumenTaskType[] = [], excludeDraftPrs?: boolean, baseIsDefaultBranch?: boolean,
		debug: boolean = false, includeAggregatedPRs?: boolean): Promise<IDashboardResponse<IChartResponse>> => {
		const query = this.generateQueryString(type, interval, dateRange, timezone, debug, teamIds,
			dataContributorIds, boardId, projectIds, repositoryIds, acumenTaskTypes, excludeDraftPrs,
			baseIsDefaultBranch, includeAggregatedPRs);

		const res = await getData<IChartResponse, any>(
			this.createCustomerEntityRoute(`${urlJoin(CHARTS_ROUTE, "data")}?${qsStringify(query)}`),
			this.token,
			this.tokenType
		);

		if (res === null) {
			return { data: {} };
		}

		return res;
	}

	public fetchPRCycleBreakdown = async (interval: MetricInterval, startTime: Date, endTime: Date,
		timezone: string, dimensions: string[]) => {

		const prCycleWIPMetricParam = this.prCycleMetricParam(interval, startTime, endTime, timezone, dimensions, PR_CYCLE_WIP_STATUS);
		const prCycleAwaitingReviewMetricParam = this.prCycleMetricParam(interval, startTime, endTime, timezone, dimensions, PR_CYCLE_AWAITING_REVIEW_STATUS);
		const prCycleInReviewMetricParam = this.prCycleMetricParam(interval, startTime, endTime, timezone, dimensions, PR_CYCLE_IN_REVIEW_STATUS);
		const prCycleReviewedMetricParam = this.prCycleMetricParam(interval, startTime, endTime, timezone, dimensions, PR_CYCLE_REVIEWED_STATUS);
		const mergedPRsMetricParam = this.metricParam(AcumenMetricGroupType.GitHubPullRequestCount, "merged", interval, startTime, endTime, timezone, dimensions);
		const metricsParams: IDashboardAcumenMetricDataRequest[] = [
			mergedPRsMetricParam, prCycleWIPMetricParam, prCycleAwaitingReviewMetricParam,
			prCycleInReviewMetricParam, prCycleReviewedMetricParam
		];
		const response = await postData<any, IDashboardAcumenMetricDataResponse[], any>(
			this.createCustomerEntityRoute(MULTI_METRICS_ROUTE),
			this.token,
			{ metricsParams },
			this.tokenType
		);
		if (!response || !response.data) {
			return null;
		}

		const mergedPrsData = response.data.filter(m => m.metric === AcumenMetricGroupType.GitHubPullRequestCount);
		if (mergedPrsData.length !== 1) {
			return null;
		}
		const mergedPRMetricData = this.metricChartIntoValues(mergedPrsData[0]);

		const prCodingData = response.data.filter(m => m.metric === AcumenMetricGroupType.GitHubPullRequestCycleSum &&
			m.filter && m.filter.pull_request_status && _.difference(m.filter!.pull_request_status, PR_CYCLE_WIP_STATUS).length === 0);
		const prCycleWIPMetricData = this.metricChartIntoValues(prCodingData[0], SECONDS_TO_HOURS_MULTIPLIER);

		const prAwaitingReviewData = response.data.filter(m => m.metric === AcumenMetricGroupType.GitHubPullRequestCycleSum &&
			m.filter && m.filter.pull_request_status && _.difference(m.filter!.pull_request_status, PR_CYCLE_AWAITING_REVIEW_STATUS).length === 0);
		const prCycleAwaitingReviewMetricData = this.metricChartIntoValues(prAwaitingReviewData[0], SECONDS_TO_HOURS_MULTIPLIER);

		const prReviewData = response.data.filter(m => m.metric === AcumenMetricGroupType.GitHubPullRequestCycleSum &&
			m.filter && m.filter.pull_request_status && _.difference(m.filter!.pull_request_status, PR_CYCLE_IN_REVIEW_STATUS).length === 0);
		const prCycleInReviewMetricData = this.metricChartIntoValues(prReviewData[0], SECONDS_TO_HOURS_MULTIPLIER);

		const prPendingMergeData = response.data.filter(m => m.metric === AcumenMetricGroupType.GitHubPullRequestCycleSum &&
			m.filter && m.filter.pull_request_status && _.difference(m.filter!.pull_request_status, PR_CYCLE_REVIEWED_STATUS).length === 0);
		const prCycleReviewedMetricData = this.metricChartIntoValues(prPendingMergeData[0], SECONDS_TO_HOURS_MULTIPLIER);

		const prCycleWIPMetric = this.metricIntoComparable(prCycleWIPMetricData, mergedPRMetricData);
		const prCycleAwaitingReviewMetric = this.metricIntoComparable(prCycleAwaitingReviewMetricData, mergedPRMetricData);
		const prCycleInReviewMetric = this.metricIntoComparable(prCycleInReviewMetricData, mergedPRMetricData);
		const prCycleReviewedMetric = this.metricIntoComparable(prCycleReviewedMetricData, mergedPRMetricData);

		const totalCurrentPeriodValue =
			prCycleWIPMetric.currentPeriodValue +
			prCycleAwaitingReviewMetric.currentPeriodValue +
			prCycleInReviewMetric.currentPeriodValue +
			prCycleReviewedMetric.currentPeriodValue;

		const totalPreviousPeriodValue =
			prCycleWIPMetric.previousPeriodValue +
			prCycleAwaitingReviewMetric.previousPeriodValue +
			prCycleInReviewMetric.previousPeriodValue +
			prCycleReviewedMetric.previousPeriodValue;
		const totalChangeFromPreviousPeriod =
			(totalCurrentPeriodValue === totalPreviousPeriodValue ? 0 :
				(totalPreviousPeriodValue === 0 ? 0 : (totalCurrentPeriodValue / totalPreviousPeriodValue - 1)));

		const totalReaction = (totalChangeFromPreviousPeriod === 0 ? DashboardTeamReportMetricChangeReaction.Neutral :
			(totalChangeFromPreviousPeriod < 0) ?
				DashboardTeamReportMetricChangeReaction.Positive : DashboardTeamReportMetricChangeReaction.Negative);

		const prCycleTotalMetric: IDashboardTeamReportMetric = {
			currentPeriodValue: totalCurrentPeriodValue,
			previousPeriodValue: totalPreviousPeriodValue,
			changeFromPreviousPeriod: totalChangeFromPreviousPeriod,
			reaction: totalReaction
		};

		return {
			data: {
				prCycleWIPMetric,
				prCycleAwaitingReviewMetric,
				prCycleInReviewMetric,
				prCycleReviewedMetric,
				prCycleTotalMetric,
			}
		};
	}

	public fetchIssueStatusTimeSpentByType = async (interval: MetricInterval, startTime: Date, endTime: Date,
		timezone: string, dimensions: string[], issueStatusNames: string[]) => {
		const metricsParams: IDashboardAcumenMetricDataRequest[] = [];

		issueStatusNames.forEach(issueStatusName => {
			metricsParams.push(this.issueStatusTimeSpentByTypeParam(interval, startTime, endTime, timezone, dimensions, issueStatusName));
		});

		const response = await postData<any, IDashboardAcumenMetricDataResponse[], any>(
			this.createCustomerEntityRoute(MULTI_METRICS_ROUTE),
			this.token,
			{ metricsParams },
			this.tokenType
		);
		if (!response || !response.data) {
			return null;
		}

		return response;
	}

	public exportPRCycleChart = async (format: DashboardExportFormat, interval: MetricInterval, dateRange: DateRangeType,
		timezone: string, dataContributorIds: string[] = [], repositoryIds: string[] = [],
		includeDraftPRs?: boolean, includeAggregatedPRs?: boolean, includeInternalPRs?: boolean) => {

		const query = this.generateQueryString(ChartType.PRCycleTime, interval, dateRange, timezone, false, [],
			dataContributorIds, [], [], repositoryIds, undefined, !includeDraftPRs, !includeInternalPRs, includeAggregatedPRs);
		query.format = format;

		return getFile(
			this.createCustomerEntityRoute(`${urlJoin(CHARTS_ROUTE, "export")}?${qsStringify(query)}`),
			this.token,
			"POST"
		);
	}

	private prCycleMetricParam(interval: MetricInterval, startTime: Date, endTime: Date,
		timezone: string, dimensions: string[], prStatus: AcumenPullRequestStatusWithWorkStart[]): IDashboardAcumenMetricDataRequest {
		const extraDimensions = [`pull_request_status=${prStatus.join(TUPLE_SEPARATOR)}`];
		extraDimensions.push(...dimensions);
		return this.metricParam(AcumenMetricGroupType.GitHubPullRequestCycleSum, "merged", interval, startTime, endTime, timezone, extraDimensions);
	}

	private issueStatusTimeSpentByTypeParam(interval: MetricInterval, startTime: Date, endTime: Date,
		timezone: string, dimensions: string[], issueStatusName: string) {
		const extraDimensions = [`issue_status_name=${issueStatusName}`];
		extraDimensions.push(...dimensions);
		return this.metricParam(AcumenMetricGroupType.JiraIssueStatusTimeSpentSumSeconds, "cycle_end",
			interval, startTime, endTime, timezone, extraDimensions, "issue_type_name");
	}

	private metricParam(metric: AcumenMetricGroupType, label: string, interval: MetricInterval,
		startTime: Date, endTime: Date, timezone: string, dimensions: string[], groupBy: string | null = null): IDashboardAcumenMetricDataRequest {
		return {
			metricsName: metric,
			label,
			interval,
			startTime: moment.tz(startTime, timezone).toISOString(true),
			endTime: moment.tz(endTime, timezone).toISOString(true),
			timezone,
			dimensions,
			groupBy
		};
	}

	private metricChartIntoValues(data: IDashboardAcumenMetricDataResponse, divider: number = 1): number[] {
		if (data) {
			const categoryMetricValues = Object.values(data.values);
			return Object.keys(categoryMetricValues[0])
				.map(dateAsString => {
					const v = categoryMetricValues[0][dateAsString];
					if (!v) {
						return v;
					}
					return v / divider;
				})
				.filter((i): i is NonNullable<typeof i> => i !== null);
		}

		return [];
	}

	private splitMetricValuesIntoPeriods(values: number[]) {
		if (values.length <= 1) {
			return {
				previousPeriodValues: [0],
				currentPeriodValues: values
			};
		}

		let targetLength = values.length;
		if ((targetLength % 2) === 1) {
			targetLength--;
		}

		return {
			previousPeriodValues: values.slice(0, targetLength / 2).filter(v => !isNaN(v)),
			currentPeriodValues: values.slice(targetLength / 2).filter(v => !isNaN(v))
		};
	}

	private metricIntoComparable(sumByValues: number[], divideByValues: number[]): IDashboardTeamReportMetric {
		const { previousPeriodValues: sumByValuesPreviousPeriod, currentPeriodValues: sumByValuesCurrentPeriod} = this.splitMetricValuesIntoPeriods(sumByValues);
		const { previousPeriodValues: divideByValuesPreviousPeriod, currentPeriodValues: divideByValuesCurrentPeriod} = this.splitMetricValuesIntoPeriods(divideByValues);

		const sumByPreviousSum = _.sum(sumByValuesPreviousPeriod);
		const sumByCurrentSum = _.sum(sumByValuesCurrentPeriod);

		const divideByPreviousSum = _.sum(divideByValuesPreviousPeriod);
		const divideByCurrentSum = _.sum(divideByValuesCurrentPeriod);

		const previousPeriodValue = divideByPreviousSum > 0 ? sumByPreviousSum / divideByPreviousSum : 0;
		const currentPeriodValue = divideByCurrentSum > 0 ? sumByCurrentSum / divideByCurrentSum : 0;

		const changeFromPreviousPeriod = (currentPeriodValue === previousPeriodValue ? 0 :
			(previousPeriodValue === 0 ? 0 : (currentPeriodValue / previousPeriodValue - 1)));

		const reaction = (changeFromPreviousPeriod === 0
			? DashboardTeamReportMetricChangeReaction.Neutral
			: (changeFromPreviousPeriod < 0)
				? DashboardTeamReportMetricChangeReaction.Positive : DashboardTeamReportMetricChangeReaction.Negative);

		const metric: IDashboardTeamReportMetric = {
			currentPeriodValue,
			previousPeriodValue,
			changeFromPreviousPeriod,
			reaction
		};

		return metric;
	}
}
