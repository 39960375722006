import * as qs from "querystring";

type QSInput = string | number | boolean | ReadonlyArray<string> | ReadonlyArray<number> | ReadonlyArray<boolean> | null;

// NOTE: Due to the fact that in case of an object with an empty array,
// qs.stringify() returns empty string and not object's name with an empty array, we assign it with a single empty string.
// for example: qs.stringify({"taskIds", []}) returns "" instead of "taskIds=[]" (ACM-4481)
export function qsStringify(
	obj: NodeJS.Dict<QSInput | Date>): string {
	const keys = Object.keys(obj);

	keys.forEach((key) => {
		const valuesOfKey = obj[key];

		if (Array.isArray(valuesOfKey) && valuesOfKey.length === 0) {
			obj[key] = [""];
		}

		if (valuesOfKey instanceof Date) {
			obj[key] = valuesOfKey.toISOString();
		}

	});

	return qs.stringify(obj as NodeJS.Dict<QSInput>);
}
