import React, { useCallback } from "react";
import "./tab.scss";
import classNames from "classnames";

export interface TabProps {
	name: string;
	title?: string;
	isActive?: boolean;
	onClick: (name: string) => void;
}

export const Tab = (props: TabProps) => {
	const { name, title, isActive, onClick } = props;
	const className = classNames("tab", isActive && "active");
	const handleClick = useCallback(() => onClick(name), [name, onClick]);

	return (
		<button className={className} onClick={handleClick}>
			{title ?? name}
		</button>
	);
};
