import { DeveloperBadge, DeveloperBadgeMetric } from "@acumen/database-types";
import _ from "lodash";
import React, { useMemo } from "react";
import { DeveloperViewModel } from "../../types";
import { DeveloperMetricBadgeFilterItem } from "./developer-metric-badge-filter-item/developer-metric-badge-filter-item";
import "./developer-metric-badge-filter.scss";

export enum DevStatsFilterType {
	TopContributor = "TopContributor",
	LowVelocity = "LowVelocity",
	HeavyLifter = "HeavyLifter",
	TopReviewer = "TopReviewer",
	FewerReviews = "FewerReviews",
	EfficientCoder = "EfficientCoder",
	LargePRs = "LargePRs",
	LongCycleTime = "LongCycleTime"
}

const FILTER_TYPE_TO_LABEL: Record<DevStatsFilterType, string> = {
	[DevStatsFilterType.TopContributor]: "Top contributor",
	[DevStatsFilterType.LowVelocity]: "Low velocity",
	[DevStatsFilterType.HeavyLifter]: "Heavy lifter",
	[DevStatsFilterType.TopReviewer]: "Top reviewer",
	[DevStatsFilterType.FewerReviews]: "Fewer reviews",
	[DevStatsFilterType.EfficientCoder]: "Efficient coder",
	[DevStatsFilterType.LargePRs]: "Large PRs",
	[DevStatsFilterType.LongCycleTime]: "Long cycle times",
};

export function filterDevelopersByType(developers: DeveloperViewModel[], filter: DevStatsFilterType | undefined): DeveloperViewModel[] {
	if (!filter) {
		return developers;
	}

	return _.filter(developers, d => {
		const badges = d.badges.metricBadges;
		if (!badges) {
			return false;
		}
		switch (filter) {
			case DevStatsFilterType.TopContributor: {
				const b = badges[DeveloperBadgeMetric.Velocity];
				return (b !== null && b.badge === DeveloperBadge.SuperStar);
			}
			case DevStatsFilterType.LowVelocity: {
				const b = badges[DeveloperBadgeMetric.Velocity];
				return (b !== null && b.badge === DeveloperBadge.NeedAttention);
			}
			case DevStatsFilterType.HeavyLifter: {
				const b = badges[DeveloperBadgeMetric.HighPriorityTasks];
				return (b !== null && (
					b.badge === DeveloperBadge.SuperStar
					|| b.badge === DeveloperBadge.TeamPlayer));
			}
			case DevStatsFilterType.TopReviewer: {
				const b = badges[DeveloperBadgeMetric.ReviewedPRs];
				return (b !== null && (
					b.badge === DeveloperBadge.SuperStar
					|| b.badge === DeveloperBadge.TeamPlayer));
			}
			case DevStatsFilterType.FewerReviews: {
				const b = badges[DeveloperBadgeMetric.ReviewedPRs];
				return (b !== null && b.badge === DeveloperBadge.NeedAttention);
			}
			case DevStatsFilterType.EfficientCoder: {
				const b1 = badges[DeveloperBadgeMetric.PRCycleTimeWIPAndReview];
				const b2 = badges[DeveloperBadgeMetric.PRSize];
				return ((b1 !== null && b1.badge === DeveloperBadge.SuperStar) ||
					(b2 !== null && b2.badge === DeveloperBadge.SuperStar));
			}
			case DevStatsFilterType.LargePRs: {
				const b = badges[DeveloperBadgeMetric.PRSize];
				return (b !== null && b.badge === DeveloperBadge.NeedAttention);
			}
			case DevStatsFilterType.LongCycleTime: {
				const b = badges[DeveloperBadgeMetric.PRCycleTimeWIPAndReview];
				return (b !== null && b.badge === DeveloperBadge.NeedAttention);
			}
			default:
			 	return false;
		}
	});
}

export interface IDeveloperMetricBadgeFilterProps {
	developers: DeveloperViewModel[];
	selectedFilterItem: DevStatsFilterType | undefined;
	onSelectedFilterItem: (filter: DevStatsFilterType | undefined) => void;
}

export const DeveloperMetricBadgeFilter = (props: IDeveloperMetricBadgeFilterProps) => {
	const { onSelectedFilterItem, selectedFilterItem, developers } = props;
	const filterToValue = useMemo<Record<DevStatsFilterType, number>>(() => {
		const mapping: Record<DevStatsFilterType, number> = {
			[DevStatsFilterType.TopContributor]: 0,
			[DevStatsFilterType.LowVelocity]: 0,
			[DevStatsFilterType.HeavyLifter]: 0,
			[DevStatsFilterType.TopReviewer]: 0,
			[DevStatsFilterType.FewerReviews]: 0,
			[DevStatsFilterType.EfficientCoder]: 0,
			[DevStatsFilterType.LargePRs]: 0,
			[DevStatsFilterType.LongCycleTime]: 0,
		};
		developers.forEach(d => {
			const badges = d.badges.metricBadges;
			if (!badges) {
				return;
			}
			const vBadge = badges[DeveloperBadgeMetric.Velocity];
			if (vBadge !== null) {
				if (vBadge.badge === DeveloperBadge.SuperStar) {
					mapping.TopContributor += 1;
				} else if (vBadge.badge === DeveloperBadge.NeedAttention) {
					mapping.LowVelocity += 1;
				}
			}
			const hptBadge = badges[DeveloperBadgeMetric.HighPriorityTasks];
			if (hptBadge !== null &&
				(hptBadge.badge === DeveloperBadge.SuperStar || hptBadge.badge === DeveloperBadge.TeamPlayer)) {
				mapping.HeavyLifter += 1;
			}
			const rpBadge = badges[DeveloperBadgeMetric.ReviewedPRs];
			if (rpBadge !== null) {
				if (rpBadge.badge === DeveloperBadge.SuperStar || rpBadge.badge === DeveloperBadge.TeamPlayer) {
					mapping.TopReviewer += 1;
				} else if (rpBadge.badge === DeveloperBadge.NeedAttention) {
					mapping.FewerReviews += 1;
				}
			}
			const prsBadge = badges[DeveloperBadgeMetric.PRSize];
			const prctBadge = badges[DeveloperBadgeMetric.PRCycleTimeWIPAndReview];
			if ((prsBadge !== null && prsBadge.badge === DeveloperBadge.SuperStar) ||
				(prctBadge !== null && prctBadge.badge === DeveloperBadge.SuperStar)) {
				mapping.EfficientCoder += 1;
			}

			if (prsBadge !== null && prsBadge.badge === DeveloperBadge.NeedAttention) {
				mapping.LargePRs += 1;
			}
			if (prctBadge !== null && prctBadge.badge === DeveloperBadge.NeedAttention) {
				mapping.LongCycleTime += 1;
			}
		});
		return mapping;
	}, [developers]);

	return (
		<div className="developer-metric-badge-filter">
			{Object.keys(DevStatsFilterType).map((t, index) => {
				const type = t as DevStatsFilterType;
				const name = FILTER_TYPE_TO_LABEL[type];
				const value = filterToValue ? filterToValue[type] : 0;
				const isSelected = selectedFilterItem !== undefined && selectedFilterItem === type;
				return <DeveloperMetricBadgeFilterItem
					key={`developer-metric-badge-filter-item-${index}`}
					name={name}
					value={value}
					isSelected={isSelected}
					onClick={() => {
						if (!isSelected) {
							onSelectedFilterItem(type);
						} else {
							onSelectedFilterItem(undefined);
						}
					}} />;
			})}
		</div>
	);
};
