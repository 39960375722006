import { IDashboardAcumenMetricDataResponse, TaskEstimationMethod } from "@acumen/dashboard-common";
import assert from "assert";
import _ from "lodash";
import moment from "moment";

interface IMetricValue {
	current: number;
	previous: number;
}

export type IdToMetricValues = Map<string, IMetricValue>;

export const SECONDS_TO_HOURS_MULTIPLIER = 3600;
export const SECONDS_TO_DAYS_MULTIPLIER = 86400;
export const DAYS_IN_SINGLE_PERIOD = 30;

const getDividerByEstimationMethod = (estimationMethod: TaskEstimationMethod | undefined): number | undefined => {
	if (estimationMethod === TaskEstimationMethod.TimeBased) {
		return SECONDS_TO_HOURS_MULTIPLIER;
	}
};

export const convertMetricValuesToPeriodicalValues = (metricData: IDashboardAcumenMetricDataResponse): IdToMetricValues => {
	const dcIdToMetricValueByPeriod: IdToMetricValues = new Map();

	Object.entries(metricData.values).forEach(([dcIdAsString, valuesByDc]) => {
		if (!dcIdAsString || dcIdAsString === "null") {
			return;
		}

		dcIdToMetricValueByPeriod.set(dcIdAsString, {
			current: valuesByDc[0] || 0,
			previous: valuesByDc[-1] || 0,
		});
	});

	return dcIdToMetricValueByPeriod;
};

export const dividePeriodicalMetricValues = (
	dcIdToMetricValueByPeriod: IdToMetricValues, divider: number
): IdToMetricValues => {
	assert(divider !== 0, "Got 0 as a divider");

	const dividedMetricValues: IdToMetricValues = new Map();

	for (const [dcId, metricValues] of Array.from(dcIdToMetricValueByPeriod.entries())) {
		dividedMetricValues.set(dcId, {
			current: metricValues.current / divider,
			previous: metricValues.previous / divider,
		});
	}

	return dividedMetricValues;
};

export const convertPeriodicalMetricValuesByEstimationMethod = (
	metricData: IDashboardAcumenMetricDataResponse, estimationMethod?: TaskEstimationMethod
): IdToMetricValues => {
	const convertedValues = convertMetricValuesToPeriodicalValues(metricData);
	const divider = getDividerByEstimationMethod(estimationMethod);

	if (divider) {
		return dividePeriodicalMetricValues(convertedValues, divider);
	}

	return convertedValues;
};

const divideTwoPeriodicalMetricValues = (
	numeratorMetricValues: IdToMetricValues,
	denominatorMetricValues: IdToMetricValues,
	manipulatorFunc?: (n: number) => number,
): IdToMetricValues => {
	const resultMap: IdToMetricValues = new Map();
	denominatorMetricValues.forEach((divideByDcValues, dc) => {
		const sumByPreviousValue = numeratorMetricValues.get(dc)!.previous;
		const sumByCurrentValue = numeratorMetricValues.get(dc)!.current;

		let current = (divideByDcValues.current > 0) ? sumByCurrentValue / divideByDcValues.current : 0;
		let previous = (divideByDcValues.previous > 0) ? sumByPreviousValue / divideByDcValues.previous : 0;

		if (manipulatorFunc) {
			current = manipulatorFunc(current);
			previous = manipulatorFunc(previous);
		}

		resultMap.set(dc, {
			current,
			previous,
		});
	});

	return resultMap;
};

export const calcPeriodicalMetricsAverage = (
	sumByValues: IdToMetricValues, divideByValues: IdToMetricValues
): IdToMetricValues => {
	return divideTwoPeriodicalMetricValues(sumByValues, divideByValues);
};

export const calcPeriodicalMetricsPercentage = (
	numeratorMetricValues: IdToMetricValues, denominatorMetricValues: IdToMetricValues
): IdToMetricValues => {
	return divideTwoPeriodicalMetricValues(numeratorMetricValues, denominatorMetricValues, (n) => n * 100);
};

export const extractSortedPeriodsFromMetric = (metricData: IDashboardAcumenMetricDataResponse): string[] => {
	// detect periods
	const categoryMetricValues = Object.values(metricData.values);
	if (categoryMetricValues.length === 0) {
		return [];
	}

	const periods = Object.keys(categoryMetricValues[0]).sort((key1, key2) => {
		if (key1.includes("-") && key2.includes("-")) {
			// sort by date
			return moment(key1).unix() - moment(key2).unix();
		}

		// sort by numeric period
		return parseInt(key1, 10) - parseInt(key2, 10);
	});

	return periods;
};

export const convertMetricValuesToChartValues = (metricData: IDashboardAcumenMetricDataResponse): number[] => {
	// detect periods
	const categoryMetricValues = Object.values(metricData.values);
	if (categoryMetricValues.length === 0) {
		return [];
	}

	const periods = extractSortedPeriodsFromMetric(metricData);

	let chartValues = _.fill(Array(periods.length), 0);
	const v: number[] = [];

	for (const key of periods) {
		const item = categoryMetricValues[0][key] || 0;
		v.push(item || 0);
	}
	chartValues = Object.assign([], chartValues, v);

	return chartValues;
};

export const divideMetricChartValues = (chartValues: number[], divider: number): number[] => {
	assert(divider !== 0, "Got 0 as a divider");
	return chartValues.map(x => x / divider);
};

export const convertChartMetricValuesByEstimationMethod = (
	metricData: IDashboardAcumenMetricDataResponse, estimationMethod: TaskEstimationMethod | undefined,
): number[] => {
	const convertedValues = convertMetricValuesToChartValues(metricData);
	const divider = getDividerByEstimationMethod(estimationMethod);

	if (divider) {
		return divideMetricChartValues(convertedValues, divider);
	}

	return convertedValues;
};

export const calcTwoMetricsChartValues = (
	sumByValues: number[],
	divideByValues: number[],
	calcFunc: (a: number, b: number) => number,
) => {
	assert(sumByValues.length === divideByValues.length, `Unable to zip - arrays must be of same length (got ${sumByValues} and ${divideByValues})`);
	return _.zip(sumByValues, divideByValues)
		.map(([sumValue, dividerValue]) => (dividerValue! > 0) ? calcFunc(sumValue!, dividerValue!) : 0);
};

export const calcMetricsAverageForChart = (sumByValues: number[], divideByValues: number[]) => {
	return calcTwoMetricsChartValues(sumByValues, divideByValues, (a, b) => a / b);
};

export const calcMetricsPercentForChart = (sumByValues: number[], divideByValues: number[]) => {
	return calcTwoMetricsChartValues(sumByValues, divideByValues, (a, b) => (a / b) * 100);
};
