import validator from "validator";

export function createIsNumericRangeValidator(min: number, max: number) {
	const validationFunc = (val: string) => {
		const isNumeric = validator.isNumeric(val);
		if (isNumeric) {
			const asNum = Number(val);
			return asNum >= min && asNum <= max;
		}
		return false;
	};

	return validationFunc;
}
