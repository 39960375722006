import { getData } from "../../../services/fetch-helpers";
import { BaseCustomerApiClient } from "./base-customer-api-client";
import { IDashboardStatusMapping } from "@acumen/dashboard-common";

export const ROUTE = "status-mapping";

export class StatusMappingApiClient extends BaseCustomerApiClient {
	public fetchAll = async () => {
		return await getData<IDashboardStatusMapping[], any>(
			this.createCustomerEntityRoute(`${ROUTE}`),
			this.token
		);
	}
}
