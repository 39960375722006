import React, { createRef, useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import classNames from "classnames";
import "./tooltip.scss";

export interface TooltipProps {
	text: React.ReactNode;
	position?: "top" | "right" | "bottom" | "left";
	color?: string;
	bold?: boolean;
	followCursorX?: boolean;
	followCursorY?: boolean;
	className?: string;
	children?: React.ReactNode;
}

const Tooltip = (props: TooltipProps) => {
	const { children, text, color, bold, className, position = "bottom", followCursorX, followCursorY } = props;
	const [isVisible, setIsVisible] = useState(false);
	const [cursorX, setCursorX] = useState(0);
	const [cursorY, setCursorY] = useState(0);
	const tooltipRef = useRef<HTMLDivElement>(null);
	const triggerRef = createRef<HTMLDivElement>();
	const tooltipClass = classNames(
		className,
		"app-tooltip",
		bold && "variant-bold",
	);

	useEffect(() => {
		if (isVisible && triggerRef.current && tooltipRef.current) {
			const tooltip = triggerRef.current;
			const container = tooltipRef.current;
			let actualCursorX = cursorX;
			let actualCursorY = cursorY;
			const positionTooltip = (currentCursorX: number, currentCursorY: number) => {
				const triggerRect = tooltip.getBoundingClientRect();
				const tooltipRect = container.getBoundingClientRect();
				const { left, top, width, height } = triggerRect;
				const scrollLeft = window.scrollX;
				const scrollTop = window.scrollY;

				switch (position) {
					case "top":
						container.style.left = `${followCursorX ? currentCursorX - (tooltipRect.width / 2) : left + scrollLeft + width / 2}px`;
						container.style.top = `${followCursorY ? currentCursorY - tooltipRect.height : top + scrollTop - height}px`;
						break;
					case "left":
						container.style.left = `${followCursorX ? currentCursorX - tooltipRect.width : left + scrollLeft - width}px`;
						container.style.top = `${followCursorY ? currentCursorY - (tooltipRect.height / 2) : top + scrollTop + height / 2}px`;
						break;
					case "right":
						container.style.left = `${followCursorX ? currentCursorX + tooltipRect.width : left + scrollLeft + width}px`;
						container.style.top = `${followCursorY ? currentCursorY - (tooltipRect.height / 2) : top + scrollTop + height / 2}px`;
						break;
					case "bottom":
						container.style.left = `${followCursorX ? currentCursorX - (tooltipRect.width / 2) : left + scrollLeft + width / 2}px`;
						container.style.top = `${followCursorY ? currentCursorY + tooltipRect.height : top + scrollTop + height}px`;
						break;
				}
			};

			positionTooltip(actualCursorX, actualCursorY);

			if (followCursorX || followCursorY) {
				const handleMouseMove = (event: MouseEvent) => {
					if (followCursorX) {
						actualCursorX = event.clientX;
					}

					if (followCursorY) {
						actualCursorY = event.clientY;
					}

					positionTooltip(actualCursorX, actualCursorY);
				};

				document.addEventListener("mousemove", handleMouseMove);

				return () => {
					document.removeEventListener("mousemove", handleMouseMove);
				};
			}
		}
	}, [triggerRef, tooltipRef, isVisible, position, cursorX, cursorY, followCursorX, followCursorY]);

	const handleMouseEnter = useCallback((event: React.MouseEvent) => {
		if (text) {
			setCursorX(event.clientX);
			setCursorY(event.clientY);
			setIsVisible(true);
		}
	}, [text]);
	const handleMouseLeave = useCallback(() => setIsVisible(false), []);

	return (
		<>
			<div
				ref={triggerRef}
				className="app-tooltip-trigger-container"
				children={children}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			/>

			{isVisible && (
				createPortal((
					<div ref={tooltipRef} className={tooltipClass} style={{ color }}>
						{text}
					</div>
				), document.body)
			)}
		</>
	);
};

export default Tooltip;
