import { IDashboardTask } from "@acumen/dashboard-common";
import { Avatar } from "../../components/avatar";
import { ExternalImage } from "../../components/external-image";
import React from "react";
import { Popup } from "semantic-ui-react";

// tslint:disable-next-line: variable-name
const TaskIconGroup = (props: { task: IDashboardTask }) => {
	const { task } = { ...props };
	return (
		<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
			<Popup
				size="mini"
				basic={true}
				content={task.type.internalType}
				trigger={
					<ExternalImage src={task.type.iconUrl ?? ""} className="ui micro image" />
				}
			/>
			{(task.priority.internalPriority !== null && task.priority.internalPriority.trim().length > 0) && (
				<Popup
					size="mini"
					basic={true}
					content={task.priority.internalPriority}
					trigger={
						<ExternalImage src={task.priority.iconUrl ?? ""} className="ui micro image" />
					}
				/>
			)}
			<Popup
				size="mini"
				basic={true}
				style={{
					textTransform: "capitalize"
				}}
				content={task.assignee?.primaryDisplayName}
				trigger={(
					<Avatar
						dataContributor={task.assignee}
					/>
				)}
			/>
		</div>
	);
};
export default TaskIconGroup;
