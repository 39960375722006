import React, { useCallback, useMemo, useState } from "react";
import classNames from "classnames";

import {
	DashboardRetroReportTaskData,
	IDashboardTaskStatusChangeResponse,
	TaskEstimationMethod
} from "@acumen/dashboard-common";
import FilterProvider, { FilterFunction } from "../../../providers/filter-provider";
import SortProvider from "../../../providers/sort-provider";
import ControlledTableHeader from "../../../components/controlled-table-header";
import { Formatters } from "../../../components/dashboard-task/formatters";
import { SortableColumn } from "../../../../types/sortable-column";
import {
	getTaskActualCycleTime,
	getTaskAssignee,
	getTaskAssigneeFilterOption,
	getTaskEstimation,
	getTaskPriority,
	getTaskPriorityFilterOption,
	getTaskTitle,
	getTaskTitleFilterOption,
	getTaskType,
	getTaskTypeFilterOption,
} from "./utils";
import {
	useDoneStatuses,
	useNotDoneStatuses,
	useTaskActualCycleTimeFilterOptionGetter,
	useTaskEndStatusFilterOptionGetter,
	useTaskEndStatusGetter,
	useTaskEstimationFilterOptionGetter,
	useTaskStartStatusFilterOptionGetter,
	useTaskStartStatusGetter,
	useTaskStateInSprintFilterOptionGetter,
	useTaskStateInSprintGetter,
} from "./hooks";
import { observer } from "mobx-react";
import { TaskHighlights } from "./enums";
import { isEmpty } from "lodash";
import TableHeader from "../../../components/table-header";

interface TasksTableHeaderProps {
	tasks: DashboardRetroReportTaskData[];
	statusDiff?: IDashboardTaskStatusChangeResponse;
	doneStatuses?: string[];
	estimationMethod?: TaskEstimationMethod;
	sortedColumn: SortableColumn | null;
	isExtended: boolean;
	isFilteringEnabled: boolean;
	taskCategory?: TaskHighlights | null;
	showCycleTimeBreakdownColumn?: boolean;
	onTaskCategoryChange?: (taskCategory: TaskHighlights | null) => void;
	onFiltersChange: (filters: Array<FilterFunction<DashboardRetroReportTaskData>>) => void;
	onSort: (columnName: string, sortBy: (item: DashboardRetroReportTaskData) => unknown) => void;
}

type TasksTableFilterNames =
	| "assignees"
	| "issueTypes"
	| "priorities"
	| "titles"
	| "taskStatesInSprint"
	| "estimations"
	| "actualCycleTimes"
	| "startStatuses"
	| "endStatuses";

type TasksTableFilterState = Partial<Record<TasksTableFilterNames, Set<string | number> | null>>;

const TasksTableHeader = (props: TasksTableHeaderProps) => {
	const {
		tasks,
		statusDiff,
		doneStatuses,
		estimationMethod,
		sortedColumn,
		isExtended,
		isFilteringEnabled,
		taskCategory,
		showCycleTimeBreakdownColumn,
		onTaskCategoryChange,
		onFiltersChange,
		onSort,
	} = props;
	const getTaskStateInSprint = useTaskStateInSprintGetter();
	const getTaskStartStatus = useTaskStartStatusGetter({ isExtended, statusDiff });
	const getTaskEndStatus = useTaskEndStatusGetter({ isExtended, statusDiff });
	const getTaskStateInSprintFilterOption = useTaskStateInSprintFilterOptionGetter();
	const getTaskEstimationFilterOption = useTaskEstimationFilterOptionGetter({ estimationMethod });
	const getTaskActualCycleTimeFilterOption = useTaskActualCycleTimeFilterOptionGetter({ isExtended });
	const getTaskStartStatusFilterOption = useTaskStartStatusFilterOptionGetter({ isExtended, statusDiff });
	const getTaskEndStatusFilterOption = useTaskEndStatusFilterOptionGetter({ isExtended, statusDiff });
	const notDoneStatuses = useNotDoneStatuses({ tasks, doneStatuses, getTaskEndStatus });
	const tasksDoneStatuses = useDoneStatuses({ tasks, doneStatuses, getTaskEndStatus });
	const [filterState, setFilterState] = useState<TasksTableFilterState>({});
	const highlightedFilterState = useTasksTableFilterState({
		taskCategory,
		doneStatuses: tasksDoneStatuses,
		notDoneStatuses,
	});
	const resolvedFilterState = highlightedFilterState ?? filterState;
	const handleFilterSelectionChange = useTasksTableFilterStateChangeHandler({
		doneStatuses: tasksDoneStatuses,
		notDoneStatuses,
		onFilterStateChange: setFilterState,
		onTaskCategoryChange,
	});

	return (
		<tr>
			<FilterProvider isFilteringEnabled={isFilteringEnabled} items={tasks} onFiltersChange={onFiltersChange}>
				<SortProvider sortedColumn={sortedColumn} onSort={onSort}>
					<ControlledTableHeader
						columnName="Assignee"
						className={classNames("left aligned", "icon-column-header icon-cell icon-cell-first")}
						labelClassName="align-center"
						sortBy={getTaskAssignee}
						filterSearchPlaceholder="Search team members..."
						mapItemToFilterOption={getTaskAssigneeFilterOption}
						filterKey="assignees"
						presetFilterSelection={highlightedFilterState}
						filterSelection={resolvedFilterState}
						onFilterSelectionChange={handleFilterSelectionChange}
						children={
							<div className="task-icon-container">
								<img className="task-icon task-icon-large" src="/assets/images/avatar-head.svg" alt=""/>
							</div>
						}
					/>
					<ControlledTableHeader
						columnName="Issue type"
						className={classNames("left aligned", "icon-column-header icon-cell")}
						labelClassName="align-center"
						sortBy={getTaskType}
						filterSearchPlaceholder="Search task types..."
						mapItemToFilterOption={getTaskTypeFilterOption}
						filterKey="issueTypes"
						presetFilterSelection={highlightedFilterState}
						filterSelection={resolvedFilterState}
						onFilterSelectionChange={handleFilterSelectionChange}
						children={
							<div className="task-icon-container">
								<img className="task-icon task-icon-small" src="/assets/images/issue-type-head.svg"
									 alt=""/>
							</div>
						}
					/>
					<ControlledTableHeader
						columnName="Priority"
						className={classNames("left aligned", "icon-column-header icon-cell icon-cell-last")}
						labelClassName="align-center"
						sortBy={getTaskPriority}
						filterSearchPlaceholder="Search priorities..."
						mapItemToFilterOption={getTaskPriorityFilterOption}
						filterKey="priorities"
						presetFilterSelection={highlightedFilterState}
						filterSelection={resolvedFilterState}
						onFilterSelectionChange={handleFilterSelectionChange}
						children={
							<div className="task-icon-container">
								<img className="task-icon task-icon-small" src="/assets/images/priority-head.svg"
									 alt=""/>
							</div>
						}
					/>
					<ControlledTableHeader
						columnName="Title"
						className="title-column-header"
						sortBy={getTaskTitle}
						filterSearchPlaceholder="Search titles..."
						mapItemToFilterOption={getTaskTitleFilterOption}
						filterKey="titles"
						presetFilterSelection={highlightedFilterState}
						filterSelection={resolvedFilterState}
						onFilterSelectionChange={handleFilterSelectionChange}
					/>
					{isExtended && (
						<ControlledTableHeader
							columnName="Planned / Unplanned"
							className="task-state-in-sprint-column-header"
							sortBy={getTaskStateInSprint}
							filterSearchPlaceholder="Search planning statuses..."
							mapItemToFilterOption={getTaskStateInSprintFilterOption}
							filterKey="taskStatesInSprint"
							presetFilterSelection={highlightedFilterState}
							filterSelection={resolvedFilterState}
							onFilterSelectionChange={handleFilterSelectionChange}
						/>
					)}
					<ControlledTableHeader
						columnName={Formatters.getEstimationShortTitle(estimationMethod)}
						className="estimation-column-header"
						sortBy={getTaskEstimation}
						filterSearchPlaceholder="Search estimations..."
						mapItemToFilterOption={getTaskEstimationFilterOption}
						filterKey="estimations"
						presetFilterSelection={highlightedFilterState}
						filterSelection={resolvedFilterState}
						onFilterSelectionChange={handleFilterSelectionChange}
					/>
					<ControlledTableHeader
						columnName="Actual cycle time"
						className="actual-cycle-time-column-header"
						sortBy={getTaskActualCycleTime}
						filterSearchPlaceholder="Search actual cycle times..."
						mapItemToFilterOption={getTaskActualCycleTimeFilterOption}
						filterKey="actualCycleTimes"
						presetFilterSelection={highlightedFilterState}
						filterSelection={resolvedFilterState}
						onFilterSelectionChange={handleFilterSelectionChange}
					/>
					{isExtended && (
						<ControlledTableHeader
							columnName="Start status"
							className="start-status-column-header"
							sortBy={getTaskStartStatus}
							filterSearchPlaceholder="Search start statuses..."
							mapItemToFilterOption={getTaskStartStatusFilterOption}
							filterKey="startStatuses"
							presetFilterSelection={highlightedFilterState}
							filterSelection={resolvedFilterState}
							onFilterSelectionChange={handleFilterSelectionChange}
						/>
					)}
					{isExtended && showCycleTimeBreakdownColumn && (
						<TableHeader
							columnName="Cycle Time Breakdown"
							className="state-progress-bar-column-header"
							sortBy={getTaskActualCycleTime}
							isFilteringEnabledForColumn={false}
							children="State Progress Bar"
						/>
					)}
					{isExtended && (
						<ControlledTableHeader
							columnName="End status"
							className="end-status-column-header"
							sortBy={getTaskEndStatus}
							filterSearchPlaceholder="Search end statuses..."
							mapItemToFilterOption={getTaskEndStatusFilterOption}
							filterKey="endStatuses"
							presetFilterSelection={highlightedFilterState}
							filterSelection={resolvedFilterState}
							onFilterSelectionChange={handleFilterSelectionChange}
						/>
					)}
				</SortProvider>
			</FilterProvider>
		</tr>
	);
};

const useTasksTableFilterState = (props: {
	taskCategory?: TaskHighlights | null;
	doneStatuses: string[] | null;
	notDoneStatuses: string[] | null;
}): TasksTableFilterState | null => {
	const { doneStatuses, notDoneStatuses, taskCategory } = props;

	return useMemo(() => {
		switch (taskCategory) {
			case TaskHighlights.All: {
				return {};
			}

			case TaskHighlights.Completed:
				return { endStatuses: new Set(doneStatuses) };

			case TaskHighlights.NotCompleted:
				return { endStatuses: new Set(notDoneStatuses) };

			case TaskHighlights.AddedMidSprint:
				return { taskStatesInSprint: new Set(["Unplanned"]) };

			case TaskHighlights.CarryOver:
				return { taskStatesInSprint: new Set(["Carry Over"]) };

			default:
				return null;
		}
	}, [taskCategory, doneStatuses, notDoneStatuses]);
};

const useTasksTableFilterStateChangeHandler = (props: {
	doneStatuses: string[] | null;
	notDoneStatuses: string[] | null;
	onFilterStateChange: (filterState: TasksTableFilterState) => void;
	onTaskCategoryChange?: (taskCategory: TaskHighlights | null) => void;
}) => {
	const { doneStatuses, notDoneStatuses, onFilterStateChange, onTaskCategoryChange } = props;

	return useCallback((filterState: TasksTableFilterState) => {
		onFilterStateChange(filterState);

		if (!onTaskCategoryChange) {
			return;
		}

		const areBasicFiltersEmpty =
			!filterState.assignees
			&& !filterState.issueTypes
			&& !filterState.priorities
			&& !filterState.titles
			&& !filterState.estimations
			&& !filterState.actualCycleTimes
			&& !filterState.startStatuses;

		const areAllFiltersEmpty =
			areBasicFiltersEmpty
			&& !filterState.taskStatesInSprint
			&& !filterState.endStatuses;

		if (areAllFiltersEmpty) {
			onTaskCategoryChange(TaskHighlights.All);
			return;
		} else if (!areBasicFiltersEmpty || (filterState.endStatuses && filterState.taskStatesInSprint)) {
			onTaskCategoryChange(null);
			return;
		}

		if (filterState.endStatuses && !filterState.taskStatesInSprint) {
			if (!doneStatuses || !notDoneStatuses || isEmpty(doneStatuses) || isEmpty(notDoneStatuses)) {
				return;
			}

			if (Array.from(doneStatuses).every(value => filterState.endStatuses!.has(value)) && notDoneStatuses.every(status => !filterState.endStatuses!.has(status))) {
				onTaskCategoryChange(TaskHighlights.Completed);
			} else if (Array.from(filterState.endStatuses!).every(value => !doneStatuses.includes(value as string)) && notDoneStatuses.every(status => filterState.endStatuses!.has(status))) {
				onTaskCategoryChange(TaskHighlights.NotCompleted);
			} else {
				onTaskCategoryChange(null);
			}
		} else if (filterState.taskStatesInSprint && !filterState.endStatuses) {
			if (filterState.taskStatesInSprint.has("Unplanned") && ["Carry Over", "Planned"].every(state => !filterState.taskStatesInSprint!.has(state))) {
				onTaskCategoryChange(TaskHighlights.AddedMidSprint);
			} else if (filterState.taskStatesInSprint.has("Carry Over") && ["Planned", "Unplanned"].every(state => !filterState.taskStatesInSprint!.has(state))) {
				onTaskCategoryChange(TaskHighlights.CarryOver);
			} else {
				onTaskCategoryChange(null);
			}
		}
	}, [
		doneStatuses,
		notDoneStatuses,
		onFilterStateChange,
		onTaskCategoryChange,
	]);
};

export default observer(TasksTableHeader);
