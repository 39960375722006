import React from "react";
import { IDashboardTeamsReport } from "@acumen/dashboard-common";
import { DEFAULT_COLOR } from "..";
import { ILegendList, ITeamsReportTrendCards, IHoverEffectProps, externalHoverType } from "../particles";

// tslint:disable-next-line: variable-name
const ChartMenuHeader = (props: {
	chartData: IDashboardTeamsReport | undefined,
	chartsLegendList?: ILegendList[]
	hoverEffect: IHoverEffectProps,
	menuItems: ITeamsReportTrendCards[],
}) => {
	const { hoverEffect, menuItems } = { ...props };
	const { hoveredMetric, setHoveredMetric } = { ...hoverEffect };

	return (
		<div className="row charts-card-header underlined-row">
			<div className="ui grid full-height">

				<div className="two wide column headers-tabs"
					onMouseOver={(e) => {
						e.stopPropagation();
						setHoveredMetric(externalHoverType);
					}}
				>
					<div className="flex-row full-height"
						style={{
							backgroundColor: hoveredMetric === externalHoverType ? DEFAULT_COLOR : "inherit",
							color: hoveredMetric === externalHoverType ? "white" : "inherit",
						}}>
						<div className="trend-card header-title-only-tab">
							<p>
								<span className="acu-capitalize medium-text">
									total
								</span>
							</p>
						</div>
					</div>
				</div>

				<div className="seven wide column headers-tabs">
					<div className="flex-row full-height">
						{menuItems.map(
							(columnDetails, i) => {
								return (
									<div
										key={i}
										className={"trend-card header-title-only-tab"}
										onMouseOver={(e) => {
											e.stopPropagation();
											if (columnDetails.metricName) {
												setHoveredMetric(columnDetails.metricName);
											}
										}}
										style={{
											backgroundColor: hoveredMetric === columnDetails.metricName ? columnDetails.color : "inherit",
											color: hoveredMetric === columnDetails.metricName ? "white" : "inherit",
										}}>
										<p className="acu-capitalize">
											{columnDetails.title}
										</p>
									</div>
								);
							})}
					</div>
				</div>
				<div className="seven wide column" />
			</div>
		</div>
	);
};
export default ChartMenuHeader;
