import React, { FormEvent, useState } from "react";
import { Radio, CheckboxProps } from "semantic-ui-react";

interface IRadioGroupProps {
	buttons: IRadioButton[];
	handleChange: (key: string) => void;
	label?: string;
	selectedValue?: string;
	disabled?: boolean;
	className?: string;
}

interface IRadioButton {
	text: string;
	key: string;
}

// tslint:disable-next-line: variable-name
const RadioGroup = (props: IRadioGroupProps) => {
	const { buttons, label, handleChange, selectedValue, disabled } = { ...props };
	const [selected, setSelected] = useState(selectedValue);
	const onToggle = (e: FormEvent<HTMLInputElement>, { value }: CheckboxProps) => {
		if (value) {
			setSelected(value.toString());
			handleChange(value.toString());
		}
	};

	const getRadioButton = (button: IRadioButton, i: number) => {
		return (
			<div className="field" key={i}>
				<Radio
					label={button.text}
					name={button.text}
					value={button.key}
					key={button.key}
					checked={button.key === selected}
					onChange={onToggle}
					disabled={disabled}
				/>
			</div>
		);
	};

	return (
		<>
			<div
				className="ui radio checkbox flex-column"
			>
				<label>{label}</label>
				{buttons.map((b, i) => getRadioButton(b, i))}
			</div>
		</>
	);
};
export default RadioGroup;
