import classNames from "classnames";
import React, { useEffect, useState } from "react";

export enum SaveOnStates {
	OnBlur,
	OnChange,
}
// tslint:disable-next-line: variable-name
const StringInput = (
	props: {
		label: string;
		name: string;
		defaultValue: string | undefined | null;
		placeholder?: string;
		registerInput: any;
		disabled?: boolean;
		required?: boolean;
		onError: (str: string) => void;
		onSave: (val: string) => void;
		showRequired?: boolean;
		saveOnState?: SaveOnStates;
	}
) => {
	const {
		label, name: _name, defaultValue, registerInput, disabled = false, placeholder,
		required = false, onSave, showRequired = false, saveOnState = SaveOnStates.OnChange } = { ...props };
	const onTriggerSave = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target ? e.target.value : "";
		setIsRequired(value);
		onSave(value);
	};
	const [isRequired, setIsRequired] = useState<string | undefined>(undefined);

	useEffect(() => {
		setIsRequired(defaultValue ?? undefined);
		return () => setIsRequired(undefined);
	}, []);

	return (
		<>
			{label && <label className="flex-spaced">{label}
				{(showRequired || (isRequired === "")) && <span className="message required">
					<i className="tiny icon asterisk" style={{ verticalAlign: "text-top" }} />required</span>}
			</label>}
			<input
				type="text"
				ref={registerInput()}
				name={_name}
				defaultValue={defaultValue || ""}
				placeholder={placeholder || ""}
				autoComplete="off"
				disabled={disabled}
				required={required}
				className={classNames("form-control is-valid", { required: isRequired === "" })}
				onBlur={onTriggerSave}
				onChange={saveOnState === SaveOnStates.OnChange ? onTriggerSave : undefined}
			/>
		</>
	);
};
export default StringInput;
