import {
	Dispatch,
	Fragment,
	ReactElement,
	ReactNode,
	SetStateAction,
	useRef,
} from "react";

import classnames from "classnames";
import { useOnClickOutside } from "usehooks-ts";
import React from "react";

export interface DropdownItem {
	title: string;
	description?: string;
	itemKey: string;
	isDisabled?: boolean;
	isDanger?: boolean;
	link?: string;
}

export interface DropdownProps {
	open: boolean;
	toggle?: Dispatch<SetStateAction<boolean>>;
	onClickItem?: (itemKey: string) => void;
	button: ReactNode;
	items?: DropdownItem[];
}
function Dropdown(props: DropdownProps): ReactElement {
	const ref = useRef(null);

	const handleToggleOff = () => {
		if (props.open && props.toggle) {
			props.toggle(false);
		}
	};

	useOnClickOutside(ref, handleToggleOff);

	return (
		<Fragment>
			<div className="inline-block relative antialiased">
				{props.button}
				<ul
					ref={ref}
					className={`${props.open
						? "absolute z-50 transition duration-200 ease mt-1 border border-[#949DAC] bg-[#FFFEFE] my-22 -right-20 w-64 rounded drop-shadow-lg overflow-hidden"
							: "hidden"
						}`}
					role="menu"
					aria-orientation="vertical"
					aria-labelledby="options-menu"
				>
					{props.items?.map((item, index) => {
						return (
							<li
								key={index}
								className={classnames(
									"block py-2 px-4 whitespace-no-wrap text-base",
									{
										"cursor-pointer hover:bg-[#EBEBEB] hover:text-[#1B3F7B]":
											item.isDisabled === undefined ||
											item.isDisabled === false,
									},
									{
										"text-[#8791A2] cursor-not-allowed":
											item.isDisabled === true,
									},
									{
										"text-[#230B33]":
											(item.isDisabled === undefined ||
												item.isDisabled === false) &&
											(item.isDanger === undefined || item.isDanger === false),
									},
									{
										"text-[#FF1F38]":
											(item.isDisabled === undefined ||
												item.isDisabled === false) && item.isDanger === true,
									}
								)}
								onClick={() => {
									if (item.isDisabled === true) {
										return;
									}
									if (props.onClickItem) {
										props.onClickItem(item.itemKey);
									}
									handleToggleOff();
								}}
							>
								<span className="flex flex-col">
									<span
										className={classnames({
											"font-bold": item.description !== undefined,
										})}
									>
										{item.link && (
											<a href={item.link} target="_blank" rel="noreferrer">
												{item.title}
											</a>
										)}
										{!item.link && item.title}
									</span>
									{item.description && <span>{item.description}</span>}
								</span>
							</li>
						);
					})}
				</ul>
			</div>
		</Fragment>
	);
}

export default Dropdown;
