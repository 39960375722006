import { IPlanningPokerVote } from "@acumen/dashboard-common";
import classnames from "classnames";
import React from "react";
import PlayerDoneCheckmark from "../svg-assets/PlayerDoneCheckmark";
import {
	getInvitePlayerAvatar,
	InvitePlayerAvatar,
} from "./InvitePlayerAvatar";
import { getPlayerAvatar } from "./PlayerAvatar";

export enum PlayerCardNamePosition {
	Top = "Top",
	Bottom = "Bottom",
}
export interface PlayerCardProps {
	playerName: string;
	playerVote?: IPlanningPokerVote | undefined;
	isCurrentPlayer: boolean;
	showVote: boolean;
	namePosition: PlayerCardNamePosition;
	isDisabled: boolean;
	isFakeUser: boolean;
	fakeUserAvatar?: InvitePlayerAvatar;
	isOnline: boolean;
	isCompact: boolean;
}

const PlayerCard: React.FC<PlayerCardProps> = ({
	showVote,
	playerName,
	playerVote,
	isCurrentPlayer,
	namePosition,
	isDisabled,
	isFakeUser,
	fakeUserAvatar,
	isOnline,
	isCompact
}) => {
	const isCardDisabled = isDisabled || isFakeUser || (!isCurrentPlayer && !isOnline);
	return (
		<div className="flex flex-col items-center justify-center m-4 inline-block">
			{namePosition === PlayerCardNamePosition.Top && (
				<h4
					className={classnames(
						"text-center mb-2 truncate",
						{
							"text-sm w-24": isCompact
						},
						{
							"text-base w-32": !isCompact
						},
						{
							"text-[#399CFF]": isCurrentPlayer && !isCardDisabled,
						},
						{
							"text-[#399CFF]/30": isCurrentPlayer && isCardDisabled,
						},
						{
							"text-[#1B3F7B]": !isCurrentPlayer && !isCardDisabled,
						},
						{
							"text-[#1B3F7B]/30": !isCurrentPlayer && isCardDisabled,
						}
					)}
				>
					{playerName}
				</h4>
			)}
			<div
				className={classnames(
					"relative inline-flex",
					"border",
					"border-2",
					"rounded-lg",
					{
						"w-11 h-14": isCompact
					},
					{
						"w-24 h-28": !isCompact
					},
					{
						"bg-white border-[#399CFF]":
							!showVote && isCurrentPlayer && !isCardDisabled,
					},
					{
						"bg-white border-[#399CFF]/30":
							!showVote && isCurrentPlayer && isCardDisabled,
					},
					{
						"bg-[#399CFF] bg-opacity-30 border-[#1B3F7B]":
							showVote && isCurrentPlayer,
					},
					{
						"bg-white border-[#8791A2]":
							!playerVote &&
							!isCurrentPlayer &&
							!isCardDisabled,
					},
					{
						"bg-white border-[#8791A2]/30":
							!playerVote &&
							!isCurrentPlayer &&
							isCardDisabled,
					},
					{
						"border-[#32C72F]":
							playerVote &&
							!isCurrentPlayer,
					}
				)}
			>
				{playerVote && <span className="absolute -top-2 -right-2 h-5 w-5 flex justify-center items-center items"><PlayerDoneCheckmark /></span>}

				<div className="w-full h-full flex justify-center items-center">
					{!showVote && (
						<div className={classnames(
							{
								"w-6": isCompact
							},
							{
								"w-12": !isCompact
							}
						)}>
							{!isFakeUser && getPlayerAvatar(playerName)}
							{isFakeUser &&
								getInvitePlayerAvatar(
									fakeUserAvatar,
									isDisabled ? "opacity-30" : ""
								)}
						</div>
					)}

					{showVote && (
						<h3 className={classnames(
							"font-bold text-[#1B3F7B]",
							{
								"text-xl": isCompact
							},
							{
								"text-5xl": !isCompact
							}
						)}>
							{playerVote ? playerVote.displayValue : "?"}
						</h3>
					)}
				</div>
			</div>

			{namePosition === PlayerCardNamePosition.Bottom && (
				<h4
					className={classnames(
						"text-center mt-2 truncate",
						{
							"text-sm w-24": isCompact
						},
						{
							"text-base w-32": !isCompact
						},
						{
							"text-[#399CFF]": isCurrentPlayer && !isCardDisabled,
						},
						{
							"text-[#399CFF]/30": isCurrentPlayer && isCardDisabled,
						},
						{
							"text-[#1B3F7B]": !isCurrentPlayer && !isCardDisabled,
						},
						{
							"text-[#1B3F7B]/30": !isCurrentPlayer && isCardDisabled,
						}
					)}
				>
					{playerName}
				</h4>
			)}
		</div>
	);
};

export { PlayerCard };
