import { getData } from "../../../services/fetch-helpers";
import { BaseCustomerApiClient } from "./base-customer-api-client";
import { ConfigurationEntityType, ConfigurationValue, IConfigurationKey } from "@acumen/dashboard-common";
import { qsStringify } from "../../../services/url-routes-helper";

export const CONFIGURATION_ROUTE = "configuration";

export class ConfigurationApiClient extends BaseCustomerApiClient {
	public fetchConfiguration = async <T extends Array<IConfigurationKey<ConfigurationValue>>>(keys: [...T],
		context: { teamId?: string } = {}) => {
		if (keys.length === 0) {
			throw new Error("Unable to issue fetch configuration call without keys");
		}

		const query: Record<string, string | string[]> = {
			"keys[]": keys.map(key => key.name)
		};

		if (context.teamId) {
			query[`context[${ConfigurationEntityType.Team}]`] = context.teamId;
		}

		return await getData<Record<string, ConfigurationValue>, any>(
			this.createCustomerEntityRoute(`${CONFIGURATION_ROUTE}?${qsStringify(query)}`),
			this.token);
	}
}
