import colorScheme, { colorFunction } from "../../pages/my-team/analytics/charts/chart-color-scheme";
import { AcumenTaskType } from "@acumen/dashboard-common";
import _ from "lodash";
import uniqolor from "../../../utils/color";

export interface ISeriesProps {
	seriesName: string;
	seriesColorHex: string;
	sortOrder?: number;
}

export const UNMAPPED_COMPONENT_SERIES_NAME = "Without component";
export const UNMAPPED_PRIORITY_NAME = "N/A";

const normalize = (input: string): string => {
	return input.toLowerCase().replace(/[^a-z]/g, "");
};

const DISPLAY_PROPERTIES: { [key: string]: ISeriesProps } = {
	[normalize(AcumenTaskType.Task)]: {
		sortOrder: 1,
		seriesName: "Task",
		seriesColorHex: colorScheme.issueType.task
	},
	[normalize(AcumenTaskType.Bug)]: {
		sortOrder: 2,
		seriesName: "Bug",
		seriesColorHex: colorScheme.issueType.bug
	},
	[normalize(AcumenTaskType["Sub-Task"])]: {
		sortOrder: 3,
		seriesName: "Sub-Task",
		seriesColorHex: colorScheme.issueType.subTask
	},
	[normalize(AcumenTaskType["New Feature"])]: {
		sortOrder: 4,
		seriesName: "New Feature",
		seriesColorHex: colorScheme.issueType.newFeature
	},
	[normalize(AcumenTaskType["Change Request"])]: {
		sortOrder: 5,
		seriesName: "Change Request",
		seriesColorHex: colorScheme.issueType.changeRequest
	},
	[normalize(AcumenTaskType.Story)]: {
		sortOrder: 6,
		seriesName: "Story",
		seriesColorHex: colorScheme.issueType.story
	},
	[normalize(AcumenTaskType.Epic)]: {
		sortOrder: 7,
		seriesName: "Epic",
		seriesColorHex: colorScheme.issueType.epic
	},
	[normalize(AcumenTaskType.Improvement)]: {
		sortOrder: 8,
		seriesName: "Improvement",
		seriesColorHex: colorScheme.issueType.improvement
	},
	[normalize(AcumenTaskType["Technical Task"])]: {
		sortOrder: 9,
		seriesName: "Technical Task",
		seriesColorHex: colorScheme.issueType.techTask
	},
	[normalize(AcumenTaskType.Support)]: {
		sortOrder: 10,
		seriesName: "Support",
		seriesColorHex: colorScheme.issueType.support
	},
	[normalize(AcumenTaskType.Security)]: {
		sortOrder: 11,
		seriesName: "Security",
		seriesColorHex: colorScheme.issueType.security
	},
	[normalize(AcumenTaskType.Documentation)]: {
		sortOrder: 12,
		seriesName: "Documentation",
		seriesColorHex: colorScheme.issueType.documentation
	},
};

interface ISeriesPropsMakerTime {
	indexOrder?: number;
	legendOrder?: number;
	seriesName: string;
	seriesColorHex: string;
}

const DISPLAY_PROPERTIES_WORK_CLASSIFICATION: { [key: string]: ISeriesPropsMakerTime } = {
	adaptive: {
		indexOrder: 3,
		legendOrder: 1,
		seriesName: "New features",
		seriesColorHex: colorScheme.issueType.newFeature
	},
	corrective: {
		indexOrder: 2,
		legendOrder: 2,
		seriesName: "Bug fixes",
		seriesColorHex: colorScheme.issueType.bug
	},
	perfective: {
		indexOrder: 1,
		legendOrder: 3,
		seriesName: "Refactoring",
		seriesColorHex: colorScheme.issueType.changeRequest
	},
};

export function seriesByTaskName(taskName: string): ISeriesProps {
	const key = normalize(taskName);
	const displayProperties = DISPLAY_PROPERTIES[key];
	if (displayProperties) {
		return displayProperties;
	}

	return {
		seriesName: taskName,
		seriesColorHex: uniqolor(key),
		sortOrder: undefined
	};
}

export function componentColorByName(index?: number, name?: string) {
	if (index === undefined || _.isEqual(name, UNMAPPED_COMPONENT_SERIES_NAME)) {
		return colorFunction.acumenTaskType.Unmapped;
	}
	let nextColor = colorFunction.acumenTaskType.Unmapped;
	if (name) {
		nextColor = uniqolor(name);
	} else if (index < colorFunction.genericScheme.length) {
		nextColor = colorFunction.genericScheme[index];
	}

	return nextColor;
}

export function seriesByComponent(componentId: string, componentsById: Record<string, { id: string, name: string | null }> = {},
	displayIndex: number) {
	let seriesColorHex: string;
	let seriesName: string;

	if (!componentsById[componentId] || !componentsById[componentId].name) {
		seriesColorHex = componentColorByName();
		seriesName = UNMAPPED_COMPONENT_SERIES_NAME;
	} else {
		seriesColorHex = componentColorByName(displayIndex, componentsById[componentId].name ?? undefined);
		seriesName = componentsById[componentId]?.name!;
	}

	return {
		seriesName,
		seriesColorHex,
		sortOrder: undefined
	};
}

export function seriesByWorkClassification(classification: string): ISeriesPropsMakerTime {
	const displayProperties = DISPLAY_PROPERTIES_WORK_CLASSIFICATION[classification];
	if (displayProperties) {
		return displayProperties;
	}

	return {
		seriesName: classification,
		seriesColorHex: uniqolor(classification),
		indexOrder: undefined,
		legendOrder: undefined
	};
}
