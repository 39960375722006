import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Switch, Route, Redirect, RouteComponentProps } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { MixpanelTrackingService } from "../services/mixpanel-tracking-service";
import { Meta } from "./components/Meta";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
import SSOAuthPage from "./pages/sso-auth";
import SSOEmailForm from "./pages/sso-email-form";
import { useStores } from "./mobx-stores";
import queryString from "query-string";

interface ConditionalWrapperProps {
	children: React.ReactElement;
	condition: boolean;
	wrapper: (children: React.ReactElement) => JSX.Element;
}
// tslint:disable-next-line:variable-name
const ConditionalWrapper: React.FC<ConditionalWrapperProps> =
	({ condition, wrapper, children }) =>
	condition ? React.cloneElement(wrapper(children)) : React.cloneElement(children);

export const mixpanelService = new MixpanelTrackingService();

export const BASE_SSO_ROUTE = "/sso/:ssoEmail?";
const OKTA_CALLBACK_ROUTE = "/sso/okta/callback";

interface ISSOProps {
	ssoEmail?: string;
}

// tslint:disable-next-line: variable-name
const SSOApp = observer((props: RouteComponentProps<ISSOProps>) => {
	const {
	ssoStore: {
		fetchSSOOrg,
		exchangeOktaToken,
	},
	authStore } = useStores();

	const [oktaAuth, setOktaAuth] = useState<OktaAuth | undefined>();
	const [email, setEmail] = useState<string | undefined>();
	const [customerId, setCustomerId] = useState<string | undefined>();

	useEffect(() => {
		if (email) {
			// tslint:disable-next-line: no-floating-promises
			fetchSSOOrg(email)
				.then(org => {
					if (org) {
						setOktaAuth(new OktaAuth({
							issuer: org.issuer,
							clientId: org.clientId,
							redirectUri: window.location.origin + OKTA_CALLBACK_ROUTE
						}));
						setCustomerId(org.customerId);
					}
				});
		}
	}, [email]);

	const restoreOriginalUri = async (auth: OktaAuth, originalUri: string) => {
		const oktaToken = auth.getIdToken();
		if (customerId && email && oktaToken) {
			const response = await exchangeOktaToken(customerId, email, oktaToken);
			if (response) {
				// inject JWT
				await authStore.handleAuthUserResponse(response);
				props.history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
			}
		}
	};

	const renderAuthOrForm = (ssoProps: RouteComponentProps) => {
		if (ssoProps && ssoProps.match && ssoProps.match.params) {
			const paramEmail = (ssoProps.match.params as any).ssoEmail || (ssoProps.match.params as any).state;
			if (paramEmail !== email) {
				setEmail(paramEmail);
			}
		}

		if (oktaAuth) {
			return <SSOAuthPage {...ssoProps} oktaAuth={oktaAuth} email={email!} customerId={customerId!} />;
		}

		return <SSOEmailForm/>;
	};

	const renderPostCallback = (ssoProps: RouteComponentProps) => {
		if (ssoProps && ssoProps.location) {
			const qs = queryString.parse(ssoProps.location.search);
			if (qs.state) {
				const state = JSON.parse(qs.state as string);
				if (state.email && state.email !== email) {
					setEmail(state.email);
				}
				if (state.customerId && state.customerId !== customerId) {
					setCustomerId(customerId);
				}
			}
		}

		if (oktaAuth) {
			return <LoginCallback/>;
		}

		return <>Signing you in...</>;
	};

	return (
		<div>
			<Meta includeCustomFonts={true}/>
			<div className="fixed-toaster-container">
				<ToastContainer
					autoClose={false}
				/>
			</div>
			<ConditionalWrapper
				condition={!!oktaAuth}
				wrapper={(children: React.ReactElement) =>
				<Security oktaAuth={oktaAuth!} restoreOriginalUri={restoreOriginalUri}>{children}</Security>}
			>
				<Switch>
					<Route path={OKTA_CALLBACK_ROUTE}
						render={(routeProps => renderPostCallback(routeProps))} />
					<Route path={BASE_SSO_ROUTE}
						render={(routeProps) => renderAuthOrForm(routeProps)}
					/>
					{/* All missed paths will need login */}
					<Redirect to="/" />
				</Switch>
			</ConditionalWrapper>
		</div>
	);
});

export default SSOApp;
