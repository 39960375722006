import { getData, postData } from "../../../services/fetch-helpers";
import { BaseCustomerApiClient } from "./base-customer-api-client";
import urlJoin from "url-join";
import {
	IAutoDetectSettingsParams, IBillingSubscriptionDetails,
	IRetroAutoDetectedConfigurationResponse, ProgressUpdate
} from "../../mobx-stores/go-retro-sprint-review-store";
import { qsStringify } from "../../../services/url-routes-helper";

export const SETUP_PROGRESS_ROUTE = urlJoin("setup", "progress");
const SELECTED_SPRINTS_ROUTE = `/go-retro/selected-sprints`;
export const STATUS_DIFF_ROUTE = urlJoin("tasks", "status-diff");
export const AUTO_DETECT_ROUTE = urlJoin("go-retro", "settings-auto-detect");
export const BILLING_SUBSCRIPTION_DETAILS_ROUTE = urlJoin("billing", "subscription-details");

export class GoRetroSprintSummaryApiClient extends BaseCustomerApiClient {
	public fetchProgressUpdate = async () => {
		return await getData<ProgressUpdate, any>(
			this.createCustomerEntityRoute(SETUP_PROGRESS_ROUTE),
			this.token,
			this.tokenType
		);
	}

	public setSprints = async (sprintIds: string[], rethrowError: boolean = true) => {
		return await postData<{ sprints: string[] }, any, any>(
			this.createCustomerEntityRoute(SELECTED_SPRINTS_ROUTE),
			this.token,
			{ sprints: sprintIds },
			this.tokenType,
			rethrowError
		);
	}

	public fetchAutoDetectedConfigurations = async (params: IAutoDetectSettingsParams) => {
		const query = { ...params };
		return await getData<IRetroAutoDetectedConfigurationResponse, any>(
			this.createCustomerEntityRoute(`${AUTO_DETECT_ROUTE}?${qsStringify(query)}`),
			this.token,
			this.tokenType,
			true
		);
	}

	public fetchSubscriptionData = async () => {
		return await getData<IBillingSubscriptionDetails, any>(
			this.createCustomerEntityRoute(BILLING_SUBSCRIPTION_DETAILS_ROUTE),
			this.token,
			this.tokenType
		);
	}
}
