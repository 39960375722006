import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { ROUTES } from "../../../../routing";
import { Accordion, Icon } from "semantic-ui-react";
import InsightRulesDetailsPane from "../insight-rule-details/insight-rule-details-pane";
import { STRINGS } from "../../../../../localization";
import InsightRuleParametersPane from "../insight-rule-parameters/insight-rule-parameters-pane";
import InsightRuleInvolvementsPane from "../insight-rule-involvements/insight-rule-involvements-pane";
import { useStores } from "../../../../mobx-stores";
import { observer } from "mobx-react";
import Loader from "../../../../components/loader";

function SingleInsightRulePage() {
	const [activeIndex, setActiveIndex] = useState(0);
	const [insightRuleId, setInsightRuleId] = useState<string>("");
	const searchParams = new URLSearchParams(useLocation().search);
	const id = searchParams.get("id") ?? "";

	useEffect(() => {
		setInsightRuleId(id);

		return function unmount() {
			insightRulesStore.resetSingleInsightRule();
		};
	}, []);

	const { insightRulesStore, routingStore } = useStores();
	const { allInsightRules, singleInsightRule } = insightRulesStore;

	useEffect(() => {
		if (insightRuleId && !allInsightRules.loaded) {
			insightRulesStore.fetchAllInsightRules().then().catch();
		}
	}, [insightRuleId]);

	useEffect(() => {
		if (allInsightRules.loaded && insightRuleId) {
			const insightRule = allInsightRules.data.find(dc => dc.id === insightRuleId);
			if (insightRule) {
				insightRulesStore.setSingleInsightRule(insightRule);
			} else {
				routingStore.push(ROUTES.NOT_FOUND);
			}
		}
	}, [allInsightRules, insightRuleId]);

	if (!singleInsightRule.data.id) {
		return null;
	}

	const toggleAccordion = (tabIndex: number) => activeIndex === tabIndex ?
		setActiveIndex(-1) :
		setActiveIndex(tabIndex);

	return (
		<Loader isActive={!allInsightRules.loaded}>
			<div className="ui grid">
				<Accordion className="minimum-three-quatres-width">
					<div className="ui segment raised">

						<Accordion.Title
							active={activeIndex === 0}
							index={0}
							onClick={() => toggleAccordion(0)}
						>
							<p className="accordion-header">
								<span className=" ui header">
									<Icon name="dropdown" />
									{STRINGS.DETAILS}
								</span>
							</p>
						</Accordion.Title>
						<Accordion.Content active={activeIndex === 0}>
							<InsightRulesDetailsPane insightRule={singleInsightRule.data} />
						</Accordion.Content>
						{singleInsightRule.data.parameters && insightRuleId !== undefined && (
							<>
								<Accordion.Title
									active={activeIndex === 1}
									index={1}
									onClick={() => toggleAccordion(1)}
								>
									<p className="accordion-header">
										<span className=" ui header">
											<Icon name="dropdown" />
											{STRINGS.PARAMETERS}
										</span>
									</p>
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 1}>
									<InsightRuleParametersPane
										insightRuleId={insightRuleId}
										insightRuleParameters={singleInsightRule.data.parameters}
									/>
								</Accordion.Content>
							</>
						)}
						{insightRuleId !== undefined && (
							<>
								<Accordion.Title
									active={activeIndex === 2}
									index={2}
									onClick={() => toggleAccordion(2)}
								>
									<p className="accordion-header">
										<span className=" ui header">
											<Icon name="dropdown" />
											{STRINGS.NOTIFICATIONS}
										</span>
									</p>
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 2}>
									<InsightRuleInvolvementsPane
										insightRuleInvolvements={singleInsightRule.data.involvements}
										insightRuleId={insightRuleId}
									/>
								</Accordion.Content>
							</>
						)}
					</div>
				</Accordion>
			</div>
		</Loader>
	);
}

export default observer(SingleInsightRulePage);
