import apiContextProvider from "../../services/api-context-provider";
import { SprintsApiClient, ISprintSort, ISprintFilter, SPRINTS_ROUTE } from "../services/crud/sprints-api-client";
import { DevelopmentMethodology, IDashboardResponse, IDashboardSprint } from "@acumen/dashboard-common";

import { action, computed, observable } from "mobx";
import BaseStore from "./base-store";
import { FetchLatestRequest } from "../../services/fetch-helpers";

export default class SprintsStore extends BaseStore<{}>  {

	private readonly apiClient: SprintsApiClient = new SprintsApiClient(apiContextProvider);

	@observable
	public sprintData: IDashboardSprint[] = [];

	@observable
	public isLoading: boolean = false;

	fetchLatestSprints = new FetchLatestRequest<IDashboardSprint[], any>(SPRINTS_ROUTE);
	@action.bound
	public async fetchData(developmentMethodology: DevelopmentMethodology,
		filter?: ISprintFilter, sort?: ISprintSort, useFetchLatest = true) {
		this.isLoading = true;

		try {
			let result: IDashboardResponse<IDashboardSprint[], any> | null;
			if (useFetchLatest) {
				result = await this.fetchLatestSprints.fetchLatest(this.apiClient.fetchSprints(developmentMethodology, filter, sort));
			} else {
				result = await this.apiClient.fetchSprints(developmentMethodology, filter, sort);
			}

			const response = result?.data ?? [];
			this.sprintData = response;

			return response;
		} finally {
			this.isLoading = false;
		}
	}

	@computed
	public get sprintIdMapping() {
		const result: Record<string, IDashboardSprint> = {};
		this.sprintData.forEach(sprint => {
			result[sprint.id] = sprint;
		});
		return result;
	}

}
