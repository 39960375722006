import React, { useMemo, useState } from "react";
import classNames from "classnames";
import { CustomTableColumnProps, CustomTableFilter, ResolvedCustomTableColumnProps, SortState } from "./types";
import { CustomTableHeaderCell } from "./components/custom-table-header-cell/custom-table-header-cell";
import { CustomTableDataCell } from "./components/custom-table-data-cell/custom-table-data-cell";
import { useFilteredItems } from "./hooks/useFilteredItems";
import { useSortedItems } from "./hooks/useSortedItems";
import { getCompareByFunction } from "./utils";
import "./custom-table.scss";

export interface CustomTableProps<T extends object> {
	columns: Array<CustomTableColumnProps<T>>;
	stickyHeader?: boolean;
	sortable?: boolean;
	items?: T[];
	getItemKey?: (item: T, index: number) => React.Key;
	topHeader?: React.ReactNode | (() => React.ReactElement);
	bottomHeader?: React.ReactNode | (() => React.ReactElement);
	topBody?: React.ReactNode | ((props: { items: T[] }) => React.ReactElement);
	bottomBody?: React.ReactNode | ((props: { items: T[] }) => React.ReactElement);
}

export function CustomTable<T extends object>(props: CustomTableProps<T>) {
	const { columns, items = [], stickyHeader = true, sortable = false, getItemKey = (_, index) => index } = props;
	const { topHeader: TopHeader, bottomHeader: BottomHeader, topBody: TopBody, bottomBody: BottomBody } = props;
	const [filters, setFilters] = useState<Array<CustomTableFilter<T>>>([]);
	const [sortState, setSortState] = useState<SortState<T> | null>(null);
	const resolvedColumns = useMemo<Array<ResolvedCustomTableColumnProps<T>>>(() => columns.map(column => ({
		...column,
		compareBy: getCompareByFunction(column),
	})), [columns]);
	const filteredItems = useFilteredItems({
		columns: resolvedColumns,
		items,
		filters,
		onFiltersChange: setFilters,
	});
	const sortedItems = useSortedItems({
		columns: resolvedColumns,
		items: filteredItems,
		sortState,
		onSortStateChange: setSortState,
	});

	return (
		<div className="custom-table-container">
			<table className="custom-table" cellSpacing={0} cellPadding={0}>
				{TopHeader && (
					<thead className="custom-table-header custom-table-header-top">
					{typeof TopHeader === "function" ? <TopHeader/> : TopHeader}
					</thead>
				)}

				<thead
					className={classNames(
						"custom-table-head",
						"custom-table-head-main",
						stickyHeader && "variant-sticky",
					)}
				>
				<tr className="custom-table-row custom-table-row-header">
					{resolvedColumns.map(column => (
						<CustomTableHeaderCell
							key={column.key}
							column={column}
							sortable={sortable}
							items={items}
							sortState={sortState}
							filters={filters}
							onSortStateChange={setSortState}
							onFiltersChange={setFilters}
						/>
					))}
				</tr>
				</thead>

				{BottomHeader && (
					<thead className="custom-table-header custom-table-header-bottom">
					{typeof BottomHeader === "function" ? <BottomHeader/> : BottomHeader}
					</thead>
				)}

				{TopBody && (
					<tbody className="custom-table-body custom-table-body-top">
					{typeof TopBody === "function" ? <TopBody items={items}/> : TopBody}
					</tbody>
				)}

				<tbody className="custom-table-body custom-table-body-main">
				{sortedItems.map((item, index) => (
					<tr key={getItemKey(item, index)} className="custom-table-row custom-table-row-data">
						{resolvedColumns.map(column => (
							<CustomTableDataCell
								key={column.key}
								column={column}
								item={item}
								index={index}
								items={items}
							/>
						))}
					</tr>
				))}
				</tbody>

				{BottomBody && (
					<tbody className="custom-table-body custom-table-body-bottom">
					{typeof BottomBody === "function" ? <BottomBody items={items}/> : BottomBody}
					</tbody>
				)}
			</table>
		</div>
	);
}
