import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Portal, Segment, Menu, Popup, Table } from "semantic-ui-react";
import {
	HighlightType, IDashboardPullRequestExtended, IPullRequestHighlight,
	IDashboardEvent, OriginatingEntityType, IDashboardTask, IDashboardPullRequest,
	IDashboardPullRequestDetails,
	IDashboardPullRequestEntity,
	IHighlightInsight,
	HighlightEntityType,
	InsightStatus
} from "@acumen/dashboard-common";
import { PullRequestStatus, TaskStatus } from "../../components/formatters";
import "./pr-details-display-panel.scss";
import { Avatar } from "../../components/avatar";
import moment from "moment";
import { useStores } from "../../mobx-stores";
import HighlightsFormatters from "../../components/formatters/highlights";
import { Formatters } from "../../components/dashboard-task/formatters";
import TriggerOnClickOutside from "../../components/ux-effects/trigger-on-click-outside";
import Linkify from "../../components/formatters/linkify";
import { DateFormat, dateFormatByLocale } from "@acumen/common";
import { filterPullRequestDisplayData } from "./pr";
import AvatarsGroup from "../../components/avatars-group";
import classNames from "classnames";
import { ExternalImage } from "../../components/external-image";
import { GA_EVENT_ACTION, GA_EVENT_CATEGORY, clickEvent } from "../../analytics-events";
import { eventsFormatting } from "../../components/formatters/string";
import { TIMELINE_EVENTS_FILTER } from "../../components/formatters/events";
import NothingFoundIcon from "../../components/svg-assets/no-content-icons/empty_state_icon_1.svg";
import { Loader } from "semantic-ui-react";

const createAttentionPoint = (dateFormat: string, h: IPullRequestHighlight, idx: number) => {
	let title;
	switch (h.type) {
		case HighlightType.Insight: {
			title = "INSIGHT";
			break;
		}
	}

	return (
		<div className="event" key={`highlightEntityId-${idx}`}>
			<div className="content">
				<div className="summary">
					{title}
					{
						h.dateMs && (
							<div className="date">
								{moment(h.dateMs).format(dateFormat)}
							</div>
						)
					}
					{(h.type === HighlightType.Insight && (h as IHighlightInsight<HighlightEntityType.PullRequest>).insightStatus === InsightStatus.Canceled &&
						<span className="ui label insight-resolved">
							RESOLVED
					</span>)}
				</div>
				<div className="extra text">
					<Linkify linkMap={h.linkifyMatchToUrl} classNamePrefix="acu-link">
						{HighlightsFormatters.highlightText(h)}
					</Linkify>
					<div className="ui divider" />
				</div>
			</div>
		</div>
	);
};

// tslint:disable-next-line: variable-name
const AttentionPointsTab = observer(({ highlights }: { highlights?: IPullRequestHighlight[] }) => {
	const {
		authStore
	} = useStores();
	const DATE_TIME_FORMAT = dateFormatByLocale(DateFormat.LongWithTime, authStore.authUser.locale);
	const filteredHighlights = highlights ? filterPullRequestDisplayData(highlights) : [];
	return (
		<div className="ui bottom attached active tab basic segment pr-details-tab">
			{(filteredHighlights.length === 0) && (
				noTabContent("No Attention Points")
			)}
			{filteredHighlights.length > 0 && (
				<div className="ui small feed scroller">
					{filteredHighlights.map((h, i) => createAttentionPoint(DATE_TIME_FORMAT, h, i))}
				</div>
			)}
		</div>
	);
});

// tslint:disable-next-line: variable-name
const TimelineTab = observer((props: { pr: IDashboardPullRequestEntity }) => {
	const {
		pullRequestsStore: {
			pullRequestEvents,
			fetchPullRequestsEvents
		},
		authStore
	} = useStores();
	useEffect(() => {
		// tslint:disable-next-line: no-floating-promises
		fetchPullRequestsEvents(props.pr);
	}, [props.pr]);

	const eventCard = (event: IDashboardEvent, i: number) => {
		const DATE_TIME_FORMAT = dateFormatByLocale(DateFormat.LongWithTime, authStore.authUser.locale);
		const formattingFunction = eventsFormatting[event.eventType];
		const relatedEventName: Record<OriginatingEntityType, (str: string) => string> = {
			[OriginatingEntityType.GitHubPullRequest]: (str: string) => `PR #${str}`,
			[OriginatingEntityType.GitHubCommit]: (str: string) => `${str.substring(0, 6)}`,
			[OriginatingEntityType.JiraIssue]: (str: string) => str
		};
		return (
			<div className="timeline-event-card acu-scroll" key={i}>
				<div className="float left avatar-container">
					<Avatar
						dataContributor={event.dataContributor}
						className={"big"}
					/>
				</div>
				<div className="float right content full-width">
					<div className="flex-column">
						<span className="small-gray-text">
							{moment(event.eventTimeMs).format(DATE_TIME_FORMAT)}
						</span>
					</div>
					{formattingFunction && formattingFunction(event)}
				</div>
				{(props.pr.entityId !== event.entity?.entityId) && event.entity && event.entity?.publicIdentifier &&
					<span className="ui gray label light-gray related-label">
						<a href={event.entity?.publicHtmlUrl ?? ""} target="_blank" rel="noopener noreferrer" className="link">
							{relatedEventName[event.entityType]((event.entity?.publicIdentifier).toString() ?? "")}
						</a>
					</span>}
			</div>
		);
	};
	return (
		<div className="timeline-events-tab acu-scroll">
			{!pullRequestEvents && noTabContent("No Work Was Logged")}
			{pullRequestEvents.length === 0 ?
				tabContentLoading() :
				pullRequestEvents
					.filter((e: IDashboardEvent) => e &&
						TIMELINE_EVENTS_FILTER.find(displayedEventType => displayedEventType === e.eventType))
					.map(eventCard)
			}
		</div>
	);
});

// tslint:disable-next-line: variable-name
const PRWorkTab = observer(({
	pr
}: {
	pr: IDashboardPullRequest | IDashboardPullRequestEntity
}) => {
	const {
		pullRequestsStore: {
			workIntervals: {
				loading,
				data: workIntervalsData
			},
			fetchWorkIntervals,
			resetWorkIntervals,
		}
	} = useStores();

	useEffect(() => {
		resetWorkIntervals();
		// tslint:disable-next-line: no-floating-promises
		fetchWorkIntervals(pr);
	}, [`${pr.entityId}-${pr.entityType}`]);

	const prWorkIntervals = workIntervalsData.workIntervalsByDataContributor;
	return (
		<div className="ui bottom attached active tab basic segment pr-details-tab">
			{loading ? (
				tabContentLoading()
			) : (prWorkIntervals && prWorkIntervals.length > 0) ? (
				<div className="scroller">
					<Table celled={false} padded={false} textAlign="center">
						<Table.Header fullWidth={true}>
							<Table.Row>
								<Table.HeaderCell textAlign="left">Assignee</Table.HeaderCell>
								<Popup
									size="small"
									basic={true}
									content="Amount of maker time spent towards commits and coding"
									trigger={<Table.HeaderCell>Coding</Table.HeaderCell>}
								/>
								<Popup
									size="small"
									basic={true}
									content="All pull request related maker time spent, including reviews and comments"
									trigger={<Table.HeaderCell>Review</Table.HeaderCell>}
								/>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{prWorkIntervals.map(
								(w) => (
									<Table.Row key={w.dataContributor.id}>
										<Table.Cell verticalAlign={"middle"} textAlign="left">
											<div className="flex-row">
												<Avatar
													dataContributor={w.dataContributor}
													className={"medium"}
												/>
												<span className="assignee-name pull-request-property-text">{w.dataContributor.primaryDisplayName} </span>
											</div>
										</Table.Cell>
										<Table.Cell>{Formatters.workIntervals(w.commitWorkInMs)}</Table.Cell>
										<Table.Cell>{Formatters.workIntervals(w.prWorkInMs)}</Table.Cell>
									</Table.Row>
								)
							)}
						</Table.Body>
					</Table>
				</div>
			) : noTabContent("No Work Was Logged")}
		</div>
	);
});

// tslint:disable-next-line: variable-name
const PRDetails = ({ pr, details }: { pr?: IDashboardPullRequest; details?: IDashboardPullRequestDetails }) => {
	const reviewers = pr?.reviewers || details?.reviewers;
	const creator = pr?.creator || details?.creator;
	const status = pr?.status || details?.status;
	return (
		<div className="ui compact three column centered aligned grid">
			<div className="three column centered row vertical-spacer-x ">
				<div className="column pull-request-property-header">Opener</div>
				<div className="column pull-request-property-header">Reviewer(s)</div>
				<div className="column pull-request-property-header">Status</div>
			</div>
			<div className="three column centered row subtitle">
				<div className="column center-align full-height">
					<div className="pull-request-property-container">
						<Avatar dataContributor={creator} className="medium" />
						<span className="pull-request-property-text">{creator?.primaryDisplayName}</span>
					</div>
				</div>
				<div className="column" style={{ verticalAlign: "sub" }}>
					{reviewers && (
						(
							<AvatarsGroup
								usersList={reviewers.map(r => ({
									id: r.id,
									displayName: r.primaryDisplayName || "",
									avatarUrl: r.primaryAvatarUrl || ""
								}))}
								maxUsersOnView={3}
								imgClassName={"medium"}
							/>
						)
					)}
				</div>
				<div className="column" style={{ verticalAlign: "text-top" }}>
					<PullRequestStatus status={status} />
				</div>
			</div>
			<div className="three column centered row vertical-spacer-x">
				<div className="column pull-request-property-header">Created</div>
				<div className="column pull-request-property-header"># of commits</div>
				<div className="column pull-request-property-header"># of comments</div>
			</div>
			<div className="three column centered row subtitle">
				<div className="column">
					{
						details?.openedFromDateMs
							? moment(details.openedFromDateMs).format("ll")
							: <Loader active={true} inline={true} inverted={true} size="small" />
					}
				</div>
				<div className="column">
					{
						details?.numberOfCommits !== undefined
							? details.numberOfCommits
							: <Loader active={true} inline={true} inverted={true} size="small" />
					}
				</div>
				<div className="column">
					{
						details?.numberOfComments !== undefined
							? details.numberOfComments
							: <Loader active={true} inline={true} inverted={true} size="small" />
					}
				</div>
			</div>
		</div>
	);
};

const taskDetailsCard = (task: IDashboardTask, i: number) => (
	<div className="ui grid raised info card" key={"rt" + i}>
		<div className="content">
			<p className="subheader">
				{task.title}
			</p>
			<div className="level">
				<span className="left floated">
					<TaskStatus taskStatus={task.status.status} internalStatus={task.status.internalStatus} hasTooltip={true} />
				</span>
				<span className="right floated">
					<Linkify
						classNamePrefix="acu-link"
						linkMap={{ [task.publicIdentifier!]: task.publicHtmlUrl }}
					>
						{task.publicIdentifier}
					</Linkify>
					<ExternalImage src={task.type.iconUrl ?? ""} className="ui micro image" />
					<ExternalImage src={task.priority.iconUrl ?? ""} className="ui micro image" />
					<Avatar
						dataContributor={task.assignee}
					/>
				</span>
			</div>
		</div>
	</div>
);

// tslint:disable-next-line: variable-name
const RelatedTasksTab = ({ relatedTasks }: { relatedTasks?: IDashboardTask[] }) => {
	return (
		<div className="cards-container">
			{!relatedTasks && tabContentLoading()}
			{(relatedTasks && relatedTasks.length === 0) && noTabContent("No Related Tasks")}
			{(relatedTasks && relatedTasks.length > 0) &&
				relatedTasks.map((t: any, i: number) => taskDetailsCard(t, i))}
		</div>
	);
};

const tabContentLoading = () => (
	<div className="ui placeholder" style={{ margin: "15px" }}>
		<div className="line" />
		<div className="line" />
		<div className="line" />
		<div className="line" />
		<div className="line" />
	</div>
);

const noTabContent = (text: string) => (
	<div className="ui placeholder segment noTabContent">
		<img className="status-icon" width={"68px"} height={"85px"} src={NothingFoundIcon} alt="" />
		<p>{text}</p>
	</div>
);

enum TAB_TYPES {
	AttentionPoints = "AttentionPoints",
	WorkIntervals = "WorkIntervals",
	RelatedTasks = "RelatedTasks",
	Timeline = "Timeline"
}

const isDetailedPR = (pr: IDashboardPullRequest | IDashboardPullRequestExtended | IDashboardPullRequestEntity): pr is IDashboardPullRequestExtended => {
	return "openedFromDateMs" in pr
		&& "totalCalculatedWorkMs" in pr
		&& "relatedTasks" in pr
		&& "linkMapping" in pr;
};

const isInfoPR = (pr: IDashboardPullRequest | IDashboardPullRequestEntity): pr is IDashboardPullRequest => {
	return "status" in pr
		&& "creator" in pr
		&& "reviewers" in pr;
};
function PRDetailsDisplayPanel<T extends IDashboardPullRequest | IDashboardPullRequestExtended | IDashboardPullRequestEntity>({
	pr,
	onDismiss,
	onDismissPRHighlights,
	shouldShowNeedAttentionFlag = false,
	outsidePanelClassName
}: {
	pr: T;
	onDismiss: () => void;
	onDismissPRHighlights?: (value: T) => void;
	shouldShowNeedAttentionFlag?: boolean;
	outsidePanelClassName?: string;
}) {
	const { pullRequestsStore } = useStores();
	const { getPRHighlights, getPRRelatedTasks, getPRDetails } = pullRequestsStore;

	const relatedTasks = isDetailedPR(pr) ? pr.relatedTasks : undefined;
	const highlights = isDetailedPR(pr) ? pr.highlights : undefined;
	const details: IDashboardPullRequestExtended | undefined = isDetailedPR(pr) ? pr : undefined;
	const prInfo: IDashboardPullRequest | undefined = (!details && isInfoPR(pr)) ? pr : undefined;
	const [prHighlights, setPRHighlights] = useState<IPullRequestHighlight[] | undefined>(highlights);
	const [prRelatedTasks, setPRRelatedTasks] = useState<IDashboardTask[] | undefined>(relatedTasks);
	const [prDetails, setPRDetails] = useState<IDashboardPullRequestDetails | undefined>(details);

	useEffect(() => {
		if (highlights) {
			setPRHighlights(highlights ?? []);
			return;
		}
		setPRHighlights(undefined);
		// tslint:disable-next-line: no-floating-promises
		getPRHighlights(pr).then((results) => {
			setPRHighlights(results ?? []);
		});
	}, [pr]);

	useEffect(() => {
		if (relatedTasks) {
			setPRRelatedTasks(relatedTasks ?? []);
			return;
		}

		setPRRelatedTasks(undefined);
		// tslint:disable-next-line: no-floating-promises
		getPRRelatedTasks(pr).then((tasks) => {
			if (!tasks) {
				setPRRelatedTasks([]);
				return;
			}

			setPRRelatedTasks(tasks);
		});
	}, [pr]);

	useEffect(() => {
		if (details) {
			setPRDetails(details);
			return;
		}

		setPRDetails(undefined);
		// tslint:disable-next-line: no-floating-promises
		getPRDetails(pr).then((results) => {
			if (results
				&& (
					pr.entityId.toString() !== results.entityId ||
					pr.entityType !== results.entityType
				)
			) {
				return;
			}

			if (!results) {
				setPRDetails(undefined);
				return;
			}

			setPRDetails(results);
		});
	}, [pr]);

	const [selectedTab, setSelectedTab] = useState<TAB_TYPES>(TAB_TYPES.AttentionPoints);

	return (
		<Portal open={true}>
			<TriggerOnClickOutside excludedClassName={outsidePanelClassName} onTrigger={onDismiss}>
				<Segment
					raised={true}
					style={{
						height: "100%",
						top: "0",
						right: "0",
						width: "480px",
						position: "fixed",
						padding: 0,
						margin: 0,
						zIndex: 1000,
					}}
					className="pull-request-details"
				>
					<div className="ui vertical stackable basic segments panel-container" >
						<div className="ui basic segment">
							<div className="details-header-container">
								<div className="details-header-title">
									PULL REQUEST DETAILS
								</div>
								<i {...clickEvent(GA_EVENT_CATEGORY.PullRequestWork, GA_EVENT_ACTION.Close)} className="large close icon details-header-close-icon" onClick={(e) => { e.stopPropagation(); onDismiss(); }} />
							</div>
						</div>
						<div className="ui segment pull-request-title-container">
							<div className={classNames("ui items panel-title", shouldShowNeedAttentionFlag && "with-icon")}>
								<div className="item">
									<div className="content">
										<div className="header">{pr.title}</div>
										{shouldShowNeedAttentionFlag && (
											<div className="description">
												<i className="red flag icon" />
												Need Attention
											</div>
										)}
									</div>
								</div>
							</div>
							{shouldShowNeedAttentionFlag && (
								<Popup
									size="mini"
									basic={true}
									flowing={true}
									hoverable={true}
									on="click"
									hideOnScroll={true}
									position="bottom right"
									trigger={
										<i {...clickEvent(GA_EVENT_CATEGORY.PullRequestWork, GA_EVENT_ACTION.Delete, "NeedAttention")} className="ui icon outline ellipsis vertical pull-request-title-settings-icon" />
									}
								>
									<div className={classNames("ui tiny header", outsidePanelClassName, "flex-row")}
										style={{ cursor: "pointer" }}
										onClick={(e) => {
											if (onDismissPRHighlights) {
												e.stopPropagation();
												onDismissPRHighlights(pr);
												onDismiss();
											}
										}}
									>
										<i className="icon check check-icon-display" />
										<span>{"Acknowledge"}</span>
									</div>
								</Popup>
							)}
						</div>
						<div className="ui basic segment">
							<PRDetails pr={prInfo} details={prDetails} />
						</div>

						<Menu className="tabs ui pointing secondary menu">
							<Menu.Item
								name="Attention points"
								{...clickEvent(GA_EVENT_CATEGORY.PullRequestWork, GA_EVENT_ACTION.Select, "AttentionPoints")}
								active={selectedTab === TAB_TYPES.AttentionPoints}
								onClick={() => setSelectedTab(TAB_TYPES.AttentionPoints)}
							/>
							<Menu.Item
								name="Maker Time"
								{...clickEvent(GA_EVENT_CATEGORY.PullRequestWork, GA_EVENT_ACTION.Select, "WorkIntervals")}
								active={selectedTab === TAB_TYPES.WorkIntervals}
								onClick={() => setSelectedTab(TAB_TYPES.WorkIntervals)}
							/>
							<Menu.Item
								name="RelatedTasks"
								{...clickEvent(GA_EVENT_CATEGORY.PullRequestWork, GA_EVENT_ACTION.Select, "RelatedTasks")}
								active={selectedTab === TAB_TYPES.RelatedTasks}
								onClick={() => setSelectedTab(TAB_TYPES.RelatedTasks)}
							/>
							<Menu.Item
								name="Timeline"
								{...clickEvent(GA_EVENT_CATEGORY.PullRequestWork, GA_EVENT_ACTION.Select, "Timeline")}
								active={selectedTab === TAB_TYPES.Timeline}
								onClick={() => setSelectedTab(TAB_TYPES.Timeline)}
							/>
						</Menu>
						{selectedTab === TAB_TYPES.AttentionPoints && <AttentionPointsTab highlights={prHighlights} />}
						{selectedTab === TAB_TYPES.WorkIntervals && <PRWorkTab pr={pr} key="work-intervals-tab" />}
						{selectedTab === TAB_TYPES.RelatedTasks && <RelatedTasksTab relatedTasks={prRelatedTasks} />}
						{selectedTab === TAB_TYPES.Timeline && <TimelineTab pr={pr} />}
					</div>
				</Segment>
			</TriggerOnClickOutside>
		</Portal>
	);
}

export default observer(PRDetailsDisplayPanel);
