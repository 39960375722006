import React from "react";
import {
	IDashboardInsightRuleParameters, DashboardInsightRuleParameterType,
	DashboardInsightRuleParameterValueType as ParameterValueType,
	IDashboardInsightRuleParameterMetadata,
	IDashboardInsightRuleNumericParameterOptions,
	IDashboardInsightRuleStringArrayParameterOptions
} from "@acumen/dashboard-common";
import ActionableTable, {
	IColumnDefinition, RowId, IEditorOptions, INumberEditorOptions, IMultiSelectEditorOptions,
	EditorType, CustomFormatter, CellValue
} from "../../../../components/actionable-table/actionable-table";
import _ from "lodash";
import { STRINGS } from "../../../../../localization";
import { useStores } from "../../../../mobx-stores";
import { observer } from "mobx-react";

interface IProps {
	insightRuleId: string;
	insightRuleParameters: IDashboardInsightRuleParameters;
}

interface IParametersMap {
	[key: string]: ParameterValueType;
}

interface IInsightRuleParameterRowPartial {
	contextKey: string;
	isActive: boolean;
	context: string;
}

export type IInsightRuleParameterRow = IInsightRuleParameterRowPartial & IParametersMap;

function InsightRuleParametersPane(props: IProps) {
	const { insightRulesStore } = useStores();
	const colDefs = createColumnsDefinition(props.insightRuleParameters);
	const rows = createInsightRuleParameterRows(props.insightRuleParameters);

	async function onCellEdited(row: IInsightRuleParameterRow, parameterKey: string, value: CellValue) {
		const contextKey = row.contextKey;
		const userInput = props.insightRuleParameters.parametersMetadata.find(a => a.key === parameterKey);
		if (userInput?.type === DashboardInsightRuleParameterType.Number && typeof value === "string") {
			value = parseInt(value, 10);
		}

		await insightRulesStore.updateInsightRule({
			id: props.insightRuleId,
			parameters: {
				values: [{ contextKey, parameterKey, value }]
			}
		});
	}
	async function onIsActiveToggled(contextKey: RowId, isActive: boolean) {
		await insightRulesStore.updateInsightRule({
			id: props.insightRuleId,
			parameters: {
				contexts: [{ key: contextKey, isActive }]
			}
		});
	}

	return (
		<ActionableTable
			data={rows}
			columnsDef={colDefs}
			rowIdKey="contextKey"
			showRowToggle={true}
			isRowActiveKey="isActive"
			onRowToggled={onIsActiveToggled}
			editable={true}
			onCellEdited={onCellEdited}
			pagination={{ show: true }}
		/>
	);
}
export default observer(InsightRuleParametersPane);

function createInsightRuleParameterRows(irParams: IDashboardInsightRuleParameters):
	IInsightRuleParameterRow[] {

	const valuesByContext = _.groupBy(irParams.values, v => v.contextKey);
	const rows: IInsightRuleParameterRow[] = Object.entries(valuesByContext).map(([contextKey, values]) => {
		const context = irParams.contexts.find(c => c.key === contextKey);
		if (!context) {
			throw new Error(`Insight Rule Parameter Context was not found for key: ${contextKey}`);
		}

		const parametersMap: IParametersMap = {};
		values.map((val) => parametersMap[val.parameterKey] = val.value);
		const row: IInsightRuleParameterRow = {
			contextKey,
			context: context.label,
			isActive: context.isActive,
			...parametersMap
		};
		return row;
	});

	return rows;
}

function createColumnsDefinition(insightRuleParameters: IDashboardInsightRuleParameters) {
	const columnDefs: Array<IColumnDefinition<IInsightRuleParameterRow>> = [];

	columnDefs.push({
		key: "isActive",
		headerText: STRINGS.ACTIVE
	});

	columnDefs.push({
		key: "context",
		headerText: insightRuleParameters.contextsHeaderLabel
	});

	insightRuleParameters.parametersMetadata.forEach(param => {
		columnDefs.push({
			key: param.key,
			headerText: param.label,
			customFormatter: param.type === DashboardInsightRuleParameterType.StringArray ? CustomFormatter.Array : undefined,
			editable: true,
			editorOptions: createRowEditorOptions(param),
		});
	});

	return columnDefs;
}

function createRowEditorOptions(param: IDashboardInsightRuleParameterMetadata): IEditorOptions {
	if (param.type === DashboardInsightRuleParameterType.Number && param.options) {
		const numberOptions = param.options as IDashboardInsightRuleNumericParameterOptions;
		if (numberOptions.minValue === undefined || numberOptions.maxValue === undefined) {
			throw new Error(`Insight Rule Parameter: ${param.key} is numeric but doesn't have min/max value options`);
		}
		const numberEditorOptions: INumberEditorOptions = {
			minValue: numberOptions.minValue,
			maxValue: numberOptions.maxValue,
			type: EditorType.Number
		};

		return numberEditorOptions;
	}

	if (param.type === DashboardInsightRuleParameterType.StringArray && param.options) {
		const stringArrayOptions = param.options as IDashboardInsightRuleStringArrayParameterOptions;

		if (!stringArrayOptions.allowedValues) {
			throw new Error(`Insight Rule Parameter: ${param.key} is a string-array but doesn't have allowedValues option`);
		}

		const multiSelectOptions: IMultiSelectEditorOptions = {
			type: EditorType.MultiSelect,
			values: stringArrayOptions.allowedValues.map((val) => ({
				label: val.label,
				value: val.value
			}))
		};

		return multiSelectOptions;
	}

	return {
		type: getEditorTypeForParameterType(param.type)
	};
}

function getEditorTypeForParameterType(type: DashboardInsightRuleParameterType) {
	switch (type) {
		case DashboardInsightRuleParameterType.Number:
			return EditorType.Number;
		case DashboardInsightRuleParameterType.StringArray:
			return EditorType.MultiSelect;
		case DashboardInsightRuleParameterType.String:
			return EditorType.Text;
		default:
			throw new Error(`Unknown DashboardInsightRuleParameterType: ${type}`);
	}
}
