import {
	DashboardPullRequestDynamicGroupingOption, DashboardPullRequestExpandOption,
	DashboardPullRequestStaticGroupingOption, DashboardPullRequestWorkStatusGrouping,
	DashboardSortOrder, DashboardTaskDynamicGroupingOption, DashboardTaskExpandOption,
	DashboardTaskStaticGroupingOption, DashboardTaskWorkStatusGrouping, DevelopmentMethodology, IDashboardDetailedPullRequestGroup,
	IDashboardDetailedTaskGroup, IDashboardDetailedTaskResponse, IDashboardPullRequestExtended, IDashboardSprint, IDashboardTaskDetailed,
	SprintSortField,
	SprintState,
	SprintType,
	TaskEstimationMethod
} from "@acumen/dashboard-common";
import { GA_EVENT_CATEGORY } from "../../../analytics-events";
import TaskDetailsDisplayPanel from "../../../components/dashboard-task/task-details-display-panel";
import PullRequestDetailsDisplayPanel from "../../../components/dashboard-pr/pr-details-display-panel";
import FiltersTopNavbar from "../../../components/filters-top-navbar";
import LoadingIndicator from "../../../components/loader";
import { observer } from "mobx-react";
import { useStores } from "../../../mobx-stores";
import React, { useCallback, useEffect, useState } from "react";
import { Message, Tab } from "semantic-ui-react";
import { EstimationColumnType } from "../work/task";
import { TasksGroup } from "../work/task/index";
import NewBugsTable from "./new-bugs";
import { PullRequestsGroup } from "../work/pr";
import BurndownChart from "../sprint-retro/burndown-chart";
import { IBurndownRequestScope } from "../../../services/crud/sprint-retro-api-client";
import ConfirmationModal from "../../../components/modals/confirmation-modal";
import moment from "moment";
import "./style.scss";

const NO_TASKS_PLACEHOLDER = "no tasks";
const NO_PRS_PLACEHOLDER = "no pull requests";
const HEADER_MESSAGE_TIME_FORMAT = "dddd, MMM Do";
const HEADER_PR_TITLE_DATE_RANGE_MESSAGE = `Pull requests shown are from the past 3 months`;

enum DetailedDisplayPanelType {
	Task,
	PullRequest
}

// tslint:disable-next-line: variable-name
const TeamDashboard = observer(() => {
	const {
		teamsStore,
		teamMembersStore,
		sprintsStore: {
			fetchData: fetchSprints,
			isLoading: isLoadingSprints
		},
		tasksStore: {
			fetchTasksByOptions,
			isLoading: isLoadingTasks,
			dismissHighlights: {
				loading: dismissingTaskHighlight
			},
			dismissTaskHighlights,
		},
		pullRequestsStore: {
			allPullRequests: {
				data: prResponse,
				loading: isLoadingPrs
			},
			fetchAllPullRequests,
			dismissHighlights: {
				loading: dismissingPRHighlight
			},
			dismissPRHighlights,
		},
		repositoriesStore: {
			fetchData: fetchGitRepoData,
			isLoading: isLoadingGitRepos,
			gitRepositoriesData
		},
		authStore,
		sprintRetroStore: {
			getBurndownData,
			subtaskSprintStrategy,
			developmentMethodology,
			estimationMethod,
			excludeTasksWithNonTeamDCs,
			timezone,
			countryCode,
			burndownChartData,
			fetchConfiguration,
			resetAllPageData
		},
		boardsStore: {
			fetchData: fetchBoardsData,
			boardsData,
			isLoading: isLoadingBoards
		}
	} = useStores();

	const [headerTasksDateRangeMessage, setHeaderTasksDataRangeMessage] = useState<string | undefined>(undefined);
	const [searchedTeamId, setSearchedTeamId] = useState<string | undefined>(undefined);
	const [activeSprint, setActiveSprint] = useState<IDashboardSprint | undefined>(undefined);
	const [dataContributorFilter, setDataContributorFilter] = useState<string[] | undefined>(undefined);
	const [selectedRowEntityType, setSelectedRowEntityType] = useState<DetailedDisplayPanelType | undefined>(undefined);
	const [refetchBurndownData, setRefetchBurndownData] = useState<boolean>(false);
	const [noIterationMode, setNoIterationMode] = useState<boolean>(false);

	const [tasksResponse, setTasksResponse] = useState<IDashboardDetailedTaskResponse | undefined>(undefined);
	const [needAttentionTasks, setNeedAttentionTasks] = useState<IDashboardDetailedTaskGroup | undefined>(undefined);
	const [selectedTaskInPanel, setSelectedTaskInPanel] = useState<[IDashboardTaskDetailed | undefined, number | undefined]>([undefined, undefined]);
	const [taskHighlightToDismiss, setTaskHighlightToDismiss] = useState<IDashboardTaskDetailed | undefined>(undefined);

	const [needAttentionPullRequests, setNeedAttentionPullRequests] = useState<IDashboardDetailedPullRequestGroup | undefined>(undefined);
	const [selectedPullRequestInPanel, setSelectedPullRequestInPanel] = useState<[IDashboardPullRequestExtended | undefined, number | undefined]>([undefined, undefined]);
	const [prHighlightToDismiss, setPRHighlightToDismiss] = useState<IDashboardPullRequestExtended | undefined>(undefined);
	const [refetchPullRequests, setRefetchPRsData] = useState<boolean>(false);

	const tasksResponseGroup = tasksResponse?.tasks && !Array.isArray(tasksResponse.tasks) ? tasksResponse.tasks : undefined;
	const inProgressTasks = tasksResponseGroup ? tasksResponseGroup[DashboardTaskWorkStatusGrouping.InProgress] : undefined;
	const taskEstimationMethod = tasksResponse?.estimationMethod;

	const prsResponseGroup = prResponse?.pullRequests && !Array.isArray(prResponse.pullRequests) ? prResponse.pullRequests : undefined;
	const openPrs = prsResponseGroup ? prsResponseGroup[DashboardPullRequestWorkStatusGrouping.Open] : undefined;
	const inReviewProcessPrs = prsResponseGroup ? prsResponseGroup[DashboardPullRequestWorkStatusGrouping.InReviewProcess] : undefined;
	const reviewedPrs = prsResponseGroup ? prsResponseGroup[DashboardPullRequestWorkStatusGrouping.Reviewed] : undefined;

	useEffect(() => {
		if (!teamsStore.singleTeam.data.id
			|| teamsStore.singleTeam.data.id === ""
			|| teamsStore.singleTeam.data.id === searchedTeamId) {
			return;
		}

		setSearchedTeamId(teamsStore.singleTeam.data.id);
		resetAllPageData();
	}, [teamsStore.singleTeam.data.id]);

	useEffect(() => {
		// tslint:disable-next-line: no-floating-promises
		fetchConfiguration(searchedTeamId!);
	}, [searchedTeamId]);

	useEffect(() => {
		async function retrieveSprintsAndBoards() {
			if (isLoadingSprints || isLoadingBoards) {
				return;
			}

			await fetchBoardsData({teamId: searchedTeamId!});

			let active;
			if (developmentMethodology && developmentMethodology !== DevelopmentMethodology.None) {
				const fetchedSprints = await fetchSprints(developmentMethodology, {
					teamId: searchedTeamId,
					sprintStates: [SprintState.Active, SprintState.Closed],
					excludeSprintsWithFutureStartDate: true,
					excludeSprintsWithNoAssignees: true
				}, {
					field: SprintSortField.StartDate,
					order: DashboardSortOrder.Descending
				}, true);
				active = fetchedSprints.find(s => s.isActive);
			}

			if (!active) {
				setActiveSprint(undefined);
				setNoIterationMode(true);
			}

			setHeaderTasksDataRangeMessage(formatHeaderTasksMessage(active));
			if (!active || active.id === activeSprint?.id) {
				setRefetchBurndownData(true);
				setTasksResponse(undefined);
				return;
			}

			setActiveSprint(active);
			setNoIterationMode(false);
		}

		async function retrieveGitRepositories(selectedTeamId: string) {
			return selectedTeamId && !isLoadingGitRepos &&
				await fetchGitRepoData({ teamId: selectedTeamId }, { order: DashboardSortOrder.Ascending });
		}

		if (!searchedTeamId || searchedTeamId === "" || !developmentMethodology) {
			return;
		}

		setDataContributorFilter([]);
		// tslint:disable-next-line: no-floating-promises
		retrieveSprintsAndBoards();
		// tslint:disable-next-line: no-floating-promises
		teamMembersStore.fetchAllTeamMembers(searchedTeamId);
		// tslint:disable-next-line: no-floating-promises
		retrieveGitRepositories(searchedTeamId);
	}, [searchedTeamId, developmentMethodology]);

	useEffect(() => {
		if (!activeSprint) {
			return;
		}
		setTasksResponse(undefined);
		setRefetchBurndownData(true);
	}, [activeSprint]);

	useEffect(() => {
		function fetchCycleTasks() {
			let startTime;
			let endTime;
			let sprintFilter;

			// TODO: support dev cycles in tasks api
			if (activeSprint && developmentMethodology === DevelopmentMethodology.Scrum) {
				sprintFilter = [`${activeSprint.id},${activeSprint.integrationType}`];
			}

			if (activeSprint && developmentMethodology === DevelopmentMethodology.Kanban
				&& activeSprint.startDate && activeSprint.endDate) {
				startTime = new Date(activeSprint.startDate);
				endTime = new Date(activeSprint.endDate);
			}

			if (!sprintFilter && (!startTime || !endTime)) {
				startTime = defaultTasksStartTime();
				endTime = defaultTasksEndTime();
			}

			// tslint:disable-next-line: no-floating-promises
			fetchTasksByOptions({
				filter: {
					teamId: teamsStore.singleTeam.data.id,
					dataContributorIds: dataContributorFilter,
					sprints: sprintFilter,
					startTime,
					endTime
				},
				staticGroupingLimit: 0,
				staticGrouping: DashboardTaskStaticGroupingOption.WorkStatusInProgress,
				dynamicGroupingLimit: 0,
				dynamicGrouping: DashboardTaskDynamicGroupingOption.Highlights,
				expand: [DashboardTaskExpandOption.Highlights, DashboardTaskExpandOption.Progress, DashboardTaskExpandOption.RelatedPRs],
			}).then(response => {
				if (!response) {
					return;
				}

				setTasksResponse(response);
				setNeedAttentionTasks(response?.dynamic);
			});
		}

		if (tasksResponse || !dataContributorFilter || (!activeSprint && !noIterationMode)) {
			return;
		}

		fetchCycleTasks();
	}, [tasksResponse, activeSprint, noIterationMode]);

	useEffect(() => {
		setSelectedTaskInPanel([undefined, undefined]);
		setSelectedPullRequestInPanel([undefined, undefined]);
	}, [dataContributorFilter]);

	useEffect(() => {
		if (!gitRepositoriesData || !dataContributorFilter) {
			return;
		}
		setRefetchPRsData(true);
	}, [gitRepositoriesData]);

	useEffect(() => {
		function fetchPullRequests() {
			if (!searchedTeamId) {
				return;
			}
			// tslint:disable-next-line: no-floating-promises
			fetchAllPullRequests({
				filter: {
					teamId: searchedTeamId,
					dataContributorIds: dataContributorFilter,
					startTime: pullRequestStartTime()
				},
				staticGroupingLimit: 0,
				staticGrouping: DashboardPullRequestStaticGroupingOption.WorkStatusInProgress,
				dynamicGroupingLimit: 0,
				dynamicGrouping: DashboardPullRequestDynamicGroupingOption.Highlights,
				expand: [DashboardPullRequestExpandOption.Highlights]
			}).then(response => {
				if (!response) {
					return;
				}

				setNeedAttentionPullRequests(response?.dynamic);
			});
		}

		if (!refetchPullRequests || !gitRepositoriesData || !dataContributorFilter) {
			return;
		}

		fetchPullRequests();
		setRefetchPRsData(false);
	}, [refetchPullRequests]);

	useEffect(() => {
		if (!refetchBurndownData || !subtaskSprintStrategy || !developmentMethodology || !searchedTeamId
			|| !activeSprint || !estimationMethod || excludeTasksWithNonTeamDCs === undefined
			|| !timezone || !countryCode || isLoadingBoards) {
			setRefetchBurndownData(false);
			return;
		}

		const scope: IBurndownRequestScope = {
			teamId: searchedTeamId,
			sprint: activeSprint,
			dataContributorIds: dataContributorFilter,
			subtaskStrategy: subtaskSprintStrategy,
			estimationMethod,
			excludeTasksWithNonTeamDCs,
			timezone,
			countryCode,
			boardIds: boardsData.ownable.map(board => board.entityId),
			developmentMethodology,
			includeUnassigned: false,
		};

		// tslint:disable-next-line: no-floating-promises
		getBurndownData(scope);
		setRefetchBurndownData(false);

	}, [refetchBurndownData]);

	function pullRequestStartTime() {
		return moment.utc(moment()).subtract(3, "months").startOf("day").toDate();
	}

	function defaultTasksEndTime() {
		return moment().toDate();
	}

	function defaultTasksStartTime() {
		return moment().subtract(2, "weeks").toDate();
	}

	function createTasksTable(index: number, detailedTaskGroup: IDashboardDetailedTaskGroup | undefined) {
		return (
			<LoadingIndicator local={true} isActive={!tasksResponse || isLoadingTasks}>
				{
					(detailedTaskGroup && detailedTaskGroup.total > 0)
						?
						<TasksGroup
							{...{
								currentIndex: index,
								selectedIndex: selectedTaskInPanel[1],
								rows: detailedTaskGroup.tasks,
								selectedRow: selectedTaskInPanel[0],
								totalRows: detailedTaskGroup.total,
								scrollable: true,
								onDismissTaskHighlights: index === 0 ? (v => setTaskHighlightToDismiss(v)) : undefined,
								onRowClicked: (v => {
									setSelectedTaskInPanel([v, index]);
									setSelectedRowEntityType(DetailedDisplayPanelType.Task);
								}),
								estimationMethod: taskEstimationMethod,
								estimationColumn: EstimationColumnType.EstProgress
							}}
						/>
						:
						<div className="table-placeholder acu-capitalize">
							{NO_TASKS_PLACEHOLDER}
						</div>
				}
			</LoadingIndicator>
		);
	}

	function createPullRequestTable(index: number, pullRequests: IDashboardDetailedPullRequestGroup | undefined) {
		return (
			<LoadingIndicator local={true} isActive={!prResponse || isLoadingPrs}>
				{
					pullRequests && pullRequests.total > 0
					? <PullRequestsGroup
						{...{
							currentIndex: index,
							selectedIndex: selectedPullRequestInPanel[1],
							rows: pullRequests.pullRequests,
							selectedRow: selectedPullRequestInPanel[0],
							totalRows: pullRequests.total,
							scrollable: true,
							onDismissPRHighlights: index === 0 ? (p: IDashboardPullRequestExtended) => setPRHighlightToDismiss(p) : undefined,
							onRowClicked: (item: IDashboardPullRequestExtended) => {
								setSelectedPullRequestInPanel([item, index]);
								setSelectedRowEntityType(DetailedDisplayPanelType.PullRequest);
							}
						}}
					/>
					: <div className="table-placeholder acu-capitalize">{NO_PRS_PLACEHOLDER}</div>
				}
			</LoadingIndicator>
		);
	}

	function onDismissTaskHighlights(task: IDashboardTaskDetailed) {
		if (dismissingTaskHighlight || !needAttentionTasks) {
			return;
		}
		const matchingTaskIndex = needAttentionTasks.tasks.findIndex((t) => t === task);
		if (matchingTaskIndex === -1) {
			return;
		}
		// tslint:disable-next-line: no-floating-promises
		dismissTaskHighlights(task).then(success => {
			if (success) {
				const tempTasks = [...needAttentionTasks.tasks];
				tempTasks.splice(matchingTaskIndex, 1);
				setNeedAttentionTasks({
					tasks: tempTasks,
					total: (needAttentionTasks.total > 0 ? needAttentionTasks.total - 1 : 0)
				});
			}
		});
	}

	function onDismissPRHighlights(pr: IDashboardPullRequestExtended) {
		if (dismissingPRHighlight || !needAttentionPullRequests) {
			return;
		}
		const matchingPRIndex = needAttentionPullRequests?.pullRequests.findIndex((p) => p === pr);
		if (matchingPRIndex === -1) {
			return;
		}
		// tslint:disable-next-line: no-floating-promises
		dismissPRHighlights(pr).then(success => {
			if (success) {
				const tempPRs = [...needAttentionPullRequests?.pullRequests];
				tempPRs.splice(matchingPRIndex, 1);
				setNeedAttentionPullRequests({
					pullRequests: tempPRs,
					total: (needAttentionPullRequests.total > 0 ? needAttentionPullRequests.total - 1 : 0)
				});
			}
		});
	}

	function generateTitleWithItemsCount(title: string, group: IDashboardDetailedTaskGroup | IDashboardDetailedPullRequestGroup | undefined): string {
		return group ? `${title} (${group.total})` : title;
	}

	function formatHeaderTasksMessage(sprint: IDashboardSprint | undefined): string {
		if (sprint && sprint.type === SprintType.Sprint) {
			return `Showing tasks for sprint ${sprint.name} [${moment(sprint.startDate).format(HEADER_MESSAGE_TIME_FORMAT)} - ${moment(sprint.endDate).format(HEADER_MESSAGE_TIME_FORMAT)}]`;
		}

		let startTime: Date | undefined;
		let endTime: Date | undefined;
		if (sprint && sprint.type === SprintType.DevCycle
			&& sprint.startDate && sprint.endDate) {
			startTime = new Date(sprint.startDate);
			endTime = new Date(sprint.endDate);
		}

		if ((!startTime || !endTime)) {
			startTime = defaultTasksStartTime();
			endTime = defaultTasksEndTime();
		}
		return `Showing tasks for dates [${moment(startTime).format(HEADER_MESSAGE_TIME_FORMAT)} - ${moment(endTime).format(HEADER_MESSAGE_TIME_FORMAT)}]`;
	}

	const handlePrInPanelDismiss = useCallback(() => setSelectedPullRequestInPanel([undefined, undefined]), []);
	const handleTaskInPanelDismiss = useCallback(() => setSelectedTaskInPanel([undefined, undefined]), []);
	return (
		<div className="team-dashboard-tab ongoing-pages ui sixteen column grid">
			<FiltersTopNavbar
				filters={{}}
				applyFilters={() => "ok"}
				popupSelectFields={[]}
				counterLabel={undefined}
				avatarSelectorField={{
					usersList: teamMembersStore.allTeamMembers.data.map(user => { user.id = user.dataContributorId; return user; }),
					onUsersSelected: (selectedUsers: string[]) => {
						setDataContributorFilter(selectedUsers);
						if (!searchedTeamId || (!activeSprint && !noIterationMode)) {
							return;
						}

						setTasksResponse(undefined);
						setRefetchPRsData(true);
						setRefetchBurndownData(true);
					},
					initialSelectedUsers: [],
				}}
				clickEventPage={GA_EVENT_CATEGORY.TeamDashboard}
			/>
			<ConfirmationModal
				className={"warning"}
				open={taskHighlightToDismiss !== undefined}
				header={"Need attention"}
				confirmButton={"Ok"}
				cancelButton={"Cancel"}
				content={`Acknowledge task and remove from attention section?`}
				onCancel={() => { setTaskHighlightToDismiss(undefined); }}
				onConfirm={() => { onDismissTaskHighlights(taskHighlightToDismiss!); setTaskHighlightToDismiss(undefined); }}
			/>
			{(selectedRowEntityType === DetailedDisplayPanelType.Task && selectedTaskInPanel[0] !== undefined && selectedTaskInPanel[1] !== undefined) &&
				<TaskDetailsDisplayPanel
					task={selectedTaskInPanel[0]}
					shouldShowNeedAttentionFlag={selectedTaskInPanel[1] === 0}
					onDismiss={handleTaskInPanelDismiss}
					onDismissTaskHighlights={(v: IDashboardTaskDetailed) => setTaskHighlightToDismiss(v)}
					outsidePanelClassName={"team-dashboard-table"}
				/>
			}
			<ConfirmationModal
				className={"warning"}
				open={prHighlightToDismiss !== undefined}
				header={"Need attention"}
				confirmButton={"Ok"}
				cancelButton={"Cancel"}
				content={`Acknowledge pull request and remove from attention section?`}
				onCancel={() => { setPRHighlightToDismiss(undefined); }}
				onConfirm={() => { onDismissPRHighlights(prHighlightToDismiss!); setPRHighlightToDismiss(undefined); }}
			/>
			{(selectedRowEntityType === DetailedDisplayPanelType.PullRequest && selectedPullRequestInPanel[0] !== undefined && selectedPullRequestInPanel[1] !== undefined) &&
				<PullRequestDetailsDisplayPanel
					pr={selectedPullRequestInPanel[0]}
					shouldShowNeedAttentionFlag={selectedPullRequestInPanel[1] === 0}
					onDismiss={handlePrInPanelDismiss}
					onDismissPRHighlights={((p: IDashboardPullRequestExtended) => setPRHighlightToDismiss(p))}
					outsidePanelClassName={"dashboard-table"}
				/>
			}
			<h1>Dashboard</h1>
			<div className="set-page-z-index full-width">
				{headerTasksDateRangeMessage &&
					<Message compact={true} attached={false} info={true} size={"small"} list={
						[
							headerTasksDateRangeMessage,
							HEADER_PR_TITLE_DATE_RANGE_MESSAGE
						]
					} />
				}
				<div className="ui sixteen wide column db-section">
					<div className="ui full-width table card">
						<div className="ui secondary header acu-capitalize">need attention</div>
						<Tab className="team-dashboard-table" menu={{ secondary: true, pointing: true, className:
							"table-tab-menu no-margin" }} panes={[
							{
								menuItem: generateTitleWithItemsCount("Tasks", needAttentionTasks), render: () =>
									<Tab.Pane attached={false}>
										{createTasksTable(0, needAttentionTasks)}
									</Tab.Pane>
							},
							{
								menuItem: generateTitleWithItemsCount("Pull requests", needAttentionPullRequests), render: () =>
									<Tab.Pane attached={false}>
										{createPullRequestTable(0, needAttentionPullRequests)}
									</Tab.Pane>
							}
						]} />
					</div>
				</div>
				<div className="ui sixteen wide column db-section">
					<div className="ui full-width table card">
						<div className="ui secondary header acu-capitalize">in the works</div>
						<Tab className="team-dashboard-table" menu={{ secondary: true, pointing: true, className:
							"table-tab-menu no-margin" }} panes={[
							{
								menuItem: generateTitleWithItemsCount("Tasks", inProgressTasks), render: () =>
									<Tab.Pane attached={false}>
										{createTasksTable(1, inProgressTasks)}
									</Tab.Pane>
							},
							{
								menuItem: generateTitleWithItemsCount("Open pull requests", openPrs), render: () =>
									<Tab.Pane attached={false}>
										{createPullRequestTable(1, openPrs)}
									</Tab.Pane>
							},
							{
								menuItem: generateTitleWithItemsCount("Awaiting review", inReviewProcessPrs), render: () =>
									<Tab.Pane attached={false}>
										{createPullRequestTable(2, inReviewProcessPrs)}
									</Tab.Pane>
							},
							{
								menuItem: generateTitleWithItemsCount("Reviewed pull requests", reviewedPrs), render: () =>
									<Tab.Pane attached={false}>
										{createPullRequestTable(3, reviewedPrs)}
									</Tab.Pane>
							}
						]} />
					</div>
				</div>
				<div className="db-section  db-grid">
					<div className="ui eight wide column new-bugs-section db-section-item">
						<div className="ui full-width table card">
							<div className="ui secondary header acu-capitalize">new bugs</div>
							<div className="team-dashboard-table">
								<NewBugsTable {...{
									setSelectedTaskInPanel: (v => {
										setSelectedTaskInPanel([v, 2]);
										setSelectedRowEntityType(DetailedDisplayPanelType.Task);
									}),
									selectedTaskInPanel: selectedTaskInPanel[0],
									searchedTeamId,
									dataContributorFilter,
									pageError: false
								}} />
							</div>
						</div>
					</div>
					<div className="db-section-separator" />
					<div className="ui eight wide column burndown-section db-section-item">
						<div className="ui full-width card">
							<div className="ui secondary header acu-capitalize">burndown</div>
							{noIterationMode ?
								<div style={{ height: `415px` }}>
									No active iteration detected
								</div>
								: <BurndownChart
									timezone={authStore.authUser.timezone}
									estimationMethod={estimationMethod ?? TaskEstimationMethod.None}
									subtaskStrategy={subtaskSprintStrategy}
									burndownChartData={burndownChartData}
									pageError={false}
									removeTitle={true}
									height={415}
									devMethodology={developmentMethodology}
								/>}
						</div>
					</div>
				</div>
			</div>
		</div>);
});

export default TeamDashboard;
