import React, { useMemo } from "react";
import { DeveloperBadgeMetric, TeamBadge, TeamBadgeMetric } from "@acumen/database-types";
import { DashboardBadge } from "../../types/workforce-health";
import { BadgeBreakdownItem } from "../../pages/workforce-health/components/badge-breakdown-item/badge-breakdown-item";
import { Badge, BadgeType } from "../badge/badge";
import {
	DeveloperBadgeBreakdownEmptyState
} from "../../pages/workforce-health/components/developer-badge-breakdown-empty-state/developer-badge-breakdown-empty-state";
import {
	DeveloperBadgeLowActivityEmptyState
} from "../../pages/workforce-health/components/developer-badge-low-activity-empty-state/developer-badge-low-activity-empty-state";
import "./badge-breakdown.scss";
import { BadgeOwner, BadgeWithReason } from "../../types/badges";

export interface DeveloperBadgeBreakdownProps {
	badges: Record<DeveloperBadgeMetric, BadgeWithReason | null> | Record<TeamBadgeMetric, BadgeWithReason | null>;
	badgeOwner: BadgeOwner;
	hasLowActivity?: boolean;
}

export const BadgeBreakdown = (props: DeveloperBadgeBreakdownProps) => {
	const { hasLowActivity, badgeOwner } = props;
	const metricBadgeData = useMetricBadgeData(props);

	return (
		<div className="badge-breakdown">
			{hasLowActivity && <DeveloperBadgeLowActivityEmptyState />}
			{!hasLowActivity && metricBadgeData.isEmpty && <DeveloperBadgeBreakdownEmptyState />}
			{(!metricBadgeData.isEmpty && !hasLowActivity) && (
				<div className="badge-breakdown-list">
					{metricBadgeData.items.map(item => (
						<BadgeBreakdownItem key={item.name} label={item.name}>
							{!item.badge && (
								<div className="badge-breakdown-list-item-empty-state">
									No score
								</div>
							)}
							{item.badge && (
								<Badge
									badgeOwner={badgeOwner}
									badgeType={BadgeType.MetricBadge}
									metric={item.name}
									badge={item.badge}
									badgeReason={item.badgeReason}
								/>
							)}
						</BadgeBreakdownItem>
					))}
				</div>
			)}
		</div>
	);
};

interface MetricBadgeItem {
	name: DeveloperBadgeMetric | TeamBadgeMetric;
	badge: DashboardBadge | TeamBadge | null | undefined;
	badgeReason: string | null | undefined;
}

const useMetricBadgeData = (props: DeveloperBadgeBreakdownProps) => {
	return useMemo<{ isEmpty: boolean, items: MetricBadgeItem[] }>(() => {
		const { badges, badgeOwner } = props;
		const enumb = badgeOwner === BadgeOwner.Developer ? DeveloperBadgeMetric : TeamBadgeMetric;
		const items: MetricBadgeItem[]  = badgeOwner === BadgeOwner.Developer
			? Object.values(enumb).map(badgeMetric => {
				const typedBadges = badges as Record<DeveloperBadgeMetric, BadgeWithReason | null>;

				return {
					name: badgeMetric,
					badge: typedBadges?.[badgeMetric as DeveloperBadgeMetric]?.badge,
					badgeReason: typedBadges?.[badgeMetric as DeveloperBadgeMetric]?.badgeReason
				};
			})
			: Object.values(TeamBadgeMetric).map(badgeMetric => {
				const typedBadges = badges as Record<TeamBadgeMetric, BadgeWithReason | null>;

				return {
					name: badgeMetric,
					badge: typedBadges?.[badgeMetric]?.badge,
					badgeReason: typedBadges?.[badgeMetric]?.badgeReason
				};
			});

		return {
			isEmpty: items.filter(item => item.badge != null).length === 0,
			items
		};
	}, [props.badges, props.badgeOwner]);
};
