import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Popup } from "semantic-ui-react";

import {
	DashboardRetroReportTaskData,
	IDashboardCycleTimeBreakdown,
	IDashboardTaskStatusChangeResponse,
	TaskEstimationMethod
} from "@acumen/dashboard-common";
import { Avatar } from "../../../components/avatar";
import { ExternalImage } from "../../../components/external-image";
import { Formatters } from "../../../components/dashboard-task/formatters";
import TaskStateInSprint from "./task-state-in-sprint";
import { formatCycleTimeValue } from "./utils";
import TaskStartStatus from "./task-start-status";
import TaskEndStatus from "./task-end-status";
import CycleTimeBreakdownProgress from "./cycle-time-breakdown/cycle-time-breakdown-progress";
import CycleTimeBreakdown from "./cycle-time-breakdown/cycle-time-breakdown";

interface TaskTableItemProps {
	task: DashboardRetroReportTaskData;
	statusDiff?: IDashboardTaskStatusChangeResponse;
	estimationMethod?: TaskEstimationMethod;
	showCycleTimeBreakdownColumn?: boolean;
	cycleTimeBreakdown?: IDashboardCycleTimeBreakdown;
	isExtended: boolean;
	isRaisedRows: boolean;
}

const TaskTableItem = (props: TaskTableItemProps, ref?: React.Ref<HTMLTableRowElement>) => {
	const {
		task,
		statusDiff,
		estimationMethod,
		showCycleTimeBreakdownColumn,
		cycleTimeBreakdown,
		isExtended,
		isRaisedRows,
	} = props;
	const topTargetRef = useRef<HTMLDivElement | null>(null);
	const bottomTargetRef = useRef<HTMLDivElement | null>(null);
	const overlayRef = useRef<HTMLDivElement | null>(null);
	const [top, setTop] = useState(0);
	const [left, setLeft] = useState(0);
	const [verticalPosition, setVerticalPosition] = useState<"top" | "bottom">("top");

	useEffect(() => {
		const handleEvent = () => {
			if (topTargetRef.current && bottomTargetRef.current && overlayRef.current && overlayRef.current.offsetHeight > 0) {
				const spaceTop = topTargetRef.current.getBoundingClientRect().top;
				const positionBottom = bottomTargetRef.current.getBoundingClientRect().bottom;
				const spaceLeft = topTargetRef.current.getBoundingClientRect().left;
				const positionRight = topTargetRef.current.getBoundingClientRect().right;
				const spaceBottom = window.innerHeight - positionBottom;
				const spaceRight = window.innerWidth - positionRight;
				const requiredVerticalSpace = overlayRef.current.offsetHeight;
				const requiredHorizontalSpace = overlayRef.current.offsetWidth;

				if (requiredVerticalSpace <= spaceTop || spaceTop > spaceBottom) {
					setTop(spaceTop - requiredVerticalSpace);
					setVerticalPosition("top");
				} else {
					setTop(positionBottom);
					setVerticalPosition("bottom");
				}

				if (spaceLeft > spaceRight) {
					setLeft(spaceLeft - Math.max(
						requiredHorizontalSpace * 0.4,
						requiredHorizontalSpace - spaceRight,
					));
				} else {
					setLeft(positionRight - Math.max(
						requiredHorizontalSpace * 0.35,
						requiredHorizontalSpace - spaceRight,
					));
				}
			}
		};

		document.addEventListener("mousemove", handleEvent);
		document.addEventListener("scroll", handleEvent);
		document.addEventListener("click", handleEvent);

		return () => {
			document.removeEventListener("mousemove", handleEvent);
			document.removeEventListener("scroll", handleEvent);
			document.removeEventListener("click", handleEvent);
		};
	}, []);

	return (
		<tr ref={ref} className={classNames({ "raised-table-row": isRaisedRows })}>
			<td className="icon-cell icon-cell-first">
				<div className="task-icon-container">
					<Popup
						size="mini"
						basic={true}
						className="capitalize"
						content={task.assignee?.primaryDisplayName}
						trigger={(
							<Avatar
								dataContributor={task.assignee}
								className="task-icon task-icon-large"
							/>
						)}
					/>
				</div>
			</td>
			<td className="icon-cell">
				<div className="task-icon-container">
					<Popup
						size="mini"
						basic={true}
						content={task.type.internalType}
						trigger={
							<ExternalImage src={task.type.iconUrl ?? ""} className="task-icon task-icon-small"/>
						}
					/>
				</div>
			</td>
			<td className="icon-cell icon-cell-last">
				<div className="task-icon-container">
					<Popup
						size="mini"
						basic={true}
						content={task.priority.internalPriority}
						trigger={
							<ExternalImage src={task.priority.iconUrl ?? ""} className="task-icon task-icon-small"/>
						}
					/>
				</div>
			</td>
			<td>
				<div className="center-align">
					<div className="task-identifier">
						{task.publicHtmlUrl
							? <a href={task.publicHtmlUrl} target="_blank" rel="noopener noreferrer"
								 className="link">{task.publicIdentifier}</a>
							: task.publicIdentifier
						}
					</div>
					<span className="task-title">{task.title}</span>
				</div>
			</td>
			{isExtended && <td><TaskStateInSprint task={task}/></td>}
			<td>{Formatters.estimationValue(task.jiraEstimation, estimationMethod, "N/A", true, false)}</td>
			<td>{formatCycleTimeValue(isExtended, task)}</td>
			{isExtended && (
				<td>
					<TaskStartStatus
						task={task}
						isExtended={isExtended}
						statusDiff={statusDiff}
					/>
				</td>
			)}
			{isExtended && showCycleTimeBreakdownColumn && (
				<td>
					{cycleTimeBreakdown && cycleTimeBreakdown.details.statuses.length > 0 && (
						<div className="cycle-time-breakdown-trigger">
							<div className="cycle-time-breakdown-target" ref={topTargetRef}/>
							<CycleTimeBreakdownProgress cycleTimeBreakdown={cycleTimeBreakdown}/>
							<div className="cycle-time-breakdown-target" ref={bottomTargetRef}/>
							<div
								ref={overlayRef}
								className="cycle-time-breakdown-overlay"
								style={{ top: top + "px", left: left + "px" }}
							>
								<CycleTimeBreakdown
									cycleTimeBreakdown={cycleTimeBreakdown}
									verticalPosition={verticalPosition}
								/>
							</div>
						</div>
					)}
				</td>
			)}
			{isExtended && (
				<td>
					<TaskEndStatus
						task={task}
						isExtended={isExtended}
						statusDiff={statusDiff}
					/>
				</td>
			)}
		</tr>
	);
};

export default React.forwardRef(TaskTableItem);
