import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React from "react";
import { ChartSeriesData } from "v2/helpers/charts";

interface ILineChart {
	series?: ChartSeriesData;
	color?: string;
	opacity?: number;
	height?: number;
}

// tslint:disable-next-line: variable-name
const LineChart = (props: ILineChart) => {
	const { series, color, height, opacity = 1 } = { ...props };

	const chartOptions: Highcharts.Options = {
		chart: {
			type: "line",
			margin: -2,
			height
		},
		title: {
			text: undefined
		},
		subtitle: {
			text: undefined
		},
		yAxis: {
			visible: false,
		},
		xAxis: {
			visible: false,
		},
		legend: {
			enabled: false,
		},
		tooltip: {
			enabled: false,
		},
		plotOptions: {
			series: {
				marker: {
					enabled: false
				},
				label: {
					enabled: false
				},
				states: {
					hover: {
						enabled: false
					}
				}
			}
		},
		series: [{
			name: "Installation",
			type: "line",
			data: series,
			opacity,
			color,
			lineWidth: 3
		}],
		responsive: {
			rules: [{
				condition: {
					maxWidth: 500
				},
				chartOptions: {
					legend: {
						layout: "horizontal",
						align: "center",
						verticalAlign: "bottom"
					}
				}
			}]
		},
		credits: {
			enabled: false
		},

	};

	return (
		<HighchartsReact
			highcharts={Highcharts}
			options={chartOptions}
			allowChartUpdate={true}
		/>
	);
};
export default LineChart;
