import React from "react";
import {
	IDashboardInsightRule,
	DashboardSubscriptionEntityType,
	DashboardEntityRelation,
	DashboardSubscriptionType,
	DashboardTimeIntervalUnits
} from "@acumen/dashboard-common";
import { Select, Dropdown, Input, TextArea } from "semantic-ui-react";
import AutoSaveForm from "../../../../components/auto-save-form";
import { STRINGS } from "../../../../../localization";
import { getValuesFromEnum } from "@acumen/common";
import * as localizeEnums from "../../../../../localization/enums";
import ValidatedMultiSelect from "../../../../components/validated-multi-select/validated-multi-select";
import moment from "moment";
import _ from "lodash";
import validator from "validator";
import { useStores } from "../../../../mobx-stores";
import RadioGroup from "../../../../components/form/radio-button-group";
import "./insight-rule-details-pane.scss";

interface IProps {
	insightRule: IDashboardInsightRule;
}

export interface IDashboardInsightRuleView extends Omit<IDashboardInsightRule, "timeIntervalValueSeconds"> {
	timeIntervalValue?: string;
}

export const INVALID_TIME_INTERVAL_VALUE = 0;

const MIN_INTERVAL_VALUE = 1;
const MAX_INTERVAL_VALUE = 31;

const DEFAULT_FORM_CONTROL_CLASSES = "form-control is-valid";
const INVALID_TIME_INTERVAL_VALUE_STRING = "";

export default function InsightRuleDetailsPane(props: IProps) {
	const { insightRulesStore } = useStores();
	const [refInsightRule, setRefInsightRule] = React.useState(props.insightRule);

	const [shouldShowTimeIntervalInputs, setShowIntervalInputs] = React.useState(
		refInsightRule.subscriptionType === DashboardSubscriptionType.TimeInterval);

	async function onAutoSaveTriggered(dataChanged: Partial<IDashboardInsightRuleView>) {
		const convertedDataChanged = transformInsightRuleView(dataChanged, refInsightRule);
		if (convertedDataChanged !== null) {
			await insightRulesStore.updateInsightRule({ ...convertedDataChanged, id: refInsightRule.id });
		}
	}

	function onValueChanged(key: keyof IDashboardInsightRuleView,
		value: IDashboardInsightRuleView[keyof IDashboardInsightRuleView] | any) {

		const dataChanged: Partial<IDashboardInsightRule> = {};
		if (key === "timeIntervalValue") {
			dataChanged.timeIntervalValueSeconds = convertTimeIntervalToSeconds(
				value as IDashboardInsightRuleView["timeIntervalValue"],
				refInsightRule.timeIntervalUnits);
		} else {
			dataChanged[key] = value;

			if (key === "timeIntervalUnits") {
				// If a time interval units is changed, it requires the value in seconds to change accordingly.
				dataChanged.timeIntervalValueSeconds = INVALID_TIME_INTERVAL_VALUE;
			}
		}

		setRefInsightRule({ ...refInsightRule, ...dataChanged });
	}

	function toggleTimeIntervalInputs(shouldShow: boolean) {
		if (shouldShowTimeIntervalInputs !== shouldShow) {
			setShowIntervalInputs(shouldShow);
		}
	}

	if (!refInsightRule) {
		return null;
	}

	return (
		<AutoSaveForm
			className="validationForm"
			onValueChanged={onValueChanged}
			onAutoSaveTriggered={onAutoSaveTriggered}
		>
			<div className="ui grid">
				<div className="ui eight wide column input">
					<div className="ui input form-input">
						{<label>{STRINGS.NAME}</label>}
						<input
							type="text"
							name="name"
							id="name"
							defaultValue={refInsightRule.name}
							autoComplete="off"
							className={DEFAULT_FORM_CONTROL_CLASSES}
							disabled={true}
						/>
					</div>

					<div className="ui input form-input">
						{<label>{STRINGS.DESCRIPTION}</label>}
						<TextArea
							type="text"
							name="name"
							id="description"
							defaultValue={refInsightRule.description}
							autoComplete="off"
							className={DEFAULT_FORM_CONTROL_CLASSES}
							rows={2}
							disabled={true}
						/>
					</div>

					<div className="ui input form-input" style={{ paddingBottom: "0.25rem" }}>
						<label>{STRINGS.EVALUATION_CRITERIA}</label>
						<div className="custom-controls-stacked radio">

							<RadioGroup
								buttons={getValuesFromEnum(DashboardSubscriptionType).map((value, i) => ({ text: localizeEnums.localizeDashboardSubscriptionType(value) || value, key: value }))}
								handleChange={(val) => {
									toggleTimeIntervalInputs(val === DashboardSubscriptionType.TimeInterval);
								}}
								selectedValue={refInsightRule.subscriptionType}
								disabled={true}
							/>
						</div>
					</div>

					{shouldShowTimeIntervalInputs && (
						<>
							<div className="ui input form-input" style={{ paddingTop: "0px" }}>
								<label>{STRINGS.EVERY}</label>
								<Input
									label={(
										<Dropdown
											defaultValue={refInsightRule.timeIntervalUnits}
											options={getValuesFromEnum(DashboardTimeIntervalUnits).map(
												(value, i) => ({ value, text: value, key: i }))}
											disabled={true}
										/>
									)}
									type="number"
									defaultValue={convertTimeIntervalFromSeconds(refInsightRule.timeIntervalValueSeconds,
										refInsightRule.timeIntervalUnits)}
									labelPosition="right"
									placeholder="Find domain"
									min={MIN_INTERVAL_VALUE}
									max={MAX_INTERVAL_VALUE}
									className={"form-button"}
									autoComplete="off"
									disabled={true}
								/>
							</div>
						</>
					)}
				</div>

				<div className="ui eight wide column input">
					<div className="ui input form-input disabled multi-select">
						<label>{STRINGS.AND_IT_RELATES_TO}</label>
						<ValidatedMultiSelect<IDashboardInsightRuleView>
							name="entityRelation"
							errorMessage={STRINGS.SELECT_ONE_ROLE}
							required={true}
							options={getValuesFromEnum(DashboardEntityRelation)
								.map(value => ({
									label: localizeEnums.localizeDashboardEntityRelation(value)!,
									value
								}))}
							value={refInsightRule.entityRelation as string[]}
							disabled={true}
						/>
					</div>

					<div className="ui input form-input">
						<label>{STRINGS.RUN_ON}</label>
						<Select
							options={getValuesFromEnum(DashboardSubscriptionEntityType).map((val, i) => ({ value: val, text: localizeEnums.localizeDashboardSubscriptionEntityType(val), key: i }))}
							defaultValue={refInsightRule.subscriptionEntityType}
							disabled={true}
						/>
					</div>

					<div className="ui input form-input">
						<label>{STRINGS.INSIGHT_FORMULA}</label>
						<TextArea
							name="formulaText"
							id="formulaText"
							required={true}
							value={refInsightRule.formulaText}
							autoComplete="off"
							rows="6"
							className={DEFAULT_FORM_CONTROL_CLASSES}
							disabled={true}
						/>
					</div>
				</div>
			</div>
		</AutoSaveForm >
	);
}

export function transformInsightRuleView(dataChanged: Partial<IDashboardInsightRuleView>,
	currentInsightRule: IDashboardInsightRule): Partial<IDashboardInsightRule> | null {

	const convertedDataChanged: Partial<IDashboardInsightRule> = {};
	if (dataChanged.timeIntervalValue) {
		let units = currentInsightRule.timeIntervalUnits;
		if (dataChanged.timeIntervalUnits) {
			units = dataChanged.timeIntervalUnits;
		}
		convertedDataChanged.timeIntervalUnits = units;
		convertedDataChanged.timeIntervalValueSeconds = convertTimeIntervalToSeconds(dataChanged.timeIntervalValue, units);
		delete dataChanged.timeIntervalValue;
	}
	if (dataChanged.timeIntervalUnits && !dataChanged.timeIntervalValue) {
		// We update the units only when a time interval changes to prevent the UI to lose sync with the data.
		delete dataChanged.timeIntervalUnits;
	}

	if (!_.isEmpty(dataChanged) || !_.isEmpty(convertedDataChanged)) {
		return { ...dataChanged, ...convertedDataChanged };
	}

	return null;
}

function convertTimeIntervalFromSeconds(timeIntervalSeconds?: number, units?: DashboardTimeIntervalUnits) {
	if (timeIntervalSeconds === undefined || units === undefined) {
		throw new Error("One or more of the time interval parameters are undefined, couldn't convert value from seconds");
	}

	if (timeIntervalSeconds === 0) {
		return INVALID_TIME_INTERVAL_VALUE_STRING;
	}

	switch (units) {
		case DashboardTimeIntervalUnits.Hours:
			return moment.duration({ seconds: timeIntervalSeconds }).asHours();
		case DashboardTimeIntervalUnits.Days:
			return moment.duration({ seconds: timeIntervalSeconds }).asDays();
		default:
			throw new Error(`Received an unknown Time Interval Unit: ${units}`);
	}
}

export function convertTimeIntervalToSeconds(timeIntervalValue?: string, units?: DashboardTimeIntervalUnits) {
	if (timeIntervalValue === undefined || units === undefined) {
		throw new Error("Failed to convert an undefined timeIntervalValue/units");
	}

	if (!validator.isNumeric(timeIntervalValue)) {
		return 0;
	}

	const timeIntervalNumber = Number(timeIntervalValue);

	switch (units) {
		case DashboardTimeIntervalUnits.Hours:
			return moment.duration({ hours: timeIntervalNumber }).asSeconds();
		case DashboardTimeIntervalUnits.Days:
			return moment.duration({ days: timeIntervalNumber }).asSeconds();
		default:
			throw new Error(`Received an unknown Time Interval Unit: ${units}`);
	}
}
