export enum FeatureFlags {
	PlannedVsActual = "ui-sprint-summary-planned-vs-actual",
	SprintSummaryPieCharts = "ui-sprint-summary-pie-charts",
	CycleTimeBreakdown = "ui-sprint-summary-cycle-time-breakdown",
	AcumenDashboardPage = "ui-dashboard-page",
	AcumenRetrospectivePage = "ui-retrospective-page",
	AcumenSlackNotificationsPage = "ui-slack-notifications-page",
	AcumenTeamAnalyticsPage = "ui-team-analytics-page",
	AcumenOrganizationAnalyticsPage = "ui-organization-analytics-page",
	PlanningPokerJiraExport = "allow-export-to-jira-in-planning-poker",
}
