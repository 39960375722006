import {
	CommonEndPoints,
	IDashboardDataContributorLabel, IDashboardDataContributorCustomerLabel
} from "@acumen/dashboard-common";
import { getData, postData } from "../../../services/fetch-helpers";
import { BaseCustomerApiClient } from "./base-customer-api-client";
import urlJoin from "url-join";

const DATA_CONTRIBUTORS_LABELS_ROUTE = "/contributors-labels";
const ASSOCIATE_SUB_ROUTE = "associate";
const DISASSOCIATE_SUB_ROUTE = "disassociate";

export class DataContributorLabelsApiClient extends BaseCustomerApiClient {
	async findAll() {
		return await getData<IDashboardDataContributorCustomerLabel[], any>(
			this.createCustomerEntityRoute(urlJoin(DATA_CONTRIBUTORS_LABELS_ROUTE, CommonEndPoints.FIND_ALL)),
			this.token);
	}

	async associate(dcId: string, labelId: string) {
		return await postData<any, IDashboardDataContributorLabel, any>(
			this.createCustomerEntityRoute(urlJoin(DATA_CONTRIBUTORS_LABELS_ROUTE, dcId,
				labelId, ASSOCIATE_SUB_ROUTE)),
			this.token,
			{},
			this.tokenType
		);
	}
	async disassociate(dcId: string, labelId: string) {
		return await postData<any, any, any>(
			this.createCustomerEntityRoute(urlJoin(DATA_CONTRIBUTORS_LABELS_ROUTE, dcId,
				labelId, DISASSOCIATE_SUB_ROUTE)),
			this.token,
			{},
			this.tokenType
		);
	}
	async createNewCustomerLabel(labelName: string) {
		return await postData<any, IDashboardDataContributorCustomerLabel, any>(
			this.createCustomerEntityRoute(urlJoin(DATA_CONTRIBUTORS_LABELS_ROUTE,
				labelName, CommonEndPoints.CREATE)),
			this.token,
			{},
			this.tokenType
		);
	}
}
