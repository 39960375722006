import React from "react";
import { CustomTableRenderProps } from "../../../../components/custom-table/types";
import { DeveloperViewModel } from "../../types";
import { Trend } from "../../../../components/trend/trend";
import { DevStatColumn } from "../../dev-stat-columns";
import { MetricData } from "../../../team-comparison/types";
import "./developer-stat-cell-content.scss";

export const createDeveloperStatCellContent = (metric: DevStatColumn, metricData: MetricData,
	onClick: (dcId: string, metric: DevStatColumn) => void) => {
	return (props: CustomTableRenderProps<DeveloperViewModel>) => {
		const { item } = props;
		const currentValue = metricData.get(item.info.dataContributorId, metric.dashboardMetricName, "current");
		const previousValue = metricData.get(item.info.dataContributorId, metric.dashboardMetricName, "previous");

		return (
			<div className="developer-stat-cell-content"
				onClick={() => onClick(item.info.dataContributorId, metric)}
			>
				<Trend
					isIncreasePositive={metric.displayValues.isIncreasePositive ?? true}
					currentValue={currentValue}
					previousValue={previousValue}
					roundingPrecision={1}
				/>
			</div>
		);
	};
};
