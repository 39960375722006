import { IDashboardStatus } from "@acumen/dashboard-common";
import React from "react";
import TaskStatus from "./task-status-icon";

interface ITaskStatusProgressionProps {
	startStatus: IDashboardStatus | null;
	endStatus: IDashboardStatus | null;
	showIcons?: boolean;
	isRemoved?: boolean;
}

// tslint:disable-next-line: variable-name
const TaskStatusProgression = (props: ITaskStatusProgressionProps) => {
	const { startStatus, endStatus, showIcons = false, isRemoved = false } = { ...props };

	const checkTooltip = (status: IDashboardStatus | null): boolean => {
		return (status?.customerStatusName?.length ?? 0) > 11;
	};

	return (
		startStatus?.statusId !== endStatus?.statusId || isRemoved ? (
			<div className="statuses-display">
				<TaskStatus internalDashboardStatus={startStatus} taskStatus={startStatus?.acumenStatus ?? null}
					internalStatus={"Created"} hasTooltip={checkTooltip(startStatus)} showIcon={showIcons}/>
				<i className="chevron right icon"/>
				<TaskStatus internalDashboardStatus={endStatus} taskStatus={endStatus?.acumenStatus ?? null}
					hasTooltip={checkTooltip(endStatus)} showIcon={showIcons} isRemoved={isRemoved}/>
			</div>
		) :
		<TaskStatus internalDashboardStatus={startStatus} taskStatus={startStatus?.acumenStatus ?? null} showIcon={showIcons}/>
	);
};
export default TaskStatusProgression;
