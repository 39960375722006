import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import "./style.scss";
import {
	AcumenTaskStatus, AcumenTaskStatusGroup, AcumenTaskType,
	DashboardSortOrder, IDashboardAcumenMetricDataResponse, IDashboardGitRepository, IDashboardProject,
	MetricInterval, metricStringDateToUnixTime, taskGroupToStatusMapping
} from "@acumen/dashboard-common";
import {
	Icon, Segment, Step, Form, Button, Select, DropdownItemProps, DropdownProps, Message, Checkbox,
	CheckboxProps, Header, InputOnChangeData, Grid,
} from "semantic-ui-react";
import { useStores } from "../../mobx-stores";
import _ from "lodash";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import useDeepCompareEffect from "use-deep-compare-effect";
import { IDeployFrequencyDORAData, IMTTRDORAData, IPullRequestSizeDORAData } from "../../mobx-stores/metric-store";
import {
	ChartSeriesData, prepareCategoryHourSeriesDataByDate, prepareCategorySeriesDataByDate, prepareGroupedCategorySeriesDataByDate
} from "../../pages/my-team/analytics/charts/charts";
import { v4 as generateUUID } from "uuid";
import colorScheme from "../../pages/my-team/analytics/charts/chart-color-scheme";
import { round } from "@acumen/common";
import HighchartsAnnotations from "highcharts/modules/annotations";
import LoadingIndicator from "../../components/loader";
import { useHistory, useLocation } from "react-router";

HighchartsAnnotations(Highcharts);

enum BuilderStage {
	Settings = "Settings",
	Charts = "Charts",
	Display = "Display",
}

enum CurrentKPILevel {
	Green = "Green",
	Yellow = "Yellow",
	Red = "Red",
	InsufficientData = "InsufficientData",
}

enum Priority {
	P0 = 0,
	P1 = 1,
	P2 = 2,
	P3 = 3,
	P4 = 4
}

const TIME_INTERVAL_OPTIONS_TO_LABEL = {
	[MetricInterval.DAY]: "Daily",
	[MetricInterval.WEEK]: "Weekly",
	[MetricInterval.MONTH]: "Monthly"
};

const PRIORITY_OPTIONS_TO_LABEL: Record<Priority, string> = {
	[Priority.P0]: "P0 (Blocker/Highest)",
	[Priority.P1]: "P1 (Critical/High)",
	[Priority.P2]: "P2 (Major/Medium)",
	[Priority.P3]: "P3 (Low/Minor)",
	[Priority.P4]: "P4 (Trivial/Lowest)"
};

type chartsTimeSpan = "LastMonth" | "ThreeMonth" | "SixMonth" | "LastYear" | "LastTwoYears" | "LastThreeYears";
const TIME_SPAN_OPTIONS: Record<chartsTimeSpan, string> = {
	LastMonth: "Last month",
	ThreeMonth: "Last 3 months",
	SixMonth: "Last 6 months",
	LastYear: "Last year",
	LastTwoYears: "Last two years",
	LastThreeYears: "Last three years"
};

type unit = "month" | "year";
const timeSpanSubtraction: Record<chartsTimeSpan, { count: number, unit: unit }> = {
	LastMonth: {
		count: 1,
		unit: "month"
	},
	ThreeMonth: {
		count: 3,
		unit: "month"
	},
	SixMonth: {
		count: 6,
		unit: "month"
	},
	LastYear: {
		count: 1,
		unit: "year"
	},
	LastTwoYears: {
		count: 2,
		unit: "year"
	},
	LastThreeYears: {
		count: 3,
		unit: "year"
	}
};

const SUPPORTED_DEPLOYED_ENV = [
	"Production",
	"Staging"
];

function calculateDateRangeFromSettings(settings: IReportBuilderSettings) {
	const dateRangeIntervalUnit =
		(
			(settings.interval === MetricInterval.SPRINT_DATE
				|| settings.interval === MetricInterval.DEV_CYCLE_DATE
				|| settings.interval === MetricInterval.DAYS_7
				|| settings.interval === MetricInterval.DAYS_30
				)
				? MetricInterval.DAY
				: settings.interval
		);
	const endTime = settings.hideCurrentPeriod
		? moment()
			.subtract(1, dateRangeIntervalUnit)
			.endOf(dateRangeIntervalUnit)
			.toDate()
		: moment().toDate();
	const subtracted = timeSpanSubtraction[settings.timeSpan] || timeSpanSubtraction.LastYear;
	const startTime = moment(endTime)
		.subtract(subtracted.count, subtracted.unit)
		.startOf(dateRangeIntervalUnit)
		.toDate();
	return {
		startTime,
		endTime
	};
}

enum SupportedCharts {
	AveragePRSizeOverTime = "AveragePRSizeOverTime",
	MTTROverTime = "MTTROverTime",
	DeploymentFrequency = "DeploymentFrequency"
}

const METRIC_GROUP_TYPE_OPTIONS: Record<SupportedCharts, string> = {
	[SupportedCharts.AveragePRSizeOverTime]: "Average pull request size over time",
	[SupportedCharts.MTTROverTime]: "Mean time to resolve ticket",
	[SupportedCharts.DeploymentFrequency]: "Deployment frequency"
};

interface IReportBuilderSettings {
	teamId?: string;
	hideCurrentPeriod: boolean;
	interval: Exclude<MetricInterval, MetricInterval.SPRINT | MetricInterval.DEV_CYCLE>;
	timeSpan: chartsTimeSpan;
	dataContributorIds: string[];
}

const DEFAULT_SETTINGS: IReportBuilderSettings = {
	teamId: undefined,
	hideCurrentPeriod: false,
	interval: MetricInterval.MONTH,
	timeSpan: "LastYear" as chartsTimeSpan,
	dataContributorIds: []
};

interface IChartKPIs {
	greenTo?: number;
	greenFrom?: number;
	yellowTo: number;
	yellowFrom: number;
	redTo?: number;
	redFrom: number;
}
interface IAvgPRSizeChart {
	type: SupportedCharts.AveragePRSizeOverTime;
	kpis: IChartKPIs;
	gitRepositoryIds: string[];
	filterPRToExcludeDraft: boolean;
}

interface IMTTRChart {
	type: SupportedCharts.MTTROverTime;
	kpis: IChartKPIs;
	jiraProjectIds: string[];
	jiraTaskTypes: AcumenTaskType[];
	priorityOrders: Priority[];
	resolvedStatusIssueFilter: AcumenTaskStatus[];
}

interface IDeploymentFrequencyChart {
	type: SupportedCharts.DeploymentFrequency;
	kpis: IChartKPIs;
	gitRepositoryIds: string[];
	deploymentEnvironments: string[];
}

interface IChartViewerProps {
	avgPRSizeChart: IAvgPRSizeChart;
	mttrChart: IMTTRChart;
	deployFreqChart: IDeploymentFrequencyChart;
	settings: IReportBuilderSettings;
	previousStage: () => void;
}

const TV_MODE_HIGHCHART_CHART_HEIGHT = 240;

// tslint:disable-next-line: variable-name
const DeploymentFrequencyChart = observer(({ settings, deployFreqChart, isTVMode }: {
	deployFreqChart: IDeploymentFrequencyChart;
	settings: IReportBuilderSettings;
	isTVMode: boolean;
}) => {
	const { metricStore, authStore } = useStores();
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const [deploymentFrequencyData, setDeploymentFrequencyData] = useState<IDeployFrequencyDORAData | undefined>();
	const { fetchData } = metricStore.deployFrequencyDataDORAStyle();
	const [chartOptions, setChartOptions] = useState<Highcharts.Options | undefined>(undefined);
	const [currentKPILevelMessage, setCurrentKPILevelMessage] = useState<string | undefined>(undefined);
	const [currentKPILevel, setCurrentKPILevel] = useState<CurrentKPILevel | undefined>(undefined);
	const [shouldDrawPlotBands, setShouldDrawPlotBands] = useState<boolean>(false);

	function fillChart(data: IDeployFrequencyDORAData, timezone: string, kpis: IChartKPIs, drawPlotBands: boolean, isInTVMode: boolean) {
		const groupedData = prepareGroupedCategorySeriesDataByDate(data.deployFrequency, timezone);
		const series: Highcharts.SeriesColumnOptions[] = Object
			.keys(groupedData)
			.map(key => {
				const seriesOptions: Highcharts.SeriesColumnOptions = {
					name: key,
					data: _.cloneDeep(groupedData[key]),
					visible: (groupedData[key].length > 0 && _.findIndex(groupedData[key], i => i[1] > 0) >= 0),
					type: "column",
					showInLegend: true,
					stack: "deployment-env"
				};
				return seriesOptions;
			});

		const lastValueForEachSeries: number[] = Object
			.keys(groupedData)
			.map(key => {
				if (groupedData[key].length > 0) {
					return groupedData[key][groupedData[key].length - 1][1];
				}
				return 0;
			});
		let kpiLevel: CurrentKPILevel = CurrentKPILevel.InsufficientData;
		let kpiLevelMessage: string | undefined;
		if (lastValueForEachSeries.length > 0) {
			const meanValue = _.mean(lastValueForEachSeries);
			if (meanValue > kpis.greenFrom!) {
				kpiLevel = CurrentKPILevel.Green;
			} else if (meanValue > kpis.yellowFrom!) {
				kpiLevel = CurrentKPILevel.Yellow;
			} else if (meanValue > kpis.redFrom) {
				kpiLevel = CurrentKPILevel.Red;
			}
			kpiLevelMessage = `Average of ${meanValue} deployments (last period)`;
		} else {
			kpiLevelMessage = `We could not find any deployments in the last period.`;
		}

		const options: Highcharts.Options = {
			chart: {
				type: "column",
				height: isInTVMode ? TV_MODE_HIGHCHART_CHART_HEIGHT : undefined
			},
			title: {
				text: undefined
			},
			annotations: isInTVMode ? [{
				labels: [{
					point: {
						xAxis: 30,
						yAxis: 30,
						x: 30,
						y: 30
					},
					text: kpiLevelMessage,
					useHTML: true,
					backgroundColor: (kpiLevel === CurrentKPILevel.InsufficientData ? "#000000" :
						(kpiLevel === CurrentKPILevel.Green ? "#5AB171" :
							(kpiLevel === CurrentKPILevel.Yellow ? "#FBD66D" :
								"#C61C31"))),
				}]
			}] : [],
			xAxis: {
				gridLineWidth: 1,
				type: "datetime",
				uniqueNames: false
			},
			yAxis: [
				{
					min: 0,
					title: {
						text: "Deployment frequency by environment",
					},
					labels: {
						// tslint:disable-next-line: object-literal-shorthand
						formatter: function _formatter() {
							return `${this.value} deploys`;
						}
					},
					plotBands: drawPlotBands ? [
						{
							color: "rgba(198,28,49,0.3)",
							from: 0,
							to: kpis.redTo ?? 0
						},
						{
							color: "rgba(251,214,109,0.3)",
							from: kpis.yellowFrom,
							to: kpis.yellowTo,
						},
						{
							color: "rgba(90,177,113,0.3)",
							from: kpis.greenFrom,
							to: Number.MAX_SAFE_INTEGER
						}
					] : undefined
				},
			],
			plotOptions: {
				area: {
					stacking: "normal",
				}
			},
			tooltip: {
				split: true,
				valueSuffix: " deploys"
			},
			credits: {
				enabled: false
			},
			series
		};

		setChartOptions(options);
		setCurrentKPILevel(kpiLevel);
		setCurrentKPILevelMessage(kpiLevelMessage);
	}

	useEffect(() => {
		if (deploymentFrequencyData) {
			fillChart(deploymentFrequencyData, authStore.authUser.timezone, deployFreqChart.kpis, shouldDrawPlotBands, isTVMode);
		}
	}, [deploymentFrequencyData, shouldDrawPlotBands]);

	useDeepCompareEffect(() => {
		let isMounted = true;
		async function fetch() {
			setIsFetching(true);
			const { startTime, endTime } = calculateDateRangeFromSettings(settings);
			const data = await fetchData(settings.teamId,
				settings.dataContributorIds,
				undefined,
				startTime,
				endTime,
				settings.interval,
				authStore.authUser.timezone,
				deployFreqChart.gitRepositoryIds,
				undefined,
				undefined,
				undefined,
				undefined,
				deployFreqChart.deploymentEnvironments
			);
			if (isMounted && data) {
				setIsFetching(false);
				setDeploymentFrequencyData(data);
			}
		}

		// tslint:disable-next-line: no-floating-promises
		fetch();
		return () => { isMounted = false; };
	}, [settings, deployFreqChart]);

	return (
		<>
			{isTVMode &&
				<>
					{!chartOptions && <LoadingIndicator local={true} isActive={true} />}
					{chartOptions && <HighchartsReact
						highcharts={Highcharts}
						options={chartOptions}
					/>}
				</>
			}
			{!isTVMode && (
				<>
					{(chartOptions === undefined || isFetching) && <LoadingIndicator local={true} isActive={true} />}
					{(chartOptions) && <Grid>
						<Grid.Column width={10}>
							{chartOptions && (
								<HighchartsReact
									highcharts={Highcharts}
									options={chartOptions}
								/>
							)}
						</Grid.Column>
						<Grid.Column width={6}>
							<Message size={"small"}>
								<Message.Header>KPI levels</Message.Header>
								<Message.List items={[
									`Red level from ${deployFreqChart.kpis.redFrom} to ${deployFreqChart.kpis.yellowFrom} deployments`,
									`Yellow level from ${deployFreqChart.kpis.yellowFrom} to ${deployFreqChart.kpis.greenFrom} deployments`,
									`Green level from ${deployFreqChart.kpis.greenFrom} ∞ deployments`,
								]} />
							</Message>
							{(currentKPILevel) && (
								<Message size={"small"}
									positive={currentKPILevel === CurrentKPILevel.Green}
									negative={currentKPILevel === CurrentKPILevel.Red}
									warning={currentKPILevel === CurrentKPILevel.Yellow}
									info={currentKPILevel === CurrentKPILevel.InsufficientData}>
									<Message.Header>{currentKPILevelMessage}</Message.Header>
								</Message>
							)}
							<Checkbox label="Render KPI plot bands" defaultChecked={false}
								onChange={(_e: React.SyntheticEvent<HTMLElement>, data: CheckboxProps) => {
									const shouldDraw = data.checked || false;
									setShouldDrawPlotBands(shouldDraw);
								}}
							/>
						</Grid.Column>
					</Grid>}
				</>
			)}
		</>
	);
});

// tslint:disable-next-line: variable-name
const MTTRChart = observer(({ settings, mttrChart, isTVMode }: {
	mttrChart: IMTTRChart;
	settings: IReportBuilderSettings;
	isTVMode: boolean;
}) => {
	const { metricStore, authStore } = useStores();
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const [mttrData, setMTTRData] = useState<IMTTRDORAData | undefined>();
	const { fetchData } = metricStore.mttrDataDORAStyle();
	const [chartOptions, setChartOptions] = useState<Highcharts.Options | undefined>(undefined);
	const [currentKPILevelMessage, setCurrentKPILevelMessage] = useState<string | undefined>(undefined);
	const [currentKPILevel, setCurrentKPILevel] = useState<CurrentKPILevel | undefined>(undefined);
	const [shouldDrawPlotBands, setShouldDrawPlotBands] = useState<boolean>(false);

	function fillChart(data: IMTTRDORAData, timezone: string, kpis: IChartKPIs, drawPlotBands: boolean, isInTVMode: boolean) {
		const averageLeadTimeSeriesData = prepareCategoryHourSeriesDataByDate(data.averageLeadTime, timezone);
		const averageLeadTimeSeries: Highcharts.SeriesColumnOptions = {
			id: generateUUID(),
			data: averageLeadTimeSeriesData,
			visible: averageLeadTimeSeriesData.length > 0,
			color: colorScheme.pr,
			name: "Avg lead time",
			type: "column"
		};

		let kpiLevel: CurrentKPILevel = CurrentKPILevel.InsufficientData;
		let kpiLevelMessage: string | undefined;

		if (averageLeadTimeSeriesData.length > 0 &&
			averageLeadTimeSeriesData[averageLeadTimeSeriesData.length - 1][1] > 0) {
			const currentValue = averageLeadTimeSeriesData[averageLeadTimeSeriesData.length - 1][1];
			if (currentValue <= kpis.greenTo!) {
				kpiLevel = CurrentKPILevel.Green;
			} else if (currentValue <= kpis.yellowTo) {
				kpiLevel = CurrentKPILevel.Yellow;
			} else if (currentValue > kpis.redFrom) {
				kpiLevel = CurrentKPILevel.Red;
			}
			kpiLevelMessage = `Average of ${averageLeadTimeSeriesData[averageLeadTimeSeriesData.length - 1][1]} hours (last period)`;
		} else {
			kpiLevelMessage = `We could not find any PRs in the last period.`;
		}

		const options: Highcharts.Options = {
			chart: {
				type: "column",
				height: isInTVMode ? TV_MODE_HIGHCHART_CHART_HEIGHT : undefined
			},
			title: {
				text: undefined
			},
			annotations: isInTVMode ? [{
				labels: [{
					point: {
						xAxis: 30,
						yAxis: 30,
						x: 30,
						y: 30
					},
					text: kpiLevelMessage,
					useHTML: true,
					backgroundColor: (kpiLevel === CurrentKPILevel.InsufficientData ? "#000000" :
						(kpiLevel === CurrentKPILevel.Green ? "#5AB171" :
							(kpiLevel === CurrentKPILevel.Yellow ? "#FBD66D" :
								"#C61C31"))),
				}]
			}] : [],
			xAxis: {
				gridLineWidth: 1,
				type: "datetime",
				uniqueNames: false
			},
			yAxis: [
				{
					min: 0,
					title: {
						text: "Mean time to resolve by priority",
					},
					labels: {
						// tslint:disable-next-line: object-literal-shorthand
						formatter: function _formatter() {
							return `${this.value} hr`;
						}
					},
					plotBands: drawPlotBands ? [
						{
							color: "rgba(198,28,49,0.3)",
							to: Number.MAX_SAFE_INTEGER,
							from: kpis.redFrom
						},
						{
							color: "rgba(251,214,109,0.3)",
							from: kpis.yellowFrom,
							to: kpis.yellowTo,
						},
						{
							color: "rgba(90,177,113,0.3)",
							from: 0,
							to: kpis.greenTo
						}
					] : undefined
				},
			],
			plotOptions: {
				area: {
					stacking: "normal",
				}
			},
			tooltip: {
				split: true,
				valueSuffix: " hr"
			},
			credits: {
				enabled: false
			},
			series: [averageLeadTimeSeries]
		};

		setChartOptions(options);
		setCurrentKPILevel(kpiLevel);
		setCurrentKPILevelMessage(kpiLevelMessage);
	}

	useEffect(() => {
		if (mttrData) {
			fillChart(mttrData, authStore.authUser.timezone, mttrChart.kpis, shouldDrawPlotBands, isTVMode);
		}
	}, [mttrData, shouldDrawPlotBands]);

	useDeepCompareEffect(() => {
		let isMounted = true;
		async function fetch() {
			setIsFetching(true);
			const { startTime, endTime } = calculateDateRangeFromSettings(settings);
			const data = await fetchData(settings.teamId,
				settings.dataContributorIds,
				mttrChart.jiraProjectIds,
				startTime,
				endTime,
				settings.interval,
				authStore.authUser.timezone,
				mttrChart.resolvedStatusIssueFilter,
				mttrChart.jiraTaskTypes,
				mttrChart.priorityOrders
			);
			if (isMounted && data) {
				setIsFetching(false);
				setMTTRData(data);
			}
		}

		// tslint:disable-next-line: no-floating-promises
		fetch();
		return () => { isMounted = false; };
	}, [settings, mttrChart]);

	return (
		<>
			{isTVMode &&
				<>
					{!chartOptions &&  <LoadingIndicator local={true} isActive={true} />}
					{chartOptions &&  <HighchartsReact
						highcharts={Highcharts}
						options={chartOptions}
					/>}
				</>
			}
			{!isTVMode && (
				<>
					{(chartOptions === undefined || isFetching) && <LoadingIndicator local={true} isActive={true} />}
					{(chartOptions) && <Grid>
						<Grid.Column width={10}>
							{chartOptions && (
								<HighchartsReact
									highcharts={Highcharts}
									options={chartOptions}
								/>
							)}
						</Grid.Column>
						<Grid.Column width={6}>
							<Message size={"small"}>
								<Message.Header>KPI levels</Message.Header>
								<Message.List items={[
									`Green level from ${mttrChart.kpis.greenFrom ?? 0} to ${mttrChart.kpis.greenTo} hours`,
									`Yellow level from ${mttrChart.kpis.yellowFrom} to ${mttrChart.kpis.yellowTo} hours`,
									`Red level from ${mttrChart.kpis.redFrom} to ∞ hours`
								]} />
							</Message>
							{(currentKPILevel) && (
								<Message size={"small"}
									positive={currentKPILevel === CurrentKPILevel.Green}
									negative={currentKPILevel === CurrentKPILevel.Red}
									warning={currentKPILevel === CurrentKPILevel.Yellow}
									info={currentKPILevel === CurrentKPILevel.InsufficientData}>
									<Message.Header>{currentKPILevelMessage}</Message.Header>
								</Message>
							)}
							<Checkbox label="Render KPI plot bands" defaultChecked={false}
								onChange={(_e: React.SyntheticEvent<HTMLElement>, data: CheckboxProps) => {
									const shouldDraw = data.checked || false;
									setShouldDrawPlotBands(shouldDraw);
								}}
							/>
						</Grid.Column>
					</Grid>}
				</>
			)}
		</>
	);
});

// tslint:disable-next-line: variable-name
const AveragePRSizeChart = observer(({ settings, avgPRSizeChart, isTVMode }: {
	avgPRSizeChart: IAvgPRSizeChart;
	settings: IReportBuilderSettings;
	isTVMode: boolean;
}) => {
	const { metricStore, authStore } = useStores();
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const [prSizeData, setPRSizeData] = useState<IPullRequestSizeDORAData | undefined>();
	const { fetchData } = metricStore.prSizeDataDORAStyle();
	const [chartOptions, setChartOptions] = useState<Highcharts.Options | undefined>(undefined);
	const [currentKPILevelMessage, setCurrentKPILevelMessage] = useState<string | undefined>(undefined);
	const [currentKPILevel, setCurrentKPILevel] = useState<CurrentKPILevel | undefined>(undefined);
	const [shouldDrawPlotBands, setShouldDrawPlotBands] = useState<boolean>(false);

	function prepareAverageSeriesData(prCountsInDateSeriesData: ChartSeriesData,
		locPRChangeMetric: IDashboardAcumenMetricDataResponse, timezone: string) {
		const locPRChangeMetricValues = Object.values(locPRChangeMetric.values);
		const dateToPRCount: Record<string | number, number> = prCountsInDateSeriesData.reduce((acc, val) =>
			Object.assign(acc, { [val[0]]: val[1] }), {});
		const keys = Object.keys(locPRChangeMetricValues[0]);
		return keys.map(valueKey => {
			const category = metricStringDateToUnixTime(valueKey, timezone);
			const prCountInDate = dateToPRCount[category];
			let prToLOCRatio = 0;
			if (prCountInDate && prCountInDate > 0) {
				const locPRChangeInDate = locPRChangeMetricValues[0][valueKey];

				if (locPRChangeInDate && locPRChangeInDate > 0) {
					prToLOCRatio = round((locPRChangeInDate / 1000) / prCountInDate);
				}
			}
			return [category, prToLOCRatio];
		});
	}

	function fillChart(data: IPullRequestSizeDORAData, timezone: string, kpis: IChartKPIs, drawPlotBands: boolean, isInTVMode: boolean) {
		const numberOfPRMergedSeriesData = prepareCategorySeriesDataByDate(data.pullRequestMergeCount, timezone);
		const prCountSeries: Highcharts.SeriesColumnOptions = {
			id: generateUUID(),
			data: numberOfPRMergedSeriesData,
			visible: numberOfPRMergedSeriesData.length > 0,
			color: colorScheme.pr,
			name: "# of pull requests",
			type: "column"
		};
		const averagePRSizeSeries	 = prepareAverageSeriesData(numberOfPRMergedSeriesData, data.pullRequestLinesOfCodeSum, timezone);

		let kpiLevel: CurrentKPILevel = CurrentKPILevel.InsufficientData;
		let kpiLevelMessage: string | undefined;
		if (numberOfPRMergedSeriesData.length > 0 &&
			numberOfPRMergedSeriesData[numberOfPRMergedSeriesData.length - 1][1] > 0) {
			if (averagePRSizeSeries.length > 0) {
				const currentValue = averagePRSizeSeries[averagePRSizeSeries.length - 1][1] * 1000;
				if (currentValue <= kpis.greenTo!) {
					kpiLevel = CurrentKPILevel.Green;
				} else if (currentValue <= kpis.yellowTo) {
					kpiLevel = CurrentKPILevel.Yellow;
				} else if (currentValue > kpis.redFrom) {
					kpiLevel = CurrentKPILevel.Red;
				}
				kpiLevelMessage = `Found ${numberOfPRMergedSeriesData[numberOfPRMergedSeriesData.length - 1][1]} PRs with average of ${averagePRSizeSeries[averagePRSizeSeries.length - 1][1]} K LOC / #PRs`;
			}
		} else {
			kpiLevelMessage = `We could not find any PRs in the last period.`;
		}

		const avgPRSizeSeries: Highcharts.SeriesSplineOptions = {
			id: generateUUID(),
			type: "spline",
			tooltip: {
				valueSuffix: " (K LOC / #PRs)"
			},
			name: "Average pull request size",
			yAxis: 1,
			marker: {
				symbol: "circle"
			},
			data: averagePRSizeSeries,
			visible: averagePRSizeSeries.length > 0,
			dashStyle: "Solid",
			color: (kpiLevel === CurrentKPILevel.InsufficientData ? "#000000" :
					(kpiLevel === CurrentKPILevel.Green ? "#5AB171" :
					(kpiLevel === CurrentKPILevel.Yellow ? "#FBD66D" :
					"#C61C31"))),
			lineWidth: 3,
		};

		const options: Highcharts.Options = {
			chart: {
				type: "column",
				height: isInTVMode ? TV_MODE_HIGHCHART_CHART_HEIGHT : undefined
			},
			title: {
				text: undefined
			},
			annotations: isInTVMode ? [{
				labels: [{
					point: {
						xAxis: 30,
						yAxis: 30,
						x: 30,
						y: 30
					},
					text: kpiLevelMessage,
					useHTML: true,
					backgroundColor: (kpiLevel === CurrentKPILevel.InsufficientData ? "#000000" :
						(kpiLevel === CurrentKPILevel.Green ? "#5AB171" :
							(kpiLevel === CurrentKPILevel.Yellow ? "#FBD66D" :
								"#C61C31"))),
				}]
			}] : [],
			xAxis: {
				gridLineWidth: 1,
				type: "datetime",
				uniqueNames: false
			},
			yAxis: [
				{
					min: 0,
					title: {
						text: "# of pull requests"
					}
				},
				{
					min: 0,
					title: {
						text: "Average pull request size (lines of code)",
					},
					labels: {
						// tslint:disable-next-line: object-literal-shorthand
						formatter: function _formatter() {
							return `${this.value}k`;
						}
					},
					plotBands: drawPlotBands ? [
						{
							color: "rgba(198,28,49,0.3)",
							to: Number.MAX_SAFE_INTEGER,
							from: kpis.redFrom / 1000
						},
						{
							color: "rgba(251,214,109,0.3)",
							from: kpis.yellowFrom / 1000,
							to: kpis.yellowTo / 1000,
						},
						{
							color: "rgba(90,177,113,0.3)",
							from: 0,
							to: kpis.greenTo! / 1000
						}
					] : undefined,
					opposite: true
				},
			],
			plotOptions: {
				area: {
					stacking: "normal",
				}
			},
			tooltip: {
				split: true,
				valueSuffix: " PRs"
			},
			credits: {
				enabled: false
			},
			series: [prCountSeries, avgPRSizeSeries]
		};

		setChartOptions(options);
		setCurrentKPILevel(kpiLevel);
		setCurrentKPILevelMessage(kpiLevelMessage);
	}

	useEffect(() => {
		if (prSizeData) {
			fillChart(prSizeData, authStore.authUser.timezone, avgPRSizeChart.kpis, shouldDrawPlotBands, isTVMode);
		}
	}, [prSizeData, shouldDrawPlotBands, isTVMode]);

	useDeepCompareEffect(() => {
		let isMounted = true;
		async function fetch() {
			setIsFetching(true);
			const { startTime, endTime } = calculateDateRangeFromSettings(settings);
			const data = await fetchData(settings.teamId,
				settings.dataContributorIds,
				undefined,
				startTime,
				endTime,
				settings.interval,
				authStore.authUser.timezone,
				avgPRSizeChart.gitRepositoryIds,
				undefined,
				avgPRSizeChart.filterPRToExcludeDraft
			);
			if (isMounted && data) {
				setIsFetching(false);
				setPRSizeData(data);
			}
		}

		// tslint:disable-next-line: no-floating-promises
		fetch();
		return () => { isMounted = false; };
	}, [settings, avgPRSizeChart]);

	return (
		<>
		{isTVMode &&
			<>
				{!chartOptions && <LoadingIndicator local={true} isActive={true} />}
				{chartOptions && <HighchartsReact
					highcharts={Highcharts}
					options={chartOptions}
				/>}
			</>
		}
		{!isTVMode && (
				<>
					{(chartOptions === undefined || isFetching) && <LoadingIndicator local={true} isActive={true} />}
					{(chartOptions) && <Grid>
						<Grid.Column width={10}>
							{chartOptions && (
								<HighchartsReact
									highcharts={Highcharts}
									options={chartOptions}
								/>
							)}
						</Grid.Column>
						<Grid.Column width={6}>
							<Message size={"small"}>
								<Message.Header>KPI levels</Message.Header>
								<Message.List items={[
									`Green level from ${avgPRSizeChart.kpis.greenFrom ?? 0} to ${avgPRSizeChart.kpis.greenTo} lines of code`,
									`Yellow level from ${avgPRSizeChart.kpis.yellowFrom} to ${avgPRSizeChart.kpis.yellowTo} lines of code`,
									`Red level from ${avgPRSizeChart.kpis.redFrom} to ∞ lines of code`
								]} />
							</Message>
							{(currentKPILevel) && (
								<Message size={"small"}
									positive={currentKPILevel === CurrentKPILevel.Green}
									negative={currentKPILevel === CurrentKPILevel.Red}
									warning={currentKPILevel === CurrentKPILevel.Yellow}
									info={currentKPILevel === CurrentKPILevel.InsufficientData}>
									<Message.Header>{currentKPILevelMessage}</Message.Header>
								</Message>
							)}
							<Checkbox label="Render KPI plot bands" defaultChecked={false}
								onChange={(_e: React.SyntheticEvent<HTMLElement>, data: CheckboxProps) => {
									const shouldDraw = data.checked || false;
									setShouldDrawPlotBands(shouldDraw);
								}}
							/>
						</Grid.Column>
					</Grid>}
				</>
		)}
		</>

	);
});

// tslint:disable-next-line: variable-name
const ChartViewer = observer((props: IChartViewerProps) => {
	const [isTVMode, setIsTVMode] = useState<boolean>(false);
	return (
		<Form>
			<Segment.Group horizontal={true} basic="true" secondary="true">
				<Segment basic={true}>
					<Header size={isTVMode ? "medium" : "large"}>KPI metrics</Header>
				</Segment>

				<Segment basic={true} textAlign="right"><Checkbox toggle={true} label="TV Mode" checked={isTVMode} onChange={(_e: React.SyntheticEvent<HTMLElement>, data: CheckboxProps) => {
					const shouldDraw = data.checked || false;
					setIsTVMode(shouldDraw);
				}}/></Segment>
			</Segment.Group>

			{isTVMode && (
				<Grid columns={2}>
					<Grid.Row>
						<Grid.Column>
							<Header size="small">
								{METRIC_GROUP_TYPE_OPTIONS[props.avgPRSizeChart.type]}
							</Header>
							<AveragePRSizeChart
								avgPRSizeChart={props.avgPRSizeChart}
								settings={props.settings}
								isTVMode={isTVMode}
							/>
						</Grid.Column>
						<Grid.Column>
							<Header size="small">
								{METRIC_GROUP_TYPE_OPTIONS[props.mttrChart.type]}
							</Header>
							<MTTRChart
								mttrChart={props.mttrChart}
								settings={props.settings}
								isTVMode={isTVMode}
							/>
						</Grid.Column>
						<Grid.Column>
							<Header size="small">
								{METRIC_GROUP_TYPE_OPTIONS[props.deployFreqChart.type]}
							</Header>
							<DeploymentFrequencyChart
								deployFreqChart={props.deployFreqChart}
								settings={props.settings}
								isTVMode={isTVMode}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			)}
			{!isTVMode && (
				<div>
					<Header size="medium">
						{METRIC_GROUP_TYPE_OPTIONS[props.avgPRSizeChart.type]}
					</Header>
					<AveragePRSizeChart
						avgPRSizeChart={props.avgPRSizeChart}
						settings={props.settings}
						isTVMode={isTVMode}
					/>
					<Header size="medium">
						{METRIC_GROUP_TYPE_OPTIONS[props.mttrChart.type]}
					</Header>
					<MTTRChart
						mttrChart={props.mttrChart}
						settings={props.settings}
						isTVMode={isTVMode}
					/>
					<Header size="medium">
						{METRIC_GROUP_TYPE_OPTIONS[props.deployFreqChart.type]}
					</Header>
					<DeploymentFrequencyChart
						deployFreqChart={props.deployFreqChart}
						settings={props.settings}
						isTVMode={isTVMode}
					/>
				</div>
			)}
			<Form.Group inline={true}>
				<Form.Field
					id="form-button-control-display-chart-back-stage"
					key="form-button-control-display-chart-back-stage"
					control={Button}
					primary={false}
					basic={true}
					color="grey"
					content="Back"
					size="large"
					onClick={() => props.previousStage()}
				/>
			</Form.Group>
		</Form>
	);
});

interface IDeploymentFrequencyChartFormProps {
	teamId: string;
	series?: IDeploymentFrequencyChart;
	onSave: (series: IDeploymentFrequencyChart) => void;
}

// tslint:disable-next-line: variable-name
const DeploymentFrequencyChartForm = observer(({ teamId, series, onSave }: IDeploymentFrequencyChartFormProps) => {
	const RED_KPI_FROM_DEFAULT_VALUE = 0;
	const YELLOW_KPI_FROM_DEFAULT_VALUE = 1;
	const GREEN_KPI_FROM_DEFAULT_VALUE = 2;

	const [chartSeries, setChartSeries] = useState<IDeploymentFrequencyChart>(series ? series : {
		type: SupportedCharts.DeploymentFrequency,
		deploymentEnvironments: SUPPORTED_DEPLOYED_ENV,
		gitRepositoryIds: [],
		kpis: {
			redFrom: RED_KPI_FROM_DEFAULT_VALUE,
			redTo: YELLOW_KPI_FROM_DEFAULT_VALUE,
			yellowFrom: YELLOW_KPI_FROM_DEFAULT_VALUE,
			yellowTo: GREEN_KPI_FROM_DEFAULT_VALUE,
			greenFrom: GREEN_KPI_FROM_DEFAULT_VALUE,
			greenTo: undefined,
		}
	});

	const {
		repositoriesStore
	} = useStores();
	const { fetchData: fetchGitRepoData, isLoading: isLoadingGitRepos, gitRepositoriesData } = repositoriesStore;
	const [availableGitRepositories, setGitRepositoriesData] = useState<DropdownItemProps[]>([]);
	const [kpiLevelsBreakdown, setKPILevelsBreakdown] = useState<string[] | undefined>(refreshKPILevelsBreakdown(chartSeries));

	function refreshKPILevelsBreakdown(s: IDeploymentFrequencyChart): string[] {
		const greenLevelFrom = s.kpis.greenFrom ?? GREEN_KPI_FROM_DEFAULT_VALUE;
		const yellowLevelFrom = s.kpis.yellowFrom ?? YELLOW_KPI_FROM_DEFAULT_VALUE;
		return [
			`Red level from ${0} to ${yellowLevelFrom} deployments`,
			`Yellow level from ${yellowLevelFrom} to ${greenLevelFrom} deployments`,
			`Green level from ${greenLevelFrom} ∞ deployments`,
		];
	}
	useEffect(() => {
		async function retrieveGitRepositories() {
			return !isLoadingGitRepos &&
				await fetchGitRepoData({ teamId }, { order: DashboardSortOrder.Ascending });
		}
		// tslint:disable-next-line: no-floating-promises
		retrieveGitRepositories();
	}, [teamId]);

	useEffect(() => {
		if (!gitRepositoriesData) {
			setGitRepositoriesData([]);
			return;
		}

		const repositories: IDashboardGitRepository[] = [];

		if (gitRepositoriesData.ownable.length !== 0) {
			repositories.push(...gitRepositoriesData.ownable);
		}
		if (gitRepositoriesData.external.length !== 0) {
			repositories.push(...gitRepositoriesData.external);
		}

		setGitRepositoriesData(repositories.map(p => ({
			key: `${p.entityType}-${p.entityId}`,
			text: p.name,
			value: p.entityId
		})));
	}, [gitRepositoriesData]);

	const handleSubmit = () => {
		onSave(chartSeries);
	};

	return (
		<Segment key={`chart-segment-deployment-frequency`} loading={isLoadingGitRepos}>
			<Header>
				{METRIC_GROUP_TYPE_OPTIONS[chartSeries.type]}
			</Header>
			<Form.Group>
				<Form.Field
					width={12}
					control={Select}
					options={availableGitRepositories}
					loading={isLoadingGitRepos}
					label={{ children: "Filter by Git repository", htmlFor: "form-select-control-git-repository" }}
					placeholder="All Git Repositories"
					search={true}
					multiple={true}
					clearable={true}
					value={chartSeries.gitRepositoryIds}
					onChange={(_e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
						const gitRepositoryIds = ((data && data.value) ? data.value as string[] : []);
						setChartSeries(_.assign({}, chartSeries, {
							gitRepositoryIds
						}));
					}}
					key="form-select-control-git-repository"
					searchInput={{ id: "form-select-control-git-repository" }}
				/>

				<Form.Field
					control={Select}
					width={4}
					options={Object.values(SUPPORTED_DEPLOYED_ENV).map(x => ({
						key: x,
						value: x,
						text: x
					})).sort((a, b) => a.text.localeCompare(b.text))}
					multiple={true}
					value={chartSeries.deploymentEnvironments}
					onChange={(_e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
						const deploymentEnvironments = ((data && data.value) ? data.value as string[] : []);
						setChartSeries(_.assign({}, chartSeries, {
							deploymentEnvironments
						}));
					}}
					label={{ children: "Filter by environment", htmlFor: "form-select-control-filter-by-environment" }}
					placeholder="Filter by environment"
					search={false}
					key="form-select-control-filter-by-environment"
					searchInput={{ id: "form-select-control-filter-by-environment" }}
				/>
			</Form.Group>

			<Header size="medium">
				KPIs
			</Header>
			<Form.Group width="inline">
				<Form.Input label="From yellow (deployments)" type="number" defaultValue={YELLOW_KPI_FROM_DEFAULT_VALUE}
					onChange={(_e: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData) => {
						chartSeries.kpis.yellowFrom = Number.parseInt(data.value as string, 10);
						chartSeries.kpis.redTo = chartSeries.kpis.yellowFrom;
						setChartSeries(chartSeries);
						setKPILevelsBreakdown(refreshKPILevelsBreakdown(chartSeries));
					}}
				/>
				<Form.Input label="From green level (deployments)" type="number" color="green" defaultValue={GREEN_KPI_FROM_DEFAULT_VALUE}
					onChange={(_e: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData) => {
						chartSeries.kpis.yellowTo = Number.parseInt(data.value as string, 10);
						chartSeries.kpis.greenFrom = chartSeries.kpis.yellowTo;
						setChartSeries(chartSeries);
						setKPILevelsBreakdown(refreshKPILevelsBreakdown(chartSeries));
					}} />
			</Form.Group>
			<Message size={"small"}>
				<Message.Header>KPI levels breakdown</Message.Header>
				<Message.List items={kpiLevelsBreakdown} />
			</Message>
			{/* Save buttons */}
			<Form.Group width="inline">
				<Form.Field
					id={`form-select-control-chart-single-metric-save-avg-pr-size`}
					key={`form-select-control-chart-single-metric-save-avg-pr-size`}
					control={Button}
					primary={true}
					icon={true}
					content="Save"
					size="large"
					onClick={() => handleSubmit()}
				/>
			</Form.Group>
			<Message
				hidden={(!isLoadingGitRepos && series === chartSeries) ? false : true}
				positive={true}
				size="mini">
				<Icon name="checkmark" />
				{`Saved deployment frequency details`}
			</Message>
		</Segment>
	);
});
interface IAvgPRSizeChartFormProps {
	teamId: string;
	series?: IAvgPRSizeChart;
	onSave: (series: IAvgPRSizeChart) => void;
}

// tslint:disable-next-line: variable-name
const AvgPRSizeChartForm = observer(({ teamId, series, onSave }: IAvgPRSizeChartFormProps) => {
	const GREEN_KPI_FROM_DEFAULT_VALUE = 0;
	const GREEN_KPI_TO_DEFAULT_VALUE = 500;
	const YELLOW_KPI_TO_DEFAULT_VALUE = 2000;

	const [chartSeries, setChartSeries] = useState<IAvgPRSizeChart>(series ? series : {
		type: SupportedCharts.AveragePRSizeOverTime,
		filterPRToExcludeDraft: true,
		gitRepositoryIds: [],
		kpis: {
			greenFrom: GREEN_KPI_FROM_DEFAULT_VALUE,
			greenTo: GREEN_KPI_TO_DEFAULT_VALUE,
			yellowFrom: GREEN_KPI_TO_DEFAULT_VALUE,
			yellowTo: YELLOW_KPI_TO_DEFAULT_VALUE,
			redFrom: YELLOW_KPI_TO_DEFAULT_VALUE,
			redTo: undefined
		}
	});

	const {
		repositoriesStore
	} = useStores();
	const { fetchData: fetchGitRepoData, isLoading: isLoadingGitRepos, gitRepositoriesData } = repositoriesStore;
	const [availableGitRepositories, setGitRepositoriesData] = useState<DropdownItemProps[]>([]);
	const [kpiLevelsBreakdown, setKPILevelsBreakdown] = useState<string[] | undefined>(refreshKPILevelsBreakdown(chartSeries));

	function refreshKPILevelsBreakdown(s: IAvgPRSizeChart): string[] {
		const greenLevelTo = s.kpis.greenTo ?? GREEN_KPI_TO_DEFAULT_VALUE;
		const yellowLevelTo = s.kpis.yellowTo ?? YELLOW_KPI_TO_DEFAULT_VALUE;
		return [
			`Green level from ${GREEN_KPI_FROM_DEFAULT_VALUE} to ${greenLevelTo} lines of code`,
			`Yellow level from ${greenLevelTo} to ${yellowLevelTo} lines of code`,
			`Red level from ${yellowLevelTo} to ∞ lines of code`
		];
	}
	useEffect(() => {
		async function retrieveGitRepositories() {
			return !isLoadingGitRepos &&
				await fetchGitRepoData({ teamId }, { order: DashboardSortOrder.Ascending });
		}
		// tslint:disable-next-line: no-floating-promises
		retrieveGitRepositories();
	}, [teamId]);

	useEffect(() => {
		if (!gitRepositoriesData) {
			setGitRepositoriesData([]);
			return;
		}

		const repositories: IDashboardGitRepository[] = [];

		if (gitRepositoriesData.ownable.length !== 0) {
			repositories.push(...gitRepositoriesData.ownable);
		}
		if (gitRepositoriesData.external.length !== 0) {
			repositories.push(...gitRepositoriesData.external);
		}

		setGitRepositoriesData(repositories.map(p => ({
			key: `${p.entityType}-${p.entityId}`,
			text: p.name,
			value: p.entityId
		})));
	}, [gitRepositoriesData]);

	const handleSubmit = () => {
		onSave(chartSeries);
	};

	return (
		<Segment key={`chart-segment-avg-pr-size`} loading={isLoadingGitRepos}>
			<Header>
				{METRIC_GROUP_TYPE_OPTIONS[chartSeries.type]}
			</Header>
			<Form.Group>
				<Form.Field
					width={12}
					control={Select}
					options={availableGitRepositories}
					loading={isLoadingGitRepos}
					label={{ children: "Filter by Git repository", htmlFor: "form-select-control-git-repository" }}
					placeholder="All Git Repositories"
					search={true}
					multiple={true}
					clearable={true}
					value={chartSeries.gitRepositoryIds}
					onChange={(_e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
						const gitRepositoryIds = ((data && data.value) ? data.value as string[] : []);
						setChartSeries(_.assign({}, chartSeries, {
							gitRepositoryIds
						}));
					}}
					key="form-select-control-git-repository"
					searchInput={{ id: "form-select-control-git-repository" }}
				/>

				<Form.Field
					width={4}
					control={Checkbox}
					toggle={true}
					label="Exclude draft PRs"
					checked={chartSeries.filterPRToExcludeDraft}
					onChange={(_e: React.SyntheticEvent<HTMLElement>, data: CheckboxProps) => {
						const filterPRToExcludeDraft = data.checked || false;
						setChartSeries(_.assign({}, chartSeries, {
							filterPRToExcludeDraft
						}));
					}}
					key="form-select-control-exclude-draft-PRs"
				/>
			</Form.Group>

			<Header size="medium">
				KPIs
			</Header>
			<Form.Group width="inline">
				<Form.Input label="Upper green level (lines)" type="number" color="green" defaultValue={GREEN_KPI_TO_DEFAULT_VALUE}
					onChange={(_e: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData) => {
						chartSeries.kpis.greenTo = Number.parseInt(data.value as string, 10);
						chartSeries.kpis.yellowFrom = chartSeries.kpis.greenTo;
						setChartSeries(chartSeries);
						setKPILevelsBreakdown(refreshKPILevelsBreakdown(chartSeries));
					}} />
				<Form.Input label="Upper yellow (lines)" type="number" defaultValue={YELLOW_KPI_TO_DEFAULT_VALUE}
					onChange={(_e: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData) => {
						chartSeries.kpis.yellowTo = Number.parseInt(data.value as string, 10);
						chartSeries.kpis.redFrom = chartSeries.kpis.yellowTo;
						setChartSeries(chartSeries);
						setKPILevelsBreakdown(refreshKPILevelsBreakdown(chartSeries));
					}}
				/>
			</Form.Group>
			<Message size={"small"}>
				<Message.Header>KPI levels breakdown</Message.Header>
				<Message.List items={kpiLevelsBreakdown} />
			</Message>
			{/* Save buttons */}
			<Form.Group width="inline">
				<Form.Field
					id={`form-select-control-chart-single-metric-save-avg-pr-size`}
					key={`form-select-control-chart-single-metric-save-avg-pr-size`}
					control={Button}
					primary={true}
					icon={true}
					content="Save"
					size="large"
					onClick={() => handleSubmit()}
				/>
			</Form.Group>
			<Message
				hidden={(!isLoadingGitRepos && series === chartSeries) ? false : true}
				positive={true}
				size="mini">
				<Icon name="checkmark" />
				{`Saved average PR size details`}
			</Message>
		</Segment>
	);
});

interface IMTTRChartFormProps {
	teamId: string;
	series?: IMTTRChart;
	onSave: (series: IMTTRChart) => void;
}

// tslint:disable-next-line: variable-name
const MTTRChartForm = observer(({ teamId, series, onSave }: IMTTRChartFormProps) => {
	const GREEN_KPI_FROM_DEFAULT_VALUE = 0;
	const GREEN_KPI_TO_DEFAULT_VALUE = 24;
	const YELLOW_KPI_TO_DEFAULT_VALUE = 96;

	const [chartSeries, setChartSeries] = useState<IMTTRChart>(series ? series : {
		type: SupportedCharts.MTTROverTime,
		jiraProjectIds: [],
		jiraTaskTypes: [],
		priorityOrders: [Priority.P0, Priority.P1, Priority.P2],
		resolvedStatusIssueFilter: taskGroupToStatusMapping[AcumenTaskStatusGroup.Done]
			.filter(status => status !== AcumenTaskStatus.Discarded),
		kpis: {
			greenFrom: GREEN_KPI_FROM_DEFAULT_VALUE,
			greenTo: GREEN_KPI_TO_DEFAULT_VALUE,
			yellowFrom: GREEN_KPI_TO_DEFAULT_VALUE,
			yellowTo: YELLOW_KPI_TO_DEFAULT_VALUE,
			redFrom: YELLOW_KPI_TO_DEFAULT_VALUE,
			redTo: undefined
		}
	});

	const {
		projectsStore
	} = useStores();
	const { fetchData: fetchProjectsData, isLoading: isLoadingProjects, projectsData } = projectsStore;
	const [availableProjects, setProjectsData] = useState<DropdownItemProps[]>([]);
	const [kpiLevelsBreakdown, setKPILevelsBreakdown] = useState<string[] | undefined>(refreshKPILevelsBreakdown(chartSeries));
	const [triedToSubmitWithError, setTriedToSubmitWithError] = useState<boolean>(false);

	function refreshKPILevelsBreakdown(s: IMTTRChart): string[] {
		const greenLevelTo = s.kpis.greenTo ?? GREEN_KPI_TO_DEFAULT_VALUE;
		const yellowLevelTo = s.kpis.yellowTo ?? YELLOW_KPI_TO_DEFAULT_VALUE;
		return [
			`Green level from ${GREEN_KPI_FROM_DEFAULT_VALUE} to ${greenLevelTo} lines of code`,
			`Yellow level from ${greenLevelTo} to ${yellowLevelTo} lines of code`,
			`Red level from ${yellowLevelTo} to ∞ lines of code`
		];
	}
	useEffect(() => {
		async function retrieveProjects() {
			return !isLoadingProjects &&
				await fetchProjectsData({ teamId }, { order: DashboardSortOrder.Ascending });
		}
		// tslint:disable-next-line: no-floating-promises
		retrieveProjects();
	}, [teamId]);

	useEffect(() => {
		if (!projectsData) {
			setProjectsData([]);
			return;
		}

		const projects: IDashboardProject[] = [];

		if (projectsData.ownable.length !== 0) {
			projects.push(...projectsData.ownable);
		}
		if (projectsData.external.length !== 0) {
			projects.push(...projectsData.external);
		}

		setProjectsData(projects.map(p => ({
			key: `${p.entityType}-${p.entityId}`,
			text: p.name,
			value: p.entityId
		})));
	}, [projectsData]);

	const handleSubmit = () => {
		if (chartSeries.resolvedStatusIssueFilter.length === 0) {
			setTriedToSubmitWithError(true);
			return;
		}
		setTriedToSubmitWithError(false);
		onSave(chartSeries);
	};

	return (
		<Segment key={`chart-segment-mttr`} loading={isLoadingProjects}>
			<Header>
				{METRIC_GROUP_TYPE_OPTIONS[chartSeries.type]}
			</Header>
			<Form.Group>
				<Form.Field
					width={10}
					control={Select}
					options={availableProjects}
					loading={isLoadingProjects}
					label={{ children: "Filter by Jira project", htmlFor: "form-select-control-jira-project" }}
					placeholder="All Jira Projects"
					search={true}
					multiple={true}
					clearable={true}
					value={chartSeries.jiraProjectIds}
					onChange={(_e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
						const jiraProjectIds = ((data && data.value) ? data.value as string[] : []);
						setChartSeries(_.assign({}, chartSeries, {
							jiraProjectIds
						}));
					}}
					key="form-select-control-jira-project"
					searchInput={{ id: "form-select-control-jira-project" }}
				/>

				<Form.Field
					control={Select}
					width={6}
					options={Object.values(AcumenTaskType).map(x => ({
						key: x,
						value: x,
						text: x
					})).sort((a, b) => a.text.localeCompare(b.text))}
					multiple={true}
					value={chartSeries.jiraTaskTypes}
					onChange={(_e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
						const jiraTaskTypes = ((data && data.value) ? data.value as AcumenTaskType[] : []);
						setChartSeries(_.assign({}, chartSeries, {
							jiraTaskTypes
						}));
					}}
					label={{ children: "Task Types", htmlFor: "form-select-control-task-types" }}
					placeholder="Task Types"
					search={true}
					key="form-select-control-task-types"
					searchInput={{ id: "form-select-control-task-types" }}
				/>
			</Form.Group>

			<Form.Group>
				<Form.Field
					control={Select}
					width={8}
					options={Object.values(AcumenTaskStatus).map(x => ({
						key: x,
						value: x,
						text: x
					})).sort((a, b) => a.text.localeCompare(b.text))}
					required={true}
					multiple={true}
					error={triedToSubmitWithError && chartSeries.resolvedStatusIssueFilter.length === 0}
					value={chartSeries.resolvedStatusIssueFilter}
					onChange={(_e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
						const resolvedStatusIssueFilter = ((data && data.value) ? data.value as AcumenTaskStatus[] : []);
						setChartSeries(_.assign({}, chartSeries, {
							resolvedStatusIssueFilter
						}));
					}}
					label={{ children: "Resolved Task Status", htmlFor: "form-select-control-task-status" }}
					placeholder="Resolved Task Status"
					search={true}
					key="form-select-control-task-status"
					searchInput={{ id: "form-select-control-task-status" }}
				/>
				<Form.Field
					control={Select}
					width={8}
					options={Object.keys(PRIORITY_OPTIONS_TO_LABEL).map((key: string | number) => {
						const _key = key as keyof typeof PRIORITY_OPTIONS_TO_LABEL;
						return ({
							key: _key,
							text: PRIORITY_OPTIONS_TO_LABEL[_key],
							value: key,
						});
					})}
					multiple={true}
					value={chartSeries.priorityOrders}
					onChange={(_e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
						const priorityOrders = ((data && data.value) ? data.value as number[] : []);
						setChartSeries(_.assign({}, chartSeries, {
							priorityOrders
						}));
					}}
					label={{ children: "Priorities to take into account", htmlFor: "form-select-control-task-priorities" }}
					placeholder="Task Priorities"
					search={true}
					key="form-select-control-task-priorities"
					searchInput={{ id: "form-select-control-task-priorities" }}
				/>
			</Form.Group>
			<Header size="medium">
				KPIs
			</Header>
			<Form.Group width="inline">
				<Form.Input label="Upper green level (hours)" type="number" color="green" defaultValue={GREEN_KPI_TO_DEFAULT_VALUE}
					onChange={(_e: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData) => {
						chartSeries.kpis.greenTo = Number.parseInt(data.value as string, 10);
						chartSeries.kpis.yellowFrom = chartSeries.kpis.greenTo;
						setChartSeries(chartSeries);
						setKPILevelsBreakdown(refreshKPILevelsBreakdown(chartSeries));
					}} />
				<Form.Input label="Upper yellow (hours)" type="number" defaultValue={YELLOW_KPI_TO_DEFAULT_VALUE}
					onChange={(_e: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData) => {
						chartSeries.kpis.yellowTo = Number.parseInt(data.value as string, 10);
						chartSeries.kpis.redFrom = chartSeries.kpis.yellowTo;
						setChartSeries(chartSeries);
						setKPILevelsBreakdown(refreshKPILevelsBreakdown(chartSeries));
					}}
				/>
			</Form.Group>
			<Message size={"small"}>
				<Message.Header>KPI levels breakdown</Message.Header>
				<Message.List items={kpiLevelsBreakdown} />
			</Message>
			{/* Save buttons */}
			<Form.Group width="inline">
				<Form.Field
					id={`form-select-control-chart-single-metric-save-mttr`}
					key={`form-select-control-chart-single-metric-save-mttr`}
					control={Button}
					primary={true}
					icon={true}
					content="Save"
					size="large"
					onClick={() => handleSubmit()}
				/>
			</Form.Group>
			<Message
				hidden={(!isLoadingProjects && series === chartSeries && !triedToSubmitWithError) ? false : true}
				positive={true}
				size="mini">
				<Icon name="checkmark" />
				{`Saved MTTR details`}
			</Message>
		</Segment>
	);
});
interface ISettingsTabProps {
	settings: IReportBuilderSettings;
	onChange: (settings: IReportBuilderSettings) => void;
}

// tslint:disable-next-line: variable-name
const SettingsTab = observer(({ settings, onChange }: ISettingsTabProps) => {
	const {
		teamsStore,
		teamMembersStore
	} = useStores();

	const [searchParameters, setSearchParameters] = useState<IReportBuilderSettings>(settings);
	const { allTeams } = teamsStore!;
	const [availableTeamMembers, setTeamMembers] = useState<DropdownItemProps[]>([]);

	useDeepCompareEffect(() => {
		setSearchParameters(settings);
	}, [settings]);

	useEffect(() => {
		if (allTeams.loading || allTeams.data.length > 0) {
			return;
		}

		teamsStore.fetchAllTeams().then().catch();
		setTeamMembers([]);
		return function unmount() {
			teamsStore.resetTeams();
		};
	}, []);

	useEffect(() => {
		if (!teamMembersStore.allTeamMembers.data) {
			setTeamMembers([]);
			return;
		}

		setTeamMembers(teamMembersStore.allTeamMembers.data.map(p => ({
			key: `member-${p.teamId}-${p.dataContributorId}`,
			text: p.displayName ? p.displayName : `[Unknown Member]: ${p.dataContributorId}`,
			value: p.dataContributorId,
			image: { avatar: true, src: p.avatarUrl }
		})));
	}, [teamMembersStore.allTeamMembers.data]);

	useEffect(() => {
		if (!searchParameters.teamId) {
			return;
		}
		async function retrieveMembers(selectedTeamId: string) {
			await teamMembersStore.fetchAllTeamMembers(selectedTeamId);
		}

		// tslint:disable-next-line: no-floating-promises
		retrieveMembers(searchParameters.teamId);
	}, [searchParameters.teamId]);

	return (
		<Form>
			<Form.Group widths="equal">
				<Form.Field
					control={Select}
					options={_.orderBy(allTeams.data, t => t.name.toLowerCase(), "asc").map(team => ({
						key: team.id,
						value: team.id,
						text: team.name
					}))}
					loading={allTeams.loading}
					label={{ children: "Filter by team", htmlFor: "form-select-control-team" }}
					placeholder="Select a team"
					required={true}
					search={true}
					clearable={false}
					value={searchParameters.teamId}
					onChange={(_e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => setSearchParameters({
						...searchParameters,
						teamId: ((data && data.value) ? data.value as string : undefined)
					})}
					error={searchParameters.teamId === undefined}
					key="form-select-control-team"
					searchInput={{ id: "form-select-control-team" }}
				/>

				<Form.Field
					control={Select}
					options={availableTeamMembers}
					loading={allTeams.loading || teamMembersStore.allTeamMembers.loading}
					label={{ children: "Filter by team members", htmlFor: "form-select-control-team-members" }}
					placeholder="Team Members"
					search={true}
					multiple={true}
					clearable={true}
					value={searchParameters.dataContributorIds}
					onChange={(_e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => setSearchParameters({
						...searchParameters,
						dataContributorIds: ((data && data.value) ? data.value as string[] : [])
					})}
					key="form-select-control-team-members"
					searchInput={{ id: "form-select-control-team-members" }}
				/>
				<Form.Field
					control={Select}
					options={Object.keys(TIME_SPAN_OPTIONS).map((key: string | number) => {
						const _key = key as keyof typeof TIME_SPAN_OPTIONS;
						return ({
							key: _key,
							text: TIME_SPAN_OPTIONS[_key],
							value: key,
						});
					})}
					required={true}
					value={(searchParameters?.timeSpan)}
					onChange={(_e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => setSearchParameters({
						...searchParameters,
						timeSpan: data.value as chartsTimeSpan
					})}
					label={{ children: "Time Span", htmlFor: "form-select-control-time-span" }}
					placeholder="Time Span"
					search={true}
					key="form-select-control-time-span"
					searchInput={{ id: "form-select-control-time-span" }}
				/>
				<Form.Field
					control={Select}
					options={Object.keys(TIME_INTERVAL_OPTIONS_TO_LABEL).map((key: string | number) => {
						const _key = key as keyof typeof TIME_INTERVAL_OPTIONS_TO_LABEL;
						return ({
							key: _key,
							text: TIME_INTERVAL_OPTIONS_TO_LABEL[_key],
							value: key,
						});
					})}
					required={true}
					value={searchParameters?.interval}
					onChange={(_e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => setSearchParameters({
						...searchParameters,
						interval: data.value as Exclude<MetricInterval, MetricInterval.SPRINT | MetricInterval.DEV_CYCLE>
					})}
					label={{ children: "Resolution", htmlFor: "form-select-control-resolution" }}
					placeholder="Resolution"
					search={true}
					key="form-select-control-resolution"
					searchInput={{ id: "form-select-control-resolution" }}
				/>
			</Form.Group>
			<Form.Group inline={true}>
				<Form.Field
					id="form-button-control-settings-next-stage"
					key="form-select-control-settings-next-stage"
					control={Button}
					primary={true}
					icon={true}
					content="Next"
					size="large"
					disabled={searchParameters.teamId === undefined}
					onClick={() => onChange(searchParameters)}
				/>
			</Form.Group>
		</Form>
	);
});

const SETTINGS_URL_PARAM_NAME = "settings";
const STAGE_URL_PARAM_NAME = "stage";
const AVG_PR_SIZE_URL_PARAM_NAME = "averagePRSize";
const MTTR_URL_PARAM_NAME = "mttr";
const DEPLOY_FERQ_URL_PARAM_NAME = "deployFreq";

export default function ReportBuilderPage() {
	const location = useLocation();
	const currentQsParams = new URLSearchParams(location.search);

	const [builderStage, setBuilderStage] = useState<BuilderStage>(BuilderStage.Settings);
	const [settings, setSettings] = useState<IReportBuilderSettings>(DEFAULT_SETTINGS);
	const [averagePRSizeChart, setAveragePRSizeChart] = useState<IAvgPRSizeChart | undefined>();
	const [mttrChart, setMTTRChart] = useState<IMTTRChart | undefined>();
	const [deployFreqChart, setDeployFreqChart] = useState<IDeploymentFrequencyChart | undefined>();

	const [shouldRefreshQS, setShouldRefreshQS] = useState<boolean>(false);

	useEffect(() => {
		setBuilderStage(defaultBuildStageFromQuerySearch(currentQsParams));
		setSettings(defaultSettingsFromQuerySearch(currentQsParams));
		setAveragePRSizeChart(defaultAvgPRSizeFromQuerySearch(currentQsParams));
		setMTTRChart(defaultMTTRChartFromQuerySearch(currentQsParams));
		setDeployFreqChart(defaultDeployFreqChartFromQuerySearch(currentQsParams));
	}, []);

	const defaultSettingsFromQuerySearch = (qs: URLSearchParams) => {
		const obj = qs.get(SETTINGS_URL_PARAM_NAME);
		let parsedObject: IReportBuilderSettings | undefined;
		try {
			if (obj) {
				parsedObject = JSON.parse(obj);
			}
		} catch (_e) {
			parsedObject = DEFAULT_SETTINGS;
		}
		if (!parsedObject) {
			parsedObject = DEFAULT_SETTINGS;
		}

		return parsedObject;
	};

	const defaultBuildStageFromQuerySearch = (qs: URLSearchParams) => {
		const obj = qs.get(STAGE_URL_PARAM_NAME);

		if (obj) {
			return obj as BuilderStage;
		}
		return BuilderStage.Settings;
	};

	const defaultDeployFreqChartFromQuerySearch = (qs: URLSearchParams) => {
		const obj = qs.get(DEPLOY_FERQ_URL_PARAM_NAME);
		let parsedObject: IDeploymentFrequencyChart | undefined;
		try {
			if (obj) {
				parsedObject = JSON.parse(obj);
			}
		} catch (_e) {
			parsedObject = undefined;
		}
		if (!parsedObject) {
			parsedObject = undefined;
		}

		return parsedObject;
	};

	const defaultAvgPRSizeFromQuerySearch = (qs: URLSearchParams) => {
		const obj = qs.get(AVG_PR_SIZE_URL_PARAM_NAME);
		let parsedObject: IAvgPRSizeChart | undefined;
		try {
			if (obj) {
				parsedObject = JSON.parse(obj);
			}
		} catch (_e) {
			parsedObject = undefined;
		}
		if (!parsedObject) {
			parsedObject = undefined;
		}

		return parsedObject;
	};

	const defaultMTTRChartFromQuerySearch = (qs: URLSearchParams) => {
		const obj = qs.get(MTTR_URL_PARAM_NAME);
		let parsedObject: IMTTRChart | undefined;
		try {
			if (obj) {
				parsedObject = JSON.parse(obj);
			}
		} catch (_e) {
			parsedObject = undefined;
		}
		if (!parsedObject) {
			parsedObject = undefined;
		}

		return parsedObject;
	};

	useEffect(() => {
		if (shouldRefreshQS) {
			updateQueryString();
			setShouldRefreshQS(false);
		}
	}, [shouldRefreshQS]);

	const history = useHistory();

	const updateQueryString = () => {
		const params = new URLSearchParams({});
		params.set(STAGE_URL_PARAM_NAME, builderStage);

		if (settings) {
			params.set(SETTINGS_URL_PARAM_NAME, JSON.stringify(settings));
		}
		if (averagePRSizeChart) {
			params.set(AVG_PR_SIZE_URL_PARAM_NAME, JSON.stringify(averagePRSizeChart));
		}
		if (mttrChart) {
			params.set(MTTR_URL_PARAM_NAME, JSON.stringify(mttrChart));
		}
		if (deployFreqChart) {
			params.set(DEPLOY_FERQ_URL_PARAM_NAME, JSON.stringify(deployFreqChart));
		}
		history.replace({ pathname: location.pathname, search: params.toString() });
	};

	return (
		<div className="dora-kpi-page ui fluid container full-width">
			<Step.Group attached="top" size="tiny">
				<Step active={builderStage === BuilderStage.Settings}>
					<Icon name="setting" />
					<Step.Content>
						<Step.Title>Settings and filters</Step.Title>
					</Step.Content>
				</Step>

				<Step active={builderStage === BuilderStage.Charts}>
					<Icon name="chart area" />
					<Step.Content>
						<Step.Title>Setup KPIs</Step.Title>
					</Step.Content>
				</Step>

				<Step active={builderStage === BuilderStage.Display} disabled={averagePRSizeChart === undefined}>
					<Icon name="check" />
					<Step.Content>
						<Step.Title>Display</Step.Title>
					</Step.Content>
				</Step>
			</Step.Group>

			<Segment attached={true}>
				{builderStage === BuilderStage.Settings && <SettingsTab
					settings={settings}
					onChange={(s) => {
						setSettings(s);
						setBuilderStage(BuilderStage.Charts);
						setShouldRefreshQS(true);
					}} />}
				{builderStage === BuilderStage.Charts && (
					<Form loading={false}>
						<AvgPRSizeChartForm
							teamId={settings.teamId!}
							series={averagePRSizeChart}
							onSave={(s) => {
								setAveragePRSizeChart(s);
							}}
						/>
						<MTTRChartForm
							teamId={settings.teamId!}
							series={mttrChart}
							onSave={(s) => {
								setMTTRChart(s);
							}}
						/>
						<DeploymentFrequencyChartForm
							teamId={settings.teamId!}
							series={deployFreqChart}
							onSave={(s) => {
								setDeployFreqChart(s);
							}}
						/>
						<Form.Group inline={true}>
							<Form.Field
								id="form-button-control-chart-metrics-back-stage"
								key="form-select-control-chart-metrics-back-stage"
								control={Button}
								primary={false}
								basic={true}
								disabled={false}
								color="grey"
								content="Back"
								size="large"
								onClick={() => {
									setBuilderStage(BuilderStage.Settings);
									setShouldRefreshQS(true);
								}}
							/>
							<Form.Field
								id="form-button-control-chart-metrics-next-stage"
								key="form-select-control-chart-metrics-next-stage"
								control={Button}
								primary={true}
								icon={true}
								disabled={averagePRSizeChart === undefined || mttrChart === undefined || deployFreqChart === undefined}
								content="Next"
								size="large"
								onClick={() => {
									if (averagePRSizeChart && mttrChart && deployFreqChart) {
										setBuilderStage(BuilderStage.Display);
										setShouldRefreshQS(true);
									}
								}}
							/>
						</Form.Group>
					</Form>
				)}
				{builderStage === BuilderStage.Display && <ChartViewer
					settings={settings}
					avgPRSizeChart={averagePRSizeChart!}
					mttrChart={mttrChart!}
					deployFreqChart={deployFreqChart!}
					previousStage={() => {
						setBuilderStage(BuilderStage.Charts);
						setShouldRefreshQS(true);
					}}
				/>}
			</Segment>
		</div>
	);
}
