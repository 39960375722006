import React from "react";
import { observer } from "mobx-react";
import { Route, useRouteMatch } from "react-router-dom";
import StatusMapping from "./status-mapping";
import "./../style.scss";

// tslint:disable-next-line: variable-name
const Page = observer(() => {
	const match = useRouteMatch();

	return (
		<React.Fragment>
			{match && (
				<>
					<Route exact={true} path={`${match.url}`} component={StatusMapping} />
				</>
			)}
		</React.Fragment>
	);
});
export default Page;
