import { action } from "mobx";

export interface ILoadable<D, M = any> {
	loaded: boolean;
	loading: boolean;
	data: D;
	metadata: M;
}

interface ISetDataOptions {
	storage?: boolean;
}

export interface IBaseStore<T> {
	setStoreData: (data: T, options?: ISetDataOptions) => void;
}

export default abstract class BaseStore<T extends object> implements IBaseStore<T> {
	private readonly isIframe: boolean;
	constructor() {
		this.isIframe = window.location !== window.parent.location;
		this.updateFromStorage();
	}

	public static initLoadable(data: any, metadata: any = null) {
		return {
			data,
			metadata,
			loaded: false,
			loading: false
		};
	}

	protected get storageKey() {
		return "GLOBAL";
	}

	@action
	public setStoreData(data: Partial<T>, options: ISetDataOptions = Object() as ISetDataOptions) {
		options = options || {};
		(Object.keys(data) as Array<(keyof T)>).forEach((key) => {
			// @ts-ignore
			this[key] = data[key];
			if (options.storage === true) {
				this.setStorage(Object({ [key]: data[key] }) as T);
			}
		});
	}

	private setStorage(data: Partial<T>) {
		if (this.isIframe) {
			return;
		}

		(Object.keys(data) as Array<(keyof T)>).forEach(key =>
			localStorage.setItem("ACUMEN-" + this.storageKey + "-" + key, JSON.stringify(data[key])));
	}

	private getStorage(key: keyof T) {
		if (this.isIframe) {
			return;
		}

		let data = localStorage.getItem("ACUMEN-" + this.storageKey + "-" + key);

		if (data) {
			try {
				if (data === "undefined") {
					return;
				}
				data = JSON.parse(data);
			} catch (ex) {
				return;
			}
		}

		return data;
	}

	private updateFromStorage() {
		if (this.isIframe) {
			return;
		}

		Object.keys(localStorage).forEach(storageKey => {
			if (storageKey.startsWith("ACUMEN-" + this.storageKey + "-")) {
				const key = storageKey.replace("ACUMEN-" + this.storageKey + "-", "");
				const value = this.getStorage(key as keyof T);

				this.setStoreData(Object({ [key]: value }) as T);
			}
		});
	}
}
