import React from "react";
import { Modal } from "semantic-ui-react";
import { IDashboardTaskStatusChange, IDashboardTaskStatusChangeResponse, SubtaskSprintStrategy } from "@acumen/dashboard-common";
import LoadingIndicator from "../../../components/loader";
import BurndownChartModalTable from "./burndown-chart-modal-table";

interface IBurndownChartModal {
	open: boolean;
	loading: boolean;
	setOpen: (open: boolean) => void;
	subtaskStrategy?: SubtaskSprintStrategy;
	content?: IDashboardTaskStatusChangeResponse;
	dateToDisplay?: string;
	removedTasks?: string[];
}

// tslint:disable-next-line: variable-name
const BurndownChartModal = ({open, loading, setOpen, subtaskStrategy, content = {}, dateToDisplay, removedTasks}: IBurndownChartModal) => {
	const shouldDisplaySubtaskStrategyNote = !!subtaskStrategy && subtaskStrategy !== SubtaskSprintStrategy.IGNORE_NONE;
	const mappedContent: IDashboardTaskStatusChange[] = Object.keys(content).map((key) => ({
		...content[key],
	}));

	return <Modal
			open={open}
			onClose={() => {
				setOpen(false);
			}}
			className="burndown-chart-modal"
		>
			<div className={"burndown-chart-modal-container"}>
				<LoadingIndicator local={true} isActive={loading}>
					<div className="burndown-chart-modal-title">Task progress for {dateToDisplay}</div>
					<div className="burndown-chart-modal-items">
						<BurndownChartModalTable tasks={mappedContent} removedTasks={removedTasks} />
					</div>
					{shouldDisplaySubtaskStrategyNote
						&& <div className="burndown-chart-modal-footer">* Showing tasks based on {subtaskStrategy === SubtaskSprintStrategy.IGNORE_PARENTS ? "subtask" : "parent"} estimate</div>}
				</LoadingIndicator>
			</div>
		</Modal>;
};

export default BurndownChartModal;
