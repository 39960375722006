import React, { useCallback, useState } from "react";
import { IDashboardTeamMember } from "@acumen/dashboard-common";
import { Popup } from "semantic-ui-react";
import { Avatar } from "./avatar";
import { capitalizeName } from "../../utils/string";
import TriggerOnClickOutside from "../components/ux-effects/trigger-on-click-outside";
import "./style.scss";
import { GA_EVENT_ACTION, GA_EVENT_CATEGORY, clickEvent } from "../analytics-events";

const DEFAULT_MAX_USERS_ON_VIEW = 7;

interface IUserA {
	id: string;
	displayName?: string;
	avatarUrl?: string;
}

interface IAvatarSelector {
	usersList: IDashboardTeamMember[] | IUserA[];
	initialSelectedUsers?: Array<IDashboardTeamMember["id"]>;
	maxUsersOnView?: number;
	imgClassName?: string;
}

// tslint:disable-next-line: variable-name
const AvatarsGroup = (props: IAvatarSelector) => {
	const [showExtraSelector, setShowExtraSelector] = useState(false);
	const { usersList, imgClassName } = { ...props };
	const allUsers = [...usersList];
	const maxUsersOnView = props.maxUsersOnView ? props.maxUsersOnView : DEFAULT_MAX_USERS_ON_VIEW;
	const numOfExtraUsers = allUsers.length - maxUsersOnView;
	const usersOnView = numOfExtraUsers >= 1 ? allUsers.splice(0, maxUsersOnView - 1) : allUsers;
	const usersAsExtra = numOfExtraUsers >= 1 ? allUsers.splice(-(numOfExtraUsers + 1)) : [];

	const toggleShowExtraSelector = useCallback((e?: Event | MouseEvent | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		if (e) {
			e.stopPropagation();
		}
		setShowExtraSelector(!showExtraSelector);
	}, [showExtraSelector]);

	return (
		<div className="ui section avatars-group">
			{usersList && usersOnView &&
				usersOnView.map((user, i) => {
					return (
						<div
							key={i}
							className={"avatar-icon-btn"}
						>
							{user.displayName && (
								<Popup
									size="small"
									basic={true}
									content={capitalizeName(user.displayName)}
									trigger={(
										<Avatar
											{...clickEvent(GA_EVENT_CATEGORY.Navigation, GA_EVENT_ACTION.Select, `AvatarUser-${user.id}`)}
											key={i}
											user={user}
											className={imgClassName || "mini"}
										/>
									)}
								/>
							)}
						</div>
					);
				})}
			{(usersAsExtra && usersAsExtra.length) ?
				(
					<>
						<div className={"extra-icon-btn small-gray-btn"}>
							<button {...clickEvent(GA_EVENT_CATEGORY.Navigation, GA_EVENT_ACTION.Select, "AvatarExtra")} className="ui mini label" onClick={(e) => toggleShowExtraSelector(e)}>{"+ " + usersAsExtra.length}</button>
						</div>
						{showExtraSelector ?
							(
								<ExtraAvatarSelector
									extraUsers={usersAsExtra}
									toggleSelf={toggleShowExtraSelector}
								/>
							) : <></>
						}
					</>
				) : <></>
			}

		</div >
	);
};

export default AvatarsGroup;

interface IExtraAvatarSelector {
	extraUsers: IDashboardTeamMember[] | IUserA[];
	toggleSelf: (e?: Event) => void;
}

// tslint:disable-next-line: variable-name
const ExtraAvatarSelector = (props: IExtraAvatarSelector) => {
	const { extraUsers, toggleSelf } = { ...props };
	const users = [...extraUsers];

	return (
		<div className="extra-avatars ui segment raised">
			<TriggerOnClickOutside onTrigger={toggleSelf}>
				{users && users.map((user: IUserA, i: number) =>
					(
						<div
							className={"avatar-item"}
							key={i}
						>
							<Avatar
								user={user}
								className="mini"
							/>
							{user.displayName &&
								<span>{capitalizeName(user.displayName)}</span>
							}
						</div>
					)
				)
				}
			</TriggerOnClickOutside>
		</div>
	);
};
