import React from "react";
import { observer } from "mobx-react";
import { Route, useRouteMatch } from "react-router-dom";
import { useStores } from "../../../mobx-stores";
import EditSingleUser from "./edit-single-user";
import InviteUser from "./invite-user";
import AllUsers from "./all-users";
import "./../style.scss";

// tslint:disable-next-line: variable-name
const Page = observer(() => {
	const match = useRouteMatch();
	const { authStore } = useStores();

	return (
		<React.Fragment>
			{match && (
				<>
					<Route exact={true} path={`${match.url}`} component={AllUsers} />
					<Route exact={true} path={`${match.url}/edit`} component={EditSingleUser} />
					{(authStore.isUserAdmin && authStore.isUserShouldShowInviteUsers) && <Route exact={true} path={`${match.url}/invite`} component={InviteUser} />}
				</>
			)}
		</React.Fragment>
	);
});
export default Page;
