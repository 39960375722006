import React from "react";
import { observer } from "mobx-react";
import { CustomTableRenderProps } from "../../../../components/custom-table/types";
import { TeamViewModel } from "../../types";
import "./team-info-cell-content.scss";
import { TeamBadge } from "@acumen/dashboard-common";
import { BadgeOwner } from "v2/types/badges";
import { Badge, BadgeType } from "v2/components/badge/badge";

export const TeamInfoCellContent = observer((props: CustomTableRenderProps<TeamViewModel>) => {
	const { item: team } = props;

	return (
		<div className="team-info-cell-content">
			<div>
				<div className="team-info-cell-content-name">{team.info.name}</div>
				<div className="team-info-cell-content-count">{team.info.members.length} members</div>
			</div>

			{team.badges.aggregatedBadges && (
				<div className="team-info-cell-content-badges">
					{team.badges.aggregatedBadges.map((badge: TeamBadge) => (
						<Badge
							key={badge}
							badge={badge}
							badgeOwner={BadgeOwner.Team}
							badgeType={BadgeType.AggregatedBadge}
						/>
					))}
				</div>
			)}
		</div>
	);
});
