import React from "react";
import { IconProps } from "../types";

// tslint:disable-next-line: variable-name
export const FilterIcon = ({ className }: IconProps) => {
	return (
		<svg
			viewBox="0 0 18 18"
			className={className}
			width="100%"
			height="100%"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Design-System" transform="translate(-622.000000, -1441.000000)" fillRule="nonzero" stroke="#0F2345" strokeWidth="1.5">
					<g id="Icon---Filter---Normal" transform="translate(622.000000, 1441.000000)">
						<g id="Group" transform="translate(0.000000, -0.000000)" fill="currentColor">
							<path d="M17.1553992,0.75 C17.1741937,0.75 17.1918837,0.752795305 17.2058572,0.762147002 C17.2225059,0.773289022 17.2336209,0.79024952 17.2409802,0.808016262 C17.2484173,0.825970694 17.2525313,0.846035196 17.2486068,0.865876623 C17.2453522,0.882330329 17.2349357,0.896825524 17.2216728,0.910089332 L17.2216728,0.910089332 L10.4999941,7.6326039 L10.4999941,17.1555436 C10.4999941,17.1731369 10.4971197,17.1896702 10.4888922,17.203235 C10.4796631,17.2184509 10.4656386,17.229609 10.4504128,17.2375366 C10.4343039,17.245924 10.4160885,17.2510387 10.3973965,17.249842 C10.3817717,17.2488416 10.366635,17.2422661 10.3523825,17.2322895 L10.3523825,17.2322895 L7.54003759,15.2643512 C7.51495897,15.246793 7.49999496,15.218116 7.49999496,15.1874971 L7.49999496,15.1874971 L7.49999496,7.63260984 L0.778459106,0.910056357 C0.76517026,0.896767511 0.754640112,0.882282416 0.751372247,0.86579003 L0.76,0.886 Z" id="Path" />
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};
