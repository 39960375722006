import React, { useEffect, useMemo, useRef, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts";
import ChartCard from "../../../components/chart-card";
import { GA_EVENT_CATEGORY } from "../../../analytics-events";
import LoadingIndicator from "../../../components/loader";
import { round } from "@acumen/common";

interface IRetroPieChart {
	title: string;
	description: string;
	chartSeries?: Array<Partial<Highcharts.Point>>;
	colorScheme?: string[];
	supportExpand?: boolean;
	legendClassName?: string;
	legendItemFormat?: string;
	countLabel?: string;
}

type SprintSummaryPieChart = Highcharts.Chart & { countText?: Highcharts.SVGElement, subText?: Highcharts.SVGElement };

const SummaryPieChart = (props: IRetroPieChart) => {
	const { title, description, chartSeries, colorScheme, legendClassName, legendItemFormat = "{name}", countLabel } = { ...props };
	const didUnmountRef = useRef(false);
	const [isChartVisible, setIsChartVisible] = useState(true);
	const [isChartAnimationEnabled, setIsChartAnimationEnabled] = useState(true);
	const shouldDisplayChart = isChartVisible && !!chartSeries;
	const options = useSummaryPieChartOptions({
		colorScheme,
		countLabel,
		isChartAnimationEnabled,
		legendItemFormat,
		legendClassName,
		chartSeries,
		didUnmountRef,
	});

	useEffect(() => () => {
		didUnmountRef.current = true;
	}, []);

	useEffect(() => {
		setIsChartVisible(true);
		const handleResize = () => {
			setIsChartVisible(false);
			setIsChartAnimationEnabled(false);
		};
		window.addEventListener("resize", handleResize, { once: true });

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	return (
		<ChartCard
			chartData={{
				title,
				description
			}}
			supportExpand={props.supportExpand}
			eventCategory={GA_EVENT_CATEGORY.SprintReport}
			className="work-done-by-type-card"
		>
			<LoadingIndicator isActive={!chartSeries}>
				{shouldDisplayChart && (
					<HighchartsReact
						highcharts={Highcharts}
						options={options}
					/>
				)}
			</LoadingIndicator>
		</ChartCard>
	);
};

const useSummaryPieChartOptions = (props: {
	colorScheme?: string[];
	countLabel?: string;
	supportExpand?: boolean;
	isChartAnimationEnabled: boolean;
	legendClassName?: string;
	legendItemFormat?: string;
	chartSeries?: Array<Partial<Highcharts.Point>>;
	didUnmountRef: React.MutableRefObject<boolean>;
}) => {
	const {
		colorScheme,
		countLabel,
		isChartAnimationEnabled,
		legendItemFormat,
		legendClassName,
		chartSeries,
		didUnmountRef,
	} = props;
	const chartSeriesRef = useRef(chartSeries);

	return useMemo<Highcharts.Options>(() => {
		const chartOptions: Highcharts.Options = {
			chart: {
				plotShadow: false,
				type: "pie",
				width: null,
				height: 214,
				animation: false,
				events: {
					load(this: SprintSummaryPieChart) {
						const chart = this;
						const countText = this.renderer.text(getCountTextValue(chartSeries), 0, 0, true)
							.attr({ class: "summary-pie-chart-count" }).add();
						const subText = this.renderer.text(
							countLabel,
							this.plotLeft + this.plotWidth / 2,
							this.plotTop + this.plotHeight / 2,
							true
						).attr({ class: "summary-pie-chart-count-label" }).add();

						adjustChartTextPosition(this, countText, subText);

						window.addEventListener("resize", function onResize() {
							if (didUnmountRef.current) {
								window.removeEventListener("resize", onResize);
							} else {
								adjustChartTextPosition(chart, countText, subText);
							}
						});

						this.countText = countText;
						this.subText = subText;
					},
					render(this: SprintSummaryPieChart) {
						if (this.countText && chartSeriesRef.current !== chartSeries) {
							chartSeriesRef.current = chartSeries;
							this.countText.attr({
								text: getCountTextValue(chartSeries),
							});
						}

						if (this.countText && this.subText) {
							adjustChartTextPosition(this, this.countText, this.subText);
						}
					}
				}
			},
			title: {
				text: ""
			},
			credits: {
				enabled: false
			},
			tooltip: {
				animation: isChartAnimationEnabled,
				pointFormat: "<b>{point.tooltip}</b>",
			},
			accessibility: {
				point: {
					valueSuffix: "%"
				}
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					animation: isChartAnimationEnabled,
					cursor: "pointer",
					slicedOffset: 20,
				}
			},
			legend: {
				align: "right",
				verticalAlign: "middle",
				itemMarginTop: 10,
				itemMarginBottom: 10,
				labelFormat: legendItemFormat,
				useHTML: true,
				className: legendClassName,
				symbolWidth: 8,
				symbolHeight: 8,
				navigation: {
					enabled: false,
				},
				borderWidth: 0,
				itemWidth: 205,
				itemStyle: {
					fontFamily: "default-regular-font, sans-serif",
					fontSize: "14px",
					fontWeight: "400",
					color: "#0F2345",
				},
			},
			series: [{
				type: "pie",
				data: chartSeries ?? [],
				size: "175px",
				innerSize: "80%",
				showInLegend: true,
				animation: isChartAnimationEnabled,
				dataLabels: {
					enabled: false,
					format: "{point.percentage:.1f}%",
					alignTo: "connectors",
					crop: false,
					distance: 8,
					padding: -10,
					overflow: "allow",
					connectorWidth: 0,
				},
				visible: chartSeries && chartSeries.length > 0
			}]
		};

		if (colorScheme) {
			chartOptions.colors = colorScheme;
		}

		return chartOptions;
	}, [
		colorScheme,
		countLabel,
		isChartAnimationEnabled,
		legendItemFormat,
		legendClassName,
		chartSeries,
	]);
};

const getCountTextValue = (chartSeries?: Array<Partial<Highcharts.Point & { value?: number }>>) => {
	const total = chartSeries
		?.reduce((acc, point) => acc + (point.value ?? 0), 0) ?? 0;

	return round(total, 1).toString();
};

const adjustChartTextPosition = (
	chart: Highcharts.Chart,
	countText: Highcharts.SVGElement,
	subText: Highcharts.SVGElement
) => {
	const countTextElement = countText.element as HTMLElement;
	const subTextElement = subText.element as HTMLElement;

	countText.attr({
		x: chart.plotLeft + chart.plotWidth / 2 - countTextElement.offsetWidth / 2,
		y: chart.plotTop + chart.plotHeight / 2 + countTextElement.offsetHeight / 2 - 32,
	});

	subText.attr({
		x: chart.plotLeft + chart.plotWidth / 2 - subTextElement.offsetWidth / 2,
		y: chart.plotTop + chart.plotHeight / 2 + subTextElement.offsetHeight / 2 - 8,
	});
};

export default SummaryPieChart;
