import BaseStore, { IBaseStore, ILoadable } from "./base-store";
import { action, observable } from "mobx";
import apiContextProvider from "../../services/api-context-provider";
import { UsersApiClient, USERS_ROUTE } from "../services/crud/users-api-client";

import {
	IDashboardUser, IDashboardResponse, DashboardUserRoleType
} from "@acumen/dashboard-common";
import { InviteUsersApiClient } from "../services/crud/invite-users-api-client";
import { FetchLatestRequest } from "../../services/fetch-helpers";

export interface IUsersState {
	allUsers: ILoadable<IDashboardUser[]>;
	singleUser: ILoadable<IDashboardUser>;
}

export interface IUsersStore extends IUsersState, IBaseStore<IUsersState> {
	fetchAllUsers: () => Promise<void>;
	deleteUser: (userId: string) => Promise<void>;
	updateUser: (user: Partial<IDashboardUser>) => Promise<IDashboardUser | void>;
	resetUsers: () => void;
	setSingleUser: (user: IDashboardUser) => void;
	resetSingleUser: () => void;
	fetchUser: (id: string) => IDashboardUser | any;
}

const defaults = {
	allUsers: BaseStore.initLoadable([]),
	singleUser: BaseStore.initLoadable({}),
	inviteUser: BaseStore.initLoadable({})
};

export default class UsersStore extends BaseStore<IUsersState> implements IUsersStore {
	private readonly userApiClient: UsersApiClient = new UsersApiClient(apiContextProvider);
	private readonly inviteUserApiClient: InviteUsersApiClient = new InviteUsersApiClient(apiContextProvider);

	@observable
	public allUsers: ILoadable<IDashboardUser[]> = defaults.allUsers;
	@observable
	public singleUser: ILoadable<IDashboardUser> = defaults.singleUser;

	fetchLatestAllUsers = new FetchLatestRequest<IDashboardUser[], any>(USERS_ROUTE);
	@action.bound
	public async fetchAllUsers() {
		this.allUsers.loading = true;

		const result = await this.fetchLatestAllUsers.fetchLatest(this.userApiClient.findAll());

		if (result) {
			const sortedData = result.data.sort((a, b) =>
				(a.firstName && b.firstName) ? a.firstName.localeCompare(b.firstName) : -1
			);

			this.allUsers = {
				data: sortedData,
				metadata: null,
				loaded: true,
				loading: false
			};
		}
	}

	fetchLatestUser = new FetchLatestRequest<IDashboardUser, any>(USERS_ROUTE);
	@action.bound
	public async fetchUser(id: string) {
		const res = await this.fetchLatestUser.fetchLatest(this.userApiClient.findOne(id));
		return res ? res.data : res;
	}

	@action.bound
	public async updateUser(user: Partial<IDashboardUser>) {
		let result: IDashboardResponse<IDashboardUser> | null;

		// this.singleUser.loading = true;

		result = await this.userApiClient.update(user);

		if (result) {
			const { data } = result;

			this.singleUser = {
				data: data as IDashboardUser,
				metadata: null,
				loaded: true,
				loading: false
			};

			const old = this.allUsers.data.find(t => t.id === user.id);

			if (old) {
				const idx = this.allUsers.data.indexOf(old);
				this.allUsers.data[idx] = this.singleUser.data;
			}
			return data;
		}
	}

	@action.bound
	public async inviteUser(dcId: string, email: string, role: DashboardUserRoleType) {
		const invitedUser = await this.inviteUserApiClient.inviteUser(dcId, email, role);
		if (invitedUser) {
			this.allUsers.loading = true;
			const sortedData = [...this.allUsers.data, invitedUser.data].sort((a, b) =>
				(a.firstName && b.firstName) ? a.firstName.localeCompare(b.firstName) : -1
			);

			this.allUsers = {
				data: sortedData,
				metadata: null,
				loaded: true,
				loading: false
			};
			this.allUsers.loading = false;
		}

		return invitedUser;
	}

	@action.bound
	public async deleteUser(userId: string) {
		this.singleUser.loading = true;

		await this.userApiClient.delete(userId);

		const user = this.allUsers.data.find(t => t.id === userId);

		if (user) {
			const idx = this.allUsers.data.indexOf(user);
			this.allUsers.data.splice(idx, 1);
			this.allUsers.data = [...this.allUsers.data];
		}
	}

	@action.bound
	public resetUsers() {
		this.allUsers = defaults.allUsers;
	}

	@action.bound
	public setSingleUser(user: IDashboardUser) {
		this.singleUser.data = user;
	}

	@action.bound
	public resetSingleUser() {
		this.singleUser = defaults.singleUser;
	}

}
