import * as Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";
import { useStores } from "../../../../mobx-stores";
import moment from "moment-timezone";
import _ from "lodash";
import { DashboardPRSizeToMergeTimeBucket, IDashboardPRSizeToMergeTime } from "@acumen/dashboard-common";
import LoadingIndicator from "../../../../components/loader";
import { inflect } from "@acumen/common";
import { isValidChartData } from "./charts";
import { observer } from "mobx-react";
import colorScheme from "./chart-color-scheme";
import { v4 as generateUUID } from "uuid";

HighchartsMore(Highcharts);

interface IPRSizeToMergeTimeProps {
	isFullScreen?: boolean;
	setDisableFullScreen?: (disable: boolean) => void;
	filters: {
		teamId?: string;
		dataContributorIds?: string[];
		projectIds?: string[];
		boardIds?: string[];
		gitRepositoryIds?: string[];
		baseIsDefaultBranch?: boolean;
		excludeDraft?: boolean;
		overrideStartTime?: Date;
		overrideEndTime?: Date;
		timezone: string;
	};
}

const DEFAULT_MAX_DAYS_FULL_SCREEN = 20;
const DEFAULT_MAX_DAYS_REGULAR_SCREEN = 10;
const DEFAULT_MAX_SIZE = 1000;

export const PR_SIZE_TO_MERGE_TIME_DATA = {
	title: "Pull request size vs. time",
	description: `Shows the relation between pull request sizes and their corresponding time (open to merge).
	Circles indicate size in lines of code, date shows when the pull request was merged.
	This chart shows two weeks of data in the minimized version and four weeks in the expanded version.`
};

function PRSizeToMergeTimeChart(props: IPRSizeToMergeTimeProps) {
	const { chartsStore } = useStores();
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const { fetchPRSizeToMergeTimeData, prSizeToMergeTimeChartData } = chartsStore;
	const [chartData, setChartData] = useState<IDashboardPRSizeToMergeTime | undefined>(prSizeToMergeTimeChartData);

	useEffect(() => {
		if (props.isFullScreen) {
			return;
		}
		if (Object.keys(props.filters).length === 0) {
			return;
		}

		let isMounted = true;
		async function fetch() {
			setIsFetching(true);
			if (props.setDisableFullScreen) {
				props.setDisableFullScreen(true);
			}
			let startTime = props.filters.overrideStartTime;
			let endTime = props.filters.overrideEndTime;
			if (!endTime || !startTime) {
				endTime = new Date();
				startTime = moment.utc(endTime).subtract((props.isFullScreen === true ? 4 : 2), "weeks").toDate();
			}

			const data = await fetchPRSizeToMergeTimeData(props.filters.teamId ? [props.filters.teamId] : undefined,
				props.filters.dataContributorIds,
				props.filters.projectIds,
				startTime,
				endTime,
				props.filters.gitRepositoryIds,
				props.filters.baseIsDefaultBranch,
				props.filters.excludeDraft
			);
			if (isMounted && data) {
				setIsFetching(false);
				setChartData(data);
			}
			if (props.setDisableFullScreen) {
				props.setDisableFullScreen(false);
			}
		}

		// tslint:disable-next-line: no-floating-promises
		fetch();
		return () => { isMounted = false; };
	}, [
		props.isFullScreen,
		props.filters.teamId,
		JSON.stringify(props.filters.dataContributorIds),
		JSON.stringify(props.filters.projectIds),
		JSON.stringify(props.filters.gitRepositoryIds),
		props.filters.baseIsDefaultBranch,
		props.filters.excludeDraft,
		props.filters.overrideStartTime?.toISOString(),
		props.filters.overrideEndTime?.toISOString(),
	]);

	let seriesData: Record<DashboardPRSizeToMergeTimeBucket, Highcharts.PointOptionsObject[]> = {
		FAST: [],
		NORMAL: [],
		SLOW: []
	};

	if (isValidChartData<IDashboardPRSizeToMergeTime>(chartData)) {
		const {
			display: {
				maxDays = (props.isFullScreen === true ? DEFAULT_MAX_DAYS_FULL_SCREEN : DEFAULT_MAX_DAYS_REGULAR_SCREEN),
				maxSize = DEFAULT_MAX_SIZE
			} = {}
		} = chartData ?? {};

		seriesData = _.mapValues(chartData?.pullRequestData,
			(data, type) => {
				return data.map<Highcharts.PointOptionsObject>(({ pullRequest, mergedAtDate, mergeTimeMs, size, additions, deletions }) => {
					const mergeTimeInDays = Number(moment.duration(mergeTimeMs, "milliseconds").asDays().toFixed(2));
					const mergeTimeInHours = Number(moment.duration(mergeTimeMs, "milliseconds").asHours().toFixed(2));

					/*
						 Highcharts requires the x-axis be a number, and since we want the values in the chart
						 to be "rounded" to the day (as higher resolution might not be as useful)
						 we're zeroing out the time portion of our merged date and leaving only the date
					*/
					return {
						x: moment.tz(mergedAtDate, props.filters.timezone)
							.hour(0)
							.minute(0)
							.second(0)
							.millisecond(0)
							.valueOf(),
						y: mergeTimeInDays > maxDays
							? maxDays + 1
							: Math.floor(mergeTimeInDays),
						z: Math.min(size, maxSize),
						// Custom Properties
						size,
						additions,
						deletions,
						mergeTime: mergeTimeInDays < 1
							? inflect(mergeTimeInHours, "hour", "hours")
							: inflect(mergeTimeInDays, "day", "days"),
						id: pullRequest.entityId,
						type,
						mergedAtDate: moment.tz(mergedAtDate, props.filters.timezone).format("L"),
						prNumber: pullRequest.publicIdentifier,
						prTitle: pullRequest.title,
						prUrl: pullRequest.publicHtmlUrl ?? "",
						prRepoName: pullRequest.repositoryName ?? ""
					};
				});
			});
	}
	const series: Highcharts.SeriesBubbleOptions[] = [
		{
			id: generateUUID(),
			data: seriesData[DashboardPRSizeToMergeTimeBucket.Slow],
			color: colorScheme.prTimeToMerge.slow,
			name: "Slow",
			visible: seriesData[DashboardPRSizeToMergeTimeBucket.Slow]?.length > 0,
			type: "bubble"
		},
		{
			id: generateUUID(),
			data: seriesData[DashboardPRSizeToMergeTimeBucket.Normal],
			color: colorScheme.prTimeToMerge.normal,
			name: "Normal",
			visible: seriesData[DashboardPRSizeToMergeTimeBucket.Normal]?.length > 0,
			type: "bubble"
		},
		{
			id: generateUUID(),
			data: seriesData[DashboardPRSizeToMergeTimeBucket.Fast],
			color: colorScheme.prTimeToMerge.fast,
			name: "Fast",
			visible: seriesData[DashboardPRSizeToMergeTimeBucket.Fast]?.length > 0,
			type: "bubble"
		}
	];
	const options: Highcharts.Options = {
		chart: {
			type: "bubble",
			zoomType: "xy"
		},
		title: undefined,
		legend: {
			alignColumns: false
		},
		xAxis: {
			type: "datetime",
			tickInterval: moment.duration(1, "day").asMilliseconds(),
			gridLineWidth: 1,
			labels: {
				// tslint:disable-next-line: object-literal-shorthand
				formatter: function _formatter() {
					return moment.tz(this.value, props.filters.timezone).format("ddd, MMM Do");
				},
				style: {
					color: "gray",
					textOverflow: "none",
				}
			},
		},
		tooltip: {
			useHTML: true,
			pointFormat: `<a target="_blank" rel="noopener noreferrer" href="{point.prUrl}">{point.prRepoName}#{point.prNumber}</a> {point.prTitle}<br />
			Changes: +{point.additions}, -{point.deletions}, total {point.size} lines of code<br />
			Merge time: {point.mergeTime}<br />
			Merge date: {point.mergedAtDate}`,
			followPointer: true,
			style: {
				pointerEvents: "auto"
			}
		},
		yAxis: {
			startOnTick: false,
			endOnTick: false,
			title: {
				text: "Cycle time (days)"
			},
			labels: {
				// tslint:disable-next-line: object-literal-shorthand
				formatter: function _formatter() {
					if (this.value === 11) {
						return "> 11d";
					}
					return `${this.value}d`;
				}
			}
		},
		plotOptions: {
			bubble: {
				crisp: true,
			},
		},
		credits: {
			enabled: false
		},
		series
	};

	return (
		<div className="description">
			<LoadingIndicator local={true} isActive={!isValidChartData(chartData) || isFetching}>
				<HighchartsReact
					highcharts={Highcharts}
					options={options}
				/>
			</LoadingIndicator>
		</div >
	);
}

export default observer(PRSizeToMergeTimeChart);
