import React from "react";
import Select from "react-select";
import OutsideAlerter from "../../../components/outside-alerter";
import "./cell-editors.scss";

interface IProps {
	value: string[];
	options: IMultiSelectOption[];
	onUpdate: (newValue: string[]) => void;
}

export interface IMultiSelectOption {
	label: string;
	value: string;
}

export default class MultiSelectCellEditor extends React.Component<IProps> {
	public state = {
		value: this.props.value
	};

	public getValue = () => {
		return this.state.value;
	}

	private onChange = (newValues: IMultiSelectOption[] | null) => {
		let valuesOnly: string[] = [];

		if (newValues) {
			valuesOnly = newValues.map(v => v.value);
		}

		this.setState({ value: valuesOnly });
	}

	private onOutside = () => {
		this.props.onUpdate(this.getValue());
	}

	public render() {
		return (
			<>
				<OutsideAlerter callback={this.onOutside} click={true}>
					<Select
						closeMenuOnSelect={false}
						value={this.state.value.map(value => {
							const { label } = this.props.options.find(opt => opt.value === value)!;
							return { value, label };
						})}
						isMulti={true}
						options={this.props.options}
						onChange={(newValue) => this.onChange(newValue as IMultiSelectOption[])}
						className="cell-editor-multiselect"
					/>
				</OutsideAlerter>
			</>
		);
	}
}
