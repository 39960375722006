import { IDashboardTeamMember } from "@acumen/dashboard-common";
import { WorkforceHealthMetrics } from "@acumen/database-types";
import _ from "lodash";
import { TeamBadgesData } from "v2/types/team-comparison";
import { PrCycleStatus } from "./enums";

export interface IMetricValue {
	current: number;
	previous: number;
}

export type MetricDictionary = Record<WorkforceHealthMetrics, IMetricValue>;

export type IdentifierToMetricDict = Record<string, MetricDictionary>;

const emptyStats: MetricDictionary = Object.assign({}, ...Object.keys(WorkforceHealthMetrics).map(x => ({
	[x]: { current: 0, previous: 0 }
})));

export class MetricData {
	private _values: IdentifierToMetricDict = {};

	public ids(): string[] {
		return Object.keys(this._values);
	}

	public set(id: string, metric: WorkforceHealthMetrics, period: "current" | "previous", value: number) {
		if (!(id in this._values)) {
			this._values[id] = _.cloneDeep(emptyStats);
		}

		this._values[id][metric][period] = value;
	}

	public get(id: string, metric: WorkforceHealthMetrics, period: "current" | "previous"): number {
		if (!(this._values[id])) {
			return 0;
		}

		return this._values[id][metric][period];
	}
}

export interface PrCycleTimeBreakdownItem {
	name: string;
	status: PrCycleStatus;
	totalSeconds: number;
}

export interface ITeamInfo {
	id: string;
	name: string;
	members: IDashboardTeamMember[];
}

export interface TeamViewModel {
	info: ITeamInfo;
	stats: Record<string, IMetricValue>;
	badges: TeamBadgesData;
	cycleTimeBreakdown: PrCycleTimeBreakdownItem[];
}

export interface TeamPerformanceSummaryViewModel {
	id: number;
	name?: WorkforceHealthMetrics;
	title: string;
	subtitle: string;
	unit: string;
	previousValue: number;
	currentValue: number;
	data: number[];
	isIncreasePositive: boolean;
	tooltip: string;
}
