import * as React from "react";
import "./pr-table-aggregated-data-cell.scss";

export interface PRTableAggregatedDataCellProps {
	value: number | undefined;
	unit: string | undefined;
}

export const PRTableAggregatedDataCell = (props: PRTableAggregatedDataCellProps) => {
	const { value, unit } = props;

	return (
		<td className="pr-table-aggregated-data-cell">
			<div className="pr-table-aggregated-data-cell-content">
				<span className="pr-table-aggregated-data-cell-content-value">
					{value ?? "-"}
				</span>
				<span className="pr-table-aggregated-data-cell-content-unit">
					{unit ?? ""}
				</span>
			</div>
		</td>
	);
};
