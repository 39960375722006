import React from "react";
import { Icon, Label, Popup } from "semantic-ui-react";
import classNames from "classnames";
import { DeveloperViewModel } from "../../types";
import { exportDataAsCsv } from "../../../../helpers/exportDataAsCsv";
import { MetricData } from "../../../team-comparison/types";
import { WorkforceHealthMetrics } from "@acumen/database-types";
import "./workforce-health-export-button.scss";

export interface WorkforceHealthExportButtonProps {
	developers?: DeveloperViewModel[];
	metricData: MetricData;
	disabled?: boolean;
}

export const WorkforceHealthExportButton = (props: WorkforceHealthExportButtonProps) => {
	const { developers = [], metricData, disabled } = props;
	const labelClassName = classNames(["workforce-health-export-button-label", { disabled }]);

	return (
		<div className="workforce-health-export-button">
			<Popup
				hoverable={true}
				wide={true}
				disabled={disabled}
				position="top center"
				content="Export the Workforce Health report as a CSV"
				trigger={
					<Label
						className={labelClassName}
						as="a"
						basic={true}
						onClick={(e) => {
							if (disabled) {
								return;
							}

							e.stopPropagation();

							const output = getExportData({
								developers,
								metricData,
							});

							exportDataAsCsv(output, "export_workforce_health");
						}}
					>
						<Icon name={"download"}/>
						<span>Export</span>
					</Label>
				}
			/>
		</div>
	);
};

const getExportData = (props: {
	developers: DeveloperViewModel[];
	metricData: MetricData;
}) => {
	const { developers, metricData } = props;

	return metricData.ids().map(dcId => {
		const dc = developers.find(sc => sc.info.dataContributorId === dcId)?.info;
		const name = (dc && dc.displayName) ? dc.displayName : `Dev-${dcId}`;
		return {
			name,
			...Object.keys(WorkforceHealthMetrics).reduce<Record<string, number>>((red, metricName) => {
				red[metricName] = metricData.get(dcId, metricName as WorkforceHealthMetrics, "current");
				return red;
			}, {})
		};
	});
};
