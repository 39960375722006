import { ChangeEvent, useState, FormEvent, useEffect } from "react";
import { getRandomDisplayName } from "../adapters/data";
import { usePoker } from "../adapters/poker";
import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { PokerPage } from "../service/internal-router";
import { Meta } from "../partials/Meta";
import Hero from "../svg-assets/JoinGameHero";
import { IPlanningPokerGame } from "@acumen/database-types";
import toast, { Toaster } from "react-hot-toast";

const DEFAULT_DISPLAY_NAME = getRandomDisplayName() || "Chuck Norris";

const JoinGamePage = () => {
	const location = useLocation();
	const currentQsParams = new URLSearchParams(location.search);
	const defaultDisplayName = decodeURIComponent(currentQsParams.get("name") || DEFAULT_DISPLAY_NAME);
	const [playerName, setPlayerName] = useState<string>(defaultDisplayName);
	const [showError, setShowError] = useState<boolean>(false);
	const [currentGame, setCurrentGame] = useState<IPlanningPokerGame | null>(null);
	const [joinAllowed, setJoinAllowed] = useState<boolean>(false);

	const poker = usePoker();
	const history = useHistory();

	useEffect(() => {
		const id = currentQsParams.get("id");
		if (poker && poker?.service && !!id) {
			poker.service.getGameDetails(id)
				.then(game => {
					if (!game) {
						toast.error(`The game you are trying to join doesn't exist, create a new game`);
						setTimeout(() => {
							history.push({
								pathname: PokerPage.CreateGame.toString(),
								search: location.search
							});
						}, 2000);
						return;
					}

					if (game.currentPlayer && !joinAllowed) {
						toast.success(`You are already a part of this game, joining`);
						setTimeout(() => {
							history.push({
								pathname: PokerPage.Game.toString(),
								search: location.search
							});
						}, 2000);
						return;
					}

					setCurrentGame(game);
					setJoinAllowed(true);
				})
				.catch();
		}
	}, [poker, poker?.service]);

	return (
		<div className="antialiased text-gray-600">
			<Meta />
			<Toaster
				containerStyle={{
					top: "40%",
					right: "15%",
					fontWeight: "bold"
				}}
			/>
			<section className="bg-white">
				<div className="flex justify-center min-h-screen">
					<div className="flex items-center w-full h-full max-w-3xl p-8 mx-auto lg:px-12 lg:w-3/5">
						<div className="w-full">
							<div className="py-4">
								<div className="container mx-auto flex flex-row items-center justify-center">
									<h1 className="text-xl font-semibold tracking-wider">
										<span className="text-[#fc09b8] capitalize border-b-4 border-[#ED51A3] py-2 ">Join game</span>
										{currentGame && <span className="capitalize py-2">{` - ${currentGame?.name}`}</span>}
									</h1>
								</div>
							</div>

							<form className="grid grid-cols-1 gap-6 mt-8 md:grid-cols-1">
								<div>
									<label className="block mb-2 text-base text-gray-600">
										Your display name
									</label>
									<input
										type="text"
										placeholder={`e.g. ${DEFAULT_DISPLAY_NAME}`}
										className="block w-full px-5 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white shadow-sm border border-gray-300 rounded-md focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
										defaultValue={playerName}
										required={true}
										onChange={(event: ChangeEvent<HTMLInputElement>) =>
											setPlayerName(event.target.value)
										}
									/>

									{showError && playerName === undefined && (
										<span className="block text-base tracking-wide text-red-500 mt-1">
											Please enter your display name
										</span>
									)}
								</div>
								<button
									onClick={async (event: FormEvent) => {
										event.preventDefault();
										if (
											poker &&
											poker.service &&
											playerName &&
											playerName.trim().length > 0 &&
											currentGame &&
											joinAllowed
										) {
											setShowError(false);

											const res = await poker.service.join(
												currentGame.xid,
												playerName
											);
											if (res) {
												currentQsParams.set("id", currentGame.xid);
												history.push(`${PokerPage.Game}?${currentQsParams.toString()}`);
											} else {
												setShowError(true);
											}
										} else {
											setShowError(true);
										}
									}}
									className="inline-block py-2 px-4 text-lg font-bold text-center text-white transition duration-200 bg-[#399CFF] rounded-lg hover:bg-[#338CE5] ease"
									disabled={!joinAllowed}
								>
									Join game
								</button>
								<p className="text-sm font-light text-gray-800">
									Want to create a new game?{" "}
									<Link
										to={{
											pathname: PokerPage.CreateGame,
											search: location.search
										}}
									>
										<span className="font-medium cursor-pointer text-[#399CFF] hover:underline hover:text-[#338CE5]">
											Click here
										</span>
									</Link>
									.
								</p>
							</form>
						</div>
					</div>
					<div className="hidden bg-cover lg:block lg:w-2/5 bg-gradient-to-br from-[#FF77D9] to-[#0049C2]">
						<div className="flex items-center justify-center h-screen">
							<Hero />
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default JoinGamePage;
