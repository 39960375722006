import React, { useEffect, useState } from "react";
import { DataContributorProfilePicture } from "../data-contributor-profile-picture/data-contributor-profile-picture";
import { Badge, BadgeType } from "../../../../components/badge/badge";
import { DeveloperViewModel } from "../../types";
import { MetricData } from "../../../team-comparison/types";
import { IDashboardDataContributorLabel, IDashboardDataContributorCustomerLabel, WorkforceHealthMetrics } from "@acumen/dashboard-common";
import "./developer-detailed-profile.scss";
import { useStores } from "v2/mobx-stores";
import ReactSelect from "react-select/creatable";
import { components, StylesConfig } from "react-select";
import { EditDataContributorLabelsExportButton } from "./edit-labels-button/edit-labels-button";
import _ from "lodash";
import { BadgeOwner } from "../../../../types/badges";

const DEFAULT_LABEL_TEXT = "Developer";
export interface DeveloperDetailedProfileProps {
	developer: DeveloperViewModel;
	metricData: MetricData;
}

export const DeveloperDetailedProfile = (props: DeveloperDetailedProfileProps) => {
	const {
		authStore,
		dataContributorLabelsStore
	} = useStores();
	const { developer, metricData } = props;
	const activeDays = metricData.get(developer.info.dataContributorId, WorkforceHealthMetrics.ActiveDays, "current");

	const [shouldEditLabelsDropdownOpen, setShouldEditLabelsDropdownOpen] = useState<boolean>(false);
	const [shouldCloseTheDropdown, setShouldCloseTheDropdown] = useState<boolean>(false);
	const [userLabelOptions, setUserLabelOptions] = useState<IDropdownOption[]>([]);
	const [labelsText, setLabelsText] = useState<string>(DEFAULT_LABEL_TEXT);
	const [isUpdatingLabels, setIsUpdatingLabels] = useState<boolean>(false);

	useEffect(() => {
		const loadCustomerLabelOptions = async () => {
			await dataContributorLabelsStore.findAll();
		};
		if (dataContributorLabelsStore.allLabelsData.length === 0 &&
			!dataContributorLabelsStore.isLoadingAllLabelsData) {
			// tslint:disable-next-line: no-floating-promises
			loadCustomerLabelOptions();
		}
	}, []);

	useEffect(() => {
		setLabelsText(developer.info.labels.length === 0 ? DEFAULT_LABEL_TEXT : developer.info.labels.map(l => l.name).join(", "));
		setUserLabelOptions(developer.info.labels.map(l => createOption(l)));
	}, [developer.info.dataContributorId]);

	useEffect(() => {
		if (!shouldCloseTheDropdown) {
			return;
		}
		setShouldEditLabelsDropdownOpen(false);
		setShouldCloseTheDropdown(false);

		const newLabelsToAdd = _.uniq(userLabelOptions.filter(i => !i.key).map(ni => ni.value));
		const preExistedItems = userLabelOptions.filter(i => i.key);

		const customerLabelIdToAssociate: string[] = [];
		const customerLabelIdToDisassociate: string[] = [];
		const keepDCLabels: IDashboardDataContributorLabel[] = [];

		// First look if one of our original options is missing in the pre existed items - that means the
		// use disassociated the item
		for (const oOption of developer.info.labels) {
			if (preExistedItems.find(pe => pe.key === oOption.customerLabelId) === undefined) {
				customerLabelIdToDisassociate.push(oOption.customerLabelId);
			}
		}
		// Last look if one of selected options is missing in the pre existed items - that means the
		// use associate the item
		for (const sOption of preExistedItems) {
			const customerLabel = developer.info.labels.find(pe => pe.customerLabelId === sOption.key);
			if (customerLabel === undefined) {
				customerLabelIdToAssociate.push(sOption.key);
			} else {
				keepDCLabels.push(customerLabel);
			}
		}
		setIsUpdatingLabels(true);
		// tslint:disable-next-line: no-floating-promises
		dataContributorLabelsStore.updateLabels(developer.info.dataContributorId, newLabelsToAdd, customerLabelIdToAssociate, customerLabelIdToDisassociate).then(l => {
			const newCustomerLabels = _.orderBy([...keepDCLabels, ...l], p => p.name.toLowerCase(), "asc");
			developer.info.labels = newCustomerLabels;
			setLabelsText(newCustomerLabels.length === 0 ? "Developer" : newCustomerLabels.map(t => t.name).join(", "));
			setIsUpdatingLabels(false);
		});
	}, [shouldCloseTheDropdown]);

	return (
		<div className="developer-detailed-profile">
			<div className="developer-detailed-profile-header">
				<DataContributorProfilePicture
					contributor={developer.info}
					size={80}
				/>
				<div className="developer-detailed-profile-info">
					<div className="developer-detailed-profile-info-name">{developer.info.displayName}</div>
					{authStore.isUserAdmin && <div className="developer-detailed-profile-info-role">
						{!shouldEditLabelsDropdownOpen && `${labelsText}`}
						{!shouldEditLabelsDropdownOpen && <EditDataContributorLabelsExportButton shouldShowLoader={isUpdatingLabels} onClick={() => setShouldEditLabelsDropdownOpen(true)} />}
						{shouldEditLabelsDropdownOpen &&
							<ReactSelect
								isDisabled={isUpdatingLabels}
								isClearable={false}
								multiValueRemove={true}
								isMulti={true}
								closeMenuOnSelect={false}
								autoFocus={true}
								isOpen={true}
								placeholder={"Select labels"}
								controlShouldRenderValue={true}
								selection={true}
								noOptionsMessage={() => ""}
								components={{ DropdownIndicator }}
								options={_.orderBy(dataContributorLabelsStore.allLabelsData.length === 0 ?
									userLabelOptions :
									dataContributorLabelsStore.allLabelsData.map(l => createOption(l)), p => p.label.toLowerCase(), "asc").filter(l => {
										if (userLabelOptions.length > 0 && userLabelOptions.find(us => us.key === l.key) !== undefined) {
											return false;
										}
										return true;
									})}
								isLoading={isUpdatingLabels || dataContributorLabelsStore.isLoadingAllLabelsData}
								value={userLabelOptions}
								onMenuClose={() => { setShouldCloseTheDropdown(true); }}
								onChange={(items?:
									IDropdownOption
									| ReadonlyArray<IDropdownOption>
									| null) => {
									if (Array.isArray(items)) {
										setUserLabelOptions(items);
									}
								}}
								styles={customStyles}
							/>}
					</div>}
					{!authStore.isUserAdmin && <div className="developer-detailed-profile-info-role">
						{labelsText}
					</div>}
					<div className="developer-detailed-profile-info-badges">
						{developer.badges.aggregatedBadges.map(badge => (
							<Badge
								key={badge}
								badge={badge}
								badgeOwner={BadgeOwner.Developer}
								badgeType={BadgeType.AggregatedBadge}
							/>
						))}
					</div>
				</div>
			</div>

			<div className="developer-detailed-profile-details-list">
				<div className="developer-detailed-profile-details-list-item">
					<div className="developer-detailed-profile-details-list-item-label">Teams</div>
					<div className="developer-detailed-profile-details-list-item-content">
						<div className="developer-detailed-profile-team-list">
							{developer.teams.map(team => (
								<div key={team.id} className="developer-detailed-profile-team-list-item">
									{team.name}
								</div>
							))}
						</div>
					</div>
				</div>

				<div className="developer-detailed-profile-details-list-item">
					<div className="developer-detailed-profile-details-list-item-label">Active Days</div>
					<div className="developer-detailed-profile-details-list-item-content">
						<b>{activeDays}</b>/30
					</div>
				</div>
			</div>
		</div>
	);
};

interface IDropdownOption {
	key: string;
	value: string;
	label: string;
}

const DropdownIndicator = (props: any) => {
	return (
		<components.DropdownIndicator  {...props} >
			<svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fillRule="evenodd" clipRule="evenodd" d="M0.38871 1.09582L0.0351562 0.742263L0.742263 0.0351562L1.09582 0.38871L3.00001 2.2929L4.9042 0.38871L5.25775 0.0351562L5.96486 0.742263L5.61131 1.09582L3.70712 3.00001L5.61127 4.90417L5.96483 5.25772L5.25772 5.96483L4.90417 5.61127L3.00001 3.70712L1.09585 5.61127L0.742296 5.96483L0.035189 5.25772L0.388742 4.90417L2.2929 3.00001L0.38871 1.09582Z" fill="#925EFF" />
			</svg>
		</components.DropdownIndicator>
	);
};
const customStyles: StylesConfig<any, true> = {
	control: (provided: Record<string, unknown>, state: any) => ({
		...provided,
		"cursor": "pointer",
		"width": "300px",
		"border": state.isFocused ? "1px solid #ECE3FE" : "1px solid #cccccc",
		"boxShadow": state.isFocused ? "0px 0px 6px #ECE3FE" : "none",
		"&:hover": {
			border: "1px solid #ECE3FE",
			boxShadow: "0px 0px 6px #ECE3FE"
		}
	})
};

const createOption = (customerLabel: IDashboardDataContributorCustomerLabel | IDashboardDataContributorLabel): IDropdownOption => {
	return {
		key: customerLabel.customerLabelId,
		value: customerLabel.customerLabelId,
		label: customerLabel.name
	};
};
