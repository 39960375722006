import React from "react";
import { Checkbox as PlainCheckbox, CheckboxProps } from "semantic-ui-react";
import "./style.scss";

interface ICheckbox {
	label?: string;
	checked?: boolean;
	disabled?: boolean;
	onChange: (data: CheckboxProps) => void;
	className?: string;
}

// tslint:disable-next-line: variable-name
export const Checkbox = ({label, checked, disabled, onChange, className}: ICheckbox) => {
	return (
		<PlainCheckbox
			label={label}
			className={`acu-checkbox ${className ? className : ""}`}
			checked={checked}
			disabled={disabled}
			onChange={(_event, data) => {
				onChange(data);
			}}
		/>
	);
};

export default Checkbox;
