import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useState } from "react";
import { useStores } from "../../../mobx-stores";
import LoadingIndicator from "../../../components/loader/loader";
import { IChartScope } from "./chart-scope";
import useDeepCompareEffect from "use-deep-compare-effect";
import _ from "lodash";
import { round } from "@acumen/common";
import PieChart from "./pie-chart";
import { ChartExternalLegend, ILegendList } from "./legend";
import {
	IDashboardJiraPriority
} from "@acumen/dashboard-common";
import uniqolor from "../../../../utils/color";
import { CategoryChartSeriesData } from "v2/helpers/charts";
import colorScheme from "./chart-color-scheme";
import { OrgMetricsCard } from "../components/org-metrics-card/org-metrics-card";

interface IProps {
	scope: IChartScope;
	showExternalLegend?: boolean;
	showDataLabels?: boolean;
	chartHeight?: {
		mainChartHeight: number,
		summaryPieChartHeight: number
	};
}

interface ISeriesProps {
	sortOrder: number;
	seriesName: string;
	seriesColorHex: string;
}

const DEFAULT_DISPLAY_PROPERTIES: { [key: string]: ISeriesProps } = {
	0: {
		sortOrder: 0,
		seriesName: "Highest",
		seriesColorHex: colorScheme.taskPriority.highest
	},
	1: {
		sortOrder: 1,
		seriesName: "High",
		seriesColorHex: colorScheme.taskPriority.high
	},
	2: {
		sortOrder: 2,
		seriesName: "Medium",
		seriesColorHex: colorScheme.taskPriority.medium
	}
};

function priorityOverrideByOrder(zeroBasedOrderIndex: number, priorities?: IDashboardJiraPriority[]): ISeriesProps {
	const displayProperties = DEFAULT_DISPLAY_PROPERTIES[zeroBasedOrderIndex];
	let overridePriority: IDashboardJiraPriority | undefined;
	if (priorities && priorities.length > zeroBasedOrderIndex) {
		overridePriority = priorities[zeroBasedOrderIndex];
	}

	// If we have a priority, but it is not on the same order level we are, then ignore it.
	if (overridePriority && overridePriority.order !== zeroBasedOrderIndex) {
		overridePriority = undefined;
	}

	if (displayProperties) {
		return {
			sortOrder: displayProperties.sortOrder,
			seriesName: (overridePriority?.name ?? displayProperties.seriesName),
			seriesColorHex: displayProperties.seriesColorHex,
		};
	}
	return {
		seriesName: `${zeroBasedOrderIndex}`,
		seriesColorHex: uniqolor(`${zeroBasedOrderIndex}`),
		sortOrder: zeroBasedOrderIndex
	};
}

function MTTRByPriority(props: IProps) {
	const { metricOrgStore, priorityStore } = useStores();
	const [expandedChartOptions, setExpandedChartOptions] = useState<Highcharts.Options | undefined>(undefined);
	const [summarizedChartOptions, setSummarizedChartOptions] = useState<Highcharts.Options | undefined>(undefined);
	const [summarizedLegendList, setSummarizedLegendList] = useState<{ data: ILegendList[], title?: string } | undefined>(undefined);
	const [hoveredSummaryMetric, setHoveredSummaryMetric] = useState<undefined | string>(undefined);

	const { scope, showDataLabels, showExternalLegend, chartHeight } = { ...props };
	const tooltip = "This metric measures the amount of time spent from a bug being opened until it is ultimately resolved. Bugs are split by priority.";

	function buildExpandedChartOptions(data: {
		averageLeadTime: CategoryChartSeriesData,
		bugCountByPriority: CategoryChartSeriesData,
	}, topPriorities: IDashboardJiraPriority[] | undefined): Highcharts.Options {
		let series: Highcharts.SeriesColumnOptions[] | undefined;
		series = Object
			.keys(data.averageLeadTime)
			.filter(key => isNaN(parseInt(key, 10)) === false)
			.map(key => {
				const priorityOrder = parseInt(key, 10);
				const displayProperties = priorityOverrideByOrder(priorityOrder, topPriorities);
				const seriesOptions: Highcharts.SeriesColumnOptions = {
					name: (displayProperties !== undefined ? displayProperties.seriesName : key),
					index: displayProperties?.sortOrder,
					legendIndex: displayProperties?.sortOrder,
					color: displayProperties?.seriesColorHex,
					data: data.averageLeadTime[key],
					tooltip: {
						headerFormat: '<span style="color:{point.color}">\u25CF</span><b> {series.name}</b>:<br>',
						pointFormatter: function _pointFormatter() {
							const matchingCategory = data.bugCountByPriority[key];
							const matchingValue = matchingCategory.find(mc => mc[0] === this.x);
							const hours = this.y ? round(this.y) : 0;
							if (!matchingValue) {
								return `${hours} hours<br />`;
							}
							return `${hours} hours<br />${matchingValue[1] ?? 0} bugs`;
						},
						followPointer: true
					},
					showInLegend: showExternalLegend ? false : true,
					type: "column"
				};
				return seriesOptions;
			});
		const options: Highcharts.Options = {
			title: undefined,
			chart: { height: 328 },
			xAxis: {
				gridLineWidth: 1,
				type: "datetime"
			},
			tooltip: {
				useHTML: true,
				style: {
					pointerEvents: "auto"
				}
			},
			yAxis: [
				{
					min: 0,
					title: {
						text: "Hours"
					},
					labels: {
						formatter: function _formatter() {
							return `${this.value}h`;
						}
					}
				}
			],
			credits: {
				enabled: false
			},
			series
		};

		return options;
	}

	type EnrichedPointOptions = Highcharts.PointOptionsObject & {
		avgHoursPerPriority: number;
	};

	function buildSummarizedChartOptions(data: {
		averageLeadTime: CategoryChartSeriesData,
		bugCountByPriority: CategoryChartSeriesData,
	}, topPriorities: IDashboardJiraPriority[] | undefined): Highcharts.Options {
		const piePoints = Object
			.keys(data.averageLeadTime)
			.filter(key => isNaN(parseInt(key, 10)) === false)
			.map(key => {
				const priorityOrder = parseInt(key, 10);
				const displayProperties = priorityOverrideByOrder(priorityOrder, topPriorities);

				const bugCountForPriorityCategorySeries = data.bugCountByPriority[key];
				let sumOfBugCountForPriority = 0;
				for (const avgLeadTimeCategory of data.averageLeadTime[key]) {
					const period = avgLeadTimeCategory[0];
					const avgLeadTimeCategoryInPeriod = avgLeadTimeCategory[1];
					const bugCountForPriorityInPeriod = bugCountForPriorityCategorySeries.find(mc => mc[0] === period);
					sumOfBugCountForPriority += ((bugCountForPriorityInPeriod ? bugCountForPriorityInPeriod[1] : 0) * avgLeadTimeCategoryInPeriod);
				}
				const totalValues = _.sumBy(bugCountForPriorityCategorySeries, d => d[1]);

				const seriesOptions: EnrichedPointOptions = {
					name: (displayProperties !== undefined ? displayProperties.seriesName : key),
					color: displayProperties?.seriesColorHex,
					y: totalValues,
					avgHoursPerPriority: (totalValues === 0 ? 0 : round(sumOfBugCountForPriority / totalValues, 0))
				};
				return seriesOptions;
			});
		setSummarizedLegendList({
			data: ((array: EnrichedPointOptions[]): ILegendList[] => {
				return array.map(point => {
					return {
						name: point.name?.toString() ?? "",
						value: point.avgHoursPerPriority,
						color: point.color?.toString(),
						valueSuffix: "hrs"
					};
				});
			})(piePoints),
			title: "Total amount of bugs vs average"
		});

		const pieSeries: Highcharts.SeriesPieOptions = {
			type: "pie",
			data: piePoints,
			innerSize: "80%",
			tooltip: {
				pointFormat: "<b>{point.percentage:.1f}%</b>"
			}
		};
		const options: Highcharts.Options = {
			chart: {
				plotBackgroundColor: undefined,
				plotBorderWidth: undefined,
				plotShadow: false
			},
			title: {
				text: `% out of total bugs`,
				verticalAlign: "middle",
				floating: true
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: "pointer",
					dataLabels: showDataLabels ? {
						enabled: true,
						format: "<b>{point.name}</b>: {point.y} hr"
					} : {
						enabled: false
					}
				}
			},
			credits: {
				enabled: false
			},
			series: [pieSeries]
		};

		return options;
	}

	async function fetchData(): Promise<{
		averageLeadTime: CategoryChartSeriesData,
		bugCountByPriority: CategoryChartSeriesData,
	} | undefined> {
		const data = await metricOrgStore.mttrByPriority(scope.startTime, scope.endTime, scope.timezone, scope.interval,
			undefined, scope.projectIds, scope.dataContributorIds);
		return data;
	}

	function shouldFetchData(): boolean {
		return (!metricOrgStore.isLoadingMTTRByPriorityMetric);
	}

	useDeepCompareEffect(() => {
		let isMounted = true;
		async function fetchChartData() {

			let topPriorities = priorityStore.priorities;
			if (!topPriorities) {
				topPriorities = await priorityStore.fetchJiraPriorities(3);
			}

			const data = await fetchData();

			if (isMounted && data) {
				setExpandedChartOptions(buildExpandedChartOptions(data, topPriorities));
				setSummarizedChartOptions(buildSummarizedChartOptions(data, topPriorities));
			}
		}

		if (shouldFetchData()) {
			// We need to clear those options as we might have a different count of columns which will cause to a crash in highcharts
			setExpandedChartOptions(undefined);
			setSummarizedChartOptions(undefined);

			// tslint:disable-next-line: no-floating-promises
			fetchChartData();
		}
		return () => { isMounted = false; };
	}, [props]);

	return (
		<OrgMetricsCard title="Mean time to resolve by priority" tooltip={tooltip} proportions={[6, 4]}>
			<div className="mttr-priority-left">
				<LoadingIndicator local={true} isActive={expandedChartOptions === undefined}>
					{expandedChartOptions && (
						<HighchartsReact
							highcharts={Highcharts}
							options={expandedChartOptions}
						/>
					)}
				</LoadingIndicator>
			</div>

			<div className="mttr-priority-right">
				<LoadingIndicator local={true} isActive={expandedChartOptions === undefined}>
					<div>
						{summarizedLegendList && (
							<h3 className="mttr-priority-chart-title">
								{summarizedLegendList?.title}
							</h3>
						)}
						{showExternalLegend && (
							<div className="mttr-priority-chart-container">
								<div className="mttr-priority-chart-pie">
									{summarizedChartOptions && (
										<PieChart
											series={summarizedChartOptions?.series}
											triggerHover={hoveredSummaryMetric}
											height={chartHeight ? chartHeight?.summaryPieChartHeight : undefined}
										/>
									)}
								</div>
								<div className="mttr-priority-chart-legend">
									{summarizedChartOptions?.series !== undefined && (
										<ChartExternalLegend
											legendList={summarizedLegendList?.data}
											hoveredEntry={hoveredSummaryMetric}
											setHoveredEntry={setHoveredSummaryMetric}
											legendTitle="Average per priority"
										/>
									)}
								</div>
							</div>
						)}
						{summarizedChartOptions && !showExternalLegend && (
							<HighchartsReact
								highcharts={Highcharts}
								options={summarizedChartOptions}
							/>
						)}
					</div>
				</LoadingIndicator>
			</div>
		</OrgMetricsCard>
	);
}

export default MTTRByPriority;
