import React from "react";
import { ExternalImage } from "../../../../components/external-image";
import groupAvatar from "../../../../components/svg-assets/group-avatar.svg";
import BaseDropdownOption from "../../../../components/filters/users-selector/users-dropdown/base-dropdown-option";
import { ContributorFilterModel } from "../../../../types/contributor-filter.model";

interface ContributorsFilterDropdownOptionProps {
	index: number;
	contributorFilter: ContributorFilterModel;
	isSelected: boolean;
	onToggle: (id: number) => void;
}

const ContributorsFilterDropdownOption = (props: ContributorsFilterDropdownOptionProps) => {
	const { index, contributorFilter, isSelected, onToggle } = props;

	return (
		<BaseDropdownOption
			value={index}
			avatar={
				<ExternalImage
					className="ui image avatar white-bg avatar-selector-item-avatar"
					src={groupAvatar}
				/>
			}
			label={contributorFilter.name}
			isSelected={isSelected}
			onToggle={onToggle}
		/>
	);
};

export default ContributorsFilterDropdownOption;
