import React from "react";
import classNames from "classnames";
import SuperstarIcon from "../svg-assets/superstar-icon.svg";
import OnTheRiseIcon from "../svg-assets/on-the-rise-icon.svg";
import TeamPlayerIcon from "../svg-assets/team-player-icon.svg";
import NeedsAttentionIcon from "../svg-assets/needs-attention-icon.svg";
import SuperstarBadge from "../svg-assets/superstar-badge.svg";
import OnTheRiseBadge from "../svg-assets/on-the-rise-badge.svg";
import TeamPlayerBadge from "../svg-assets/team-player-badge.svg";
import NeedsAttentionBadge from "../svg-assets/needs-attention-badge.svg";
import "./badge.scss";
import { DeveloperBadge as DeveloperBadgeEnum, DeveloperBadgeMetric, TeamBadge, TeamBadgeMetric } from "@acumen/database-types";
import { DashboardBadge } from "../../types/workforce-health";
import { BadgeOwner } from "v2/types/badges";

export enum BadgeType {
	AggregatedBadge = "agg-badge",
	MetricBadge = "metric-badge"
}
export interface BadgeProps {
	badgeOwner: BadgeOwner;
	badgeType: BadgeType;
	metric?: DeveloperBadgeMetric | TeamBadgeMetric;
	badge: DashboardBadge | TeamBadge;
	badgeReason?: string | null;
}

export const Badge = (props: BadgeProps) => {
	const { badgeType, metric, badge, badgeReason, badgeOwner } = props;
	const badgeText = badgeOwner === BadgeOwner.Developer ? devBadgeTexts[badge as DashboardBadge] : teamBadgeTexts[badge as TeamBadge];
	const badgeDrillDownText = badgeOwner === BadgeOwner.Developer
		? devBadgeDrilldownTexts[badge as DashboardBadge][metric as DeveloperBadgeMetric]
		: teamBadgeDrilldownTexts[badge as TeamBadge][metric as TeamBadgeMetric];
	const icon = (icons[badgeType][badgeOwner] as Record<string, string>)[badge];
	const text = (badgeType === BadgeType.AggregatedBadge) ? badgeText : badgeDrillDownText;

	return (
		<div>
			<div className={classNames("badge", `${badgeType}`, `badge-${badge.toLowerCase()}`)}>
				<img className="badge-icon" src={icon} alt={`${badge} badge`} />
				<div className="badge-text">{text}</div>

			</div>
			<div className="badge-reason">{badgeReason ?? ""}</div>
		</div>
	);
};

type BadgeIconByTypeAndOwner = {
	[badgeType in BadgeType]: {
		[BadgeOwner.Developer]: Record<DashboardBadge, string>,
		[BadgeOwner.Team]: Record<TeamBadge, string>,
	}
};

const icons: BadgeIconByTypeAndOwner = {
	[BadgeType.AggregatedBadge]: {
		[BadgeOwner.Developer]: {
			[DeveloperBadgeEnum.SuperStar]: SuperstarIcon,
			[DeveloperBadgeEnum.OnTheRise]: OnTheRiseIcon,
			[DeveloperBadgeEnum.TeamPlayer]: TeamPlayerIcon,
			[DeveloperBadgeEnum.NeedAttention]: NeedsAttentionIcon,
		},
		[BadgeOwner.Team]: {
			[TeamBadge.TopPerformer]: SuperstarIcon,
			[TeamBadge.OnTheRise]: OnTheRiseIcon,
			[TeamBadge.NeedAttention]: NeedsAttentionIcon,
		},
	},
	[BadgeType.MetricBadge]: {
		[BadgeOwner.Developer]: {
			[DeveloperBadgeEnum.SuperStar]: SuperstarBadge,
			[DeveloperBadgeEnum.OnTheRise]: OnTheRiseBadge,
			[DeveloperBadgeEnum.TeamPlayer]: TeamPlayerBadge,
			[DeveloperBadgeEnum.NeedAttention]: NeedsAttentionBadge,
		},
		[BadgeOwner.Team]: {
			[TeamBadge.TopPerformer]: SuperstarBadge,
			[TeamBadge.OnTheRise]: OnTheRiseBadge,
			[TeamBadge.NeedAttention]: NeedsAttentionBadge,
		},
	},
};

const devBadgeTexts: Record<DashboardBadge, string> = {
	[DeveloperBadgeEnum.SuperStar]: "Superstar",
	[DeveloperBadgeEnum.OnTheRise]: "On the rise",
	[DeveloperBadgeEnum.TeamPlayer]: "Team Player",
	[DeveloperBadgeEnum.NeedAttention]: "Needs Attention",
};

const teamBadgeTexts: Record<TeamBadge, string> = {
	[TeamBadge.TopPerformer]: "Top performer",
	[TeamBadge.OnTheRise]: "On the rise",
	[TeamBadge.NeedAttention]: "Needs attention",
};

const devBadgeDrilldownTexts: Record<DashboardBadge, {[K in DeveloperBadgeMetric]?: string}> = {
	[DeveloperBadgeEnum.SuperStar]: {
		[DeveloperBadgeMetric.Velocity]: "Top contributor",
		[DeveloperBadgeMetric.HighPriorityTasks]: "Heavy lifter",
		[DeveloperBadgeMetric.ReviewedPRs]: "Top reviewer",
		[DeveloperBadgeMetric.PRSize]: "Efficient coder",
		[DeveloperBadgeMetric.PRCycleTimeWIPAndReview]: "Efficient coder"
	},
	[DeveloperBadgeEnum.OnTheRise]: {
		[DeveloperBadgeMetric.Velocity]: "Improving",
		[DeveloperBadgeMetric.HighPriorityTasks]: "Improving",
		[DeveloperBadgeMetric.ReviewedPRs]: "Improving",
		[DeveloperBadgeMetric.PRSize]: "Improving",
		[DeveloperBadgeMetric.PRCycleTimeWIPAndReview]: "Improving"
	},
	[DeveloperBadgeEnum.TeamPlayer]: {
		[DeveloperBadgeMetric.HighPriorityTasks]: "Heavy lifter",
		[DeveloperBadgeMetric.ReviewedPRs]: "Top reviewer"
	},
	[DeveloperBadgeEnum.NeedAttention]: {
		[DeveloperBadgeMetric.Velocity]: "Low velocity",
		[DeveloperBadgeMetric.ReviewedPRs]: "Fewer reviews",
		[DeveloperBadgeMetric.PRSize]: "Large PRs",
		[DeveloperBadgeMetric.PRCycleTimeWIPAndReview]: "Long cycle times"
	}
};

const teamBadgeDrilldownTexts: Record<TeamBadge, {[K in TeamBadgeMetric]?: string}> = {
	[TeamBadge.TopPerformer]: {
		[TeamBadgeMetric.Velocity]: "Top contributor",
		[TeamBadgeMetric.AvgPRCycleTimeTotal]: "Efficient coders",
		[TeamBadgeMetric.AvgPRCycleTimeAwaitingReview]: "Quick pickup",
		[TeamBadgeMetric.AvgPRSize]: "Efficient coders",
		[TeamBadgeMetric.PRReviewersPercent]: "Distributed reviewers",
		[TeamBadgeMetric.WorkDistributionBugsPercent]: "Balanced work",
		[TeamBadgeMetric.MTTR]: "Bug squashers"
	},
	[TeamBadge.OnTheRise]: {
		[TeamBadgeMetric.Velocity]: "Improving",
		[TeamBadgeMetric.AvgPRCycleTimeTotal]: "Improving",
		[TeamBadgeMetric.AvgPRCycleTimeAwaitingReview]: "Improving",
		[TeamBadgeMetric.AvgPRSize]: "Improving",
		[TeamBadgeMetric.PRReviewersPercent]: "Improving",
		[TeamBadgeMetric.WorkDistributionBugsPercent]: "Improving",
		[TeamBadgeMetric.MTTR]: "Improving"

	},
	[TeamBadge.NeedAttention]: {
		[TeamBadgeMetric.Velocity]: "Low velocity",
		[TeamBadgeMetric.AvgPRCycleTimeTotal]: "Long cycle time",
		[TeamBadgeMetric.AvgPRCycleTimeAwaitingReview]: "Long pickup time",
		[TeamBadgeMetric.AvgPRSize]: "Large PRs",
		[TeamBadgeMetric.PRReviewersPercent]: "Few reviewers",
		[TeamBadgeMetric.WorkDistributionBugsPercent]: "Bug heavy",
		[TeamBadgeMetric.MTTR]: "Long time to resolve"
	}
};
