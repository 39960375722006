import React from "react";

// tslint:disable-next-line: variable-name
export const ExternalImage = (props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => {
	return (
		// eslint-disable-next-line jsx-a11y/alt-text
		<img
			onError={
				i => i.currentTarget.src = "/assets/images/broken-image.svg"
			}
			{...props}
		/>
	);
};
