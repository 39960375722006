import BaseStore from "./base-store";
import { observable, action } from "mobx";
import apiContextProvider from "../../services/api-context-provider";
import { CategoryChartSeriesData, ChartsApiClient } from "../services/crud/charts-api-client";
import {
	AcumenTaskType, ChartType, DashboardExportFormat, DateRangeType,
	IChartResponse, IDashboardPRSizeToMergeTime, MetricInterval
} from "@acumen/dashboard-common";
import { FetchLatestRequest } from "../../services/fetch-helpers";
import { createHighChartOptionObject } from "../services/crud/chart-creator";

export default class ChartsStore extends BaseStore<{}> {
	private readonly apiClient: ChartsApiClient = new ChartsApiClient(apiContextProvider);

	@observable
	public prSizeToMergeTimeChartData?: IDashboardPRSizeToMergeTime;

	@observable
	public isLoadingDeploymentFrequencyChartData: boolean = false;
	@observable
	public deploymentFrequencyChartData?: CategoryChartSeriesData;

	@observable
	public isLoadingGitHubReportedDeploymentFrequencyChartData: boolean = false;
	@observable
	public githubReportedDeploymentFrequencyChartData?: CategoryChartSeriesData;

	@observable
	public pullRequestCycleTimeChart?: Highcharts.Options;
	private fetchLatestsPullRequestCycleTimeChart = new FetchLatestRequest<IChartResponse, void>("/charts/data?pullRequestChartCycleTime");

	@observable
	public closedUnmergedPrsChart?: Highcharts.Options;
	private fetchLatestsClosedUnmergedPrsChart = new FetchLatestRequest<IChartResponse, void>("/charts/data?closedUnmergedPrs");

	private fetchLatestPRSizeToMergeTimeData = new FetchLatestRequest<IDashboardPRSizeToMergeTime, any>("/charts/prSizeToMergeTime");

	@action.bound
	public async fetchPRSizeToMergeTimeData(teamIds?: string[], dataContributorIds?: string[], projectIds?: string[],
		startTime?: Date, endTime?: Date, repositoryIds?: string[], baseIsDefaultBranch?: boolean, excludeDraft?: boolean): Promise<IDashboardPRSizeToMergeTime | undefined> {

		const result = await this.fetchLatestPRSizeToMergeTimeData.fetchLatest(this.apiClient.fetchPRSizeToMergeTimeData(
			teamIds, dataContributorIds, projectIds, startTime, endTime, repositoryIds, baseIsDefaultBranch, excludeDraft));

		const chartResponse = result?.data ?? undefined;
		this.prSizeToMergeTimeChartData = chartResponse;
		return chartResponse;
	}

	private fetchLatestDeploymentFrequency = new FetchLatestRequest<CategoryChartSeriesData, any>("/charts/deployment-frequency");
	@action.bound
	public async fetchDeploymentFrequency(interval: MetricInterval, startTime: Date, endTime: Date,
		timezone: string, teamIds?: string[], dataContributorIds?: string[], gitRepositoryIds?: string[]): Promise<CategoryChartSeriesData | undefined> {

		this.isLoadingDeploymentFrequencyChartData = true;
		const result = await this.fetchLatestDeploymentFrequency.fetchLatest(this.apiClient.fetchDeploymentFrequency(
			interval, startTime, endTime, timezone, teamIds, dataContributorIds, gitRepositoryIds));
		const chartResponse = result?.data ?? undefined;
		this.deploymentFrequencyChartData = chartResponse;
		this.isLoadingDeploymentFrequencyChartData = false;
		return chartResponse;
	}

	private fetchLatestGitHubReportedDeploymentFrequency = new FetchLatestRequest<CategoryChartSeriesData, any>("/charts/github-reported-deployment-frequency");
	@action.bound
	public async fetchGitHubReportedDeploymentFrequency(interval: MetricInterval, startTime: Date, endTime: Date,
		timezone: string, teamIds?: string[], dataContributorIds?: string[], gitRepositoryIds?: string[], environments?: string[]): Promise<CategoryChartSeriesData | undefined> {

		this.isLoadingGitHubReportedDeploymentFrequencyChartData = true;
		const result = await this.fetchLatestGitHubReportedDeploymentFrequency.fetchLatest(this.apiClient.fetchGitHubReportedDeploymentFrequency(
			interval, startTime, endTime, timezone, teamIds, dataContributorIds, gitRepositoryIds, environments));
		const chartResponse = result?.data ?? undefined;
		this.githubReportedDeploymentFrequencyChartData = chartResponse;
		this.isLoadingGitHubReportedDeploymentFrequencyChartData = false;
		return chartResponse;
	}

	@action.bound
	public async refreshPullRequestCycleTimeChart(interval: MetricInterval, dateRange: DateRangeType,
		timezone: string, teamIds: string[] = [], dataContributorIds: string[] = [],
		boardIds: string[] = [], projectIds: string[] = [], repositoryIds: string[] = [],
		acumenTaskTypes: AcumenTaskType[] = [], excludeDraftPrs?: boolean, baseIsDefaultBranch?: boolean) {
		this.pullRequestCycleTimeChart = undefined;

		this.fetchLatestsPullRequestCycleTimeChart.fetchLatest(this.apiClient.fetchChartData(ChartType.PRCycleTime, interval, dateRange,
			timezone, teamIds, dataContributorIds, boardIds, projectIds, repositoryIds, acumenTaskTypes, excludeDraftPrs, baseIsDefaultBranch,
		)).then(x => {
			this.pullRequestCycleTimeChart = createHighChartOptionObject(ChartType.PRCycleTime, (x === null) ? {} : x.data, interval);
		}).catch(e => {
			this.pullRequestCycleTimeChart = undefined;
		});
	}

	@action.bound
	public async refreshClosedUnmergedPrsChart(interval: MetricInterval, dateRange: DateRangeType,
		timezone: string, teamIds: string[] = [], dataContributorIds: string[] = [],
		boardIds: string[] = [], projectIds: string[] = [], repositoryIds: string[] = [],
		acumenTaskTypes: AcumenTaskType[] = [], excludeDraftPrs?: boolean, baseIsDefaultBranch?: boolean) {
		this.closedUnmergedPrsChart = undefined;

		this.fetchLatestsClosedUnmergedPrsChart.fetchLatest(this.apiClient.fetchChartData(ChartType.ClosedUnMergedPrs, interval, dateRange,
			timezone, teamIds, dataContributorIds, boardIds, projectIds, repositoryIds, acumenTaskTypes, excludeDraftPrs, baseIsDefaultBranch,
		)).then(x => {
			this.closedUnmergedPrsChart = createHighChartOptionObject(ChartType.ClosedUnMergedPrs, (x === null) ? {} : x.data, interval);
		}).catch(e => {
			this.closedUnmergedPrsChart = undefined;
		});
	}

	public async generateEmbeddedChart(type: ChartType, interval: MetricInterval, dateRange: DateRangeType,
			timezone: string, teamIds: string[] = [], dataContributorIds: string[] = [],
			boardIds: string[] = [], projectIds: string[] = [], repositoryIds: string[] = [],
			acumenTaskTypes: AcumenTaskType[] = [], excludeDraftPrs?: boolean, baseIsDefaultBranch?: boolean) {
		return this.apiClient.createChartIframe(type, interval, dateRange,
			timezone, teamIds, dataContributorIds, boardIds, projectIds, repositoryIds, acumenTaskTypes, excludeDraftPrs, baseIsDefaultBranch);
	}

	@action.bound
	public async exportChart(format: DashboardExportFormat, chart: ChartType, interval: MetricInterval, dateRange: DateRangeType,
		timezone: string, teamIds: string[] = [], dataContributorIds: string[] = [],
		boardIds: string[] = [], projectIds: string[] = [], repositoryIds: string[] = [],
		acumenTaskTypes: AcumenTaskType[] = [], excludeDraftPrs?: boolean, baseIsDefaultBranch?: boolean) {

		return this.apiClient.exportChart(format, chart, interval, dateRange, timezone,
			teamIds, dataContributorIds, boardIds, projectIds, repositoryIds, acumenTaskTypes, excludeDraftPrs,
			baseIsDefaultBranch);
	}
}
