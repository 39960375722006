export const normalizeSeriesKey = (input: string): string => {
	return input.toLowerCase().replace(/[^a-z]/g, "");
};
export const colorFunction = {
	genericScheme: {
		task: "#B8A1EA",
		pr: "#6C9EE5"
	},
	issueType: {
		bug: "#F77C9A",
		epic: "#B8A1EA",
		story: "#73D789",
		task: "#6C9EE5",
		subTask: "#FFBB36",
		changeRequest: "#E1A2B2",
		newFeature: "#AE6AAF",
		improvement: "#B8D974",
		techTask: "#F3E13D",
		support: "#606EB8",
		security: "#73D7CB",
		documentation: "#F47C56",
		productionIssue: "#B9C7DA",
	},
	taskPriority: {
		highest: "#E9153B",
		high: "#F77C9A",
		medium: "#FFBB36"
	},
	prStatus: {
		WIP: "#2278F3",
		reviewedRequested: "#32B195",
		inReview: "#925EFF",
		pendingMerge: "#FFBB36"
	},
	deployments: {
		production: "#925EFF",
		staging: "#2278F3"
	},
};
export default colorFunction;
