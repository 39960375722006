import * as React from "react";
const Chicken = (props: React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fill="#EA5A47"
			d="M44.62 26.25S48.995 19 46.995 18s-5 1-5 1 2-7-2-7-5 4-5 4-1.457-8.296-7-6c-3.464 1.435-5 5 0 15"
		/>
		<path
			fill="#FFF"
			d="M21 35s4-11 15-11 15 11 15 11c15 28-15 29-15 29S6 63 21 35z"
		/>
		<path fill="#F1B31C" d="M36 43s18-1 0 18c0 0-18-18 0-18z" />
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M44.46 26.25S48.835 19 46.835 18s-5 1-5 1 2-7-2-7-5 4-5 4-1.457-8.296-7-6c-3.464 1.435-5 5 0 15"
		/>
		<circle cx={27} cy={39} r={2} />
		<circle cx={45} cy={39} r={2} />
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M36.02 43s18-1 0 18c0 0-18-18 0-18z"
		/>
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M21 35s4-11 15-11 15 11 15 11c15 28-15 29-15 29S6 63 21 35z"
		/>
	</svg>
);
export default Chicken;
