import apiContextProvider from "../../services/api-context-provider";
import { FetchLatestRequest } from "../../services/fetch-helpers";
import {
	SETUP_PROGRESS_ROUTE, GoRetroSprintSummaryApiClient, BILLING_SUBSCRIPTION_DETAILS_ROUTE
} from "../services/crud/go-retro-sprint-review-api-client";
import { action, observable } from "mobx";
import {
	AcumenId,
	BillingState,
	BillingType,
	CustomizableConfiguration, IDashboardSprint, PremiumFeatureType, SubtaskSprintStrategy, TaskEstimationMethod
} from "@acumen/dashboard-common";
import moment from "moment-timezone";
import "moment/min/locales";

export interface ProgressUpdate {
	fastFetchingFinished: boolean;
	fastFetchingError: string | null;
	sprintsSelectedPerformed: boolean;
	goRetroConfigurationPerformed: boolean;
	secondFetchingFinished: boolean;
	hasActiveJIRAIntegration: boolean;
	jira3loFetchingPeriodStart: Date | null;
}

export interface IAutoDetectSettingsParams {
	"sprints[]": string[];
	"timezone": string;
	"locale": string;
}

export interface IRetroAutoDetectedConfigurationResponse {
	[CustomizableConfiguration.EstimationMethod]: TaskEstimationMethod;
	[CustomizableConfiguration.Locale]: string;
	[CustomizableConfiguration.PlannedGracePeriod]: number;
	[CustomizableConfiguration.SubtaskSprintStrategy]: SubtaskSprintStrategy;
	[CustomizableConfiguration.TimeZone]: string;
}

export interface IBillingSubscriptionDetails {
	customerId: AcumenId;
	tierName: string | null;
	billingPeriodEnd: Date | null;
	billingPeriodStart: Date | null;
	billingType: BillingType | null;
	state: BillingState | null;
	activeFeatures: PremiumFeatureType[];
	isActive: boolean;
}

const DEFAULT_LOCALE = "en";
export const getAutoDetectParams = (selectedSprints: string[]): IAutoDetectSettingsParams => {
	let locale = DEFAULT_LOCALE;
	if (window.navigator.language) {
		const localesBeforeSet = moment.locales();
		const parsedLocale = moment.locale(window.navigator.language);

		const matchingLocale = localesBeforeSet.find(l => l.toLowerCase() === parsedLocale);
		if (matchingLocale) {
			locale = parsedLocale;
		}
	}

	return {
		"sprints[]": selectedSprints,
		"timezone": moment.tz.guess(),
		"locale": locale.toLowerCase()
	};
};

export default class GoRetroSprintSummaryStore {
	private readonly apiClient: GoRetroSprintSummaryApiClient = new GoRetroSprintSummaryApiClient(apiContextProvider);

	@observable
	progressUpdate: ProgressUpdate | undefined = undefined;

	@observable
	selectedSprint: IDashboardSprint | undefined = undefined;

	@observable
	subscriptionData: IBillingSubscriptionDetails | undefined = undefined;

	private fetchLatestProgress = new FetchLatestRequest<ProgressUpdate, any>(SETUP_PROGRESS_ROUTE);
	@action.bound
	async fetchIntegrationProgress(): Promise<ProgressUpdate | undefined> {
		const result = await this.fetchLatestProgress.fetchLatest(this.apiClient.fetchProgressUpdate());
		this.progressUpdate = result?.data;
		return result?.data;
	}

	@action.bound
	async setSprintSelection(sprintIds: string[]) {
		return await this.apiClient.setSprints(sprintIds, false);
	}

	@action.bound
	async fetchAutoDetectedConfigurations(params: IAutoDetectSettingsParams) {
		return await this.apiClient.fetchAutoDetectedConfigurations(params);
	}

	@action.bound
	setSelectedSprint(sprint: IDashboardSprint) {
		this.selectedSprint = sprint;
	}

	private fetchLatestSubscriptionDetails = new FetchLatestRequest<IBillingSubscriptionDetails, any>(BILLING_SUBSCRIPTION_DETAILS_ROUTE);
	@action.bound
	async fetchSubscriptionDetails(): Promise<IBillingSubscriptionDetails | undefined> {
		const result = await this.fetchLatestSubscriptionDetails.fetchLatest(this.apiClient.fetchSubscriptionData());
		this.subscriptionData = result?.data;
		return result?.data;
	}
}
