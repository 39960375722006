import { useEffect, useState } from "react";
import { DashboardSortOrder, DateRangeType, DevelopmentMethodology, SprintSortField } from "@acumen/dashboard-common";
import { ISprintDropdownOption } from "../../../../components/filters-selector/sections/sprints-selector";
import { useStores } from "../../../../mobx-stores";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import { MAP_DATE_RANGE_TO_VALUE } from "../../../../components/filters-selector/sections/time-frames-selector";
import moment from "moment";
import { DateFormatters, DEFAULT_DATE_RANGE_IN_DAYS } from "../../../../components/filters-selector/date-formatters";

interface ISprintFilter {
	selectedTeamId: string;
	id?: string;
	sprintValue?: string;
	dateRangeValue?: DateRangeType;
}

interface IFilterSlice {
	searchedTeamId?: string;
	sprintFilter: ISprintFilter;
	setSprintFilter: React.Dispatch<React.SetStateAction<ISprintFilter>>;
	storageKey: string;
}

/* eslint-disable import/no-anonymous-default-export */
export default ({
	searchedTeamId,
	sprintFilter,
	setSprintFilter,
	storageKey
	}: IFilterSlice) => {
	const { sprintsStore, authStore } = useStores();
	const { fetchData, isLoading } = sprintsStore;
	const [sprintsList, setSprintsList] = useState<ISprintDropdownOption[]>([]);
	const sort = { field: SprintSortField.StartDate, order: DashboardSortOrder.Descending };
	const [endTime, setEndTime] = useState<Date | undefined>(moment().toDate());
	const [startTime, setStartTime] = useState<Date | undefined>(moment(endTime).subtract((DEFAULT_DATE_RANGE_IN_DAYS), "days").startOf("day").toDate());
	const [previousStartTime, setPreviousStartTime] = useState<Date>(startTime!);
	const [previousEndTime, setPreviousEndTime] = useState<Date>(endTime!);
	const isRangeSelector = true;
	const formatters = new DateFormatters(isRangeSelector, authStore.authUser.locale);

	const [selectedSelectorValue, setSelectedSelectorValue] =
		useLocalStorage<string | undefined>(storageKey, undefined);

	useEffect(() => {
		async function retrieveSprints() {
			if (isLoading) {
				return;
			}
			// Note: we currently don't support dev cycles
			const fetchedSprints = await fetchData(DevelopmentMethodology.Scrum, {teamId: searchedTeamId}, sort);

			setSprintsList(fetchedSprints.map(s => ({
				key: s.id,
				value: `${s.id},${s.integrationType}`,
				label: (s.isActive ? `${s.name} - Active` : s.name)
			})));
		}

		if (searchedTeamId !== "" && searchedTeamId !== undefined) {
			// tslint:disable-next-line: no-floating-promises
			retrieveSprints();
		}
	}, [searchedTeamId, setSprintFilter]);

	useEffect(() => {
		if (!sprintFilter.selectedTeamId || sprintFilter.selectedTeamId?.length < 1) {
			setSprintFilter(prevState => {
				return {
					...prevState,
					selectedTeamId: searchedTeamId || ""
				};
			});
		}
	}, [sprintFilter.selectedTeamId, setSprintFilter, searchedTeamId]);

	useEffect(() => {
		if (!sprintFilter.sprintValue || sprintFilter.sprintValue?.length < 1 ||
			!sprintFilter.dateRangeValue || sprintFilter.dateRangeValue?.length < 1) {
			if (searchedTeamId) {
				const defaultKey: string | DateRangeType = DateRangeType.Last2Weeks;
				const selectedValueOrDefaultKey = selectedSelectorValue || defaultKey;
				if (Object.values(DateRangeType).includes(selectedValueOrDefaultKey as DateRangeType)) {
					setSprintFilter(prevState => {
						return {
							...prevState,
							dateRangeValue: selectedValueOrDefaultKey as DateRangeType,
							id: `${searchedTeamId}-${selectedValueOrDefaultKey}`
						};
					});
				} else {
					setSprintFilter(prevState => {
						return {
							...prevState,
							sprintValue: selectedValueOrDefaultKey,
							id: `${searchedTeamId}-${selectedValueOrDefaultKey}`
						};
					});
				}

				setSprintFilter(prevState => {
					return {
						...prevState,
						value: selectedSelectorValue || defaultKey,
						id: `${searchedTeamId}-${selectedSelectorValue ? selectedSelectorValue : defaultKey}`
					};
				});
			}
		}
	}, [setSprintFilter, searchedTeamId]);

	useEffect(() => {
		if (sprintFilter.id && searchedTeamId) {
			const sprintFilterId = sprintFilter.id.split("-") || [];
			if (sprintFilterId[1]) {
				setSprintFilter(prevState => {
					return {
						...prevState,
						id: `${searchedTeamId}-${sprintFilterId[1]}`,
						selectedTeamId: searchedTeamId
					};
				});
			}
		}
	}, [searchedTeamId]);

	const getStartAndEndDates = () => {
		let rangeStartTime: Date | undefined;
		let rangeEndTime: Date | undefined;

		if (!sprintFilter.sprintValue && !sprintFilter.dateRangeValue) {
			return {
				rangeStartTime: startTime,
				rangeEndTime: endTime,
				isCustomDateRange: true
			};
		}

		if (sprintFilter.sprintValue) {
			return {
				rangeStartTime: undefined,
				rangeEndTime: undefined,
				isCustomDateRange: false
			};
		}

		rangeEndTime = new Date();
		switch (sprintFilter.dateRangeValue) {
			case DateRangeType.LastDay: {
				rangeStartTime = moment.utc(endTime).subtract(1, "day").toDate();
				break;
			}
			case DateRangeType.Last3Days: {
				rangeStartTime = moment.utc(endTime).subtract(2, "days").toDate();
				break;
			}
			case DateRangeType.LastWeek: {
				rangeStartTime = moment.utc(endTime).subtract(1, "weeks").toDate();
				break;
			}
			case DateRangeType.Last2Weeks: {
				rangeStartTime = moment.utc(endTime).subtract(2, "weeks").toDate();
				break;
			}
			case DateRangeType.LastMonth: {
				rangeStartTime = moment.utc(endTime).subtract(1, "month").toDate();
				break;
			}
			case DateRangeType.Last3Months:
			default: {
				rangeStartTime = moment.utc(endTime).subtract(3, "months").toDate();
				break;
			}
		}
		return {
			rangeStartTime,
			rangeEndTime,
			isCustomDateRange: false
		};
	};

	const getFilteredValueLabel = () => {
		const selectedDateRange = getStartAndEndDates();

		if (!sprintFilter.dateRangeValue && !sprintFilter.sprintValue &&
			!selectedDateRange.rangeStartTime && !selectedDateRange.rangeEndTime) {
			return "";
		}
		const customDateValue = formatters.dateRangeLabelFormat(selectedDateRange.rangeStartTime, selectedDateRange.rangeEndTime);
		const presetDateValue = MAP_DATE_RANGE_TO_VALUE[sprintFilter.dateRangeValue as DateRangeType];

		let dateRangeValue = presetDateValue;
		if (selectedDateRange.isCustomDateRange) {
			dateRangeValue = customDateValue;
		}
		const sprintListValue = sprintsList.filter(sprint => sprint.value === sprintFilter.sprintValue)[0];

		return dateRangeValue ? dateRangeValue : sprintListValue?.label || "";
	};

	const setEndDate = (t: Date | undefined, previousStart?: Date) => {
		if (t && startTime) {
			setPreviousStartTime(previousStart ?? startTime);
			setPreviousEndTime(t);
		}
		setEndTime(t);
	};

	return {
		sprintsList,
		getFilteredValueLabel,
		setSelectedSelectorValue,
		isRangeSelector,
		setStartTime,
		setEndDate,
		previousStartTime,
		previousEndTime,
		locale: authStore.authUser.locale,
		getStartAndEndDates
	};
};
