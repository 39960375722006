import React from "react";
import {
	IDashboardWorkInterval, CommitEventType, PullRequestEventType, IssueEventType, IDashboardEvent,
	IDashboardIssueAssigneeHistory, IDashboardIssueStatusHistory, DashboardWorkIntervalType
} from "@acumen/dashboard-common";
import Linkify from "../../../components/formatters/linkify";
import { ExternalImage } from "../../../components/external-image";
import { Avatar } from "../../../components/avatar";
import { TaskStatus } from "../../../components/formatters";
import { Formatters } from "../../../components/dashboard-task/formatters";
import { Popup } from "semantic-ui-react";
import classNames from "classnames";
import { DashboardTaskDigestCard } from "../../../mobx-stores/digest-store";
import moment from "moment";
import { DateFormat, dateFormatByLocale } from "@acumen/common";
import { eventsFormatting } from "../../../components/formatters/string";
import _ from "lodash";

const eventTypesToShow = Object.values(IssueEventType)
	.filter(eventType => (eventType !== IssueEventType.IssueStatusChanged
		&& eventType !== IssueEventType.IssueAssigned
		&& eventType !== IssueEventType.IssueAssignedByUser
	));

const getIconClass = (score: number): string[] => {
	const className = ["icon angle thin small white"];
	if (Math.abs(score) === 2) {
		className.push("double");
	}
	className.push(score > 0 ? "right" : "left");
	return className;
};

const getMarkerColor = (statusMoves: number): string => {
	let className = (Math.abs(statusMoves) === 2) ? "extreme-" : "";
	className += (statusMoves > 0) ? "primary" : "warning";
	return className;
};

// tslint:disable-next-line: variable-name
const DigestTaskCard = (props: {
	card: DashboardTaskDigestCard,
	locale: string
	className: string
}) => {
	const { task, statusHistory, insights, workIntervals, assignees, relatedEvents, movementRanking, onClick } = { ...props.card };
	const numOfCommits = relatedEvents.filter(event => Object.values(CommitEventType).find(t => t === event.eventType)).length;

	const DATE_TIME_FORMAT = dateFormatByLocale(DateFormat.LongWithDayTime, props.locale);
	const dateFormatter = (timeMs: number) => moment(timeMs).format(DATE_TIME_FORMAT);

	return (
		<div className={`ui grid raised clickable task card ${props.className}`}>
			<div className="content" onClick={onClick}>
				<p className="subheader">
					{task.title}
				</p>
				<div className="flex-row spaced">
					<span className="spaced ellipsis limit-width">
						<TaskStatus taskStatus={task.status.status} internalStatus={task.status.internalStatus} hasTooltip={false} />
					</span>
					<span className="inline-pad no-wrap">
						<Linkify
							classNamePrefix="link"
							linkMap={{ [task.publicIdentifier as string]: task.publicHtmlUrl }}
						>
							{task.publicIdentifier}
						</Linkify>
						<Popup
							size="mini"
							basic={true}
							content={task.type.internalType}
							trigger={
								<ExternalImage src={task.type.iconUrl ?? ""} className="ui micro image" />
							}
						/>
						<Popup
							size="mini"
							basic={true}
							content={task.priority.internalPriority}
							trigger={
								<ExternalImage src={task.priority.iconUrl ?? ""} className="ui micro image" />
							}
						/>
						<Popup
							size="mini"
							basic={true}
							style={{ textTransform: "capitalize" }}
							content={task.assignee?.primaryDisplayName}
							trigger={
								<Avatar className="medium" dataContributor={task.assignee} />
							}
						/>
					</span>
				</div>
			</div>
			{(movementRanking !== undefined && movementRanking !== 0) && (
				<div className={classNames("marker", getMarkerColor(movementRanking))}>
					<div className="arrows">
						{[1, 2, 3].map((i: number) =>
							Math.abs(movementRanking) > 1 ?
								<i key={i} className={classNames(getIconClass(movementRanking))} />
								:
								<i key={i} className={classNames(getIconClass(movementRanking))} />
						)}
					</div>
				</div>
			)}
			{
				<WorkIntervals intervals={workIntervals} />
			}
			{insights && insights.length > 0 && (
				<div className="extended-info">
					{insights.map((insight: { text: React.ReactNode; }, i: string | number | undefined) => {
						return (
							<p key={i}>
								<i className={"icon red flag"} />
								{insight.text}
							</p>
						);
					})}
				</div>
			)}
			{ numOfCommits > 0 && <Commits numOfCommits={numOfCommits} />}
			{ statusHistory && statusHistory.length > 1 && <StatusChange statusHistory={statusHistory} dateFormatter={dateFormatter}/>}
			{
				assignees && assignees.length > 0 && assignees[assignees.length - 1].from && assignees[assignees.length - 1].to &&
				<AssigneeChange assignees={assignees} dateFormatter={dateFormatter}/>
			}
			{ relatedEvents && relatedEvents.length > 0 && <IssueEvents relatedEvents={relatedEvents} dateFormatter={dateFormatter}/>}
			{ relatedEvents && relatedEvents.length > 0 && <PREvents relatedEvents={relatedEvents} dateFormatter={dateFormatter} />}
		</div >
	);
};
export default DigestTaskCard;

// tslint:disable-next-line: variable-name
const WorkIntervals = (props: { intervals: IDashboardWorkInterval[] }) => {
	const { intervals } = { ...props };
	const getTotalWork = (_intervals: IDashboardWorkInterval[]) => {
		const total = _intervals.reduce((acc, a) => {
			return (a.endTimeMs - a.startTimeMs) + acc;
		}, 0);
		return (total);
	};
	const titles: Record<DashboardWorkIntervalType, string> = {
		[DashboardWorkIntervalType.Commit]: "Coding",
		[DashboardWorkIntervalType.Task]: "Task Mgmt.",
		[DashboardWorkIntervalType.PullRequest]: "Review",
		[DashboardWorkIntervalType.Unknown]: "Unknown",
	};
	const totalWork = getTotalWork(intervals);
	const tooltip = Object.values(_.groupBy(intervals, a => a.type)).map((log, i) => {
		return (
			<div key={i}>
				<strong>{Formatters.workIntervals(getTotalWork(log))}</strong> {titles[log[0].type]}
			</div>
		);
	});

	return (intervals.length > 0 ?
		<Popup
			content={tooltip}
			basic={true}
			trigger={
				< div className="extended-info">
					{Formatters.workIntervals(totalWork)} worked
				</div>
			}
		/> : <></ >
	);
};

// tslint:disable-next-line: variable-name
const StatusChange = (props: { statusHistory: IDashboardIssueStatusHistory[], dateFormatter: (timeMs: number) => string }) => {
	const { statusHistory } = { ...props };
	const firstEvent = statusHistory[0].status;
	const lastEvent = statusHistory[statusHistory.length - 1].status;

	const tooltip = `${props.dateFormatter(moment(statusHistory[statusHistory.length - 1].from).valueOf())}`;
	return (
		(firstEvent !== lastEvent) ?
			(
				<Popup
					content={tooltip}
					basic={true}
					trigger={
						<div className="extended-info"  >
							<div className="level left floated inline-pad ">
								<span>Status change: </span>
								<TaskStatus taskStatus={firstEvent.status} internalStatus={firstEvent.internalStatus} hasTooltip={false} />
								<i className="chevron right thin icon small" style={{ verticalAlign: "middle" }} />
								<TaskStatus taskStatus={lastEvent.status} internalStatus={lastEvent.internalStatus} hasTooltip={false} />
							</div>
						</div>
					}
				/>
			) :
			<></>
	);
};

// tslint:disable-next-line: variable-name
const AssigneeChange = (props: { assignees: IDashboardIssueAssigneeHistory[], dateFormatter: (timeMs: number) => string  }) => {
	const { assignees } = { ...props };
	const firstAssignee = assignees[0].from?.id ? assignees[0].from : assignees[0].to;
	const lastChange = assignees[assignees.length - 1];
	const tooltip = `${props.dateFormatter(moment(lastChange.dateContext).valueOf())}`;
	return (
		(firstAssignee && lastChange && firstAssignee.id !== lastChange.to?.id) ?
			<Popup
				content={tooltip}
				basic={true}
				trigger={
					<div className="extended-info"  >
						<div className="level left floated ">
							<span>Assignee change: </span>
							<Avatar className="medium" dataContributor={firstAssignee} />
							<i className="chevron right thin icon" style={{ verticalAlign: "middle", marginBottom: "4px" }} />
							<Avatar className="medium" dataContributor={lastChange.to} />
						</div>
					</div>
				}
			/> :
			<></>
	);
};

// tslint:disable-next-line: variable-name
const Commits = (props: { numOfCommits: number }) => <div className="extended-info"  >
	<div className="level left floated ">
		<span>Commits added: {props.numOfCommits} Commits </span>
	</div>
</div>;

// tslint:disable-next-line: variable-name
const PREvents = (props: { relatedEvents: IDashboardEvent[], dateFormatter: (timeMs: number) => string  }) => {
	const { relatedEvents } = { ...props };
	const prEvents = relatedEvents.filter(event =>
		Object.values(PullRequestEventType).find(e => e === event.eventType));

	return (
		<>
			{prEvents.map((prEvent, i) => {
				const desc = eventsFormatting[prEvent.eventType];
				const tooltip = `${prEvent.dataContributor?.primaryDisplayName} ${props.dateFormatter(prEvent.eventTimeMs)}`;

				return (prEvent &&
					<Popup
						key={i}
						content={tooltip}
						basic={true}
						trigger={
							<div className="extended-info" >
								{
									<div className="level left floated label">
										<span>
											{desc && desc(prEvent)}
										</span>
									</div>
								}
							</div >
						}
					/>

				);
			})}
		</>
	);
};

// tslint:disable-next-line: variable-name
const IssueEvents = (props: { relatedEvents: IDashboardEvent[], dateFormatter: (timeMs: number) => string  }) => {
	const { relatedEvents } = { ...props };
	const issueEvents = relatedEvents.filter(event =>
		eventTypesToShow.find(e => e === event.eventType));
	const eventsSumUp = Object.values(_.groupBy(issueEvents, a => a.eventType)).map(e => {
		const sortedByChangeLog = e.sort((a, b) => {
			return (moment(a.changelog?.date).isAfter(b.changelog?.date, "milliseconds") ? 1 : -1);
		});
		const squeezeEvent = sortedByChangeLog[sortedByChangeLog.length - 1];
		if (squeezeEvent.changelog && sortedByChangeLog[0].changelog?.from) {
			squeezeEvent.changelog.from = sortedByChangeLog[0].changelog?.from;
		}
		return squeezeEvent;
	}).filter(event => {
		return event.changelog?.to !== event.changelog?.from;
	});

	return (
		<>
			{eventsSumUp.map((event, i) => {
				const tooltip = event ? `${event.dataContributor?.primaryDisplayName} ${props.dateFormatter(event.eventTimeMs)}` : "";
				const desc = event ? eventsFormatting[event.eventType] : undefined;

				return (event &&
					<Popup
						key={i}
						content={tooltip}
						basic={true}
						trigger={
							<div className="extended-info" >
								{
									<div className="level left floated label">
										<span>
											{desc && desc(event)}
										</span>
									</div>
								}
							</div >
						}
					/>
				);
			})}
		</>
	);
};
