import React from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
	return (
		<>
			<Helmet>
				<title>
					GoRetro - Planning Poker
				</title>
				<meta charSet="UTF-8" key="charset" />
				<meta
					name="viewport"
					content="width=device-width,initial-scale=1"
					key="viewport" />
				<link
					rel="apple-touch-icon"
					href={`/assets/images/planning-poker/apple-touch-icon.png`}
					key="apple" />
				<link
					rel="icon"
					type="image/png"
					sizes="32x32"
					href={`/assets/images/planning-poker/favicon-32x32.png`}
					key="icon32" />
				<link
					rel="icon"
					type="image/png"
					sizes="16x16"
					href={`/assets/images/planning-poker/favicon-16x16.png`}
					key="icon16" />
				<link
					rel="icon"
					href={`/assets/images/planning-poker/favicon.ico`}
					key="favicon" />

				<link
					rel="stylesheet"
					type="text/css"
					href={`/assets/style/tailwind.out.css`}
					key="tailwind" />
			</Helmet>
		</>
	);
};

export { Meta };
