import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useState } from "react";
import { useStores } from "../../../mobx-stores";
import { ChartSeriesData } from "../../../pages/my-team/analytics/charts/charts";
import LoadingIndicator from "../../../components/loader";
import { IChartScope } from "./chart-scope";
import useDeepCompareEffect from "use-deep-compare-effect";

interface IProps {
	scope: IChartScope;
}

function DefectDensityChart(props: IProps) {
	const { teamsReportStore } = useStores();
	const [chartOptions, setChartOptions] = useState<Highcharts.Options | undefined>(undefined);

	function buildChartOptions(data: {
		prDefectDensity: ChartSeriesData,
		locDefectDensity: ChartSeriesData
	}): Highcharts.Options {

		const series: Highcharts.SeriesLineOptions[] = [
			{
				type: "line",
				color: "#170987",
				name: "Pull request defect density",
				data: data.prDefectDensity,
				marker: {
					symbol: "circle"
				},
				visible: data.prDefectDensity.length > 0,
			},
			{
				type: "line",
				color: "#AF2387",
				name: "Lines of code (K) defect density",
				data: data.locDefectDensity,
				marker: {
					symbol: "circle"
				},
				visible: data.locDefectDensity.length > 0,
			}
		];
		const options: Highcharts.Options = {
			chart: {
				plotBackgroundColor: undefined,
				plotBorderWidth: undefined,
				plotShadow: false,
				type: "spline",
			},
			title: undefined,
			tooltip: {
				split: true,
			},
			xAxis: {
				gridLineWidth: 1,
				type: "datetime"
			},
			yAxis: {
				min: 0,
				title: {
					text: "Ratio"
				}
			},
			credits: {
				enabled: false
			},
			series
		};

		return options;
	}

	async function fetchData(): Promise<{
		prDefectDensity: ChartSeriesData,
		locDefectDensity: ChartSeriesData
	} | undefined> {
		const scope = props.scope;
		const data = await teamsReportStore.defectDensity(scope.startTime, scope.endTime, scope.timezone, scope.interval,
			scope.teamIds, scope.repositoryIds, scope.projectIds);
		return data;
	}

	function shouldFetchData(): boolean {
		return (!teamsReportStore.isLoadingDefectDensityMetric);
	}

	useDeepCompareEffect(() => {
		let isMounted = true;
		async function fetchChartData() {
			const data = await fetchData();
			if (isMounted && data) {
				setChartOptions(buildChartOptions(data));
			}
		}

		if (shouldFetchData()) {
			// tslint:disable-next-line: no-floating-promises
			fetchChartData().then(() => {
				isMounted = false;
			});
		}
	}, [props]);

	return (
		<LoadingIndicator local={true} isActive={chartOptions === undefined}>
			{chartOptions && <HighchartsReact
				highcharts={Highcharts}
				options={chartOptions}
			/>}
		</LoadingIndicator>
	);
}

export default DefectDensityChart;
