import React from "react";
import "./edit-labels-button.scss";
import { Image, Loader } from "semantic-ui-react";
import editIconAsset from "./edit-icon-asset.svg";

export interface IEditDataContributorLabelsExportButtonProps {
	shouldShowLoader: boolean;
	onClick?: () => void;
}

export const EditDataContributorLabelsExportButton = (props: IEditDataContributorLabelsExportButtonProps) => {
	return (
		<>
			{props.shouldShowLoader === false &&
				<div className="edit-contributor-labels-button" onClick={props.onClick}>
					<Image src={editIconAsset} alt="edit labels icon" />
				</div>
			}
			{props.shouldShowLoader === true &&
				<div className="edit-contributor-labels-button">
					<Loader active={true} inline={true} size="mini" />
				</div>
			}
		</>
	);
};
