import classnames from "classnames";
import React from "react";

export enum MenuItem {
	Votes = "Votes",
	Issues = "Issues",
}

interface GameMenuItemProps {
	item: MenuItem;
	isSelected: boolean;
	onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const SELECTED_FILL_COLOR = "#001A72";
const NOT_SELECTED_FILL_COLOR = "#8791A2";

const IssuesMenuItem = (fillColor: string = NOT_SELECTED_FILL_COLOR) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			className="h-8 w-8 mr-1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3 12L2.29775 11.7367C2.26618 11.8209 2.25 11.9101 2.25 12H3ZM14.5937 14.1094L13.9697 13.6934L14.5937 14.1094ZM15.4063 12.8906L16.0303 13.3066L15.4063 12.8906ZM21 12H21.75C21.75 11.9101 21.7338 11.8209 21.7022 11.7367L21 12ZM5.51334 5.29775L6.21559 5.5611V5.5611L5.51334 5.29775ZM18.4867 5.29775L17.7844 5.5611V5.5611L18.4867 5.29775ZM11.2361 15.75H12.9296V14.25H11.2361V15.75ZM3 12.75H7.26393V11.25H3V12.75ZM8.38197 13.441L8.77639 14.2298L10.118 13.559L9.72361 12.7702L8.38197 13.441ZM15.2178 14.5254L16.0303 13.3066L14.7822 12.4746L13.9697 13.6934L15.2178 14.5254ZM17.0704 12.75H21V11.25H17.0704V12.75ZM16.0303 13.3066C16.2621 12.9589 16.6524 12.75 17.0704 12.75V11.25C16.1509 11.25 15.2923 11.7095 14.7822 12.4746L16.0303 13.3066ZM7.26393 12.75C7.7374 12.75 8.17023 13.0175 8.38197 13.441L9.72361 12.7702C9.25778 11.8385 8.30556 11.25 7.26393 11.25V12.75ZM12.9296 15.75C13.8491 15.75 14.7077 15.2905 15.2178 14.5254L13.9697 13.6934C13.7379 14.0411 13.3476 14.25 12.9296 14.25V15.75ZM11.2361 14.25C10.7626 14.25 10.3298 13.9825 10.118 13.559L8.77639 14.2298C9.24222 15.1615 10.1944 15.75 11.2361 15.75V14.25ZM20.25 12V18H21.75V12H20.25ZM19 19.25H5V20.75H19V19.25ZM3.75 18V12H2.25V18H3.75ZM3.70225 12.2633L6.21559 5.5611L4.8111 5.03441L2.29775 11.7367L3.70225 12.2633ZM17.7844 5.5611L20.2978 12.2633L21.7022 11.7367L19.1889 5.03441L17.7844 5.5611ZM5 19.25C4.30964 19.25 3.75 18.6904 3.75 18H2.25C2.25 19.5188 3.48122 20.75 5 20.75V19.25ZM20.25 18C20.25 18.6904 19.6904 19.25 19 19.25V20.75C20.5188 20.75 21.75 19.5188 21.75 18H20.25ZM7.386 4.75H16.614V3.25H7.386V4.75ZM6.21559 5.5611C6.39854 5.07322 6.86495 4.75 7.386 4.75V3.25C6.23968 3.25 5.2136 3.96108 4.8111 5.03441L6.21559 5.5611ZM19.1889 5.03441C18.7864 3.96108 17.7603 3.25 16.614 3.25V4.75C17.1351 4.75 17.6015 5.07322 17.7844 5.5611L19.1889 5.03441Z"
				fill={fillColor}
			/>
		</svg>
	);
};

const VotesMenuItem = (fillColor: string = NOT_SELECTED_FILL_COLOR) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			className="h-8 w-8 mr-1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.9355 9.91594C11.9705 9.67207 11.9506 9.41726 11.864 9.16929C11.5752 8.34201 10.6674 7.90384 9.83984 8.19292C9.0128 8.48169 8.57495 9.3895 8.86371 10.2169C8.95005 10.465 9.09318 10.6773 9.27244 10.8461C8.45796 11.1426 8.02839 12.0415 8.31491 12.8623C8.60367 13.6894 9.51149 14.1274 10.3391 13.8386C10.7382 13.6989 11.0458 13.4146 11.2257 13.0658C11.318 13.6524 11.3276 14.3932 10.8378 14.5641L11.9313 14.1822L13.0249 13.8007C12.5351 13.9715 12.0817 13.3862 11.7888 12.8691C12.1471 13.031 12.5644 13.0618 12.9638 12.9224C13.791 12.6336 14.2289 11.7256 13.9401 10.8984C13.653 10.0774 12.7575 9.6411 11.9355 9.91594Z"
				fill={fillColor}
			/>
			<path
				d="M19.8286 17.8552C19.8951 17.7178 19.9041 17.5585 19.8535 17.4144L14.3613 1.68289C14.3109 1.53831 14.205 1.41979 14.0669 1.35373C13.9299 1.28727 13.771 1.2781 13.6264 1.32871L2.65859 5.15781C2.35775 5.26241 2.19917 5.59157 2.30409 5.89232L7.79603 21.6241C7.84648 21.7687 7.9522 21.8871 8.09026 21.9531C8.16911 21.9918 8.25488 22.0106 8.34049 22.0106C8.4047 22.0106 8.4689 22 8.53053 21.9783L19.4989 18.1489C19.6435 18.0988 19.7621 17.993 19.8286 17.8552ZM8.69523 20.6996L3.5829 6.05678L13.4623 2.6072L18.5748 17.2503L8.69523 20.6996Z"
				fill={fillColor}
			/>
			<path
				d="M16.4753 2.75947C16.3705 2.45848 16.0405 2.29965 15.7408 2.40497C15.44 2.50957 15.2811 2.83872 15.3864 3.13948L20.6888 18.3268L10.2652 21.9662C9.96434 22.071 9.8056 22.4 9.91052 22.7007C9.99371 22.9388 10.2166 23.0876 10.455 23.0876C10.5181 23.0876 10.5823 23.0777 10.645 23.0553L21.613 19.2259C21.9137 19.1214 22.0726 18.7921 21.9675 18.4914L16.4753 2.75947Z"
				fill={fillColor}
			/>
		</svg>
	);
};

const getMenuItemIcon = (item: MenuItem, isSelected: boolean) => {
	const fillColor = isSelected ? SELECTED_FILL_COLOR : NOT_SELECTED_FILL_COLOR;
	switch (item) {
		case MenuItem.Votes: {
			return VotesMenuItem(fillColor);
		}
		case MenuItem.Issues: {
			return IssuesMenuItem(fillColor);
		}
		default: {
			return <></>;
		}
	}
};

const getMenuItemText = (item: MenuItem) => {
	switch (item) {
		case MenuItem.Votes: {
			return "Votes";
		}
		case MenuItem.Issues: {
			return "Issues";
		}
		default: {
			return "";
		}
	}
};

const GameMenuItem: React.FC<GameMenuItemProps> = ({
	item,
	isSelected,
	onClick,
}) => {
	return (
		<div
			onClick={onClick}
			className={classnames(
				"flex",
				"items-center",
				"px-4",
				"py-2",
				"border-b-4",
				"cursor-pointer",
				{
					"border-[#ED51A3]": isSelected,
				}
			)}
		>
			{getMenuItemIcon(item, isSelected)}

			<span
				className={classnames(
					"text-base",
					{
						"font-semibold text-[#1B3F7B]": isSelected,
					},
					{
						"font-normal text-[#949DAC]": !isSelected,
					}
				)}
			>
				{getMenuItemText(item)}
			</span>
		</div>
	);
};

export interface GameMenuBarProps {
	selectedMenuItem: MenuItem;
	onSelectedMenuItem?: (value: MenuItem) => void;
}

const GameMenuBar: React.FC<GameMenuBarProps> = ({
	selectedMenuItem,
	onSelectedMenuItem,
}) => {
	return (
		<div className="px-4 py-4 sm:px-8">
			<div className="container mx-auto flex flex-row items-center justify-center space-x-7">
				<GameMenuItem
					isSelected={selectedMenuItem === MenuItem.Votes}
					item={MenuItem.Votes}
					onClick={() => {
						if (onSelectedMenuItem) {
							onSelectedMenuItem(MenuItem.Votes);
						}
					}}
				/>
				<GameMenuItem
					isSelected={selectedMenuItem === MenuItem.Issues}
					item={MenuItem.Issues}
					onClick={() => {
						if (onSelectedMenuItem) {
							onSelectedMenuItem(MenuItem.Issues);
						}
					}}
				/>
			</div>
		</div>
	);
};

export { GameMenuBar };
