import { ICard } from "../types/card";
import { PokerGameType } from "@acumen/dashboard-common";

export const QUESTION_MARK_CARD_VALUE = 1000000;
export const QUESTION_MARK_CARD_DISPLAY_VALUE = "?";

const fibonacciCards: ICard[] = [
	{ value: 0, displayValue: "0" },
	{ value: 10, displayValue: "1" },
	{ value: 20, displayValue: "2" },
	{ value: 30, displayValue: "3" },
	{ value: 50, displayValue: "5" },
	{ value: 80, displayValue: "8" },
	{ value: 130, displayValue: "13" },
	{ value: 210, displayValue: "21" },
	{ value: QUESTION_MARK_CARD_VALUE, displayValue: QUESTION_MARK_CARD_DISPLAY_VALUE },
];

const shortFibonacciCards: ICard[] = [
	{ value: 0, displayValue: "0" },
	{ value: 5, displayValue: "½" },
	{ value: 20, displayValue: "2" },
	{ value: 30, displayValue: "3" },
	{ value: 50, displayValue: "5" },
	{ value: 80, displayValue: "8" },
	{ value: 130, displayValue: "13" },
	{ value: 200, displayValue: "20" },
	{ value: QUESTION_MARK_CARD_VALUE, displayValue: QUESTION_MARK_CARD_DISPLAY_VALUE },
];

const tShirtCards: ICard[] = [
	{ value: 10, displayValue: "XXS" },
	{ value: 20, displayValue: "XS" },
	{ value: 30, displayValue: "S" },
	{ value: 40, displayValue: "M" },
	{ value: 50, displayValue: "L" },
	{ value: 60, displayValue: "XL" },
	{ value: QUESTION_MARK_CARD_VALUE, displayValue: QUESTION_MARK_CARD_DISPLAY_VALUE },
];

const scrumCards: ICard[] = [
	{ value: 0, displayValue: "0" },
	{ value: 5, displayValue: "½" },
	{ value: 10, displayValue: "1" },
	{ value: 20, displayValue: "2" },
	{ value: 30, displayValue: "3" },
	{ value: 50, displayValue: "5" },
	{ value: 80, displayValue: "8" },
	{ value: 130, displayValue: "13" },
	{ value: 200, displayValue: "20" },
	{ value: 400, displayValue: "40" },
	{ value: 1000, displayValue: "100" },
	{ value: QUESTION_MARK_CARD_VALUE, displayValue: QUESTION_MARK_CARD_DISPLAY_VALUE },
];

const sequentialCards: ICard[] = [
	{ value: 0, displayValue: "0" },
	{ value: 10, displayValue: "1" },
	{ value: 20, displayValue: "2" },
	{ value: 30, displayValue: "3" },
	{ value: 40, displayValue: "4" },
	{ value: 50, displayValue: "5" },
	{ value: 60, displayValue: "6" },
	{ value: 70, displayValue: "7" },
	{ value: 80, displayValue: "8" },
	{ value: 90, displayValue: "9" },
	{ value: 100, displayValue: "10" },
	{ value: QUESTION_MARK_CARD_VALUE, displayValue: QUESTION_MARK_CARD_DISPLAY_VALUE },
];

const halfCardsCards: ICard[] = [
	{ value: 5, displayValue: "½" },
	{ value: 10, displayValue: "1" },
	{ value: 14, displayValue: "1½" },
	{ value: 20, displayValue: "2" },
	{ value: 25, displayValue: "2½" },
	{ value: 30, displayValue: "3" },
	{ value: QUESTION_MARK_CARD_VALUE, displayValue: QUESTION_MARK_CARD_DISPLAY_VALUE },
];

export const generateCardsByGameType = (gameType: PokerGameType): ICard[] => {
	switch (gameType) {
		case PokerGameType.Fibonacci:
			return fibonacciCards;
		case PokerGameType.ShortFibonacci:
			return shortFibonacciCards;
		case PokerGameType.TShirt:
			return tShirtCards;
		case PokerGameType.Scrum:
			return scrumCards;
		case PokerGameType.Sequential:
			return sequentialCards;
		case PokerGameType.HalfCards:
			return halfCardsCards;
	}
};
