import React from "react";
import classNames from "classnames";
import { round } from "@acumen/common";
import ArrowUp, { DEFAULT_ARROW_GREEN_COLOR, DEFAULT_ARROW_RED_COLOR } from "v2/components/svg-assets/arrow-up";
import ArrowDown from "v2/components/svg-assets/arrow-down";
import { getChangeRatePercentage } from "v2/helpers/calculate-change-rate";
import "./trend.scss";

export interface TrendProps {
	isIncreasePositive: boolean;
	hideCurrentValue?: boolean;
	bold?: boolean;
	currentValue?: number;
	previousValue?: number;
	roundingPrecision?: number;
}

export const Trend = (props: TrendProps) => {
	const {
		currentValue,
		previousValue,
		hideCurrentValue = false,
		bold = false,
		isIncreasePositive,
		roundingPrecision = 2,
	} = props;
	const changeRate = (currentValue !== undefined && previousValue)
		? getChangeRatePercentage(currentValue, previousValue, roundingPrecision)
		: NaN;
	const className = classNames("trend", {
		"variant-bold": bold,
		"trend-growth": (changeRate > 0 && isIncreasePositive) || (changeRate < 0 && !isIncreasePositive),
		"trend-decline": (changeRate < 0 && isIncreasePositive) || (changeRate > 0 && !isIncreasePositive)
	});

	return (
		<div className={className}>
			{!hideCurrentValue && (
				<div className="trend-current-value">
					{currentValue !== undefined ? round(currentValue, roundingPrecision) : "-"}
				</div>
			)}

			{!isNaN(changeRate) && changeRate !== 0 && (
				<div className="trend-change-rate">
					<span className="trend-change-rate-value">{changeRate}%</span>
					{changeRate > 0 && (
						<ArrowUp
							className="trend-change-rate-icon"
							indicationfillcolor={isIncreasePositive ? DEFAULT_ARROW_GREEN_COLOR : DEFAULT_ARROW_RED_COLOR}
						/>
					)}
					{changeRate < 0 && (
						<ArrowDown
							className="trend-change-rate-icon"
							indicationfillcolor={isIncreasePositive ? DEFAULT_ARROW_RED_COLOR : DEFAULT_ARROW_GREEN_COLOR}
						/>
					)}
				</div>
			)}
		</div>
	);
};
