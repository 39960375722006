import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useState } from "react";
import { useStores } from "../../../mobx-stores";
import { ChartSeriesData } from "../../../pages/my-team/analytics/charts/charts";
import LoadingIndicator from "../../../components/loader";
import { IChartScope } from "./chart-scope";
import useDeepCompareEffect from "use-deep-compare-effect";
import _ from "lodash";
import colorScheme from "../../../pages/my-team/analytics/charts/chart-color-scheme";
import { Grid, Popup } from "semantic-ui-react";
import { round } from "@acumen/common";
import { ChartExternalLegend, ILegendList } from "../../../pages/org-analytics/particles";
import PieChart from "../../../components/highchart-chart-components/pie-chart";

interface IBugsRaisedVsFixesProps {
	scope: IChartScope;
	mainTitle?: { title: string, tooltip?: string };
	showDataLabels?: boolean;
	showExternalLegend?: boolean;
	chartHeight?: {
		mainChartHeight: number,
		summaryPieChartHeight: number
	};
}

function BugsRaisedVsFixed(props: IBugsRaisedVsFixesProps) {
	const { teamsReportStore } = useStores();
	const [expandedChartOptions, setExpandedChartOptions] = useState<Highcharts.Options | undefined>(undefined);
	const [summarizedChartOptions, setSummarizedChartOptions] = useState<Highcharts.Options | undefined>(undefined);
	const [summarizedLegendList, setSummarizedLegendList] = useState<{ data: ILegendList[], title?: string } | undefined>(undefined);
	const [hoveredSummaryMetric, setHoveredSummaryMetric] = useState<undefined | string>(undefined);

	const { scope, mainTitle, showDataLabels, showExternalLegend, chartHeight } = { ...props };

	function getSummaryTitle(data: {
		bugsFixed: ChartSeriesData,
		bugsRaised: ChartSeriesData
	}): string {
		const totalBugsRaised = _.sumBy(data.bugsRaised, d => d[1]);
		const totalBugsFixed = _.sumBy(data.bugsFixed, d => d[1]);
		const bugFixingRatio = (totalBugsRaised === 0 ? 0 : totalBugsFixed / totalBugsRaised);

		return `Bug fix ratio ${round((bugFixingRatio) * 100)}%`;
	}

	function buildExpandedChartOptions(data: {
		bugsFixed: ChartSeriesData,
		bugsRaised: ChartSeriesData
	}): Highcharts.Options {
		const series: Highcharts.SeriesColumnOptions[] = [
			{
				data: data.bugsRaised,
				visible: data.bugsRaised.length > 0,
				color: colorScheme.issueType.bug,
				name: "Filed",
				type: "column",
				showInLegend: showExternalLegend ? false : true,
			},
			{
				data: data.bugsFixed,
				visible: data.bugsFixed.length > 0,
				color: colorScheme.issueType.task,
				name: "Fixed",
				type: "column",
				showInLegend: showExternalLegend ? false : true,
			}
		];
		const options: Highcharts.Options = {
			title: undefined,
			chart: showExternalLegend ? { height: 400 } : {},
			tooltip: {
				split: true,
			},
			xAxis: {
				gridLineWidth: 1,
				type: "datetime"
			},
			yAxis: [
				{
					min: 0,
					title: {
						text: "Bugs"
					}
				}
			],
			credits: {
				enabled: false
			},
			series
		};

		return options;
	}

	function buildSummarizedChartOptions(data: {
		bugsFixed: ChartSeriesData,
		bugsRaised: ChartSeriesData
	}): Highcharts.Options {
		const totalBugsRaised = _.sumBy(data.bugsRaised, d => d[1]);
		const totalBugsFixed = _.sumBy(data.bugsFixed, d => d[1]);
		const piePoints: Highcharts.PointOptionsObject[] = [
			{
				color: colorScheme.issueType.bug,
				name: "Bugs filed",
				y: totalBugsRaised
			},
			{
				color: colorScheme.issueType.task,
				name: "Bugs fixed",
				y: totalBugsFixed
			}
		];
		setSummarizedLegendList({
			data: [
				{
					color: colorScheme.issueType.bug,
					name: "Bugs filed",
					value: totalBugsRaised
				},
				{
					color: colorScheme.issueType.task,
					name: "Bugs fixed",
					value: totalBugsFixed
				}
			],
			title: getSummaryTitle(data)
		});

		const pieSeries: Highcharts.SeriesPieOptions = {
			type: "pie",
			data: piePoints,
			innerSize: "80%",
			tooltip: {
				pointFormat: "<b>{point.y}</b>"
			},
			point: {
				events: {
					mouseOver: function _mouseOver() {
						setHoveredSummaryMetric(this.name);
					},
					mouseOut: function _mouseOver() {
						setHoveredSummaryMetric(undefined);
					}
				}
			}
		};

		const options: Highcharts.Options = {
			chart: {
				plotBackgroundColor: undefined,
				plotBorderWidth: undefined,
				plotShadow: false
			},
			title: {
				text: getSummaryTitle(data),
				verticalAlign: "middle",
				floating: true
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: "pointer",
					dataLabels: showDataLabels ? {
						enabled: true,
						format: "<b>{point.name}</b>: {point.percentage:.1f}%"
					} : {
						enabled: false
					}
				}
			},
			credits: {
				enabled: false
			},
			series: [pieSeries]
		};
		return options;
	}

	async function fetchData(): Promise<{
		bugsFixed: ChartSeriesData,
		bugsRaised: ChartSeriesData
	} | undefined> {

		const data = await teamsReportStore.bugsRaisedVsFixed(scope.startTime, scope.endTime, scope.timezone, scope.interval,
			scope.teamIds, scope.projectIds);
		return data;
	}

	function shouldFetchData(): boolean {
		return (!teamsReportStore.isLoadingBugsRaisedVsFixedMetric);
	}

	useDeepCompareEffect(() => {
		let isMounted = true;
		async function fetchChartData() {
			const data = await fetchData();
			if (isMounted && data) {
				setExpandedChartOptions(buildExpandedChartOptions(data));
				setSummarizedChartOptions(buildSummarizedChartOptions(data));
			}
		}

		if (shouldFetchData()) {
			// tslint:disable-next-line: no-floating-promises
			fetchChartData().then(() => {
				isMounted = false;
			});
		}
	}, [props]);

	return (
		<LoadingIndicator local={true} isActive={expandedChartOptions === undefined && summarizedChartOptions === undefined}>
			<Grid.Column width={10}>
				{mainTitle &&
					<h2 className="ui title acu-capitalize">{mainTitle.title}
						{mainTitle.tooltip && <Popup
							hoverable={true}
							wide={true}
							className={"tiny-text"}
							position="top center"
							content={mainTitle.tooltip}
							trigger={
								<i className="chart-tooltip info circle outline icon" />
							}
						/>}
					</h2>}
				{expandedChartOptions && <HighchartsReact
					highcharts={Highcharts}
					options={expandedChartOptions}
				/>}
			</Grid.Column>
			<Grid.Column width={6}>
				{mainTitle &&
					<h2 className="ui title acu-capitalize">{summarizedLegendList?.title}</h2>
				}
				{showExternalLegend &&
					<div className="ui grid full-height">
						<Grid.Column width={8}>
							{summarizedChartOptions &&
								<div className="pie-chart-container">
									<PieChart
										series={summarizedChartOptions?.series}
										triggerHover={hoveredSummaryMetric}
										height={chartHeight ? chartHeight?.summaryPieChartHeight : undefined}
									/>
								</div>
							}
						</Grid.Column>
						<Grid.Column width={8}>
							<div className="legend-container">
								{summarizedChartOptions?.series !== undefined && <ChartExternalLegend
									legendList={summarizedLegendList?.data}
									hoveredEntry={hoveredSummaryMetric}
									setHoveredEntry={setHoveredSummaryMetric}
								/>}
							</div>
						</Grid.Column>
					</div>
				}
				{summarizedChartOptions && !showExternalLegend && <HighchartsReact
					highcharts={Highcharts}
					options={summarizedChartOptions}
				/>}
			</Grid.Column>
		</LoadingIndicator>
	);
}

export default BugsRaisedVsFixed;
