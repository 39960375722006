import React, { useMemo } from "react";
import { WorkforceHealthMetrics } from "@acumen/database-types";
import {
	DeveloperStatsTableAggregatedDataCell
} from "../developer-stats-table-aggregated-data-cell/developer-stats-table-aggregated-data-cell";
import {
	DeveloperStatsTableAggregatedDataHeaderCell
} from "../developer-stats-table-aggregated-data-header-cell/developer-stats-table-aggregated-data-header-cell";
import { useStores } from "../../../../mobx-stores";
import { getShortEstimationMethodName } from "v2/helpers/formatting";
import { DeveloperViewModel } from "../../types";
import { DevStatColumn } from "../../dev-stat-columns";
import { MetricData } from "../../../team-comparison/types";
import { calculateAvgByMetric } from "../../../../helpers/calculate-average-by-metric";
import { round } from "@acumen/common";

export interface DeveloperStatsTableAggregatedDataRowProps {
	items: DeveloperViewModel[];
	selectedDashboardMetrics: DevStatColumn[];
	metricData: MetricData;
}

export const DeveloperStatsTableAggregatedDataRow = (props: DeveloperStatsTableAggregatedDataRowProps) => {
	const { items: developers, selectedDashboardMetrics, metricData } = props;
	const { developerStatsStore } = useStores();
	const { developerBadgesData } = developerStatsStore;
	const currentDevStatsAvgByMetric = useMemo(() => {
		return calculateAvgByMetric(
			metricData,
			"current",
			developers.map(developer => developer.info.dataContributorId),
		);
	}, [developers, metricData]);
	const unitNameByEstimationMethod = getShortEstimationMethodName(developerBadgesData?.config?.configs.devStats.estimationMethod);

	return (
		<tr className="developer-stats-table-aggregated-data-row">
			<DeveloperStatsTableAggregatedDataHeaderCell/>
			{selectedDashboardMetrics.map(metric => (
				<DeveloperStatsTableAggregatedDataCell
					key={metric.dashboardMetricName}
					value={getAvgIfExists(currentDevStatsAvgByMetric, metric.dashboardMetricName)}
					unit={metric.displayValues.unit || unitNameByEstimationMethod}
				/>
			))}
		</tr>
	);
};

const getAvgIfExists = (
	currentDevStatsAvgByMetric: Partial<Record<WorkforceHealthMetrics, number>> | null,
	selectedMetricName: WorkforceHealthMetrics,
): number => {
	if (currentDevStatsAvgByMetric && currentDevStatsAvgByMetric[selectedMetricName]) {
		return round(currentDevStatsAvgByMetric[selectedMetricName]!, 2);
	}
	return 0;
};
