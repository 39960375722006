import React from "react";
import "./statuses.scss";
import { Popup } from "semantic-ui-react";

const statusFunction = ({ classNameFromStatus, iconFromStatus, textFromStatus, defaultStatusValue }) => {
	return ({ status, internalStatus = null, missingStatusValue = defaultStatusValue }) => {
		const statusText = textFromStatus(status, internalStatus, missingStatusValue);
		return (
			<Popup
				size="small"
				basic={true}
				content={statusText}
				trigger={
					<div className={`status-label ${classNameFromStatus(status)}`}>
						{status && <i className={`${iconFromStatus(status)}`} />}
						{statusText}
					</div>
				}
			/>
		);
	};
};
export default statusFunction;
