import { TeamBadge } from "@acumen/database-types";
import { DashboardBadge } from "./workforce-health";

export enum BadgeOwner {
	Team = "Team",
	Developer = "Developer"
}

export interface BadgeWithReason {
	badge: DashboardBadge | TeamBadge;
	badgeReason: string;
}
