import _ from "lodash";
import { FilterName } from "../../mobx-stores/digest-store";
import React from "react";
import { Controller } from "react-hook-form";
import ReactSelect from "react-select";

export interface ISelectOptionBase {
	options: ISelectOptionGroups[] | ISelectOptions[];
	name: string;
	label: string;
	isLoading: boolean;
	isSingleSelect?: boolean;
	isClearable?: boolean;
}

export interface ISelectOptions {
	label: string;
	value: string;
	key: string;
	isDisabled?: boolean;
}

export interface ISelectOptionGroups {
	label: string;
	options: ISelectOptions[];
}

interface ISelectProps {
	label: string;
	name: FilterName | string;
	control?: any;
	register?: any;
	keyId?: number;
	onChange?: (val: any) => void;
	options: ISelectOptionGroups[] | ISelectOptions[];
	isClearable?: boolean;
	isLoading: boolean;
	isSingleSelect?: boolean;
}

export function isGroupedSelectOptions(options: ISelectOptionGroups[] | ISelectOptions[]): options is ISelectOptionGroups[] {
	return options.length > 0 && (_.isObject(options[0])) && "options" in options[0];
}

// tslint:disable-next-line: variable-name
const Select = (props: ISelectProps) => {
	const { control, keyId, options, label, onChange, name, isClearable = true, isSingleSelect = false, isLoading, ...rest } = { ...props };
	return (
		<div key={keyId} className={"clickable field"} >
			{label && <label className="capitalize">{label.replace("_", " ")}</label>}
			{options && (
				<Controller
					{...rest}
					control={control}
					name={name}
					label={label}
					defaultValue={isSingleSelect ? undefined : []}
					render={(option) => {
						let current: any[] | any | undefined;
						if (option && option.value) {
							if (isSingleSelect) {
								current = (options as ISelectOptions[]).find((opt: { value: any; }) => option.value === opt.value);
							} else {
								if (isGroupedSelectOptions(options)) {
									current = [];
									options.forEach((g: { options: any[]; }) => g.options.forEach(
										(a: any) => option.value?.filter((v: any) => v === a.value)
											.forEach((_f: any) => current.push(a))));
								} else {
									current = options?.filter((opt: { value: any; }) => option.value.find((v: any) => v === opt.value));
								}
							}
						}
						return (
							<ReactSelect
								closeMenuOnSelect={isSingleSelect}
								isClearable={isClearable}
								isLoading={isLoading}
								isDisabled={isLoading}
								multiValueRemove={true}
								autoBlur={true}
								isMulti={!isSingleSelect}
								controlShouldRenderValue={true}
								options={options}
								value={current}
								isSearchable={true}
								selection={true}
								clearable={isClearable}
								onChange={(values: any[] | any | null) => {
									if (!onChange) {
										return;
									}
									if (isSingleSelect) {
										onChange(values.value ?? undefined);
									} else {
										onChange((values as any[]).map(opt => opt.value) ?? []);
									}
								}}
								styles={{
									option: (styles) => ({
										...styles,
										cursor: "pointer",
										color: "#052348"
									}),
									control: (styles) => ({
										...styles,
										cursor: "pointer",
									})
								}}
							/>
						);
					}}
				/>
			)}
		</div>
	);
};
export default Select;
