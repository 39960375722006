import React, { useState, useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../../mobx-stores";
import moment from "moment";
import _ from "lodash";
import { Accordion, Popup, Pagination, Segment } from "semantic-ui-react";
import { PullRequestStatus } from "../../../../components/formatters";
import {
	DashboardPullRequestExpandOption, DashboardPullRequestStaticGroupingOption,
	IDashboardPullRequestExtended, DashboardPullRequestWorkStatusGrouping,
	IDashboardDetailedPullRequestGroup, DashboardPullRequestDynamicGroupingOption,
	AcumenPullRequestStatus, DashboardSortOrder, DateRangeType
} from "@acumen/dashboard-common";
import classNames from "classnames";
import PullRequestDetailsDisplayPanel from "../../../../components/dashboard-pr/pr-details-display-panel";
import HighlightsFormatters from "../../../../components/formatters/highlights";
import { Formatters } from "../../../../components/dashboard-task/formatters";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import Linkify from "../../../../components/formatters/linkify";
import { Avatar } from "../../../../components/avatar";
import { ACCORDION_PANEL_NO_CONTENT_MESSAGE } from "../accordion";
import AvatarsGroup from "../../../../components/avatars-group";
import { filterPullRequestDisplayData } from "../../../../components/dashboard-pr/pr";
import { dateRelativeToNow } from "../../../../../utils/date";
import ConfirmationModal from "../../../../components/modals/confirmation-modal";
import { DateFormat, dateFormatByLocale } from "@acumen/common";
import { GA_EVENT_ACTION, GA_EVENT_CATEGORY, clickEvent } from "../../../../analytics-events";
import FiltersTopNavbar from "../../../../components/filters-top-navbar";
import { ISelectOptionBase } from "../../../../components/form/option-select";
import "./style.scss";
import FiltersSelector from "../../../../components/filters-selector/filters-selector";
import { TimeFramesSelector } from "../../../../components/filters-selector/sections/time-frames-selector";
import filtersSlice from "./filters-slice";
import DatePickerSelector from "../../../../components/filters-selector/sections/date-picker";

const MAXIMUM_STATIC_GROUP_ITEMS = 10;
const ROWS_PER_PAGE = 20;

const createPanel = (index: number, key: string, title: string,
	{ pullRequests, total }: IDashboardDetailedPullRequestGroup | undefined = { pullRequests: [], total: 0 },
	activeIndex: number | number[], onSeeAllClick: () => void,
	selectedPullRequest: IDashboardPullRequestExtended | undefined,
	setSelectedPullRequest: (value: IDashboardPullRequestExtended | undefined) => void,
	selectedIndex: number | undefined,
	customIconName: ((isEmpty: boolean) => string) | undefined = undefined,
	onDismissPRHighlights?: (value: IDashboardPullRequestExtended) => void,
	tooltip: string | undefined = undefined) => {

	const isSeeAllMode = !Array.isArray(activeIndex) && activeIndex === index;
	const isOpen = Array.isArray(activeIndex) && activeIndex.includes(index);
	const noItemsMessage = pullRequests.length === 0 ? ACCORDION_PANEL_NO_CONTENT_MESSAGE.find(panelMsg => panelMsg.key === key)?.message : undefined;

	return {
		key: `panel-${key}`,
		index,
		title: {
			icon: "",
			content: (
				<StaticGroupTitle
					{...clickEvent(GA_EVENT_CATEGORY.PullRequestWork, GA_EVENT_ACTION.Select, `Accordion-${key}`)}
					{...{
						pullRequestsLength: pullRequests.length,
						totalPullRequests: total,
						isSeeAllMode,
						onSeeAllClick,
						title,
						customIconName,
						noItemsMessage,
						tooltip,
						isOpen
					}}
				/>
			)
		},
		content: {
			content: (
				<>
					{(pullRequests.length > 0) && (
						<PullRequestsGroup
							{...{
								currentIndex: index,
								rows: pullRequests,
								onDismissPRHighlights,
								onRowClicked: (item: IDashboardPullRequestExtended) => setSelectedPullRequest(item),
								selectedRow: selectedPullRequest,
								selectedIndex,
								totalRows: total,
								isSeeAllMode
							}}
						/>
					)}
				</>
			)
		}
	};
};

// tslint:disable-next-line: variable-name
export const PullRequestsGroup = observer(({ rows = [], totalRows, isSeeAllMode, onRowClicked, selectedRow, currentIndex, selectedIndex, onDismissPRHighlights, scrollable }: {
	currentIndex: number
	rows: IDashboardPullRequestExtended[];
	selectedRow: IDashboardPullRequestExtended | undefined;
	totalRows: number;
	selectedIndex: number | undefined;
	isSeeAllMode?: boolean;
	scrollable?: boolean;
	onDismissPRHighlights?: (item: IDashboardPullRequestExtended) => void;
	onRowClicked?: (item: IDashboardPullRequestExtended) => void;
}) => {
	const { authStore } = useStores();
	const [page, setPage] = useState<number>(1);
	const [total, setTotal] = useState(0);
	const [pullRequests, setPullRequests] = useState<IDashboardPullRequestExtended[]>([]);
	const dateTimeFormat = dateFormatByLocale(DateFormat.LongWithDay, authStore.authUser.locale);

	useEffect(() => {
		if (scrollable) {
			setTotal(totalRows);
			setPullRequests(rows);
			return;
		}

		if (isSeeAllMode) {
			const offset = (page - 1) * ROWS_PER_PAGE;
			const pagedItems = _.drop(rows, offset).slice(0, ROWS_PER_PAGE);
			setTotal(totalRows);
			setPullRequests(pagedItems);
			return;
		}

		setPage(1);
		const maxPullRequestsPerGroup = rows.slice(0, MAXIMUM_STATIC_GROUP_ITEMS);
		setTotal(maxPullRequestsPerGroup.length);
		setPullRequests(maxPullRequestsPerGroup);
	}, [rows, page, isSeeAllMode]);

	return (
		<div className={classNames("segment raised", scrollable ? "acu-scroll" : "")}>
			<table className={classNames("ui very compact small", "fixed single line", "unstackable selectable table",
			scrollable ? "sticky-table" : "")}>
				<colgroup span={2} />
				<thead className="full-width no-collapse">
					<tr>
						<th className="one wide">Opener</th>
						<th className="one wide">Reviewers</th>
						<th className="four wide">Title</th>
						<th className="two wide">Status</th>
						<th className="two wide">Attention Points</th>
						<th className="one wide">Tasks</th>
						<th className="one wide">Created</th>
						<Popup
							size="small"
							basic={true}
							content="Total amount of development time invested into the pull request (as calculated by Acumen)"
							trigger={
								<th className="one wide">
									<div className="labeled-header" >
										<span >Maker Time</span>
										<div className="label-margin">
											<span className="ui label tiny">beta</span>
										</div>
									</div>
								</th>
							}
						/>
						<Popup
							size="small"
							basic={true}
							content="Number of commits"
							trigger={
								<th className="half wide"><i className="code icon" /></th>
							}
						/>
						<Popup
							size="small"
							basic={true}
							content="Number of conversation"
							trigger={
								<th colSpan={onDismissPRHighlights ? 2 : 0} scope="colgroup" className="half wide"><i className="comment outline icon" /></th>
							}
						/>
					</tr>
				</thead>
				<tbody>
					{pullRequests.length === 0 && (
						<tr>
							<td colSpan={10}>
								<div className="rows-placeholder ui basic fluid center aligned segment" />
							</td>
						</tr>
					)}
					{pullRequests.map(
						(p) => {
							const highlights = p.highlights ? filterPullRequestDisplayData(p.highlights) : [];
							return (
								<tr
									key={p.entityId}
									{...clickEvent(GA_EVENT_CATEGORY.PullRequestWork, GA_EVENT_ACTION.Select, "SidePanel")}
									onClick={() => onRowClicked && onRowClicked(p)}
									className={classNames((
										(selectedRow && selectedRow.entityId === p.entityId && selectedRow.entityType === p.entityType) &&
											(isSeeAllMode || (selectedIndex !== undefined && selectedIndex === currentIndex))
											? "active"
											: ""))}
								>
									<td>
										<Popup
											size="mini"
											basic={true}
											style={{ textTransform: "capitalize" }}
											content={p.creator?.primaryDisplayName}
											trigger={
												<Avatar dataContributor={p.creator} />
											}
										/>
									</td>
									<td className="overflow-visible">
										<Popup
											size="mini"
											basic={true}
											style={{ textTransform: "capitalize" }}
											content={p.reviewers.map(r => r.primaryDisplayName).join(", ")}
											trigger={
												(
													<AvatarsGroup
														usersList={p.reviewers.map(r => ({
															id: r.id,
															displayName: r.primaryDisplayName || "",
															avatarUrl: r.primaryAvatarUrl || ""
														}))}
														maxUsersOnView={3}
														imgClassName={"medium"}
													/>
												)
											}
										/>
									</td>
									<td>
										<div className="hover-ellipsis">
											{p.publicHtmlUrl
												? <a href={p.publicHtmlUrl} target="_blank" rel="noopener noreferrer" className="acu-link">
													{`${p.repositoryName ?? ""}#${p.publicIdentifier}`}
												</a>
												: `${p.repositoryName ?? ""}#${p.publicIdentifier}`
											}
											<span className="pr-title after-space">{p.title}</span>
										</div>
									</td>
									<td>
										<PullRequestStatus status={p.status} />
									</td>
									<td>
										<div className="pr-highlight-container">
											{highlights.length > 0 && <span className="pr-highlight-text">{HighlightsFormatters.highlightText(highlights[0])}</span>}
											{highlights.length > 1 && <div className="ui tiny basic label">+{highlights.length - 1}</div>}
										</div>
									</td>
									<td>
										{(p.relatedTasks.length > 1) ?
											<span className="side-margin">{p.relatedTasks.length} Tasks</span>
											:
											(
												<>
													<Linkify
														splitterCharacter=", "
														linkMap={p.linkMapping}
														classNamePrefix="acu-link"
													>
														{p.relatedTasks.filter(t => t.publicIdentifier !== null)[0]?.publicIdentifier}
													</Linkify>
												</>
											)
										}
									</td>
									<td>
										<div className="hover-ellipsis">
											<span className="after-space">
												{p.openedFromDateMs && dateRelativeToNow(dateTimeFormat, p.openedFromDateMs)}
											</span>
										</div>
									</td>
									<td>{Formatters.workIntervals(p.totalCalculatedWorkMs)}</td>
									<td>{p.numberOfCommits}</td>
									<td>{p.numberOfComments}</td>
									{onDismissPRHighlights && (
										// eslint-disable-next-line no-sequences
										<td className="collapsing right aligned" onClick={(e) => (e.stopPropagation(), false)}>
											<Popup
												className="set-popup-menu-z-index"
												size="mini"
												basic={true}
												flowing={true}
												hoverable={true}
												on="click"
												hideOnScroll={true}
												position="bottom right"
												trigger={
													<i {...clickEvent(GA_EVENT_CATEGORY.PullRequestWork, GA_EVENT_ACTION.Delete, "NeedAttention")}
														className="ui icon outline ellipsis vertical align-button" />
												}
											>
												{
													<div className={classNames("ui tiny header", "flex-row")}
														style={{ cursor: "pointer" }}
														onClick={(e) => {
															onDismissPRHighlights(p);
															e.stopPropagation();
														}
														}
													>
														<i className="icon check check-icon-display" />
														<span>{"Acknowledge"}</span>
													</div>
												}
											</Popup>
										</td>
									)}
								</tr>
							);
						}
					)}
				</tbody>
				{!scrollable && total > ROWS_PER_PAGE && (
					<tfoot>
						<tr>
							<td colSpan={10}>
								<div className="ui basic center aligned segment">
									<Pagination
										boundaryRange={0}
										defaultActivePage={page}
										ellipsisItem={null}
										firstItem={null}
										lastItem={null}
										siblingRange={1}
										totalPages={Math.ceil(total / ROWS_PER_PAGE)}
										onPageChange={(_e, { activePage }) => setPage(activePage as number ?? 1)}
									/>
								</div>
							</td>
						</tr>
					</tfoot>
				)}
			</table>
		</div >
	);
});

const WORK_PR_FILTER_LOCAL_STORAGE_KEY = "work_pull_request_filters";
const WORK_PR_DATE_RANGE_FILTER_LOCAL_STORAGE_KEY = "work_pull_request_date_range_selector_selected";
const WORK_PR_FILTER_DATA_CONTRIBUTOR_FILTER_LOCAL_STORAGE_KEY = "work_pull_request_data_contributor_filter";
const WORK_PR_DATE_RANGE_FILTER_DEFAULT_RANGE = DateRangeType.Last2Weeks;

interface IFilterOptions {
	gitRepositories?: string[];
	statuses?: AcumenPullRequestStatus[];
	excludeDraft?: boolean;
}

const DEFAULT_FILTERS: IFilterOptions = {
	gitRepositories: [],
	statuses: [],
	excludeDraft: false
};

// tslint:disable-next-line: variable-name
const StaticGroupTitle = observer(({
	pullRequestsLength,
	totalPullRequests,
	isSeeAllMode,
	onSeeAllClick,
	title,
	customIconName,
	noItemsMessage,
	tooltip,
	isOpen
}: {
	title: string;
	pullRequestsLength: number;
	totalPullRequests: number;
	isSeeAllMode: boolean;
	customIconName?: ((isEmpty: boolean) => string);
	onSeeAllClick: () => void;
	noItemsMessage: string | undefined;
	tooltip: string | undefined;
	isOpen?: boolean;
}) => {
	return (
		<div className="ui compact grid">
			<div className="equal width row">
				<div className="column">
					<i className={classNames("toggler-icons icon", (isSeeAllMode ? "chevron left" : isOpen ? "chevron down" :"chevron right"))} />
					{customIconName && <i className={classNames("icon  " + (customIconName(pullRequestsLength === 0)))} />}
					<Popup
						size="small"
						basic={true}
						content={tooltip}
						disabled={tooltip === undefined}
						trigger={
							<span className="accordion-section-title">{title} ({totalPullRequests})</span>
						}
					/>
					{(noItemsMessage) && <span className="ui label transparent">{noItemsMessage}</span>}
				</div>
				<div className="right aligned  three wide column">
					<div className="ui tiny compact text labeled menu">
						{!isSeeAllMode
							&& Formatters.itemsRange(pullRequestsLength, MAXIMUM_STATIC_GROUP_ITEMS, totalPullRequests)
						}
						{!isSeeAllMode && totalPullRequests > MAXIMUM_STATIC_GROUP_ITEMS && (
							<span
								className="see-all-link"
								onClick={(e) => { e.stopPropagation(); onSeeAllClick(); }}
							>
								See All
							</span>
						)}
					</div>
				</div>
			</div>
		</div>
	);
});

// tslint:disable-next-line: variable-name
const PullRequestsPage = observer(() => {
	const {
		pullRequestsStore: {
			allPullRequests: {
				loading,
				data: prResponse
			},
			dismissHighlights: {
				loading: dismissingPRHighlight
			},
			prStatusesOptions,
			dismissPRHighlights,
			fetchAllPullRequests,
			resetAllPullRequests,
		},
		repositoriesStore: {
			fetchData: fetchGitRepoData,
			isLoading: isLoadingGitRepos,
			gitRepositoriesOptions
		},
		teamsStore,
		teamMembersStore,
	} = useStores();

	const [searchFilters, setSearchFilters] =
		useLocalStorage<IFilterOptions>(WORK_PR_FILTER_LOCAL_STORAGE_KEY, DEFAULT_FILTERS);
	const [searchedTeamId, setSearchedTeamId] = useState<string | undefined>(undefined);

	const [selectedPullRequestInPanel, setSelectedPullRequestInPanel] = useState<[IDashboardPullRequestExtended | undefined, number | undefined]>([undefined, undefined]);
	const [needAttentionPullRequests, setNeedAttentionPullRequests] = useState<IDashboardDetailedPullRequestGroup | undefined>(undefined);
	const [inProgressPullRequests, setInProgressPullRequests] = useState<IDashboardDetailedPullRequestGroup | undefined>(undefined);
	const [backlogPullRequests, setBacklogPullRequests] = useState<IDashboardDetailedPullRequestGroup | undefined>(undefined);
	const [donePullRequests, setDonePullRequests] = useState<IDashboardDetailedPullRequestGroup | undefined>(undefined);
	const [activeIndex, setActiveIndex] = useState<number | number[]>([0, 1, 2, 3]);
	const [prHighlightToDismiss, setPRHighlightToDismiss] = useState<IDashboardPullRequestExtended | undefined>(undefined);
	const [dataContributorFilter, setDataContributorFilter] =
		useLocalStorage<string[]>(WORK_PR_FILTER_DATA_CONTRIBUTOR_FILTER_LOCAL_STORAGE_KEY, []);

	const [dateRangeFilter, setDateRangeFilter] = useState<{
		selectedTeamId: string;
		value?: string | DateRangeType;
	}>({
		selectedTeamId: teamsStore.singleTeam.data.id,
		value: WORK_PR_DATE_RANGE_FILTER_DEFAULT_RANGE
	});

	const {
		getFilteredValueLabel,
		setSelectedSelectorValue,
		isRangeSelector,
		setStartTime,
		setEndDate,
		previousStartTime,
		previousEndTime,
		locale,
		getStartAndEndDates
	} =
		filtersSlice({
			searchedTeamId,
			dateRangeFilter,
			setDateRangeFilter,
			storageKey: WORK_PR_DATE_RANGE_FILTER_LOCAL_STORAGE_KEY
		});

	const { rangeStartTime, rangeEndTime, isCustomDateRange } = getStartAndEndDates();

	function clearPullRequests() {
		setSelectedPullRequestInPanel([undefined, undefined]);
		setNeedAttentionPullRequests(undefined);
		setInProgressPullRequests(undefined);
		setDonePullRequests(undefined);
		setBacklogPullRequests(undefined);
		setActiveIndex([0, 1, 2, 3]);
		resetAllPullRequests();
	}

	function onDismissPRHighlights(pr: IDashboardPullRequestExtended) {
		if (dismissingPRHighlight || !needAttentionPullRequests) {
			return;
		}
		const matchingPRIndex = needAttentionPullRequests?.pullRequests.findIndex((p) => p === pr);
		if (matchingPRIndex === -1) {
			return;
		}
		// tslint:disable-next-line: no-floating-promises
		dismissPRHighlights(pr).then(success => {
			if (success) {
				const tempPRs = [...needAttentionPullRequests?.pullRequests];
				tempPRs.splice(matchingPRIndex, 1);
				setNeedAttentionPullRequests({
					pullRequests: tempPRs,
					total: (needAttentionPullRequests.total > 0 ? needAttentionPullRequests.total - 1 : 0)
				});
			}
		});
	}

	useEffect(() => () => {
		clearPullRequests();
	}, []);

	useEffect(() => {
		async function retrieveMembers(selectedTeamId: string) {
			await teamMembersStore.fetchAllTeamMembers(selectedTeamId);
			const dataContributorIds = dataContributorFilter.filter(dcId =>
				teamMembersStore.allTeamMembers.data.find(teamMember => teamMember.id === dcId));
			setDataContributorFilter(dataContributorIds);
		}
		async function retrieveGitRepositories(selectedTeamId: string) {
			return selectedTeamId && !isLoadingGitRepos &&
				await fetchGitRepoData({ teamId: selectedTeamId }, { order: DashboardSortOrder.Ascending });
		}
		if (searchedTeamId) {
			// tslint:disable-next-line: no-floating-promises
			retrieveMembers(searchedTeamId);
			// tslint:disable-next-line: no-floating-promises
			retrieveGitRepositories(searchedTeamId);
			setDateRangeFilter(prevState => {
				return {
					...prevState,
					selectedTeamId: searchedTeamId
				};
			});
		}
	}, [searchedTeamId]);

	useEffect(() => {
		const { pullRequests, dynamic } = prResponse;
		setNeedAttentionPullRequests(dynamic);

		if (!pullRequests || Array.isArray(pullRequests)) {
			return;
		}

		const inProgressGroup = pullRequests[DashboardPullRequestWorkStatusGrouping.InReview];
		setInProgressPullRequests(inProgressGroup);

		const backlogGroup = pullRequests[DashboardPullRequestWorkStatusGrouping.Open];
		setBacklogPullRequests(backlogGroup);

		const doneGroup = pullRequests[DashboardPullRequestWorkStatusGrouping.Done];
		setDonePullRequests(doneGroup);

		if (Array.isArray(activeIndex)) {
			const onLoadActiveIndex: number[] = [];
			if (dynamic && dynamic.total > 0) {
				onLoadActiveIndex.push(0);
			}
			if (inProgressGroup && inProgressGroup?.total > 0) {
				onLoadActiveIndex.push(1);
			}
			if (backlogGroup && backlogGroup?.total > 0) {
				onLoadActiveIndex.push(2);
			}
			if (doneGroup && doneGroup?.total > 0) {
				onLoadActiveIndex.push(3);
			}
			setActiveIndex(onLoadActiveIndex);
		}
	}, [prResponse]);

	useEffect(() => {
		if (teamsStore.singleTeam.data.id &&
			teamsStore.singleTeam.data.id !== searchedTeamId) {
			if (searchedTeamId !== undefined && !_.isEqual(searchFilters, DEFAULT_FILTERS)) {
				// Reset only the data contributors (team specific filter).
				setDataContributorFilter([]);
			}
			setSearchedTeamId(teamsStore.singleTeam.data.id);
		}
	}, [teamsStore.singleTeam.data.id]);

	useEffect(() => {
		if (searchedTeamId !== undefined && dateRangeFilter !== undefined &&
			dateRangeFilter.selectedTeamId === searchedTeamId && rangeEndTime) {
			clearPullRequests();
			fetchPullRequests(DashboardPullRequestStaticGroupingOption.WorkStatus);
		}
	}, [searchFilters, dateRangeFilter, dataContributorFilter]);

	function fetchPullRequests(staticGrouping: DashboardPullRequestStaticGroupingOption = DashboardPullRequestStaticGroupingOption.WorkStatus,
		limit?: number) {
		const filters = searchFilters || DEFAULT_FILTERS;

		let startTime: Date | undefined;
		let endTime: Date | undefined;

		endTime = rangeEndTime || new Date();
		startTime = rangeStartTime || moment.utc(endTime).subtract(3, "months").toDate();

		if (!isCustomDateRange) {
			setEndDate(endTime, startTime);
		}

		// tslint:disable-next-line: no-floating-promises
		fetchAllPullRequests({
			filter: {
				teamId: teamsStore.singleTeam.data.id,
				dataContributorIds: dataContributorFilter,
				startTime,
				endTime,
				gitRepositories: filters.gitRepositories,
				statuses: filters.statuses,
				excludeDraft: filters.excludeDraft
			},
			staticGroupingLimit: limit,
			staticGrouping,
			dynamicGroupingLimit: limit,
			dynamicGrouping: DashboardPullRequestDynamicGroupingOption.Highlights,
			expand: [DashboardPullRequestExpandOption.Highlights]
		});
	}

	const getCounterLabel = () => {
		return !loading ? (inProgressPullRequests?.total ?? 0) + (backlogPullRequests?.total ?? 0) + (donePullRequests?.total ?? 0) + ` Pull Requests` : undefined;
	};

	const selectFields: ISelectOptionBase[] = [
		{
			name: "gitRepositories",
			label: "repositories",
			isLoading: isLoadingGitRepos,
			options: gitRepositoriesOptions
		},
		{
			name: "statuses",
			label: "Statuses",
			isLoading: false,
			options: prStatusesOptions
		},
	];

	const popupCheckboxFields = [
		{
			name: "excludeDraft",
			label: "Hide draft PRs"
		}
	];

	const tableWithSidePanel = " main-table-with-panel";

	const handlePrInPanelDismiss = useCallback(() => setSelectedPullRequestInPanel([undefined, undefined]), []);

	return (
		<div className="pull-request-tab ongoing-pages ui sixteen column grid">
			<ConfirmationModal
				className={"warning"}
				open={prHighlightToDismiss !== undefined}
				header={"Need attention"}
				confirmButton={"Ok"}
				cancelButton={"Cancel"}
				content={`Acknowledge pull request and remove from attention section?`}
				onCancel={() => { setPRHighlightToDismiss(undefined); }}
				onConfirm={() => { onDismissPRHighlights(prHighlightToDismiss!); setPRHighlightToDismiss(undefined); }}
			/>
			{(selectedPullRequestInPanel[0] !== undefined && selectedPullRequestInPanel[1] !== undefined) &&
				<PullRequestDetailsDisplayPanel
					pr={selectedPullRequestInPanel[0]}
					shouldShowNeedAttentionFlag={selectedPullRequestInPanel[1] === 0}
					onDismiss={handlePrInPanelDismiss}
					onDismissPRHighlights={(v => setPRHighlightToDismiss(v))}
					outsidePanelClassName={tableWithSidePanel}
				/>
			}
			<FiltersTopNavbar
				filters={searchFilters || DEFAULT_FILTERS}
				applyFilters={setSearchFilters}
				popupSelectFields={selectFields}
				popupCheckboxFields={popupCheckboxFields}
				avatarSelectorField={{
					usersList: teamMembersStore.allTeamMembers.data.map(user => { user.id = user.dataContributorId; return user; }),
					singleUser: true,
					onUsersSelected: (selectedUsers: string[]) => {
						if (!_.isEqual(selectedUsers.sort(), dataContributorFilter.sort())) {
							setDataContributorFilter(selectedUsers);
						}
					},
					initialSelectedUsers: dataContributorFilter
				}}
				counterLabel={getCounterLabel()}
				clickEventPage={GA_EVENT_CATEGORY.PullRequestWork}
				classNameString={"right-padding"}
			>
				<div className="control-width">
					<div className="control-width">
						<FiltersSelector
							placeholder={"Select Filters"}
							value={getFilteredValueLabel()}
							clickEvent={clickEvent(GA_EVENT_CATEGORY.PullRequestWork, GA_EVENT_ACTION.Filter, "date-selector")}
							onClose={() => {
								if (!rangeEndTime && isCustomDateRange) {
									setStartTime(previousStartTime);
									setEndDate(previousEndTime, previousStartTime);
								}
							}}
						>
							<TimeFramesSelector
								value={dateRangeFilter.value}
								setValue={(value) => {
									setSelectedPullRequestInPanel([undefined, undefined]);
									setSelectedSelectorValue(value);
									setDateRangeFilter(prevState => {
										return {
											selectedTeamId: prevState.selectedTeamId,
											value,
										};
									});
								}}
								isCustomDateRange={isCustomDateRange}
							/>
							<DatePickerSelector
								locale={locale}
								isRangeSelector={isRangeSelector}
								setStartDate={(t) => {
									setSelectedPullRequestInPanel([undefined, undefined]);
									setDateRangeFilter(prevState => {
										return {
											...prevState,
											id: undefined,
											value: undefined,
										};
									});
									setStartTime(t);
								}}
								startDate={rangeStartTime}
								setEndDate={(t) => {
									setSelectedPullRequestInPanel([undefined, undefined]);
									setEndDate(t);
									setDateRangeFilter(prevState => {
										return {
											...prevState,
											id: `${prevState.selectedTeamId}-${t?.toDateString()}`,
											value: undefined,
										};
									});
								}}
								endDate={rangeEndTime}
							/>
						</FiltersSelector>
					</div>
				</div>
			</FiltersTopNavbar>
			<h1>Pull Requests</h1>
			<div className="main-table acu-scroll set-page-z-index">
				<div className="ui sixteen wide column">
					{loading && <Segment placeholder={true} loading={true} />}
					{!loading &&
						(
							<Accordion
								className={tableWithSidePanel}
								panels={
									[
										createPanel(0, "need-attention", "Need Attention", needAttentionPullRequests, activeIndex, () => {
											setActiveIndex(0);
											fetchPullRequests(undefined, 0);
										}, selectedPullRequestInPanel[0], (v => setSelectedPullRequestInPanel([v, 0])), selectedPullRequestInPanel[1], ((isEmpty) => isEmpty ? "gray-font flag" : "red flag"), (v => setPRHighlightToDismiss(v)),
											"Here are several selected pull requests that Acumen has found might require your attention. This is based on insights and various data points and events. Click an item to see more information."),
										createPanel(1, "in-the-works", "In the Works", inProgressPullRequests, activeIndex, () => {
											setActiveIndex(1);
											fetchPullRequests(DashboardPullRequestStaticGroupingOption.WorkStatusInReview, 0);
										}, selectedPullRequestInPanel[0], (v => setSelectedPullRequestInPanel([v, 1])), selectedPullRequestInPanel[1]),
										createPanel(2, "backlog", "Haven’t Started", backlogPullRequests, activeIndex, () => {
											setActiveIndex(2);
											fetchPullRequests(DashboardPullRequestStaticGroupingOption.WorkStatusOpen, 0);
										}, selectedPullRequestInPanel[0], (v => setSelectedPullRequestInPanel([v, 2])), selectedPullRequestInPanel[1]),
										createPanel(3, "done", "Done", donePullRequests, activeIndex, () => {
											setActiveIndex(3);
											fetchPullRequests(DashboardPullRequestStaticGroupingOption.WorkStatusDone, 0);
										}, selectedPullRequestInPanel[0], (v => setSelectedPullRequestInPanel([v, 3])), selectedPullRequestInPanel[1]),
									]
										.filter(p => Array.isArray(activeIndex) || p.index === activeIndex)
										.map((p, idx) => {
											p.index = idx;
											return p;
										})
								}
								activeIndex={Array.isArray(activeIndex) ? activeIndex : 0}
								exclusive={Array.isArray(activeIndex) ? false : true}
								styled={true}
								fluid={true}
								onTitleClick={(_e, { index, active }) => {
									if (!Array.isArray(activeIndex)) {
										fetchPullRequests();
										setActiveIndex([0, 1, 2, 3]);
									} else {
										if (!active) {
											setActiveIndex([...activeIndex, index as number]);
										} else {
											setActiveIndex(_.filter(activeIndex, (i) => i !== index));
										}
									}
								}}
							/>
						)}
				</div>
			</div>
		</div >
	);
});

export default PullRequestsPage;
