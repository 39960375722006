import urlJoin from "url-join";
import { BaseCustomerApiClient } from "./base-customer-api-client";
import { getData, getFile, postData } from "../../../services/fetch-helpers";
import {
	AcumenTaskType, ChartType, DashboardExportFormat,
	DateRangeType,
	GitRepositoryIntegrationType,
	IChartResponse,
	IDashboardPRSizeToMergeTime, IDashboardResponse, MetricInterval, TUPLE_SEPARATOR
} from "@acumen/dashboard-common";
import moment from "moment-timezone";
import _ from "lodash";
import { qsStringify } from "../../../services/url-routes-helper";

export const CHARTS_ROUTE = "charts";

type ChartSeriesData = Array<[string | number, number]>;
export interface CategoryChartSeriesData { [x: string]: ChartSeriesData; }

function extractIds(dimensionValueArray: string[]): string[] {
	return dimensionValueArray.map(idAndTypeTuple => {
		const [id, ] = idAndTypeTuple.split(TUPLE_SEPARATOR);
		return id;
	});
}

function cleanDimensionsData(dimensions: { [dim: string]: string[] }): string[] {
	return _.chain(dimensions)
		.pickBy(x => x.length > 0)
		.map((val, key) => `${key}=${extractIds(val!).join(",")}`)
		.value();
}

export class ChartsApiClient extends BaseCustomerApiClient {
	public fetchPRSizeToMergeTimeData = async (teamIds?: string[], dataContributorIds?: string[],
		projectIds?: string[], startTime?: Date, endTime?: Date, repositoryIds?: string[], baseIsDefaultBranch?: boolean, excludeDraft?: boolean) => {
		const query: Record<string, string | string[] | boolean | Date> = {};

		if (teamIds) {
			query["teamIds[]"] = teamIds;
		}

		if (dataContributorIds) {
			query["dataContributorIds[]"] = dataContributorIds;
		}

		// ACM-3540 we decided not to filter by project ids as it makes the
		// response to look different than the other charts.
		// if (projectIds && projectIds.length > 0) {
		// 	query["projectIds[]"] = projectIds;
		// }

		if (startTime) {
			query.startTime = startTime;
		}

		if (endTime) {
			query.endTime = endTime;
		}

		if (repositoryIds) {
			query["repositoryIds[]"] = repositoryIds;
		}

		if (baseIsDefaultBranch !== undefined) {
			query.baseIsDefaultBranch = baseIsDefaultBranch;
		}
		if (excludeDraft !== undefined) {
			query.excludeDraft = excludeDraft;
		}

		return getData<IDashboardPRSizeToMergeTime, any>(
			this.createCustomerEntityRoute(`${urlJoin(CHARTS_ROUTE, "prSizeToMergeTime")}?${qsStringify(query)}`),
			this.token
		);
	}

	public fetchDeploymentFrequency = async (interval: MetricInterval, startTime: Date, endTime: Date,
		timezone: string, teamIds?: string[], dataContributorIds?: string[], gitRepositoryIds?: string[]) => {
		const query: { [label: string]: string | string[] | number } = {};

		query.interval = interval;
		query.timezone = timezone;
		query.startTime = moment.tz(startTime, timezone).toISOString(true);
		query.endTime = moment.tz(endTime, timezone).toISOString(true);
		if (teamIds && teamIds.length > 0) {
			query["teamIds[]"] = teamIds;
		}
		if (gitRepositoryIds) {
			query["repositoryIds[]"] = gitRepositoryIds.map(r => `${r},${GitRepositoryIntegrationType.GITHUB}`);
		}
		if (dataContributorIds) {
			query["dataContributorIds[]"] = dataContributorIds;
		}

		return getData<CategoryChartSeriesData, any>(
			this.createCustomerEntityRoute(`${urlJoin(CHARTS_ROUTE, "deployment-frequency")}?${qsStringify(query)}`),
			this.token
		);
	}

	public fetchGitHubReportedDeploymentFrequency = async (interval: MetricInterval, startTime: Date, endTime: Date,
		timezone: string, teamIds?: string[], dataContributorIds?: string[], gitRepositoryIds?: string[], environments?: string[]) => {
		const query: { [label: string]: string | string[] | number } = {};

		query.interval = interval;
		query.timezone = timezone;
		query.startTime = moment.tz(startTime, timezone).toISOString(true);
		query.endTime = moment.tz(endTime, timezone).toISOString(true);
		if (teamIds && teamIds.length > 0) {
			query["teamIds[]"] = teamIds;
		}
		if (gitRepositoryIds) {
			query["repositoryIds[]"] = gitRepositoryIds.map(r => `${r},${GitRepositoryIntegrationType.GITHUB}`);
		}
		if (dataContributorIds) {
			query["dataContributorIds[]"] = dataContributorIds;
		}
		if (environments) {
			query["environments[]"] = environments;
		}

		return getData<CategoryChartSeriesData, any>(
			this.createCustomerEntityRoute(`${urlJoin(CHARTS_ROUTE, "github-reported-deployment-frequency")}?${qsStringify(query)}`),
			this.token
		);
	}

	public exportChart = async (format: DashboardExportFormat, type: ChartType, interval: MetricInterval, dateRange: DateRangeType,
			timezone: string, teamIds: string[] = [], dataContributorIds: string[] = [],
			boardId: string[] = [], projectIds: string[] = [], repositoryIds: string[] = [],
			acumenTaskTypes: AcumenTaskType[] = [], excludeDraftPrs?: boolean, baseIsDefaultBranch?: boolean,
			debug: boolean = false) => {
		const query = this.generateQueryString(type, interval, dateRange, timezone, debug, teamIds,
			dataContributorIds, boardId, projectIds, repositoryIds, acumenTaskTypes, excludeDraftPrs, baseIsDefaultBranch);
		query.format = format;

		return getFile(
			this.createCustomerEntityRoute(`${urlJoin(CHARTS_ROUTE, "export")}?${qsStringify(query)}`),
			this.token,
			"POST"
		);
	}

	public generateQueryString = (type: ChartType, interval: MetricInterval, dateRange: DateRangeType,
			timezone: string, debug: boolean = false, teamIds: string[] = [], dataContributorIds: string[] = [],
			boardId: string[] = [], projectIds: string[] = [], repositoryIds: string[] = [],
			acumenTaskTypes: AcumenTaskType[] = [], excludeDraftPrs?: boolean, baseIsDefaultBranch?: boolean)
			: { [label: string]: string | string[] | number } => {
		const query: { [label: string]: string | string[] | number } = {};
		query.chart = type;
		query.interval = interval;
		query.timezone = timezone;
		query.dateRange = dateRange;

		if (debug ?? false) {
			query.debug = "true";
		}

		const dimensions = this.generateDimensionsMap(type, teamIds, dataContributorIds, boardId,
			projectIds, repositoryIds, acumenTaskTypes, excludeDraftPrs, baseIsDefaultBranch);

		query["dimensions[]"] = cleanDimensionsData(dimensions);

		return query;
	}

	public generateDimensionsMap = (type: ChartType, teamIds: string[] = [], dataContributorIds: string[] = [],
			boardId: string[] = [], projectIds: string[] = [], repositoryIds: string[] = [],
			acumenTaskTypes: AcumenTaskType[] = [], excludeDraftPrs?: boolean, baseIsDefaultBranch?: boolean,
			): Record<string, string[]> => {
		const dims: Record<string, string[]> = {};
		switch (type) {
			case ChartType.PRCycleTime: {
				dims[`team_id`] = teamIds;

				dims[`data_contributor_id`] = dataContributorIds;
				dims[`project_id`] = projectIds;
				dims[`repository_id`] = repositoryIds;
				dims[`sprint_board_id`] = boardId;
				dims[`acumen_task_type`] = acumenTaskTypes;

				if (excludeDraftPrs !== undefined && excludeDraftPrs) {
					dims[`is_draft`] = [false.toString()];
				}

				if (baseIsDefaultBranch !== undefined && baseIsDefaultBranch) {
					dims[`base_is_default_branch`] = [true.toString()];
				}

				break;
			}

			case ChartType.ClosedUnMergedPrs: {
				dims[`team_id`] = teamIds;

				dims[`data_contributor_id`] = dataContributorIds;
				dims[`project_id`] = projectIds;
				dims[`repository_id`] = repositoryIds;
				dims[`sprint_board_id`] = boardId;
				dims[`acumen_task_type`] = acumenTaskTypes;

				break;
			}

			default:
				throw new Error(`Unsupported chart ${type}`);

		}

		return dims;
	}

	public fetchChartData = async (type: ChartType, interval: MetricInterval, dateRange: DateRangeType,
			timezone: string, teamIds: string[] = [], dataContributorIds: string[] = [],
			boardId: string[] = [], projectIds: string[] = [], repositoryIds: string[] = [],
			acumenTaskTypes: AcumenTaskType[] = [], excludeDraftPrs?: boolean, baseIsDefaultBranch?: boolean,
			debug: boolean = false): Promise<IDashboardResponse<IChartResponse>> => {
		const query = this.generateQueryString(type, interval, dateRange, timezone, debug, teamIds,
			dataContributorIds, boardId, projectIds, repositoryIds, acumenTaskTypes, excludeDraftPrs, baseIsDefaultBranch);

		const res = await getData<IChartResponse, any>(
			this.createCustomerEntityRoute(`${urlJoin(CHARTS_ROUTE, "data")}?${qsStringify(query)}`),
			this.token,
			this.tokenType
		);

		if (res === null) {
			return { data: {}};
		}

		return res;
	}

	public createChartIframe = async (type: ChartType, interval: MetricInterval, dateRange: DateRangeType,
			timezone: string, teamIds: string[] = [], dataContributorIds: string[] = [],
			boardId: string[] = [], projectIds: string[] = [], repositoryIds: string[] = [],
			acumenTaskTypes: AcumenTaskType[] = [], excludeDraftPrs?: boolean, baseIsDefaultBranch?: boolean,
			debug: boolean = false): Promise<string> => {
		const token = await this.fetchChartToken();

		if (!token) {
			return "Failed to create token";
		}

		const queryString = this.generateQueryString(type, interval, dateRange, timezone, debug, teamIds,
			dataContributorIds, boardId, projectIds, repositoryIds, acumenTaskTypes, excludeDraftPrs, baseIsDefaultBranch);

		return `<iframe src=${urlJoin(window.location.origin, "external", this.customerId, token, "charts")}?${qsStringify(queryString)} width="600" height="400"> </iframe>`;
	}

	public fetchChartToken = async () => {
		const res = await postData<void, {token: string}, any>(
			this.createCustomerEntityRoute(`${urlJoin(CHARTS_ROUTE, "token")}`),
			this.token
		);

		if (res === null) {
			return null;
		}

		return res.data.token;
	}

}
