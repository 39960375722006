import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useState } from "react";
import { useStores } from "../../../mobx-stores";
import { ChartSeriesData } from "v2/helpers/charts";
import LoadingIndicator from "../../../components/loader/loader";
import { IChartScope } from "./chart-scope";
import useDeepCompareEffect from "use-deep-compare-effect";
import colorScheme from "./chart-color-scheme";
import { OrgMetricsCard } from "../components/org-metrics-card/org-metrics-card";

export enum ThroughputSum {
	IssuesCount = "IssuesCount",
	StoryPoints = "StoryPoints"
}

interface IProps {
	scope: IChartScope;
	throughput: ThroughputSum;
	chartHeight?: {
		mainChartHeight: number
	};
}

function ThroughputChart(props: IProps) {
	const { metricOrgStore } = useStores();
	const [chartOptions, setChartOptions] = useState<Highcharts.Options | undefined>(undefined);
	const tooltip = "Shows the amount of pull requests merged, and issues marked done for the given period.";

	function buildChartOptions(data: {
		prMerge: ChartSeriesData,
		issuesDone: ChartSeriesData
	}): Highcharts.Options {

		const categorySeries: Highcharts.SeriesColumnOptions[] = [
			{
				data: data.issuesDone,
				visible: data.issuesDone.length > 0,
				color: colorScheme.genericScheme.task,
				name: "Issues marked done",
				type: "column"
			},
			{
				data: data.prMerge,
				visible: data.prMerge.length > 0,
				color: colorScheme.genericScheme.pr,
				name: "Pull requests merged",
				type: "column"
			}
		];

		const options: Highcharts.Options = {
			chart: {
				plotBackgroundColor: undefined,
				plotBorderWidth: undefined,
				plotShadow: false,
				height: props.chartHeight?.mainChartHeight
			},
			title: undefined,
			tooltip: {
				split: true,
			},
			xAxis: {
				gridLineWidth: 1,
				type: "datetime"
			},
			yAxis: [
				{
					min: 0,
					title: {
						text: "Pull requests / Issues"
					}
				}
			],
			credits: {
				enabled: false
			},
			series: categorySeries,
			legend: {
				align: "left",
			},
		};

		return options;
	}

	async function fetchData(): Promise<{
		prMerge: ChartSeriesData,
		issuesDone: ChartSeriesData
	} | undefined> {
		let data: {
			prMerge: ChartSeriesData,
			issuesDone: ChartSeriesData
		} | undefined;
		const scope = props.scope;

		switch (props.throughput) {
			case ThroughputSum.StoryPoints: {
				data = await metricOrgStore.throughputStoryPoints(scope.startTime, scope.endTime, scope.timezone, scope.interval,
					undefined, scope.repositoryIds, scope.projectIds, scope.dataContributorIds, scope.subtaskStrategy,
					!scope.includeDraftPRs, !scope.includeInternalPRs, scope.includeAggregatedPRs);
				break;
			}
			case ThroughputSum.IssuesCount: {
				data = await metricOrgStore.throughputIssuesCount(scope.startTime, scope.endTime, scope.timezone, scope.interval,
					undefined, scope.repositoryIds, scope.projectIds, scope.dataContributorIds, scope.subtaskStrategy,
					!scope.includeDraftPRs, !scope.includeInternalPRs, scope.includeAggregatedPRs);
				break;
			}
		}
		return data;
	}

	function shouldFetchData(): boolean {
		let shouldFetch = false;
		switch (props.throughput) {
			case ThroughputSum.StoryPoints: {
				shouldFetch = (!metricOrgStore.isLoadingThroughputIssueStoryPointMetric);
				break;
			}
			case ThroughputSum.IssuesCount: {
				shouldFetch = (!metricOrgStore.isLoadingThroughputIssuesCountMetric);
				break;
			}
		}

		return shouldFetch;
	}

	useDeepCompareEffect(() => {
		let isMounted = true;
		async function fetchChartData() {
			const data = await fetchData();
			if (isMounted && data) {
				setChartOptions(buildChartOptions(data));
			}
		}

		if (shouldFetchData()) {
			setChartOptions(undefined);
			// tslint:disable-next-line: no-floating-promises
			fetchChartData().then(() => {
				isMounted = false;
			});
		}
	}, [props]);

	return (
		<OrgMetricsCard title="Velocity" tooltip={tooltip}>
			<div className="org-metrics-velocity-container">
				<LoadingIndicator local={true} isActive={chartOptions === undefined}>
					{chartOptions && (
						<HighchartsReact
							highcharts={Highcharts}
							options={chartOptions}
						/>
					)}
				</LoadingIndicator>
			</div>
		</OrgMetricsCard>
	);
}

export default ThroughputChart;
