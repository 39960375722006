import React, { useCallback, useMemo, useState } from "react";

import { FilterOptionModel } from "./filter-dropdown/types";
import { useFilterEffect } from "./filter-dropdown/hooks";
import ControlledTableHeader from "./controlled-table-header";

interface TableHeaderProps<Item> extends React.ComponentProps<"th"> {
	columnName?: string;
	sortable?: boolean;
	labelClassName?: string;
	sortBy?: (item: Item) => unknown;
	filterSearchPlaceholder?: string;
	isFilteringEnabledForColumn?: boolean;
	presetFilterSelection?: { default: Set<string | number> | null } | null;
	mapItemToFilterOption?: (item: Item) => FilterOptionModel;
}

/**
 * Useful for sortable column headers (th-s) as it wraps re-usable logic for such table cells as:
 * - Displaying appropriate arrow
 * - Passing onClick handle for changing sort directions, etc.
 * @param props
 */
function TableHeader<Item>(props: TableHeaderProps<Item>) {
	const {
		columnName,
		sortable,
		sortBy,
		filterSearchPlaceholder,
		mapItemToFilterOption = defaultMapItemToFilterOption,
		children = columnName,
		labelClassName,
		isFilteringEnabledForColumn,
		presetFilterSelection,
		onClick,
		...restProps
	} = props;
	const [selectedFilterItems, setSelectedFilterItems] = useState<Set<string | number> | null>(null);
	const filterSelection = useMemo(() => ({ default: selectedFilterItems }), [selectedFilterItems]);
	const handleFilterSelectionChange = useCallback((selection: { default: Set<string | number> | null }) => {
		setSelectedFilterItems(selection.default);
	}, []);

	useFilterEffect({ mapItemToFilterOption, selection: selectedFilterItems });

	return (
		<ControlledTableHeader
			{...restProps}
			columnName={columnName}
			sortable={sortable}
			labelClassName={labelClassName}
			sortBy={sortBy}
			filterSearchPlaceholder={filterSearchPlaceholder}
			isFilteringEnabledForColumn={isFilteringEnabledForColumn}
			mapItemToFilterOption={mapItemToFilterOption}
			filterKey="default"
			presetFilterSelection={presetFilterSelection}
			filterSelection={filterSelection}
			onFilterSelectionChange={handleFilterSelectionChange}
			children={children}
		/>
	);
}

const defaultMapItemToFilterOption = () => "";

export default TableHeader;
