import React, { useCallback, useEffect, useState } from "react";
import CheckboxInput, { ICheckboxInputPropsBase } from "../../components/form/checkbox-input";
import { ISelectOptionBase, ISelectOptionGroups, ISelectOptions, isGroupedSelectOptions } from "../../components/form/option-select";
import DrawerMultiSelectBox, { MultiSelectComponent } from "./drawer-multi-select";
import { clickEvent, GA_EVENT_ACTION, GA_EVENT_CATEGORY } from "../../analytics-events";
import { Menu, Sidebar } from "semantic-ui-react";
import TriggerOnClickOutside from "../../components/ux-effects/trigger-on-click-outside";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import _ from "lodash";
import "./style.scss";
import { FilterIcon } from "../icons";

export type FilterType = Record<string, boolean | string[] | undefined>;
const FLOATING_OPTIONS_BOX = "FloatingOptionsBox";

interface IFiltersDrawer<Filters extends FilterType> {
	filters: Filters;
	applyFilters: (filters: Filters) => void;
	counterLabel?: number | string;
	popupSelectFields: ISelectOptionBase[];
	popupCheckboxFields?: ICheckboxInputPropsBase[];
	onSearchByString?: (str: string) => void;
	clickEventPage: GA_EVENT_CATEGORY;
	children?: React.ReactChild | React.ReactChild[];
}

// tslint:disable-next-line: variable-name
const FilterDrawer = <Filters extends FilterType>(props: IFiltersDrawer<Filters>) => {
	const {
		popupSelectFields,
		popupCheckboxFields,
		applyFilters,
		filters,
		clickEventPage
	} = { ...props };

	const [isOpen, setIsOpen] = useState<boolean>(false);
	const { control, register, getValues, setValue } = useForm({});

	const [openMultiSelectMenus, toggleMultiSelectMenus] = useState<Record<string, boolean>>({});
	const [optionsWindow, setOptionsWindow] = useState<number | undefined>(0);
	const currentSelectField = popupSelectFields.find(field => openMultiSelectMenus[field.name]);
	const [optionsInSelector, setOptionsInSelector] = useState<any[]>(currentSelectField?.options ?? []);
	const [filtersCount, setFiltersCount] = useState<number>(0);
	const [isSelectionDirty, setSelectionDirty] = useState<boolean>(false);

	useEffect(() => {
		const defaultMenuState: Record<string, boolean> = {};
		if (openMultiSelectMenus === defaultMenuState) {
			popupSelectFields.forEach(field => {
				const name = field.name;
				defaultMenuState[name] = false;
			});
			toggleMultiSelectMenus(defaultMenuState);
		}
	}, [popupSelectFields]);

	useEffect(() => {
		let selectedFilters = 0;
		Object.entries(filters).forEach(filter => {
			const [filterName, filterValues] = [...filter];
			if (![...popupSelectFields, ...(popupCheckboxFields ?? [])].find(_filter => _filter.name === filterName)) {
				return;
			}
			setValue(filterName, filterValues);
			setValue(`${FLOATING_OPTIONS_BOX}${filterName}`, filterValues);
			if (Array.isArray(filterValues) && (filterValues as string[]).length > 0) {
				selectedFilters++;
			} else if (_.isBoolean(filterValues) && filterValues === true) {
				selectedFilters++;
			} else if (_.isString(filterValues) && filterValues !== undefined) {
				selectedFilters++;
			}
		});
		setFiltersCount(selectedFilters);
		setSelectionDirty(false);
	}, [filters, isOpen]);

	const toggleMenu = useCallback((name?: string) => {
		const menuState: Record<string, boolean> = {};
		setOptionsWindow(undefined);
		popupSelectFields.forEach((field, i) => {
			if (field.name === name) {
				setOptionsWindow(i);
			}
			menuState[field.name] = (field.name === name) ? !openMultiSelectMenus[field.name] : false;
		});
		toggleMultiSelectMenus(menuState);
	}, [popupSelectFields, openMultiSelectMenus]);

	const closeMenu = useCallback(() => {
		setIsOpen(false);
		toggleMenu();
	}, [toggleMenu]);

	const filterSelectedValuesFromOptions = (options: ISelectOptionGroups[] | ISelectOptions[], selectedValues: string[]) => {
		if (isGroupedSelectOptions(options)) {
			const newGroupedOptions = options.map(group => {
				return {
					label: group.label,
					options: group?.options.filter(opt => !selectedValues?.find((v) => v === opt.value))
				};
			});
			return newGroupedOptions;
		} else {
			const newOptions = options.filter(opt => !selectedValues?.find((v) => v === opt.value));
			return newOptions;
		}
	};

	const applyNewFilters = () => {
		const savedValues: Filters = _.cloneDeepWith(getValues(), (value) => {
			if (_.isBoolean(value) || _.isString(value) || _.isTypedArray(value)) {
				return value;
			}
			return undefined;
		});

		closeMenu();
		Object.keys(savedValues).forEach(field => {
			if (field.match(FLOATING_OPTIONS_BOX)) {
				delete savedValues[field];
			}
		});
		applyFilters(savedValues);
	};

	const safeSetValue = (filterName: string, newValues: string[]) => {
		const filtersOptions = popupSelectFields.find(a => a.name === filterName)?.options;
		if (filtersOptions && isGroupedSelectOptions(filtersOptions)) {
			const validFields = filtersOptions.map(optionsGroup => {
				return newValues.filter(val => optionsGroup.options.find(opt => opt.value === val));
			}).reduce((acc, b) => {
				return [...acc, ...b];
			}, []);
			setValue(filterName, validFields);
		} else if (filtersOptions && !isGroupedSelectOptions(filtersOptions)) {
			const validFields = newValues.filter(val => filtersOptions.find(opt => opt.value === val));
			setValue(filterName, validFields);
		}
	};

	return (
		<div id="filter-drawer">
			<TriggerOnClickOutside onTrigger={closeMenu}>
				<div className={classNames("inner", { active: isOpen })}>
					<Sidebar
						as={Menu}
						visible={(isOpen)}
						className="push filter-drawer"
						inverted={true}
						vertical={true}
					>
						<div className="drawer-content">
							<p className="filters-title underline header flex-row spaced">
								Filters
								<i className="icon thin times clickable" onClick={(e) => {
									e.stopPropagation();
									closeMenu();
								}} />
							</p>

							{popupSelectFields?.map((select, i: number) => {
								return <div key={i} className="light-scroll">
									<div
										className="drawer-header clickable flex-centered"
										onClick={(e) => {
											e.stopPropagation();
											toggleMenu(select.name);
											const options = popupSelectFields.find(field => field.name === select.name)?.options;
											if (options && isOpen) {
												const values = getValues(select.name);
												setOptionsInSelector(filterSelectedValuesFromOptions(options, values));
											}
										}}
									>
										{sideArrow(openMultiSelectMenus[select.name])}
										<span className="flex-spaced full-width">
											<span className="acu-capitalize">
												{select.label}
											</span>
											{getValues(select.name)?.length > 0 && <span className="ui primary mini circular label">{getValues(select.name)?.length}</span>}
										</span>
									</div>
									<div id="filters-view-options" style={{ zIndex: 101 }}>
										<DrawerMultiSelectBox
											isOpen={openMultiSelectMenus[select.name]}
											key={i}
											multiSelectComponent={MultiSelectComponent.CurrentSelected}
											name={`${select.name}`}
											options={select.options}
											isLoading={select.isLoading}
											isSingleSelect={false}
											control={control}
											register={register}
											onChange={(newValue: string[], filterName: string) => {
												safeSetValue(filterName, newValue);
												setValue(`${FLOATING_OPTIONS_BOX}${filterName}`, newValue);
												if (currentSelectField) {
													setOptionsInSelector(filterSelectedValuesFromOptions(currentSelectField?.options, newValue));
												}
												setSelectionDirty(true);
											}}
											clickEventPage={clickEventPage}
										/>
									</div>
								</div>;
							}
							)}
							{popupCheckboxFields ? popupCheckboxFields?.map((field, i: number) =>
								<div className="drawer-header clickable flex-centered" key={i}>
									<CheckboxInput
										name={`${field.name}`}
										label={field.label}
										className={"field acu-checkbox"}
										tooltip={field?.tooltip}
										onChange={(newValue: boolean) => {
											if (field.name) {
												setValue(field.name, newValue);
												setSelectionDirty(true);
												toggleMenu();
											}
										}}
										register={register}
									/>
								</div>) : <></>
							}
							<div className="button-row flex-row">
								<input
									type="submit"
									className={classNames("ui primary small button", { disabled: !isSelectionDirty })}
									value="Apply"
									onClick={(e) => {
										e.preventDefault();
										setSelectionDirty(false);
										applyNewFilters();
									}}
									{...clickEvent(clickEventPage, GA_EVENT_ACTION.Filter, "Apply")}
								/>
							</div>
						</div>
					</Sidebar>
					<FloatingOptionsSelector
						options={optionsInSelector}
						name={`${FLOATING_OPTIONS_BOX}${currentSelectField?.name}`}
						isLoading={currentSelectField?.isLoading ?? true}
						control={control}
						onChange={(newValue: any) => {
							if (currentSelectField) {
								const currentValues = getValues(currentSelectField.name);
								const allSelectedValues = _.union(currentValues, newValue) as string[];
								safeSetValue(currentSelectField.name, allSelectedValues);
								setOptionsInSelector(filterSelectedValuesFromOptions(currentSelectField?.options, allSelectedValues));
								setSelectionDirty(true);
							}
						}}
						active={!!currentSelectField && isOpen}
						optionsWindow={optionsWindow ?? 0}
					/>
				</div>
			</TriggerOnClickOutside>
			<div className="menu-trigger">
				<div
					onClick={(e) => {
						e.stopPropagation();
						setIsOpen(!isOpen);
					}}
					className={`filters-icon-container ${filtersCount > 0 && "--selected"}`}
				>
					<FilterIcon />
					{filtersCount > 0 && <div className={classNames("ui primary clickable floating mini circular label",
						{ transparent: filtersCount === 0 })}
						onClick={(e) => {
						 e.stopPropagation();
						 setIsOpen(!isOpen);
						}}
					>
						{filtersCount}
					</div>}
				</div>
			</div >
		</div >
	);
};
export default FilterDrawer;

const sideArrow = (isActive: boolean) => {
	return (
		isActive ? <i aria-hidden="true" className="chevron down icon small" /> :
			<i aria-hidden="true" className="chevron right ui icon small" />
	);
};

// tslint:disable-next-line: variable-name
const FloatingOptionsSelector = (props: {
	options: any;
	name: string;
	isLoading: boolean;
	control: any;
	onChange: (newValue: string[]) => void;
	active: boolean;
	optionsWindow: number;
}) => {
	const { name, options, isLoading, onChange, control, optionsWindow, active } = { ...props };
	return (
		<div className={classNames("filters-select", { active })} style={{
			top: optionsWindow !== undefined ? `calc(0.5rem + (${optionsWindow} * 50px))` : undefined,
			transition: "all 0.5s"
		}} >
			{
				<DrawerMultiSelectBox
					multiSelectComponent={MultiSelectComponent.SelectOptions}
					name={name}
					options={options}
					placeholder={true}
					isLoading={isLoading}
					isSingleSelect={false}
					isClearable={false}
					control={control}
					onChange={onChange}
				/>
			}
		</div>
	);
};
