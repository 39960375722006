import BaseStore from "./base-store";
import { observable, action } from "mobx";
import {
	IDashboardSSOOrg,
} from "@acumen/dashboard-common";
import { SSOApiClient } from "v2/services/crud/sso-api-client";

export interface ISSOState {
	ssoOrg: IDashboardSSOOrg;
	jwtToken: string;
}

export default class SSOStore extends BaseStore<ISSOState> {
	private readonly client: SSOApiClient = new SSOApiClient();

	@observable
	public ssoOrg: IDashboardSSOOrg | undefined = undefined;

	@observable
	public token: string | undefined = undefined;

	@action.bound
	async fetchSSOOrg(email: string) {
		const org = await this.client.fetchSSOOrg(email);
		if (!org) {
			this.ssoOrg = undefined;
			return;
		}

		this.ssoOrg = org;
		return this.ssoOrg;
	}

	@action.bound
	async exchangeOktaToken(customerId: string, email: string, oktaToken: string) {
		const jwt = await this.client.oktaTokenExchange(customerId, email, oktaToken);
		if (!jwt) {
			this.token = undefined;
			return;
		}

		this.token = jwt;
		return this.token;
	}
}
