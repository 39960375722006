export const download = (localFileName: string, url: string) => {
	const a = document.createElement("a");
	a.download = localFileName;
	a.href = url;
	const clickEvt = new MouseEvent("click", {
		view: window,
		bubbles: true,
		cancelable: true,
	});
	a.dispatchEvent(clickEvt);
	a.remove();
};
