import { round } from "@acumen/common";
import {
	AcumenMetricGroupType, AcumenPullRequestStatus, AcumenPullRequestStatusWithWorkStart,
	AcumenTaskStatusGroup, DashboardTeamReportMetric,
	DashboardTeamsReportExpandOption, IDashboardAcumenMetricDataResponse,
	IDashboardTeamsReport, MetricInterval, taskGroupToStatusMapping, TUPLE_SEPARATOR
} from "@acumen/dashboard-common";
import { action, observable } from "mobx";
import {
	ChartSeriesData, prepareCategoryHourSeriesDataByDate, prepareCategorySeriesDataByDate
} from "../pages/my-team/analytics/charts/charts";
import { HoverSelectionPart } from "../pages/org-analytics/particles";
import { TeamsReportApiClient, TEAMS_REPORT_ROUTE } from "../services/crud/teams-report-api-client";
import { FetchLatestRequest } from "../../services/fetch-helpers";
import apiContextProvider from "../../services/api-context-provider";
import BaseStore from "./base-store";

export const prCycleDatesArray = "prCycleDatesArray";
export const devStatsDatesArray = "devStatsDatesArray";

const RESOLVED_STATUS_ISSUE_FILTER = `acumen_status_type=${taskGroupToStatusMapping[AcumenTaskStatusGroup.Done].join(TUPLE_SEPARATOR)}`;

const VELOCITY_METRICS = [
	DashboardTeamReportMetric.PRCycleWorkInProgressHours,
	DashboardTeamReportMetric.PRCycleAwaitingReviewHours,
	DashboardTeamReportMetric.PRCycleInReviewHours,
	DashboardTeamReportMetric.PRCycleReviewedHours,
	DashboardTeamReportMetric.PRCycleTotalTimeHours,
	DashboardTeamReportMetric.LeadTimeHours,
	DashboardTeamReportMetric.MergedPRsCount,
	DashboardTeamReportMetric.MergedPRFrequencyDay
];

const OUTPUT_METRICS = [
	DashboardTeamReportMetric.CompletedTicketsCount,
	DashboardTeamReportMetric.PRLinkedToTasksOutOfPRs,
	DashboardTeamReportMetric.PRsReviewedCount,
	DashboardTeamReportMetric.MergedPRsCount
];

const QUALITY_METRICS = [
	DashboardTeamReportMetric.MTTR,
	DashboardTeamReportMetric.BugsFixedToBugsOpenedRate,
	DashboardTeamReportMetric.AveragePRSizeLOC,
	DashboardTeamReportMetric.BugsCount
];

const DEV_STATS_METRICS = [
	DashboardTeamReportMetric.ActiveDaysCount,
	DashboardTeamReportMetric.CompletedTicketsCount,
	DashboardTeamReportMetric.MergedPRSizeLOCSum,
	DashboardTeamReportMetric.MergedPRsCount,
	DashboardTeamReportMetric.OpenPRsCount,
	DashboardTeamReportMetric.PRsReviewedCount
];

const DEV_STATS_PLUS_METRICS = [
	DashboardTeamReportMetric.VelocityStoryPointsCompleted,
	DashboardTeamReportMetric.VelocityEstimatedTimeCompleted,
	DashboardTeamReportMetric.NumberOfTasksCompletedPriorityHighest,
	DashboardTeamReportMetric.NumberOfTasksCompletedPriorityHigh,
	DashboardTeamReportMetric.NumberOfTasksCompletedPriorityNormal,
	DashboardTeamReportMetric.NumberOfTasksCompletedPriorityLowAndLowest,
	DashboardTeamReportMetric.AveragePRSizeLOC,
	DashboardTeamReportMetric.CompletedTicketsCount,
	DashboardTeamReportMetric.PRsReviewedCount,
	DashboardTeamReportMetric.MergedPRsCount,
	DashboardTeamReportMetric.PRCycleWorkInProgressHours,
	DashboardTeamReportMetric.PRCycleAwaitingReviewHours,
	DashboardTeamReportMetric.PRCycleInReviewHours,
	DashboardTeamReportMetric.PRCycleReviewedHours,
	DashboardTeamReportMetric.PRCycleTotalTimeHours,
	DashboardTeamReportMetric.MergedPRsCount,
];

export interface IPRCycleTimeChartData {
	average: ChartSeriesData;
	total: ChartSeriesData;
}

export interface IFilterOptions {
	gitRepositoryIds?: string[];
	projectIds?: string[];
}

export default class NewTeamsReportStore extends BaseStore<{}> {
	private readonly apiClient: TeamsReportApiClient = new TeamsReportApiClient(apiContextProvider);

	@observable
	public teamsReportVelocityData: IDashboardTeamsReport | undefined = undefined;

	@observable
	public teamsReportOutputData: IDashboardTeamsReport | undefined = undefined;

	@observable
	public teamsReportQualityData: IDashboardTeamsReport | undefined = undefined;

	@observable
	public teamsReportDevStatsData: IDashboardTeamsReport | undefined = undefined;

	@observable
	private gitHubPullRequestMergedCount: IDashboardAcumenMetricDataResponse | undefined = undefined;

	@observable
	public prCycleChartsData = new Map<HoverSelectionPart | typeof prCycleDatesArray, number[]>();

	@observable
	public prCycleChartsTotalData = new Map<HoverSelectionPart | typeof prCycleDatesArray, number[]>();

	@observable
	public devStatsChartsData = new Map<HoverSelectionPart | typeof devStatsDatesArray, number[]>();

	fetchLatestsVelocityPrCount = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, void>(AcumenMetricGroupType.GitHubPullRequestCount);

	@action.bound
	public getVelocityTrendCardCharts = async (
		startTime: Date,
		endTime: Date,
		selectedTeamIds: string[],
		boardInterval: MetricInterval,
		timezone: string,
		filters?: IFilterOptions,
	) => {
		const gitDimensions = this.createDimensions(selectedTeamIds, filters?.gitRepositoryIds);

		// tslint:disable-next-line: no-floating-promises
		this.fetchLatestsVelocityPrCount.fetchLatest(this.safeFetchMetric(AcumenMetricGroupType.GitHubPullRequestCount, "merged",
			boardInterval, timezone, startTime, endTime, gitDimensions)).then(async res => {
				if (!res) {
					return;
				}
				this.gitHubPullRequestMergedCount = res.data;
				const [prCycleWIP, prCycleAwaitingReview, prCycleInReview, prCycleReviewed] = await Promise.all([
					this.mergedPRCycleTimeByState(startTime, endTime,
						[AcumenPullRequestStatusWithWorkStart.Open, AcumenPullRequestStatusWithWorkStart.WorkStart],
						timezone, boardInterval, gitDimensions),
					this.mergedPRCycleTimeByState(startTime, endTime,
						[AcumenPullRequestStatusWithWorkStart.AwaitingReview],
						timezone, boardInterval, gitDimensions),
					this.mergedPRCycleTimeByState(startTime, endTime,
						[AcumenPullRequestStatusWithWorkStart.InReview],
						timezone, boardInterval, gitDimensions),
					this.mergedPRCycleTimeByState(startTime, endTime,
						[AcumenPullRequestStatusWithWorkStart.Reviewed],
						timezone, boardInterval, gitDimensions),
				]);
				this.prCycleChartsData.set(DashboardTeamReportMetric.PRCycleWorkInProgressHours, prCycleWIP?.average?.map(a => a[1]) ?? []);
				this.prCycleChartsData.set(DashboardTeamReportMetric.PRCycleAwaitingReviewHours, prCycleAwaitingReview?.average?.map(a => a[1]) ?? []);
				this.prCycleChartsData.set(DashboardTeamReportMetric.PRCycleInReviewHours, prCycleInReview?.average?.map(a => a[1]) ?? []);
				this.prCycleChartsData.set(DashboardTeamReportMetric.PRCycleReviewedHours, prCycleReviewed?.average?.map(a => a[1]) ?? []);
				this.prCycleChartsData.set(prCycleDatesArray, prCycleWIP?.average?.slice().map(a => (typeof a[0] === "number" ? a[0] : 0)) ?? []);

				this.prCycleChartsTotalData.set(DashboardTeamReportMetric.PRCycleWorkInProgressHours, prCycleWIP?.total?.map(a => a[1]) ?? []);
				this.prCycleChartsTotalData.set(DashboardTeamReportMetric.PRCycleAwaitingReviewHours, prCycleAwaitingReview?.total?.map(a => a[1]) ?? []);
				this.prCycleChartsTotalData.set(DashboardTeamReportMetric.PRCycleInReviewHours, prCycleInReview?.total?.map(a => a[1]) ?? []);
				this.prCycleChartsTotalData.set(DashboardTeamReportMetric.PRCycleReviewedHours, prCycleReviewed?.total?.map(a => a[1]) ?? []);
				this.prCycleChartsTotalData.set(prCycleDatesArray, prCycleWIP?.total?.slice().map(a => (typeof a[0] === "number" ? a[0] : 0)) ?? []);
			});
	}

	@action.bound
	public getDevStatsTrendCardCharts = async (
		startTime: Date,
		endTime: Date,
		selectedTeamIds: string[],
		boardInterval: MetricInterval,
		timezone: string,
		filters?: IFilterOptions
	) => {
		const jiraDimensions = this.createDimensions(selectedTeamIds, undefined, filters?.projectIds);
		const gitDimensions = this.createDimensions(selectedTeamIds, filters?.gitRepositoryIds);

		const [doneTicketsCount, openPRsCount, mergedPRsCount, mergedPRSizeLOCSum, activeDays, reviewedPRsCount] = await Promise.all([
			this.safeFetchMetric(AcumenMetricGroupType.JiraIssueCount, "cycle_end",
				boardInterval, timezone, startTime, endTime, [
				...jiraDimensions,
				RESOLVED_STATUS_ISSUE_FILTER,
			]),
			this.safeFetchMetric(AcumenMetricGroupType.GitHubPullRequestCount, "opened",
				boardInterval, timezone, startTime, endTime, gitDimensions),
			this.safeFetchMetric(AcumenMetricGroupType.GitHubPullRequestCount, "merged",
				boardInterval, timezone, startTime, endTime, gitDimensions),
			this.safeFetchMetric(AcumenMetricGroupType.GitHubPullRequestLinesOfCodeChangedSum, "merged",
				boardInterval, timezone, startTime, endTime, gitDimensions),
			this.safeFetchMetric(AcumenMetricGroupType.AcumenEventsCount, "event_time",
				boardInterval, timezone, startTime, endTime),
			this.safeFetchMetric(AcumenMetricGroupType.GitHubPullRequestsReviewedCount, "review_date",
				boardInterval, timezone, startTime, endTime, gitDimensions),
		]);

		if (!doneTicketsCount || !doneTicketsCount.data ||
			!openPRsCount || !openPRsCount.data ||
			!mergedPRsCount || !mergedPRsCount.data ||
			!mergedPRSizeLOCSum || !mergedPRSizeLOCSum.data ||
			!activeDays || !activeDays.data ||
			!reviewedPRsCount || !reviewedPRsCount.data) {
			return;
		}
		const doneTicketsData = prepareCategorySeriesDataByDate(doneTicketsCount.data, timezone);
		this.devStatsChartsData.set(DashboardTeamReportMetric.CompletedTicketsCount,
			doneTicketsData.map(a => a[1]) ?? []);
		this.devStatsChartsData.set(DashboardTeamReportMetric.OpenPRsCount,
			prepareCategorySeriesDataByDate(openPRsCount.data, timezone).map(a => a[1]) ?? []);
		this.devStatsChartsData.set(DashboardTeamReportMetric.MergedPRsCount,
			prepareCategorySeriesDataByDate(mergedPRsCount.data, timezone).map(a => a[1]) ?? []);
		this.devStatsChartsData.set(DashboardTeamReportMetric.MergedPRSizeLOCSum,
			prepareCategorySeriesDataByDate(mergedPRSizeLOCSum.data, timezone).map(a => a[1]) ?? []);
		this.devStatsChartsData.set(DashboardTeamReportMetric.ActiveDaysCount,
			prepareCategorySeriesDataByDate(activeDays.data, timezone).map(a => a[1]) ?? []);
		this.devStatsChartsData.set(DashboardTeamReportMetric.PRsReviewedCount,
			prepareCategorySeriesDataByDate(reviewedPRsCount.data, timezone).map(a => a[1]) ?? []);
		this.devStatsChartsData.set(devStatsDatesArray, doneTicketsData
			.map(a => (typeof a[0] === "number" ? a[0] : 0)) ?? []);
	}

	fetchLatestTeamsReportVelocityData = new FetchLatestRequest<IDashboardTeamsReport, void>(TEAMS_REPORT_ROUTE);
	@action.bound
	public getTeamsReportVelocityData = async (startDate: Date, endDate: Date, selectedTeamIds: string[], filters?: IFilterOptions) => {
		this.teamsReportVelocityData = undefined;

		const result = await this.fetchLatestTeamsReportVelocityData.fetchLatest(this.apiClient.fetchReport(
			startDate, endDate, selectedTeamIds, filters?.gitRepositoryIds,
			filters?.projectIds, undefined, VELOCITY_METRICS
			));
		if (result) {
			const { data } = result;
			this.teamsReportVelocityData = data;
		}
	}

	fetchLatestTeamsReportOutputData = new FetchLatestRequest<IDashboardTeamsReport, void>(TEAMS_REPORT_ROUTE);
	@action.bound
	public getTeamsReportOutputData = async (startDate: Date, endDate: Date, selectedTeamIds: string[], filters?: IFilterOptions) => {
		this.teamsReportOutputData = undefined;

		const result = await this.fetchLatestTeamsReportVelocityData.fetchLatest(this.apiClient.fetchReport(
			startDate, endDate, selectedTeamIds, filters?.gitRepositoryIds,
			filters?.projectIds, undefined, OUTPUT_METRICS
		));
		if (result) {
			const { data } = result;
			this.teamsReportOutputData = data;
		}
	}

	fetchLatestTeamsReportQualityData = new FetchLatestRequest<IDashboardTeamsReport, void>(TEAMS_REPORT_ROUTE);
	@action.bound
	public getTeamsReportQualityData = async (startDate: Date, endDate: Date, selectedTeamIds: string[], filters?: IFilterOptions) => {
		this.teamsReportQualityData = undefined;

		const result = await this.fetchLatestTeamsReportVelocityData.fetchLatest(this.apiClient.fetchReport(
			startDate, endDate, selectedTeamIds, filters?.gitRepositoryIds,
			filters?.projectIds, undefined, QUALITY_METRICS
		));
		if (result) {
			const { data } = result;
			this.teamsReportQualityData = data;
		}
	}

	fetchLatestTeamsReportDevStatsData = new FetchLatestRequest<IDashboardTeamsReport, void>(TEAMS_REPORT_ROUTE);
	@action.bound
	public getTeamsReportDevStatsData = async (startDate: Date, endDate: Date, selectedTeamIds: string[], filters?: IFilterOptions,
		shouldExtendWithMoreMetrics?: boolean) => {
		this.teamsReportDevStatsData = undefined;
		const result = await this.fetchLatestTeamsReportVelocityData.fetchLatest(this.apiClient.fetchReport(
			startDate, endDate, selectedTeamIds, filters?.gitRepositoryIds,
			filters?.projectIds, [DashboardTeamsReportExpandOption.DevStats],
			shouldExtendWithMoreMetrics === true ? [...DEV_STATS_PLUS_METRICS, ...DEV_STATS_METRICS] : DEV_STATS_METRICS
		));
		if (result) {
			const { data } = result;
			this.teamsReportDevStatsData = data;
		}
	}
	private async mergedPRCycleTimeByState(
		startDate: Date, endDate: Date, prStatusInCycle: AcumenPullRequestStatusWithWorkStart[], timezone: string,
		interval: MetricInterval, dimensions: string[]
	): Promise<IPRCycleTimeChartData | undefined> {

		const gitPRCycleSumDimensions = [...dimensions];
		if (prStatusInCycle.length > 0) {
			gitPRCycleSumDimensions.push(`pull_request_status=${prStatusInCycle.join(TUPLE_SEPARATOR)}`);
		}

		const prCountResponse = await this.safeFetchMetric(AcumenMetricGroupType.GitHubPullRequestCycleSum, "merged",
			interval, timezone, startDate, endDate, gitPRCycleSumDimensions);

		if (!this.gitHubPullRequestMergedCount || !prCountResponse || !prCountResponse.data) {
			return undefined;
		}

		const prCountMetricValues = Object.values(this.gitHubPullRequestMergedCount.values);
		const prCountValues = prCountMetricValues[0];

		const average = prepareCategorySeriesDataByDate(prCountResponse.data, timezone, ([category, secondsInDate], categoryString) => {
			const countOfPRsInDate = prCountValues[categoryString];

			if (!countOfPRsInDate) {
				return [category, 0];
			}
			if (!secondsInDate) {
				return [category, 0];
			}

			const hoursInDate = secondsInDate / 3600;
			const ratio = hoursInDate / countOfPRsInDate;
			return [category, round(ratio)];
		});

		const total = prepareCategoryHourSeriesDataByDate(prCountResponse.data, timezone);
		return {
			average,
			total
		};
	}

	private createDimensions(teamIds?: string[], repositoryIds?: string[], projectIds?: string[]) {
		const dimensions: string[] = [];
		if (teamIds && teamIds.length > 0) {
			dimensions.push(`team_id=${teamIds.join(TUPLE_SEPARATOR)}`);
		}
		const normalizedRepositoryIds = this.normalizeIdsDimension(repositoryIds);
		if (normalizedRepositoryIds && normalizedRepositoryIds.length > 0) {
			dimensions.push(`repository_id=${normalizedRepositoryIds.join(TUPLE_SEPARATOR)}`);
		}
		const normalizedProjectIds = this.normalizeIdsDimension(projectIds);
		if (normalizedProjectIds && normalizedProjectIds.length > 0) {
			dimensions.push(`project_id=${normalizedProjectIds.join(TUPLE_SEPARATOR)}`);
		}
		return dimensions;
	}

	private normalizeIdsDimension(idAndTypes?: string[]): string[] | undefined {
		if (!idAndTypes || idAndTypes.length === 0) {
			return undefined;
		}

		return idAndTypes.map(idAndTypeTuple => {
			const [id] = idAndTypeTuple.split(TUPLE_SEPARATOR);
			return id;
		});
	}

	private async safeFetchMetric(metric: AcumenMetricGroupType, label: string,
		interval: MetricInterval, timezone: string, startTime: Date, endTime: Date,
		dimensions?: string[], groupBy?: string) {

		return this.apiClient.fetchMetric(metric, label, interval,
			timezone, startTime, endTime, dimensions, groupBy);
	}
}

export const REPORT_METRIC_TO_UNITS: {
	[type in DashboardTeamReportMetric | DashboardTeamReportMetric]: string | undefined;
} = {
	[DashboardTeamReportMetric.LeadTimeHours]: "hours",
	[DashboardTeamReportMetric.PRCycleTotalTimeHours]: "hours",
	[DashboardTeamReportMetric.MergedPRsCount]: undefined,
	[DashboardTeamReportMetric.MergedPRFrequencyDay]: "/ day",
	[DashboardTeamReportMetric.PRCycleWorkInProgressHours]: "hours",
	[DashboardTeamReportMetric.PRCycleAwaitingReviewHours]: "hours",
	[DashboardTeamReportMetric.PRCycleInReviewHours]: "hours",
	[DashboardTeamReportMetric.PRCycleReviewedHours]: "hours",
	[DashboardTeamReportMetric.BugsCount]: undefined,
	[DashboardTeamReportMetric.MTTR]: "hours",
	[DashboardTeamReportMetric.BugsFixedToBugsOpenedRate]: "%",
	[DashboardTeamReportMetric.AveragePRSizeLOC]: "lines",
	[DashboardTeamReportMetric.CompletedTicketsCount]: undefined,
	[DashboardTeamReportMetric.PRLinkedToTasksOutOfPRs]: "%",
	[DashboardTeamReportMetric.OpenPRsCount]: undefined,
	[DashboardTeamReportMetric.MergedPRsCount]: undefined,
	[DashboardTeamReportMetric.ActiveDaysCount]: "days",
	[DashboardTeamReportMetric.MergedPRSizeLOCSum]: "lines",
	[DashboardTeamReportMetric.PRsReviewedCount]: undefined,
	[DashboardTeamReportMetric.VelocityStoryPointsCompleted]: "sp",
	[DashboardTeamReportMetric.VelocityEstimatedTimeCompleted]: "hours",
	[DashboardTeamReportMetric.NumberOfClosedUnmergedPRs]: "undefined",
	[DashboardTeamReportMetric.NumberOfTasksCompletedPriorityHighest]: "undefined",
	[DashboardTeamReportMetric.NumberOfTasksCompletedPriorityHigh]: "undefined",
	[DashboardTeamReportMetric.NumberOfTasksCompletedPriorityNormal]: "undefined",
	[DashboardTeamReportMetric.NumberOfTasksCompletedPriorityLowAndLowest]: "undefined"
};

export interface IChartInfoEntry {
	name: AcumenPullRequestStatus;
	color?: string;
	order: number;
	// data: Array<("" | number | null)> | Array<[(string), (string | number)]>;
	data?: Array<("" | Array<(number | null)>)>;
	marker?: {
		symbol?: string;
	};
}
