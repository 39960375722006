import React from "react";
import "./style.scss";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";
import classNames from "classnames";
import { IInput } from "./text-input";

interface ITimeInput extends Omit<IInput, "time"> {
	minuteIncrement?: number;
}

const DEFAULT_MINUTE_INCREMENT: number = 10;

// tslint:disable-next-line: variable-name
export const TimeInput = ({ value, label, onChange, error, minuteIncrement = DEFAULT_MINUTE_INCREMENT}: ITimeInput) => {
	if (minuteIncrement < 1) {
		minuteIncrement = DEFAULT_MINUTE_INCREMENT;
	}

	return (
		<div className="acu-input">
			{label && <div className="acu-input-label">{label}</div>}
			<Flatpickr
				value={value}
				onChange={(datesArray, timeString, fp) => {
					const date = fp.latestSelectedDateObj;
					if (onChange) {
						if (date) {
							const mins = date.getMinutes();
							if (mins % minuteIncrement) {
								date.setMinutes(minuteIncrement * Math.round(date.getMinutes() / minuteIncrement));
								timeString = `${date.getHours()}:${date.getMinutes()}`;
								fp.setDate(date, false);
							}
						}
						onChange(timeString);
					}
				}}
				className={classNames("acu-text-input", "flatpickr-input", error ? "--error" : "")}
				options={
					{
						dateFormat: "H:i",
						enableTime: true,
						noCalendar: true,
						minuteIncrement,
						clickOpens: true,
						time_24hr: true
					}
				}
			/>
		</div>
	);
};

export default TimeInput;
