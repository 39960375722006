import React from "react";
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts";
import ChartCard from "../../../components/chart-card";
import { GA_EVENT_CATEGORY } from "../../../analytics-events";
import LoadingIndicator from "../../../components/loader";

interface IRetroPieChart {
	title: string;
	description: string;
	chartSeries?: Array<Partial<Highcharts.Point>>;
	colorScheme?: string[];
	supportExpand?: boolean;
}

// tslint:disable-next-line: variable-name
const RetroPieChart = (props: IRetroPieChart) => {
	const { title, description, chartSeries, colorScheme } = { ...props };

	const options: Highcharts.Options = {
		chart: {
			plotShadow: false,
			type: "pie",
		},
		title: {
			text: ""
		},
		credits: {
			enabled: false
		},
		tooltip: {
			pointFormat: "<b>{point.tooltip}</b>",
		},
		accessibility: {
			point: {
				valueSuffix: "%"
			}
		},
		plotOptions: {
			pie: {
				center: ["50%", "100px"],
				allowPointSelect: true,
				cursor: "pointer"
			}
		},
		legend: {
			alignColumns: false,
			symbolRadius: 0,
			itemMarginTop: 10,
		},
		series: [{
			type: "pie",
			data: chartSeries ?? [],
			size: "175px",
			innerSize: "75%",
			showInLegend: true,
			dataLabels: {
				enabled: true,
				format: "{point.percentage:.1f}%",
				alignTo: "connectors",
				crop: false,
				distance: 8,
				padding: -10,
				overflow: "allow"
			},
			visible: chartSeries && chartSeries.length > 0
		}]
	};

	if (colorScheme) {
		options.colors = colorScheme;
	}

	return (
		<ChartCard
			chartData={{
				title,
				description
			}}
			supportExpand={props.supportExpand}
			eventCategory={GA_EVENT_CATEGORY.SprintReport}
		>
			<LoadingIndicator isActive={!chartSeries}>
				<HighchartsReact
					highcharts={Highcharts}
					options={options}
				/>
			</LoadingIndicator>
		</ChartCard>
	);
};
export default RetroPieChart;
