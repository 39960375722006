import React from "react";
import { observer } from "mobx-react";

import { DashboardRetroReportTaskData, IDashboardTaskStatusChangeResponse } from "@acumen/dashboard-common";
import { TaskStatus } from "../../../components/formatters";
import { useStores } from "../../../mobx-stores";

interface TaskStartStatusProps {
	task: DashboardRetroReportTaskData;
	isExtended: boolean;
	statusDiff?: IDashboardTaskStatusChangeResponse;
}

const TaskStartStatus = (props: TaskStartStatusProps) => {
	const { task, isExtended, statusDiff } = props;
	const { taskStatusStore } = useStores();
	const { getStatus, statusIdToStatus } = taskStatusStore;
	const taskStatus = isExtended && statusDiff ? statusDiff[task.entityId] : undefined;
	const startStatus = taskStatus?.startStatus ? statusIdToStatus[taskStatus?.startStatus] : null;
	const endStatus = taskStatus?.endStatus ? statusIdToStatus[taskStatus?.endStatus] : null;

	return (!getStatus || !endStatus) ? null : (
		<div className="status-container spaced">
			<TaskStatus
				internalDashboardStatus={startStatus}
				taskStatus={startStatus?.acumenStatus ?? null}
				internalStatus="Created"
				hasTooltip={startStatus?.customerStatusName?.length ?
					startStatus.customerStatusName?.length > 21 : false}
				showIcon={false}
			/>
		</div>
	);
};

export default observer(TaskStartStatus);
