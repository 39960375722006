import { IDeveloperBasicDetails } from "@acumen/dashboard-common";
import React, { CSSProperties, useEffect, useMemo, useRef, useState } from "react";

import Tooltip from "../../../../components/tooltip/tooltip";
import "./data-contributor-profile-picture.scss";

const PROFILE_PICTURE_FETCH_FAIL_TIMEOUT_MS = 3000;

export interface DataContributorProfilePictureProps {
	contributor?: IDeveloperBasicDetails;
	text?: string;
	size?: number;
	tooltip?: string;
	style?: CSSProperties;
}

export const DataContributorProfilePicture = (props: DataContributorProfilePictureProps) => {
	const { contributor, text, size = 25, tooltip, style } = props;
	const name = contributor?.displayName ?? "";
	const pictureUrl = contributor?.avatarUrl ?? "";
	const firstLetter = name[0] ?? "";
	const secondLetter = name[1] ?? "";
	const fallbackText = `${firstLetter}${secondLetter}`.toUpperCase();
	const [shouldFallbackToText, setShouldFallbackToText] = useState<boolean>(false);
	const imgRef = useRef<HTMLImageElement>(null);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (imgRef.current && !imgRef.current.complete) {
				setShouldFallbackToText(true);
			}
		}, PROFILE_PICTURE_FETCH_FAIL_TIMEOUT_MS);

		return () => clearTimeout(timer);
	}, [name]);

	const resolvedStyles = useMemo(() => ({
		width: size,
		height: size,
		minWidth: size,
		maxWidth: size,
		minHeight: size,
		maxHeight: size,
		...style,
	}), [size, style]);

	return (
		<Tooltip text={`${tooltip ?? name}; pictureUrl=${pictureUrl}; shouldFallbackToText=${JSON.stringify(shouldFallbackToText)}`}>
			{!shouldFallbackToText && pictureUrl ? (
				<img
					ref={imgRef}
					className="data-contributor-profile-picture"
					src={pictureUrl}
					alt={text ?? fallbackText ?? ""}
					style={resolvedStyles}
				/>
			) : (
				<div className="data-contributor-profile-picture" style={resolvedStyles}>
					{text ?? fallbackText}
				</div>
			)}
		</Tooltip>
	);
};
