import {
	ConfigParamKeyType, CustomizableConfigInputType, CustomizableConfiguration, CustomizableConfigurationCategories, IDashboardCustomizableSelectStaticConfig, SINGLE_CONFIG_KEY,
} from "@acumen/dashboard-common";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useStores } from "../../mobx-stores";
import {
	customizableConfigurationFromString, customizationValue,
	getCustomizationCategoryTitle, IConfigFields, IConfigParamsTypes, IDashboardCustomizableValue
} from "../../mobx-stores/customization-store";
import { Select as SelectInput } from "../../components/select";
import { clickEvent, GA_EVENT_ACTION, GA_EVENT_CATEGORY } from "../../analytics-events";
import _ from "lodash";
import {
	ConfigurationsAccessType,
	getFieldDescription,
	getFieldName,
	getFriendlyNamesRecord,
	IConfigurationForm,
	IConfigurationsCard
} from ".";
import { CheckboxInputInputField, DateInput, SingleFreeInputField } from "./customization-form";
import Loader from "../loader/loader";
import { observer } from "mobx-react";
import ChipMultiselect, { ChipMultiselectOption } from "../chip-multiselect/chip-multiselect";
import { Divider, Popup } from "semantic-ui-react";
import infoIcon from "../../components/svg-assets/info-icon.svg";

const VM_REFRESH_MSG = "Some changes would take approximately 30 minutes to propagate.";
const FILL_GROUP_MSG = "You must fill in all of the fields.";

// tslint:disable-next-line: variable-name
const CustomizationFields = observer((props: IConfigurationsCard & {
	onRender?: () => void
	onChange: (values: Partial<IDashboardCustomizableValue>) => void;
	onInitialConfigFieldsValues?: (configFields: IConfigFields) => void;
	showLoadingIndicator?: boolean;
}) => {
	const {
		customizationStore: {
			getConfigurationByCategory,
			userContexts
		}
	} = useStores();

	const {
		category, teamId, entityType, onRender, onChange,
		showLoadingIndicator = false, configurationsAccessType = ConfigurationsAccessType.acumen,
		showTitle = true, onInitialConfigFieldsValues
	} = { ...props };
	const [configFields, setConfigFields] = useState<undefined | IConfigFields>(undefined);
	const [isLoadingPage, setIsLoadingPage] = useState<boolean>(false);

	useEffect(() => {
		const teamContext = configurationsAccessType === ConfigurationsAccessType.external ? undefined : teamId;
		if (configurationsAccessType === ConfigurationsAccessType.external ||
			userContexts(teamId).find(ctx => ctx === entityType)) {
			setIsLoadingPage(true);
			// tslint:disable-next-line: no-floating-promises
			getConfigurationByCategory(category, teamContext).then(res => {
				if (res) {
					setConfigFields(res);
					if (onRender) {
						onRender();
					}
					if (onInitialConfigFieldsValues) {
						onInitialConfigFieldsValues(res);
					}
				}
				setIsLoadingPage(false);
			});
		}
		return () => setConfigFields(undefined);
	}, [teamId]);

	return (
		<Loader isActive={showLoadingIndicator && isLoadingPage} local={true}>
			{configFields ?
				<ConfigurationFields
					teamId={teamId}
					category={category}
					entityType={entityType}
					configFields={configFields}
					onChange={onChange}
					refreshPage={() => {
						return;
					}}
					configurationsAccessType={configurationsAccessType}
					showTitle={showTitle}
				/> : <></>
			}
		</Loader>
	);
});
export default CustomizationFields;

// tslint:disable-next-line: variable-name
export const ConfigurationFields = (props: IConfigurationForm & {
	onChange: (values: Partial<IDashboardCustomizableValue>) => void;
}) => {
	const {
		customizationStore: {
			getCategoriesCurrentValues,
		}
	} = useStores();

	const { configFields, category, onChange, configurationsAccessType, showTitle = true } = { ...props };
	const [changedFormFields, setChangedFormFields] = useState<Partial<IDashboardCustomizableValue>>({});
	const [currentValues, setCurrentValues] = useState<Partial<IDashboardCustomizableValue>>({});
	const title = getCustomizationCategoryTitle[category];

	useEffect(() => {
		if (configFields) {
			setCurrentValues(getCategoriesCurrentValues(configFields));
		}
	}, []);

	const shouldRefreshPage = configFields ? Object.values(configFields).find((field: IDashboardCustomizableValue | undefined) => {
		return field && field.refreshPage;
	}) : false;

	const getCurrentValueOfField = (configField: IDashboardCustomizableValue) => {
		let current;
		if (configField.key === SINGLE_CONFIG_KEY) {
			const fieldChanges = _.clone(changedFormFields[configField.name]);
			if ((fieldChanges && fieldChanges[configField.key])) {
				current = fieldChanges[configField.key];
			} else {
				const fieldCurrent = currentValues[configField.name];
				current = (fieldCurrent) ? fieldCurrent[configField.key].value : undefined;
			}
		} else {
			const fieldName = configField.fieldName;
			if (!fieldName) {
				return;
			}
			if (changedFormFields[fieldName]) {
				const fieldChanges = _.clone(changedFormFields[fieldName]);
				if (fieldChanges) {
					current = fieldChanges[configField.key];
				}
			} else {
				const fieldChanges = currentValues[fieldName];
				if (fieldChanges && fieldChanges[configField.key]) {
					current = fieldChanges[configField.key].value;
				}
			}
		}
		return current;
	};

	const getConfigInputFieldByType = (
		_configField: IDashboardCustomizableValue, configCategory: CustomizableConfigurationCategories
	) => {

		// ACM-4806 TODO: This should be invisible config.
		if (_configField.name === CustomizableConfiguration.PlannedGracePeriod
			&& configCategory === CustomizableConfigurationCategories.GoRetro) {
			return;
		}

		const validName = customizableConfigurationFromString(_configField.name);
		if (!validName) {
			return;
		}
		const singleConfig = _configField.configParams[SINGLE_CONFIG_KEY];
		const inputType = singleConfig ?
			singleConfig.inputType :
			_configField.configParams.inputType;

		const configField: IDashboardCustomizableValue = singleConfig ?
			_configField :
			{
				..._configField,
				name: _configField.key,
				fieldName: _configField.name,
			};

		const onFieldChange = (selected: null | boolean | string | number | string[] | number[]) => {
			if (!configFields) {
				return;
			}
			if (configField.key === SINGLE_CONFIG_KEY) {
				const newValue: Partial<IDashboardCustomizableValue> = changedFormFields[validName] ?? {};
				newValue[configField.key] = selected;
				setChangedFormFields(Object.assign(changedFormFields, { [configField.name]: newValue }));
				onChange(changedFormFields);
			} else {
				const newValue = changedFormFields[validName] ? changedFormFields[validName] : currentValues[validName];
				newValue[configField.key] = selected;
				setChangedFormFields(Object.assign(changedFormFields, { [validName]: newValue }));
				const fieldsGroup = Object.values(configFields).find((field) => field && field.name === validName);

				if (fieldsGroup && validateFieldGroup(fieldsGroup)) {
					onChange(changedFormFields);
				}
			}
		};

		const current = getCurrentValueOfField(configField);

		switch (inputType) {
			case CustomizableConfigInputType.String:
				return SingleFreeInputField(configField, "text", onFieldChange, current);
			case CustomizableConfigInputType.MultiSelectStaticValues:
				return SelectStaticValuesInputField(configField, inputType, onFieldChange, current);
			case CustomizableConfigInputType.MultiSelectDynamicValues:
				return SelectDynamicValuesInputField(configField, inputType, onFieldChange, current);
			case CustomizableConfigInputType.Number:
				return SingleFreeInputField(configField, "number", onFieldChange, current);
			case CustomizableConfigInputType.SingleSelectStaticValues:
				return SelectStaticValuesInputField(configField, inputType, onFieldChange, current);
			case CustomizableConfigInputType.Date:
				return <DateInput
					value={current}
					configField={configField}
					onChange={onFieldChange}
				/>;
			case CustomizableConfigInputType.Boolean:
				return CheckboxInputInputField(configField, onFieldChange, current);
			default:
				return undefined;
		}
	};

	const getConfigInputField = (configField: IDashboardCustomizableValue) => {
		if (!configField.configParams[SINGLE_CONFIG_KEY]) {
			return getMultiFieldsInputType(configField, category);
		}
		return getConfigInputFieldByType(configField, category);
	};

	const getMultiFieldsInputType = (
		configField: IDashboardCustomizableValue, configCategory: CustomizableConfigurationCategories
	) => {
		const validFields: boolean = validateFieldGroup(configField);
		return (
			<div className="field-group">
				<h4 className="ui header acu-capitalize">{(configField.groupName)}
					<div className="small-text gray-text">
						{!validFields && FILL_GROUP_MSG}
					</div>
				</h4>
				{
					Object.entries(configField.configParams).map((([name, params], i) => {
						const _val = configField.currentValue;
						const value = _val ? Object.entries(_val).find(([key, ]) => key === name) : [];
						const current = {
							[name]: value as customizationValue
						};
						return (
							<div key={i}>
								{getConfigInputFieldByType({
									name: configField?.name,
									configParams: params as Partial<IConfigParamsTypes>,
									key: name as ConfigParamKeyType,
									currentValue: current
								}, configCategory)}
							</div>
						);
					}))
				}
			</div >
		);
	};

	const validateFieldGroup = (configField: IDashboardCustomizableValue) => {
		return !!(configField.configParams &&
			_.isEqual(Object.keys(changedFormFields[configField.name] ?? {}).sort(), Object.keys(configField.configParams).sort()));
	};

	return (
		configFields ?
			(
				<div className="configurations-form-fields-container">
					{showTitle &&
						<div className="sixteen wide column" style={{ height: "5rem" }}>
							<h2 className="card-header full-width acu-capitalize">
								{title}
								{shouldRefreshPage && configurationsAccessType !== ConfigurationsAccessType.external &&
									<div className="small-text gray-text">
										{VM_REFRESH_MSG}
									</div>
								}
							</h2>
						</div>
					}

					{configFields && Object.values(configFields)
						.filter(field => field !== undefined && (!field.configParams.single || field.configParams.single.position !== -1))
						.sort((a, b) => {
							if (!a.configParams.single || !b.configParams.single) {
								return 0;
							}

							return a.configParams.single.position < b.configParams.single.position ? -1 : 1;
						})
						.map((field, i: number) => {
							const configField = field && getConfigInputField(field);
							return (
								configField &&
								<div className={"config-field"} key={i} >
									{configField}
								</div>
							);
						})}
				</div>
			) : <></>);
};

// tslint:disable-next-line: variable-name
const SelectStaticValuesInputField = (configField: IDashboardCustomizableValue, inputType: CustomizableConfigInputType,
	onChange: (selected: string | number | string[] | number[]) => void, currentValue?: string | string[] | number) => {

	const isMultiSelect = inputType === CustomizableConfigInputType.MultiSelectStaticValues;
	const friendlyNames = getFriendlyNamesRecord(configField.name, configField.fieldName);
	const singleValue = configField.configParams[SINGLE_CONFIG_KEY];
	const possibleValues = singleValue ?
		(singleValue as IDashboardCustomizableSelectStaticConfig).possibleValues :
		(configField.configParams).possibleValues;
	const [_currentValue, setCurrentValue] = useState<any>(currentValue);

	useEffect(() => {
		setCurrentValue(currentValue);
	}, [currentValue]);

	const _onChange = (val: string | number | string[] | number[]) => {
		setCurrentValue(val);
		onChange(val);
	};

	return (
		<div className="form-control">
			<label className="configuration-name side-label flex-spaced">
				<span className="acu-capitalize">
					{getFieldName(configField)}
				</span>
			</label>

			<div className="input-field acu-capitalize" >
				<SelectInput
					required={true}
					multiselect={isMultiSelect}
					searchable={possibleValues!.length > 4 ?? false}
					options={possibleValues?.map((value: string) => ({
						value,
						key: value,
						text: friendlyNames ? friendlyNames(value) : value
					})) ?? []}
					onChange={_onChange}
					value={_.isArray(_currentValue) ? undefined : _currentValue}
					selected={_.isArray(_currentValue) ? [..._currentValue] : undefined}
					{...clickEvent(GA_EVENT_CATEGORY.Customization, GA_EVENT_ACTION.Select, configField.name)}
				/>
			</div>
		</div>
	);
};

const SelectDynamicValuesInputField = (
	configField: IDashboardCustomizableValue,
	inputType: CustomizableConfigInputType,
	onChange: (selected: string | number | string[] | number[]) => void,
	currentValue?: string | string[] | number
) => {
	const friendlyNames = getFriendlyNamesRecord(configField.name, configField.fieldName);
	const singleValue = configField.configParams[SINGLE_CONFIG_KEY];
	const possibleValues = singleValue ?
		(singleValue as IDashboardCustomizableSelectStaticConfig).possibleValues :
		(configField.configParams).possibleValues;
	const [_currentValue, setCurrentValue] = useState<any>(currentValue);

	useEffect(() => {
		setCurrentValue(currentValue);
	}, [currentValue]);

	const options = useMemo(() => possibleValues?.map((value: string) => ({
		value,
		key: value,
		label: (friendlyNames ? friendlyNames(value) : value) as React.ReactNode,
	})) ?? [], [possibleValues, friendlyNames]);
	const selectedOptions = useMemo(
		() => !_.isArray(_currentValue) ? [] : _currentValue
			.map((value: string) => options.find(option => option.value === value))
			.filter((option): option is ChipMultiselectOption<string> => !!option),
		[_currentValue, options]
	);

	const handleChange = useCallback((selection: Array<ChipMultiselectOption<string>>) => {
		const values = selection.map(option => option.value);
		setCurrentValue(values);
		onChange(values);
	}, []);

	const description = getFieldDescription(configField);

	return (
		<>
			<div className="form-control configuration-dynamic-multiselect-control">
				<label className="config-field-label">
					<span className="acu-capitalize">
						{getFieldName(configField)}
					</span>
					{description && (
						<Popup
							hoverable={true}
							wide={true}
							position="top center"
							content={description}
							trigger={
								<img src={infoIcon} className="info-icon" alt="" />
							}
						/>
					)}
				</label>

				<div className="input-field acu-capitalize" >
					<ChipMultiselect
						addOptionsLabel={configField.configParams[SINGLE_CONFIG_KEY]?.dropdownButtonText ?? "Add Option"}
						value={selectedOptions}
						options={options}
						staticValues={(configField.DEFAULT as any)?.[SINGLE_CONFIG_KEY]?.value ?? emptyArray}
						allowEmpty={false}
						onChange={handleChange}
					/>
				</div>
			</div>
			<Divider />
		</>
	);
};

const emptyArray: unknown[] = [];
