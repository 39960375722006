import { TaskEstimationMethod } from "@acumen/database-types";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { getShortEstimationMethodName } from "v2/helpers/formatting";
import { TeamDetailsSidePanel } from "v2/pages/workforce-health/components/team-details-side-panel/team-details-side-panel";
import { CustomTable } from "../../../../components/custom-table/custom-table";
import { CustomTableColumnProps, ResolvedCustomTableColumnProps } from "../../../../components/custom-table/types";
import { DevStatColumn, ESTIMATION_UNITS } from "../../../workforce-health/dev-stat-columns";
import { TeamViewModel } from "../../types";
import { TeamInfoCellContent } from "../team-info-cell-content/team-info-cell-content";
import { TeamStatCellContent } from "../team-stat-cell-content/team-stat-cell-content";
import "./team-comparison-table.scss";
import Plus from "v2/components/svg-assets/plus.svg";

export interface TeamComparisonTableProps {
	columns: DevStatColumn[];
	items: TeamViewModel[];
	estimationMethod: TaskEstimationMethod | undefined;
	onAddColumnClick?: () => void;
}

export const TeamComparisonTable = (props: TeamComparisonTableProps) => {
	const { columns, items, estimationMethod, onAddColumnClick } = props;
	const drawerRef = useRef<HTMLDivElement>(null);
	const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
	const [expandedTeam, setExpandedDeveloper] = React.useState<TeamViewModel | null>(null);
	const handleCloseDrawer = useCallback(() => setIsDrawerOpen(false), []);
	const handleTeamClick = useCallback((team: TeamViewModel) => {
		if (expandedTeam === team) {
			handleCloseDrawer();
			return;
		}

		setIsDrawerOpen(true);
		setExpandedDeveloper(team);
	}, [expandedTeam, handleCloseDrawer]);
	const columnItems = useMemo<Array<CustomTableColumnProps<TeamViewModel>>>(() => {

		return [
			{
				key: "name",
				title: "Team",
				sticky: true,
				component: TeamInfoCellContent,
				onClick: handleTeamClick
			},
			...columns.map(column => {
				const tooltip = column.displayValues.teamTooltip.replace(ESTIMATION_UNITS, getShortEstimationMethodName(estimationMethod));

				return ({
					key: column.dashboardMetricName,
					title: column.displayValues.title,
					subtitle: column.displayValues.subtitle,
					tooltip,
					component: TeamStatCellContent,
					sortBy: compareStatBy,
					compareBy: compareStatBy,
				});
			}),
		];
	}, [columns, handleTeamClick]);

	useEffect(() => {
		if (isDrawerOpen) {
			return;
		}

		const handleTransitionEnd = () => setExpandedDeveloper(null);

		drawerRef.current?.addEventListener("transitionend", handleTransitionEnd, {
			once: true,
		});

		return () => {
			drawerRef.current?.removeEventListener("transitionend", handleTransitionEnd);
		};
	}, [isDrawerOpen]);

	return (
		<div className="team-comparison-table">
			{onAddColumnClick && (
				<button className="team-comparison-table-add-column-button" onClick={onAddColumnClick}>
					<img src={Plus} alt="+"/>
				</button>
			)}

			<CustomTable
				columns={columnItems}
				items={items}
				stickyHeader={true}
				sortable={true}
			/>
			<TeamDetailsSidePanel
				ref={drawerRef}
				team={expandedTeam}
				isOpen={isDrawerOpen}
				onClose={handleCloseDrawer}
			/>
		</div>
	);
};

const compareStatBy = (team: TeamViewModel, column: ResolvedCustomTableColumnProps<TeamViewModel>) => {
	return team.stats?.[column.key as keyof typeof team.stats]?.current ?? NaN;
};
