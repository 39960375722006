import * as React from "react";
const Bug = (props: React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" {...props}>
		<ellipse cx={36.46} cy={21.915} rx={11.02} ry={11.097} fill="#3f3f3f" />
		<ellipse cx={36.486} cy={40.683} rx={16.529} ry={17.987} fill="#d22f27" />
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			d="M18.984 21.727c0 3.309 4.225 5.914 5.483 6.605M14.123 38.257c0 .736 3.954 2.039 5.835 2.628M18.984 57.697c0-2.877 3.194-4.69 4.848-5.438M49.126 28.53s4.861-3.89 4.861-6.806M53.005 40.137a8.432 8.432 0 0 0 4.872-1.88M48.643 52.871c1.258.113 4.566.725 5.348 3.852M29.678 9.084c.76 3.014 2.096 3.693 2.663 3.84M42.32 12.973s2.659-2.333 1.994-3.889"
		/>
		<ellipse
			cx={36.486}
			cy={40.683}
			rx={16.529}
			ry={17.987}
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
		/>
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			d="M36.486 23.668v34.03"
		/>
		<circle cx={26.819} cy={35.28} r={2} />
		<circle cx={46.265} cy={35.28} r={2} />
		<circle cx={42.376} cy={41.114} r={2} />
		<circle cx={46.265} cy={46.947} r={2} />
		<circle cx={30.708} cy={41.114} r={2} />
		<circle cx={26.819} cy={46.947} r={2} />
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			d="M26.46 21.724a10.004 10.004 0 0 1 20 0"
		/>
	</svg>
);
export default Bug;
