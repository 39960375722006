import { DashboardTeamReportMetricChangeReaction, DashboardTeamReportMetric } from "@acumen/dashboard-common";
import classNames from "classnames";
import React from "react";
import { round } from "@acumen/common";
import "./graphs.scss";
import { IHoverEffectProps, externalHoverType } from "../../pages/org-analytics/particles";

interface IStackedSingleBarChartLabel {
	value: number | string;
	reaction: DashboardTeamReportMetricChangeReaction | null;
	change: number;
}
interface IStackedSingleBarChart<T> {
	barData?: Array<{
		value: number;
		name: T;
	}>;
	relativeTotal?: number;
	borderRadiusPx?: number;
	hoverEffect?: IHoverEffectProps;
	label?: IStackedSingleBarChartLabel;
	colorScheme?: string[];
	barWidthProp?: string;
	barHeightProp?: string;
}

const DEFAULT_BORDER_RADIUS = 4;
const DEFAULT_COLORS = [
	"#170987", "#F49F09", "#AF2387", "#05B2DC"
];
const DEFAULT_WIDTH_PROP = "85%";
const DEFAULT_BAR_HEIGHT_PROP = "6px";
const getColor = (i: number, colors: string[] = DEFAULT_COLORS) => colors[i % colors.length];

// tslint:disable-next-line: variable-name
const StackedSingleBarChart = (props: IStackedSingleBarChart<DashboardTeamReportMetric>) => {
	const {
		barData: data = [],
		relativeTotal,
		borderRadiusPx = DEFAULT_BORDER_RADIUS,
		hoverEffect,
		colorScheme,
		label,
		barWidthProp,
		barHeightProp
	} = { ...props };
	const { hoveredMetric, setHoveredMetric, withLabel } = { ...hoverEffect };

	const totalValues = relativeTotal ?? data.reduce((acc, d) => acc + d.value, 0);
	const colorsInUse = colorScheme ?? DEFAULT_COLORS;
	const barWidth = barWidthProp ?? DEFAULT_WIDTH_PROP;
	const barHeight = barHeightProp ?? DEFAULT_BAR_HEIGHT_PROP;

	const getValuePercentage = (v: number): number => (100 / totalValues) * v;

	const getBarStyle = (value: number, barIndex: number, height: number, colors: string[] = colorsInUse) => {
		const backgroundColor = getColor(barIndex, colors);
		const border = `${height}px solid ${backgroundColor}`;
		let borderRadius;

		if (data.length === 1) {
			borderRadius = `${borderRadiusPx}px ${borderRadiusPx}px ${borderRadiusPx}px ${borderRadiusPx}px`;
		} else if (barIndex === 0) {
			borderRadius = `${borderRadiusPx}px 0px 0px ${borderRadiusPx}px`;
		} else if (barIndex === data.length - 1) {
			borderRadius = `0px ${borderRadiusPx}px ${borderRadiusPx}px 0px`;
		}
		return { width: (getValuePercentage(value) + "%"), backgroundColor, border, borderRadius };
	};

	const getBar = () => (
		<div className="bar-chart" style={{ width: barWidth }}>
			{data.map((_d, i) => (
				_d.value > 0 && <div
					key={i}
					className={classNames("main-bar")}
					style={{ ...getBarStyle(_d.value, i, 3), height: barHeight }}
					data-value={i}
					onMouseOver={(e) => {
						e.stopPropagation();
						if (setHoveredMetric) {
							setHoveredMetric(_d.name);
						}
					}}
					onMouseOut={(e) => {
						if (setHoveredMetric) {
							setHoveredMetric(externalHoverType);
						}
					}}
				/>
			))}
		</div>
	);

	const getHoverBar = () => (
		<div className="stacked-bar-chart" style={{ width: barWidth }}>
			{data.map((barData, barIndex) => (
				<div
					key={barIndex}
					className={classNames("hover-bar", { visible: barData.name === hoveredMetric })}
					style={{
						...getBarStyle(barData.value, barIndex, 1),
						position: "relative",
						top: "-23px",
						height: "1px",
						color: getColor(barIndex, colorsInUse)
					}}
				>{withLabel && round(barData.value)}</div>
			))
			}
		</div >
	);

	const chevronClassName = (_label: IStackedSingleBarChartLabel) => (
		{
			"gray-text": (_label.change === 0 || _label.reaction === DashboardTeamReportMetricChangeReaction.Neutral),
			"success-text": (_label.change !== 0 && _label.reaction === DashboardTeamReportMetricChangeReaction.Positive),
			"warning-text": (_label.change !== 0 && _label.reaction === DashboardTeamReportMetricChangeReaction.Negative),
		});

	return (
		data && data.find(d => d.value > 0) ?
			<>
				{label &&
					<span
						className={classNames("change", "small-text semi-bold-text")}
						style={{ position: "relative", top: "-.75rem", left: "1rem", fontSize: "14px" }}>
						{label.value}
						{label.change > 0 && <i className={classNames("caret up icon", chevronClassName(label))} />}
						{label.change < 0 && <i className={classNames("caret down icon", chevronClassName(label))} />}
					</span>
				}
				<div style={label && { marginTop: "-1.5rem" }}>
					{getBar()}
					{hoverEffect && getHoverBar()}
				</div>
			</> : <>
				<span className="empty-bar tiny-text gray-text" style={{ width: barWidth }}>
					No data
				</span>
			</>
	);
};
export default StackedSingleBarChart;
