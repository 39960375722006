import React, { useState, useEffect, useMemo, useCallback } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../../mobx-stores";
import moment from "moment";
import _ from "lodash";
import { Accordion, Popup, Pagination, Segment, SemanticShorthandItem, PopupContentProps } from "semantic-ui-react";
import TaskStatus, { taskStatusText } from "../../../../components/formatters/task-status-icon";
import { DateRangeType, IDashboardStatus, IDashboardTaskStatusChangeResponse } from "@acumen/dashboard-common";
import {
	DashboardSortOrder, DashboardTaskExpandOption,
	DashboardTaskStaticGroupingOption, IDashboardTaskDetailed, DashboardTaskWorkStatusGrouping,
	IDashboardDetailedTaskGroup, DashboardTaskDynamicGroupingOption, AcumenTaskStatus, TaskEstimationMethod, AcumenTaskType, SubtaskSprintStrategy
} from "@acumen/dashboard-common";
import { ISelectOptionBase } from "../../../../components/form/option-select";
import classNames from "classnames";
import TaskDetailsDisplayPanel from "../../../../components/dashboard-task/task-details-display-panel";
import HighlightsFormatters from "../../../../components/dashboard-task/highlights-formatters";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import { filterTaskDisplayData } from "../../../../components/dashboard-task/formatters";
import { ExternalImage } from "../../../../components/external-image";
import { Avatar } from "../../../../components/avatar";
import { ACCORDION_PANEL_NO_CONTENT_MESSAGE } from "../accordion";
import ConfirmationModal from "../../../../components/modals/confirmation-modal";
import { Formatters } from "../../../../components/dashboard-task/formatters";
import { dateRelativeToNow } from "../../../../../utils/date";
import { dateFormatByLocale, DateFormat, round } from "@acumen/common";
import BarChart from "../../../../components/graphs/bar-chart";
import { GA_EVENT_ACTION, GA_EVENT_CATEGORY, clickEvent } from "../../../../analytics-events";
import FiltersTopNavbar from "../../../../components/filters-top-navbar";
import "./style.scss";
import { taskVelocityGraph, getEstimationValue } from "../../../../components/dashboard-task/task-velocity-graph";
import { labeledHeader } from "../../../../components/acumen-labels";
import FiltersSelector from "../../../../components/filters-selector/filters-selector";
import {
	TimeFramesSelector
} from "../../../../components/filters-selector/sections/time-frames-selector";
import { SprintsSelector } from "../../../../components/filters-selector/sections/sprints-selector";
import filtersSlice from "./filters-slice";
import DatePickerSelector from "../../../../components/filters-selector/sections/date-picker";
import SortProvider from "../../../../providers/sort-provider";
import useSortState from "../../../../hooks/useSortState";
import useSortedItems from "../../../../hooks/useSortedItems";
import TableHeader from "../../../../components/table-header";
import { createTaskEndStatusGetter, createTaskStartStatusGetter } from "../../../../components/iteration-review/utils";

const MAXIMUM_STATIC_GROUP_ITEMS = 10;
const ROWS_PER_PAGE = 20;

const EXCLUDED_FILTER_TASK_STATUSES: AcumenTaskStatus[] =
	[AcumenTaskStatus.Backlog, AcumenTaskStatus.Discarded, AcumenTaskStatus.Closed, AcumenTaskStatus.Done];

export enum EstimationColumnType {
	Velocity = "Velocity",
	Progression = "Progression",
	Value = "Value",
	EstProgress = "Est. Progress"
}

interface IFilterOptions {
	labels: string[];
	statuses?: AcumenTaskStatus[];
	types?: AcumenTaskType[];
	projectIds: string[];
}

const WORK_TASK_FILTER_LOCAL_STORAGE_KEY = "work_task_filters";
const WORK_TASK_FILTER_DATA_CONTRIBUTOR_FILTER_LOCAL_STORAGE_KEY = "work_task_data_contributor_filter";
const SELECTED_SPRINT_VALUE_LOCAL_STORAGE_KEY = "global_sprint_selector_selected";

const DEFAULT_FILTERS: IFilterOptions = {
	projectIds: [],
	labels: [],
	statuses: Object.values(AcumenTaskStatus).filter(status => EXCLUDED_FILTER_TASK_STATUSES.indexOf(status) === -1),
	types: []
};

const createPanel = (index: number, key: string, title: string,
	{ tasks, total }: IDashboardDetailedTaskGroup | undefined = { tasks: [], total: 0 },
	activeIndex: number | number[], onSeeAllClick: () => void,
	selectedTask: IDashboardTaskDetailed | undefined,
	setSelectedTask: (value: IDashboardTaskDetailed | undefined) => void,
	selectedIndex: number | undefined,
	customIconName: ((isEmpty: boolean) => string) | undefined = undefined,
	onDismissTaskHighlights?: (value: IDashboardTaskDetailed) => void,
	estimationMethod: TaskEstimationMethod | undefined = undefined,
	estimationColumn: EstimationColumnType | undefined = undefined,
	tooltip: string | undefined = undefined) => {

	const isSeeAllMode = !Array.isArray(activeIndex) && activeIndex === index;
	const isOpen = Array.isArray(activeIndex) && activeIndex.includes(index);
	const noItemsMessage = tasks.length === 0 ? ACCORDION_PANEL_NO_CONTENT_MESSAGE.find(panelMsg => panelMsg.key === key)?.message : undefined;

	return {
		key: `panel-${key}`,
		index,
		title: {
			icon: "",
			content: (
				<StaticGroupTitle
					{...clickEvent(GA_EVENT_CATEGORY.TaskWork, GA_EVENT_ACTION.Select, `Accordion-${key}`)}
					{...{
						tasksLength: tasks.length,
						totalTasks: total,
						isSeeAllMode,
						onSeeAllClick,
						title,
						customIconName,
						noItemsMessage,
						tooltip,
						isOpen
					}}
				/>
			)
		},
		content: {
			content: (
				<>
					{(tasks.length > 0) && (
						<TasksGroup
							{...{
								currentIndex: index,
								rows: tasks,
								onDismissTaskHighlights,
								onRowClicked: (item: IDashboardTaskDetailed) => setSelectedTask(item),
								selectedRow: selectedTask,
								selectedIndex,
								totalRows: total,
								isSeeAllMode,
								estimationMethod,
								estimationColumn,
							}}
						/>
					)}
				</>
			)
		}
	};
};

function estimationColumnHeader(estimationMethod?: TaskEstimationMethod, columnType?: EstimationColumnType): {title: string, tooltip?: string} {
	if (!estimationMethod || !columnType) {
		return {title: ""};
	}

	switch (columnType) {
		case EstimationColumnType.Progression: {
			return {title: "Est. progress", tooltip: "Estimate of progress based on historical data and current given estimation"};
		}
		case EstimationColumnType.Velocity: {
			return {title: "Est. accuracy", tooltip: "Calculation of how fast the work was done in relation to past performance"};
		}
		case EstimationColumnType.EstProgress: {
			return {title: "Est. progress", tooltip: "Estimate of progress based on historical data and current given estimation"};
		}
		case EstimationColumnType.Value: {
			switch (estimationMethod) {
				case TaskEstimationMethod.StoryPoints: {
					return {title: "Story points"};
				}
				case TaskEstimationMethod.TShirtSize: {
					return { title: "T-shirt size"};
				}
				case TaskEstimationMethod.TimeBased:
				default: {
					return { title: "estimation" };
				}
			}
		}
		default: {
			return { title: "Estimation"};
		}
	}
}

function getMissingEstimationTooltip(action: string, task: IDashboardTaskDetailed, estimationMethod?: TaskEstimationMethod) {
	let tooltip: SemanticShorthandItem<PopupContentProps> | undefined;

	if (task.totalCommitCalculatedWorkSeconds === null || task.totalCommitCalculatedWorkSeconds === 0) {
		tooltip = (<>Cannot estimate {action} as <b>{task.publicIdentifier}</b> has no recorded Git commits</>);
	} else if (estimationMethod) {
		switch (estimationMethod) {
			case TaskEstimationMethod.StoryPoints: {
				if (task.storyPoints === null) {
					tooltip = (<>Cannot estimate {action} for <b>{task.publicIdentifier}</b>, no story points entered</>);
				} else if (task.storyPoints === 0) {
					tooltip = (<>Cannot estimate {action} for <b>{task.publicIdentifier}</b>, task's story points are set to <b>0</b></>);
				}
				break;
			}
			case TaskEstimationMethod.TShirtSize: {
				if (task.tshirtSizeName === null) {
					tooltip = (<>Cannot estimate {action} for <b>{task.publicIdentifier}</b>, task has no T shirt size estimate</>);
				}
				break;
			}
			case TaskEstimationMethod.TimeBased: {
				if (task.timeEstimateSeconds === null) {
					tooltip = (<>Cannot estimate {action} for <b>{task.publicIdentifier}</b>, task has no time estimate</>);
				} else if (task.timeEstimateSeconds === 0) {
					tooltip = (<>Cannot estimate {action} for <b>{task.publicIdentifier}</b>, time estimate is set to <b>0</b></>);
				}
				break;
			}
			default:
		}
	}
	return tooltip;
}

function getProgressBar(task: IDashboardTaskDetailed, estimationMethod?: TaskEstimationMethod) {
	let tooltip: SemanticShorthandItem<PopupContentProps> | undefined;

	if (task.progression) {
		const estimationValue = Formatters.estimationValueFromTask(task, estimationMethod);
		const givenEstimateText = (estimationValue === null) ? "given estimate" : `given estimate (${estimationValue})`;
		if (task.progression === 0) {
			tooltip = (<><b>{task.publicIdentifier}</b> has no recorded progression</>);
		} else if (task.progression > 0 && task.progression <= 1.1) {
			tooltip = (<><b>{task.publicIdentifier}</b> progressing correctly according to {givenEstimateText}</>);
		} else if (task.progression > 1.1 && task.progression <= 1.3) {
			tooltip = (<><b>{task.publicIdentifier}</b> maker time seems to be exceeding {givenEstimateText} by more than <b>10%</b> (compared with similar tasks)</>);
		} else if (task.progression > 1.3) {
			tooltip = (<><b>{task.publicIdentifier}</b> maker time seems to be exceeding {givenEstimateText} by more than <b>20%</b> (compared with similar tasks)</>);
		}
	} else {
		tooltip = getMissingEstimationTooltip("progression", task, estimationMethod);
	}

	if (!tooltip) {
		tooltip = (<>Cannot estimate progression for <b>{task.publicIdentifier}</b> - insufficient data</>);
	}

	return (
		<BarChart
			percents={(task.progression || 0) * 100}
			animate={false}
			tooltip={tooltip}
		/>
	);
}

const CONSTANT_COLUMNS_COUNT = 2;

const getIssueTitle = (issue: IDashboardTaskDetailed) => issue.title;
const getIssueEstimation = (issue: IDashboardTaskDetailed) => issue.estimation ?? -1;
const getIssueCycleTime = (issue: IDashboardTaskDetailed) => issue.totalCycleTimeInSeconds ?? -1;
const getIssueStatus = (issue: IDashboardTaskDetailed) => taskStatusText(issue.status.status ?? null, issue.status.internalStatus);

export enum StatusType {
	CurrentStatus,
	StartEndStatus
}
// tslint:disable-next-line: variable-name
export const TasksGroup = observer((
	{
		rows = [], totalRows, isSeeAllMode, onRowClicked, selectedRow, currentIndex, selectedIndex,
		onDismissTaskHighlights, estimationMethod, estimationColumn, scrollable, wrapTableTitle = true,
		statusType = StatusType.CurrentStatus, shouldDisplayMakerTimePercentage = false, tableClassName, subtaskStrategy,
		shouldDisplayTotalMakerTime = true, shouldDisplayCycleTime = false, shouldDisplayEstimationColumn, statusDiff
	}:
		{
			rows: IDashboardTaskDetailed[];
			selectedRow: IDashboardTaskDetailed | undefined;
			totalRows: number;
			selectedIndex: number | undefined;
			subtaskStrategy?: SubtaskSprintStrategy;
			currentIndex?: number;
			isSeeAllMode?: boolean;
			scrollable?: boolean;
			onDismissTaskHighlights?: (item: IDashboardTaskDetailed) => void;
			onRowClicked?: (item: IDashboardTaskDetailed) => void;
			estimationMethod?: TaskEstimationMethod;
			estimationColumn?: EstimationColumnType;
			wrapTableTitle?: boolean;
			statusType?: StatusType;
			shouldDisplayMakerTimePercentage?: boolean;
			shouldDisplayTotalMakerTime?: boolean;
			shouldDisplayEstimationColumn?: boolean;
			tableClassName?: string;
			shouldDisplayCycleTime?: boolean;
			statusDiff?: IDashboardTaskStatusChangeResponse;
		}) => {
	const {
		authStore,
		taskStatusStore: {
			statusIdToStatus,
			getStatus
		},
		sprintRetroStore: {
			issueIdsByCategory
		}
	} = useStores();

	const dateTimeFormat = dateFormatByLocale(DateFormat.LongWithDay, authStore.authUser.locale);
	const [page, setPage] = useState<number>(1);
	const [total, setTotal] = useState(0);
	const [tasks, setTasks] = useState<IDashboardTaskDetailed[]>([]);

	useEffect(() => {
		if (scrollable) {
			setTotal(totalRows);
			setTasks(rows);
			return;
		}

		if (isSeeAllMode) {
			const offset = (page - 1) * ROWS_PER_PAGE;
			const pagedItems = _.drop(rows, offset).slice(0, ROWS_PER_PAGE);
			setTotal(totalRows);
			setTasks(pagedItems);
			return;
		}

		setPage(1);
		const maxTasksPerGroup = rows.slice(0, MAXIMUM_STATIC_GROUP_ITEMS);
		setTotal(maxTasksPerGroup.length);
		setTasks(maxTasksPerGroup);
	}, [rows, page, isSeeAllMode]);

	const getTaskEstimation = (task: IDashboardTaskDetailed , _estimationMethod: TaskEstimationMethod) => {
		switch (task.status.status) {
			case AcumenTaskStatus["Code Review"]:
			case AcumenTaskStatus["In Progress"]:
			case AcumenTaskStatus.Testing:
			case AcumenTaskStatus["To Test"]:
				return getProgressBar(task, estimationMethod);
			default:
				return;
		}
	};

	const calcMakerTimePercentage = (taskTotalCalculatedWorkMs: number, totalCalculatedWorkSumMs: number) => {
		if (!taskTotalCalculatedWorkMs) {
			return 0;
		}

		return round((taskTotalCalculatedWorkMs / totalCalculatedWorkSumMs) * 100);
	};

	// Note: issue from the iteration review has empty array in highlights, relatedPRs
	// and has null in calculatedWorkStartMs
	const displayHighlights = _.some(tasks, (issue) => !_.isEmpty(issue.highlights));
	const displayRelatedPRs = _.some(tasks, (issue) => !_.isEmpty(issue.relatedPRs));
	const displayStartedOn = _.some(tasks, (issue) => !_.isNull(issue.calculatedWorkStartMs));
	const displayEstimation = tasks[0]?.estimation !== undefined;
	const displayEstimationColumn = (shouldDisplayEstimationColumn === undefined)
		? estimationMethod && estimationMethod !== TaskEstimationMethod.None
		: shouldDisplayEstimationColumn;
	const numberOfColumns = CONSTANT_COLUMNS_COUNT + _.reduce(
		[displayHighlights, displayRelatedPRs, displayStartedOn, displayEstimation, displayEstimationColumn,
			shouldDisplayMakerTimePercentage, onDismissTaskHighlights, shouldDisplayCycleTime, shouldDisplayTotalMakerTime,
			shouldDisplayCycleTime], (sum, prev) => sum + (prev ? 1 : 0), 0
	);
	const getIssueStartStatus = useMemo(() => createTaskStartStatusGetter(true, statusDiff, statusIdToStatus), [statusDiff, statusIdToStatus]);
	const getIssueEndStatus = useMemo(() => createTaskEndStatusGetter(true, statusDiff, statusIdToStatus, issueIdsByCategory), [statusDiff, statusIdToStatus, issueIdsByCategory]);
	const { sortedColumn, sort } = useSortState();
	const sortedTasks = useSortedItems({ items: tasks, sortedColumn });

	return (
	<div className={scrollable ? "acu-scroll": ""}>
		<table className={classNames("ui very compact", "small fixed", "single line", "unstackable selectable table",
			scrollable ? "sticky-table" : "", tableClassName ?? "")}>
				<colgroup span={numberOfColumns} />
				<thead className={classNames("full-width", "no-collapse", wrapTableTitle ? "" : "single line", "acu-capitalize")}>
				<tr>
					<TableHeader className="left aligned one wide" />
					<SortProvider sortedColumn={sortedColumn} onSort={sort}>
						<TableHeader columnName="Title" className="issues-table-title" sortBy={getIssueTitle} />
						{statusType === StatusType.CurrentStatus &&
							<TableHeader columnName="Status" className="two wide status" sortBy={getIssueStatus} />
						}
						{statusType === StatusType.StartEndStatus &&
							<>
								<TableHeader columnName="Start status" className="two wide status" sortBy={getIssueStartStatus} />
								<TableHeader columnName="End status" className="two wide status" sortBy={getIssueEndStatus} />
							</>
						}
						{displayHighlights &&
							<TableHeader columnName="Attention points" className="three wide" />
						}
						{displayEstimation &&
							<TableHeader
								columnName={Formatters.getEstimationTitle(estimationMethod)}
								className="two wide"
								sortBy={getIssueEstimation}
							/>
						}
						{displayStartedOn &&
							<Popup
								size="small"
								basic={true}
								content="Time of when work started for the task as was detected by Acumen"
								trigger={
									<TableHeader columnName="Started on" className="two wide" />
								}
							/>
						}
						{displayRelatedPRs &&
							<TableHeader columnName="Pull requests" className="one wide" />
						}
						{shouldDisplayTotalMakerTime &&
							<Popup
								size="small"
								basic={true}

								content="Total amount of maker time done in issue's scope (as calculated by Acumen)"
								trigger={
									<TableHeader className="one wide maker-time">
										{labeledHeader("Maker time")}
									</TableHeader>
								}
							/>
						}
						{shouldDisplayMakerTimePercentage &&
							<Popup
								size="small"
								basic={true}
								hoverable={true}
								content={"Percentage of how much maker time was invested into the issue"}
								trigger={
									<TableHeader className="two wide">
										{labeledHeader("Maker time(%)")}
									</TableHeader>
								}
							/>
						}
						{displayEstimationColumn
							? estimationColumnHeader(estimationMethod, estimationColumn).tooltip ?
								<Popup
									size="small"
									basic={true}
									content={estimationColumnHeader(estimationMethod, estimationColumn).tooltip}
									trigger={
										<TableHeader className="two wide" colSpan={onDismissTaskHighlights ? 2 : 0} scope="colgroup">
											{labeledHeader(estimationColumnHeader(estimationMethod, estimationColumn).title)}
										</TableHeader>
									}
								/>:
								<TableHeader className="two wide" colSpan={onDismissTaskHighlights ? 2 : 0} scope="colgroup">
									{estimationColumnHeader(estimationMethod, estimationColumn).title}
								</TableHeader>
							: onDismissTaskHighlights &&
								<TableHeader className="one wide" />
						}
						{shouldDisplayCycleTime &&
							<TableHeader className="two wide" columnName="Cycle Time" sortBy={getIssueCycleTime} />
						}
					</SortProvider>
				</tr>
			</thead>
			<tbody>
				{tasks.length === 0 && (
					<tr>
						<td colSpan={numberOfColumns}>
							<div className="rows-placeholder ui basic fluid center aligned segment" />
						</td>
					</tr>
				)}
				{sortedTasks.map(
					(t) => {
						const highlights = filterTaskDisplayData(t.highlights);
						const taskStatusDiff = statusDiff?.[t.entityId];
						const startStatus = taskStatusDiff && taskStatusDiff!.startStatus ? statusIdToStatus[taskStatusDiff!.startStatus] : null;
						const endStatus = taskStatusDiff && taskStatusDiff!.endStatus ? statusIdToStatus[taskStatusDiff!.endStatus] : null;
						return (
							<tr
								key={t.entityId}
								{...clickEvent(GA_EVENT_CATEGORY.TaskWork, GA_EVENT_ACTION.Select, "SidePanel")}
								onClick={() => onRowClicked && onRowClicked(t)}
								className={classNames((
									(selectedRow && selectedRow.entityId === t.entityId && selectedRow.entityType === t.entityType) &&
										(isSeeAllMode || (selectedIndex !== undefined && selectedIndex === currentIndex))
										? "active"
										: ""))}
							>
								<td>
									<div className="task-icon-items flex-spaced">
										<Popup
											size="mini"
											basic={true}
											content={t.type.internalType}
											trigger={
												<ExternalImage src={t.type.iconUrl ?? ""} className="ui micro image" />
											}
										/>
										{(t.priority.internalPriority !== null && t.priority.internalPriority.trim().length > 0) && (
											<Popup
												size="mini"
												basic={true}
												content={t.priority.internalPriority}
												trigger={
													<ExternalImage src={t.priority.iconUrl ?? ""} className="ui micro image" />
												}
											/>
										)}
										<Popup
											size="mini"
											basic={true}
											className="capitalize"
											content={t.assignee?.primaryDisplayName}
											trigger={(
												<Avatar
													dataContributor={t.assignee}
													className="medium"
												/>
											)}
										/>
									</div>
								</td>
								<td>
									<div className="hover-ellipsis">
										{t.publicHtmlUrl
											? <a href={t.publicHtmlUrl} target="_blank" rel="noopener noreferrer" className="acu-link">{t.publicIdentifier}</a>
											: t.publicIdentifier
										}
										<span className="task-title after-space">{t.title}</span>
									</div>
								</td>
								{statusType === StatusType.CurrentStatus &&
									<td>
										<TaskStatus
											taskStatus={t.status.status ?? null}
											internalStatus={t.status.internalStatus}
										/>
									</td>
								}
								{statusType === StatusType.StartEndStatus &&
									<>
										<td>
											{(getStatus && taskStatusDiff) && (
												<TaskStatus
													internalDashboardStatus={startStatus}
													taskStatus={startStatus?.acumenStatus ?? null}
													internalStatus="Created"
													hasTooltip={shouldStatusHaveTooltip(startStatus)}
												/>
											)}
										</td>
										<td>
											{(getStatus && taskStatusDiff) && (
												<TaskStatus
													internalDashboardStatus={endStatus}
													taskStatus={endStatus?.acumenStatus ?? null}
													hasTooltip={shouldStatusHaveTooltip(endStatus)}
													isRemoved={t.removed}
												/>
											)}
										</td>
									</>
								}
								{displayHighlights &&
									<td>
										<div className="task-highlight-container">
											{highlights.length > 0 && <span className="task-highlight-text">{HighlightsFormatters.highlightText(highlights[0])}</span>}
											{highlights.length > 1 && <div className="ui tiny basic label">+{highlights.length - 1}</div>}
										</div>
									</td>
								}
								{displayEstimation &&
									<td>
										<div>
										{Formatters.estimationValue(t.estimation ?? null, estimationMethod, "N/A", true)}
										</div>
									</td>
								}
								{displayStartedOn && (
									<td>
										{t.calculatedWorkStartMs && (
											<Popup
												size="small"
												basic={true}
												content={moment(t.calculatedWorkStartMs).format(dateTimeFormat)}
												trigger={(
													<span>
														{dateRelativeToNow(dateTimeFormat, t.calculatedWorkStartMs)}
													</span>
												)}
											/>
										)}
									</td>
								)}
								{displayRelatedPRs &&
									<td>
									{(t.relatedPRs && t.relatedPRs.length) ? (t.relatedPRs.length > 1) ?
										<span className="side-margin">{t.relatedPRs.length} PRs</span>
										:
										(<>
											<a href={t.relatedPRs[0].publicHtmlUrl ?? ""} target="_blank" rel="noopener noreferrer" className="acu-link">
												#{t.relatedPRs[0].publicIdentifier}
											</a>
										</>)
										:
										<></>
									}
									</td>
								}
								{shouldDisplayTotalMakerTime &&
									<td className="no-ellipsis">
									{Formatters.workIntervals(t.totalCalculatedWorkMs)}
								</td>
								}
								{shouldDisplayMakerTimePercentage &&
									<td>
										<span>{`${calcMakerTimePercentage(t.totalCalculatedWorkMs ?? 0, _.sumBy(tasks, "totalCalculatedWorkMs"))}%`}</span>
									</td>
								}
								{
								displayEstimationColumn &&
									<td className="collapsing always-visible">
										{(estimationMethod && estimationColumn && estimationColumn === EstimationColumnType.Value) && (
											<>
												<span>{Formatters.estimationValueFromTask(t, estimationMethod, "")}</span>
											</>
										)}
										{(estimationMethod && estimationColumn && estimationColumn === EstimationColumnType.Velocity) && (
											<div>
												{taskVelocityGraph(t, getEstimationValue(t, estimationMethod), estimationMethod,
													t.percentileRank, t.totalCommitCalculatedWorkSeconds)}
											</div>
										)}
										{(estimationMethod && estimationColumn && estimationColumn === EstimationColumnType.Progression) && (
											<>
												{getProgressBar(t, estimationMethod)}
											</>
										)}
										{(estimationMethod && estimationColumn && estimationColumn === EstimationColumnType.EstProgress) && (
											<>
												{getTaskEstimation(t, estimationMethod)}
											</>
										)}
									</td>
								}
								{shouldDisplayCycleTime &&
									<td>
										<div>
											{t.totalCycleTimeInSeconds ? Formatters.cycleTime(t.totalCycleTimeInSeconds * 1000) : "N/A"}
										</div>
									</td>
								}
								{onDismissTaskHighlights && (
									// eslint-disable-next-line no-sequences
									<td className="collapsing right aligned" onClick={(e) => (e.stopPropagation(), false)}>
											<Popup
												className="set-popup-menu-z-index"
												size="mini"
												basic={true}
												flowing={true}
												hoverable={true}
												on="click"
												hideOnScroll={true}
												position="bottom right"
												trigger={<i {...clickEvent(GA_EVENT_CATEGORY.TaskWork, GA_EVENT_ACTION.Delete, "NeedAttention")}
													className="ui icon outline ellipsis vertical align-button" />}
											>
											{<div className={classNames("ui tiny header", "flex-row")}
													style={{ cursor: "pointer" }}
													onClick={(e) => {
														onDismissTaskHighlights(t);
														e.stopPropagation();
													} }
												>
													<i className="icon check check-icon-display"/>
													<span>{"Acknowledge"}</span>
												</div>
											}
											</Popup>
									</td>
								)
								}
							</tr>
						);
					}
				)}
			</tbody>
			{!scrollable && total > ROWS_PER_PAGE && (
				<tfoot>
					<tr>
						<td colSpan={numberOfColumns}>
							<div className="ui basic center aligned segment">
								<Pagination
									boundaryRange={0}
									defaultActivePage={page}
									ellipsisItem={null}
									firstItem={null}
									lastItem={null}
									siblingRange={1}
									totalPages={Math.ceil(total / ROWS_PER_PAGE)}
									onPageChange={(_e, { activePage }) => setPage(activePage as number ?? 1)}
								/>
							</div>
						</td>
					</tr>
				</tfoot>
			)}
		</table>
	</div>
	);
});

// tslint:disable-next-line: variable-name
const StaticGroupTitle = observer(({
	tasksLength,
	totalTasks,
	isSeeAllMode,
	onSeeAllClick,
	title,
	customIconName,
	noItemsMessage,
	tooltip,
	isOpen
}: {
	title: string;
	tasksLength: number;
	totalTasks: number;
	isSeeAllMode: boolean;
	customIconName?: ((isEmpty: boolean) => string);
	onSeeAllClick: () => void;
	noItemsMessage: string | undefined;
	tooltip: string | undefined;
	isOpen?: boolean;
}) => {
	return (
		<div className="ui compact grid">
			<div className="equal width row">
				<div className="column">
					<i className={classNames("toggler-icons icon", (isSeeAllMode ? "chevron left" : isOpen ? "chevron down" :"chevron right"))} />
					{customIconName && <i className={classNames("icon  " + (customIconName(totalTasks === 0)))} />}
					<Popup
						size="small"
						basic={true}
						content={tooltip}
						disabled={tooltip === undefined}
						trigger={
							<span className={"accordion-section-title"}>{title} ({totalTasks})</span>
						}
					/>

					{(noItemsMessage) && <span className="ui label transparent">{noItemsMessage}</span>}
				</div>
				<div className="right aligned three wide column">
					<div className="ui tiny compact text labeled menu">
						{!isSeeAllMode
							&& Formatters.itemsRange(tasksLength, MAXIMUM_STATIC_GROUP_ITEMS, totalTasks)
						}
						{!isSeeAllMode && totalTasks > MAXIMUM_STATIC_GROUP_ITEMS && (
							<span
								className="see-all-link"
								onClick={(e) => { e.stopPropagation(); onSeeAllClick(); }}
							>
								See All
							</span>
						)}
					</div>
				</div>
			</div>
		</div>
	);
});

// tslint:disable-next-line: variable-name
const TasksPage = observer(() => {
	const {
		tasksStore: {
			allTasks: {
				loading,
				data: taskResponse
			},
			fetchAllTasks,
			resetAllTasks,
			dismissHighlights: {
				loading: dismissingTaskHighlight
			},
			dismissTaskHighlights,
			resetDismissTaskHighlights,
			taskStatusesOptions,
			taskTypeOptions
		},
		projectsStore: {
			projectOptions,
			fetchData: fetchProjectsData,
			isLoading: projectsLoading
		},
		labelsStore: {
			fetchData: fetchLabelsData,
			isLoading: isLoadingLabels,
			labelOptions
		},
		teamsStore,
		teamMembersStore,
	} = useStores();

	const [searchFilters, setSearchFilters] =
		useLocalStorage<IFilterOptions>(WORK_TASK_FILTER_LOCAL_STORAGE_KEY, DEFAULT_FILTERS);
	const [searchedTeamId, setSearchedTeamId] = useState<string | undefined>(undefined);

	const [selectedTaskInPanel, setSelectedTaskInPanel] = useState<[IDashboardTaskDetailed | undefined, number | undefined]>([undefined, undefined]);
	const [needAttentionTasks, setNeedAttentionTasks] = useState<IDashboardDetailedTaskGroup | undefined>(undefined);
	const [inProgressTasks, setInProgressTasks] = useState<IDashboardDetailedTaskGroup | undefined>(undefined);
	const [backlogTasks, setBacklogTasks] = useState<IDashboardDetailedTaskGroup | undefined>(undefined);
	const [doneTasks, setDoneTasks] = useState<IDashboardDetailedTaskGroup | undefined>(undefined);
	const [taskHighlightToDismiss, setTaskHighlightToDismiss] = useState<IDashboardTaskDetailed | undefined>(undefined);
	const [taskEstimationMethod, setTaskEstimationMethod] = useState<TaskEstimationMethod | undefined>(undefined);
	const [dataContributorFilter, setDataContributorFilter] =
		useLocalStorage<string[]>(WORK_TASK_FILTER_DATA_CONTRIBUTOR_FILTER_LOCAL_STORAGE_KEY, []);

	const [sprintFilter, setSprintFilter] = useState<{
		selectedTeamId: string;
		id?: string;
		sprintValue?: string;
		dateRangeValue?: DateRangeType;
		shouldReloadIfNoEnd?: boolean;
	}>({
		selectedTeamId: teamsStore.singleTeam.data.id,
		id: undefined,
		sprintValue: undefined,
		dateRangeValue: undefined
	});
	const [activeIndex, setActiveIndex] = useState<number | number[]>([0, 1, 2, 3]);

	const {
		sprintsList,
		getFilteredValueLabel,
		setSelectedSelectorValue,
		isRangeSelector,
		setStartTime,
		setEndDate,
		previousStartTime,
		previousEndTime,
		locale,
		getStartAndEndDates
	} =
		filtersSlice({
			searchedTeamId,
			sprintFilter,
			setSprintFilter,
			storageKey: SELECTED_SPRINT_VALUE_LOCAL_STORAGE_KEY,
		});

	const { rangeStartTime, rangeEndTime, isCustomDateRange } = getStartAndEndDates();

	function clearTasks() {
		setSelectedTaskInPanel([undefined, undefined]);
		setNeedAttentionTasks(undefined);
		setInProgressTasks(undefined);
		setDoneTasks(undefined);
		setBacklogTasks(undefined);
		setTaskEstimationMethod(undefined);
		setActiveIndex([0, 1, 2, 3]);
		resetAllTasks();
		resetDismissTaskHighlights();
	}

	useEffect(() => () => {
		clearTasks();
	}, []);

	useEffect(() => {
		const { tasks, dynamic, estimationMethod} = taskResponse;
		setNeedAttentionTasks(dynamic);

		if (!tasks || Array.isArray(tasks)) {
			return;
		}

		const inProgressGroup = tasks[DashboardTaskWorkStatusGrouping.InProgress];
		setInProgressTasks(inProgressGroup);

		const backlogGroup = tasks[DashboardTaskWorkStatusGrouping.Backlog];
		setBacklogTasks(backlogGroup);

		const doneGroup = tasks[DashboardTaskWorkStatusGrouping.Done];
		setDoneTasks(doneGroup);

		if (estimationMethod) {
			setTaskEstimationMethod(estimationMethod);
		}

		if (Array.isArray(activeIndex)) {
			const onLoadActiveIndex: number[] = [];
			if (dynamic && dynamic.total > 0) {
				onLoadActiveIndex.push(0);
			}
			if (inProgressGroup && inProgressGroup?.total > 0) {
				onLoadActiveIndex.push(1);
			}
			if (backlogGroup && backlogGroup?.total > 0) {
				onLoadActiveIndex.push(2);
			}
			if (doneGroup && doneGroup?.total > 0) {
				onLoadActiveIndex.push(3);
			}
			setActiveIndex(onLoadActiveIndex);
		}
	}, [taskResponse]);

	useEffect(() => {
		if (teamsStore.singleTeam.data.id &&
			teamsStore.singleTeam.data.id !== searchedTeamId) {
			if (searchedTeamId !== undefined && !_.isEqual(searchFilters, DEFAULT_FILTERS)) {
				// Reset only the data contributors (team specific filter).
				setDataContributorFilter([]);
			}
			setSearchedTeamId(teamsStore.singleTeam.data.id);
		}
	}, [teamsStore.singleTeam.data.id]);

	useEffect(() => {
		if (searchedTeamId !== undefined && sprintFilter.id !== undefined &&
			sprintFilter.selectedTeamId === searchedTeamId &&
			(rangeEndTime || sprintFilter.sprintValue || sprintFilter.dateRangeValue)) {
			clearTasks();
			fetchTasks(DashboardTaskStaticGroupingOption.WorkStatus);
		}
	}, [sprintFilter.id, searchFilters, searchedTeamId, dataContributorFilter]);

	useEffect(() => {
		if (rangeStartTime && rangeEndTime && sprintFilter.shouldReloadIfNoEnd) {
			clearTasks();
			fetchTasks(DashboardTaskStaticGroupingOption.WorkStatus);
			setSprintFilter(prevState => {
				return {
					...prevState,
					shouldReloadIfNoEnd: false
				};
			});
		}
	}, [rangeEndTime]);

	useEffect(() => {
		async function retrieveMembers(selectedTeamId: string) {
			await teamMembersStore.fetchAllTeamMembers(selectedTeamId);
			const dataContributorIds = dataContributorFilter.filter(dcId =>
				teamMembersStore.allTeamMembers.data.find(teamMember => teamMember.id === dcId));
			setDataContributorFilter(dataContributorIds);
		}
		async function retrieveLabels(selectedTeamId: string) {
			return !isLoadingLabels
				&& await fetchLabelsData({ teamId: selectedTeamId }, { order: DashboardSortOrder.Ascending });
		}
		if (searchedTeamId) {
			// tslint:disable-next-line: no-floating-promises
			retrieveMembers(searchedTeamId);
			// tslint:disable-next-line: no-floating-promises
			retrieveLabels(searchedTeamId);
			// tslint:disable-next-line: no-floating-promises
			fetchProjectsData({ teamId: searchedTeamId }, { order: DashboardSortOrder.Ascending });
		}
	}, [searchedTeamId]);

	function onDismissTaskHighlights(task: IDashboardTaskDetailed) {
		if (dismissingTaskHighlight || !needAttentionTasks) {
			return;
		}
		const matchingTaskIndex = needAttentionTasks.tasks.findIndex((t) => t === task);
		if (matchingTaskIndex === -1) {
			return;
		}
		// tslint:disable-next-line: no-floating-promises
		dismissTaskHighlights(task).then(success => {
			if (success) {
				const tempTasks = [...needAttentionTasks.tasks];
				tempTasks.splice(matchingTaskIndex, 1);
				setNeedAttentionTasks({
					tasks: tempTasks,
					total: (needAttentionTasks.total > 0 ? needAttentionTasks.total - 1 : 0)
				});
			}
		});
	}

	function fetchTasks(staticGrouping: DashboardTaskStaticGroupingOption = DashboardTaskStaticGroupingOption.WorkStatus,
		dynamicGrouping: DashboardTaskDynamicGroupingOption | null = DashboardTaskDynamicGroupingOption.Highlights,
		limit?: number) {
		const filters = searchFilters || DEFAULT_FILTERS;
		let sprints: string[] | undefined;
		let startTime: Date | undefined;
		let endTime: Date | undefined;

		if (sprintFilter.sprintValue) {
			sprints = sprintFilter.sprintValue ? [sprintFilter.sprintValue] : undefined;
		} else {
			endTime = rangeEndTime || new Date();
			startTime = rangeStartTime || moment.utc(endTime).subtract(3, "months").toDate();
		}

		if (!isCustomDateRange) {
			setEndDate(endTime, startTime);
		}

		// tslint:disable-next-line: no-floating-promises
		fetchAllTasks({
			filter: {
				teamId: teamsStore.singleTeam.data.id,
				dataContributorIds: dataContributorFilter,
				sprints,
				labels: filters.labels,
				statuses: filters.statuses,
				types: filters.types,
				projectIds: filters.projectIds,
				startTime,
				endTime
			},
			staticGroupingLimit: limit,
			staticGrouping,
			dynamicGroupingLimit: limit,
			dynamicGrouping: dynamicGrouping ?? undefined,
			expand: [DashboardTaskExpandOption.Highlights, DashboardTaskExpandOption.Progress, DashboardTaskExpandOption.RelatedPRs]
		});
	}

	const getCounterLabel = () => {
		return !loading ? (inProgressTasks?.total ?? 0) + (backlogTasks?.total ?? 0) + (doneTasks?.total ?? 0) + ` Tasks` : undefined;
	};

	const selectFields: ISelectOptionBase[] = [
		{
			name: "projectIds",
			label: "projects",
			isLoading: projectsLoading,
			options: projectOptions
		},
		{
			name: "labels",
			label: "Labels",
			isLoading: isLoadingLabels,
			options: labelOptions
		},
		{
			name: "statuses",
			label: "Statuses",
			isLoading: false,
			options: taskStatusesOptions
		},
		{
			name: "types",
			label: "Issue Types",
			isLoading: false,
			options: taskTypeOptions
		},
	];
	const tableWithSidePanel = " main-table-with-panel";
	const handleTaskInPanelDismiss = useCallback(() => setSelectedTaskInPanel([undefined, undefined]), []);

	return (
		<div className="tasks-tab ongoing-pages ui sixteen column grid" >
			<ConfirmationModal
				className={"warning"}
				open={taskHighlightToDismiss !== undefined}
				header={"Need attention"}
				confirmButton={"Ok"}
				cancelButton={"Cancel"}
				content={`Acknowledge task and remove from attention section?`}
				onCancel={() => { setTaskHighlightToDismiss(undefined); }}
				onConfirm={() => { onDismissTaskHighlights(taskHighlightToDismiss!); setTaskHighlightToDismiss(undefined); }}
			/>
			{(selectedTaskInPanel[0] !== undefined && selectedTaskInPanel[1] !== undefined) &&
				<TaskDetailsDisplayPanel
					task={selectedTaskInPanel[0]}
					shouldShowNeedAttentionFlag={selectedTaskInPanel[1] === 0}
					onDismiss={handleTaskInPanelDismiss}
					onDismissTaskHighlights={(v => setTaskHighlightToDismiss(v))}
					outsidePanelClassName={tableWithSidePanel}
				/>
			}
			<FiltersTopNavbar
				filters={searchFilters || DEFAULT_FILTERS}
				applyFilters={setSearchFilters}
				popupSelectFields={selectFields}
				avatarSelectorField={{
					usersList: teamMembersStore.allTeamMembers.data.map(user => { user.id = user.dataContributorId; return user; }),
					singleUser: true,
					onUsersSelected: (selectedUsers: string[]) => {
						if (!_.isEqual(selectedUsers.sort(), dataContributorFilter.sort())) {
							setDataContributorFilter(selectedUsers);
						}
					},
					initialSelectedUsers: dataContributorFilter
				}}
				counterLabel={getCounterLabel()}
				clickEventPage={GA_EVENT_CATEGORY.TaskWork}
				classNameString={"right-padding"}
			>
				<div className="control-width">
					<FiltersSelector
						placeholder={"Select Filters"}
						value={getFilteredValueLabel()}
						clickEvent={clickEvent(GA_EVENT_CATEGORY.TaskWork, GA_EVENT_ACTION.Filter, "date-selector")}
						onClose={() => {
							if (!rangeEndTime && isCustomDateRange) {
								setStartTime(previousStartTime);
								setEndDate(previousEndTime, previousStartTime);
							}
						}}
					>
						<TimeFramesSelector
							value={sprintFilter.dateRangeValue}
							setValue={(value) => {
								setSelectedTaskInPanel([undefined, undefined]);
								setSelectedSelectorValue(value);
								setSprintFilter(prevState => {
									return {
										...prevState,
										id: `${prevState.selectedTeamId}-${value}`,
										dateRangeValue: value as DateRangeType,
										sprintValue: undefined,
										shouldReloadIfNoEnd: false
									};
								});
							}}
							isCustomDateRange={isCustomDateRange}
						/>
						<SprintsSelector
							value={sprintFilter.sprintValue}
							setValue={(value) => {
								setSelectedTaskInPanel([undefined, undefined]);
								setSelectedSelectorValue(value);
								setStartTime(undefined);
								setEndDate(undefined);
								setSprintFilter(prevState => {
									return {
										...prevState,
										id: `${prevState.selectedTeamId}-${value}`,
										sprintValue: value,
										dateRangeValue: undefined,
										shouldReloadIfNoEnd: false
									};
								});
							}}
							sprintsList={sprintsList}
						/>
						<DatePickerSelector
							locale={locale}
							isRangeSelector={isRangeSelector}
							setStartDate={(t) => {
								setSelectedTaskInPanel([undefined, undefined]);
								setSprintFilter(prevState => {
									return {
										...prevState,
										id: undefined,
										sprintValue: undefined,
										dateRangeValue: undefined,
										shouldReloadIfNoEnd: !!prevState.sprintValue
									};
								});
								setStartTime(t);
							}}
							startDate={rangeStartTime}
							setEndDate={(t) => {
								setSelectedTaskInPanel([undefined, undefined]);
								setEndDate(t);
								setSprintFilter(prevState => {
									return {
										...prevState,
										id: `${prevState.selectedTeamId}-${t?.toDateString()}`,
										sprintValue: undefined,
										dateRangeValue: undefined,
										shouldReloadIfNoEnd: t === undefined
											? prevState.shouldReloadIfNoEnd : false
									};
								});
							}}
							endDate={rangeEndTime}
						/>
					</FiltersSelector>
				</div>
			</FiltersTopNavbar>
			<h1>Tasks</h1>
			<div className="main-table acu-scroll set-page-z-index">
				<div className="ui sixteen wide column" >
					{loading && <Segment placeholder={true} loading={true} />}
					{!loading &&
						(
							<Accordion
								className={tableWithSidePanel}
								panels={
									[
										createPanel(0, "need-attention", "Need Attention", needAttentionTasks, activeIndex, () => {
											setActiveIndex(0);
											fetchTasks(DashboardTaskStaticGroupingOption.WorkStatus, DashboardTaskDynamicGroupingOption.Highlights, 0);
										}, selectedTaskInPanel[0], (v => setSelectedTaskInPanel([v, 0])), selectedTaskInPanel[1],
											((isEmpty) => isEmpty ? "gray-font flag" : "red flag"), (v => setTaskHighlightToDismiss(v)), taskEstimationMethod, EstimationColumnType.EstProgress,
											"Here are several selected tasks that Acumen has found might require your attention. This is based on insights and various data points and events. Click an item to see more information."),
										createPanel(1, "in-the-works", "In the Works", inProgressTasks, activeIndex, () => {
											setActiveIndex(1);
											fetchTasks(DashboardTaskStaticGroupingOption.WorkStatusInProgress, null, 0);
										}, selectedTaskInPanel[0], (v => setSelectedTaskInPanel([v, 1])), selectedTaskInPanel[1],
											undefined, undefined, taskEstimationMethod, EstimationColumnType.Progression),
										createPanel(2, "backlog", "Haven’t Started", backlogTasks, activeIndex, () => {
											setActiveIndex(2);
											fetchTasks(DashboardTaskStaticGroupingOption.WorkStatusBacklog, null, 0);
										}, selectedTaskInPanel[0], (v => setSelectedTaskInPanel([v, 2])), selectedTaskInPanel[1],
											undefined, undefined, taskEstimationMethod, EstimationColumnType.Value),
										createPanel(3, "done", "Done", doneTasks, activeIndex, () => {
											setActiveIndex(3);
											fetchTasks(DashboardTaskStaticGroupingOption.WorkStatusDone, null, 0);
										}, selectedTaskInPanel[0], (v => setSelectedTaskInPanel([v, 3])), selectedTaskInPanel[1],
											undefined, undefined, taskEstimationMethod, EstimationColumnType.Velocity),
									]
										.filter(p => Array.isArray(activeIndex) || p.index === activeIndex)
										.map((p, idx) => {
											p.index = idx;
											return p;
										})
								}
								activeIndex={Array.isArray(activeIndex) ? activeIndex : 0}
								exclusive={Array.isArray(activeIndex) ? false : true}
								styled={true}
								fluid={true}
								onTitleClick={(_e, { index, active }) => {
									if (!Array.isArray(activeIndex)) {
										fetchTasks();
										setActiveIndex([0, 1, 2, 3]);
									} else {
										if (!active) {
											setActiveIndex(_.uniq([...activeIndex, index as number]));
										} else {
											setActiveIndex(_.uniq(_.filter(activeIndex, (i) => i !== index)));
										}
									}
								}}
							/>
						)}
				</div>
			</div>
		</div>
	);
});

const shouldStatusHaveTooltip = (status: IDashboardStatus | null): boolean => {
	return (status?.customerStatusName?.length ?? 0) > 11;
};

export default TasksPage;
