import urlJoin from "url-join";
import { CommonEndPoints, IDashboardResponse } from "@acumen/dashboard-common";
import { APIContextProvider } from "../../../services/api-context-provider";
import { getData, postData } from "../../../services/fetch-helpers";
import { BaseCustomerApiClient } from "./base-customer-api-client";
export interface ICrudApiClient
	<T, TFindParams, TUpdatePartial, TRootParams, TAddPartial, TMetadata = any> {
	readonly create?: (entity: TAddPartial & TRootParams) => Promise<IDashboardResponse<T, TMetadata> | null>;
	readonly findAll?: (rootParams?: TRootParams) => Promise<IDashboardResponse<T[], TMetadata> | null>;
	readonly findOne?: (findParams: TFindParams & TRootParams) =>
		Promise<IDashboardResponse<T, TMetadata> | null>;
	readonly findCondition?: (partialEntity: Partial<T> & TRootParams) =>
		Promise<IDashboardResponse<T, TMetadata> | null>;
	readonly update?: (partialEntity: TUpdatePartial & TRootParams) =>
		Promise<IDashboardResponse<T, TMetadata> | null>;
	readonly delete?: (findParams: TFindParams & TRootParams) =>
		Promise<IDashboardResponse<T, TMetadata> | null>;
}

export interface IRouteUrlsMapping {
	[key: string]: (basePath: string, id?: string) => string;
}

export abstract class BaseCrudApiClient
	<T, TUpdatePartial, TFindParams, TMetadata = any, TRootParams = void, TAddPartial = any>
	extends BaseCustomerApiClient
	implements ICrudApiClient<T, TFindParams, TUpdatePartial, TRootParams, TAddPartial, TMetadata> {

	protected readonly _createRoutePath: IRouteUrlsMapping;

	constructor(protected readonly routeBasePath: string,
		apiContextProvider: APIContextProvider) {
		super(apiContextProvider);

		this._createRoutePath = {
			[CommonEndPoints.CREATE]: (basePath: string) => this.createCustomerEntityRoute(
				urlJoin(basePath, CommonEndPoints.CREATE)),

			[CommonEndPoints.DELETE]: (basePath: string, idPath: string = "") => this.createCustomerEntityRoute(
				urlJoin(basePath, idPath, CommonEndPoints.DELETE)),

			[CommonEndPoints.FIND_ALL]: (basePath: string) => this.createCustomerEntityRoute(
				urlJoin(basePath, CommonEndPoints.FIND_ALL)),

			[CommonEndPoints.FIND_CONDITION]: (basePath: string) => this.createCustomerEntityRoute(
				urlJoin(basePath, CommonEndPoints.FIND_CONDITION)),

			[CommonEndPoints.FIND_ONE]: (basePath: string, idPath: string = "") => this.createCustomerEntityRoute(
				urlJoin(basePath, idPath, CommonEndPoints.FIND_ONE)),

			[CommonEndPoints.UPDATE]: (basePath: string, idPath: string = "") => this.createCustomerEntityRoute(
				urlJoin(basePath, idPath, CommonEndPoints.UPDATE))
		};
	}

	protected abstract convertFindParamsToPath(findParams: TFindParams): string;

	protected abstract convertPartialEntityToPath(partialEntity: TUpdatePartial): string;

	protected createBasePathFromRootParams(_rootParams?: TRootParams): string {
		return this.routeBasePath;
	}

	public findAll = async (rootParams?: TRootParams) => {
		return await getData<T[], TMetadata>(
			this._createRoutePath[CommonEndPoints.FIND_ALL](this.createBasePathFromRootParams(rootParams)),
			this.token,
			this.tokenType
		);
	}

	public findOne = async (findParams: TFindParams & TRootParams) => {
		return await getData<T, TMetadata>(
			this._createRoutePath[CommonEndPoints.FIND_ONE](this.createBasePathFromRootParams(findParams),
				this.convertFindParamsToPath(findParams)),
			this.token,
			this.tokenType
		);
	}

	public findCondition = async (partialEntity: Partial<T> & TRootParams) => {
		return await postData<Partial<T>, T, TMetadata>(
			this._createRoutePath[CommonEndPoints.FIND_CONDITION](this.createBasePathFromRootParams(partialEntity)),
			this.token,
			partialEntity,
			this.tokenType
		);
	}

	public update = async (partialEntity: TUpdatePartial & TRootParams) => {
		return await postData<TUpdatePartial, T, TMetadata>(
			this._createRoutePath[CommonEndPoints.UPDATE](this.createBasePathFromRootParams(partialEntity),
				this.convertPartialEntityToPath(partialEntity)),
			this.token,
			partialEntity,
			this.tokenType
		);
	}

	public delete = async (findParams: TFindParams & TRootParams) => {
		return await postData<Partial<T>, T, TMetadata>(
			this._createRoutePath[CommonEndPoints.DELETE](this.createBasePathFromRootParams(findParams),
				this.convertFindParamsToPath(findParams)),
			this.token,
			{},
			this.tokenType
		);
	}

	public create = async (entity: TAddPartial & TRootParams) => {
		return await postData<TAddPartial, T, TMetadata>(
			this._createRoutePath[CommonEndPoints.CREATE](this.createBasePathFromRootParams(entity)),
			this.token,
			entity,
			this.tokenType
		);
	}
}
