import React from "react";
import DeveloperBadgeLowActivityEmptyStateImage from "./../../../../components/svg-assets/developer-badge-low-activity-empty-state.svg";
import "./developer-badge-low-activity-empty-state.scss";

export const DeveloperBadgeLowActivityEmptyState = () => {
	return (
		<div className="developer-badge-low-activity-empty-state">
			<div className="developer-badge-low-activity-empty-state-image">
				<img className="emptyStateIcon" src={DeveloperBadgeLowActivityEmptyStateImage} alt="Low activity badge" />
			</div>
			<div className="developer-badge-low-activity-empty-state-header">
				Not enough active days
			</div>
			<div className="developer-badge-low-activity-empty-state-subtitle">
				Team members need to be active more than 50% of selected time period to get badges
			</div>
		</div>
	);
};
