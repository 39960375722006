import apiContextProvider from "../../services/api-context-provider";
import { action, observable } from "mobx";
import { FetchLatestRequest } from "../../services/fetch-helpers";
import { IDashboardStatusMapping } from "@acumen/dashboard-common";
import { ROUTE, StatusMappingApiClient } from "../services/crud/status-mapping-api-client";
import _ from "lodash";

export default class JiraStatusMappingStore {
	private readonly apiClient: StatusMappingApiClient = new StatusMappingApiClient(apiContextProvider);

	@observable
	public statusMappingData: IDashboardStatusMapping[] = [];

	@observable
	public isLoading: boolean = false;

	private fetchLatestStatusMapping = new FetchLatestRequest<IDashboardStatusMapping[], any>(ROUTE);

	@action.bound
	async fetchData(): Promise<IDashboardStatusMapping[] | null> {
		this.isLoading = true;
		const response = await this.fetchLatestStatusMapping.fetchLatest(this.apiClient.fetchAll());
		const sortedData = response?.data
			? _.orderBy(response.data, [x => x.projectName.toLowerCase(), x => x.jiraStatusName.toLowerCase()])
			: [];
		this.statusMappingData = sortedData;
		this.isLoading = false;
		return sortedData;
	}
}
