import React from "react";
import {
	useState,
	ChangeEvent,
	FormEvent,
	useCallback,
	useEffect,
	useRef,
} from "react";
import classnames from "classnames";
import { useOnClickOutside } from "usehooks-ts";
import CloseButton from "../svg-assets/DialogCloseButton";
import { NewPlanningPokerIssue } from "../../../services/crud/go-retro-planning-poker-api-client";

export const FAKE_ISSUE_KEY_PREFIX = "PP-";
export interface AddIssueModalProps {
	nextFakeIssueKey: number;
	onClose: (addedIssue?: NewPlanningPokerIssue) => void;
}

export function AddIssueModal({
	nextFakeIssueKey,
	onClose,
}: AddIssueModalProps) {
	const [title, setTitle] = useState<string | undefined>(undefined);
	const [description, setDescription] = useState<string | undefined>(undefined);
	const [issueKey, setIssueKey] = useState<string | undefined>(undefined);
	const [link, setLink] = useState<string | undefined>(undefined);
	const ref = useRef(null);

	useOnClickOutside(ref, () => onClose());

	const onEscape = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === "Escape" && onClose) {
				onClose();
			}
		},
		[onClose]
	);

	useEffect(() => {
		document.addEventListener("keydown", onEscape, false);

		return () => {
			document.removeEventListener("keydown", onEscape, false);
		};
	}, [onEscape]);

	return (
		<div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 backdrop-blur-sm backdrop-brightness-50 bg-black/30">
			<div className="relative h-full w-full max-w-2xl p-4 md:h-auto">
				<div
					className="relative rounded-lg bg-[#FFFEFE]/95 w-3/4 h-5/6 border border-[#CED5DF] rounded-lg shadow"
					ref={ref}
				>
					<button
						type="button"
						className="absolute top-3 right-2.5 ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
						onClick={() => onClose()}
					>
						<CloseButton className="h-5 w-5" />
					</button>
					<div className="py-6 px-6 lg:px-8">
						<h3 className="mb-2 text-2xl font-bold tracking-wider text-[#399CFF] capitalize">
							Add issue
						</h3>
						<div className="space-y-4">
							<div>
								<label
									htmlFor="title"
									className="block text-base text-[#8791A2]"
								>
									Title

									<span
										className={classnames(
											"text-xs ml-1 text-[#8791A2]"
										)}>
										(required)
									</span>
								</label>
								<input
									type="text"
									name="title"
									id="title"
									className="block mt-1 w-full px-2 py-1 text-sm font-medium text-gray-700 placeholder-gray-400 bg-white shadow-sm border border-gray-300 rounded-md focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
									placeholder="Learning Capabilities and Self-Awareness"
									required={true}
									onChange={(event: ChangeEvent<HTMLInputElement>) =>
										setTitle(event.target.value)
									}
								/>
							</div>
							<div>
								<label
									htmlFor="description"
									className="block text-base text-[#8791A2]"
								>
									Description
								</label>
								<textarea
									rows={4}
									name="description"
									id="description"
									className="block mt-1 w-full px-2 py-1 leading-5 text-sm font-medium text-gray-700 placeholder-gray-400 bg-white shadow-sm border border-gray-300 rounded-md focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
									placeholder="Upgrade the 800 series Learning Capabilities and Self-Awareness from read to read-write so it can learn new skills"
									required={false}
									onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
										setDescription(event.target.value)
									}
								/>
							</div>
							<div>
								<label htmlFor="key" className="block text-base text-[#8791A2]">
									Key
								</label>
								<input
									type="text"
									name="key"
									id="key"
									className="block mt-1 w-full px-2 py-1 text-sm font-medium text-gray-700 placeholder-gray-400 bg-white shadow-sm border border-gray-300 rounded-md focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
									placeholder="T-800"
									required={false}
									onChange={(event: ChangeEvent<HTMLInputElement>) =>
										setIssueKey(event.target.value)
									}
								/>
							</div>
							<div>
								<label
									htmlFor="link"
									className="block text-base text-[#8791A2]"
								>
									Link
								</label>
								<input
									type="text"
									name="link"
									id="link"
									className="block mt-1 w-full px-2 py-1 text-sm font-medium text-gray-700 placeholder-gray-400 bg-white shadow-sm border border-gray-300 rounded-md focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
									placeholder="https://skynet.atlassian.net/browse/T-800"
									required={false}
									onChange={(event: ChangeEvent<HTMLInputElement>) =>
										setLink(event.target.value)
									}
								/>
							</div>
						</div>

						<div className="mt-8 flex flex-row justify-center space-x-4">
							<button
								type="button"
								className="w-36 py-3 border border-[#399CFF] text-xl rounded font-normal text-[#399CFF] hover:bg-gray-200 transition duration-300 ease-in-out"
								onClick={async (event: FormEvent) => {
									event.preventDefault();
									onClose();
								}}
							>
								Cancel
							</button>
							<button
								type="button"
								className={classnames(
									"w-36 py-3 bg-[#399CFF] border border-[#399CFF] text-xl rounded font-normal text-white transition duration-300 ease-in-out",
									{
										"cursor-not-allowed": !title || title.trim().length === 0,
									},
									{
										"bg-[#399CFF] hover:border-[#338CE5] hover:bg-[#338CE5]": title && title.trim().length > 0,
									}
								)}
								onClick={async (event: FormEvent) => {
									if (title && title.trim().length > 0) {
										event.preventDefault();
										onClose({
											title,
											description: description ?? null,
											key: issueKey || `${FAKE_ISSUE_KEY_PREFIX}${nextFakeIssueKey}`,
											link: link ?? null,
											note: null,
										});
									}
								}}
							>
								Save Issue
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
