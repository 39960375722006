import React from "react";
import "./trend-card.scss";
import { TrendItem } from "../../types";
import LineChart from "../../charts/line-chart";
import { TrendIndicator } from "../trend-indicator/trend-indicator";
import { TrendTotal } from "../trend-total/trend-total";
import { Loader, Popup } from "semantic-ui-react";

const DEFAULT_CARD_COLOR = "#8791A2";

export interface TrendCardProps {
	trend: TrendItem;
}

export const TrendCard = (props: TrendCardProps) => {
	const { trend } = props;
	const color = trend.cardData?.color ?? DEFAULT_CARD_COLOR;

	return (
		<div className="trend-card-v2">
			<h3 className="trend-card-title" style={{ color }}>
				{trend.cardData?.title ? (
					<Popup
						hoverable={true}
						wide={true}
						className="tiny-text"
						position="top center"
						content={trend.cardData.tooltipText}
						trigger={<span>{trend.cardData.title}</span>}
					/>
				) : (
					<Loader
						size="small"
						active={true}
						inline={true}
						inverted={true}
					/>
				)}
			</h3>
			<h4 className="trend-card-subtitle">{trend.cardData?.secondaryTitle}</h4>

			<div className="trend-card-total-container">
				<TrendTotal
					size="medium"
					currentPeriodValue={trend.metric?.currentPeriodValue}
					unit={trend.metric?.changeFromPreviousPeriod != null ? trend.cardData?.units : null}
				/>
			</div>

			<div className="trend-card-indicator-container">
				<TrendIndicator
					changeFromPreviousPeriod={trend.metric?.changeFromPreviousPeriod}
					reaction={trend.metric?.reaction}
				/>
			</div>

			<div className="trend-card-chart-container">
				<LineChart
					color={color}
					height={50}
					opacity={1}
					series={trend.series}
				/>
			</div>
		</div>
	);
};
