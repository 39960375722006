import React, { useMemo } from "react";
import { Popup } from "semantic-ui-react";
import classNames from "classnames";
import moment from "moment";

import { IDashboardSprint } from "@acumen/dashboard-common";
import TabCard from "./tab-card";

interface SprintDatesProps {
	sprint: IDashboardSprint | undefined;
	timezone: string | undefined;
}

const DEFAULT_DATE = "MM/DD";
const SPRINT_DATE_TIME_FORMAT = "DD/MMM/YYYY hh:mm A";

const SprintDates = (props: SprintDatesProps) => {
	const { sprint, timezone } = props;
	const { sprintStart, sprintEnd, sprintStartDateTime, sprintEndDateTime, isSprintCompleted } = useMemo(() => {
		return getSprintDates(sprint, timezone);
	}, [sprint, timezone]);

	return (
		<Popup
			hoverable={true}
			wide={true}
			className="tiny-text sprint-date-time-tooltip"
			position="bottom center"
			content={
				<div className="sprint-date-time-tooltip-content">
					<div className="sprint-date-time">
						Start Date
						<p>{sprintStartDateTime}</p>
					</div>

					<div className="sprint-date-time">
						End Date
						<p>{sprintEndDateTime}</p>
					</div>
				</div>
			}
			trigger={
				<div className={classNames("ui card header tabs", "table-shadow", "sprint-dates")}>
					<div className="card-padding">
						<TabCard
							value={sprintStart}
							tooltip=""
							title="started"
							isDisabled={!sprint}
							isActive={true}
						/>
						<TabCard
							value={sprintEnd}
							tooltip=""
							title={isSprintCompleted ? "completed" : "end date"}
							isDisabled={!sprint}
							isActive={true}
						/>
					</div>
				</div>
			}
		/>
	);
};

const getSprintDateFormat = (date: moment.Moment | undefined, extendedFormat: boolean = false): string => {
	if (!date) {
		return DEFAULT_DATE;
	}

	const dateFormat = extendedFormat ? "MMM DD, YY" : "MMM DD";

	return date.format(dateFormat);
};

const getFormattedDateTime = (date: moment.Moment | undefined): string => {
	if (!date) {
		return SPRINT_DATE_TIME_FORMAT;
	}

	return date.format(SPRINT_DATE_TIME_FORMAT);
};

const getSprintDates = (sprint: IDashboardSprint | undefined, timezone: string | undefined) => {
	if (!sprint) {
		return {
			sprintStart: DEFAULT_DATE,
			sprintEnd: DEFAULT_DATE,
			sprintStartDateTime: SPRINT_DATE_TIME_FORMAT,
			sprintEndDateTime: SPRINT_DATE_TIME_FORMAT,
			isSprintCompleted: false
		};
	}
	const isCompleted = !!sprint.completeDate;
	const startDate = sprint.startDate ? new Date(sprint.startDate) : undefined;
	const end = sprint.completeDate ?? sprint.endDate;
	const endDate = end ? new Date(end) : undefined;
	const localizedStartDate = startDate ? (timezone ? moment(startDate).tz(timezone) : moment(startDate)) : undefined;
	const localizedEndDate = endDate ? (timezone ? moment(endDate).tz(timezone) : moment(endDate)) : undefined;
	const startYear = localizedStartDate ? localizedStartDate.year() : undefined;
	const endYear = localizedEndDate ? localizedEndDate.year() : undefined;

	const isDifferentYears = startDate && endDate && startYear !== endYear;

	return {
		sprintStart: getSprintDateFormat(localizedStartDate, isDifferentYears),
		sprintEnd: getSprintDateFormat(localizedEndDate, isDifferentYears),
		sprintStartDateTime: getFormattedDateTime(localizedStartDate),
		sprintEndDateTime: getFormattedDateTime(localizedEndDate),
		isSprintCompleted: isCompleted
	};
};

export default SprintDates;
