import React from "react";
import { TabModel } from "./types";
import { Tab } from "./tab/tab";
import "./tabs.scss";

export interface TabsProps {
	tabs: TabModel[];
	activeTabName: string;
	onActiveTabChange: (tabName: string) => void;
}

export const Tabs = (props: TabsProps) => {
	const { tabs, activeTabName, onActiveTabChange } = props;

	return (
		<div className="tabs">
			{tabs.map(tab => (
				<Tab
					key={tab.name}
					name={tab.name}
					title={tab.title}
					isActive={tab.name === activeTabName}
					onClick={onActiveTabChange}
				/>
			))}
			<div className="tabs-border-filler"/>
		</div>
	);
};
