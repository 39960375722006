import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";
import { useStores } from "../../../../mobx-stores";
import LoadingIndicator from "../../../../components/loader";
import { AcumenTaskType, IDashboardSprint, MetricInterval } from "@acumen/dashboard-common";
import { IDistributionClassificationData } from "../../../../mobx-stores/metric-store";
import colorScheme from "./chart-color-scheme";
import {
	CategoryChartSeriesData, isSameIntervalCategory, isValidChartData, packSprintByIdIfNeeded,
	prepareGroupedCategorySeriesDataByDate, prepareGroupedCategorySeriesDataBySprint
} from "./charts";
import { v4 as generateUUID } from "uuid";
import { round } from "@acumen/common";

interface IDistributionClassificationProps {
	isFullScreen?: boolean;
	setDisableFullScreen?: (disable: boolean) => void;
	filters: {
		teamId?: string;
		dataContributorIds?: string[];
		startTime?: Date;
		endTime?: Date;
		interval?: MetricInterval;
		projectIds?: string[];
		boardIds?: string[];
		gitRepositoryIds?: string[];
		customerSprints?: IDashboardSprint[];
		issueTypes?: AcumenTaskType[];
		timezone: string;
	};
}

interface ISeriesProps {
	indexOrder: number;
	legendOrder: number;
	seriesName: string;
	seriesColorHex: string;
}

const DISPLAY_PROPERTIES: { [key: string]: ISeriesProps } = {
	adaptive: {
		indexOrder: 3,
		legendOrder: 1,
		seriesName: "New features and capabilities",
		seriesColorHex: colorScheme.commitClassificationTypes.adaptive
	},
	corrective: {
		indexOrder: 2,
		legendOrder: 2,
		seriesName: "Bug fixes and tweaks",
		seriesColorHex: colorScheme.commitClassificationTypes.corrective
	},
	perfective: {
		indexOrder: 1,
		legendOrder: 3,
		seriesName: "Refactoring",
		seriesColorHex: colorScheme.commitClassificationTypes.perfective
	},
};

export const DISTRIBUTION_CLASSIFICATION_DATA = {
	title: "Maker time split by type",
	description: `Shows the split between the different types of commits done by the team.
	Maker time is the metric used for weight.`,
};

function DistributionClassificationChart(props: IDistributionClassificationProps) {
	const { metricStore } = useStores();
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const { fetchData } = metricStore.distributionClassificationData();
	const { distributionClassificationDataStored } = metricStore;
	const [chartData, setChartData] = useState<IDistributionClassificationData | undefined>(distributionClassificationDataStored);

	useEffect(() => {
		if (props.isFullScreen) {
			return;
		}
		if (Object.keys(props.filters).length === 0) {
			return;
		}

		let isMounted = true;
		async function fetch() {
			setIsFetching(true);
			if (props.setDisableFullScreen) {
				props.setDisableFullScreen(true);
			}
			const data = await fetchData(props.filters.teamId,
				props.filters.dataContributorIds,
				props.filters.projectIds,
				props.filters.startTime,
				props.filters.endTime,
				props.filters.interval,
				props.filters.timezone,
				props.filters.gitRepositoryIds,
				props.filters.boardIds,
				props.filters.issueTypes);
			if (isMounted && data) {
				setIsFetching(false);
				setChartData(data);
			}
			if (props.setDisableFullScreen) {
				props.setDisableFullScreen(false);
			}
		}

		// tslint:disable-next-line: no-floating-promises
		fetch();
		return () => { isMounted = false; };
	}, [props.filters]);

	let series: Highcharts.SeriesColumnOptions[] | undefined;

	if (isValidChartData<IDistributionClassificationData>(chartData) &&
		isSameIntervalCategory(chartData.sumOfCommitActualEffort.interval, props.filters.interval)) {
		let seriesData: CategoryChartSeriesData;
		const sprintById = packSprintByIdIfNeeded(props.filters.interval, props.filters.customerSprints);
		if (sprintById) {
			seriesData = prepareGroupedCategorySeriesDataBySprint(chartData.sumOfCommitActualEffort, sprintById);
		} else {
			seriesData = prepareGroupedCategorySeriesDataByDate(chartData.sumOfCommitActualEffort, props.filters.timezone);
		}

		series = Object
			.keys(chartData.sumOfCommitActualEffort.values)
			.map(key => {
				const displayProperties = DISPLAY_PROPERTIES[key];
				const seriesName = (displayProperties !== undefined ? displayProperties.seriesName : key);
				return {
					id: generateUUID(),
					name: seriesName,
					index: displayProperties?.indexOrder,
					legendIndex: displayProperties?.legendOrder,
					color: displayProperties?.seriesColorHex,
					data: seriesData[key],
					type: "column",
					visible: true,
					tooltip: {
						useHTML: true,
						pointFormatter: function _formatter() {
							const workPrcnt = this.percentage ? round(this.percentage, 0) : 0;
							return `<span style="color:${displayProperties?.seriesColorHex}">\u25CF </span><strong>${seriesName}</strong><br/><strong>${workPrcnt}%</strong>`;
						},
					},
					stack: "effort"
				};
			});
	} else {
		series = [
			{
				data: [],
				visible: false,
				name: "classification",
				type: "column",
				stack: "effort"
			}
		];
	}

	const options: Highcharts.Options = {
		chart: {
			type: "column",
			zoomType: "xy"
		},
		title: undefined,
		tooltip: {
			split: true,
		},
		xAxis: {
			gridLineWidth: 1,
			type: (props.filters.interval === MetricInterval.SPRINT_DATE ? "category" : "datetime"),
			uniqueNames: false
		},
		yAxis: {
			min: 0,
			title: {
				text: "Percentage"
			},
			labels: {
				// tslint:disable-next-line: object-literal-shorthand
				formatter: function _formatter() {
					return `${this.value}%`;
				}
			}
		},
		plotOptions: {
			column: {
				pointPadding: 0.2,
				borderWidth: 0,
				stacking: "percent",
				dataLabels: {
					enabled: true,
					// tslint:disable-next-line: object-literal-shorthand
					formatter: function _formatter() {
						if (!this.y || !this.total || this.y === 0 || this.total === 0) {
							return "0%";
						}
						return `${round(100 * this.y / this.total, 0)}%`;
					},
				},
			},
			series: {
				dataLabels: {
					enabled: true,
					// tslint:disable-next-line: object-literal-shorthand
					formatter: function _formatter() {
						if (!this.y) {
							return "0%";
						}
						return `${round(this.y)}%`;
					}
				}
			},
		},
		credits: {
			enabled: false
		},
		series
	};

	return (
		<div className="description">
			<LoadingIndicator local={true} isActive={!isValidChartData(chartData) || isFetching}>
				<HighchartsReact
					highcharts={Highcharts}
					options={options}
				/>
			</LoadingIndicator>
		</div>
	);
}

export default DistributionClassificationChart;
