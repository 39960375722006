import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { DeveloperViewModel } from "../../types";
import { CustomTable } from "../../../../components/custom-table/custom-table";
import {
	CustomTableColumnProps,
	CustomTableFilter,
	ResolvedCustomTableColumnProps
} from "../../../../components/custom-table/types";
import { DeveloperInfoCellContent } from "../developer-info-cell-content/developer-info-cell-content";
import { createDeveloperStatCellContent } from "../developer-stat-cell-content/developer-stat-cell-content";
import {
	DeveloperStatsTableAggregatedDataRow
} from "../developer-stats-table-aggregated-data-row/developer-stats-table-aggregated-data-row";
import { DevStatColumn, ESTIMATION_UNITS } from "../../dev-stat-columns";
import Plus from "v2/components/svg-assets/plus.svg";
import { DeveloperDetailsSidePanel } from "../developer-details-side-panel/developer-details-side-panel";
import { DeveloperBadge, TaskEstimationMethod, WorkforceHealthMetrics } from "@acumen/database-types";
import { MetricData } from "../../../team-comparison/types";
import "./developer-stats-table.scss";
import { getShortEstimationMethodName } from "v2/helpers/formatting";
import _ from "lodash";
import { CustomSelectOption } from "v2/components";

const FILTER_BY_BADGE_GROUP_TITLE = "Filter by badge";
const FILTER_BY_ROLE_GROUP_TITLE = "Filter by role";

const BADGE_FILTER_OPTIONS: Array<CustomSelectOption<string | number>> = [
	{ label: "All", value: null, group: FILTER_BY_BADGE_GROUP_TITLE },
	{ label: "Superstar", value: DeveloperBadge.SuperStar, group: FILTER_BY_BADGE_GROUP_TITLE },
	{ label: "On the rise", value: DeveloperBadge.OnTheRise, group: FILTER_BY_BADGE_GROUP_TITLE },
	{ label: "Needs Attention", value: DeveloperBadge.NeedAttention, group: FILTER_BY_BADGE_GROUP_TITLE },
	{ label: "Team Player", value: DeveloperBadge.TeamPlayer, group: FILTER_BY_BADGE_GROUP_TITLE },
];
export interface DeveloperStatsTableProps {
	selectedDashboardMetrics: DevStatColumn[];
	developers: DeveloperViewModel[];
	metricData: MetricData;
	onAddColumnClick?: () => void;
	onCellClick?: (dcId: string, metricColumn: DevStatColumn) => void;
	estimationMethod: TaskEstimationMethod;
}

export const DeveloperStatsTable = (props: DeveloperStatsTableProps) => {
	const {
		selectedDashboardMetrics, developers, metricData, onAddColumnClick,
		estimationMethod, onCellClick = (dcId, metricColumn) => undefined } = props;
	const drawerRef = useRef<HTMLDivElement>(null);
	const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
	const [expandedDeveloper, setExpandedDeveloper] = React.useState<DeveloperViewModel | null>(null);
	const handleCloseDrawer = useCallback(() => setIsDrawerOpen(false), []);
	const handleDeveloperProfileClick = useCallback((developer: DeveloperViewModel) => {
		if (expandedDeveloper === developer) {
			handleCloseDrawer();
			return;
		}

		setIsDrawerOpen(true);
		setExpandedDeveloper(developer);
	}, [expandedDeveloper, handleCloseDrawer]);
	const compareStatBy = useCallback((
		developer: DeveloperViewModel,
		column: ResolvedCustomTableColumnProps<DeveloperViewModel>,
	) => {
		return metricData.get(developer.info.dataContributorId, column.key as WorkforceHealthMetrics, "current");
	}, [metricData]);
	const columns = useMemo<Array<CustomTableColumnProps<DeveloperViewModel>>>(() => {
		const labels = _.chain(developers.map(d => d.info.labels))
			.flatten()
			.uniqBy(x => x.customerLabelId)
			.value();
		let filterOptions = BADGE_FILTER_OPTIONS;
		if (labels.length > 0) {
			const labelFilterOptions =
				labels.map(l => ({ label: l.name, value: l.customerLabelId, group: FILTER_BY_ROLE_GROUP_TITLE }));
			filterOptions = [...filterOptions, ...labelFilterOptions];
		}
		return [
			{
				key: "developer",
				title: "Developer",
				sticky: true,
				component: DeveloperInfoCellContent,
				filterable: {
					options: filterOptions
				},
				sortBy: sortDeveloperBy,
				compareBy: compareDeveloperBy,
				onClick: handleDeveloperProfileClick,
			},
			...selectedDashboardMetrics.map(column => {
				const tooltip =
					column.displayValues.devTooltip.replace(ESTIMATION_UNITS, getShortEstimationMethodName(estimationMethod));

				return ({
					key: column.dashboardMetricName,
					title: column.displayValues.title,
					component: createDeveloperStatCellContent(column, metricData, onCellClick),
					sortBy: compareStatBy,
					tooltip
				});
			}),
		];
	}, [selectedDashboardMetrics, metricData, developers, handleDeveloperProfileClick]);

	useEffect(() => {
		if (isDrawerOpen) {
			return;
		}

		const handleTransitionEnd = () => setExpandedDeveloper(null);

		drawerRef.current?.addEventListener("transitionend", handleTransitionEnd, {
			once: true,
		});

		return () => {
			drawerRef.current?.removeEventListener("transitionend", handleTransitionEnd);
		};
	}, [isDrawerOpen]);

	return (
		<div className="developer-stats-table">
			{onAddColumnClick && (
				<button className="developer-stats-table-add-column-button" onClick={onAddColumnClick}>
					<img src={Plus} alt="+"/>
				</button>
			)}

			<div className="developer-stats-table-grid-container">
				<CustomTable
					getItemKey={getDeveloperKey}
					columns={columns}
					items={developers}
					stickyHeader={true}
					sortable={true}
					topBody={
						<DeveloperStatsTableAggregatedDataRow
							items={developers}
							selectedDashboardMetrics={selectedDashboardMetrics}
							metricData={metricData}
						/>
					}
				/>
			</div>

			<DeveloperDetailsSidePanel
				ref={drawerRef}
				developer={expandedDeveloper}
				metricData={metricData}
				isOpen={isDrawerOpen}
				onClose={handleCloseDrawer}
			/>
		</div>
	);
};

const sortDeveloperBy = (developer: DeveloperViewModel) => {
	return developer.info.displayName ?? "";
};

const getDeveloperKey = (developer: DeveloperViewModel) => {
	return developer.info.dataContributorId;
};

const compareDeveloperBy = (
	developer: DeveloperViewModel,
	column: ResolvedCustomTableColumnProps<DeveloperViewModel>,
	filter: CustomTableFilter<DeveloperViewModel>,
) => {
	if (!filter.groupName || filter.groupName === FILTER_BY_BADGE_GROUP_TITLE) {
		return developer.badges.aggregatedBadges.find(badge => filter.selectedValues.includes(badge)) ?? -1;
	} else if (filter.groupName === FILTER_BY_ROLE_GROUP_TITLE) {
		return developer.info.labels.find(l => filter.selectedValues.includes(l.customerLabelId))?.customerLabelId ?? -1;
	}

	return -1;
};
