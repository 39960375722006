import React, { useEffect, useState } from "react";
import { clickEvent, GA_EVENT_ACTION, GA_EVENT_CATEGORY } from "../../../analytics-events";
import FiltersTopNavbar from "../../../components/filters-top-navbar";
import { useStores } from "../../../mobx-stores";
import { observer } from "mobx-react";
import { AcumenIdentityType, IDashboardDetailedDataContributor, IdentityType } from "@acumen/dashboard-common";
import ContributorsTable from "./contributors-table";
import useLocalStorage from "../../../hooks/useLocalStorage";
import _ from "lodash";
import moment from "moment";
import DropdownSelector, { IDropdownOption } from "../../../components/form/dropdown-selector";
import LoadingIndicator from "../../../components/loader/loader";
import classNames from "classnames";
import { Button, Header, Modal, Feed } from "semantic-ui-react";
import { Avatar } from "v2/components/avatar";

export type IContributorsActionType = "Remove" | "Add";
export interface IContributorsActions {
	type: IContributorsActionType;
	action: (dc: IDashboardDetailedDataContributor) => void;
	style?: string;
}
enum LastActiveTimeOption {
	Week = "Week",
	Month = "Month",
	ThreeMonths = "ThreeMonths",
	SixMonths = "SixMonths",
	OneYear = "OneYear",
	AllTimes = "AllTimes"
}

const TIME_SPAN_OPTIONS: Record<LastActiveTimeOption, string> = {
	[LastActiveTimeOption.Week]: "Last week",
	[LastActiveTimeOption.Month]: "Last month",
	[LastActiveTimeOption.ThreeMonths]: "Last 3 months",
	[LastActiveTimeOption.SixMonths]: "Last 6 months",
	[LastActiveTimeOption.OneYear]: "Last year",
	[LastActiveTimeOption.AllTimes]: "All time"
};
interface IFilterOptions {
	lastActive: LastActiveTimeOption;
}

const FILTER_LOCAL_STORAGE_KEY = "account_contributors_filters";

const DEFAULT_FILTERS: IFilterOptions = {
	lastActive: LastActiveTimeOption.SixMonths
};

const TIME_SPAN_TO_HOURS_DIFF: Record<LastActiveTimeOption, number> = {
	[LastActiveTimeOption.Week]: 168,
	[LastActiveTimeOption.Month]: moment().daysInMonth() * 24,
	[LastActiveTimeOption.ThreeMonths]: moment().diff(moment().subtract(3, "months"), "hours"),
	[LastActiveTimeOption.SixMonths]: moment().diff(moment().subtract(6, "months"), "hours"),
	[LastActiveTimeOption.OneYear]: moment().diff(moment().subtract(12, "months"), "hours"),
	[LastActiveTimeOption.AllTimes]: moment().diff(new Date(0), "hours")
};

const identityTypeDisplayName: Record<AcumenIdentityType, string> = {
	[IdentityType.GITHUB_COLLABORATOR]: "GitHub",
	[IdentityType.GITHUB_CONTRIBUTOR]: "GitHub",
	[IdentityType.SLACK]: "Slack",
	[IdentityType.JIRA]: "Jira",
	[IdentityType.ACUMEN]: "Acumen"
};
// tslint:disable-next-line: variable-name
const Contributors = () => {
	const { dataContributorsStore: {
		fetchUnMergedDataContributors,
		unMergedDataContributors,
		toMergeDataContributors,
		filteredUnMergedContributors,
		updateToMergeList,
		clearToMergeList,
		sortDataContributors,
		sortUnMergedDataContributorsByFilter,
		sortToBeMergedDataContributorsByFilter,
		mergeDataContributors,
	} } = useStores();

	const [currentPage, setPage] = useState<number>(1);
	const [searchFilters, setSearchFilters] =
		useLocalStorage<IFilterOptions>(FILTER_LOCAL_STORAGE_KEY, DEFAULT_FILTERS);
	const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
	const [isMerging, setIsMerging] = useState<boolean>(false);
	const [openMergeDialog, setOpenMergeDialog] = useState<boolean>(false);
	const [fetchingDataContributors, setFetchingDataContributors] = useState<boolean>(false);

	useEffect(() => {
		let isMounted = true;
		async function fetch() {
			setFetchingDataContributors(true);
			await fetchUnMergedDataContributors();
			if (isMounted) {
				setFetchingDataContributors(false);
			}
		}

		// tslint:disable-next-line: no-floating-promises
		fetch();
		return () => { isMounted = false; };
	}, []);

	const onSearchByString = (str?: string) => {
		setSearchTerm(str);
	};

	useEffect(() => {
		sortDataContributors(searchTerm || "");
	}, [searchTerm]);

	const removeContributor: IContributorsActions = {
		type: "Remove",
		action: (dc) => updateToMergeList(dc, "Remove"),
		style: "basic primary",
	};

	const addContributor: IContributorsActions = {
		type: "Add",
		action: (dc) => updateToMergeList(dc, "Add"),
		style: "primary"
	};

	const availableContributors = filteredUnMergedContributors.filter(dc => {
		if (searchFilters.lastActive === LastActiveTimeOption.AllTimes) {
			return _.isEmpty(dc.lastActivity) ||
				moment().diff(moment(dc.lastActivity), "hours") <= TIME_SPAN_TO_HOURS_DIFF[searchFilters.lastActive];
		} else {
			return moment().diff(moment(dc.lastActivity), "hours") <= TIME_SPAN_TO_HOURS_DIFF[searchFilters.lastActive];
		}
	});

	return (
		<div id="data-contributors">

			<div className="ui page raised card full-width" style={{ justifyContent: "stretch" }}>
				<h2 className="ui header spaced-header full-width no-margin on-hover-only-container">
					<span className="acu-capitalize">
						merge contributors
					</span>
					{toMergeDataContributors.length === 0 && <p className="on-hover-only small-text gray-text" style={{ marginTop: "0.5rem", }}>
						Add the first contributor from the table below to start the merging process</p>}
					<span className="spaced-buttons">
						<button className="ui link-button button disabled-fade"
							onClick={clearToMergeList}
							disabled={toMergeDataContributors.length === 0}
						>Clear</button>
						<Modal
							onClose={() => setOpenMergeDialog(false)}
							onOpen={() => setOpenMergeDialog(true)}
							open={openMergeDialog}
							trigger={<button
								className={classNames("ui primary button disabled-fade", { loading: isMerging })}
								disabled={toMergeDataContributors.length < 2}
							>Merge</button>}
						>
							<Modal.Header>Confirm merging accounts</Modal.Header>
							<Modal.Content>
								<Modal.Description>
									<Header>Are you sure you want to merge these two accounts? This action is irreversible.</Header>
									<Feed>
										{toMergeDataContributors.map((item: IDashboardDetailedDataContributor, i: number) => {
											return (
												<Feed.Event key={`merge-dcs-${i}`}>
													<Feed.Label>
													<Avatar
														className="avatar acu-size"
														user={{
															displayName: item.primaryDisplayName ?? item.title,
															pictureUrl: item.primaryAvatarUrl
														}}
														/>
													</Feed.Label>
													<Feed.Content>
														<Feed.Summary>
															<Feed.User>{item.primaryDisplayName}</Feed.User>
														</Feed.Summary>
														<Feed.Extra text={true}>
															{`With links to ${_.uniqBy(item.links, l => l.identityType).map(l => identityTypeDisplayName[l.identityType]).join(", ")}`}
														</Feed.Extra>
													</Feed.Content>
												</Feed.Event>
											);
										})}
									</Feed>
								</Modal.Description>
							</Modal.Content>
							<Modal.Actions>
								<Button color="black" disabled={isMerging} onClick={() => {
									if (isMerging) {
										return;
									}
									setOpenMergeDialog(false);
								}}>
									Nope
								</Button>
								<Button
									content="Yep, Merge!"
									labelPosition="right"
									icon="checkmark"
									primary={true}
									className="ui primary button"
									loading={isMerging}
									onClick={() => {
										if (isMerging) {
											return;
										}
										setIsMerging(true);

										// tslint:disable-next-line: no-floating-promises
										mergeDataContributors(toMergeDataContributors).then(res => {
											setIsMerging(false);
											setOpenMergeDialog(false);
											if (res) {
												clearToMergeList();
												sortDataContributors(searchTerm || "");
											}
										});
									}}
								/>
							</Modal.Actions>
						</Modal>
					</span>
				</h2>
				{toMergeDataContributors.length > 0 ?
					<ContributorsTable
						contributors={toMergeDataContributors}
						action={removeContributor}
						page={1}
						sortDataContributors={sortToBeMergedDataContributorsByFilter}
					/> :
					<div className="ui centered-content" />
				}
			</div>

			<div className="ui page raised card full-width">
				<FiltersTopNavbar
					counterLabel={`${availableContributors.length} Contributors`}
					clickEventPage={GA_EVENT_CATEGORY.Contributors}
					searchString={searchTerm}
					setSearchString={setSearchTerm}
					onSearchByString={(str: string) => { setPage(1); onSearchByString(str); }}>
					<div className="control-width">
						<DropdownSelector
							placeholder="Filter"
							value={searchFilters.lastActive}
							name="lastActive"
							onChange={(opt: IDropdownOption | null) => {
								const lastActive = (opt ? opt.value as LastActiveTimeOption : DEFAULT_FILTERS.lastActive);
								setSearchFilters({
									lastActive
								});
							}}
							options={[
								{
									key: "header",
									value: "N/A",
									label: "Filter by last activity",
									isDisabled: true,
								},
								...Object.entries(LastActiveTimeOption)
									.map(([, type]) => ({
										key: type,
										value: type,
										label: TIME_SPAN_OPTIONS[type]
									}))
							]

							}
							clickEvent={clickEvent(GA_EVENT_CATEGORY.Contributors, GA_EVENT_ACTION.Filter, "active-filter")}
						/>
					</div>
				</FiltersTopNavbar >

				{(unMergedDataContributors !== undefined && !fetchingDataContributors) ?
					<ContributorsTable
						contributors={availableContributors}
						action={addContributor}
						itemsPerPage={50}
						page={currentPage}
						setPage={setPage}
						sortDataContributors={sortUnMergedDataContributorsByFilter}
					/> :
					<LoadingIndicator local={true} isActive={true} />
				}
			</div>
		</div >
	);
};
export default observer(Contributors);
