import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React from "react";
import colorAxis from "highcharts/modules/coloraxis";
colorAxis(Highcharts);

const INVISIBLE_CHARACTER = "‎";

const DEFAULT_FONT_STYLE = {
	color: "#000000",
	fontSize: "14px",
	fontFamily: "default-regular-font, sans-serif",
	fontWeight: "600",
	padding: "10px",
};

const BACKGROUND_COLOR_GRADIENT: Highcharts.GradientColorStopObject[] = [
	[0, "#FFFEFE99"],
	[1, "#F4F4F499"],
];

const SERIES_COLOR_GRADIENT = {
	minColor: "#E0E7ED",
	maxColor: "#925EFF",
};

const CHART_TYPE = "bubble";

const DEFAULT_AXIS_VALUES = {
	// ACM-7250 Note: Internal padding, the bulletproof way
	min: -0.05,
	max: 1.05,

	tickInterval: 0.1,
	gridLineWidth: 0,
	minorTickLength: 0,
	tickLength: 0,
	lineWidth: 0,

	labels: {
		enabled: false
	},

	plotLines: [{
		color: "#979797",
		width: 2,
		value: 0.5,
		outerWidth: "95%",
	}]
};

export interface ITeamsDisplayValues {
	[teamId: string]: {
		teamName: string,

		x: number;
		y: number | null;

		xUnit: string;
		yUnit: string | null;
	};
}

interface IQuadrantsChartProps {
	series: Array<Partial<Highcharts.SeriesOptionsType>>;
	teamsDisplayValues: ITeamsDisplayValues;
	xAxisMetricName: string | null;
	yAxisMetricName: string | null;
	tooltipFormatter: (displayValues: Record<string, any>) => string;
}

// tslint:disable-next-line: variable-name
const QuadrantsChart = (props: IQuadrantsChartProps) => {
	const { series, xAxisMetricName, yAxisMetricName, teamsDisplayValues, tooltipFormatter } = props;

	const chartOptions: Highcharts.Options = {
		chart: {
			type: CHART_TYPE,
			spacing: [20, 70, 30, 70],
			backgroundColor: {
				linearGradient: { x1: 0, x2: 1, y1: 0, y2: 1},
				stops: BACKGROUND_COLOR_GRADIENT,
			},

			events: {
				load() {
					this.renderer.text(
						"High performing",
						this.chartWidth * 0.85,
						this.chartHeight * 0.07,
					).add();

					this.renderer.text(
						"Low performing",
						this.chartWidth * 0.03,
						this.chartHeight * 0.95,
					).add();
				}
			}
		},

		plotOptions: { series: { shadow: true } },

		credits: { enabled: false },

		tooltip: {
			formatter() {
				const displayValues = teamsDisplayValues[this.series.name];
				return tooltipFormatter(displayValues);
			}
		},

		legend: {
			enabled: false,
		},

		series: series.map(s => Object.assign(s, {
			type: CHART_TYPE,
			colorKey: "x",
		})),

		colorAxis: {
			...SERIES_COLOR_GRADIENT,
			min: 0,
			max: 1,
			labels: {
				enabled: false
			}
		},

		xAxis: {
			...DEFAULT_AXIS_VALUES,
			title: {
				text: xAxisMetricName,
				align: "high",
				reserveSpace: false,
				y: -185,
				style: DEFAULT_FONT_STYLE,
			}
		},

		yAxis: {
			...DEFAULT_AXIS_VALUES,
			title: {
				// ACM-7251 Note: the title for the y axis is implemented with the chart title property,
				// because it is visually neater
				text: undefined,
			}
		},

		title: {
			// ACM-7251 Note: The invisible character is used here so that a space will be reserved for the title.
			// Otherwise, the graph layout changes and an annoying animation-like motion occurs.
			text: yAxisMetricName ?? INVISIBLE_CHARACTER,
			style: DEFAULT_FONT_STYLE,
		},

	};
	return (
		<HighchartsReact
			highcharts={Highcharts}
			options={chartOptions}
			allowChartUpdate={true}
		/>
	);
};
export default QuadrantsChart;
