import * as React from "react";
const IssuesMoreMenu = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 4 18"
		fill="none"
		{...props}
	>
		<path
			d="M2 1v1-1Zm1 1h1-1ZM2 3v1-1ZM1 2h1-1Zm1 6v1-1Zm1 1h1-1Zm-1 1v1-1ZM1 9h1-1Zm1 6v1-1Zm1 1h1-1Zm-1 1v1-1Zm-1-1h1-1ZM2 2h2a2 2 0 0 0-2-2v2Zm0 0v2a2 2 0 0 0 2-2H2Zm0 0H0a2 2 0 0 0 2 2V2Zm0 0V0a2 2 0 0 0-2 2h2Zm0 7h2a2 2 0 0 0-2-2v2Zm0 0v2a2 2 0 0 0 2-2H2Zm0 0H0a2 2 0 0 0 2 2V9Zm0 0V7a2 2 0 0 0-2 2h2Zm0 7h2a2 2 0 0 0-2-2v2Zm0 0v2a2 2 0 0 0 2-2H2Zm0 0H0a2 2 0 0 0 2 2v-2Zm0 0v-2a2 2 0 0 0-2 2h2Z"
			fill="#1B3F7B"
		/>
	</svg>
);
export default IssuesMoreMenu;
