export interface IPRData {
	"pull request number": number;
	"acumen iD": string;
	"repo name": string;
	"pull request url": string;
	"pull request title": string;
	opener: string;
	"creation time": Date;
	"merge time": Date;
	"close time": Date;
	"cycle time (open-to-merge in hours)": number;
	"coding (in hours)": number;
	"waiting for review (in hours)": number;
	"in review (in hours)": number;
	"pending merge (in hours)": number;
}

export enum PRColumns {
	Number = "Number",
	Title = "Title",
	CreationTime = "CreationTime",
	MergeTime = "MergeTime",
	CloseTime = "CloseTime",
	Opener = "Opener",
	CodingInHours = "CodingInHours",
	WaitingForReviewInHours = "WaitingForReviewInHours",
	InReviewInHours = "InReviewInHours",
	PendingMergeInHours = "PendingMergeInHours",
	TotalCycleTimeInHours = "TotalCycleTimeInHours",
}
