import { useEffect, useState } from "react";
import { DateRangeType } from "@acumen/dashboard-common";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import { MAP_DATE_RANGE_TO_VALUE } from "../../../../components/filters-selector/sections/time-frames-selector";
import moment from "moment";
import { useStores } from "../../../../mobx-stores";
import { DateFormatters, DEFAULT_DATE_RANGE_IN_DAYS } from "../../../../components/filters-selector/date-formatters";

interface IDateRangeFilter {
	selectedTeamId: string;
	value?: string | DateRangeType;
	sprintValue?: string;
	dateRangeValue?: DateRangeType;
}

const DEFAULT_DATE_RANGE_FILTER_TYPE = DateRangeType.Last2Weeks;

interface IFilterSlice {
	searchedTeamId?: string;
	dateRangeFilter: IDateRangeFilter;
	setDateRangeFilter: React.Dispatch<React.SetStateAction<IDateRangeFilter>>;
	storageKey: string;
}

/* eslint-disable import/no-anonymous-default-export */
export default ({
	searchedTeamId,
	dateRangeFilter,
	setDateRangeFilter,
	storageKey
}: IFilterSlice) => {
	const { authStore } = useStores();
	const [endTime, setEndTime] = useState<Date | undefined>(moment().toDate());
	const [startTime, setStartTime] = useState<Date | undefined>(moment(endTime).subtract((DEFAULT_DATE_RANGE_IN_DAYS), "days").startOf("day").toDate());
	const [previousStartTime, setPreviousStartTime] = useState<Date>(startTime!);
	const [previousEndTime, setPreviousEndTime] = useState<Date>(endTime!);
	const isRangeSelector = true;
	const formatters = new DateFormatters(isRangeSelector, authStore.authUser.locale);

	const [selectedSelectorValue, setSelectedSelectorValue] =
		useLocalStorage<string | undefined>(storageKey, undefined);

	useEffect(() => {
		if (!dateRangeFilter.selectedTeamId || dateRangeFilter.selectedTeamId?.length < 1) {
			setDateRangeFilter(prevState => {
				return {
					...prevState,
					selectedTeamId: searchedTeamId || ""
				};
			});
		}
	}, [dateRangeFilter.selectedTeamId, setDateRangeFilter, searchedTeamId]);

	useEffect(() => {
		if (selectedSelectorValue) {
			setDateRangeFilter(prevState => {
				return {
					...prevState,
					value: selectedSelectorValue || DEFAULT_DATE_RANGE_FILTER_TYPE,
					id: `${searchedTeamId}-${selectedSelectorValue ? selectedSelectorValue : DEFAULT_DATE_RANGE_FILTER_TYPE}`
				};
			});
		}
	}, []);

	const getStartAndEndDates = () => {
		let rangeStartTime: Date | undefined;
		let rangeEndTime: Date | undefined;

		if (!dateRangeFilter.value) {
			return {
				rangeStartTime: startTime,
				rangeEndTime: endTime,
				isCustomDateRange: true
			};
		}

		rangeEndTime = new Date();
		switch (dateRangeFilter.value) {
			case DateRangeType.LastDay: {
				rangeStartTime = moment.utc(rangeEndTime).subtract(1, "day").toDate();
				break;
			}
			case DateRangeType.Last3Days: {
				rangeStartTime = moment.utc(rangeEndTime).subtract(2, "days").toDate();
				break;
			}
			case DateRangeType.LastWeek: {
				rangeStartTime = moment.utc(rangeEndTime).subtract(1, "weeks").toDate();
				break;
			}
			case DateRangeType.Last2Weeks: {
				rangeStartTime = moment.utc(rangeEndTime).subtract(2, "weeks").toDate();
				break;
			}
			case DateRangeType.LastMonth: {
				rangeStartTime = moment.utc(rangeEndTime).subtract(1, "month").toDate();
				break;
			}
			case DateRangeType.Last3Months:
			default: {
				rangeStartTime = moment.utc(rangeEndTime).subtract(3, "months").toDate();
				break;
			}
		}
		return {
			rangeStartTime,
			rangeEndTime,
			isCustomDateRange: false
		};
	};

	const getFilteredValueLabel = () => {
		const selectedDateRange = getStartAndEndDates();

		if (!dateRangeFilter.value &&
			!selectedDateRange.rangeStartTime && !selectedDateRange.rangeEndTime) {
			return "";
		}
		const customDateValue = formatters.dateRangeLabelFormat(selectedDateRange.rangeStartTime, selectedDateRange.rangeEndTime);
		const presetDateValue = MAP_DATE_RANGE_TO_VALUE[dateRangeFilter.value as DateRangeType];

		let dateRangeValue = presetDateValue;
		if (selectedDateRange.isCustomDateRange) {
			dateRangeValue = customDateValue;
		}

		return dateRangeValue ? dateRangeValue : "";
	};

	const setEndDate = (t: Date | undefined, previousStart?: Date) => {
		if (t && startTime) {
			setPreviousStartTime(previousStart ?? startTime);
			setPreviousEndTime(t);
		}
		setEndTime(t);
	};

	return {
		getFilteredValueLabel,
		setSelectedSelectorValue,
		isRangeSelector,
		setStartTime,
		setEndDate,
		previousStartTime,
		previousEndTime,
		locale: authStore.authUser.locale,
		getStartAndEndDates

	};
};
