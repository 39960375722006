import { getData, postData } from "../../../services/fetch-helpers";
import { BaseCustomerApiClient } from "./base-customer-api-client";
import { ConfigsValuesByCategory, CustomizableConfiguration, CustomizableConfigurationCategories } from "@acumen/dashboard-common";
import { qsStringify } from "../../../services/url-routes-helper";

export const CUSTOMIZATION_ROUTE = "customizable-config";

export interface IConfigurationsParams {
	teamId?: string;
	categories?: CustomizableConfigurationCategories[];
}

export interface IConfigurationsUpdateParams {
	category: CustomizableConfigurationCategories;
	configs: Partial<Record<CustomizableConfiguration, any>>;
}

const GET = "GET";

export class CustomizationApiClient extends BaseCustomerApiClient {
	public fetchCustomization = async (params: IConfigurationsParams) => {
		const query: Record<string, string | string[]> = {};
		const { teamId, categories } = { ...params };

		if (teamId) {
			query.teamId = teamId;
		}

		if (categories) {
			query["categories[]"] = categories;
		}

		return await getData<ConfigsValuesByCategory, any>(
			this.createCustomerEntityRoute(`${CUSTOMIZATION_ROUTE}/${GET}?${qsStringify(query)}`),
			this.token,
			this.tokenType
		);
	}

	public updateCustomization = async (params: IConfigurationsUpdateParams, rethrowError: boolean = false) => {
		return await postData<IConfigurationsUpdateParams, void, any>(
			this.createCustomerEntityRoute(`${CUSTOMIZATION_ROUTE}/${"UPDATE"}`),
			this.token,
			params,
			this.tokenType,
			rethrowError
		);
	}
}
