import React, { useCallback } from "react";
import "./custom-table-sort-button.scss";
import classNames from "classnames";

export interface CustomTableSortButtonProps {
	direction: "asc" | "desc";
	isActive?: boolean;
	activeIcon: string;
	inactiveIcon: string;
	onClick: (role: "asc" | "desc") => void;
}

export const CustomTableSortButton = (props: CustomTableSortButtonProps) => {
	const { direction, isActive, activeIcon, inactiveIcon, onClick } = props;
	const handleClick = useCallback(() => onClick(direction), [direction, onClick]);
	const className = classNames(
		"developer-stats-table-sort-button",
		isActive && "variant-active",
	);

	return (
		<button type="button" className={className} onClick={handleClick}>
			<img
				className="developer-stats-table-sort-button-icon developer-stats-table-sort-button-icon-inactive"
				src={inactiveIcon}
				alt={`${direction}Inactive`}
			/>
			<img
				className="developer-stats-table-sort-button-icon developer-stats-table-sort-button-icon-active"
				src={activeIcon}
				alt={`${direction}Active`}
			/>
		</button>
	);
};
