import React from "react";
import "./team-stat-cell-content.scss";
import { CustomTableRenderProps } from "../../../../components/custom-table/types";
import { TeamViewModel } from "../../types";
import { Trend } from "../../../../components/trend/trend";
import { WHF_METRICS_TO_COLUMNS } from "v2/pages/workforce-health/dev-stat-columns";
import { WorkforceHealthMetrics } from "@acumen/database-types";
import assert from "assert";

export const TeamStatCellContent = (props: CustomTableRenderProps<TeamViewModel>) => {
	const { item, column } = props;
	const stat = item.stats[column.key as keyof typeof item.stats];
	assert(Object.values(WorkforceHealthMetrics).includes(column.key as WorkforceHealthMetrics),
		`team comparison cell content - column key '${column.key}' is not one of the possivle values of WorkforceHealthMetrics`);
	// Note: assumes column.key is WorkforceHealthMetrics
	const isIncreasePositive = WHF_METRICS_TO_COLUMNS[column.key as WorkforceHealthMetrics].displayValues.isIncreasePositive;

	return (
		<div className="team-stat-cell-content">
			<Trend
				isIncreasePositive={isIncreasePositive}
				currentValue={stat?.current}
				previousValue={stat?.previous}
				roundingPrecision={1}
			/>
		</div>
	);
};
