import React, { forwardRef } from "react";
import { createPortal } from "react-dom";
import classNames from "classnames";
import TriggerOnClickOutside from "../../../../components/ux-effects/trigger-on-click-outside";
import CloseIcon from "../../../../components/svg-assets/close-icon.svg";
import { DeveloperDetailedProfile } from "../developer-detailed-profile/developer-detailed-profile";
import { DeveloperPerformanceBreakdown } from "../developer-performance-breakdown/developer-performance-breakdown";
import { DeveloperViewModel } from "../../types";
import { MetricData } from "../../../team-comparison/types";
import "./developer-details-side-panel.scss";

export interface DeveloperDetailsSidePanelProps {
	developer: DeveloperViewModel | null;
	metricData: MetricData;
	isOpen: boolean;
	onClose: () => void;
}

// tslint:disable-next-line:variable-name
export const DeveloperDetailsSidePanel = forwardRef((
	props: DeveloperDetailsSidePanelProps,
	ref: React.Ref<HTMLDivElement>
) => {
	const { developer, metricData, isOpen, onClose } = props;
	const className = classNames("developer-badge-breakdown-drawer", isOpen && "open");

	return (
		createPortal((
			<TriggerOnClickOutside excludedClassName="developer-info-cell-content" onTrigger={onClose}>
				<div ref={ref} className={className}>
					<div className="developer-badge-breakdown-drawer-header">
						<button className="developer-badge-breakdown-drawer-header-close-button" onClick={onClose}>
							<img src={CloseIcon} alt="Close"/>
						</button>
					</div>

					{developer && (
						<div className="developer-badge-breakdown-drawer-content">
							<DeveloperDetailedProfile developer={developer} metricData={metricData}/>

							<div className="developer-performance-breakdown-container">
								<DeveloperPerformanceBreakdown developer={developer} metricData={metricData}/>
							</div>
						</div>
					)}
				</div>
			</TriggerOnClickOutside>
		), document.body)
	);
});
