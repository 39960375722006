import apiContextProvider from "../../services/api-context-provider";
import { ConfigurationApiClient, CONFIGURATION_ROUTE } from "../services/crud/configuration-api-client";
import { ConfigurationValue, IConfigurationKey } from "@acumen/dashboard-common";
import { FetchLatestRequest } from "../../services/fetch-helpers";

export default class ConfigurationStore {
	private readonly apiClient: ConfigurationApiClient = new ConfigurationApiClient(apiContextProvider);

	fetchLatestComponentDataByTeam = new FetchLatestRequest<Record<string, ConfigurationValue>, any>(CONFIGURATION_ROUTE);
	public async fetch<T extends Array<IConfigurationKey<ConfigurationValue>>>(keys: [...T], context: { teamId?: string } = {}) {
		const result = await this.fetchLatestComponentDataByTeam.fetchLatest(this.apiClient.fetchConfiguration(keys, context));
		return result ? result.data : undefined;
	}
}
