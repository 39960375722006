import {
	CommonEndPoints, DashboardDataContributorExpandOption, IDashboardDataContributorsMetadata,
	IDashboardDetailedDataContributor, IntegrationType
} from "@acumen/dashboard-common";
import { getData, postData } from "../../../services/fetch-helpers";
import { BaseCustomerApiClient } from "./base-customer-api-client";
import * as qs from "querystring";
import urlJoin from "url-join";
import { qsStringify } from "../../../services/url-routes-helper";

export const DATA_CONTRIBUTORS_ROUTE = "contributors";
export const MERGE_SUB_ROUTE = "/merge";

export interface IDataContributorFilter {
	onlyHumanContributors?: boolean;
	onlyActiveContributors?: boolean;
	integrations?: IntegrationType[];
}

export interface IDataContributorNegationFilter {
	noAcumenAccount?: boolean;
}

export interface IDataContributorRequestParams {
	filter?: IDataContributorFilter;
	negFilter?: IDataContributorNegationFilter;
	expand?: DashboardDataContributorExpandOption[];
	groupGitHub?: boolean;
}

export class DataContributorsApiClient extends BaseCustomerApiClient {
	public getAll = async ({ filter, expand, groupGitHub, negFilter }: IDataContributorRequestParams) => {
		const query: qs.ParsedUrlQueryInput = {};
		if (filter?.onlyHumanContributors) {
			query["filters[human]"] = filter.onlyHumanContributors;
		}
		if (filter?.onlyActiveContributors) {
			query["filters[active]"] = filter.onlyActiveContributors;
		}
		if (filter?.integrations && filter.integrations.length > 0) {
			query["filters[integrations][]"] = filter.integrations;
		}
		if (negFilter?.noAcumenAccount) {
			query["negFilters[noAcumenAccount]"] = negFilter.noAcumenAccount;
		}
		if (expand) {
			query["expand[]"] = expand;
		}
		if (groupGitHub) {
			query.groupGitHub = groupGitHub;
		}
		return await getData<IDashboardDetailedDataContributor[], IDashboardDataContributorsMetadata>(
			this.createCustomerEntityRoute(urlJoin(DATA_CONTRIBUTORS_ROUTE, CommonEndPoints.FIND_ALL, `?${qsStringify(query)}`)),
			this.token
		);
	}

	public mergeDataContributors = async (contributorsToMerge: IDashboardDetailedDataContributor[]) => {

		const body = {
			targetDataContributorId: contributorsToMerge[0].id,
			sourceDataContributorsIds: contributorsToMerge.slice(1).map(dc => dc.id)
		};
		return await postData(
			this.createCustomerEntityRoute(urlJoin(DATA_CONTRIBUTORS_ROUTE, MERGE_SUB_ROUTE,
			)),
			this.token,
			body,
			this.tokenType
		);
	}
}
