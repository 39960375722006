import React, { useState } from "react";
import {
	DashboardTeamMetrics, IDashboardTeamReportMetric, IDashboardTeamsReport, DashboardTeamReportMetric,
	DashboardTeamReportMetricChangeReaction,
} from "@acumen/dashboard-common";
import classNames from "classnames";
import StackedSingleBarChart from "../../components/graphs/stacked-single-bar-chart";
import { prCycleDatesArray, REPORT_METRIC_TO_UNITS } from "../../mobx-stores/new-teams-report-store";
import { IChartScope } from "../../pages/org-analytics/charts/chart-scope";
import { Loader, Popup } from "semantic-ui-react";
import { round } from "@acumen/common";
import { ITeamsReportTrendCards, TrendCard, IHoverEffectProps, externalHoverType, HoverSelectionPart } from "./particles";
import { useStores } from "../../mobx-stores";
import PRCycleTimeSegment from "./charts-segments/pr-cycle-time-segment";
import { ChartSeriesData } from "../../pages/my-team/analytics/charts/charts";
import { observer } from "mobx-react";
import { TeamsReportStrings } from "../../pages/org-analytics/tooltips";
import PRSizeToMergeTimeChart from "../../pages/org-analytics/charts/pr-size-to-merge-time";
import LoadingIndicator from "../../components/loader";
import DropdownSelector, { IDropdownOption } from "../../components/form/dropdown-selector";
import { clickEvent, GA_EVENT_ACTION, GA_EVENT_CATEGORY } from "../../analytics-events";
import DeploymentFrequency from "../../pages/org-analytics/charts/deployment-frequency";
import GitHubReportedDeploymentFrequency from "../../pages/org-analytics/charts/github-reported-deployment-frequency";
import { CHART_ROW_SIZE } from ".";
import useDeepCompareEffect from "use-deep-compare-effect";

// tslint:disable-next-line: variable-name
const VelocityBoard = (props: {
	chartScope: IChartScope;
}) => {
	const {
		newTeamsReportStore: {
			teamsReportVelocityData: velocityData,
			getTeamsReportVelocityData,
			getVelocityTrendCardCharts,
			prCycleChartsData
		},
		authStore
	} = useStores();

	const { chartScope } = { ...props };
	const [hoveredMetric, setHoveredMetric] = useState<HoverSelectionPart>(externalHoverType);

	useDeepCompareEffect(() => {
		let isMounted = true;
		async function retrieveVelocityData(scope: IChartScope) {

			return getTeamsReportVelocityData(scope.startTime, scope.endTime, scope.teamIds, {
				gitRepositoryIds: scope.repositoryIds,
				projectIds: scope.projectIds
			});
		}
		async function retrieveTrendCardCharts(scope: IChartScope) {
			return getVelocityTrendCardCharts(scope.startTime, scope.endTime, scope.teamIds, scope.interval, scope.timezone, {
				gitRepositoryIds: scope.repositoryIds,
				projectIds: scope.projectIds
			});
		}

		if (!isMounted) {
			return;
		}
		// tslint:disable-next-line: no-floating-promises
		retrieveVelocityData(chartScope);
		// tslint:disable-next-line: no-floating-promises
		retrieveTrendCardCharts(chartScope);
		return () => { isMounted = false; };
	}, [chartScope]);

	const dates = prCycleChartsData.get(prCycleDatesArray) ?? [];
	const safeMatchChartDataToDates: (series: number[]) => Array<[number, number]> = (
		(series: number[]) => {
			return series
				.filter((_a, i) => i < dates.length)
				.map(((metricData: number, i: number) => ([dates[i], metricData])));
		}
	);

	const [prCycleWIP, prCycleAwaitingReview, prCycleInReview, prCycleReviewed] = [
		prCycleChartsData.get(DashboardTeamReportMetric.PRCycleWorkInProgressHours),
		prCycleChartsData.get(DashboardTeamReportMetric.PRCycleAwaitingReviewHours),
		prCycleChartsData.get(DashboardTeamReportMetric.PRCycleInReviewHours),
		prCycleChartsData.get(DashboardTeamReportMetric.PRCycleReviewedHours)
	];

	const trendsSeries: Partial<Record<HoverSelectionPart, ChartSeriesData>> = {
		[DashboardTeamReportMetric.PRCycleWorkInProgressHours]: prCycleWIP ?
			safeMatchChartDataToDates(prCycleWIP) : [],
		[DashboardTeamReportMetric.PRCycleAwaitingReviewHours]: prCycleAwaitingReview ?
			safeMatchChartDataToDates(prCycleAwaitingReview) : [],
		[DashboardTeamReportMetric.PRCycleInReviewHours]: prCycleInReview ?
			safeMatchChartDataToDates(prCycleInReview) : [],
		[DashboardTeamReportMetric.PRCycleReviewedHours]: prCycleReviewed ?
			safeMatchChartDataToDates(prCycleReviewed) : [],
	};

	return (
		<div className={"teams-report-container set-page-z-index"}>
			<h1 className="ui header ">Velocity</h1>
			<div className="pr-cycle-top ui centered grid">
				<div className="row">
					<div className="two wide column" />
					<div className="seven wide column">
						{totalPRCycleHeader(velocityData?.average.metrics.PRCycleTotalTimeHours)}
					</div>
					<div className="five wide column" />
					<div className="two wide column" />
				</div>

				<div className="row cards-row">
					<div className="two wide column margin-auto" />
					<div className="seven wide column">
						<div className="flex-row velocity-cards">
							{velocityTrendCards.slice(0, 4).map(
								(chartData) => <TrendCard
									series={trendsSeries[chartData.metricName]}
									cardData={chartData}
									metricData={velocityData?.average.metrics[chartData.metricName as DashboardTeamReportMetric]}
									hoverEffect={{ hoveredMetric, setHoveredMetric }}
									key={chartData.metricName}
									units={chartData.units}
								/>
							)}
						</div>
					</div>
					<div className="seven wide column">
						<div className="flex-row velocity-cards left-trend-cards">
							{velocityTrendCards.slice(-3).map(
								(chartData) => <TrendCard
									series={trendsSeries[chartData.metricName]}
									cardData={chartData}
									metricData={velocityData?.average.metrics[chartData.metricName as DashboardTeamReportMetric]}
									hoverEffect={{ hoveredMetric, setHoveredMetric }}
									key={chartData.metricName}
									units={chartData.units}
								/>
							)}
						</div>
					</div>
				</div>

				<TeamsVelocityTable
					teamsData={velocityData?.teams}
					hoverEffect={{ hoveredMetric, setHoveredMetric }}
				/>

				<VelocityCharts
					chartData={velocityData}
					chartScope={chartScope}
					shouldShowDeploymentFrequency={authStore.isUserShouldShowDeploymentFrequency}
					shouldShowGitHubReportedDeploymentFrequency={authStore.shouldShowGitHubReportedDeploymentFrequency}
				/>
			</div>
		</div>
	);
};
export default observer(VelocityBoard);

const totalPRCycleHeader = (velocityData?: IDashboardTeamReportMetric) => {
	const prTotalCount = velocityData ? round(velocityData.currentPeriodValue) : undefined;
	const units = REPORT_METRIC_TO_UNITS.PRCycleTotalTimeHours;
	const change = velocityData ? round(velocityData.changeFromPreviousPeriod * 100) : "-";
	const reaction = velocityData?.reaction;

	return (
		<div className="ui acu-card flex-spaced cycle-header">
			<Popup
				hoverable={true}
				wide={true}
				className={"tiny-text"}
				position="top center"
				content={TeamsReportStrings.HEADER_PR_CYCLE_TIME_TOOLTIP}
				trigger={
					<span className="cursor-help">PR cycle time</span>
				}
			/>
			<p className="cycle-count">
				<span className="big-text">{prTotalCount}</span>
				<span className="gray-text small-text">{units}</span>
				<span className={classNames("change", "small-text", "semi-bold-text", {
					"gray-text": (change === 0 || reaction === DashboardTeamReportMetricChangeReaction.Neutral),
					"success-text": (change !== 0 && reaction === DashboardTeamReportMetricChangeReaction.Positive),
					"warning-text": (change !== 0 && reaction === DashboardTeamReportMetricChangeReaction.Negative),
				})}>
					{change > 0 && <i className="caret up icon" />}
					{change < 0 && <i className="caret down icon" />}
					{`${change}%`}
				</span>
			</p>
		</div>
	);
};

// tslint:disable-next-line: variable-name
const TeamsVelocityTable = (
	props: {
		teamsData: DashboardTeamMetrics[] | undefined,
		hoverEffect: IHoverEffectProps
	}
) => {
	const { teamsData, hoverEffect } = { ...props };
	const { hoveredMetric, setHoveredMetric } = { ...hoverEffect };
	const teamsPRCycle = teamsData?.map(team => {
		return {
			cycleStageArr: [
				{
					value: team.metrics.PRCycleWorkInProgressHours.currentPeriodValue,
					name: DashboardTeamReportMetric.PRCycleWorkInProgressHours
				},
				{
					value: team.metrics.PRCycleAwaitingReviewHours.currentPeriodValue,
					name: DashboardTeamReportMetric.PRCycleAwaitingReviewHours
				},
				{
					value: team.metrics.PRCycleInReviewHours.currentPeriodValue,
					name: DashboardTeamReportMetric.PRCycleInReviewHours
				},
				{
					value: team.metrics.PRCycleReviewedHours.currentPeriodValue,
					name: DashboardTeamReportMetric.PRCycleReviewedHours
				}
			],
			total: team.metrics.PRCycleTotalTimeHours
		};
	});
	const teamsTotals = teamsPRCycle?.map(arr => arr.cycleStageArr.reduce((acc, a) => acc + a.value, 0));
	const relativeTotal = teamsTotals ? Math.max(...teamsTotals) : undefined;

	const leadTimeHoursTotal = Math.max(...teamsData?.map((team) => Math.abs(team.metrics.LeadTimeHours.currentPeriodValue)) ?? []);
	const mergedPRsCountTotal = Math.max(...teamsData?.map((team) => Math.abs(team.metrics.MergedPRsCount.currentPeriodValue)) ?? []);
	const releasedFrequencyDay = Math.max(...teamsData?.map((team) => Math.abs(team.metrics.MergedPRFrequencyDay.currentPeriodValue)) ?? []);

	return (
		<div className="row">

			<div className="two wide column ">
				<div className="ui grid">
					<div className="sixteen wide column only-side-padding" >
						{teamsData && teamsData.map((w, i) => (
							<div className="underlined-row hide-overflow" style={{ paddingTop: "1.5rem" }} key={i}> {w.teamName} </div>
						))}
					</div>
				</div>
			</div>

			<div className="fourteen wide column">
				{teamsPRCycle ?
					<div className="acu-card ">
						<div className="ui grid">

							<div className="eight wide column only-side-padding no-right-padding" >
								{teamsPRCycle.map((w, i) => {
									return (
										<div key={i} className="underlined-row">
											<div style={{ paddingTop: "1.5rem" }}>
												<StackedSingleBarChart
													barData={w.cycleStageArr}
													relativeTotal={relativeTotal}
													hoverEffect={{
														hoveredMetric,
														setHoveredMetric,
														withLabel: true
													}}
													label={{
														value: round(w.total.currentPeriodValue),
														reaction: w.total.reaction,
														change: round(w.total.changeFromPreviousPeriod * 100, 0)
													}}
												/>
											</div>
										</div>
									);
								})}
							</div>

							<div className="eight wide column only-side-padding no-left-padding">
								{teamsData && teamsData.map((w, i) => {
									return (
										<div key={i} className="ui grid underlined-row">
											<div className="four wide column" style={{ paddingTop: "1.5rem" }} />
											<div className="four wide column stack-bar-centered">
												<StackedSingleBarChart
													barData={[{
														value: round(w.metrics.MergedPRFrequencyDay.currentPeriodValue),
														name: DashboardTeamReportMetric.MergedPRFrequencyDay
													}]}
													relativeTotal={releasedFrequencyDay}
													hoverEffect={{
														hoveredMetric,
														setHoveredMetric
													}}
													label={{
														value: round(w.metrics.MergedPRFrequencyDay.currentPeriodValue),
														reaction: w.metrics.MergedPRFrequencyDay.reaction,
														change: round(w.metrics.MergedPRFrequencyDay.changeFromPreviousPeriod * 100, 0)
													}}
													colorScheme={["#1E4066"]}
												/>
											</div>
											<div className="four wide column stack-bar-centered">
												<StackedSingleBarChart
													barData={[{
														value: round(w.metrics.MergedPRsCount.currentPeriodValue),
														name: DashboardTeamReportMetric.MergedPRsCount
													}]}
													relativeTotal={mergedPRsCountTotal}
													hoverEffect={{
														hoveredMetric,
														setHoveredMetric
													}}
													label={{
														value: round(w.metrics.MergedPRsCount.currentPeriodValue),
														reaction: w.metrics.MergedPRsCount.reaction,
														change: round(w.metrics.MergedPRsCount.changeFromPreviousPeriod * 100, 0)
													}}
													colorScheme={["#1E4066"]}
												/>
											</div>
											<div className="four wide column stack-bar-centered">
												<StackedSingleBarChart
													barData={[{
														value: round(w.metrics.LeadTimeHours.currentPeriodValue),
														name: DashboardTeamReportMetric.LeadTimeHours
													}
													]}
													relativeTotal={leadTimeHoursTotal}
													hoverEffect={{
														hoveredMetric,
														setHoveredMetric
													}}
													label={{
														value: round(w.metrics.LeadTimeHours.currentPeriodValue),
														reaction: w.metrics.LeadTimeHours.reaction,
														change: round(w.metrics.LeadTimeHours.changeFromPreviousPeriod * 100, 0)
													}}
													colorScheme={["#1E4066"]}
												/>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
					:
					<div style={{ width: "100%", height: "5vh" }}>
						<Loader active={true} size="large" />
					</div>
				}
			</div>
		</div >
	);
};

// tslint:disable-next-line: variable-name
const VelocityCharts = ((
	props: {
		chartData: IDashboardTeamsReport | undefined,
		chartScope: IChartScope,
		shouldShowDeploymentFrequency: boolean,
		shouldShowGitHubReportedDeploymentFrequency: boolean
	}
) => {
	const { chartData, chartScope, shouldShowDeploymentFrequency, shouldShowGitHubReportedDeploymentFrequency } = { ...props };
	const [selectedTeamIdPRSizeToMergeChart, setSelectedTeamPRSizeToMergeChart] = useState<string | undefined>(undefined);
	if (selectedTeamIdPRSizeToMergeChart) {
		if (chartData) {
			if (chartData.teams.length === 0) {
				setSelectedTeamPRSizeToMergeChart(undefined);
			} else {
				if (chartData.teams.map(t => t.teamId).indexOf(selectedTeamIdPRSizeToMergeChart) === -1) {
					setSelectedTeamPRSizeToMergeChart(undefined);
				}
			}
		}
	}

	if (!selectedTeamIdPRSizeToMergeChart && chartData && chartData.teams.length > 0) {
		setSelectedTeamPRSizeToMergeChart(chartData.teams[0].teamId);
	}
	return (
		<div className="velocity-charts-header row">
			<div className="sixteen wide column">
				<div className="acu-card ">
					<PRCycleTimeSegment
						chartData={chartData}
						chartScope={chartScope}
						menuItems={velocityTrendCards.slice(0, 4)}
					/>
				</div>

				{shouldShowDeploymentFrequency &&
					<div className="acu-card">
						<div className="row charts-line-container underlined-row">
							<div className="ui grid">
								<DeploymentFrequency
									mainTitle={{
										title: "Deployment frequency",
										tooltip: "Shows the amount of code deployments done by the teams per each configured git branch (either via direct commit or pull request)."
									}}
									showExternalLegend={true}
									scope={chartScope}
									chartHeight={CHART_ROW_SIZE}
								/>
							</div>
						</div>
					</div>
				}

				{shouldShowGitHubReportedDeploymentFrequency &&
					<div className="acu-card">
						<div className="row charts-line-container underlined-row">
							<div className="ui grid">
								<GitHubReportedDeploymentFrequency
									mainTitle={{
										title: "GitHub deployment frequency",
										tooltip: "Shows the amount of successful GitHub deployments done by the teams per each environment."
									}}
									showExternalLegend={true}
									scope={chartScope}
									chartHeight={CHART_ROW_SIZE}
								/>
							</div>
						</div>
					</div>
				}
				<div className="acu-card ">
					<div className="row charts-line-container title-with-menu underlined-row">
						<h2 className="ui title acu-capitalize flex-row">
							Pull request size vs. time
							<Popup
								hoverable={true}
								wide={true}
								className={"tiny-text"}
								position="top center"
								content={TeamsReportStrings.PR_SIZE_TO_MERGE_TIME_TOOLTIP}
								trigger={<i className="chart-tooltip info circle outline icon" />}
							/>
						</h2>

						{chartData !== undefined &&
							<DropdownSelector
								placeholder="Select Team"
								isLoading={chartData === undefined}
								value={selectedTeamIdPRSizeToMergeChart}
								name="select-team-filter-chart"
								onChange={(opt: IDropdownOption | null) => {
									setSelectedTeamPRSizeToMergeChart(opt ? (opt.value as string) : undefined);
								}}
								options={!chartData ? [] : chartData.teams.map((item) => ({
									key: item.teamId,
									value: item.teamId,
									label: item.teamName
								}))}
								clickEvent={clickEvent(GA_EVENT_CATEGORY.OrgAnalytics, GA_EVENT_ACTION.Filter, "pr-size-to-merge-team-filter")}
							/>}

						{(chartData === undefined ?
							<LoadingIndicator local={true} isActive={true} />
							: (
								<PRSizeToMergeTimeChart
									teamIds={selectedTeamIdPRSizeToMergeChart === undefined ?
										(chartData.teams.length > 0 ? [chartData.teams[0].teamId] : undefined) :
										[selectedTeamIdPRSizeToMergeChart]}
									scope={chartScope}
								/>
							)
						)}
					</div>
				</div>
			</div>
		</div>
	);
});

export const velocityTrendCards: ITeamsReportTrendCards[] = [
	{
		metricName: DashboardTeamReportMetric.PRCycleWorkInProgressHours,
		title: "WIP",
		secondaryTitle: "Avg per PR",
		color: "#170987",
		tooltipText: TeamsReportStrings.HEADER_PR_CYCLE_WIP_TOOLTIP,
		units: REPORT_METRIC_TO_UNITS[DashboardTeamReportMetric.PRCycleWorkInProgressHours]
	},
	{
		metricName: DashboardTeamReportMetric.PRCycleAwaitingReviewHours,
		title: "Awaiting review",
		secondaryTitle: "Avg per PR",
		color: "#F49F09",
		tooltipText: TeamsReportStrings.HEADER_PR_CYCLE_AWAITING_REVIEW_TOOLTIP,
		units: REPORT_METRIC_TO_UNITS[DashboardTeamReportMetric.PRCycleAwaitingReviewHours]
	},
	{
		metricName: DashboardTeamReportMetric.PRCycleInReviewHours,
		title: "In review",
		secondaryTitle: "Avg per PR",
		color: "#AF2387",
		tooltipText: TeamsReportStrings.HEADER_PR_CYCLE_IN_REVIEW_TOOLTIP,
		units: REPORT_METRIC_TO_UNITS[DashboardTeamReportMetric.PRCycleInReviewHours]
	},
	{
		metricName: DashboardTeamReportMetric.PRCycleReviewedHours,
		title: "Pending merge",
		secondaryTitle: "Avg per PR",
		color: "#05B2DC",
		tooltipText: TeamsReportStrings.HEADER_PR_CYCLE_REVIEWED_TOOLTIP,
		units: REPORT_METRIC_TO_UNITS[DashboardTeamReportMetric.PRCycleReviewedHours]
	},
	{
		metricName: DashboardTeamReportMetric.LeadTimeHours,
		title: "Lead time",
		secondaryTitle: "Avg per issue",
		color: "#0F2345",
		tooltipText: TeamsReportStrings.HEADER_LEAD_TIME_TOOLTIP,
		units: REPORT_METRIC_TO_UNITS[DashboardTeamReportMetric.LeadTimeHours]
	},
	{
		metricName: DashboardTeamReportMetric.MergedPRsCount,
		title: "Merged PRs",
		secondaryTitle: "Total sum",
		color: "#0F2345",
		tooltipText: TeamsReportStrings.HEADER_MERGED_PRS_SUM_TOOLTIP,
		units: REPORT_METRIC_TO_UNITS[DashboardTeamReportMetric.MergedPRsCount]
	},
	{
		metricName: DashboardTeamReportMetric.MergedPRFrequencyDay,
		title: "Merged PRs frequency",
		secondaryTitle: "Total Avg",
		color: "#0F2345",
		tooltipText: TeamsReportStrings.HEADER_PR_MERGED_FREQ_TOOLTIP,
		units: REPORT_METRIC_TO_UNITS[DashboardTeamReportMetric.MergedPRFrequencyDay]
	}
];
