import { getData } from "../../../services/fetch-helpers";
import { BaseCustomerApiClient } from "./base-customer-api-client";
import { ITeamGitRepositories, DashboardSortOrder } from "@acumen/dashboard-common";
import { qsStringify } from "../../../services/url-routes-helper";

export const GIT_REPOSITORIES_ROUTE = "git-repositories";

export interface IGitRepositorySort {
	order: DashboardSortOrder;
}

export interface IGitRepositoryFilter {
	teamId?: string;
}

export class GitRepositoriesApiClient extends BaseCustomerApiClient {
	public fetchGitRepositories = async (filter?: IGitRepositoryFilter, sort?: IGitRepositorySort) => {
		const query: Record<string, string> = {};
		if (filter?.teamId) {
			query.teamId = filter?.teamId;
		}

		if (sort?.order) {
			query.sortOrder = sort.order;
		}

		return await getData<ITeamGitRepositories, any>(
			this.createCustomerEntityRoute(`${GIT_REPOSITORIES_ROUTE}?${qsStringify(query)}`),
			this.token
		);
	}
}
