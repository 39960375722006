import React from "react";
import classNames from "classnames";
import { IDashboardTaskCycleTimeBreakdownStatus } from "@acumen/dashboard-common";
import { AcumenStatusToClassName } from "./acumen-status-to-class-name";

interface CycleTimeBreakdownPhaseProps {
	status: IDashboardTaskCycleTimeBreakdownStatus;
	highlight: boolean;
	width: number;
}

const CycleTimeBreakdownPhase = (props: CycleTimeBreakdownPhaseProps) => {
	const { status, highlight, width } = props;
	const indicatorClassName = classNames(
		"cycle-time-breakdown-progress-phase-indicator",
		highlight ? "status-bg" : "cycle-time-breakdown-default",
		highlight && AcumenStatusToClassName[status.acumenTaskStatus],
	);

	return (
		<div className="cycle-time-breakdown-progress-phase" style={{ flex: `${width} 1 0` }}>
			<div className={indicatorClassName}/>
		</div>
	);
};

export default CycleTimeBreakdownPhase;
