import { FilterOptionModel, ResolvedFilterOption } from "./types";

export const resolveFilterOption = <I extends string | number = string | number>(
	option: FilterOptionModel<I>
): ResolvedFilterOption<I> => {
	const value = typeof option === "string" || option === null ? option as I : option.value as I;
	const label = typeof option === "string" || option === null ? option ?? "" : option.label;
	const searchValue = getFilterOptionSearchValue(option).toLowerCase();

	return { value, label, searchValue };
};

export const getFilterOptionSearchValue = (filterOptionModel: FilterOptionModel) => {
	if (typeof filterOptionModel === "string" || filterOptionModel === null) {
		return (filterOptionModel ?? "") as string;
	}

	if (typeof filterOptionModel.label === "string") {
		return filterOptionModel.searchValue ?? filterOptionModel.label;
	}

	return filterOptionModel.searchValue ?? "";
};

export const getFilterOptionValue = <I = string | number>(filterOptionModel: FilterOptionModel<I>) => {
	return typeof filterOptionModel === "string" ? filterOptionModel as unknown as I : filterOptionModel.value as I;
};
