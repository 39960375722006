export const routesWithAllowedParams: Array<{
	name: string;
	route: string;
	exact: boolean;
	params: string[];
	modifyParams: Array<[string, string]>;
}> = [{
	name: "Add Integration",
	route: `/my-account/integrations/add/setup-`,
	exact: false,
	params: ["code", "status"],
	modifyParams: [["state", "slack-state"]]
}];
