import { HttpStatusCode, IDashboardResponse } from "@acumen/dashboard-common";
import { raiseHttpResponseError } from "./error-helpers";
import { RootStore } from "../v1/mobx-stores";
import { TokenType } from "./api-context-provider";

const TIMEOUT_DURATION_MS: number = 60000;

export class HttpResponseError extends Error {
	constructor(public readonly status: HttpStatusCode, public readonly url: string, public readonly method: "GET" | "POST" | "DELETE", message?: string) {
		super(message);
	}
}

function normalizeResponseData<T, TMetadata = any>(responseData: T | IDashboardResponse<T, TMetadata>):
	IDashboardResponse<T, TMetadata> | null {

	if ("data" in responseData) {
		return {
			data: responseData.data,
			metadata: responseData.metadata
		};
	}
	if (responseData) {
		return {
			data: responseData
		};
	}

	return null;
}

function watchForResponseTimeout(timeoutMs: number, routePath: string, method: "GET" | "POST" | "DELETE"): Promise<HttpResponseError> {
	return new Promise((resolve) => setTimeout(() => {
		resolve((new HttpResponseError(HttpStatusCode.SERVER_ERROR_GATEWAY_TIMEOUT, routePath, method)));
	}, timeoutMs));
}

export async function getData<T, TMetadata>(routePath: string, token?: string, tokenType: TokenType = TokenType.Auth0,
											rethrowError: boolean = false): Promise<IDashboardResponse<T, TMetadata> | null> {
	const requestInit: RequestInit = {
		method: "GET"
	};

	routePath = addTokenToRequest(requestInit, routePath, tokenType, token);

	return fetchOrTimeout<T, TMetadata>(routePath, requestInit, rethrowError);
}

export async function deleteData<TInput, TResult, TMetadata>(
	routePath: string, token: string, payload?: TInput | Partial<TInput>, tokenType: TokenType = TokenType.Auth0,
	rethrowError: boolean = false): Promise<IDashboardResponse<TResult, TMetadata> | null> {
	const requestInit: RequestInit = {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
		},
	};

	routePath = addTokenToRequest(requestInit, routePath, tokenType, token);

	if (payload) {
		requestInit.body = JSON.stringify(payload);
	}

	return fetchOrTimeout<TResult, TMetadata>(routePath, requestInit, rethrowError);
}

export async function postData<T, TResult, TMetadata>(
	routePath: string, token: string, payload?: T | Partial<T>, tokenType: TokenType = TokenType.Auth0,
	rethrowError: boolean = false): Promise<IDashboardResponse<TResult, TMetadata> | null> {

	const requestInit: RequestInit = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
	};

	routePath = addTokenToRequest(requestInit, routePath, tokenType, token);

	if (payload) {
		requestInit.body = JSON.stringify(payload);
	}

	return fetchOrTimeout<TResult, TMetadata>(routePath, requestInit, rethrowError);
}

function addTokenToRequest(requestInit: RequestInit, routePath: string, tokenType: TokenType, token?: string) {
	if (token) {
		switch (tokenType) {
			case TokenType.Auth0:
				requestInit.headers = {
					...(requestInit.headers ?? {}),
					Authorization: `Bearer ${token}`
				};

				break;

			case TokenType.AcumenToken:
				const prefix = (routePath.includes("?")) ? "&" : "?";
				routePath += `${prefix}token=${token}`;

				break;
		}
	}

	return routePath;
}

const DEBUG_RESPONSES = false;

export async function fetchOrTimeout<TResult, TMetadata>(path: string,
	requestOptions: RequestInit, rethrowError: boolean = false) {
	const fetchResult = fetch(path, requestOptions);
	const method = requestOptions.method! as "GET" | "POST" | "DELETE";
	const timeout = watchForResponseTimeout(TIMEOUT_DURATION_MS, path, method);

	return Promise.race([fetchResult, timeout])
		.then(async (result: any | Response) => {
			const data = await result.json();
			if (result.status === HttpStatusCode.OK) {
				if (data) {
					return normalizeResponseData<TResult, TMetadata>(data);
				}
			} else {
				if (result.status === HttpStatusCode.CLIENT_ERROR_UNAUTHORIZED) {
					RootStore.fetcherStore.setTokenErrorState(true);

					if (DEBUG_RESPONSES) {
						// tslint:disable-next-line: no-console
						console.log(`UnAuthorized request to ${requestOptions.method} ${path}`);
					}

					return null;
				}

				raiseHttpResponseError(new HttpResponseError(result.status, result.url, method, data));
			}

			return null;
		}).catch((e) => {
			if (DEBUG_RESPONSES) {
				// tslint:disable-next-line: no-console
				console.log(`Request failed ${requestOptions.method} ${path}`);
			}

			if (rethrowError) {
				throw e;
			}

			return null;
		});
}

// NOTE: feel free to rewrite/unify this method with the others
export async function getFile<T>(routePath: string, token: string,
								 method: "GET" | "POST" = "GET", payload?: T | Partial<T>): Promise<string | null> {
	const requestInit: RequestInit = {
		method,
		headers: {
			"Authorization": `Bearer ${token}`,
			"Content-Type": "application/json"
		},
	};

	if (payload) {
		requestInit.body = JSON.stringify(payload);
	}

	const fetchResult = fetch(routePath, requestInit);
	const timeout = watchForResponseTimeout(TIMEOUT_DURATION_MS, routePath, method);

	return Promise.race([fetchResult, timeout])
		.then(async (result: any | Response) => {
			if (result.status === HttpStatusCode.OK) {
				return result.text();
			} else {
				if (result.status === HttpStatusCode.CLIENT_ERROR_UNAUTHORIZED) {
					RootStore.fetcherStore.setTokenErrorState(true);
					return null;
				}

				raiseHttpResponseError(new HttpResponseError(result.status, result.url, method));
			}

			return null;
		}).catch((e) => {
			return null;
		});
}

export class FetchLatestRequest<T, TMetadata> {
	count = 0;
	routeName: string;
	latestResultPromise: Promise<IDashboardResponse<T, TMetadata> | null> | null = null;
	resolve: ((data: IDashboardResponse<T, TMetadata> | null) => void) | null = null;
	reject: ((error: unknown) => void) | null = null;

	constructor(routeName: string = "undefined") {
		this.routeName = routeName;
	}

	public fetchLatest(
		fetchFunction: Promise<IDashboardResponse<T, TMetadata> | null>,
		method: "GET" | "POST" = "GET"
	): Promise<IDashboardResponse<T, TMetadata> | null> {
		const promiseId = ++this.count;

		if (!this.latestResultPromise) {
			this.latestResultPromise = new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			});
		}

		fetchFunction
			.then(result => {
				if (!result) {
					raiseHttpResponseError(
						new HttpResponseError(
							HttpStatusCode.SERVER_ERROR_INTERNAL_ERROR,
							this.routeName,
							method,
						)
					);
				}

				if (this.count === promiseId) {
					this.resolve!(result);
				}
			})
			.finally(() => {
				this.latestResultPromise = null;
			})
			.catch(error => this.reject!(error));

		return this.latestResultPromise;
	}
}
