import mixpanel from "mixpanel-browser";
import { MixpanelEventType } from "@acumen/database-types";
import config from "../utils/config";

const { mixpanelToken, mixpanelDebug } = config();
const MIXPANEL_ORIGIN_ACUMEN_FRONTEND = "acumen-frontend";
const MIXPANEL_EVENTS_VERSION = "v1";
export class MixpanelTrackingService {
	private static origin: string = MIXPANEL_ORIGIN_ACUMEN_FRONTEND;
	private static version: string = MIXPANEL_EVENTS_VERSION;

	constructor() {
		mixpanel.init(mixpanelToken, { debug: mixpanelDebug });
	}

	identify(userEmail: string) {
		mixpanel.identify(userEmail);
	}

	track(event: MixpanelEventType, properties?: Record<string, any>): void {
		mixpanel.track(event, {
			origin: MixpanelTrackingService.origin,
			mixpanelEventsVersion: MixpanelTrackingService.version,
			...properties
		});
	}
}

export type SprintCustomizationReferrer = "IntegrationSetUp" | "SprintReviewSettings" | "Banner";
