import classNames from "classnames";
import React from "react";

// tslint:disable-next-line: variable-name
export type columnSortStates = boolean | undefined;

// tslint:disable-next-line: variable-name
export const TwoWaysSortButton = (props: {
	columnSortedUp?: columnSortStates,
	color?: string
}) => {
	const { columnSortedUp, color } = { ...props };
	const notSorted = columnSortedUp === undefined;

	return (
		<span className="sort-icons" style={{ color }}>
			<i className={classNames("icon sort fad alt up", { thin: notSorted || !columnSortedUp })}
				style={{ position: "fixed", opacity: !notSorted ? 1 : 0.25 }} />
			<i className={classNames("icon sort fad alt down", { thin: notSorted || !!columnSortedUp })}
				style={{ position: "fixed", opacity: !notSorted ? 1 : 0.25 }}
				aria-hidden="true" />
		</span>
	);
};
