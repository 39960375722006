import React, { useCallback, useEffect } from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import LoadingIndicator from "../../../components/loader";
import { useStores } from "../../../mobx-stores";
import { AcumenMetricGroupType, TaskEstimationMethod } from "@acumen/dashboard-common";
import { Formatters } from "../../../components/dashboard-task/formatters";
import { observer } from "mobx-react";
import _ from "lodash";
import { TASK_ESTIMATION_METHOD_DISPLAY_NAMES, TASK_ESTIMATION_METHOD_SHORT_DISPLAY_NAMES } from "../../../../localization/text-records";
import { round } from "@acumen/common";
import { getSprintLabel } from "./";
import { IPlannedVsActualRequestScope } from "../../../mobx-stores/planned-vs-actual-store";
import { IterationReview } from "../../../components/iteration-review/utils";
import "./style.scss";

const ESTIMATION_METHOD_SHORT_SUFFIX: Record<TaskEstimationMethod, string | null> = {
	[TaskEstimationMethod.StoryPoints]: "Story Points",
	[TaskEstimationMethod.TShirtSize]: null,
	[TaskEstimationMethod.TimeBased]: null,
	[TaskEstimationMethod.None]: "Issues",
};

type SettingsSeriesOptionsType = Highcharts.SeriesOptionsType & { settingName?: string };

const ACTUAL_RUNNING_AVG_SETTING_NAME = "runningAverage";

// tslint:disable-next-line: variable-name
const PlanVsActualChart = observer((props: {
	plannedVsActualScope: IPlannedVsActualRequestScope
	pageError?: boolean;
	timezone?: string;
	height?: number;
	legendPosition?: "top" | "bottom";
	showLoadingIndicator?: boolean;
	sprintFilter?: string | null;
}) => {
	const {
		sprintsStore: { sprintIdMapping },
		planVsActualStore: { planVsActualData, isLoadingPlanVsActualData, getPlanVsActualChartData }
	} = useStores();

	const { plannedVsActualScope, pageError, timezone, height, legendPosition, showLoadingIndicator = true, sprintFilter = null } = props;
	const { estimationMethod } = plannedVsActualScope;
	const chartDataSuffix = TASK_ESTIMATION_METHOD_SHORT_DISPLAY_NAMES[estimationMethod];
	const decimalPlaces = estimationMethod === TaskEstimationMethod.None ? "0" : "2";

	const filterFunc = useCallback((sprintName: string): boolean => {
		if (sprintFilter === null) {
			return true;
		}

		return sprintName.toLowerCase().includes(sprintFilter.toLowerCase());
	}, [sprintFilter]);

	useEffect(() => {
		// tslint:disable-next-line: no-floating-promises
		getPlanVsActualChartData(plannedVsActualScope, timezone);
	}, [plannedVsActualScope]);

	const barsData: SettingsSeriesOptionsType[] = planVsActualData ?
		_.sortBy(planVsActualData?.barsData, (entry) => PLAN_VS_ACTUAL_CHART_SETTINGS[entry.name].order || 0)
			.map((dataEntry) => {
				const data = _
					.chain(dataEntry.data)
					.filter(d => sprintIdMapping[d[0]] !== undefined)
					.filter(d =>  filterFunc(sprintIdMapping[d[0]].name))
					.sortBy(d => sprintIdMapping[d[0]].startDate)
					.map(d => [d[0], IterationReview.Charts.Formatters.transformChartValue(d[1], estimationMethod)])
					.value();

				const barColor = PLAN_VS_ACTUAL_CHART_SETTINGS[dataEntry.name].color;

				const firstBarColor = typeof barColor === "string"
								? barColor
								: barColor.stops[0][1];

				const secondBarColor = typeof barColor === "string"
								? barColor
								: barColor.stops[1][1];

				return {
					settingName: dataEntry.name,
					type: "column",

					name: PLAN_VS_ACTUAL_CHART_SETTINGS[dataEntry.name].title,
					data,
					visible: data.length > 0,
					color: barColor,
					maxPointWidth: 35,
					tooltip: IterationReview.Charts.ToolTip.getFormattedColumnTooltip(PLAN_VS_ACTUAL_CHART_SETTINGS[dataEntry.name].title,
						firstBarColor, secondBarColor, decimalPlaces, chartDataSuffix)
				};
			}) : [];

	const actualSplineData = (_
		.chain(planVsActualData?.barsData.find(barSeries =>
			ACTUAL_EFFORT_SERIES.find(name => name === barSeries.name))?.data)
		.filter(d => sprintIdMapping[d[0]] !== undefined)
		.filter(d => filterFunc(sprintIdMapping[d[0]].name))
		.sortBy(d => sprintIdMapping[d[0]].startDate)
		.value()
	) ?? undefined;

	const splineColor = PLAN_VS_ACTUAL_CHART_SETTINGS[ACTUAL_RUNNING_AVG_SETTING_NAME].color;

	const firstSplineColor = typeof splineColor === "string"
		? splineColor
		: splineColor.stops[0][1];

	const secondSplineColor = typeof splineColor === "string"
		? splineColor
		: splineColor.stops[1][1];

	const splineData: SettingsSeriesOptionsType = {
		settingName: ACTUAL_RUNNING_AVG_SETTING_NAME,
		type: "spline",
		name: "Velocity (running avg. past 5 iterations)",
		data: getSeriesRunningAverage(actualSplineData, 5, estimationMethod),
		marker: {
			symbol: "circle"
		},
		zIndex: 1,
		dashStyle: "Dot",
		lineWidth: 2,
		color: splineColor,
		tooltip: IterationReview.Charts.ToolTip.getFormattedColumnTooltip(PLAN_VS_ACTUAL_CHART_SETTINGS[ACTUAL_RUNNING_AVG_SETTING_NAME].title,
			firstSplineColor, secondSplineColor, decimalPlaces, chartDataSuffix)
	};

	const options: Highcharts.Options = {
		chart: {
			type: "column",
			zoomType: "xy",
			height,
		},
		title: undefined,
		tooltip: {
			style: {
				pointerEvents: "auto"
			},
			shared: true,
			useHTML: true,
			formatter: function _formatter(tooltip) {
				const key = this.points ? this.points[0].key : "";
				const header = `<div class="tooltip-container">
									<div class="tooltip-header">
										<h5>${sprintIdMapping[key] ? getSprintLabel(sprintIdMapping[key]) : ""}</h5>
									</div>`;
				return header + tooltip.defaultFormatter.call(this, tooltip).join("");
			},
			headerFormat: "",
			footerFormat: `</div>`,
			enabled: true,
			backgroundColor: "white",
			borderRadius: 6,
			borderWidth: 0,
			padding: 0,
			distance: 60,
		},
		xAxis: {
			gridLineWidth: 1,
			type: "category",
			uniqueNames: false,
			labels: {
				align: "center",
				autoRotation: undefined,
				// tslint:disable-next-line: object-literal-shorthand
				formatter: function _formatter() {
					return `${sprintIdMapping[this.value] ? getSprintLabel(sprintIdMapping[this.value], true) : ""}`;
				},
				style: {
					color: "gray",
					textOverflow: "none",
				}
			},
		},
		yAxis: [
			{
				min: 0,
				title: {
					text: (estimationMethod ? TASK_ESTIMATION_METHOD_DISPLAY_NAMES[estimationMethod] : "Actual")
				},
				labels: {
					format: "{value}",
					style: {
						color: "gray"
					}
				},
			}
		],
		legend: (!legendPosition || legendPosition === "bottom") ? {
			layout: "horizontal",
			align: "center",
			verticalAlign: "bottom",
		} : {
			layout: "horizontal",
			align: "right",
			verticalAlign: "top",
		},
		credits: {
			enabled: false
		},
		series: _.compact([splineData, ...barsData])
	};

	return (
		<LoadingIndicator local={true} isActive={showLoadingIndicator &&
			(isLoadingPlanVsActualData || planVsActualData === undefined) && !pageError}>
			{planVsActualData && <HighchartsReact
				highcharts={Highcharts}
				options={options}
				allowChartUpdate={true}
			/>}
		</LoadingIndicator>
	);
});
export default PlanVsActualChart;

const getSeriesRunningAverage = (seriesData: Array<[string, number | string]>, xLastValuesToSum: number, estimationMethod: TaskEstimationMethod) => {
	const runningAverageSeries: Array<[string, number]> = seriesData.map((dataEntry: [string, number | string], i) => {
		const endIndex = i + 1;
		let startIndex = 0;
		if (i >= xLastValuesToSum) {
			startIndex = i - xLastValuesToSum + 1;
		}
		const sum = seriesData.slice(startIndex, endIndex)
			.reduce((acc, entry) => (acc + _.toNumber(entry[1])), 0);

		return [dataEntry[0], IterationReview.Charts.Formatters.transformChartValue(round(sum / (endIndex - startIndex)), estimationMethod)];
	});
	return runningAverageSeries;
};

interface IPlanVsActualChartSettings {
	title: string;
	color: string | Highcharts.GradientColorObject;
	order: number;
	formatValue: (value: number, estimationMethod: TaskEstimationMethod) => string;
}

const addSuffix: (value: number, estimationMethod: TaskEstimationMethod) => string = (value, estimationMethod) => {
	const formatted = Formatters.estimationValue(value, estimationMethod, null, true);
	if (!formatted) {
		return "N/A";
	}
	const suffix = ESTIMATION_METHOD_SHORT_SUFFIX[estimationMethod];
	if (!suffix) {
		return formatted;
	}
	return `${formatted} ${suffix}`;
};

const ACTUAL_EFFORT_SERIES = [
	AcumenMetricGroupType.JiraIssuesActualEffortStoryPoints,
	AcumenMetricGroupType.JiraIssuesActualEffortTimeEstimate,
	AcumenMetricGroupType.JiraIssuesActualEffortNoneEstimation
];

const PLANNED_EFFORT_COLOR: Highcharts.GradientColorObject = {
	linearGradient: {
		x1: 0,
		x2: 0,
		y1: 0,
		y2: 1,
	},
	stops: [
		[0, "#8B84C3"],
		[1, "#170987"]
	]
};

const ACTUAL_EFFORT_COLOR: Highcharts.GradientColorObject = {
	linearGradient: {
		x1: 0,
		x2: 0,
		y1: 0,
		y2: 1,
	},
	stops: [
		[0, "#C1E7DF"],
		[1, "#32B195"]
	]
};

const PLAN_VS_ACTUAL_CHART_SETTINGS: Record<string, IPlanVsActualChartSettings> = {
	[AcumenMetricGroupType.JiraIssuesPlannedEffortStoryPoints]: {
		title: "Planned",
		color: PLANNED_EFFORT_COLOR,
		order: 1,
		formatValue: addSuffix
	},
	[AcumenMetricGroupType.JiraIssuesPlannedEffortTimeEstimate]: {
		title: "Planned",
		color: PLANNED_EFFORT_COLOR,
		order: 1,
		formatValue: addSuffix
	},
	[AcumenMetricGroupType.JiraIssuesPlannedEffortNoneEstimation]: {
		title: "Planned",
		color: PLANNED_EFFORT_COLOR,
		order: 1,
		formatValue: addSuffix
	},
	[AcumenMetricGroupType.JiraIssuesEstimatedEffortStoryPoints]: { /// what?
		title: "Actual",
		color: ACTUAL_EFFORT_COLOR,
		order: 2,
		formatValue: addSuffix
	},
	[AcumenMetricGroupType.JiraIssuesActualEffortStoryPoints]: {
		title: "Actual",
		color: ACTUAL_EFFORT_COLOR,
		order: 2,
		formatValue: addSuffix
	},
	[AcumenMetricGroupType.JiraIssuesActualEffortTimeEstimate]: {
		title: "Actual",
		color: ACTUAL_EFFORT_COLOR,
		order: 2,
		formatValue: addSuffix
	},
	[AcumenMetricGroupType.JiraIssuesActualEffortNoneEstimation]: {
		title: "Actual",
		color: ACTUAL_EFFORT_COLOR,
		order: 2,
		formatValue: addSuffix
	},
	[ACTUAL_RUNNING_AVG_SETTING_NAME]: {
		title: "Velocity",
		color: "#0BB2DC",
		order: 0,
		formatValue: addSuffix
	},
};
