import { ILocalizedStrings } from "./strings";

export class ENUSStrings implements ILocalizedStrings {
	public CLIENT_ERROR_BAD_REQUEST = "Invalid server request (400)";
	public ACUMEN = "Acumen";
	public MEMBER_DELETE_CONFIRM_MESSAGE = "You are going to delete \"%s\"";
	public PLEASE_TRY_AGAIN = "Please try again later.";
	public OOPS_REQUEST_FAILED = "Oops! Your request has failed";
	public REQUEST_TIMEOUT = "Looks like this is taking longer than expected. Please try to reload the page or contact our support team.";
	public INVALID_TEAM_NAME_ALREADY_EXISTS = "Team name is invalid or already exists";
	public ADD_TEAM = "Add Team";
	public TEAM_MANAGEMENT = "Team Management";
	public DISCARD = "Discard";
	public ADD_MEMBER = "Add Member";
	public CONTRIBUTOR = "Contributor";
	public EDIT_MEMBER_TEMPLATE = "Edit Team Member: %s";
	public DONE = "Done";
	public SELECT_A_MEMBERSHIP_TYPE = "Select a membership type";
	public MEMBER = "Member";
	public NA = "N/A";
	public ROLE = "Role";
	public TITLE = "Title";
	public NONE = "None";
	public SELECT_A_TEAM_LEAD = "Select a team lead...";
	public TEAMS = "Teams";
	public USER_CHANGE_STATUS_CONFIRM_MESSAGE = "Are you sure you wish to change the status for %s?";
	public MEMBERS = "Members";
	public TEAM_LEAD = "Team Lead";
	public LINK_IDS_TEMPLATE = "Link IDs: %s";
	public ANONYMOUS = "Anonymous";
	public USERNAME_NA = "Username N/A";
	public EMAIL_NA = "Email N/A";
	public CALCULATION = "Calculation";
	public VALUE = "Value";
	public PULL_REQUESTS = "Pull Requests";
	public NOTIFICATION_TARGET = "Notification Target";
	public MORE_INFO_RATING_TITLE = "How valuable was this insight for you?";
	public MORE_INFO_RATING_REVIEW_HEADER = "Help us improve. Do you have any feedback?";
	public MORE_INFO_RATING_REVIEW_PLACEHOLDER = "Your feedback is highly appreciated and will help us to improve our ability to serve you and others";
	public MORE_INFO_RATING_SUBMIT_BUTTON = "Submit";
	public MORE_INFO_ERROR_EXPIRED_TOKEN_MESSAGE = "The token to see this notification was expired";
	public MORE_INFO_ERROR_BAD_PARAMETERS_MESSAGE = "No notification was found for the given token";
	public MORE_INFO_NOTIFICATIONS_ENABLED_FOR_RULE = "Notifications for this type of insight";
	public SLACK = "Slack";
	public JIRA = "Jira";
	public EMAIL = "Email";
	public API = "API";
	public NOTIFICATION_CHANNELS = "Notification Channels";
	public NOTIFICATION_MESSAGE = "Notification Message";
	public NOTIFICATIONS = "Notifications";
	public IS_INVALID = "Invalid value: %s";
	public ACTIVE = "Active";
	public PARAMETERS = "Parameters";
	public PULL_REQUEST = "Pull Request";
	public DAYS = "Day(s)";
	public HOURS = "Hour(s)";
	public EVERY = "Every";
	public INSIGHT_FORMULA = "Insight Formula";
	public DETAILS = "Details";
	public SELECT_AN_ENTITY_RELATION = "Please select a relation entity";
	public SELECT_A_SUBSCRIPTION_TYPE = "Please select a subscription type";
	public SELECT_ONE_ROLE = "Please select at least one role";
	public SELECT_A_STATUS = "Please select a status";
	public ENTER_A_VALID_EMAIL = "Please enter a valid email";

	public USER_DISABLE_CONFIRM_MESSAGE = "Are you sure you want to disable \"%s\"?";
	public USER_ENABLE_CONFIRM_MESSAGE = "Are you sure you want to enable \"%s\"?";
	public USER_DELETE_CONFIRM_MESSAGE = "You are going to disable user: \"%s\"";
	public CONFIRM = "Confirm";
	public CANCEL = "Cancel";
	public ARE_YOU_SURE = "Are you sure?";
	public PLEASE_CONFIRM = "Please confirm";
	public CONFIRM_BTN_TEXT = "Yes, delete it!";

	public INSIGHTS = "Insights";
	public SETTINGS = "Settings";

	public USERS = "Users";
	public CONTRIBUTORS = "Contributors";
	public NAME = "Name";
	public DESCRIPTION = "Description";
	public RUN_ON = "Run on";
	public AND_IT_RELATES_TO = "and it relates to";
	public EVALUATION_CRITERIA = "Evaluation Criteria";

	public COMMIT = "Commit";
	public TASK = "Task";

	public COMMITS = "Commits";
	public TASKS = "Tasks";
	public CREATED = "Created";
	public UPDATED = "Updated";
	public DELETED = "Deleted";
	public TIME_INTERVAL = "Time Interval";

	public GLOBAL_DATE_FORMAT = "DD-MM-YYYY";
	public GLOBAL_DURATION_FORMAT = "HH:mm:ss:SSS";

	public INTEGRATION_INTEGRATIONS = "Integrations";
	public INTEGRATION_ADD_INTEGRATION = "Add Integration";
	public INTEGRATION_MANAGEMENT = "Integration Management";
	public INTEGRATION_GRID_ID = "Id";
	public INTEGRATION_GRID_CUSTOMER_ID = "Customer Id";
	public INTEGRATION_GRID_PROVIDER = "Provider";
	public INTEGRATION_GRID_STATE = "State";
	public INTEGRATION_GRID_STATUS = "Status";

	public INTEGRATION_SELECT_PROVIDER_GET_STARTED = "Get started by selecting an integration provider that suits your needs.\nWhether it is a repo, issue tracking or ticketing or an events integration.";
	public INTEGRATION_SELECT_PROVIDER_COMING_SOON = "coming soon";

	public INTEGRATION_FLOW_INIT_TEXT = "Fetching your integration details...Hang on!";
	public INTEGRATION_FLOW_SUCCESS_STEP_TITLE = "Success!";
	public INTEGRATION_FLOW_SUCCESS_STEP_ADD_ANOTHER = "Add another integration";

	public INTEGRATION_GITHUB_FLOW_TITLE = "GitHub Integration";
	public INTEGRATION_GITHUB_FLOW_INSTALL_STEP_TEXT = "You'll be redirected to GitHub for authorization. You may choose which repos to authorize for Acumen on that page.";
	public INTEGRATION_GITHUB_FLOW_INSTALL_STEP_NOTE = "Note:";
	public INTEGRATION_GITHUB_FLOW_INSTALL_STEP_NOTE_TEXT = "GitHub requires that you have the \"owner\" role in an organization to add integrations";
	public INTEGRATION_GITHUB_FLOW_INSTALL_STEP_BUTTON = "Install GitHub App";
	public INTEGRATION_GITHUB_FLOW_ENTER_DATA_STEP_TEXT = "Please copy the the URL in which you have been redirected to and paste it here.\nThe URL should contain \"installation_id\" query variable";
	public INTEGRATION_GITHUB_FLOW_ENTER_DATA_STEP_REDIRECTED_URL = "Redirected URL";
	public INTEGRATION_GITHUB_FLOW_ENTER_DATA_STEP_PASTE_URL_PLACEHOLDER = "Paste your redirected URL here";
	public INTEGRATION_GITHUB_FLOW_ENTER_DATA_URL_STEP_VALIDATION = "Please enter a valid URL";
	public INTEGRATION_GITHUB_FLOW_ENTER_DATA_URL_STEP_SUBMIT = "Submit";

	public INTEGRATION_SLACK_FLOW_TITLE = "Slack Integration";
	public INTEGRATION_SLACK_FLOW_INSTALL_STEP_TEXT = "When you click \"Install Slack App\" you'll be directed to Slack for authentication.";
	public INTEGRATION_SLACK_FLOW_INSTALL_STEP_BUTTON = "Install Slack App";
	public INTEGRATION_SLACK_FLOW_ENTER_DATA_STEP_TEXT = "Please copy the the URL in which you have been redirected to and paste it here.\nThe URL should contain both \"code\" and \"state\" query variables";
	public INTEGRATION_SLACK_FLOW_ENTER_DATA_STEP_REDIRECTED_URL = "Redirected URL";
	public INTEGRATION_SLACK_FLOW_ENTER_DATA_STEP_PASTE_URL = "Paste your redirected URL here";
	public INTEGRATION_SLACK_FLOW_ENTER_DATA_URL_STEP_VALIDATION = "Please enter a valid URL";
	public INTEGRATION_SLACK_FLOW_ENTER_DATA_URL_STEP_SUBMIT = "Submit";
	public INTEGRATION_SLACK_FLOW_LINKING_STEP_TEXT = "Connecting to Slack...";
	public INTEGRATION_SLACK_FLOW_SUCCESS_STEP_TEXT = "Your Slack organization is connected to Acumen";

	public INTEGRATION_JIRA_CLOUD_FLOW_TITLE = "JIRA Cloud Integration";
	public INTEGRATION_JIRA_ON_PREMISE_FLOW_TITLE = "JIRA Server Integration";
	public INTEGRATION_JIRA_FLOW_ENTER_INSTANCE_STEP_TEXT = "Enter the URL address for your JIRA instance.\nBe sure to include the protocol (HTTPS:) and the base URL (domain address).\nIt should be everything before the first ‘/’";
	public INTEGRATION_JIRA_FLOW_ENTER_INSTANCE_STEP_NOTE = "Note:";
	public INTEGRATION_JIRA_FLOW_ENTER_INSTANCE_STEP_NOTE_TEXT = "You need to have admin access to JIRA";
	public INTEGRATION_JIRA_FLOW_ENTER_INSTANCE_STEP_PLACEHOLDER = "https://your-company.atlassian.net";
	public INTEGRATION_JIRA_FLOW_ENTER_INSTANCE_STEP_VALIDATION = "Please enter a valid URL";
	public INTEGRATION_JIRA_FLOW_ENTER_INSTANCE_STEP_SUBMIT = "Get KeyPair";
	public INTEGRATION_JIRA_FLOW_ENTER_INSTANCE_STEP_SUBMIT_LOADING = "Getting KeyPair";

	public INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_TEXT = "Please follow the step-by-step instructions to configure your Jira instance for use with Acumen.";
	public INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_1 = "1. Open the Configure \"Application Links\" page on your JIRA instance";
	public INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_OPEN_LINKS_BUTTON = "Open Application Links";
	public INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_2 = "2. Enter the following URL, then hit the \"Create new link\" button";
	public INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_3 = "3. JIRA will warn that they didn't not get a response for this URL. This is fine. Click \"Continue\"";
	public INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4 = "4. On the screen that appears:";
	public INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_A = "- For \"Application Name\" use:";
	public INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_B = "- For \"Application Type\" select Generic Application:";
	public INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_C = "- For \"Service Provider Name\" use:";
	public INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_D = "- For \"Consumer Key\" use:";
	public INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_E = "- For \"Shared Secret\" use:";
	public INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_F = "- For \"Request Token URL\" use:";
	public INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_G = "- For \"Access Token URL\" use:";
	public INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_H = "- For \"Authorize URL\" use:";
	public INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_I = "- Check the \"Create incoming link\" box";
	public INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_J = "- Click \"Continue\"";
	public INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_5 = "5. On the next screen:";
	public INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_5_A = "- For \"Consumer Key\" use:";
	public INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_5_B = "- For \"Consumer Name\" use:";
	public INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_5_C = "- For \"Public Key\" use:";
	public INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_5_D = "- Click \"Continue\"";
	public INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SUBMIT = "Next";
	public INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_COPY_BUTTON = "Copy";
	public INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_COPY_BUTTON_COPIED = "Copied!";

	public INTEGRATION_JIRA_FLOW_VERIFY_STEP_TEXT = "You will have to manually approve the app in the JIRA console.";
	public INTEGRATION_JIRA_FLOW_VERIFY_STEP_VALIDATION_URL = "Verification URL";
	public INTEGRATION_JIRA_FLOW_VERIFY_STEP_OPEN_IN_BROWSER = "Open In Browser";
	public INTEGRATION_JIRA_FLOW_VERIFY_STEP_VERIFICATION_CODE = "Copy & Paste Verification Code";
	public INTEGRATION_JIRA_FLOW_VERIFY_STEP_VERIFICATION_CODE_PLACEHOLDER = "Paste your verification code here";
	public INTEGRATION_JIRA_FLOW_VERIFY_STEP_VERIFICATION_CODE_VALIDATION = "Please enter a valid code";
	public INTEGRATION_JIRA_FLOW_VERIFY_STEP_SUBMIT = "Next";
	public INTEGRATION_JIRA_FLOW_CREATING_AUTH_URL_STEP_TEXT = "Creating Authorization URL...";
	public INTEGRATION_JIRA_FLOW_LINKING_STEP_TEXT = "Linking JIRA instance and Acumen...";
	public INTEGRATION_JIRA_FLOW_SUCCESS_STEP_TEXT = "Your JIRA Cloud organization is connected to Acumen";

	public MY_TEAM = "My Team";

	public MY_TEAM_TASKS_TABLE_COLUMN_TYPE = "Type";
	public MY_TEAM_TASKS_TABLE_COLUMN_TASK_KEY = "Key";
	public MY_TEAM_TASKS_TABLE_COLUMN_TITLE = "Title";
	public MY_TEAM_TASKS_TABLE_COLUMN_ASSIGNEE = "Assignee";
	public MY_TEAM_TASKS_TABLE_COLUMN_STATUS = "Status";
	public MY_TEAM_TASKS_TABLE_COLUMN_STARTED_ON = "Started On";
	public MY_TEAM_TASKS_TABLE_COLUMN_MAKER_TIME = "Maker Time";

	public DETAILED_DAILY_REPORT_TITLE = "Detailed Daily Report";
	public DETAILED_DAILY_REPORT_TABLE_COLUMN_DC_NAME = "Name";
	public DETAILED_DAILY_REPORT_TABLE_COLUMN_TASK_ID = "Task ID";
	public DETAILED_DAILY_REPORT_TABLE_COLUMN_TASK_TITLE = "Task Title";
	public DETAILED_DAILY_REPORT_TABLE_COLUMN_FROM = "From";
	public DETAILED_DAILY_REPORT_TABLE_COLUMN_TO = "To";

	public MY_TEAM_TAB_TEAM_WORK = "Team's Work";
	public MY_TEAM_TAB_WORK_DIGEST = "Work Digest";
	public MY_TEAM_TAB_ANALYTICS = "Analytics";
}
