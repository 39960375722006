import React from "react";
import "./style.scss";
import { Loader } from "semantic-ui-react";
import { ACUMEN_SUPPORT_IN_GO_RETRO_EMAIL } from "../../external-app";
import Lottie, { Options as LottieOptions } from "react-lottie";

interface LoadingPageProps {
	animationSrc: any;
	isLastFetch: boolean;
	text?: string;
	isFinished?: boolean;
	hasError?: boolean;

}

// tslint:disable-next-line: variable-name
const LoadingPage = (props: LoadingPageProps) => {
	const { text, animationSrc, isLastFetch, isFinished, hasError } = props;

	const defaultLottieOptions: LottieOptions = {
		loop: true,
		autoplay: true,
		animationData: animationSrc,
	};

	return (
		<div className="external-page show-background-image loading-page">
			<Lottie options={defaultLottieOptions} height="35rem"/>
			{(!isLastFetch && !hasError) && (
				<>
					<h1 className="loading-page-title">{text}</h1>
					<div className="loading-page-text bold">This can take up to 2 minutes.</div>
				</>
			)}
			{(isLastFetch && !hasError) && (
				<>
					<div className="loading-page-text bold">Your sprint monitoring is on its way!</div>
					<div className="loading-page-text">This can take up to 60 minutes - we will email you once it’s ready.</div>
				</>
			)}
			<Loader className="loader-margin" active={!isFinished && !hasError} inline={true}/>
			{hasError &&
				<><div className="loading-page-text">Error while integrating with Jira.</div>
				<div className="loading-page-text">Please contact us in the chat or write an
					<a href={`mailto:${ACUMEN_SUPPORT_IN_GO_RETRO_EMAIL}`} target="_blank" rel="noopener noreferrer" className="link">
						{` email: `}
					</a>
					<div className="underline">{ACUMEN_SUPPORT_IN_GO_RETRO_EMAIL}</div>
				</div></>

			}
		</div>
	);
};

export default LoadingPage;
