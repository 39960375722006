import React, { useCallback } from "react";
import classNames from "classnames";
import SortArrowUpActive from "../../../svg-assets/sort-arrow-up-active.svg";
import SortArrowUpInactive from "../../../svg-assets/sort-arrow-up-inactive.svg";
import SortArrowDownActive from "../../../svg-assets/sort-arrow-down-active.svg";
import SortArrowDownInactive from "../../../svg-assets/sort-arrow-down-inactive.svg";
import { CustomTableColumnProps, SortState } from "../../types";
import { CustomTableSortButton } from "../custom-table-sort-button/custom-table-sort-button";
import "./custom-table-sort-controls.scss";

export interface CustomTableSortControlsProps<T extends object> {
	className?: string;
	column: CustomTableColumnProps<T>;
	sortState: SortState<T> | null;
	onSortStateChange: (state: SortState<T> | null) => void;
}

export const CustomTableSortControls = <T extends object>(props: CustomTableSortControlsProps<T>) => {
	const { column, sortState, onSortStateChange } = props;
	const { key } = column;
	const className = classNames(
		props.className,
		"custom-table-sort-controls",
		sortState?.key === key && "variant-active",
	);
	const setSortDirection = useCallback((direction: "asc" | "desc" | null) => {
		onSortStateChange(direction === null ? null : { key, direction });
	}, [key, onSortStateChange]);
	const handleClick = useCallback((direction: "asc" | "desc") => {
		setSortDirection(sortState && sortState.key === key && sortState.direction === direction
			? null
			: direction);
	}, [key, sortState, setSortDirection]);

	return (
		<div className={className}>
			<CustomTableSortButton
				direction="asc"
				isActive={!!sortState && sortState.key === column.key && sortState.direction === "asc"}
				activeIcon={SortArrowUpActive}
				inactiveIcon={SortArrowUpInactive}
				onClick={handleClick}
			/>
			<CustomTableSortButton
				direction="desc"
				isActive={!!sortState && sortState.key === column.key && sortState.direction === "desc"}
				activeIcon={SortArrowDownActive}
				inactiveIcon={SortArrowDownInactive}
				onClick={handleClick}
			/>
		</div>
	);
};
