import React from "react";
import classNames from "classnames";

const DEFAULT_COLOR_LIGHT_BLUE = "#e0eaf6";

export interface ILegendList {
	name: string;
	value: string | number;
	color?: string;
	valueSuffix?: string;
}
// tslint:disable-next-line: variable-name
export const ChartExternalLegend = (props: {
	legendList?: ILegendList[];
	legendTitle?: string;
	hoveredEntry?: string;
	setHoveredEntry?: React.Dispatch<React.SetStateAction<string | undefined>>
}) => {
	const { legendList, hoveredEntry, setHoveredEntry, legendTitle } = { ...props };
	return (
		<div className="acu-legend no-padding">
			{legendTitle && <span className="acu-legend-item"><b>{legendTitle}</b></span>}
			{legendList?.map((item, i) => (
				<div
					key={i}
					className={classNames({ hovered: hoveredEntry === item.name }, "acu-legend-item")}
					onMouseOver={() => setHoveredEntry && setHoveredEntry(item.name)}
					onMouseOut={() => setHoveredEntry && setHoveredEntry(undefined)}
				>
					<div className="circle" style={{ backgroundColor: item.color ?? DEFAULT_COLOR_LIGHT_BLUE }} />
					<p>
						<span>{item.name}</span>
						<span className="legend-item-value"> {item.valueSuffix ? `${item.value} ${item.valueSuffix}` : item.value}</span>
					</p>
				</div>))
			}
		</div >
	);
};
