import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import _ from "lodash";
import MyUser from "../../layout/navigation/my-user";

interface LocationState {
	hideLastPathnamePart?: boolean;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
	let { pathname } = useLocation<LocationState>();
	const { state } = useLocation<LocationState>();

	if (state?.hideLastPathnamePart) {
		pathname = pathname.substring(0, pathname.lastIndexOf("/"));
	}

	return (
		<div className="ui fluid basic segment acumen-header">
			<Helmet>
				<title>
					Acumen{" "}
					{_.chain(pathname)
						.split("/")
						.drop(1)
						.map((path) => _.startCase(path))
						.join(" > ")
						.value()}
				</title>
			</Helmet>
			<div className="ui fluid grid">
				<div className="eight wide column">
					<div className="section">
						<div className="ui breadcrumb">
							{pathname
								.split("/")
								.slice(1)
								.map((path, index, arr) => {
									const capitalizedString = path.charAt(0).toUpperCase() + path.slice(1);
									return (
										<NavLink
											className="section active"
											to={"/" + arr.slice(0, index + 1).join("/")}
											key={index}
										>
											{index < arr.length && (
												<i aria-hidden="true" className="chevron right icon" />
											)}
											{capitalizedString.split("-").join(" ")}
										</NavLink>
									);
								})}
						</div>
					</div>
				</div>
				<div className="eight wide right aligned column">
					<MyUser />
				</div>
			</div>
		</div>
	);
};
