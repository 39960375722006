import React from "react";
import { MobXProviderContext } from "mobx-react";
import { RouterStore } from "mobx-react-router";
import AuthStore from "./auth-store";
import DataContributorsStore from "./data-contributors-store";
import MetricStore from "./metric-store";
import TeamMembersStore from "./team-members-store";
import TeamsStore from "./teams-store";
import UsersStore from "./users-store";
import IntegrationsStore from "./new-integrations-store";
import OldIntegrationsStore from "./old-integrations-store";
import InsightRulesStore from "./insight-rules-store";
import ChartsStore from "./charts-store";
import SprintsStore from "./sprints-store";
import ProjectsStore from "./projects-store";
import LabelsStore from "./labels-store";
import TasksStore from "./tasks-store";
import PullRequestsStore from "./pull-requests-store";
import GitRepositoriesStore from "./git-repositories-store";
import DigestStore from "./digest-store";
import SprintRetroStore from "./sprint-retro-store";
import ConfigurationStore from "./configuration-store";
import TeamsReportStore from "./teams-report-store";
import NewTeamsReportStore from "./new-teams-report-store";
import BoardsStore from "./boards-store";
import SlackNotificationsStore from "./slack-notifications-store";
import FetcherStore from "./fetcher-store";
import ComponentsStore from "./components-store";
import CustomizationStore from "./customization-store";
import PrioritiesStore from "./priorities-store";
import TaskStatusStore from "./status-store";
import GoRetroSprintSummaryStore from "./go-retro-sprint-review-store";
import JiraStatusMappingStore from "./jira-status-mapping-store";
import PlanVsActualStore from "./planned-vs-actual-store";
import FeatureFlagStore from "./feature-flag-store";

export class RootStore {
	public static fetcherStore: FetcherStore = new FetcherStore();
	public static routingStore: RouterStore = new RouterStore();
	public static dataContributorsStore: DataContributorsStore = new DataContributorsStore();
	public static metricStore: MetricStore = new MetricStore();
	public static teamsStore: TeamsStore = new TeamsStore();
	public static teamMembersStore: TeamMembersStore = new TeamMembersStore();
	public static usersStore: UsersStore = new UsersStore();
	public static integrationsStore: IntegrationsStore = new IntegrationsStore();
	public static insightRulesStore: InsightRulesStore = new InsightRulesStore();
	public static chartsStore: ChartsStore = new ChartsStore();
	public static projectsStore: ProjectsStore = new ProjectsStore();
	public static sprintsStore: SprintsStore = new SprintsStore();
	public static labelsStore: LabelsStore = new LabelsStore();
	public static tasksStore: TasksStore = new TasksStore();
	public static digestStore: DigestStore = new DigestStore();
	public static sprintRetroStore: SprintRetroStore = new SprintRetroStore();
	public static pullRequestsStore: PullRequestsStore = new PullRequestsStore();
	public static teamsReportStore: TeamsReportStore = new TeamsReportStore();
	public static newTeamsReportStore: NewTeamsReportStore = new NewTeamsReportStore();
	public static repositoriesStore: GitRepositoriesStore = new GitRepositoriesStore();
	public static configurationStore: ConfigurationStore = new ConfigurationStore();
	public static boardsStore: BoardsStore = new BoardsStore();
	public static slackNotificationsStore: SlackNotificationsStore = new SlackNotificationsStore();
	public static componentsStore: ComponentsStore = new ComponentsStore();
	public static priorityStore: PrioritiesStore = new PrioritiesStore();
	public static authStore: AuthStore = new AuthStore(RootStore.fetcherStore);
	public static customizationStore: CustomizationStore = new CustomizationStore(RootStore.authStore);
	public static taskStatusStore: TaskStatusStore = new TaskStatusStore();
	public static goRetroSprintSummaryStore: GoRetroSprintSummaryStore = new GoRetroSprintSummaryStore();
	public static statusMappingStore: JiraStatusMappingStore = new JiraStatusMappingStore();
	public static planVsActualStore: PlanVsActualStore = new PlanVsActualStore();
	public static featureFlagStore: FeatureFlagStore = new FeatureFlagStore();
	public static oldIntegrationsStore: OldIntegrationsStore = new OldIntegrationsStore();
}

export function useStores(): typeof RootStore {
	return React.useContext(MobXProviderContext).rootStore as typeof RootStore;
}
