import { DependencyList, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
	AcumenTaskType,
	DashboardRetroReportTaskData,
	IDashboardJiraPriority,
	IDashboardTaskStatusChangeResponse,
	IRetroReportTasksStateResponse,
	TaskEstimationMethod
} from "@acumen/dashboard-common";
import { useStores } from "../../../mobx-stores";
import {
	createTaskActualCycleTimeFilterOptionGetter,
	createTaskEndStatusFilterOptionGetter,
	createTaskEstimationFilterOptionGetter,
	createTaskStartStatusFilterOptionGetter,
	createTaskStateInSprintFilterOptionGetter,
	createTaskStateInSprintGetter
} from "./utils";
import {
	createTaskEndStatusGetter,
	createTaskStartStatusGetter,
	TaskCategory
} from "../../../components/iteration-review/utils";
import _, { round } from "lodash";
import { UNMAPPED_PRIORITY_NAME } from "../../../components/dashboard-task/task-type-series-prop";
import colorFunction from "../../my-team/analytics/charts/chart-color-scheme";
import {
	adjustValueForTimeEstimation,
	IChartData,
	mapEstimationMethodToUnit
} from "../../../mobx-stores/sprint-retro-store";
import Highcharts from "highcharts";

const FEATURED_ISSUE_TYPES_MAX_COUNT = 7;

type TasksChartData = Record<string, {
	acumenType: AcumenTaskType | null
	internalType: string
	estimationValue: number
	tasksCount: number
}>;

export const useTaskStateInSprintGetter = () => {
	const { sprintRetroStore } = useStores();
	const { issueIdsByCategory } = sprintRetroStore;

	return useMemo(
		() => createTaskStateInSprintGetter(issueIdsByCategory),
		[issueIdsByCategory]
	);
};

export const useTaskStateInSprintFilterOptionGetter = () => {
	const { sprintRetroStore } = useStores();
	const { issueIdsByCategory } = sprintRetroStore;

	return useMemo(
		() => createTaskStateInSprintFilterOptionGetter(issueIdsByCategory),
		[issueIdsByCategory]
	);
};

export const useTaskEstimationFilterOptionGetter = (props: {
	estimationMethod?: TaskEstimationMethod;
}) => {
	const { estimationMethod } = props;

	return useMemo(
		() => createTaskEstimationFilterOptionGetter(estimationMethod),
		[estimationMethod]
	);
};

export const useTaskActualCycleTimeFilterOptionGetter = (props: {
	isExtended: boolean;
}) => {
	const { isExtended } = props;

	return useMemo(
		() => createTaskActualCycleTimeFilterOptionGetter(isExtended),
		[isExtended]
	);
};

export const useTaskStartStatusGetter = (props: {
	isExtended: boolean;
	statusDiff?: IDashboardTaskStatusChangeResponse;
}) => {
	const { isExtended, statusDiff } = props;
	const { taskStatusStore } = useStores();
	const { statusIdToStatus } = taskStatusStore;

	return useMemo(() => createTaskStartStatusGetter(
		isExtended,
		statusDiff,
		statusIdToStatus
	), [isExtended, statusDiff, statusIdToStatus]);
};

export const useTaskEndStatusGetter = (props: {
	isExtended: boolean;
	statusDiff?: IDashboardTaskStatusChangeResponse;
}) => {
	const { isExtended, statusDiff } = props;
	const { taskStatusStore, sprintRetroStore } = useStores();
	const { statusIdToStatus } = taskStatusStore;
	const { issueIdsByCategory } = sprintRetroStore;

	return useMemo(() => createTaskEndStatusGetter(
		isExtended,
		statusDiff,
		statusIdToStatus,
		issueIdsByCategory
	), [isExtended, statusDiff, statusIdToStatus, issueIdsByCategory]);
};

export const useTaskStartStatusFilterOptionGetter = (props: {
	isExtended: boolean;
	statusDiff?: IDashboardTaskStatusChangeResponse;
}) => {
	const { isExtended, statusDiff } = props;
	const { taskStatusStore } = useStores();
	const { statusIdToStatus } = taskStatusStore;

	return useMemo(() => createTaskStartStatusFilterOptionGetter(
		isExtended,
		statusDiff,
		statusIdToStatus
	), [isExtended, statusDiff, statusIdToStatus]);
};

export const useTaskEndStatusFilterOptionGetter = (props: {
	isExtended: boolean;
	statusDiff?: IDashboardTaskStatusChangeResponse;
}) => {
	const { isExtended, statusDiff } = props;
	const { taskStatusStore, sprintRetroStore } = useStores();
	const { statusIdToStatus } = taskStatusStore;
	const { issueIdsByCategory } = sprintRetroStore;

	return useMemo(() => createTaskEndStatusFilterOptionGetter(
		isExtended,
		statusDiff,
		statusIdToStatus,
		issueIdsByCategory
	), [isExtended, statusDiff, statusIdToStatus, issueIdsByCategory]);
};

export const useNotDoneStatuses = (props: {
	tasks: DashboardRetroReportTaskData[];
	doneStatuses?: string[];
	getTaskEndStatus: (task: DashboardRetroReportTaskData) => string;
}) => {
	const { tasks, doneStatuses, getTaskEndStatus } = props;

	return useMemo(
		() => !doneStatuses ? null : Array.from(new Set(tasks.map(getTaskEndStatus))).filter(status => !doneStatuses.includes(status)),
		[tasks, doneStatuses, getTaskEndStatus],
	);
};

export const useDoneStatuses = (props: {
	tasks: DashboardRetroReportTaskData[];
	doneStatuses?: string[];
	getTaskEndStatus: (task: DashboardRetroReportTaskData) => string;
}) => {
	const { tasks, doneStatuses, getTaskEndStatus } = props;

	return useMemo(
		() => !doneStatuses ? null : _.intersection(Array.from(new Set(tasks.map(getTaskEndStatus))), doneStatuses),
		[tasks, doneStatuses, getTaskEndStatus],
	);
};

export const useBugsAddedMidSprint = (props: {
	issues: DashboardRetroReportTaskData[] | undefined;
	priorities: IDashboardJiraPriority[] | undefined;
	issueIdsByCategory: IRetroReportTasksStateResponse | undefined;
	isLoadingData: boolean;
}) => {
	const { issues, priorities, issueIdsByCategory, isLoadingData } = props;
	const getTaskStateInSprint = useMemo(() => createTaskStateInSprintGetter(issueIdsByCategory), [issueIdsByCategory]);
	const [chartSeries, setChartSeries] = useState<Array<Partial<Highcharts.Point & { value: number }>> | undefined>(undefined);

	useEffect(() => {
		if (!issues || !priorities || !issueIdsByCategory || isLoadingData) {
			return undefined;
		}

		const unplannedBugs = issues
			.filter(issue => issue.type.internalType === AcumenTaskType.Bug)
			.filter(issue => getTaskStateInSprint(issue) === TaskCategory.Unplanned);
		const total = unplannedBugs.length;
		const chartDataMap = unplannedBugs.reduce<Record<number, IChartData & { value: number }>>((values, bug) => {
			const priority = bug.priority && priorities.find(p => p.internalId === bug.priority.internalPriorityId);

			if (!priority) {
				return values;
			}

			if (!values.hasOwnProperty(priority.order)) {
				values[priority.order] = {
					name: bug.priority?.internalPriority ?? UNMAPPED_PRIORITY_NAME,
					color: bug.priority?.internalPriority
						? colorFunction.acumenTaskPriority[priority.order as 0 | 1 | 2] ?? priority.statusColor
						: undefined,
					value: 0,
					tooltip: 0,
					y: 0,
				};
			}

			const item = values[priority.order];
			item.value += 1;
			item.tooltip = item.value;
			item.y = total > 0 ? (100 / total) * item.value : 0;

			return values;
		}, {});

		setChartSeries(Object.values(chartDataMap));
	}, [issues, priorities, issueIdsByCategory, isLoadingData, getTaskStateInSprint]);

	return chartSeries;
};

export const useDoneTasksByType = (props: {
	issues: DashboardRetroReportTaskData[] | undefined;
	issueIdsByCategory: IRetroReportTasksStateResponse | undefined;
	estimationMethod: TaskEstimationMethod | undefined;
	isLoadingData: boolean;
}) => {
	const { issues, issueIdsByCategory, estimationMethod, isLoadingData } = props;
	const [chartSeries, setChartSeries] = useState<Array<Partial<Highcharts.Point & { value: number }>> | undefined>(undefined);

	useEffect(() => {
		if (!issues || !issueIdsByCategory || !estimationMethod || isLoadingData) {
			return undefined;
		}

		let totalEstimationValue = 0;
		const tooltipSuffix = mapEstimationMethodToUnit(estimationMethod);
		const doneTasks = issues.filter(issue => issueIdsByCategory.completed.includes(issue.entityId));
		const tasksGroupedByType = doneTasks.reduce<TasksChartData>((red, task) => {
			const groupBy = task?.type?.internalType ?? "Other";

			if (!red.hasOwnProperty(groupBy)) {
				red[groupBy] = {
					acumenType: task.acumenType,
					internalType: groupBy,
					estimationValue: 0,
					tasksCount: 0,
				};
			}

			const rawEstimationValue = task.jiraEstimation ?? 0;
			const resolvedEstimationValue = adjustValueForTimeEstimation(estimationMethod, rawEstimationValue);
			red[groupBy]!.estimationValue += resolvedEstimationValue;
			red[groupBy]!.tasksCount += 1;
			totalEstimationValue += rawEstimationValue;

			return red;
		}, {});
		const tasksSummedByType = Object
			.values(tasksGroupedByType)
			.filter(typeTasks => typeTasks.estimationValue !== 0)
			.sort((a, b) => b.estimationValue - a.estimationValue)
			.reduce<TasksChartData>((red, cur, index) => {
				const groupBy = index < FEATURED_ISSUE_TYPES_MAX_COUNT ? cur.internalType : "Other";
				cur.internalType = groupBy;

				if (!red.hasOwnProperty(groupBy)) {
					red[groupBy] = cur;
				} else {
					red[groupBy].estimationValue += cur.estimationValue;
				}

				return red;
			}, {});

		totalEstimationValue = adjustValueForTimeEstimation(estimationMethod, totalEstimationValue);

		setChartSeries(
			Object
				.values(tasksSummedByType)
				.map(item => ({
					name: item.internalType,
					color: item.acumenType ? colorFunction.acumenNewTaskColors[item.acumenType] : colorFunction.acumenNewTaskColors.Unmapped,
					tooltip: `${round(item.estimationValue, 2)}${tooltipSuffix}`,
					value: item.estimationValue,
					y: (totalEstimationValue > 0)
						? (100 / totalEstimationValue) * item.estimationValue
						: 0,
				}), {})
		);
	}, [issues, issueIdsByCategory, estimationMethod, isLoadingData]);

	return chartSeries;
};

export const useRefChildrenSizes = <T extends HTMLElement>(dependencies: DependencyList = []) => {
	const ref = useRef<T | null>(null);
	const [widths, setWidths] = useState<number[]>([]);
	const [heights, setHeights] = useState<number[]>([]);
	const getWidthAt = useCallback((index: number) => index < widths.length ? widths[index] : 0, [widths]);
	const getHeightAt = useCallback((index: number) => index < heights.length ? heights[index] : 0, [heights]);

	useEffect(() => {
		const setSizes = () => {
			if (ref.current) {
				const children = ref.current.children;
				const childrenWidths: number[] = [];
				const childrenHeights: number[] = [];

				for (let i = 0; i < children.length; ++i) {
					const child = children.item(i) as HTMLElement | null;
					childrenWidths.push(child?.offsetWidth ?? 0);
					childrenHeights.push(child?.offsetHeight ?? 0);
				}

				setWidths(childrenWidths);
				setHeights(childrenHeights);
			}
		};

		setSizes();

		window.addEventListener("resize", setSizes);
		window.addEventListener("scroll", setSizes);

		return () => {
			window.removeEventListener("resize", setSizes);
			window.addEventListener("scroll", setSizes);
		};
	}, dependencies);

	return {
		ref,
		getWidthAt,
		getHeightAt,
	};
};
