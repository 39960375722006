import { BaseCustomerApiClient } from "./base-customer-api-client";
import {
	CommonEndPoints, IDashboardSlackNotification, IDashboardAddSlackNotification,
	IDashboardUpdateSlackNotification
} from "@acumen/dashboard-common";
import { getData, postData } from "../../../services/fetch-helpers";
import urlJoin from "url-join";

export const SLACK_NOTIFICATIONS_ROUTE = "/slack-notifications";

export class SlackNotificationsApiClient extends BaseCustomerApiClient {
	public findAll = async () => {
		return getData<IDashboardSlackNotification[], any>(
			this.createCustomerEntityRoute(urlJoin(SLACK_NOTIFICATIONS_ROUTE, CommonEndPoints.FIND_ALL)),
			this.token
		);
	}

	public create = async (entity: IDashboardAddSlackNotification) => {
		return await postData<IDashboardAddSlackNotification, IDashboardSlackNotification, any>(
			this.createCustomerEntityRoute(urlJoin(SLACK_NOTIFICATIONS_ROUTE, CommonEndPoints.CREATE)),
			this.token,
			entity
		);
	}

	public update = async (entity: IDashboardUpdateSlackNotification) => {
		return await postData<IDashboardUpdateSlackNotification, void, any>(
			this.createCustomerEntityRoute(urlJoin(SLACK_NOTIFICATIONS_ROUTE, entity.id, CommonEndPoints.UPDATE)),
			this.token,
			entity
		);
	}

	public delete = async (entityId: string) => {
		return await postData<void, void, any>(
			this.createCustomerEntityRoute(urlJoin(SLACK_NOTIFICATIONS_ROUTE, entityId, CommonEndPoints.DELETE)),
			this.token
		);
	}
}
