import React, { useCallback, useEffect, useRef, useState } from "react";
import { GA_EVENT_CATEGORY } from "../../analytics-events";
import "../style.scss";
import classNames from "classnames";
import TriggerOnClickOutside from "../../components/ux-effects/trigger-on-click-outside";

export type FilterType = Record<string, boolean | string[] | any>;
const START_SEARCH_LETTERS_COUNT = 1;

interface IFiltersTopNavbar {
	counterLabel?: number | string;
	children?: React.ReactChild | React.ReactChild[];
	onSearchByString?: (str: string) => void;
	clickEventPage: GA_EVENT_CATEGORY;
	pageErrorMessage?: string;
	searchString?: string;
	setSearchString?: (term?: string) => void;
	classNameString?: string;
}

// tslint:disable-next-line: variable-name
const FiltersTopNavbar = (props: IFiltersTopNavbar) => {
	const {
		children, counterLabel,
		pageErrorMessage, onSearchByString, searchString, setSearchString, classNameString
	} = { ...props };

	const [searchOn, setSearchOn] = useState<boolean>(false);
	const [searchIsLoading, setSearchIsLoading] = useState<boolean>(false);
	const [debounce, setDebounce] = useState<NodeJS.Timeout | undefined>(undefined);

	useEffect(() => {
		if (!onSearchByString) {
			return;
		}
		if (debounce) {
			clearTimeout(debounce);
			setSearchIsLoading(false);
		}
		if (searchString === "") {
			setSearchIsLoading(false);
		}
		if (searchString !== undefined &&
			(searchString.length > START_SEARCH_LETTERS_COUNT || searchString.length === 0)) {
			setSearchIsLoading(true);
			setDebounce(setTimeout(() => {
				onSearchByString(searchString ?? "");
				setSearchIsLoading(false);
			}, 500));
		}
	}, [searchString]);

	const inputRef = useRef<HTMLInputElement | null>(null);

	const handleInputFocus = () => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	};

	const handleSetSearchString = (term?: string) => {
		if (setSearchString) {
			setSearchString(term);
		}
	};

	const handleClickAway = useCallback(() => {
		if (!inputRef.current || (inputRef.current && inputRef.current.value.length < 1)) {
			setSearchOn(false);
		}
	}, []);

	const toggleSearchOn = () => {
		if (searchOn) {
			setSearchOn(false);
			handleSetSearchString("");
		} else {
			setSearchOn(true);
			handleInputFocus();
		}
	};

	return (
		<div className={classNames("top-nav", classNameString ?? "")}>
			<div className="flex-row" >
				{
					<div className="placeholder-1-rem" />
				}
				{onSearchByString ? (
					<>
						<TriggerOnClickOutside onTrigger={handleClickAway}>
							<div className="ui expanded-search">
								<i className="large thin search icon clickable" onClick={toggleSearchOn} />
								<div className={classNames("ui icon input underline")} style={{ zIndex: 0 }}>
									{searchOn && searchIsLoading && <i className="			circle notch icon loading" />}
									<input
										ref={inputRef}
										value={searchString || ""}
										className={classNames("prompt", { active: searchOn, inactive: !searchOn })}
										type="text"
													onChange={(e) => {
											handleSetSearchString(e.currentTarget.value ?? "");
										}}
										placeholder="Search"
									/>
									{searchOn && !searchIsLoading &&
										<button className={"close-search"} onClick={() => {
											handleSetSearchString("");
											setSearchOn(false);
										}}>
											<i className="hoverable times notch thin icon" />
										</button>
									}
								</div>
							</div>
						</TriggerOnClickOutside>
						<span className="span-divider" />
					</>
				) :
					<></>
				}
				{!!counterLabel &&
					<>
						<div className="small-gray-label">
							<div className="ui label mini note-label light-blue-text">{counterLabel}</div>
						</div>
					</>
				}
			</div>
			{pageErrorMessage && <div className="page-error-message">
				<i aria-hidden="true" className="outline triangle exclamation icon" />
				{pageErrorMessage}
			</div>}

			<div className="right-side-controls">
				{children}
			</div>
		</div >
	);
};
export default FiltersTopNavbar;
