import { round } from "@acumen/common";
import classNames from "classnames";
import React from "react";
import ArrowDown from "v2/components/svg-assets/arrow-down";
import ArrowUp, { DEFAULT_ARROW_GREEN_COLOR, DEFAULT_ARROW_RED_COLOR } from "v2/components/svg-assets/arrow-up";
import { getChangeRatePercentage } from "v2/helpers/calculate-change-rate";
import "./developer-metrics-tab-table-row.scss";

export interface DeveloperMetricsTabTableRowProps {
	statName: string;
	isIncreasePositive: boolean;
	currentStat?: number;
	previousStat?: number;
}

// tslint:disable-next-line:variable-name
export const DeveloperMetricsTabTableRow = (props: DeveloperMetricsTabTableRowProps) => {
	const { statName, isIncreasePositive, currentStat, previousStat } = props;

	const changeRate = (currentStat !== undefined && previousStat)
		? getChangeRatePercentage(currentStat, previousStat, 0)
		: 0;

	const changeRateClass = classNames("developer-metrics-table-row-change-rate-value", {
			"developer-metrics-table-row-change-rate-value-growth":
			(changeRate > 0 && isIncreasePositive) || (changeRate < 0 && !isIncreasePositive),
			"developer-metrics-table-row-change-rate-value-decline":
			(changeRate < 0 && isIncreasePositive) || (changeRate > 0 && !isIncreasePositive)
		});

	return (
		<tr className="developer-metrics-table-row">
			<td>
				<div className="developer-metrics-table-row-metric-name">{statName}</div>
			</td>
			<td>
				<div className="developer-metrics-table-row-current-value">
					{currentStat !== undefined ? round(currentStat) : "-"}</div>
			</td>
			<td>
			{(changeRate !== 0)
				&& <div className="developer-metrics-table-row-change-rate">
					<div className={changeRateClass}>{changeRate}%
					</div>
				</div>
			}
			</td>
			<td>
			{(changeRate !== 0)
				&& <div>
					{changeRate > 0 && <ArrowUp className="developer-metrics-table-rows-team-trend-stats-change-rate-icon"
						indicationfillcolor={isIncreasePositive ? DEFAULT_ARROW_GREEN_COLOR : DEFAULT_ARROW_RED_COLOR} />}
					{changeRate < 0 && <ArrowDown className="developer-metrics-table-rows-team-trend-stats-change-rate-icon"
						indicationfillcolor={isIncreasePositive ? DEFAULT_ARROW_RED_COLOR : DEFAULT_ARROW_GREEN_COLOR} />}
					</div>
			}
			</td>
		</tr>
	);
};
