import React from "react";
import { Grid, Image } from "semantic-ui-react";
import emptyStateChartColumns from "../../svg-assets/empty-state-chart-columns.svg";
import emptyStateChartSummary from "../../svg-assets/empty-state-chart-summary.svg";
import "./deployment-frequency-empty-state.scss";

interface IProps {
	onClickEmptyStateMissingInfo: () => void;
}

export const DeploymentFrequencyEmptyState = (props: IProps) => {
	return (
		<Grid>
			<Grid.Row stretched={true} columns={3}>
				<Grid.Column only="computer">
					<Image src={emptyStateChartColumns} alt="deployment integration empty state columns" />
				</Grid.Column>
				<Grid.Column verticalAlign={"middle"}>
					<div className="deployment-frequency-empty-state-content">
						<div className="deployment-frequency-empty-state-content-header">
							Want to see your deployment frequency?
						</div>

						<div className="deployment-frequency-empty-state-content-text">
							<span>It looks like you don't have any GitHub deployment data available. Click the button below to chat with our support team and we'll help you set it up.</span>
						</div>

						<button type="submit" className="deployment-frequency-empty-state-content-action" onClick={props.onClickEmptyStateMissingInfo}>
							Ask us how
						</button>
					</div>
				</Grid.Column>
				<Grid.Column only="widescreen">
					<Image src={emptyStateChartSummary} alt="deployment integration empty state chart summary" />
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};
