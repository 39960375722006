import React, { useCallback, useEffect, useRef, useState } from "react";
import "./style.scss";
import { IDashboardCustomizableValue } from "../../../../mobx-stores/customization-store";
import { ConfigurationEntityType, CustomizableConfigurationCategories } from "@acumen/dashboard-common";
import CustomizationFields from "../../../../components/customize-configurations/customization-fields";
import classNames from "classnames";
import TriggerOnClickOutside from "../../../../components/ux-effects/trigger-on-click-outside";
import { Divider, Modal } from "semantic-ui-react";
import cogIcon from "../../../../components/svg-assets/cog-icon.svg";
import { ConfigurationsAccessType } from "../../../../components/customize-configurations";
import Loader from "../../../../components/loader";
import { useStores } from "../../../../mobx-stores";
import "./style.scss";
import ConfigurationSprintSelector from "./sprint-selector";
import { getAutoDetectParams } from "../../../../mobx-stores/go-retro-sprint-review-store";

const GoRetroSprintSummaryConfiguration = (props: { selectedSprints: string[], onSubmit?: () => void }) => {
	const [isModuleOpen, setIsModuleOpen] = useState<boolean>(false);
	const topMouldedRef = useRef<HTMLInputElement | null>(null);
	const {
		customizationStore: {
			updateConfigurationByCategory
		},
		goRetroSprintSummaryStore: {
			setSprintSelection,
			fetchAutoDetectedConfigurations
		}
	} = useStores();

	useEffect(() => {
		if (topMouldedRef && topMouldedRef.current) {
			topMouldedRef.current?.scrollIntoView();
		}
	}, [topMouldedRef.current]);

	const toggleModuleState = useCallback(() => setIsModuleOpen(!isModuleOpen), [isModuleOpen]);

	const { selectedSprints, onSubmit } = props;
	const [changedFormFields, setChangedFormFields] = useState<Partial<IDashboardCustomizableValue>>({});
	const [selectedSprintIds, setSelectedSprintIds] = useState<string[]>(selectedSprints);
	const [savedSelectedSprintIds, setSavedSelectedSprintIds] = useState<string[]>(selectedSprints);
	const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

	const updateConfigurations = async (configs: Partial<IDashboardCustomizableValue>) => {
		if (Object.keys(configs).length === 0) {
			return;
		}
		await updateConfigurationByCategory(
			{
				category: CustomizableConfigurationCategories.GoRetro,
				configs
			},
			ConfigurationEntityType.CustomerDefault,
		);
	};

	const handleTrigger = useCallback(() => {
		setSelectedSprintIds(savedSelectedSprintIds);
		toggleModuleState();
	}, [savedSelectedSprintIds, toggleModuleState]);

	return (
		<>
			<img className={classNames("clickable", "padded-icon")} src={cogIcon} alt="" onClick={toggleModuleState} />
			{(isModuleOpen) &&
				<TriggerOnClickOutside onTrigger={handleTrigger} excludedClassName="configurations-form chip-multiselect-dropdown">
					<Modal
						open={isModuleOpen}
						className={classNames("configurations-form", "sprint-review-configuration-modal", { large: true })}>
						<Modal.Content >
							<div className={classNames("ui form grid no-margin")}>
								<div className="full-width">
									<div ref={topMouldedRef} />
									<div className="configuration-container go-retro-configurations">
										<Loader isActive={isRefreshing} local={true}>
											<div className="configurations-title">
												<img className="padded-icon" src={cogIcon} alt="" />
												<h2 className="acu-capitalize config-title">
													{"Sprint Review Configuration"}
												</h2>
											</div>
											<ConfigurationSprintSelector
												setSelectedSprintIds={setSelectedSprintIds}
												selectedSprintIds={selectedSprintIds}
											/>
											<Divider />
											<CustomizationFields
												category={CustomizableConfigurationCategories.GoRetro}
												entityType={ConfigurationEntityType.CustomerDefault}
												refreshPage={() => {
													return;
												}}
												showLoadingIndicator={true}
												configurationsAccessType={ConfigurationsAccessType.external}
												onChange={(values: Partial<IDashboardCustomizableValue>) => {
													setChangedFormFields(values);
												}}
												showTitle={false}
											/>
										</Loader>
									</div>
									<div className="padding-bottom" />
								</div>
							</div>
						</Modal.Content>

						<Modal.Actions>
							<div className="flex-row spaced buttons-row center-align">
								<div className={classNames("ui button retro-link-secondary",
									{ loading: isRefreshing, disabled: isRefreshing || savedSelectedSprintIds.length === 0 })
								}
									onClick={async (e) => {
										e.preventDefault();
										setIsRefreshing(true);
										try {
											const res = await fetchAutoDetectedConfigurations(getAutoDetectParams(savedSelectedSprintIds));
											if (res) {
												const configs: any = {};
												Object.entries(res.data).forEach(([key, value]) => {
													configs[key as any as string] = {
														single: value
													};
												});
												await updateConfigurations(configs);
												setChangedFormFields(configs);
												setSelectedSprintIds(savedSelectedSprintIds);
											}
											setIsRefreshing(false);
										} catch (e) {
											setIsRefreshing(false);
										}
									}}>
									Restore default
								</div>
								<div className="right-buttons center-align">
									<div className={classNames("ui button retro-button configurations",
										{ loading: isRefreshing, disabled: isRefreshing })
									} onClick={async () => {
										setIsRefreshing(true);
										await Promise.all([
											updateConfigurations(changedFormFields),
											setSprintSelection(selectedSprintIds)
										]);
										setSavedSelectedSprintIds(selectedSprintIds);
										if (toggleModuleState) { toggleModuleState(); }
										if (onSubmit) {
											onSubmit();
										}
										setIsRefreshing(false);
									}}>
										Save
									</div>

									<div className={classNames("ui button retro-link-secondary configuration-form-button",
										{ loading: isRefreshing, disabled: isRefreshing })
									}
										onClick={() => {
											toggleModuleState();
											setSelectedSprintIds(savedSelectedSprintIds);
										}}>
										Cancel
									</div>
								</div>
							</div>
						</Modal.Actions >
					</Modal >
				</TriggerOnClickOutside>
			}
		</>
	);
};

export default GoRetroSprintSummaryConfiguration;
