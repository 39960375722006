import React, { useMemo } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

import {
	DashboardRetroReportTaskData,
	IDashboardCycleTimeBreakdown,
	IDashboardTaskStatusChangeResponse,
	TaskEstimationMethod,
	SubtaskSprintStrategy,
} from "@acumen/dashboard-common";
import useSortState from "../../../hooks/useSortState";
import useSortedItems from "../../../hooks/useSortedItems";
import useFiltersState from "../../../hooks/useFilterState";
import useFilteredItems from "../../../hooks/useFilteredItems";
import TasksTableHeader from "./tasks-table-header";
import TaskTableItem from "./task-table-item";
import { TaskHighlights } from "./enums";
import { FeatureFlags } from "../../../feature-flags";
import { useStores } from "../../../mobx-stores";
import { useRefChildrenSizes } from "./hooks";
import { TasksTableFooter } from "./tasks-table-footer";

interface TaskTableProps {
	tasks: DashboardRetroReportTaskData[];
	estimationMethod?: TaskEstimationMethod;
	subtaskStrategy?: SubtaskSprintStrategy;
	className?: string;
	statusDiff?: IDashboardTaskStatusChangeResponse;
	doneStatuses?: string[];
	isRaisedRows?: boolean;
	isExtended?: boolean;
	isFilteringEnabled?: boolean;
	taskCategory?: TaskHighlights | null;
	cycleTimeBreakdown?: IDashboardCycleTimeBreakdown[] | null;
	onTaskCategoryChange?: (taskCategory: TaskHighlights | null) => void;
}

export const TasksTable = observer((props: TaskTableProps) => {
	const {
		tasks,
		estimationMethod,
		subtaskStrategy,
		className,
		statusDiff,
		doneStatuses,
		isRaisedRows = false,
		isExtended = false,
		isFilteringEnabled = false,
		taskCategory,
		cycleTimeBreakdown,
		onTaskCategoryChange
	} = props;
	const {
		featureFlagStore: {
			isFeatureEnabled,
		},
	} = useStores();
	const { filters, setFilters } = useFiltersState<DashboardRetroReportTaskData>();
	const { sortedColumn, sort } = useSortState<DashboardRetroReportTaskData>();
	const filteredTasks = useFilteredItems({ items: tasks, filters });
	const sortedTasks = useSortedItems({ items: filteredTasks, sortedColumn });
	const { ref: rowRef, getWidthAt: getCellWidthAt } = useRefChildrenSizes<HTMLTableRowElement>([tasks]);
	const cycleTimeBreakdownMap = useMemo(() => (
		cycleTimeBreakdown
			? cycleTimeBreakdown.reduce((map: Record<string, IDashboardCycleTimeBreakdown>, item) => {
				map[item.taskId] = item;

				return map;
			}, {})
			: null
	), [cycleTimeBreakdown]);
	const tableClassName = classNames("" +
		"acu ui very compact",
		"small fixed",
		"single line",
		"unstackable selectable table",
		"sticky-table",
		"tasks-table",
		isRaisedRows ? "raised" : "",
		className,
	);
	const showCycleTimeBreakdownColumn = useMemo(() => isFeatureEnabled(FeatureFlags.CycleTimeBreakdown) && !!cycleTimeBreakdownMap, [cycleTimeBreakdownMap]);

	return (
		<div className="tasks-table-container">
			<div className={classNames("acu-scroll" , "limit-table-height", { extended: isExtended })}>
				<table className={tableClassName}>
					<colgroup span={isExtended ? 7 : 4}/>
					<thead className={classNames("full-width", "no-collapse")}>
					<TasksTableHeader
						tasks={tasks}
						statusDiff={statusDiff}
						doneStatuses={doneStatuses}
						estimationMethod={estimationMethod}
						sortedColumn={sortedColumn}
						isExtended={isExtended}
						isFilteringEnabled={isFilteringEnabled}
						taskCategory={taskCategory}
						showCycleTimeBreakdownColumn={showCycleTimeBreakdownColumn}
						onTaskCategoryChange={onTaskCategoryChange}
						onSort={sort}
						onFiltersChange={setFilters}
					/>
					</thead>
					<tbody>
					{sortedTasks.map((task, index) => (
						<TaskTableItem
							key={index}
							ref={index === 0 ? rowRef : undefined}
							task={task}
							statusDiff={statusDiff}
							estimationMethod={estimationMethod}
							showCycleTimeBreakdownColumn={showCycleTimeBreakdownColumn}
							cycleTimeBreakdown={cycleTimeBreakdownMap?.[task.entityId]}
							isExtended={isExtended}
							isRaisedRows={isRaisedRows}
						/>
					))}
					</tbody>
				</table>
			</div>

			<TasksTableFooter
				tasks={sortedTasks}
				getCellWidthAt={getCellWidthAt}
				estimationMethod={estimationMethod}
				subtaskStrategy={subtaskStrategy}
			/>
		</div>
	);
});
