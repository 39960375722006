import _ from "lodash";
import React, { ChangeEvent } from "react";
import {
	Input
} from "semantic-ui-react";
import "./style.scss";

export interface IInput {
	label?: string;
	iconLabel?: string;
	value?: string | number;
	placeholder?: string;
	onChange?: (value: string | number) => void;
	error?: boolean;
	required?: boolean;
	loading?: boolean;
	autoFocus?: boolean;
	type?: "number" | "text" | "time";
	pattern?: "string";
}

// tslint:disable-next-line: variable-name
export const TextInput = ({value, label, iconLabel, placeholder, onChange, error, autoFocus, type = "text", pattern}: IInput) => {

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (onChange) {
			onChange(e.target.value);
		}
	};

	return (
		<div className="acu-input">
		 	{label && <div className="acu-input-label">{label}</div>}
			<Input
				label={iconLabel}
				value={_.toString(value)}
				placeholder={placeholder}
				className={`acu-text-input ${error ? "--error" : ""}`}
				onChange={handleChange}
				autoFocus={autoFocus}
				type={type}
				pattern={pattern}
				error={error}
			/>
		</div>
	);
};

export default TextInput;
