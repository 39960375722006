import React, { PropsWithChildren } from "react";
import _ from "lodash";
import classNames from "classnames";

interface IProps {
	linkMap?: { [key: string]: string | null } | null;
	splitterCharacter?: string;
	classNamePrefix?: string;
}

function linkify({ linkMap, splitterCharacter, classNamePrefix, ...props }: PropsWithChildren<IProps>) {
	let contents;
	if (_.isString(props.children) && linkMap) {
		const extraCharacter = splitterCharacter ?? " ";
		const tokens = props.children.split(splitterCharacter ? splitterCharacter : /\s/);

		contents = _.map(tokens, (token, i) => {
			const hasSplitter = i !== (tokens.length - 1);
			const maybeSplitter = hasSplitter ? extraCharacter : "";
			const target = linkMap[token];
			if (target) {
				return (
					<span key={i}>
						<a href={target} target="_blank" rel="noopener noreferrer" className={classNames(classNamePrefix ? classNamePrefix : "")}>
							{token}
						</a>
						{maybeSplitter}
					</span>
				);
			} else {
				return token + maybeSplitter;
			}
		});

		return (
			<span {...props}>
				{contents}
			</span>
		);
	}

	return <span {...props} />;
}

export default linkify;
