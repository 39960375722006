import React, { useEffect, useState } from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { observer } from "mobx-react";
import LoadingIndicator from "../../components/loader/loader";
import { Dropdown, Button, Input, Grid, Header, Segment } from "semantic-ui-react";
import { useStores } from "../../mobx-stores";
import "./metric-tester-page.scss";
import {
	MetricInterval, AcumenMetricGroupType, IDashboardAcumenMetricDataResponse, AcumenTaskStatus,
	AcumenTaskType, IDashboardSprint, DevelopmentMethodology
} from "@acumen/dashboard-common";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { toast } from "react-toastify";
import _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import Papa from "papaparse";

interface IMetricSelectionState {
	selectedMetricName?: string;
	selectedInterval?: string;
	startTime?: Date;
	endTime?: Date;
	selectedLabel?: string;
	selectedGroupBy?: string;
	dimensions?: { [dimName: string]: string };
	ranOnStart: boolean;
}

interface IDropDownOption {
	key: string;
	value: string;
	text: string;
	image?: { avatar: boolean, src: string };
}

interface ISelectionOptions {
	metricDimensions: IDropDownOption[];
	metricLabels: IDropDownOption[];
	metricIntervals: IDropDownOption[];
	dataContributors: IDropDownOption[];
	teams: IDropDownOption[];
	repositories: IDropDownOption[];
	components: IDropDownOption[];
	projects: IDropDownOption[];
	sprints: IDropDownOption[];
	boards: IDropDownOption[];
	acumenIssueTypes: IDropDownOption[];
	acumenStatuses: IDropDownOption[];
	devCycles: IDropDownOption[];
}

enum DimensionType {
	BOOLEAN = "BOOLEAN",
	DC = "DC",
	TEAM = "TEAM",
	COMPONENT = "COMPONENT",
	PROJECT = "PROJECT",
	REPOSITORY = "REPOSITORY",
	SPRINT = "SPRINT",
	BOARD = "BOARD",
	DEV_CYCLE = "DEV_CYCLE",
	ISSUE_TYPE = "ISSUE_TYPE",
	ISSUE_STATUS = "ISSUE_STATUS",
	UNKNOWN = "UNKNOWN"
}

enum FormatOption {
	DATE = "DATE",
	MILLISECONDS = "MILLISECONDS",
}

function dimToType(dimension: string): DimensionType {
	switch (dimension) {
		case "github_closed_unmerged":
		case "acumen_closed_unmerged":
		case "task_related":
		case "merge_commit":
		case "merged_to_default_branch":
		case "has_subtasks":
		case "is_aggregated_pr":
		case "base_is_default_branch":
		case "is_draft":
		case "is_success":
			return DimensionType.BOOLEAN;

		case "assignee_data_contributor_id":
		case "work_interval_data_contributor_id":
		case "data_contributor_id":
		case "wi_data_contributor_id":
		case "reviewer":
			return DimensionType.DC;

		case "component_owned_by_team":
		case "owned_by_team":
		case "team_id":
		case "board_owned_by_team":
			return DimensionType.TEAM;

		case "repository_id":
			return DimensionType.REPOSITORY;

		case "project_id":
			return DimensionType.PROJECT;

		case "component_id":
			return DimensionType.COMPONENT;

		case "sprint_board_id":
			return DimensionType.BOARD;

		case "closure_sprint_id":
		case "sprint_id":
			return DimensionType.SPRINT;

		case "dev_cycle_id":
		case "closure_dev_cycle_id":
			return DimensionType.DEV_CYCLE;

		case "acumen_status_type":
			return DimensionType.ISSUE_STATUS;

		case "acumen_task_type":
		case "issue_type_name":
			return DimensionType.ISSUE_TYPE;

		case "loc_changes_bucket":
		case "opened_to_merged_hours_bucket":
		case "base_branch_id":
		case "pr_id":
		case "priority_order":
		default:
			return DimensionType.UNKNOWN;
	}
}

const NON_EXISTING_OPTION = "-----";
function MetricTesterPage() {

	const currentQsParams = new URLSearchParams(useLocation().search);
	const startState: IMetricSelectionState = {
		selectedMetricName: currentQsParams.get("metric") ?? undefined,
		selectedInterval: currentQsParams.get("interval") ?? undefined,
		startTime: currentQsParams.get("startTime") ? new Date(currentQsParams.get("startTime") as string) : undefined,
		endTime: currentQsParams.get("endTime") ? new Date(currentQsParams.get("endTime") as string) : undefined,
		selectedLabel: currentQsParams.get("label") ?? undefined,
		selectedGroupBy: currentQsParams.get("groupBy") ?? undefined,
		dimensions: _.fromPairs((currentQsParams.getAll("dimensions[]") ?? []).map((x: string) => x.split("=")).map(x => [x[0], x[1] ?? ""])) ?? {},
		ranOnStart: false
	};

	const [selectedState, changeState] = useState<IMetricSelectionState>(startState);
	const [dropDownState, changeDropDownState] = useState<ISelectionOptions>({
		metricLabels: [], metricIntervals: [],
		metricDimensions: [], teams: [], dataContributors: [],
		repositories: [], components: [], projects: [], boards: [],
		sprints: [], acumenIssueTypes: [], acumenStatuses: [], devCycles: []

	});
	const [isLoadingChart, setIsLoadingChart] = useState<boolean>(false);
	const [metricResponse, setMetricResponse] = useState<IDashboardAcumenMetricDataResponse | null>(null);
	const [sprints, setSprints] = useState<IDashboardSprint[] | undefined>(undefined);
	const [devCycles, setDevCycles] = useState<IDashboardSprint[] | undefined>(undefined);
	const [chartOptions, setChartOptions] = useState<Highcharts.Options | undefined>();

	const {
		teamsStore, metricTesterStore, dataContributorsStore, repositoriesStore, componentsStore, projectsStore,
		boardsStore, sprintsStore } = useStores();
	const { metadata } = metricTesterStore!;

	useEffect(() => {
		teamsStore.fetchAllTeams().then().catch();
		dataContributorsStore.fetchAllDataContributors().then().catch();
		repositoriesStore.fetchData().then().catch();
		componentsStore.fetchData().then().catch();
		projectsStore.fetchData().then().catch();
		sprintsStore.fetchData(DevelopmentMethodology.Scrum, undefined, undefined, false).then(response => setSprints(response)).catch();
		sprintsStore.fetchData(DevelopmentMethodology.Kanban, undefined, undefined, false).then(response => setDevCycles(response)).catch();
		boardsStore.fetchData().then().catch();
	}, []);

	useEffect(() => {
		if (metadata.loading || metadata.loaded) {
			return;
		}

		metricTesterStore.fetchMetadata().then().catch();

	}, []);

	useEffect(() => {
		if (!metadata.loaded) {
			return;
		}

		changeDropDownState({
			metricLabels: selectedLabelOptions(),
			metricDimensions: selectedGroupByOptions(),
			metricIntervals: selectedIntervalOptions(),
			dataContributors: dataContributorsStore.allDataContributors.data.map(x => ({
				key: x.primaryDisplayName ?? "",
				value: x.id,
				image: (x.primaryAvatarUrl) ? { avatar: true, src: x.primaryAvatarUrl } : undefined,
				text: `${x.primaryDisplayName} (${x.id})`
			})),
			teams: teamsStore.allTeams.data.map(x => ({
				key: x.name,
				value: x.id,
				text: `${x.name} (${x.id})`
			})).sort((a, b) => a.text.localeCompare(b.text)),
			repositories: repositoriesStore.gitRepositoriesData.external.map(x => ({
				key: x.fullName,
				value: x.entityId,
				text: x.name
			})).sort((a, b) => a.text.localeCompare(b.text)),
			components: (componentsStore.components ?? []).map(x => ({
				key: x.name!,
				value: x.entityId,
				text: x.name!
			})).sort((a, b) => a.text.localeCompare(b.text)),
			projects: projectsStore.projectsData.external.map(x => ({
				key: x.name,
				value: x.entityId,
				text: x.name
			})).sort((a, b) => a.text.localeCompare(b.text)),
			sprints: _.sortBy(sprints, s => [s.startDate, s.endDate, s.name])
				.reverse()
				.map(x => ({
					key: x.name!,
					value: x.id,
					text: x.name!
				})),
			devCycles: _.sortBy(devCycles, s => [s.startDate, s.endDate, s.name])
				.reverse()
				.map(x => ({
					key: x.name!,
					value: x.id,
					text: x.name!
				})),
			boards: boardsStore.boardsData.external.map(x => ({
				key: x.name!,
				value: x.entityId,
				text: x.name!
			})).sort((a, b) => a.text.localeCompare(b.text)),
			acumenIssueTypes: Object.values(AcumenTaskType).map(x => ({
				key: x,
				value: x,
				text: x
			})).sort((a, b) => a.text.localeCompare(b.text)),
			acumenStatuses: Object.values(AcumenTaskStatus).map(x => ({
				key: x,
				value: x,
				text: x
			})).sort((a, b) => a.text.localeCompare(b.text))
		});

		if (!selectedState.ranOnStart && metricTesterStore.metadata.loaded && dataContributorsStore.allDataContributors.loaded &&
			teamsStore.allTeams.loaded && !repositoriesStore.isLoading && componentsStore.components
			&& !projectsStore.isLoading && !boardsStore.isLoading && devCycles && sprints) {
			onStateChange({ ranOnStart: true });

			if (validateInput() === undefined) {
				// tslint:disable-next-line: no-floating-promises
				onSearch();
			}
		}
	}, [
		metricTesterStore.metadata.loaded, selectedState.selectedMetricName, dataContributorsStore.allDataContributors.loaded,
		teamsStore.allTeams.loaded, repositoriesStore.isLoading, componentsStore.components, projectsStore.isLoading,
		boardsStore.isLoading, devCycles, sprints
	]);

	const onStateChange = (newState: Partial<IMetricSelectionState>) => {
		changeState(x => {
			const res = Object.assign({}, x, newState);

			return res;
		});
	};

	const onMetricNameSelect = async (metricName: string) => {
		changeState({ selectedMetricName: metricName, selectedInterval: undefined, selectedLabel: undefined, selectedGroupBy: undefined, dimensions: {}, ranOnStart: true });
		setMetricResponse(null);
	};

	const onDimValueChange = async (dimension: string, value?: string) => {
		changeState(x => {
			const res = Object.assign({}, x);

			if ((value === undefined)) {
				if (res.dimensions && res.dimensions[dimension] !== undefined) {
					delete res.dimensions[dimension];
				}
			} else {
				if (!res.dimensions) {
					res.dimensions = {};
				}

				res.dimensions[dimension] = value!;
			}

			return res;
		});
	};

	const dimensionToDropDownOption = (dimType: DimensionType): IDropDownOption[] => {
		switch (dimType) {
			case DimensionType.BOARD:
				return dropDownState.boards;

			case DimensionType.BOOLEAN:
				return [{ key: "true", value: "true", text: "true" }, { key: "false", value: "false", text: "false" }, { key: "unknown", value: "null", text: "unknown" }];

			case DimensionType.COMPONENT:
				return dropDownState.components;

			case DimensionType.DC:
				return dropDownState.dataContributors;

			case DimensionType.DEV_CYCLE:
				return dropDownState.devCycles;

			case DimensionType.ISSUE_STATUS:
				return dropDownState.acumenStatuses;

			case DimensionType.ISSUE_TYPE:
				return dropDownState.acumenIssueTypes;

			case DimensionType.PROJECT:
				return dropDownState.projects;

			case DimensionType.REPOSITORY:
				return dropDownState.repositories;

			case DimensionType.SPRINT:
				return dropDownState.sprints;

			case DimensionType.TEAM:
				return dropDownState.teams;

			case DimensionType.UNKNOWN:
				throw new Error("Not a drop down");
		}
	};

	const generateWrappedInput = (dim: string): JSX.Element => {
		return (<Grid.Row key={`row_${dim}`} columns={3}>
			<Grid.Column width={3} verticalAlign="middle">
				{dim}:
			</Grid.Column>
			<Grid.Column key={`column_${dim}`} width={12} verticalAlign="middle">
				{generateInput(dim)}
			</Grid.Column>
			<Grid.Column width={1} verticalAlign="middle">
				<Button negative={true}
					onClick={() => onDimValueChange(dim)}
					icon="ban"
				/>
			</Grid.Column>
		</Grid.Row>
		);

	};

	const generateInput = (dimension: string): JSX.Element => {
		switch (dimToType(dimension)) {
			case DimensionType.DC:
			case DimensionType.BOOLEAN:
			case DimensionType.TEAM:
			case DimensionType.REPOSITORY:
			case DimensionType.PROJECT:
			case DimensionType.COMPONENT:
			case DimensionType.BOARD:
			case DimensionType.SPRINT:
			case DimensionType.DEV_CYCLE:
			case DimensionType.ISSUE_STATUS:
			case DimensionType.ISSUE_TYPE:
				return (
					<Dropdown
						placeholder={dimension}
						fluid={true}
						selection={true}
						search={true}
						clearable={true}
						onChange={(_e, data) => onDimValueChange(dimension, (data.value as string[]).map(x => x.trim()).join(","))}
						value={(selectedState.dimensions && selectedState.dimensions[dimension]) ? selectedState.dimensions[dimension].split(",") : []}
						options={dimensionToDropDownOption(dimToType(dimension))}
						multiple={true}
					/>
				);

			case DimensionType.UNKNOWN:
				return (<Input
					placeholder={dimension + " value"}
					value={(selectedState.dimensions && selectedState.dimensions[dimension]) ? selectedState.dimensions[dimension] : undefined}
					onChange={(_x, y) => onDimValueChange(dimension, y.value as string)}
				/>);

		}
	};

	const selectedLabelOptions = () => {
		if (!selectedState.selectedMetricName) {
			return [];
		}

		const metric = selectedState.selectedMetricName as AcumenMetricGroupType;

		if (!metricTesterStore.metadata.loaded) {
			return [];
		}

		return Object.values(metricTesterStore.metadata.data[metric].labels).sort().map(value => ({
			key: value,
			value,
			text: value
		}));
	};

	const selectedIntervalOptions = () => {
		if (!selectedState.selectedMetricName) {
			return [];
		}

		const metric = selectedState.selectedMetricName as AcumenMetricGroupType;

		if (!metricTesterStore.metadata.loaded) {
			return [];
		}

		return Object.values(metricTesterStore.metadata.data[metric].intervals).sort().map(value => ({
			key: value,
			value,
			text: value
		}));
	};

	const selectedGroupByOptions = () => {
		if (!selectedState.selectedMetricName) {
			return [];
		}

		if (!metricTesterStore.metadata.loaded) {
			return [];
		}

		const metric = selectedState.selectedMetricName as AcumenMetricGroupType;
		return Object.values(metricTesterStore.metadata.data[metric].groupBy).sort().map(value => ({
			key: value,
			value,
			text: value
		}));
	};

	const mapDimensionIdToName = (dimension: string, id: string): string => {
		switch (dimToType(dimension)) {
			case DimensionType.BOOLEAN:
				return id;

			case DimensionType.COMPONENT:
				return dropDownState.components.find(x => x.value === id)?.text ?? id;

			case DimensionType.PROJECT:
				return dropDownState.projects.find(x => x.value === id)?.text ?? id;

			case DimensionType.TEAM:
				return dropDownState.teams.find(x => x.value === id)?.text ?? id;

			case DimensionType.REPOSITORY:
				return dropDownState.repositories.find(x => x.value === id)?.text ?? id;

			case DimensionType.DC:
				return dropDownState.dataContributors.find(x => x.value === id)?.text ?? id;

			case DimensionType.SPRINT:
				return dropDownState.sprints.find(x => x.value === id)?.text ?? id;

			case DimensionType.BOARD:
				return dropDownState.boards.find(x => x.value === id)?.text ?? id;

			case DimensionType.DEV_CYCLE:
				return dropDownState.devCycles.find(x => x.value === id)?.text ?? id;

			case DimensionType.ISSUE_TYPE:
				return dropDownState.acumenIssueTypes.find(x => x.value === id)?.text ?? id;

			case DimensionType.ISSUE_STATUS:
				return dropDownState.acumenStatuses.find(x => x.value === id)?.text ?? id;

			case DimensionType.UNKNOWN:
				return id;
		}
	};

	const parseMetricResponse = (metricRes: IDashboardAcumenMetricDataResponse, format: FormatOption) => {
		return Object.keys(metricRes.values).map(key => {
			const values = metricRes.values[key];

			let data: Array<[string | number, number | null]> = [];

			if (_.includes([MetricInterval.SPRINT, MetricInterval.SPRINT_DATE], metricRes.interval)) {
				data = dropDownState.sprints.slice().reverse().filter(x => x.value in values)
					.map(x => [x.text, values[x.value]]);
			} else if (_.includes([MetricInterval.DEV_CYCLE, MetricInterval.DEV_CYCLE_DATE], metricRes.interval)) {
				data = dropDownState.devCycles.slice().reverse().filter(x => x.value in values)
					.map(x => [x.text, values[x.value]]);
			} else if (_.includes([MetricInterval.DAYS_30, MetricInterval.DAYS_7], metricRes.interval)) {
				const days = (metricRes.interval === MetricInterval.DAYS_30) ? 30 : 7;
				data = Object.keys(values).map(parseFloat).sort().map(valueKey => {
					const value = values[valueKey];
					const date = moment().add(valueKey * days, "day");
					switch (format) {
						case FormatOption.DATE: {
							return [date.format("YYYY-MM-DD"), value];
						}
						case FormatOption.MILLISECONDS:
						default: {
							return [date.toDate().getTime(), value];
						}
					}
				});
			} else {
				data = Object.keys(values).map(valueKey => {
					const value = values[valueKey];
					const date = moment.tz(valueKey, "YYYY-MM-DD", metricRes.timezone);
					switch (format) {
						case FormatOption.DATE: {
							return [date.format("YYYY-MM-DD"), value];
						}
						case FormatOption.MILLISECONDS:
						default: {
							return [date.toDate().getTime(), value];
						}
					}
				});
			}
			const isEmptySeries = data.map(x => x[1]).filter(x => x !== null && x > 0).length === 0;
			const seriesName = metricRes.groupBy === null ?
						`${metricRes.metric} - ${metricRes.label}` :
						mapDimensionIdToName(metricRes.groupBy!, key);
			return {
				isEmptySeries,
				seriesName,
				data
			};
		});
	};

	const createOptions = (metricRes: IDashboardAcumenMetricDataResponse | null) => {
		if (!metricRes) {
			const emptyChartOptions: Highcharts.Options = {
				title: {
					text: ``
				},
				credits: {
					enabled: false
				},
			};

			return emptyChartOptions;
		}

		const series =
			parseMetricResponse(metricRes, FormatOption.MILLISECONDS)
			.map(values => {
				return {
					name: (metricRes.groupBy === null) ? values.seriesName :
						`${values.seriesName}${`${values.isEmptySeries ? "" : " (No Data)"}`}`,
					data: values.data,
					lineWidth: 2,
					dashStyle: "solid",
					type: "spline",
					tooltip: {
						valueDecimals: 2
					},
					visible: !values.isEmptySeries,
				};
			});
		const options: Highcharts.Options = {
			chart: {
				type: "spline"
			},
			title: {
				text: `${metricRes.metric} - ${metricRes.label} - ${metricRes.interval}`
			},
			xAxis: {
				gridLineWidth: 1,
				type: (_.includes([MetricInterval.DEV_CYCLE, MetricInterval.DEV_CYCLE_DATE, MetricInterval.SPRINT, MetricInterval.SPRINT_DATE], metricRes.interval) ? "category" : "datetime"),
			},
			yAxis: {
				startOnTick: false,
				endOnTick: false,
				title: {
					text: metricRes.metric
				}
			},
			credits: {
				enabled: false
			},
			series: series as any
		};

		return options;
	};

	const createExport = (metricRes: IDashboardAcumenMetricDataResponse | null) => {
		if (!metricRes) {
			return [];
		}

		const series =
			parseMetricResponse(metricRes, FormatOption.DATE)
			.map(item => {
				return item.data.map(id => {
					if (metricRes.groupBy === null) {
						return {
							time: id[0],
							value: id[1] ?? 0
						};
					} else {
						return {
							series: item.seriesName,
							time: id[0],
							value: id[1] ?? 0
						};
					}
				});
			});
		return _.flatten(series);
	};

	useEffect(() => {
		setChartOptions(createOptions(metricResponse));
	}, [metricResponse]);

	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		updateQueryString();
	}, [selectedState]);

	const updateQueryString = () => {
		const params = new URLSearchParams({});
		if (selectedState.selectedMetricName) {
			params.set("metric", selectedState.selectedMetricName);
		}

		if (selectedState.selectedInterval) {
			params.set("interval", selectedState.selectedInterval);
		}

		if (selectedState.selectedLabel) {
			params.set("label", selectedState.selectedLabel);
		}

		if (selectedState.startTime) {
			params.set("startTime", selectedState.startTime.toISOString());
		}

		if (selectedState.endTime) {
			params.set("endTime", selectedState.endTime.toISOString());
		}

		if (selectedState.dimensions) {
			Object.keys(selectedState.dimensions)
				.map(dim => `${dim}=${selectedState.dimensions![dim]}`)
				.forEach(x => params.append("dimensions[]", x));
		}

		if (selectedState.selectedGroupBy) {
			params.set("groupBy", selectedState.selectedGroupBy);
		}

		history.replace({ pathname: location.pathname, search: params.toString() });
	};

	const validateInput = (validateOptionals: boolean = false): string | undefined => {
		if (!selectedState.selectedMetricName) {
			return "Missing metric";
		}

		if (!selectedState.selectedInterval) {
			return "Missing metric interval";
		}

		if (!selectedState.selectedLabel) {
			return "Missing metric label";
		}

		if (!selectedState.startTime) {
			return "Missing start time";
		}

		if (!selectedState.endTime && validateOptionals) {
			return "Missing end time";
		}

		return undefined;
	};

	const onSearch = async () => {
		const inputRes = validateInput();
		if (inputRes) {
			toast.error(inputRes, {
				toastId: `MetricTester-${_.kebabCase(inputRes)}`
			});

			return;
		}

		if (!selectedState.endTime) {
			onStateChange({ endTime: new Date() });
		}

		setMetricResponse(null);
		setIsLoadingChart(true);

		const dimensions: string[] = Object.keys(selectedState.dimensions ?? {})
			.filter(dim => selectedState.dimensions![dim] && selectedState.dimensions![dim].length > 0)
			.map(dim => `${dim}=${selectedState.dimensions![dim]}`);

		const timezone = "Asia/Jerusalem";
		const res = await metricTesterStore.fetchMetric(selectedState.selectedMetricName as AcumenMetricGroupType, selectedState.selectedLabel!,
			selectedState.selectedInterval as MetricInterval, timezone, selectedState.startTime!, selectedState.endTime!, dimensions, selectedState.selectedGroupBy);

		setIsLoadingChart(false);
		updateQueryString();
		setMetricResponse(res);
	};

	return (
		<Grid className="metric-tester-page">
			<Grid.Row>
				<Grid.Column width={16}>
					<LoadingIndicator local={true} isActive={metadata.loading}>
					<Segment raised={true} padded={true} secondary={true} loading={!metadata.data}>
						<Grid relaxed={true}>
							<Grid.Row centered={true}>
								<Grid.Column textAlign={"left"}><Header textAlign={"left"} as="h2">Metric properties</Header></Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={7}>
									<Dropdown
										placeholder="Metric"
										fluid={true}
										selection={true}
										search={true}
										onChange={(_e, data) => onMetricNameSelect(data.value as string)}
										value={selectedState.selectedMetricName}
										error={selectedState.selectedMetricName === undefined}
										options={Object.keys(metricTesterStore.metadata.data).sort().map(value => ({
											key: value,
											value,
											text: value
										}))}
									/>
								</Grid.Column>
								<Grid.Column width={6}>
									<Dropdown
										placeholder="Label"
										fluid={true}
										selection={true}
										clearable={true}
										onChange={(_e, data) => onStateChange({ selectedLabel: data.value as string })}
										value={(selectedState.selectedLabel) ? selectedState.selectedLabel: NON_EXISTING_OPTION}
										options={dropDownState.metricLabels}
										error={selectedState.selectedLabel === undefined}
									/>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={5}>
									<Dropdown
										placeholder="Interval"
										fluid={true}
										selection={true}
										clearable={true}
										options={dropDownState.metricIntervals}
										value={selectedState.selectedInterval ? selectedState.selectedInterval?.toString(): NON_EXISTING_OPTION}
										error={selectedState.selectedInterval === undefined}
										onChange={(_e, data) => onStateChange({ selectedInterval: data.value as string })}
									/>
								</Grid.Column>
								<Grid.Column width={4}>
									<DatePicker
										className="form-control"
										name="startTime"
										placeholderText="Start date"
										isClearable={true}
										selected={selectedState.startTime}
										required={true}
										onChange={(date: Date | null) => onStateChange({ startTime: date ?? undefined })}
									/>
								</Grid.Column>
								<Grid.Column width={4}>
									<div className="field">
										<DatePicker
											className="form-control"
											placeholderText="End date"
											name="endTime"
											isClearable={true}
											selected={selectedState.endTime}
											required={true}
											onChange={(date: Date | null) => onStateChange({ endTime: date ?? undefined })}
											todayButton={true}
										/>
									</div>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Segment>
					</LoadingIndicator>
				</Grid.Column>
				<Grid.Column width={16}>
					<Segment raised={true} padded={true} secondary={true} loading={!metadata.data}>
						<Grid>
							<Grid.Row>
								<Grid.Column textAlign={"left"}><Header textAlign={"left"} as="h2">Select dimensions to filter by</Header></Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column>
									<Dropdown
										placeholder="Dimensions"
										fluid={true}
										selection={true}
										multiple={true}
										search={true}
										clearable={true}
										onChange={(_e, data) => (data.value as string[]).filter(dim => !(dim in (selectedState.dimensions ?? {}))).map(x => onDimValueChange(x, ""))}
										value={Object.keys(selectedState.dimensions ?? {}).filter(x => x !== undefined)}
										options={dropDownState.metricDimensions}
									/>

								</Grid.Column>
							</Grid.Row>
							{Object.keys(selectedState.dimensions ?? {})
								.filter(x => (x in (selectedState.dimensions ?? {})) && selectedState.dimensions !== undefined)
								.map((dim) => generateWrappedInput(dim))
							}
						</Grid>
					</Segment>
				</Grid.Column>
				<Grid.Column width={16}>
					<Segment raised={true} padded={true} secondary={true} loading={!metadata.data}>
						<Grid>
							<Grid.Row>
								<Grid.Column textAlign={"left"}><Header textAlign={"left"} as="h2">Select group by</Header></Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column>
									<Dropdown
										placeholder="Group by"
										fluid={true}
										selection={true}
										clearable={true}
										search={true}
										onChange={(_e, data) => onStateChange({ selectedGroupBy: data.value as string })}
										value={selectedState.selectedGroupBy}
										options={dropDownState.metricDimensions}
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Segment>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row textAlign={"left"}>
				<Grid.Column>
					<Button primary={true} onClick={() => onSearch()} size="large">
						Search
					</Button>
					{metricResponse && <Button
						basic={true}
						secondary={true}
						size="large" onClick={async (e) => {
							e.stopPropagation();
							const output = createExport(metricResponse);
							const a = document.createElement("a");
							const data = Papa.unparse(output);
							a.download = `export_metric_${new Date()
								.toJSON()
								.slice(0, 10)}.csv`;
							const blob = new Blob([data], { type: "text/csv" });
							a.href = window.URL.createObjectURL(blob);
							const clickEvt = new MouseEvent("click", {
								view: window,
								bubbles: true,
								cancelable: true,
							});
							a.dispatchEvent(clickEvt);
							a.remove();
						}}>
						Export
					</Button>}
				</Grid.Column>
			</Grid.Row>
			<Grid.Row>
				<LoadingIndicator isActive={metadata.loading || isLoadingChart}>
					{chartOptions ?
					<div className="sixteen wide column">
						<div className="ui segment raised">
							<div className="reports-grid">
								<HighchartsReact
									highcharts={Highcharts}
									options={chartOptions}
								/>
							</div>
						</div>
					</div>
					: <div/>
					}
				</LoadingIndicator>
			</Grid.Row>
		</Grid>
	);
}

export default observer(MetricTesterPage);
