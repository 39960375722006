import React from "react";
import seedrandom from "seedrandom";
import BearAvatarIcon from "../svg-assets/avatars/Bear";
import BugAvatarIcon from "../svg-assets/avatars/Bug";
import CatAvatarIcon from "../svg-assets/avatars/Cat";
import ChickAvatarIcon from "../svg-assets/avatars/Chick";
import ChickenAvatarIcon from "../svg-assets/avatars/Chicken";
import CowAvatarIcon from "../svg-assets/avatars/Cow";
import DogAvatarIcon from "../svg-assets/avatars/Dog";
import MonkeyAvatarIcon from "../svg-assets/avatars/Monkey";
import PandaAvatarIcon from "../svg-assets/avatars/Panda";
import TigerAvatarIcon from "../svg-assets/avatars/Tiger";
import UnicornAvatarIcon from "../svg-assets/avatars/Unicorn";
import WolfAvatarIcon from "../svg-assets/avatars/Wolf";

export enum InvitePlayerAvatar {
	Tiger = "Tiger",
	Wolf = "Wolf",
	Bear = "Bear",
	Panda = "Panda",
	Chicken = "Chicken",
	Chick = "Chick",
	Monkey = "Diamond",
	Dog = "Dog",
	Cat = "Cat",
	Bug = "Bug",
	Cow = "Cow",
	Unicorn = "Unicorn",
}

export const getInvitePlayerAvatar = (
	avatar?: InvitePlayerAvatar,
	className?: string
) => {
	if (avatar) {
		switch (avatar) {
			case InvitePlayerAvatar.Tiger: {
				return <TigerAvatarIcon className={className} />;
			}
			case InvitePlayerAvatar.Wolf: {
				return <WolfAvatarIcon className={className} />;
			}
			case InvitePlayerAvatar.Bear: {
				return <BearAvatarIcon className={className} />;
			}
			case InvitePlayerAvatar.Panda: {
				return <PandaAvatarIcon className={className} />;
			}
			case InvitePlayerAvatar.Chicken: {
				return <ChickenAvatarIcon className={className} />;
			}
			case InvitePlayerAvatar.Chick: {
				return <ChickAvatarIcon className={className} />;
			}
			case InvitePlayerAvatar.Monkey: {
				return <MonkeyAvatarIcon className={className} />;
			}
			case InvitePlayerAvatar.Dog: {
				return <DogAvatarIcon className={className} />;
			}
			case InvitePlayerAvatar.Cat: {
				return <CatAvatarIcon className={className} />;
			}
			case InvitePlayerAvatar.Unicorn: {
				return <UnicornAvatarIcon className={className} />;
			}
			case InvitePlayerAvatar.Cow: {
				return <CowAvatarIcon className={className} />;
			}
			case InvitePlayerAvatar.Bug: {
				return <BugAvatarIcon className={className} />;
			}
		}
	}

	return <CatAvatarIcon className={className} />;
};

export function shuffledAvatarArray(seed: string): InvitePlayerAvatar[] {
	const avatars = Object.values(InvitePlayerAvatar);
	const rng = seedrandom(seed);

	const shuffled = avatars
		.map((value) => ({ value, sort: rng() }))
		.sort((a, b) => a.sort - b.sort)
		.map(({ value }) => value);
	return shuffled;
}
