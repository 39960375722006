import React, { useCallback, useMemo, useState } from "react";

import TriggerOnClickOutside from "../../../ux-effects/trigger-on-click-outside";
import UsersDropdownOption from "./users-dropdown-option";
import "./users-dropdown.scss";
import { UserOption } from "../types";

interface UsersDropdownOptionsProps {
	options: UserOption[];
	committedContributorIds: Set<string> | null;
	selectedContributorIds: Set<string> | null;
	actionButtons?: React.ReactNode;
	itemsBeforeUsers?: React.ReactNode;
	footer?: React.ReactNode;
	onToggleContributor: (id: string) => void;
	onHide: () => void;
}

const UsersDropdownOptions = (props: UsersDropdownOptionsProps) => {
	const { options, committedContributorIds, selectedContributorIds, actionButtons, itemsBeforeUsers, footer, onToggleContributor, onHide } = props;
	const [searchTerm, setSearchTerm] = useState("");
	const filteredUsers = useUsersFilteredBySearchTerm(options, searchTerm);
	const optionsSortedBySelectionState = useMemo(() => {
		return filteredUsers.sort(user => !!committedContributorIds?.has(user.id) ? -1 : 1);
	}, [filteredUsers, committedContributorIds]);
	const handleSearchInput = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(event.currentTarget.value),
		[]
	);

	return (
		<div className="users-dropdown ui segment raised clickable">
			<div className="items-search-field">
				<span className="search-icon"/>
				<input
					autoFocus={true}
					type="search"
					className="items-search-input"
					placeholder="Search team member..."
					value={searchTerm}
					onChange={handleSearchInput}
				/>
			</div>
			{actionButtons}
			<TriggerOnClickOutside excludedClassName="extra-icon-btn create-contributors-filter-modal" onTrigger={onHide}>
				<ul className="items">
					{itemsBeforeUsers}
					{optionsSortedBySelectionState.map(option => (
						<UsersDropdownOption
							key={option.id}
							user={option}
							isSelected={!!selectedContributorIds?.has(option.id)}
							onToggle={onToggleContributor}
						/>
					))}
				</ul>
			</TriggerOnClickOutside>
			{footer}
		</div>
	);
};

const useUsersFilteredBySearchTerm = (users: UserOption[], searchTerm: string): UserOption[] => {
	return useMemo(() => {
		const lowerCaseSearchTerm = searchTerm.toLowerCase();

		return users.filter(user => user.displayName?.toLowerCase().includes(lowerCaseSearchTerm));
	}, [users, searchTerm]);
};

export default UsersDropdownOptions;
