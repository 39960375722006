import { ConfigurationEntityType, CustomizableConfigurationCategories, DashboardTeamMembershipType, TeamOwnableEntityType } from "@acumen/dashboard-common";
import { useStores } from "../../../mobx-stores";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useForm, Controller, Control } from "react-hook-form";
import ReactSelect, { ActionMeta, OptionsType } from "react-select";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { GA_EVENT_ACTION, GA_EVENT_CATEGORY, clickEvent } from "../../../analytics-events";
import "./style.scss";
import _, { concat } from "lodash";
import { FieldValues } from "react-hook-form/dist/types/form";
import classNames from "classnames";
import { NEW_TEAM_ROUTE } from ".";
import StringInput from "../../../components/form/string-input";
import { ICreateMembersData, IDeleteMembersData } from "../../../mobx-stores/team-members-store";
import { ISelectOptionGroups, ISelectOptions } from "../../../components/form/option-select";
import ConfirmationModal from "../../../components/modals/confirmation-modal";
import { IDashboardCustomizableValue } from "../../../mobx-stores/customization-store";
import CustomizationFields from "../../../components/customize-configurations/customization-fields";
import { DEFAULT_DATA_CONTRIBUTOR_FILTERS } from "../../../mobx-stores/data-contributors-store";

interface ParamTypes {
	teamId: string;
}

enum TeamFormFields {
	Name = "name",
	Description = "description",
	TeamMembers = "teamMembers",
	TeamLead = "teamLead",
	Boards = "boards",
	Projects = "projects",
	Components = "components",
	GitRepositories = "gitRepositories",
}

interface IEditTeamForm {
	[TeamFormFields.Name]: string;
	[TeamFormFields.Description]: string;
	[TeamFormFields.TeamMembers]: ISelectOption[];
	[TeamFormFields.TeamLead]: ISelectOption;
	[TeamFormFields.Boards]: ISelectOption;
	[TeamFormFields.Projects]: ISelectOption;
	[TeamFormFields.Components]: ISelectOption;
	[TeamFormFields.GitRepositories]: ISelectOption;
}

const ownableEntitiesFormFields = [
	{
		name: TeamFormFields.Boards,
		entityType: TeamOwnableEntityType.JiraBoard
	},
	{
		name: TeamFormFields.Projects,
		entityType: TeamOwnableEntityType.JiraProject
	},
	{
		name: TeamFormFields.Components,
		entityType: TeamOwnableEntityType.JiraComponent
	},
	{
		name: TeamFormFields.GitRepositories,
		entityType: TeamOwnableEntityType.GitHubRepository
	}
];

const CANT_REMOVE_TL_WARNING_TOAST_ID = "warning_cannot_remove_team_lead";
const SAVED_TEAM_SUCCESS_TOAST_ID = "saved_team_details_successfully";
const SAVED_TEAM_MESSAGE = "Success: All your changes have been saved.";
const CANT_REMOVE_TL_WARNING_MESSAGE = "Warning: Make sure the current team lead is replaced before removing her.";
const AUTO_DISMISS_TOAST_MS = 5000;

// tslint:disable-next-line: variable-name
const EditTeamPage = (() => {
	const [originalTeamMembers, setOriginalTeamMembers] = useState<ISelectOption[]>([]);
	const [newTeamMembers, setNewTeamMembers] = useState<ISelectOption[] | undefined>(undefined);
	const [teamLead, setTeamLead] = useState<ISelectOption | undefined>(undefined);
	const [isNewTeam, setIsNewTeam] = useState<boolean>(false);
	const [isNewTeamValid, setIsNewTeamValid] = useState<boolean>(false);
	const [isFormDirty, setFormDirty] = useState<boolean>(false);
	const [dirtyOwnableEntities, setDirtyOwnableEntities] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [hasTeamData, setHasTeamData] = useState<boolean>(false);
	const [changeTeamOwnershipForManager, toggleChangeTeamOwnershipForManagerAlert] = useState<boolean | undefined>(undefined);
	const [haveCustomizationConfig, setCustomizationConfig] = useState<boolean>(false);
	const [changedFormFields, setChangedFormFields] = useState<Partial<IDashboardCustomizableValue>>({});
	const [shouldShowConfigurationSection, setShouldShowConfigurationSection] = useState<boolean | undefined>(undefined);

	const {
		register,
		control,
		setValue,
		getValues,
		reset
	} = useForm({
		defaultValues: {},
		reValidateMode: "onBlur"
	});

	const { teamId } = useParams<ParamTypes>();

	const {
		teamsStore: {
			allTeams,
			fetchAllTeams,
			updateTeam,
			createTeam
		},
		teamMembersStore: {
			fetchAllTeamMembers,
			createTeamMembers,
			deleteTeamMembers,
		},
		dataContributorsStore: {
			fetchAllDataContributors,
			allDataContributors
		},
		projectsStore: {
			projectOptions,
			fetchData: fetchProjectsData,
			isLoading: projectsLoading
		},
		boardsStore: {
			boardOptions,
			fetchData: fetchBoardsData,
			isLoading: isLoadingBoards
		},
		componentsStore: {
			componentsOptions,
			componentsByTeamOptions,
			fetchComponentDataByTeam,
			fetchData: fetchComponentsData,
		},
		repositoriesStore: {
			gitRepositoriesOptions,
			fetchData: fetchGitRepoData,
			isLoading: isLoadingGitRepos
		},
		authStore: {
			isUserExecutiveOrAbove,
			isUserManagerOrAbove,
			authUser
		},
		customizationStore: {
			updateConfigurationByCategory,
		}
	} = useStores();
	const history = useHistory();

	useEffect(() => {
		// tslint:disable-next-line: no-floating-promises
		fetchAllDataContributors(DEFAULT_DATA_CONTRIBUTOR_FILTERS);
		return () => {
			reset({
				[TeamFormFields.Boards]: [],
				[TeamFormFields.Projects]: [],
				[TeamFormFields.Components]: [],
				[TeamFormFields.GitRepositories]: []
			});
		};
	}, []);

	useEffect(() => {
		async function retrieveTeamsData() {
			if (!allTeams.loaded) {
				// tslint:disable-next-line: no-floating-promises
				fetchAllTeams();
			}
			if (teamId && teamId !== NEW_TEAM_ROUTE && !hasTeamData) {
				setHasTeamData(true);
				// tslint:disable-next-line: no-floating-promises
				fetchProjectsData({ teamId });
				// tslint:disable-next-line: no-floating-promises
				fetchBoardsData({ teamId });
				// tslint:disable-next-line: no-floating-promises
				fetchGitRepoData({ teamId });
				// tslint:disable-next-line: no-floating-promises
				fetchComponentDataByTeam({ teamId });
				// tslint:disable-next-line: no-floating-promises
				fetchAllTeamMembers(teamId).then((res) => {
					const _teamLead = res?.find(_member => _member.membershipType === DashboardTeamMembershipType.TeamLeader);
					if (_teamLead) {
						setTeamLead({
							key: _teamLead.dataContributorId,
							value: _teamLead.id,
							label: _teamLead.displayName,
						});
						setValue(TeamFormFields.TeamLead, {
							key: _teamLead.dataContributorId,
							value: _teamLead.id,
							label: _teamLead.displayName,
						});
					}
					const teamMembers = _.orderBy(res?.map((_dc, i) => {
						return {
							key: _dc.dataContributorId,
							value: _dc.id,
							label: _dc.displayName,
						};
					}), u => u ? u.label?.toLowerCase() : "");
					setOriginalTeamMembers(teamMembers);
					setValue(TeamFormFields.TeamMembers, teamMembers);
					setShouldShowConfigurationSection(canShowConfigurationControl());
				});
			} else if (teamId && teamId === NEW_TEAM_ROUTE) {
				setIsNewTeam(true);
				// tslint:disable-next-line: no-floating-promises
				fetchProjectsData();
				// tslint:disable-next-line: no-floating-promises
				fetchBoardsData();
				// tslint:disable-next-line: no-floating-promises
				fetchGitRepoData();
				// tslint:disable-next-line: no-floating-promises
				fetchComponentsData();
			}
		}
		// tslint:disable-next-line: no-floating-promises
		retrieveTeamsData();
	}, [teamId, allTeams]);

	useEffect(() => {
		if (allTeams && allTeams.data.length > 0) {
			const team = allTeams.data.find(_team => _team.id === teamId);
			if (!team && teamId !== NEW_TEAM_ROUTE) {
				history.push(`/my-account/teams`);
			}
			setValue(TeamFormFields.Name, team?.name);
			setValue(TeamFormFields.Description, team?.description);
		}
		return () => {
			setValue(TeamFormFields.Name, undefined);
			setValue(TeamFormFields.Description, undefined);
		};
	}, [allTeams]);

	useEffect(() => {
		if (isNewTeam) {
			setValue(TeamFormFields.Projects, []);
		} else if (projectOptions && projectOptions.length && !isNewTeam) {
			const ownable = projectOptions.find(g => g.label === "ownable")?.options;
			setValue(TeamFormFields.Projects, ownable ?? []);
		}
		return () => setValue(TeamFormFields.Projects, []);
	}, [projectOptions]);

	useEffect(() => {
		if (isNewTeam) {
			setValue(TeamFormFields.Boards, []);
		} else if (boardOptions && boardOptions.length && !isNewTeam) {
			const ownable = boardOptions.find(g => g.label === "ownable")?.options;
			setValue(TeamFormFields.Boards, ownable ?? []);
		}
		return () => setValue(TeamFormFields.Boards, []);
	}, [boardOptions]);

	useEffect(() => {
		if (isNewTeam) {
			setValue(TeamFormFields.Components, []);
		} else if (componentsByTeamOptions && componentsByTeamOptions.length) {
			const ownable = componentsByTeamOptions.find(g => g.label === "ownable")?.options;
			setValue(TeamFormFields.Components, ownable ?? []);
		}
		return () => setValue(TeamFormFields.Components, []);
	}, [componentsByTeamOptions, componentsOptions]);

	useEffect(() => {
		if (isNewTeam) {
			setValue(TeamFormFields.GitRepositories, []);
		} else if (gitRepositoriesOptions && gitRepositoriesOptions.length) {
			const ownable = gitRepositoriesOptions.find(g => g.label === "ownable")?.options;
			setValue(TeamFormFields.GitRepositories, ownable ?? []);
			return () => setValue(TeamFormFields.GitRepositories, []);
		}
	}, [gitRepositoriesOptions]);

	const onError = (error: string) => {
		toast.error(error, {
			autoClose: AUTO_DISMISS_TOAST_MS
		});
	};
	const onWarning = (warn: string, toastId?: string) => {
		toast.warning(warn, {
			toastId,
			autoClose: AUTO_DISMISS_TOAST_MS
		});
	};
	const onSuccess = (success: string, toastId?: string) => {
		toast.success(success, {
			toastId,
			autoClose: AUTO_DISMISS_TOAST_MS
		});
	};
	const getDataContributorsOptions = () => _.chain(allDataContributors.data || [])
		.map(({ id, primaryDisplayName }) => ({ key: id, value: id, label: primaryDisplayName || "", text: primaryDisplayName || "" }))
		.differenceBy(allDataContributors.data, [...originalTeamMembers ?? [], ...newTeamMembers ?? []], "label")
		.orderBy(_dc => _dc.label.toLowerCase(), "asc")
		.value();

	// Executive and above can update any team, manager can update only its own teams.
	const canUpdateTeam = (isUserExecutiveOrAbove ||
		(isUserManagerOrAbove && (teamId !== NEW_TEAM_ROUTE && teamLead && teamLead.key === authUser.dataContributorDetails.id)));
	const getFormValues: () => IEditTeamForm = () => {
		const formValues = getValues();
		const getString = (value: any) => _.isString(value) ? value : "";
		return {
			[TeamFormFields.Name]: getString(formValues[TeamFormFields.Name]),
			[TeamFormFields.Description]: getString(formValues[TeamFormFields.Description]),
			[TeamFormFields.TeamMembers]: formValues[TeamFormFields.TeamMembers],
			[TeamFormFields.TeamLead]: formValues[TeamFormFields.TeamLead],
			[TeamFormFields.Boards]: formValues[TeamFormFields.Boards],
			[TeamFormFields.Projects]: formValues[TeamFormFields.Projects],
			[TeamFormFields.Components]: formValues[TeamFormFields.Components],
			[TeamFormFields.GitRepositories]: formValues[TeamFormFields.GitRepositories]
		};
	};

	const getSelectedOwnableEntities = () => {
		const formValues = getFormValues();
		const ownableProjects = ownableEntitiesFormFields.map(ownableField => {
			const fieldValues = formValues[ownableField.name];
			if (_.isArray(fieldValues)) {
				return fieldValues.map(value => ({
					entityId: value.key,
					entityType: ownableField.entityType,
				}));
			}
			return undefined;
		}).filter(entry => entry);
		return concat(_.flatten(ownableProjects));
	};

	const validateForm = (fieldName?: TeamFormFields) => {
		const formValues = getFormValues();
		const validateFormField = (field: TeamFormFields) => {
			const value = formValues[field];
			if (!value) {
				return false;
			} else if (field === TeamFormFields.Name || field === TeamFormFields.Description) {
				return _.isString(formValues[field]) ? (value as string).length > 0 : false;
			} else if (field === TeamFormFields.TeamLead) {
				return formValues[field] && _.isString(formValues[field].value);
			}
			return false;
		};
		if (fieldName) {
			return validateFormField(fieldName);
		}

		return [
			validateFormField(TeamFormFields.Name),
			validateFormField(TeamFormFields.Description),
			validateFormField(TeamFormFields.TeamLead)
		].reduce((isAllValid, isValid) => {
			return isValid && isAllValid;
		}, true) ?? false;
	};

	const onChangeOwnableEntityField = (fieldName: TeamFormFields, newValue: OptionsType<ISelectOption | ISelectOption[]>) => {
		if (newValue && _.isArray(newValue)) {
			setValue(fieldName, newValue);
			setFormDirty(true);
			setIsNewTeamValid(validateForm());
			setDirtyOwnableEntities(true);
		}
	};

	const updateTeamData = async (id: string) => {
		const formValue: IEditTeamForm = getFormValues();
		const ownableEntities = dirtyOwnableEntities ? getSelectedOwnableEntities() : undefined;
		if (!_.isEmpty(changedFormFields)) {
			await updateConfigurationByCategory(
				{
					category: CustomizableConfigurationCategories.Team,
					configs: changedFormFields
				},
				ConfigurationEntityType.Team,
				teamId
			);
		}
		await updateTeam({
			description: formValue.description, name: formValue.name,
			teamLeadContributorId: formValue.teamLead.key, id
		}, ownableEntities);
	};

	const canShowConfigurationControl = () => {
		if (!teamId || teamId === NEW_TEAM_ROUTE) {
			return false;
		}

		if (isUserExecutiveOrAbove) {
			return true;
		}

		const updateTeamLeadId = getFormValues().teamLead.key;
		if (!updateTeamLeadId) {
			return false;
		}

		return (updateTeamLeadId === authUser.dataContributorDetails.id);
	};

	const saveTeamUpdates = async () => {
		setIsLoading(true);
		setFormDirty(false);

		if (originalTeamMembers && newTeamMembers?.length) {
			const dcToRemove = originalTeamMembers.filter(newDc => {
				return newTeamMembers?.indexOf(newDc) < 0;
			});

			const dcToAdd = newTeamMembers.filter(newDc => {
				return originalTeamMembers?.indexOf(newDc) < 0;
			});
			const createPayload: ICreateMembersData = {
				memberIds: dcToAdd.map(member => ({
					id: member.value,
					membershipType: DashboardTeamMembershipType.Member
				}))
			};

			const deletePayload: IDeleteMembersData = {
				memberIds: dcToRemove.map(dc => dc.value)
			};

			await Promise.all([
				createTeamMembers(createPayload, teamId),
				deleteTeamMembers(deletePayload, teamId)
			]);
			await updateTeamData(teamId)
				.then(() => {
					onSuccess(SAVED_TEAM_MESSAGE, SAVED_TEAM_SUCCESS_TOAST_ID);
					setOriginalTeamMembers(newTeamMembers);
					setIsLoading(false);
				});
		} else {
			await updateTeamData(teamId)
				.then(() => {
					onSuccess(SAVED_TEAM_MESSAGE, SAVED_TEAM_SUCCESS_TOAST_ID);
					setIsLoading(false);
				});
		}
	};

	return (
		<div className="ui grid" id="edit-team">
			<div className={classNames("edit-team-form", { sixteen: haveCustomizationConfig, eight: !haveCustomizationConfig }, "wide column")}>
				<div className={classNames("ui segment raised", { loading: (!isNewTeam && !hasTeamData) })}>

					<div className="ui grid">

						<form
							className={classNames("form-padding form team-edit", "ui", { sixteen: !haveCustomizationConfig, eight: haveCustomizationConfig }, "wide column")}
						>
							<div className="ui grid" style={{ padding: "1rem" }}>
								<ConfirmationModal
									className={"warning"}
									open={changeTeamOwnershipForManager !== undefined}
									header={"Change team ownership"}
									confirmButton={"Ok"}
									cancelButton={"Cancel"}
									content={`Are you sure you wish to change team ownership? This cannot be reversed without admin privileges`}
									onCancel={() => toggleChangeTeamOwnershipForManagerAlert(undefined)}
									onConfirm={async () => {
										if (changeTeamOwnershipForManager !== undefined) {
											toggleChangeTeamOwnershipForManagerAlert(undefined);
											await saveTeamUpdates();
										}
									}}
								/>

								<div className="sixteen wide column acu-capitalize" style={{ height: "5rem" }}>
									<h2 className="card-header full-width acu-capitalize">
										{isNewTeam ? "Add new team" : "Edit team"}
									</h2>
								</div>

								<div className="sixteen wide column">
									{<StringInput
										label={"Team name"}
										name={TeamFormFields.Name}
										defaultValue={getValues(TeamFormFields.Name)}
										registerInput={register}
										required={true}
										onError={(str) => onError(str)}
										disabled={!canUpdateTeam}
										onSave={(value) => {
											setValue(TeamFormFields.Name, value);
											setFormDirty(true);
											setIsNewTeamValid(validateForm());
										}}
										showRequired={isNewTeam || !validateForm(TeamFormFields.Name)}
									/>}
								</div>

								<div className="sixteen wide column">
									{<StringInput
										label={"Team description"}
										name={TeamFormFields.Description}
										defaultValue={getValues(TeamFormFields.Description)}
										registerInput={register}
										onError={(str) => onError(str)}
										required={false}
										disabled={!canUpdateTeam}
										onSave={(value) => {
											setValue(TeamFormFields.Description, value);
											setFormDirty(true);
											setIsNewTeamValid(validateForm());
										}}
										showRequired={isNewTeam || !validateForm(TeamFormFields.Description)}
									/>}
								</div>

								<div className="sixteen wide column">
									<label className="flex-spaced">Team members
										{!teamLead && <span className="message required">
											<i className="tiny icon asterisk" style={{ verticalAlign: "text-top" }} />
											required
										</span>}
									</label>
									<SelectField
										control={control}
										name={TeamFormFields.TeamMembers}
										label={TeamFormFields.TeamMembers}
										placeholder="Select team members"
										defaultValue={originalTeamMembers}
										options={getDataContributorsOptions()}
										isDisabled={!canUpdateTeam}
										onChange={(teamMembers, changedItem) => {
											if (teamId && teamMembers && Array.isArray(teamMembers)) {
												if (changedItem.action === "select-option" && changedItem?.option) {
													setValue(TeamFormFields.TeamMembers, teamMembers);
													setFormDirty(true);
													setNewTeamMembers(teamMembers);
												} else if (changedItem.action === "remove-value") {
													const currentTeamLead = getValues(TeamFormFields.TeamLead);
													if (currentTeamLead?.key === changedItem?.removedValue?.key) {
														onWarning(CANT_REMOVE_TL_WARNING_MESSAGE, CANT_REMOVE_TL_WARNING_TOAST_ID);
													} else {
														setValue(TeamFormFields.TeamMembers, teamMembers);
														setFormDirty(true);
														setNewTeamMembers(teamMembers);
													}
												}
												setIsNewTeamValid(validateForm());
											}
										}}
										isMultiSelect={true}
									/>
								</div>

								<div className="sixteen wide column">
									<label className="flex-spaced">Team lead
									{!teamLead && <span className="message required">
											<i className="tiny icon asterisk" style={{ verticalAlign: "text-top" }} />
										required
									</span>}
									</label>
									<SelectField
										control={control}
										name={TeamFormFields.TeamLead}
										label="Team lead"
										placeholder="Select team lead from team members"
										defaultValue={teamLead}
										options={(newTeamMembers ?? originalTeamMembers).sort((a, b) => ((a.label?.toLocaleLowerCase() ?? 0) > (b.label?.toLocaleLowerCase() ?? 0)) ? 1 : -1) ?? []}
										onChange={(newValue) => {
											if (newValue) {
												setValue(TeamFormFields.TeamLead, newValue);
												setFormDirty(true);
												setIsNewTeamValid(validateForm());
												setShouldShowConfigurationSection(canShowConfigurationControl());
											}
										}}
										isMultiSelect={false}
										isDisabled={!canUpdateTeam}
										closeMenuOnSelect={true}
									/>
								</div>

								<div className="sixteen wide column">
									<label className="flex-spaced">Projects</label>
									<SelectField
										control={control}
										name={TeamFormFields.Projects}
										label={TeamFormFields.Projects}
										options={getFlattenOptions(projectOptions)}
										defaultValue={isNewTeam ? [] : projectOptions.find(g => g.label === "ownable")?.options}
										onChange={(newValue) => onChangeOwnableEntityField(TeamFormFields.Projects, newValue)}
										isMultiSelect={true}
										isDisabled={projectsLoading || !canUpdateTeam}
									/>
								</div>

								<div className="sixteen wide column">
									<label className="flex-spaced">Boards</label>
									<SelectField
										control={control}
										name={TeamFormFields.Boards}
										label={TeamFormFields.Boards}
										options={getFlattenOptions(boardOptions)}
										defaultValue={isNewTeam ? [] : boardOptions.find(g => g.label === "ownable")?.options}
										onChange={(newValue) => onChangeOwnableEntityField(TeamFormFields.Boards, newValue)}
										isMultiSelect={true}
										isDisabled={isLoadingBoards || !canUpdateTeam}
									/>
								</div>

								<div className="sixteen wide column">
									<label className="flex-spaced">Components</label>
									<SelectField
										control={control}
										name={TeamFormFields.Components}
										label={TeamFormFields.Components}
										options={isNewTeam ? componentsOptions : getFlattenOptions(componentsByTeamOptions)}
										defaultValue={isNewTeam ? [] : componentsByTeamOptions.find(g => g.label === "ownable")?.options}
										onChange={(newValue) => onChangeOwnableEntityField(TeamFormFields.Components, newValue)}
										isMultiSelect={true}
										isDisabled={!canUpdateTeam}
									/>
								</div>

								<div className="sixteen wide column">
									<label className="flex-spaced">Git repositories</label>
									<SelectField
										control={control}
										name={TeamFormFields.GitRepositories}
										label={TeamFormFields.GitRepositories}
										options={getFlattenOptions(gitRepositoriesOptions)}
										defaultValue={isNewTeam ? [] : gitRepositoriesOptions.find(g => g.label === "ownable")?.options}
										onChange={(newValue) => onChangeOwnableEntityField(TeamFormFields.GitRepositories, newValue)}
										isMultiSelect={true}
										isDisabled={isLoadingGitRepos || !canUpdateTeam}
									/>
								</div>
							</div>
						</form>

						<div className={classNames("ui", { eight: haveCustomizationConfig }, "wide column", "form-padding")}>
							{shouldShowConfigurationSection && <CustomizationFields
								teamId={teamId}
								category={CustomizableConfigurationCategories.Team}
								entityType={ConfigurationEntityType.Team}
								onRender={() => setCustomizationConfig(true)}
								onChange={(values: Partial<IDashboardCustomizableValue>) => {
									setIsNewTeamValid(validateForm());
									setFormDirty(true);
									setChangedFormFields(values);
								}}
								refreshPage={() => {
									return;
								}}
							/>}
						</div>

						<div className="full-width pull-right">
							{
								isNewTeam ?
									<button
										type="submit"
										className={classNames("ui button", { loading: isLoading, disabled: !isNewTeamValid || !canUpdateTeam })}
										onClick={async (e) => {
											e.preventDefault();
											e.stopPropagation();
											setIsLoading(true);
											const formValue = getFormValues();
											const _teamId = await createTeam({
												name: formValue.name,
												description: formValue.description
											});
											if (_teamId) {
												await createTeamMembers({
													memberIds: formValue[TeamFormFields.TeamMembers].map((member: ISelectOption) => ({
														id: member.value,
														membershipType: DashboardTeamMembershipType.Member
													})
													)
												}, _teamId);
												if (isFormDirty || dirtyOwnableEntities) {
													await updateTeamData(_teamId);
												}
												history.push(`/my-account/teams`);
											}
											setIsLoading(false);
										}}
										{...clickEvent(GA_EVENT_CATEGORY.TeamAdd, GA_EVENT_ACTION.Save, "New team")}
									>Create team</button>
									:
									<button
										type="submit"
										className={classNames("ui button", { loading: isLoading, disabled: !isFormDirty || !isNewTeamValid || !canUpdateTeam })}
										onClick={async (e) => {
											e.preventDefault();
											let clearToSave = true;

											if (!isUserExecutiveOrAbove) {
												const currentTeamLead = getValues(TeamFormFields.TeamLead);
												if (!currentTeamLead || currentTeamLead.key !== authUser.dataContributorDetails.id) {
													clearToSave = false;
													toggleChangeTeamOwnershipForManagerAlert(true);
												}
											}

											if (clearToSave) {
												await saveTeamUpdates();
											}
										}}
										{...clickEvent(GA_EVENT_CATEGORY.TeamEdit, GA_EVENT_ACTION.Edit, "Edit team")}
									>Save</button>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});
export default observer(EditTeamPage);

const getFlattenOptions = (optionsGroup: ISelectOptionGroups[]) => {
	return _.flatten(optionsGroup.map(group => group.options))
		.sort((a: ISelectOptions, b: ISelectOptions) => a.label > b.label ? 1 : -1);
};

interface ISelectOption {
	key: string;
	value: string;
	label?: string;
}

// tslint:disable-next-line: variable-name
const SelectField = (props: {
	control: Control<FieldValues>;
	name: string;
	label: string;
	defaultValue?: ISelectOption | ISelectOption[];
	onChange: ((newValue: OptionsType<ISelectOption | ISelectOption[]>, actionMeta: ActionMeta<any>) => void);
	options: ISelectOption[];
	placeholder?: string;
	isLoading?: boolean;
	isMultiSelect: boolean;
	isDisabled?: boolean;
	isRequired?: boolean;
	closeMenuOnSelect?: boolean;
	noOptionsMessage?: string;
}) => {
	const {
		control, name, label, defaultValue, onChange, placeholder, isLoading = false, isDisabled = false,
		options, isMultiSelect, isRequired, noOptionsMessage, closeMenuOnSelect = false
	} = { ...props };
	return (
		<Controller
			control={control}
			name={name}
			label={label}
			isLoading={isLoading}
			defaultValue={defaultValue}
			className={"clickable"}
			render={({ value }) => {
				return (
					<ReactSelect
						className={classNames("form-control is-valid clickable", { required: isRequired && !defaultValue })}
						isDisabled={isDisabled}
						isClearable={false}
						multiValueRemove={true}
						isMulti={isMultiSelect}
						closeMenuOnSelect={closeMenuOnSelect}
						controlShouldRenderValue={true}
						options={options}
						value={value}
						onChange={((newValue: OptionsType<ISelectOption[]>, actionMeta: ActionMeta<any>) => {
							onChange(newValue, actionMeta);
						})}
						{...clickEvent(GA_EVENT_CATEGORY.TeamEdit, GA_EVENT_ACTION.Select, "Team members")}
						placeholder={placeholder}
					/>);
			}}

			noOptionsMessage={() => noOptionsMessage ?? ""}
			styles={{
				option: () => ({
					textTransform: "capitalize",
				}),
				multiValue: () => ({
					textTransform: "capitalize",
				}),
			}}
		/>);
};
