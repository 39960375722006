import { IDashboardTeam } from "@acumen/dashboard-common";
import { WorkforceHealthMetrics } from "@acumen/database-types";
import React, { useMemo } from "react";
import { getShortEstimationMethodName } from "v2/helpers/formatting";
import { useStores } from "v2/mobx-stores";
import { DeveloperBadgesData, DeveloperStats, DeveloperStatsChartData } from "../../../../types/workforce-health";
import { DevStatColumn, IMetricDisplayValuesCreationParams } from "../../dev-stat-columns";
import { MetricChartCard } from "../../../../components/metric-chart-card/metric-chart-card";
import { DeveloperStatsTeamTrendsCard } from "../developer-stats-team-trends-card/developer-stats-team-trends-card";
import "./workforce-health-header.scss";
import { round } from "@acumen/common";
import { IDeveloperInfo } from "../../types";

export interface IMetricDisplayValues {
	title: string;
	subtitle: string;
	isIncreasePositive: boolean;
	unit: string;
	data: number[];
	currentAvg: number;
	previousAvg: number;
	devTooltip: string;
	teamTooltip: string;
}

export interface WorkforceHealthHeaderProps {
	teams: IDashboardTeam[];
	contributors: IDeveloperInfo[];
	maxMembersToShow: number;
	selectedTeamIds: string[];
	developerBadgesData: DeveloperBadgesData;
	developerStatsChartData: DeveloperStatsChartData;
	onSelectedTeamsChange: (team: string[]) => void;
	selectedDashboardMetrics: DevStatColumn[];
}

export const WorkforceHealthHeader = (props: WorkforceHealthHeaderProps) => {
	const {
		teams,
		contributors,
		maxMembersToShow,
		developerBadgesData,
		developerStatsChartData,
		selectedTeamIds,
		onSelectedTeamsChange,
		selectedDashboardMetrics,
	} = props;

	const items = useDevStatsChartData(selectedDashboardMetrics, { developerStatsChartData });
	const unitName = getShortEstimationMethodName(developerBadgesData.config?.configs.devStats.estimationMethod);

	return (
		<div className="developer-stats-cards">
			<DeveloperStatsTeamTrendsCard
				teams={teams}
				contributors={contributors}
				maxMembersToShow={maxMembersToShow}
				developerBadgesData={developerBadgesData}
				selectedTeamIds={selectedTeamIds}
				onSelectedTeamsChange={onSelectedTeamsChange}
			/>

			<div className="developer-stats-cards-grid">
				{items.map((item, index) => (
					<MetricChartCard
						key={index}
						title={item.title}
						unit={item.unit || unitName}
						data={item.data}
						currentValue={item.currentAvg}
						previousValue={item.previousAvg}
						isIncreasePositive={item.isIncreasePositive}
						roundingPrecision={0}
					/>
				))}
			</div>
		</div>
	);
};

interface IDevStatsChart {
	title: string;
	unit?: string;
	data: number[];
	currentAvg: number;
	previousAvg: number;
	isIncreasePositive: boolean;
	devTooltip: string;
}

const devStatsMetricToDisplayValues = (
	metric: DevStatColumn, developerStatsChartData: DeveloperStatsChartData,
	currentDevStatsAvgByMetric: DeveloperStats | null, previousDevStatsAvgByMetric: DeveloperStats | null,
): IMetricDisplayValues => {
	const { title, subtitle, isIncreasePositive, unit, devTooltip, teamTooltip }: IMetricDisplayValuesCreationParams = metric.displayValues;
	const metricName = metric.dashboardMetricName;
	const data =
		developerStatsChartData && developerStatsChartData[metricName] ? developerStatsChartData[metricName]! : [];

	const getMetricAvgDisplayValue = (periodAvgByMetric: DeveloperStats | null, _metricName: WorkforceHealthMetrics) => {
		if (periodAvgByMetric && periodAvgByMetric[_metricName]) {
			return round(periodAvgByMetric[_metricName]!, 2);
		}
		return 0;
	};

	return {
		title,
		unit,
		isIncreasePositive,
		data,
		subtitle,
		currentAvg: getMetricAvgDisplayValue(currentDevStatsAvgByMetric, metricName),
		previousAvg: getMetricAvgDisplayValue(previousDevStatsAvgByMetric, metricName),
		devTooltip,
		teamTooltip
	};
};

const useDevStatsChartData = (
	selectedDashboardMetrics: DevStatColumn[], props: { developerStatsChartData: DeveloperStatsChartData}
): IDevStatsChart[] => {
	const { developerStatsChartData } = props;
	const {
		developerStatsStore: {
			currentDevStatsAvgByMetric,
			previousDevStatsAvgByMetric,
		}
	} = useStores();

	return useMemo(() => selectedDashboardMetrics.map((selectedMetricName: DevStatColumn) =>
		devStatsMetricToDisplayValues(
			selectedMetricName, developerStatsChartData, currentDevStatsAvgByMetric, previousDevStatsAvgByMetric
		)
	), [developerStatsChartData, currentDevStatsAvgByMetric, previousDevStatsAvgByMetric]);
};
