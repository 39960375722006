import { getData } from "../../../services/fetch-helpers";
import { AcumenId, IDashboardJiraPriority } from "@acumen/dashboard-common";
import { BaseCustomerApiClient } from "./base-customer-api-client";
import { qsStringify } from "../../../services/url-routes-helper";

export const PRIORITIES_ROUTE = "priorities";

export class PrioritiesApiClient extends BaseCustomerApiClient {
	public fetchPriorities = async (integrationId?: AcumenId, limit?: number) => {
		const query: Record<string, string> = {};
		if (integrationId) {
			query.integrationId = integrationId.toString();
		}
		if (limit) {
			query.limit = limit.toString();
		}

		const queryString = qsStringify(query);

		return await getData<IDashboardJiraPriority[], any>(
			this.createCustomerEntityRoute(`${PRIORITIES_ROUTE}${queryString ? `?${queryString}` : ""}`),
			this.token,
			this.tokenType,
		);
	}
}
