import useLocalStorage from "../../hooks/useLocalStorage";
import { observer } from "mobx-react";
import { useStores } from "../../mobx-stores";
import React, { useEffect, useState } from "react";
import { DropdownProps } from "semantic-ui-react";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import { toast } from "react-toastify";
import {
	IDashboardCurrentUserProfile, DashboardUserRoleType, IDashboardTeam, Status,
} from "@acumen/dashboard-common";
import { Select } from "../../components/select/select";

const SELECTED_TEAM_ID_LOCAL_STORAGE_KEY = "global_team_selector_selected";

function supportedTeamsForUser(authUser: IDashboardCurrentUserProfile, availableTeams: IDashboardTeam[]): IDashboardTeam[] {
	if (authUser.status === Status.Disabled) {
		return [];
	}

	if (authUser.roles.find(r => r === DashboardUserRoleType.Administrator ||
		r === DashboardUserRoleType.Executive) !== undefined) {
		return availableTeams;
	}

	const participateInTeamIds = authUser.teamMemberships
		.map(t => t.teamId);

	if (participateInTeamIds.length === 0) {
		return [];
	}
	return availableTeams.filter(at => participateInTeamIds.indexOf(at.id) > -1);
}

// tslint:disable-next-line: variable-name
const TeamSelector = observer(() => {
	const {
		authStore: { authUser },
		teamsStore: {
			allTeams,
			singleTeam,
			fetchAllTeams,
			setSingleTeam,
			resetTeams }
	} = useStores();

	const { loading: loadingAllTeams, data: teams } = allTeams;
	const { data: currentTeam } = singleTeam;

	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const [selectedTeamId, setSelectedTeamId] =
		useLocalStorage<string | undefined>(SELECTED_TEAM_ID_LOCAL_STORAGE_KEY, undefined);
	const [availableTeams, setAvailableTeams] = useState<IDashboardTeam[]>([]);

	useEffect(() => {
		let teamIdFromSearchParams: string | null;
		async function retrieveTeams() {
			if (!loadingAllTeams) {
				return fetchAllTeams();
			}
		}
		// tslint:disable-next-line: no-floating-promises
		retrieveTeams().then(() => {
			teamIdFromSearchParams = searchParams.get("filters[teamId]");
			if (teamIdFromSearchParams && !_.isEmpty(teamIdFromSearchParams) &&
				teams.findIndex(t => t.id === teamIdFromSearchParams) >= 0) {
				setSelectedTeamId(teamIdFromSearchParams);
			}
		});
		if (!window.location.href.includes("/my-team")) {
			resetTeams();
		}
	}, []);

	useEffect(() => {
		if (teams.length === 0) {
			return;
		}
		const supportedTeams = supportedTeamsForUser(authUser, teams);
		setAvailableTeams(supportedTeams);
		if (supportedTeams.length === 0) {
			toast.error(`A team must be designated for your user, please contact your account admin.`);
			return;
		}

		let shouldAutoSelectTeam = false;
		let selectedTeam: IDashboardTeam | undefined;

		if (selectedTeamId) {
			selectedTeam = supportedTeams.find(({ id }) => id === selectedTeamId);
			if (!selectedTeam) {
				shouldAutoSelectTeam = true;
			}
		} else {
			shouldAutoSelectTeam = true;
		}

		if (shouldAutoSelectTeam) {
			selectedTeam = supportedTeams[0];
			setSelectedTeamId(selectedTeam.id);
		}

		if (selectedTeam) {
			setSingleTeam(selectedTeam);
		}
	}, [teams]);
	return (
		<>
			{ availableTeams.length === 0 && <span className="empty-team-selector">My Team</span>}
			{ availableTeams.length === 1 && <span className="empty-team-selector">{availableTeams[0].name}</span>}
			{ availableTeams.length > 1 && <div className="team-selector">
				<Select
					placeholder={currentTeam.name}
					options={
						availableTeams.slice().sort((a, b) => (a.name.localeCompare(b.name))).map((v, i) => ({
							key: i, text: v.name, value: v.id
						}))
					}
					optionHeader="Select a Team"
					value={currentTeam.name}
					onChange={(data: DropdownProps | string | number) => {
						const selectedTeam = availableTeams.find(t => t.id === data);
						if (selectedTeam) {
							setSingleTeam(selectedTeam);
							setSelectedTeamId(selectedTeam.id);
						}

					}}
				/>
			</div>}
		</>
	);
});

export default TeamSelector;
