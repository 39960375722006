import React from "react";
import { observer } from "mobx-react";
import {
	Route, useRouteMatch, Switch, Redirect
} from "react-router-dom";
import UsersPage from "./users";
import StatusMappingPage from "./jira-statuses";
import TeamsPage from "./teams";
import ConnectedApps from "./connected-apps";
import Contributors from "./contributors";
import Header from "./header";
import "./style.scss";
import EditTeamPage from "./teams/edit-team-page";
import EditSingleUser from "./users/edit-single-user";
import { useStores } from "../../mobx-stores";
import GeneralSettings from "./general-settings";
import EmptyState from "../empty-state";
import _ from "lodash";

const PATHS_TO_DISPLAY_PRE_INTEGRATION = ["connected-apps", "general-settings", "users", "/account"];

// tslint:disable-next-line: variable-name
const Page = observer(() => {
	const match = useRouteMatch()!;
	const {
		authStore: {
			isUserAdmin,
			authUser
		},
		connectedAppsStore: {
			hasAnyActiveIntegration
		}
	} = useStores();

	const path = window.location.pathname;

	const shouldDisplayPreIntegration = _.some(PATHS_TO_DISPLAY_PRE_INTEGRATION, str => path.includes(str));

	if (hasAnyActiveIntegration === false && !shouldDisplayPreIntegration) {
		return (
			<><Header/>
			<div className="ui grid">
				<EmptyState
					isActive={isUserAdmin}
				/>
			</div></>
		);
	}

	return (
		<div className="my-account-page ui fluid container">
			<Header />
			<div className="ui grid">
				<div className="sixteen wide column set-page-z-index">
					<Switch>
						{isUserAdmin && <Route path={`${match.url}/general-settings`} component={GeneralSettings} />}
						{isUserAdmin && <Route path={`${match.url}/users`} component={UsersPage} />}
						{<Route path={`${match.url}/teams/edit/:teamId`} component={EditTeamPage} />}
						{<Route path={`${match.url}/teams`} component={TeamsPage} />}
						{<Route path={`${match.url}/jira-statuses`} component={StatusMappingPage} />}
						{isUserAdmin && <Route path={`${match.url}/connected-apps`} component={ConnectedApps} />}
						{isUserAdmin && <Route path={`${match.url}/contributors`} component={Contributors} />}
						{<Route path={`${match.url}/account`} render={(props) => (
							<EditSingleUser {...props} userId={authUser.id} />
						)} />}
						<Route>
							{isUserAdmin && <Redirect to={`${match.url}/users`} />}
							{(!isUserAdmin) && <Redirect to={`${match.url}/teams`} />}
						</Route>
					</Switch>
				</div>
			</div>
		</div>
	);
});

export default Page;
