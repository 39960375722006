import {
	DashboardSubscriptionEntityType,
	DashboardSubscriptionType,
	DashboardEntityRelation,
	DashboardTimeIntervalUnits,
	DashboardNotificationChannel,
	DashboardTeamMembershipType
} from "@acumen/dashboard-common";
import { STRINGS } from ".";

function throwStringNotFound(value: string) {
	throw new Error(`Localized string was not found for ${value}`);
}

export function localizeDashboardSubscriptionEntityType(value: DashboardSubscriptionEntityType) {
	switch (value) {
		case DashboardSubscriptionEntityType.Commit:
			return STRINGS.COMMIT;
		case DashboardSubscriptionEntityType.Task:
			return STRINGS.TASK;
		case DashboardSubscriptionEntityType.PullRequest:
			return STRINGS.PULL_REQUEST;
		default:
			throwStringNotFound(value);
	}
}

export function localizeDashboardTimeIntervalUnits(value: DashboardTimeIntervalUnits) {
	switch (value) {
		case DashboardTimeIntervalUnits.Days:
			return STRINGS.DAYS;
		case DashboardTimeIntervalUnits.Hours:
			return STRINGS.HOURS;
		default:
			throwStringNotFound(value);
	}
}

export function localizeDashboardEntityRelation(value: DashboardEntityRelation) {
	switch (value) {
		case DashboardEntityRelation.Commits:
			return STRINGS.COMMITS;
		case DashboardEntityRelation.Tasks:
			return STRINGS.TASKS;
		case DashboardEntityRelation.PullRequests:
			return STRINGS.PULL_REQUESTS;
		default:
			throwStringNotFound(value);
	}
}

export function localizeDashboardSubscriptionType(value: DashboardSubscriptionType) {
	switch (value) {
		case DashboardSubscriptionType.Created:
			return STRINGS.CREATED;
		case DashboardSubscriptionType.Updated:
			return STRINGS.UPDATED;
		case DashboardSubscriptionType.TimeInterval:
			return STRINGS.TIME_INTERVAL;
		case DashboardSubscriptionType.Deleted:
			return STRINGS.DELETED;
		default:
			throwStringNotFound(value);
	}
}

export function localizeDashboardNotificationChannel(value: DashboardNotificationChannel) {
	switch (value) {
		case DashboardNotificationChannel.API:
			return STRINGS.API;
		case DashboardNotificationChannel.Email:
			return STRINGS.EMAIL;
		case DashboardNotificationChannel.Slack:
			return STRINGS.SLACK;
		default:
			throwStringNotFound(value);
	}
}

export function localizeDashboardTeamMembershipType(value: DashboardTeamMembershipType | string) {
	switch (value) {
		case DashboardTeamMembershipType.Member:
			return STRINGS.MEMBER;
		case DashboardTeamMembershipType.TeamLeader:
			return STRINGS.TEAM_LEAD;
		default:
			throwStringNotFound(value);
	}
}
