import React from "react";
import { ICheckboxInputPropsBase } from "../../components/form/checkbox-input";
import { ISelectOptionBase } from "../../components/form/option-select";
import { GA_EVENT_CATEGORY } from "../../analytics-events";
import { FilterType } from "../filters-top-navbar/index";
import "../style.scss";
import FilterDrawer from "../../components/filters-drawer/filters-drawer";
import classNames from "classnames";

interface IFiltersTopNavbar<Filters extends FilterType> {
	popupSelectFields: ISelectOptionBase[];
	clickEventPage: GA_EVENT_CATEGORY;
	filters?: Filters;
	applyFilters?: (filters: Filters) => void;
	resetFilters?: Filters;
	counterLabel?: number | string;
	popupCheckboxFields?: ICheckboxInputPropsBase[];
	children?: React.ReactChild | React.ReactChild[];
	onSearchByString?: (str: string) => void;
	className?: string;
}

// tslint:disable-next-line: variable-name
const LightNavBar = <Filters extends FilterType>(props: IFiltersTopNavbar<Filters>) => {
	const {
		children, counterLabel, popupSelectFields, clickEventPage, filters, popupCheckboxFields, applyFilters,
		className = ""
	} = { ...props };

	return (
		<div className={classNames("top-nav", className)}>
			<div className="flex-row full-width">
				{(filters && applyFilters) &&
					((popupSelectFields.length > 0)  ?
						<FilterDrawer
							filters={filters}
							popupCheckboxFields={popupCheckboxFields}
							applyFilters={applyFilters}
							popupSelectFields={popupSelectFields}
							clickEventPage={clickEventPage}
						/>
						: <div className="placeholder-1-rem" />
					)
				}
				{!!counterLabel &&
					<>
						<div className="small-gray-label">
							<div className="ui label mini note-label light-blue-text">{counterLabel}</div>
						</div>
					</>
				}
				{children}
			</div>
		</div >
	);
};
export default LightNavBar;
