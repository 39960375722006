import React, { useState } from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { observer } from "mobx-react";
import LoadingIndicator from "../../../components/loader";
import moment from "moment";
import {
	DevelopmentMethodology,
	IDashboardBurndownData,
	IDashboardBurndownDataDay,
	IDashboardSprint,
	SubtaskSprintStrategy,
	TaskEstimationMethod
} from "@acumen/dashboard-common";
import { useStores } from "../../../mobx-stores";
import { TASK_ESTIMATION_METHOD_DISPLAY_NAMES, TASK_ESTIMATION_METHOD_SHORT_DISPLAY_NAMES } from "../../../../localization/text-records";
import { Popup } from "semantic-ui-react";
import BurndownChartModal from "./burndown-chart-modal";
import { IDashboardTaskStatusChangeResponse } from "@acumen/dashboard-common";
import useDeepCompareEffect from "use-deep-compare-effect";
import "./burndown-chart.scss";
import { IterationReview } from "../../../components/iteration-review/utils";

const NON_WORK_DAY_PLOT_BAND_COLOR = "#DADDE0";
const BURNDOWN_COLORS = {
	unplannedWork: ["#EFA5AE", "#D81E35"],
	plannedWork: ["#8B84C3", "#170987"],
	carryOver: "#8E81F7",
	expectedProgress: "#96B5E8",
	remainingWork: "#7835FF",
	removedTasks: "#AF2387",
	completedTasks: "#32B195"
};
const MILLISECOND_FOR_DAY =  86400000;

function createPlotBands(dataArray?: IDashboardBurndownDataDay[], timezone?: string) {
	if (!dataArray || dataArray.length === 0 || !timezone) {
		return [];
	}

	const quarterDay = moment.duration(0.25, "day").asMilliseconds();
	const plotBands: Array<{ color: string, from: number, to: number }> = [];
	let from: number | undefined;
	let to: number | undefined;

	for (const currentDay of dataArray) {
		if (currentDay.workDay) {
			if (to && from) {
				plotBands.push({ to, from, color: NON_WORK_DAY_PLOT_BAND_COLOR });
				to = undefined;
				from = undefined;
			}
		} else {
			const dayTickerMs = moment.tz(currentDay.date, "L", timezone).startOf("day").toDate().getTime();

			to = dayTickerMs + quarterDay;
			if (!from) {
				from = dayTickerMs - quarterDay;
			}
		}
	}

	if (to && from) {
		plotBands.push({ to, from, color: NON_WORK_DAY_PLOT_BAND_COLOR });
	}

	return plotBands;
}

interface IBurndownChartchartProps {
	timezone: string;
	pageError: boolean;
	removeTitle?: boolean;
	height?: number;
	showBurndownRemovedTasksLine?: boolean;
	showBurndownAddedTasksLine?: boolean;
	devMethodology?: DevelopmentMethodology;
	burndownChartData?: IDashboardBurndownData;
	estimationMethod: TaskEstimationMethod;
	subtaskStrategy?: SubtaskSprintStrategy;
	legendPosition?: "top" | "bottom";
	sprint?: IDashboardSprint;
}

// tslint:disable-next-line: variable-name
const BurndownChart = (props: IBurndownChartchartProps) => {
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [selectedDateRange, setSelectedDateRange] = useState<string | undefined>(undefined);
	const {
		tasksStore,
	} = useStores();
	const { fetchStatusDiff, statusDiff } = tasksStore;
	const [statusDiffData, setStatusDiffData] = useState<IDashboardTaskStatusChangeResponse | undefined>(undefined);
	const [removedTasks, setRemovedTasks] = useState<string[] | undefined>(undefined);
	const [chartOptions, setChartOptions] = useState<Highcharts.Options | undefined>(undefined);
	const [shouldShowLoading, setShouldShowLoading] = useState<boolean>(false);

	useDeepCompareEffect(() => {
		const { data } = { ...props.burndownChartData };
		setShouldShowLoading(!data || props.pageError);
		setChartOptions(buildChartOptions(props));
	}, [props]);

	const handleFetchStatusDiffs = async (selectedDayData?: IDashboardBurndownDataDay): Promise<void> => {
		if (!selectedDayData?.date || !selectedDayData?.tasks || !props.sprint) {
			return;
		}
		setModalOpen(true);
		const startDate = new Date(IterationReview.Charts.Formatters.metricKeyToTime(selectedDayData?.date, props.timezone));
		const endOfDay = new Date(
			IterationReview.Charts.Formatters.metricKeyToTime(selectedDayData?.date, props.timezone) + MILLISECOND_FOR_DAY);
		const endDate = props.sprint.completeDate ?
			new Date(Math.min(
				endOfDay.getTime(), new Date(props.sprint.completeDate).getTime()
			))
			: endOfDay;

		setSelectedDateRange(`${moment(startDate).format("dddd, MMM Do")}`);
		const result = await fetchStatusDiff(
			[...selectedDayData?.tasks, ...selectedDayData?.completedTasks,
				...selectedDayData?.removedTasks], startDate, endDate);
		setStatusDiffData(result ?? undefined);
		setRemovedTasks(selectedDayData?.removedTasks);
	};

	function buildChartOptions(chartProps: IBurndownChartchartProps) {
		const estimationMethod = chartProps.estimationMethod;
		const { data } = { ...chartProps.burndownChartData };

		if (data?.length === 0) {
			return;
		}

		const firstDayDateInMillis = data ? Date.parse(data[0].date) : -1;
		const planned = (data ? data.map(dailyData =>
			IterationReview.Charts.Formatters.dateValueTuple(dailyData.date, dailyData.planned, chartProps.timezone, estimationMethod)) : []);
		const unplanned = (data ? data.map(dailyData =>
			IterationReview.Charts.Formatters.dateValueTuple(dailyData.date, dailyData.unplanned, chartProps.timezone, estimationMethod)) : []);
		const actualProgress = (data ? data.map(dailyData =>
			IterationReview.Charts.Formatters.dateValueTuple(dailyData.date, dailyData.planned + dailyData.unplanned, chartProps.timezone, estimationMethod)) : []);
		const carryOver = ((data && chartProps.devMethodology === DevelopmentMethodology.Scrum) ? data.map(dailyData =>
			IterationReview.Charts.Formatters.dateValueTuple(dailyData.date, dailyData.carryOver, chartProps.timezone, estimationMethod)) : []);
		const removed = (data ? data.map(dailyData =>
			[IterationReview.Charts.Formatters.metricKeyToTime(dailyData.date, chartProps.timezone), dailyData.removedTasks.length]) : []);
		const completed = (data ? data.map(dailyData =>
			[IterationReview.Charts.Formatters.metricKeyToTime(dailyData.date, chartProps.timezone), dailyData.completedTasks.length]) : []);
		const linear = (data ? data.map(dailyData =>
			IterationReview.Charts.Formatters.dateValueTuple(dailyData.date, dailyData.linearComp, chartProps.timezone, estimationMethod)) : []);
		const plotBands = createPlotBands(data, chartProps.timezone);
		const chartDataSuffix = TASK_ESTIMATION_METHOD_SHORT_DISPLAY_NAMES[estimationMethod];
		const decimalPlaces = estimationMethod === TaskEstimationMethod.None ? "0" : "2";
		const series: Highcharts.SeriesOptionsType[] = [
			{
				visible: true,
				name: "Unplanned work",
				type: "column",
				stacking: "overlap",
				yAxis: 0,
				data: unplanned,
				pointInterval: 24 * 3600 * 1000,
				showInLegend: true,
				color: {
					linearGradient: {
						x1: 0,
						x2: 0,
						y1: 0,
						y2: 1,
					},
					stops: [
						[0, BURNDOWN_COLORS.unplannedWork[0]],
						[1, BURNDOWN_COLORS.unplannedWork[1]]
					]
				},
				borderColor: BURNDOWN_COLORS.unplannedWork[1],
				dashStyle: "Solid",
				enableMouseTracking: true,
				tooltip: IterationReview.Charts.ToolTip.getFormattedColumnTooltip("Unplanned", BURNDOWN_COLORS.unplannedWork[0],
					BURNDOWN_COLORS.unplannedWork[1], decimalPlaces, chartDataSuffix)
			},
			{
				name: "Planned work",
				type: "column",
				stacking: "overlap",
				yAxis: 0,
				data: planned,
				pointInterval: 24 * 3600 * 1000,
				showInLegend: true,
				color: {
					linearGradient: {
						x1: 0,
						x2: 0,
						y1: 0,
						y2: 1
					},
					stops: [
						[0, BURNDOWN_COLORS.plannedWork[0]],
						[1, BURNDOWN_COLORS.plannedWork[1]]
					]
				},
				borderColor: BURNDOWN_COLORS.plannedWork[1],
				dashStyle: "Solid",
				enableMouseTracking: true,
				tooltip: IterationReview.Charts.ToolTip.getFormattedColumnTooltip("Planned", BURNDOWN_COLORS.plannedWork[0],
					BURNDOWN_COLORS.plannedWork[1], decimalPlaces, chartDataSuffix)
			},
			{
				name: "Carry over",
				type: "column",
				stack: "carry-over-in-sprint",
				yAxis: 0,
				data: carryOver,
				pointInterval: 24 * 3600 * 1000,
				showInLegend: chartProps.devMethodology === DevelopmentMethodology.Scrum,
				color: BURNDOWN_COLORS.carryOver,
				visible: chartProps.devMethodology === DevelopmentMethodology.Scrum,
				borderColor: BURNDOWN_COLORS.carryOver,
				enableMouseTracking: true,
				tooltip: IterationReview.Charts.ToolTip.getFormattedColumnTooltip("Carry over", BURNDOWN_COLORS.carryOver,
					BURNDOWN_COLORS.carryOver, decimalPlaces, chartDataSuffix)
			},
			{
				name: "Expected progress",
				type: "line",
				yAxis: 0,
				data: linear,
				pointInterval: 24 * 3600 * 1000,
				showInLegend: true,
				color: BURNDOWN_COLORS.expectedProgress,
				enableMouseTracking: true,
				tooltip: {
					pointFormat: undefined
				}
			},
			{
				name: "Remaining work",
				type: "spline",
				yAxis: 0,
				data: actualProgress,
				pointInterval: 24 * 3600 * 1000,
				showInLegend: true,
				color: BURNDOWN_COLORS.remainingWork,
				dashStyle: "Solid",
				enableMouseTracking: true,
				visible: false,
				tooltip: {
					pointFormat: undefined
				}
			},
			{
				visible: true,
				opacity: 0,
				name: "Removed issues",
				type: "column",
				yAxis: 1,
				data: removed,
				pointInterval: 24 * 3600 * 1000,
				color: BURNDOWN_COLORS.removedTasks,
				pointWidth: 8,
				borderWidth: 0,
				enableMouseTracking: true,
				showInLegend: false,
				tooltip: {
					pointFormatter: function _pointFormatter() {
						if (this.x === firstDayDateInMillis) {
							// ACM-4154: Removed tasks are temporarily hidden on the first day.
							return "";
						}
						return IterationReview.Charts.ToolTip.getSimpleColumnTooltip("Removed issues", BURNDOWN_COLORS.removedTasks, this.y);
					}
				}
			},
			{
				name: "Completed issues",
				opacity: 0,
				type: "column",
				yAxis: 1,
				data: completed,
				pointInterval: 24 * 3600 * 1000,
				color: BURNDOWN_COLORS.completedTasks,
				pointWidth: 8,
				borderWidth: 0,
				enableMouseTracking: true,
				visible: true,
				showInLegend: false,
				tooltip: {
					pointFormatter: function _pointFormatter() {
						if (this.x === firstDayDateInMillis) {
							// ACM-4154: Completed tasks are temporarily hidden on the first day.
							return "";
						}
						return IterationReview.Charts.ToolTip.getSimpleColumnTooltip("Completed issues", BURNDOWN_COLORS.completedTasks, this.y);
					},
				},
			}
		];

		// NOTE: This new "Z" formatter is for the X axis tooltip header which is statically generated from
		// the point.key field. This is the only way to set the timezone offset for it dynamically.
		Highcharts.dateFormats.Z = (time) => {
			return moment.tz(time, chartProps.timezone).format("dddd MMMM Do");
		};

		const options: Highcharts.Options = {
			chart: {
				plotBackgroundColor: undefined,
				plotBorderWidth: undefined,
				plotShadow: false,
				height: chartProps.height ? `${chartProps.height}px` : undefined
			},
			title: {
				text: undefined
			},
			xAxis: {
				crosshair: false,
				tickInterval: 24 * 3600 * 1000,
				type: "datetime",
				labels: {
					align: "center",
					autoRotation: undefined,
					// tslint:disable-next-line: object-literal-shorthand
					formatter: function _formatter() {
						return moment.tz(this.value, chartProps.timezone).format("ddd<br/> MMM Do");
					},
					style: {
						color: "gray",
						textOverflow: "none",
					}
				},
				plotBands
			},
			yAxis: [
				{
					title: {
						text: TASK_ESTIMATION_METHOD_DISPLAY_NAMES[estimationMethod]
					},
					labels: {
						format: "{value}",
						style: {
							color: "gray"
						}
					},
					min: 0,
					gridLineWidth: 1
				},
				{
					visible: false
				}
			],
			tooltip: {
				style: {
					pointerEvents: "auto"
				},
				shared: true,
				useHTML: true,
				headerFormat: `<div class="tooltip-container"><div class="tooltip-header"><h5>{point.key}</h5></div>`,
				// Uses our custom Z formatter set above to format `point.key` properly
				xDateFormat: "%Z",
				footerFormat: `</div>`,
				enabled: true,
				backgroundColor: "white",
				borderRadius: 6,
				borderWidth: 0,
				padding: 0,
				distance: 20
			},
			plotOptions: {
				column: {
					stacking: "normal",
					grouping: false,
					shadow: false,
					borderWidth: 0,
					cursor: "pointer",
					point: {
						events: {
							click() {
								// tslint:disable-next-line: no-floating-promises
								handleFetchStatusDiffs(data && data[this.index]);
							}
						}
					},
					maxPointWidth: 35,
				},
				spline: {
					dataLabels: {
						allowOverlap: true,
						enabled: false
					},
				},
				line: {
					dataLabels: {
						allowOverlap: true,
						enabled: false
					},
				}
			},
			credits: {
				enabled: false
			},
			legend: (!chartProps.legendPosition || chartProps.legendPosition === "bottom") ? {
				layout: "horizontal",
				align: "center",
				verticalAlign: "bottom"
			} : {
				layout: "horizontal",
				align: "right",
				verticalAlign: "top",
			},
			responsive: {
				rules: [{
					condition: {
						maxWidth: 500
					},
					chartOptions: {
						plotOptions: {
							column: {
								pointWidth: 20,
								stacking: "normal"
							}
						}
					}
				}]
			},
			series,
			time: {
				timezone: chartProps.timezone,
				moment
			}
		};

		return options;
	}

	return (
		<div style={{ height: props.pageError ? "auto" : `${props.height ?? 490}px` }}>
			{modalOpen && <BurndownChartModal
				open={modalOpen}
				loading={statusDiff.loading}
				setOpen={setModalOpen}
				subtaskStrategy={props.subtaskStrategy}
				content={statusDiffData}
				dateToDisplay={selectedDateRange}
				removedTasks={removedTasks}
			/>}
			{!props.removeTitle && <h4 className="ui header flex-row spaced">
				<span>
					Burndown chart (end of day)
					<Popup
						hoverable={true}
						wide={true}
						position="top center"
						content={getBurndownTooltip(props.devMethodology)}
						trigger={
							<i className="chart-tooltip info icon" />
						}
					/>
				</span>
			</h4>}
			<div className="burndown-container">
				<LoadingIndicator local={true} isActive={shouldShowLoading}>
					{chartOptions && <HighchartsReact
						highcharts={Highcharts}
						options={chartOptions}
						allowChartUpdate={true}
					/>}
				</LoadingIndicator>
			</div>
		</div>
	);
};
export default observer(BurndownChart);

const getBurndownTooltip = (devMethodology?: DevelopmentMethodology) => {
	const period = devMethodology ? (DevelopmentMethodology.Scrum ? "sprint" : "iteration") : "sprint/iteration";
	const TOOLTIP_TEXT = `The burndown chart shows the aggregate of work left at the end of each day of the ${period}.
		The amount is designated in aggregated estimate (story points, time estimate or task count) and is correct for the end of each day.
		As each day of the iteration progresses, expect the burndown to slowly drop as tasks are being completed.
		Tasks that have entered the iteration after it started (unplanned work) are shown as an addition at the top,
		whereas tasks that were carried over from the previous iteration (carry over) are shown as a different color at the bottom`;
	return TOOLTIP_TEXT;
};
