import { HighlightType, IPullRequestHighlight } from "@acumen/dashboard-common";

const formatters = {
	highlightText(h: IPullRequestHighlight) {
		switch (h.type) {
			case HighlightType.Insight: {
				return h.text;
			}
		}
	}
};

export default formatters;
