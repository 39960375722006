import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../mobx-stores";
import Loader from "../../../components/loader/loader";
import "./style.scss";
import _ from "lodash";
import { IDashboardStatusMapping } from "@acumen/dashboard-common";
import TaskStatusGroup, { taskStatusGroupText } from "../../../components/formatters/task-status-group";

interface IJiraStatusMappingSort {
	columnName: JiraStatusMappingColumn;
	asc: boolean | undefined;
}

enum JiraStatusMappingColumn {
	ProjectName = "Project name",
	JiraStatus= "Jira status",
	CategoryInJira =  "Category in Jira",
	AcumenMapping = "Acumen mapping",
	IsMappedByAcumen =  "Is mapped by Acumen",
	AcumenStatusGroup = "Acumen status group"
}

const getFieldDetailsForColumn = (column: JiraStatusMappingColumn, record: IDashboardStatusMapping) => {
	switch (column) {
		case JiraStatusMappingColumn.ProjectName:
			return record.projectName;
		case JiraStatusMappingColumn.JiraStatus:
			return record.jiraStatusName;
		case JiraStatusMappingColumn.IsMappedByAcumen:
			return record.isMappedByAcumen;
		case JiraStatusMappingColumn.CategoryInJira:
			return record.jiraCategoryName;
		case JiraStatusMappingColumn.AcumenStatusGroup:
			return record.acumenTaskStatusGroup;
		case JiraStatusMappingColumn.AcumenMapping:
			return record.acumenStatusName;
	}
};

const statusMappingTable = observer(() => {
	const {
		statusMappingStore: { statusMappingData, isLoading, fetchData },
	} = useStores();

	const [sortedColumn, setSortedColumn] = useState<IJiraStatusMappingSort>({
		columnName: JiraStatusMappingColumn.ProjectName,
		asc: true
	});

	const handleSort = useCallback((columnName: JiraStatusMappingColumn) => {
		const isAsc = columnName === sortedColumn.columnName
			? !sortedColumn.asc
			: false;
		setSortedColumn({columnName, asc: isAsc});
	}, [sortedColumn, setSortedColumn]);

	const sortedData = useMemo(() => {
		const getRecordField = (x: IDashboardStatusMapping) => {
			return getFieldDetailsForColumn(sortedColumn.columnName, x);
		};

		const sortDirection = sortedColumn.asc ? "asc" : "desc";

		return _.orderBy(statusMappingData, getRecordField, sortDirection);
	}, [sortedColumn, statusMappingData]);

	const TableHeaders = useMemo(() => {
			return Object.values(JiraStatusMappingColumn).map(columnName => {
				const isSorted = columnName === sortedColumn.columnName;
				const sortArrow = sortedColumn.asc ? "↓" : "↑";
				const columnDisplayName = isSorted ? `${columnName} ${sortArrow}` : columnName;
				return <th key={columnName} className="table-header two wide" onClick={() => handleSort(columnName)}>{columnDisplayName}</th>;
		});
	}, [sortedColumn, handleSort]);

	useEffect(() => {
		const retrieveStatusMappingData = async () => {
			await fetchData();
		};
		// tslint:disable-next-line: no-floating-promises
		retrieveStatusMappingData();
	}, []);

	return (
		<div className="ui segment basic jira-statuses">
			<div className="form-header">
				<h1 className="form-title">
					Jira statuses
				</h1>
			</div>
			<Loader isActive={isLoading}>
				<div className="ui segment raised">
					<table className="ui very compact single line small stackable sticky-table clickable table">
						<thead>
							<tr>
								{TableHeaders}
							</tr>
						</thead>

						<tbody>
							{sortedData.map(s => (
								<tr key={s.statusId}>
									<td>{s.projectName}</td>
									<td>{s.jiraStatusName}</td>
									<td>{s.jiraCategoryName}</td>
									<td>
										<TaskStatusGroup
											taskStatusGroup={s.acumenTaskStatusGroup}
											taskStatus={s.acumenStatusName}
										/>
									</td>
									<td>
										{
											s.isMappedByAcumen
												? <i className="check circle icon"/>
												: <i className="times circle icon"/>
										}
									</td>
									<td>
										<span className="status-group-label">
											{taskStatusGroupText(s.acumenTaskStatusGroup)}
										</span>
									</td>
								</tr>
							)
							)}
						</tbody>
					</table>
				</div>
			</Loader>
		</div>
	);
});
export default statusMappingTable;
