import { FilterName, UIFilters } from "../../mobx-stores/digest-store";
import { getData } from "../../../services/fetch-helpers";
import { BaseCustomerApiClient } from "./base-customer-api-client";
import { GroupedDashboardTaskDigest } from "@acumen/dashboard-common";
import { qsStringify } from "../../../services/url-routes-helper";

export const DIGEST_ROUTE = "digests/tasks";

export interface ITaskDigestFilters {
	projects: string[];
}

type DigestFilter = Omit<Record<FilterName, string[]>, UIFilters> & { events: string[] } & { teamId: string[] };
export class DigestApiClient extends BaseCustomerApiClient {
	public fetchDigest = async (filters: DigestFilter, dateRange: { startTime: Date, endTime: Date }) => {
		const query: {
			startTime?: Date;
			endTime?: Date;
			[key: string]: string | string[] | Date | undefined;
		} = {
			startTime: dateRange?.startTime,
			endTime: dateRange?.endTime
		};
		Object.keys(filters).forEach(filterName => {
			const urlFilter = filters[filterName as keyof DigestFilter];
			if (Array.isArray(urlFilter) && urlFilter.length > 0) {
				query[`filters[${filterName}]`] = filters[filterName as keyof DigestFilter].join(",");
			}
		});
		return await getData<GroupedDashboardTaskDigest, any>(
			this.createCustomerEntityRoute(`${DIGEST_ROUTE}?${qsStringify(query)}`),
			this.token
		);
	}
}
