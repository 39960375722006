import React, { useMemo } from "react";
import { CustomSelect } from "../../../../components";
import { IDashboardTeam } from "@acumen/dashboard-common";
import {
	DeveloperStatsTeamTrendMembers
} from "../developer-stats-team-trend-members/developer-stats-team-trend-members";
import "./developer-stats-team-trends-card-header.scss";
import { IDeveloperInfo } from "../../types";
import _ from "lodash";

export interface DeveloperStatsTeamTrendsCardProps {
	teams: IDashboardTeam[];
	contributors: IDeveloperInfo[];
	maxMembersToShow: number;
	selectedTeamIds: string[];
	onSelectedTeamsChange: (team: string[]) => void;
}

export const DeveloperStatsTeamTrendsCardHeader = (props: DeveloperStatsTeamTrendsCardProps) => {
	const {
		teams,
		contributors,
		maxMembersToShow,
		selectedTeamIds,
		onSelectedTeamsChange,
	} = props;
	const teamOptions = useMemo(() =>
		_
		.orderBy(teams, t => t.name.toLowerCase(), "asc")
		.map(team => ({
		value: team.id,
		label: team.name,
	})), [teams]);

	return (
		<div className="developer-stats-team-trends-card-header">
			<div className="developer-stats-team-trends-card-header-row">
				<div className="developer-stats-team-trends-card-header-title">
					Developers
				</div>

				<div>
					<CustomSelect
						className="developer-stats-team-trends-card-header-team-select"
						triggerClass="developer-stats-team-trends-card-header-team-select-trigger"
						labelClass="developer-stats-team-trends-card-header-team-select-trigger-label"
						arrowClass="developer-stats-team-trends-card-header-team-select-trigger-arrow"
						options={teamOptions}
						value={selectedTeamIds}
						multiple={true}
						showSelectAllOption={true}
						selectAllOptionText="All teams"
						assumeAllSelectedByDefault={true}
						onChange={onSelectedTeamsChange}
					/>
				</div>
			</div>

			<div className="developer-stats-team-trends-card-header-row">
				<div className="developer-stats-team-trends-card-header-team-count">
					{contributors.length}
				</div>

				<DeveloperStatsTeamTrendMembers
					contributors={contributors}
					maxMembersToShow={maxMembersToShow}
				/>
			</div>
		</div>
	);
};
