import BaseStore from "./base-store";
import { action, computed, observable } from "mobx";
import apiContextProvider from "../../services/api-context-provider";
import { IntegrationsApiClient, INTEGRATIONS_ROUTE } from "../services/crud/integrations-api-client";
import { DashboardIntegrationType, IDashboardIntegration, IntegrationStatus, Status, IDashboardJIRA3LOProjectSelection } from "@acumen/dashboard-common";
import { FetchLatestRequest } from "../../services/fetch-helpers";

interface IEnabledIntegration {
	integrationId: string;
	integrationType: DashboardIntegrationType;
}

export default class ConnectedAppsStore extends BaseStore<{}> {
	private readonly apiClient: IntegrationsApiClient = new IntegrationsApiClient(apiContextProvider);

	@observable
	public allIntegrations: IDashboardIntegration[] | undefined = undefined;

	@observable
	public jiraActiveEnabledIntegration: IEnabledIntegration | undefined = undefined;

	@observable
	public githubActiveEnabledIntegration: IEnabledIntegration | undefined = undefined;

	@computed
	public get hasAnyActiveIntegration() {
		if (this.jiraActiveEnabledIntegration !== undefined ||
			this.githubActiveEnabledIntegration !== undefined) {
			return true;
		}

		return false;
	}

	fetchLatestAllIntegrations = new FetchLatestRequest<IDashboardIntegration[], any>(INTEGRATIONS_ROUTE);
	@action.bound
	public async refreshIntegrationState() {
		const result = await this.fetchLatestAllIntegrations.fetchLatest(this.apiClient.findAll());
		if (result) {
			const { data } = result;
			this.jiraActiveEnabledIntegration = this.toEnabledIntegration(data.find(i => (i.type === DashboardIntegrationType.JIRACloud || i.type === DashboardIntegrationType.JIRAServer) && i.state === IntegrationStatus.ACTIVE && i.status === Status.Enabled));
			this.githubActiveEnabledIntegration = this.toEnabledIntegration(data.find(i => i.type === DashboardIntegrationType.GitHub && i.state === IntegrationStatus.ACTIVE && i.status === Status.Enabled));
			this.allIntegrations = data;

			return data;
		}
	}

	@action.bound
	public async createGitHubInstallLink() {
		const result = await this.apiClient.createGitHubInstallLink();
		if (result) {
			const { data } = result;
			return data;
		}
	}

	@action.bound
	public async authorizeGitHubInstall(githubData: { installationId: string, integrationId: string }) {
		const gitInstallId = parseInt(githubData.installationId, 10);
		if (isNaN(gitInstallId)) {
			return;
		}

		const result = await this.apiClient.updateGitHubInstallId({
			gitInstallId,
			integrationId: githubData.integrationId
		});
		if (result) {
			const { data } = result;
			if (data.integrationId) {
				this.githubActiveEnabledIntegration = {
					integrationId: data.integrationId,
					integrationType: DashboardIntegrationType.GitHub
				};
			}
		}
	}

	@action.bound
	public createJira3loURL(customerId: string, userExternalId: string, jiraIntegrationClientID: string, permissionsScope: string) {
		const params = {
			audience: "api.atlassian.com",
			client_id: jiraIntegrationClientID,
			scope: permissionsScope,
			state: Buffer.from(`${customerId}:${userExternalId}`).toString("base64"),
			response_type: "code",
			prompt: "consent"
		};
		const queryParams = new URLSearchParams(params);
		return `https://auth.atlassian.com/authorize?${queryParams.toString()}`;
	}

	fetchLatestListAvailableJira3LOProjects = new FetchLatestRequest<IDashboardJIRA3LOProjectSelection[], any>(INTEGRATIONS_ROUTE + "/projects");
	@action.bound
	public async listAvailableJira3LOProjects() {
		if (!this.jiraActiveEnabledIntegration) {
			return [];
		}

		const jiraProjects = await this.fetchLatestListAvailableJira3LOProjects
			.fetchLatest(this.apiClient.listAvailableJira3LOProjects(this.jiraActiveEnabledIntegration.integrationId));

		if (jiraProjects) {
			const { data } = jiraProjects;
			return data;
		}
	}
	@action.bound
	public async updateSelectedJira3LOProjectKeys(projectKeys: string[]) {
		if (projectKeys.length === 0) {
			return;
		}

		await this.apiClient.updateSelectedJira3LOProjectKeys(projectKeys);
	}
	private toEnabledIntegration = (integration?: IDashboardIntegration): IEnabledIntegration | undefined => {
		if (!integration) {
			return integration;
		}

		return {
			integrationId: integration.id,
			integrationType: integration.type
		};
	}
}
