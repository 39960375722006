import React from "react";
import { IDashboardPullRequestExtended, IDashboardTaskDetailed } from "@acumen/dashboard-common";
import { usePullRequestsState, useTasksState } from "../../hooks/fetchers";
import "./developer-completed-work.scss";
import { DeveloperCompletedWorkItem, DeveloperCompletedWorkItemProps } from "../developer-completed-work-item/developer-completed-work-item";
import _ from "lodash";
import { useIsAsyncDataLoaded } from "v2/hooks/async-state";
import Loader from "v2/components/loader/loader";
import { IDeveloperInfo } from "../../types";

export interface DeveloperCompletedWorkProps {
	contributor: IDeveloperInfo;
}

interface CompletedTasks extends DeveloperCompletedWorkItemProps {
	key: string;
	cycleEndTimestamp: number | null;
}

export const DeveloperCompletedWork = (props: DeveloperCompletedWorkProps) => {
	const { contributor } = props;

	const tasks = useTasksState([contributor.dataContributorId]);
	const prs = usePullRequestsState([contributor.dataContributorId]);

	let completedWork: CompletedTasks[];

	const completedTasks: CompletedTasks[] = (tasks.data.tasks as IDashboardTaskDetailed[]).map(task => {
		return {
			key: task.entityId,
			type: task.entityType,
			publicIdentifier: task.publicIdentifier,
			link: task.publicHtmlUrl,
			title: task.title,
			cycleEndTimestamp: task.cycleEndTimestamp
		};
	});

	const completedPRs: CompletedTasks[] = (prs.data.pullRequests as IDashboardPullRequestExtended[]).map(pr => {
		return {
			key: pr.entityId,
			type: pr.entityType,
			publicIdentifier:`${_.startCase(pr.repositoryName ?? "")}#${pr.publicIdentifier}`,
			link: pr.publicHtmlUrl,
			title: pr.title,
			cycleEndTimestamp: pr.closureTimestamp
		};
	});

	const isDataLoaded = useIsAsyncDataLoaded(
		tasks,
		prs
	);

	completedWork = completedTasks.concat(completedPRs).sort((a, b) => b.cycleEndTimestamp! - a.cycleEndTimestamp!);

	return (
		<div className="developer-completed-work">
			{<Loader isActive={!isDataLoaded} local={true}/>}
			{(isDataLoaded && completedWork.length > 0) && (
				<div className="developer-completed-work-container">
					<div className="developer-completed-work-table-header">Completed PRs and tasks</div>
					<div className="developer-completed-work-table-subheader">from the last 30 days, order from newest to oldest</div>
					<div className="developer-completed-work-table-content">
						{completedWork.map(task =>
							<DeveloperCompletedWorkItem
								key={task.key}
								type={task.type}
								publicIdentifier={task.publicIdentifier}
								link={task.link}
								title={task.title}
							/>
						)}
					</div>
				</div>
			)}
			{(isDataLoaded && completedWork.length === 0) && (
				<div className="developer-completed-work-table-empty-state">
					<div className="developer-completed-work-table-empty-state-img">
						<img className="emptyStateIcon" src="/assets/images/completed-work-empty-state.svg" alt="" />
					</div>
					<div className="developer-completed-work-table-empty-state-text">
						This team member hasn't completed any tasks/pull requests yet
					</div>
				</div>
			)}
		</div>
	);
};
