import React from "react";
import classNames from "classnames";
import { Popup } from "semantic-ui-react";

export interface ICheckboxInputPropsBase {
	name: string;
	label?: string;
	tooltip?: string;
}

interface ICheckboxInputProps extends ICheckboxInputPropsBase {
	value?: boolean;
	className?: string;
	onChange?: (data: boolean) => void;
	register?: () => {};
	disabled?: boolean;
	isToggle?: boolean;
}

// tslint:disable-next-line: variable-name
const CheckboxInput = (props: ICheckboxInputProps) => {
	const { label, value, name, disabled, register, isToggle, onChange, className, tooltip } = { ...props };
	const onToggle = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation();
		const child = e.currentTarget ? e.currentTarget.children.namedItem(name) as HTMLInputElement : false;
		if (!child || disabled) {
			return;
		} else {
			if (child.checked !== undefined && onChange) {
				onChange(!child.checked);
			}
		}
	};

	return (
		<div
			className={classNames("ui checkbox", className, { toggle: isToggle })}
			onClick={onToggle}
		>
			<input
				type="checkbox"
				ref={register}
				name={name}
				className="hidden"
				checked={value}
				// tslint:disable-next-line: no-empty
				onChange={() => { }}
				disabled={disabled}
			/>
			<label>{label}
				{tooltip && <Popup
					hoverable={true}
					wide={true}
					position="top center"
					content={tooltip}
					trigger={
						<span><i className="icon info circle" style={{ cursor: "help", marginLeft: "0.5rem" }} /></span>
					}
				/>}
			</label>
		</div>
	);
};
export default CheckboxInput;
