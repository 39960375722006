import React, {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from "react";
import { useStores } from "v1/mobx-stores";
import FeatureFlagStore from "v1/mobx-stores/feature-flag-store";
import { APIContextProvider } from "../../../../services/api-context-provider";

import { PokerService } from "../service/poker";

interface PokerController {
	service?: PokerService | null;
	featureFlagStore: FeatureFlagStore;
}

const PokerContext = createContext<PokerController | undefined>(undefined);

interface Props {
	children: ReactNode;
	contextProvider: APIContextProvider;
}

export default function PokerProvider(props: Props) {
	const { children, contextProvider } = props;
	const [service, setPokerService] = useState<PokerService | null>();
	const { featureFlagStore } = useStores();

	useEffect(() => {
		setPokerService(new PokerService(contextProvider));
		featureFlagStore
			.initialize(contextProvider.getContext().customerId)
			.catch();
	}, []);

	return (
		<PokerContext.Provider
			value={{
				service, featureFlagStore
			}}
		>
			{children}
		</PokerContext.Provider>
	);
}

export function usePoker() {
	return useContext(PokerContext);
}
