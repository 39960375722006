import Checkbox from "../../components/checkbox/checkbox";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import "./style.scss";
import TriggerOnClickOutside from "../../components/ux-effects/trigger-on-click-outside";

interface ISelectOption {
	key: any;
	text?: string | JSX.Element;
	value?: string | number;
	disabled?: boolean;
}

interface ISelect {
	label?: string;
	placeholder?: string;
	options: ISelectOption[];
	selected?: string[];
	value?: string | number;
	onChange: (selected: string[] | number[] | string | number) => void;
	searchable?: boolean;
	multiselect?: boolean;
	error?: boolean;
	required?: boolean;
	loading?: boolean;
	optionHeader?: string;
}

// tslint:disable-next-line: variable-name
export const Select = ({ label, placeholder, options, selected, value, onChange, searchable, multiselect, error, required, optionHeader, ...rest }: ISelect) => {
	const [open, setOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState<string>("");
	const defaultValue = multiselect ? [] : "";
	const [selection, setSelection] = useState<string[]>(selected ? selected : []);
	const [selectedValue, setSelectedValue] = useState<string[] | string | number>(value ? value : defaultValue);
	const selectedItemRef = useRef<HTMLDivElement>(null);
	const scrollableRef = useRef<HTMLDivElement>(null);
	const excludeRef = useRef<HTMLDivElement>(null);
	const close = useCallback(() => setOpen(false), []);

	useEffect(() => {
		setSelectedValue(value ? value : defaultValue);
		setSelection(selected ?? []);
	}, [value, selected]);

	useEffect(() => {
		if (open && scrollableRef.current && selectedItemRef.current) {
			scrollableRef.current.scrollTop = selectedItemRef.current.offsetTop - 90;
		}
	}, [open]);

	const handleMultipleSelect = (checked: boolean, val?: string | number) => {
		if (!val) {
			return;
		}
		val = val.toString();
		const updatedState: string[] = [...selection];
		const index = updatedState.indexOf(val);

		if (checked) {
			updatedState.push(val);
		} else {
			updatedState.splice(index, 1);
		}

		setSelection(updatedState);
		onChange(updatedState);
	};

	const handleSelect = (val?: string | number) => {
		if (!val) {
			return;
		}
		setSelectedValue(val);
		onChange(val);
		setOpen(false);
	};

	const handleClearSelection = () => {
		setSelection([]);
		if (multiselect) {
			setSelection([]);
		} else {
			setSelectedValue("");
		}
		onChange(defaultValue);
	};

	const handleSearchOptions = () => {
		if (!searchTerm) {
			return options;
		}
		return options?.filter(option => option?.text?.toString().toLowerCase().includes(searchTerm.toLowerCase()));
	};

	const filteredOptions = handleSearchOptions();
	const selectedOptions = options.filter(option => option.value && (multiselect ? selection.includes(option.value.toString()) : option.value === selectedValue));
	const clearDisabled = selection.length < 1;

	const displaySingleSelectValue = selectedOptions && selectedOptions[0]?.text;

	return <div className="acu-select">
		{label && <div className="acu-select-label">{label}</div>}
		<div
			className={`acu-select-input ${error ? "--error" : ""}`}
			ref={excludeRef}
			onClick={() => {
				setOpen(prevState => !prevState);
			}}
		>

			{multiselect && <>
				{
					selectedOptions.length > 0 ? selectedOptions.map(item => {
						return (
							<div
								key={item.key}
								className={"acu-select-input-selected-item"}
							>
								{item.text}
								<div
									className={"acu-select-remove-option"}
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										handleMultipleSelect(false, item.key);
									}}
									{...rest}
								>
									<i aria-hidden="true" className="close icon" />
								</div>
							</div>
						);
					}) : <div className="acu-select-input-placeholder acu-capitalize">
						<span className="acu-capitalize">
							{placeholder}
						</span>
					</div>
				}
			</>}
			{!multiselect && <>
				{
					displaySingleSelectValue ?
						<div className="acu-select-input-selected-single">
							<span className="acu-capitalize">
								{displaySingleSelectValue}
							</span>
						</div> :
						<div className="acu-select-input-placeholder">
							<span className="acu-capitalize">
								{placeholder}
							</span>
						</div>
				}
			</>}
			<div className="acu-select-arrows">
				{open ? <i aria-hidden="true" className="chevron up icon" /> :
					<i aria-hidden="true" className="chevron down icon" />}
			</div>
		</div>
		{open && <TriggerOnClickOutside onTrigger={close} excludedElements={excludeRef.current}>
			<div className="acu-select-input-container">
				{optionHeader && <div className="acu-select-input-dropdown-header">{optionHeader}</div>}
				{searchable && <div className="acu-select-input-search">
					<input
						type="text"
						placeholder="Search..."
						value={searchTerm}
						onChange={(e) => {
							setSearchTerm(e.target.value);
						}}
						autoFocus={true}
					/>
				</div>}
				<div className="acu-select-input-items" ref={scrollableRef}>
					{multiselect &&
						<div
							className={`acu-select-clear-selection ${clearDisabled ? "--disabled" : ""}`}
							onClick={!clearDisabled ? handleClearSelection : undefined}>
							Clear selection
						</div>
					}
					{/* tslint:disable-next-line: no-shadowed-variable */}
					{filteredOptions.map(({ key, text, value, disabled }) => {
						const isActive = multiselect ? !!value && selection.includes(value.toString()) : value === selectedValue;
						return (
							<Dropdown.Item key={key} className="acu-select-input-item">
								{multiselect ?
									<Checkbox
										className={`acu-select-input-child-item acu-capitalize`}
										disabled={disabled}
										label={text?.toString()} checked={isActive}
										onChange={(data) => {
											handleMultipleSelect(!!data.checked, value);
										}}
									/> :
									<div
										className={`acu-select-input-child-item ${disabled ? "--disabled" : ""} ${isActive ? "--selected" : ""}`}
										onClick={() => {
											handleSelect(value);
										}}
										ref={isActive ? selectedItemRef : undefined}
									>
										<span className="acu-capitalize">
											{text}
										</span>
									</div>
								}
							</Dropdown.Item>
						);
					})}
				</div>
			</div>
		</TriggerOnClickOutside>}
	</div>;
};

export default Select;
