import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { observer } from "mobx-react";
import { useStores } from "../../../mobx-stores";
import {
	ConfigurationEntityType,
	CustomizableConfigurationCategories, DevelopmentMethodology, IDashboardPullRequest,
	IDashboardPullRequestEntity, IDashboardPullRequestExtended, IDashboardSprint,
	IDashboardTask, IDashboardTaskStatusChangeResponse, SprintState, SprintType, TaskEstimationMethod
} from "@acumen/dashboard-common";
import BurndownChart from "./burndown-chart";
import { ObstaclePanel } from "./obstacle-panels";
import { IssuesTable } from "./issues-table";
import FiltersTopNavbar from "../../../components/filters-top-navbar";
import { GA_EVENT_CATEGORY } from "../../../analytics-events";
import PullRequestDetailsDisplayPanel from "../../../components/dashboard-pr/pr-details-display-panel";
import TaskDetailsDisplayPanel from "../../../components/dashboard-task/task-details-display-panel";
import RetroPieChart from "./retro-pie-chart";
import colorFunction from "../analytics/charts/chart-color-scheme";
import PRSizeToMergeTimeChart from "../../org-analytics/charts/pr-size-to-merge-time";
import PlanVsActualChart from "./plan-vs-actual-chart";
import ChartCard from "../../../components/chart-card";
import RetroHighlights from "./retro-highlights";
import classNames from "classnames";
import { inflect } from "@acumen/common";
import { IBurndownRequestScope, IEpicsRetroRequestScope, ISprintRetroRequestScope } from "../../../services/crud/sprint-retro-api-client";
import LoadingIndicator from "../../../components/loader";
import { IChartScope } from "../../../pages/org-analytics/charts/chart-scope";
import { componentColorByName } from "../../../components/dashboard-task/task-type-series-prop";
import { TimeFramesSelector } from "../../../components/filters-selector/sections/time-frames-selector";
import FiltersSelector from "../../../components/filters-selector/filters-selector";
import CustomizeConfiguration from "../../../components/customize-configurations";
import "./style.scss";
import { IPlannedVsActualRequestScope } from "../../../mobx-stores/planned-vs-actual-store";
import { DEFAULT_CHART_TIME_SPAN_MONTHS } from "../../../mobx-stores/sprint-retro-store";
import { Header } from "semantic-ui-react";
import EmptyState from "../../../pages/empty-state";

const getDateFormat = (date: string | null) => date ? moment(date).format("MMM, DD") : "";

const getSprintKey = (sprint: IDashboardSprint) => `${sprint.id},${sprint.type}`;

export const getSprintLabel = (sprint: IDashboardSprint, shouldNewLineDates: boolean = false): string => {
	const startDate = getDateFormat(sprint.startDate);
	const endDate = getDateFormat(sprint.endDate);
	const dateString = `${startDate}${startDate.length > 0 ? ` - ` : ""}${endDate}`;

	if (sprint.type === SprintType.Sprint) {
		return `${sprint.name ? `${sprint.name}${shouldNewLineDates ? "<br/>" : " "}` : ""}(${dateString})`;
	}

	return `${dateString}`;
};

interface IIterationPeriodSelectorProps {
	sprint: IDashboardSprint | undefined;
	devMethodology: DevelopmentMethodology;
	onChange: (s: any) => void;
	showArrow?: boolean;
	sprints: IDashboardSprint[] | undefined;
}

// tslint:disable-next-line: variable-name
export const IterationPeriodSelector = observer(({
	sprint,
	devMethodology,
	onChange,
	showArrow = false,
	sprints
}: IIterationPeriodSelectorProps) => {
	const {
		sprintsStore,
		sprintRetroStore
	} = useStores();

	if (devMethodology === DevelopmentMethodology.None) {
		return null;
	}

	const { isLoading: sprintsLoading } = sprintsStore;
	const { isLoadingReportData } = sprintRetroStore;

	let placeholder = "Select";
	let label = "Sprint";

	switch (devMethodology) {
		case DevelopmentMethodology.Scrum:
			placeholder = "Select Sprint";
			label = "Sprint";
			break;
		case DevelopmentMethodology.Kanban:
			placeholder = "Select Cycle";
			label = "Cycle";
			break;
	}

	return (
		<FiltersSelector
			placeholder={placeholder}
			value={sprint ? getSprintLabel(sprint) : undefined}
			loading={isLoadingReportData || (sprintsLoading || !sprints)}
			showArrow={showArrow}
			className="time-frames-selector-container"
		>
			{sprints && <TimeFramesSelector
				label={label}
				value={sprint ? sprint.id : undefined}
				options={sprints.filter(s => s.state !== SprintState.Future).map(s => ({
					key: getSprintKey(s),
					value: s.id,
					label: getSprintLabel(s),
					isActive: s.isActive,
					isDisabled: false
				}))}
				setValue={(value) => {
					const selectedSprintData: IDashboardSprint = sprints!.filter(s => s.id === value)[0];
					if (selectedSprintData) {
						onChange({
							key: getSprintKey(selectedSprintData),
							value: selectedSprintData,
							label: getSprintLabel(selectedSprintData),
							isActiveSprint: selectedSprintData.isActive
						});
					}
				}}
				showSearchBar={true}
			/>}
		</FiltersSelector>
	);
});

// tslint:disable-next-line: variable-name
const SprintRetro = observer(() => {
	const {
		teamsStore,
		teamMembersStore,
		sprintsStore,
		sprintRetroStore,
		authStore,
		componentsStore,
		boardsStore,
		tasksStore: {
			fetchTasksStatusDiff
		},
		integrationsStore: {
			hasActiveIntegrations,
			setIntegrationType
		}
	} = useStores();

	const {
		resetAllPageData,
		getBurndownData,
		getReportData,
		getIssuesCountDoneByTypeData,
		getIssuesDoneByComponentData,
		getIssues,
		getEpics,
		getSprintRetroTimeScope,
		fetchTasksStateInSprint,
		workSplitByType,
		workSplitByComponent,
		sprintRetroObstaclesData,
		developmentMethodology,
		estimationMethod,
		burndownChartData,
		subtaskSprintStrategy,
		excludeTasksWithNonTeamDCs,
		unplannedTasksGraceHours,
		issues,
		timezone,
		epicsTasks: epics,
		countryCode
	} = sprintRetroStore;

	const {
		fetchData: fetchBoardsData,
		isLoading: isLoadingBoards,
		boardsData
	} = boardsStore;

	const { componentsById } = componentsStore;

	const [searchedTeamId, setSearchedTeamId] = useState<string | undefined>(undefined);
	const [sprint, setSprint] = useState<IDashboardSprint | undefined>(undefined);
	const [updatePage, setUpdatePage] = useState<boolean>(false);
	const [selectedPullRequestInPanel, setSelectedPullRequestInPanel] = useState<IDashboardPullRequest | IDashboardPullRequestExtended | IDashboardPullRequestEntity | undefined>(undefined);
	const [selectedTaskInPanel, setSelectedTaskInPanel] = useState<IDashboardTask | undefined>(undefined);
	const [dataContributorFilter, setDataContributorFilter] = useState<string[] | undefined>(undefined);
	const [chartScope, setTimeScope] = useState<Omit<IChartScope, "teamIds"> | undefined>(undefined);
	const [pageErrorMessage, setPageErrorMessage] = useState<string | undefined>(undefined);
	const [epicsStatusDiffData, setEpicsStatusDiffData] = useState<IDashboardTaskStatusChangeResponse | undefined>(undefined);
	const [statusDiffData, setStatusDiffData] = useState<IDashboardTaskStatusChangeResponse | undefined>(undefined);

	useEffect(() => {
		let isMounted = true;
		setPageErrorMessage(undefined);
		async function retrieveScopeAndFiltersData(selectedTeamId: string) {
			await teamMembersStore.fetchAllTeamMembers(selectedTeamId);

			const [
				configuration
			] = await Promise.all([
				sprintRetroStore.fetchConfiguration(selectedTeamId),
				fetchBoardsData({ teamId: selectedTeamId }),
				componentsStore.fetchData()
			]);

			let sprints: IDashboardSprint[] | undefined;
			if (configuration && configuration.developmentMethodology) {
				sprints = await sprintsStore.fetchData(configuration.developmentMethodology as DevelopmentMethodology, {
					teamId: selectedTeamId,
					sprintStates: [SprintState.Active, SprintState.Closed],
					excludeSprintsWithFutureStartDate: true,
					excludeSprintsWithNoAssignees: true,
				});
			}

			return { sprints, configuration };
		}

		if (searchedTeamId && isMounted) {
			resetAllPageData();
			setSprint(undefined);

			// tslint:disable-next-line: no-floating-promises
			retrieveScopeAndFiltersData(searchedTeamId).then(({ sprints, configuration }) => {
				if (!configuration) {
					return;
				}

				if ((!sprints || _.isEmpty(sprints))) {
					const cycleLabel = configuration.developmentMethodology === DevelopmentMethodology.Scrum
						? "sprints"
						: "cycles";
					setPageErrorMessage(`No ${cycleLabel} detected, please configure your development cycles in the app settings to use the iteration review`);
					return;
				}

				let chosenSprint = sprints.find((s: any) => s.isActive === true);
				if (!chosenSprint) {
					chosenSprint = sprints.filter(s => s.state !== SprintState.Future)[0];
				}
				if (!chosenSprint) {
					setPageErrorMessage("Could not find a sprint to show.");
				} else {
					setSprint(chosenSprint);
					setUpdatePage(true);
				}
			});
		}

		return () => { isMounted = false; };
	}, [searchedTeamId]);

	useEffect(() => {
		let isMounted = true;
		setPageErrorMessage(undefined);
		if (teamsStore.singleTeam.data.id
			&& teamsStore.singleTeam.data.id !== searchedTeamId
			&& teamsStore.singleTeam.data.id !== "" && isMounted) {
			setSearchedTeamId(teamsStore.singleTeam.data.id);
		}

		return () => { isMounted = false; };
	}, [teamsStore.singleTeam.data.id]);

	useEffect(() => {
		let isMounted = true;
		setPageErrorMessage(undefined);
		async function retrieveIterationReviewData() {
			if (updatePage && searchedTeamId && sprint
				&& subtaskSprintStrategy && developmentMethodology
				&& estimationMethod && sprint.startDate && sprint.endDate
				&& excludeTasksWithNonTeamDCs !== undefined
				&& componentsById !== undefined && timezone !== undefined
				&& countryCode !== undefined && !isLoadingBoards) {

				const retroScope: ISprintRetroRequestScope = {
					teamId: searchedTeamId,
					sprint,
					dataContributorIds: dataContributorFilter,
					subtaskStrategy: subtaskSprintStrategy,
					estimationMethod,
					excludeTasksWithNonTeamDCs,
					developmentMethodology
				};

				const burndownScope: IBurndownRequestScope = {
					...retroScope,
					timezone,
					countryCode,
					boardIds: boardsData.ownable.map(board => board.entityId),
					includeUnassigned: false,
				};

				const userTimezone = authStore.authUser.timezone;
				setUpdatePage(false);
				// tslint:disable-next-line: no-floating-promises
				getReportData(retroScope);

				// tslint:disable-next-line: no-floating-promises
				getBurndownData(burndownScope);
				// tslint:disable-next-line: no-floating-promises
				getIssuesCountDoneByTypeData(retroScope, sprint, userTimezone);
				// tslint:disable-next-line: no-floating-promises
				getIssuesDoneByComponentData(retroScope, sprint, userTimezone, componentsById);

				// tslint:disable-next-line: no-floating-promises
				getIssues({
					...burndownScope,
					// Note: excludeTasksWithNonTeamDCs was removed from the endpoint
					dataContributorIds: calcDataContributorIdByExcludeNonTeamMembersFlag(),
					includeUnassigned: false,
				});
				setTimeScope({ ...getSprintRetroTimeScope(), timezone: userTimezone });
			}
		}
		if (!searchedTeamId || !isMounted) {
			return;
		}
		// tslint:disable-next-line: no-floating-promises
		retrieveIterationReviewData();

		return () => { isMounted = false; };
	}, [updatePage]);

	useEffect(() => {
		if (!issues || issues.length === 0 || !sprint || !sprint.startDate || !sprint.endDate
			|| developmentMethodology === undefined || excludeTasksWithNonTeamDCs === undefined
			|| excludeTasksWithNonTeamDCs === undefined || unplannedTasksGraceHours === undefined
			|| !subtaskSprintStrategy || !estimationMethod
		) {
			setStatusDiffData(undefined);
			return;
		}

		const allIssuesIds = issues.map(issue => issue.entityId);

		// tslint:disable-next-line: no-floating-promises
		fetchTasksStatusDiff(allIssuesIds, new Date(sprint.startDate),
			sprint.completeDate ? new Date(sprint.completeDate) : new Date()
		).then(response => setStatusDiffData(response ?? undefined));

		// tslint:disable-next-line: no-floating-promises
		fetchTasksStateInSprint({
			sprintId: sprint.id,
			taskIds: allIssuesIds,
			allowedAssignees: calcDataContributorIdByExcludeNonTeamMembersFlag(),
			includeUnassigned: false,
			developmentMethodology,
			boardIds: boardsData.ownable.map(board => board.entityId)
		});

		const epicsScope: IEpicsRetroRequestScope = {
			subtaskSprintStrategy,
			taskEstimationMethod: estimationMethod,
			taskIds: allIssuesIds,
			startTime: new Date(sprint.startDate),
			endTime: new Date(sprint.endDate)
		};
		// tslint:disable-next-line: no-floating-promises
		getEpics(epicsScope);
	}, [issues]);

	useEffect(() => {
		if (!epics || epics.length === 0 || !sprint || !sprint.startDate || !sprint.endDate) {
			setEpicsStatusDiffData(undefined);
			return;
		}

		const epicsIds = epics.map(epic => epic.epic.entityId);
		// tslint:disable-next-line: no-floating-promises
		fetchTasksStatusDiff(epicsIds, new Date(sprint.startDate),
			sprint.completeDate ? new Date(sprint.completeDate) : new Date()
		).then(response => setEpicsStatusDiffData(response ?? undefined));

	}, [epics]);

	const getChartScopeBasedCurrentSprintIfPossible = (scope: Omit<IChartScope, "teamIds">) => {
		const clonedScope = _.cloneDeep(scope);
		if (sprint && sprint.startDate && sprint.endDate) {
			clonedScope.startTime = new Date(sprint.startDate);
			clonedScope.endTime = new Date(sprint.endDate);
		}

		return clonedScope;
	};

	const getIssuesTableTitle = () => {
		switch (developmentMethodology) {
			case DevelopmentMethodology.Scrum:
				return "Issues in sprint";
			case DevelopmentMethodology.Kanban:
				return "Issues in cycle";
			default:
				return "Issues";
		}
	};

	const calcDataContributorIdByExcludeNonTeamMembersFlag = () => {
		if (dataContributorFilter && dataContributorFilter.length > 0) {
			return dataContributorFilter;
		}

		return excludeTasksWithNonTeamDCs
			? teamMembersStore.allTeamMembers.data.map(member => member.id)
			: [];
	};

	const plannedVsActualScope = useMemo((): IPlannedVsActualRequestScope | undefined => {
		if (sprint
			&& (dataContributorFilter || teamMembersStore.allTeamMembers)
			&& subtaskSprintStrategy
			&& estimationMethod
			&& excludeTasksWithNonTeamDCs !== undefined
			&& developmentMethodology
			&& chartScope
			&& !isLoadingBoards
		) {
			const sprintStartTime = sprint.startDate ? moment(sprint.startDate).toDate().getTime() : Infinity;
			const chartScopeStartTime = chartScope.startTime.getTime();
			const startDate = new Date(Math.min(sprintStartTime, chartScopeStartTime));

			const endDate = moment(startDate).add(DEFAULT_CHART_TIME_SPAN_MONTHS, "months").toDate();

			return {
				sprint,
				dataContributorIds: calcDataContributorIdByExcludeNonTeamMembersFlag(),
				subtaskStrategy: subtaskSprintStrategy,
				estimationMethod,
				excludeTasksWithNonTeamDCs,
				developmentMethodology,
				startDate,
				endDate,
				boardIds: boardsData.ownable.map(board => board.entityId)
			};
		}

		return undefined;
	}, [
		sprint, dataContributorFilter, teamMembersStore.allTeamMembers, subtaskSprintStrategy, estimationMethod,
		excludeTasksWithNonTeamDCs, developmentMethodology, chartScope?.startTime, chartScope?.endTime, isLoadingBoards
	]);
	const handlePrInPanelDismiss = useCallback(() => setSelectedPullRequestInPanel(undefined), []);
	const handleTaskInPanelDismiss = useCallback(() => setSelectedTaskInPanel(undefined), []);

	const KEEP_PANEL_OPEN_CLS_NAME = "main-table-with-panel";

	if (hasActiveIntegrations === false) {
		return (
			<><Header />
				<div className="ui grid">
					<EmptyState
						onChoose={(type) => { setIntegrationType(type); }}
						isActive={authStore.isUserAdmin}
					/>
				</div></>
		);
	}
	return (
		<div id="iteration-review" className="ui sixteen column centered stretched grid">
			{(selectedPullRequestInPanel !== undefined) &&
				<PullRequestDetailsDisplayPanel
					pr={selectedPullRequestInPanel}
					shouldShowNeedAttentionFlag={false}
					onDismiss={handlePrInPanelDismiss}
					outsidePanelClassName={KEEP_PANEL_OPEN_CLS_NAME}
				/>
			}
			{(selectedTaskInPanel !== undefined) &&
				<TaskDetailsDisplayPanel
					task={selectedTaskInPanel}
					shouldShowNeedAttentionFlag={false}
					onDismiss={handleTaskInPanelDismiss}
					outsidePanelClassName={KEEP_PANEL_OPEN_CLS_NAME}
				/>
			}
			<FiltersTopNavbar
				filters={{}}
				applyFilters={() => "ok"}
				popupSelectFields={[]}
				counterLabel={sprintRetroObstaclesData?.taskActualCount ? inflect(sprintRetroObstaclesData.taskActualCount, "Task completed", "Tasks completed") : undefined}
				avatarSelectorField={{
					usersList: teamMembersStore.allTeamMembers.data.map(user => { user.id = user.dataContributorId; return user; }),
					onUsersSelected: (selectedUsers: string[]) => {
						if (!!pageErrorMessage) {
							return;
						}
						setDataContributorFilter(selectedUsers);
						setUpdatePage(true);
					},
					initialSelectedUsers: []
				}}
				clickEventPage={GA_EVENT_CATEGORY.SprintReport}
				pageErrorMessage={pageErrorMessage}
			>
				<div className="control-width flex-row">
					<div className="customization-icon-nav">
						{searchedTeamId && <CustomizeConfiguration
							teamId={searchedTeamId}
							category={CustomizableConfigurationCategories.IterationReview}
							entityType={ConfigurationEntityType.Team}
						/>}
					</div>
					<IterationPeriodSelector
						sprint={sprint}
						devMethodology={developmentMethodology ?? DevelopmentMethodology.None}
						onChange={(opt: any) => {
							setSprint(opt.value);
							setUpdatePage(true);
						}}
						sprints={sprintsStore.sprintData}
					/>
				</div>
			</FiltersTopNavbar>

			<div className="four wide column set-page-z-index">
				<div className="ui full-width raised card" >
					<RetroHighlights pageError={!!pageErrorMessage} />
				</div>
			</div>

			<div className="twelve wide column set-page-z-index">
				<div className="ui full-width raised card" >
					<BurndownChart
						timezone={authStore.authUser.timezone}
						pageError={!!pageErrorMessage}
						devMethodology={developmentMethodology}
						estimationMethod={estimationMethod ?? TaskEstimationMethod.None}
						subtaskStrategy={subtaskSprintStrategy}
						burndownChartData={burndownChartData}
						sprint={sprint}
					/>
				</div>
			</div>

			<div className="ui sixteen wide column set-page-z-index">
				<ChartCard
					chartData={{
						title: "Planned vs. actual over time",
						description: `This chart shows the relationship between the amount of planned tasks and the amount actually done per sprint (in estimation units). Note that tasks that have been reopened are removed from the actual count. The line graph in the chart shows the velocity of the team, calculated by taking the running average of the past 5 iterations' actual ticket completions.`
					}}
					supportExpand={false}
					eventCategory={GA_EVENT_CATEGORY.SprintReport}
				>
					<LoadingIndicator isActive={!plannedVsActualScope} local={true}>
						{plannedVsActualScope &&
							<PlanVsActualChart
								plannedVsActualScope={plannedVsActualScope}
								pageError={!!pageErrorMessage}
								timezone={timezone}
							/>
						}
					</LoadingIndicator>
				</ChartCard>
			</div>

			<div className="sixteen wide column set-page-z-index">
				<div className="ui two column grid">
					<div className="column">
						<RetroPieChart
							title={"Work done by type"}
							description={"Issues that have completed their cycle (done) in this sprint, split by issue type."}
							chartSeries={workSplitByType}
							supportExpand={false}
							colorScheme={workSplitByType?.map(data =>
								colorFunction.acumenTaskType[data.name as keyof typeof colorFunction.acumenTaskType] ??
								colorFunction.acumenTaskType.Unmapped)}
						/>
					</div>
					<div className="column">
						<RetroPieChart
							title={"Work done by component"}
							description={"Total amount of (story points/time estimates/issues) completed in this (sprint/dev cycle) attributed to each component"}
							chartSeries={workSplitByComponent}
							supportExpand={false}
							colorScheme={workSplitByComponent?.map((data, index) => {
								return componentColorByName(index, data.name);
							})}
						/>
					</div>
				</div>
			</div>

			<div className="ui sixteen wide column set-page-z-index">
				{<ChartCard
					chartData={{
						title: "Pull request size vs. time",
						description: `Shows the relation between pull request sizes and their corresponding time (open to merge).
	Circles indicate size in lines of code, date shows when the pull request was merged.`
					}}
					supportExpand={false}
					eventCategory={GA_EVENT_CATEGORY.SprintReport}
				>
					{!!pageErrorMessage ? <></> :
						(chartScope && !updatePage) ? <PRSizeToMergeTimeChart
							teamIds={searchedTeamId ? [searchedTeamId] : undefined}
							dataContributorsIds={dataContributorFilter}
							scope={getChartScopeBasedCurrentSprintIfPossible(chartScope)}
							onClick={async (pr) => {
								if (!searchedTeamId) {
									return;
								}
								setSelectedPullRequestInPanel(pr);
							}}
						/> :
							<LoadingIndicator isActive={true} />
					}
				</ChartCard>}
			</div>

			<div className={classNames("sixteen wide column", KEEP_PANEL_OPEN_CLS_NAME, "set-page-z-index")}>
				<ObstaclePanel
					isLoading={!sprintRetroObstaclesData}
					setSelectedPullRequestInPanel={setSelectedPullRequestInPanel}
					setSelectedTaskInPanel={setSelectedTaskInPanel}
					selectedTaskIdInPanel={selectedTaskInPanel?.entityId}
					selectedPrIdInPanel={selectedPullRequestInPanel?.entityId}
					pageError={!!pageErrorMessage}
				/>
			</div >

			<div className="sixteen wide column set-page-z-index">
				<IssuesTable
					title={getIssuesTableTitle()}
					setSelectedIssueInPanel={setSelectedTaskInPanel}
					selectedIssueIdInPanel={selectedTaskInPanel?.entityId}
					pageError={!!pageErrorMessage}
					sprintType={sprint?.type}
					subtaskStrategy={subtaskSprintStrategy}
					issuesStatusDiff={{ ...statusDiffData, ...epicsStatusDiffData }}
				/>
			</div>
		</div >
	);
});
export default SprintRetro;
