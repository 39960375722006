import * as React from "react";
const NotesArrowRight = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width="8"
		height="12"
		viewBox="0 0 8 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
	<path fillRule="evenodd" d="M0.763873 11.7004C0.62094 11.5388 0.637493 11.2931 0.800845 11.1517L6.41305 6.29259C6.592 6.13765 6.592 5.86219 6.41305 5.70725L0.800845 0.848148C0.637493 0.706717 0.62094 0.461031 0.763873 0.299396C0.906806 0.137759 1.1551 0.12138 1.31845 0.262812L6.93065 5.12191C7.4675 5.58673 7.46751 6.41311 6.93065 6.87792L1.31845 11.737C1.1551 11.8785 0.906806 11.8621 0.763873 11.7004Z" clipRule="evenodd" fill="#0F2345" />
</svg>
);
export default NotesArrowRight;
