import React, { useState } from "react";
import { TabModel, Tabs } from "../../../../components/tabs";
import { BadgeBreakdown } from "../../../../components/badge-breakdown/badge-breakdown";
import { DeveloperMetricsTabTable } from "../developer-metrics-tab-table/developer-metrics-tab-table";
import { DeveloperCompletedWork } from "../developer-completed-work/developer-completed-work";
import { DeveloperViewModel } from "../../types";
import "./developer-performance-breakdown.scss";
import { MetricData } from "../../../team-comparison/types";
import { BadgeOwner } from "../../../../types/badges";

export interface DeveloperPerformanceBreakdownProps {
	developer: DeveloperViewModel;
	metricData: MetricData;
}

const BADGE_BREAKDOWN_TAB = "badge-breakdown";
const METRICS_TAB = "metrics";
const COMPLETED_WORK_TAB = "completed-work";

// tslint:disable-next-line:variable-name
export const DeveloperPerformanceBreakdown = (props: DeveloperPerformanceBreakdownProps) => {
	const { developer, metricData } = props;
	const [activeTabName, setActiveTabName] = useState(BADGE_BREAKDOWN_TAB);

	return (
		<div className="developer-performance-breakdown">
			<Tabs
				tabs={tabs}
				activeTabName={activeTabName}
				onActiveTabChange={setActiveTabName}
			/>

			{activeTabName === BADGE_BREAKDOWN_TAB && (
				<BadgeBreakdown
					badges={developer.badges.metricBadges}
					hasLowActivity={developer.badges.hasLowActivity}
					badgeOwner={BadgeOwner.Developer}
				/>
			)}
			{activeTabName === METRICS_TAB && (
				<DeveloperMetricsTabTable developer={developer} metricData={metricData}/>
			)}
			{activeTabName === COMPLETED_WORK_TAB && (
				<DeveloperCompletedWork contributor={developer.info}/>
			)}
		</div>
	);
};

const tabs: TabModel[] = [
	{ name: BADGE_BREAKDOWN_TAB, title: "Badge Breakdown" },
	{ name: METRICS_TAB, title: "Metrics" },
	{ name: COMPLETED_WORK_TAB, title: "Completed Work" },
];
