import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "./mobx-stores";
import { useAuth0 } from "@auth0/auth0-react";
import { Switch, Route, Redirect } from "react-router-dom";
import BasicLayout from "./layout";
import MyTeam from "./pages/my-team";
import MyAccount from "./pages/my-account";
import MetricTesterPage from "./pages/metric-tester";
import DoraKPIPage from "./pages/dora-kpi";
import OrgAnalyticsPage from "./pages/org-analytics";
import SlackNotificationsPage from "./pages/slack-notifications";
import KitchenSink from "./pages/kitchen-sink";
import Loader from "./components/loader";
import { ToastContainer } from "react-toastify";
import useAnalytics from "../hooks/analytics";
import { routesWithAllowedParams } from "./config";
import config from "../utils/config";
import "./components/style.scss";
import { useIntercom } from "react-use-intercom";
import { FeatureFlags } from "./feature-flags";
import _ from "lodash";
import { Meta } from "./components/Meta";

// tslint:disable-next-line: variable-name
const App = observer(() => {
	const { update } = useIntercom();
	const {
		getAccessTokenSilently,
		isAuthenticated,
		loginWithRedirect,
		isLoading,
		error,
		getIdTokenClaims,
		logout
	} = useAuth0();
	const {
		authStore, routingStore, fetcherStore,
		integrationsStore: {
			fetchActiveIntegrationsCount
		},
		featureFlagStore: {
			isInitialized: isFeatureFlagStoreInitialized,
			initialize: initializeFeatureFlagStore,
			isFeatureEnabled,
		}
	} = useStores();

	if (process.env.NODE_ENV !== "development") {
		useAnalytics(authStore.authUser);
	}

	const allowedRoute = routesWithAllowedParams.find(r => window.location.pathname.match(r.route));
	let returnTo = window.location.pathname;
	let params = window.location.search;
	if (allowedRoute) {
		allowedRoute.modifyParams?.forEach(modify => {
			const [incomingName, newParamName] = [...modify];
			if (incomingName && newParamName) {
				params = params.replace(incomingName, newParamName);
			}
		});
		returnTo += params;
	}

	async function auth() {
		const cachedToken = authStore.getCachedJWT();
		if (cachedToken) {
			return authStore.handleAuthUserResponse(cachedToken).then(fetchActiveIntegrationsCount);
		}

		if (!isLoading) {
			if (isAuthenticated) {
				const accessToken = await getAccessTokenSilently({});
				return authStore.handleAuthUserResponse(accessToken).then(fetchActiveIntegrationsCount);
			} else {
				return loginWithRedirect({
					appState: { returnTo },
				});
			}
		}
	}

	useEffect(() => {
		if (!_.isEmpty(authStore.authUser.customerId)) {
			initializeFeatureFlagStore(authStore.authUser.customerId).catch();
		}
	}, [authStore.authUser.customerId]);

	useEffect(() => {
		update({
			name: authStore.authUser.dataContributorDetails.primaryDisplayName ?? undefined,
			email: authStore.authUser.email ?? undefined,
			userId: authStore.authUser.id ?? undefined,
			userHash: authStore.authUser.intercomHash,
			createdAt: authStore.authUser.createdAt.toString() ?? undefined,
			company: {
				name: authStore.authUser.companyName,
				companyId: authStore.authUser.customerId
			},
			avatar: {
				type: "avatar",
				imageUrl: authStore.authUser.dataContributorDetails.primaryAvatarUrl ?? undefined
			},
			customAttributes: {
				acumenRoles: authStore.authUser.roles.join(","),
				teamId: authStore.authUser.teamMemberships.map(t => t.teamId).join(","),
			}
		});
	}, [routingStore.location.pathname, authStore.authUser]);

	useEffect(() => {
		// tslint:disable-next-line: no-floating-promises
		auth();
	}, [isLoading, isAuthenticated, authStore, error]);

	async function onTokenError() {
		const idToken = await getIdTokenClaims();
		if (idToken && idToken.exp && idToken.exp <= 0) {
			await auth();
		} else {
			const {
				auth0: { logoutReturnTo },
			} = config();
			logout({ returnTo: logoutReturnTo });
		}
	}
	useEffect(() => {
		if (fetcherStore.hasTokenError) {
			// tslint:disable-next-line: no-floating-promises
			onTokenError();
		}
	}, [fetcherStore.hasTokenError]);

	function defaultRoute(): string {
		if (isFeatureEnabled(FeatureFlags.AcumenDashboardPage)) {
			return "/my-team";
		}
		if (authStore.isUserExecutiveOrAbove && isFeatureEnabled(FeatureFlags.AcumenOrganizationAnalyticsPage)) {
			return "/org-analytics";
		}
		if (isFeatureEnabled(FeatureFlags.AcumenTeamAnalyticsPage)) {
			return "/my-team/analytics";
		}
		if (isFeatureEnabled(FeatureFlags.AcumenRetrospectivePage)) {
			return "/my-team/iteration-review";
		}
		if (authStore.isUserAdmin) {
			return "/my-account/my-account/teams";
		}
		return "/my-account/account";
	}

	// NOTE: redirects below are for backwards-compatibility with old links generated by our system
	return (
		<Loader isActive={authStore.authUser.customerId === "" || !isFeatureFlagStoreInitialized}>
			<Meta />
			<div className="fixed-toaster-container">
				<ToastContainer
					autoClose={false}
				/>
			</div>
			<BasicLayout>
				<div className="ui basic segment">
					<Switch>
						{
							isFeatureEnabled(FeatureFlags.AcumenDashboardPage) &&
							<Route path="/my-team" component={MyTeam} />
						}

						{
							isFeatureEnabled(FeatureFlags.AcumenRetrospectivePage) &&
							<Route path="/work-diff">
								<Redirect to="/my-team/work-diff" />
							</Route>
						}
						{
							isFeatureEnabled(FeatureFlags.AcumenTeamAnalyticsPage) &&
							<Route path="/analytics">
								<Redirect to="/my-team/analytics" />
							</Route>
						}
						<Route path="/my-account" component={MyAccount} />
						{
							isFeatureEnabled(FeatureFlags.AcumenSlackNotificationsPage) &&
							<Route path="/slack-notifications" component={SlackNotificationsPage} />
						}

						{authStore.isUserAdmin && <Route path="/metric-tester" exact={true}><MetricTesterPage /></Route>}
						{authStore.isUserAdmin && <Route path="/dora-kpi" exact={true}><DoraKPIPage /></Route>}

						{authStore.isUserExecutiveOrAbove && isFeatureEnabled(FeatureFlags.AcumenOrganizationAnalyticsPage) &&
							<Route path={["/org-analytics", "/org-analytics/velocity", "/org-analytics/quality", "/org-analytics/output", "/org-analytics/dev-stats"]}>
								<OrgAnalyticsPage />
							</Route>
						}
						{authStore.isUserAdmin && <Route path="/kitchen-sink" exact={true}><KitchenSink /></Route>}

						<Route>
							<Redirect to={defaultRoute()} />
						</Route>
					</Switch>
				</div>
			</BasicLayout>
		</Loader>
	);
});
export default App;
