import apiContextProvider from "../../services/api-context-provider";
import {
	AcumenMetricGroupType,
	AcumenTaskStatus,
	AcumenTaskStatusGroup,
	AcumenTaskType,
	IDashboardAcumenMetricDataResponse,
	IDashboardAcumenMetricMetadataResponse,
	IDashboardResponse,
	IDashboardTeamsReport, MetricInterval, metricStringDateToUnixTime, taskGroupToStatusMapping, TUPLE_SEPARATOR
} from "@acumen/dashboard-common";
import { action, observable } from "mobx";
import { TeamsReportApiClient, TEAMS_REPORT_ROUTE } from "../services/crud/teams-report-api-client";
import BaseStore from "./base-store";
import {
	CategoryChartSeriesData, ChartSeriesData,
	prepareCategorySeriesDataByDate, prepareGroupedCategoryHourSeriesDataByDate, prepareGroupedCategorySeriesDataByDate
} from "../pages/my-team/analytics/charts/charts";
import { round } from "@acumen/common";
import { FetchLatestRequest } from "../../services/fetch-helpers";

const RESOLVED_ISSUE_STATUSES = taskGroupToStatusMapping[AcumenTaskStatusGroup.Done]
	.filter(status => status !== AcumenTaskStatus.Discarded);
const RESOLVED_STATUS_ISSUE_FILTER = `acumen_status_type=${RESOLVED_ISSUE_STATUSES.join(TUPLE_SEPARATOR)}`;
const DISCARDED_STATUS_ISSUE_FILTER = `acumen_status_type=${AcumenTaskStatus.Discarded}`;

export default class TeamsReportStore extends BaseStore<{}> {
	private readonly apiClient: TeamsReportApiClient = new TeamsReportApiClient(apiContextProvider);

	@observable
	private metadata: IDashboardAcumenMetricMetadataResponse | undefined = undefined;

	@observable
	public isLoadingTeamsReportData: boolean = false;
	@observable
	public teamsReportData: IDashboardTeamsReport | undefined = undefined;

	@observable
	public isLoadingTeamsPRCycleWIPMetric: boolean = false;
	@observable
	public teamsPRCycleWIPMetric: ChartSeriesData | undefined = undefined;

	@observable
	public isLoadingTeamsPRCycleAwaitingReviewMetric: boolean = false;
	@observable
	public teamsPRCycleAwaitingReviewMetric: ChartSeriesData | undefined = undefined;

	@observable
	public isLoadingTeamsPRCycleInReviewMetric: boolean = false;
	@observable
	public teamsPRCycleInReviewMetric: ChartSeriesData | undefined = undefined;

	@observable
	public isLoadingTeamsPRCycleReviewedMetric: boolean = false;
	@observable
	public teamsPRCycleMergedMetric: ChartSeriesData | undefined = undefined;

	@observable
	public isLoadingThroughputIssuesCountMetric: boolean = false;
	@observable
	public teamsThroughputIssuesCountMetric: {
		prMerge: ChartSeriesData,
		issuesDone: ChartSeriesData
	} | undefined = undefined;

	@observable
	public isLoadingMTTRByPriorityMetric: boolean = false;
	@observable
	public teamsMTTRByPriorityMetric: {
		averageLeadTime: CategoryChartSeriesData,
		bugCountByPriority: CategoryChartSeriesData,
	} | undefined = undefined;

	@observable
	public isLoadingBugsRaisedVsFixedMetric: boolean = false;
	@observable
	public teamsBugsRaisedVsFixedMetric: {
		bugsRaised: ChartSeriesData,
		bugsFixed: ChartSeriesData,
	} | undefined = undefined;

	@observable
	public isLoadingWastedWorkMetric: boolean = false;
	@observable
	public teamsWastedWorkPointMetric: {
		numberOfPRs: ChartSeriesData
	} | undefined = undefined;

	@observable
	public isLoadingAbandonedWorkMetric: boolean = false;
	@observable
	public teamsAbandonedWorkPointMetric: {
		numberOfCommit: ChartSeriesData
	} | undefined = undefined;

	@observable
	public isLoadingThroughputIssueStoryPointMetric: boolean = false;
	@observable
	public teamsThroughputIssueStoryPointMetric: {
		prMerge: ChartSeriesData,
		issuesDone: ChartSeriesData
	} | undefined = undefined;

	@observable
	public isLoadingDefectDensityMetric: boolean = false;
	@observable
	public teamsDefectDensityMetric: {
		prDefectDensity: ChartSeriesData,
		locDefectDensity: ChartSeriesData
	} | undefined = undefined;

	@observable
	public isLoadingIssuesByTypeCycleStartMetric: boolean = false;
	@observable
	public teamsIssuesByTypeCycleStartMetric: CategoryChartSeriesData | undefined = undefined;

	@observable
	public isLoadingIssuesByTypeCycleEndMetric: boolean = false;
	@observable
	public teamsIssuesByTypeCycleEndMetric: CategoryChartSeriesData | undefined = undefined;

	@observable
	public isLoadingIssuesCycleTimeByTypeMetric: boolean = false;
	@observable
	public teamsIssuesCycleTimeByTypeMetric: CategoryChartSeriesData | undefined = undefined;

	@observable
	public isLoadingDistributionClassificationData: boolean = false;
	@observable
	public distributionClassificationData: CategoryChartSeriesData | undefined = undefined;

	@observable
	public isLoadingTraceabilityOverTimeData: boolean = false;
	@observable
	public traceabilityOverTimeData: CategoryChartSeriesData | undefined = undefined;

	private loadMetadataPromise: Promise<IDashboardResponse<IDashboardAcumenMetricMetadataResponse, any> | null> | undefined = undefined;

	fetchLatestPRCount = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(TEAMS_REPORT_ROUTE + "/WastedWork-PRCount");
	fetchLatestPRWorkIntervalSum = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(TEAMS_REPORT_ROUTE +
		"/WastedWork-PRWorkInterval");
	@action.bound
	public wastedWork = async (startDate: Date, endDate: Date,
		timezone: string, interval: MetricInterval, teamIds?: string[], repositoryIds?: string[]) => {
		this.isLoadingWastedWorkMetric = true;
		const gitDimensions = [...this.createGitDimensions(teamIds, repositoryIds), "github_closed_unmerged=true"];

		const [
			prCountResponse,
		] = await Promise.all([
			this.fetchLatestPRCount.fetchLatest(this.safeFetchMetric(
				AcumenMetricGroupType.GitHubPullRequestCount, "closed",
				interval, timezone, startDate, endDate, gitDimensions))
		]);
		if (!prCountResponse || !prCountResponse.data) {
			this.isLoadingWastedWorkMetric = false;
			return undefined;
		}

		const numberOfPRs = prepareCategorySeriesDataByDate(prCountResponse.data, timezone);

		this.teamsWastedWorkPointMetric = {
			numberOfPRs
		};

		this.isLoadingWastedWorkMetric = false;
		return this.teamsWastedWorkPointMetric;
	}

	fetchLatestAvgLeadTime = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(TEAMS_REPORT_ROUTE + "/mttrByPriority-AvgLeadTime");
	fetchLatestJiraIssueCount = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(TEAMS_REPORT_ROUTE + "/mttrByPriority-IssueCount");
	@action.bound
	public mttrByPriority = async (startDate: Date, endDate: Date,
		timezone: string, interval: MetricInterval, teamIds?: string[], projectIds?: string[]) => {
		this.isLoadingMTTRByPriorityMetric = true;
		const jiraDimensions = [
			...this.createJiraDimensions(teamIds, projectIds),
			`acumen_task_type=${AcumenTaskType.Bug}`,
			`priority_order=0,1,2`,
			RESOLVED_STATUS_ISSUE_FILTER,
		];

		const [
			averageLeadTimeResponse,
			bugCountResponse
		] = await Promise.all([
			this.fetchLatestAvgLeadTime.fetchLatest(this.safeFetchMetric(
				AcumenMetricGroupType.JiraIssueAverageLeadTime, "cycle_end",
				interval, timezone, startDate, endDate, jiraDimensions, "priority_order")),
			this.fetchLatestJiraIssueCount.fetchLatest(this.safeFetchMetric(
				AcumenMetricGroupType.JiraIssueCount, "cycle_end",
				interval, timezone, startDate, endDate, jiraDimensions, "priority_order")),
		]);

		if (!averageLeadTimeResponse || !averageLeadTimeResponse.data ||
			!bugCountResponse || !bugCountResponse.data) {
			this.isLoadingMTTRByPriorityMetric = false;
			return undefined;
		}

		const averageLeadTime = prepareGroupedCategoryHourSeriesDataByDate(averageLeadTimeResponse.data, timezone);
		const bugCountByPriority = prepareGroupedCategorySeriesDataByDate(bugCountResponse.data, timezone);
		this.teamsMTTRByPriorityMetric = {
			averageLeadTime,
			bugCountByPriority
		};
		this.isLoadingMTTRByPriorityMetric = false;
		return this.teamsMTTRByPriorityMetric;
	}

	fetchLatestBugsRaised = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(TEAMS_REPORT_ROUTE + "/BugsRaisedVsFixed-BugsRaised");
	fetchLatestBugsFixed = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(TEAMS_REPORT_ROUTE + "/BugsRaisedVsFixed-BugsFixed");
	@action.bound
	public bugsRaisedVsFixed = async (startDate: Date, endDate: Date,
		timezone: string, interval: MetricInterval, teamIds?: string[], projectIds?: string[]) => {
		this.isLoadingBugsRaisedVsFixedMetric = true;
		const jiraDimensionsCreated = [
			...this.createJiraDimensions(teamIds, projectIds),
			`acumen_task_type=${AcumenTaskType.Bug}`,
		];
		const jiraDimensionsResolved = [...jiraDimensionsCreated, RESOLVED_STATUS_ISSUE_FILTER];

		const [
			bugsRaisedResponse,
			bugsFixedResponse
		] = await Promise.all([
			this.fetchLatestBugsRaised.fetchLatest(this.safeFetchMetric(
				AcumenMetricGroupType.JiraIssueCount, "created",
				interval, timezone, startDate, endDate, jiraDimensionsCreated, undefined, [DISCARDED_STATUS_ISSUE_FILTER])),
			this.fetchLatestBugsFixed.fetchLatest(this.safeFetchMetric(
				AcumenMetricGroupType.JiraIssueCount, "cycle_end",
				interval, timezone, startDate, endDate, jiraDimensionsResolved))
		]);

		if (!bugsRaisedResponse || !bugsRaisedResponse.data ||
			!bugsFixedResponse || !bugsFixedResponse.data) {
			this.isLoadingBugsRaisedVsFixedMetric = false;
			return undefined;
		}

		const bugsRaised = prepareCategorySeriesDataByDate(bugsRaisedResponse.data, timezone);
		const bugsFixed = prepareCategorySeriesDataByDate(bugsFixedResponse.data, timezone);

		this.teamsBugsRaisedVsFixedMetric = {
			bugsRaised,
			bugsFixed
		};

		this.isLoadingBugsRaisedVsFixedMetric = false;
		return this.teamsBugsRaisedVsFixedMetric;
	}

	fetchLatestGHCommitCount = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(TEAMS_REPORT_ROUTE +
		"/AbandonedWork-GHCommitCount");
	fetchLatestGHCommitWorkInterval = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(TEAMS_REPORT_ROUTE +
		"/AbandonedWork-GHCommitWork-Interval");
	@action.bound
	public abandonedWork = async (startDate: Date, endDate: Date,
		timezone: string, interval: MetricInterval, teamIds?: string[], repositoryIds?: string[]) => {
		this.isLoadingAbandonedWorkMetric = true;
		const gitDimensions = [...this.createGitDimensions(teamIds, repositoryIds), "merged_to_default_branch=false"];

		const [
			commitCountResponse,
		] = await Promise.all([
			this.fetchLatestGHCommitCount.fetchLatest(this.safeFetchMetric(
				AcumenMetricGroupType.GitHubCommitCount, "commit_authored",
				interval, timezone, startDate, endDate, gitDimensions))
		]);

		if (!commitCountResponse || !commitCountResponse.data) {
			this.isLoadingAbandonedWorkMetric = false;
			return undefined;
		}

		const numberOfCommit = prepareCategorySeriesDataByDate(commitCountResponse.data, timezone);

		this.teamsAbandonedWorkPointMetric = {
			numberOfCommit
		};

		this.isLoadingAbandonedWorkMetric = false;
		return this.teamsAbandonedWorkPointMetric;
	}

	@action.bound
	public throughputStoryPoints = async (startDate: Date, endDate: Date,
		timezone: string, interval: MetricInterval, teamIds?: string[], repositoryIds?: string[], projectIds?: string[]) => {
		this.isLoadingThroughputIssueStoryPointMetric = true;
		this.teamsThroughputIssueStoryPointMetric = await this.throughput(startDate, endDate,
			"story-points", timezone, interval, teamIds, repositoryIds, projectIds);
		this.isLoadingThroughputIssueStoryPointMetric = false;
		return this.teamsThroughputIssueStoryPointMetric;
	}

	@action.bound
	public throughputIssuesCount = async (startDate: Date, endDate: Date,
		timezone: string, interval: MetricInterval, teamIds?: string[], repositoryIds?: string[], projectIds?: string[]) => {
		this.isLoadingThroughputIssuesCountMetric = true;
		this.teamsThroughputIssuesCountMetric = await this.throughput(startDate, endDate,
			"issues", timezone, interval, teamIds, repositoryIds, projectIds);
		this.isLoadingThroughputIssuesCountMetric = false;
		return this.teamsThroughputIssuesCountMetric;
	}

	fetchLatestIssuesCycleTimeByType = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(TEAMS_REPORT_ROUTE +
		"/IssuesCycleTimeByType");
	@action.bound
	public issuesCycleTimeByType = async (startDate: Date, endDate: Date,
		timezone: string, interval: MetricInterval, teamIds?: string[], projectIds?: string[]) => {
		this.isLoadingIssuesCycleTimeByTypeMetric = true;
		const jiraDimensions = [...this.createJiraDimensions(teamIds, projectIds), RESOLVED_STATUS_ISSUE_FILTER];

		const issueByType = await this.fetchLatestIssuesCycleTimeByType.fetchLatest(this.safeFetchMetric(
			AcumenMetricGroupType.JiraIssueCycleTimeSum, "cycle_end",
			interval, timezone, startDate, endDate, jiraDimensions, "issue_type_name"));

		if (!issueByType || !issueByType.data) {
			this.isLoadingIssuesCycleTimeByTypeMetric = false;
			return undefined;
		}
		this.teamsIssuesCycleTimeByTypeMetric = prepareGroupedCategoryHourSeriesDataByDate(issueByType.data, timezone);
		this.isLoadingIssuesCycleTimeByTypeMetric = false;
		return this.teamsIssuesCycleTimeByTypeMetric;
	}

	fetchLatestTraceabilityOverTime = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(TEAMS_REPORT_ROUTE + "/TraceabilityOverTime");
	@action.bound
	public traceabilityOverTime = async (startDate: Date, endDate: Date,
		timezone: string, interval: MetricInterval, teamIds?: string[], repositoryIds?: string[]) => {
		this.isLoadingTraceabilityOverTimeData = true;

		const gitDimensions = this.createGitDimensions(teamIds, repositoryIds);

		const mergedPrs = await this.fetchLatestTraceabilityOverTime.fetchLatest(this.safeFetchMetric(
			AcumenMetricGroupType.GitHubPullRequestCount, "merged",
			interval, timezone, startDate, endDate, gitDimensions, "task_related"
		));

		if (!mergedPrs || !mergedPrs.data) {
			this.isLoadingTraceabilityOverTimeData = false;
			return undefined;
		}

		this.traceabilityOverTimeData = prepareGroupedCategorySeriesDataByDate(mergedPrs.data, timezone);

		this.isLoadingTraceabilityOverTimeData = false;

		return this.traceabilityOverTimeData;
	}

	fetchLatestDistributionClassification = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(TEAMS_REPORT_ROUTE + "/DistributionClassification");
	@action.bound
	public distributionClassification = async (startDate: Date, endDate: Date,
		timezone: string, interval: MetricInterval, teamIds?: string[], repositoryIds?: string[]) => {
		this.isLoadingDistributionClassificationData = true;

		const gitDimensions = this.createGitDimensions(teamIds, repositoryIds);

		const sumOfCommitActualEffort = await this.fetchLatestDistributionClassification.fetchLatest(this.safeFetchMetric(
			AcumenMetricGroupType.GitHubCommitWorkIntervalSum, "commit_authored",
			interval, timezone, startDate, endDate, gitDimensions, "primary_classification"
		));

		if (!sumOfCommitActualEffort || !sumOfCommitActualEffort.data) {
			this.isLoadingDistributionClassificationData = false;
			return undefined;
		}

		this.distributionClassificationData = prepareGroupedCategoryHourSeriesDataByDate(sumOfCommitActualEffort.data, timezone);
		this.isLoadingDistributionClassificationData = false;
		return this.distributionClassificationData;
	}

	fetchLatestIssueByTypeStart = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(TEAMS_REPORT_ROUTE + "/IssueByTypeStart");
	@action.bound
	public issuesByTypeCycleStart = async (startDate: Date, endDate: Date,
		timezone: string, interval: MetricInterval, teamIds?: string[], projectIds?: string[]) => {
		this.isLoadingIssuesByTypeCycleStartMetric = true;
		this.teamsIssuesByTypeCycleStartMetric = await this.issueByType(this.fetchLatestIssueByTypeStart, startDate, endDate,
			"cycle_start", timezone, interval, teamIds, projectIds, undefined, [DISCARDED_STATUS_ISSUE_FILTER]);
		this.isLoadingIssuesByTypeCycleStartMetric = false;
		return this.teamsIssuesByTypeCycleStartMetric;
	}

	fetchLatestIssueByTypeEnd = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(TEAMS_REPORT_ROUTE + "/IssueByTypeEnd");
	@action.bound
	public issuesByTypeCycleEnd = async (startDate: Date, endDate: Date,
		timezone: string, interval: MetricInterval, teamIds?: string[], projectIds?: string[]) => {
		this.isLoadingIssuesByTypeCycleEndMetric = true;
		this.teamsIssuesByTypeCycleEndMetric = await this.issueByType(this.fetchLatestIssueByTypeEnd, startDate, endDate,
			"cycle_end", timezone, interval, teamIds, projectIds, [RESOLVED_STATUS_ISSUE_FILTER]);
		this.isLoadingIssuesByTypeCycleEndMetric = false;
		return this.teamsIssuesByTypeCycleEndMetric;
	}

	fetchLatestDefectDensityIssueCount = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(TEAMS_REPORT_ROUTE +
		"/DefectDensity-IssueCount");
	fetchLatestDefectDensityPRCount = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(TEAMS_REPORT_ROUTE +
		"/DefectDensity-PRCount");
	fetchLatestDefectDensityPRCodeLines = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(TEAMS_REPORT_ROUTE +
		"/DefectDensity-PRCodeLines");
	@action.bound
	public defectDensity = async (startDate: Date, endDate: Date, timezone: string,
		interval: MetricInterval, teamIds?: string[], repositoryIds?: string[], projectIds?: string[]) => {
		this.isLoadingDefectDensityMetric = true;
		const gitDimensions = this.createGitDimensions(teamIds, repositoryIds);
		const jiraBugCountDimensions = [...this.createJiraDimensions(teamIds, projectIds), `acumen_task_type=${AcumenTaskType.Bug}`];

		const [
			issueBugCountResponse,
			prCountResponse,
			prLOCChangedResponse
		] = await Promise.all([
			this.fetchLatestDefectDensityIssueCount.fetchLatest(this.safeFetchMetric(
				AcumenMetricGroupType.JiraIssueCount, "created",
				interval, timezone, startDate, endDate, jiraBugCountDimensions, undefined, [DISCARDED_STATUS_ISSUE_FILTER])),
			this.fetchLatestDefectDensityPRCount.fetchLatest(this.safeFetchMetric(
				AcumenMetricGroupType.GitHubPullRequestCount, "merged",
				interval, timezone, startDate, endDate, gitDimensions)),
			this.fetchLatestDefectDensityPRCodeLines.fetchLatest(this.safeFetchMetric(
				AcumenMetricGroupType.GitHubPullRequestLinesOfCodeChangedSum, "merged",
				interval, timezone, startDate, endDate, gitDimensions)),
		]);

		if (!issueBugCountResponse || !issueBugCountResponse.data ||
			!prCountResponse || !prCountResponse.data ||
			!prLOCChangedResponse || !prLOCChangedResponse.data) {
			this.isLoadingDefectDensityMetric = false;
			return undefined;
		}

		const issueBugCountMetricValues = Object.values(issueBugCountResponse.data.values);
		const prCountMetricValues = Object.values(prCountResponse.data.values);
		const prLOCChangedMetricValues = Object.values(prLOCChangedResponse.data.values);
		let keys = Object.keys(issueBugCountMetricValues[0]);
		const locDefectDensity: ChartSeriesData = keys.map(valueKey => {
			const category = metricStringDateToUnixTime(valueKey, timezone);
			const bugsCountInDate = issueBugCountMetricValues[0][valueKey];
			let bugsToLOCRatio = 0;
			if (bugsCountInDate && bugsCountInDate > 0) {
				const locPRChangeInDate = prLOCChangedMetricValues[0][valueKey];
				if (locPRChangeInDate && locPRChangeInDate > 0) {
					bugsToLOCRatio = (bugsCountInDate / (locPRChangeInDate / 1000));
					bugsToLOCRatio = round(bugsToLOCRatio);
				}
			}
			return [category, bugsToLOCRatio];
		});

		keys = Object.keys(prCountMetricValues[0]);
		const prDefectDensity: ChartSeriesData = keys.map(valueKey => {
			const category = metricStringDateToUnixTime(valueKey, timezone);
			const prCount = prCountMetricValues[0][valueKey];
			let bugsToPRRatio = 0;
			if (prCount && prCount > 0) {
				const bugsCountInDate = issueBugCountMetricValues[0][valueKey];
				if (bugsCountInDate && bugsCountInDate > 0) {
					bugsToPRRatio = (bugsCountInDate / prCount);
					bugsToPRRatio = round(bugsToPRRatio);
				}
			}

			return [category, bugsToPRRatio];
		});
		this.teamsDefectDensityMetric = {
			prDefectDensity,
			locDefectDensity
		};
		this.isLoadingDefectDensityMetric = false;
		return this.teamsDefectDensityMetric;
	}

	private async issueByType(fetcher: FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>, startDate: Date, endDate: Date,
		cycle: "cycle_end" | "cycle_start", timezone: string, interval: MetricInterval, teamIds?: string[],
		projectIds?: string[], additionalDimentions?: string[], negationDimensions?: string[]): Promise<CategoryChartSeriesData | undefined> {

		const dimensions = this.createJiraDimensions(teamIds, projectIds);

		if (additionalDimentions) {
			dimensions.push(...additionalDimentions);
		}

		const issueByType = await fetcher.fetchLatest(this.safeFetchMetric(
			AcumenMetricGroupType.JiraIssueCount, cycle,
			interval, timezone, startDate, endDate, dimensions, "issue_type_name", negationDimensions));

		if (!issueByType || !issueByType.data) {
			return undefined;
		}
		return prepareGroupedCategorySeriesDataByDate(issueByType.data, timezone);
	}

	fetchLatestThroughputSP = [
		new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(TEAMS_REPORT_ROUTE),
		new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(TEAMS_REPORT_ROUTE),
		new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(TEAMS_REPORT_ROUTE)
	];
	fetchLatestThroughputIssueCount = [
		new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(TEAMS_REPORT_ROUTE),
		new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(TEAMS_REPORT_ROUTE),
		new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(TEAMS_REPORT_ROUTE)
	];

	private async throughput(startDate: Date, endDate: Date, countBy: "story-points" | "issues",
		timezone: string, interval: MetricInterval, teamIds?: string[], repositoryIds?: string[], projectIds?: string[]): Promise<{
			prMerge: ChartSeriesData,
			issuesDone: ChartSeriesData
		} | undefined> {
		const jiraDimensions = [...this.createJiraDimensions(teamIds, projectIds), RESOLVED_STATUS_ISSUE_FILTER];
		const gitDimensions = this.createGitDimensions(teamIds, repositoryIds);
		let workEstimationMetric: AcumenMetricGroupType;
		let fetchLatestArray: Array<FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>>;

		switch (countBy) {
			case "story-points":
				workEstimationMetric = AcumenMetricGroupType.JiraIssuesEstimatedEffortStoryPoints;
				fetchLatestArray = this.fetchLatestThroughputSP;
				break;
			case "issues":
				workEstimationMetric = AcumenMetricGroupType.JiraIssueCount;
				fetchLatestArray = this.fetchLatestThroughputIssueCount;
				break;
			default:
				return undefined;
		}

		const [
			workEstimationResponse,
			prCountResponse
		] = await Promise.all([
			this.safeFetchLatestThroughput(
				fetchLatestArray[0], workEstimationMetric, "cycle_end",
				interval, timezone, startDate, endDate, jiraDimensions),
			this.safeFetchLatestThroughput(
				fetchLatestArray[1], AcumenMetricGroupType.GitHubPullRequestCount, "merged",
				interval, timezone, startDate, endDate, gitDimensions)
		]);

		if (!workEstimationResponse || !workEstimationResponse.data ||
			!prCountResponse || !prCountResponse.data) {
			return undefined;
		}

		const prMerge = prepareCategorySeriesDataByDate(prCountResponse.data, timezone);
		const issuesDone = prepareCategorySeriesDataByDate(workEstimationResponse.data, timezone);

		return {
			prMerge,
			issuesDone
		};
	}

	private createJiraDimensions(teamIds?: string[], projectIds?: string[]) {
		const dimensions: string[] = [];
		if (teamIds && teamIds.length > 0) {
			dimensions.push(`team_id=${teamIds.join(TUPLE_SEPARATOR)}`);
		}
		const normalizedProjectIds = this.normalizeIdsDimension(projectIds);
		if (normalizedProjectIds && normalizedProjectIds.length > 0) {
			dimensions.push(`project_id=${normalizedProjectIds.join(TUPLE_SEPARATOR)}`);
		}
		return dimensions;
	}

	private createGitDimensions(teamIds?: string[], repositoryIds?: string[]) {
		const dimensions: string[] = [];
		if (teamIds && teamIds.length > 0) {
			dimensions.push(`team_id=${teamIds.join(TUPLE_SEPARATOR)}`);
		}
		const normalizedRepositoryIds = this.normalizeIdsDimension(repositoryIds);
		if (normalizedRepositoryIds && normalizedRepositoryIds.length > 0) {
			dimensions.push(`repository_id=${normalizedRepositoryIds.join(TUPLE_SEPARATOR)}`);
		}
		return dimensions;
	}
	private async safeFetchMetric(metric: AcumenMetricGroupType, label: string,
		interval: MetricInterval, timezone: string, startTime: Date, endTime: Date,
		dimensions?: string[], groupBy?: string, negationDimensions?: string[]) {

		let sanitizedDimensions = dimensions;
		if (dimensions && dimensions.length > 0) {
			if (!this.loadMetadataPromise) {
				this.loadMetadataPromise = this.apiClient.fetchMetadata();
			}

			const metaResponse = await this.loadMetadataPromise;
			if (!this.metadata && metaResponse?.data) {
				this.metadata = metaResponse.data;
				sanitizedDimensions = await this.cleanDimensions(this.metadata, metric, dimensions);
			}
		}

		return this.apiClient.fetchMetric(metric, label, interval,
			timezone, startTime, endTime, sanitizedDimensions, groupBy, negationDimensions);
	}

	private async safeFetchLatestThroughput(fetcher: FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>,
		metric: AcumenMetricGroupType, label: string, interval: MetricInterval, timezone: string, startTime: Date, endTime: Date,
		dimensions?: string[]) {
			return fetcher.fetchLatest(this.safeFetchMetric(metric, label, interval, timezone, startTime, endTime, dimensions));
	}

	private async cleanDimensions(metadata: IDashboardAcumenMetricMetadataResponse,
		metricType: AcumenMetricGroupType, dimensions: string[]) {
		const dimensionKeyValue = dimensions.map(i => {
			const dim = i.split("=");
			return {
				key: dim[0],
				value: dim[1]
			};
		});
		const metric = metadata[metricType];
		const supportedKeys = dimensionKeyValue.filter(i => metric.dimensions.indexOf(i.key) !== -1);
		return supportedKeys.map(i => `${i.key}=${i.value}`);
	}

	private normalizeIdsDimension(idAndTypes?: string[]): string[] | undefined {
		if (!idAndTypes || idAndTypes.length === 0) {
			return undefined;
		}

		return idAndTypes.map(idAndTypeTuple => {
			const [id] = idAndTypeTuple.split(TUPLE_SEPARATOR);
			return id;
		});
	}
}
