import { getData } from "../../../services/fetch-helpers";
import { BaseCustomerApiClient } from "./base-customer-api-client";
import { ITeamBoards, DashboardSortOrder } from "@acumen/dashboard-common";
import { qsStringify } from "../../../services/url-routes-helper";

export const BOARDS_ROUTE = "boards";

export interface IBoardSort {
	order: DashboardSortOrder;
}

export interface IBoardFilter {
	teamId?: string;
}

export class BoardsApiClient extends BaseCustomerApiClient {
	public fetchBoards = async (filter?: IBoardFilter, sort?: IBoardSort) => {
		const query: Record<string, string> = {};
		if (filter?.teamId) {
			query.teamId = filter?.teamId;
		}

		if (sort?.order) {
			query.sortOrder = sort.order;
		}

		return await getData<ITeamBoards, any>(
			this.createCustomerEntityRoute(`${BOARDS_ROUTE}?${qsStringify(query)}`),
			this.token
		);
	}
}
