import { IDashboardJIRA3LOProjectSelection } from "@acumen/dashboard-common";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Dropdown, Button, Form, Message, DropdownItemProps } from "semantic-ui-react";

const OK_PARAM_KEY_JIRA_POST_AUTH_CALLBACK = "ok";
const MESSAGE_PARAM_KEY_JIRA_POST_AUTH_CALLBACK = "msg";

interface IJira3LOSelectProjectsProps {
	listAvailableJira3LOProjects: () => Promise<IDashboardJIRA3LOProjectSelection[] | undefined>;
	updateSelectedJira3LOProjectKeys: (projectKeys: string[]) => Promise<void>;
}

// tslint:disable-next-line: variable-name
const Jira3LOSelectProjects = ((props: IJira3LOSelectProjectsProps) => {
	const searchParams = new URLSearchParams(useLocation().search);
	const ok = searchParams.get(OK_PARAM_KEY_JIRA_POST_AUTH_CALLBACK) ?? undefined;
	const msg = searchParams.get(MESSAGE_PARAM_KEY_JIRA_POST_AUTH_CALLBACK) ?? undefined;
	const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
	const [listedProjects, setListedProjects] = useState<DropdownItemProps[] | undefined>();
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const [isUpdatingSetting, setIsUpdatingSetting] = useState<boolean>(false);

	useEffect(() => {
		let isMounted = true;
		async function fetch() {
			setIsFetching(true);
			const data = await props.listAvailableJira3LOProjects();
			if (isMounted) {
				setIsFetching(false);
				const projectOptions: DropdownItemProps[] = data ?
				data.map(d => ({
					key: d.key,
					image: { avatar: true, src: d.projectAvatarUrl },
					text: d.name,
					description: `${d.key} [${d.projectType}]`,
					value: d.key
				})) : [];
				setListedProjects(projectOptions);
			}
		}

		// tslint:disable-next-line: no-floating-promises
		fetch();
		return () => { isMounted = false; };
	}, []);

	return (
		<div className="ui raised segment">
			{ok === "true" && <>
				<Form size="large">
					<Message positive={true}>
						<Message.Header>Jira 3LO integration passed successfully</Message.Header>
						<p>
							Now let's select which <b>projects</b> you'd like to sync.
						</p>
					</Message>
					<Form.Field>
						<Dropdown
							placeholder="Select Jira projects to integrate"
							fluid={true}
							multiple={true}
							search={true}
							loading={isFetching}
							options={listedProjects}
							onChange={(_e, data) => setSelectedProjects(data.value as string[])}
							value={selectedProjects}
						/>
					</Form.Field>
					<Button
						disabled={selectedProjects.length === 0}
						loading={isUpdatingSetting}
						onClick={async (e) => {
							if (selectedProjects.length === 0) {
								return;
							}
							if (isUpdatingSetting) {
								return;
							}
							e.stopPropagation();
							setIsUpdatingSetting(true);
							await props.updateSelectedJira3LOProjectKeys(selectedProjects);
							setIsUpdatingSetting(false);
						}}
						type="submit">
						{!isUpdatingSetting && "Sync selected projects"}
						{!isUpdatingSetting && <i className="arrow right icon" />}
						{isUpdatingSetting && "Updating"}
						{isUpdatingSetting && <i className="wait icon" />}
					</Button>
				</Form>
			</>}
			{(ok === "false" || !ok) && <Message negative={true}>
				<Message.Header>Jira 3LO integration failed</Message.Header>
				{msg && <p> {decodeURI(Buffer.from(msg, "base64").toString("utf-8"))}</p>}
				{!msg && <p> Unknown error. Please contact support.</p>}
			</Message>}
		</div>
	);
});
export default observer(Jira3LOSelectProjects);
