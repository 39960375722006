import React, { useContext, useMemo } from "react";
import { SortableColumn } from "../../types/sortable-column";

interface SortProviderProps<T> {
	sortedColumn: SortableColumn | null;
	onSort: (columnName: string, sortBy: (item: T) => unknown) => void;
	children?: React.ReactNode;
}

interface SortContextProps<T> {
	isSortingEnabled: boolean;
	sortedColumn: SortableColumn | null;
	sort: (columnName: string, sortBy: (item: T) => unknown) => void;
}

const SortContext = React.createContext<SortContextProps<any>>({
	isSortingEnabled: false,
	sortedColumn: null,
	sort: () => void 0,
});

export function useSortContext() {
	return useContext(SortContext);
}

/**
 * Provides sorted column (if any) and a sort function to descendants.
 * Additionally - isSortingEnabled flag is provided with "true" value to tell children that sorting is enabled.
 * (By default it is "false").
 * Child components can use {@link useSortContext} to retrieve the provided state.
 * @param props
 */
function SortProvider<T>(props: SortProviderProps<T>) {
	const { sortedColumn, onSort, children } = props;
	const sortContextValue = useMemo(() => ({
		isSortingEnabled: true,
		sortedColumn,
		sort: onSort,
	}), [sortedColumn, onSort]);

	return (
		<SortContext.Provider value={sortContextValue}>
			{children}
		</SortContext.Provider>
	);
}

export default SortProvider;
