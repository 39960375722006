import React, { useRef } from "react";
import { FilterName } from "../../mobx-stores/digest-store";
import { Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { ISelectOptionGroups, ISelectOptions, isGroupedSelectOptions } from "../../components/form/option-select";
import classNames from "classnames";
import { GA_EVENT_CATEGORY } from "../../analytics-events";

interface ISelectProps {
	multiSelectComponent: MultiSelectComponent;
	label?: string;
	name: FilterName | string;
	control?: any;
	register?: any;
	keyId?: number;
	onChange?: (val: any, fieldName: FilterName | string) => void;
	options: ISelectOptionGroups[] | ISelectOptions[];
	isClearable?: boolean;
	isLoading: boolean;
	isSingleSelect?: boolean;
	placeholder?: boolean;
	className?: string;
	defaultValue?: any;
	isOpen?: boolean;
	clickEventPage?: GA_EVENT_CATEGORY;
}

export enum MultiSelectComponent {
	SelectOptions = "SelectOptions",
	CurrentSelected = "CurrentSelected"
}

// tslint:disable-next-line: variable-name
const DrawerMultiSelectBox = (props: ISelectProps) => {
	const {
		control, keyId, options, label, onChange, name, isClearable = true, isSingleSelect = false, isLoading,
		multiSelectComponent, placeholder = false, className, defaultValue, isOpen, clickEventPage, ...rest
	} = { ...props };

	const selectRef = useRef<any>(null);

	const handleBlur = () => {
		if (selectRef && selectRef.current) {
			selectRef.current.blur();
		}
	};

	return (
		<div key={keyId} className={classNames("field", className, {options: multiSelectComponent === MultiSelectComponent.SelectOptions})}>
			{options && (
				<Controller
					{...rest}
					control={control}
					name={name}
					label={label}
					render={(option) => {
						let current: any[] | any | undefined;
						if (option && option.value) {
							if (isSingleSelect) {
								current = (options as ISelectOptions[]).find((opt: { value: any; }) => option.value === opt.value);
							} else {
								if (isGroupedSelectOptions(options)) {
									current = [];
									options.forEach((g: { options: any[]; }) => g.options.forEach(
										(a: any) => option.value?.filter((v: any) => v === a.value)
											.forEach((_f: any) => current.push(a))));
								} else {
									current = options?.filter((opt: { value: any; }) => option.value.find((v: any) => v === opt.value));
								}
							}
						}
						return (
							isOpen || multiSelectComponent === MultiSelectComponent.SelectOptions ?
								<ReactSelect
									ref={selectRef}
									defaultValue={defaultValue}
									autoFocus={true}
									isOpen={isOpen}
									placeholder={placeholder && (<div style={{ color: "#CED5DF" }}>
										<i aria-hidden="true" className="search ui icon" style={{ paddingLeft: "0.25rem" }} />
									</div>)}
									isClearable={false}
									isLoading={isLoading}
									isDisabled={isLoading}
									multiValueRemove={true}
									isMulti={!isSingleSelect}
									controlShouldRenderValue={true}
									options={options}
									value={current}
									isSearchable={multiSelectComponent === MultiSelectComponent.SelectOptions}
									selection={true}
									clearable={isClearable}
									noOptionsMessage={() => ""}
									onChange={(values: any[] | any | null) => {
										handleBlur();
										if (!onChange) {
											return;
										}
										if (isSingleSelect) {
											onChange(values.value ?? undefined, name);
										} else {
											onChange((values as any[]).map(opt => opt.value) ?? [], name);
										}
									}}
									components={multiSelectComponent === MultiSelectComponent.CurrentSelected ?
										{
											DropdownIndicator: () => null,
											IndicatorSeparator: () => null,
											Menu: () => null,
										} : {
											IndicatorSeparator: () => null,
											DropdownIndicator: () => null,
										}
									}
									menuIsOpen={multiSelectComponent === MultiSelectComponent.SelectOptions}
									theme={(theme) => ({
										...theme,
										colors: {
											...theme.colors,
											primary25: "#edf2f6",
											primary50: "#edf2f6",
										},
									})}
									styles={{
										option: (styles) => ({
											...styles,
											cursor: "pointer",
											color: "#052348"
										}),
										control: (styles) => ({
											...styles,
											cursor: "pointer",
											backgroundColor: "transparent",
											border: "0px",
											borderRadios: "0px",
											boxShadow: "none",
											padding: "10px 0px"
										}),
										menu: (styles) => ({
											...styles,
											borderRadius: "0px",
											marginTop: "0px"
										}),
										multiValue: (styles) => ({
											...styles,
											backgroundColor: "#F1F4F8",
											height: "2rem",
											maxHeight: "2rem",
											visibility: multiSelectComponent === MultiSelectComponent.SelectOptions ? "hidden" : "visible",
											margin: "5px 15px",
										}),
										multiValueLabel: (styles) => ({
											...styles,
											width: "220px",
											padding: "5px",
											fontWeight: "normal",
										}),
										menuList: (base) => ({
											...base,
											"height": "250px",
											"overflow": "auto",
											"overflowY": "auto",
											"transition": "color 0.3s",
											"color": "#ced5dc",
											"::-webkit-scrollbar::hover": {
												color: "#052348"
											},
											"::-webkit-scrollbar": {
												width: "12px"
											},
											"::-webkit-scrollbar-thumb": {
												backgroundClip: "content-box",
												border: "4px solid transparent",
												borderRadius: "7px",
												boxShadow: "inset 0 0 0 10px"
											},
											"::-webkit-scrollbar-button": {
												width: "0",
												height: "0",
												display: "none"
											},
											"::-webkit-scrollbar-corner": {
												backgroundColor: "transparent"
											},
										})
									}}
								/> : <></>
						);
					}}
				/>
			)}
		</div>
	);
};
export default DrawerMultiSelectBox;
