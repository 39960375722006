import React from "react";
import {
	DashboardRetroReportTaskData,
	IDashboardCycleTimeBreakdown,
	IDashboardTaskStatusChangeResponse,
	TaskEstimationMethod,
	SubtaskSprintStrategy
} from "@acumen/dashboard-common";
import { TaskHighlights } from "./enums";
import emptySprintTable from "../../../components/svg-assets/empty-sprint-in-table.svg";
import { TasksTable } from "./tasks-table";
import EmptySprintState from "./empty-sprint-state";

interface TasksTableContainerProps {
	isTableDisabled: boolean;
	textTypeOfTasks: string;
	tasks: DashboardRetroReportTaskData[] | undefined;
	estimationMethod?: TaskEstimationMethod;
	subtaskStrategy?: SubtaskSprintStrategy;
	statusDiff?: IDashboardTaskStatusChangeResponse;
	doneStatuses?: string[];
	className?: string;
	isRaisedRows?: boolean;
	isExtended?: boolean;
	isFilteringEnabled?: boolean;
	taskCategory?: TaskHighlights | null;
	cycleTimeBreakdown?: IDashboardCycleTimeBreakdown[] | null;
	onTaskCategoryChange?: (taskCategory: TaskHighlights | null) => void;
}

const TasksTableContainer = (props: TasksTableContainerProps) => {
	const {
		isTableDisabled,
		tasks,
		estimationMethod,
		subtaskStrategy,
		className,
		textTypeOfTasks,
		statusDiff,
		doneStatuses,
		isRaisedRows,
		isExtended,
		isFilteringEnabled,
		taskCategory,
		cycleTimeBreakdown,
		onTaskCategoryChange
	} = props;

	if (isTableDisabled) {
		return <EmptySprintState imageSrc={emptySprintTable}/>;
	}

	if (!tasks || tasks.length === 0) {
		return (
			<div className="empty-state">
				<span className="acu-capitalize">
					No {textTypeOfTasks ? textTypeOfTasks + " " : ""}tasks in sprint.
				</span>
			</div>
		);
	}

	return (
		<TasksTable
			tasks={tasks}
			estimationMethod={estimationMethod}
			subtaskStrategy={subtaskStrategy}
			className={className}
			statusDiff={statusDiff}
			doneStatuses={doneStatuses}
			isRaisedRows={isRaisedRows}
			isExtended={isExtended}
			isFilteringEnabled={isFilteringEnabled}
			taskCategory={taskCategory}
			cycleTimeBreakdown={cycleTimeBreakdown}
			onTaskCategoryChange={onTaskCategoryChange}
		/>
	);
};

export default TasksTableContainer;
