import {
	IPlanningPokerDeck,
	IPlanningPokerGame,
	IPlanningPokerIssue,
	IPlanningPokerPlayer,
	PokerGameMetricSystem,
	PokerPlanningGameState,
	PokerIssuesSortOption,
} from "@acumen/database-types";
import { qsStringify } from "services/url-routes-helper";
import { deleteData, getData, postData } from "../../../services/fetch-helpers";
import { BaseCustomerApiClient } from "./base-customer-api-client";

const MAIN_ROUTE = `/planning-poker/game`;

export type NewPlanningPokerIssue = Omit<IPlanningPokerIssue, "xid" | "estimation">;

export class GoRetroPlanningPokerApiClient extends BaseCustomerApiClient {
	public getToken = () => this.token;
	public listGames = async (goRetroTeamUuid: string | null): Promise<IPlanningPokerGame[] | null> => {

		const res =  await getData<IPlanningPokerGame[], any>(
			this.createCustomerEntityRoute(`/planning-poker/games?${qsStringify({myGamesOnly: true, goRetroTeamUuid})}`),
			this.token,
			this.tokenType
		);

		return res?.data || null;
	}

	public listDecks = async (): Promise<IPlanningPokerDeck[] | null> => {
		const res =  await getData<IPlanningPokerDeck[], any>(
			this.createCustomerEntityRoute(`/planning-poker/decks`),
			this.token,
			this.tokenType
		);

		return res?.data || null;
	}

	public createDeck = async (deckName: string, cardDisplayValues: string[]): Promise<IPlanningPokerDeck | null> => {
		const res = await postData<any, IPlanningPokerDeck, any>(
			this.createCustomerEntityRoute(`/planning-poker/deck`),
			this.token,
			{ name: deckName, cards: cardDisplayValues },
			this.tokenType
		);

		return res?.data || null;
	}

	public createGame = async (name: string, metricSystem: PokerGameMetricSystem, deckUuid: string,
		creatorPlayerName: string, rethrowError: boolean = true, goRetroTeamUuid: string| null) => {
		const res =  await postData<any, IPlanningPokerGame, any>(
			this.createCustomerEntityRoute(MAIN_ROUTE),
			this.token,
			{ name, metricSystem, deckUuid, creatorPlayerName, goRetroTeamUuid },
			this.tokenType,
			rethrowError
		);

		return res?.data || null;
	}

	public getGame = async (gameXid: string) => {
		const res =  await getData<IPlanningPokerGame, any>(
			this.createCustomerEntityRoute(`${MAIN_ROUTE}/${gameXid}`),
			this.token,
			this.tokenType
		);

		return res?.data || null;
	}

	public deleteIssues = async (gameXid: string, issues: IPlanningPokerIssue[]) =>  {
		await deleteData<{issues: string[]}, any, any>(
			this.createCustomerEntityRoute(`${MAIN_ROUTE}/${gameXid}/issue`),
			this.token,
			{issues: issues.map(x => x.xid)},
			this.tokenType
		);
	}

	public addIssues = async (gameXid: string, issuesToAdd: NewPlanningPokerIssue[]) => {
		const issues = issuesToAdd.map((i) => ({
			key: i.key,
			title: i.title,
			description: i.description ?? undefined,
			link: i.link ?? undefined,
		}));
		await postData<{issues: Array<{
			key: string,
			title: string,
			description: string | undefined,
			link: string | undefined
		}>}, any, any>(
			this.createCustomerEntityRoute(`${MAIN_ROUTE}/${gameXid}/issue`),
			this.token,
			{issues},
			this.tokenType
		);
	}

	public updateIssue = async (gameXid: string, issueId: string, update: Partial<Omit<IPlanningPokerIssue, "xid">>) =>  {
		const res = await postData<any, IPlanningPokerIssue, any>(
			this.createCustomerEntityRoute(`${MAIN_ROUTE}/${gameXid}/issue/${issueId}`),
			this.token,
			{...update},
			this.tokenType,
			true
		);

		return res ? res.data : null;
	}

	public vote = async (gameXid: string, issue: IPlanningPokerIssue, value: number, displayValue: string) =>  {
		await postData<any, any, any>(
			this.createCustomerEntityRoute(`${MAIN_ROUTE}/${gameXid}/issue/${issue.xid}/vote`),
			this.token,
			{ value, displayValue },
			this.tokenType
		);
	}

	public voteOnIssue = async (gameXid: string, issue: IPlanningPokerIssue) => {
		await postData<any, any, any>(
			this.createCustomerEntityRoute(`${MAIN_ROUTE}/${gameXid}`),
			this.token,
			{ votingOnIssueUuid: issue.xid, votingState: PokerPlanningGameState.Voting },
			this.tokenType
		);
	}

	public updateGame = async (
		gameXid: string,
		name?: string,
		metricSystem?: PokerGameMetricSystem,
		deckUuid?: string,
		votingState?: PokerPlanningGameState,
		issuesSortOption?: PokerIssuesSortOption,
	) => {
		await postData<any, any, any>(
			this.createCustomerEntityRoute(`${MAIN_ROUTE}/${gameXid}`),
			this.token,
			{ name, metricSystem, deckUuid, votingState, issuesSortOption },
			this.tokenType
		);
	}

	public updatePlayer = async (gameXid: string, playerUuid: string, name: string) => {
		await postData<any, any, any>(
			this.createCustomerEntityRoute(`${MAIN_ROUTE}/${gameXid}/player/${playerUuid}`),
			this.token,
			{ name },
			this.tokenType
		);
	}

	public joinGame = async (gameXid: string, name: string) => {
		const res = await postData<any, IPlanningPokerPlayer, any>(
			this.createCustomerEntityRoute(`${MAIN_ROUTE}/${gameXid}/player`),
			this.token,
			{ name },
			this.tokenType
		);

		return res?.data || null;
	}

	public deleteVote = async (gameXid: string, issue?: IPlanningPokerIssue, player?: IPlanningPokerPlayer) =>  {
		const qs = [];
		if (issue) {
			qs.push(`issue=${issue.xid}`);
		}

		if (player) {
			qs.push(`player=${player.xid}`);
		}

		const qsString = (qs) ? `?${qs.join("&")}` : "";
		await deleteData<any, any, any>(
			this.createCustomerEntityRoute(`${MAIN_ROUTE}/${gameXid}/votes/reset${qsString}`),
			this.token,
			undefined,
			this.tokenType
		);
	}
}
