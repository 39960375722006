import React, { useEffect, useRef, useMemo } from "react";

interface ITriggerOnClick {
	excludedClassName?: string;
	excludedElements?: HTMLElement | null;
	onTrigger: () => void;
	children: React.ReactChild | React.ReactChild[];
}

export default function TriggerOnClickOutside(props: ITriggerOnClick) {
	const { excludedClassName, excludedElements, onTrigger, children } = { ...props };
	const referenceDiv = useRef<HTMLDivElement>(null);
	const excludedClassNames = useMemo(() => excludedClassName?.split(/[ ]+/g) ?? [], [excludedClassName]);

	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			event.stopPropagation();

			if (referenceDiv.current) {
				const target = event.target as HTMLElement;
				const path = event.composedPath() as HTMLElement[];

				const clickOnSelf = referenceDiv.current.contains(target);
				let excludedArea = false;
				let clickOnParent = false;

				if (excludedClassName) {
					excludedArea = !!(path && path.find((p) => {
						return (p.className && typeof (p.className) === "string")
							? excludedClassNames.some(className => p.className.match(className))
							: false;
					}));
				}

				if (excludedElements) {
					clickOnParent = excludedElements.contains(target);
				}

				if (!clickOnSelf && !excludedArea && !clickOnParent) {
					onTrigger();
				}
			}
		}
		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [referenceDiv, onTrigger]);

	return (
		<div ref={referenceDiv}>
			{children}
		</div>
	);
}
