import { getData } from "../../../services/fetch-helpers";
import { BaseCustomerApiClient } from "./base-customer-api-client";
import { DashboardSortOrder } from "@acumen/dashboard-common";
import { qsStringify } from "../../../services/url-routes-helper";

export const LABELS_ROUTE = "labels";

export interface ILabelSort {
	order: DashboardSortOrder;
}

export interface ILabelFilter {
	teamId?: string;
}

export class LabelsApiClient extends BaseCustomerApiClient {
	public fetchLabels = async (filter?: ILabelFilter, sort?: ILabelSort) => {
		const query: Record<string, string> = {};
		if (filter?.teamId) {
			query.teamId = filter?.teamId;
		}

		if (sort?.order) {
			query.sortOrder = sort.order;
		}

		return await getData<string[], any>(
			this.createCustomerEntityRoute(`${LABELS_ROUTE}?${qsStringify(query)}`),
			this.token
		);
	}
}
