import React from "react";
import { useState } from "react";

import { IPlanningPokerPlayer } from "@acumen/dashboard-common";
import Dropdown from "./Dropdown";
import { Cog } from "./Cog";

export enum UserMenuBarItem {
	ChangeDisplayName = "ChangeDisplayName",
	Settings = "Settings"
}

export interface UserMenuBarProps {
	currentPlayer: IPlanningPokerPlayer;
	isModerator: boolean;
	onSelectedMenuItem?: (value: UserMenuBarItem) => void;
}

const UserMenuBar: React.FC<UserMenuBarProps> = ({
	currentPlayer,
	isModerator,
	onSelectedMenuItem,
}) => {
	const [toggleAvatarSelectionDropdown, setToggleAvatarSelectionDropdown] =
		useState(false);
	return (
		<Dropdown
			open={toggleAvatarSelectionDropdown}
			items={[
				{
					title: currentPlayer.name,
					description: "Change display name",
					itemKey: UserMenuBarItem.ChangeDisplayName,
				},
				{
					title: "Game settings",
					itemKey: UserMenuBarItem.Settings,
					isDisabled: !isModerator,
				}
			]}
			toggle={setToggleAvatarSelectionDropdown}
			onClickItem={(itemKey: string) => {
				if (onSelectedMenuItem) {
					onSelectedMenuItem(itemKey as UserMenuBarItem);
				}
			}}
			button={
				<div className="">
					<button
						type="button"
						onClick={(e) => {
							e.preventDefault();
							setToggleAvatarSelectionDropdown(true);
						}}
					>
						<div className="h-6 w-6 m-4">
							<Cog/>
						</div>
					</button>
				</div>
			}
		/>
	);
};

export { UserMenuBar };
