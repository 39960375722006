import { AcumenPullRequestStatus, AcumenTaskType } from "@acumen/dashboard-common";

export const acumenStatuses = {
	acumenPullRequestStatus: {
		[AcumenPullRequestStatus.AwaitingReview]: "#F49F0B",
		[AcumenPullRequestStatus.Closed]: "#B28BFF",
		[AcumenPullRequestStatus.InReview]: "#AF2387",
		[AcumenPullRequestStatus.Merged]: "#142E5A",
		[AcumenPullRequestStatus.Open] : "#170987",
		[AcumenPullRequestStatus.Reviewed]: "#B28BFF",
		[AcumenPullRequestStatus.Unknown]: "#96B5E8"
	}
};

export const colorFunction = {
	genericScheme: [
		"#C61C31",
		"#96B5E8",
		"#32B195",
		"#275AB2",
		"#608EDC",
		"#288D77",
		"#DE5AB8",
		"#86E3B1",
		"#0BB2DC",
		"#B28BFF",
		"#F9BF1E"
	],
	issueType: {
		bug: "#C61C31",
		changeRequest: "#170987",
		newFeature: "#288D77",
		task: "#275AB2",
		subTask: "#608EDC",
		epic: "#B28BFF",
		story: "#2AB56A",
		improvement: "#86E3B1",
		techTask: "#0BB2DC",
		support: "#96B5E8",
		security: "#F096A1",
		documentation: "#B7CDEF",
		productionIssue: "#E86071",
	},
	acumenTaskType: {
		[AcumenTaskType["Sub-Task"]]: "#608EDC",
		[AcumenTaskType.Bug]: "#C61C31",
		[AcumenTaskType.Epic]: "#B28BFF",
		[AcumenTaskType["Change Request"]]: "#170987",
		[AcumenTaskType.Improvement]: "#86E3B1",
		[AcumenTaskType["New Feature"]]: "#288D77",
		[AcumenTaskType.Story]: "#2AB56A",
		[AcumenTaskType.Task]: "#275AB2",
		[AcumenTaskType["Technical Task"]]: "#0BB2DC",
		[AcumenTaskType.Support]: "#96B5E8",
		[AcumenTaskType.Security]: "#F096A1",
		[AcumenTaskType.Documentation]: "#B7CDEF",
		Unmapped: "#F9BF1E"
	},
	acumenTaskTypeNew: {
		[AcumenTaskType["Sub-Task"]]: "#608EDC",
		[AcumenTaskType.Bug]: "#E86071",
		[AcumenTaskType.Epic]: "#3B24F0",
		[AcumenTaskType["Change Request"]]: "#608EDC",
		[AcumenTaskType.Improvement]: "#275AB2",
		[AcumenTaskType["New Feature"]]: "#288D77",
		[AcumenTaskType.Story]: "#32B195",
		[AcumenTaskType.Task]: "#275AB2",
		[AcumenTaskType["Technical Task"]]: "#0BB2DC",
		[AcumenTaskType.Support]: "#96B5E8",
		[AcumenTaskType.Security]: "#F3C4E6",
		[AcumenTaskType.Documentation]: "#86E3B1",
		Unmapped: "#CED5DC"
	},
	acumenNewTaskColors: {
		[AcumenTaskType["Sub-Task"]]: "#608EDC",
		[AcumenTaskType.Bug]: "#FF5630",
		[AcumenTaskType.Epic]: "#6554C0",
		[AcumenTaskType["Change Request"]]: "#608EDC",
		[AcumenTaskType.Improvement]: "#36B37E",
		[AcumenTaskType["New Feature"]]: "#288D77",
		[AcumenTaskType.Story]: "#36B37E",
		[AcumenTaskType.Task]: "#275AB2",
		[AcumenTaskType["Technical Task"]]: "#0BB2DC",
		[AcumenTaskType.Support]: "#96B5E8",
		[AcumenTaskType.Security]: "#F3C4E6",
		[AcumenTaskType.Documentation]: "#36B37E",
		Unmapped: "#CED5DC"
	},
	acumenTaskPriority: {
		0: "#E86071",
		1: "#FBD66D",
		2: "#6ADBF8",
	},
	sizeGray: {
		color: {
			s: "#EFF1F4",
			m: "#E0E4E9",
			l: "#D0D7DD",
			xl: "#C1C9D2",
			xxl: "#B1BCC7",
		},
		border: {
			s: "#9FA1A3",
			m: "#8D8F91",
			l: "#66696D",
			xl: "#53575A",
			xxl: "#404448",
		},
	},
	sizeBlue: {
		color: {
			s: "#B7CDEF",
			m: "#96B5E8",
			l: "#608EDC",
			xl: "#275AB2",
			xxl: "#142E5A",
		},
		border: {
			s: "#8898AC",
			m: "#6E829B",
			l: "#4F6A8A",
			xl: "#324B69",
			xxl: "#1B3758",
		},
	},
	sizePurple: {
		color: {
			s: "#E5D8FF",
			m: "#D4BEFF",
			l: "#B28BFF",
			xl: "#7835FF",
			xxl: "#4800D8",
		},
		border: {
			s: "#8A7C96",
			m: "#766785",
			l: "#635373",
			xl: "#513F61",
			xxl: "#3E2B50",
		},
	},
	commit: "#B28BFF",
	pr: "#608EDC",
	commitLight: "#E5D8FF",
	prLight: "#B7CDEF",
	prStatus: {
		WIP: "#170987",
		reviewedRequested: "#F49F0B",
		inReview: "#AF2387",
		pendingMerge: "#0BB2DC"
	},
	commitClassificationTypes: {
		adaptive: "#32B195",
		corrective: "#C61C31",
		perfective: "#170987",
	},
	issuePriority: {
		highest: "#C61C31",
		high: "#F49F0B",
		medium: "#FBD66D"
	},
	prTimeToMerge: {
		normal: "#32B195",
		fast: "#B28BFF",
		slow: "#96B5E8"
	},
};
export default colorFunction;
