import { action, observable } from "mobx";
import BaseStore, { IBaseStore, ILoadable } from "./base-store";
import apiContextProvider from "../../services/api-context-provider";
import { TasksApiClient, TASKS_ROUTE } from "../services/crud/tasks-api-client";
import {
	IDashboardDetailedTaskResponse,
	DashboardTaskSortOption,
	DashboardSortOrder,
	AcumenTaskStatus,
	AcumenTaskType,
	DashboardTaskStaticGroupingOption,
	DashboardTaskDynamicGroupingOption,
	DashboardTaskExpandOption,
	TaskEstimationMethod,
	DashboardProjectEntityType,
	DashboardTaskTimeContext
} from "@acumen/dashboard-common";
import { FetchLatestRequest } from "../../services/fetch-helpers";

export interface ITasksState {
	allTasks: ILoadable<IDashboardDetailedTaskResponse>;
}

interface IFindTasksOptions {
	sortField?: DashboardTaskSortOption;
	sortOrder?: DashboardSortOrder;
	filter: {
		teamId?: string;
		dataContributorIds?: string[];
		labels?: string[];
		sprints?: string[];
		projectIds?: string[];
		statuses?: AcumenTaskStatus[];
		types?: AcumenTaskType[];
		startTime?: Date;
		timeContext?: DashboardTaskTimeContext;
		endTime?: Date;
	};
	staticGroupingLimit?: number;
	staticGrouping?: DashboardTaskStaticGroupingOption;
	dynamicGroupingLimit?: number;
	dynamicGrouping?: DashboardTaskDynamicGroupingOption;
	expand?: DashboardTaskExpandOption[];
	filterTasksByCycleEnd?: boolean;
}

export interface ITasksStore extends ITasksState, IBaseStore<ITasksState> {
	fetchAllTasks: (options: IFindTasksOptions) => Promise<IDashboardDetailedTaskResponse>;
	resetAllTasks: () => void;
}

const defaults = {
	allTasks: BaseStore.initLoadable({}),
};

export default class TasksStore extends BaseStore<ITasksState> implements ITasksStore {
	private readonly apiClient: TasksApiClient = new TasksApiClient(apiContextProvider);

	@observable
	public allTasks: ILoadable<IDashboardDetailedTaskResponse> = defaults.allTasks;

	public isLoading: boolean = this.allTasks.loading;

	fetchLatestAllTasks = new FetchLatestRequest<IDashboardDetailedTaskResponse, void>(TASKS_ROUTE);
	@action.bound
	public async fetchAllTasks(options: IFindTasksOptions) {
		this.allTasks.loading = true;
		const filter = options.filter;
		const jiraProjects = filter.projectIds?.map(pID => `${pID},${DashboardProjectEntityType.JIRA}`);
		const result = await this.fetchLatestAllTasks.fetchLatest(this.apiClient.fetchTasks(filter.teamId, filter.dataContributorIds,
			filter.labels, filter.sprints, jiraProjects, filter.startTime, filter.endTime, filter.timeContext,
			filter.statuses, filter.types, options.staticGroupingLimit, options.staticGrouping,
			options.dynamicGroupingLimit, options.dynamicGrouping, options.sortField, options.sortOrder, options.expand,
			options.filterTasksByCycleEnd));

		if (result) {
			const { data } = result;

			this.allTasks = {
				data,
				metadata: null,
				loaded: true,
				loading: false
			};
			return data as IDashboardDetailedTaskResponse;
		}

		return {
			estimationMethod: TaskEstimationMethod.None,
			tasks: {},
			total: 0
		};
	}

	@action.bound
	public resetAllTasks() {
		this.allTasks = defaults.allTasks;
	}
}
