import React from "react";
import { observer } from "mobx-react";

import { DashboardRetroReportTaskData, IDashboardTaskStatusChangeResponse } from "@acumen/dashboard-common";
import { TaskStatus } from "../../../components/formatters";
import { useStores } from "../../../mobx-stores";
import { isTaskRemoved } from "../../../components/iteration-review/utils";

interface TaskEndStatusProps {
	task: DashboardRetroReportTaskData;
	isExtended: boolean;
	statusDiff?: IDashboardTaskStatusChangeResponse;
}

const TaskEndStatus = (props: TaskEndStatusProps) => {
	const { task, isExtended, statusDiff } = props;
	const { taskStatusStore, sprintRetroStore } = useStores();
	const { getStatus, statusIdToStatus } = taskStatusStore;
	const { issueIdsByCategory } = sprintRetroStore;
	const taskStatus = isExtended && statusDiff ? statusDiff[task.entityId] : undefined;
	const endStatus = taskStatus?.endStatus ? statusIdToStatus[taskStatus?.endStatus] : null;
	const isRemoved = isTaskRemoved(task, issueIdsByCategory);
	const hasTooltip = endStatus?.customerStatusName?.length ? endStatus.customerStatusName?.length > 15 : false;

	return (!getStatus || !endStatus) ? null : (
		<div className="status-container">
			<TaskStatus
				internalDashboardStatus={endStatus}
				taskStatus={endStatus.acumenStatus}
				isRemoved={isRemoved}
				hasTooltip={hasTooltip}
				showIcon={false}
			/>
		</div>
	);
};

export default observer(TaskEndStatus);
