import apiContextProvider from "../../services/api-context-provider";
import {
	AcumenMetricGroupType, AcumenTaskStatus, AcumenTaskStatusGroup, AcumenTaskType,
	DateRangeType, IChartResponse, IDashboardTeamReportMetric,
	IDashboardAcumenMetricDataResponse, MetricInterval, taskGroupToStatusMapping, TUPLE_SEPARATOR, DashboardExportFormat, SubtaskSprintStrategy
} from "@acumen/dashboard-common";
import { action, observable } from "mobx";
import { MetricOrgApiClient } from "../services/crud/metric-org-api-client";
import BaseStore from "./base-store";
import {
	CategoryChartSeriesData, ChartSeriesData,
	StackedChartData,
	prepareCategorySeriesDataByDate, prepareGroupedCategoryHourSeriesDataByDate, prepareGroupedCategorySeriesDataByDate,
	preparedStackedCategorySeriesDataSum
} from "../helpers/charts";
import { FetchLatestRequest } from "../../services/fetch-helpers";
import moment from "moment-timezone";
import { MULTI_METRICS_ROUTE } from "v2/services/crud/metric-api-client";

const METRIC_ORG_ROUTE = "metric-org";
const RESOLVED_ISSUE_STATUSES = taskGroupToStatusMapping[AcumenTaskStatusGroup.Done]
	.filter(status => status !== AcumenTaskStatus.Discarded);
const RESOLVED_STATUS_ISSUE_FILTER = `acumen_status_type=${RESOLVED_ISSUE_STATUSES.join(TUPLE_SEPARATOR)}`;

export default class MetricOrgStore extends BaseStore<{}> {
	private readonly apiClient: MetricOrgApiClient = new MetricOrgApiClient(apiContextProvider);

	@observable
	public isLoadingThroughputIssuesCountMetric: boolean = false;
	@observable
	public teamsThroughputIssuesCountMetric: {
		prMerge: ChartSeriesData,
		issuesDone: ChartSeriesData
	} | undefined = undefined;

	@observable
	public isLoadingMTTRByPriorityMetric: boolean = false;
	@observable
	public teamsMTTRByPriorityMetric: {
		averageLeadTime: CategoryChartSeriesData,
		bugCountByPriority: CategoryChartSeriesData,
	} | undefined = undefined;

	@observable
	public isLoadingThroughputIssueStoryPointMetric: boolean = false;
	@observable
	public teamsThroughputIssueStoryPointMetric: {
		prMerge: ChartSeriesData,
		issuesDone: ChartSeriesData
	} | undefined = undefined;

	@observable
	public isLoadingIssuesByTypeCycleEndMetric: boolean = false;
	@observable
	public teamsIssuesByTypeCycleEndMetric: CategoryChartSeriesData | undefined = undefined;

	@observable
	public isLoadingIssueStatusTimeSpentByTypeCycleEndMetric: boolean = false;
	@observable
	public teamsIssueStatusTimeSpentByTypeCycleEndMetric: StackedChartData | undefined = undefined;

	@observable
	public isLoadingGitHubReportedDeploymentFrequencyChartData: boolean = false;
	@observable
	public githubReportedDeploymentFrequencyChartData?: CategoryChartSeriesData;

	@observable
	public isLoadingPRCycleTimeChartData: boolean = false;
	@observable
	public prCycleTimeChartData?: IChartResponse;

	@observable
	public isLoadingPRCycleBreakdownData: boolean = false;
	@observable
	public prCycleBreakdownData: {
		prCycleWIPMetric: IDashboardTeamReportMetric,
		prCycleAwaitingReviewMetric: IDashboardTeamReportMetric,
		prCycleInReviewMetric: IDashboardTeamReportMetric,
		prCycleReviewedMetric: IDashboardTeamReportMetric,
		prCycleTotalMetric: IDashboardTeamReportMetric,
	} | undefined = undefined;

	fetchLatestPRCycleBreakdownData = new FetchLatestRequest<{
		prCycleWIPMetric: IDashboardTeamReportMetric,
		prCycleAwaitingReviewMetric: IDashboardTeamReportMetric,
		prCycleInReviewMetric: IDashboardTeamReportMetric,
		prCycleReviewedMetric: IDashboardTeamReportMetric,
		prCycleTotalMetric: IDashboardTeamReportMetric,
	}, null>(MULTI_METRICS_ROUTE + "/pr-cycle-time");
	@action.bound
	public fetchPRCycleBreakdownData = async (startDate: Date, endDate: Date,
		timezone: string, interval: MetricInterval, teamIds?: string[],
		repositoryIds?: string[], dataContributorIds?: string[],
		includeDraftPRs?: boolean, includeAggregatedPRs?: boolean, includeInternalPRs?: boolean) => {
		this.isLoadingPRCycleBreakdownData = true;

		const gitDimensions = this.createGitDimensions(
			teamIds, repositoryIds, dataContributorIds, includeDraftPRs, includeAggregatedPRs, includeInternalPRs);
		const startOfPeriod = moment.tz(startDate, timezone);
		const endOfPeriod = moment.tz(endDate, timezone);

		const daysInPeriod = Math.ceil(endOfPeriod.diff(startOfPeriod, "days", true));
		const startComparePeriod = startOfPeriod.clone().subtract(daysInPeriod, "d").startOf("day");

		const response = await this.fetchLatestPRCycleBreakdownData.fetchLatest(
			this.apiClient.fetchPRCycleBreakdown(interval, startComparePeriod.toDate(), endOfPeriod.toDate(),
			timezone, gitDimensions)
		);

		if (!response || !response.data) {
			this.isLoadingPRCycleBreakdownData = false;
			return undefined;
		}
		this.prCycleBreakdownData = response.data;
		this.isLoadingPRCycleBreakdownData = false;
		return this.prCycleBreakdownData;
	}

	@action.bound
	public async exportPRCycleChart(format: DashboardExportFormat, interval: MetricInterval, dateRange: DateRangeType,
		timezone: string, dataContributorIds: string[] = [], repositoryIds: string[] = [],
		includeDraftPRs?: boolean, includeAggregatedPRs?: boolean, includeInternalPRs?: boolean) {
		return this.apiClient.exportPRCycleChart(format, interval, dateRange, timezone,
			dataContributorIds, repositoryIds, includeDraftPRs, includeAggregatedPRs, includeInternalPRs);
	}

	fetchLatestAvgLeadTime = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(METRIC_ORG_ROUTE + "/mttrByPriority-AvgLeadTime");
	fetchLatestJiraIssueCount = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(METRIC_ORG_ROUTE + "/mttrByPriority-IssueCount");
	@action.bound
	public mttrByPriority = async (startDate: Date, endDate: Date,
		timezone: string, interval: MetricInterval, teamIds?: string[],
		projectIds?: string[], dataContributorIds?: string[]) => {
		this.isLoadingMTTRByPriorityMetric = true;

		const jiraDimensions = [
			...this.createJiraDimensions(teamIds, projectIds, dataContributorIds),
			`acumen_task_type=${AcumenTaskType.Bug}`,
			`priority_order=0,1,2`,
			RESOLVED_STATUS_ISSUE_FILTER
		];
		const [
			averageLeadTimeResponse,
			bugCountResponse
		] = await Promise.all([
			this.fetchLatestAvgLeadTime.fetchLatest(this.fetchMetric(
				AcumenMetricGroupType.JiraIssueAverageLeadTime, "cycle_end",
				interval, timezone, startDate, endDate, jiraDimensions, "priority_order")),
			this.fetchLatestJiraIssueCount.fetchLatest(this.fetchMetric(
				AcumenMetricGroupType.JiraIssueCount, "cycle_end",
				interval, timezone, startDate, endDate, jiraDimensions, "priority_order")),
		]);

		if (!averageLeadTimeResponse || !averageLeadTimeResponse.data ||
			!bugCountResponse || !bugCountResponse.data) {
			this.isLoadingMTTRByPriorityMetric = false;
			return undefined;
		}

		const averageLeadTime = prepareGroupedCategoryHourSeriesDataByDate(averageLeadTimeResponse.data, timezone);
		const bugCountByPriority = prepareGroupedCategorySeriesDataByDate(bugCountResponse.data, timezone);
		this.teamsMTTRByPriorityMetric = {
			averageLeadTime,
			bugCountByPriority
		};
		this.isLoadingMTTRByPriorityMetric = false;
		return this.teamsMTTRByPriorityMetric;
	}

	fetchLatestGitHubReportedDeploymentFrequency = new FetchLatestRequest<CategoryChartSeriesData, any>(METRIC_ORG_ROUTE + "/GitHubDeployFreq");
	@action.bound
	public async fetchGitHubReportedDeploymentFrequency(interval: MetricInterval, startTime: Date, endTime: Date,
		timezone: string, teamIds?: string[], dataContributorIds?: string[],
		gitRepositoryIds?: string[], environments?: string[]): Promise<CategoryChartSeriesData | undefined> {

		this.isLoadingGitHubReportedDeploymentFrequencyChartData = true;
		const result = await this.fetchLatestGitHubReportedDeploymentFrequency.fetchLatest(
			this.apiClient.fetchGitHubReportedDeploymentFrequency(
			interval, startTime, endTime, timezone, teamIds, dataContributorIds, gitRepositoryIds, environments));
		const chartResponse = result?.data ?? undefined;
		this.githubReportedDeploymentFrequencyChartData = chartResponse;
		this.isLoadingGitHubReportedDeploymentFrequencyChartData = false;
		return chartResponse;
	}

	fetchLatestPRCycleTime = new FetchLatestRequest<IChartResponse, any>(METRIC_ORG_ROUTE + "/PRCycleTime");
	@action.bound
	public async fetchPRCycleTime(interval: MetricInterval, dateRange: DateRangeType, timezone: string,
		teamIds?: string[], dataContributorIds?: string[], gitRepositoryIds?: string[],
		excludeDraftPrs?: boolean, baseIsDefaultBranch?: boolean, includeAggregatedPRs?: boolean)
		: Promise<IChartResponse | undefined> {

		this.isLoadingPRCycleTimeChartData = true;
		const result = await this.fetchLatestPRCycleTime.fetchLatest(this.apiClient.fetchPRCycleTime(
			interval, dateRange, timezone, teamIds, dataContributorIds, gitRepositoryIds, excludeDraftPrs,
			baseIsDefaultBranch, includeAggregatedPRs));
		const chartResponse = result?.data ?? undefined;
		this.prCycleTimeChartData = chartResponse;
		this.isLoadingPRCycleTimeChartData = false;
		return chartResponse;
	}

	@action.bound
	public throughputStoryPoints = async (startDate: Date, endDate: Date,
		timezone: string, interval: MetricInterval, teamIds?: string[], repositoryIds?: string[],
		projectIds?: string[], dataContributorIds?: string[], subtaskStrategy?: SubtaskSprintStrategy,
		excludeDraftPrs?: boolean, baseIsDefaultBranch?: boolean, includeAggregatedPRs?: boolean) => {
		this.isLoadingThroughputIssueStoryPointMetric = true;
		this.teamsThroughputIssueStoryPointMetric = await this.throughput(startDate, endDate,
			"story-points", timezone, interval, teamIds, repositoryIds, projectIds, dataContributorIds, subtaskStrategy,
			excludeDraftPrs, baseIsDefaultBranch, includeAggregatedPRs);
		this.isLoadingThroughputIssueStoryPointMetric = false;
		return this.teamsThroughputIssueStoryPointMetric;
	}

	@action.bound
	public throughputIssuesCount = async (startDate: Date, endDate: Date,
		timezone: string, interval: MetricInterval, teamIds?: string[], repositoryIds?: string[],
		projectIds?: string[], dataContributorIds?: string[], subtaskStrategy?: SubtaskSprintStrategy,
		excludeDraftPrs?: boolean, baseIsDefaultBranch?: boolean, includeAggregatedPRs?: boolean) => {
		this.isLoadingThroughputIssuesCountMetric = true;
		this.teamsThroughputIssuesCountMetric = await this.throughput(startDate, endDate,
			"issues", timezone, interval, teamIds, repositoryIds, projectIds, dataContributorIds, subtaskStrategy,
			excludeDraftPrs, baseIsDefaultBranch, includeAggregatedPRs);
		this.isLoadingThroughputIssuesCountMetric = false;
		return this.teamsThroughputIssuesCountMetric;
	}

	fetchLatestIssueByTypeEnd = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(METRIC_ORG_ROUTE + "/IssueByTypeEnd");
	@action.bound
	public issuesByTypeCycleEnd = async (startDate: Date, endDate: Date,
		timezone: string, interval: MetricInterval, teamIds?: string[], projectIds?: string[],
		dataContributorIds?: string[], subtaskStrategy?: SubtaskSprintStrategy) => {
		this.isLoadingIssuesByTypeCycleEndMetric = true;

		this.teamsIssuesByTypeCycleEndMetric = await this.issueByType(this.fetchLatestIssueByTypeEnd, startDate, endDate,
			"cycle_end", timezone, interval, teamIds, projectIds, dataContributorIds, subtaskStrategy);
		this.isLoadingIssuesByTypeCycleEndMetric = false;
		return this.teamsIssuesByTypeCycleEndMetric;
	}

	private async issueByType(fetcher: FetchLatestRequest<IDashboardAcumenMetricDataResponse,
		any>, startDate: Date, endDate: Date,
		cycle: "cycle_end" | "cycle_start", timezone: string, interval: MetricInterval, teamIds?: string[],
		projectIds?: string[], dataContributorIds?: string[], subtaskStrategy?: SubtaskSprintStrategy,
		additionalDimensions?: string[], negationDimensions?: string[]): Promise<CategoryChartSeriesData | undefined> {

		const subtaskStrategyDimensions = this.createSubtaskStrategyDimensions(subtaskStrategy);
		const jiraDimensions = [
			...this.createJiraDimensions(teamIds, projectIds, dataContributorIds),
			RESOLVED_STATUS_ISSUE_FILTER,
			...subtaskStrategyDimensions.subtaskDimensions
		];

		const issueByType = await fetcher.fetchLatest(this.fetchMetric(
			AcumenMetricGroupType.JiraIssueCycleTimeSum, cycle,
			interval, timezone, startDate, endDate, jiraDimensions, "issue_type_name", subtaskStrategyDimensions.subtaskNegationDimensions));

		if (!issueByType || !issueByType.data) {
			return undefined;
		}
		return prepareGroupedCategoryHourSeriesDataByDate(issueByType.data, timezone);
	}

	fetchLatestIssueStatusTimeSpentByType = new FetchLatestRequest<IDashboardAcumenMetricDataResponse[], any>(MULTI_METRICS_ROUTE + "/IssueStatusTimeSpentByType");
	@action.bound
	public issueStatusTimeSpentByTypeCycleEnd = async (startDate: Date, endDate: Date,
		timezone: string, interval: MetricInterval, issueStatusNames: string[],
		teamIds?: string[], projectIds?: string[], dataContributorIds?: string[]) => {

		this.isLoadingIssueStatusTimeSpentByTypeCycleEndMetric = true;

		const jiraDimensions = [
			...this.createJiraDimensions(teamIds, projectIds, dataContributorIds),
		];

		const issueStatusTimeSpentByType = await this.fetchLatestIssueStatusTimeSpentByType.fetchLatest(
			this.apiClient.fetchIssueStatusTimeSpentByType(
				interval, startDate, endDate, timezone, jiraDimensions, issueStatusNames
			));

		if (!issueStatusTimeSpentByType || !issueStatusTimeSpentByType.data) {
			return undefined;
		}

		this.teamsIssueStatusTimeSpentByTypeCycleEndMetric = preparedStackedCategorySeriesDataSum(issueStatusTimeSpentByType.data, "issue_status_name");

		this.isLoadingIssueStatusTimeSpentByTypeCycleEndMetric = false;

		return this.teamsIssueStatusTimeSpentByTypeCycleEndMetric;
	}

	fetchLatestThroughputIssues = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(METRIC_ORG_ROUTE + "/VelocityIssues");
	fetchLatestThroughputPRs = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(METRIC_ORG_ROUTE + "/VelocityIssues");

	private async throughput(startDate: Date, endDate: Date, countBy: "story-points" | "issues",
		timezone: string, interval: MetricInterval, teamIds?: string[], repositoryIds?: string[],
		projectIds?: string[], dataContributorIds?: string[], subtaskStrategy?: SubtaskSprintStrategy,
		excludeDraftPrs?: boolean, baseIsDefaultBranch?: boolean, includeAggregatedPRs?: boolean
		): Promise<{
			prMerge: ChartSeriesData,
			issuesDone: ChartSeriesData
		} | undefined> {

		const subtaskStrategyDimensions = this.createSubtaskStrategyDimensions(subtaskStrategy);
		const jiraDimensions = [
			...this.createJiraDimensions(teamIds, projectIds, dataContributorIds),
			RESOLVED_STATUS_ISSUE_FILTER,
			...subtaskStrategyDimensions.subtaskDimensions
		];
		const gitDimensions = this.createGitDimensions(
			teamIds, repositoryIds, dataContributorIds, !excludeDraftPrs, includeAggregatedPRs, !baseIsDefaultBranch);
		let workEstimationMetric: AcumenMetricGroupType;

		switch (countBy) {
			case "story-points":
				workEstimationMetric = AcumenMetricGroupType.JiraIssuesEstimatedEffortStoryPoints;
				break;
			case "issues":
				workEstimationMetric = AcumenMetricGroupType.JiraIssueCount;
				break;
			default:
				return undefined;
		}

		const [
			workEstimationResponse,
			prCountResponse
		] = await Promise.all([
			this.fetchLatestThroughputIssues.fetchLatest(this.fetchMetric(
				workEstimationMetric, "cycle_end",
				interval, timezone, startDate, endDate, jiraDimensions, undefined, subtaskStrategyDimensions.subtaskNegationDimensions)),
			this.fetchLatestThroughputPRs.fetchLatest(this.fetchMetric(
				AcumenMetricGroupType.GitHubPullRequestCount, "merged",
				interval, timezone, startDate, endDate, gitDimensions))
		]);

		if (!workEstimationResponse || !workEstimationResponse.data ||
			!prCountResponse || !prCountResponse.data) {
			return undefined;
		}

		const prMerge = prepareCategorySeriesDataByDate(prCountResponse.data, timezone);
		const issuesDone = prepareCategorySeriesDataByDate(workEstimationResponse.data, timezone);

		return {
			prMerge,
			issuesDone
		};
	}

	private createJiraDimensions(teamIds: string[] | undefined,
		projectIds: string[] | undefined, dataContributorIds: string[] | undefined) {
		const dimensions: string[] = [];
		if (teamIds && teamIds.length > 0) {
			dimensions.push(`team_id=${teamIds.join(TUPLE_SEPARATOR)}`);
		}
		const normalizedProjectIds = this.normalizeIdsDimension(projectIds);
		if (normalizedProjectIds && normalizedProjectIds.length > 0) {
			dimensions.push(`project_id=${normalizedProjectIds.join(TUPLE_SEPARATOR)}`);
		}
		if (dataContributorIds && dataContributorIds.length > 0) {
			dimensions.push(`data_contributor_id=${dataContributorIds.join(TUPLE_SEPARATOR)}`);
		}
		return dimensions;
	}

	private createGitDimensions(teamIds: string[] | undefined,
		repositoryIds: string[] | undefined, dataContributorIds: string[] | undefined,
		includeDraftPRs?: boolean, includeAggregatedPRs?: boolean, includeInternalPRs?: boolean) {
		const dimensions: string[] = [];
		if (teamIds && teamIds.length > 0) {
			dimensions.push(`team_id=${teamIds.join(TUPLE_SEPARATOR)}`);
		}
		const normalizedRepositoryIds = this.normalizeIdsDimension(repositoryIds);
		if (normalizedRepositoryIds && normalizedRepositoryIds.length > 0) {
			dimensions.push(`repository_id=${normalizedRepositoryIds.join(TUPLE_SEPARATOR)}`);
		}
		if (dataContributorIds && dataContributorIds.length > 0) {
			dimensions.push(`data_contributor_id=${dataContributorIds.join(TUPLE_SEPARATOR)}`);
		}

		// default here is to not include, including means specifying both values
		if (includeDraftPRs !== undefined) {
			dimensions.push(`is_draft=${includeDraftPRs ? `true${TUPLE_SEPARATOR}false` : "false"}`);
		}
		if (includeAggregatedPRs !== undefined && includeAggregatedPRs) {
			dimensions.push(`is_aggregated_pr=${includeAggregatedPRs ? `true${TUPLE_SEPARATOR}false` : "false"}`);
		}
		if (includeInternalPRs !== undefined) {
			dimensions.push(`base_is_default_branch=${includeInternalPRs ? `true${TUPLE_SEPARATOR}false` : "false"}`);
		}

		return dimensions;
	}

	private async fetchMetric(metric: AcumenMetricGroupType, label: string,
		interval: MetricInterval, timezone: string, startTime: Date, endTime: Date,
		dimensions?: string[], groupBy?: string, negationDimensions?: string[]) {
		return this.apiClient.fetchMetric(metric, label, interval,
			timezone, startTime, endTime, dimensions, groupBy, negationDimensions);
	}

	private normalizeIdsDimension(idAndTypes?: string[]): string[] | undefined {
		if (!idAndTypes || idAndTypes.length === 0) {
			return undefined;
		}

		return idAndTypes.map(idAndTypeTuple => {
			const [id] = idAndTypeTuple.split(TUPLE_SEPARATOR);
			return id;
		});
	}

	private createSubtaskStrategyDimensions(subtaskStrategy?: SubtaskSprintStrategy) {
		const subtaskDimensions = [];
		const subtaskNegationDimensions = [`acumen_task_type=${AcumenTaskType.Epic}`];

		if (subtaskStrategy) {
			switch (subtaskStrategy) {
				case SubtaskSprintStrategy.IGNORE_NONE:
					break;
				case SubtaskSprintStrategy.IGNORE_PARENTS:
					subtaskDimensions.push("has_subtasks=false");
					break;
				case SubtaskSprintStrategy.IGNORE_SUBTASKS:
					subtaskNegationDimensions.push(`acumen_task_type=${AcumenTaskType["Sub-Task"]}`);
					break;
			}
		}

		return { subtaskDimensions, subtaskNegationDimensions };
	}
}
