import React from "react";
import _ from "lodash";
import classNames from "classnames";
import { DashboardTeamReportMetricChangeReaction } from "@acumen/dashboard-common";
import "./trend-indicator.scss";

export interface TrendIndicatorProps {
	changeFromPreviousPeriod: number | undefined;
	reaction: DashboardTeamReportMetricChangeReaction | null | undefined;
}

export const TrendIndicator = (props: TrendIndicatorProps) => {
	const { changeFromPreviousPeriod = NaN, reaction } = props;
	const className = classNames("trend-indicator", reaction && `variant-${reaction}`);

	return (
		<div className={className}>
			{changeFromPreviousPeriod > 0 && <i className="trend-indicator-icon caret up icon"/>}
			{changeFromPreviousPeriod < 0 && <i className="trend-indicator-icon caret down icon"/>}
			<div className="trend-indicator-value">
				{_.round(changeFromPreviousPeriod * 100, 1)}%
			</div>
		</div>
	);
};
