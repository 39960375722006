import React from "react";
import { Trend } from "../../../../components/trend/trend";
import "./pr-cycle-time.scss";
import { MetricChartCard } from "../../../../components/metric-chart-card/metric-chart-card";
import { TeamPerformanceSummaryViewModel } from "../../types";
import { round } from "@acumen/common";

export interface PrCycleTimeProps {
	items: TeamPerformanceSummaryViewModel[];
	topTrend: TeamPerformanceSummaryViewModel;
}

export const PrCycleTime = (props: PrCycleTimeProps) => {
	const { items, topTrend } = props;

	return (
		<div className="pr-cycle-time">
			<div className="pr-cycle-time-header">
				<div className="pr-cycle-time-header-title-container">
					<div className="pr-cycle-time-header-title">
						PR cycle time
					</div>

					<div className="pr-cycle-time-header-trend-container">
						<Trend
							currentValue={topTrend.data[topTrend.data.length - 1]}
							previousValue={topTrend.previousValue}
							hideCurrentValue={true}
							isIncreasePositive={topTrend.isIncreasePositive}
						/>
					</div>
				</div>

				<div className="pr-cycle-time-header-sum">
					<div className="pr-cycle-time-header-sum-value">
						{round(topTrend.currentValue)}
					</div>

					<div className="pr-cycle-time-header-sum-unit">
						{topTrend.unit}
					</div>
				</div>
			</div>

			<div className="developer-stats-cards-grid">
				{items.map(item => (
					<MetricChartCard
						key={item.id}
						className="developer-stats-cards-grid-item"
						name={item.name}
						title={item.title}
						unit={item.unit}
						data={item.data}
						currentValue={item.currentValue}
						previousValue={item.previousValue}
						isIncreasePositive={item.isIncreasePositive}
						elevated={true}
					/>
				))}
			</div>
		</div>
	);
};
