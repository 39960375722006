import React from "react";
import { Modal as BSModal } from "react-bootstrap";
import { PropsWithChildren } from "react";

interface IProps {
	show: boolean;
	onHide: () => void;
	headerText?: string;
	actionButtons?: JSX.Element;
	size?: "sm" | "lg" | "xl" | undefined;
	scrollable?: boolean;
}

export default function Modal(props: PropsWithChildren<IProps>) {
	return (
		<BSModal
			show={props.show}
			onHide={props.onHide}
			size={props.size}
			backdrop={false}
			scrollable={props.scrollable}
			onClick={(e: { stopPropagation: () => void; }) => e.stopPropagation()}
		>
			<BSModal.Header closeButton={true}>
				{props.headerText}
			</BSModal.Header>

			<BSModal.Body>
				{props.children}
			</BSModal.Body>

			{props.actionButtons && (
				<BSModal.Footer>
					{props.actionButtons}
				</BSModal.Footer>
			)}
		</BSModal>
	);
}
