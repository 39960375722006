import React, { CSSProperties, useCallback, useState } from "react";
import { IClickEvent } from "../../analytics-events";
import TriggerOnClickOutside from "../../components/ux-effects/trigger-on-click-outside";
import Select, { components, Theme, ValueType } from "react-select";

export interface IDropdownOption {
	key: string | number;
	value: string | number;
	label?: string;
	isDisabled?: boolean;
}

export interface IDropdownListSelectorProps {
	onChange: (opt: (any | ValueType<IDropdownOption, false>)) => void;
	options: IDropdownOption[];
	values?: string[];
	name?: string;
	title?: string;
	placeholder?: string;
	isLoading?: boolean;
	clickEvent?: IClickEvent;
	isDisabled?: boolean;
	customColors?: Partial<Theme>;
	customSize?: CSSProperties;
	isMulti?: boolean;
}

// tslint:disable-next-line: variable-name
const DropdownListSelector = (props: IDropdownListSelectorProps) => {
	const {
		options, values, onChange, name, placeholder, isLoading, title,
		isDisabled, clickEvent, customColors, customSize, isMulti
	} = { ...props };

	const [isOpen, setOpen] = useState<boolean>(false);
	const close = useCallback(() => setOpen(false), []);

	function formatOptionLabel({ label, value }: any) {
		return (
			<div className={"flex-row"}>
				<div
					className={"ui checkbox"}
				>
					<input
						type="checkbox"
						name={name}
						checked={values !== undefined &&
							Array.isArray(values) &&
							values.find(val => val === value) ?
							true : false}
						onChange={(e) => {
							e.preventDefault();
							e.stopPropagation();
						}}
					/>
					<label>{label}</label>
				</div>
			</div>
		);
	}

	return (
		<TriggerOnClickOutside onTrigger={close}>
			<div className="ui section control control-width small dropdown-list left-align"
				{...clickEvent}
			>
				<Select
					name={name}
					menuIsOpen={isOpen}
					isLoading={isLoading}
					classNamePrefix="ui section"
					isMulti={!!isMulti}
					isDisabled={isDisabled}
					closeOnBlur={true}
					onMenuOpen={() => { setOpen(true); }}
					onMenuClose={() => { setOpen(false); }}
					closeMenuOnSelect={false}
					options={options}
					onChange={onChange}
					placeholder={placeholder}
					styles={{
						control: (base) => ({
							...base,
							...customSize
						})
					}}
					theme={(theme) => {
						return ({
							...theme,
							...customColors,
							colors: {
								...theme.colors,
								...customColors?.colors,
							}
						});
					}}
					hideSelectedOptions={false}
					formatOptionLabel={(opt) => formatOptionLabel(opt)}
					value={options.filter(o => values && (o.value !== undefined) && values.indexOf(`${o.value}`) > -1)}
					components={{
						ValueContainer: (selectProps: any): JSX.Element => {
							const selected = selectProps.getValue();
							const numberOfSelected: number | undefined = selected?.length || undefined;
							if (!numberOfSelected || numberOfSelected === 0) {
								return (
									<components.ValueContainer {...selectProps}>
										{selectProps.children}
									</components.ValueContainer>
								);
							}
							return (
								<components.ValueContainer {...selectProps} className="dropdown-list-value-container">
									{title}
									<span className={"counter purple-text"}>
										{numberOfSelected}
									</span>
								</components.ValueContainer>
							);
						},
					}}
				/>
			</div>
		</TriggerOnClickOutside>
	);
};

export default DropdownListSelector;
