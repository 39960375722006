import { CommonEndPoints, IDashboardTeamMember } from "@acumen/dashboard-common";
import { APIContextProvider } from "../../../services/api-context-provider";
import { TEAMS_ROUTE } from "./teams-api-client";
import urlJoin from "url-join";
import { IdBasedCrudApiClient } from "./id-based-crud-api-client";
import { getData, postData } from "../../../services/fetch-helpers";
import { ICreateMembersData, IDeleteMembersData } from "../../mobx-stores/team-members-store";

export const MEMBERS_SUB_ROUTE = "members";

export interface ITeamMembersRootParams {
	teamId: string;
}

export interface IDeleteFindParams extends ITeamMembersRootParams {
	id: string;
}

export class TeamMembersApiClient
	extends IdBasedCrudApiClient<IDashboardTeamMember, ITeamMembersRootParams> {
	constructor(apiContextProvider: APIContextProvider) {
		super(TEAMS_ROUTE, apiContextProvider);
	}

	protected createBasePathFromRootParams = (rootParams: ITeamMembersRootParams) => {
		return urlJoin(this.routeBasePath, rootParams.teamId, MEMBERS_SUB_ROUTE);
	}

	protected convertFindParamsToPath = (findParams: string | IDeleteFindParams) => {
		if (typeof findParams === "string") {
			return findParams;
		}

		return findParams.id;
	}

	public fetchAllTeamMembers = async () => {
		return await getData<IDashboardTeamMember[], any>(
			this._createRoutePath[CommonEndPoints.FIND_ALL](urlJoin(this.routeBasePath, MEMBERS_SUB_ROUTE)),
			this.token
		);
	}

	public createMultiple = async (partialEntities: ICreateMembersData, rootParams: ITeamMembersRootParams) => {
		return await postData<ICreateMembersData, IDashboardTeamMember[], any>(
			this._createRoutePath[CommonEndPoints.CREATE](this.createBasePathFromRootParams(rootParams)),
			this.token,
			partialEntities,
			this.tokenType
		);
	}

	public deleteMultiple = async (ids: IDeleteMembersData, rootParams: ITeamMembersRootParams) => {
		return await postData<IDeleteMembersData, IDashboardTeamMember[], any>(
			this._createRoutePath[CommonEndPoints.DELETE](this.createBasePathFromRootParams(rootParams)),
			this.token,
			ids,
			this.tokenType
		);
	}
}
