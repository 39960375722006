import React, { useEffect, useState } from "react";
import "./style.scss";

export enum DayOfWeek {
	Sunday = "Sunday",
	Monday = "Monday",
	Tuesday = "Tuesday",
	Wednesday = "Wednesday",
	Thursday = "Thursday",
	Friday = "Friday",
	Saturday = "Saturday"
}

export const DAY_OF_WEEK_OPTIONS_TO_LABEL = {
	[DayOfWeek.Sunday]: "Sun",
	[DayOfWeek.Monday]: "Mon",
	[DayOfWeek.Tuesday]: "Tue",
	[DayOfWeek.Wednesday]: "Wed",
	[DayOfWeek.Thursday]: "Thu",
	[DayOfWeek.Friday]: "Fri",
	[DayOfWeek.Saturday]: "Sat"
};

export const DAY_OF_WEEK_OPTIONS_TO_CRON_VALUE = {
	[DayOfWeek.Sunday]: 0,
	[DayOfWeek.Monday]: 1,
	[DayOfWeek.Tuesday]: 2,
	[DayOfWeek.Wednesday]: 3,
	[DayOfWeek.Thursday]: 4,
	[DayOfWeek.Friday]: 5,
	[DayOfWeek.Saturday]: 6
};

const DAY_OF_WEEK_OPTIONS = [
	{key: DayOfWeek.Sunday, value: DAY_OF_WEEK_OPTIONS_TO_CRON_VALUE[DayOfWeek.Sunday], text: DAY_OF_WEEK_OPTIONS_TO_LABEL[DayOfWeek.Sunday]},
	{key: DayOfWeek.Monday, value: DAY_OF_WEEK_OPTIONS_TO_CRON_VALUE[DayOfWeek.Monday], text: DAY_OF_WEEK_OPTIONS_TO_LABEL[DayOfWeek.Monday]},
	{key: DayOfWeek.Tuesday, value: DAY_OF_WEEK_OPTIONS_TO_CRON_VALUE[DayOfWeek.Tuesday], text: DAY_OF_WEEK_OPTIONS_TO_LABEL[DayOfWeek.Tuesday]},
	{key: DayOfWeek.Wednesday, value: DAY_OF_WEEK_OPTIONS_TO_CRON_VALUE[DayOfWeek.Wednesday], text: DAY_OF_WEEK_OPTIONS_TO_LABEL[DayOfWeek.Wednesday]},
	{key: DayOfWeek.Thursday, value: DAY_OF_WEEK_OPTIONS_TO_CRON_VALUE[DayOfWeek.Thursday], text: DAY_OF_WEEK_OPTIONS_TO_LABEL[DayOfWeek.Thursday]},
	{key: DayOfWeek.Friday, value: DAY_OF_WEEK_OPTIONS_TO_CRON_VALUE[DayOfWeek.Friday], text: DAY_OF_WEEK_OPTIONS_TO_LABEL[DayOfWeek.Friday]},
	{key: DayOfWeek.Saturday, value: DAY_OF_WEEK_OPTIONS_TO_CRON_VALUE[DayOfWeek.Saturday], text: DAY_OF_WEEK_OPTIONS_TO_LABEL[DayOfWeek.Saturday]},
];

interface ISelectDay {
	label?: string;
	selected?: number[];
	onChange: (selected: number[]) => void;
	error?: boolean;
}

// tslint:disable-next-line: variable-name
export const SelectDay = ({label, selected, onChange, error}: ISelectDay) => {
	const [selectedDays, setSelectedDays] = useState(selected || []);

	useEffect(() => {
		setSelectedDays(selected || []);
	}, [selected, setSelectedDays]);

	const handleSelect = (day: number) => {
		const updatedState = [...selectedDays];
		const index = updatedState.indexOf(day);

		if (updatedState.includes(day)) {
			updatedState.splice(index, 1);
		} else {
			updatedState.push(day);
		}

		onChange(updatedState);
		setSelectedDays(updatedState);
	};

	return <div className="select-day">
		{label && <div className="select-day-label">{label}</div>}
		<div className="select-day-input-container">
			{DAY_OF_WEEK_OPTIONS.map(option => {
				const isSelected = selectedDays.includes(option.value);

				return (
					<div
						className={`select-day-input-options ${isSelected ? "--selected" : ""} ${error ? "--error" : ""}`}
						key={option.key}
						onClick={()=> {
							handleSelect(option.value);
						}}
					>{option.text}</div>
				);
			})}
		</div>
	</div>;
};

export default SelectDay;
