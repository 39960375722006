import { IDashboardTask, IDashboardTaskDetailed, TaskEstimationMethod } from "@acumen/dashboard-common";
import PieChart from "../../components/graphs/pie-chart";
import React from "react";
import { PopupContentProps, SemanticShorthandItem } from "semantic-ui-react";
import { Formatters } from "./formatters";
import { round } from "@acumen/common";

export const taskVelocityGraph = (task: IDashboardTask, estimationValue: string | number | null, estimationMethod: TaskEstimationMethod,
		percentileRank: number | null | undefined, totalCommitCalculatedWorkSeconds: number | null) => {
	let color: string | undefined;
	let tooltip: SemanticShorthandItem<PopupContentProps> | undefined;

	if (percentileRank) {
		const inversePercentile = round((1 - percentileRank) * 100);
		const givenEstimateText = (estimationValue === null) ? "similar tasks" : `similar tasks (${Formatters.estimationValue(estimationValue, estimationMethod)})`;

		if (percentileRank <= 0.05) {
			color = "green";
			tooltip = (<><b>{task.publicIdentifier}</b> completed faster than other {givenEstimateText}</>);
		} else if (percentileRank > 0.05 && percentileRank <= 0.3) {
			color = "green";
			tooltip = (<><b>{task.publicIdentifier}</b> completed faster than <b>{inversePercentile}%</b> of other {givenEstimateText}</>);
		} else if (percentileRank > 0.3 && percentileRank < 0.5) {
			color = "blue";
			tooltip = (<><b>{task.publicIdentifier}</b> completed mostly as expected, faster than <b>{inversePercentile}%</b> of other {givenEstimateText}</>);
		} else if (percentileRank >= 0.5 && percentileRank <= 0.8) {
			color = "blue";
			tooltip = (<><b>{task.publicIdentifier}</b> completed in line with given estimate. Faster than <b>{inversePercentile}%</b> of other {givenEstimateText}</>);
		} else if (percentileRank > 0.8 && percentileRank < 0.98) {
			const percentile = round(percentileRank * 100);
			color = "orange";
			tooltip = (<><b>{task.publicIdentifier}</b> velocity was slower than <b>{percentile}%</b> of other {givenEstimateText}</>);
		} else if (percentileRank >= 0.98) {
			color = "orange";
			tooltip = (<><b>{task.publicIdentifier}</b> velocity was slower than nearly all other {givenEstimateText}</>);
		}
	} else {
		tooltip = getMissingEstimationTooltip("velocity", task, estimationValue, totalCommitCalculatedWorkSeconds, estimationMethod);
	}

	if (!color) {
		color = "gray";
	}
	if (!tooltip) {
		tooltip = (<>Cannot estimate velocity for <b>{task.publicIdentifier}</b> - insufficient data</>);
	}

	return (
		<PieChart
			percentileRank={percentileRank ?? 0}
			color={color}
			tooltip={tooltip}
		/>
	);
};

function getMissingEstimationTooltip(action: string, task: IDashboardTask, estimationValue: string | number | null | undefined,
	totalCommitCalculatedWorkSeconds: number | null, estimationMethod?: TaskEstimationMethod) {
	let tooltip: SemanticShorthandItem<PopupContentProps> | undefined;

	if (totalCommitCalculatedWorkSeconds === null || totalCommitCalculatedWorkSeconds === 0) {
		tooltip = (<>Cannot estimate {action} as <b>{task.publicIdentifier}</b> has no recorded Git commits</>);
	} else if (estimationMethod) {
		switch (estimationMethod) {
			case TaskEstimationMethod.StoryPoints: {
				if (estimationValue === null) {
					tooltip = (<>Cannot estimate {action} for <b>{task.publicIdentifier}</b>, no story points entered</>);
				} else if (estimationValue === 0) {
					tooltip = (<>Cannot estimate {action} for <b>{task.publicIdentifier}</b>, task's story points are set to <b>0</b></>);
				}
				break;
			}
			case TaskEstimationMethod.TShirtSize: {
				if (estimationValue === null) {
					tooltip = (<>Cannot estimate {action} for <b>{task.publicIdentifier}</b>, task has no T shirt size estimate</>);
				}
				break;
			}
			case TaskEstimationMethod.TimeBased: {
				if (estimationValue === null) {
					tooltip = (<>Cannot estimate {action} for <b>{task.publicIdentifier}</b>, task has no time estimate</>);
				} else if (estimationValue === 0) {
					tooltip = (<>Cannot estimate {action} for <b>{task.publicIdentifier}</b>, time estimate is set to <b>0</b></>);
				}
				break;
			}
			default:
		}
	}
	return tooltip;
}

export function getEstimationValue(task: IDashboardTaskDetailed, estimationMethod: TaskEstimationMethod) {
	switch (estimationMethod) {
		case TaskEstimationMethod.StoryPoints:
			return task.storyPoints;
		case TaskEstimationMethod.TShirtSize:
			return task.tshirtSizeName;
		case TaskEstimationMethod.TimeBased:
			return task.timeEstimateSeconds;
	}

	return null;
}
