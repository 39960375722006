import React, { useCallback, useMemo, useRef } from "react";
import classNames from "classnames";
import UserAvatarItem from "./user-avatar-item";
import UsersDropdown from "./users-dropdown/users-dropdown";
import { FilterOptionModel } from "../../filter-dropdown/types";
import { areSelectionsEqual } from "../../../../utils/checkers";
import { UserOption } from "./types";

interface UsersSelectorProps {
	options: UserOption[];
	committedContributorIds: Set<string> | null;
	selectedContributorIds: Set<string> | null;
	maxUsersOnView?: number;
	showArrow?: boolean;
	multiselect?: boolean;
	className?: string;
	disabled?: boolean;
	actionButtons?: React.ReactNode;
	itemsBeforeUsers?: React.ReactNode;
	footer?: React.ReactNode;
	onToggleContributorFilter?: (index: number) => void;
	onToggleContributor: (id: string) => void;
	onCommitContributorsSelection: (selection: Set<string> | null) => void;
	onHide?: () => void;
}

const DEFAULT_MAX_USERS_ON_VIEW = 7;

const UsersSelector = (props: UsersSelectorProps) => {
	const {
		options,
		committedContributorIds,
		selectedContributorIds,
		maxUsersOnView,
		showArrow = false,
		multiselect = true,
		className,
		disabled = false,
		actionButtons,
		itemsBeforeUsers,
		footer,
		onToggleContributor,
		onCommitContributorsSelection,
		onHide,
	} = props;
	const resolvedMaxUsersOnView = maxUsersOnView ? maxUsersOnView : DEFAULT_MAX_USERS_ON_VIEW;
	const numOfExtraUsers = options.length - resolvedMaxUsersOnView;
	const usersOnView = useMemo(
		() => numOfExtraUsers >= 1 ? options.slice(0, resolvedMaxUsersOnView) : options,
		[options, numOfExtraUsers, resolvedMaxUsersOnView]
	);
	const dropdownOptions = useMemo(
		() => numOfExtraUsers >= 1 ? options.slice(resolvedMaxUsersOnView) : [],
		[options, numOfExtraUsers]
	);

	const handleCommitSelectedContributors = useContributorsSelectionCommitHandler({
		selectedContributorIds,
		multiselect,
		onCommitContributorsSelection,
	});
	const handleToggleOnView = useToggleOnViewHandler({
		selectedContributorIds,
		onToggle: onToggleContributor,
		onCommitSelection: handleCommitSelectedContributors
	});
	const handleHide = useCallback(() => {
		handleCommitSelectedContributors(selectedContributorIds);
		onHide?.();
	}, [selectedContributorIds, handleCommitSelectedContributors, onHide]);

	return (
		<div className={classNames("ui section", "avatar-selector", className)}>
			{usersOnView?.map(option => (
				<UserAvatarItem
					key={option.id}
					option={option}
					isSelected={!!selectedContributorIds?.has(option.id)}
					disabled={disabled}
					onToggle={handleToggleOnView}
				/>
			))}

			{!!dropdownOptions?.length && (
				<UsersDropdown
					options={dropdownOptions}
					showArrow={showArrow}
					committedContributorIds={committedContributorIds}
					selectedContributorIds={selectedContributorIds}
					actionButtons={actionButtons}
					itemsBeforeUsers={itemsBeforeUsers}
					footer={footer}
					onToggleContributor={onToggleContributor}
					onHide={handleHide}
				/>
			)}
		</div>
	);
};

export const mapUserToFilterOption = (user: UserOption): FilterOptionModel<string> => ({
	value: user.id,
	label: user.displayName,
	searchValue: user.displayName,
});

const useContributorsSelectionCommitHandler = (props: {
	selectedContributorIds: Set<string> | null;
	multiselect: boolean;
	onCommitContributorsSelection: (selection: Set<string> | null) => void;
}) => {
	const { selectedContributorIds, multiselect, onCommitContributorsSelection } = props;
	const lastSelectionRef = useRef(selectedContributorIds);

	return useCallback((newSelection: Set<string> | null) => {
		if (areSelectionsEqual(lastSelectionRef.current, newSelection)) {
			return;
		}

		lastSelectionRef.current = newSelection;

		if (multiselect || newSelection === null) {
			onCommitContributorsSelection(newSelection);
		} else {
			const selectionArray = Array.from(newSelection);
			onCommitContributorsSelection(selectionArray.length > 0 ? new Set(selectionArray[0]) : new Set());
		}
	}, [multiselect, onCommitContributorsSelection]);
};

const useToggleOnViewHandler = (props: {
	selectedContributorIds: Set<string> | null;
	onToggle: (id: string) => void;
	onCommitSelection: (selection: Set<string> | null) => void;
}) => {
	const { selectedContributorIds, onToggle, onCommitSelection } = props;

	return useCallback((id: string) => {
		const newSelectedContributorIds = new Set(selectedContributorIds);

		if (!newSelectedContributorIds.has(id)) {
			newSelectedContributorIds.add(id);
		} else {
			newSelectedContributorIds.delete(id);
		}

		onCommitSelection(newSelectedContributorIds);
		onToggle(id);
	}, [selectedContributorIds, onToggle, onCommitSelection]);
};

export default UsersSelector;
