import React, { useCallback, useMemo } from "react";
import "../style.scss";
import UsersSelector, { mapUserToFilterOption } from "../filters/users-selector/users-selector";
import { useSelectionState } from "../filter-dropdown/hooks";
import { ResolvedFilterOption } from "../filter-dropdown/types";
import { resolveFilterOption } from "../filter-dropdown/utils";
import { UserOption } from "../filters/users-selector/types";

export interface TopNavbarUserSelectorProps {
	usersList: UserOption[];
	singleUser?: boolean;
	onUsersSelected: (selectedUsers: string[]) => void;
	initialSelectedUsers: Array<UserOption["id"]>;
	maxUsersOnView?: number;
	disabled?: boolean;
	className?: string;
	showArrow?: boolean;
	isAllSelectedByDefault?: boolean;
}

// tslint:disable-next-line: variable-name
const TopNavbarUserSelector = (props: TopNavbarUserSelectorProps) => {
	const { usersList, singleUser, initialSelectedUsers, maxUsersOnView, disabled, className } = props;
	const { showArrow, isAllSelectedByDefault = false, onUsersSelected } = props;
	const selectedContributorIdsSet = useMemo(() => initialSelectedUsers.length > 0 ? new Set(initialSelectedUsers) : null, [initialSelectedUsers]);
	const userOptions = useMemo(
		() => usersList
			.map(mapUserToFilterOption)
			.map<ResolvedFilterOption<string>>(resolveFilterOption),
		[usersList]
	);
	const { selection: selectedContributorIds, toggle } = useSelectionState<string>({
		options: userOptions,
		selection: selectedContributorIdsSet,
		isAllSelectedByDefault,
	});
	const handleCommitSelection = useCallback(
		(selection: Set<string> | null) => onUsersSelected(selection ? Array.from(selection) : []),
		[onUsersSelected]
	);

	return (
		<UsersSelector
			options={usersList}
			committedContributorIds={selectedContributorIdsSet}
			selectedContributorIds={selectedContributorIds}
			maxUsersOnView={maxUsersOnView}
			showArrow={showArrow}
			multiselect={!singleUser}
			className={className}
			disabled={disabled}
			onToggleContributor={toggle}
			onCommitContributorsSelection={handleCommitSelection}
		/>
	);
};

export default TopNavbarUserSelector;
