import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../mobx-stores";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { clickEvent, GA_EVENT_ACTION, GA_EVENT_CATEGORY } from "../../analytics-events";
import _ from "lodash";
import DropdownListSelector from "../../components/form/dropdown-list-selector";
import Header from "../my-account/header";
import VelocityBoard from "./velocity-board";
import OutputBoard from "./output-board";
import QualityBoard from "./quality-board";
import DeveloperStatsTable from "./dev-stats-board";
import LightNavBar from "../../components/filters-top-navbar/filters-navbar";
import {
	DashboardSortOrder,	DateRangeType,
	getDatesFromTimeSpan,
	MetricInterval, TimeUnitMetricInterval
} from "@acumen/dashboard-common";
import useLocalStorage from "../../hooks/useLocalStorage";
import "./style.scss";
import { ISelectOptionBase } from "../../components/form/option-select";
import { IFilterOptions } from "../../mobx-stores/new-teams-report-store";
import {
	IDropdownOption,
	TIME_INTERVAL_OPTIONS_TO_LABEL,
	TimeFramesSelector
} from "../../components/filters-selector/sections/time-frames-selector";
import FiltersSelector from "../../components/filters-selector/filters-selector";
import filtersSlice from "./filters-slice";
import EmptyState from "../empty-state";
import { IChartScope } from "./charts/chart-scope";

const TEAMS_REPORT_DATE_RANGE_FILTER_LOCAL_STORAGE_KEY = "teams_report_date_filters";
const TEAMS_REPORT_DATE_OPTIONS_FILTER_LOCAL_STORAGE_KEY = "teams_report_date_option_filters";
const TEAMS_REPORT_TEAMS_FILTER_LOCAL_STORAGE_KEY = "teams_report_teams_filters";
const TEAMS_REPORT_SEARCH_FILTER_LOCAL_STORAGE_KEY = "teams_report_search_filters";
export const DEFAULT_COLOR = "#0f2345";
export const CHART_ROW_SIZE = {
	mainChartHeight: 400,
	summaryPieChartHeight: 198,
};

const DEFAULT_FILTERS: IFilterOptions = {
	gitRepositoryIds: [],
	projectIds: [],
};

const metricIntervalTypes: TimeUnitMetricInterval[] = [
	MetricInterval.DAY, MetricInterval.WEEK, MetricInterval.MONTH
];

const INTERVAL_DATE_OPTIONS: IDropdownOption[] =
	Object.values(metricIntervalTypes).map((v: TimeUnitMetricInterval) => {
		return {
			key: v,
			value: v,
			label: TIME_INTERVAL_OPTIONS_TO_LABEL[v],
			isDisabled: false,
		};
	});

const monthlyInterval = INTERVAL_DATE_OPTIONS.find((opt) => opt.key === MetricInterval.MONTH);

// tslint:disable-next-line: variable-name
const TeamsReport = () => {
	const {
		teamsStore: {
			fetchAllTeams,
			allTeams
		},
		projectsStore: {
			projectOptions,
			fetchData: fetchProjectsData,
			isLoading: isLoadingProjects
		},
		repositoriesStore: {
			fetchData: fetchGitRepoData,
			isLoading: isLoadingGitRepos,
			gitRepositoriesOptions
		},
		integrationsStore: {
			hasActiveIntegrations,
			setIntegrationType
		},
		authStore
	} = useStores();

	const match = useRouteMatch();

	const [searchFilters, setSearchFilters] =
		useLocalStorage<IFilterOptions>(TEAMS_REPORT_SEARCH_FILTER_LOCAL_STORAGE_KEY, DEFAULT_FILTERS);

	const [selectedTeamIds, setSelectedTeamsIds] = useLocalStorage<string[]>(TEAMS_REPORT_TEAMS_FILTER_LOCAL_STORAGE_KEY, []);
	const [selectedDateRangeStorage, setSelectedDateRangeStorage] = useLocalStorage<DateRangeType | undefined>(TEAMS_REPORT_DATE_RANGE_FILTER_LOCAL_STORAGE_KEY, DateRangeType.LastMonth);
	const [selectedDateRange, setSelectedDateRange] = useState<DateRangeType | undefined>(DateRangeType.LastMonth);
	const [selectedInterval, setSelectedInterval] = useLocalStorage<TimeUnitMetricInterval>(TEAMS_REPORT_DATE_OPTIONS_FILTER_LOCAL_STORAGE_KEY, MetricInterval.WEEK);
	const [chartScope, setChartScope] = useState<IChartScope | undefined>();
	const timezone = authStore.authUser.timezone;

	useEffect(() => {
		if (selectedDateRangeStorage) {
			setSelectedDateRange(selectedDateRangeStorage);
		}
	}, []);

	const {
		filtersDropdownDisplayName,
		dateRangeDisplayName
	} =
		filtersSlice({
			selectedDateRange,
			selectedInterval,
			timezone
		});

	useEffect(() => {
		async function fetchFiltersOptions() {
			// tslint:disable-next-line: no-floating-promises
			fetchGitRepoData({}, { order: DashboardSortOrder.Ascending });
			// tslint:disable-next-line: no-floating-promises
			fetchProjectsData({}, { order: DashboardSortOrder.Ascending });
			// tslint:disable-next-line: no-floating-promises
			return fetchAllTeams();
		}
		// tslint:disable-next-line: no-floating-promises
		fetchFiltersOptions();
	}, []);

	useEffect(() => {
		if (setDisableMonthlyInterval() && selectedInterval === MetricInterval.MONTH) {
			setSelectedInterval(MetricInterval.WEEK);
		}
	}, [selectedDateRange]);

	const setDisableMonthlyInterval = (): boolean => {
		if (monthlyInterval) {
			if (selectedDateRange === DateRangeType.LastWeek || selectedDateRange === DateRangeType.Last2Weeks) {
				monthlyInterval.isDisabled = true;
				return true;
			}
			monthlyInterval.isDisabled = false;
		}
		return false;
	};

	useEffect(() => {
		if (allTeams.data.length === 0) {
			return;
		}
		if (!selectedDateRange) {
			return;
		}

		const { endTime, startTime } = getDatesFromTimeSpan(selectedDateRange, timezone, selectedInterval);
		const scope =  {
			startTime,
			endTime,
			timezone,
			interval: selectedInterval,
			teamIds: selectedTeamIds.length === 0 ? allTeams.data.map(t => t.id) : selectedTeamIds,
			repositoryIds: searchFilters.gitRepositoryIds,
			projectIds: searchFilters.projectIds
		};
		setChartScope(scope);

	}, [selectedTeamIds, selectedDateRange, selectedInterval, searchFilters, allTeams.data]);

	const searchFields: ISelectOptionBase[] = [
		{
			name: "projectIds",
			label: "projects",
			isLoading: isLoadingProjects,
			options: projectOptions
		},
		{
			name: "gitRepositoryIds",
			label: "repositories",
			isLoading: isLoadingGitRepos,
			options: gitRepositoriesOptions
		}
	];

	const checkInTimeUnitMetricInterval = (key: string | number): TimeUnitMetricInterval => {
		if (key === MetricInterval.DAY || key === MetricInterval.WEEK || key === MetricInterval.MONTH) {
			return key;
		}
		return MetricInterval.WEEK;
	};

	if (hasActiveIntegrations === false) {
		return (
			<><Header/>
			<div className="ui grid">
				<EmptyState
					onChoose={(type) => { setIntegrationType(type); } }
					isActive={authStore.isUserAdmin}
				/>
			</div></>
		);
	}

	return (
		<div className="my-team-page ui fluid container">
			<Header />
			<div className="ui grid">
				<div id="team-reports" className="ui sixteen column centered stretched grid">

					<LightNavBar
						filters={searchFilters}
						applyFilters={setSearchFilters}
						popupSelectFields={searchFields}
						clickEventPage={GA_EVENT_CATEGORY.OrgAnalytics}
					>
						<div className="align-left flex-centered">
							<div className="analytics-teams-selector min-200-px" style={{ maxWidth: "200px" }}>
								<DropdownListSelector
									isLoading={_.isEmpty(allTeams.data)}
									title="Teams"
									placeholder="Select Teams"
									values={selectedTeamIds}
									isMulti={true}
									name="sprint"
									onChange={(selected: any) => {
										if (selected) {
											setSelectedTeamsIds(selected.map((opt: any) => opt.value));
										}
									}}
									options={allTeams.data.map(team => {
										return {
											key: team.id,
											value: team.id,
											label: team.name,
										};
									})}
									customColors={selectorsTheme.colors}
									customSize={selectorsTheme.size}
								/>
							</div>
						</div>
						<div className="right-side-controls full-width">
							<div className="flex-row pull-row-right">
								<div className={"filters-navbar-wrapper"}>
									<FiltersSelector
										placeholder={"Select Filters"}
										value={filtersDropdownDisplayName}
										loading={isLoadingGitRepos || isLoadingProjects}
										disabled={isLoadingGitRepos || isLoadingProjects}
									>
										<TimeFramesSelector
											value={selectedDateRange}
											setValue={(value) => {
												setSelectedDateRange(value as DateRangeType);
												setSelectedDateRangeStorage(value as DateRangeType);
											}}
											longTermDateRange={true}
											clickEvent={clickEvent(GA_EVENT_CATEGORY.OrgAnalytics, GA_EVENT_ACTION.Filter, "date-selector")}
										/>
										<TimeFramesSelector
											label={"Interval"}
											value={selectedInterval}
											setValue={(value) => {
												setSelectedInterval(checkInTimeUnitMetricInterval(value));
											}}
											options={INTERVAL_DATE_OPTIONS}
											clickEvent={clickEvent(GA_EVENT_CATEGORY.OrgAnalytics, GA_EVENT_ACTION.Filter, "dropdown-date-interval-selector")}
										/>
									</FiltersSelector>
								</div>
							</div>
						</div>
					</LightNavBar>

					<Switch>
						<Route exact={true} path={`${match?.url}/velocity`}>
							{chartScope && <VelocityBoard
								chartScope={chartScope}
							/>}
						</Route>
						<Route exact={true} path={`${match?.url}/quality`}>
							{chartScope && <QualityBoard
								chartScope={chartScope}
							/>}
						</Route>
						<Route exact={true} path={`${match?.url}/output`}>
							{chartScope && <OutputBoard
								chartScope={chartScope}
								shouldShowMakeTimeCharts={authStore.shouldShowMakeTimeCharts}
							/>}
						</Route>
						{authStore.isUserShouldExpandDevStats &&
							<Route exact={true} path={`${match?.url}/dev-stats`}>
								{chartScope && <DeveloperStatsTable
									chartScope={chartScope}
									selectedDateRange={dateRangeDisplayName}
									shouldExtendWithMoreMetrics={authStore.isUserShouldExpandDevStatsPlus}
								/>}
							</Route>
						}
						<Route path={`${match?.url}`}>
							{chartScope && <VelocityBoard
								chartScope={chartScope}
							/>}
						</Route>
						<Route>
							<Redirect to={`${match?.url}/velocity`} />
						</Route>
					</Switch>

				</div>
			</div>
		</div>
	);
};
export default observer(TeamsReport);

const selectorsTheme = {
	colors: {
		borderRadius: 1,
		colors: {
			text: "#0F2345",
			primary: "#8791A2",
			primary25: "#CED5DF",
			neutral0: "#dee5ed",
			neutral80: "0F2345",
		},
	},
	size: {
		minHeight: 30,
		height: 30,
		minWidth: 200,
		fontSize: 15,
		alignContent: "space-around",
		border: 0,
		boxShadow: "none",
	}
};
