import React from "react";
import {
	ITaskHighlight, HighlightType,
	HighlightTaskProperty, HighlightEventType,
	IHighlightTimeSpan, HighlightTimeSpanUnit
 } from "@acumen/dashboard-common";
import { assertExhaustiveSwitch, inflect } from "@acumen/common";
import moment from "moment";

const formatters = {
	taskProperty(property: HighlightTaskProperty, count: number = 1) {
		switch (property) {
			case HighlightTaskProperty.Description:
				return inflect(count, "description", "description", false);
			case HighlightTaskProperty.Comments:
				return inflect(count, "comment", "comments", false);
			case HighlightTaskProperty.Assignee:
				return inflect(count, "assignee", "assignees", false);
			case HighlightTaskProperty.Priority:
				return inflect(count, "priority", "priorities", false);
			case HighlightTaskProperty.Type:
				return inflect(count, "type", "types", false);
			case HighlightTaskProperty.Status:
				return inflect(count, "status", "statuses", false);
			case HighlightTaskProperty.Flagged:
				return inflect(count, "flagged", "flags", false);
			default:
				assertExhaustiveSwitch(property, new Error(`Unable to return text for unknown property ${property}`));
		}
	},
	timeSpan(timeSpan: IHighlightTimeSpan) {
		const spanMoment = moment.duration(timeSpan.spanMs, "milliseconds");

		switch (timeSpan.unit) {
			 case HighlightTimeSpanUnit.HOUR:
				const time = spanMoment.as("hours");
				const unit = inflect(time, "hour", "hours");
				return `in the past ${unit}`;
		}
	},
	changeType(property: HighlightTaskProperty) {
		if (property === HighlightTaskProperty.Priority) {
			return "increased";
		}

		return "has changed";
	},
	highlightText(h: ITaskHighlight) {
		switch (h.type) {
			case HighlightType.Event: {
				switch (h.eventType) {
					case HighlightEventType.PropertyChangeCount:
						return (
							<>
								Task <b>{this.taskProperty(h.property)}</b> has changed <b>{h.count}</b> {inflect(h.count, "time", "times", false)}
							</>
						);
					case HighlightEventType.PropertyChange:
						return (
							<>
								Task <b>{this.taskProperty(h.property)}</b> {this.changeType(h.property)}{h.timeSpan ? ` ${this.timeSpan(h.timeSpan)}` : null}
							</>
						);
					case HighlightEventType.PropertyChangeDuringStatus:
						return (
							<>
								Task <b>{this.taskProperty(h.property)}</b> has changed{h.timeSpan ? ` ${this.timeSpan(h.timeSpan)}` : null}
								{h.status ? <> while the task's status was <b>{h.status}</b></> : null}
							</>
						);
					default:
						return (
							<>
							</>
						);
				}
			}
			case HighlightType.Insight: {
				return h.text;
			}
			case HighlightType.Property: {
				switch (h.property) {
					case HighlightTaskProperty.Priority: {
						return (
							<>
								Task's priority is <b>{h.propertyValue}</b>
							</>
						);
					}
					case HighlightTaskProperty.Type: {
						return (
							<>
								Task's type is <b>{h.propertyValue}</b>
							</>
						);
					}
					case HighlightTaskProperty.Status: {
						return (
							<>

								Task status is <b>{h.propertyValue}</b>
							</>
						);
					}
					case HighlightTaskProperty.Flagged: {
						return (
							<>
								Task has been flagged in Jira
							</>
						);
					}
				}
				return "N/A";
			}
			case HighlightType.PropertyCount: {
				return (
					<>
						Task has <b>{h.count}</b> {this.taskProperty(h.property, h.count)}
					</>
				);
			}
		}
	}
};

export default formatters;
