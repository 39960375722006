import React from "react";
import "./badge-breakdown-item.scss";

export interface BadgeBreakdownItemProps {
	label: string;
	children?: React.ReactNode;
}

export const BadgeBreakdownItem = (props: BadgeBreakdownItemProps) => {
	const { label, children } = props;

	return (
		<div className="badge-breakdown-item">
			<div className="badge-breakdown-item-label">{label}</div>
			<div className="badge-breakdown-item-content">{children}</div>
		</div>
	);
};
