import React from "react";
import ArrowUp from "../../../../components/svg-assets/arrow-up";
import ArrowDown from "../../../../components/svg-assets/arrow-down";
import Tooltip from "../../../../components/tooltip/tooltip";
import "./developer-stats-team-trend.scss";
import { round } from "@acumen/common";
import { getChangeRatePercentage } from "v2/helpers/calculate-change-rate";

export interface DeveloperStatsTeamTrendProps {
	name: string;
	badge: string;
	numberOfContributors: number;
	currentValue: number;
	previousValue: number;
	isIncreasePositive: boolean;
}

export const DeveloperStatsTeamTrend = (props: DeveloperStatsTeamTrendProps) => {
	const RED_ARROW_COLOR = "#E24B63";
	const GREEN_ARROW_COLOR = "#61C1AA";

	const RED_LINE_COLOR = "#EC9EAB";
	const GREEN_LINE_COLOR = "#C7E9E1";

	const { badge, name, numberOfContributors, currentValue, previousValue, isIncreasePositive } = props;
	const changeRate = (previousValue)
		? getChangeRatePercentage(currentValue, previousValue, 0)
		: NaN;
	const noChange = changeRate === 0 || (previousValue === 0 && currentValue === 0);
	const isIncrease = (changeRate && changeRate > 0) || (isNaN(changeRate) && currentValue > 0);
	const isChangePositive = (isIncrease && isIncreasePositive) || (!isIncrease && !isIncreasePositive);
	const currentLineColor = (noChange || isChangePositive) ? GREEN_LINE_COLOR : RED_LINE_COLOR;

	const currentLineWidth = numberOfContributors > 0 ? Math.min(round(currentValue / numberOfContributors * 100), 100) : 0;
	const previousLineWidth = numberOfContributors > 0 ? Math.min(round(previousValue / numberOfContributors * 100), 100) : 0;

	return (
		<Tooltip
			text={
				<div>
					<div>Current: {round(currentValue, 2)}</div>
					{!!previousValue && <div>Previous: {round(previousValue, 2)}</div>}
				</div>
			}
			position="bottom"
			followCursorX={true}
		>
			<div className="developer-stats-team-trend">
				<div className="developer-stats-team-trend-info">
					<img
						className="developer-stats-team-trend-info-badge"
						src={badge}
						alt={name}
					/>

					<div className="developer-stats-team-trend-info-badge-name">{name}</div>
				</div>

				<div className="developer-stats-team-trend-stats-lines">
					<div
						className="developer-stats-team-trend-stats-current-line"
						style={{ width: `${currentLineWidth}%`, backgroundColor: currentLineColor }}
					/>

					{!!previousValue && (
						<div
							className="developer-stats-team-trend-stats-previous-line"
							style={{ width: `${previousLineWidth}%` }}
						/>
					)}
				</div>

				<div className="developer-stats-team-trend-stats">
					<div className="developer-stats-team-trend-stats-current-value">
						{currentValue}
					</div>

					{
						noChange
							? <div className="developer-stats-team-trend-stats-change-rate-icon icon-placeholder" />
							: <Tooltip
								text={isNaN(changeRate) ? "" : `${changeRate > 0 ? "+" : "-"}${(Math.abs(changeRate) + "%")}`}
								color={isChangePositive ? GREEN_ARROW_COLOR : RED_ARROW_COLOR}
								bold={true}
								children={
									isIncrease ?
										<ArrowUp
											className="developer-stats-team-trend-stats-change-rate-icon"
											indicationfillcolor={isIncreasePositive ? GREEN_ARROW_COLOR : RED_ARROW_COLOR}
										/> :
										<ArrowDown
											className="developer-stats-team-trend-stats-change-rate-icon"
											indicationfillcolor={isIncreasePositive ? RED_ARROW_COLOR : GREEN_ARROW_COLOR}
										/>
								}
							/>
					}
				</div>
			</div>
		</Tooltip>
	);
};
