import React from "react";
import classNames from "classnames";
import { WorkforceHealthMetrics } from "@acumen/database-types";
import { getChangeRatePercentage } from "v2/helpers/calculate-change-rate";
import { formatLargeNumber } from "v2/helpers/formatting";
import { MetricChart } from "../metric-chart/metric-chart";
import { Trend } from "../trend/trend";
import { colorVariants } from "./constants";
import "./metric-chart-card.scss";

export interface MetricChartCardProps {
	name?: WorkforceHealthMetrics;
	title: string;
	subtitle?: string;
	unit: string;
	data: number[];
	currentValue: number;
	previousValue: number;
	isIncreasePositive: boolean;
	elevated?: boolean;
	roundingPrecision?: number;
	className?: string;
}

export const MetricChartCard = (props: MetricChartCardProps) => {
	const { name, title, subtitle, unit, data, currentValue, previousValue, isIncreasePositive, elevated, roundingPrecision = 2 } = props;
	const className = classNames(
		props.className,
		"metric-card",
		elevated && "variant-elevated",
	);
	const changeRate = previousValue
		? getChangeRatePercentage(currentValue, previousValue, roundingPrecision)
		: NaN;
	const isChangeNegative = (changeRate < 0 && isIncreasePositive) || (changeRate > 0 && !isIncreasePositive);

	return (
		<div className={className}>
			<div className="metric-card-info">
				<div className="metric-card-info-title-container">
					<div className="metric-card-info-title" style={{ color: name && colorVariants[name] }}>
						{title}
					</div>
					{subtitle && <div className="metric-card-info-subtitle">{subtitle}</div>}
				</div>

				<div className="metric-card-info-sum">
					<div className="metric-card-info-sum-value">
						{formatLargeNumber(currentValue, roundingPrecision)}
					</div>
					<div className="metric-card-info-sum-unit">{unit}</div>
				</div>
			</div>

			<div className="metric-card-stats">
				<Trend
					currentValue={currentValue}
					previousValue={previousValue}
					hideCurrentValue={true}
					bold={true}
					isIncreasePositive={isIncreasePositive}
					roundingPrecision={roundingPrecision}
				/>
				{data.length > 0 && (
					<MetricChart
						data={data}
						color={(name && colorVariants?.[name]) ?? (
							isChangeNegative ? "#E9153B" : "#61C1AA"
						)}
					/>
				)}
				<div className="developer-aggregated-stat-filler"/>
			</div>
		</div>
	);
};
