import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import "./style.scss";
import TriggerOnClickOutside from "../ux-effects/trigger-on-click-outside";
import { IClickEvent } from "../../analytics-events";
import classNames from "classnames";

interface IFiltersSelector {
	children?: ReactNode;
	placeholder?: string;
	value?: string;
	error?: boolean;
	clickEvent?: IClickEvent;
	showArrow?: boolean;
	showDoubleArrows?: boolean;
	loading?: boolean;
	disabled?: boolean;
	className?: string;
	onClose?: () => void;
}

// tslint:disable-next-line: variable-name
const FiltersSelector = ({
		value, children, placeholder, error, clickEvent, showArrow, showDoubleArrows, disabled, loading, className, onClose
	}: IFiltersSelector) => {
	const [open, setOpen] = useState(false);
	const excludeRef = useRef<HTMLDivElement>(null);
	const close = useCallback(() => setOpen(false), []);

	useEffect(() => {
		if (!open && onClose) {
			onClose();
		}
	}, [open]);

	return (
		<div className={classNames("acu-select acu-filters-selector", {"--disabled": disabled || loading})}
			{...clickEvent}
		>
			<div
				className={classNames("acu-select-input acu-filters-select-input", {"--error": error})}
				ref={excludeRef}
				onClick={() => {
					setOpen(prevState => !prevState);
				}}
			>
				{showDoubleArrows && <div className="acu-filters-arrow">
					<i aria-hidden="true" className="chevron left icon"/>
				</div>}
				<div className={"acu-filters-input-value"}>
					{value ?
						<div className="acu-select-input-selected-single acu-filters-selector-value">
							{value}
						</div> :
						<span className="acu-select-input-placeholder">{placeholder}</span>
					}
				</div>
				{showDoubleArrows && <div className="acu-filters-arrow">
					<i aria-hidden="true" className="chevron right icon"/>
				</div>}
				{showArrow && <div className="acu-filters-arrow">
					<i aria-hidden="true" className={classNames("chevron icon", open ? "down" : "right")} />
				</div>}
			</div>
			{open && <TriggerOnClickOutside onTrigger={close} excludedElements={excludeRef.current}>
				<div className={classNames("acu-filters-selector-container", className)}>
					{children}
				</div>
			</TriggerOnClickOutside>}
		</div>
	);
};

export default FiltersSelector;
