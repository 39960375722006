import React, { useMemo } from "react";
import { IDashboardStatus, IDashboardTaskStatusChange } from "@acumen/dashboard-common";
import { useStores } from "../../../mobx-stores";
import { Popup } from "semantic-ui-react";
import { ExternalImage } from "../../../components/external-image";
import classNames from "classnames";
import TaskStatusProgression from "../../../components/formatters/task-status-progression";
import useSortState from "../../../hooks/useSortState";
import useSortedItems from "../../../hooks/useSortedItems";
import SortProvider from "../../../providers/sort-provider";
import TableHeader from "../../../components/table-header";
import {
	IterationReview,
	taskCategoryToColor,
	taskCategoryToLabel
} from "../../../components/iteration-review/utils";

interface IBurndownChartModalTable {
	tasks?: IDashboardTaskStatusChange[];
	removedTasks?: string[];
}

const getTaskKey = (item: IDashboardTaskStatusChange) => item.key;
const getTaskTitle = (item: IDashboardTaskStatusChange) => item.title;
const createTaskAcumenStatusGetter = (statusIdToStatus: Record<string, IDashboardStatus>) => {
	return (item: IDashboardTaskStatusChange) => {
		const startStatus = item?.startStatus ? statusIdToStatus[item?.startStatus.toString()] : null;
		const endStatus = item?.endStatus ? statusIdToStatus[item?.endStatus.toString()] : null;
		const acumenStartStatus = startStatus?.customerStatusName ?? startStatus?.acumenStatus;
		const acumenEndStatus = endStatus?.customerStatusName ?? endStatus?.acumenStatus;

		return (acumenStartStatus ?? "") + ":" + (acumenEndStatus ?? "");
	};
};

// tslint:disable-next-line: variable-name
const BurndownChartModalTable = ({tasks = [], removedTasks}: IBurndownChartModalTable) => {
	const {
		taskStatusStore: {
			getStatus,
			statusIdToStatus,
		},
		sprintRetroStore: {
			issueIdsByCategory,
		}
	} = useStores();

	const { sortedColumn, sort } = useSortState();
	const sortedTasks = useSortedItems({ items: tasks, sortedColumn });
	const getTaskStateInSprint = useMemo(() => IterationReview.TasksTable.createTaskStateInSprintGetter(issueIdsByCategory, "id"), [issueIdsByCategory]);
	const getTaskStatus = useMemo(() => createTaskAcumenStatusGetter(statusIdToStatus), [statusIdToStatus]);

	return (
		<table className={classNames("acu ui very compact", "unstackable selectable table", "sticky-table",
			"burndown-chart-modal-table", "clickable")}>
			<colgroup span={4} />
			<thead className={classNames("full-width", "no-collapse", "acu-capitalize")}>
			<tr>
				<TableHeader className="left aligned one wide"/>
				<SortProvider sortedColumn={sortedColumn} onSort={sort}>
					<TableHeader columnName="Key" className="two wide" sortBy={getTaskKey}/>
					<TableHeader columnName="Title" className="title" sortBy={getTaskTitle}/>
					<TableHeader columnName="Planned/Unplanned" className="two wide" sortBy={getTaskStateInSprint}/>
					<TableHeader columnName="Status" className="three wide" sortBy={getTaskStatus}/>
				</SortProvider>
			</tr>
			</thead>
			<tbody>
			{sortedTasks?.length === 0 && (
				<tr>
					<td colSpan={8}>
						<div className="rows-placeholder ui basic fluid center aligned segment" />
					</td>
				</tr>
			)}
			{sortedTasks?.map(
				(t) => {
					const taskCategory = IterationReview.TasksTable.findTaskCategory(issueIdsByCategory, t.id);

					return (
						<tr
							key={t.key}
						>
							<td>
								<div className="task-icon-items">
									<Popup
										size="mini"
										basic={true}
										content={t.type?.internalType}
										trigger={
											<ExternalImage src={t.type?.iconUrl ?? ""} className="ui micro image" />
										}
										disabled={!t.type?.internalType}
									/>
									{(t.priority?.internalPriority && t.priority?.internalPriority?.trim().length > 0) && (
										<Popup
											size="mini"
											basic={true}
											content={t.priority?.internalPriority}
											trigger={
												<ExternalImage src={t.priority?.iconUrl ?? ""} className="ui micro image" />
											}
										/>
									)}
								</div>
							</td>
							<td>
								<div className="bcm-item-key">
									<a href={t?.link} target="_blank" rel="noopener noreferrer" className="acu-link">
										{t?.key}
									</a>
								</div>
							</td>
							<td>
								<div className="bcm-item-title">{t?.title}</div>
							</td>
							<td>
								{taskCategory &&
									<>
										<span className={classNames("task-category", taskCategoryToColor[taskCategory], "bullet")}>
											{"\u25CF"}
										</span>
										{taskCategoryToLabel[taskCategory]}
									</>
								}
							</td>
							<td>
								{getStatus && <div className="bcm-item-status-diff">
									<TaskStatusProgression
										startStatus={t?.startStatus ? statusIdToStatus[t?.startStatus.toString()] : null}
										endStatus={t?.endStatus ? statusIdToStatus[t?.endStatus.toString()] : null}
										isRemoved={removedTasks?.includes(t.id)}
									/>
								</div>}
							</td>
						</tr>
					);
				}
			)}
			</tbody>
		</table>
	);
};

export default BurndownChartModalTable;
