import React from "react";
import { TeamViewModel } from "../../../team-comparison/types";
import "./team-details.scss";
import { DeveloperStatsTeamTrendMembers } from "../developer-stats-team-trend-members/developer-stats-team-trend-members";
import { TeamBadge } from "@acumen/database-types";
import { Badge, BadgeType } from "v2/components/badge/badge";
import { BadgeOwner } from "v2/types/badges";
import { Link } from "react-router-dom";
import { createSearchParamsForPage } from "../../workforce-health";
import { ROUTES } from "v2/routing";
import urlJoin from "url-join";

export interface TeamDetailsProps {
	team: TeamViewModel;
}

export const TeamDetails = (props: TeamDetailsProps) => {
	const { team } = props;

	const urlForTeamBreakdown = (teamId: string) => {
		const searchParams = createSearchParamsForPage([teamId]);
		return urlJoin(ROUTES.WORKFORCE_HEALTH, `?${searchParams.toString()}`);
	};

	return (
		<div className="team-detailed-profile">
			<div className="team-detailed-profile-header">
				<div className="team-detailed-profile-info">
					<div className="team-detailed-profile-info-name">{team.info.name}</div>
					<div className="team-detailed-profile-info-members-count">{team.info.members.length} Members</div>
					<div className="team-detailed-profile-info-members-avatars">
						<DeveloperStatsTeamTrendMembers
							contributors={team.info.members}
							maxMembersToShow={7}
							size={40}
							justify={"flex-start"}
						/>
					</div>

					<Link to={urlForTeamBreakdown(team.info.id)} className="team-detailed-profile-info-see-workforce-breakdown">
						<span>See workforce breakdown</span>
					</Link>
					{team.badges.aggregatedBadges && team.badges.aggregatedBadges.length > 0 && (
						<div className="team-detailed-profile-info-badges">
							{team.badges.aggregatedBadges.map((badge: TeamBadge) => (
								<Badge
									key={badge}
									badge={badge}
									badgeOwner={BadgeOwner.Team}
									badgeType={BadgeType.AggregatedBadge}
								/>
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
