import BaseStore, { IBaseStore } from "./base-store";
import { action, observable, computed } from "mobx";
import apiContextProvider from "../../services/api-context-provider";
import { getSelfUser } from "../../services/auth";
import {
	Status,
	IDashboardCurrentUserProfile,
	DashboardUserRoleType,
	UserFlags,
	ROLE_TO_ALLOWED_CONFIGURATION_CONTEXT,
	AcumenUiVersion,
} from "@acumen/dashboard-common";
import Highcharts from "highcharts";
import moment from "moment-timezone";
import FetcherStore from "./fetcher-store";
import _ from "lodash";
import TagManager from "react-gtm-module";

const JWT_TOKEN_STORAGE_KEY = "acumen-jwt-storage-key";

export interface IAuthState {
	authUser: IDashboardCurrentUserProfile;
}

export interface IAuthStore extends IAuthState, IBaseStore<IAuthState> {
}

const defaults = {
	authUser: {
		customerId: "",
		companyName: "",
		createdAt: new Date(),
		dataContributorDetails: {
			id: "",
			externalId: "",
			primaryDisplayName: null,
			primaryAvatarUrl: null
		},
		id: "",
		roles: [],
		status: Status.Enabled,
		timezone: "",
		locale: "",
		teamMemberships: [],
		userFlags: [],
		nonWorkingDays: [],
		acumenUiVersion: AcumenUiVersion.V1,
		allowUiVersionUpdate: false
	}
};

export default class AuthStore extends BaseStore<IAuthState> implements IAuthStore {
	@observable
	public authUser: IDashboardCurrentUserProfile = defaults.authUser;

	constructor(private fetcherStore: FetcherStore) { super(); }

	@action.bound
	public async handleAuthUserResponse(accessToken?: string) {
		if (!accessToken) {
			throw new Error("Auth call back failed, Auth0 user profile or token are invalid");
		}

		localStorage.setItem(JWT_TOKEN_STORAGE_KEY, accessToken);

		let selfUser: IDashboardCurrentUserProfile | null;
		selfUser = await getSelfUser(accessToken);
		if (!selfUser) {
			if (this.fetcherStore.hasTokenError) {
				return;
			}
			throw new Error("Couldn't fetch dashboard user for equivalent authenticated user");
		}
		apiContextProvider.setContext({ customerId: selfUser.customerId, token: accessToken });

		this.authUser = selfUser;
		this.fetcherStore.setTokenErrorState(false);

		const tagManagerArgs = {
			dataLayer: {
				customerId: selfUser.customerId,
				userId: selfUser.id,
				userEmail: selfUser.email
			}
		};
		TagManager.dataLayer(tagManagerArgs);

		if (this.authUser.timezone) {
			Highcharts.setOptions({
				lang: {
					thousandsSep: ","
				},
				plotOptions: {
					series: {
						animation: false
					}
				},
				time: {
					timezone: this.authUser.timezone,
					moment
				}
			});
		}
	}

	public getCachedJWT() {
		const token = localStorage.getItem(JWT_TOKEN_STORAGE_KEY);

		if (token && this.isTokenExpired(token)) {
			localStorage.removeItem(JWT_TOKEN_STORAGE_KEY);
			return null;
		}
		return token;
	}

	private isTokenExpired(jwt: string) {
		return Date.now() >= (JSON.parse(atob(jwt.split(".")[1]))).exp * 1000;
	}

	@computed
	public get isUserAdmin() {
		return ((this.authUser.status === Status.Enabled) &&
			!!this.authUser.roles.find(r => r === DashboardUserRoleType.Administrator));
	}
	@computed
	public get isUserExecutiveOrAbove() {
		return ((this.authUser.status === Status.Enabled) &&
			!!this.authUser.roles.find(r => r === DashboardUserRoleType.Administrator || r === DashboardUserRoleType.Executive));
	}
	@computed
	public get isUserManagerOrAbove() {
		return ((this.authUser.status === Status.Enabled) &&
			!!this.authUser.roles.find(r =>
				r === DashboardUserRoleType.Administrator ||
				r === DashboardUserRoleType.Executive ||
				r === DashboardUserRoleType.Manager));
	}
	@computed
	public get isUserShouldExpandDevStats() {
		return (this.authUser.userFlags !== undefined) &&
			(this.authUser.userFlags.indexOf(UserFlags.DevStats) !== -1);
	}
	@computed
	public get isUserShouldShowDeploymentFrequency() {
		return (this.authUser.userFlags !== undefined) &&
			(this.authUser.userFlags.indexOf(UserFlags.DeploymentFrequency) !== -1);
	}
	@computed
	public get isUserShouldExpandDevStatsPlus() {
		return (this.authUser.userFlags !== undefined) &&
			(this.authUser.userFlags.indexOf(UserFlags.DevStatsPlus) !== -1);
	}
	@computed
	public get isUserShouldShowInviteUsers() {
		return (this.authUser.userFlags !== undefined) &&
			(this.authUser.userFlags.indexOf(UserFlags.InviteUsers) !== -1);
	}
	@computed
	public get shouldShowMakeTimeCharts() {
		return (this.authUser.userFlags !== undefined) &&
			(this.authUser.userFlags.indexOf(UserFlags.MakerTimeCharts) !== -1);
	}
	@computed
	public get userConfigurationContext() {
		return ((this.authUser.status === Status.Enabled) &&
			_.union(...this.authUser.roles.map((r: DashboardUserRoleType) => ROLE_TO_ALLOWED_CONFIGURATION_CONTEXT[r])));
	}
	@computed
	public get shouldShowGitHubReportedDeploymentFrequency() {
		return (this.authUser.userFlags !== undefined) &&
			(this.authUser.userFlags.indexOf(UserFlags.GitHubReportedDeploymentFrequency) !== -1);
	}
}
