import * as React from "react";
import "./pr-table-aggregated-data-header-cell.scss";

export const PRTableAggregatedDataHeaderCell = () => {
	return (
		<th className="pr-table-aggregated-data-header-cell">
			<div className="pr-table-aggregated-data-header-cell-content">
				Total
			</div>
		</th>
	);
};
