import { getData } from "../../../services/fetch-helpers";
import { BaseCustomerApiClient } from "./base-customer-api-client";
import { ITeamProjects, DashboardSortOrder } from "@acumen/dashboard-common";
import { qsStringify } from "../../../services/url-routes-helper";

export const PROJECTS_ROUTE = "projects";

export interface IProjectSort {
	order: DashboardSortOrder;
}

export interface IProjectFilter {
	teamId?: string;
	enforceProjectWhitelist?: boolean;
}

export class ProjectsApiClient extends BaseCustomerApiClient {
	public fetchProjects = async (filter?: IProjectFilter, sort?: IProjectSort) => {
		const query: Record<string, string | boolean> = {};
		if (filter?.teamId) {
			query.teamId = filter?.teamId;
		}
		if (filter?.enforceProjectWhitelist) {
			query.enforceProjectWhitelist = filter?.enforceProjectWhitelist;
		}

		if (sort?.order) {
			query.sortOrder = sort.order;
		}

		return await getData<ITeamProjects, any>(
			this.createCustomerEntityRoute(`${PROJECTS_ROUTE}?${qsStringify(query)}`),
			this.token
		);
	}
}
