// Taken from https://github.com/streamich/react-use/blob/master/docs/useLocalStorage.md
import { useState, useCallback, Dispatch, SetStateAction } from "react";
import { useStores } from "../mobx-stores";

type parserOptions<T> =
	| {
		raw: true;
	}
	| {
		raw: false;
		serializer: (value: T) => string;
		deserializer: (value: string) => T;
	};

function useLocalStorage<T>(
	key: string,
	initialValue: T,
	options?: parserOptions<T>
): [T, Dispatch<SetStateAction<T>>, () => void];
function useLocalStorage<T>(
	key: string,
	initialValue?: T,
	options?: parserOptions<T>
): [T | undefined, Dispatch<SetStateAction<T | undefined>>, () => void];
function useLocalStorage<T>(
	key: string,
	initialValue?: T,
	options?: parserOptions<T>
): [T | undefined, Dispatch<SetStateAction<T | undefined>>, () => void] {
	if (!key) {
		throw new Error("useLocalStorage key may not be falsy");
	}

	const { authStore } = useStores();
	const userId = authStore.authUser.id;
	if (!userId) {
		throw new Error("useLocalStorage must have user id available");
	}
	const userSpecificKey = `${userId}:${key}`;
	const deserializer = options ? (options.raw ? (value: string) => value : options.deserializer) : JSON.parse;

	const [state, setState] = useState<T | undefined>(() => {
		try {
			const serializer = options ? (options.raw ? String : options.serializer) : JSON.stringify;

			const localStorageValue = localStorage.getItem(userSpecificKey);
			if (localStorageValue !== null) {
				return deserializer(localStorageValue);
			} else {
				if (initialValue) {
					localStorage.setItem(userSpecificKey, serializer(initialValue));
				}
				return initialValue;
			}
		} catch {
			// If user is in private mode or has storage restriction
			// localStorage can throw. JSON.parse and JSON.stringify
			// can throw, too.
			return initialValue;
		}
	});

	const set: Dispatch<SetStateAction<T | undefined>> = useCallback(
		valOrFunc => {
			try {
				const newState = typeof valOrFunc === "function" ?
					(valOrFunc as (prevState: T | undefined) => void)(state) : valOrFunc;
				if (typeof newState === "undefined") {
					return;
				}
				let value: string;

				if (options) {
					if (options.raw) {
						if (typeof newState === "string") {
							value = newState;
						} else {
							value = JSON.stringify(newState);
						}
					} else if (options.serializer) {
						value = options.serializer(newState);
					} else {
						value = JSON.stringify(newState);
					}
				} else {
					value = JSON.stringify(newState);
				}

				localStorage.setItem(userSpecificKey, value);
				setState(deserializer(value));
			} catch {
				// If user is in private mode or has storage restriction
				// localStorage can throw. Also JSON.stringify can throw.
			}
		},
		[userSpecificKey, setState]
	);

	const remove = useCallback(() => {
		try {
			localStorage.removeItem(userSpecificKey);
			setState(undefined);
		} catch {
			// If user is in private mode or has storage restriction
			// localStorage can throw.
		}
	}, [userSpecificKey, setState]);

	return [state, set, remove];
}

export default useLocalStorage;
