import React, { useMemo } from "react";
import { OrgMetricsCard } from "../org-metrics-card/org-metrics-card";
import "./pr-cycle-time-in-phases.scss";
import { IChartScope } from "../../charts/chart-scope";
import PRCycleTime from "../../charts/pr-cycle-time";

export interface PrCycleTimeInPhasesProps {
	chartScope: IChartScope | undefined;
	chartHeight: number;
	breakdownURL: string;
}

export const PrCycleTimeInPhases = (props: PrCycleTimeInPhasesProps) => {
	const { chartScope, chartHeight, breakdownURL } = props;
	const tooltip = "Average amount of time from the creation of the first commit in a pull request, until that pull request is merged. Only merged pull requests from the selected teams are included in the calculation.";
	const chartHeightProps = useMemo(() => ({ mainChartHeight: chartHeight }), [chartHeight]);

	return (
		<OrgMetricsCard bodyClassName="pr-cycle-time-in-phases" title="PR Cycle time in phases" tooltip={tooltip}>
			{chartScope && (
				<PRCycleTime
					scope={chartScope}
					chartHeight={chartHeightProps}
					breakdownURL={breakdownURL}
				/>
			)}
		</OrgMetricsCard>
	);
};
