import BaseStore, { IBaseStore, ILoadable } from "./base-store";
import { observable, action, computed } from "mobx";
import apiContextProvider from "../../services/api-context-provider";
import {
	DataContributorsApiClient, IDataContributorFilter, IDataContributorRequestParams,
	IDataContributorNegationFilter, DATA_CONTRIBUTORS_ROUTE, MERGE_SUB_ROUTE, LABELS_SUB_ROUTE
} from "../services/crud/data-contributors-api-client";
import {
	IDashboardDetailedDataContributor, IDashboardDataContributorsMetadata, IntegrationType, DashboardDataContributorExpandOption, IdentityType, IDashboardDataContributorLink, AcumenIdentityType, IDashboardDataContributorLabel
} from "@acumen/dashboard-common";
import { IContributorsActionType } from "../pages/my-account/contributors";
import _ from "lodash";
import { FetchLatestRequest } from "../../services/fetch-helpers";

export type ContributorsTableColumnType = AcumenIdentityType | ContributorTableFilterType.lastActivity | ContributorTableFilterType.contributorsName;

export enum ContributorTableFilterType {
	IdentityType,
	lastActivity,
	contributorsName
}

interface IDataContributorsState {
	allDataContributors: ILoadable<IDashboardDetailedDataContributor[], IDashboardDataContributorsMetadata>;
	singleDataContributor: ILoadable<IDashboardDetailedDataContributor>;
}

export interface IDataContributorsStore extends IDataContributorsState, IBaseStore<IDataContributorsState> {
	fetchAllDataContributors: (filter?: IDataContributorFilter) => Promise<void>;
	resetDataContributors: () => void;
	setSingleDataContributor: (dataContributor: IDashboardDetailedDataContributor) => void;
	resetSingleDataContributor: () => void;
	unMergedDataContributors?: IDashboardDetailedDataContributor[];
	sortDataContributors: (str: string) => void;
	sortUnMergedDataContributorsByFilter: (filterBy: ContributorsTableColumnType, sortAscending?: boolean) => void;
	filteredUnMergedContributors: IDashboardDetailedDataContributor[];
	fetchLabels: (dataContributorIds: string[]) => Promise<Map<string, IDashboardDataContributorLabel[]>>;
}

const defaults = {
	allDataContributors: BaseStore.initLoadable([], { integrations: [] }),
	nonAcumenDataContributors: BaseStore.initLoadable([], { integrations: [] }),
	singleDataContributor: BaseStore.initLoadable({}),
	dataContributorToLabels: BaseStore.initLoadable(new Map())
};

export const DEFAULT_DATA_CONTRIBUTOR_FILTERS: IDataContributorFilter = {
	onlyHumanContributors: true,
	onlyActiveContributors: true,
	integrations: [IntegrationType.Acumen, IntegrationType.JIRA, IntegrationType.GitHub, IntegrationType.Slack]
};

const DEFAULT_DATA_CONTRIBUTORS_TO_MERGE_QUERY: IDataContributorRequestParams = {
	filter: DEFAULT_DATA_CONTRIBUTOR_FILTERS,
	expand: [DashboardDataContributorExpandOption.LINKS, DashboardDataContributorExpandOption.LAST_ACTIVITY],
	groupGitHub: true
};

export default class DataContributorsStore extends BaseStore<IDataContributorsState> implements IDataContributorsStore {
	private readonly apiClient: DataContributorsApiClient = new DataContributorsApiClient(apiContextProvider);

	@observable
	public allDataContributors:
		ILoadable<IDashboardDetailedDataContributor[], IDashboardDataContributorsMetadata> = defaults.allDataContributors;
	@observable
	public nonAcumenDataContributors:
		ILoadable<IDashboardDetailedDataContributor[], IDashboardDataContributorsMetadata> = defaults.nonAcumenDataContributors;
	@observable
	public singleDataContributor:
		ILoadable<IDashboardDetailedDataContributor> = defaults.singleDataContributor;
	@observable
	public unMergedDataContributors:
		IDashboardDetailedDataContributor[] | undefined = undefined;
	@observable
	public toMergeDataContributors:
		IDashboardDetailedDataContributor[] = [];

	@observable
	public dataContributorToLabels:
		ILoadable<Map<string, IDashboardDataContributorLabel[]>> = defaults.dataContributorToLabels;

	@computed
	public get filteredUnMergedContributors() {
		return this.unMergedDataContributors?.filter(
			dc => !this.toMergeDataContributors.find(mergedDc => mergedDc.id === dc.id)) ?? [];
	}

	fetchLatestAllDC = new FetchLatestRequest<IDashboardDetailedDataContributor[], IDashboardDataContributorsMetadata>(DATA_CONTRIBUTORS_ROUTE);
	@action.bound
	public async fetchAllDataContributors(filter?: IDataContributorFilter, expand?: DashboardDataContributorExpandOption[], groupGitHub?: boolean,
		negFilter?: IDataContributorNegationFilter) {
		this.allDataContributors.loading = true;

		const result = await this.fetchLatestAllDC.fetchLatest(
			this.apiClient.getAll({ filter, expand, groupGitHub, negFilter }
			));
		if (result) {
			const { data, metadata } = result;
			this.sortByDisplayName(data);
			this.allDataContributors = {
				data,
				metadata: metadata as IDashboardDataContributorsMetadata,
				loaded: true,
				loading: false
			};
		}
	}

	fetchLatestNonAcumenDC = new FetchLatestRequest<IDashboardDetailedDataContributor[], IDashboardDataContributorsMetadata>(DATA_CONTRIBUTORS_ROUTE);
	@action.bound
	public async fetchNonAcumenRelatedDataContributors() {
		this.nonAcumenDataContributors.loading = true;

		const filter = { onlyActiveContributors: true, onlyHumanContributors: true };
		const expand = [DashboardDataContributorExpandOption.LINKS];
		const negFilter = { noAcumenAccount: true };
		const result = await this.fetchLatestNonAcumenDC.fetchLatest(
			this.apiClient.getAll({ filter, expand, negFilter }
			));
		if (result) {
			const { data, metadata } = result;
			this.sortByDisplayName(data);
			this.nonAcumenDataContributors = {
				data,
				metadata: metadata as IDashboardDataContributorsMetadata,
				loaded: true,
				loading: false
			};
		}
	}
	fetchLatestUnMergedDC = new FetchLatestRequest<IDashboardDetailedDataContributor[], IDashboardDataContributorsMetadata>(DATA_CONTRIBUTORS_ROUTE + MERGE_SUB_ROUTE);
	@action.bound
	public async fetchUnMergedDataContributors() {
		const result = await this.fetchLatestUnMergedDC.fetchLatest(
			this.apiClient.getAll(DEFAULT_DATA_CONTRIBUTORS_TO_MERGE_QUERY
			));
		if (result) {
			const { data, metadata } = result;
			this.sortByDisplayName(data);
			this.allDataContributors = {
				data,
				metadata: metadata as IDashboardDataContributorsMetadata,
				loaded: true,
				loading: false
			};
		}
		this.unMergedDataContributors = result?.data;
	}

	@action.bound
	public resetDataContributors() {
		this.allDataContributors = defaults.allDataContributors;
		this.nonAcumenDataContributors = defaults.nonAcumenDataContributors;
	}

	@action.bound
	public setSingleDataContributor(dataContributor: IDashboardDetailedDataContributor) {
		this.singleDataContributor.data = dataContributor;
	}

	@action.bound
	public resetSingleDataContributor() {
		this.singleDataContributor = defaults.singleDataContributor;
	}

	@action.bound
	public sortDataContributors(searchString: string) {
		const sortedContributors = this.allDataContributors.data;
		if (_.isEmpty(searchString)) {
			this.unMergedDataContributors = sortedContributors;
			return;
		}
		const search = new RegExp(searchString, "i");

		this.unMergedDataContributors = sortedContributors
			.filter(dc => !this.toMergeDataContributors.find(mergedDc => mergedDc.id === dc.id))
			.filter(dc => (
				dc.primaryDisplayName?.match(search) ||
				dc.links.find(link => (
					link.displayName?.match(search) ||
					link.emails.find(email => email.match(search))
				))
			));
	}

	@action.bound
	public sortUnMergedDataContributorsByFilter(filterBy: ContributorsTableColumnType = ContributorTableFilterType.lastActivity, sortAscending: boolean = true) {
		const sortedContributors = this.filteredUnMergedContributors;

		const filterLinksByIdentityType = (links: IDashboardDataContributorLink[]) => {
			return links
				.filter(link => this.getIdentityTypeToCategoryIdentities(link.identityType, filterBy)).length;
		};

		if (filterBy === ContributorTableFilterType.lastActivity) {
			this.unMergedDataContributors = _.orderBy(sortedContributors, ["lastActivity"], sortAscending ? "asc" : "desc");
		} else if (filterBy === ContributorTableFilterType.contributorsName) {
			this.sortByDisplayName(sortedContributors, sortAscending);
			this.unMergedDataContributors = sortedContributors;
		} else {
			this.unMergedDataContributors = _.orderBy(sortedContributors
				.map(dc => ({ ...dc, foundLinks: sortAscending ? -filterLinksByIdentityType(dc.links) : filterLinksByIdentityType(dc.links) })), ["foundLinks"]);
		}
	}

	fetchLatestDataContributorLabels = new FetchLatestRequest<IDashboardDataContributorLabel[], any>(DATA_CONTRIBUTORS_ROUTE + LABELS_SUB_ROUTE);
	@action.bound
	public async fetchLabels(dataContributorIds: string[]): Promise<Map<string, IDashboardDataContributorLabel[]>> {
		if (dataContributorIds.length === 0) {
			return new Map();
		}

		const response = await this.fetchLatestDataContributorLabels.fetchLatest(this.apiClient.fetchLabels(dataContributorIds));

		const data: Map<string, IDashboardDataContributorLabel[]> = new Map();
		if (response) {
			const dcToLabels = _.groupBy(response.data, d => d.dataContributorId);
			Object.entries(dcToLabels).forEach(([dcIds, labels]) => {
				data.set(dcIds, labels);
			});
			this.dataContributorToLabels = {
				data,
				metadata: undefined,
				loaded: true,
				loading: false
			};
		}
		return data;
	}

	@action.bound
	public sortToBeMergedDataContributorsByFilter(filterBy: ContributorsTableColumnType = ContributorTableFilterType.lastActivity, sortAscending: boolean = true) {

		const filterLinksByIdentityType = (links: IDashboardDataContributorLink[]) => {
			return links
				.filter(link => this.getIdentityTypeToCategoryIdentities(link.identityType, filterBy)).length;
		};

		if (filterBy === ContributorTableFilterType.lastActivity) {
			this.toMergeDataContributors = _.orderBy(this.toMergeDataContributors, ["lastActivity"], sortAscending ? "asc" : "desc");
		} else if (filterBy === ContributorTableFilterType.contributorsName) {
			this.toMergeDataContributors = this.sortByDisplayName(this.toMergeDataContributors, sortAscending);
		} else {
			this.toMergeDataContributors = _.orderBy(this.toMergeDataContributors
				.map(dc => ({ ...dc, foundLinks: sortAscending ? -filterLinksByIdentityType(dc.links) : filterLinksByIdentityType(dc.links) })), ["foundLinks"]);
		}
	}

	private sortByDisplayName(dcs: IDashboardDetailedDataContributor[], sortAscending: boolean = true) {
		return dcs.sort((a, b) =>
			(a.primaryDisplayName && b.primaryDisplayName) ? (
				sortAscending ?
					a.primaryDisplayName.localeCompare(b.primaryDisplayName) :
					b.primaryDisplayName.localeCompare(a.primaryDisplayName)) : -1
		);
	}

	private getIdentityTypeToCategoryIdentities = (_type: IdentityType, filterBy: ContributorsTableColumnType) => {
		if (filterBy === IdentityType.GITHUB_COLLABORATOR || filterBy === IdentityType.GITHUB_CONTRIBUTOR) {
			return _type === IdentityType.GITHUB_COLLABORATOR || _type === IdentityType.GITHUB_CONTRIBUTOR;
		} else {
			return _type === filterBy;
		}
	}

	@action.bound
	public updateToMergeList = (dc: IDashboardDetailedDataContributor, dcAction: IContributorsActionType) => {
		switch (dcAction) {
			case "Add":
				this.toMergeDataContributors = [...this.toMergeDataContributors, dc];
				break;
			case "Remove":
				this.toMergeDataContributors = this.toMergeDataContributors.filter(_dc => _dc.id !== dc.id);
				break;
		}
	}

	@action.bound
	public clearToMergeList = () => {
		this.toMergeDataContributors = [];
	}

	@action.bound
	public async mergeDataContributors(dcList: IDashboardDetailedDataContributor[]) {
		const result = await this.apiClient.mergeDataContributors(dcList);
		if (result) {
			await this.fetchUnMergedDataContributors();
		}
		return result;
	}
}
