import { action, observable } from "mobx";

export interface IFetcherStore {
	hasTokenError: boolean;
	setTokenErrorState: (a: boolean) => void;
}

export default class FetcherStore {
	@observable
	public hasTokenError: boolean = false;

	@action.bound
	public setTokenErrorState(value: boolean) {
		this.hasTokenError = value;
	}
}
