import * as React from "react";
const Tiger = (props: React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fill="#E27022"
			d="M19.369 9.217s-8.31-.769-4.796 9.174c0 0 2.491 6.133 4.795 7.375 0 0-9.367 6.404-3.74 18.569l.647 3.852s3.75 5.563 8.5 5.688l1.75 1.625s1.188 3.563 2.125 4c.938.438 5.438 3.029 5.438 3.029l4.358-.276 5.454-2.565s2.438-2.063 2.438-4.688l1.002-1.375s6.185-1.217 8.623-5.883l1.91-7.117v-4.527l-.375-2.89-2.348-5.833-1.562-1.75s1.312-2.225 2-3.3c.687-1.075 2.285-4.745 2.285-4.745l1.402-3.33-1.402-3.5-3.995-1.547-2.353.86-5.403 5.708s-5.591-3.006-9.157-2.763c-3.565.242-11.035 2.43-11.035 2.43l-6.561-6.221z"
		/>
		<path
			fill="#FFF"
			d="M38.338 29.506s4.187 3.787 4.5 8.13c0 0 4 3.764 5.937-1.982l2.813-6.423s-1.932-3.59-8.125-3.78l-5.125 4.055zM33.838 29.57s-3.063 3.78-3.375 8.123c0 0-4 3.763-5.938-1.983L20.4 29.231s2.37-4.04 8.563-4.231l4.875 4.57z"
		/>
		<path
			fill="#FFF"
			d="M52.213 33.5c0 .313.125 12-11.063 13.375 0 0-6.625-2.125-10.625.375 0 0-11.437-4.313-10.937-13.875l-.188 5.813c-1.437 1.131-3.676-4.032-.646-13.021-5.23 3.484-5.962 16.14-2.479 22.02 0 0 3.5 5.75 9.313 5.938 0 0 3.5 4.438 10.312 1.188 0 0 5.625 3.624 10.5-1.376 0 0 7.188-.766 9.563-6.195 0 0 5.208-15.242-2.209-21.659 0 0 3.334 13.542-1.229 13.167l-.312-5.75z"
		/>
		<path
			fill="#D0CFCE"
			d="M26.525 55.5s.813 4.25 3.563 4.917l5.666 2.25 4.75-.917 3.396-2.063s2.688-1.52 2.438-4.687c0 0-6.75 2.5-10.167.167 0 0-5.792 2.166-9.646.333z"
		/>
		<path
			fill="#E27022"
			d="M31.504 47.083s3.5 2.75 9.084.084l-.25 2.666-4.417 1.834-3.917-1-.5-3.584z"
		/>
		<path d="M47.34 34.618s.06 2.342-1.204 3.16c-1.265.817-3.376-.2-3.376-.2s-.06-2.342 1.204-3.16 3.375.2 3.375.2zM25.93 34.618s-.06 2.342 1.204 3.16c1.265.817 3.375-.2 3.375-.2s.061-2.342-1.203-3.16-3.376.2-3.376.2z" />
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M24.198 47.367s-1.667 12.708 11.958 7.583v-3.344M21.24 28.492c-.167.333-4.667 9.166.917 16.25"
		/>
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M22.155 23.24c-4.665 2.775-12.117 9.782-5.249 24.127M27.323 48.992s-11.167.75-12.75 6.833M28.906 52.158c-.25.167-10.333 5.75-8.583 10.667M29.577 59.152c1.98 2.05 7.412 6.378 13.517.13M17.49 22.325c-7.929-9.061-3.076-11.658-.224-12.877a2.969 2.969 0 0 1 2.865.317l5.799 6.06s9.324-6.394 20.14 0l5.8-6.06a2.97 2.97 0 0 1 2.864-.317c2.852 1.219 7.705 3.816-.224 12.877M40.719 47.742s1 2.875-3.417 3.625h-2.604c-4.417-.75-3.417-3.625-3.417-3.625"
		/>
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M47.802 47.367s1.667 12.708-11.958 7.583v-3.583M50.76 28.492c.167.333 4.667 9.166-.917 16.25"
		/>
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M49.883 23.263c4.668 2.79 12.06 9.798 5.21 24.103M44.677 48.992s11.167.75 12.75 6.833M43.094 52.158c.25.167 10.333 5.75 8.583 10.667M29.577 24.107s7.077-3.333 13.183.417M31.52 29.286s4.99-3.333 9.296.417"
		/>
	</svg>
);
export default Tiger;
