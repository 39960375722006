import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useState } from "react";
import { useStores } from "../../../mobx-stores";
import { CategoryChartSeriesData } from "v2/helpers/charts";
import LoadingIndicator from "../../../components/loader/loader";
import { IChartScope } from "./chart-scope";
import useDeepCompareEffect from "use-deep-compare-effect";
import _ from "lodash";
import { Grid } from "semantic-ui-react";
import PieChart from "./pie-chart";
import { ChartExternalLegend, ILegendList } from "./legend";
import uniqolor from "../../../../utils/color";
import { DeploymentFrequencyEmptyState } from "./deployment-frequency-empty-state/deployment-frequency-empty-state";
import colorScheme, { normalizeSeriesKey } from "./chart-color-scheme";

interface IProps {
	scope: IChartScope;
	showExternalLegend?: boolean;
	showDataLabels?: boolean;
	chartHeight: {
		mainChartHeight: number,
		summaryPieChartHeight: number
	};
	onClickEmptyStateMissingInfo: () => void;
}

interface ISeriesProps {
	seriesName: string;
	seriesColorHex: string;
	sortOrder?: number;
}

const DISPLAY_PROPERTIES: { [key: string]: ISeriesProps } = {
	[normalizeSeriesKey("Production")]: {
		sortOrder: 0,
		seriesName: "Production",
		seriesColorHex: colorScheme.deployments.production
	},
	[normalizeSeriesKey("Staging")]: {
		sortOrder: 1,
		seriesName: "Staging",
		seriesColorHex: colorScheme.deployments.staging
	}
};
function seriesByDeploymentName(deploymentName: string): ISeriesProps {
	const key = normalizeSeriesKey(deploymentName);
	const displayProperties = DISPLAY_PROPERTIES[key];
	if (displayProperties) {
		return displayProperties;
	}

	return {
		seriesName: deploymentName,
		seriesColorHex: uniqolor(key),
		sortOrder: undefined
	};
}

function GitHubReportedDeploymentFrequency(props: IProps) {
	const { metricOrgStore } = useStores();
	const [expandedChartOptions, setExpandedChartOptions] = useState<Highcharts.Options | undefined>(undefined);
	const [summarizedChartOptions, setSummarizedChartOptions] = useState<Highcharts.Options | undefined>(undefined);
	const [summarizedLegendList, setSummarizedLegendList] = useState<{ data: ILegendList[], title?: string } | undefined>(undefined);
	const [hoveredSummaryMetric, setHoveredSummaryMetric] = useState<undefined | string>(undefined);
	const [shouldShowEmptyState, setShouldShowEmptyState] = useState<boolean>(false);

	const { scope, showDataLabels, showExternalLegend, chartHeight } = { ...props };

	function buildExpandedChartOptions(data: CategoryChartSeriesData): Highcharts.Options {
		let series: Highcharts.SeriesColumnOptions[] | undefined;
		series = Object
			.keys(data)
			.filter(key => _.sumBy(data[key], d => d[1]) > 0)
			.map(key => {
				const displayProperties = seriesByDeploymentName(key);
				const seriesOptions: Highcharts.SeriesColumnOptions = {
					name: displayProperties.seriesName,
					index: displayProperties.sortOrder,
					legendIndex: displayProperties.sortOrder,
					color: displayProperties.seriesColorHex,
					data: _.cloneDeep(data[key]),
					visible: (data[key].length > 0 && _.findIndex(data[key], i => i[1] > 0) >= 0),
					tooltip: {
						headerFormat: '<span style="color:{point.color}">\u25CF</span><b> {series.name}</b>:<br>',
						pointFormatter: function _pointFormatter() {
							return `${this.y} deployments<br />`;
						},
						followPointer: true
					},
					showInLegend: showExternalLegend ? false : true,
					type: "column"
				};
				return seriesOptions;
			});
		const options: Highcharts.Options = {
			title: undefined,
			chart: chartHeight ? { height: chartHeight?.mainChartHeight } : {},
			xAxis: {
				gridLineWidth: 1,
				type: "datetime"
			},
			tooltip: {
				useHTML: true,
				style: {
					pointerEvents: "auto"
				}
			},
			yAxis: [
				{
					min: 0,
					title: {
						text: "Deployments"
					},
					labels: {
						formatter: function _formatter() {
							return `${this.value}`;
						}
					}
				}
			],
			credits: {
				enabled: false
			},
			series
		};

		return options;
	}

	function buildSummarizedChartOptions(data: CategoryChartSeriesData): Highcharts.Options {
		const piePoints = Object
			.keys(data)
			.map(key => {
				const displayProperties = seriesByDeploymentName(key);
				const totalValues = _.sumBy(data[key], d => d[1]);

				const seriesOptions: Highcharts.PointOptionsObject = {
					name: displayProperties.seriesName,
					color: displayProperties.seriesColorHex,
					y: totalValues,
				};
				return seriesOptions;
			})
			.filter(point => point.y && point.y > 0);
		setSummarizedLegendList({
			data: ((array: Highcharts.PointOptionsObject[]): ILegendList[] => {
				return array.map(point => {
					return {
						name: point.name?.toString() ?? "",
						value: point.y ?? 0,
						color: point.color?.toString(),
					};
				});
			})(piePoints),
			title: "Total deployments"
		});

		const pieSeries: Highcharts.SeriesPieOptions = {
			type: "pie",
			data: piePoints,
			innerSize: "80%",
			tooltip: {
				pointFormat: "<b>{point.percentage:.1f}%</b>"
			}
		};
		const options: Highcharts.Options = {
			chart: {
				plotBackgroundColor: undefined,
				plotBorderWidth: undefined,
				plotShadow: false
			},
			title: {
				text: `% out of total deployments`,
				verticalAlign: "middle",
				floating: true
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: "pointer",
					dataLabels: showDataLabels ? {
						enabled: true,
						format: "<b>{point.name}</b>: {point.y} hr"
					} : {
						enabled: false
					}
				}
			},
			credits: {
				enabled: false
			},
			series: [pieSeries]
		};

		return options;
	}

	async function fetchData(): Promise<CategoryChartSeriesData | undefined> {
		return metricOrgStore.fetchGitHubReportedDeploymentFrequency(scope.interval, scope.startTime,
			scope.endTime, scope.timezone, undefined, scope.dataContributorIds, scope.repositoryIds, ["Production", "Staging"]);
	}

	function shouldFetchData(): boolean {
		return (!metricOrgStore.isLoadingGitHubReportedDeploymentFrequencyChartData);
	}

	function isDataEmpty(data: CategoryChartSeriesData): boolean {
		const items = (Object
			.keys(data)
			.map(key => _.some(data[key], d => d[1] > 0)))
			.filter(i => i === true);
		return (items.length === 0);
	}

	useDeepCompareEffect(() => {
		let isMounted = true;
		async function fetchChartData() {
			const data = await fetchData();
			if (isMounted && data) {
				if (isDataEmpty(data)) {
					setShouldShowEmptyState(true);
				} else {
					setShouldShowEmptyState(false);
					setExpandedChartOptions(buildExpandedChartOptions(data));
					setSummarizedChartOptions(buildSummarizedChartOptions(data));
				}
			}
		}

		if (shouldFetchData()) {
			// We need to clear those options as we might have a different count of columns which will cause to a crash in highcharts
			setExpandedChartOptions(undefined);
			setSummarizedChartOptions(undefined);
			// tslint:disable-next-line: no-floating-promises
			fetchChartData().then(() => {
				isMounted = false;
			});
		}
	}, [props]);

	return (
		<LoadingIndicator local={true} isActive={expandedChartOptions === undefined && !shouldShowEmptyState}>
			{expandedChartOptions && (
				<Grid>
					<Grid.Column width={10}>
						{expandedChartOptions && (
							<HighchartsReact
								highcharts={Highcharts}
								options={expandedChartOptions}
							/>
						)}
					</Grid.Column>
					<Grid.Column width={6}>
						{summarizedLegendList && (
							<h2 className="ui title acu-capitalize">
								{summarizedLegendList?.title}
							</h2>
						)}
						{showExternalLegend && (
							<div className="ui grid full-height">
								<Grid.Column width={8}>
									{summarizedChartOptions &&
										<div className="pie-chart-container">
											<PieChart
												series={summarizedChartOptions?.series}
												triggerHover={hoveredSummaryMetric}
												height={chartHeight ? chartHeight?.summaryPieChartHeight : undefined}
											/>
										</div>
									}
								</Grid.Column>
								<Grid.Column width={8}>
									<div className="legend-container">
										{summarizedChartOptions?.series !== undefined &&
											<ChartExternalLegend
												legendList={summarizedLegendList?.data}
												hoveredEntry={hoveredSummaryMetric}
												setHoveredEntry={setHoveredSummaryMetric}
											/>}
									</div>
								</Grid.Column>
							</div>
						)}
						{summarizedChartOptions && !showExternalLegend && (
							<HighchartsReact
								highcharts={Highcharts}
								options={summarizedChartOptions}
							/>
						)}
					</Grid.Column>
				</Grid>
			)}
			{shouldShowEmptyState && <DeploymentFrequencyEmptyState onClickEmptyStateMissingInfo={props.onClickEmptyStateMissingInfo}/>}
		</LoadingIndicator>
	);
}

export default GitHubReportedDeploymentFrequency;
