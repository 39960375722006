import * as React from "react";
const Chick = (props: React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fill="#FCEA2B"
			stroke="#FCEA2B"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M51.989 51.91C63 43 53.76 34.196 53.76 34.196c0-13.088-10.032-17.473-15.272-18.87a10.913 10.913 0 0 0-5.88.053c-15.466 4.529-14.275 18.817-14.275 18.817C10 44 19.44 51.467 19.44 51.467s16.606 14.614 32.549.443"
		/>
		<path
			fill="#F1B31C"
			d="M36.705 38.807s10.475-.515 0 9.27c0-.001-10.474-9.27 0-9.27z"
		/>
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="m31.396 15.596-4.428-3.321M34.054 14.489l-1.772-3.764M37.596 15.153l.222-5.757"
		/>
		<circle cx={28.961} cy={32.425} r={3} />
		<circle cx={44} cy={32} r={3} />
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M51.989 51.91C63 43 53.76 34.196 53.76 34.196c0-13.088-10.032-17.473-15.272-18.87a10.913 10.913 0 0 0-5.88.053c-15.466 4.529-14.275 18.817-14.275 18.817C10 44 19.44 51.467 19.44 51.467s16.606 14.614 32.549.443"
		/>
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M36.705 38.807s10.475-.515 0 9.27c0-.001-10.474-9.27 0-9.27z"
		/>
	</svg>
);
export default Chick;
