import * as React from "react";
const Cow = (props: React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fill="#FFF"
			d="M15 10v3l6 4-1 8 2 8-1 8 2 8 3 2 1 7s0 4 3 5 7 2 7 2l6-2s4-4 4-6v-5l3-7 1-13 1-6-2-9 4-1 3-4V9l-9 2-5-2-8-2-6 2-4 2-10-1z"
		/>
		<path
			fill="#A57939"
			d="m67 16.451-5.58.38-7.332 2.505-2.885 1.29s-10.365 1.46-10.199 7.293c0 0-.464 3.6 1.951 6.148 1.88 1.982 2.99 4.647 2.52 7.339-.31 1.777-1.252 3.53-3.47 4.594l3.59 1.26 1.552 4.437s2.19-.695 3.524-4.111c0 0 .98-5.917.917-6.104-.063-.188-.375-6.063-.375-6.063l-.313-2.062 1.505-1.152 6.12-1.556 4.797-3.302 2.923-4.998L67 16.45zM5.049 16.451l5.58.38 7.332 2.505 2.885 1.29s10.365 1.46 10.198 7.293c0 0 .465 3.6-1.95 6.148-1.88 1.982-2.99 4.647-2.52 7.339.31 1.777 1.252 3.53 3.47 4.594l-3.59 1.26-1.552 4.437s-2.191-.695-3.524-4.111c0 0-.98-5.917-.917-6.104s.375-6.063.375-6.063l.313-2.062-1.505-1.152-6.12-1.556-4.797-3.302-2.923-4.998-.755-5.898z"
		/>
		<path
			fill="#F4AA41"
			d="m27.921 45.336-2.357 7.083.598 5.25 1.887.173 8.374-.663 8.998.992 1.59-1.553.27-5.681-2.364-4.616L34.754 46z"
		/>
		<path d="M26.162 31.06s-3.048-.22-3.829-1a2 2 0 0 1 2.829-2.829c.78.781 1 3.828 1 3.828z" />
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="m19.62 26.235 2.037-9.248s-7.68-.627-6.505-7.445c0 0 9.953 3.292 12.775.47 0 0 6.237-6.664 16.195-.043 3.323 2.21 12.775-.47 12.775-.47 1.176 6.818-6.505 7.445-6.505 7.445l2.038 9.248M28.483 46h15.726c.272 0 .532.114.708.321.847 1 3.144 4.347 2.094 10.297a1.895 1.895 0 0 1-2.203 1.55c-3.476-.64-11.324-1.79-16.76-.326a1.87 1.87 0 0 1-2.343-1.591c-.302-2.511-.315-6.628 2.01-9.868a.945.945 0 0 1 .768-.383z"
		/>
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M29.867 50.277s-.686 2.855 3.154 2.926M29.128 61.466s6.348 7.916 13.793.314M17.518 20.154c-3.064-1.957-7.38-4.587-12.436-3.342 0 0-1.489 14.029 15.362 15.518l1.213.88s-2.29 8.918 1.262 16.102"
		/>
		<path d="M45.887 31.014s3.047-.22 3.828-1a2 2 0 1 0-2.828-2.828c-.78.78-1 3.828-1 3.828z" />
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M43.182 50.234s.686 2.855-3.154 2.926M54.774 19.959c3.048-1.888 7.269-4.402 12.192-3.19 0 0 1.49 14.029-15.36 15.518l-1.214.88s2.538 7.978-1.014 15.162"
		/>
	</svg>
);
export default Cow;
