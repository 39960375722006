import {
	IDashboardAcumenMetricMetadataResponse, AcumenMetricGroupType, MetricInterval, IDashboardAcumenMetricDataResponse
} from "@acumen/dashboard-common";
import { getData } from "../../../services/fetch-helpers";
import { BaseCustomerApiClient } from "./base-customer-api-client";
import moment from "moment-timezone";
import urlJoin from "url-join";
import { qsStringify } from "../../../services/url-routes-helper";

export const METRIC_ROUTE = "metrics";
export const MULTI_METRICS_ROUTE = "multiple-metrics";

export class MetricApiClient extends BaseCustomerApiClient {
	public fetchMetadata = async () => {
		return await getData<IDashboardAcumenMetricMetadataResponse, any>(
			this.createCustomerEntityRoute(`${METRIC_ROUTE}`), this.token
		);
	}

	public fetchMetric = async (metric: AcumenMetricGroupType, label: string,
			interval: MetricInterval, timezone: string, startTime: Date, endTime: Date,
			dimensions?: string[], groupBy?: string, negationDimensions?: string[]) => {
		const metricUrl = urlJoin(METRIC_ROUTE, metric);
		const query: { [label: string]: string | string[] } = {};
		query.label = label;
		query.interval = interval;
		query.timezone = timezone;
		// NOTE: http caching optimization by rounding metric requests to start of an hour
		query.startTime = moment.tz(startTime, timezone).startOf("hour").toISOString(true);
		query.endTime = moment.tz(endTime, timezone).startOf("hour").toISOString(true);

		if (dimensions && dimensions.length > 0) {
			query["dimensions[]"] = dimensions;
		}

		if (negationDimensions && negationDimensions.length > 0) {
			query["negDimensions[]"] = negationDimensions;
		}

		if (groupBy) {
			query.groupBy = groupBy;
		}
		return await getData<IDashboardAcumenMetricDataResponse, any>(
			this.createCustomerEntityRoute(
				`${metricUrl}?${qsStringify(query)}`),
			this.token,
			this.tokenType
		);
	}
}
