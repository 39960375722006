import apiContextProvider from "../../services/api-context-provider";
import { LabelsApiClient, ILabelSort, ILabelFilter, LABELS_ROUTE } from "../services/crud/labels-api-client";
import { observable, action, computed } from "mobx";
import { ISelectOptions } from "../components/form/option-select";
import _ from "lodash";
import { FetchLatestRequest } from "../../services/fetch-helpers";

export default class LabelsStore {
	private readonly apiClient: LabelsApiClient = new LabelsApiClient(apiContextProvider);

	@observable
	labelsData: string[] = [];

	@observable
	isLoading: boolean = false;

	private fetchLatestLabels = new FetchLatestRequest<string[], any>(LABELS_ROUTE);
	@action.bound
	async fetchData(filter?: ILabelFilter, sort?: ILabelSort) {
		this.isLoading = true;
		const result = await this.fetchLatestLabels.fetchLatest(this.apiClient.fetchLabels(filter, sort));

		this.labelsData = result?.data ?? [];
		this.isLoading = false;
	}

	@computed
	public get labelOptions(): ISelectOptions[] {
		return _.orderBy(this.labelsData, l => l.toLowerCase(), "asc").map(label => {
			return {
				key: label,
				value: label,
				label
			};
		});
	}
}
