import {
	ConfigContextValues,
	ConfigParamKeyType,
	ConfigsValues,
	ConfigsValuesByCategory, ConfigurationEntityType, ConfigValuesByContext, CustomizableConfigDefinition, CustomizableConfigInputType, CustomizableConfiguration,
	CustomizableConfigurationCategories, CUSTOMIZABLE_CONFIGURATION_DEFINITION, DashboardCustomizableConfig, DashboardTeamMembershipType, IConfigContextValueWithName, IDashboardResponse, IDetectedDeploymentConfig, IDevCycleConfig, SINGLE_CONFIG_KEY, SupportedConfigurationContextType
} from "@acumen/dashboard-common";
import _ from "lodash";
import moment from "moment";
import apiContextProvider from "../../services/api-context-provider";
import { CustomizationApiClient, IConfigurationsParams, IConfigurationsUpdateParams } from "../services/crud/customization-api-client";
import AuthStore from "./auth-store";
import BaseStore from "./base-store";
import { isConfigValueOptionsPair, isSingleValueConfig } from "../../utils/guards";

export type IConfigFields = IDashboardCustomizableValue[];
export type IConfigFieldsByCategory = Partial<Record<CustomizableConfigurationCategories, IConfigFields>>;
export type ConfigValues = Partial<Record<CustomizableConfiguration, any>>;
type OptionalCustomizationFields = { [K in (ConfigParamKeyType | CustomizableConfiguration)]: any };

export interface IConfigParamsTypes extends configMultiParamsType {
	inputType: Partial<CustomizableConfigInputType.MultiSelectStaticValues | CustomizableConfigInputType.SingleSelectStaticValues>;
	possibleValues: any[];
	name: string;
	single?: DashboardCustomizableConfig;
	position: number;
	dropdownButtonText?: string;
}

export type configMultiParamsType = Record<(keyof (IDevCycleConfig | IDetectedDeploymentConfig)),
	DashboardCustomizableConfig>;

export type customizationValue = IConfigContextValueWithName[] | ConfigContextValues;
export interface IDashboardCustomizableValue extends Partial<OptionalCustomizationFields> {
	groupDescription?: IDashboardCustomizableValue;
	name: CustomizableConfiguration | ConfigParamKeyType;
	groupName?: string;
	currentValue?: customizationValue | { [name: string]: customizationValue };
	refreshPage?: boolean;
	DEFAULT?: customizationValue;
	configParams: Partial<IConfigParamsTypes>;
	key: ConfigParamKeyType;
	fieldName?: CustomizableConfiguration | ConfigParamKeyType;
}

const USER_CONFIG_KEY = "configs";

export default class CustomizationStore extends BaseStore<{}> {
	private readonly customizationApiClient: CustomizationApiClient = new CustomizationApiClient(apiContextProvider);

	constructor(private authStore: AuthStore) {
		super();
	}

	public getConfigurationByCategory =
		async (category: CustomizableConfigurationCategories, teamId?: string): Promise<IConfigFields | undefined> => {
			return this.confDataByTeam({ categories: [category], teamId }).then(categoryOjc => {
				return categoryOjc ? categoryOjc[category] : undefined;
			});
		}

	public updateConfigurationByCategory = async (updateParams: IConfigurationsUpdateParams, entityType: ConfigurationEntityType,
		teamId?: string, rethrowError: boolean = false) => {
		const wrappedConfigs = { ...updateParams.configs };
		Object.keys(wrappedConfigs).forEach((confName) => {
			const validName = customizableConfigurationFromString(confName);
			if (!validName) {
				return;
			}

			let fieldData;
			if (entityType === ConfigurationEntityType.Team) {
				fieldData = [{
					id: teamId,
					configs:
						updateParams.configs[validName]
				}];
			} else if (entityType === ConfigurationEntityType.DataContributor) {
				fieldData = [{
					id: this.authStore.authUser.dataContributorDetails.id,
					configs:
						updateParams.configs[validName]
				}];
			} else {
				fieldData = updateParams.configs[validName];
			}
			wrappedConfigs[validName] = {
				[entityType]: fieldData
			};
		});
		updateParams.configs = wrappedConfigs;
		return this.customizationApiClient.updateCustomization(updateParams, rethrowError);
	}

	public userContexts = (teamId?: string): SupportedConfigurationContextType[] => {
		const emptyContexts: SupportedConfigurationContextType[] = [];
		if (!this.authStore) {
			return emptyContexts;
		}
		const isLead = teamId ? this.authStore.authUser.teamMemberships.find((team: {
			teamId: string;
			type: DashboardTeamMembershipType;
		}) => team.teamId === teamId)?.type === DashboardTeamMembershipType.TeamLeader : false;

		const contexts = this.authStore.userConfigurationContext ?
			this.authStore.userConfigurationContext.filter(con => {
				if (this.authStore.isUserExecutiveOrAbove) {
					return con;
				} else {
					return con === ConfigurationEntityType.Team && isLead;
				}
			}) : emptyContexts;
		return contexts;
	}

	public confDataByTeam = async (params: IConfigurationsParams): Promise<IConfigFieldsByCategory | undefined> => {
		let configWithOptions: IConfigFieldsByCategory = {};
		const configValues = await this.customizationApiClient.fetchCustomization(params!)
			.then((res: null | IDashboardResponse<ConfigsValuesByCategory, IConfigurationsParams>) => {
				if (res && res.data) {
					return res.data;
				}
			});
		if (configValues && configValues !== undefined) {
			Object.entries(configValues)
				.forEach(([name, fields]) => {
					const category = customizableConfigurationCategoriesFromString(name);

					if (fields && category) {
						const def = this.getCategoryWithDefinitions(fields, params?.teamId);
						if (def) {
							configWithOptions = Object.assign(configWithOptions, { [category]: def });
						}
					}
				});
		}
		return configWithOptions as IConfigFieldsByCategory;
	}

	public getCategoriesCurrentValues = (fields: IConfigFields) => {
		const currentValues: Partial<IDashboardCustomizableValue> = {};
		Object.values(fields).forEach((field) => {
			if (field) {
				currentValues[field.name] = field.currentValue;
			}
		});
		return currentValues;
	}

	public getCategoryDefaultValues = (fields: IConfigFields) => {
		const defaultValues: Partial<IDashboardCustomizableValue> = {};
		Object.values(fields).forEach((field) => {
			if (field) {
				defaultValues[field.name] = _.cloneDeep(field.DEFAULT);
			}
		});
		return (defaultValues);
	}

	public getDefinitionByConfigurationName(configName: CustomizableConfiguration): CustomizableConfigDefinition {
		return CUSTOMIZABLE_CONFIGURATION_DEFINITION[configName];
	}

	private shouldShowCategory(configField: CustomizableConfiguration) {
		switch (configField) {
			case CustomizableConfiguration.DevCycle:
				return false;
			default:
				return true;
		}
	}

	private getCategoryWithDefinitions(fields: ConfigsValues, teamId?: string):
		Partial<Array<(IDashboardCustomizableValue | undefined)>> {
		const fieldsWithDefs = Object.keys(fields).map((name) => {
			const confName = customizableConfigurationFromString(name);
			const showCategory = confName && this.shouldShowCategory(confName);
			const definitions: (ConfigValuesByContext | undefined) = fields[name as CustomizableConfiguration];
			if (!confName || !definitions || !showCategory) {
				return undefined;
			}

			const configDefs = this.getDefinitionByConfigurationName(confName);
			const teamsValues = (definitions !== undefined) ? definitions.TEAM : undefined;
			const key = (Object.keys(configDefs.configParams).findIndex((_key) => _key === SINGLE_CONFIG_KEY) > -1 ?
				SINGLE_CONFIG_KEY : confName) as ConfigParamKeyType;

			let currentValue: unknown;
			const _team = _.isArray(teamsValues) && teamsValues?.find((team) => _.toString(team.id) === teamId);
			currentValue = _team ? _team[USER_CONFIG_KEY] : definitions.CUSTOMER_DEFAULT ?? definitions.DEFAULT;

			return {
				name: confName,
				groupName: configDefs.groupName ?? name,
				configParams: !isSingleValueConfig(configDefs.configParams) ? configDefs.configParams : {
					...configDefs.configParams,
					single: {
						...configDefs.configParams.single,
						possibleValues: isSingleValueConfig(currentValue) && isConfigValueOptionsPair(currentValue.single)
							? currentValue.single.possibleValues
							: (configDefs.configParams.single as { possibleValues: unknown[] }).possibleValues
					},
				},
				currentValue,
				key,
				refreshPage: !configDefs.requiresMaterializedViewRefresh,
				...definitions,
			};
		});
		return fieldsWithDefs as Partial<Array<(IDashboardCustomizableValue | undefined)>>;
	}
}

export const getCustomizationCategoryTitle: Record<CustomizableConfigurationCategories, string> = {
	[CustomizableConfigurationCategories.Global]: "Global settings",
	[CustomizableConfigurationCategories.IterationReview]: "Iteration review settings",
	[CustomizableConfigurationCategories.PRSizeToMergeTimeChart]: "PR size to merge time chart settings",
	[CustomizableConfigurationCategories.Team]: "Team settings",
	[CustomizableConfigurationCategories.TeamAnalyticsCharts]: "Team analytics charts settings",
	[CustomizableConfigurationCategories.GoRetro]: "Sprint monitoring configurations",
	[CustomizableConfigurationCategories.DailyDevStats]: "Workforce health configurations",
	[CustomizableConfigurationCategories.TeamComparison]: "Team comparison configurations",
	[CustomizableConfigurationCategories.DailyDevStatsBadgeGeneral]: "General settings",
	[CustomizableConfigurationCategories.DailyDevStatsBadgeVelocity]: "Velocity",
	[CustomizableConfigurationCategories.DailyDevStatsBadgeHighPriorityTasks]: "High priority tasks",
	[CustomizableConfigurationCategories.DailyDevStatsBadgePRSize]: "PR size",
	[CustomizableConfigurationCategories.DailyDevStatsBadgePRCycleTime]: "PR cycle time",
	[CustomizableConfigurationCategories.DailyDevStatsBadgeReviewedPRs]: "Reviewed PRs",
	[CustomizableConfigurationCategories.TeamBadgesGeneral]: "General settings",
	[CustomizableConfigurationCategories.TeamBadgesVelocity]: "Velocity",
	[CustomizableConfigurationCategories.TeamBadgesAvgPRAwaitingReviewTime]: "Avg PR awaiting review time",
	[CustomizableConfigurationCategories.TeamBadgesAvgPRSize]: "AVG PR size",
	[CustomizableConfigurationCategories.TeamBadgesAvgPRCycleTimeBadge]: "AVG PR cycle time",
	[CustomizableConfigurationCategories.TeamBadgesPRReviewersPercentBadge]: "Reviewed PRs percentage",
	[CustomizableConfigurationCategories.TeamBadgesWorkDistributionBugsPercentBadge]: "Work distribution bugs percentage",
	[CustomizableConfigurationCategories.TeamBadgesMTTRBadge]: "MTTR",
};

export const customizableConfigurationFromString = (str: string): CustomizableConfiguration | undefined => {
	return (Object.values(CustomizableConfiguration).find((cc: CustomizableConfiguration) => cc === str));
};

export const customizableConfigurationCategoriesFromString = (str: string): CustomizableConfigurationCategories => {
	switch (str) {
		case CustomizableConfigurationCategories.Global:
			return CustomizableConfigurationCategories.Global;
		case CustomizableConfigurationCategories.IterationReview:
			return CustomizableConfigurationCategories.IterationReview;
		case CustomizableConfigurationCategories.PRSizeToMergeTimeChart:
			return CustomizableConfigurationCategories.PRSizeToMergeTimeChart;
		case CustomizableConfigurationCategories.Team:
			return CustomizableConfigurationCategories.Team;
		case CustomizableConfigurationCategories.TeamAnalyticsCharts:
			return CustomizableConfigurationCategories.TeamAnalyticsCharts;
		case CustomizableConfigurationCategories.GoRetro:
			return CustomizableConfigurationCategories.GoRetro;
		case CustomizableConfigurationCategories.DailyDevStatsBadgeGeneral:
			return CustomizableConfigurationCategories.DailyDevStatsBadgeGeneral;
		case CustomizableConfigurationCategories.DailyDevStatsBadgeVelocity:
			return CustomizableConfigurationCategories.DailyDevStatsBadgeVelocity;
		case CustomizableConfigurationCategories.DailyDevStatsBadgeHighPriorityTasks:
			return CustomizableConfigurationCategories.DailyDevStatsBadgeHighPriorityTasks;
		case CustomizableConfigurationCategories.DailyDevStatsBadgePRSize:
			return CustomizableConfigurationCategories.DailyDevStatsBadgePRSize;
		case CustomizableConfigurationCategories.DailyDevStatsBadgePRCycleTime:
			return CustomizableConfigurationCategories.DailyDevStatsBadgePRCycleTime;
		case CustomizableConfigurationCategories.DailyDevStatsBadgeReviewedPRs:
			return CustomizableConfigurationCategories.DailyDevStatsBadgeReviewedPRs;
		case CustomizableConfigurationCategories.TeamBadgesGeneral:
			return CustomizableConfigurationCategories.TeamBadgesGeneral;
		case CustomizableConfigurationCategories.TeamBadgesVelocity:
			return CustomizableConfigurationCategories.TeamBadgesVelocity;
		case CustomizableConfigurationCategories.TeamBadgesAvgPRAwaitingReviewTime:
			return CustomizableConfigurationCategories.TeamBadgesAvgPRAwaitingReviewTime;
		case CustomizableConfigurationCategories.TeamBadgesAvgPRSize:
			return CustomizableConfigurationCategories.TeamBadgesAvgPRSize;
		case CustomizableConfigurationCategories.TeamBadgesAvgPRCycleTimeBadge:
			return CustomizableConfigurationCategories.TeamBadgesAvgPRCycleTimeBadge;
		case CustomizableConfigurationCategories.TeamBadgesPRReviewersPercentBadge:
			return CustomizableConfigurationCategories.TeamBadgesPRReviewersPercentBadge;
		case CustomizableConfigurationCategories.TeamBadgesWorkDistributionBugsPercentBadge:
			return CustomizableConfigurationCategories.TeamBadgesWorkDistributionBugsPercentBadge;
		case CustomizableConfigurationCategories.TeamBadgesMTTRBadge:
			return CustomizableConfigurationCategories.TeamBadgesMTTRBadge;
		default:
			return CustomizableConfigurationCategories.Global;
	}
};

export const formatValuesToDbByFieldName = (value: string, fieldName: string) => {
	const formatter = VALUE_FORMATTING_BY_FIELDS_TO_SERVER_TYPES[fieldName];
	return formatter ? formatter(value) : value;
};

const VALUE_FORMATTING_BY_FIELDS_TO_SERVER_TYPES: Record<string, (val: string) => number> = {
	durationMs: ((valueInDays: string) => moment.duration(parseInt(valueInDays, 10), "d").asMilliseconds()),
	[CustomizableConfiguration.PlannedGracePeriod]: ((valueInHours: string) => valueInHours ? parseInt(valueInHours, 10) : 0),
	[CustomizableConfiguration.PrSizeToMergeTimeFastThreshold]: ((valueInMinutes: string) => moment.duration(parseInt(valueInMinutes, 10), "h").asMilliseconds()),
	[CustomizableConfiguration.PrSizeToMergeTimeSlowThreshold]: ((valueInDays: string) => moment.duration(parseInt(valueInDays, 10), "d").asMilliseconds()),
};

export const formatValuesToUiByFieldName = (value: string | number | undefined, fieldName: string) => {
	if (value === undefined) {
		return;
	}
	const formatter = VALUE_TO_UI_BY_FIELDS[fieldName];
	return formatter ? formatter(_.toString(value)) : value;
};

const VALUE_TO_UI_BY_FIELDS: Record<string, (val: string) => number> = {
	durationMs: ((valueInDays: string) => moment.duration(parseInt(valueInDays, 10), "milliseconds").asDays()),
	[CustomizableConfiguration.PrSizeToMergeTimeFastThreshold]: ((valueInMinutes: string) => moment.duration(parseInt(valueInMinutes, 10), "milliseconds").asMinutes()),
	[CustomizableConfiguration.PrSizeToMergeTimeSlowThreshold]: ((valueInDays: string) => moment.duration(parseInt(valueInDays, 10), "milliseconds").asDays()),
};
