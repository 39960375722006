export enum GoRetroMessages {
	RedirectToSprintSummary = "redirect-to-sprint-summary",
	RefreshHandshake = "refresh-handshake",
	JiraIntegrationCompleted = "jira-integration-complete",
	ExitIFrame = "exit-iframe",
	FffFetchingFinished = "fff-fetching-complete",
	SubscriptionInactive = "subscription-inactive"
}

export enum GoRetroMessagesWithParam {
	Sprint = "sprint"
}

export const sendMessageToGoRetro = (message: GoRetroMessages) => {
	window.parent.postMessage(message, "*");
};

export const sendMessageToGoRetroWithParam = (message: GoRetroMessagesWithParam, value: string | number) => {
	window.parent.postMessage(`${message}${`=${value}`}`, "*");
};
