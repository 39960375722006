import React from "react";
import { Icon } from "semantic-ui-react";

export interface ISprintDropdownOption {
	key: string;
	value: string;
	label: string;
}

interface ISprintsSelector {
	value?: string;
	setValue: (value: string, label?: string) => void;
	sprintsList?: ISprintDropdownOption[];
}

// tslint:disable-next-line: variable-name
export const SprintsSelector = ({value, setValue, sprintsList}: ISprintsSelector) => {
	if (!sprintsList || sprintsList.length < 1) {
		return <span />;
	}

	return <div className={"acu-filters-selector-section sprint-selector"}>
		<div className="acu-filters-selector-title">Sprints</div>
		<div className="acu-filters-selector-options">
			{
				sprintsList.map((sprint: ISprintDropdownOption) => {
					const selected = value === sprint.value;
					return (
						<div key={sprint.key}
							 onClick={() => setValue(sprint.value, sprint.label)}
							 className={`acu-filters-selector-options-item ${selected ? "--selected" : ""}`}
						>
							{selected && <Icon className="acu-filters-selector-options-checkmark" name="checkmark"/>}
							{sprint.label}
						</div>
					);
				})
			}
		</div>
	</div>;
};
