import apiContextProvider from "../../services/api-context-provider";
import { BoardsApiClient, IBoardSort, IBoardFilter, BOARDS_ROUTE } from "../services/crud/boards-api-client";
import { IDashboardBoard, ITeamBoards } from "@acumen/dashboard-common";
import { observable, action, computed } from "mobx";
import { ISelectOptionGroups } from "../components/form/option-select";
import _ from "lodash";
import { FetchLatestRequest } from "../../services/fetch-helpers";

export default class BoardsStore {
	private readonly apiClient: BoardsApiClient = new BoardsApiClient(apiContextProvider);

	@observable
	public boardsData: ITeamBoards = {
		external: [],
		ownable: []
	};

	@observable
	isLoading = false;

	fetchLatestBoards = new FetchLatestRequest<ITeamBoards, any>(BOARDS_ROUTE);
	@action.bound
	async fetchData(filter?: IBoardFilter, sort?: IBoardSort) {
		this.isLoading = true;

		const result = await this.fetchLatestBoards.fetchLatest(this.apiClient.fetchBoards(filter, sort));

		this.boardsData = result?.data ?? {
			external: [],
			ownable: []
		};
		this.isLoading = false;

		return this.boardsData;
	}

	@computed
	public get boardOptions(): ISelectOptionGroups[] {
		return Object.keys(this.boardsData).map(boardType => {
			return {
				label: boardType,
				options:
					_
					.orderBy(this.boardsData[boardType as keyof ITeamBoards], b => b.name.toLowerCase(), "asc")
					.map((opt: IDashboardBoard) => {
						return {
							key: opt.entityId,
							value: `${opt.entityId},${opt.entityType}`,
							label: opt.name
						};
					})
			};
		});
	}
}
