import apiContextProvider from "../../services/api-context-provider";
import { ProjectsApiClient, IProjectSort, IProjectFilter, PROJECTS_ROUTE } from "../services/crud/projects-api-client";
import { IDashboardProject, ITeamProjects } from "@acumen/dashboard-common";
import { observable, action, computed } from "mobx";
import { ISelectOptionGroups } from "../components/form/option-select";
import _ from "lodash";
import { FetchLatestRequest } from "../../services/fetch-helpers";

export default class ProjectsStore {
	private readonly apiClient: ProjectsApiClient = new ProjectsApiClient(apiContextProvider);

	@observable
	public projectsData: ITeamProjects = {
		external: [],
		ownable: []
	};

	@observable
	isLoading = false;

	private fetchLatestProjects = new FetchLatestRequest<ITeamProjects, any>(PROJECTS_ROUTE);
	@action.bound
	async fetchData(filter?: IProjectFilter, sort?: IProjectSort) {
		this.isLoading = true;

		const result = await this.fetchLatestProjects.fetchLatest(this.apiClient.fetchProjects(filter, sort));

		this.projectsData = result?.data ?? {
			external: [],
			ownable: []
		};
		this.isLoading = false;
	}

	@computed
	public get projectOptions(): ISelectOptionGroups[] {
		return Object.keys(this.projectsData).map(projectCategory => {
			return {
				label: projectCategory,
				options:
					_
					.orderBy(this.projectsData[projectCategory as keyof ITeamProjects], p => p.name.toLowerCase(), "asc")
					.map((opt: IDashboardProject) => {
						return {
							key: opt.entityId,
							value: `${opt.entityId},${opt.entityType}`,
							label: opt.name
						};
					})
			};
		});
	}
}
