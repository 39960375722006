import React, { useState, useEffect } from "react";
import moment from "moment";
import {
	CONFIGURATION_KEYS, MetricInterval, IDashboardSprint, DashboardSortOrder, AcumenTaskType,
	SprintSortField, TimeUnitMetricInterval, DateRangeType, getDatesFromTimeSpan, CustomizableConfigurationCategories, DashboardExportFormat, ChartType, ConfigurationEntityType, DevelopmentMethodology
} from "@acumen/dashboard-common";
import { observer } from "mobx-react";
import { useStores } from "../../../mobx-stores";
import PRSizeToMergeTimeChart, { PR_SIZE_TO_MERGE_TIME_DATA } from "./charts/pr-size-to-merge-time";
import PRCycleTimeChart from "./charts/pr-cycle-time";
import DistributionClassificationChart, { DISTRIBUTION_CLASSIFICATION_DATA } from "./charts/distribution-classification";
import ThroughputIssueCountChart, { THROUGHPUT_ISSUE_COUNT_DATA } from "./charts/throughput-issue-count";
import ThroughputStoryPointChart, { THROUGHPUT_STORY_POINTS_DATA } from "./charts/throughput-story-points";
import DefectDensityChart, { DEFECT_DENSITY_DATA } from "./charts/defect-density";
import IssueDoneByTypeChart, { ISSUES_DONE_BY_TYPE_DATA } from "./charts/issue-done-by-type";
import ClosedAndUnmergedPRsChart from "./charts/closed-and-unmerged-prs";
import WorkClassificationChart, { WORK_CLASSIFICATION_DATA } from "./charts/work-classification";
import PrSizeChart, { PR_SIZE_LOC_DATA } from "./charts/pr-size-loc";
import PrTimeToMergeChart, { PULL_REQUEST_TIME_TO_MERGE_DATA } from "./charts/pr-time-to-merge";
import AbandonedWorkChart, { ABANDONED_WORK_DATA } from "./charts/abandoned-work";
import IssueCycleTimeByTypeChart, { ISSUES_CYCLE_TIME_BY_TYPE_DATA } from "./charts/issue-cycle-time-by-type";
import IssueCycleTimeByComponentChart, { ISSUES_CYCLE_TIME_BY_COMPONENT_DATA } from "./charts/issue-cycle-time-by-component";
import useLocalStorage from "../../../hooks/useLocalStorage";
import ChartCard, { IChartCard, IChartProps } from "../../../components/chart-card";
import useDeepCompareEffect from "use-deep-compare-effect";
import _ from "lodash";
import { clickEvent, GA_EVENT_ACTION, GA_EVENT_CATEGORY } from "../../../analytics-events";
import FiltersTopNavbar from "../../../components/filters-top-navbar";
import { ISelectOptionBase } from "../../../components/form/option-select";
import "./style.scss";
import { MAP_DATE_RANGE_TO_VALUE } from "../../../components/filters/date-range-selector";
import { CLOSED_AND_UNMERGED_PRS, PR_CYCLE_TIME_DATA } from "../../../services/crud/chart-creator";
import {
	TIME_INTERVAL_OPTIONS_TO_LABEL,
	TimeFramesSelector
} from "../../../components/filters-selector/sections/time-frames-selector";
import FiltersSelector from "../../../components/filters-selector/filters-selector";
import CustomizeConfiguration from "../../../components/customize-configurations";

interface IFilterOptions {
	dataContributorIds?: string[];
	projectIds: string[];
	gitRepositoryIds?: string[];
	filterPRToNonDefaultBranch?: boolean;
	filterPRToExcludeDraft?: boolean;
	boardIds: string[];
	issueTypes: AcumenTaskType[];
}

const TIME_SPAN_OPTIONS = [
	DateRangeType.LastMonth, DateRangeType.Last3Months, DateRangeType.Last6Months,
	DateRangeType.LastYear, DateRangeType.Last2Years, DateRangeType.Last3Years
];

// Note: for backward compatibility
type chartsTimeSpan = "LastMonth" | "ThreeMonth" | "SixMonth" | "LastYear" | "LastTwoYears" | "LastThreeYears";

const CHARTS_TIME_SPAN_TO_DATE_RANGE_TYPE: Record<chartsTimeSpan, DateRangeType> = {
	LastMonth: DateRangeType.LastMonth,
	ThreeMonth: DateRangeType.Last3Months,
	SixMonth: DateRangeType.Last6Months,
	LastYear: DateRangeType.LastYear,
	LastTwoYears: DateRangeType.Last2Years,
	LastThreeYears: DateRangeType.Last3Years
};

// tslint:disable-next-line: interface-over-type-literal
type IAnalyticsSettings = {
	interval: Exclude<MetricInterval, MetricInterval.SPRINT | MetricInterval.DEV_CYCLE | MetricInterval.DEV_CYCLE_DATE | MetricInterval.DAYS_30 | MetricInterval.DAYS_7>;
	timeSpan: DateRangeType;
};

// tslint:disable-next-line: interface-over-type-literal
type FilterOptions = {
	projectIds: string[];
	boardIds: string[];
	issueTypes: AcumenTaskType[];
	gitRepositoryIds?: string[];
	filterPRToNonDefaultBranch?: boolean;
	filterPRToExcludeDraft?: boolean;
};

const ANALYTICS_DATA_CONTRIBUTOR_FILTER_LOCAL_STORAGE_KEY = "analytics_data_contributor_filter";
const ANALYTICS_SETTINGS_LOCAL_STORAGE_KEY = "analytics_settings";
const DEFAULT_SETTINGS: IAnalyticsSettings = {
	interval: MetricInterval.MONTH,
	timeSpan: DateRangeType.LastYear
};
const DEFAULT_FILTERS: FilterOptions = {
	projectIds: [],
	boardIds: [],
	issueTypes: [],
	gitRepositoryIds: [],
	filterPRToNonDefaultBranch: false,
	filterPRToExcludeDraft: false,
};

const CHART_MAPPING: Record<ChartType, {
	data: IChartCard["chartData"],
	component: React.FunctionComponent<any>,
	supportExportChart?: boolean,
	supportEmbedChart?: boolean
	isMakerTimeBasedChart?: boolean
}> = {
	[ChartType.PRSizeToMergeTime]: {
		component: PRSizeToMergeTimeChart,
		data: PR_SIZE_TO_MERGE_TIME_DATA
	},
	[ChartType.PRCycleTime]: {
		component: PRCycleTimeChart,
		data: PR_CYCLE_TIME_DATA,
		supportExportChart: true,
		supportEmbedChart: true
	},
	[ChartType.ThroughputIssueCount]: {
		component: ThroughputIssueCountChart,
		data: THROUGHPUT_ISSUE_COUNT_DATA
	},
	[ChartType.ThroughputStoryPoints]: {
		component: ThroughputStoryPointChart,
		data: THROUGHPUT_STORY_POINTS_DATA
	},
	[ChartType.DefectDensity]: {
		component: DefectDensityChart,
		data: DEFECT_DENSITY_DATA
	},
	[ChartType.PRSize]: {
		component: PrSizeChart,
		data: PR_SIZE_LOC_DATA
	},
	[ChartType.PRTimeToMerge]: {
		component: PrTimeToMergeChart,
		data: PULL_REQUEST_TIME_TO_MERGE_DATA
	},
	[ChartType.ClosedUnMergedPrs]: {
		component: ClosedAndUnmergedPRsChart,
		data: CLOSED_AND_UNMERGED_PRS,
		supportExportChart: true,
		supportEmbedChart: true,
	},
	[ChartType.AbandonedWork]: {
		component: AbandonedWorkChart,
		data: ABANDONED_WORK_DATA
	},
	[ChartType.IssueDoneByType]: {
		component: IssueDoneByTypeChart,
		data: ISSUES_DONE_BY_TYPE_DATA
	},
	[ChartType.WorkClassification]: {
		component: WorkClassificationChart,
		data: WORK_CLASSIFICATION_DATA
	},
	[ChartType.DistributionClassification]: {
		component: DistributionClassificationChart,
		data: DISTRIBUTION_CLASSIFICATION_DATA,
		isMakerTimeBasedChart: true
	},
	[ChartType.IssueCycleTimeByType]: {
		component: IssueCycleTimeByTypeChart,
		data: ISSUES_CYCLE_TIME_BY_TYPE_DATA
	},
	[ChartType.IssueCycleTimeByComponent]: {
		component: IssueCycleTimeByComponentChart,
		data: ISSUES_CYCLE_TIME_BY_COMPONENT_DATA
	}
};
const ANALYTICS_FILTER_LOCAL_STORAGE_KEY = "analytics_filters";

function TeamAnalytics() {
	const {
		teamsStore: { singleTeam: { data: { id: teamId } } },
		projectsStore,
		teamMembersStore,
		configurationStore,
		repositoriesStore,
		sprintsStore,
		boardsStore,
		tasksStore,
		chartsStore,
		authStore
	} = useStores();

	const { fetchData: fetchProjectsData, projectOptions, isLoading: isLoadingProjects, isLoading: projectsLoading } = projectsStore;
	const { fetchData: fetchGitRepoData, isLoading: isLoadingGitRepos, gitRepositoriesOptions } = repositoriesStore;
	const { fetchData: fetchSprintsData, isLoading: isLoadingSprintData } = sprintsStore;
	const { taskTypeOptions } = tasksStore;
	const { fetchData: fetchBoardsData, isLoading: isLoadingBoards, boardOptions } = boardsStore;
	const [searchSettings, setSearchSettings] = useLocalStorage<IAnalyticsSettings>(ANALYTICS_SETTINGS_LOCAL_STORAGE_KEY, DEFAULT_SETTINGS, {
		raw: false,
		serializer: (value: IAnalyticsSettings) => JSON.stringify(value),
		deserializer: (value: string) => {
			const settings: IAnalyticsSettings = JSON.parse(value);
			const timeSpan = Object.values(DateRangeType).includes(settings.timeSpan as DateRangeType)
				? settings.timeSpan as DateRangeType
				: CHARTS_TIME_SPAN_TO_DATE_RANGE_TYPE[settings.timeSpan as chartsTimeSpan];

			return {
				interval: settings.interval,
				timeSpan
			};
		}
	});
	const [searchFilters, setSearchFilters] =
		useLocalStorage<FilterOptions>(ANALYTICS_FILTER_LOCAL_STORAGE_KEY, DEFAULT_FILTERS);
	const [dataContributorFilter, setDataContributorFilter] =
		useLocalStorage<string[]>(ANALYTICS_DATA_CONTRIBUTOR_FILTER_LOCAL_STORAGE_KEY, []);

	const [chartList, setChartList] = useState<ChartType[]>([]);
	const [customerSprints, setCustomerSprints] = useState<IDashboardSprint[] | undefined>(undefined);
	const [ownableBoardsValues, setOwnableBoardsValues] = useState<string[] | undefined>(undefined);

	const [searchParameters, setSearchParameters] = useState<{
		filter?: IFilterOptions;
		settings?: IAnalyticsSettings;
		teamId?: string;
	}>({});
	const [reportParams, setReportParams] = useState<IChartProps>({} as unknown as IChartProps);
	const [hasFetchedInitialData, setHasFetchedInitialData] = useState<boolean | undefined>();
	const [shouldRefreshPage, setRefreshPage] = useState<boolean>(false);

	function fetchData() {
		if (!searchParameters.teamId
			|| searchParameters.filter === undefined
			|| searchParameters.settings === undefined
			|| (searchParameters.settings.interval === MetricInterval.SPRINT_DATE && !customerSprints)
			|| authStore.authUser.timezone === undefined
		) {
			return;
		}
		setHasFetchedInitialData(true);

		const timezone = authStore.authUser.timezone;
		const intervalUnit: TimeUnitMetricInterval = [MetricInterval.WEEK, MetricInterval.MONTH].includes(searchParameters.settings.interval)
			? searchParameters.settings.interval as TimeUnitMetricInterval : MetricInterval.DAY;
		const { endTime, startTime } = getDatesFromTimeSpan(searchParameters.settings.timeSpan, timezone, intervalUnit);

		setReportParams({
			teamId: searchParameters.teamId,
			dataContributorIds: dataContributorFilter,
			projectIds: searchParameters.filter.projectIds,
			boardIds: searchParameters.filter.boardIds,
			issueTypes: searchParameters.filter.issueTypes,
			gitRepositoryIds: searchParameters.filter.gitRepositoryIds,
			interval: searchParameters.settings.interval,
			startTime,
			endTime,
			baseIsDefaultBranch: (searchParameters.filter.filterPRToNonDefaultBranch === true) ? true : undefined,
			excludeDraft: (searchParameters.filter.filterPRToExcludeDraft === true) ? true : undefined,
			customerSprints,
			timezone,
			dateRange: searchParameters.settings.timeSpan
		});
	}
	useEffect(() => {
		let isMounted = true;
		async function retrieveProjects(selectedTeamId: string) {
			return selectedTeamId && !isLoadingProjects &&
				await fetchProjectsData({ teamId: selectedTeamId }, { order: DashboardSortOrder.Ascending });
		}
		async function retrieveGitRepositories(selectedTeamId: string) {
			return selectedTeamId && !isLoadingGitRepos &&
				await fetchGitRepoData({ teamId: selectedTeamId }, { order: DashboardSortOrder.Ascending });
		}
		async function retrieveBoards(selectedTeamId: string) {
			if (selectedTeamId && !isLoadingBoards) {
				const response = await fetchBoardsData({ teamId: selectedTeamId }, { order: DashboardSortOrder.Ascending });
				const data = response.ownable.map((opt) => {
					return `${opt.entityId},${opt.entityType}`;
				});
				setOwnableBoardsValues(data);
			}
		}
		async function retrieveSprints(selectedTeamId: string) {
			if (selectedTeamId && !isLoadingSprintData) {
				// Note: we currently don't support dev cycles
				const sprints = await fetchSprintsData(DevelopmentMethodology.Scrum, { teamId: selectedTeamId }, { order: DashboardSortOrder.Descending, field: SprintSortField.EndDate });
				setCustomerSprints(sprints);
			}
		}

		if (!teamId || !isMounted) {
			return;
		}
		// tslint:disable-next-line: no-floating-promises
		retrieveProjects(teamId);
		// tslint:disable-next-line: no-floating-promises
		retrieveGitRepositories(teamId);
		// tslint:disable-next-line: no-floating-promises
		retrieveBoards(teamId);
		// tslint:disable-next-line: no-floating-promises
		retrieveSprints(teamId);
		return () => { isMounted = false; };
	}, [teamId]);

	useEffect(() => {
		if (!teamId) {
			return;
		}
		let isMounted = true;
		async function retrieveMembers(selectedTeamId: string) {
			await teamMembersStore.fetchAllTeamMembers(selectedTeamId);
			if (!isMounted) {
				return;
			}
			if (!previouslySearchedTeamId) {
				const dataContributorIds = dataContributorFilter.filter(dcId =>
					teamMembersStore.allTeamMembers.data.find(teamMember => teamMember.id === dcId));
				setDataContributorFilter(dataContributorIds);
			}
			setSearchParameters((previousParameters) => ({ ...previousParameters, teamId }));
		}
		const previouslySearchedTeamId = searchParameters.teamId;
		setSearchParameters({ ...searchParameters, teamId: undefined });

		if (previouslySearchedTeamId && previouslySearchedTeamId !== teamId) {
			if (!_.isEqual(searchFilters, DEFAULT_FILTERS)) {
				setSearchFilters(DEFAULT_FILTERS);
			}
			setDataContributorFilter([]);
		}

		// tslint:disable-next-line: no-floating-promises
		retrieveMembers(teamId);
		return () => { isMounted = false; };
	}, [teamId]);

	useDeepCompareEffect(() => {
		let isMounted = true;
		if (isMounted) {
			setSearchParameters((previousParameters) => ({ ...previousParameters, filter: searchFilters }));
		}

		return () => { isMounted = false; };
	}, [searchFilters]);
	useDeepCompareEffect(() => {
		let isMounted = true;
		if (isMounted) {
			setSearchParameters((previousParameters) => ({ ...previousParameters, settings: searchSettings }));
		}

		return () => { isMounted = false; };

	}, [searchSettings]);
	useDeepCompareEffect(() => {
		let isMounted = true;
		if (isMounted) {
			setSearchParameters((previousParameters) => ({ ...previousParameters, dataContributorIds: dataContributorFilter }));
		}

		return () => { isMounted = false; };
	}, [dataContributorFilter]);

	useDeepCompareEffect(() => {
		let isMounted = true;
		if (searchParameters && searchParameters.settings && authStore.authUser.timezone && isMounted) {
			fetchData();
		}

		return () => { isMounted = false; };
	}, [searchParameters, authStore.authUser.timezone]);

	useEffect(() => {
		let isMounted = true;
		if (!customerSprints || !isMounted) {
			return;
		}

		if (customerSprints.length === 0 && searchSettings?.interval === MetricInterval.SPRINT_DATE) {
			setSearchSettings(Object.assign({}, searchSettings, { interval: DEFAULT_SETTINGS.interval }));
		}

		if (searchParameters && searchParameters.settings && authStore.authUser.timezone
			&& searchParameters.settings.interval === MetricInterval.SPRINT_DATE && !hasFetchedInitialData) {
			fetchData();
		}

		return () => { isMounted = false; };
	}, [customerSprints, authStore.authUser.timezone]);

	useEffect(() => {
		let isMounted = true;
		if (!ownableBoardsValues || !isMounted) {
			return;
		}
		if (ownableBoardsValues.length === 0 && searchFilters.boardIds?.length === 0) {
			return;
		}
		setSearchFilters(Object.assign({}, searchFilters, { boardIds: ownableBoardsValues }));

		return () => { isMounted = false; };
	}, [ownableBoardsValues]);

	useEffect(() => {
		let isMounted = true;

		async function fetchConfiguration(configurationTeamId: string) {
			return configurationStore.fetch([
				CONFIGURATION_KEYS.dashboard.analytics.charts.list
			], { teamId: configurationTeamId });
		}

		if (teamId && isMounted) {
			setRefreshPage(false);
			// tslint:disable-next-line: no-floating-promises
			fetchConfiguration(teamId)
				.then((configurations) => {
					if (configurations) {
						for (const configurationChartList of Object.values(configurations)) {
							if (_.isArray(configurationChartList)) {
								setChartList(
									configurationChartList.filter((chart: any): chart is ChartType => Object.values(ChartType).includes(chart as ChartType))
								);
							}
						}
					}
				});
		}

		return () => { isMounted = false; };
	}, [teamId, shouldRefreshPage]);

	const selectFields: ISelectOptionBase[] = [
		{
			name: "projectIds",
			label: "projects",
			isLoading: projectsLoading,
			options: projectOptions
		},
		{
			name: "gitRepositoryIds",
			label: "repositories",
			isLoading: isLoadingGitRepos,
			options: gitRepositoriesOptions
		},
		{
			name: "issueTypes",
			label: "Issue Types",
			isLoading: false,
			options: taskTypeOptions
		},
		{
			name: "boardIds",
			label: "boards",
			isLoading: isLoadingBoards,
			options: boardOptions
		}
	];

	const popupCheckboxFields = [
		{
			name: "filterPRToNonDefaultBranch",
			label: "Filter non-default branch PRs"
		},
		{
			name: "filterPRToExcludeDraft",
			label: "Exclude draft PRs"
		}
	];

	const chartExport = async (chart: ChartType, chartProps: IChartProps): Promise<void> => {
		return chartsStore
			.exportChart(DashboardExportFormat.CSV, chart, chartProps.interval!, chartProps.dateRange!, authStore.authUser.timezone,
				(chartProps.teamId) ? [chartProps.teamId] : [], chartProps.dataContributorIds, chartProps.boardIds,
				chartProps.projectIds, chartProps.gitRepositoryIds, chartProps.issueTypes, chartProps.excludeDraft, chartProps.baseIsDefaultBranch)
			.then(exportData => {
				if (exportData) {
					const dateFormat = "YY-MM-DD-HH-mm";
					const element = document.createElement("a");
					const file = new Blob([exportData], { type: "text/csv" });
					element.href = URL.createObjectURL(file);
					element.download = `${_.kebabCase(chart!)}_${chartProps.interval}_${moment(chartProps.startTime).format(dateFormat)}--${moment(chartProps.endTime).format(dateFormat)}.csv`;
					document.body.appendChild(element); // Required for this to work in FireFox
					element.click();
					document.body.removeChild(element);
				}
			});
	};

	const chartEmbed = async (chart: ChartType, chartProps: IChartProps): Promise<string> => {
		return chartsStore
			.generateEmbeddedChart(chart, chartProps.interval!, chartProps.dateRange!, authStore.authUser.timezone, chartProps.teamId ? [chartProps.teamId] : [],
				chartProps.dataContributorIds, chartProps.boardIds, chartProps.projectIds, chartProps.gitRepositoryIds, chartProps.issueTypes,
				chartProps.excludeDraft, chartProps.baseIsDefaultBranch);
	};

	const getDateRangeDisplayName = () => {
		if (!searchSettings?.timeSpan) {
			return "";
		}
		return MAP_DATE_RANGE_TO_VALUE[searchSettings.timeSpan];
	};

	const getIntervalDisplayName = () => {
		if (!searchSettings?.interval) {
			return "";
		}
		return TIME_INTERVAL_OPTIONS_TO_LABEL[searchSettings.interval];
	};

	const filtersDropdownDisplayName = [getDateRangeDisplayName(), getIntervalDisplayName()].filter(name => name.length > 0).join(" - ");

	return (
		<div className="reports-tab ui sixteen column centered grid">
			<FiltersTopNavbar
				filters={searchFilters}
				applyFilters={setSearchFilters}
				popupSelectFields={selectFields}
				popupCheckboxFields={popupCheckboxFields}
				avatarSelectorField={{
					usersList: teamMembersStore.allTeamMembers.data.map(user => { user.id = user.dataContributorId; return user; }),
					singleUser: true,
					onUsersSelected: (selectedUsers: string[]) => {
						setDataContributorFilter(selectedUsers);
					},
					initialSelectedUsers: dataContributorFilter
				}}
				clickEventPage={GA_EVENT_CATEGORY.Analytics}
				classNameString={"right-padding"}
			>
				{teamId && <CustomizeConfiguration
					teamId={teamId}
					entityType={ConfigurationEntityType.Team}
					category={CustomizableConfigurationCategories.TeamAnalyticsCharts}
					refreshPage={() => setRefreshPage(true)}
				/>}
				<div className={"filters-navbar-wrapper"}>
					<FiltersSelector
						placeholder={"Select Filters"}
						value={filtersDropdownDisplayName}
						loading={isLoadingSprintData || isLoadingGitRepos || isLoadingBoards || isLoadingProjects}
						disabled={isLoadingSprintData || isLoadingGitRepos || isLoadingBoards || isLoadingProjects}
					>
						<TimeFramesSelector
							value={(searchSettings?.timeSpan)}
							options={TIME_SPAN_OPTIONS.map(key => ({
								key,
								value: key,
								label: MAP_DATE_RANGE_TO_VALUE[key]
							}))}
							setValue={(value) => {
								if (searchSettings) {
									setSearchSettings(Object.assign({}, searchSettings, { timeSpan: value }));
								}
							}}
							clickEvent={clickEvent(GA_EVENT_CATEGORY.Analytics, GA_EVENT_ACTION.Filter, "dropdown-date-range")}
						/>
						<TimeFramesSelector
							label={"Interval"}
							value={(searchSettings?.interval === MetricInterval.SPRINT_DATE && (!customerSprints || customerSprints.length === 0)) ? DEFAULT_SETTINGS.interval : searchSettings?.interval}
							options={Object.keys(TIME_INTERVAL_OPTIONS_TO_LABEL).map((key: string | number) => {
								const _key = key as keyof typeof TIME_INTERVAL_OPTIONS_TO_LABEL;
								return ({
									key: _key,
									value: key,
									label: TIME_INTERVAL_OPTIONS_TO_LABEL[_key],
									isDisabled: (_key === MetricInterval.SPRINT_DATE && (!customerSprints || customerSprints.length === 0))
								});
							})}
							setValue={(value) => {
								if (searchSettings) {
									setSearchSettings(Object.assign({}, searchSettings, { interval: value }));
								}
							}}
							clickEvent={clickEvent(GA_EVENT_CATEGORY.Analytics, GA_EVENT_ACTION.Filter, "dropdown-date-intervals")}
						/>
					</FiltersSelector>
				</div>
			</FiltersTopNavbar>

			<div className="main-table acu-scroll set-page-z-index">
				<div className="ui sixteen wide column">
					<div className="ui two stackable cards">
						{
							chartList.filter(chart => {
								if (!(chart in CHART_MAPPING)) {
									return false;
								}

								if (CHART_MAPPING[chart].isMakerTimeBasedChart === true) {
									return authStore.shouldShowMakeTimeCharts;
								}
								return true;
							}).map((chart) => {
								// tslint:disable-next-line: variable-name
								const { component: ChartComponent, data: chartData, supportExportChart, supportEmbedChart } = CHART_MAPPING[chart]!;
								return (
									<ChartCard
										key={chart}
										chartData={chartData}
										filters={reportParams}
										eventCategory={GA_EVENT_CATEGORY.Analytics}
										onExportRequest={((supportExportChart ?? false) === true) ? () => chartExport(chart, reportParams) : undefined}
										onEmbedRequest={((supportEmbedChart ?? false) === true) ? async () => chartEmbed(chart, reportParams) : undefined}
									>
										<ChartComponent filters={reportParams} />
									</ChartCard>
								);
							})
						}
					</div>
				</div>
			</div>
		</div>
	);
}

export default observer(TeamAnalytics);
