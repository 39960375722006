import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import "./style.scss";
import { useStores } from "../../../../mobx-stores";
import { DevelopmentMethodology, IDashboardSprint } from "@acumen/dashboard-common";
import { observer } from "mobx-react";
import { useForm, Controller } from "react-hook-form";
import { getSprintLabel } from "../../../../pages/my-team/sprint-retro";
import ChipMultiselect, { ChipMultiselectOption } from "../../../../components/chip-multiselect/chip-multiselect";

const SPRINT_FIELD_NAME = "selectSprints";

export const ConfigurationSprintSelector = observer((props: {
	setSelectedSprintIds: Dispatch<SetStateAction<string[]>>
	selectedSprintIds: string[]
}) => {
	const { setSelectedSprintIds } = props;
	const {
		sprintsStore: {
			fetchData: fetchSprints
		}
	} = useStores();

	const {
		control,
		setValue
	} = useForm({
		defaultValues: {},
	});

	const [allSprints, setAllSprints] = useState<IDashboardSprint[] | undefined>(undefined);
	const [selectedSprints, setSelectedSprints] = useState<IDashboardSprint[] | undefined>(undefined);

	useEffect(() => {
		// Note: currently we don't support dev cycles for go retro customers
		fetchSprints(DevelopmentMethodology.Scrum, { excludeSprintsWithFutureStartDate: true }).then(sprints => setAllSprints(sprints)).catch();
	}, []);

	useEffect(() => {
		if (!allSprints) {
			return;
		}

		const initialSelectedSprints = allSprints.filter(sprint => props.selectedSprintIds.includes(sprint.id));
		setSelectedSprints(initialSelectedSprints);
		setValue(SPRINT_FIELD_NAME, getSprintOptions(initialSelectedSprints));
	}, [allSprints]);

	const handleChange = useCallback((newValue: Array<ChipMultiselectOption<string>>) => {
		setSelectedSprintIds(newValue.map(sprint => sprint.key));
		setValue(SPRINT_FIELD_NAME, newValue);
	}, []);

	const renderChipMultiselect = useCallback(({ value }: { value: any }) => {
		return (
			<ChipMultiselect
				addOptionsLabel="Add Sprints"
				value={value}
				options={getSprintOptions(allSprints ?? [])}
				allowEmpty={false}
				onChange={handleChange}
			/>
		);
	}, [allSprints, handleChange]);

	return (
		<div className="configuration-sprint-selector-wrapper">
			<h4 className="selector-title">Select Sprints</h4>
			<p className="acu-capitalize">Select some sample sprints you would like to review. We will automatically pick up new sprints</p>
			<Controller
				control={control}
				name={SPRINT_FIELD_NAME}
				isLoading={!props.selectedSprintIds}
				defaultValue={selectedSprints}
				render={renderChipMultiselect}
				noOptionsMessage={getNoOptionsMessage}
			/>
		</div>
	);
});

const getSprintOptions = (sprints: IDashboardSprint[]): ReadonlyArray<ChipMultiselectOption<string>> => {
	return sprints.map(sprint => {
		return {
			key: sprint.id,
			value: `${sprint.id},${sprint.type}`,
			label: getSprintLabel(sprint)
		};
	});
};

const getNoOptionsMessage = () => "no sprints available";

export default ConfigurationSprintSelector;
