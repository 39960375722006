import { IDashboardUser } from "@acumen/dashboard-common";
import { APIContextProvider } from "../../../services/api-context-provider";
import { IdBasedCrudApiClient } from "./id-based-crud-api-client";

export const USERS_ROUTE = "users";

export class UsersApiClient extends IdBasedCrudApiClient<IDashboardUser> {
	constructor(apiContextProvider: APIContextProvider) {
		super(USERS_ROUTE, apiContextProvider);
	}
}
