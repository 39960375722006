import { IDashboardDataContributorLabel, IDashboardDataContributorCustomerLabel } from "@acumen/dashboard-common";
import { action, observable } from "mobx";
import apiContextProvider from "../../services/api-context-provider";
import { FetchLatestRequest } from "../../services/fetch-helpers";
import { DataContributorLabelsApiClient } from "../services/crud/data-contributor-labels-api-client";
import BaseStore from "./base-store";

const CONTRIBUTORS_LABELS_ROUTE = "contributors-labels";

export default class DataContributorLabelsStore extends BaseStore<{}> {
	private readonly apiClient: DataContributorLabelsApiClient = new DataContributorLabelsApiClient(apiContextProvider);

	@observable isLoadingAllLabelsData = false;
	@observable allLabelsData: IDashboardDataContributorCustomerLabel[] = [];

	fetchLatestAllLabels = new FetchLatestRequest<IDashboardDataContributorCustomerLabel[], null>(CONTRIBUTORS_LABELS_ROUTE);
	@action.bound
	async findAll() {
		this.isLoadingAllLabelsData = true;
		const response = await this.fetchLatestAllLabels.fetchLatest(
			this.apiClient.findAll()
		);

		if (response) {
			this.allLabelsData = response.data;
		}

		this.isLoadingAllLabelsData = false;
	}

	@action.bound
	async associate(dcId: string, labelId: string) {
		const response = await this.apiClient.associate(dcId, labelId);
		return (response ? response.data : undefined);
	}

	@action.bound
	async disassociate(dcId: string, labelId: string) {
		await this.apiClient.disassociate(dcId, labelId);
	}

	@action.bound
	async createAndAssociateNewCustomerLabel(dcId: string, labelName: string) {
		const newCustomerLabelResponse = await this.apiClient.createNewCustomerLabel(labelName);
		if (newCustomerLabelResponse && newCustomerLabelResponse.data) {
			if (this.allLabelsData &&
				this.allLabelsData.find(ld => ld.customerLabelId === newCustomerLabelResponse.data.customerLabelId) === undefined) {
				this.allLabelsData.push(newCustomerLabelResponse.data);
			}
			const response = await this.apiClient.associate(dcId, newCustomerLabelResponse.data.customerLabelId);
			return (response ? response.data : undefined);
		}
		return undefined;
	}

	@action.bound
	async updateLabels(dcId: string, newLabelNames: string[], associateCustomerLabelIds: string[], disassociateCustomerLabelIds: string[]) {
		if (disassociateCustomerLabelIds.length > 0) {
			await Promise.all(disassociateCustomerLabelIds.map(t =>
				this.disassociate(dcId, t)));
		}
		const userLabels: IDashboardDataContributorLabel[] = [];

		if (newLabelNames.length > 0) {
			const items =
				(await Promise.all(newLabelNames.map(t =>
					this.createAndAssociateNewCustomerLabel(dcId, t))))
					.filter((i): i is NonNullable<typeof i> => !!i);
			userLabels.push(...items);
		}

		if (associateCustomerLabelIds.length > 0) {
			const items =
				(await Promise.all(associateCustomerLabelIds.map(t =>
					this.associate(dcId, t))))
					.filter((i): i is NonNullable<typeof i> => !!i);
			userLabels.push(...items);
		}

		return userLabels;
	}
}
