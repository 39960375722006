import React, { useMemo } from "react";
import { DataContributorProfilePicture } from "../data-contributor-profile-picture/data-contributor-profile-picture";
import "./developer-stats-team-trend-members.scss";
import { IDeveloperBasicDetails } from "@acumen/dashboard-common";

export interface DeveloperStatsTeamTrendMembersProps {
	contributors: IDeveloperBasicDetails[];
	maxMembersToShow: number;
	size?: number;
	justify?: "flex-start" | "flex-end";
}

export const DeveloperStatsTeamTrendMembers = (props: DeveloperStatsTeamTrendMembersProps) => {
	const PIXEL_OVERLAP = 8;
	const { contributors, maxMembersToShow, size = 25, justify = "flex-end" } = props;
	const maxProfilePicturesToShow = contributors.length - maxMembersToShow > 1
		? maxMembersToShow
		: maxMembersToShow + 1;
	const contributorsToShow = useMemo(
		() => contributors.slice(0, maxProfilePicturesToShow),
		[contributors, maxProfilePicturesToShow]
	);
	const showExtraMembersCircle = contributors.length - maxMembersToShow;
	const translateX = (index: number) => (
		(justify === "flex-end" ? contributorsToShow.length : 0)
		- index
		+ (showExtraMembersCircle > 1 ? 0 : (justify === "flex-end" ? -1 : 0))
	) * PIXEL_OVERLAP;

	return (
		<div
			className="developer-stats-team-trend-members"
			style={{
				justifyContent: justify
			}}
		>
			{contributorsToShow.map((contributor, index) => (
				<DataContributorProfilePicture
					key={contributor.dataContributorId}
					contributor={contributor}
					size={size}
					style={{
						transform: `translateX(${translateX(index)}px)`,
					}}
				/>
			))}

			{showExtraMembersCircle > 1 && (
				<DataContributorProfilePicture
					text={`+${contributors.length - maxMembersToShow}`}
					size={size + 1}
					style={{
						fontSize: `${size / 2.3}px`,
						transform: `translateX(${translateX(contributorsToShow.length)}px)`,
					}}
				/>
			)}
		</div>
	);
};
