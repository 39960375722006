import { getData, postData } from "../../../services/fetch-helpers";
import { BaseCustomerApiClient } from "./base-customer-api-client";
import {
	DashboardRetroReportExpandOptions,
	IDashboardBurndownData,
	IDashboardDataContributor,
	IDashboardRetroReportData,
	IDashboardRetroReportIssuesData, IDashboardSprint, SubtaskSprintStrategy, TaskEstimationMethod,
	IRetroReportTasksStateBodyParameters, IRetroReportTasksStateResponse,
	IDashboardRetroReportEpics,

} from "@acumen/dashboard-common";
import urlJoin from "url-join";
import { TokenType } from "../../../services/api-context-provider";
import { qsStringify } from "../../../services/url-routes-helper";
import { DevelopmentMethodology } from "@acumen/database-types";

export interface ISprintRetroRequestScope {
	teamId?: string;
	sprint: IDashboardSprint;
	dataContributorIds?: string[];
	subtaskStrategy: SubtaskSprintStrategy;
	estimationMethod: TaskEstimationMethod;
	excludeTasksWithNonTeamDCs: boolean;
	expandedFields?: DashboardRetroReportExpandOptions[];
	developmentMethodology: DevelopmentMethodology;
}

export interface ISprintIssuesRequestScope {
	sprint: IDashboardSprint;
	dataContributorIds?: string[];
	subtaskStrategy: SubtaskSprintStrategy;
	estimationMethod: TaskEstimationMethod;
	developmentMethodology: DevelopmentMethodology;
	includeUnassigned: boolean;
}

export interface IEpicsRetroRequestScope {
	subtaskSprintStrategy: SubtaskSprintStrategy;
	taskEstimationMethod: TaskEstimationMethod;
	taskIds: string[];
	startTime: Date;
	endTime: Date;
}

export interface IBurndownRequestScope {
	teamId?: string;
	sprint: IDashboardSprint;
	dataContributorIds?: string[];
	subtaskStrategy: SubtaskSprintStrategy;
	estimationMethod: TaskEstimationMethod;
	excludeTasksWithNonTeamDCs?: boolean;
	developmentMethodology: DevelopmentMethodology;
	timezone: string;
	countryCode: string;
	boardIds: string[];
	includeUnassigned: boolean;
}

export interface IBurndownRequestPayload {
	teamId?: string;
	sprint: string;
	dataContributorIds?: string[];
	subtaskSprintStrategy: SubtaskSprintStrategy;
	estimationMethod: TaskEstimationMethod;
	developmentMethodology: DevelopmentMethodology;
	excludeTasksWithNonTeamDCs?: boolean;
	timezone: string;
	countryCode: string;
	boardIds: string[];
	includeUnassigned: boolean;
}

export interface IIssuesRequestPayload {
	sprint: string;
	dataContributorIds?: string[];
	subtaskSprintStrategy: SubtaskSprintStrategy;
	taskEstimationMethod: TaskEstimationMethod;
	developmentMethodology: DevelopmentMethodology;
	includeUnassigned: boolean;
}

export const BURNDOWN_RETRO_ROUTE = "reports/burndown";
export const REPORT_ROUTE = "reports/retro";
export const ISSUES_SUB_ROUTE = "issues";
export const EPICS_SUB_ROUTE = "epics";
export const TASKS_STATE_IN_SPRINT_ROUTE = "tasks-state-in-sprint";
export const PARTICIPANTS_IN_SPRINT_ROUTE = "participants";
export class SprintRetroApiClient extends BaseCustomerApiClient {

	public fetchBurndownData = async (scope: IBurndownRequestScope) => {
		return await postData<IBurndownRequestPayload, IDashboardBurndownData, void>(
			this.createCustomerEntityRoute(`${BURNDOWN_RETRO_ROUTE}`),
			this.token,
			this.buildBurndownPayloadFromScope(scope),
			this.tokenType
		);
	}

	public fetchSprintRetroData = async (scope: ISprintRetroRequestScope) => {
		return await getData<IDashboardRetroReportData, void>(
			this.createCustomerEntityRoute(`${REPORT_ROUTE}?${qsStringify(this.buildQueryFromScope(scope))}`),
			this.token
		);
	}

	public fetchIssues = async (scope: ISprintIssuesRequestScope) => {
		return await postData<IIssuesRequestPayload, IDashboardRetroReportIssuesData, void>(
			this.createCustomerEntityRoute(`${urlJoin(REPORT_ROUTE, ISSUES_SUB_ROUTE)}`),
			this.token,
			this.buildIssuesPayloadFromScope(scope),
			this.tokenType
		);
	}

	public fetchEpics = async (scope: IEpicsRetroRequestScope) => {
		return await getData<IDashboardRetroReportEpics, void>(
			this.createCustomerEntityRoute(`${urlJoin(REPORT_ROUTE, EPICS_SUB_ROUTE)}?${qsStringify(this.buildEpicsQueryFromScope(scope))}`),
			this.token
		);
	}

	public fetchTasksState = async (scope: IRetroReportTasksStateBodyParameters) => {
		let route = urlJoin(REPORT_ROUTE, TASKS_STATE_IN_SPRINT_ROUTE);
		if (this.tokenType === TokenType.AcumenToken) {
			route += `?token=${this.token}`;
		}

		return await postData<IRetroReportTasksStateBodyParameters, IRetroReportTasksStateResponse, any>(
			this.createCustomerEntityRoute(route),
			this.token,
			scope
		);
	}

	public fetchParticipants = async (sprintId: string) => {
		const query: Record<string, string> = {};
		query.sprintId = sprintId;

		return await getData<IDashboardDataContributor[], any>(
			this.createCustomerEntityRoute(`${urlJoin(REPORT_ROUTE, PARTICIPANTS_IN_SPRINT_ROUTE)}?${qsStringify(query)}`),
			this.token,
			this.tokenType
		);
	}

	private buildQueryFromScope(scope: ISprintRetroRequestScope) {
		const query: Record<string, string | undefined | string[]> = {
			teamId: scope.teamId,
			sprint: `${scope.sprint.id},${scope.sprint.type},${scope.sprint.integrationType}`
		};
		if (scope.dataContributorIds) {
			query["dataContributorIds[]"] = scope.dataContributorIds;
		}
		if (scope.expandedFields) {
			query["expand[]"] = scope.expandedFields;
		}
		return query;
	}

	private buildEpicsQueryFromScope(scope: IEpicsRetroRequestScope) {
		const query: Record<string, string | string[] | Date> = {
			"subtaskSprintStrategy": scope.subtaskSprintStrategy,
			"taskEstimationMethod": scope.taskEstimationMethod,
			"taskIds[]": scope.taskIds,
			"startTime": scope.startTime,
			"endTime": scope.endTime
		};

		return query;
	}

	private buildIssuesPayloadFromScope(scope: ISprintIssuesRequestScope): IIssuesRequestPayload {
		return {
			sprint: `${scope.sprint.id},${scope.sprint.type},${scope.sprint.integrationType}`,
			subtaskSprintStrategy: scope.subtaskStrategy,
			taskEstimationMethod: scope.estimationMethod,
			developmentMethodology: scope.developmentMethodology,
			dataContributorIds: scope.dataContributorIds ?? [],
			includeUnassigned: scope.includeUnassigned
		};
	}

	private buildBurndownPayloadFromScope(scope: IBurndownRequestScope): IBurndownRequestPayload {
		return {
			sprint: `${scope.sprint.id},${scope.sprint.type},${scope.sprint.integrationType}`,
			timezone: scope.timezone,
			countryCode: scope.countryCode,
			estimationMethod: scope.estimationMethod,
			subtaskSprintStrategy: scope.subtaskStrategy,
			teamId: "teamId" in scope ? scope.teamId : undefined,
			boardIds: scope.boardIds,
			includeUnassigned: scope.includeUnassigned,
			excludeTasksWithNonTeamDCs: "excludeTasksWithNonTeamDCs" in scope ? scope.excludeTasksWithNonTeamDCs : undefined,
			dataContributorIds: scope.dataContributorIds ?? [],
			developmentMethodology: scope.developmentMethodology
		};
	}
}
