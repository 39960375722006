import BaseStore, { IBaseStore, ILoadable } from "./base-store";
import { action, observable } from "mobx";

import apiContextProvider from "../../services/api-context-provider";
import { TeamsApiClient, TEAMS_ROUTE } from "../services/crud/teams-api-client";

import {
	IDashboardTeam, IDashboardResponse, IDashboardWorkIntervalWithEntities,
	IDashboardTeamAddPartial, IDashboardTeamUpdatePartial, IDashboardTeamOwnableEntityLink
} from "@acumen/dashboard-common";
import { OwnableEntitiesClient } from "../services/crud/ownable-entities-client";
import { FetchLatestRequest } from "../../services/fetch-helpers";
import _ from "lodash";

export interface ITeamsState {
	allTeams: ILoadable<IDashboardTeam[]>;
	singleTeam: ILoadable<IDashboardTeam>;
	workIntervals: ILoadable<IDashboardWorkIntervalWithEntities[]>;
}

export interface ITeamsStore extends ITeamsState, IBaseStore<ITeamsState> {
	fetchAllTeams: () => Promise<void>;
	deleteTeam: (teamId: string) => Promise<void>;
	updateTeam: (team: IDashboardTeamUpdatePartial, ownableEntities?: IDashboardTeamOwnableEntityLink[]) => Promise<void>;
	createTeam: (team: IDashboardTeamAddPartial) => Promise<string | undefined>;
	resetTeams: () => void;
	setSingleTeam: (team: IDashboardTeam) => void;
	resetSingleTeam: () => void;
	fetchWork: (teamId?: string, startTime?: Date, endTime?: Date) => Promise<void>;
}

const defaults = {
	allTeams: BaseStore.initLoadable([]),
	singleTeam: BaseStore.initLoadable({ id: "" }),
	workIntervals: BaseStore.initLoadable([]),
};

export default class TeamsStore extends BaseStore<ITeamsState> implements ITeamsStore {
	private readonly apiClient: TeamsApiClient = new TeamsApiClient(apiContextProvider);
	private readonly ownableEntitiesApiClient: OwnableEntitiesClient = new OwnableEntitiesClient(apiContextProvider);

	@observable
	public allTeams: ILoadable<IDashboardTeam[]> = defaults.allTeams;
	@observable
	public singleTeam: ILoadable<IDashboardTeam> = defaults.singleTeam;
	@observable
	public workIntervals: ILoadable<IDashboardWorkIntervalWithEntities[]> = defaults.workIntervals;

	fetchLatestAllTeams = new FetchLatestRequest<IDashboardTeam[], any>(TEAMS_ROUTE);
	@action.bound
	public async fetchAllTeams() {
		this.allTeams.loading = true;

		const result = await this.fetchLatestAllTeams.fetchLatest(this.apiClient.findAll());

		if (result) {
			const { data } = result;

			this.allTeams = {
				data: _.orderBy(data, t => t.name.toLowerCase(), "asc"),
				metadata: null,
				loaded: true,
				loading: false
			};
		}
	}

	@action.bound
	public async createTeam(team: IDashboardTeamAddPartial): Promise<string | undefined> {
		let result: IDashboardResponse<IDashboardTeam> | null;

		this.singleTeam.loading = true;

		result = await this.apiClient.create(team);

		if (!result) {
			return undefined;
		}
		const { data } = result;

		this.singleTeam = {
			data: data as IDashboardTeam,
			metadata: null,
			loaded: true,
			loading: false
		};

		this.allTeams.data.push(this.singleTeam.data);
		this.allTeams.data = [...this.allTeams.data];

		return data.id;
	}

	@action.bound
	public async updateTeam(team: IDashboardTeamUpdatePartial, ownableEntities?: any | IDashboardTeamOwnableEntityLink) {
		let result: IDashboardResponse<IDashboardTeam> | null;
		this.singleTeam.loading = true;
		if (ownableEntities && ownableEntities.length > 0) {
			await this.ownableEntitiesApiClient.update({ id: team.id, ownableEntities });
		}
		result = await this.apiClient.update(team);

		if (result) {
			const { data } = result;

			this.singleTeam = {
				data: data as IDashboardTeam,
				metadata: null,
				loaded: true,
				loading: false
			};

			const old = this.allTeams.data.find(t => t.id === team.id);

			if (old) {
				const idx = this.allTeams.data.indexOf(old);
				this.allTeams.data[idx] = this.singleTeam.data;
			}
		}
	}

	@action.bound
	public async deleteTeam(teamId: string) {
		this.singleTeam.loading = true;

		await this.apiClient.delete(teamId);

		const team = this.allTeams.data.find(t => t.id === teamId);

		if (team) {
			const idx = this.allTeams.data.indexOf(team);
			this.allTeams.data.splice(idx, 1);
			this.allTeams.data = [...this.allTeams.data];
		}
	}

	@action.bound
	public async fetchWork(teamId?: string, startTime?: Date, endTime?: Date) {
		let result: IDashboardResponse<IDashboardWorkIntervalWithEntities[]> | null;

		this.workIntervals.loading = true;

		result = await this.apiClient.fetchWork(teamId, startTime, endTime);

		if (result) {
			const { data } = result;
			this.workIntervals = {
				data: data as IDashboardWorkIntervalWithEntities[],
				metadata: [],
				loaded: true,
				loading: false
			};
		}
	}

	@action.bound
	public resetTeams() {
		this.allTeams = defaults.allTeams;
	}

	@action.bound
	public setSingleTeam(team: IDashboardTeam) {
		this.singleTeam = {
			data: team,
			metadata: null,
			loaded: true,
			loading: false
		};
	}

	@action.bound
	public resetSingleTeam() {
		this.singleTeam = defaults.singleTeam;
	}
}
