import { IDashboardSSOOrg, IDashboardTokenResponse } from "@acumen/dashboard-common";
import urljoin from "url-join";
import { ROOT_ROUTE } from "v1/services/api-routes";
import { fetchOrTimeout } from "../../../services/fetch-helpers";

export class SSOApiClient {
	public fetchSSOOrg = async (email: string): Promise<IDashboardSSOOrg | null> => {
		const requestOptions: RequestInit = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email
			})
		};

		const result = await fetchOrTimeout<IDashboardSSOOrg, null>(
			urljoin(ROOT_ROUTE, "/v1/sso/query"), requestOptions, false);
		if (!result) {
			return null;
		}

		return result.data;
	}

	public oktaTokenExchange = async (customerId: string, email: string, oktaToken: string): Promise<string | null> => {
		const requestOptions: RequestInit = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email,
				customerId,
				oktaToken
			})
		};

		const result = await fetchOrTimeout<IDashboardTokenResponse, null>(
			urljoin(ROOT_ROUTE, "/v1/sso/okta/validate"), requestOptions, false);
		if (!result || !result.data || !result.data.jwt) {
			return null;
		}

		return result.data.jwt;
	}
}
