import * as React from "react";
const Panda = (props: React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fill="#FFF"
			d="m35.797 14.76-11.163 1.409-4.13 5-5.416 7.828L11.138 39l.46 8.397s.61 5.48 4.425 7.252 4.481 3.854 4.481 3.854S33.246 61.92 36 61.42s14.421-1.833 16.588-3.417c2.166-1.583 4.833-4.232 4.833-4.232l3.547-7.889-.986-9.266-2.894-10.28s-5.25-5.925-5.5-6.379-6.167-3.527-6.167-3.527l-9.624-1.67z"
		/>
		<path
			fill="#3F3F3F"
			d="m26.63 19.585-3.334 3-5.5 2.709-4.042 6.292-3.333-2.334-4-9.333s1.833-5.667 2.333-6.167 5-3 5-3l5.167 1.167 7.708 7.666zM45.296 19.544l3.894 3.293 5.856 3.123 3.042 5.625 3.333-2.333 4-9.333s-1.833-5.667-2.333-6.167-5-3-5-3L52.92 11.92l-7.625 7.625zM34.754 41.002s-3 5.833-6.166 5.667c-3.167-.167-6.417-1.417-6.417-1.417l-1.667-4.083 1.5-3.333 2.334-2.5 3.083-1.334 4-.083 1.417 1.417 1.916 5.666zM37.338 41.002s3 5.833 6.166 5.667c3.167-.167 6.417-1.417 6.417-1.417l1.667-4.083-1.5-3.333-2.334-2.5-3.083-1.334-4-.083-1.417 1.417-1.916 5.666zM31.525 46.836s4.938-2.188 8.813.062l-.75 2.438-6.313.5-1.75-3z"
		/>
		<circle cx={28.602} cy={38.999} r={2} />
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M35.968 49.135s-4.07 1.797-4.172-1.21M25.977 18.895S18.29 7.27 11.04 12.082c-6.601 4.382-3.912 13.959.603 16.915"
		/>
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M21.04 57.582C5.102 51.27 13.19 33.57 13.19 33.57s3.644-9.359 9.34-11.609M51.066 57.582c15.938-6.312 7.85-24.013 7.85-24.013s-3.644-9.359-9.34-11.609"
		/>
		<circle cx={43.334} cy={38.999} r={2} />
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M44.657 51.531c-1.22 1.924-4.055 5.119-8.26 2.239h-.857c-4.206 2.88-7.04-.315-8.26-2.239M35.968 49.135s4.07 1.797 4.172-1.21M45.96 18.895s7.687-11.625 14.937-6.813c6.601 4.382 3.911 13.959-.603 16.915M24.634 16.951s8.71-4.93 22.325 0M30.602 59.582c3.592 1.222 7.17 1.231 10.732 0M35.968 49.135 36 53.77M43.938 45.836s7.369 1.613 7.413-4.207c.023-3.021-1.897-5.68-4.607-7.015-3.522-1.735-8.432-2.576-8.432 6.221M28.257 45.836s-7.369 1.613-7.413-4.207c-.023-3.021 1.897-5.68 4.607-7.015 3.522-1.735 8.431-2.576 8.431 6.221"
		/>
	</svg>
);
export default Panda;
