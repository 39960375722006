import React from "react";
import { DeveloperStatsTeamTrend } from "../developer-stats-team-trend/developer-stats-team-trend";
import SuperstarBadge from "../../../../components/svg-assets/superstar-badge.svg";
import OnTheRiseBadge from "../../../../components/svg-assets/on-the-rise-badge.svg";
import TeamPlayerBadge from "../../../../components/svg-assets/team-player-badge.svg";
import NeedsAttentionBadge from "../../../../components/svg-assets/needs-attention-badge.svg";
import { DeveloperBadgesData } from "../../../../types/workforce-health";
import "./developer-stats-team-trends-breakdown.scss";
import { DeveloperBadge } from "@acumen/dashboard-common";

export interface DeveloperStatsTeamTrendsBreakdownProps {
	numberOfContributors: number;
	developerBadgesData: DeveloperBadgesData;
}

export const DeveloperStatsTeamTrendsBreakdown = (props: DeveloperStatsTeamTrendsBreakdownProps) => {
	const { numberOfContributors, developerBadgesData } = props;
	const { totals } = developerBadgesData;

	return (
		<div className="developer-stats-team-trends-breakdown">
			<DeveloperStatsTeamTrend
				name="Superstar"
				badge={SuperstarBadge}
				numberOfContributors={numberOfContributors}
				currentValue={totals.current[DeveloperBadge.SuperStar] ?? 0}
				previousValue={totals.previous[DeveloperBadge.SuperStar]}
				isIncreasePositive={true}
			/>
			<DeveloperStatsTeamTrend
				name="On the rise"
				badge={OnTheRiseBadge}
				numberOfContributors={numberOfContributors}
				currentValue={totals.current[DeveloperBadge.OnTheRise] ?? 0}
				previousValue={totals.previous[DeveloperBadge.OnTheRise]}
				isIncreasePositive={true}
			/>
			<DeveloperStatsTeamTrend
				name="Team Player"
				badge={TeamPlayerBadge}
				numberOfContributors={numberOfContributors}
				currentValue={totals.current[DeveloperBadge.TeamPlayer] ?? 0}
				previousValue={totals.previous[DeveloperBadge.TeamPlayer]}
				isIncreasePositive={true}
			/>
			<DeveloperStatsTeamTrend
				name="Needs Attention"
				badge={NeedsAttentionBadge}
				numberOfContributors={numberOfContributors}
				currentValue={totals.current[DeveloperBadge.NeedAttention] ?? 0}
				previousValue={totals.previous[DeveloperBadge.NeedAttention]}
				isIncreasePositive={false}
			/>
		</div>
	);
};
