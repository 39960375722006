import { round } from "@acumen/common";
import { TaskEstimationMethod } from "@acumen/database-types";

export const formatLargeNumber = (value: number, precision = 2) => {
	return value < 1000 ? `${round(value, precision)}` : `${Math.floor(value)}`;
};

export const getShortEstimationMethodName = (estimationMethod: TaskEstimationMethod | undefined): string => {
	switch (estimationMethod) {
		case TaskEstimationMethod.None: {
			return "issues";
		}
		case TaskEstimationMethod.StoryPoints: {
			return "SPs";
		}
		case TaskEstimationMethod.TimeBased: {
			return "hours";
		}
		default: {
			return "";
		}
	}
};
