import React from "react";
import seedrandom from "seedrandom";

const DEFAULT_FILL_COLOR = "#E4ECF9";
const FILL_COLOR_ARRAY = [
	DEFAULT_FILL_COLOR,
	"#D6E1DF",
	"#E2D4FF",
	"#FFF4BC",
	"#EDD2D9",
];

const HeartAvatar = (fillColor: string = DEFAULT_FILL_COLOR) => {
	return (
		<svg viewBox="0 0 41 37" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.03263 18.6096L20.5 37L37.9674 18.6096C39.9091 16.5652 41 13.7924 41 10.9012C41 4.88065 36.3643 0 30.6459 0C27.8999 0 25.2663 1.14852 23.3245 3.19289L20.5 6.16667L17.6755 3.1929C15.7337 1.14852 13.1001 0 10.3541 0C4.63567 0 0 4.88065 0 10.9012C0 13.7924 1.09087 16.5652 3.03263 18.6096Z"
				fill={fillColor}
			/>
		</svg>
	);
};

const SpadeAvatar = (fillColor: string = DEFAULT_FILL_COLOR) => {
	return (
		<svg viewBox="0 0 31 39" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.0769 0L27.9235 14.1847C29.3516 15.7616 30.1538 17.9002 30.1538 20.1302C30.1538 24.774 26.7445 28.5385 22.5389 28.5385C20.5192 28.5385 18.5823 27.6526 17.1542 26.0757L16.4015 25.2447L21.1391 38.5H9.01475L13.7523 25.2446L12.9996 26.0757C11.5715 27.6526 9.63462 28.5385 7.615 28.5385C3.40935 28.5385 0 24.774 0 20.1302C0 17.9002 0.802293 15.7616 2.23038 14.1847L15.0769 0Z"
				fill={fillColor}
			/>
		</svg>
	);
};

const DiamondAvatar = (fillColor: string = DEFAULT_FILL_COLOR) => {
	return (
		<svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				y="20"
				width="28.2842"
				height="28.2842"
				transform="rotate(-45 0 20)"
				fill={fillColor}
			/>
		</svg>
	);
};

const ClubAvatar = (fillColor: string = DEFAULT_FILL_COLOR) => {
	return (
		<svg viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M29.7924 31.2243L23.4261 31.1371L22.3582 31.1324L25.9413 41.1577H13.9696L17.5602 31.1114L10.141 31.0788C9.6016 31.0765 9.06259 31.0271 8.52973 30.933L7.77689 30.9227L7.79374 30.7738C6.17699 30.3589 4.64355 29.5317 3.36029 28.3593C-0.58391 24.7555 -1.13552 18.9384 2.12824 15.3663C3.69555 13.6509 5.95108 12.6932 8.39865 12.704L9.83683 12.7103L10.2514 9.04464C10.5265 6.61256 11.7625 4.29341 13.6876 2.59737C13.9165 2.39569 14.1514 2.20578 14.3915 2.02764C15.8428 0.774947 17.668 0.11133 19.5702 0.0354882C22.1471 -0.187583 24.6925 0.641325 26.4947 2.51246C26.5844 2.58914 26.6731 2.66759 26.7609 2.74781C28.655 4.47838 29.8489 6.8195 30.0799 9.25616L30.4221 12.8642L31.8668 12.884C34.3142 12.9175 36.552 13.9158 38.088 15.6592C41.2867 19.2898 40.63 25.096 36.6212 28.6278C35.3152 29.7785 33.7647 30.5783 32.1383 30.9633L32.1584 31.1754L30.9373 31.1701C30.5568 31.2112 30.1745 31.2296 29.7924 31.2243Z"
				fill={fillColor}
			/>
		</svg>
	);
};

enum CardSuit {
	Heart = "Heart",
	Club = "Club",
	Spade = "Spade",
	Diamond = "Diamond",
}

export const getPlayerAvatar = (playerName: string) => {
	const rng = seedrandom(playerName);

	const enumValues = Object.values(CardSuit);
	const randomCardSuit = Math.floor(rng() * enumValues.length);

	const fillColor =
		FILL_COLOR_ARRAY[Math.floor(rng() * FILL_COLOR_ARRAY.length)];

	switch (enumValues[randomCardSuit]) {
		case CardSuit.Club: {
			return ClubAvatar(fillColor);
		}
		case CardSuit.Spade: {
			return SpadeAvatar(fillColor);
		}
		case CardSuit.Diamond: {
			return DiamondAvatar(fillColor);
		}
		case CardSuit.Heart:
		default: {
			return HeartAvatar(fillColor);
		}
	}
};
