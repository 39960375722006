import React, { useEffect } from "react";
import moment from "moment";
import { IDashboardCycleTimeBreakdown } from "@acumen/dashboard-common";
import CycleTimeBreakdownItem from "./cycle-time-breakdown-item";
import "./style.scss";

interface CycleTimeBreakdownProps {
	cycleTimeBreakdown: IDashboardCycleTimeBreakdown;
	verticalPosition: "top" | "bottom";
}

const CycleTimeBreakdown = (props: CycleTimeBreakdownProps) => {
	const { cycleTimeBreakdown, verticalPosition } = props;
	const statuses = cycleTimeBreakdown.details.statuses;
	const firstStatus = statuses[0];
	const lastStatus = statuses[statuses.length - 1];
	const dateFormat = "D MMMM, YYYY";
	const startDate = firstStatus && moment(firstStatus.started).format(dateFormat);
	const endDate = lastStatus && moment(lastStatus.ended).format(dateFormat);

	useEffect(() => {
		if (statuses.length < 2) {
			/* tslint:disable-next-line */
			console.warn("Status array should contain at least 2 elements.");
		}
	}, [statuses]);

	return (
		<div className="cycle-time-breakdown-container">
			{verticalPosition === "bottom" && (
				<div className="cycle-time-breakdown-arrow cycle-time-breakdown-arrow-bottom"/>
			)}
			<div className="cycle-time-breakdown">
				<div className="cycle-time-breakdown-header">
					<div className="cycle-time-breakdown-title">
						State Progress Breakdown
					</div>

					{(startDate && endDate) && (
						<div className="cycle-time-breakdown-dates">
							{startDate} - {endDate}
						</div>
					)}
				</div>

				<div className="cycle-time-breakdown-content">
					<ul className="cycle-time-breakdown-list">
						<div className="cycle-time-breakdown-list-scrollable-area">
							{statuses.map((status, index) => (
								<CycleTimeBreakdownItem
									key={`${status.acumenTaskStatus}${index}`}
									cycleTimeBreakdown={cycleTimeBreakdown}
									label={status.jiraStatusName}
									index={index}
									currentStatus={status.acumenTaskStatus}
									durationInSeconds={status.totalSeconds}
								/>
							))}
						</div>
						<hr className="cycle-time-breakdown-divider"/>
						<CycleTimeBreakdownItem
							cycleTimeBreakdown={cycleTimeBreakdown}
							label="Actual Cycle Time"
							durationInSeconds={cycleTimeBreakdown.details.totalCycleTimeSeconds ? +cycleTimeBreakdown.details.totalCycleTimeSeconds : null}
							durationInBold={true}
						/>
					</ul>
				</div>
			</div>
			{verticalPosition === "top" && (
				<div className="cycle-time-breakdown-arrow cycle-time-breakdown-arrow-top"/>
			)}
		</div>
	);
};

export default CycleTimeBreakdown;
