import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { Button } from "react-bootstrap";
import { STRINGS } from "../../../../../../../localization";
import { IntegrationWizardState } from "../../../../../../services/crud/integrations-api-client";

import "./integration-flow.scss";

export interface IProps extends IntegrationWizardState {
	onInit: (setTitle?: string) => void;
	onNewIntegration: () => void;
}

export default abstract class IntegrationFlowBase<T extends IProps> extends React.Component<T> {
	protected initialState = {};

	constructor(props: T) {
		super(props);
		this.initFlow();
	}

	protected initFlow(): void {
		this.props.onInit();
	}

	protected onNewIntegrationClick = (): void => {
		const {onNewIntegration} = this.props;

		onNewIntegration();
		this.setState({...this.initialState});
	}

	protected renderPendingStep(pendingText: string) {
		return (
			<>
				<h3>{pendingText}</h3>
				<br/>
				<Dimmer active={true} inverted={true}>
					<Loader inline="centered" size="huge" />
				</Dimmer>
			</>
		);
	}

	protected renderSuccessStep(successText: string) {
		return (
			<>
				<h2>{STRINGS.INTEGRATION_FLOW_SUCCESS_STEP_TITLE}</h2>
				<br/>
				<p>{successText}</p>
				<br/>
				<Button className="btn-rounded" size="lg" onClick={this.onNewIntegrationClick}>
					{STRINGS.INTEGRATION_FLOW_SUCCESS_STEP_ADD_ANOTHER}
				</Button>
			</>
		);
	}
}
