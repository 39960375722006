/* eslint-disable react-hooks/rules-of-hooks */
import BaseStore, { IBaseStore, ILoadable } from "./base-store";
import { action, observable } from "mobx";
import apiContextProvider from "../../services/api-context-provider";
import { MetricApiClient, METRIC_ROUTE } from "../services/crud/metric-api-client";
import {
	IDashboardAcumenMetricMetadataResponse, IDashboardAcumenMetricDataResponse,
	MetricInterval, AcumenMetricGroupType, AcumenPullRequestStatus, AcumenTaskType, pullRequestDashboardStatuses, TUPLE_SEPARATOR, AcumenTaskStatus
} from "@acumen/dashboard-common";
import moment from "moment";
import _ from "lodash";
import { FetchLatestRequest } from "../../services/fetch-helpers";

export interface IMetricState {
	metadata: ILoadable<IDashboardAcumenMetricMetadataResponse>;
}

export interface IDistributionClassificationData {
	sumOfCommitActualEffort: IDashboardAcumenMetricDataResponse;
}

export interface IThroughputData {
	prCount: IDashboardAcumenMetricDataResponse;
	workEstimationCount: IDashboardAcumenMetricDataResponse;
}

export interface IDefectDensityData {
	prCount: IDashboardAcumenMetricDataResponse;
	issueBugCount: IDashboardAcumenMetricDataResponse;
	pullRequestLinesOfCodeChanged: IDashboardAcumenMetricDataResponse;
}

export interface IIssuesDoneByTypeData {
	issueCountByType: IDashboardAcumenMetricDataResponse;
}
export interface IIssuesCycleTimeByTypeData {
	issueCycleTimeByType: IDashboardAcumenMetricDataResponse;
}

export interface IIssuesCycleTimeByComponentData {
	issuesCycleTimeByComponent: IDashboardAcumenMetricDataResponse;
}

export interface IAbandonedWorkData {
	commitCount: IDashboardAcumenMetricDataResponse;
}

export interface IWorkClassificationData {
	issuesWorkIntervalSum: IDashboardAcumenMetricDataResponse;
	commitWorkIntervalSum: IDashboardAcumenMetricDataResponse;
	pullRequestWorkIntervalSum: IDashboardAcumenMetricDataResponse;
}

export interface IPullRequestSizeData {
	pullRequestMergeLineOfCodeChangedBucket: IDashboardAcumenMetricDataResponse;
	pullRequestLinesOfCodeChanged: IDashboardAcumenMetricDataResponse;
}

export interface IPullRequestTimeToMergeData {
	pullRequestMergeHoursBucket: IDashboardAcumenMetricDataResponse;
	pullRequestCycleOpenToMergeSum: IDashboardAcumenMetricDataResponse;
}

export interface IPullRequestSizeDORAData {
	pullRequestMergeCount: IDashboardAcumenMetricDataResponse;
	pullRequestLinesOfCodeSum: IDashboardAcumenMetricDataResponse;
}

export interface IMTTRDORAData {
	averageLeadTime: IDashboardAcumenMetricDataResponse;
}

export interface IDeployFrequencyDORAData {
	deployFrequency: IDashboardAcumenMetricDataResponse;
}

export interface IMetricStore extends IMetricState, IBaseStore<IMetricState> {
	fetchMetadata: () => Promise<void>;
	resetMetadata: () => void;
	fetchMetric: (metric: AcumenMetricGroupType, label: string,
		interval: MetricInterval, timezone: string, startTime: Date, endTime: Date,
		dimensions?: string[], groupBy?: string) => Promise<IDashboardAcumenMetricDataResponse>;
}

const defaults = {
	metadata: BaseStore.initLoadable({}),
};

function createGeneralDimensions(teamId?: string, dataContributorIds?: string[],
	dataContributorKey: string = "data_contributor_id"): { [dim: string]: string[] } {
	const res: Record<string, string[]> = {};

	if (teamId) {
		res.team_id = [`${teamId}`];
	}

	if (dataContributorIds && dataContributorIds.length > 0) {
		res[`${dataContributorKey}`] = dataContributorIds;
	}

	return res;
}

function createGitDimensions(repositoryIds?: string[], baseIsDefaultBranch?: boolean, excludeDraft?: boolean): { [dim: string]: string[] } {
	const res: Record<string, string[]> = {};

	if (baseIsDefaultBranch !== undefined) {
		res[`base_is_default_branch`] = [`${baseIsDefaultBranch}`];
	}
	if (excludeDraft !== undefined) {
		res[`is_draft`] = ["false"];
	}
	const normalizedRepositoryIds = normalizeIdsDimension(repositoryIds);
	if (normalizedRepositoryIds && normalizedRepositoryIds.length > 0) {
		res[`repository_id`] = normalizedRepositoryIds;
	}

	return res;
}

function normalizeIdsDimension(idAndTypes?: string[]): string[] | undefined {
	if (!idAndTypes || idAndTypes.length === 0) {
		return undefined;
	}

	return idAndTypes.map(idAndTypeTuple => {
		const [id, ] = idAndTypeTuple.split(TUPLE_SEPARATOR);
		return id;
	});
}

function createTaskDimensions(addExclusiveNull: boolean, projectIds?: string[], boardIds?: string[],
	taskTypes?: AcumenTaskType[], taskStatus?: AcumenTaskStatus[], priority?: number[]): { [dim: string]: string[] } {
	const res: Record<string, string[]> = {};

	if (projectIds && projectIds.length > 0) {
		const projectIdsToAdd = [...projectIds];
		if (addExclusiveNull === true) {
			// When the user wish to see showing Git and Jira data on the same graph under Sprint Resolution.
			// We face an issue due to the fact that some GH commits are not linked to tickets, and by that
			// not falling to the 'sprint' intervals. Forcing us to use the "Sprint_Date" interval for
			// git based metrics. By passing project id and null we asking the server to include
			// also all GitHub data that is not linked directly to those tasks/sprints. Read more in ACM-2069.
			projectIdsToAdd.push("null");
		}

		const normalizedProjectIds = normalizeIdsDimension(projectIdsToAdd);
		if (normalizedProjectIds && normalizedProjectIds.length > 0) {
			res[`project_id`] = normalizedProjectIds;
		}
	}

	const normalizedBoardIds = normalizeIdsDimension(boardIds);
	if (normalizedBoardIds && normalizedBoardIds.length > 0) {
		// Read comment above for explanation as to why we do this
		if (addExclusiveNull === true) {
			normalizedBoardIds.push("null");
		}

		res[`sprint_board_id`] = normalizedBoardIds;
	}

	if (taskTypes && taskTypes.length > 0) {
		res[`acumen_task_type`] = taskTypes;
	}

	if (taskStatus && taskStatus.length > 0) {
		res[`acumen_status_type`] = taskStatus;
	}
	if (priority && priority.length > 0) {
		res[`priority_order`] = priority.map(p => p.toString());
	}
	return res;
}

function transformGitIntervalIfNeeded(interval: MetricInterval): MetricInterval {
	if (interval === MetricInterval.SPRINT) {
		return MetricInterval.SPRINT_DATE;
	}
	return interval;
}

function convertPRDimensionsToPRWorkIntervalSumDimensions(dimensions: { [dim: string]: string[] }): { [dim: string]: string[] } {
	const res: Record<string, string[]> = {};
	for (const dim of Object.keys(dimensions)) {
		if (dim.startsWith("data_contributor_id")) {
			res[`wi_${dim}`] = dimensions[dim]; // converts data_contributor_id=XXX to wi_data_contributor_id=XXX
		} else {
			res[dim] = dimensions[dim];
		}

	}

	return res;
}

export function generateWastedEffortDimensionsAndInterval(teamId?: string,
	dataContributorIds?: string[],
	interval: MetricInterval = MetricInterval.MONTH, projectIds?: string[],
	repositoryIds?: string[], baseIsDefaultBranch?: boolean, excludeDraft?: boolean,
	boardIds?: string[], taskTypes?: AcumenTaskType[]
) {
	const generalDimensions = createGeneralDimensions(teamId, dataContributorIds);

	let gitDimensions = {
		...generalDimensions,
		...createGitDimensions(repositoryIds, baseIsDefaultBranch, excludeDraft),
		github_closed_unmerged: ["true"]
	};

	if (interval === MetricInterval.SPRINT_DATE) {
		gitDimensions = Object.assign(gitDimensions, createTaskDimensions(true, projectIds, boardIds, taskTypes));
	}

	const prWorkIntervalSumDimensions = convertPRDimensionsToPRWorkIntervalSumDimensions(gitDimensions);

	const gitInterval = transformGitIntervalIfNeeded(interval);

	return { gitDimensions, gitInterval, prWorkIntervalSumDimensions };
}

export function generatePRCycleDimensionsAndInterval(teamId?: string,
	dataContributorIds?: string[], interval: MetricInterval = MetricInterval.MONTH, projectIds?: string[],
	repositoryIds?: string[], baseIsDefaultBranch?: boolean, excludeDraft?: boolean,
	boardIds?: string[], taskTypes?: AcumenTaskType[]) {

	const generalDimensions = createGeneralDimensions(teamId, dataContributorIds);

	const gitPRCountDimensions = {
		...createGitDimensions(repositoryIds, baseIsDefaultBranch, excludeDraft),
		...generalDimensions
	};

	const gitPRCycleTimeDimensions = _.cloneDeep(gitPRCountDimensions);
	gitPRCycleTimeDimensions.pull_request_status = _.flatMap(pullRequestDashboardStatuses, "acumenStatuses");

	if (interval === MetricInterval.SPRINT_DATE) {
		const taskBasedDimensions = createTaskDimensions(true, projectIds, boardIds, taskTypes);
		Object.assign(gitPRCountDimensions, taskBasedDimensions);
		Object.assign(gitPRCycleTimeDimensions, taskBasedDimensions);
	}

	const gitInterval = transformGitIntervalIfNeeded(interval);

	return { gitPRCountDimensions, gitPRCycleTimeDimensions, gitInterval };
}

function transformDimensions(dimensions: { [dim: string]: string[] }): string[] {
	return _.map(dimensions, (val, key) => `${key}=${val.join(",")}`);
}

export default class MetricStore extends BaseStore<IMetricState> implements IMetricState {
	private readonly apiClient: MetricApiClient = new MetricApiClient(apiContextProvider);

	@observable
	public metadata: ILoadable<IDashboardAcumenMetricMetadataResponse> = defaults.metadata;

	@action
	public async fetchMetadata() {
		this.metadata.loading = true;

		const result = await this.apiClient.fetchMetadata();

		if (result) {
			const { data } = result;

			this.metadata = {
				data,
				metadata: null,
				loaded: true,
				loading: false
			};
		}
	}

	private fetchLatestMetric = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(METRIC_ROUTE);
	@action.bound
	public async fetchMetric(metric: AcumenMetricGroupType, label: string,
		interval: MetricInterval, timezone: string, startTime: Date, endTime: Date,
		dimensions?: string[], groupBy?: string) {
		const result =
			await this.fetchLatestMetric.fetchLatest(this.apiClient.fetchMetric(metric, label, interval, timezone, startTime, endTime, dimensions, groupBy));
		return (result?.data ? result?.data : null);
	}

	private jiraMetricEndLabelByInterval(interval: MetricInterval): string {
		switch (interval) {
			case MetricInterval.SPRINT_DATE:
				return "closed_in_sprint_date";
			case MetricInterval.DEV_CYCLE_DATE:
				return "closed_in_dev_cycle_date";
			case MetricInterval.SPRINT:
				return "closure_sprint";
			case MetricInterval.DEV_CYCLE:
				return "closure_dev_cycle";
			default:
				return "cycle_end";
		}
	}

	@action
	public resetMetadata() {
		this.metadata = defaults.metadata;
	}

	@observable
	public distributionClassificationDataStored?: IDistributionClassificationData;

	@action
	public distributionClassificationData() {
		const fetchData = async (teamId: string | undefined = undefined, dataContributorIds: string[] | undefined = undefined,
			projectIds: string[] | undefined = undefined, startTime: Date = moment().subtract(1, "year").toDate(),
			endTime: Date = new Date(), interval: MetricInterval = MetricInterval.MONTH, timezone: string,
			repositoryIds: string[] | undefined = undefined,
			boardIds?: string[], taskTypes?: AcumenTaskType[]): Promise<IDistributionClassificationData | undefined> => {
			const dimensions = createGeneralDimensions(teamId, dataContributorIds);
			if (interval === MetricInterval.SPRINT_DATE) {
				Object.assign(dimensions, createTaskDimensions(true, projectIds, boardIds, taskTypes));
			}

			Object.assign(dimensions, createGitDimensions(repositoryIds));
			const gitInterval = transformGitIntervalIfNeeded(interval);

			const sumOfCommitActualEffortResponse = await this.apiClient
				.fetchMetric(AcumenMetricGroupType.GitHubCommitWorkIntervalSum, "commit_authored",
					gitInterval, timezone, startTime, endTime, transformDimensions(dimensions), "primary_classification");

			const sumOfCommitActualEffort =
				(sumOfCommitActualEffortResponse?.data ? sumOfCommitActualEffortResponse?.data : null);
			if (sumOfCommitActualEffort) {
				const response: IDistributionClassificationData = {
					sumOfCommitActualEffort
				};
				this.distributionClassificationDataStored = response;
				return response;
			}

			return undefined;
		};

		return { fetchData };
	}

	@observable
	public issuesDoneByTypeDataStored?: IIssuesDoneByTypeData;

	@action
	public issuesDoneByTypeData() {
		const fetchData = async (teamId: string | undefined = undefined, dataContributorIds: string[] | undefined = undefined,
			projectIds: string[] | undefined = undefined, startTime: Date = moment().subtract(1, "year").toDate(),
			endTime: Date = new Date(), interval: MetricInterval = MetricInterval.MONTH,
			timezone: string, boardIds?: string[], taskTypes?: AcumenTaskType[]): Promise<IIssuesDoneByTypeData | undefined> => {

			const dimensions = {
				...createGeneralDimensions(teamId, dataContributorIds),
				...createTaskDimensions(false, projectIds, boardIds, taskTypes)
			};

			// Note: the metric is a sprint metric so the interval must update
			// TODO: (ACM-4996) update
			if (interval === MetricInterval.SPRINT_DATE) {
				interval = MetricInterval.SPRINT;
			}

			const jiraLabel = this.jiraMetricEndLabelByInterval(interval);

			const [
				issueCountByTypeResponse,
			] = await Promise.all([
				this.apiClient.fetchMetric(
					AcumenMetricGroupType.JiraIssueCount, jiraLabel,
					interval, timezone, startTime, endTime, transformDimensions(dimensions), "issue_type_name")
			]);

			const issueCountByType = (issueCountByTypeResponse?.data ? issueCountByTypeResponse?.data : null);

			if (issueCountByType) {
				const response: IIssuesDoneByTypeData = {
					issueCountByType
				};
				this.issuesDoneByTypeDataStored = response;
				return response;
			}

			return undefined;
		};

		return { fetchData };
	}

	@observable
	public issuesCycleTimeByTypeDataStored?: IIssuesCycleTimeByTypeData;

	@action
	public issuesCycleTimeByTypeData() {
		const fetchData = async (teamId: string | undefined = undefined, dataContributorIds: string[] | undefined = undefined,
			projectIds: string[] | undefined = undefined, startTime: Date = moment().subtract(1, "year").toDate(),
			endTime: Date = new Date(), interval: MetricInterval = MetricInterval.MONTH,
			timezone: string, boardIds?: string[], taskTypes?: AcumenTaskType[]): Promise<IIssuesCycleTimeByTypeData | undefined> => {

			const dimensions = {
				...createGeneralDimensions(teamId, dataContributorIds),
				...createTaskDimensions(false, projectIds, boardIds, taskTypes)
			};

			// Note: the metric is a sprint metric so the interval must update
			// TODO: (ACM-4996) update
			if (interval === MetricInterval.SPRINT_DATE) {
				interval = MetricInterval.SPRINT;
			}

			const jiraLabel = this.jiraMetricEndLabelByInterval(interval);

			const [
				issueCycleTimeByTypeResponse,
			] = await Promise.all([
				this.apiClient.fetchMetric(
					AcumenMetricGroupType.JiraIssueCycleTimeSum, jiraLabel,
					interval, timezone, startTime, endTime, transformDimensions(dimensions), "issue_type_name")
			]);

			const issueCycleTimeByType = issueCycleTimeByTypeResponse?.data ?? null;

			if (issueCycleTimeByType) {
				const response: IIssuesCycleTimeByTypeData = {
					issueCycleTimeByType
				};
				this.issuesCycleTimeByTypeDataStored = response;
				return response;
			}

			return undefined;
		};

		return { fetchData };
	}

	@observable
	public issuesCycleTimeByComponentDataStored?: IIssuesCycleTimeByComponentData;

	@action
	public issuesCycleTimeByComponentData() {
		const fetchData = async (teamId: string | undefined = undefined, dataContributorIds: string[] | undefined = undefined,
			projectIds: string[] | undefined = undefined, startTime: Date = moment().subtract(1, "year").toDate(),
			endTime: Date = new Date(), interval: MetricInterval = MetricInterval.MONTH,
			timezone: string, boardIds?: string[], taskTypes?: AcumenTaskType[]): Promise<IIssuesCycleTimeByComponentData | undefined> => {

			const dimensions = {
				...createGeneralDimensions(teamId, dataContributorIds),
				...createTaskDimensions(false, projectIds, boardIds, taskTypes)
			};

			// Note: the metric is a sprint metric so the interval must update
			// TODO: (ACM-4996) update
			if (interval === MetricInterval.SPRINT_DATE) {
				interval = MetricInterval.SPRINT;
			}

			const jiraLabel = this.jiraMetricEndLabelByInterval(interval);

			const [
				issueCycleTimeByTypeResponse,
			] = await Promise.all([
				this.apiClient.fetchMetric(
					AcumenMetricGroupType.JiraIssueCycleTimeSum, jiraLabel,
					interval, timezone, startTime, endTime, transformDimensions(dimensions), "component_id")
			]);

			const issuesCycleTimeByComponent = issueCycleTimeByTypeResponse?.data ?? null;

			if (issuesCycleTimeByComponent) {
				const response: IIssuesCycleTimeByComponentData = {
					issuesCycleTimeByComponent
				};
				this.issuesCycleTimeByComponentDataStored = response;
				return response;
			}

			return undefined;
		};

		return { fetchData };
	}

	@observable
	public throughputDataStored?: IThroughputData;

	@action
	public throughputData(options: { countBy?: "story-points" | "issues" } = {}) {
		const fetchData = async (teamId: string | undefined = undefined, dataContributorIds: string[] | undefined = undefined,
			projectIds: string[] | undefined = undefined, startTime: Date = moment().subtract(1, "year").toDate(),
			endTime: Date = new Date(), interval: MetricInterval = MetricInterval.MONTH, timezone: string,
			repositoryIds: string[] | undefined = undefined, baseIsDefaultBranch?: boolean, excludeDraft?: boolean,
			boardIds?: string[], taskTypes?: AcumenTaskType[]): Promise<IThroughputData | undefined> => {

			const gitInterval = transformGitIntervalIfNeeded(interval);
			const generalDimensions = createGeneralDimensions(teamId, dataContributorIds);
			const taskMetricDimensions = createTaskDimensions(false, projectIds, boardIds, taskTypes);

			const gitDimensions = createGitDimensions(repositoryIds);
			if (interval === MetricInterval.SPRINT_DATE) {
				Object.assign(gitDimensions, createTaskDimensions(true, projectIds, boardIds, taskTypes));
			}

			const gitPRDimensions = {
				...gitDimensions,
				...createGitDimensions(undefined, baseIsDefaultBranch, excludeDraft)
			};
			const gitPRWorkIntervalDimensions = convertPRDimensionsToPRWorkIntervalSumDimensions(gitPRDimensions);

			Object.assign(gitPRDimensions, generalDimensions);
			Object.assign(gitDimensions, generalDimensions);
			Object.assign(taskMetricDimensions, generalDimensions);
			Object.assign(gitPRWorkIntervalDimensions, generalDimensions);

			let workEstimationMetric: AcumenMetricGroupType;
			const workEstimationMetricLabel = "cycle_end";

			switch (options.countBy) {
				case "story-points":
					workEstimationMetric = AcumenMetricGroupType.JiraIssuesEstimatedEffortStoryPoints;
					break;
				case "issues":
					workEstimationMetric = AcumenMetricGroupType.JiraIssueCount;
					break;
				default:
					throw new Error(`Unknown countBy option ${options.countBy} for throughput request`);
			}

			const [
				workEstimationResponse,
				prCountResponse,
			] = await Promise.all([
				this.apiClient.fetchMetric(
					workEstimationMetric, workEstimationMetricLabel,
					interval, timezone, startTime, endTime, transformDimensions(taskMetricDimensions)),
				this.apiClient.fetchMetric(
					AcumenMetricGroupType.GitHubPullRequestCount, "merged",
					gitInterval, timezone, startTime, endTime, transformDimensions(gitPRDimensions))
			]);

			const workEstimationCount = (workEstimationResponse?.data ? workEstimationResponse?.data : null);
			const prCount = (prCountResponse?.data ? prCountResponse?.data : null);

			if (workEstimationCount && prCount) {
				const response: IThroughputData = {
					workEstimationCount,
					prCount
				};
				this.throughputDataStored = response;
				return response;
			}

			return undefined;
		};

		return { fetchData };
	}

	@observable
	public workClassificationDataStored?: IWorkClassificationData;

	@action
	public workClassificationData() {
		const fetchData = async (teamId: string | undefined = undefined, dataContributorIds: string[] | undefined = undefined,
			startTime: Date = moment().subtract(1, "year").toDate(), endTime: Date = new Date(),
			projectIds: string[] | undefined = undefined, interval: MetricInterval = MetricInterval.MONTH, timezone: string,
			repositoryIds: string[] | undefined = undefined, baseIsDefaultBranch?: boolean, excludeDraft?: boolean,
			boardIds?: string[], taskTypes?: AcumenTaskType[]): Promise<IWorkClassificationData | undefined> => {

			const gitInterval = transformGitIntervalIfNeeded(interval);
			const gitDimensions = createGeneralDimensions(teamId, dataContributorIds);
			const jiraIssueWorkIntervalMetricDimensions = createGeneralDimensions(teamId, dataContributorIds, "assignee_data_contributor_id");
			if (interval === MetricInterval.SPRINT_DATE) {
				Object.assign(gitDimensions, createTaskDimensions(true, projectIds, boardIds, taskTypes));
			}

			Object.assign(gitDimensions, createGitDimensions(repositoryIds));
			const gitPRDimensions = convertPRDimensionsToPRWorkIntervalSumDimensions({ ...gitDimensions, ...createGitDimensions(undefined, baseIsDefaultBranch, excludeDraft) });

			const jiraLabel = this.jiraMetricEndLabelByInterval(interval);

			const [
				issuesWorkIntervalSumResponse,
				commitWorkIntervalSumResponse,
				pullRequestWorkIntervalResponse
			] = await Promise.all([
				this.apiClient.fetchMetric(
					AcumenMetricGroupType.JiraIssuesWorkIntervalSum, jiraLabel,
					interval, timezone, startTime, endTime, transformDimensions(jiraIssueWorkIntervalMetricDimensions), "owned_by_team"),
				this.apiClient.fetchMetric(
					AcumenMetricGroupType.GitHubCommitWorkIntervalSum, "commit_authored",
					gitInterval, timezone, startTime, endTime, transformDimensions(gitDimensions), "owned_by_team"),
				this.apiClient.fetchMetric(
					AcumenMetricGroupType.GitHubPullRequestWorkIntervalSum, "merged",
					gitInterval, timezone, startTime, endTime, transformDimensions(gitPRDimensions), "owned_by_team"),
			]);

			const issuesWorkIntervalSum = (issuesWorkIntervalSumResponse?.data ? issuesWorkIntervalSumResponse?.data : null);
			const commitWorkIntervalSum =
				(commitWorkIntervalSumResponse?.data ? commitWorkIntervalSumResponse?.data : null);
			const pullRequestWorkIntervalSum =
				(pullRequestWorkIntervalResponse?.data ? pullRequestWorkIntervalResponse?.data : null);

			if (issuesWorkIntervalSum && commitWorkIntervalSum && pullRequestWorkIntervalSum) {
				const response: IWorkClassificationData = {
					issuesWorkIntervalSum,
					commitWorkIntervalSum,
					pullRequestWorkIntervalSum
				};
				this.workClassificationDataStored = response;
				return response;
			}

			return undefined;
		};

		return { fetchData };
	}

	@observable
	public defectDensityDataStored?: IDefectDensityData;

	@action
	public defectDensityData() {
		const fetchData = async (teamId: string | undefined = undefined, dataContributorIds: string[] | undefined = undefined,
			projectIds: string[] | undefined = undefined, startTime: Date = moment().subtract(1, "year").toDate(),
			endTime: Date = new Date(), interval: MetricInterval = MetricInterval.MONTH, timezone: string,
			repositoryIds: string[] | undefined = undefined, baseIsDefaultBranch?: boolean, excludeDraft?: boolean,
			boardIds?: string[], taskTypes?: AcumenTaskType[]): Promise<IDefectDensityData | undefined> => {

			const gitInterval = transformGitIntervalIfNeeded(interval);
			const generalDimensions = createGeneralDimensions(teamId, dataContributorIds);
			const taskMetricDimensions = createTaskDimensions(false, projectIds, boardIds, taskTypes);

			const gitDimensions = createGitDimensions(repositoryIds, baseIsDefaultBranch, excludeDraft);
			if (interval === MetricInterval.SPRINT_DATE) {
				Object.assign(gitDimensions, createTaskDimensions(true, projectIds, boardIds, taskTypes));
			}

			Object.assign(gitDimensions, generalDimensions);
			Object.assign(taskMetricDimensions, generalDimensions);

			const [
				issueBugCountResponse,
				prCountResponse,
				pullRequestLinesOfCodeChangedResponse
			] = await Promise.all([
				this.apiClient.fetchMetric(
					AcumenMetricGroupType.JiraIssueCount, "created",
					interval, timezone, startTime, endTime,
					transformDimensions({ ...taskMetricDimensions, acumen_task_type: [`${AcumenTaskType.Bug}`] })),
				this.apiClient.fetchMetric(
					AcumenMetricGroupType.GitHubPullRequestCount, "merged",
					gitInterval, timezone, startTime, endTime, transformDimensions(gitDimensions)),
				this.apiClient.fetchMetric(
					AcumenMetricGroupType.GitHubPullRequestLinesOfCodeChangedSum, "merged",
					gitInterval, timezone, startTime, endTime, transformDimensions(gitDimensions)),
			]);

			const issueBugCount = (issueBugCountResponse?.data ? issueBugCountResponse?.data : null);
			const prCount = (prCountResponse?.data ? prCountResponse?.data : null);
			const pullRequestLinesOfCodeChanged =
				(pullRequestLinesOfCodeChangedResponse?.data ? pullRequestLinesOfCodeChangedResponse?.data : null);

			if (issueBugCount && prCount && pullRequestLinesOfCodeChanged) {
				const response: IDefectDensityData = {
					issueBugCount,
					prCount,
					pullRequestLinesOfCodeChanged
				};
				this.defectDensityDataStored = response;
				return response;
			}

			return undefined;
		};

		return { fetchData };
	}

	@observable
	public abandonedWorkDataStored?: IAbandonedWorkData;

	@action
	public abandonedWorkData() {
		const fetchData = async (teamId: string | undefined = undefined, dataContributorIds: string[] | undefined = undefined,
			projectIds: string[] | undefined = undefined, startTime: Date = moment().subtract(1, "year").toDate(),
			endTime: Date = new Date(), interval: MetricInterval = MetricInterval.MONTH, timezone: string,
			repositoryIds: string[] | undefined = undefined,
			boardIds?: string[], taskTypes?: AcumenTaskType[]): Promise<IAbandonedWorkData | undefined> => {

			const generalDimensions = createGeneralDimensions(teamId, dataContributorIds);
			const gitDimensions = { ...createGitDimensions(repositoryIds), merged_to_default_branch: ["false"] };
			if (interval === MetricInterval.SPRINT_DATE) {
				Object.assign(gitDimensions, createTaskDimensions(true, projectIds, boardIds, taskTypes));
			}

			Object.assign(gitDimensions, generalDimensions);

			const gitInterval = transformGitIntervalIfNeeded(interval);

			const [
				commitCountResponse,
			] = await Promise.all([
				this.apiClient.fetchMetric(
					AcumenMetricGroupType.GitHubCommitCount, "commit_authored",
					gitInterval, timezone, startTime, endTime, transformDimensions(gitDimensions))
			]);

			const commitCount = (commitCountResponse?.data ? commitCountResponse?.data : null);

			if (commitCount) {
				const response: IAbandonedWorkData = {
					commitCount
				};
				this.abandonedWorkDataStored = response;
				return response;
			}

			return undefined;
		};

		return { fetchData };
	}

	@observable
	public prTimeToMergeStored?: IPullRequestTimeToMergeData;

	@action
	public prTimeToMerge() {
		const fetchData = async (teamId: string | undefined = undefined, dataContributorIds: string[] | undefined = undefined,
			projectIds: string[] | undefined = undefined, startTime: Date = moment().subtract(1, "year").toDate(),
			endTime: Date = new Date(), interval: MetricInterval = MetricInterval.MONTH, timezone: string,
			repositoryIds: string[] | undefined = undefined, baseIsDefaultBranch?: boolean, excludeDraft?: boolean,
			boardIds?: string[], taskTypes?: AcumenTaskType[]): Promise<IPullRequestTimeToMergeData | undefined> => {
			const generalDimensions = createGeneralDimensions(teamId, dataContributorIds);
			const gitDimensions = createGitDimensions(repositoryIds, baseIsDefaultBranch, excludeDraft);
			if (interval === MetricInterval.SPRINT_DATE) {
				Object.assign(gitDimensions, createTaskDimensions(true, projectIds, boardIds, taskTypes));
			}

			Object.assign(gitDimensions, generalDimensions);

			const openToMergePRStatus = [
				AcumenPullRequestStatus.Open, AcumenPullRequestStatus.AwaitingReview,
				AcumenPullRequestStatus.InReview, AcumenPullRequestStatus.Reviewed,
				AcumenPullRequestStatus.Merged].join(",");

			const gitInterval = transformGitIntervalIfNeeded(interval);

			const [
				pullRequestMergeHoursBucketResponse,
				pullRequestCycleOpenToMergeSumResponse,
			] = await Promise.all([
				this.apiClient.fetchMetric(
					AcumenMetricGroupType.GitHubPullRequestCount, "merged",
					gitInterval, timezone, startTime, endTime, transformDimensions(gitDimensions), "opened_to_merged_hours_bucket"),
				this.apiClient.fetchMetric(
					AcumenMetricGroupType.GitHubPullRequestCycleSum, "merged",
					gitInterval, timezone, startTime, endTime,
					transformDimensions({ ...gitDimensions, pull_request_status: [`${openToMergePRStatus}`] }))
			]);

			const pullRequestMergeHoursBucket =
				(pullRequestMergeHoursBucketResponse?.data ? pullRequestMergeHoursBucketResponse?.data : null);
			const pullRequestCycleOpenToMergeSum =
				(pullRequestCycleOpenToMergeSumResponse?.data ? pullRequestCycleOpenToMergeSumResponse?.data : null);

			if (pullRequestMergeHoursBucket && pullRequestCycleOpenToMergeSum) {
				const response: IPullRequestTimeToMergeData = {
					pullRequestMergeHoursBucket,
					pullRequestCycleOpenToMergeSum
				};
				this.prTimeToMergeStored = response;
				return response;
			}

			return undefined;
		};

		return { fetchData };
	}

	@observable
	public prSizeDataStored?: IPullRequestSizeData;

	@action
	public prSizeData() {
		const fetchData = async (teamId: string | undefined = undefined, dataContributorIds: string[] | undefined = undefined,
			projectIds: string[] | undefined = undefined, startTime: Date = moment().subtract(1, "year").toDate(),
			endTime: Date = new Date(), interval: MetricInterval = MetricInterval.MONTH, timezone: string,
			repositoryIds: string[] | undefined = undefined, baseIsDefaultBranch?: boolean, excludeDraft?: boolean,
			boardIds?: string[], taskTypes?: AcumenTaskType[]): Promise<IPullRequestSizeData | undefined> => {

			const generalDimensions = createGeneralDimensions(teamId, dataContributorIds);
			const gitDimensions = createGitDimensions(repositoryIds, baseIsDefaultBranch, excludeDraft);
			if (interval === MetricInterval.SPRINT_DATE) {
				Object.assign(gitDimensions, createTaskDimensions(true, projectIds, boardIds, taskTypes));
			}

			Object.assign(gitDimensions, generalDimensions);
			const gitInterval = transformGitIntervalIfNeeded(interval);

			const [
				pullRequestMergeLineOfCodeChangedBucketResponse,
				pullRequestLinesOfCodeChangedResponse
			] = await Promise.all([
				this.apiClient.fetchMetric(
					AcumenMetricGroupType.GitHubPullRequestCount, "merged",
					gitInterval, timezone, startTime, endTime, transformDimensions(gitDimensions), "loc_changes_bucket"),
				this.apiClient.fetchMetric(
					AcumenMetricGroupType.GitHubPullRequestLinesOfCodeChangedSum, "merged",
					gitInterval, timezone, startTime, endTime, transformDimensions(gitDimensions)),
			]);

			const pullRequestMergeLineOfCodeChangedBucket =
				(pullRequestMergeLineOfCodeChangedBucketResponse?.data ?
					pullRequestMergeLineOfCodeChangedBucketResponse?.data : null);
			const pullRequestLinesOfCodeChanged =
				(pullRequestLinesOfCodeChangedResponse?.data ? pullRequestLinesOfCodeChangedResponse?.data : null);

			if (pullRequestMergeLineOfCodeChangedBucket && pullRequestLinesOfCodeChanged) {
				const response: IPullRequestSizeData = {
					pullRequestMergeLineOfCodeChangedBucket,
					pullRequestLinesOfCodeChanged
				};
				this.prSizeDataStored = response;
				return response;
			}

			return undefined;
		};

		return { fetchData };
	}

	@observable
	public prSizeDataStoredDORAStyle?: IPullRequestSizeDORAData;

	@action
	public prSizeDataDORAStyle() {
		const fetchData = async (teamId: string | undefined = undefined, dataContributorIds: string[] | undefined = undefined,
			projectIds: string[] | undefined = undefined, startTime: Date = moment().subtract(1, "year").toDate(),
			endTime: Date = new Date(), interval: MetricInterval = MetricInterval.MONTH, timezone: string,
			repositoryIds: string[] | undefined = undefined, baseIsDefaultBranch?: boolean, excludeDraft?: boolean,
			boardIds?: string[], taskTypes?: AcumenTaskType[]): Promise<IPullRequestSizeDORAData | undefined> => {

			const generalDimensions = createGeneralDimensions(teamId, dataContributorIds);
			const gitDimensions = createGitDimensions(repositoryIds, baseIsDefaultBranch, excludeDraft);
			if (interval === MetricInterval.SPRINT_DATE) {
				Object.assign(gitDimensions, createTaskDimensions(true, projectIds, boardIds, taskTypes));
			}

			Object.assign(gitDimensions, generalDimensions);
			const gitInterval = transformGitIntervalIfNeeded(interval);

			const [
				pullRequestMergeCountResponse,
				pullRequestLinesOfCodeSumResponse
			] = await Promise.all([
				this.apiClient.fetchMetric(
					AcumenMetricGroupType.GitHubPullRequestCount, "merged",
					gitInterval, timezone, startTime, endTime, transformDimensions(gitDimensions)),
				this.apiClient.fetchMetric(
					AcumenMetricGroupType.GitHubPullRequestLinesOfCodeChangedSum, "merged",
					gitInterval, timezone, startTime, endTime, transformDimensions(gitDimensions)),
			]);

			const pullRequestMergeCount =
				(pullRequestMergeCountResponse?.data ?
					pullRequestMergeCountResponse?.data : null);
			const pullRequestLinesOfCodeSum =
				(pullRequestLinesOfCodeSumResponse?.data ? pullRequestLinesOfCodeSumResponse?.data : null);

			if (pullRequestMergeCount && pullRequestLinesOfCodeSum) {
				const response: IPullRequestSizeDORAData = {
					pullRequestMergeCount,
					pullRequestLinesOfCodeSum
				};
				this.prSizeDataStoredDORAStyle = response;
				return response;
			}

			return undefined;
		};

		return { fetchData };
	}

	@observable
	public mttrDORAStyle?: IMTTRDORAData;

	@action
	public mttrDataDORAStyle() {
		const fetchData = async (teamId: string | undefined = undefined, dataContributorIds: string[] | undefined = undefined,
			projectIds: string[] | undefined = undefined, startTime: Date = moment().subtract(1, "year").toDate(),
			endTime: Date = new Date(), interval: MetricInterval = MetricInterval.MONTH, timezone: string,
			taskStatus?: AcumenTaskStatus[], taskTypes?: AcumenTaskType[], priority?: number[]): Promise<IMTTRDORAData | undefined> => {

			const dimensions = {
				...createGeneralDimensions(teamId, dataContributorIds),
				...createTaskDimensions(false, projectIds, undefined, taskTypes, taskStatus, priority)
			};

			const averageLeadTimeResponse = await this.apiClient.fetchMetric(
				AcumenMetricGroupType.JiraIssueAverageLeadTime, "cycle_end",
				interval, timezone, startTime, endTime, transformDimensions(dimensions));

			const averageLeadTime = (averageLeadTimeResponse?.data ? averageLeadTimeResponse?.data : null);

			if (averageLeadTime) {
				const response: IMTTRDORAData = {
					averageLeadTime,
				};
				this.mttrDORAStyle = response;
				return response;
			}

			return undefined;
		};

		return { fetchData };
	}

	@observable
	public deployFrequencyDORAStyle?: IDeployFrequencyDORAData;

	@action
	public deployFrequencyDataDORAStyle() {
		const fetchData = async (teamId: string | undefined = undefined, dataContributorIds: string[] | undefined = undefined,
			projectIds: string[] | undefined = undefined, startTime: Date = moment().subtract(1, "year").toDate(),
			endTime: Date = new Date(), interval: MetricInterval = MetricInterval.MONTH, timezone: string,
			repositoryIds: string[] | undefined = undefined, baseIsDefaultBranch?: boolean, excludeDraft?: boolean,
			boardIds?: string[], taskTypes?: AcumenTaskType[], deploymentEnvironments?: string[]): Promise<IDeployFrequencyDORAData | undefined> => {

			const generalDimensions = createGeneralDimensions(teamId, dataContributorIds);
			const gitDimensions = createGitDimensions(repositoryIds, baseIsDefaultBranch, excludeDraft);

			gitDimensions[`is_success`] = ["true"];

			if (deploymentEnvironments && deploymentEnvironments.length > 0) {
				gitDimensions[`deployment_environment`] = deploymentEnvironments;
			}
			if (interval === MetricInterval.SPRINT_DATE) {
				Object.assign(gitDimensions, createTaskDimensions(true, projectIds, boardIds, taskTypes));
			}

			Object.assign(gitDimensions, generalDimensions);
			const gitInterval = transformGitIntervalIfNeeded(interval);

			const deploymentFrequencyData = await this.apiClient.fetchMetric(
				AcumenMetricGroupType.DeploymentFrequency, "github_reported_deployment",
				gitInterval, timezone, startTime, endTime, transformDimensions(gitDimensions), "deployment_environment");

			const deployFrequency =
				(deploymentFrequencyData?.data ?
					deploymentFrequencyData?.data : null);
			if (deployFrequency) {
				const response: IDeployFrequencyDORAData = {
					deployFrequency
				};
				this.deployFrequencyDORAStyle = response;
				return response;
			}

			return undefined;
		};

		return { fetchData };
	}
}
