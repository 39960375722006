import * as React from "react";
const Monkey = (props: React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fill="#3F3F3F"
			d="M35.838 9.419c-.834.166-9 .166-9 .166l-8.75 7.25-4.917 10.25-3 12.417 1.333 8.75 5.75 7.167s4.75 4 11.167 3.75c0 0 20 2.917 26.5-3.583l3.333-3.584 3.167-6.333.083-7.667-1.5-7-2.166-6.916-4.084-7.5-4.916-4.167-3.917-2.584-4.167-.833-4.916.417z"
		/>
		<path
			fill="#F4AA41"
			d="m55.588 19.502 2.5-1.833 3.083-.5 1.917 1.25.75 1s.916 3.666 1 3.916c.083.25 0 6 0 6l-1.25 3.167-2.167 1.917-2.333-5.5-3.167-7.25-.333-2.167zM16.671 19.002l-2.667-1.833-2.75-.584-2.916 1.834-1.667 4.916.5 5.834 1.167 2.666 2.5 2.834 1.416-4.5 2.167-5.917zM46.338 44.586l4.083-2.417 1.75-4.667 1.25-7.25-1.083-4.75-2.417-2.833-4.333-1.833-5.417.166-3.583-.083-7-.333-4.667.333-4.25 2.833-2 4.584.083 3.916s-.083 3.5.167 3.917c.25.417 1.583 4.417 1.583 4.417l1.167 1.666 4 1.834-1 4s-.167 6.5 1.25 8.416c1.417 1.917 5.75 5.417 5.75 5.417l6.417.667 4.583-1.667 3.833-4.833c.842-2.689 1.122-5.415 1-8.167l-1.166-3.333z"
		/>
		<circle cx={27.634} cy={29.677} r={2} />
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M23.04 27.954s4.725-6.544 9.451.102M30.897 40.17s.648 2.3 3.537 2.712M27.989 51.767l1.292.805a7.436 7.436 0 0 0 3.938 1.14h5.622a7.424 7.424 0 0 0 3.79-1.05l1.51-.895M31.056 35.588c-2.616 1.508-5.969 4.829-5.58 11.876M26.818 56.026l.28.67a9.325 9.325 0 0 0 5.773 5.279l.786.25a9.325 9.325 0 0 0 6.833-.465h0a9.326 9.326 0 0 0 4.52-4.644l.486-1.095M16.08 18.845l-1.77-1.228a4.35 4.35 0 0 0-5.296.256h0a4.325 4.325 0 0 0-1.464 2.531c-.62 3.446-1.574 11.572 2.67 13.712"
		/>
		<circle cx={43.366} cy={29.913} r={2} />
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M48.017 28.176s-4.726-6.543-9.452.102"
		/>
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M49.96 41.42c2.516-1.751 2.7-5.969 3.302-10.18.413-2.89-.472-5.896-3.596-8.49-3.132-2.6-7.315-2.148-9.39-1.699-1.022.221-2.06.315-3.106.276l-2.727-.101a13.2 13.2 0 0 1-2.497-.347c-1.986-.462-6.364-1.047-9.612 1.65-3.124 2.593-4.009 5.6-3.596 8.488.602 4.212.786 8.43 3.301 10.18"
		/>
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M41.103 40.17s-.648 2.3-3.537 2.712M41.253 35.995c2.563 1.588 5.645 4.919 5.271 11.691M41.253 35.995a12.791 12.791 0 0 0-1.771-.896 9.518 9.518 0 0 0-3.914-.72l-.052.001c-1.544.037-3.07.415-4.412 1.18l-.048.028"
		/>
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M54.463 54.76s12.676-6.052 3.951-28.462S36.066 9.71 36.066 9.71 22.31 3.665 13.585 26.075s3.952 28.462 3.952 28.462M55.92 19.067l1.77-1.228a4.35 4.35 0 0 1 5.296.257h0a4.325 4.325 0 0 1 1.464 2.53c.62 3.446 1.574 11.572-2.67 13.712"
		/>
	</svg>
);
export default Monkey;
