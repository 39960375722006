import React from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { DashboardRetroReportTaskData } from "@acumen/dashboard-common";
import { useStores } from "../../../mobx-stores";
import { IterationReview, taskCategoryToColor, taskCategoryToLabel } from "../../../components/iteration-review/utils";

interface TaskStateInSprintProps {
	task: DashboardRetroReportTaskData;
}

const TaskStateInSprint = (props: TaskStateInSprintProps) => {
	const { task } = props;
	const { sprintRetroStore } = useStores();
	const { issueIdsByCategory } = sprintRetroStore;
	const taskCategory = IterationReview.TasksTable.findTaskCategory(issueIdsByCategory, task.entityId);

	return !taskCategory ? null : (
		<>
			<span className={classNames("task-category", taskCategoryToColor[taskCategory], "bullet")}>
				{"\u25CF"}
			</span>
			{taskCategoryToLabel[taskCategory]}
		</>
	);
};

export default observer(TaskStateInSprint);
