import urlJoin from "url-join";
import config from "../../utils/config";

const { apiBaseUrl } = config({ apiBaseUrl: "" });
const { wsUrl } = config({ wsUrl: "" });

export const ROOT_ROUTE = `${apiBaseUrl}/`;
export const WS_ROUTE = `${wsUrl}/`;
export const VERSION_ROUTE = "v1";
export const BASE_ROUTE = urlJoin(ROOT_ROUTE, VERSION_ROUTE);

export const SELF_ROUTE = urlJoin(BASE_ROUTE, "self");
