import { observer } from "mobx-react";
import React from "react";
import { Avatar } from "../../components/avatar";
import _ from "lodash";
import config from "../../../utils/config";
import { Dropdown } from "semantic-ui-react";
import { AcumenUiVersion, ConfigurationEntityType, CustomizableConfigurationCategories, IDashboardCurrentUserProfile, SINGLE_CONFIG_KEY } from "@acumen/dashboard-common";
import { useAuth0 } from "@auth0/auth0-react";
import { useStores } from "../../mobx-stores";
import { useHistory } from "react-router";
import { IConfigurationsUpdateParams } from "v2/services/crud/customization-api-client";

const {
	auth0: { logoutReturnTo },
} = config();

const createUserMenuHeader = (user: IDashboardCurrentUserProfile) => {
	if (!user.firstName || !user.surname) {
		return (
			<span>
				<strong>Settings</strong>
			</span>
		);
	}

	const fullName = _.compact([user.firstName, user.surname]).join(" ");
	return (
		<span>
			Signed in as <strong>{`${fullName}`}</strong>
		</span>
	);
};

const createUserMenu = (user: IDashboardCurrentUserProfile, logoutCallback: () => Promise<void>, editSelf: () => void,
	switchUiVersion: () => Promise<void>, allowUiVersionUpdate: boolean) => {
	const HEADER_OPTION_KEYS = {
		signOut: "sign-out",
		editSelf: "edit-self",
		resetSelected: "reset",
		switchUiVersion: "switchUiVersion"
	};

	const options = [
		{
			key: "header",
			text: createUserMenuHeader(user),
			disabled: true,
		},
		{ key: HEADER_OPTION_KEYS.editSelf, text: "Edit details", value: HEADER_OPTION_KEYS.editSelf },
		{ key: HEADER_OPTION_KEYS.signOut, text: "Sign out", value: HEADER_OPTION_KEYS.signOut }
	];

	if (allowUiVersionUpdate) {
		const switchUiOption = 	{
			key: HEADER_OPTION_KEYS.switchUiVersion,
			text: `Switch to ${AcumenUiVersion.V1.toUpperCase()}`,
			value: HEADER_OPTION_KEYS.switchUiVersion,
		};

		options.splice(2, 0, switchUiOption);
	}

	return (
		<Dropdown
			className="show-user-dropdown-options"
			onChange={(_e, { value }) => {
				if (value === HEADER_OPTION_KEYS.signOut) {
					// tslint:disable-next-line: no-floating-promises
					logoutCallback();
				} else if (value === HEADER_OPTION_KEYS.editSelf) {
					editSelf();
				} else if (value === HEADER_OPTION_KEYS.switchUiVersion) {
					// tslint:disable-next-line: no-floating-promises
					switchUiVersion();
				}
			}}
			trigger={(
				<span>
					<Avatar
						user={user}
						dataContributor={user.dataContributorDetails}
					/>
					{user.firstName && `${user.firstName}`}
					{!user.firstName && `Settings`}
				</span>
			)}
			selectOnBlur={false}
			pointing="top right"
			options={options}
			value={HEADER_OPTION_KEYS.resetSelected}
		/>
	);
};

// tslint:disable-next-line: variable-name
const MyUser = observer(() => {
	const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
	const { authStore, customizationStore } = useStores();
	const history = useHistory();
	const allowUiVersionUpdate = authStore.authUser.allowUiVersionUpdate;
	const cachedToken = authStore.getCachedJWT();

	const switchUiVersion = async () => {
		const updateParams: IConfigurationsUpdateParams = {
			category: CustomizableConfigurationCategories.Global,
			configs: {
				AcumenUiVersion: {
					[SINGLE_CONFIG_KEY]: AcumenUiVersion.V1
				}
			}
		};
		await customizationStore.updateConfigurationByCategory(updateParams, ConfigurationEntityType.DataContributor);
		window.location.reload();
	};

	return (
		<>
			{(isAuthenticated || cachedToken) && createUserMenu(
				authStore.authUser,
				() => authStore.handleLogout(logout, logoutReturnTo),
				() => history.push(`/my-account/account`),
				switchUiVersion,
				allowUiVersionUpdate
			)}
			{(!isAuthenticated && !cachedToken) && (
				<button
					className="ui primary button"
					onClick={
						() => loginWithRedirect({
							appState: { returnTo: window.location.pathname },
						})
					}
				> Sign In
				</button>
			)}
		</>
	);
});

export default MyUser;
