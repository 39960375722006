import React, { CSSProperties, useEffect } from "react";
import { observer } from "mobx-react";
import useLocalStorage from "../../hooks/useLocalStorage";
import Select, { Theme } from "react-select";
import { IClickEvent } from "../../analytics-events";
import { DateRangeType } from "@acumen/dashboard-common";

interface IDateRangeSelectorProps {
	selectorTag?: string;
	localStorageKey: string;
	onChange?: (selectorTag: string | undefined, dateRange: DateRangeType) => void;
	defaultRange: DateRangeType;
	clickEvent?: IClickEvent;
	customColors?: Partial<Theme>;
	customSize?: CSSProperties;
	longTermDateRange?: boolean;
}

interface IDropdownOption {
	key: string;
	value: string;
	label: string;
}

export const MAP_DATE_RANGE_TO_VALUE: Record<DateRangeType, string> = {
	[DateRangeType.LastDay]: "Last day",
	[DateRangeType.Last3Days]: "Last 3 days",
	[DateRangeType.LastWeek]: "Last week",
	[DateRangeType.Last2Weeks]: "Last 2 weeks",
	[DateRangeType.LastMonth]: "Last month",
	[DateRangeType.Last3Months]: "Last 3 months",
	[DateRangeType.Last6Months]: "Last 6 months",
	[DateRangeType.LastYear]: "Last year",
	[DateRangeType.Last2Years]: "Last 2 years",
	[DateRangeType.Last3Years]: "Last 3 years"
};

const LONG_RANGE_TYPES = [
	DateRangeType.LastWeek, DateRangeType.Last2Weeks, DateRangeType.LastMonth, DateRangeType.Last3Months,
	DateRangeType.Last6Months, DateRangeType.LastYear
];
const SHORT_RANGE_TYPES = [
	DateRangeType.LastDay, DateRangeType.Last3Days, DateRangeType.LastWeek, DateRangeType.Last2Weeks,
	DateRangeType.LastMonth, DateRangeType.Last3Months
];

const DEFAULT_DATE_RANGE_OPTIONS: IDropdownOption[] = Object.values(SHORT_RANGE_TYPES).map(v => ({
	key: v,
	value: v,
	label: MAP_DATE_RANGE_TO_VALUE[v]
}));

const LONG_TERM_DATE_RANGE_OPTIONS: IDropdownOption[] = Object.values(LONG_RANGE_TYPES).map(v => ({
	key: v,
	value: v,
	label: MAP_DATE_RANGE_TO_VALUE[v]
}));

const checkIsDateRangeType = (rangeOptions: DateRangeType[], value: string): DateRangeType => {
	const selectedOption: DateRangeType | undefined = rangeOptions.find((opt) => opt === value);
	return selectedOption ?? DateRangeType.LastMonth;
};

// tslint:disable-next-line: variable-name
const DateRangeSelector = observer(({
	selectorTag,
	onChange,
	localStorageKey,
	defaultRange,
	clickEvent,
	customColors,
	customSize,
	longTermDateRange
}: IDateRangeSelectorProps) => {
	const [selectedSelectorValue, setSelectedSelectorValue] = useLocalStorage<DateRangeType | undefined>(localStorageKey, defaultRange);

	useEffect(() => {
		if (selectorTag !== "" && selectorTag !== undefined) {
			let activeKey: DateRangeType = defaultRange;

			if (selectedSelectorValue) {
				const matchedOption = Object.values(DateRangeType).find(o => o === selectedSelectorValue);
				if (matchedOption) {
					activeKey = selectedSelectorValue;
				} else {
					setSelectedSelectorValue(activeKey);
				}
			}
			onChange?.(selectorTag, activeKey);
		}
	}, [selectorTag]);

	const dateRangeOption = longTermDateRange ? LONG_TERM_DATE_RANGE_OPTIONS : DEFAULT_DATE_RANGE_OPTIONS;

	return (
		<div className="ui section"
			{...clickEvent}
		>
			<Select
				name="dateRange"
				classNamePrefix="select"
				isMulti={false}
				isSearchable={true}
				closeMenuOnSelect={true}
				selection={true}
				value={selectedSelectorValue ? dateRangeOption.find(o => o.value === selectedSelectorValue) : (
					defaultRange ? {
						key: defaultRange,
						value: defaultRange,
						label: MAP_DATE_RANGE_TO_VALUE[defaultRange]
					} : undefined
				)}
				options={dateRangeOption}
				onChange={(newValue) => {
					if (onChange && newValue) {
						const selectedValue = newValue;
						const rangeOptions = longTermDateRange ? LONG_RANGE_TYPES : SHORT_RANGE_TYPES;
						const selectedDateRange: DateRangeType = checkIsDateRangeType(rangeOptions, selectedValue.value);
						setSelectedSelectorValue(selectedDateRange);
						onChange(selectorTag, selectedDateRange);
					}
				}}
				placeholder="Select Date Range"
				styles={{
					option: (styles) => ({
						...styles,
						cursor: "pointer",
					}),
					control: (base) => ({
						...base,
						...customSize,
						cursor: "pointer",
					})
				}}
				theme={(theme) => {
					return ({
						...theme,
						...customColors,
						colors: {
							...theme.colors,
							...customColors?.colors,
						}
					});
				}}

			/>
		</div>
	);
});

export default DateRangeSelector;
