import React from "react";
import { Popup } from "semantic-ui-react";
import classNames from "classnames";
import { InfoIcon } from "../info-icon/info-icon";
import "./org-metrics-card.scss";

export interface OrgMetricsCardProps {
	title: string;
	tooltip?: string;
	className?: string;
	bodyClassName?: string;
	proportions?: number[];
	children?: React.ReactNode | React.ReactNode[];
}

export const OrgMetricsCard = (props: OrgMetricsCardProps) => {
	const { title, tooltip, className, bodyClassName, proportions, children } = props;
	const items = React.Children.toArray(children);

	return (
		<div className={classNames(className, "org-metrics-card")}>
			<div className="org-metrics-card-header">
				<h2 className="org-metrics-card-header-text">{title}</h2>
				{tooltip && (
					<Popup
						hoverable={true}
						wide={true}
						className="tiny-text"
						position="top center"
						content={tooltip}
						trigger={<InfoIcon/>}
					/>
				)}
			</div>

			<div className="org-metrics-card-items">
				{items.map((item, index) => (
					<div
						key={index}
						className={classNames(bodyClassName, "org-metrics-card-item")}
						children={item}
						style={proportions ? { flex: proportions[index] ?? 1 } : undefined}
					/>
				))}
			</div>
		</div>
	);
};
