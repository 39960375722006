import { useMemo } from "react";
import { DeveloperViewModel } from "../types";
import { AsyncState, STATUS_SUCCESS, useAggregatedAsyncState } from "../../../hooks/async-state";
import { IDashboardDataContributorLabel, IDashboardTeam, IDashboardTeamMember } from "@acumen/dashboard-common";
import { DeveloperBadgesData } from "../../../types/workforce-health";
import { DeveloperBadgeMetric } from "@acumen/database-types";
import _ from "lodash";

export const useDevelopersState = (
	teamsState: AsyncState<IDashboardTeam[]>,
	badgesDataState: AsyncState<DeveloperBadgesData>,
	teamMembersState: AsyncState<{
		members: IDashboardTeamMember[];
		labels: Map<string, IDashboardDataContributorLabel[]>;
	}, {
		members: IDashboardTeamMember[];
		labels: Map<string, IDashboardDataContributorLabel[]>;
	}>
) => {
	const aggregatedState = useAggregatedAsyncState(
		teamMembersState,
		teamsState,
		badgesDataState,
	);

	return useMemo<AsyncState<DeveloperViewModel[], DeveloperViewModel[]>>(() => {
		if (aggregatedState.status !== STATUS_SUCCESS) {
			return {
				...aggregatedState,
				data: [],
			};
		}

		const [membersState, teams, badges] = aggregatedState.data;
		const teamMembers = membersState.members;
		const dcLabels = membersState.labels;
		const uniqueMembers = _.uniqBy(teamMembers, tm => tm.dataContributorId);

		return {
			status: STATUS_SUCCESS,
			data: uniqueMembers.map(member => ({
				info: {
					dataContributorId: member.dataContributorId,
					avatarUrl: member.avatarUrl,
					displayName: member.displayName,
					labels: dcLabels.get(member.dataContributorId) ?? []
				},
				badges: badges?.dataContributors[member.dataContributorId] ?? {
					aggregatedBadges: [],
					metricBadges: {
						[DeveloperBadgeMetric.Velocity]: null,
						[DeveloperBadgeMetric.HighPriorityTasks]: null,
						[DeveloperBadgeMetric.ReviewedPRs]: null,
						[DeveloperBadgeMetric.PRSize]: null,
						[DeveloperBadgeMetric.PRCycleTimeWIPAndReview]: null,
					}
				},
				teams: teamMembers
					.filter(tm => tm.dataContributorId === member.dataContributorId)
					.map(tm => teams.find(team => team.id === tm.teamId))
					.filter(team => !!team) as IDashboardTeam[],
			})),
			error: null,
		};
	}, [aggregatedState]);
};
