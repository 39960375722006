import React from "react";
import classNames from "classnames";
import { AcumenTaskStatus, AcumenTaskStatusGroup } from "@acumen/dashboard-common";
import { taskStatusIcons, taskStatusIconsColor, taskStatusText } from "./task-status-icon";

interface TaskStatusGroupProps {
	taskStatusGroup: AcumenTaskStatusGroup;
	taskStatus: AcumenTaskStatus;
}

const TaskStatusGroup = (props: TaskStatusGroupProps) => {
	const { taskStatusGroup, taskStatus } = props;

	return (
		<div className={classNames(taskStatusIconsColor(taskStatusGroup), "icon-label")} >
			<img className="status-icon" src={taskStatusIcons(taskStatus, "")} alt=""/>
			<span className="ellipsis">
				{taskStatusText(taskStatus, null)}
			</span>
		</div>
	);
};

export const taskStatusGroupText = (taskStatusGroup: AcumenTaskStatusGroup) => {
	switch (taskStatusGroup) {
		case AcumenTaskStatusGroup.Done:
			return "done";
		case AcumenTaskStatusGroup.Backlog:
			return "backlog";
		case AcumenTaskStatusGroup.InProgress:
		return "in progress";
	}
};

export default TaskStatusGroup;
