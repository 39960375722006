import React, { useCallback, useMemo, useRef } from "react";
import classes from "classnames";
import _ from "lodash";
import "../../../../components/style.scss";
import { useSelectionState } from "../../../../components/filter-dropdown/hooks";
import UsersSelector, { mapUserToFilterOption } from "../../../../components/filters/users-selector/users-selector";
import CreateNewContributorsFilterButton from "./create-new-contributors-filter-button";
import { ContributorFilterModel } from "../../../../types/contributor-filter.model";
import { resolveFilterOption } from "../../../../components/filter-dropdown/utils";
import { ResolvedFilterOption } from "../../../../components/filter-dropdown/types";
import { UserOption } from "../../../../components/filters/users-selector/types";
import { areSelectionsEqual } from "../../../../../utils/checkers";
import ContributorsFilterDropdownOption from "./contributors-filter-dropdown-option";

export interface SprintReviewContributorsSelectorProps {
	showCreateFilterButton: boolean;
	contributors: UserOption[];
	selectedContributorIds: string[];
	maxUsersOnView?: number;
	showArrow?: boolean;
	multiselect?: boolean;
	disabled?: boolean;
	className?: string;
	contributorFilters: ContributorFilterModel[];
	selectedContributorFilterIndices: number[];
	onSelectedContributorFiltersChange: (indices: number[]) => void;
	onSelectedContributorsChange: (contributorIds: string[]) => void;
	onFilterCreated?: () => void;
}

// tslint:disable-next-line: variable-name
const SprintReviewContributorsSelector = (props: SprintReviewContributorsSelectorProps) => {
	const { showCreateFilterButton, contributors, maxUsersOnView, showArrow, multiselect = true, disabled, className, selectedContributorIds } = props;
	const { contributorFilters, onSelectedContributorFiltersChange, onSelectedContributorsChange, onFilterCreated } = props;
	const selectedContributorIdsSet = useMemo(
		() => selectedContributorIds.length > 0 ? new Set(selectedContributorIds) : null,
		[selectedContributorIds]
	);
	const contributorsOptions = useMemo(
		() => contributors
			.map(mapUserToFilterOption)
			.map<ResolvedFilterOption<string>>(resolveFilterOption),
		[contributors]
	);
	const {
		selection: uncommittedSelectedContributorIds,
		toggle: toggleContributor,
		selectAll: selectAllContributors,
		clear: clearSelectedContributors,
		setSelection,
	} = useSelectionState<string>({
		options: contributorsOptions,
		selection: selectedContributorIdsSet,
		isAllSelectedByDefault: false,
	});
	const selectedContributorFilterIndicesSet = useMemo(() => {
		const selectedFilterIndices = contributorFilters
			.map((filter, index) => [filter, index] as const)
			.filter(([filter]) => filter.contributorIds.every(id => uncommittedSelectedContributorIds?.has(id)))
			.map(([, index]) => index);

		return selectedFilterIndices.length > 0 ? new Set(selectedFilterIndices) : null;
	}, [contributorFilters, uncommittedSelectedContributorIds]);
	const handleToggleContributorFilter = useCallback((index: number) => {
		const contributorFilter = contributorFilters[index];

		if (!selectedContributorFilterIndicesSet?.has(index)) {
			setSelection(selection => {
				const selectedIds = selection
					? [...Array.from(selection), ...contributorFilter.contributorIds]
					: contributorFilter.contributorIds;

				return new Set(_.uniq(selectedIds));
			});
		} else {
			setSelection(selection => {
				const selectedIds = selection
					? Array.from(selection).filter(id => !contributorFilter.contributorIds.includes(id))
					: [];

				return new Set(selectedIds);
			});
		}
	}, [contributorFilters, selectedContributorFilterIndicesSet, setSelection]);
	const handleCommitContributorsSelection = useCallback(
		(selection: Set<string> | null) => onSelectedContributorsChange(selection ? Array.from(selection) : []),
		[onSelectedContributorsChange]
	);
	const handleCommitContributorFiltersSelection = useCallback(
		(selection: Set<number> | null) => onSelectedContributorFiltersChange(selection ? Array.from(selection) : []),
		[onSelectedContributorFiltersChange]
	);
	const handleCommitSelectedContributorFilters = useContributorFiltersSelectionCommitHandler({
		selectedContributorFilterIndices: selectedContributorFilterIndicesSet,
		onCommitContributorFiltersSelection: handleCommitContributorFiltersSelection,
	});
	const handleHide = useCallback(() => {
		handleCommitSelectedContributorFilters?.(selectedContributorFilterIndicesSet ?? null);
	}, [selectedContributorFilterIndicesSet, handleCommitSelectedContributorFilters]);

	return (
		<UsersSelector
			options={contributors}
			committedContributorIds={selectedContributorIdsSet}
			selectedContributorIds={uncommittedSelectedContributorIds}
			maxUsersOnView={maxUsersOnView}
			showArrow={showArrow}
			multiselect={multiselect}
			className={classes("contributors-selector", className)}
			disabled={disabled}
			onToggleContributorFilter={handleToggleContributorFilter}
			onToggleContributor={toggleContributor}
			onCommitContributorsSelection={handleCommitContributorsSelection}
			onHide={handleHide}
			actionButtons={
				<div className="action-buttons">
					<button className="action-button" onClick={selectAllContributors}>All</button>
					<button className="action-button" onClick={clearSelectedContributors}>None</button>
				</div>
			}
			itemsBeforeUsers={
				<React.Fragment>
					{contributorFilters?.map((contributorFilter, index) => (
						<ContributorsFilterDropdownOption
							key={index}
							index={index}
							contributorFilter={contributorFilter}
							isSelected={!!selectedContributorFilterIndicesSet?.has(index)}
							onToggle={handleToggleContributorFilter}
						/>
					))}
				</React.Fragment>
			}
			footer={showCreateFilterButton && (
				<div className="contributors-selector-footer">
					<CreateNewContributorsFilterButton
						contributorFilters={contributorFilters}
						contributorIds={uncommittedSelectedContributorIds}
						onFilterCreated={onFilterCreated}
					/>
				</div>
			)}
		/>
	);
};

const useContributorFiltersSelectionCommitHandler = (props: {
	selectedContributorFilterIndices?: Set<number> | null;
	onCommitContributorFiltersSelection?: (selection: Set<number> | null) => void;
}) => {
	const { selectedContributorFilterIndices, onCommitContributorFiltersSelection } = props;
	const lastSelectionRef = useRef(selectedContributorFilterIndices);

	return useCallback((newSelection: Set<number> | null) => {
		if (!onCommitContributorFiltersSelection || areSelectionsEqual(lastSelectionRef.current, newSelection)) {
			return;
		}

		lastSelectionRef.current = newSelection;
		onCommitContributorFiltersSelection(newSelection);
	}, [onCommitContributorFiltersSelection]);
};

export default SprintReviewContributorsSelector;
