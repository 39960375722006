import * as React from "react";
const EditIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width="11"
		height="11"
		viewBox="0 0 11 11"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path d="M7.97552 0.795512C7.97544 0.795367 7.97534 0.795172 7.97519 0.794918L7.97552 0.795512ZM7.97552 0.795512L7.97571 0.795838C7.97572 0.795848 7.97569 0.795797 7.97562 0.795673C7.97559 0.795629 7.97556 0.795575 7.97552 0.795512ZM9.85724 3.36855L9.99999 3.22277V3.01873V2.9834V2.71227L9.77276 2.56436C9.76246 2.55765 9.75307 2.5519 9.74521 2.54722C9.74213 2.54538 9.73917 2.54364 9.73639 2.54202C9.14305 1.95067 8.55086 1.3585 7.95882 0.76548C7.95775 0.763608 7.95663 0.761667 7.95547 0.759674C7.95087 0.751752 7.9452 0.742254 7.93856 0.731816L7.79124 0.5H7.51657H7.48124H7.27296L7.12628 0.647865C6.99201 0.783211 6.85788 0.91846 6.72494 1.05251C6.53099 1.24809 6.33955 1.44113 6.15389 1.62821L5.80309 1.98169L6.15517 2.3339L8.18003 4.3598L8.53757 4.71748L8.89115 4.35588C9.2069 4.03296 9.53194 3.70075 9.85724 3.36855ZM9.70848 2.52613C9.70845 2.52611 9.70845 2.52612 9.70848 2.52614L9.70848 2.52613ZM9.76873 2.57425C9.76824 2.57377 9.76776 2.57328 9.76727 2.5728C9.76776 2.57328 9.76824 2.57377 9.76873 2.57425ZM7.92431 0.730913C7.92441 0.731015 7.92451 0.731116 7.92462 0.731218L7.92431 0.730913Z" stroke="#979797"/>
		<path d="M0.510797 9.32563C0.475847 9.49107 0.526882 9.66297 0.646448 9.78253L0.71737 9.85345C0.83714 9.97322 1.0094 10.0242 1.17506 9.98893C1.29343 9.96372 1.4115 9.9385 1.52939 9.91332C1.85409 9.84395 2.17741 9.77488 2.50164 9.70728L2.5021 9.70718C2.75627 9.65394 3.00971 9.60288 3.25519 9.55342C3.35928 9.53245 3.46194 9.51177 3.56262 9.49133C3.74311 9.45469 3.88885 9.32178 3.94193 9.14543C3.99501 8.96907 3.94683 8.7778 3.81655 8.64763C3.15749 7.9891 2.50081 7.33323 1.85051 6.684C1.72023 6.55393 1.52902 6.50598 1.35277 6.55917C1.17653 6.61236 1.04378 6.7581 1.00721 6.93853C0.987511 7.03574 0.967706 7.13473 0.947635 7.23505C0.909039 7.42796 0.869458 7.62579 0.827737 7.82536L0.827681 7.82563C0.758261 8.15859 0.687815 8.49061 0.617156 8.82363C0.581723 8.99063 0.546235 9.15788 0.510797 9.32563Z" stroke="#979797" strokeLinejoin="round"/>
		<mask id="path-3-outside-1_9990_304497" maskUnits="userSpaceOnUse" x="0.0537109" y="1.54639" width="10" height="9" fill="black">
		<rect fill="white" x="0.0537109" y="1.54639" width="10" height="9"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M5.97965 2.54639C6.63521 3.20168 7.30087 3.86733 7.95537 4.52182C6.65194 5.82682 5.33524 7.14484 4.03288 8.44878C3.37387 7.79031 2.70768 7.12466 2.05371 6.47123C3.35979 5.16542 4.67729 3.8482 5.97965 2.54639Z"/>
		</mask>
		<path d="M6.68661 1.83913C6.296 1.44869 5.66284 1.44882 5.27239 1.83943C4.88195 2.23003 4.88208 2.8632 5.27269 3.25364L6.68661 1.83913ZM7.95537 4.52182L8.6629 5.2285L9.36915 4.52139L8.66247 3.81471L7.95537 4.52182ZM4.03288 8.44878L3.32606 9.15618L4.0336 9.86314L4.74042 9.15546L4.03288 8.44878ZM2.05371 6.47123L1.34668 5.76404L0.639132 6.47144L1.34689 7.17862L2.05371 6.47123ZM6.68661 3.25364C7.07721 2.8632 7.07734 2.23003 6.6869 1.83943C6.29646 1.44882 5.66329 1.44869 5.27269 1.83913L6.68661 3.25364ZM5.27269 3.25364C5.92817 3.90885 6.59375 4.57442 7.24826 5.22893L8.66247 3.81471C8.00798 3.16023 7.34225 2.49451 6.68661 1.83913L5.27269 3.25364ZM7.24783 3.81514C5.94982 5.11473 4.6224 6.44348 3.32535 7.74211L4.74042 9.15546C6.04808 7.84621 7.35407 6.53892 8.6629 5.2285L7.24783 3.81514ZM4.7397 7.74139C4.08069 7.08292 3.4145 6.41726 2.76053 5.76383L1.34689 7.17862C2.00086 7.83205 2.66704 8.4977 3.32606 9.15618L4.7397 7.74139ZM2.76074 7.17841C4.06683 5.8726 5.38429 4.55541 6.68661 3.25364L5.27269 1.83913C3.97029 3.14099 2.65275 4.45825 1.34668 5.76404L2.76074 7.17841Z" fill="#979797" mask="url(#path-3-outside-1_9990_304497)"/>
	</svg>
);
export default EditIcon;
