import { TeamBadge, TeamBadgeMetric } from "@acumen/database-types";
import _ from "lodash";
import React, { useMemo } from "react";
import { TeamViewModel } from "../../types";
import { TeamMetricBadgeFilterItem } from "./team-metric-badge-filter-item/team-metric-badge-filter-item";
import "./team-metric-badge-filter.scss";

export enum TeamMetricFilterType {
	TopContributors = "TopContributors",
	QuickPickup = "QuickPickup",
	EfficientCoders = "EfficientCoders",
	DistributedReviewers = "DistributedReviewers",
	BalancedWork = "BalancedWork",
	BugSquashers = "BugSquashers",
	LowVelocity = "LowVelocity",
	LongCycleTime = "LongCycleTime",
	LongPickupTime = "LongPickupTime",
	LargePRs = "LargePRs",
	FewerReviews = "FewerReviews",
	BugHeavy = "BugHeavy",
	LongTimeToResolve = "LongTimeToResolve",
}

const FILTER_TYPE_TO_LABEL: Record<TeamMetricFilterType, string> = {
	[TeamMetricFilterType.TopContributors]: "Top contributors",
	[TeamMetricFilterType.QuickPickup]: "Quick pickup",
	[TeamMetricFilterType.DistributedReviewers]: "Distributed reviewers",
	[TeamMetricFilterType.LowVelocity]: "Low velocity",
	[TeamMetricFilterType.BalancedWork]: "Balanced work",
	[TeamMetricFilterType.BugSquashers]: "Bug squashers",
	[TeamMetricFilterType.FewerReviews]: "Fewer reviews",
	[TeamMetricFilterType.EfficientCoders]: "Efficient coders",
	[TeamMetricFilterType.LargePRs]: "Large PRs",
	[TeamMetricFilterType.LongCycleTime]: "Long cycle time",
	[TeamMetricFilterType.LongPickupTime]: "Long pickup time",
	[TeamMetricFilterType.BugHeavy]: "Bug heavy",
	[TeamMetricFilterType.LongTimeToResolve]: "Long time to resolve"
};

export function filterTeamsByType(teams: TeamViewModel[], filter: TeamMetricFilterType | undefined): TeamViewModel[] {
	if (!filter) {
		return teams;
	}

	return _.filter(teams, d => {
		const badges = d.badges.metricBadges;
		if (!badges) {
			return false;
		}
		switch (filter) {
			case TeamMetricFilterType.TopContributors: {
				const b = badges[TeamBadgeMetric.Velocity];
				return (b !== null && b.badge === TeamBadge.TopPerformer);
			}
			case TeamMetricFilterType.QuickPickup: {
				const b = badges[TeamBadgeMetric.AvgPRCycleTimeAwaitingReview];
				return (b !== null && b.badge === TeamBadge.TopPerformer);
			}
			case TeamMetricFilterType.DistributedReviewers: {
				const b = badges[TeamBadgeMetric.PRReviewersPercent];
				return (b !== null && b.badge === TeamBadge.TopPerformer);
			}
			case TeamMetricFilterType.LowVelocity: {
				const b = badges[TeamBadgeMetric.Velocity];
				return (b !== null && b.badge === TeamBadge.NeedAttention);
			}
			case TeamMetricFilterType.BalancedWork: {
				const b = badges[TeamBadgeMetric.WorkDistributionBugsPercent];
				return (b !== null && b.badge === TeamBadge.TopPerformer);
			}
			case TeamMetricFilterType.BugSquashers: {
				const b = badges[TeamBadgeMetric.MTTR];
				return (b !== null && b.badge === TeamBadge.TopPerformer);
			}
			case TeamMetricFilterType.FewerReviews: {
				const b = badges[TeamBadgeMetric.PRReviewersPercent];
				return (b !== null && b.badge === TeamBadge.NeedAttention);
			}
			case TeamMetricFilterType.EfficientCoders: {
				const b1 = badges[TeamBadgeMetric.AvgPRCycleTimeTotal];
				const b2 = badges[TeamBadgeMetric.AvgPRSize];
				return ((b1 !== null && b1.badge === TeamBadge.TopPerformer) ||
					(b2 !== null && b2.badge === TeamBadge.TopPerformer));
			}
			case TeamMetricFilterType.LargePRs: {
				const b = badges[TeamBadgeMetric.AvgPRSize];
				return (b !== null && b.badge === TeamBadge.NeedAttention);
			}
			case TeamMetricFilterType.LongCycleTime: {
				const b = badges[TeamBadgeMetric.AvgPRCycleTimeTotal];
				return (b !== null && b.badge === TeamBadge.NeedAttention);
			}
			case TeamMetricFilterType.LongPickupTime: {
				const b = badges[TeamBadgeMetric.AvgPRCycleTimeAwaitingReview];
				return (b !== null && b.badge === TeamBadge.NeedAttention);
			}
			case TeamMetricFilterType.BugHeavy: {
				const b = badges[TeamBadgeMetric.WorkDistributionBugsPercent];
				return (b !== null && b.badge === TeamBadge.NeedAttention);
			}
			case TeamMetricFilterType.LongTimeToResolve: {
				const b = badges[TeamBadgeMetric.MTTR];
				return (b !== null && b.badge === TeamBadge.NeedAttention);
			}
			default:
			 	return false;
		}
	});
}

export interface ITeamMetricBadgeFilterProps {
	teams: TeamViewModel[];
	selectedFilterItem: TeamMetricFilterType | undefined;
	onSelectedFilterItem: (filter: TeamMetricFilterType | undefined) => void;
}

export const TeamMetricBadgeFilter = (props: ITeamMetricBadgeFilterProps) => {
	const { onSelectedFilterItem, selectedFilterItem, teams } = props;
	const filterToValue = useMemo<Record<TeamMetricFilterType, number>>(() => {
		const mapping: Record<TeamMetricFilterType, number> = {
			[TeamMetricFilterType.TopContributors]: 0,
			[TeamMetricFilterType.QuickPickup]: 0,
			[TeamMetricFilterType.EfficientCoders]: 0,
			[TeamMetricFilterType.DistributedReviewers]: 0,
			[TeamMetricFilterType.BalancedWork]: 0,
			[TeamMetricFilterType.BugSquashers]: 0,
			[TeamMetricFilterType.LowVelocity]: 0,
			[TeamMetricFilterType.LongCycleTime]: 0,
			[TeamMetricFilterType.LongPickupTime]: 0,
			[TeamMetricFilterType.LargePRs]: 0,
			[TeamMetricFilterType.FewerReviews]: 0,
			[TeamMetricFilterType.BugHeavy]: 0,
			[TeamMetricFilterType.LongTimeToResolve]: 0,
		};
		teams.forEach(d => {
			const badges = d.badges.metricBadges;
			if (!badges) {
				return;
			}
			const vBadge = badges[TeamBadgeMetric.Velocity];
			if (vBadge) {
				if (vBadge.badge === TeamBadge.TopPerformer) {
					mapping.TopContributors += 1;
				} else if (vBadge.badge === TeamBadge.NeedAttention) {
					mapping.LowVelocity += 1;
				}
			}
			const prctarBadge = badges[TeamBadgeMetric.AvgPRCycleTimeAwaitingReview];
			if (prctarBadge) {
				if (prctarBadge.badge === TeamBadge.TopPerformer) {
					mapping.QuickPickup += 1;
				} else if (prctarBadge.badge === TeamBadge.NeedAttention) {
					mapping.LongPickupTime += 1;
				}
			}
			const wdpBadge = badges[TeamBadgeMetric.WorkDistributionBugsPercent];
			if (wdpBadge) {
				if (wdpBadge.badge === TeamBadge.TopPerformer) {
					mapping.BalancedWork += 1;
				} else if (wdpBadge.badge === TeamBadge.NeedAttention) {
					mapping.BugHeavy += 1;
				}
			}
			const prpBadge = badges[TeamBadgeMetric.PRReviewersPercent];
			if (prpBadge) {
				if (prpBadge.badge === TeamBadge.TopPerformer) {
					mapping.DistributedReviewers += 1;
				} else if (prpBadge.badge === TeamBadge.NeedAttention) {
					mapping.FewerReviews += 1;
				}
			}
			const apsBadge = badges[TeamBadgeMetric.AvgPRSize];
			if (apsBadge && apsBadge.badge === TeamBadge.NeedAttention) {
				mapping.LargePRs += 1;
			}
			const mttrBadge = badges[TeamBadgeMetric.MTTR];
			if (mttrBadge) {
				if (mttrBadge.badge === TeamBadge.TopPerformer) {
					mapping.BugSquashers += 1;
				} else if (mttrBadge.badge === TeamBadge.NeedAttention) {
					mapping.LongTimeToResolve += 1;
				}
			}
			const prctBadge = badges[TeamBadgeMetric.AvgPRCycleTimeTotal];
			if (prctBadge && prctBadge.badge === TeamBadge.NeedAttention) {
				mapping.LongCycleTime += 1;
			}
			if ((apsBadge !== null && apsBadge.badge === TeamBadge.TopPerformer) ||
				(prctBadge !== null && prctBadge.badge === TeamBadge.TopPerformer)) {
				mapping.EfficientCoders += 1;
			}
		});
		return mapping;
	}, [teams]);

	return (
		<div className="team-metric-badge-filter">
			{Object.keys(TeamMetricFilterType).map((t, index) => {
				const type = t as TeamMetricFilterType;
				const name = FILTER_TYPE_TO_LABEL[type];
				const value = filterToValue ? filterToValue[type] : 0;
				const isSelected = selectedFilterItem !== undefined && selectedFilterItem === type;
				return <TeamMetricBadgeFilterItem
					key={`team-metric-badge-filter-item-${index}`}
					name={name}
					value={value}
					isSelected={isSelected}
					onClick={() => {
						if (!isSelected) {
							onSelectedFilterItem(type);
						} else {
							onSelectedFilterItem(undefined);
						}
					}} />;
			})}
		</div>
	);
};
