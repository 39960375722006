import "./actionable-table.scss";
import React from "react";
import BootstrapTable, { Column, ColumnFormatter } from "react-bootstrap-table-next";
import CheckBox from "../../components/form/checkbox-input";
import cellEditFactory from "react-bootstrap-table2-editor";
import { createIsNumericRangeValidator } from "../../../utils/validations";
import { format, STRINGS } from "../../../localization";
import MultiSelectCellEditor, { IMultiSelectOption } from "./cell-editors/multi-select-cell-editor";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { v1 } from "uuid";
import ConfirmationPrompt from "../../components/modals/confirmation-prompt/confirmation-prompt";
import paginationFactory from "react-bootstrap-table2-paginator";

type StringKeyOf<T> = Extract<keyof T, string>;
export type CellValue = string | string[] | number | boolean;
export type RowId = string;
export const ACTION_BUTTONS_COL_DATA_FIELD = "ACTION_BUTTON_COL_DATA_FIELD";
const DEFAULT_MINIMUM_ENTRIES_FOR_PAGINATION = 10;
const DEFAULT_EDIT_ICON_CLASS_NAME = "icon edit";
const DEFAULT_DELETE_ICON_CLASS_NAME = "icon trash";

export enum EditorType {
	Text = "TEXT",
	TextArea = "TEXT_AREA",
	Number = "NUMBER",
	Select = "SELECT",
	MultiSelect = "MULTI_SELECT",
	CheckBox = "CHECK_BOX"
}

export enum CustomFormatter {
	Array = "ARRAY",
	Image = "IMAGE"
}

export enum Alignment {
	Left = "left",
	Right = "right",
	Center = "center"
}

export interface IEditorOptions {
	type: EditorType;
}

export interface INumberEditorOptions extends IEditorOptions {
	type: EditorType.Number;
	minValue: number;
	maxValue: number;
}

export interface IMultiSelectEditorOptions extends IEditorOptions {
	type: EditorType.MultiSelect;
	values: IMultiSelectOption[];
}

export interface IColumnDefinition<T> {
	key: StringKeyOf<T>;
	headerText: string;
	editable?: boolean;
	editorOptions?: IEditorOptions | INumberEditorOptions | IMultiSelectEditorOptions;
	customFormatter?: CustomFormatter;
	showToolTip?: boolean;
	toolTipText?: (row: T, key: StringKeyOf<T>) => string;
	nestedTextFieldKey?: string;
	component?: (value: any, row: T) => JSX.Element;
	align?: Alignment;
}

interface IActionButtonProps<T> {
	show: boolean;
	onClick: (row: T) => void;
	customIconClass?: string;
}

interface IDeleteActionButtonProps<T> extends IActionButtonProps<T> {
	shouldDisableButtonForRow?: (rowData: T) => boolean;
	deleteConfirmationMessageLabelCreator?: (rowData: T) => string;
}

interface IActionButtonsProps<T> {
	delete?: IDeleteActionButtonProps<T>;
	edit?: IActionButtonProps<T>;
}

interface IPaginationOptions {
	show: boolean;
	minimumEntries?: number;
	options?: PaginationProps;
}

interface IProps<T> {
	data: T[];
	columnsDef: Array<IColumnDefinition<T>>;
	rowIdKey: StringKeyOf<T>;
	showRowToggle?: boolean;
	isRowActiveKey?: StringKeyOf<T>;
	isRowActiveReadonly?: boolean;
	onRowClicked?: (row: T) => void;
	onCellClicked?: (row: T, colKey: StringKeyOf<T>) => void;
	onRowToggled?: (rowId: RowId, isActive: boolean) => void;
	editable?: boolean;
	onCellEdited?: (row: T, dataField: string, newValue: CellValue) => void;
	actionButtons?: IActionButtonsProps<T>;
	pagination?: IPaginationOptions;
}

export default function ActionableTable<T>(props: IProps<T>) {
	const [rowBeforeDeletion, setRowBeforeDeletion] = React.useState<T | null>(null);
	const showConfirmAlert = !!rowBeforeDeletion;

	function getRowId(row: T): RowId {
		const rowId = row[props.rowIdKey];
		if (typeof (rowId) !== "string") {
			throw new Error(`rowId must be a string, value: ${rowId}`);
		}

		return rowId;
	}

	function onRowDeletionConfirmed() {
		if (rowBeforeDeletion && props.actionButtons &&
			props.actionButtons.delete && props.actionButtons.delete.onClick) {
			props.actionButtons.delete.onClick(rowBeforeDeletion);
		}

		setRowBeforeDeletion(null);
	}

	function onCellClicked(e: any, column: Column, colIndex: number, row: T, rowIndex: number) {
		if ((column.dataField === props.isRowActiveKey &&
			props.isRowActiveKey && props.showRowToggle && !props.isRowActiveReadonly) ||
			column.dataField === ACTION_BUTTONS_COL_DATA_FIELD) {
			// If the cell is a toggle button/action button, suppress click event.
			return;
		}

		if (props.onRowClicked) {
			props.onRowClicked(row);
		}

		if (props.onCellClicked) {
			const rootKey = column.dataField.split(".")[0]; // Data field might be a nested key (eg: "entity.name")
			props.onCellClicked(row, rootKey as StringKeyOf<T>);
		}
	}

	function onCellEdited(oldValue: CellValue, newValue: CellValue, row: T, column: Column) {
		if (!props.onCellEdited || oldValue === newValue) {
			return;
		}

		props.onCellEdited(row, column.dataField, newValue);
	}

	const columns: Column[] = props.columnsDef.map(colDef => {
		const validatorFunc = createValidatorFuncForColumn(colDef);
		const editorRenderer = createCustomEditorRendererForColumn(colDef);
		const customFormatter = createCustomFormatterForColumn(colDef);

		let dataField: string = colDef.key;
		if (colDef.nestedTextFieldKey) {
			dataField += `.${colDef.nestedTextFieldKey}`;
		}

		const col: Column = {
			dataField,
			text: colDef.headerText,
			align: colDef.align,
			editable: () => colDef.editable ? colDef.editable : false,
			validator: validatorFunc ? wrapValidatorFunc(validatorFunc) : undefined,
			editorRenderer,
			formatter: colDef.component || customFormatter,
			events: {
				onClick: onCellClicked
			}
		};
		return col;
	});

	const actionButtonColumn = createActionButtonsColumn(props.actionButtons, setRowBeforeDeletion);
	if (actionButtonColumn) {
		columns.push(actionButtonColumn);
	}

	if (props.showRowToggle) {
		if (!props.isRowActiveKey) {
			throw new Error("isRowActiveKey prop must be present when showRowToggle=true");
		}

		const isActiveRowIndex = columns.findIndex((col) => col.dataField === props.isRowActiveKey);
		if (isActiveRowIndex === -1) {
			throw new Error(`isActive row was not found by key: ${props.isRowActiveKey}`);
		}

		columns[isActiveRowIndex].formatter = (value: boolean, row: T) => (
			<StatefulToggleButton
				rowId={getRowId(row)}
				initialValue={value}
				onToggleCallback={props.onRowToggled}
				readonly={props.isRowActiveReadonly}
			/>
		);
	}

	let shouldShowPagination = false;
	if (props.pagination && props.pagination.show) {
		const minEntries = props.pagination.minimumEntries || DEFAULT_MINIMUM_ENTRIES_FOR_PAGINATION;
		shouldShowPagination = props.data.length > minEntries;
	}

	const rowClass = props.onRowClicked ? "clickable" : "";
	return (
		<>
			{(props.actionButtons && props.actionButtons.delete) && (
				<ConfirmationPrompt
					show={showConfirmAlert}
					headerText={STRINGS.PLEASE_CONFIRM}
					bodyText={(props.actionButtons.delete.deleteConfirmationMessageLabelCreator && rowBeforeDeletion) ?
						props.actionButtons.delete.deleteConfirmationMessageLabelCreator(rowBeforeDeletion)
						: STRINGS.ARE_YOU_SURE}
					onCancel={() => setRowBeforeDeletion(null)}
					onConfirm={onRowDeletionConfirmed}
				/>
			)}

			<BootstrapTable
				bootstrap4={true}
				columns={columns}
				pagination={shouldShowPagination ? paginationFactory(props.pagination!.options) : undefined}
				bordered={false}
				keyField={props.rowIdKey}
				data={props.data}
				rowClasses={rowClass}
				cellEdit={props.editable ? cellEditFactory({
					mode: "click",
					blurToSave: true,
					afterSaveCell: onCellEdited
				}) : undefined}
			/>
		</>
	);
}

function createActionButtonsColumn<T>(actionButtonsProps: IActionButtonsProps<T> | undefined,
	setRowBeforeDeletion: React.Dispatch<React.SetStateAction<T | null>>): Column | undefined {
	if (!actionButtonsProps || (!actionButtonsProps.delete && !actionButtonsProps.edit)) {
		return undefined;
	}

	const column: Column = {
		isDummyField: true,
		dataField: ACTION_BUTTONS_COL_DATA_FIELD,
		editable: () => false,
		text: ""
	};

	const shouldDisableButtonForRow = (row: T) => {
		if (actionButtonsProps && actionButtonsProps.delete && actionButtonsProps.delete.shouldDisableButtonForRow) {
			return actionButtonsProps.delete.shouldDisableButtonForRow(row);
		}

		return false;
	};

	column.formatter = (_value: any, row: T) => (
		<div className="action-buttons">
			{(actionButtonsProps.edit && actionButtonsProps.edit.show) && (
				<button className="icon-btn" onClick={() => actionButtonsProps.edit!.onClick(row)}>
					<i className={actionButtonsProps.edit.customIconClass || DEFAULT_EDIT_ICON_CLASS_NAME} />
				</button>
			)}
			{(actionButtonsProps.delete && actionButtonsProps.delete.show) && (
				<button
					className="icon-btn"
					onClick={() => setRowBeforeDeletion(row)}
					disabled={shouldDisableButtonForRow(row)}
				>
					<i className={actionButtonsProps.delete.customIconClass || DEFAULT_DELETE_ICON_CLASS_NAME} />
				</button>
			)}
		</div>
	);

	return column;
}

function stringArrayFormatter<T>(cellValue: string[], row: T) {
	if (!Array.isArray(cellValue)) {
		return cellValue;
	}

	return (
		<span>
			{cellValue.join(", ")}
		</span>
	);

}

function createValidatorFuncForColumn<T>(colDef: IColumnDefinition<T>): ((val: string) => boolean) | undefined {
	if (colDef.editable && colDef.editorOptions
		&& colDef.editorOptions.type === EditorType.Number) {
		const numberEditorOptions = colDef.editorOptions as INumberEditorOptions;
		if (numberEditorOptions.minValue !== undefined && numberEditorOptions.maxValue !== undefined) {
			return createIsNumericRangeValidator(numberEditorOptions.minValue, numberEditorOptions.maxValue);
		}
	}
	return undefined;
}

function wrapValidatorFunc(validatorFunc: (val: string) => boolean) {
	return (newValue: string, row: any, column: Column, done: any) => {
		if (validatorFunc(newValue) === false) {
			return { valid: false, message: format(STRINGS.IS_INVALID, newValue) };
		}
		return true;
	};
}

function createCustomEditorRendererForColumn<T>(colDef: IColumnDefinition<T>) {
	if (colDef.editable && colDef.editorOptions
		&& colDef.editorOptions.type === EditorType.MultiSelect) {
		const multiSelectEditorOptions = colDef.editorOptions as IMultiSelectEditorOptions;

		if (!multiSelectEditorOptions.values) {
			throw new Error(`Column ${colDef.key} is of type multi-select, but allowedValues option is undefined`);
		}

		return (editorProps: EditorProps, value: string[]) => (
			<MultiSelectCellEditor value={value} options={multiSelectEditorOptions.values} onUpdate={editorProps.onUpdate} />
		);
	}
}

function wrapWithToolTipFormatter<T>(key: StringKeyOf<T>, toolTipTextFunc: (row: T, key: StringKeyOf<T>) => string,
	wrappedFormatter?: ColumnFormatter) {
	return (cellValue: any, row: T) => {
		return (
			<OverlayTrigger
				placement="top"
				overlay={(
					<Tooltip id={`tooltip-${v1().slice(0, 4)}`}>
						{toolTipTextFunc(row, key)}
					</Tooltip>
				)}
			>
				<span>
					{wrappedFormatter ? wrappedFormatter(cellValue, row) : cellValue}
				</span>
			</OverlayTrigger>
		);
	};
}

function imageFormatter<T>(imgUrl: string, row: T) {
	return (
		<img src={imgUrl} className={"img-cell"} alt="" />
	);
}

function createCustomFormatterForColumn<T>(colDef: IColumnDefinition<T>): ColumnFormatter | undefined {
	let formatter: ColumnFormatter | undefined;
	if (colDef.customFormatter) {
		if (colDef.customFormatter === CustomFormatter.Array) {
			formatter = stringArrayFormatter;
		} else if (colDef.customFormatter === CustomFormatter.Image) {
			formatter = imageFormatter;
		} else {
			throw new Error(`Unsupported custom formatter: ${colDef.customFormatter}`);
		}
	}

	if (colDef.showToolTip) {
		if (!colDef.toolTipText) {
			throw new Error("showToolTip column property was set to true but no toolTipText property is present");
		}

		formatter = wrapWithToolTipFormatter(colDef.key, colDef.toolTipText, formatter);
	}

	return formatter;
}

function StatefulToggleButton(props: {
	rowId: RowId,
	initialValue: boolean,
	onToggleCallback?: (rowId: RowId, isActive: boolean) => void,
	readonly?: boolean
}) {
	const [isOn, setIsOn] = React.useState(props.initialValue);

	function onToggle(newVal: boolean) {
		if (props.readonly) {
			return;
		}

		setIsOn(newVal);

		if (props.onToggleCallback) {
			props.onToggleCallback(props.rowId, newVal);
		}
	}
	return <CheckBox value={isOn} onChange={onToggle} name={props.rowId} />;
}
