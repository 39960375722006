import { BaseCrudApiClient } from "./base-crud-api-client";

export interface IIdBasedEntity {
	id: string;
}

export abstract class IdBasedCrudApiClient<T, TMetadata = any, TRootParams = any>
	extends BaseCrudApiClient<T, IIdBasedEntity, string, TMetadata, TRootParams> {

	protected convertFindParamsToPath = (findParams: string) => {
		return findParams;
	}

	protected convertPartialEntityToPath = (idPartial: IIdBasedEntity) => {
		return idPartial.id;
	}
}
