export interface IAccordionMessage {
	key: string;
	message: string | undefined;
}

export const ACCORDION_PANEL_NO_CONTENT_MESSAGE: IAccordionMessage[] = [
	{ key: "need-attention", message: "Doing great! Nothing needs special attention at the moment." },
	{ key: "in-the-works", message: undefined },
	{ key: "backlog", message: undefined },
	{ key: "done", message: undefined },
];
