import { action, observable } from "mobx";
import * as LaunchDarkly from "launchdarkly-js-client-sdk";

import BaseStore from "./base-store";
import config from "../../utils/config";

export default class FeatureFlagStore extends BaseStore<{}> {
	@observable
	public isInitialized = false;

	private client: LaunchDarkly.LDClient | null = null;

	@action.bound
	isFeatureEnabled(flag: string) {
		// ACM-7811: Removed LD
		return true;

		// if (!this.client) {
		// 	return false;
		// }

		// const variantDetails = this.client.variationDetail(flag, false);
		// if (!variantDetails || !variantDetails.value) {
		// 	return false;
		// }
		// return Boolean(variantDetails.value).valueOf();
	}

	@action.bound
	async initialize(customerId: string) {
		const { launchDarklyClientId } = config();

		if (!launchDarklyClientId) {
			throw new Error("launchDarklyClientId is not defined");
		}

		// ACM-7811: Disabled LD
		// const client = LaunchDarkly.initialize(
		// 	launchDarklyClientId,
		// 	FeatureFlagStore.getLaunchDarklyUser(customerId),
		// 	{ bootstrap: "localStorage" }
		// );
		// await client.waitUntilReady();
		// this.client = client;
		this.isInitialized = true;
	}

	@action.bound
	async close() {
		if (this.client) {
			await this.client.close();
		}
	}

	// private static getLaunchDarklyUser(customerId: string) {
	// 	return {
	// 		key: customerId,
	// 		custom: {
	// 			customerId,
	// 		}
	// 	};
	// }
}
