import * as React from "react";
const Bear = (props: React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fill="#6A462F"
			d="m24.754 17.919-6.666-5.333-8.167 1-3 4.666.5 6.667 3.833 5.833s1.834 1.167 2.667 1.667l-2.5 7.667-.333 6.333 1.5 5.5 2.833 3.667 5.5 3.833s15.667 9 30.667.167l5.833-4.834 3.167-6 .666-4.666-1.333-8.834-1.667-2.5 2.834-2.166 2.5-4.834 1.666-5.333-1.666-5-4.334-3-4.166-.167-6 3.834-1.334 1.5-8.333-1.667h-8.167l-6.5 2z"
		/>
		<path
			fill="#A57939"
			d="m29.588 37.752-2 5-.834 6.667 2 3.167.334 4.333 2.833 2.167 8.667-.334 2.333-1 .167-5.167 2-3.166-1.167-6.667-2.5-5.5s-7-4.833-11.833.5z"
		/>
		<path
			fill="#3F3F3F"
			d="M30.754 47.752s4.334-4.667 9.834-.333l-1.834 2.833-6-.167-2-2.333z"
		/>
		<path
			fill="#A57939"
			d="M47.754 18.419s12.834-4 15.167 8l2-4-.167-5.5-3.5-3.833-5.5-1.334-5.5 3.667-2.5 3zM24.254 18.419s-12.833-4-15.166 8l-2-4 .166-5.5 3.5-3.833 5.5-1.334 5.5 3.667 2.5 3z"
		/>
		<circle cx={28.634} cy={36.999} r={2} />
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M26.009 19.895S18.32 8.27 11.07 13.082c-6.6 4.382-3.91 13.959.603 16.915"
		/>
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M21.071 58.582c-15.937-6.312-7.85-24.013-7.85-24.013s3.645-9.359 9.341-11.609M51.098 58.582c15.937-6.312 7.85-24.013 7.85-24.013s-3.645-9.359-9.341-11.609"
		/>
		<circle cx={43.366} cy={36.999} r={2} />
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="m43.495 43.331 1.56 4.804c.22.817.086 1.682-.367 2.396-1.22 1.924-4.054 5.119-8.26 2.239h-.857c-4.205 2.88-7.04-.315-8.26-2.239a3.007 3.007 0 0 1-.367-2.396l1.56-4.804"
		/>
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M40.172 47.924c-.101 3.008-2.805 1.656-2.805 1.656L36 48.904l-1.367.676s-2.704 1.352-2.805-1.656M45.991 19.895S53.68 8.27 60.93 13.082c6.6 4.382 3.91 13.959-.603 16.915M29.946 57.148s5.188 6.601 12.108.562M24.665 17.951s8.71-4.93 22.326 0M36 52.77v-3.19"
		/>
	</svg>
);
export default Bear;
