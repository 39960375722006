import { useCallback, useState } from "react";
import { SortableColumn } from "../../types/sortable-column";

/**
 * Takes an optional initial sorted column and returns an object containing sorted column (if any) and
 * a sort function which takes column name/id and a selector function to be used by sorting algorithm.
 * @param initialSortedColumn
 */
function useSortState<T>(initialSortedColumn: SortableColumn | null = null) {
	const [sortedColumn, setSortedColumn] = useState(initialSortedColumn);

	const sort = useCallback((columnName: string, sortBy: (item: T) => unknown) => {
		setSortedColumn(prevSortedColumn => {
			const sortDirection = prevSortedColumn && columnName === prevSortedColumn.columnName
				? prevSortedColumn.sortDirection === "asc" ? "desc" : "asc"
				: "asc";

			return {
				columnName,
				sortDirection,
				sortBy,
			};
		});
	}, []);

	return { sortedColumn, sort };
}

export default useSortState;
