import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

interface IChooseIntegration {
	isActive: boolean;
}

// tslint:disable-next-line: variable-name
const EmptyState = (props: IChooseIntegration) => {
	const { isActive = true } = { ...props };

	return (
		<div className="show-background-image"
			style={{ backgroundImage: `url(/assets/images/empty-state-background.svg)`}}>
			<div id="empty-state-page">
				<img className="acumen-logo" src="/assets/images/logo-white.svg" alt="Acumen" />
				<div className="empty-state-page-title">Let’s get started!</div>
				<div className="empty-state-page-line">Integrate your data sources to get started with Acumen.</div>
				{isActive &&
					<Link to="/my-account/connected-apps">
						<button className="ui basic blue button integration-button">Connect</button>
					</Link>
				}
				{!isActive &&
					<div className="empty-state-page-line cant-integrate">Notify your administrator to start the integration.</div>
				}
			</div>
		</div>
	);
};
export default EmptyState;
