import { useCallback, useState } from "react";
import * as React from "react";

export function useInputState<T extends HTMLInputElement | HTMLTextAreaElement = HTMLInputElement>(defaultValue: string) {
	const [name, setName] = useState("");
	const handleChange = useCallback((event: React.ChangeEvent<T>) => setName(event.target.value), []);
	const clear = useCallback(() => setName(""), []);

	return [name, handleChange, clear] as const;
}
