import { round } from "@acumen/common";
import { IDashboardTeam } from "@acumen/dashboard-common";
import { WorkforceHealthMetrics } from "@acumen/database-types";
import _ from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import DropdownListSelector from "../../components/form/dropdown-list-selector";
import LoadingIndicator from "../../components/loader/loader";
import MyUser from "../../layout/navigation/my-user";
import { useStores } from "../../mobx-stores";
import { DevStatsColumnConfigModal } from "../workforce-health/components/developer-stats-column-config-modal/developer-stats-column-config-modal";
import { TEAM_COMPARISON_METRIC_NAMES_BY_CATEGORIES, WHF_METRICS_TO_COLUMNS } from "../workforce-health/dev-stat-columns";
import {
	PrCycleTimeForSelectedTeams
} from "./components/pr-cycle-time-for-selected-teams/pr-cycle-time-for-selected-teams";
import { PrCycleTime } from "./components/pr-cycle-time/pr-cycle-time";
import { TeamComparisonQuadrants } from "./components/team-comparison-quadrants/team-comparison-quadrants";
import { TeamComparisonTable } from "./components/team-comparison-table/team-comparison-table";
import { filterTeamsByType, TeamMetricBadgeFilter, TeamMetricFilterType } from "./components/team-metric-badge-filter/team-metric-badge-filter";
import { PrCycleStatus } from "./enums";
import "./team-comparison.scss";
import { MetricData, PrCycleTimeBreakdownItem, TeamPerformanceSummaryViewModel, TeamViewModel } from "./types";
import moment from "moment";

const METRICS_NEEDED_FOR_PR_BREAKDOWN = [
	WorkforceHealthMetrics.AveragePRCodingTime,
	WorkforceHealthMetrics.AveragePRPendingReviewTime,
	WorkforceHealthMetrics.AveragePRReviewTime,
	WorkforceHealthMetrics.AveragePRPendingMergeTime
];

const TOP_METRIC_FOR_PR_BREAK_DOWN = WorkforceHealthMetrics.AveragePRCycleTime;

const METRICS_NEEDED_FOR_QUADRANTS = [
	TOP_METRIC_FOR_PR_BREAK_DOWN,
	WorkforceHealthMetrics.Velocity,
	WorkforceHealthMetrics.MergedPRs,
	WorkforceHealthMetrics.AveragePRSize,
	WorkforceHealthMetrics.MTTR,
	WorkforceHealthMetrics.LeadTime,
];

const QS_PARAM_TEAM = "team";

export const createSearchParamsForPage = (teamIds: string[]) => {
	const params = new URLSearchParams({});
	if (teamIds && teamIds.length > 0) {
		teamIds.forEach(x => params.append(`${QS_PARAM_TEAM}[]`, x));
	}

	return params;
};

export const TeamMetrics = observer(() => {
	const { teamsStore, developerStatsStore, authStore, featureFlagsStore, teamMembersStore } = useStores();
	const location = useLocation();
	const history = useHistory();
	const currentQsParams = new URLSearchParams(location.search);

	const startState = {
		teamsIds: currentQsParams.getAll(`${QS_PARAM_TEAM}[]`) ?? [],
	};

	const isAddRemoveColumnEnabled = featureFlagsStore.isFeatureEnabled("ui-show-add-remove-column");

	const [items, setItems] = useState<TeamViewModel[] | null>(null);
	const [selectedTeams, setSelectedTeams] = useState<IDashboardTeam[]>([]);
	const [chartData, setChartData] = useState<TeamPerformanceSummaryViewModel[]>([]);
	const [modalOpenState, setModalOpenState] = useState<boolean>(false);
	const [selectedFilterType, setSelectedFilterType] = useState<TeamMetricFilterType | undefined>();
	const [topTrendData, setTopTrendData] = useState<TeamPerformanceSummaryViewModel>({
		data: [],
		id: 0,
		isIncreasePositive: true,
		previousValue: 0,
		currentValue: 0,
		subtitle: "",
		title: "",
		unit: "hours",
		tooltip: ``
	});

	const columns = useMemo(
		() => (developerStatsStore.teamComparisonSelectedMetrics ?? []).map(x => WHF_METRICS_TO_COLUMNS[x]),
		[developerStatsStore.teamComparisonSelectedMetrics]
	);

	useEffect(() => {
		teamsStore.fetchAllTeams().catch();
		developerStatsStore.getTeamComparisonSelectedMetrics(authStore.authUser.dataContributorDetails.id).catch();
		teamMembersStore.fetchTeamMembersForAllTeams().catch();
	}, []);

	useEffect(() => {
		if (teamsStore.allTeams.loaded) {
			setSelectedTeams(teamsStore.allTeams.data.filter(t => {
				// select only selected teams if we have a start state
				if (startState.teamsIds.length > 0) {
					if (startState.teamsIds.indexOf(t.id) > -1) {
						return true;
					}
					return false;
				}
				return true;
			}));
		}
	}, [teamsStore.allTeams]);

	const updateQueryString = (teams: IDashboardTeam[]) => {
		const params = createSearchParamsForPage(teams.map(t=>t.id));

		history.replace({ pathname: location.pathname, search: params.toString() });
	};

	useEffect(() => {
		if (!selectedTeams || selectedTeams.length === 0) {
			return;
		}
		developerStatsStore.getTeamBadgesData(selectedTeams.map(t => t.id)).catch();
	}, [selectedTeams]);

	useEffect(() => {
		if (!developerStatsStore.teamComparisonSelectedMetrics || developerStatsStore.teamComparisonSelectedMetrics.length === 0) {
			return;
		}

		developerStatsStore.getTeamStatsData(
			authStore.authUser.timezone,
			selectedTeams.map(t => t.id),
			_.uniq([
				TOP_METRIC_FOR_PR_BREAK_DOWN,
				...METRICS_NEEDED_FOR_PR_BREAKDOWN,
				...METRICS_NEEDED_FOR_QUADRANTS,
				...developerStatsStore.teamComparisonSelectedMetrics,
			])
			.map(x => WHF_METRICS_TO_COLUMNS[x])
		).catch();

		developerStatsStore
			.getTeamStatsChartData(authStore.authUser.timezone,
				selectedTeams.map(x => x.id),
				_.uniq([TOP_METRIC_FOR_PR_BREAK_DOWN, ...METRICS_NEEDED_FOR_PR_BREAKDOWN]).map(x => WHF_METRICS_TO_COLUMNS[x]))
			.catch();
	}, [selectedTeams, developerStatsStore.teamComparisonSelectedMetrics]);

	useEffect(() => {
		if (!teamsStore.allTeams.loaded) {
			return;
		}

		if (!developerStatsStore.teamStats || !developerStatsStore.teamBadgesData) {
			return;
		}

		if (!teamMembersStore.teamMembersForAllTeams.loaded) {
			return;
		}

		if (developerStatsStore.teamComparisonSelectedMetrics === null
			|| developerStatsStore.teamComparisonSelectedMetrics.length === 0) {
			return;
		}

		const currItems: TeamViewModel[] = selectedTeams.map(t => ({
			info: {
				id: t.id,
				name: t.name,
				members: teamMembersStore.teamMembersForAllTeams.data.filter(member => member.teamId === t.id)
			},
			stats: Object.assign({},
				...(developerStatsStore.teamComparisonSelectedMetrics ?? []).map((wfhMetric: WorkforceHealthMetrics) => ({
					[wfhMetric]: {
						current: developerStatsStore.teamStats!.get(t.id, wfhMetric, "current"),
						previous: developerStatsStore.teamStats!.get(t.id, wfhMetric, "previous")
					}
				}))
			),
			cycleTimeBreakdown: cycleTimeBreakdown(t.id, developerStatsStore.teamStats!),
			badges: {
				metricBadges: developerStatsStore.teamBadgesData!.teams[t.id]?.metricBadges,
				aggregatedBadges: developerStatsStore.teamBadgesData!.teams[t.id]?.aggregatedBadges,
			},
		}));

		setItems(currItems);
		if (developerStatsStore.teamChartData) {
			const currentAvg = developerStatsStore.currentTeamStatsAvgByMetric!;
			const previousAvg = developerStatsStore.previousTeamStatsAvgByMetric!;
			const currentChartData: TeamPerformanceSummaryViewModel[] = [TOP_METRIC_FOR_PR_BREAK_DOWN, ...METRICS_NEEDED_FOR_PR_BREAKDOWN]
				.map(x => WHF_METRICS_TO_COLUMNS[x])
				.map((metric, ind) => ({
					id: ind,
					name: metric.dashboardMetricName,
					title: metric.displayValues.title,
					subtitle: metric.displayValues.subtitle,
					previousValue: round(previousAvg[metric.dashboardMetricName]!, 2),
					currentValue: round(currentAvg[metric.dashboardMetricName]!, 2),
					data: developerStatsStore.teamChartData![metric.dashboardMetricName]!,
					unit: metric.displayValues.unit,
					isIncreasePositive: metric.displayValues.isIncreasePositive,
					tooltip: metric.displayValues.teamTooltip
				}));

			setChartData(currentChartData.slice(1));
			setTopTrendData(currentChartData[0]);
		}

	}, [
		developerStatsStore.teamStats,
		teamsStore.allTeams,
		selectedTeams,
		developerStatsStore.teamChartData,
		teamMembersStore.teamMembersForAllTeams,
		developerStatsStore.teamBadgesData
	]);

	useEffect(() => {
		if (!featureFlagsStore.isFeatureEnabled("ui-team-comparison")) {
			history.push("/");
		}
	}, []);

	return (
		<LoadingIndicator isActive={items === null}>
			<div className="team-comparison">
				<div className="header-container">
					<div className="ui fluid grid">
						<div className="eight wide column">
							<h1 className="team-comparison-title">Team Metrics
							</h1>
							<span className="team-comparison-last-generated">
								{developerStatsStore.teamBadgesData?.config?.startTime
									&& `Last generated at ${moment.tz(developerStatsStore.teamBadgesData?.config?.startTime, developerStatsStore.teamBadgesData?.config?.configs.devStats.timezone).format("MMM Do YYYY, HH:mm zz")}`}
							</span>
						</div>
						<div className="eight wide right aligned column">
							<MyUser/>
						</div>
					</div>
				</div>
				<div className="flex-row team-comparison-subheader">
					<div className="flex-gap">
						<DropdownListSelector
							isLoading={teamsStore.allTeams.loading}
							title="Teams"
							placeholder="Select teams"
							values={(selectedTeams).map(selectedTeam => selectedTeam.id)}
							isMulti={true}
							onChange={(selectedOptions: any) => {
								if (selectedOptions) {
									const currentSelectedTeams = selectedOptions.map((x: { key: string }) => teamsStore.allTeams.data.find(y => y.id === x.key));
									setSelectedTeams(currentSelectedTeams);
									updateQueryString(currentSelectedTeams);
								}
							}}
							options={teamsStore.allTeams.data.map(team => {
								return {
									key: team.id,
									value: team.id,
									label: team.name,
								};
							})}
							customColors={selectorsTheme.colors}
							customSize={selectorsTheme.size}
						/>
					</div>
					<div className="team-comparison-note">
						Based on data from the past 30 days
					</div>
				</div>
				<div className="team-comparison-cards">
					<div className="team-comparison-card-container">
						{items && (
							<PrCycleTimeForSelectedTeams teams={items}/>
						)}
					</div>
					<PrCycleTime items={chartData} topTrend={topTrendData}/>
				</div>

				{items && (
					<div>
						<div className="team-comparison-sub-title">Team comparison</div>
						<TeamComparisonQuadrants
							teams={items}
							metricNames={METRICS_NEEDED_FOR_QUADRANTS}
						/>
					</div>
				)}

				{isAddRemoveColumnEnabled && (
					<div>
						<DevStatsColumnConfigModal
							isOpen={modalOpenState}
							setIsOpen={setModalOpenState}
							selectedMetrics={developerStatsStore.teamComparisonSelectedMetrics ?? []}
							updateSelectedMetrics={async (dcId, modalSelectedMetrics) => {
								await developerStatsStore.updateTeamComparisonSelectedMetrics(dcId, modalSelectedMetrics);
							}}
							metricNamesByDisplayCategories={TEAM_COMPARISON_METRIC_NAMES_BY_CATEGORIES}
						/>
					</div>
				)}

				{items && (
					<div>
						<div className="team-comparison-metric-badge-filter-container">
							<h3 className="team-comparison-sub-title">
								Breakdown
							</h3>
							<TeamMetricBadgeFilter onSelectedFilterItem={setSelectedFilterType} selectedFilterItem={selectedFilterType} teams={items} />
						</div>
						<TeamComparisonTable
							columns={columns}
							items={filterTeamsByType(items, selectedFilterType)}
							estimationMethod={developerStatsStore.teamBadgesData?.config?.configs.devStats.estimationMethod}
							onAddColumnClick={isAddRemoveColumnEnabled ? () => setModalOpenState(true) : undefined}
						/>
					</div>
				)}
				{items === null && <LoadingIndicator isActive={items === null} local={true} />}
			</div>
		</LoadingIndicator>
	);
});

function cycleTimeBreakdown(teamId: string, data: MetricData): PrCycleTimeBreakdownItem[] {
	return [
		{
			name: "WIP",
			status: PrCycleStatus.WIP,
			totalSeconds: data.get(teamId, WorkforceHealthMetrics.AveragePRCodingTime, "current") * 3600
		},
		{
			name: "Awaiting Review",
			status: PrCycleStatus.AwaitingReview,
			totalSeconds: data.get(teamId, WorkforceHealthMetrics.AveragePRPendingReviewTime, "current") * 3600
		},
		{
			name: "In Review",
			status: PrCycleStatus.InReview,
			totalSeconds: data.get(teamId, WorkforceHealthMetrics.AveragePRReviewTime, "current") * 3600
		},
		{
			name: "Pending Merge",
			status: PrCycleStatus.PendingMerge,
			totalSeconds: data.get(teamId, WorkforceHealthMetrics.AveragePRPendingMergeTime, "current") * 3600
		},
	];
}

const selectorsTheme = {
	colors: {
		borderRadius: 1,
		colors: {
			text: "#925EFF",
			primary: "#C2C4C3",
			primary25: "#f3f6f4",
			neutral0: "#FFFFFF",
			neutral80: "#925EFF",
		},
	},
	size: {
		minHeight: 30,
		height: 30,
		minWidth: 200,
		fontSize: 15,
		alignContent: "space-around",
		border: 0,
		boxShadow: "none",
	}
};
