import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useState } from "react";
import { useStores } from "../../../mobx-stores";
import { CategoryChartSeriesData } from "v2/helpers/charts";
import LoadingIndicator from "../../../components/loader/loader";
import { IChartScope } from "./chart-scope";
import useDeepCompareEffect from "use-deep-compare-effect";
import _ from "lodash";
import PieChart from "./pie-chart";
import { ChartExternalLegend, ILegendList } from "./legend";
import { AcumenTaskType } from "@acumen/dashboard-common";
import uniqolor from "utils/color";
import colorScheme, { normalizeSeriesKey } from "./chart-color-scheme";
import { round } from "@acumen/common";
import { Formatters } from "./formatters";
import { OrgMetricsCard } from "../components/org-metrics-card/org-metrics-card";

interface IProps {
	scope: IChartScope;
	showDataLabels?: boolean;
	showExternalLegend?: boolean;
	chartHeight?: {
		mainChartHeight: number,
		summaryPieChartHeight: number
	};
}

function IssueCycleTimeByType(props: IProps) {
	const { metricOrgStore } = useStores();
	const [expandedChartOptions, setExpandedChartOptions] = useState<Highcharts.Options | undefined>(undefined);
	const [summarizedChartOptions, setSummarizedChartOptions] = useState<Highcharts.Options | undefined>(undefined);
	const [summarizedLegendList, setSummarizedLegendList] = useState<{ data: ILegendList[], title?: string } | undefined>(undefined);
	const [hoveredSummaryMetric, setHoveredSummaryMetric] = useState<undefined | string>(undefined);
	const tooltip = "Shows the amount of cycle time (how long each ticket was in progress) spent on each task type.";

	const { scope, showDataLabels, showExternalLegend, chartHeight } = { ...props };

	function buildExpandedChartOptions(data: CategoryChartSeriesData): Highcharts.Options {
		let series: Highcharts.SeriesColumnOptions[] | undefined;
		series = Object
			.keys(data)
			.map(key => {
				const displayProperties = seriesByTaskName(key);
				const seriesOptions: Highcharts.SeriesColumnOptions = {
					name: displayProperties.seriesName,
					index: displayProperties.sortOrder,
					legendIndex: displayProperties.sortOrder,
					color: displayProperties.seriesColorHex,
					data: _.cloneDeep(data[key]),
					visible: (data[key].length > 0 && _.findIndex(data[key], i => i[1] > 0) >= 0),
					type: "column",
					showInLegend: showExternalLegend ? false : true,
					stack: "issue-type"
				};
				return seriesOptions;
			});
		const options: Highcharts.Options = {
			title: undefined,
			chart: { height: 349 },
			tooltip: {
				headerFormat: '<span style="color:{point.color}">\u25CF</span><b> {series.name}</b>:<br>',
				pointFormatter: function _pointFormatter() {
					return `${Formatters.cycleTime(this.y)}<br/>`;
				},
				formatter: function _formatter() {
					if (!this.y || !this.total || this.y === 0 || this.total === 0) {
						return "0%";
					}
					return `${round(100 * this.y / this.total, 0)}%`;
				},
			},
			xAxis: {
				gridLineWidth: 1,
				type: "datetime"
			},
			yAxis: [
				{
					min: 0,
					title: {
						text: "%"
					}
				}
			],
			plotOptions: {
				column: {
					pointPadding: 0.2,
					borderWidth: 0,
					stacking: "percent",
					dataLabels: {
						enabled: false,
						// tslint:disable-next-line: object-literal-shorthand
					},
				}
			},
			credits: {
				enabled: false
			},
			series
		};

		return options;
	}

	type EnrichedPointOptions = Highcharts.PointOptionsObject & {
		totalHoursPerIssueType: number;
	};

	function buildSummarizedChartOptions(data: CategoryChartSeriesData): Highcharts.Options {
		const totalHoursInvested = _.sum(Object.keys(data).map(key => _.sumBy(data[key], d => d[1])));

		const piePoints = Object.keys(data)
			.map(key => {
				const displayProperties = seriesByTaskName(key);
				const totalHoursPerIssueType = _.sumBy(data[key], d => d[1]);
				const seriesOptions: EnrichedPointOptions = {
					name: displayProperties.seriesName,
					color: displayProperties.seriesColorHex,
					y: totalHoursInvested === 0 ? 0 : round(100 * totalHoursPerIssueType / totalHoursInvested, 1),
					totalHoursPerIssueType
				};
				return seriesOptions;
			})
			.filter(point => point.y && point.y > 0);
		setSummarizedLegendList({
			data: ((array: EnrichedPointOptions[]): any[] => {
				return array.map(point => ({
					name: point.name?.toString() ?? "",
					value: point.y ?? 0,
					color: point.color?.toString(),
					valueSuffix: "%"
				})
				);
			})(piePoints),
			title: "Total work time by task type"
		});
		const pieSeries: Highcharts.SeriesPieOptions = {
			type: "pie",
			data: piePoints,
			innerSize: "80%",
			tooltip: {
				pointFormatter: function _pointFormatter() {
					const point: EnrichedPointOptions = this as unknown as EnrichedPointOptions;
					return `<b>${Formatters.cycleTime(point.totalHoursPerIssueType)}</b>${point.y ? ` (${point.y}%)` : ""}`;
				}
			},
		};
		const options: Highcharts.Options = {
			chart: {
				plotBackgroundColor: undefined,
				plotBorderWidth: undefined,
				plotShadow: false
			},
			title: {
				verticalAlign: "middle",
				floating: true
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: "pointer",
					dataLabels: showDataLabels ? {
						enabled: true,
						format: "<b>{point.name}</b>: {point.y}"
					} : {
						enabled: false,
					}
				}
			},
			credits: {
				enabled: false
			},
			series: [pieSeries]
		};

		return options;
	}

	async function fetchData(): Promise<CategoryChartSeriesData | undefined> {
		const data = await metricOrgStore.issuesByTypeCycleEnd(scope.startTime, scope.endTime, scope.timezone, scope.interval,
			undefined, scope.projectIds, scope.dataContributorIds, scope.subtaskStrategy);

		return data;
	}

	function shouldFetchData(): boolean {
		return (!metricOrgStore.isLoadingIssuesByTypeCycleEndMetric);
	}

	useDeepCompareEffect(() => {
		let isMounted = true;
		async function fetchChartData() {
			const data = await fetchData();
			if (isMounted && data) {
				setExpandedChartOptions(buildExpandedChartOptions(data));
				setSummarizedChartOptions(buildSummarizedChartOptions(data));
			}
		}

		if (shouldFetchData()) {
			// We need to clear those options as we might have a different count of columns which will cause to a crash in highcharts
			setExpandedChartOptions(undefined);
			setSummarizedChartOptions(undefined);
			// tslint:disable-next-line: no-floating-promises
			fetchChartData().then(() => {
				isMounted = false;
			});
		}
	}, [props]);

	return (
		<OrgMetricsCard title="Work time by task type" tooltip={tooltip}>
			<LoadingIndicator local={true} isActive={expandedChartOptions === undefined}>
				<div className="issue-cycle-time-by-type-chart">
					<div>
						{expandedChartOptions && (
							<HighchartsReact
								highcharts={Highcharts}
								options={expandedChartOptions}
							/>
						)}
					</div>

					<div>
						<div className="issue-cycle-time-by-type-chart-header">
							<div className="issue-cycle-time-by-type-chart-title-container">
								{summarizedLegendList && (
									<h2 className="issue-cycle-time-by-type-chart-title">
										{summarizedLegendList?.title}
									</h2>
								)}
							</div>
							<div className="issue-cycle-time-by-type-chart-header-filler"/>
						</div>
						{showExternalLegend && (
							<div className="issue-cycle-time-by-type-chart-container">
								<div className="issue-cycle-time-by-type-chart-pie">
									{summarizedChartOptions && (
										<PieChart
											series={summarizedChartOptions?.series}
											triggerHover={hoveredSummaryMetric}
											height={chartHeight ? chartHeight?.summaryPieChartHeight : undefined}
										/>
									)}
								</div>

								<div className="issue-cycle-time-by-type-chart-legend">
									{summarizedChartOptions?.series !== undefined && (
										<ChartExternalLegend
											legendList={summarizedLegendList?.data}
											hoveredEntry={hoveredSummaryMetric}
											setHoveredEntry={setHoveredSummaryMetric}
										/>
									)}
								</div>
							</div>
						)}
						{summarizedChartOptions && !showExternalLegend && (
							<HighchartsReact
								highcharts={Highcharts}
								options={summarizedChartOptions}
							/>
						)}
					</div>
				</div>
			</LoadingIndicator>
		</OrgMetricsCard>
	);
}

export default IssueCycleTimeByType;

interface ISeriesProps {
	seriesName: string;
	seriesColorHex: string;
	sortOrder?: number;
}

const DISPLAY_PROPERTIES: { [key: string]: ISeriesProps } = {
	[normalizeSeriesKey(AcumenTaskType.Task)]: {
		sortOrder: 1,
		seriesName: "Task",
		seriesColorHex: colorScheme.issueType.task
	},
	[normalizeSeriesKey(AcumenTaskType.Bug)]: {
		sortOrder: 2,
		seriesName: "Bug",
		seriesColorHex: colorScheme.issueType.bug
	},
	[normalizeSeriesKey(AcumenTaskType["Sub-Task"])]: {
		sortOrder: 3,
		seriesName: "Sub-Task",
		seriesColorHex: colorScheme.issueType.subTask
	},
	[normalizeSeriesKey(AcumenTaskType["New Feature"])]: {
		sortOrder: 4,
		seriesName: "New Feature",
		seriesColorHex: colorScheme.issueType.newFeature
	},
	[normalizeSeriesKey(AcumenTaskType["Change Request"])]: {
		sortOrder: 5,
		seriesName: "Change Request",
		seriesColorHex: colorScheme.issueType.changeRequest
	},
	[normalizeSeriesKey(AcumenTaskType.Story)]: {
		sortOrder: 6,
		seriesName: "Story",
		seriesColorHex: colorScheme.issueType.story
	},
	[normalizeSeriesKey(AcumenTaskType.Epic)]: {
		sortOrder: 7,
		seriesName: "Epic",
		seriesColorHex: colorScheme.issueType.epic
	},
	[normalizeSeriesKey(AcumenTaskType.Improvement)]: {
		sortOrder: 8,
		seriesName: "Improvement",
		seriesColorHex: colorScheme.issueType.improvement
	},
	[normalizeSeriesKey(AcumenTaskType["Technical Task"])]: {
		sortOrder: 9,
		seriesName: "Technical Task",
		seriesColorHex: colorScheme.issueType.techTask
	},
	[normalizeSeriesKey(AcumenTaskType.Support)]: {
		sortOrder: 10,
		seriesName: "Support",
		seriesColorHex: colorScheme.issueType.support
	},
	[normalizeSeriesKey(AcumenTaskType.Security)]: {
		sortOrder: 11,
		seriesName: "Security",
		seriesColorHex: colorScheme.issueType.security
	},
	[normalizeSeriesKey(AcumenTaskType.Documentation)]: {
		sortOrder: 12,
		seriesName: "Documentation",
		seriesColorHex: colorScheme.issueType.documentation
	},
};

function seriesByTaskName(taskName: string): ISeriesProps {
	const key = normalizeSeriesKey(taskName);
	const displayProperties = DISPLAY_PROPERTIES[key];
	if (displayProperties) {
		return displayProperties;
	}

	return {
		seriesName: taskName,
		seriesColorHex: uniqolor(key),
		sortOrder: undefined
	};
}
