import React from "react";
import {
	AcumenTaskStatus,
	DashboardRetroReportTaskData,
	IDashboardStatus,
	IDashboardTaskStatusChange,
	IRetroReportTasksStateResponse,
	TaskEstimationMethod
} from "@acumen/dashboard-common";
import {
	createTaskEndStatusGetter,
	createTaskStartStatusGetter,
	IterationReview,
	taskCategoryToLabel
} from "../../../components/iteration-review/utils";
import { Formatters } from "../../../components/dashboard-task/formatters";
import { Avatar } from "../../../components/avatar";
import { ExternalImage } from "../../../components/external-image";
import TaskStateInSprint from "./task-state-in-sprint";
import TaskStartStatus from "./task-start-status";
import TaskEndStatus from "./task-end-status";

export const formatCycleTimeValue = (isExtended: boolean, item: DashboardRetroReportTaskData): string | number => {
	if (!isExtended && item.status.status === AcumenTaskStatus.Discarded) {
		return "Discarded";
	}

	return formatTotalCycleTime(item.totalCycleTimeInSeconds);
};

export const formatTotalCycleTime = (totalCycleTimeInSeconds: number | null) => {
	return totalCycleTimeInSeconds
		? Formatters.cycleTime(totalCycleTimeInSeconds * 1000)
		: "N/A";
};

export const getTaskAssignee = (item: DashboardRetroReportTaskData) => item.assignee?.primaryDisplayName ?? "";
export const getTaskType = (item: DashboardRetroReportTaskData) => item.type.internalType ?? "";
export const getTaskPriority = (item: DashboardRetroReportTaskData) => item.priority.internalPriority ?? "";
export const getTaskTitle = (item: DashboardRetroReportTaskData) => item.title ?? "";
export const getTaskEstimation = (item: DashboardRetroReportTaskData) => item.jiraEstimation;
export const getTaskActualCycleTime = (item: DashboardRetroReportTaskData) => item.totalCycleTimeInSeconds ?? -1;

export const getTaskAssigneeFilterOption = (item: DashboardRetroReportTaskData) => {
	const assignee = getTaskAssignee(item);

	return {
		value: assignee,
		label: (
			<div className="task-icon-label-container">
				<Avatar dataContributor={item.assignee} className="task-icon task-icon-large"/>
				<span>{assignee}</span>
			</div>
		)
	};
};

export const getTaskTypeFilterOption = (item: DashboardRetroReportTaskData) => {
	const type = getTaskType(item);

	return {
		value: type,
		label: (
			<div className="task-icon-label-container">
				<ExternalImage src={item.type.iconUrl ?? ""} className="task-icon task-icon-small" />
				<span>{type}</span>
			</div>
		)
	};
};

export const getTaskPriorityFilterOption = (item: DashboardRetroReportTaskData) => {
	const priority = getTaskPriority(item);

	return {
		value: priority,
		label: (
			<div className="task-icon-label-container">
				<ExternalImage src={item.priority.iconUrl ?? ""} className="task-icon task-icon-small" />
				<span>{priority}</span>
			</div>
		)
	};
};

export const getTaskTitleFilterOption = (item: DashboardRetroReportTaskData) => {
	return `${(item.publicIdentifier ?? "")} - ${getTaskTitle(item)}`;
};

export const createTaskStateInSprintGetter = (issueIdsByCategory: IRetroReportTasksStateResponse | undefined) => {
	return (item: DashboardRetroReportTaskData) => {
		const taskCategory = IterationReview.TasksTable.findTaskCategory(issueIdsByCategory, item.entityId);

		return !taskCategory ? "" : taskCategoryToLabel[taskCategory];
	};
};

export const createTaskActualCycleTimeFilterOptionGetter = (isExtended: boolean) => {
	return (item: DashboardRetroReportTaskData) => formatCycleTimeValue(isExtended, item).toString();
};

export const createTaskEstimationFilterOptionGetter = (estimationMethod: TaskEstimationMethod | undefined) => {
	return (item: DashboardRetroReportTaskData) => ({
		value: getTaskEstimation(item)?.toString() ?? "",
		label: Formatters.estimationValue(item.jiraEstimation, estimationMethod, "N/A", true, false),
	});
};

export const createTaskStateInSprintFilterOptionGetter = (issueIdsByCategory: IRetroReportTasksStateResponse | undefined) => {
	const getTaskStateInSprint = createTaskStateInSprintGetter(issueIdsByCategory);

	return (item: DashboardRetroReportTaskData) => ({
		value: getTaskStateInSprint(item),
		label: <TaskStateInSprint task={item}/>
	});
};

export const createTaskStartStatusFilterOptionGetter = (
	isExtended: boolean,
	statusDiff: Record<string, IDashboardTaskStatusChange> | undefined,
	statusIdToStatus: Record<string, IDashboardStatus>
) => {
	const getTaskStartStatus = createTaskStartStatusGetter(isExtended, statusDiff, statusIdToStatus);

	return (item: DashboardRetroReportTaskData) => ({
		value: getTaskStartStatus(item),
		label: <TaskStartStatus task={item} isExtended={isExtended} statusDiff={statusDiff}/>,
	});
};

export const createTaskEndStatusFilterOptionGetter = (
	isExtended: boolean,
	statusDiff: Record<string, IDashboardTaskStatusChange> | undefined,
	statusIdToStatus: Record<string, IDashboardStatus>,
	issueIdsByCategory: IRetroReportTasksStateResponse | undefined
) => {
	const getTaskEndStatus = createTaskEndStatusGetter(isExtended, statusDiff, statusIdToStatus, issueIdsByCategory);

	return (item: DashboardRetroReportTaskData) => ({
		value: getTaskEndStatus(item),
		label: <TaskEndStatus task={item} isExtended={isExtended} statusDiff={statusDiff}/>,
	});
};
