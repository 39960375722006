import React, { useState } from "react";
import { Accordion, Icon } from "semantic-ui-react";
import LoadingIndicator from "../../../components/loader";
import InsightRulesGrid from "./insight-rules-grid/insight-rules-grid";
import { useStores } from "../../../mobx-stores";
import { observer } from "mobx-react";
import { useRouteMatch, useHistory, useLocation } from "react-router-dom";
import SingleInsightRulePage from "./single-insight-rule-page/single-insight-rule-page";

function InsightManagementPage() {
	const match = useRouteMatch();
	const history = useHistory();
	const { insightRulesStore } = useStores();
	const { allInsightRules } = insightRulesStore;
	const { allInsightsCategories } = insightRulesStore;

	const [activeIndex, setActiveIndex] = useState(0);
	const searchParams = new URLSearchParams(useLocation().search);
	const insightId = searchParams.get("id") ?? undefined;

	React.useEffect(() => {
		if (!(allInsightRules.loading || allInsightRules.loaded)) {
			insightRulesStore.fetchAllInsightRules().then().catch();
		}

		if (!(allInsightsCategories.loading || allInsightsCategories.loaded)) {
			insightRulesStore.fetchAllInsightsCategories().then().catch();
		}

		return function unmount() {
			insightRulesStore.resetInsightRules();
			insightRulesStore.resetInsightsCategories();
		};
	}, []);

	async function onInsightToggle(id: string, isActive: boolean) {
		await insightRulesStore.updateInsightRule({ id, isActive });
	}

	return (
		insightId ? (
			<SingleInsightRulePage />
		) : (
				<LoadingIndicator
					isActive={allInsightRules.loading || allInsightsCategories.loading}
				>
					<div className="ui segment raised insights-menu">
						<Accordion>
							{allInsightsCategories.data.map((category, i) => {
								const currentInsightRules = allInsightRules.data.filter((r) => r.categoryId === category.id);
								return (
									currentInsightRules.length > 0 ?
										(
											<div key={i}>
												<Accordion.Title
													active={activeIndex === i}
													index={i}
													onClick={() => activeIndex === 0 ? setActiveIndex(-1) : setActiveIndex(i)}
												>
													<p className="accordion-header">
														<span className=" ui header">
															<Icon name="dropdown" />
															{category.name}
														</span>
														<span className="ui subtitle">{category.description}</span>
													</p>
												</Accordion.Title>
												<Accordion.Content active={activeIndex === i}>
													{allInsightRules.data && (
														<InsightRulesGrid
															// tslint:disable-next-line: no-floating-promises
															onInsightToggleActive={(id: string, isActive: boolean) => onInsightToggle(id, isActive)}
															insightRules={currentInsightRules}
															onInsightClicked={(insight) => {
																history.push(`${match?.url}?id=${insight.id}`);
															}}
														/>
													)}
												</Accordion.Content>
											</div>
										)
										:
										<div key={i} />
								);
							})}
						</Accordion>
					</div>
				</LoadingIndicator>
			)
	);
}
export default observer(InsightManagementPage);
