import "./statuses.scss";
import StatusFormatter from "./status";
import { DashboardPullRequestStatus } from "@acumen/dashboard-common";

const DEFAULT_MISSING_STATUS_VALUE = "N/A";

function classNameFromStatus(prStatus) {
	if (!prStatus) {
		return "";
	}

	switch (prStatus) {
		case DashboardPullRequestStatus.Merged:
		case DashboardPullRequestStatus.Closed:
			return "green";
		case DashboardPullRequestStatus.ClosedNotMerged:
			return "red";
		case DashboardPullRequestStatus.Open:
			return "gray";
		case DashboardPullRequestStatus.AwaitingReview:
		case DashboardPullRequestStatus.InReview:
			return "blue";
		case DashboardPullRequestStatus.Reviewed:
			return "deep-blue";
		default:
			return "";
	}
}

function iconFromStatus(s) {
	switch (s) {
		case DashboardPullRequestStatus.ClosedNotMerged:
			return "window close icon";
		case DashboardPullRequestStatus.Merged:
		case DashboardPullRequestStatus.Closed:
			return "check square icon";
		case DashboardPullRequestStatus.Open:
		case DashboardPullRequestStatus.AwaitingReview:
		case DashboardPullRequestStatus.InReview:
			return "play icon";
		case DashboardPullRequestStatus.Reviewed:
			return "search alternate icon";
		default:
			return "";
	}
}

function textFromStatus(
	prStatus,
	internalStatus,
	defaultValue = DEFAULT_MISSING_STATUS_VALUE
) {
	if (internalStatus) {
		return internalStatus;
	}

	if (prStatus) {
		switch (prStatus) {
			case DashboardPullRequestStatus.ClosedNotMerged:
				return "closed and not merged";
			case DashboardPullRequestStatus.Merged:
				return "merged";
			case DashboardPullRequestStatus.Open:
				return "open";
			case DashboardPullRequestStatus.AwaitingReview:
				return "review requested";
			case DashboardPullRequestStatus.Reviewed:
				return "pending merge";
			case DashboardPullRequestStatus.Closed:
				return "closed";
			case DashboardPullRequestStatus.InReview:
				return "in review";
			case DashboardPullRequestStatus.Unknown:
			default:
				return "";
		}
	}
	return defaultValue || DEFAULT_MISSING_STATUS_VALUE;
}

export default StatusFormatter({
	classNameFromStatus,
	iconFromStatus,
	textFromStatus,
	defaultStatusValue: DEFAULT_MISSING_STATUS_VALUE
});
