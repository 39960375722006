import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";

import { useFilterContext } from "../../providers/filter-provider";
import { FilterOptionModel, ResolvedFilterOption } from "./types";
import { resolveFilterOption } from "./utils";

export const useSearchState = (): [
	string,
	(event: React.ChangeEvent<HTMLInputElement>) => void
] => {
	const [searchTerm, setSearchTerm] = useState("");
	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(event.currentTarget.value),
		[]
	);

	return [searchTerm, handleChange];
};

export const useSelectionState = <I = string | number>(props: {
	options: Array<ResolvedFilterOption<I>>;
	selection?: Set<I> | null;
	isAllSelectedByDefault?: boolean;
}) => {
	const { options, selection = null, isAllSelectedByDefault = true } = props;
	const [currentSelection, setSelection] = useState<Set<I> | null>(selection);
	const values = useMemo(() => options.map(option => option.value), [options]);
	const resolvedSelection = useMemo(
		() => currentSelection ?? new Set(isAllSelectedByDefault ? values : []),
		[currentSelection, values, isAllSelectedByDefault]
	);
	const toggle = useCallback((value: I) => {
		setSelection(selectedValues => {
			const resolvedValues = selectedValues ?? new Set(isAllSelectedByDefault ? values : []);
			const copy = new Set(resolvedValues);

			if (copy.has(value)) {
				copy.delete(value);
			} else {
				copy.add(value);
			}

			return copy.size === options.length ? null : copy;
		});
	}, [values]);
	const selectAll = useCallback(
		() => setSelection(isAllSelectedByDefault ? null : new Set(values)),
		[isAllSelectedByDefault, values]
	);
	const clear = useCallback(
		() => setSelection(isAllSelectedByDefault ? new Set() : null),
		[isAllSelectedByDefault]
	);
	const isMountedRef = useRef(false);

	useEffect(() => {
		if (!isMountedRef.current) {
			isMountedRef.current = true;
		} else {
			setSelection(selection ?? null);
		}
	}, [selection]);

	return {
		options,
		selection: resolvedSelection,
		toggle,
		selectAll,
		clear,
		setSelection,
	};
};

export const useFilterEffect = <T extends any>(props: {
	mapItemToFilterOption?: (item: T) => FilterOptionModel;
	selection: Set<string | number> | null;
}) => {
	const { mapItemToFilterOption, selection } = props;
	const { addFilter, removeFilter } = useFilterContext();
	const isMountedRef = useRef(true);

	useEffect(() => {
		isMountedRef.current = true;

		return () => {
			isMountedRef.current = false;
		};
	}, []);

	useEffect(() => {
		const predicate = (item: T) => {
			if (!selection || !mapItemToFilterOption) {
				return true;
			}

			const model = mapItemToFilterOption(item);
			const value = typeof model === "string" ? model : model.value;

			return selection.has(value);
		};

		addFilter(predicate);

		return () => {
			if (isMountedRef.current) {
				removeFilter(predicate);
			}
		};
	}, [mapItemToFilterOption, selection, addFilter, removeFilter]);
};

export const useUniqueOptions = <T extends any>(props: {
	items: T[];
	mapItemToFilterOption?: (item: T) => FilterOptionModel
}) => {
	const { items, mapItemToFilterOption } = props;

	return useMemo<ResolvedFilterOption[]>(() => {
		if (!mapItemToFilterOption) {
			return [];
		}

		const options = items.map(item => resolveFilterOption(mapItemToFilterOption(item)));

		return _.uniqBy(options, option => option.value);
	}, [items, mapItemToFilterOption]);
};

export const useFilteredOptions = (props: {
	options: ResolvedFilterOption[];
	searchTerm: string;
}): ResolvedFilterOption[] => {
	const { options, searchTerm } = props;

	return useMemo(() => {
		const lowerCaseSearchTerm = searchTerm.toLowerCase();

		return lowerCaseSearchTerm
			? options.filter(item => item.searchValue.toLowerCase().includes(lowerCaseSearchTerm))
			: options;
	}, [options, searchTerm]);
};
