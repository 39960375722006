import {
	DashboardTeamReportMetric, IDashboardDevStatsMetrics,
	IDashboardTeamReportMetric, DashboardTeamReportMetricChangeReaction,
} from "@acumen/dashboard-common";
import { IChartScope } from "../../pages/org-analytics/charts/chart-scope";
import React, { useState } from "react";
import { Avatar } from "../../components/avatar";
import { HoverSelectionPart, TrendCard } from "./particles";
import { observer } from "mobx-react";
import { Icon, Label, Loader, Popup } from "semantic-ui-react";
import { TeamsReportStrings } from "./tooltips";
import classNames from "classnames";
import { ChartSeriesData } from "../../pages/my-team/analytics/charts/charts";
import { useStores } from "../../mobx-stores";
import { devStatsDatesArray, REPORT_METRIC_TO_UNITS } from "../../mobx-stores/new-teams-report-store";
import { round } from "@acumen/common";
import _ from "lodash";
import { columnSortStates } from "../../components/buttons/icon-buttons";
import useDeepCompareEffect from "use-deep-compare-effect";
import Papa from "papaparse";

const DEFAULT_COLOR_HEX = "#2F3236";

// tslint:disable-next-line: variable-name
const DevStatsIndicator = (props: {
	metric: IDashboardTeamReportMetric
	units?: string,
	dateRange: string
}) => {
	const { metric, units } = { ...props };
	const current = metric.currentPeriodValue;
	const change = metric.changeFromPreviousPeriod;
	const previous = metric.previousPeriodValue;

	const reaction = metric.reaction;

	return (
		<p className="dev-stats-indicator">
			<span className="small-text value">{current.toLocaleString(undefined, { minimumFractionDigits: 0 })}</span>
			{change !== 0 && <Popup
				hoverable={true}
				wide={true}
				className={"small-text"}
				position="top center"
				content={<span>Previous value: {previous.toLocaleString(undefined, { minimumFractionDigits: 0 })}{units ? ` ${units}` : ""} (Compared with the previous {props.dateRange})</span>}
				trigger={
					(<span className={classNames("change small-text cursor-help", {
						"gray-text": (change === 0 || reaction === DashboardTeamReportMetricChangeReaction.Neutral),
						"success-text": (change !== 0 && reaction === DashboardTeamReportMetricChangeReaction.Positive),
						"warning-text": (change !== 0 && reaction === DashboardTeamReportMetricChangeReaction.Negative),
					})}>
						{`${change > 0 ? "+" : ""}${round(change * 100, 0)}%`}
					</span>)
				}
			/>}
		</p>
	);
};

const sortColumnsDefaultState: Record<DashboardTeamReportMetric, columnSortStates> = {
	[DashboardTeamReportMetric.LeadTimeHours]: undefined,
	[DashboardTeamReportMetric.PRCycleTotalTimeHours]: undefined,
	[DashboardTeamReportMetric.MergedPRsCount]: undefined,
	[DashboardTeamReportMetric.MergedPRFrequencyDay]: undefined,
	[DashboardTeamReportMetric.PRCycleWorkInProgressHours]: undefined,
	[DashboardTeamReportMetric.PRCycleAwaitingReviewHours]: undefined,
	[DashboardTeamReportMetric.PRCycleInReviewHours]: undefined,
	[DashboardTeamReportMetric.PRCycleReviewedHours]: undefined,
	[DashboardTeamReportMetric.BugsCount]: undefined,
	[DashboardTeamReportMetric.MTTR]: undefined,
	[DashboardTeamReportMetric.BugsFixedToBugsOpenedRate]: undefined,
	[DashboardTeamReportMetric.AveragePRSizeLOC]: undefined,
	[DashboardTeamReportMetric.CompletedTicketsCount]: undefined,
	[DashboardTeamReportMetric.PRLinkedToTasksOutOfPRs]: undefined,
	[DashboardTeamReportMetric.MergedPRSizeLOCSum]: undefined,
	[DashboardTeamReportMetric.ActiveDaysCount]: undefined,
	[DashboardTeamReportMetric.OpenPRsCount]: undefined,
	[DashboardTeamReportMetric.PRsReviewedCount]: undefined,
	[DashboardTeamReportMetric.VelocityStoryPointsCompleted]: undefined,
	[DashboardTeamReportMetric.VelocityEstimatedTimeCompleted]: undefined,
	[DashboardTeamReportMetric.NumberOfClosedUnmergedPRs]: undefined,
	[DashboardTeamReportMetric.NumberOfTasksCompletedPriorityHighest]: undefined,
	[DashboardTeamReportMetric.NumberOfTasksCompletedPriorityHigh]: undefined,
	[DashboardTeamReportMetric.NumberOfTasksCompletedPriorityNormal]: undefined,
	[DashboardTeamReportMetric.NumberOfTasksCompletedPriorityLowAndLowest]: undefined
};

// tslint:disable-next-line: variable-name
const DevStatsBoard = (props: {
	chartScope: IChartScope;
	selectedDateRange: string,
	shouldExtendWithMoreMetrics: boolean
}) => {
	const { chartScope, shouldExtendWithMoreMetrics } = { ...props };
	const {
		newTeamsReportStore: {
			teamsReportDevStatsData: devStatsData,
			getTeamsReportDevStatsData,
			getDevStatsTrendCardCharts,
			devStatsChartsData,
		},
	} = useStores();
	useDeepCompareEffect(() => {
		let isMounted = true;
		async function retrieveDevStatsData(scope: IChartScope) {
			return getTeamsReportDevStatsData(scope.startTime, scope.endTime, scope.teamIds, {
				gitRepositoryIds: scope.repositoryIds,
				projectIds: scope.projectIds
			}, shouldExtendWithMoreMetrics);
		}
		async function retrieveTrendCardCharts(scope: IChartScope) {
			return getDevStatsTrendCardCharts(scope.startTime, scope.endTime, scope.teamIds, scope.interval, scope.timezone, {
				gitRepositoryIds: scope.repositoryIds,
				projectIds: scope.projectIds
			});
		}

		if (!isMounted) {
			return;
		}
		// tslint:disable-next-line: no-floating-promises
		retrieveDevStatsData(chartScope);
		// tslint:disable-next-line: no-floating-promises
		retrieveTrendCardCharts(chartScope);
		return () => { isMounted = false; };
	}, [chartScope]);

	const [columnsSortState, setSortedColumns] = useState<Record<DashboardTeamReportMetric, columnSortStates>>(sortColumnsDefaultState);
	const [sortedColumn, setSortedColumn] = useState<ISortedDevStatsColumn | undefined>(undefined);

	const dates = devStatsChartsData.get(devStatsDatesArray) ?? [];
	const safeMatchChartDataToDates: (series: number[]) => Array<[number, number]> = (
		(series: number[]) => {
			return series
				.filter((_a, i) => i < dates.length)
				.map(((metricData: number, i: number) => ([dates[i], metricData])));
		}
	);

	const [doneTicketsCount, openPRsCount, mergedPRsCount, mergedPRSizeLOCSum, activeDays, prsReviewedCount] = [
		devStatsChartsData.get(DashboardTeamReportMetric.CompletedTicketsCount),
		devStatsChartsData.get(DashboardTeamReportMetric.OpenPRsCount),
		devStatsChartsData.get(DashboardTeamReportMetric.MergedPRsCount),
		devStatsChartsData.get(DashboardTeamReportMetric.MergedPRSizeLOCSum),
		devStatsChartsData.get(DashboardTeamReportMetric.ActiveDaysCount),
		devStatsChartsData.get(DashboardTeamReportMetric.PRsReviewedCount),
	];

	const trendsSeries: Partial<Record<HoverSelectionPart, ChartSeriesData>> = {
		[DashboardTeamReportMetric.CompletedTicketsCount]: doneTicketsCount ?
			safeMatchChartDataToDates(doneTicketsCount) : [],
		[DashboardTeamReportMetric.OpenPRsCount]: openPRsCount ?
			safeMatchChartDataToDates(openPRsCount) : [],
		[DashboardTeamReportMetric.MergedPRsCount]: mergedPRsCount ?
			safeMatchChartDataToDates(mergedPRsCount) : [],
		[DashboardTeamReportMetric.MergedPRSizeLOCSum]: mergedPRSizeLOCSum ?
			safeMatchChartDataToDates(mergedPRSizeLOCSum) : [],
		[DashboardTeamReportMetric.ActiveDaysCount]: activeDays ?
			safeMatchChartDataToDates(activeDays) : [],
		[DashboardTeamReportMetric.PRsReviewedCount]: prsReviewedCount ?
			safeMatchChartDataToDates(prsReviewedCount) : [],
	};

	const hasFetchedDevStatsData = (devStatsData?.expand?.devStats);

	const calcMetricAverageToDc = (metric: IDashboardTeamReportMetric | undefined) => {
		if (!metric) {
			return;
		}

		const dcCount = devStatsData?.expand?.devStats!.length;
		if (!dcCount) {
			return;
		}

		const averageMetric = _.cloneDeep(metric);

		averageMetric.currentPeriodValue = metric.currentPeriodValue / dcCount;
		averageMetric.previousPeriodValue = metric.previousPeriodValue / dcCount;

		return averageMetric;
	};

	const sortOnColumnClick = (columnName: DashboardTeamReportMetric) => {
		setSortedColumns({
			...sortColumnsDefaultState,
			[columnName]: !columnsSortState[columnName]
		});
		setSortedColumn({
			metricName: columnName,
			asc: !columnsSortState[columnName]
		});
	};

	return (
		<div className={"teams-report-container set-page-z-index"}>
			<h1 className="ui header">Developer Stats
				{shouldExtendWithMoreMetrics && devStatsData?.expand?.devStats && <span>
					<Popup
						hoverable={true}
						wide={true}
						position="top center"
						content="Export table as CSV"
						trigger={
							<Label className="chart-export" as="a" basic={true} onClick={async (e) => {
								e.stopPropagation();
								const dataPoints = devStatsData?.expand?.devStats;
								if (!dataPoints) {
									return;
								}

								const output = dataPoints.map(dp => {
									const object: Record<string, string | number> = {};
									for (const [key, record] of Object.entries(dp.metrics)) {
										object[`${key} - current`] = record.currentPeriodValue;
										object[`${key} - previous`] = record.previousPeriodValue;
										object[`${key} - change`] = record.changeFromPreviousPeriod;
									}
									object.teams = dp.teamNames.join(";");

									return {
										dcId: dp.dataContributor.id,
										dcDisplayName: dp.dataContributor.primaryDisplayName,
										...object
									};
								});
								const a = document.createElement("a");
								const data = Papa.unparse(output);
								a.download = `export_dev_stats_${new Date()
									.toJSON()
									.slice(0, 10)}.csv`;
								const blob = new Blob([data], { type: "text/csv" });
								a.href = window.URL.createObjectURL(blob);
								const clickEvt = new MouseEvent("click", {
									view: window,
									bubbles: true,
									cancelable: true,
								});
								a.dispatchEvent(clickEvt);
								a.remove();
							}}><Icon name={"download"} />Export</Label>
						}
					/>
				</span>}

			</h1>
			<div className="ui centered grid">
				<div className="row cards-row sticky-cards-row">
					<div className="two wide column acu-capitalize">
						<div className="margin-auto">
							average
						</div>
					</div>
					<div className="fourteen wide column">
						<div className="flex-row dev-stat-cards">
							<div className="acu-card trend-card">
								<div className="trend-card-header">
									<div className="medium-text">
										<span>Developers</span>
									</div>
									<div className="value">
										{(hasFetchedDevStatsData) ?
											<span className="big-text bold-text">
												{devStatsData?.expand?.devStats?.length}
											</span>
											: <Loader active={true} inline={true} inverted={true} size="small" />
										}
									</div>
								</div>
							</div>
							<TrendCard
								key={DashboardTeamReportMetric.ActiveDaysCount}
								cardData={{
									metricName: DashboardTeamReportMetric.ActiveDaysCount,
									title: "Active days",
									color: DEFAULT_COLOR_HEX,
									tooltipText: TeamsReportStrings.HEADER_ACTIVE_DAYS_TOOLTIP
								}}
								series={trendsSeries[DashboardTeamReportMetric.ActiveDaysCount]}
								metricData={calcMetricAverageToDc(devStatsData?.average.metrics.ActiveDaysCount)}
								units={REPORT_METRIC_TO_UNITS[DashboardTeamReportMetric.ActiveDaysCount]}
								sortState={columnsSortState[DashboardTeamReportMetric.ActiveDaysCount]}
								onSort={() => sortOnColumnClick(DashboardTeamReportMetric.ActiveDaysCount)}
							/>
							<TrendCard
								key={DashboardTeamReportMetric.CompletedTicketsCount}
								cardData={{
									metricName: DashboardTeamReportMetric.CompletedTicketsCount,
									title: "Tickets resolved",
									color: DEFAULT_COLOR_HEX,
									tooltipText: TeamsReportStrings.HEADER_RESOLVED_TICKETS_AVG_TOOLTIP
								}}
								series={trendsSeries[DashboardTeamReportMetric.CompletedTicketsCount]}
								metricData={calcMetricAverageToDc(devStatsData?.average.metrics.CompletedTicketsCount)}
								units={REPORT_METRIC_TO_UNITS[DashboardTeamReportMetric.CompletedTicketsCount]}
								sortState={columnsSortState[DashboardTeamReportMetric.CompletedTicketsCount]}
								onSort={() => sortOnColumnClick(DashboardTeamReportMetric.CompletedTicketsCount)}
							/>
							<TrendCard
								key={DashboardTeamReportMetric.OpenPRsCount}
								cardData={{
									metricName: DashboardTeamReportMetric.OpenPRsCount,
									title: "Open PRs",
									color: DEFAULT_COLOR_HEX
								}}
								series={trendsSeries[DashboardTeamReportMetric.OpenPRsCount]}
								metricData={calcMetricAverageToDc(devStatsData?.average.metrics.OpenPRsCount)}
								units={REPORT_METRIC_TO_UNITS[DashboardTeamReportMetric.OpenPRsCount]}
								sortState={columnsSortState[DashboardTeamReportMetric.OpenPRsCount]}
								onSort={() => sortOnColumnClick(DashboardTeamReportMetric.OpenPRsCount)}
							/>
							<TrendCard
								key={DashboardTeamReportMetric.MergedPRsCount}
								cardData={{
									metricName: DashboardTeamReportMetric.MergedPRsCount,
									title: "Merged PRs",
									color: DEFAULT_COLOR_HEX,
									tooltipText: TeamsReportStrings.HEADER_MERGED_PRS_AVG_TOOLTIP
								}}
								series={trendsSeries[DashboardTeamReportMetric.MergedPRsCount]}
								metricData={calcMetricAverageToDc(devStatsData?.average.metrics.MergedPRsCount)}
								units={REPORT_METRIC_TO_UNITS[DashboardTeamReportMetric.MergedPRsCount]}
								sortState={columnsSortState[DashboardTeamReportMetric.MergedPRsCount]}
								onSort={() => sortOnColumnClick(DashboardTeamReportMetric.MergedPRsCount)}
							/>
							<TrendCard
								key={DashboardTeamReportMetric.PRsReviewedCount}
								cardData={{
									metricName: DashboardTeamReportMetric.PRsReviewedCount,
									title: "Reviewed PRs",
									color: DEFAULT_COLOR_HEX,
									tooltipText: TeamsReportStrings.HEADER_REVIEWED_DEV_STATS_PRS_TOOLTIP
								}}
								series={trendsSeries[DashboardTeamReportMetric.PRsReviewedCount]}
								metricData={calcMetricAverageToDc(devStatsData?.average.metrics.PRsReviewedCount)}
								units={REPORT_METRIC_TO_UNITS[DashboardTeamReportMetric.PRsReviewedCount]}
								sortState={columnsSortState[DashboardTeamReportMetric.PRsReviewedCount]}
								onSort={() => sortOnColumnClick(DashboardTeamReportMetric.PRsReviewedCount)}
							/>
							<TrendCard
								key={DashboardTeamReportMetric.MergedPRSizeLOCSum}
								cardData={{
									metricName: DashboardTeamReportMetric.MergedPRSizeLOCSum,
									title: "Lines of code",
									color: DEFAULT_COLOR_HEX,
								}}
								series={trendsSeries[DashboardTeamReportMetric.MergedPRSizeLOCSum]}
								metricData={calcMetricAverageToDc(devStatsData?.average.metrics.MergedPRSizeLOCSum)}
								units={REPORT_METRIC_TO_UNITS[DashboardTeamReportMetric.MergedPRSizeLOCSum]}
								sortState={columnsSortState[DashboardTeamReportMetric.MergedPRSizeLOCSum]}
								onSort={() => sortOnColumnClick(DashboardTeamReportMetric.MergedPRSizeLOCSum)}
							/>
						</div>
					</div>
				</div>

				{hasFetchedDevStatsData && (
					<DevStatsTable
						devStats={devStatsData?.expand?.devStats!}
						dateRange={props.selectedDateRange}
						columnsSortState={columnsSortState}
						sortedColumn={sortedColumn}
					/>
				)}
			</div>
		</div>
	);
};
export default observer(DevStatsBoard);

export interface IHoverEffectProps {
	elementKey?: DashboardTeamReportMetric;
	withLabel?: boolean;
}

interface ISortedDevStatsColumn {
	metricName: DashboardTeamReportMetric;
	asc: columnSortStates;
}

// tslint:disable-next-line: variable-name
const DevStatsTable = (
	props: {
		devStats: IDashboardDevStatsMetrics[],
		dateRange: string,
		columnsSortState: Record<DashboardTeamReportMetric, columnSortStates>,
		sortedColumn: ISortedDevStatsColumn | undefined
	}) => {

	const { devStats, sortedColumn } = { ...props };
	const sortedDevStats = devStats.slice().sort((a, b) => {
		if (sortedColumn === undefined) {
			return 0;
		}
		if (sortedColumn.asc) {
			return a.metrics[sortedColumn.metricName].currentPeriodValue - b.metrics[sortedColumn.metricName].currentPeriodValue;
		} else {
			return b.metrics[sortedColumn.metricName].currentPeriodValue - a.metrics[sortedColumn.metricName].currentPeriodValue;
		}
	});

	return (
		<div className="dev-stats-table row">
			<div className="two wide column">
				<div className="sixteen wide column only-side-padding">
					{sortedDevStats.map((devStatRow, i) => (
						<div className="underlined-row avatar-container hide-overflow" key={`avatar-${devStatRow.dataContributor.id}`}>
							<Avatar dataContributor={devStatRow.dataContributor} />
							{devStatRow.dataContributor.primaryDisplayName && <Popup
								hoverable={true}
								wide={true}
								className={"tiny-text"}
								position="top center"
								content={devStatRow.dataContributor.primaryDisplayName}
								trigger={
									<span className="small-text">{devStatRow.dataContributor.primaryDisplayName}</span>
								}
							/>}
						</div>
					))}
				</div>
			</div>
			<div className="fourteen wide column">
				<div className="acu-card">
					{sortedDevStats.map((devStatRow, i) => (
						<div key={`row-${devStatRow.dataContributor.id}`} className="ui grid underlined-row">
							<div className="dev-stats-table-column">
								<div className="flex-row full-height align-center">
									{devStatRow.teamNames.length > 0 && <span className="small-text">{devStatRow.teamNames[0]}</span>}
									{devStatRow.teamNames.length > 1 &&
										<Popup
											hoverable={true}
											wide={true}
											className={"tiny-text"}
											position="top center"
											content={_.tail(devStatRow.teamNames).join(", ")}
											trigger={
												<span className="ui tiny basic label small-text">+{devStatRow.teamNames.length - 1}</span>
											}
										/>
									}
								</div>
							</div>
							<div className="dev-stats-table-column">
								<DevStatsIndicator metric={devStatRow.metrics.ActiveDaysCount} units={REPORT_METRIC_TO_UNITS[DashboardTeamReportMetric.ActiveDaysCount]} dateRange={props.dateRange} />
							</div>
							<div className="dev-stats-table-column">
								<DevStatsIndicator metric={devStatRow.metrics.CompletedTicketsCount} units={REPORT_METRIC_TO_UNITS[DashboardTeamReportMetric.CompletedTicketsCount]} dateRange={props.dateRange} />
							</div>
							<div className="dev-stats-table-column">
								<DevStatsIndicator metric={devStatRow.metrics.OpenPRsCount} units={REPORT_METRIC_TO_UNITS[DashboardTeamReportMetric.OpenPRsCount]} dateRange={props.dateRange} />
							</div>
							<div className="dev-stats-table-column">
								<DevStatsIndicator metric={devStatRow.metrics.MergedPRsCount} units={REPORT_METRIC_TO_UNITS[DashboardTeamReportMetric.MergedPRsCount]} dateRange={props.dateRange} />
							</div>
							<div className="dev-stats-table-column">
								<DevStatsIndicator metric={devStatRow.metrics.PRsReviewedCount} units={REPORT_METRIC_TO_UNITS[DashboardTeamReportMetric.PRsReviewedCount]} dateRange={props.dateRange} />
							</div>
							<div className="dev-stats-table-column">
								<DevStatsIndicator metric={devStatRow.metrics.MergedPRSizeLOCSum} units={REPORT_METRIC_TO_UNITS[DashboardTeamReportMetric.MergedPRSizeLOCSum]} dateRange={props.dateRange} />
							</div>
						</div>
					))}
				</div>
			</div>
		</div >
	);
};
