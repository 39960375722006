import * as React from "react";
const NotesArrowDown = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width="12"
		height="8"
		viewBox="0 0 12 8"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path d="M0.299557 0.763995C0.461193 0.621062 0.706879 0.637615 0.84831 0.800967L5.70741 6.41317C5.86235 6.59212 6.13781 6.59212 6.29275 6.41317L11.1519 0.800967C11.2933 0.637615 11.539 0.621062 11.7006 0.763995C11.8622 0.906928 11.8786 1.15522 11.7372 1.31857L6.87809 6.93077C6.41327 7.46763 5.58689 7.46763 5.12208 6.93078L0.262973 1.31857C0.121542 1.15522 0.137921 0.906928 0.299557 0.763995Z" fill="#0F2345" />
	</svg>
);
export default NotesArrowDown;
