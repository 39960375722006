import Papa from "papaparse";
import { download } from "./download";

export const exportDataAsCsv = <T>(data: T[], prefix: string) => {
	const encodedData = Papa.unparse(data);
	const blob = new Blob([encodedData], { type: "text/csv" });
	const url = window.URL.createObjectURL(blob);
	const suffix = new Date().toJSON().slice(0, 10);
	const localFileName = `${prefix}_${suffix}.csv`;

	download(localFileName, url);
};
