import { IdBasedCrudApiClient } from "./id-based-crud-api-client";
import { ACTIVE_INTEGRATIONS_COUNT_SUBROUTE, CommonEndPoints, IDashboardIntegration, IDashboardResponse,
	IntegrationStatus, IntegrationType } from "@acumen/dashboard-common";
import { APIContextProvider } from "../../../services/api-context-provider";
import { getData, postData } from "../../../services/fetch-helpers";
import urlJoin from "url-join";
import { IActionData } from "../../mobx-stores/new-integrations-store";

export const INTEGRATIONS_ROUTE = "integrations";

export enum JiraIntegrationType {
	Cloud = "Cloud",
	Server = "Server"
}

interface IUpdateGitHubInstallIdRequestPayload { githubInstallationId: number; }
interface IUpdateSlackInstallDataRequestPayload { code: string; state: string; redirectUri?: string; }
interface IInitializeJiraInstanceUrlPayload { hostUrl: string; }
interface IVerifyJiraInstancePayload { verificationCode: string; }

const MAP_JIRA_INTEGRATION_TYPE_TO_PATH: Record<JiraIntegrationType, string> = {
	[JiraIntegrationType.Cloud]: "jira-cloud",
	[JiraIntegrationType.Server]: "jira-server",
};

export interface IIntegrationWizard {
	integrationId?: string;
	installLink?: string;
}

export interface IIntegrationWizardGitHub extends IIntegrationWizard {
	gitInstallId?: number;
}

export interface IIntegrationWizardSlack extends IIntegrationWizard {
	slackInstallCode?: string;
	slackInstallState?: string;
}

export interface IIntegrationWizardJira extends IIntegrationWizard {
	jiraHostUrl?: string;
	publicKey?: string;
	guide?: {
		applicationName: string,
		applicationType: string,
		serviceProviderName: string,
		consumerKey: string,
		consumerName: string,
		sharedSecret: string,
		requestTokenUrl: string,
		accessTokenUrl: string,
		authorizeTokenUrl: string,
		appLinkInternalUrl: string
	};
	jiraAuthorizationUrl?: string;
	jiraVerificationCode?: string;
	jiraVerificationSuccess?: boolean;
}

export type IntegrationWizardState = IIntegrationWizardGitHub & IIntegrationWizardSlack & IIntegrationWizardJira;

export class IntegrationsApiClient extends IdBasedCrudApiClient<IDashboardIntegration> {
	constructor(apiContextProvider: APIContextProvider) {
		super(INTEGRATIONS_ROUTE, apiContextProvider);
	}

	private async postData(routePath: string, token: string, payload?: object):
		Promise<IDashboardResponse<IntegrationWizardState> | null> {
		return await postData(routePath, token, payload);
	}

	public createGitHubInstallLink = async () => {
		return await this.postData(
			this.createCustomerEntityRoute(urlJoin(INTEGRATIONS_ROUTE, `github`, `integrate`, `initialization`)),
			this.token
		);
	}

	public updateGitHubInstallId = async (data: IntegrationWizardState) => {
		if (!data.gitInstallId) {
			return;
		}

		const body: IUpdateGitHubInstallIdRequestPayload = {
			githubInstallationId: data.gitInstallId
		};

		return await this.postData(
			this.createCustomerEntityRoute(urlJoin(INTEGRATIONS_ROUTE, `github`, `integrate`,
				`${data.integrationId}`, `authorization`)),
			this.token,
			body
		);
	}

	public createSlackInstallLink = async (redirectUri?: string) => {
		const body = { redirectUri };
		return await this.postData(
			this.createCustomerEntityRoute(urlJoin(INTEGRATIONS_ROUTE, `slack`, `integrate`, `initialization`)),
			this.token,
			body
		);
	}

	public updateSlackInstallData = async (data: IntegrationWizardState, redirectUri?: string) => {
		if (!data.slackInstallCode) {
			return;
		}

		if (!data.slackInstallState) {
			return;
		}

		const body: IUpdateSlackInstallDataRequestPayload = {
			code: data.slackInstallCode,
			state: data.slackInstallState,
			redirectUri
		};

		return await this.postData(
			this.createCustomerEntityRoute(urlJoin(INTEGRATIONS_ROUTE, `slack`, `integrate`,
				`${data.integrationId}`, `authorization`)),
			this.token,
			body
		);
	}

	public initializeJiraInstanceUrl = async (data: IntegrationWizardState, type: JiraIntegrationType) => {
		if (!data.jiraHostUrl) {
			return;
		}

		const body: IInitializeJiraInstanceUrlPayload = { hostUrl: data.jiraHostUrl };

		const res = await this.postData(
			this.createCustomerEntityRoute(urlJoin(INTEGRATIONS_ROUTE,
					MAP_JIRA_INTEGRATION_TYPE_TO_PATH[type], `integrate`, `oauth`, `initialization`)),
			this.token,
			body
		);
		if (!res) {
			return;
		}
		const { externalIntegrationId, token } = res?.data as any;
		const appLink = this.createCustomerEntityRoute(`/jira-cloud/integrate/${externalIntegrationId}/${token}`);
		return { data: { appLink, ...res?.data } };
	}

	public fetchActiveIntegrationsCount = async () => {
		return await getData<{ activeIntegrationCount: number }, any>(
			this.createCustomerEntityRoute(urlJoin(INTEGRATIONS_ROUTE, ACTIVE_INTEGRATIONS_COUNT_SUBROUTE)),
			this.token
		);
	}

	public fetchJiraAuthorizationUrl = async (data: Partial<IntegrationWizardState | IActionData>, type: JiraIntegrationType) => {
		if (!data.integrationId) {
			return null;
		}

		return await this.postData(
			this.createCustomerEntityRoute(urlJoin(INTEGRATIONS_ROUTE, MAP_JIRA_INTEGRATION_TYPE_TO_PATH[type],
				`integrate`, `oauth`, `${data.integrationId}`, `verification`)),
			this.token
		);
	}

	public verifyJiraInstance = async (data: IntegrationWizardState, type: JiraIntegrationType) => {
		if (!data.jiraVerificationCode) {
			return;
		}

		const body: IVerifyJiraInstancePayload = { verificationCode: data.jiraVerificationCode };

		return await this.postData(
			this.createCustomerEntityRoute(urlJoin(INTEGRATIONS_ROUTE, MAP_JIRA_INTEGRATION_TYPE_TO_PATH[type],
				`integrate`, `oauth`, `${data.integrationId}`, `authorization`)),
			this.token,
			body
		);
	}

	public getSlackGuide = async (integrationId: string) => {
		return this.postData(
			this.createCustomerEntityRoute(urlJoin(INTEGRATIONS_ROUTE, `slack`, `integrate`,
				`${integrationId}`, `guide`)),
			this.token
		);
	}

	public getJiraGuide = async (integrationId: string) => {
		const res = await this.postData(
			this.createCustomerEntityRoute(urlJoin(INTEGRATIONS_ROUTE, `jira-cloud`, `integrate`, `oauth`,
				`${integrationId}`, `guide`)),
			this.token
		);
		if (!res || !res.data) {
			return;
		}

		const { externalIntegrationId, token } = res?.data as any;
		const appLink = this.createCustomerEntityRoute(`/jira-cloud/integrate/${externalIntegrationId}/${token}`);
		return { data: { appLink, ...res?.data } };
	}

	public getGithubGuide = async (integrationId: string) => {
		return this.postData(
			this.createCustomerEntityRoute(urlJoin(INTEGRATIONS_ROUTE, `github`, `integrate`,
				`${integrationId}`, `guide`)),
			this.token
		);
	}

	public getIntegrationsByQuery = async (query: { type: IntegrationType, status: IntegrationStatus }) => {
		const res = await postData<any, IDashboardIntegration[], unknown>(
			this.createCustomerEntityRoute(urlJoin(INTEGRATIONS_ROUTE, CommonEndPoints.FIND_CONDITION)),
			this.token,
			query,
			this.tokenType
		);

		return res?.data || null;
	}

	public getJiraFields = async (integrationId: string) => {
		const res = await getData<any, unknown>(
			this.createCustomerEntityRoute(urlJoin("integration", integrationId, "jira-custom-fields")),
			this.token,
			this.tokenType,
			true
		);

		return res?.data || null;
	}

	public updateEstimateInJira = async (integrationId: string, key: string,
		field: string, type: "string" | "number", value: string) => {
		const res = await postData<any, any, unknown>(
			this.createCustomerEntityRoute(urlJoin("integration", integrationId, "update-estimate")),
			this.token,
			{
				key,
				field,
				type,
				value
			},
			this.tokenType,
			true
		);

		return res?.data || null;
	}

	public addCommentInJira = async (integrationId: string, key: string, comment: string) => {
		const res = await postData<any, any, unknown>(
			this.createCustomerEntityRoute(urlJoin("integration", integrationId, "add-comment")),
			this.token,
			{
				key,
				comment,
			},
			this.tokenType,
			true
		);

		return res?.data || null;
	}
}
