import { IDashboardResponse, IDashboardCurrentUserProfile } from "@acumen/dashboard-common";
import urlJoin from "url-join";
import config from "utils/config";
import { getData } from "./fetch-helpers";

const { apiBaseUrl } = config({ apiBaseUrl: "" });
export const SELF_ROUTE = urlJoin(apiBaseUrl, "v1", "self");

export async function getSelfUser(accessToken: string): Promise<IDashboardCurrentUserProfile | null> {
	const response: IDashboardResponse<IDashboardCurrentUserProfile> | null = await getData(SELF_ROUTE, accessToken);

	if (response) {
		const user = response.data as IDashboardCurrentUserProfile;

		return user;
	}
	return null;
}
