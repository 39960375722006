import { AcumenMetricGroupType, IDashboardAcumenMetricDataResponse, IDashboardAcumenMetricMetadataResponse, MetricInterval } from "@acumen/dashboard-common";
import { action, observable } from "mobx";
import { FetchLatestRequest } from "services/fetch-helpers";
import apiContextProvider from "../../services/api-context-provider";
import { MetricApiClient } from "../services/crud/metric-api-client";
import BaseStore, { ILoadable } from "./base-store";

const defaults = {
	metadata: BaseStore.initLoadable({}),
};

export default class MetricTesterStore extends BaseStore<{}> {
	private readonly apiClient: MetricApiClient = new MetricApiClient(apiContextProvider);

	@observable
	public metadata: ILoadable<IDashboardAcumenMetricMetadataResponse> = defaults.metadata;

	@action
	public async fetchMetadata() {
		this.metadata.loading = true;

		const result = await this.apiClient.fetchMetadata();

		if (result) {
			const { data } = result;

			this.metadata = {
				data,
				metadata: null,
				loaded: true,
				loading: false
			};
		}
	}

	private fetchLatestMetric = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>("metric");
	@action.bound
	public async fetchMetric(metric: AcumenMetricGroupType, label: string,
		interval: MetricInterval, timezone: string, startTime: Date, endTime: Date,
		dimensions?: string[], groupBy?: string) {
		const result =
			await this.fetchLatestMetric.fetchLatest(this.apiClient.fetchMetric(metric, label, interval, timezone, startTime, endTime, dimensions, groupBy));
		return (result?.data ? result?.data : null);
	}
}
