import PokerProvider from "./adapters/poker";

import "./styles/global.css";

import React, { Suspense, useEffect, useLayoutEffect, useState } from "react";
import { observer } from "mobx-react";
import { Switch, Route, Redirect, RouteComponentProps, useLocation } from "react-router-dom";
import BoardGamePage from "./pages/game";
import CreateGamePage from "./pages/create-game";
import JoinGamePage from "./pages/join-game";
import { useStores } from "../../mobx-stores";
import urlJoin from "url-join";
import { PokerPage } from "./service/internal-router";
import apiContextProvider, { TokenType } from "../../../services/api-context-provider";
import MixpanelProvider from "./adapters/mixpanel";
import IntercomProvider from "./adapters/intercom";

export const BASE_POKER_ROUTE = "/planning-poker/:customerId/:token";
export const ACUMEN_SUPPORT_IN_GO_RETRO_EMAIL = "support@goretro.ai";

interface TokenAuthProps {
	customerId: string;
	token: string;
}

export type ConnectionRequestType = "reconnect" | "request-write" | "connect";

export function sendMessageToGoRetroFromPokerPlanning(type: "url-change" | "refresh-handshake" | "track-intercom" | "track-mixpanel" | "jira-connection-request", data: any = {}) {
	window.parent.postMessage({type, data}, "*");
}

// tslint:disable-next-line: variable-name
const PokerApp = observer((props: RouteComponentProps<TokenAuthProps>) => {
	apiContextProvider.setContext({ customerId: props.match.params.customerId, token: props.match.params.token, tokenType: TokenType.AcumenToken });

	const { fetcherStore } = useStores();
	const location = useLocation();
	const [hasLoadedAssets, setHasLoadedAssets] = useState<boolean>(false);

	async function onTokenError() {
		sendMessageToGoRetroFromPokerPlanning("refresh-handshake");
	}

	useLayoutEffect(() => {
		const styleSrc = "/assets/style/tailwind.out.css";
		const existingStyleTag = document.querySelector(
			`link[href="${styleSrc}"]`
		);
		if (existingStyleTag) {
			return;
		}
		const styleTag = document.createElement("link");
		styleTag.type = "text/css";
		styleTag.rel = "stylesheet";
		styleTag.onload = () => { setHasLoadedAssets(true); };
		styleTag.href = styleSrc;
		document.head.appendChild(styleTag);
	}, []);

	useEffect(() => {
		sendMessageToGoRetroFromPokerPlanning("url-change", location);
	}, [location]);

	useEffect(() => {
		if (fetcherStore.hasTokenError) {
			// tslint:disable-next-line: no-floating-promises
			onTokenError();
		}
	}, [fetcherStore.hasTokenError]);

	return (
		<MixpanelProvider>
			<IntercomProvider>
				<PokerProvider contextProvider={apiContextProvider}>
					<Switch>
						<Route path={urlJoin(BASE_POKER_ROUTE, PokerPage.Game)} component={BoardGamePage} />
						<Route path={urlJoin(BASE_POKER_ROUTE, PokerPage.CreateGame)}
							render={() => (<Suspense fallback={<>Loading...</>}>{hasLoadedAssets && <CreateGamePage />}</Suspense>)} />
						<Route path={urlJoin(BASE_POKER_ROUTE, PokerPage.JoinGame)}
							render={() => (<Suspense fallback={<>Loading...</>}>{hasLoadedAssets && <JoinGamePage />}</Suspense>)} />
						{/* All missed paths will need login */}
						<Redirect to="/" />
					</Switch>
				</PokerProvider>
			</IntercomProvider>
		</MixpanelProvider>
	);
});

export default PokerApp;
