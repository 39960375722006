import React from "react";
import PropTypes from "prop-types";
import "./loader.scss";

function LoadingIndicator({ local = false, isActive = true, children }) {
	return isActive ? (
		local ? (
			<div className="ui basic segment loading">
				<div className="ui fluid placeholder">
					<div className="paragraph">
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
					</div>
				</div>
			</div>
		) : (
			<div className="loader-bg">
				<div className="loader-track">
					<div className="loader-fill" />
				</div>
			</div>
		)
	) : (
		<>{children ?? []}</>
	);
}

LoadingIndicator.propTypes = {
	isActive: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

export default LoadingIndicator;
