import {
	IDashboardDetailedTaskResponse,
	DashboardSortOrder,
	AcumenTaskStatus,
	AcumenTaskType,
	DashboardTaskStaticGroupingOption,
	DashboardTaskDynamicGroupingOption,
	DashboardTaskSortOption,
	DashboardTaskExpandOption,
	DashboardTaskTimeContext
} from "@acumen/dashboard-common";
import { getData } from "../../../services/fetch-helpers";
import { BaseCustomerApiClient } from "./base-customer-api-client";
import { qsStringify } from "../../../services/url-routes-helper";

export const TASKS_ROUTE = "tasks";
export const RELATED_PRS_SUB_ROUTE = "related-prs";

export class TasksApiClient extends BaseCustomerApiClient {
	public fetchTasks = async (teamId?: string, dataContributorIds?: string[],
		labels?: string[], sprints?: string[], projects?: string[], startTime?: Date, endTime?: Date,
		timeContext?: DashboardTaskTimeContext, statuses?: AcumenTaskStatus[], types?: AcumenTaskType[],
		staticGroupingLimit?: number, staticGrouping?: DashboardTaskStaticGroupingOption,
		dynamicGroupingLimit?: number, dynamicGrouping?: DashboardTaskDynamicGroupingOption,
		sortField?: DashboardTaskSortOption, sortOrder?: DashboardSortOrder, expand?: DashboardTaskExpandOption[],
		filterTasksByCycleEnd?: boolean) => {
		const query: { [label: string]: string | string[] | number | Date } = {};

		if (teamId) {
			query.teamId = teamId;
		}

		if (dataContributorIds) {
			query["dataContributorIds[]"] = dataContributorIds;
		}
		if (labels) {
			query["labels[]"] = labels;
		}
		if (sprints) {
			query["sprints[]"] = sprints;
		}
		if (projects) {
			query["projects[]"] = projects;
		}
		if (statuses) {
			query["statuses[]"] = statuses;
		}
		if (types) {
			query["types[]"] = types;
		}

		if (startTime) {
			query.startTime = startTime;
		}

		if (endTime) {
			query.endTime = endTime;
		}

		if (timeContext) {
			query.timeContext = timeContext;
		}

		if (staticGrouping) {
			query.staticGrouping = staticGrouping;
		}
		if (dynamicGrouping) {
			query.dynamicGrouping = dynamicGrouping;
		}
		if (sortField) {
			query.sortField = sortField;
		}
		if (sortOrder) {
			query.sortOrder = sortOrder;
		}
		if (expand) {
			query["expand[]"] = expand;
		}

		if (staticGroupingLimit !== undefined) {
			query.staticGroupingLimit = staticGroupingLimit;
		}
		if (dynamicGroupingLimit !== undefined) {
			query.dynamicGroupingLimit = dynamicGroupingLimit;
		}

		if (filterTasksByCycleEnd !== undefined) {
			query.filterTasksByCycleEnd = filterTasksByCycleEnd.toString();
		}

		return getData<IDashboardDetailedTaskResponse, any>(
			this.createCustomerEntityRoute(`${TASKS_ROUTE}?${qsStringify(query)}`),
			this.token,
			this.tokenType
		);
	}
}
