import * as React from "react";
import { Modal } from "semantic-ui-react";
import { useCallback, useState } from "react";
import contributorFilterModalLogo from "../../../../components/svg-assets/contributor-filter-modal-logo.svg";
import closeIcon from "../../../../components/svg-assets/close-icon.svg";
import { useInputState } from "../../../../hooks/useInputState";
import { useStores } from "../../../../mobx-stores";
import { ConfigurationEntityType, CustomizableConfigurationCategories } from "@acumen/database-types";
import { IConfigurationsUpdateParams } from "../../../../services/crud/customization-api-client";
import { ContributorFilterModel } from "../../../../types/contributor-filter.model";

interface CreateNewContributorsFilterButtonProps {
	contributorFilters: ContributorFilterModel[];
	contributorIds: Set<string> | null;
	onFilterCreated?: () => void;
}

const CreateNewContributorsFilterButton = (props: CreateNewContributorsFilterButtonProps) => {
	const { contributorFilters, contributorIds, onFilterCreated } = props;
	const { customizationStore } = useStores();
	const { updateConfigurationByCategory } = customizationStore;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [name, handleNameChange, clearName] = useInputState("");
	const handleOpen = useCallback(() => setIsModalOpen(true), []);
	const handleClose = useCallback(() => {
		setIsModalOpen(false);
		clearName();
	}, []);
	const handleSubmit = useCallback((event: React.FormEvent | React.KeyboardEvent) => {
		event.preventDefault();
		const updateParams: IConfigurationsUpdateParams = {
			category: CustomizableConfigurationCategories.GoRetro,
			configs: {
				ContributorFilters: {
					single: [
						...contributorFilters,
						{ name, contributorIds: Array.from(contributorIds!) },
					],
				},
			},
		};

		updateConfigurationByCategory(updateParams, ConfigurationEntityType.CustomerDefault).then(() => {
			handleClose();
			onFilterCreated?.();
		}).catch();
	}, [contributorFilters, contributorIds, name, handleClose, onFilterCreated]);
	const handleFormKeyDown = useCallback((event: React.KeyboardEvent) => {
		if (event.key === "Enter") {
			handleSubmit(event);
		}
	}, [handleSubmit]);

	return (
		<React.Fragment>
			<button className="create-new-filter-button" disabled={!contributorIds?.size} onClick={handleOpen}>
				Create New Filter
			</button>

			<Modal
				open={isModalOpen}
				className="create-contributors-filter-modal"
				onClose={handleClose}
			>
				<form onKeyDown={handleFormKeyDown} onSubmit={handleSubmit}>
					<div className="create-contributors-filter-modal-header">
						<div className="create-contributors-filter-modal-header-side">
							<img src={contributorFilterModalLogo} className="contributor-filter-modal-logo" alt=""/>
							<div className="create-contributors-filter-modal-title">Create New Filter</div>
							<div className="filter-items-badge">{contributorIds?.size}</div>
						</div>

						<div className="create-contributors-filter-modal-header-side">
							<button className="create-contributors-filter-modal-close-button" onClick={handleClose}>
								<img src={closeIcon} alt=""/>
							</button>
						</div>
					</div>

					<div className="create-contributors-filter-modal-body">
						<label className="filter-name-label">
							<div>Filter Name</div>
							<input
								className="filter-name-input"
								placeholder="Type your filter name here..."
								value={name}
								autoFocus={true}
								onChange={handleNameChange}
							/>
						</label>
					</div>

					<div className="create-contributors-filter-modal-footer">
						<button type="button" className="create-contributors-filter-modal-cancel-button" onClick={handleClose}>
							Cancel
						</button>

						<button type="submit" className="create-contributors-filter-modal-save-button" disabled={!name}>
							Save
						</button>
					</div>
				</form>
			</Modal>
		</React.Fragment>
	);
};

export default CreateNewContributorsFilterButton;
