import {
	IDashboardDetailedTaskResponse,
	DashboardSortOrder,
	AcumenTaskStatus,
	AcumenTaskType,
	DashboardTaskStaticGroupingOption,
	DashboardTaskDynamicGroupingOption,
	DashboardTaskSortOption,
	DashboardTaskExpandOption,
	IDashboardEntityWorkIntervals,
	IDashboardTask,
	IDashboardPullRequest,
	IDashboardEvent,
	ITaskHighlight,
	IDashboardTaskDetails,
	DashboardTaskTimeContext,
	IDashboardTaskStatusChangeResponse,
	IDashboardTaskStatusChangeBodyParameters,
	IDashboardCycleTimeBreakdown
} from "@acumen/dashboard-common";
import { getData, postData } from "../../../services/fetch-helpers";
import { BaseCustomerApiClient } from "./base-customer-api-client";
import urlJoin from "url-join";
import { qsStringify } from "../../../services/url-routes-helper";

export const TASKS_ROUTE = "tasks";
export const WORK_INTERVALS_SUB_ROUTE = "work-intervals";
export const HIGHLIGHTS_SUB_ROUTE = "highlights";
export const RELATED_PRS_SUB_ROUTE = "related-prs";
export const HIGHLIGHTS_DISMISS_SUB_ROUTE = "dismiss";
export const TASK_EVENTS_SUB_ROUTE = "events";
export const TASK_DETAILS_SUB_ROUTE = "details";
export const STATUS_DIFF_SUB_ROUTE = "status-diff";
export const CYCLE_TIME_BREAKDOWN_SUB_ROUTE = "cycle-time-breakdown";

export class TasksApiClient extends BaseCustomerApiClient {
	public fetchTasks = async (teamId: string, dataContributorIds?: string[],
		labels?: string[], sprints?: string[], projects?: string[], startTime?: Date, endTime?: Date,
		timeContext?: DashboardTaskTimeContext, statuses?: AcumenTaskStatus[], types?: AcumenTaskType[],
		staticGroupingLimit?: number, staticGrouping?: DashboardTaskStaticGroupingOption,
		dynamicGroupingLimit?: number, dynamicGrouping?: DashboardTaskDynamicGroupingOption,
		sortField?: DashboardTaskSortOption, sortOrder?: DashboardSortOrder, expand?: DashboardTaskExpandOption[]) => {
		const query: { [label: string]: string | string[] | number | Date } = {};
		query.teamId = teamId;

		if (dataContributorIds) {
			query["dataContributorIds[]"] = dataContributorIds;
		}
		if (labels) {
			query["labels[]"] = labels;
		}
		if (sprints) {
			query["sprints[]"] = sprints;
		}
		if (projects) {
			query["projects[]"] = projects;
		}
		if (statuses) {
			query["statuses[]"] = statuses;
		}
		if (types) {
			query["types[]"] = types;
		}

		if (startTime) {
			query.startTime = startTime;
		}

		if (endTime) {
			query.endTime = endTime;
		}

		if (timeContext) {
			query.timeContext = timeContext;
		}

		if (staticGrouping) {
			query.staticGrouping = staticGrouping;
		}
		if (dynamicGrouping) {
			query.dynamicGrouping = dynamicGrouping;
		}
		if (sortField) {
			query.sortField = sortField;
		}
		if (sortOrder) {
			query.sortOrder = sortOrder;
		}
		if (expand) {
			query["expand[]"] = expand;
		}

		if (staticGroupingLimit !== undefined) {
			query.staticGroupingLimit = staticGroupingLimit;
		}
		if (dynamicGroupingLimit !== undefined) {
			query.dynamicGroupingLimit = dynamicGroupingLimit;
		}

		return getData<IDashboardDetailedTaskResponse, any>(
			this.createCustomerEntityRoute(`${TASKS_ROUTE}?${qsStringify(query)}`),
			this.token,
			this.tokenType
		);
	}

	public fetchWorkIntervals = async (task: IDashboardTask) => {
		return await getData<IDashboardEntityWorkIntervals, any>(
			this.createCustomerEntityRoute(urlJoin(TASKS_ROUTE, `${task.entityType}`, task.entityId, WORK_INTERVALS_SUB_ROUTE)),
			this.token,
			this.tokenType
		);
	}
	public dismissTaskHighlights = async (task: IDashboardTask) => {
		return await getData<void, any>(
			this.createCustomerEntityRoute(urlJoin(TASKS_ROUTE, `${task.entityType}`, task.entityId, HIGHLIGHTS_SUB_ROUTE, HIGHLIGHTS_DISMISS_SUB_ROUTE)),
			this.token,
			this.tokenType
		);
	}
	public fetchRelatedPrs = async (task: IDashboardTask) => {
		return await getData<Record<string, IDashboardPullRequest[] | undefined>, any>(
			this.createCustomerEntityRoute(urlJoin(TASKS_ROUTE, `${task.entityType}`, task.entityId, RELATED_PRS_SUB_ROUTE)),
			this.token,
			this.tokenType
		);
	}
	public fetchTaskHighlights = async (task: IDashboardTask) => {
		return await getData<{ highlights: ITaskHighlight[] }, any>(
			this.createCustomerEntityRoute(urlJoin(TASKS_ROUTE, `${task.entityType}`, task.entityId, HIGHLIGHTS_SUB_ROUTE)),
			this.token,
			this.tokenType
		);
	}
	public fetchTaskEvents = async (task: IDashboardTask) => {
		return await getData<IDashboardEvent[], any>(
			this.createCustomerEntityRoute(urlJoin(TASKS_ROUTE, `${task.entityType}`, task.entityId, TASK_EVENTS_SUB_ROUTE)),
			this.token,
			this.tokenType
		);
	}
	public fetchTaskDetails = async (task: IDashboardTask) => {
		return await getData<IDashboardTaskDetails, any>(
			this.createCustomerEntityRoute(urlJoin(TASKS_ROUTE, `${task.entityType}`, task.entityId, TASK_DETAILS_SUB_ROUTE)),
			this.token,
			this.tokenType
		);
	}

	public fetchStatusDiff = async (taskIds?: string[], startDate?: Date, endDate?: Date, expand: boolean = false) => {
		return await postData<IDashboardTaskStatusChangeBodyParameters, IDashboardTaskStatusChangeResponse, any>(
			this.createCustomerEntityRoute(urlJoin(TASKS_ROUTE, STATUS_DIFF_SUB_ROUTE)),
			this.token,
			{ taskIds, startDate, endDate, expand },
			this.tokenType
		);
	}
	public fetchCycleTimeBreakdown = async (taskIds: string[]) => {
		return await getData<IDashboardCycleTimeBreakdown[], any>(
			this.createCustomerEntityRoute(urlJoin(TASKS_ROUTE, CYCLE_TIME_BREAKDOWN_SUB_ROUTE) + "?" + taskIds.map(taskId => "taskIds[]=" + taskId).join("&")),
			this.token,
			this.tokenType
		);
	}
}
