import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../../mobx-stores";
import LoadingIndicator from "../../../../components/loader";
import { MetricInterval, IDashboardSprint, AcumenTaskType } from "@acumen/dashboard-common";
import { IThroughputData } from "../../../../mobx-stores/metric-store";
import colorScheme from "./chart-color-scheme";
import {
	ChartSeriesData, isSameIntervalCategory, isValidChartData, packSprintByIdIfNeeded,
	prepareCategorySeriesDataByDate, prepareCategorySeriesDataBySprint
} from "./charts";
import { v4 as generateUUID } from "uuid";

interface IThroughputStoryPointsProps {
	isFullScreen?: boolean;
	setDisableFullScreen?: (disable: boolean) => void;
	filters: {
		teamId?: string;
		dataContributorIds?: string[];
		startTime?: Date;
		endTime?: Date;
		interval?: MetricInterval;
		projectIds?: string[];
		boardIds?: string[];
		gitRepositoryIds?: string[];
		baseIsDefaultBranch?: boolean;
		customerSprints?: IDashboardSprint[];
		excludeDraft?: boolean;
		issueTypes?: AcumenTaskType[];
		timezone: string;
	};
}

export const THROUGHPUT_STORY_POINTS_DATA = {
	title: "Throughput (story points)",
	description: `Shows the amount of pull requests merged, the amount of story points
	for issues marked done for the given period.`
};

function ThroughputStoryPoints(props: IThroughputStoryPointsProps) {
	const { metricStore } = useStores();
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const { fetchData } = metricStore.throughputData({ countBy: "story-points" });
	const { throughputDataStored } = metricStore;
	const [chartData, setChartData] = useState<IThroughputData | undefined>(throughputDataStored);

	useEffect(() => {
		if (props.isFullScreen) {
			return;
		}
		if (Object.keys(props.filters).length === 0) {
			return;
		}

		let isMounted = true;
		async function fetch() {
			setIsFetching(true);
			if (props.setDisableFullScreen) {
				props.setDisableFullScreen(true);
			}
			const data = await fetchData(props.filters.teamId,
				props.filters.dataContributorIds,
				props.filters.projectIds,
				props.filters.startTime,
				props.filters.endTime,
				props.filters.interval,
				props.filters.timezone,
				props.filters.gitRepositoryIds,
				props.filters.baseIsDefaultBranch,
				props.filters.excludeDraft,
				props.filters.boardIds,
				props.filters.issueTypes
			);
			if (isMounted && data) {
				setIsFetching(false);
				setChartData(data);
			}
			if (props.setDisableFullScreen) {
				props.setDisableFullScreen(false);
			}
		}

		// tslint:disable-next-line: no-floating-promises
		fetch();
		return () => { isMounted = false; };
	}, [props.filters]);

	let sumOfStoryPointsSeriesData: ChartSeriesData = [];
	let numberOfPRMergedSeriesData: ChartSeriesData = [];

	if (isValidChartData<IThroughputData>(chartData) &&
		isSameIntervalCategory(chartData.prCount.interval, props.filters.interval)) {
		const sprintById = packSprintByIdIfNeeded(props.filters.interval, props.filters.customerSprints);

		if (sprintById) {
			numberOfPRMergedSeriesData = prepareCategorySeriesDataBySprint(chartData.prCount, sprintById);
			sumOfStoryPointsSeriesData = prepareCategorySeriesDataBySprint(chartData.workEstimationCount, sprintById);
		} else {
			numberOfPRMergedSeriesData = prepareCategorySeriesDataByDate(chartData.prCount, props.filters.timezone);
			sumOfStoryPointsSeriesData = prepareCategorySeriesDataByDate(chartData.workEstimationCount, props.filters.timezone);
		}
	}

	const categorySeries: Highcharts.SeriesColumnOptions[] = [
		{
			id: generateUUID(),
			data: sumOfStoryPointsSeriesData,
			visible: sumOfStoryPointsSeriesData.length > 0,
			color: colorScheme.issueType.task,
			name: "Story points for issues marked done",
			type: "column"
		},
		{
			id: generateUUID(),
			data: numberOfPRMergedSeriesData,
			visible: numberOfPRMergedSeriesData.length > 0,
			color: colorScheme.pr,
			name: "Pull requests merged",
			type: "column"
		}
	];

	const options: Highcharts.Options = {
		chart: {
			type: "column",
			zoomType: "xy"
		},
		title: undefined,
		tooltip: {
			split: true,
		},
		xAxis: {
			gridLineWidth: 1,
			type: (props.filters.interval === MetricInterval.SPRINT_DATE ? "category" : "datetime"),
			uniqueNames: false
		},
		yAxis: [
			{
				min: 0,
				title: {
					text: "# Pull requests / Story Points"
				}
			}
		],
		credits: {
			enabled: false
		},
		series: categorySeries
	};

	return (
		<div className="description">
			<LoadingIndicator local={true} isActive={!isValidChartData(chartData) || isFetching}>
				<HighchartsReact
					highcharts={Highcharts}
					options={options}
				/>
			</LoadingIndicator>
		</div>
	);
}

export default observer(ThroughputStoryPoints);
