import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useState } from "react";
import { useStores } from "../../../mobx-stores";
import { CategoryChartSeriesData } from "../../../pages/my-team/analytics/charts/charts";
import LoadingIndicator from "../../../components/loader";
import { IChartScope } from "./chart-scope";
import useDeepCompareEffect from "use-deep-compare-effect";
import _ from "lodash";
import { Grid, Popup } from "semantic-ui-react";
import PieChart from "../../../components/highchart-chart-components/pie-chart";
import { ChartExternalLegend, ILegendList } from "../../../pages/org-analytics/particles";
import { seriesByTaskName } from "../../../components/dashboard-task/task-type-series-prop";

export enum CycleState {
	Start = "Start",
	End = "End",
}

interface IProps {
	scope: IChartScope;
	cycle: CycleState;
	mainTitle?: { title: string, tooltip?: string };
	showDataLabels?: boolean;
	showExternalLegend?: boolean;
	chartHeight?: {
		mainChartHeight: number,
		summaryPieChartHeight: number
	};
}

function TicketsByType(props: IProps) {
	const { teamsReportStore } = useStores();
	const [expandedChartOptions, setExpandedChartOptions] = useState<Highcharts.Options | undefined>(undefined);
	const [summarizedChartOptions, setSummarizedChartOptions] = useState<Highcharts.Options | undefined>(undefined);
	const [summarizedLegendList, setSummarizedLegendList] = useState<{ data: ILegendList[], title?: string } | undefined>(undefined);
	const [hoveredSummaryMetric, setHoveredSummaryMetric] = useState<undefined | string>(undefined);

	const { scope, mainTitle, showDataLabels, showExternalLegend, chartHeight } = { ...props };

	function buildExpandedChartOptions(data: CategoryChartSeriesData): Highcharts.Options {
		let series: Highcharts.SeriesColumnOptions[] | undefined;
		series = Object
			.keys(data)
			.map(key => {
				const displayProperties = seriesByTaskName(key);
				const seriesOptions: Highcharts.SeriesColumnOptions = {
					name: displayProperties.seriesName,
					index: displayProperties.sortOrder,
					legendIndex: displayProperties.sortOrder,
					color: displayProperties.seriesColorHex,
					data: _.cloneDeep(data[key]),
					visible: (data[key].length > 0 && _.findIndex(data[key], i => i[1] > 0) >= 0),
					type: "column",
					showInLegend: showExternalLegend ? false : true,
					stack: "issue-type"
				};
				return seriesOptions;
			});
		const options: Highcharts.Options = {
			title: undefined,
			chart: chartHeight ? { height: chartHeight?.mainChartHeight } : {},
			tooltip: {
				headerFormat: '<span style="color:{point.color}">\u25CF</span><b> {series.name}</b>:<br>',
				pointFormat: `{point.y}<br/>`
			},
			xAxis: {
				gridLineWidth: 1,
				type: "datetime"
			},
			yAxis: [
				{
					min: 0,
					title: {
						text: "Amount"
					}
				}
			],
			plotOptions: {
				column: {
					stacking: "normal",
					dataLabels: {
						enabled: false
					}
				}
			},
			credits: {
				enabled: false
			},
			series
		};

		return options;
	}

	function buildSummarizedChartOptions(data: CategoryChartSeriesData): Highcharts.Options {
		const piePoints = Object.keys(data)
			.map(key => {
				const displayProperties = seriesByTaskName(key);
				const seriesOptions: Highcharts.PointOptionsObject = {
					name: displayProperties.seriesName,
					color: displayProperties.seriesColorHex,
					y: _.sumBy(data[key], d => d[1]),
				};
				return seriesOptions;
			})
			.filter(point => point.y && point.y > 0);
		setSummarizedLegendList({
			data: ((array: Highcharts.PointOptionsObject[]): any[] => {
				return array.map(point => ({
					name: point.name?.toString() ?? "",
					value: point.y ?? 0,
					color: point.color
				})
				);
			})(piePoints),
			title: "Total tickets by type"
		});
		const totalCount = _.sum(piePoints.map(pp => pp.y ?? 0));
		const pieSeries: Highcharts.SeriesPieOptions = {
			type: "pie",
			data: piePoints,
			innerSize: "80%",
			tooltip: {
				pointFormat: "<b>{point.y}</b> ({point.percentage:.1f}%)"
			},
		};
		const options: Highcharts.Options = {
			chart: {
				plotBackgroundColor: undefined,
				plotBorderWidth: undefined,
				plotShadow: false
			},
			title: {
				text: `${totalCount} Tickets`,
				verticalAlign: "middle",
				floating: true
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: "pointer",
					dataLabels: showDataLabels ? {
						enabled: true,
						format: "<b>{point.name}</b>: {point.y}"
					} : {
						enabled: false,
					}
				}
			},
			credits: {
				enabled: false
			},
			series: [pieSeries]
		};

		return options;
	}

	async function fetchData(): Promise<CategoryChartSeriesData | undefined> {
		let data: CategoryChartSeriesData | undefined;
		switch (props.cycle) {
			case CycleState.Start: {
				data = await teamsReportStore.issuesByTypeCycleStart(scope.startTime, scope.endTime, scope.timezone, scope.interval,
					scope.teamIds, scope.projectIds);
				break;
			}
			case CycleState.End: {
				data = await teamsReportStore.issuesByTypeCycleEnd(scope.startTime, scope.endTime, scope.timezone, scope.interval,
					scope.teamIds, scope.projectIds);
				break;
			}
		}
		return data;
	}

	function shouldFetchData(): boolean {
		let shouldFetch = false;
		switch (props.cycle) {
			case CycleState.Start: {
				shouldFetch = (!teamsReportStore.isLoadingIssuesByTypeCycleStartMetric);
				break;
			}
			case CycleState.End: {
				shouldFetch = (!teamsReportStore.isLoadingIssuesByTypeCycleEndMetric);
				break;
			}
		}

		return shouldFetch;
	}

	useDeepCompareEffect(() => {
		let isMounted = true;
		async function fetchChartData() {
			const data = await fetchData();
			if (isMounted && data) {
				setExpandedChartOptions(buildExpandedChartOptions(data));
				setSummarizedChartOptions(buildSummarizedChartOptions(data));
			}
		}

		if (shouldFetchData()) {
			// We need to clear those options as we might have a different count of columns which will cause to a crash in highcharts
			setExpandedChartOptions(undefined);
			setSummarizedChartOptions(undefined);
			// tslint:disable-next-line: no-floating-promises
			fetchChartData().then(() => {
				isMounted = false;
			});
		}
	}, [props]);

	return (
		<LoadingIndicator local={true} isActive={expandedChartOptions === undefined && summarizedChartOptions === undefined}>
			<Grid.Column width={10}>
				{mainTitle &&
					<h2 className="ui title acu-capitalize">{mainTitle.title}
						{mainTitle.tooltip && <Popup
							hoverable={true}
							wide={true}
							className={"tiny-text"}
							position="top center"
							content={mainTitle.tooltip}
							trigger={
								<i className="chart-tooltip info circle outline icon" />
							}
						/>}
					</h2>}
				{expandedChartOptions && <HighchartsReact
					highcharts={Highcharts}
					options={expandedChartOptions}
				/>}
			</Grid.Column>
			<Grid.Column width={6}>
				{mainTitle &&
					<h2 className="ui title acu-capitalize">{summarizedLegendList?.title}</h2>
				}
				{showExternalLegend &&
					<div className="ui grid full-height">
						<Grid.Column width={8}>
							{summarizedChartOptions &&
								<div className="pie-chart-container">
									<PieChart
										series={summarizedChartOptions?.series}
										triggerHover={hoveredSummaryMetric}
										height={chartHeight ? chartHeight?.summaryPieChartHeight : undefined}
									/>
								</div>
							}
						</Grid.Column>
						<Grid.Column width={8}>
							<div className="legend-container">
								{summarizedChartOptions?.series !== undefined &&
									<ChartExternalLegend
										legendList={summarizedLegendList?.data}
										hoveredEntry={hoveredSummaryMetric}
										setHoveredEntry={setHoveredSummaryMetric}
									/>}
							</div>
						</Grid.Column>
					</div>
				}
				{summarizedChartOptions && !showExternalLegend &&
					<HighchartsReact
						highcharts={Highcharts}
						options={summarizedChartOptions}
					/>}
			</Grid.Column>
		</LoadingIndicator>
	);
}

export default TicketsByType;
