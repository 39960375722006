import { TaskIntegrationType, PullRequestIntegrationType } from "@acumen/database-types";
import React from "react";
import "./developer-completed-work-item.scss";

export interface DeveloperCompletedWorkItemProps {
	type: TaskIntegrationType | PullRequestIntegrationType;
	publicIdentifier: string | null;
	link: string | null;
	title: string | null;
}

export const DeveloperCompletedWorkItem = (props: DeveloperCompletedWorkItemProps) => {
	const { type, publicIdentifier, link, title } = props;

	return (
		<div className="developer-completed-work-item">
			<div className="developer-completed-work-item-img">
				{type === TaskIntegrationType.JIRA ?
				 <img className="jiraIcon" src="/assets/images/jira-icon-14x14.svg" alt="" /> :
				 <img className="githubIcon" src="/assets/images/github-icon-14x14.svg" alt="" />}
			</div>
			<div className="developer-completed-work-item-public-identifier"><a href={link!} target="_blank" rel="noreferrer">{publicIdentifier}</a></div>
			<div className="developer-completed-work-item-title">{title}</div>
		</div>
	);
};
