import * as React from "react";
const IssuesSearch = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width={20}
		height={20}
		viewBox="0 0 20 20"
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
	</svg>
);
export default IssuesSearch;
