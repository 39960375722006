import React, { useCallback, useRef, useState } from "react";
import classNames from "classnames";

import { useSortContext } from "../providers/sort-provider";
import { FilterOptionModel, ResolvedFilterOption } from "./filter-dropdown/types";
import FilterDropdown from "./filter-dropdown/filter-dropdown";
import FilterProvider, { useFilterContext } from "../providers/filter-provider";
import { useFilterEffect, useUniqueOptions } from "./filter-dropdown/hooks";
import useFiltersState from "../hooks/useFilterState";
import useFilteredItems from "../hooks/useFilteredItems";

interface ControlledTableHeaderProps<Item, FiltersState extends Partial<Record<string, Set<string | number> | null>>> extends React.ComponentProps<"th"> {
	columnName?: string;
	sortable?: boolean;
	labelClassName?: string;
	sortBy?: (item: Item) => unknown;
	filterSearchPlaceholder?: string;
	isFilteringEnabledForColumn?: boolean;
	mapItemToFilterOption?: (item: Item) => FilterOptionModel;
	filterKey: keyof FiltersState;
	presetFilterSelection?: FiltersState | null;
	filterSelection: FiltersState;
	onFilterSelectionChange: (selection: FiltersState) => void;
}

function ControlledTableHeader<Item, FiltersState extends Partial<Record<string, Set<string | number> | null>>>(props: ControlledTableHeaderProps<Item, FiltersState>) {
	const {
		columnName,
		sortable,
		sortBy,
		filterSearchPlaceholder,
		isFilteringEnabledForColumn,
		mapItemToFilterOption,
		children = columnName,
		labelClassName,
		onClick,
		filterKey,
		presetFilterSelection,
		filterSelection,
		onFilterSelectionChange,
		...restProps
	} = props;
	const { isSortable, isSorted, sortArrow, handleClick } = useSort({ columnName, sortable, sortBy, onClick });
	const { items, isFilteringEnabled } = useFilterContext();
	const isFilterable = isFilteringEnabled && !!mapItemToFilterOption && isFilteringEnabledForColumn !== false;
	const className = classNames(
		"table-header",
		(isSortable || isFilterable) && "advanced-table-cell",
		restProps.className
	);
	const [isFilterDropdownVisible, setIsFilterDropdownVisible] = useState(false);
	const { filters, setFilters } = useFiltersState<ResolvedFilterOption>();
	const handleFilterButtonClick = useCallback(() => setIsFilterDropdownVisible(isVisible => !isVisible), []);
	const hideFilterDropdown = useCallback(() => setIsFilterDropdownVisible(false), []);
	const triggerRef = useRef<HTMLButtonElement>(null);
	const options = useUniqueOptions({ items, mapItemToFilterOption });
	const optionsFilteredByPreset = useFilteredItems({ items: options, filters });
	const selectedFilterItems = filterSelection[filterKey] ?? null;
	const filterItemsSelectedByPreset = presetFilterSelection?.[filterKey] ?? null;
	const handleFilterItemsChange = useCallback((selection: Set<string | number> | null) => {
		onFilterSelectionChange({
			...filterSelection,
			[filterKey]: selection,
		});
	}, [filterKey, filterSelection, onFilterSelectionChange]);

	useFilterEffect({ mapItemToFilterOption, selection: selectedFilterItems });

	return (
		<th {...restProps} className={className}>
			{(!isSortable && !isFilterable) ? children : (
				<div className="cell-content">
					<span className={classNames("cell-label", labelClassName)}>
						<span onClick={handleClick}>{children}</span>
						{isFilterable && (
							<button
								ref={triggerRef}
								type="button"
								className={classNames("cell-filter-button", selectedFilterItems && "visible")}
								onClick={handleFilterButtonClick}
							/>
						)}
					</span>
					<span className="cell-arrow" onClick={handleClick}>{isSorted && sortArrow}</span>
				</div>
			)}

			{isFilterDropdownVisible && (
				<FilterProvider items={items} onFiltersChange={setFilters}>
					<FilterDropdown
						triggerRef={triggerRef}
						placeholder={filterSearchPlaceholder}
						options={optionsFilteredByPreset}
						presetSelection={filterItemsSelectedByPreset}
						selection={selectedFilterItems}
						onSave={handleFilterItemsChange}
						onHide={hideFilterDropdown}
					/>
				</FilterProvider>
			)}
		</th>
	);
}

const useSort = <Item extends any>(props: {
	columnName?: string;
	sortable?: boolean;
	sortBy?: (item: Item) => unknown;
	onClick?: (event: React.MouseEvent<HTMLTableHeaderCellElement>) => void;
}) => {
	const { columnName, sortable, sortBy, onClick } = props;
	const { isSortingEnabled, sortedColumn, sort } = useSortContext();
	const isSorted = sortedColumn?.columnName === columnName;
	const sortArrow = sortedColumn?.sortDirection === "asc" ? "↓" : "↑";
	// NOTE: Ignore isSortingEnabled and disable sort if, for a specific column:
	// - "sortable" is explicitly set to false
	// - columnName is not set
	// - sortBy is not set
	const isSortable = isSortingEnabled && sortable !== false && !!columnName && !!sortBy;
	const handleClick = useTableHeaderClickHandler({ columnName, isSortable, sort, sortBy, onClick });

	return {
		isSortable,
		isSorted,
		sortArrow,
		handleClick,
	};
};

const useTableHeaderClickHandler = <Item extends any>(props: {
	columnName?: string;
	isSortable: boolean;
	sort: (columnName: string, sortBy: (item: Item) => unknown) => void;
	sortBy?: (item: Item) => unknown;
	onClick?: (event: React.MouseEvent<HTMLTableHeaderCellElement>) => void;
}) => {
	const { columnName, isSortable, sort, sortBy, onClick } = props;

	return useCallback((event: React.MouseEvent<HTMLTableHeaderCellElement>) => {
		if (isSortable) {
			sort(columnName!, sortBy!);
		}

		if (onClick) {
			onClick(event);
		}
	}, [columnName, isSortable, sort, sortBy, onClick]);
};

export default ControlledTableHeader;
