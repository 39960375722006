import { useCallback, useMemo } from "react";
import _ from "lodash";
import { SortableColumn } from "../../types/sortable-column";

interface UseSortedItemsProps<T> {
	items: T[];
	sortedColumn: SortableColumn | null;
}

/**
 * Takes an array of items and a sorted column (if any), returns a sorted array of items.
 * @param items
 * @param sortedColumn
 */
function useSortedItems<T>({ items, sortedColumn }: UseSortedItemsProps<T>) {
	const sortBy = sortedColumn?.sortBy;
	const internalSortBy = useCallback((item: T) => {
		if (!sortBy) {
			return null;
		}

		const value = sortBy(item);

		return typeof value === "string"
			? value.trim().toLowerCase()
			: value;
	}, [sortBy]);

	return useMemo(() => {
		const sortDirection = sortedColumn?.sortDirection;

		return (!sortedColumn || !sortDirection)
			? items
			: _.orderBy(items, internalSortBy, sortDirection);
	}, [items, sortedColumn, internalSortBy]);
}

export default useSortedItems;
