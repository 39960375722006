import React, { useState } from "react";
import { IConfigFields, IDashboardCustomizableValue, } from "../../mobx-stores/customization-store";
import "./style.scss";
import "./go-retro-sprint-review/style.scss";
import { ConfigurationEntityType, CustomizableConfiguration, CustomizableConfigurationCategories, INonObjectConfigContextValues, isINonObjectConfigContextValues, MixpanelEventType, SINGLE_CONFIG_KEY } from "@acumen/dashboard-common";
import CustomizationFields from "../../components/customize-configurations/customization-fields";
import { ConfigurationsAccessType } from "../../components/customize-configurations";
import { useStores } from "../../mobx-stores";
import classNames from "classnames";
import Loader from "../../components/loader";
import { SprintCustomizationReferrer } from "../../../services/mixpanel-tracking-service";
import { getAutoDetectParams } from "../../mobx-stores/go-retro-sprint-review-store";
import { mixpanelService } from "../../external-app";

const GoRetroConfiguration = (props: { onNext?: () => void, referrer: SprintCustomizationReferrer }) => {
	const {
		customizationStore: {
			updateConfigurationByCategory
		},
		goRetroSprintSummaryStore: {
			fetchAutoDetectedConfigurations
		},
	} = useStores();

	const [changedFormFields, setChangedFormFields] = useState<Partial<IDashboardCustomizableValue>>({});
	const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
	const [userPreselectedSprintIds, setUserPreselectedSprintIds] = useState<string[]>([]);
	const { onNext, referrer } = props;

	const updateConfigurations = async (configs: Partial<IDashboardCustomizableValue>) => {
		if (Object.keys(configs).length === 0) {
			return;
		}

		mixpanelService.track(MixpanelEventType.SprintReviewCustomization, {
			estimationMethod: configs.EstimationMethod,
			subTaskStrategy: configs.SubtaskSprintStrategy,
			planningGracePeriodHours: configs.PlannedGracePeriod,
			locale: configs.Locale,
			timezone: configs.TimeZone,
			leadingScreen: referrer
		});

		await updateConfigurationByCategory(
			{
				category: CustomizableConfigurationCategories.GoRetro,
				configs
			},
			ConfigurationEntityType.CustomerDefault,
			undefined, true
		);
	};

	return (
		<div className="ext-section-wrapper configuration">
			<div className="go-retro-configurations configurations-container">
				<div className="configuration-container">
					<Loader isActive={isRefreshing} local={true}>
						<CustomizationFields
							category={CustomizableConfigurationCategories.GoRetro}
							entityType={ConfigurationEntityType.CustomerDefault}
							refreshPage={() => {
								return;
							}}
							showLoadingIndicator={true}
							configurationsAccessType={ConfigurationsAccessType.external}
							onChange={(values: Partial<IDashboardCustomizableValue>) => {
								setChangedFormFields(values);
							}}
							onInitialConfigFieldsValues={(fields: IConfigFields) => {
								const sprintIdsField = fields.find(f => f.name === CustomizableConfiguration.SprintIds);
								if (!sprintIdsField) {
									return;
								}
								const valueForUpdate = isINonObjectConfigContextValues(sprintIdsField.currentValue)
									? (sprintIdsField.currentValue as INonObjectConfigContextValues)[SINGLE_CONFIG_KEY]
									: undefined;
								setUserPreselectedSprintIds(valueForUpdate as string[]);
							}}
						/>
					</Loader>
				</div>
				<div className="buttons-row flex-row spaced">
					<div className={classNames("ui button retro-link-secondary",
						{ loading: isRefreshing, disabled: isRefreshing || userPreselectedSprintIds.length === 0})
					}
						onClick={async (e) => {
							e.preventDefault();
							setIsRefreshing(true);
							try {
								const res = await fetchAutoDetectedConfigurations(getAutoDetectParams(userPreselectedSprintIds));
								if (res) {
									const configs: any = {};
									Object.entries(res.data).forEach(([key, value]) => {
										configs[key as any as string] = {
											single: value
										};
									});
									await updateConfigurationByCategory(
										{
											category: CustomizableConfigurationCategories.GoRetro,
											configs
										},
										ConfigurationEntityType.CustomerDefault,
										undefined, true
									);
								}
							} finally {
								setIsRefreshing(false);
							}
						}}>
						Restore default
					</div>

					<div className={classNames("ui button retro-button configurations",
						{ loading: isRefreshing, disabled: isRefreshing })
					} onClick={() => {
						setIsRefreshing(true);
						// tslint:disable-next-line: no-floating-promises
						updateConfigurations(changedFormFields).then(() => {
							setIsRefreshing(false);
							if (onNext) {
								onNext();
							}
						}).catch(() => setIsRefreshing(false));
					}}>
						Next
					</div>
				</div>
			</div>
		</div>
	);
};

export default GoRetroConfiguration;
