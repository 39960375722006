import * as React from "react";
const DeleteIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width="11"
		height="12"
		viewBox="0 0 11 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path fillRule="evenodd" clipRule="evenodd" d="M7.75249 0.895167C7.75445 0.920331 7.75544 0.945763 7.75544 0.971429V1.1198H9.80614C10.3426 1.1198 10.7776 1.55472 10.7776 2.09123V3.13492C10.7776 3.62218 10.4188 4.02566 9.95105 4.09562L9.92838 4.33083L9.35111 10.3209C9.30311 10.819 8.88457 11.1991 8.38416 11.1991H2.59352C2.09311 11.1991 1.67457 10.819 1.62657 10.3209L1.0493 4.33083L1.02663 4.09561C0.558898 4.02561 0.200195 3.62216 0.200195 3.13493V2.09123C0.200195 1.55473 0.635119 1.1198 1.17162 1.1198H3.22228V0.971428C3.22228 0.945763 3.22328 0.92033 3.22523 0.895167C3.26412 0.394307 3.68287 0 4.19371 0H6.78401C7.29485 0 7.7136 0.394307 7.75249 0.895167ZM9.80614 2.09123H1.17162L1.17162 3.13493H9.80614L9.80614 2.09123ZM2.02523 4.33083L2.59352 10.2277H8.38416L8.95245 4.33083H2.02523Z" fill="#979797"/>
	</svg>
);
export default DeleteIcon;
