import {
	IntercomEventType,
	IPlanningPokerCard,
	IPlanningPokerIssue,
	IPlanningPokerPlayer,
	IPlanningPokerVote,
	MixpanelEventType,
	PokerGameMetricSystem, PokerIssuesSortOption, PokerPlanningGameState
} from "@acumen/dashboard-common";
import confetti from "canvas-confetti";
import classnames from "classnames";
import fuzzysort from "fuzzysort";
import Linkify from "linkify-react";
import _ from "lodash";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useHistory, useLocation } from "react-router-dom";
import useDeepCompareEffect from "use-deep-compare-effect";
import { FeatureFlags } from "v1/feature-flags";
import { QUESTION_MARK_CARD_VALUE } from "../adapters/cards";
import { useIntercom } from "../adapters/intercom";
import { useMixpanel } from "../adapters/mixpanel";
import { usePoker } from "../adapters/poker";
import { CardPicker } from "../partials/CardPicker";
import { ChangeDisplayNameModal } from "../partials/ChangeDisplayNameModal";
import { GameMenuBar, MenuItem } from "../partials/GameMenuBar";
import { GameSettingsModal } from "../partials/GameSettingsModal";
import { ActivationState, GameTable } from "../partials/GameTable";
import {
	InvitePlayerAvatar,
	shuffledAvatarArray
} from "../partials/InvitePlayerAvatar";
import { MODAL_TO_OPEN_ON_RETURN_FROM_JIRA, ImportExportDialogOptions, IssuePicker } from "../partials/IssuePicker";
import { Meta } from "../partials/Meta";
import {
	PlayerCard,
	PlayerCardNamePosition
} from "../partials/PlayerCard";
import { UserMenuBar, UserMenuBarItem } from "../partials/UserMenuBar";
import { PokerPage } from "../service/internal-router";
import { ICard } from "../types/card";

const MAX_SUPPORTED_PLAYERS = 20;
const SWITCH_TO_COMPACT_VIEW_PLAYERS_COUNT = 12;

const LinkifyOptions = {
	className: "text-blue-500 hover:underline break-all",
	rel: "noopener noreferrer",
	target: "_blank",
	validate: true,
};

type VisualPlayer = IPlanningPokerPlayer & {
	isFakeUser?: boolean;
	fakeUserAvatar?: InvitePlayerAvatar;
};

interface ISelectedCardOnIssue {
	card: ICard;
	issueXid: string;
}

const BoardGamePage = observer(() => {
	const poker = usePoker();
	const mixpanel = useMixpanel();
	const intercom = useIntercom();
	const history = useHistory();

	const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItem>(
		MenuItem.Issues
	);
	const [gameId, setGameId] = useState<string | undefined>(undefined);
	const [currentPlayer, setCurrentPlayer] = useState<IPlanningPokerPlayer | undefined>(
		undefined
	);
	const [gamePlayers, setGamePlayers] = useState<IPlanningPokerPlayer[]>([]);
	const [votingIssue, setVotingIssue] = useState<IPlanningPokerIssue | undefined>(undefined);
	const [nextVotingIssue, setNextVotingIssue] = useState<IPlanningPokerIssue | undefined>(
		undefined
	);
	const [cards, setCards] = useState<IPlanningPokerCard[]>([]);
	const [isModerator, setIsModerator] = useState<boolean>(false);

	const [topRowPlayers, setTopRowPlayers] = useState<
		VisualPlayer[] | undefined
	>(undefined);
	const [bottomRowPlayers, setBottomRowPlayers] = useState<
		VisualPlayer[] | undefined
	>(undefined);

	const [activationState, setActivationState] = useState<ActivationState>(
		ActivationState.Loading
	);

	const [selectedCard, setSelectedCard] = useState<ISelectedCardOnIssue | undefined>();
	const [userMenuSelectedOption, setUserMenuSelectedOption] = useState<
		UserMenuBarItem | undefined
	>();

	const [issues, setIssues] = useState<IPlanningPokerIssue[]>([]);
	const [filterKeywords, setFilterKeywords] = useState<string | undefined>(undefined);
	const [filteredIssues, setFilteredIssues] = useState<IPlanningPokerIssue[]>(issues);
	const location = useLocation();
	const currentQsParams = new URLSearchParams(location.search);
	const [invitationUrl, setInvitationUrl] = useState<string>(`https://example.com/${PokerPage.JoinGame}`);
	const [finishedEstimatingAllIssues, setFinishedEstimatingAllIssues] = useState<boolean>(false);
	const [lastIssueXidMovedToVotingOn, setLastIssueXidMovedToVotingOn] = useState<string | undefined>();
	const [gameUpdateTrigger, setGameUpdateTrigger] = useState(0);
	const game = poker?.service?.game;
	const integration = poker?.service?.integration;
	const isJiraExportEnabled = useMemo(() =>
		poker?.featureFlagStore.isFeatureEnabled(FeatureFlags.PlanningPokerJiraExport),
	[poker, poker?.featureFlagStore.isInitialized]);

	useEffect(() => {
		const id = currentQsParams.get("id");
		if (id && poker && poker.service) {
			setGameId(id);
			poker.service.getGameDetails(id)
				.then(firstCallToGame => {
					if (!firstCallToGame) {
						toast.error(`The game you are trying to play doesn't exist, create a new game`);
						setTimeout(() => {
							history.push({
								pathname: PokerPage.CreateGame.toString(),
								search: location.search
							});
						}, 2000);
						return;
					}
					// tslint:disable-next-line: no-floating-promises
					poker.service!.subscribeToGame(id);
					// tslint:disable-next-line: no-floating-promises
					poker.service!.getActiveIntegration();
				}).catch();
		}
		const inviteUrl = currentQsParams.get("invite-url");
		if (inviteUrl) {
			setInvitationUrl(decodeURIComponent(inviteUrl));
		}

	}, [poker, poker?.service, gameUpdateTrigger]);

	const getClosestCardAccordingToMetric = (
		votes: IPlanningPokerVote[],
		usedCards: ICard[],
		metric: PokerGameMetricSystem
	) => {
		if (usedCards.length === 0 || votes.length === 0) {
			return undefined;
		}
		const sorted = votes
			.map((vp) => vp.value)
			.sort((a, b) => a - b);

		const sortedWithoutQuestionMarks = sorted
			.filter(i => i !== QUESTION_MARK_CARD_VALUE);

		let matchedCardValue: number | undefined;

		if (sorted.length === 0) {
			matchedCardValue = QUESTION_MARK_CARD_VALUE;
		} else if (sorted.length === 1) {
			matchedCardValue = sorted[0]!;
		} else if (sortedWithoutQuestionMarks.length === 0) {
			matchedCardValue = QUESTION_MARK_CARD_VALUE;
		} else if (sortedWithoutQuestionMarks.length === 1) {
			matchedCardValue = sortedWithoutQuestionMarks[0]!;
		} else { // We have more than one actual selected value
			// Average running on actual values
			if (metric === PokerGameMetricSystem.Average) {
				const sumOfVotes = sortedWithoutQuestionMarks.reduce((partialSum, a) => partialSum + a, 0);
				matchedCardValue = sumOfVotes / sortedWithoutQuestionMarks.length;
			} else if (metric === PokerGameMetricSystem.Median) {
				const mid = Math.floor(sortedWithoutQuestionMarks.length / 2);
				matchedCardValue =
					sortedWithoutQuestionMarks.length % 2 !== 0
					? sortedWithoutQuestionMarks[mid]
					: (sortedWithoutQuestionMarks[mid - 1]! + sortedWithoutQuestionMarks[mid]!) / 2;
			} else if (metric === PokerGameMetricSystem.Consensus) {
				const MISSING_VALUE = -1000; // Draw option
				const consensus = [...sorted, MISSING_VALUE];
				const consensusFigure = consensus
					.sort(
						(a, b) =>
							consensus.filter((v) => v === a).length -
							consensus.filter((v) => v === b).length
					)
					.pop();
				if (consensusFigure && consensusFigure !== MISSING_VALUE) {
					matchedCardValue = consensusFigure;
				} else {
					matchedCardValue = QUESTION_MARK_CARD_VALUE;
				}
			}
		}

		if (matchedCardValue !== undefined) {
			const closestCard = usedCards.reduce((prev, curr) =>
				Math.abs(curr.value - matchedCardValue!) <
					Math.abs(prev.value - matchedCardValue!)
					? curr
					: prev
			);

			return closestCard;
		}
		return undefined;
	};

	const gameVotingIsCompleted = () => {
		confetti({
			spread: Math.random() * (70 - 50) + 50,
			particleCount: Math.random() * (100 - 50) + 50,
			origin: { x: 0.4, y: 0.7 },
		});

		if (game) {
			toast.success(`${game.name} voting is completed 🥳`, {
				duration: 4000
			});
		}
	};

	const copyInviteLink = useCallback(async () => {
		if (mixpanel) {
			mixpanel.track(MixpanelEventType.UserCopiedPokerInvitationLink);
		}
		if (intercom) {
			intercom.track(IntercomEventType.UserCopiedPokerInvitationLink);
		}
		const url = `${invitationUrl}id=${gameId}`;
		let hasManagedToCopyToClipboard = false;

		try {
			// @ts-ignore TS thinks that clipboard-write isn't a valid PermissionName, it is.
			const { state } = await navigator.permissions.query({ name: "clipboard-write" });
			if (state === "granted" || state === "prompt") {
				await navigator.clipboard.writeText(url);
				hasManagedToCopyToClipboard = true;
			}
		} catch (e) {
			hasManagedToCopyToClipboard = false;
		}

		if (!hasManagedToCopyToClipboard) {
			const dummy = document.createElement("input");
			document.body.appendChild(dummy);
			dummy.value = url;
			dummy.select();
			document.execCommand("copy");
			document.body.removeChild(dummy);
		}
		toast.success("The invite URL has been copied to your clipboard!");
	}, [gameId]);

	const voteOnIssue = useCallback(
		async (issueToVote: IPlanningPokerIssue) => {
			if (!poker || !poker.service || !game) {
				return;
			}

			if (lastIssueXidMovedToVotingOn && issueToVote.xid && issueToVote.xid === lastIssueXidMovedToVotingOn) {
				setLastIssueXidMovedToVotingOn(undefined);
			}

			await poker.service.setGameVotingState(game.xid, PokerPlanningGameState.PreVoting);
			if (game && game.votes.filter(gv => gv.issueUuid === issueToVote.xid).length > 0) {
				await poker.service.resetVotesOnIssue(game.xid, issueToVote);
			}
			await poker.service.voteOnIssue(game, issueToVote);
			setActivationState(ActivationState.Voting);
			setSelectedCard(undefined);
		},
		[game?.votes]
	);

	const onSaveEstimation = useCallback(
		async (issue: IPlanningPokerIssue, estimation: string) => {
		if (game && poker && poker.service && game.creatorUuid === game.currentPlayer?.xid) {
			await poker.service.saveEstimationOnIssue(
				game.xid,
				issue,
				estimation
			);
		}
	},
		[game]
	);

	const onSaveNote = useCallback(
		async (issue: IPlanningPokerIssue, note?: string | null) => {
		if (game && poker && poker.service && game.creatorUuid === game.currentPlayer?.xid) {
			await poker.service.saveNoteOnIssue(
				game.xid,
				issue,
				note
			);
		}
	},
		[game]
	);

	const onGetJiraFields = useCallback(
		async (integrationId: string) => {
			if (poker && poker.service) {
				return await poker.service.getJiraFields(integrationId);
			}
		},
		[poker, game]
	);

	const onUpdateJiraEstimate = useCallback(
		async (integrationId: string, key: string, field: string, type: "string" | "number", value: string) => {
			if (poker && poker.service) {
				return await poker.service.updateEstimateInJira(integrationId, key, field, type, value);
			}
		},
		[poker, game]
	);

	const onAddJiraComment = useCallback(
		async (integrationId: string, key: string, comment: string) => {
			if (poker && poker.service) {
				return await poker.service.addCommentInJira(integrationId, key, comment);
			}
		},
		[poker, game]
	);

	const handleSortIssues = useCallback(async (issuesSortOption: PokerIssuesSortOption) => {
		if (game && poker && poker.service) {
			await poker.service.updateGameSettings(
				game.xid,
				game.name,
				game.deck.xid,
				game.metricSystem,
				issuesSortOption,
			);
			setGameUpdateTrigger(trigger => trigger + 1);
		}
	}, [poker, game]);

	useDeepCompareEffect(() => {
		if (gamePlayers.length === 0 || !currentPlayer) {
			return;
		}
		// Are we in waiting mode? let's fill the players
		if (gamePlayers.length > 1) {
			const playersWithoutCurrentPlayerCapped =
				gamePlayers.filter(gp => gp.xid !== currentPlayer.xid).slice(0, MAX_SUPPORTED_PLAYERS);
			const playersCappedWithoutCurrentPlayer = _.take(playersWithoutCurrentPlayerCapped, MAX_SUPPORTED_PLAYERS - 1);

			const middleIndex = Math.ceil(playersCappedWithoutCurrentPlayer.length / 2);
			const top = playersCappedWithoutCurrentPlayer.splice(0, middleIndex);

			const bottomPart = playersCappedWithoutCurrentPlayer.splice(-middleIndex);
			const bottomPartMiddleIndex = Math.ceil(bottomPart.length / 2);
			const leftSideBottomPlayers = bottomPart.splice(0, bottomPartMiddleIndex);
			const rightSideBottomPlayers = bottomPart.splice(-bottomPartMiddleIndex);
			const bottom = [...leftSideBottomPlayers, currentPlayer, ...rightSideBottomPlayers];

			setTopRowPlayers(top);
			setBottomRowPlayers(bottom);
		} else if (gamePlayers.length === 1) {
			const avatars = shuffledAvatarArray(gamePlayers[0].name!);
			const top: VisualPlayer[] = Array.from({ length: 3 }, (_u, k) => ({
				xid: `fake-player-top-${k}`,
				name: "Invite Me",
				isFakeUser: true,
				fakeUserAvatar: avatars.pop(),
				isOnline: true
			}));
			const bottom: VisualPlayer[] = [
				{
					xid: `fake-player-bottom-1`,
					name: "Invite Me",
					isFakeUser: true,
					fakeUserAvatar: avatars.pop(),
					isOnline: true
				},
				gamePlayers[0]!,
				{
					xid: `fake-player-bottom-2`,
					name: "Invite Me",
					isFakeUser: true,
					fakeUserAvatar: avatars.pop(),
					isOnline: true
				}
			];

			setTopRowPlayers(top);
			setBottomRowPlayers(bottom);
		}
	}, [gamePlayers, currentPlayer]);

	useDeepCompareEffect(() => {
		if (!game) {
			return;
		}

		setCards(game.deck.cards);
	}, [game, cards]);

	useMemo(() => {
		if (!game || game.issues.length === 0 || game.players.length === 1 || finishedEstimatingAllIssues) {
			return;
		}

		const hasNonEstimatedIssue = game.issues.find(i => i.estimation === null) !== undefined;
		if (!hasNonEstimatedIssue) {
			setFinishedEstimatingAllIssues(true);
			if (!isModerator) {
				gameVotingIsCompleted();
			}
		}
	}, [game, finishedEstimatingAllIssues, isModerator]);

	useEffect(() => {
		if (!gameId || !poker || !poker.service) {
			return;
		}

		if (game) {
			if (game.currentPlayer) {
				setIsModerator(game.creatorUuid === game.currentPlayer.xid);
				setCurrentPlayer(game.currentPlayer);
			}

			const sortedPlayers = game.players.slice().sort((first, second) => {
				if (first.name > second.name) {
					return -1;
				}
				if (second.name > first.name) {
					return 1;
				}
				return 0;
			});
			setGamePlayers(sortedPlayers);
			setIssues(game.issues);
		}
	}, [game]);

	useEffect(() => {
		if (!filterKeywords || filterKeywords.trim().length === 0) {
			setFilteredIssues(issues);
			return;
		}
		const filtered: IPlanningPokerIssue[] = fuzzysort.go(filterKeywords, issues, {
			keys: ["key", "title", "description"],
			limit: 50,
			threshold: -10000,
		}).map(x => x.obj);

		setFilteredIssues(filtered);
	}, [issues, filterKeywords]);

	useMemo(() => {
		if (!game) {
			setActivationState(ActivationState.Loading);
			return;
		}

		if (
			!game.votingOnIssueUuid ||
			game.votingOnIssueUuid.length === 0 ||
			issues.length === 0
		) {
			setActivationState(ActivationState.SelectIssueToVote);
			setVotingIssue(undefined);
			setNextVotingIssue(undefined);
			return;
		}

		if (
			game.votingOnIssueUuid &&
			game.votingOnIssueUuid.length > 0 &&
			issues.length > 0 &&
			(!votingIssue ||
				votingIssue.xid !== game.votingOnIssueUuid ||
				game.votes.filter(v => v.issueUuid === game.votingOnIssueUuid).length === 0)
		) {
			const currentVotingIssue = issues.find(
				(i) => i.xid === game.votingOnIssueUuid
			);

			if (currentVotingIssue !== undefined) {
				setVotingIssue(currentVotingIssue);

				// Find the next voting issue which is not estimated and not the current one.
				setNextVotingIssue(issues.find(i => i.estimation === null && i.xid !== currentVotingIssue.xid));

				if (game.currentPlayer) {
					const currentPlayerVote =
						game.votes.find(v => v.issueUuid === game.votingOnIssueUuid && v.voterUuid === game.currentPlayer!.xid);
					if (currentPlayerVote) {
						setSelectedCard({
							card: {
								value: currentPlayerVote.value,
								displayValue: currentPlayerVote.displayValue
							},
							issueXid: game.votingOnIssueUuid
						});
					}
				}

				setActivationState(ActivationState.Voting);

				if (selectedCard && selectedCard.issueXid !== game.votingOnIssueUuid) {
					setSelectedCard(undefined);
				}
			} else {
				setVotingIssue(undefined);
				setNextVotingIssue(undefined);
			}

			let shouldMoveMenuToVoting = true;
			if (currentVotingIssue && lastIssueXidMovedToVotingOn && lastIssueXidMovedToVotingOn === currentVotingIssue.xid) {
				shouldMoveMenuToVoting = false;
			}
			if (shouldMoveMenuToVoting) {
				setSelectedCard(undefined);
				if (currentVotingIssue) {
					setLastIssueXidMovedToVotingOn(currentVotingIssue.xid);
				}
				setSelectedMenuItem(MenuItem.Votes);
			}
			return;
		}

		if (
			votingIssue &&
			(activationState === ActivationState.Voting ||
				activationState === ActivationState.ShowRevealCardsOption)
		) {
			const numberOfVotedPlayers = game.votes.filter((p) => p.issueUuid === votingIssue.xid).length;
			if (numberOfVotedPlayers > 0) {
				if (numberOfVotedPlayers === game.players.length || game.votingState === PokerPlanningGameState.PostVoting) {
					setActivationState(ActivationState.ShowVotes);
					setSelectedMenuItem(MenuItem.Issues);
				} else if (isModerator) {
					setActivationState(ActivationState.ShowRevealCardsOption);
				}
				setLastIssueXidMovedToVotingOn(undefined);
			}
		}
	}, [issues, activationState, votingIssue, game, isModerator]);

	return (
		<main className="bg-white antialiased">
			<Meta />
			<Toaster />
			<div className="flex flex-row h-screen">
				<div className="h-full w-full max-h-screen flex flex-col flex-grow">
					<div className="flex bg-white border-b border-[#DDDDDD]/30">
						<div className="container px-4 py-2 sm:px-8  mx-auto flex flex-wrap items-center justify-between">
							<div className="flex md:order-2">
								{gameId && (
									<button
										type="button"
										className={classnames(
											"inline-block py-2 px-4 text-base font-bold text-center text-white transition duration-200 bg-[#ED51A3] rounded hover:bg-[#D54892] ease",
											{
												invisible: activationState === ActivationState.Loading,
											}
										)}
										onClick={copyInviteLink}
									>
										Invite members
									</button>
								)}
							</div>
						</div>
						{currentPlayer && (
							<UserMenuBar
								currentPlayer={currentPlayer}
								isModerator={isModerator}
								onSelectedMenuItem={(option) => {
									switch (option) {
										case UserMenuBarItem.ChangeDisplayName:
										case UserMenuBarItem.Settings: {
											setUserMenuSelectedOption(option);
											break;
										}
										default: {
											break;
										}
									}
								}}
							/>
						)}
					</div>
					<div className="flex flex-col grow-1 h-full justify-center items-center content-center">
						<div className={classnames(
							"grid grid-flow-col mt-4",
							{
								"grid-rows-2": gamePlayers && gamePlayers.length > SWITCH_TO_COMPACT_VIEW_PLAYERS_COUNT
							},
							{
								"grid-rows-1": gamePlayers && gamePlayers.length <= SWITCH_TO_COMPACT_VIEW_PLAYERS_COUNT
							}
						)}>
							{topRowPlayers &&
								topRowPlayers.map((player) => (
									<PlayerCard
										key={player.xid}
										playerName={player.name}
										playerVote={game && game.votingOnIssueUuid ? game.votes.find(v => v.voterUuid === player.xid && v.issueUuid === game.votingOnIssueUuid) : undefined}
										showVote={activationState === ActivationState.ShowVotes}
										isDisabled={
											activationState === ActivationState.SelectIssueToVote
										}
										isFakeUser={player.isFakeUser === true}
										fakeUserAvatar={player.fakeUserAvatar}
										isCurrentPlayer={currentPlayer ? currentPlayer.xid === player.xid : false}
										namePosition={PlayerCardNamePosition.Bottom}
										isOnline={player.isOnline}
										isCompact={gamePlayers.length > SWITCH_TO_COMPACT_VIEW_PLAYERS_COUNT}
									/>
								))}
						</div>

						{votingIssue && (
							<div className="group flex flex-col justify-center items-center justify-self-center text-black font-light text-base">
								{<h2 className="text-xl font-bold underline text-[#1B3F7B]">
									Voting on
								</h2>}
								<div className="group relative">
									<h2
										className={classnames(
											"text-xl",
											"text-[#1B3F7B]",
											"text-center",
											{
												"cursor-help": votingIssue.description !== null && votingIssue.description.length > 0,
											}
										)}
									>
										{`${votingIssue.key} - ${votingIssue.title}`}
									</h2>
									{votingIssue.description &&
										votingIssue.description.trim().length > 0 && (
											<div className="absolute left-1/2 transform -translate-x-1/2 hidden py-2 px-6 z-50 bg-[#F5F5F5] shadow-lg shadow-gray-900/30 rounded-lg group-hover:block">
												<Linkify
													as="article"
													options={LinkifyOptions}
													className="line-clamp-6 text-left w-96 text-[#1B3F7B]"
												>
													{votingIssue.description}
												</Linkify>
											</div>
										)}
								</div>
							</div>
						)}

						<GameTable
							isModerator={isModerator}
							activation={activationState}
							currentIssue={votingIssue}
							nextIssue={nextVotingIssue}
							gameMetric={game?.metricSystem ?? PokerGameMetricSystem.Consensus}
							hasAnyIssues={issues.length > 0}
							onFinishedVoting={() => {
								gameVotingIsCompleted();
							}}
							onClickRevealCards={async () => {
								if (game && poker && poker.service) {
									await poker.service.setGameVotingState(game.xid, PokerPlanningGameState.PostVoting);
								}
							}}
							getClosestCardAccordingToMetric={(metric: PokerGameMetricSystem) => {
								const closestCard = getClosestCardAccordingToMetric(
									(game && game.votingOnIssueUuid) ? game.votes.filter(v => v.issueUuid === game.votingOnIssueUuid) : [],
									cards,
									metric
								);
								return closestCard;
							}}
							onVoteIssue={voteOnIssue}
							onSaveEstimation={onSaveEstimation}
						/>
						<div className={classnames(
							"grid grid-flow-col mt-4",
							{
								"grid-rows-2": gamePlayers && gamePlayers.length > SWITCH_TO_COMPACT_VIEW_PLAYERS_COUNT
							},
							{
								"grid-rows-1": gamePlayers && gamePlayers.length <= SWITCH_TO_COMPACT_VIEW_PLAYERS_COUNT
							}
						)}>
							{bottomRowPlayers &&
								bottomRowPlayers.map((player) => (
									<PlayerCard
										key={player.xid}
										playerName={player.name}
										playerVote={game && game.votingOnIssueUuid ? game.votes.find(v => v.voterUuid === player.xid && v.issueUuid === game.votingOnIssueUuid) : undefined}
										showVote={activationState === ActivationState.ShowVotes}
										isDisabled={
											activationState === ActivationState.SelectIssueToVote
										}
										isFakeUser={player.isFakeUser === true}
										fakeUserAvatar={player.fakeUserAvatar}
										isCurrentPlayer={currentPlayer ? currentPlayer.xid === player.xid : false}
										namePosition={PlayerCardNamePosition.Top}
										isOnline={player.isOnline}
										isCompact={gamePlayers.length > SWITCH_TO_COMPACT_VIEW_PLAYERS_COUNT}
									/>
								))}
						</div>
					</div>
				</div>
				{game && <div className="overflow-y-auto max-h-screen hidden lg:block lg:w-2/5 bg-gradient-to-br from-[#EDF0F3] to-[#E4ECF9]">
					<GameMenuBar
						selectedMenuItem={selectedMenuItem}
						onSelectedMenuItem={setSelectedMenuItem}
					/>
					{selectedMenuItem === MenuItem.Votes && (
						<CardPicker
							hasSelectedIssue={
								!!(
									game &&
									game.votingOnIssueUuid &&
									game.votingOnIssueUuid.length > 0
								)
							}
							selectedCard={selectedCard ? selectedCard.card : undefined}
							cards={cards}
							onSelectedCard={async (card) => {
								if (
									!game ||
									!poker ||
									!votingIssue ||
									!poker ||
									!poker.service
								) {
									return;
								}
								await poker.service.vote(
									game.xid,
									card.value,
									card.displayValue,
									votingIssue
								);
								setSelectedCard({
									card,
									issueXid: votingIssue.xid
								});
							}}
							hasVotedOnIssue={activationState === ActivationState.ShowVotes}
						/>
					)}
					{selectedMenuItem === MenuItem.Issues && (
						<IssuePicker
							gameName={game?.name}
							currentGameDeck={game.deck}
							issues={filteredIssues}
							integration={integration}
							isModerator={isModerator}
							isJiraExportEnabled={isJiraExportEnabled ?? false}
							votingOnIssueId={
								game && game.votingOnIssueUuid && game.votingOnIssueUuid.length > 0
									? game.votingOnIssueUuid
									: undefined
							}
							onNewIssues={async (issuesToAdd) => {
								if (!poker || !poker.service) {
									return;
								}
								if (game && issuesToAdd.length > 0) {
									if (issuesToAdd.length > 1) {
										toast.loading(`Adding ${issuesToAdd.length} issues...`);
									}
									await poker.service.addIssuesToGame(game.xid, issuesToAdd);
									toast.dismiss();
									if (issuesToAdd.length === 1) {
										toast.success(
											`Added ${issuesToAdd[0]!.key ?? ""
											} issue successfully`
										);
									} else {
										toast.success(
											`Added ${issuesToAdd.length} issues successfully`
										);
									}
								}
							}}
							onFilterIssueByKeywords={setFilterKeywords}
							onSortIssues={handleSortIssues}
							onDeleteIssues={async (issuesToDelete) => {
								if (!poker || !poker.service) {
									return;
								}
								if (game) {
									await poker.service.deleteIssuesFromGame(
										game.xid,
										issuesToDelete
									);
								}
							}}
							onVoteIssue={voteOnIssue}
							onSaveEstimation={onSaveEstimation}
							onSaveNote={onSaveNote}
							onGetJiraFields={onGetJiraFields}
							onUpdateJiraEstimate={onUpdateJiraEstimate}
							onAddJiraComment={onAddJiraComment}
							modalToOpenOnReturnFromJira={currentQsParams.get(MODAL_TO_OPEN_ON_RETURN_FROM_JIRA) === "null"
								? null
								: currentQsParams.get(MODAL_TO_OPEN_ON_RETURN_FROM_JIRA) as ImportExportDialogOptions ?? null
							}
							editQsParams={(queryParams: Record<string, string | null>) => {
								Object.entries(queryParams).forEach(([k, v]) => {
									const parsedValue = v === null ? "null" : v;
									currentQsParams.set(k, parsedValue);
								});

								history.push({
									pathname: location.pathname,
									search: currentQsParams.toString(),
								});
							}}
						/>
					)}
				</div>}
			</div>

			{game && userMenuSelectedOption === UserMenuBarItem.Settings && (
				<GameSettingsModal
					onCancel={() => {
						setUserMenuSelectedOption(undefined);
					}}
					currentGameName={game.name}
					currentGameMetricSystem={game.metricSystem}
					currentGameDeck={game.deck}
					isCurrentGameStarted={game.votes.length > 0 || _.some(game.issues, "estimation")}
					onSave={async (name: string, deckUuid: string, metric: PokerGameMetricSystem) => {
						if (poker && poker.service) {
							await poker.service.updateGameSettings(
								game.xid,
								name,
								deckUuid,
								metric
							);
						}
						setUserMenuSelectedOption(undefined);
					}}
				/>
			)}
			{currentPlayer &&
				game &&
				userMenuSelectedOption === UserMenuBarItem.ChangeDisplayName && (
					<ChangeDisplayNameModal
						onCancel={() => {
							setUserMenuSelectedOption(undefined);
						}}
						playerName={currentPlayer.name}
						onSave={async (newPlayerName) => {
							if (poker && poker.service) {
								await poker.service.updatePlayerName(
									game.xid,
									currentPlayer.xid,
									newPlayerName
								);
							}
							setUserMenuSelectedOption(undefined);
						}}
					/>
				)}
		</main>
	);
});

export default BoardGamePage;
