//  Code is taken from 'https://www.npmjs.com/package/uniqolor'
import _ from "lodash";

const ACUMEN_COLOR_PALETTE =
[
	"#E5D8FF",
	"#D4BEFF",
	"#B28BFF",
	"#7835FF",
	"#4800D8",
	"#E0DDFD",
	"#C6C0FB",
	"#8E81F7",
	"#3B24F0",
	"#170987",
	"#F8DDF0",
	"#F3C4E6",
	"#E784CA",
	"#DE5AB8",
	"#AF2387",
	"#FADEE1",
	"#F5BAC1",
	"#F096A1",
	"#E86071",
	"#C61C31",
	"#FDF0D8",
	"#FBDCA7",
	"#F9CD80",
	"#F8BE59",
	"#F49F0B",
	"#FDF0CC",
	"#FDE7A9",
	"#FBD66D",
	"#F9BF1E",
	"#E3A906",
	"#C6EFE6",
	"#92E0CF",
	"#42CAAC",
	"#32B195",
	"#288D77",
	"#DDF7E9",
	"#C0F0D6",
	"#86E3B1",
	"#48D489",
	"#2AB56A",
	"#CBF2FC",
	"#A4E9FA",
	"#6ADBF8",
	"#1CC8F4",
	"#0BB2DC",
	"#B7CDEF",
	"#96B5E8",
	"#608EDC",
	"#142E5A"
];

/**
 * Generate hashCode
 * @param  {string} str
 * @return {number}
 */
const hashCode = (str: string): number => {
	const len = str.length;
	let hash: number = 0;

	for (let i = 0; i < len; i++) {
		// tslint:disable-next-line: no-bitwise
		hash = ((hash << 5) - hash) + str.charCodeAt(i);
		// tslint:disable-next-line: no-bitwise
		hash &= hash; // Convert to 32bit integer
	}

	return hash;
};

/**
 * Clamps `num` within the inclusive `range` bounds
 * @param  {number}       num
 * @param  {number|number[]} range
 * @return {number}
 */
const boundHashCode = (num: number, range: number | number[]): number => {
	if (typeof range === "number") {
		return range;
	}

	return (num % Math.abs(range[1] - range[0])) + range[0];
};

/**
 * Generate unique color from `value`
 * @param  {string} value
 * @return {Object}
 */
const uniqolor = (string: string) => {
	const value = string.split("#").reverse().join("-");
	const hash = Math.abs(hashCode(String(_.kebabCase(_.lowerCase(value)))));

	const colorIndex = boundHashCode(hash, [0, ACUMEN_COLOR_PALETTE.length]);
	return ACUMEN_COLOR_PALETTE[colorIndex];
};

export default uniqolor;
