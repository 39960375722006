import apiContextProvider from "../../services/api-context-provider";
import {
	DashboardComponentEntityType, DashboardProjectEntityType,
	IDashboardComponent, ITeamComponents, ITeamProjects
} from "@acumen/dashboard-common";
import { observable, action, computed } from "mobx";
import { ComponentsApiClient, COMPONENTS_ROUTE, IComponentFilter } from "../services/crud/components-api-client";
import BaseStore from "./base-store";
import _ from "lodash";
import { PROJECTS_ROUTE, ProjectsApiClient } from "../services/crud/projects-api-client";
import { ISelectOptionGroups, ISelectOptions } from "../components/form/option-select";
import { FetchLatestRequest } from "../../services/fetch-helpers";

export default class ComponentsStore extends BaseStore<{}> {
	private readonly apiClient: ComponentsApiClient = new ComponentsApiClient(apiContextProvider);
	private readonly projectsApiClient = new ProjectsApiClient(apiContextProvider);

	@observable
	public components: IDashboardComponent[] | undefined = undefined;

	@observable
	public componentsByTeam: ITeamComponents = {
		external: [],
		ownable: []
	};

	@observable
	private projects: ITeamProjects = {
		external: [],
		ownable: []
	};

	private fetchLatestComponents = new FetchLatestRequest<IDashboardComponent[], any>(COMPONENTS_ROUTE);
	private fetchLatestProjects = new FetchLatestRequest<ITeamProjects, any>(PROJECTS_ROUTE);
	@action.bound
	async fetchData() {
		const [componentsResult, projectsResult] = await Promise.all([
			this.fetchLatestComponents.fetchLatest(this.apiClient.fetchComponents()),
			this.fetchLatestProjects.fetchLatest(this.projectsApiClient.fetchProjects())
		]);

		this.components = componentsResult?.data ?? [] as IDashboardComponent[];
		this.projects = projectsResult?.data ?? {
			external: [],
			ownable: []
		};
	}

	private fetchLatestComponentDataByTeam = new FetchLatestRequest<ITeamComponents, any>(COMPONENTS_ROUTE);
	@action.bound
	async fetchComponentDataByTeam(filter: IComponentFilter) {
		const componentsResult = await this.fetchLatestComponentDataByTeam.fetchLatest(this.apiClient.fetchComponentsByFilter(filter));

		this.componentsByTeam = componentsResult?.data ?? {
			external: [],
			ownable: []
		};
	}

	@computed
	public get componentsById() {
		if (this.components === undefined) {
			return undefined;
		}

		return _
			.chain(this.components)
			.groupBy("name")
			.mapValues((values) => {
				return values.map(v => ({
					id: v.entityId,
					name: values.length > 1
						? this.getUniqueName(v, this.projects)
						: v.name
				}));
			})
			.values()
			.flatten()
			.keyBy("id")
			.value();
	}

	@computed
	public get componentsOptions(): ISelectOptions[] {
		if (!this.components) {
			return [];
		}
		return this.components.map(comp => ({
			key: comp.entityId,
			value: `${comp.entityId},${comp.entityType}`,
			label: comp.name ?? ""
		}));
	}

	@computed
	public get componentsByTeamOptions(): ISelectOptionGroups[] {
		return Object.keys(this.componentsByTeam).map(componentCategory => {
			return {
				label: componentCategory,
				options:
					_
						.orderBy(this.componentsByTeam[componentCategory as keyof ITeamComponents], p => p.name?.toLowerCase(), "asc")
						.map((opt: IDashboardComponent) => {
							return {
								key: opt.entityId,
								value: `${opt.entityId},${opt.entityType}`,
								label: opt.name ?? ""
							};
						})
			};
		});
	}

	public getUniqueName(component: IDashboardComponent, projects: ITeamProjects) {
		if (component.entityType !== DashboardComponentEntityType.JIRA) {
			return component.name;
		}

		const project = [
			...projects.external,
			...projects.ownable
		].find(p => p.entityType === DashboardProjectEntityType.JIRA && p.entityId === component.parentEntityId);

		if (!project) {
			return component.name;
		}

		return `${project.key}-${component.name}`;
	}
}
