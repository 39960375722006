import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useState } from "react";
import { useStores } from "../../../mobx-stores";
import { ChartSeriesData } from "../../../pages/my-team/analytics/charts/charts";
import LoadingIndicator from "../../../components/loader";
import { IChartScope } from "./chart-scope";
import useDeepCompareEffect from "use-deep-compare-effect";

interface IProps {
	scope: IChartScope;
}

function AbandonedWorkChart(props: IProps) {
	const { teamsReportStore } = useStores();
	const [chartOptions, setChartOptions] = useState<Highcharts.Options | undefined>(undefined);

	function buildChartOptions(data: {
		numberOfCommit: ChartSeriesData
	}): Highcharts.Options {
		const splineSeries: Highcharts.SeriesSplineOptions[] = [
			{
				type: "spline",
				name: "# of commits",
				data: data.numberOfCommit,
				visible: data.numberOfCommit.length > 0,
				dashStyle: "Dash",
				color: "#000000",
				lineWidth: 1,
				marker: {
					symbol: "circle"
				},
			}
		];
		const options: Highcharts.Options = {
			chart: {
				plotBackgroundColor: undefined,
				plotBorderWidth: undefined,
				plotShadow: false,
				type: "column",
			},
			title: undefined,
			tooltip: {
				split: true,
			},
			xAxis: {
				gridLineWidth: 1,
				type: "datetime"
			},
			yAxis: [
				{
					min: 0,
					title: {
						text: "# of commits"
					}
				}
			],
			credits: {
				enabled: false
			},
			series: splineSeries
		};

		return options;
	}

	async function fetchData(): Promise<{
		numberOfCommit: ChartSeriesData
	} | undefined> {
		const scope = props.scope;
		const data = await teamsReportStore.abandonedWork(scope.startTime, scope.endTime, scope.timezone, scope.interval,
			scope.teamIds, scope.repositoryIds);
		return data;
	}

	function shouldFetchData(): boolean {
		return (!teamsReportStore.isLoadingAbandonedWorkMetric || !chartOptions);
	}

	useDeepCompareEffect(() => {
		let isMounted = true;
		async function fetchChartData() {
			const data = await fetchData();
			if (isMounted && data) {
				setChartOptions(buildChartOptions(data));
			}
		}

		if (shouldFetchData()) {
			// tslint:disable-next-line: no-floating-promises
			fetchChartData().then(() => {
				isMounted = false;
			});
		}
	}, [props]);

	return (
		<LoadingIndicator local={true} isActive={chartOptions === undefined}>
			{chartOptions && <HighchartsReact
				highcharts={Highcharts}
				options={chartOptions}
			/>}
		</LoadingIndicator>
	);
}

export default AbandonedWorkChart;
