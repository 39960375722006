import {
	IDashboardTeamsReport, DashboardTeamsReportExpandOption, DashboardTeamReportMetric
} from "@acumen/dashboard-common";
import { getData } from "../../../services/fetch-helpers";
import { MetricApiClient } from "./metric-api-client";
import { qsStringify } from "../../../services/url-routes-helper";

export const TEAMS_REPORT_ROUTE = "reports/teams";

export class TeamsReportApiClient extends MetricApiClient {
	public fetchReport = async (startTime: Date, endTime: Date,
		teamIds?: string[], gitRepositoryIds?: string[], jiraProjectIds?: string[],
		expand?: DashboardTeamsReportExpandOption[], metrics?: DashboardTeamReportMetric[]) => {
		const query: { [label: string]: string | string[] | number | Date } = {};

		query.startTime = startTime;
		query.endTime = endTime;
		if (teamIds) {
			query["teamIds[]"] = teamIds;
		}
		if (gitRepositoryIds) {
			query["repositories[]"] = gitRepositoryIds;
		}
		if (jiraProjectIds) {
			query["projects[]"] = jiraProjectIds;
		}
		if (expand) {
			query["expand[]"] = expand;
		}
		if (metrics && metrics.length > 0) {
			query["metrics[]"] = metrics;
		}

		return getData<IDashboardTeamsReport, any>(
			this.createCustomerEntityRoute(`${TEAMS_REPORT_ROUTE}?${qsStringify(query)}`),
			this.token
		);
	}
}
