import React from "react";

import Sidebar from "./navigation/sidebar";

const layoutFunction = ({ children }) => {
	return (
		<>
			<Sidebar />
			<div className="pusher">
				{children}
			</div>
		</>
	);
};

export default layoutFunction;
