import { useMemo } from "react";
import { CustomTableFilter, ResolvedCustomTableColumnProps } from "../types";

export interface UseFilteredItemsProps<T extends object> {
	columns: Array<ResolvedCustomTableColumnProps<T>>;
	items: T[];
	filters: Array<CustomTableFilter<T>>;
	onFiltersChange?: (filters: Array<CustomTableFilter<T>>) => void;
}

export const useFilteredItems = <T extends object>(props: UseFilteredItemsProps<T>) => {
	const { columns, items, filters, onFiltersChange } = props;

	return useMemo(() => {
		if (filters.length === 0) {
			return items;
		}

		return items.filter(item => filters.every(filter => {
			const column = columns.find(c => c.key === filter.key)!;
			const valueForComparison = column.compareBy(item, column, filter);

			if (filter.selectedValues.length === 0) {
				return true;
			}

			return filter.selectedValues.includes(valueForComparison);
		}));
	}, [columns, items, filters, onFiltersChange]);
};
