import * as React from "react";
import Select from "react-select";
import { BaseFormControl } from "react-bootstrap4-form-validation";

interface IMultiselectEvent {
	readonly value: string[] | string;
	readonly target: HTMLInputElement;
}

export interface IMultiSelectProps<T> {
	name: keyof T;
	options: IOption[];
	value?: string[] | string;
	onChange?: (e: IMultiselectEvent) => void;
	required?: boolean;
	errorMessage?: string;
	disabled?: boolean;
	isSingle?: boolean;
}

export interface IOption {
	readonly value: string;
	readonly label: string;
}

export default class ValidatedMultiSelect<T> extends BaseFormControl<IMultiSelectProps<T>> {
	public state = {
		value: String(this.props.value)
	};

	private inputRef: any = React.createRef();

	protected getInputRef = () => {
		return this.inputRef.current;
	}

	private handleChange = (selectResults: IOption[] | IOption) => {
		let results: string[] | string = [];
		if (selectResults) {
			if (Array.isArray(selectResults)) {
				results = selectResults.map(res => res.value);
			} else {
				results = selectResults.value;
			}
		}

		this.setState({ value: String(results) }, () => {
			this.checkError();

			if (this.props.onChange) {
				this.props.onChange({
					value: results,
					target: this.getInputRef()
				});
			}
		});
	}

	public render() {
		let values: string[] = [];
		if (this.props.value) {
			if (typeof (this.props.value) === "string") {
				values = this.props.value.split(",");
			} else {
				values = this.props.value;
			}
		}

		return (
			<>
				<Select
					name={`${this.props.name}_select`}
					closeMenuOnSelect={this.props.isSingle}
					value={values.map(value => {
						const option = this.props.options.find(opt => opt.value === value);
						if (option) {
							const { label } = option;
							return { value, label };
						}
						return undefined;
					})}
					isMulti={!this.props.isSingle}
					options={this.props.options}
					isDisabled={this.props.disabled}
					onChange={val => this.handleChange(val as (IOption[] | IOption))}
				/>
				{/* Since the 'Select' component is not a real input, hidden 'text' input is used to enable validation*/}
				<input
					type="text"
					ref={this.inputRef}
					required={this.props.required}
					name={this.props.name as string}
					className="form-control is-valid"
					value={this.state.value}
					style={{ display: "none" }}
					onChange={() => { return; }}
					disabled={this.props.disabled}
				/>
				{this.displayErrorMessage()}
				{this.displaySuccessMessage()}
			</>
		);
	}
}
