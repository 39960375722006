import { STRINGS } from "../localization";
import { HttpResponseError } from "./fetch-helpers";
import { HttpStatusCode } from "@acumen/dashboard-common";
import { toast } from "react-toastify";

export type IToastErrorMapping = {
	[key in HttpStatusCode]?: IToastErrorHandle;
};

export interface IToastErrorHandle {
	type: "success" | "info" | "warning" | "light" | "error" | "confirm" | "message";
	title: string;
	message: string;
}

export const DEFAULT_TOAST_HANDLE: IToastErrorHandle = {
	type: "error",
	title: STRINGS.OOPS_REQUEST_FAILED,
	message: STRINGS.PLEASE_TRY_AGAIN
};

export const HTTP_TOAST_HANDLE: IToastErrorMapping = {
	[HttpStatusCode.CLIENT_ERROR_UNAUTHORIZED]: {
		type: "error",
		title: STRINGS.OOPS_REQUEST_FAILED,
		message: STRINGS.MORE_INFO_ERROR_EXPIRED_TOKEN_MESSAGE
	},
	[HttpStatusCode.CLIENT_ERROR_BAD_REQUEST]: {
		type: "error",
		title: STRINGS.OOPS_REQUEST_FAILED,
		message: STRINGS.CLIENT_ERROR_BAD_REQUEST
	},
	[HttpStatusCode.SERVER_ERROR_GATEWAY_TIMEOUT]: {
		type: "error",
		title: STRINGS.OOPS_REQUEST_FAILED,
		message: STRINGS.REQUEST_TIMEOUT
	},
};

export function raiseHttpResponseError(error: HttpResponseError) {
	let toastHandler = HTTP_TOAST_HANDLE[error.status];
	if (!toastHandler) {
		toastHandler = DEFAULT_TOAST_HANDLE;
	}
	toast.error(`${toastHandler.title} - ${toastHandler.message}`, {
		autoClose: 3000,
		hideProgressBar: true
	});
	throw error;
}
