import { MakeOptional } from "@acumen/common";
import { AcumenMetricGroupType, AcumenTaskType, DevelopmentMethodology, IDashboardAcumenMetricDataResponse, MetricInterval, SprintType, SubtaskSprintStrategy, TaskEstimationMethod } from "@acumen/dashboard-common";
import Highcharts from "highcharts";
import _ from "lodash";
import { action, observable } from "mobx";
import apiContextProvider from "../../services/api-context-provider";
import { MetricApiClient, METRIC_ROUTE } from "../services/crud/metric-api-client";
import { ISprintRetroRequestScope } from "../services/crud/sprint-retro-api-client";
import { FetchLatestRequest } from "../../services/fetch-helpers";
import BaseStore from "./base-store";
import {
	createBoardOwnedByTeamDimensions, createDimensionsFromScope,
	IssueMetricLabel
} from "./sprint-retro-store";

export interface IPlannedVsActualRequestScope extends MakeOptional<ISprintRetroRequestScope, "teamId"> {
	startDate: Date;
	endDate: Date;
	boardIds: string[];
}

export interface IPlannedVsActualChartData {
	splineData?: IPlanVsActualChartSeries;
	barsData: IPlanVsActualChartSeries[];
}

export interface IPlanVsActualChartSeries {
	name: string;
	data: Array<[string, number | string]>;
}

export default class PlanVsActualStore extends BaseStore<{}> {
	private readonly metricApiClient: MetricApiClient = new MetricApiClient(apiContextProvider);

	@observable
	planVsActualData: IPlannedVsActualChartData | undefined = undefined;

	@observable
	currentSprintPlanned: number | undefined = undefined;

	@observable
	currentSprintActual: number | undefined = undefined;

	@observable
	currentSprintPlannedVsActualPercentage: number | null | undefined = undefined;

	@observable
	public isLoadingPlanVsActualData: boolean = false;

	@action.bound
	resetPlannedVsActualData() {
		this.isLoadingPlanVsActualData = false;
		this.planVsActualData = undefined;
		this.currentSprintPlanned = undefined;
		this.currentSprintActual = undefined;
		this.currentSprintPlannedVsActualPercentage = undefined;
	}

	fetchLatestActualSP = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(METRIC_ROUTE + "/ActualSP");
	fetchLatestPlannedSP = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(METRIC_ROUTE + "/PlannedSP");
	fetchLatestActualTE = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(METRIC_ROUTE + "/ActualTE");
	fetchLatestPlannedTE = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(METRIC_ROUTE + "/PlannedTE");
	fetchLatestActualNE = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(METRIC_ROUTE + "/ActualNE");
	fetchLatestPlannedNE = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(METRIC_ROUTE + "/PlannedNE");
	@action.bound
	async getPlanVsActualChartData(
		scope: IPlannedVsActualRequestScope,
		timezone: string | undefined = Highcharts.getOptions().time?.timezone) {

		if (!timezone) {
			return;
		}

		this.isLoadingPlanVsActualData = true;

		const plannedDimensions = createDimensionsFromScope(scope, false, true);
		const actualDimensions = createDimensionsFromScope(scope, false);
		const { subtaskDimensions, subtaskNegationDimensions } = createSubtaskStrategyDimensions(scope.subtaskStrategy);
		const { boardOwnedByTeamDimensions } = createBoardOwnedByTeamDimensions(scope);
		const sprintBoardIdDimensions = createSprintBoardIdDimensions(scope);

		const mergedDimensions = [...boardOwnedByTeamDimensions, ...subtaskDimensions, ...sprintBoardIdDimensions];
		const mergedDimensionsForActual = [...mergedDimensions, ...actualDimensions];
		const mergedDimensionsForPlanned = [...mergedDimensions, ...plannedDimensions];
		const mergedNegations = [...subtaskNegationDimensions];
		this.planVsActualData = undefined;

		const barsMetrics: any[] = [];
		const issueMetricLabel = scope.sprint.type === SprintType.Sprint
			? IssueMetricLabel.Sprint
			: IssueMetricLabel.DevCycle;
		const issueMetricInterval = scope.sprint.type === SprintType.Sprint
			? MetricInterval.SPRINT
			: MetricInterval.DEV_CYCLE;

		let plannedMetric: AcumenMetricGroupType;
		let actualMetric: AcumenMetricGroupType;

		switch (scope.estimationMethod) {
			case TaskEstimationMethod.StoryPoints:
				actualMetric = AcumenMetricGroupType.JiraIssuesActualEffortStoryPoints;
				barsMetrics.push(this.fetchLatestActualSP.fetchLatest(this.metricApiClient.fetchMetric(
					actualMetric, issueMetricLabel,
					issueMetricInterval, timezone, scope.startDate, scope.endDate,
					mergedDimensionsForActual, undefined, mergedNegations)
				));
				plannedMetric = AcumenMetricGroupType.JiraIssuesPlannedEffortStoryPoints;
				barsMetrics.push(this.fetchLatestPlannedSP.fetchLatest(this.metricApiClient.fetchMetric(
					plannedMetric, issueMetricLabel, issueMetricInterval,
					timezone, scope.startDate, scope.endDate, mergedDimensionsForPlanned, undefined, mergedNegations)
				));
				break;
			case TaskEstimationMethod.TimeBased:
				actualMetric = AcumenMetricGroupType.JiraIssuesActualEffortTimeEstimate;
				barsMetrics.push(this.fetchLatestActualTE.fetchLatest(this.metricApiClient.fetchMetric(
					actualMetric, issueMetricLabel, issueMetricInterval, timezone, scope.startDate,
					scope.endDate, mergedDimensionsForActual, undefined, mergedNegations)
				));
				plannedMetric = AcumenMetricGroupType.JiraIssuesPlannedEffortTimeEstimate;
				barsMetrics.push(this.fetchLatestPlannedTE.fetchLatest(this.metricApiClient.fetchMetric(
					plannedMetric, issueMetricLabel, issueMetricInterval, timezone, scope.startDate,
					scope.endDate, mergedDimensionsForPlanned, undefined, mergedNegations)
				));
				break;
			case TaskEstimationMethod.None:
				actualMetric = AcumenMetricGroupType.JiraIssuesActualEffortNoneEstimation;
				barsMetrics.push(this.fetchLatestActualNE.fetchLatest(this.metricApiClient.fetchMetric(
					actualMetric, issueMetricLabel, issueMetricInterval, timezone, scope.startDate,
					scope.endDate, mergedDimensionsForActual, undefined, mergedNegations)
				));
				plannedMetric = AcumenMetricGroupType.JiraIssuesPlannedEffortNoneEstimation;
				barsMetrics.push(this.fetchLatestPlannedNE.fetchLatest(this.metricApiClient.fetchMetric(
					plannedMetric, issueMetricLabel, issueMetricInterval, timezone, scope.startDate,
					scope.endDate, mergedDimensionsForPlanned, undefined, mergedNegations)
				));
				break;
		}

		const barsSeries = await Promise.all(barsMetrics).then(all => {
			return _.compact(all.map(result => {
				if (!result?.data) {
					return null;
				}
				const values = result.data.values.val;
				const data: Array<[string, number]> = _.compact(Object.keys(values).map((sprintId => {
					let value = _.toNumber(values[sprintId]);
					if (isNaN(value)) {
						value = 0;
					}

					if (sprintId === scope.sprint.id) {
						if (result.data.metric === plannedMetric) {
							this.currentSprintPlanned = value;
						} else if (result.data.metric === actualMetric) {
							this.currentSprintActual = value;
						}
					}
					return [sprintId, value];
				})));

				return {
					name: result.data.metric,
					data,
				};
			}));
		});

		this.planVsActualData = {
			barsData: barsSeries
		};

		this.currentSprintPlannedVsActualPercentage = this.calculatePlannedVsActual();
		this.isLoadingPlanVsActualData = false;
	}

	calculatePlannedVsActual = () => {
		if (!this.currentSprintPlanned || this.currentSprintActual === undefined) {
			return null;
		}

		return this.currentSprintActual / this.currentSprintPlanned * 100;
	}
}

function createSprintBoardIdDimensions(scope: IPlannedVsActualRequestScope) {
	if (!scope.boardIds || scope.boardIds.length === 0 || scope.developmentMethodology === DevelopmentMethodology.Kanban) {
		return [];
	}
	return [`sprint_board_id=${scope.boardIds.join(",")}`];
}

export function createSubtaskStrategyDimensions(subtaskStrategy: SubtaskSprintStrategy) {
	const subtaskDimensions = [];
	const subtaskNegationDimensions = [];

	switch (subtaskStrategy) {
		case SubtaskSprintStrategy.IGNORE_NONE:
			break;
		case SubtaskSprintStrategy.IGNORE_PARENTS:
			subtaskDimensions.push("has_subtasks=false");
			break;
		case SubtaskSprintStrategy.IGNORE_SUBTASKS:
			subtaskNegationDimensions.push(`acumen_task_type=${AcumenTaskType["Sub-Task"]}`);
			break;
	}

	return { subtaskDimensions, subtaskNegationDimensions };
}
