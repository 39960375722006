export enum TokenType {
	Auth0 = "Auth0",
	AcumenToken = "AcumenToken"
}

interface IAPIContext {
	customerId: string;
	token: string;
	tokenType: TokenType;
}

export class APIContextProvider {
	constructor(private _apiContext?: IAPIContext) {
	}

	public getContext(): IAPIContext {
		if (!this._apiContext) {
			throw new Error("Context was attempted to be fetched but it wasn't set yet");
		}

		return this._apiContext;
	}

	public setContext(partialContext: Partial<IAPIContext>) {
		if (this._apiContext) {
			this._apiContext = { ...{tokenType: TokenType.Auth0}, ...this._apiContext, ...partialContext };
		} else {
			this._apiContext = { ...{tokenType: TokenType.Auth0}, ...partialContext} as unknown as IAPIContext;
		}
	}
}

const apiContextProvider: APIContextProvider = new APIContextProvider();
export default apiContextProvider;
