import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export interface MetricChartProps {
	data: number[];
	color: string;
}

export const MetricChart = (props: MetricChartProps) => {
	const { data, color } = props;
	const options: Highcharts.Options = {
		title: {
			text: "",
		},
		yAxis: {
			visible: false,
		},
		xAxis: {
			visible: false,
		},
		legend: {
			enabled: false,
		},
		tooltip: {
			enabled: false,
		},
		plotOptions: {
			series: {
				marker: {
					enabled: false
				},
				label: {
					enabled: false
				},
				states: {
					hover: {
						enabled: false
					}
				}
			}
		},
		credits: {
			enabled: false
		},
		chart: {
			type: "line",
			width: 97,
			height: 60
		},
		series: [{
			type: "line",
			data,
			color
		}]
	};

	return (
		<HighchartsReact
			highcharts={Highcharts}
			options={options}
		/>
	);
};
