import { useEffect } from "react";
import _ from "lodash";
import { useAnalytics } from "use-analytics";
import { useLocation } from "react-router-dom";
import "autotrack/lib/plugins/event-tracker";

// eslint-disable-next-line import/no-anonymous-default-export
export default (authUser) => {
	const { page, identify } = useAnalytics();
	const location = useLocation();

	useEffect(() => {
		page();
	}, [location]);

	useEffect(() => {
		async function setAnalyticsIdentity() {
			const currentUser = authUser;
			if (!currentUser) {
				return;
			}
			// eslint-disable-next-line no-undef
			ga("require", "eventTracker");
			await identify(currentUser.id, {
				customerId: currentUser.customerId,
				userId: currentUser.id,
				userName: _.compact([currentUser.firstName, currentUser.surname]).join(
					" "
				),
			});
		}
		setAnalyticsIdentity();
	}, [authUser]);
};
