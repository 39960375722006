import React, { FormEvent } from "react";
import IntegrationFlowBase, { IProps as IIntegrationFlowBaseProps } from "./integration-flow-base";
import IntegrationProviders from "../integration-providers";
import { Transition } from "semantic-ui-react";
import { Button, Carousel, Col, Container, Form, Image } from "react-bootstrap";
import { STRINGS } from "../../../../../../../localization";
import { observer } from "mobx-react";
import { IIntegrationsStore } from "../../../../../../mobx-stores/old-integrations-store";
import { DashboardIntegrationType } from "@acumen/dashboard-common";

export interface IProps extends IIntegrationFlowBaseProps {
	oldIntegrationsStore: IIntegrationsStore;
}

enum GitHubIntegrationFlowSteps {
	InstallStep,
	EnterInstallIdStep,
	PendingLinkingStep,
	SuccessLinkingStep
}

@observer
export default class GitHubIntegrationFlow extends IntegrationFlowBase<IProps> {
	protected initialState = {
		internalStep: GitHubIntegrationFlowSteps.InstallStep,
		formValidated: false,
		inputInstallId: 0,
		shakeButton: false
	};

	public state = {...this.initialState};

	protected async initFlow() {
		const { oldIntegrationsStore, onInit } = this.props;
		const { integrationWizard } = oldIntegrationsStore;

		await oldIntegrationsStore.createGitHubInstallLink();

		const { installLink } = integrationWizard;

		if (installLink) {
			onInit(STRINGS.INTEGRATION_GITHUB_FLOW_TITLE);
		}
	}

	private onInstallClick = (): void => {
		const { oldIntegrationsStore } = this.props;
		const { integrationWizard } = oldIntegrationsStore;
		const { installLink } = integrationWizard;

		window.open(installLink);

		this.setState({internalStep: GitHubIntegrationFlowSteps.EnterInstallIdStep});
	}

	private onRedirectedUrlChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const url: string = e.target.value;

		if (!url) {
			return;
		}

		const urlObj = new URL(url);

		let inputInstallId: string | null = urlObj.searchParams.get("installation_id");

		if (!inputInstallId) {
			const match = /\/settings\/installations\/(.+)/.exec(url);
			if (match) {
				inputInstallId = match[1];
			} else {
				inputInstallId = "";
			}
		}

		this.setState({ inputInstallId });
	}

	private onInstallIdSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const { inputInstallId } = this.state;
		const { oldIntegrationsStore } = this.props;
		const { integrationWizard } = oldIntegrationsStore;
		const { integrationId } = integrationWizard;

		if (!inputInstallId) {
			e.stopPropagation();
			this.setState({formValidated: true, shakeButton: true});
			return;
		}

		this.setState({internalStep: GitHubIntegrationFlowSteps.PendingLinkingStep});

		await oldIntegrationsStore.updateGitHubInstallId({integrationId, gitInstallId: inputInstallId});

		if (integrationWizard.gitInstallId) {
			this.setState({internalStep: GitHubIntegrationFlowSteps.SuccessLinkingStep});
		}
	}

	private renderInstallStep() {
		return (
			<>
				<p>
					{STRINGS.INTEGRATION_GITHUB_FLOW_INSTALL_STEP_TEXT}
				</p>
				<br/>
				<p>
					<b><u>{STRINGS.INTEGRATION_GITHUB_FLOW_INSTALL_STEP_NOTE}</u></b>
					{" "}{STRINGS.INTEGRATION_GITHUB_FLOW_INSTALL_STEP_NOTE_TEXT}
				</p>
				<br/>
				<Button className="btn-rounded install-button" size="lg" onClick={this.onInstallClick}>
					{STRINGS.INTEGRATION_GITHUB_FLOW_INSTALL_STEP_BUTTON}
				</Button>
			</>
		);
	}

	private renderEnterInstallIdStep() {
		const { formValidated, shakeButton } = this.state;

		return (
			<>
				<p>
					{STRINGS.INTEGRATION_GITHUB_FLOW_ENTER_DATA_STEP_TEXT}
				</p>
				<br/><br/>
				<Form
					noValidate={true}
					validated={formValidated}
					onSubmit={this.onInstallIdSubmit}
				>
					<Form.Row>
						<Form.Group as={Col} md="4">
							<Form.Label>{STRINGS.INTEGRATION_GITHUB_FLOW_ENTER_DATA_STEP_REDIRECTED_URL}</Form.Label>
							<Form.Control
								className="integration-flow-step-text-input"
								type="url"
								placeholder={STRINGS.INTEGRATION_GITHUB_FLOW_ENTER_DATA_STEP_PASTE_URL_PLACEHOLDER}
								required={true}
								onChange={this.onRedirectedUrlChange}
								autoComplete="off"
							/>
							<Form.Control.Feedback type="invalid">
								{STRINGS.INTEGRATION_GITHUB_FLOW_ENTER_DATA_URL_STEP_VALIDATION}
							</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>
					<br/>
					<Form.Row>
						<Transition
							visible={shakeButton}
							animation="shake"
							duration={800}
							onComplete={() => this.setState({shakeButton: false})}
							mountOnShow={false}
						>
							<Button className="btn-rounded install-button" size="lg" type="submit">
								{STRINGS.INTEGRATION_GITHUB_FLOW_ENTER_DATA_URL_STEP_SUBMIT}
							</Button>
						</Transition>
					</Form.Row>
				</Form>
			</>
		);
	}

	public render() {
		const { internalStep } = this.state;
		const provider = IntegrationProviders.find(p => p.integrationType === DashboardIntegrationType.GitHub);

		if (!provider) { return null; }

		return (
			<Container className="integration-flow-step-root">
				<div className="integration-flow-step-logo-header">
					<Image src={provider.logo} alt={provider.title} />
					<hr/>
				</div>
				<div>
					<Carousel activeIndex={internalStep} controls={false} indicators={false} onSelect={(() => false)}>
						<Carousel.Item>
							{this.renderInstallStep()}
						</Carousel.Item>
						<Carousel.Item>
							{this.renderEnterInstallIdStep()}
						</Carousel.Item>
						<Carousel.Item>
							{this.renderPendingStep("Linking GitHub and Acumen...")}
						</Carousel.Item>
						<Carousel.Item>
							{this.renderSuccessStep("Your GitHub organization is connected to Acumen")}
						</Carousel.Item>
					</Carousel>
				</div>
				<br/>
			</Container>
		);
	}
}
