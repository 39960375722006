import * as React from "react";
const RevoteIssue = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		aria-hidden="true"
		viewBox="0 0 21 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M2.718 13.741a.75.75 0 1 0-1.415.499l1.415-.499Zm2.794 4.751-.415.624.415-.624Zm10.801-.622.485.573-.485-.573Zm2.401-10.548.684-.307-.684.307Zm-3.86-4.199.363-.656-.363.656ZM9.245 2.088l.105.742-.105-.742ZM1.5 7H.75c0 .414.336.75.75.75V7Zm.75-6a.75.75 0 0 0-1.5 0h1.5ZM7.5 7.75a.75.75 0 0 0 0-1.5v1.5Zm-6.197 6.49a9.75 9.75 0 0 0 3.794 4.876l.83-1.248a8.25 8.25 0 0 1-3.21-4.127l-1.414.499Zm3.794 4.876a9.751 9.751 0 0 0 5.963 1.618l-.087-1.498a8.252 8.252 0 0 1-5.045-1.368l-.831 1.248Zm5.963 1.618a9.752 9.752 0 0 0 5.738-2.291l-.97-1.145a8.252 8.252 0 0 1-4.855 1.938l.087 1.498Zm5.738-2.291a9.75 9.75 0 0 0 3.209-5.28l-1.463-.332a8.25 8.25 0 0 1-2.715 4.467l.969 1.145Zm3.209-5.28a9.75 9.75 0 0 0-.609-6.148l-1.369.613a8.25 8.25 0 0 1 .515 5.203l1.463.333Zm-.609-6.148a9.75 9.75 0 0 0-4.181-4.548L14.49 3.78a8.25 8.25 0 0 1 3.538 3.848l1.37-.613Zm-4.181-4.548A9.751 9.751 0 0 0 9.14 1.345L9.35 2.83a8.251 8.251 0 0 1 5.141.95l.726-1.313ZM9.14 1.345c-3.547.5-5.907 3.091-8.142 5.097l1.002 1.116C4.42 5.387 6.372 3.25 9.35 2.83l-.21-1.485ZM.75 1v6h1.5V1H.75Zm.75 6.75h6v-1.5h-6v1.5Z"
			fill="#1B3F7B"
		/>
	</svg>
);
export default RevoteIssue;
