import React, { forwardRef } from "react";
import { createPortal } from "react-dom";
import classNames from "classnames";
import TriggerOnClickOutside from "../../../../components/ux-effects/trigger-on-click-outside";
import CloseIcon from "../../../../components/svg-assets/close-icon.svg";
import { TeamViewModel } from "../../../team-comparison/types";
import "./team-details-side-panel.scss";
import { TeamDetails } from "../team-details/team-details";
import { TeamPerformanceBreakdown } from "../team-performance-breakdown/team-performance-breakdown";

export interface TeamDetailsSidePanelProps {
	team: TeamViewModel | null;
	isOpen: boolean;
	onClose: () => void;
}

// tslint:disable-next-line:variable-name
export const TeamDetailsSidePanel = forwardRef((
	props: TeamDetailsSidePanelProps,
	ref: React.Ref<HTMLDivElement>
) => {
	const { team, isOpen, onClose } = props;
	const className = classNames("team-badge-breakdown-drawer", isOpen && "open");

	return (
		createPortal((
			<TriggerOnClickOutside excludedClassName="team-info-cell-content" onTrigger={onClose}>
				<div ref={ref} className={className}>
					<div className="team-badge-breakdown-drawer-header">
						<button className="team-badge-breakdown-drawer-header-close-button" onClick={onClose}>
							<img src={CloseIcon} alt="Close"/>
						</button>
					</div>

					{team && (
						<div className="team-badge-breakdown-drawer-content">
							<TeamDetails team={team}/>

							<div className="team-performance-breakdown-container">
								<TeamPerformanceBreakdown team={team}/>
							</div>
						</div>
					)}
				</div>
			</TriggerOnClickOutside>
		), document.body)
	);
});
