import React, { useCallback, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { CustomSelectOption, CustomSelectValue } from "./types";
import { CustomSelectDropdown } from "./custom-select/custom-select-dropdown";
import { CustomSelectTrigger } from "./custom-select-trigger/custom-select-trigger";
import "./custom-select.scss";

export interface CustomSelectProps<T extends CustomSelectValue> {
	className?: string;
	value?: T[] | null;
	placeholder?: React.ReactNode;
	options?: Array<CustomSelectOption<T>>;
	trigger?: React.ReactNode;
	triggerClass?: string;
	labelClass?: string;
	arrowClass?: string;
	multiple?: boolean;
	shouldGroup?: boolean;
	showSelectAllOption?: boolean;
	selectAllOptionText?: string;
	assumeAllSelectedByDefault?: boolean;
	onChange?: (value: T[], groupName?: string) => void;
}

export const CustomSelect = <T extends CustomSelectValue>(props: CustomSelectProps<T>) => {
	const {
		className,
		value,
		placeholder,
		options = [],
		trigger,
		triggerClass,
		labelClass,
		arrowClass,
		multiple,
		showSelectAllOption = false,
		selectAllOptionText = "All",
		assumeAllSelectedByDefault = false,
		onChange,
		shouldGroup
	} = props;
	const values = useMemo(() => value ?? [], [value]);
	const [isOpen, setIsOpen] = useState(false);
	const selectedOptions = useMemo(() => options.filter(option => option.value !== null && values.includes(option.value)), [values, options]);
	const isAllSelected = selectedOptions.length === options.length
		|| (selectedOptions.length === 0 && assumeAllSelectedByDefault);
	const displayValue = useMemo(() => selectedOptions.length > 0
		? selectedOptions.map(option => option.label).join(", ")
		: (isAllSelected && showSelectAllOption ? selectAllOptionText : placeholder), [selectedOptions, placeholder]);
	const triggerContainerRef = useRef<HTMLDivElement | null>(null);
	const handleToggleSelectAll = useCallback(() => {
		onChange?.(isAllSelected ? [] : options.map(option => option.value).filter(v => v !== null) as T[]);
	}, [isAllSelected, onChange]);
	const handleTriggerClick = useCallback(() => setIsOpen(state => !state), [isOpen]);
	const handleClose = useCallback(() => setIsOpen(false), []);

	return (
		<div className={classNames(className, "custom-select")}>
			<div ref={triggerContainerRef} onMouseDown={handleTriggerClick}>
				{trigger || (
					<CustomSelectTrigger
						text={displayValue}
						triggerClass={triggerClass}
						labelClass={labelClass}
						arrowClass={arrowClass}
					/>
				)}
			</div>

			{isOpen && (
				<CustomSelectDropdown
					triggerRef={triggerContainerRef}
					options={options}
					value={values}
					multiple={multiple}
					showSelectAllOption={showSelectAllOption}
					selectAllOptionText={selectAllOptionText}
					isAllSelected={isAllSelected}
					onChange={onChange}
					onToggleSelectAll={handleToggleSelectAll}
					onClose={handleClose}
					shouldGroup={shouldGroup}
				/>
			)}
		</div>
	);
};
