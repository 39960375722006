import React from "react";
import classNames from "classnames";
import { Container, Carousel } from "react-bootstrap";
import { Transition } from "semantic-ui-react";
import { IDashboardIntegrationProvider } from "./integration-providers";
import Modal from "../../../../../components/modals/modal";
import SelectIntegrationProvider from "./select-integration-provider/select-integration-provider";
import { STRINGS } from "../../../../../../localization";
import { useStores } from "../../../../../mobx-stores";

import "./add-integration-pane.scss";
import { observer } from "mobx-react";

interface IProps {
	show: boolean;
	onDismiss: (created: boolean) => void;
}

function AddIntegrationPane(props: IProps) {
	const { oldIntegrationsStore } = useStores();
	const [providerSelectionKey, setProviderSelectionKey] = React.useState(0);
	const [currentStep, setCurrentStep] = React.useState(0);
	const [isLoading, setIsLoading] = React.useState(false);
	const [infoBoxText, setInfoBoxText] = React.useState("");
	const [integrationComponent, setIntegrationComponent] = React.useState<JSX.Element>();
	const [modalTitle, setModalTitle] = React.useState("Select an integration provider");

	const onIntegrationProviderSelect = (integrationProvider: IDashboardIntegrationProvider) => {
		setIsLoading(true);
		setInfoBoxText(STRINGS.INTEGRATION_FLOW_INIT_TEXT);

		if (integrationProvider.component) {
			setIntegrationComponent(integrationProvider.component({
				oldIntegrationsStore,
				onInit: (setTitle: string) => {
					setCurrentStep(1);
					setInfoBoxText("");
					if (setTitle) {
						setModalTitle(setTitle);
					}
				},
				onNewIntegration: () => {
					onDismiss(false);
				}
			}));
		}
	};

	const onDismiss = (hideModal?: boolean) => {
		const created: boolean = currentStep > 0;

		setProviderSelectionKey(providerSelectionKey + 1);
		setCurrentStep(0);
		setIsLoading(false);
		setIntegrationComponent(undefined);
		setInfoBoxText("");

		oldIntegrationsStore.resetIntegrationWizard();

		if (hideModal !== false) {
			props.onDismiss(created);
		}
	};

	return (
		<Modal
			size="lg"
			show={props.show}
			onHide={() => onDismiss()}
			headerText={modalTitle}
			scrollable={true}
			actionButtons={Boolean(infoBoxText) ? (
				<div className={classNames("action-buttons", {"info-box-shown": infoBoxText})}>
					<Transition visible={Boolean(infoBoxText)} animation="fade" duration={1000} mountOnShow={false}>
						<div className="show">
							<div className="info-box">
								<i className="feather icon-info blink" />
								{infoBoxText}
							</div>
						</div>
					</Transition>
				</div>
			) : undefined}
		>
			<Container>
				<Carousel activeIndex={currentStep} controls={false} indicators={false} onSelect={(() => false)}>
					<Carousel.Item>
						<SelectIntegrationProvider
							key={providerSelectionKey}
							onSelect={(provider) => onIntegrationProviderSelect(provider)}
							isLoading={isLoading}
						/>
					</Carousel.Item>
					<Carousel.Item>
						{integrationComponent}
					</Carousel.Item>
				</Carousel>
			</Container>
		</Modal>
	);
}

export default observer(AddIntegrationPane);
