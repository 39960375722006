import BaseStore, { IBaseStore, ILoadable } from "./base-store";
import { action, observable } from "mobx";
import apiContextProvider from "../../services/api-context-provider";
import { PullRequestsApiClient, PULL_REQUESTS_ROUTE } from "../services/crud/pull-requests-api-client";
import {
	IDashboardDetailedPullRequestResponse, DashboardPullRequestSortOption, DashboardSortOrder, AcumenPullRequestStatus,
	DashboardPullRequestStaticGroupingOption, DashboardPullRequestDynamicGroupingOption, DashboardPullRequestExpandOption
} from "@acumen/dashboard-common";
import { FetchLatestRequest } from "../../services/fetch-helpers";

export interface IPullRequestsState {
	allPullRequests: ILoadable<IDashboardDetailedPullRequestResponse>;
}

interface IFindPullRequestsOptions {
	sortField?: DashboardPullRequestSortOption;
	sortOrder?: DashboardSortOrder;
	filter: {
		teamId?: string;
		dataContributorIds?: string[];
		gitRepositories?: string[];
		statuses?: AcumenPullRequestStatus[];
		startTime?: Date;
		endTime?: Date;
		excludeDraft?: boolean;
		prIds?: string[];
	};
	staticGroupingLimit?: number;
	staticGrouping?: DashboardPullRequestStaticGroupingOption;
	dynamicGroupingLimit?: number;
	dynamicGrouping?: DashboardPullRequestDynamicGroupingOption;
	expand?: DashboardPullRequestExpandOption[];
}

export interface IPullRequestsStore extends IPullRequestsState, IBaseStore<IPullRequestsState> {
	fetchAllPullRequests: (options: IFindPullRequestsOptions) => Promise<IDashboardDetailedPullRequestResponse>;
	resetAllPullRequests: () => void;
}

const defaults = {
	allPullRequests: BaseStore.initLoadable({}),
};

export default class PullRequestsStore extends BaseStore<IPullRequestsState> implements IPullRequestsStore {
	private readonly apiClient: PullRequestsApiClient = new PullRequestsApiClient(apiContextProvider);

	@observable
	public allPullRequests: ILoadable<IDashboardDetailedPullRequestResponse> = defaults.allPullRequests;

	fetchLatestAllPullRequests = new FetchLatestRequest<IDashboardDetailedPullRequestResponse, void>(PULL_REQUESTS_ROUTE);
	@action.bound
	public async fetchAllPullRequests(options: IFindPullRequestsOptions): Promise<IDashboardDetailedPullRequestResponse> {
		this.allPullRequests.loading = true;
		const filter = options.filter;
		const result = await this.fetchLatestAllPullRequests.fetchLatest(this.apiClient.fetchPullRequests(filter.teamId, filter.dataContributorIds,
			filter.gitRepositories, filter.statuses, filter.excludeDraft, filter.prIds, options.staticGroupingLimit, options.staticGrouping,
			options.dynamicGroupingLimit, options.dynamicGrouping, options.filter.startTime, options.filter.endTime,
			options.sortField, options.sortOrder, options.expand));

		if (result) {
			const { data } = result;

			this.allPullRequests = {
				data,
				metadata: null,
				loaded: true,
				loading: false
			};
			return data as IDashboardDetailedPullRequestResponse;
		}

		return {
			pullRequests: {},
			total: 0
		};
	}

	@action.bound
	public resetAllPullRequests() {
		this.allPullRequests = defaults.allPullRequests;
	}
}
