import BaseStore, { IBaseStore, ILoadable } from "./base-store";
import { action, observable } from "mobx";
import apiContextProvider from "../../services/api-context-provider";
import { ITeamMembersRootParams, MEMBERS_SUB_ROUTE, TeamMembersApiClient } from "../services/crud/team-members-api-client";

import {
	IDashboardTeamMember,
	IDashboardResponse,
	IDashboardTeamMemberUpdatePartial,
	DashboardTeamMembershipType
} from "@acumen/dashboard-common";
import { FetchLatestRequest } from "../../services/fetch-helpers";

export interface ITeamMembersState {
	allTeamMembers: ILoadable<IDashboardTeamMember[]>;
	updatedTeamMembers: ILoadable<IDashboardTeamMember[]>;
	teamMembersForAllTeams: ILoadable<IDashboardTeamMember[]>;
}

export interface ICreateMembersData {
	memberIds: Array<{ id: string; membershipType: DashboardTeamMembershipType }>;
}

export interface IDeleteMembersData {
	memberIds: string[];
}

export interface ITeamMembersStore extends ITeamMembersState, IBaseStore<ITeamMembersState> {
	fetchAllTeamMembers: (teamId: string) => Promise<IDashboardTeamMember[] | undefined>;
	fetchTeamMembersForAllTeams: () => Promise<IDashboardTeamMember[]>;
	resetTeamMembersForAllTeams: () => void;
	deleteTeamMembers: (teamMemberIds: IDeleteMembersData, teamId: string) => Promise<void>;
	createTeamMembers: (teamMember: ICreateMembersData, teamId: string) => Promise<void>;
	updateTeamMember: (teamMember: IDashboardTeamMemberUpdatePartial) => Promise<void>;
	resetTeamMembers: () => void;
	setTeamMembers: (teamMembers: IDashboardTeamMember[]) => void;
	setUpdatedTeamMembers: (teamMembers: IDashboardTeamMember[]) => void;
	resetUpdateTeamMembers: () => void;
}

const defaults = {
	allTeamMembers: BaseStore.initLoadable([]),
	updatedTeamMembers: BaseStore.initLoadable([]),
	teamMembersForAllTeams: BaseStore.initLoadable([]),
};

export default class TeamMembersStore extends BaseStore<ITeamMembersState> implements ITeamMembersStore {
	private readonly apiClient: TeamMembersApiClient = new TeamMembersApiClient(apiContextProvider);

	@observable
	public allTeamMembers: ILoadable<IDashboardTeamMember[]> = defaults.allTeamMembers;
	@observable
	public updatedTeamMembers: ILoadable<IDashboardTeamMember[]> = defaults.updatedTeamMembers;
	@observable
	public teamMembersForAllTeams: ILoadable<IDashboardTeamMember[]> = defaults.teamMembersForAllTeams;

	fetchLatestAllTeamMembers = new FetchLatestRequest<IDashboardTeamMember[], ITeamMembersRootParams>(MEMBERS_SUB_ROUTE);
	@action.bound
	public async fetchAllTeamMembers(teamId: string) {
		this.allTeamMembers.loading = true;

		const result = await this.fetchLatestAllTeamMembers.fetchLatest(this.apiClient.findAll({ teamId }));

		if (result) {
			const { data } = result;

			this.allTeamMembers = {
				data: data as IDashboardTeamMember[],
				metadata: null,
				loaded: true,
				loading: false
			};
			return data;
		}
	}

	fetchLatestAllTeamMembersForAllTeams = new FetchLatestRequest<IDashboardTeamMember[], any>(MEMBERS_SUB_ROUTE);
	@action.bound
	public async fetchTeamMembersForAllTeams() {
		this.teamMembersForAllTeams.loading = true;

		const result = await this.fetchLatestAllTeamMembersForAllTeams.fetchLatest(this.apiClient.fetchAllTeamMembers());

		if (result) {
			const { data } = result;

			this.teamMembersForAllTeams = {
				data: data as IDashboardTeamMember[],
				metadata: null,
				loaded: true,
				loading: false
			};
			return data;
		}
		return [];
	}

	@action.bound
	public async createTeamMembers(teamMembers: ICreateMembersData, teamId: string) {
		if (teamMembers.memberIds.length === 0) {
			return;
		}

		let result: IDashboardResponse<IDashboardTeamMember[]> | null;

		this.updatedTeamMembers.loading = true;

		result = await this.apiClient.createMultiple(teamMembers, { teamId });

		if (result) {
			const { data } = result;

			this.updatedTeamMembers = {
				data: data as IDashboardTeamMember[],
				metadata: null,
				loaded: true,
				loading: false
			};

			this.allTeamMembers.data = [...this.allTeamMembers.data, ...this.updatedTeamMembers.data];
		}
	}

	@action.bound
	public async deleteTeamMembers(teamMemberIds: IDeleteMembersData, teamId: string) {
		if (teamMemberIds.memberIds.length === 0) {
			return;
		}
		this.updatedTeamMembers.loading = true;

		await this.apiClient.deleteMultiple(teamMemberIds, { teamId });

		this.allTeamMembers.data = this.allTeamMembers.data.filter(t => !teamMemberIds.memberIds.includes(t.id));

		this.updatedTeamMembers.loading = false;
	}

	@action.bound
	public async updateTeamMember(updateTeamMember: IDashboardTeamMemberUpdatePartial) {
		this.updatedTeamMembers.loading = true;

		const result = await this.apiClient.update(updateTeamMember);
		if (result) {
			const { data: updatedMember } = result;
			const teamMember = this.allTeamMembers.data.find(t => t.id === updatedMember.id);
			if (teamMember) {
				const idx = this.allTeamMembers.data.indexOf(teamMember);
				this.allTeamMembers.data.splice(idx, 1);

				this.allTeamMembers.data.push(updatedMember);
			}
		}

		this.updatedTeamMembers.loading = false;
	}

	@action.bound
	public resetTeamMembersForAllTeams() {
		this.teamMembersForAllTeams = defaults.teamMembersForAllTeams;
	}

	@action.bound
	public resetTeamMembers() {
		this.allTeamMembers = defaults.allTeamMembers;
	}

	@action.bound
	public setTeamMembers(teamMembers: IDashboardTeamMember[]) {
		this.allTeamMembers.data = teamMembers;
	}

	@action.bound
	public setUpdatedTeamMembers(data: IDashboardTeamMember[]) {
		this.updatedTeamMembers = {
			data,
			metadata: null,
			loaded: true,
			loading: false
		};
	}

	@action.bound
	public resetUpdateTeamMembers() {
		this.updatedTeamMembers = defaults.updatedTeamMembers;
	}
}
