import { round } from "@acumen/common";
import { WorkforceHealthMetrics } from "@acumen/database-types";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import QuadrantsChart, { ITeamsDisplayValues } from "v1/components/highchart-chart-components/quadrants-chart";
import Checkbox from "v2/components/checkbox/checkbox";
import { getShortEstimationMethodName } from "v2/helpers/formatting";
import { useStores } from "v2/mobx-stores";
import { WHF_METRICS_TO_COLUMNS } from "../../../workforce-health/dev-stat-columns";
import { IdentifierToMetricDict, MetricDictionary, TeamViewModel } from "../../types";
import "./team-comparison-quadrants.scss";

const DEFAULT_Y_VALUE = 0.5;

interface ITeamsPlotValues {
	[teamId: string]: {
		x: number;
		y: number;
	};
}

const calculatePointAxisValue = (
	teamMetricData: MetricDictionary, metricName: WorkforceHealthMetrics, divider: number,
	isIncreasePositive: boolean,
): number => {
	const res = divider === 0 ? 0 : teamMetricData[metricName].current / divider;
	return isIncreasePositive ? res : 1 - res;
};

export interface TeamComparisonQuadrantsProps {
	teams: TeamViewModel[];
	metricNames: WorkforceHealthMetrics[];
}

export const TeamComparisonQuadrants = (props: TeamComparisonQuadrantsProps) => {
	const { developerStatsStore } = useStores();
	const { teams, metricNames } = props;

	const [xAxisMetricName, setXAxisMetricName] = useState<WorkforceHealthMetrics | null>(metricNames[1]);
	const [yAxisMetricName, setYAxisMetricName] = useState<WorkforceHealthMetrics | null>(metricNames[0]);

	const [teamsPlotValues, setTeamsPlotValues] = useState<ITeamsPlotValues | null>(null);
	const [teamsDisplayValues, setTeamsDisplayValues] = useState<ITeamsDisplayValues | null>(null);

	const unitNameByEstimationMethod = getShortEstimationMethodName(
		developerStatsStore.developerBadgesData?.config.configs.devStats.estimationMethod
	);

	useEffect(() => {
		const _plotValues = {} as any as ITeamsPlotValues;
		const _displayValues = {} as any as ITeamsDisplayValues;

		if (!developerStatsStore.teamStats || !teams.length || !xAxisMetricName) {
			setTeamsPlotValues(_plotValues);
			setTeamsDisplayValues(_displayValues);
			return;
		}

		const teamStats: IdentifierToMetricDict = Object.values(developerStatsStore.teamStats)[0];

		const xMax = _.max(Object.values(teamStats).map(x => x[xAxisMetricName].current))!;
		const isXPositive = WHF_METRICS_TO_COLUMNS[xAxisMetricName].displayValues.isIncreasePositive;

		const yMax = yAxisMetricName ? _.max(Object.values(teamStats).map(y => y[yAxisMetricName].current))! : null;
		const isYPositive = yAxisMetricName
			? WHF_METRICS_TO_COLUMNS[yAxisMetricName].displayValues.isIncreasePositive
			: null;

		for (const [teamId, teamMetricData] of Object.entries(teamStats)) {
			_plotValues[teamId] = {
				x: calculatePointAxisValue(teamMetricData, xAxisMetricName, xMax, isXPositive),
				y: DEFAULT_Y_VALUE,
			};

			_displayValues[teamId] = {
				teamName: teams.find(x => x.info.id === teamId)!.info.name,

				x: round(teamMetricData[xAxisMetricName].current),
				xUnit: WHF_METRICS_TO_COLUMNS[xAxisMetricName].displayValues.unit || unitNameByEstimationMethod,

				y: null,
				yUnit: null,
			};

			if (yAxisMetricName) {
				Object.assign(_plotValues[teamId], {
					y: calculatePointAxisValue(teamMetricData, yAxisMetricName, yMax!, isYPositive!),
				});

				Object.assign(_displayValues[teamId], {
					y: round(teamMetricData[yAxisMetricName].current),
					yUnit: WHF_METRICS_TO_COLUMNS[yAxisMetricName].displayValues.unit || unitNameByEstimationMethod,
				});
			}

			setTeamsPlotValues(_plotValues);
			setTeamsDisplayValues(_displayValues);
		}
	}, [developerStatsStore.teamStats, xAxisMetricName, yAxisMetricName]);

	return (
		<div className="team-comparison-quadrants">
			{developerStatsStore.teamComparisonSelectedMetrics && (
				<div className="quadrants-metric-selection-container">
					<div className="quadrants-metric-selection-header">
						Choose <b>2 metrics</b> to see how your teams perform compared to one another
					</div>

					<div className="checkbox-container">
						{metricNames.map((metricName: WorkforceHealthMetrics) => {
							return (
								<div key={metricName}>
									<Checkbox
										name={metricName}
										className="checkbox-background-gray checked-text-bold"
										label={WHF_METRICS_TO_COLUMNS[metricName].displayValues.title}
										onChange={() => {
											if (!xAxisMetricName) {
												setXAxisMetricName(metricName);
												return;
											}

											if (yAxisMetricName) {
												setXAxisMetricName([xAxisMetricName, yAxisMetricName].filter(x => x !== metricName)[0]);
												setYAxisMetricName(null);
												return;
											}

											if (metricName === xAxisMetricName) {
												setXAxisMetricName(null);
												return;
											}

											setYAxisMetricName(metricName);
										}}
										checked={[xAxisMetricName, yAxisMetricName].includes(metricName)}
										disabled={!!(xAxisMetricName && yAxisMetricName)
											&& !([xAxisMetricName, yAxisMetricName].includes(metricName))}
									/>
								</div>
							);
						})}
					</div>
				</div>
			)}

			<div className="quadrants-chart-container">
				{teamsPlotValues && teamsDisplayValues && (
					<QuadrantsChart
						xAxisMetricName={xAxisMetricName
							? WHF_METRICS_TO_COLUMNS[xAxisMetricName].displayValues.title
							: null
						}
						yAxisMetricName={yAxisMetricName
							? WHF_METRICS_TO_COLUMNS[yAxisMetricName].displayValues.title
							: null
						}
						series={Object.entries(teamsPlotValues).map(([teamId, teamPlotData]) => {
							return {
								name: teamId,
								data: [teamPlotData],
							};
						})}
						tooltipFormatter={(displayValues: Record<string, any>) => {
							const title = `<strong>${displayValues.teamName}</strong>`;

							const xDescription = `${WHF_METRICS_TO_COLUMNS[xAxisMetricName!].displayValues.title}:
								<b>${displayValues.x}</b> ${displayValues.xUnit}`;

							const yDescription = displayValues.y !== null
								? `${WHF_METRICS_TO_COLUMNS[yAxisMetricName!].displayValues.title}:
									<b>${displayValues.y}</b> ${displayValues.yUnit}<br>`
								: "";

							const res = `${title}<br><br>${yDescription}${xDescription}`;
							return res;
						}}
						teamsDisplayValues={teamsDisplayValues}
					/>
				)}
			</div>
		</div>
	);
};
