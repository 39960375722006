import React, { useEffect, useState } from "react";
import { DashboardIntegrationType, IDashboardIntegration, IntegrationStatus } from "@acumen/dashboard-common";
import IntegrationFlowStep from "./integration-flow-step";
import { useStores } from "../../../../mobx-stores";
import { IIntegrationFlow, NEW_INTEGRATION_URL, StepsActionType } from "../../../../mobx-stores/new-integrations-store";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { Icon, Step } from "semantic-ui-react";
import JiraIntegrationFlow from "./jira-custom-integration-flow";
import classNames from "classnames";

// tslint:disable-next-line: variable-name
const IntegrationFlow = (props: {
	editIntegrationId?: string;
	onClose?: () => void;
}) => {
	const { integrationsStore: {
		currentIntegrationType,
		setIntegrationType,
		currentIntegrationFlow,
		getIntegrationGuide,
		jiraCloudIntegrationFlow,
		jiraServerIntegrationFlow,
		fetchAllIntegrations
	}
	} = useStores();
	const history = useHistory();
	const [currentStepNumber, setCurrentStepNumber] = useState<number>(-1);
	const [integrationId, setIntegrationId] = useState<string | undefined>(undefined);
	const [message, setMessage] = useState<{ msg: string, isError: boolean } | undefined>(undefined);
	const [installLink, setInstallLink] = useState<string | undefined>(undefined);
	const [userText, setUserText] = useState<string | undefined>(undefined);
	const { editIntegrationId, onClose } = { ...props };

	const clearState = () => {
		setCurrentStepNumber(0);
		setMessage(undefined);
		setIntegrationType(undefined);
		setIntegrationId(undefined);
		if (onClose) {
			onClose();
		}
	};

	useEffect(() => {
		if (editIntegrationId) {
			// tslint:disable-next-line: no-floating-promises
			getIntegrationGuide(editIntegrationId).then(res => {
				if (!res) {
					setCurrentStepNumber(0);
					setMessage({ msg: "Sorry. We could not restore the integration, please start a new one.", isError: true });
					return;
				}
				if (res.installLink) {
					setInstallLink(res.installLink);
				} else if (res.appLink) {
					const wasItInstallLink = res.integrationAuthUrl ?? res?.guide ? res?.guide?.appLinkInternalUrl : null;
					if (wasItInstallLink) {
						setInstallLink(wasItInstallLink);
					}
					setUserText(res.appLink);
				}
				setIntegrationId(editIntegrationId);
				setCurrentStepNumber(1);
			});
		} else {
			setCurrentStepNumber(0);
		}
	}, []);

	const getOnNext = () => {
		const currentStep = currentIntegrationFlow?.steps[currentStepNumber];
		switch (currentStep?.actionType) {
			case StepsActionType.FetchInstallData:
				return () => {
					setMessage(undefined);
					if (integrationId === undefined) {
						currentStep.action({}).then((res: any) => {
							setIntegrationId("");
							if (res) {
								setIntegrationId(res.integrationId);
								setInstallLink(res.installLink);
								setCurrentStepNumber(currentStepNumber + 1);
							} else {
								setIntegrationId(undefined);
								setMessage({ msg: "Could not fetch install link", isError: true });
							}
						});
					}
				};
			case StepsActionType.UserInput:
				return (userInput?: string) => {
					setMessage(undefined);
					currentStep.action({ userInput, integrationId }).then((res: any) => {
						if (!res || res?.error) {
							setMessage({ msg: res ? res.error : "Something went wrong", isError: true });
							return;
						}
						const wasItInstallLink = res.integrationAuthUrl ?? res?.guide ? res?.guide?.appLinkInternalUrl : null;
						if (wasItInstallLink) {
							setInstallLink(wasItInstallLink);
						}
						setUserText(res.appLink);
						setIntegrationId(res.integrationId);
						if (res.moveStep) {
							setCurrentStepNumber(currentStepNumber + 1);
						}
					});
				};
			case StepsActionType.GoToRedirect:
				return () => {
					setMessage(undefined);
					if (installLink) {
						const newWin = window.open(installLink, "installLink");
						const winInterval = setInterval(() => {
							if (newWin !== null && newWin.closed) {
								clearInterval(winInterval);
								// tslint:disable-next-line: no-floating-promises
								fetchAllIntegrations().then(allIntegrations => {
									const targetIntegration = allIntegrations?.find((int: IDashboardIntegration) => int.id === integrationId);
									if (targetIntegration?.state === IntegrationStatus.ACTIVE) {
										setCurrentStepNumber(currentIntegrationFlow.steps.length - 1);
									}
								});
							}
						}, 1000);
						setMessage({ msg: `We opened a <a target="_blank" rel="noopener noreferrer" href=${installLink}> new window</a> for this integration`, isError: false });
						setCurrentStepNumber(currentStepNumber + 1);
					} else {
						setMessage({ msg: "Could not redirect to install link", isError: true });
					}
				};
			case StepsActionType.VerifyLink:
				return () => {
					setUserText(undefined);
					currentStep.action({ integrationId }).then((res: any) => {
						if (res && res.integrationAuthUrl) {
							setInstallLink(res.integrationAuthUrl);
							setMessage({ msg: `Check out new window or go to: <a target="_blank" rel="noopener noreferrer" href=${installLink}> ${installLink} </a>`, isError: false });
							setCurrentStepNumber(currentStepNumber + 1);
						} else {
							setMessage({ msg: `could not validate the link`, isError: true });
						}
						return res;
					});
				};
			case StepsActionType.Success:
				return () => { clearState(); history.push(`/${NEW_INTEGRATION_URL}/add`); };
			default:
				return () => { setCurrentStepNumber(currentStepNumber + 1); };
		}
	};

	const getNewIntegrationComponent = () => {
		if (!currentIntegrationType) {
			return (
				<IntegrationFlowStep
					currentStepNumber={0}
					onNext={(integrationType) => {
						setIntegrationType(integrationType as DashboardIntegrationType);
					}}
					message={message}
					onClose={() => {
						clearState();
						history.push(`/${NEW_INTEGRATION_URL}`);
					}}

				/>
			);
		} else {
			switch (currentIntegrationType) {
				case DashboardIntegrationType.JIRAServer:
					return <JiraIntegrationFlow
						flow={jiraServerIntegrationFlow}
						integrationId={integrationId}
						setIntegrationId={setIntegrationId}
						currentStepNumber={currentStepNumber}
						setCurrentStepNumber={setCurrentStepNumber}
						onClose={() => {
							clearState();
							history.push(`/${NEW_INTEGRATION_URL}`);
						}}
						overrideUserText={userText}
						overrideInstallLink={installLink}
						clearState={clearState}
					/>;
				case DashboardIntegrationType.JIRACloud:
					return <JiraIntegrationFlow
						flow={jiraCloudIntegrationFlow}
						integrationId={integrationId}
						setIntegrationId={setIntegrationId}
						currentStepNumber={currentStepNumber}
						setCurrentStepNumber={setCurrentStepNumber}
						onClose={() => {
							clearState();
							history.push(`/${NEW_INTEGRATION_URL}`);
						}}
						overrideUserText={userText}
						overrideInstallLink={installLink}
						clearState={clearState}
					/>;
				default:
					return (
						<IntegrationFlowStep
							currentStepNumber={currentStepNumber}
							onNext={getOnNext()}
							message={message}
							onClose={() => {
								clearState();
								history.push(`/${NEW_INTEGRATION_URL}`);
							}}
							userText={userText}
							installLink={installLink}
						/>
					);
			}
		}
	};

	return (
		<div>
			{getNewIntegrationComponent()}
		</div>
	);
};
export default observer(IntegrationFlow);

export const flowStepsHeader = (completeFlow: IIntegrationFlow, currentStepNumber: number, isStepDone: boolean) => {
	const { iconSrc, name, steps, length } = { ...completeFlow };
	return (
		<Step.Group widths={length}>
			<Step className="steps-header">
				<span className="title-icon">
					<img src={iconSrc} alt={""} />
				</span>
				<Step.Content>
					<Step.Title>{name}</Step.Title>
				</Step.Content>
			</Step>
			{steps.map((step, i) => (
				step.isVisible &&
				<Step
					key={i}
					className={classNames({
						disabled: i > currentStepNumber,
						completed: (i < currentStepNumber || (i === currentStepNumber && isStepDone)),
						active: i === currentStepNumber
					})}
				>
					<Icon />
					<Step.Content>
						<Step.Title style={{ fontWeight: "inherit" }}>{step.name} </Step.Title>
					</Step.Content>
				</Step>
			))}
		</Step.Group>
	);
};
