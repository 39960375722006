import React, { useCallback } from "react";
import { Checkbox } from "semantic-ui-react";
import classNames from "classnames";

interface BaseDropdownOptionProps<T extends string | number> {
	value: T;
	label?: string;
	avatar: React.ReactNode;
	isSelected: boolean;
	onToggle: (id: T) => void;
}

const BaseDropdownOption = <T extends string | number>(props: BaseDropdownOptionProps<T>) => {
	const { value, label, avatar, isSelected, onToggle } = { ...props };
	const handleToggle = useCallback(() => onToggle(value), [value, onToggle]);
	const className = classNames("item", isSelected && "selected");

	return (
		<li className={className} onClick={handleToggle}>
			<Checkbox name="avatar" value={value} checked={isSelected}/>
			{avatar}

			{label && (
				<span className="avatar-selector-item-text">
					{label}
				</span>
			)}
		</li>
	);
};

export default BaseDropdownOption;
