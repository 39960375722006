import { IDashboardTask, SprintType, DashboardRetroReportTaskData, IDashboardTaskDetailed,
	SubtaskSprintStrategy, IDashboardTaskStatusChangeResponse
} from "@acumen/dashboard-common";
import React from "react";
import { observer } from "mobx-react";
import { Tab } from "semantic-ui-react";
import LoadingIndicator from "../../../components/loader";
import _ from "lodash";
import { useStores } from "../../../mobx-stores";
import { StatusType, TasksGroup } from "../work/task";
import { AcumenTaskType } from "@acumen/database-types";

interface IIssuesTable {
	title: string;
	setSelectedIssueInPanel: React.Dispatch<React.SetStateAction<IDashboardTask | undefined>>;
	pageError: boolean;
	subtaskStrategy?: SubtaskSprintStrategy;
	selectedIssueIdInPanel?: string;
	sprintType?: SprintType;
	issuesStatusDiff?: IDashboardTaskStatusChangeResponse;
}

// tslint:disable-next-line: variable-name
export const IssuesTable = observer((props: IIssuesTable) => {
	const {
		sprintRetroStore
	} = useStores();
	const { title, setSelectedIssueInPanel, selectedIssueIdInPanel, pageError, subtaskStrategy } = { ...props };
	const { issues, epicsTasks, estimationMethod } = sprintRetroStore;
	const { issueIdsByCategory } = sprintRetroStore;

	function toIDashboardTaskDetailed(issuesArray: DashboardRetroReportTaskData[]): IDashboardTaskDetailed[] {
		return issuesArray.map((issue) => {
			const partialObject = {
				// This is a partial object, we want the display panel to fetch missing items
				isDashboardTaskDetailed: false,
				estimation: issue.jiraEstimation ?? null,
				calculatedWorkStartMs: null,
				highlights: [],
				totalCommitCalculatedWorkSeconds: null,
				storyPoints: null,
				timeEstimateSeconds: null,
				tshirtSizeName: null,
				relatedPRs: [],
				percentileRank: undefined,
				removed: issueIdsByCategory?.removed.includes(issue.entityId),
				totalCalculatedWorkMs: null,
				cycleEndTimestamp: null
			};
			return { ...partialObject, ..._.omit(issue, "percentileRank") };
		});
	}

	function createIssuesTab(issuesArray: DashboardRetroReportTaskData[] | undefined,
		emptyTabTitle: string) {

		if (issuesArray && issuesArray.length > 0) {
			const issuesForTable = toIDashboardTaskDetailed(issuesArray);
			return (
				<TasksGroup
					rows={issuesForTable}
					onRowClicked={(item) => setSelectedIssueInPanel(item)}
					selectedRow={issuesForTable.find((issue) => issue.entityId === selectedIssueIdInPanel)}
					selectedIndex={undefined}
					isSeeAllMode={true}
					totalRows={issuesArray.length}
					scrollable={true}
					subtaskStrategy={subtaskStrategy}
					estimationMethod={estimationMethod}
					wrapTableTitle={false}
					estimationColumn={undefined}
					statusType={StatusType.StartEndStatus}
					shouldDisplayMakerTimePercentage={false}
					tableClassName="change-table-column-width"
					shouldDisplayTotalMakerTime={false}
					shouldDisplayCycleTime={true}
					shouldDisplayEstimationColumn={false}
					statusDiff={props.issuesStatusDiff}
				/>
			);
		}

		return (
			<div className="use-placeholder table-placeholder dark-blue-text acu-capitalize">
				no {emptyTabTitle}
			</div>
		);
	}

	const tasks = _.filter(issues, task => task.acumenType === null ||
		![AcumenTaskType.Bug, AcumenTaskType.Story].includes(task.acumenType));
	const stories = _.filter(issues, task => task.acumenType === AcumenTaskType.Story);
	const bugs = _.filter(issues, task => task.acumenType === AcumenTaskType.Bug);
	const epics = epicsTasks?.map(x => x.epic);

	return (
		<div className="ui full-width acu-scroll raised card issues-table">
			<div className="ui secondary header acu-capitalize">{title}</div>
				<LoadingIndicator local={true} isActive={!issues && !pageError}>
				<Tab className="team-dashboard-table" menu={{ secondary: true, pointing: true, className:
					"table-tab-menu no-margin" }} panes={
						[
							{
								menuItem: `Tasks (${tasks.length ?? 0})`, render: () =>
									<Tab.Pane attached={false}>
										{createIssuesTab(tasks, "tasks")}
									</Tab.Pane>
							},
							{
								menuItem: `User stories (${stories.length ?? 0})`, render: () =>
									<Tab.Pane attached={false}>
										{createIssuesTab(stories, "user stories")}
									</Tab.Pane>
							},
							{
								menuItem: `Bugs (${bugs.length ?? 0})`, render: () =>
									<Tab.Pane attached={false}>
										{createIssuesTab(bugs, "bugs")}
									</Tab.Pane>
							},
							epics ?
								{
									menuItem: `Epics (${epics.length ?? 0})`, render: () =>
										<Tab.Pane attached={false}>
											{createIssuesTab(epics, "epics")}
										</Tab.Pane>
								} : {},
							{
								menuItem: `All (${(issues?.length ?? 0) + (epics?.length ?? 0)})`, render: () =>
									<Tab.Pane attached={false}>
										{createIssuesTab([...issues!, ...(epics || [])], "issues")}
									</Tab.Pane>
							}
						]
				}
				/>
			</LoadingIndicator>
		</div >
	);
});
