import { useMemo } from "react";
import { CustomTableColumnFilterProps, ResolvedCustomTableColumnProps } from "../types";
import { CustomSelectOption } from "../../custom-select";
import { getItemPropertyValueAsString } from "../utils";

export const useResolvedFilterOptions = <T extends object>(props: {
	column: ResolvedCustomTableColumnProps<T>;
	items: T[];
	filterable: CustomTableColumnFilterProps<T>;
}) => {
	const { column, items, filterable } = props;

	return useMemo<Array<CustomSelectOption<string | number>>>(() => {
		if (!filterable || filterable.disabled) {
			return [];
		}

		const { options, selectLabel, selectValue } = filterable;

		if (options) {
			return options;
		}

		if (!selectValue) {
			return [];
		}

		return items
			.map((item, index) => [item, selectValue(item, column, index, items)] as const)
			.map(([item, valueToCompare], index) => ({
				value: valueToCompare as string | number,
				label: (selectLabel
					? selectLabel(item, column, index, items)
					: getItemPropertyValueAsString(item, column.key)),
			}));
	}, [column, items, filterable]);
};
