import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";
import { useStores } from "../../../../mobx-stores";
import LoadingIndicator from "../../../../components/loader";
import { MetricInterval, IDashboardAcumenMetricDataResponse, IDashboardSprint, AcumenTaskType, metricStringDateToUnixTime } from "@acumen/dashboard-common";
import { IPullRequestSizeData } from "../../../../mobx-stores/metric-store";
import _ from "lodash";
import colorScheme from "./chart-color-scheme";
import {
	ChartSeriesData, isSameIntervalCategory, isValidChartData, metricKeyToSprintCategory,
	packSprintByIdIfNeeded, prepareGroupedCategorySeriesDataById, sortSprintIds, sortSprintIdsTuple, SprintByIdMap
} from "./charts";
import { v4 as generateUUID } from "uuid";
import { round } from "@acumen/common";

interface IPullRequestSizeProps {
	isFullScreen?: boolean;
	setDisableFullScreen?: (disable: boolean) => void;
	filters: {
		teamId?: string;
		dataContributorIds?: string[];
		startTime?: Date;
		endTime?: Date;
		interval?: MetricInterval;
		projectIds?: string[];
		boardIds?: string[];
		gitRepositoryIds?: string[];
		baseIsDefaultBranch?: boolean;
		excludeDraft?: boolean;
		customerSprints?: IDashboardSprint[];
		issueTypes?: AcumenTaskType[];
		timezone: string;
	};
}

export const PR_SIZE_LOC_DATA = {
	title: "Pull request size",
	description: `Shows both average pull request size vs the number of pull requests split into buckets by size.`
};

function prepareAverageSeriesData(prCountsInDateSeriesData: ChartSeriesData,
	locPRChangeMetric: IDashboardAcumenMetricDataResponse, timezone: string, sprintById?: SprintByIdMap) {
	const locPRChangeMetricValues = Object.values(locPRChangeMetric.values);
	const dateToPRCount: Record<string | number, number> = prCountsInDateSeriesData.reduce((acc, val) =>
		Object.assign(acc, { [val[0]]: val[1] }), {});
	let keys = Object.keys(locPRChangeMetricValues[0]);
	if (sprintById) {
		keys = sortSprintIds(keys, sprintById);
	}
	return keys.map(valueKey => {
		let category: string | number | undefined;
		if (sprintById) {
			category = metricKeyToSprintCategory(valueKey, sprintById);
		} else {
			category = metricStringDateToUnixTime(valueKey, timezone);
		}
		const prCountInDate = dateToPRCount[category];
		let prToLOCRatio = 0;
		if (prCountInDate && prCountInDate > 0) {
			const locPRChangeInDate = locPRChangeMetricValues[0][valueKey];

			if (locPRChangeInDate && locPRChangeInDate > 0) {
				prToLOCRatio = round((locPRChangeInDate / 1000) / prCountInDate);
			}
		}
		return [category, prToLOCRatio];
	});
}

function sortSeriesByName(series: Highcharts.SeriesAreasplineOptions[]): Highcharts.SeriesAreasplineOptions[] {
	return series.sort((a, b) => {
		const aKeys = a.name?.split("-").filter(x => x !== "").map(Number);
		const bKeys = b.name?.split("-").filter(x => x !== "").map(Number);
		const firstKeyA = (aKeys !== undefined ? aKeys[0] : undefined);
		const firstKeyB = (bKeys !== undefined ? bKeys[0] : undefined);
		const isFirstKeyAValid = (firstKeyA !== undefined && !isNaN(firstKeyA));
		const isFirstKeyBValid = (firstKeyB !== undefined && !isNaN(firstKeyB));
		if (!isFirstKeyAValid || !isFirstKeyBValid) {
			if (!isFirstKeyAValid && isFirstKeyBValid) {
				return 1;
			}
			if (isFirstKeyAValid && !isFirstKeyBValid) {
				return -1;
			}

			return 0;
		}
		if (firstKeyA! < firstKeyB!) {
			return 1;
		}
		if (firstKeyA! > firstKeyB!) {
			return -1;
		}
		return 0;
	});
}

function sortSeriesData(data: Array<[string, number]>): Array<[string, number]> {
	return _.sortBy(data);
}

function mergeDataAddition(data: Array<[string, number]>): Array<[string, number]> {
	const merged: { [key: string]: number } = {};
	data.forEach((dataSlot) => {
		const key = dataSlot[0];
		const value: number = dataSlot[1];
		const currentValue = merged[key] || 0;
		merged[key] = currentValue + value;
	});

	return Object.keys(merged).map((key) => [key, merged[key]]);
}

function convertSeriesToLocal(seriesData: Array<[string, number]>, timezone: string, sprintById?: SprintByIdMap): ChartSeriesData {
	let sortedSeriesData = seriesData;
	if (sprintById) {
		sortedSeriesData = sortSprintIdsTuple(seriesData, sprintById);
	}
	return sortedSeriesData.map((dataSlot) => {
		const key = dataSlot[0];
		const value: number = dataSlot[1];
		let category: string | number | undefined;
		if (sprintById) {
			category = metricKeyToSprintCategory(key, sprintById);
		} else {
			category = metricStringDateToUnixTime(key, timezone);
		}
		return [category, value];
	});
}

function PullRequestSize(props: IPullRequestSizeProps) {
	const { metricStore } = useStores();
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const { fetchData } = metricStore.prSizeData();
	const { prSizeDataStored } = metricStore;
	const [chartData, setChartData] = useState<IPullRequestSizeData | undefined>(prSizeDataStored);

	useEffect(() => {
		if (props.isFullScreen) {
			return;
		}
		if (Object.keys(props.filters).length === 0) {
			return;
		}

		let isMounted = true;
		async function fetch() {
			setIsFetching(true);
			if (props.setDisableFullScreen) {
				props.setDisableFullScreen(true);
			}
			const data = await fetchData(props.filters.teamId,
				props.filters.dataContributorIds,
				props.filters.projectIds,
				props.filters.startTime,
				props.filters.endTime,
				props.filters.interval,
				props.filters.timezone,
				props.filters.gitRepositoryIds,
				props.filters.baseIsDefaultBranch,
				props.filters.excludeDraft,
				props.filters.boardIds,
				props.filters.issueTypes
			);
			if (isMounted && data) {
				setIsFetching(false);
				setChartData(data);
			}
			if (props.setDisableFullScreen) {
				props.setDisableFullScreen(false);
			}
		}

		// tslint:disable-next-line: no-floating-promises
		fetch();
		return () => { isMounted = false; };
	}, [props.filters]);

	let prSizeSeries: Highcharts.SeriesAreasplineOptions[] | undefined;
	let dataGroupingSeries: Highcharts.SeriesSplineOptions[] | undefined;

	if (isValidChartData<IPullRequestSizeData>(chartData) &&
		isSameIntervalCategory(chartData.pullRequestMergeLineOfCodeChangedBucket.interval, props.filters.interval)) {
		const sprintById = packSprintByIdIfNeeded(props.filters.interval, props.filters.customerSprints);
		const seriesCount = Object.keys(chartData.pullRequestMergeLineOfCodeChangedBucket.values).length;
		const seriesData = prepareGroupedCategorySeriesDataById(chartData.pullRequestMergeLineOfCodeChangedBucket);
		let prCountSeries = _.flatten(Object.values(seriesData));

		prSizeSeries = Object
			.keys(chartData.pullRequestMergeLineOfCodeChangedBucket.values)
			.map(key => {
				const seriesName = (seriesCount === 1 ? "Lines of code" : key);
				const data = convertSeriesToLocal(seriesData[key], props.filters.timezone, sprintById);

				const seriesOptions: Highcharts.SeriesAreasplineOptions = {
					name: seriesName,
					data,
					stack: "pr",
					stacking: "normal",
					type: "areaspline"
				};
				return seriesOptions;
			});

		prSizeSeries = sortSeriesByName(prSizeSeries);
		const colorSize = colorScheme.sizeBlue.color;
		const borderSize = colorScheme.sizeBlue.border;
		const colorsBySizeOrder = [colorSize.xxl, colorSize.xl, colorSize.l, colorSize.m, colorSize.s];
		const colorsByBorderOrder = [borderSize.xxl, borderSize.xl, borderSize.l, borderSize.m, borderSize.s];

		for (let i = 0; i < prSizeSeries.length; i++) {
			if (i >= colorsBySizeOrder.length) {
				break;
			}
			prSizeSeries[i].color = colorsBySizeOrder[i];
			prSizeSeries[i].lineColor = colorsByBorderOrder[i];
			prSizeSeries[i].marker = {
				color: colorsByBorderOrder[i],
				fillColor: colorsByBorderOrder[i],
				symbol: "circle"
			};
			prSizeSeries[i].lineWidth = 1;
			prSizeSeries[i].legendIndex = prSizeSeries.length - i;
		}
		prCountSeries = sortSeriesData(mergeDataAddition(prCountSeries));

		const prCountSeriesUpdated = convertSeriesToLocal(prCountSeries, props.filters.timezone, sprintById);
		const averagePRSizeSeries = prepareAverageSeriesData(prCountSeriesUpdated, chartData.pullRequestLinesOfCodeChanged, props.filters.timezone, sprintById);

		dataGroupingSeries = [
			{
				id: generateUUID(),
				type: "spline",
				tooltip: {
					valueSuffix: " (K LOC / #PRs)"
				},
				name: "Average pull request size",
				yAxis: 1,
				marker: {
					symbol: "circle"
				},
				data: averagePRSizeSeries,
				visible: averagePRSizeSeries.length > 0,
				dashStyle: "Dash",
				color: "#000000",
				lineWidth: 1,
			},
		];
	} else {
		prSizeSeries = [
			{
				id: generateUUID(),
				data: [],
				visible: false,
				name: "Lines of code",
				stack: "pr",
				marker: {
					symbol: "circle"
				},
				stacking: "normal",
				type: "areaspline",
			}
		];
		dataGroupingSeries = [
			{
				type: "spline",
				dashStyle: "ShortDash",
				color: colorScheme.pr,
				name: "Average pull request size",
				data: [],
				marker: {
					symbol: "circle"
				},
				visible: false
			},
		];
	}

	const options: Highcharts.Options = {
		chart: {
			type: "area",
			zoomType: "xy"
		},
		title: undefined,
		xAxis: {
			gridLineWidth: 1,
			type: (props.filters.interval === MetricInterval.SPRINT_DATE ? "category" : "datetime"),
			uniqueNames: false
		},
		yAxis: [
			{
				min: 0,
				title: {
					text: "# of pull requests"
				}
			},
			{
				min: 0,
				title: {
					text: "Average pull request size (lines of code)",
				},
				labels: {
					// tslint:disable-next-line: object-literal-shorthand
					formatter: function _formatter() {
						return `${this.value}k`;
					}
				},
				opposite: true
			}
		],
		plotOptions: {
			area: {
				stacking: "normal",
			}
		},
		tooltip: {
			split: true,
			valueSuffix: " PRs"
		},
		credits: {
			enabled: false
		},
		series: [...prSizeSeries, ...dataGroupingSeries]
	};

	return (
		<div className="description">
			<LoadingIndicator local={true} isActive={!isValidChartData(chartData) || isFetching}>
				<HighchartsReact
					highcharts={Highcharts}
					options={options}
				/>
			</LoadingIndicator>
		</div>
	);
}

export default PullRequestSize;
