import * as React from "react";

export const DEFAULT_ARROW_GREEN_COLOR = "#32B195";
export const DEFAULT_ARROW_RED_COLOR = "#E24B63";

interface IProps extends React.SVGProps<SVGSVGElement> {
	indicationfillcolor?: string;
}
const ArrowDownIcon = (props: IProps) => (
	<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path d="M3.8335 6L12.8335 6L8.3335 11L3.8335 6Z" fill={props.indicationfillcolor ? props.indicationfillcolor : DEFAULT_ARROW_RED_COLOR} />
	</svg>
);

export default ArrowDownIcon;
