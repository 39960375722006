import {
	IPlanningPokerDeck,
	IPlanningPokerGame,
	IPlanningPokerIssue,
	IPlanningPokerPlayer,
	PokerGameMetricSystem,
	PokerPlanningGameState,
	SocketEvents,
	SocketNamespace,
	PokerIssuesSortOption,
	IDashboardIntegration,
	IntegrationType,
	IntegrationStatus,
} from "@acumen/dashboard-common";
import { observable } from "mobx";
import BaseStore, { ILoadable } from "../../../mobx-stores/base-store";
import { APIContextProvider } from "../../../../services/api-context-provider";
import { WS_ROUTE } from "../../../services/api-routes";
import { GoRetroPlanningPokerApiClient, NewPlanningPokerIssue } from "../../../services/crud/go-retro-planning-poker-api-client";
import io, { Socket } from "socket.io-client";
import urlJoin from "url-join";
import { IntegrationsApiClient } from "v1/services/crud/integrations-api-client";

export class PokerService {
	private readonly _api: GoRetroPlanningPokerApiClient;
	private readonly _integrationsApi: IntegrationsApiClient;
	private socket: Socket | undefined;

	@observable
	game?: IPlanningPokerGame | null;

	@observable
	decks: ILoadable<IPlanningPokerDeck[] | null> = BaseStore.initLoadable([]);

	@observable
	integration?: IDashboardIntegration | null;

	constructor(contextProvider: APIContextProvider) {
		this._api = new GoRetroPlanningPokerApiClient(contextProvider);
		this._integrationsApi = new IntegrationsApiClient(contextProvider);
	}

	async subscribeToGame(gameXid: string) {
		// Note: if there is already a socket connection with the same gameXid there is not need to connect
		if (this.socket && this.socket.io.opts.query?.gameXid === gameXid) {
			return;
		}

		if (this.socket) {
			// Note: there is an active socket for a different game, disconnecting
			this.socket.disconnect();
		}

		this.socket = io(urlJoin(WS_ROUTE, SocketNamespace.PlanningPoker), {
			withCredentials: true,
			transports: ["websocket", "polling"],
			query: {
				token: this._api.getToken(),
				gameXid
			}
		});

		this.socket!.on("connect", () => {
			// tslint:disable-next-line: no-console
			console.log("connected to server websocket");
		});

		// Note: not on connect to avoid new handler registration on every reconnect
		// https://socket.io/docs/v4/client-socket-instance/#connect
		this.socket!.on(SocketEvents.GameState, ({game}) => {
			this.game = game;
		});

		this.socket!.on("connect_error", async (error) => {
			// Note: on connect_error we must manually try to reconnect
			this.socket!.disconnect();
			this.socket = undefined;
			await this.subscribeToGame(gameXid);
		});

		this.socket!.on("disconnect", async (reason) => {
			// Note: the socket manager will try to reconnect, to need for an action
			// https://socket.io/docs/v4/client-socket-instance/
		});
	}

	public async addIssuesToGame(gameId: string, issues: NewPlanningPokerIssue[]) {
		if (issues.length === 0) {
			return;
		}

		await this._api.addIssues(gameId, issues);
	}

	public async createGame(name: string, metricSystem: PokerGameMetricSystem, deckUuid: string,
		creatorPlayerName: string, goRetroTeamUuid: string | null
	): Promise<IPlanningPokerGame | null> {
		return await this._api.createGame(name, metricSystem, deckUuid, creatorPlayerName, undefined, goRetroTeamUuid);
	}

	public async getGameDetails(gameXid: string): Promise<IPlanningPokerGame | null> {
		const game = await this._api.getGame(gameXid);
		this.game = game;

		return game;
	}

	public async saveEstimationOnIssue(
		gameId: string,
		issue: IPlanningPokerIssue,
		estimation: string
	) {
		return await this._api.updateIssue(gameId, issue.xid, {estimation});
	}

	public async saveNoteOnIssue(
		gameId: string,
		issue: IPlanningPokerIssue,
		note?: string | null
	) {
		return await this._api.updateIssue(gameId, issue.xid, {note});
	}

	public async deleteIssuesFromGame(gameId: string, issues: IPlanningPokerIssue[]) {
		await this._api.deleteIssues(gameId, issues);
	}

	public async vote(
		gameId: string,
		value: number,
		cardDisplayValue: string,
		issueToVote: IPlanningPokerIssue
	) {
		await this._api.vote(gameId, issueToVote, value, cardDisplayValue);
	}

	public async voteOnIssue(game: IPlanningPokerGame, issueToVote: IPlanningPokerIssue) {
		await this._api.voteOnIssue(game.xid, issueToVote);
	}

	public async updateGameSettings(
		gameId: string,
		name: string,
		deckUuid: string,
		metric: PokerGameMetricSystem,
		issuesSortOption?: PokerIssuesSortOption,
	) {
		await this._api.updateGame(gameId, name, metric, deckUuid, undefined, issuesSortOption);
	}

	public async updatePlayerName(gameId: string, playerId: string, newPlayerName: string) {
		await this._api.updatePlayer(gameId, playerId, newPlayerName);
	}

	public async join(gameId: string, playerName: string) {
		return await this._api.joinGame(gameId, playerName);
	}

	public async resetVotesOnIssue(gameId: string, issue: IPlanningPokerIssue) {
		await this._api.deleteVote(gameId, issue);
	}

	public async resetVotesAllVotes(gameId: string) {
		await this._api.deleteVote(gameId);
	}

	public async resetVotesForPlayer(gameId: string, player: IPlanningPokerPlayer) {
		await this._api.deleteVote(gameId, undefined, player);
	}

	public async setGameVotingState(gameId: string, state: PokerPlanningGameState) {
		await this._api.updateGame(gameId, undefined, undefined, undefined, state);
	}

	public async listGames(goRetroTeamUuid: string | null) {
		return await this._api.listGames(goRetroTeamUuid);
	}

	public async listDecks(forceFetch = false): Promise<ILoadable<IPlanningPokerDeck[] | null>> {
		if (forceFetch || !this.decks.data?.length) {
			try {
				this.decks.loading = true;
				this.decks.data = await this._api.listDecks();
				this.decks.loaded = true;
			} finally {
				this.decks.loading = false;
			}
		}

		return this.decks;
	}

	public async createDeck(deckName: string, cardDisplayValues: string[]) {
		const createdDeck = await this._api.createDeck(deckName, cardDisplayValues);
		await this.listDecks(true);
		return createdDeck;
	}

	public async getActiveIntegration() {
		const integrations = await this._integrationsApi.getIntegrationsByQuery({ type: IntegrationType.JIRA, status: IntegrationStatus.ACTIVE });

		this.integration = (integrations && integrations.length > 0) ? integrations[0] : undefined;

		return this.integration;
	}

	public async getJiraFields(integrationId: string) {
		const fields = await this._integrationsApi.getJiraFields(integrationId);

		return fields;
	}

	public async updateEstimateInJira(integrationId: string, key: string,
		field: string, type: "string" | "number", value: string) {
		const response = await this._integrationsApi.updateEstimateInJira(integrationId, key, field, type, value);

		return response;
	}

	public async addCommentInJira(integrationId: string, key: string, comment: string) {
		const response = await this._integrationsApi.addCommentInJira(integrationId, key, comment);

		return response;
	}
}
