import { getData } from "../../../services/fetch-helpers";
import { BaseCustomerApiClient } from "./base-customer-api-client";
import { IDashboardSprint, DashboardSortOrder, SprintSortField, SprintState, DevelopmentMethodology } from "@acumen/dashboard-common";
import { qsStringify } from "../../../services/url-routes-helper";

export const SPRINTS_ROUTE = "sprints";

export interface ISprintSort {
	field: SprintSortField;
	order: DashboardSortOrder;
}

export interface ISprintFilter {
	teamId?: string;
	sprintStates?: SprintState[];
	excludeSprintsWithFutureStartDate?: boolean;
	excludeSprintsWithNoAssignees?: boolean;
	boardIds?: string[];
	sprintStartTimeFrom?: Date | null;
}

export class SprintsApiClient extends BaseCustomerApiClient {
	public fetchSprints = async (developmentMethodology: DevelopmentMethodology, filter?: ISprintFilter, sort?: ISprintSort) => {
		const query: Record<string, string | string[] | boolean | SprintState[]> = {};

		query.developmentMethodology = developmentMethodology;

		if (filter?.teamId) {
			query.teamId = filter?.teamId;
		}

		if (filter?.sprintStates) {
			query.sprintStates = filter?.sprintStates;
		}

		if (filter?.excludeSprintsWithFutureStartDate) {
			query.excludeSprintsWithFutureStartDate = filter?.excludeSprintsWithFutureStartDate;
		}

		if (filter?.excludeSprintsWithNoAssignees) {
			query.excludeSprintsWithNoAssignees = filter?.excludeSprintsWithNoAssignees;
		}
		if (filter?.boardIds) {
			query["boardIds[]"] = filter.boardIds;
		}

		if (sort?.field) {
			query.sort = sort.field;
		}

		if (sort?.order) {
			query.sortOrder = sort.order;
		}

		if (filter?.sprintStartTimeFrom) {
			query.sprintStartTimeFrom = new Date(filter.sprintStartTimeFrom).toISOString();
		}

		return await getData<IDashboardSprint[], any>(
			this.createCustomerEntityRoute(`${SPRINTS_ROUTE}?${qsStringify(query)}`),
			this.token,
			this.tokenType
		);
	}
}
