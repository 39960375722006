import React, { useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import UsersDropdownOptions from "./users-dropdown-options";
import { UserOption } from "../types";

interface UsersDropdownFieldProps {
	options: UserOption[];
	showArrow: boolean;
	committedContributorIds: Set<string> | null;
	selectedContributorIds: Set<string> | null;
	actionButtons?: React.ReactNode;
	itemsBeforeUsers?: React.ReactNode;
	footer?: React.ReactNode;
	onToggleContributor: (id: string) => void;
	onHide: () => void;
}

const UsersDropdown = (props: UsersDropdownFieldProps) => {
	const { options, showArrow, committedContributorIds, selectedContributorIds, actionButtons, itemsBeforeUsers, footer, onToggleContributor, onHide } = props;
	const dropdownHasSelection = useMemo(
		() => options.some(option => selectedContributorIds?.has(option.id)),
		[options, selectedContributorIds]
	);
	const [isOpen, setIsOpen] = useState(false);
	const toggleOpen = useCallback(() => setIsOpen(open => !open), []);
	const hide = useCallback(() => {
		setIsOpen(false);
		onHide();
	}, [onHide]);
	const rootClassName = classNames(
		"extra-icon-btn small-gray-btn extra-avatar-dropdown-button",
		dropdownHasSelection && "selected",
	);

	return (
		<div className={rootClassName}>
			{showArrow ? (
				<i
					className={classNames("chevron down icon", "dropdown-arrow", "clickable")}
					onClick={toggleOpen}
				/>
			) : (
				<button className="ui mini label " onClick={toggleOpen}>
					{"+ " + options.length}
				</button>
			)}

			{isOpen && (
				<UsersDropdownOptions
					options={options}
					committedContributorIds={committedContributorIds}
					selectedContributorIds={selectedContributorIds}
					actionButtons={actionButtons}
					itemsBeforeUsers={itemsBeforeUsers}
					footer={footer}
					onToggleContributor={onToggleContributor}
					onHide={hide}
				/>
			)}
		</div>
	);
};

export default UsersDropdown;
