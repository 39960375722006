import { IDashboardDetailedPullRequestResponse, IDashboardDetailedTaskResponse } from "@acumen/dashboard-common";
import { AcumenPullRequestStatus, AcumenTaskStatusGroup, DeveloperBadge, taskGroupToStatusMapping, WorkforceHealthMetrics } from "@acumen/database-types";
import { DeveloperBadgesData, DcBadgesData, DeveloperStatsChartData, DeveloperWeeklyData } from "../../../types/workforce-health";
import { DevStatColumn, devStatColumns } from "../dev-stat-columns";
import { AsyncState, useAsyncState } from "../../../hooks/async-state";
import { useStores } from "../../../mobx-stores";
import { getMetricDatesForDevStatsPeriod } from "../../../mobx-stores/developer-stats-store";
import { MetricData } from "../../team-comparison/types";
import _ from "lodash";

const EMPTY_METRIC_CHART_RESPONSE = [0, 0, 0, 0, 0, 0, 0, 0];

const DAYS_IN_SINGLE_PERIOD = 30;
const GROUPING_LIMIT = 1000;

const charsum = (s: string) => {
	let i, sum = 0;
	for (i = 0; i < s.length; i++) {
		sum += (s.charCodeAt(i) * (i + 1));
	}
	return sum;
};

export const arrayHash = (a: string[]) => {
	let i, sum = 0;
	for (i = 0; i < a.length; i++) {
		const cs = charsum(a[i]);
		sum = sum + (65027 / cs);
	}
	return ("" + sum).slice(0, 16);
};

export const useTeamsState = () => {
	const { teamsStore, authStore } = useStores();

	return useAsyncState(async () => {
		await teamsStore.fetchAllTeams();
		let allTeams = teamsStore.allTeams.data ?? [];
		if (authStore.userRoleManagerOnly) {
			allTeams = allTeams.filter(t => t.teamLeadContributorId === authStore.authUser.dataContributorDetails.id);
		}
		return allTeams;
	}, { initialState: [] });
};

export const useTeamMembersState = () => {
	const { teamMembersStore, dataContributorsStore } = useStores();

	return useAsyncState(async () => {
		const members = await teamMembersStore.fetchTeamMembersForAllTeams();
		const dataContributorIds = _.uniq(members.map(m => m.dataContributorId));
		const labels = await dataContributorsStore.fetchLabels(dataContributorIds);
		return {
			members,
			labels
		};
	}, {
		initialState: {
		members: [],
		labels: new Map()
	} });
};

export const useBadgesState = (dataContributorIds: string[]): AsyncState<DeveloperBadgesData, DeveloperBadgesData> => {
	const { developerStatsStore } = useStores();
	const emptyResponse = {
		dataContributors: {},
		totals: {
			current: {
				[DeveloperBadge.NeedAttention]: 0,
				[DeveloperBadge.OnTheRise]: 0,
				[DeveloperBadge.SuperStar]: 0,
				[DeveloperBadge.TeamPlayer]: 0
			},
			previous: {
				[DeveloperBadge.NeedAttention]: 0,
				[DeveloperBadge.OnTheRise]: 0,
				[DeveloperBadge.SuperStar]: 0,
				[DeveloperBadge.TeamPlayer]: 0
			}
		},
		config: {
			configs: {
				devStats: {} as any,
				badges: {} as any,
				teamBadges: {} as any
			},
			startTime: "",
			endTime: ""
		}
	};

	return useAsyncState(async () => {
		if (dataContributorIds.length === 0) {
			return emptyResponse;
		}

		await developerStatsStore.getDeveloperBadgesData(dataContributorIds);

		let data: DeveloperBadgesData = emptyResponse;

		if (developerStatsStore.developerBadgesData) {
			data = {
				...developerStatsStore.developerBadgesData,
				dataContributors: Object.assign({}, ...Object.entries(developerStatsStore.developerBadgesData.dataContributors).map(([dcId, dcBadgesData]) => ({
					[dcId]: {
						aggregatedBadges: dcBadgesData.aggregatedBadges.filter(badge => badge as DeveloperBadge !== DeveloperBadge.LowActivity),
						metricBadges: dcBadgesData.metricBadges,
						hasLowActivity: dcBadgesData.aggregatedBadges.filter(badge => badge as DeveloperBadge === DeveloperBadge.LowActivity).length > 0
					}
				}))) as Record<string, DcBadgesData>
			};
		}

		return data;
	}, {
		initialState: emptyResponse,
		deps: [JSON.stringify(dataContributorIds.sort())],
	});
};

export const useDeveloperStatsChartData = (dataContributorIds: string[], selectedMetrics: DevStatColumn[]) => {
	const {
		developerStatsStore,
		authStore
	} = useStores();

	const emptyResponse: DeveloperStatsChartData = {};
	selectedMetrics.forEach(metric => emptyResponse[metric.dashboardMetricName] = EMPTY_METRIC_CHART_RESPONSE);

	return useAsyncState(async () => {
		if (dataContributorIds.length === 0) {
			return emptyResponse;
		}

		await developerStatsStore.getDeveloperStatsChartData(
			authStore.authUser.timezone, dataContributorIds, selectedMetrics
		);

		return developerStatsStore.devStatChartData ?? emptyResponse;
	}, {
		initialState: emptyResponse,
		deps: [JSON.stringify(dataContributorIds.sort())],
	});
};

export const useDeveloperStatsState = (
	dataContributorIds: string[],
	selectedMetrics: DevStatColumn[]
): AsyncState<MetricData, MetricData> => {
	const {
		developerStatsStore,
		authStore
	} = useStores();

	const emptyResponse = new MetricData();

	return useAsyncState(async () => {
		if (dataContributorIds.length === 0 || !developerStatsStore.developerBadgesData) {
			return emptyResponse;
		}

		await developerStatsStore.getDeveloperStatsData(
			authStore.authUser.timezone,
			dataContributorIds,
			devStatColumns
		);

		return developerStatsStore.developerStats ?? emptyResponse;
	}, {
		initialState: emptyResponse,
		deps: [dataContributorIds, developerStatsStore.developerBadgesData],
	});
};

export const useDeveloperSingleMetricState = (
	dataContributorId: string,
	metric: DevStatColumn
): AsyncState<DeveloperWeeklyData, DeveloperWeeklyData> => {
	const {
		developerStatsStore,
		authStore
	} = useStores();

	const emptyResponse: DeveloperWeeklyData = {
		chartData: {},
		dates: []
	};

	return useAsyncState(async () => {
		if (!dataContributorId || !developerStatsStore.developerBadgesData) {
			return emptyResponse;
		}

		await developerStatsStore.getSingleDeveloperWeeklyStats(
			// TODO: (ACM-7676) we should be using the config's timezone!
			authStore.authUser.timezone,
			dataContributorId,
			metric
		);

		return developerStatsStore.developerWeeklyStats ?? emptyResponse;
	}, {
		initialState: emptyResponse,
		deps: [dataContributorId, metric, developerStatsStore.developerBadgesData],
	});
};

export const useDeveloperSelectedMetricsState = (): AsyncState<WorkforceHealthMetrics[], WorkforceHealthMetrics[]> => {
	const {
		developerStatsStore,
		authStore
	} = useStores();

	const emptyResponse: WorkforceHealthMetrics[] = [];

	return useAsyncState(async () => {
		await developerStatsStore.getWorkForceSelectedMetrics(authStore.authUser.dataContributorDetails.id);

		return developerStatsStore.workForceSelectedMetrics ?? emptyResponse;
	}, {
		initialState: emptyResponse
	});
};

export const useTasksState = (
	dataContributorIds: string[]
): AsyncState<IDashboardDetailedTaskResponse, IDashboardDetailedTaskResponse> => {
	const { tasksStore, authStore } = useStores();

	const emptyResponse = {
		tasks: [],
		total: 0
	};

	const { startTime, endTime } = getMetricDatesForDevStatsPeriod(authStore.authUser.timezone, DAYS_IN_SINGLE_PERIOD);

	return useAsyncState(async () => {
		if (dataContributorIds.length === 0) {
			return emptyResponse;
		}

		await tasksStore.fetchAllTasks({
			filter: {
				dataContributorIds,
				startTime,
				endTime,
				statuses: taskGroupToStatusMapping[AcumenTaskStatusGroup.Done]
			},
			staticGroupingLimit: GROUPING_LIMIT,
			filterTasksByCycleEnd: true
		});

		return tasksStore.allTasks.data ?? emptyResponse;
	}, {
		initialState: emptyResponse,
		deps: [JSON.stringify(dataContributorIds.sort())],
	});
};

export const usePullRequestsState = (
	dataContributorIds: string[]
): AsyncState<IDashboardDetailedPullRequestResponse, IDashboardDetailedPullRequestResponse> => {
	const { pullRequestsStore, authStore } = useStores();

	const emptyResponse = {
		pullRequests: [],
		total: 0
	};

	const { startTime, endTime } = getMetricDatesForDevStatsPeriod(authStore.authUser.timezone, DAYS_IN_SINGLE_PERIOD);

	return useAsyncState(async () => {
		if (dataContributorIds.length === 0) {
			return emptyResponse;
		}

		await pullRequestsStore.fetchAllPullRequests({
			filter: {
				dataContributorIds,
				startTime,
				endTime,
				statuses: [AcumenPullRequestStatus.Closed, AcumenPullRequestStatus.Merged],
			},
			staticGroupingLimit: GROUPING_LIMIT
		});

		return pullRequestsStore.allPullRequests.data ?? emptyResponse;
	}, {
		initialState: emptyResponse,
		deps: [JSON.stringify(dataContributorIds.sort())],
	});
};
