import { IdBasedCrudApiClient } from "./id-based-crud-api-client";
import {
	IDashboardTeam, IDashboardWorkIntervalWithEntities
} from "@acumen/dashboard-common";
import { APIContextProvider } from "../../../services/api-context-provider";
import { getData } from "../../../services/fetch-helpers";
import urlJoin from "url-join";
import { qsStringify } from "../../../services/url-routes-helper";

export const TEAMS_ROUTE = "teams";

export class TeamsApiClient extends IdBasedCrudApiClient<IDashboardTeam> {
	constructor(apiContextProvider: APIContextProvider) {
		super(TEAMS_ROUTE, apiContextProvider);
	}

	public fetchWork = async (teamId?: string, startTime?: Date, endTime?: Date) => {
		let workIntervalUrl = urlJoin("work", "intervals");
		if (teamId || startTime || endTime) {
			workIntervalUrl = `${workIntervalUrl}?`;
			const query: { [label: string]: string | Date } = {};
			if (teamId) {
				query.teamId = teamId;
			}
			if (startTime) {
				query.startTime = startTime;
			}
			if (endTime) {
				query.endTime = endTime;
			}
			workIntervalUrl = `${workIntervalUrl}${qsStringify(query)}`;
		}
		return await getData<IDashboardWorkIntervalWithEntities[], any>(
			this.createCustomerEntityRoute(workIntervalUrl),
			this.token
		);
	}
}
