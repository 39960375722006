import * as React from "react";
const DialogCloseButton = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		aria-hidden="true"
		fill="#1F1440"
		viewBox="0 0 20 20"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path d="M4.293 4.293a1 1 0 0 1 1.414 0L10 8.586l4.293-4.293a1 1 0 1 1 1.414 1.414L11.414 10l4.293 4.293a1 1 0 0 1-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 0 1-1.414-1.414L8.586 10 4.293 5.707a1 1 0 0 1 0-1.414z" />
	</svg>
);
export default DialogCloseButton;
