import {
	IDashboardDetailedPullRequestResponse, DashboardSortOrder, AcumenPullRequestStatus,
	DashboardPullRequestStaticGroupingOption, DashboardPullRequestDynamicGroupingOption, DashboardPullRequestSortOption,
	DashboardPullRequestExpandOption
} from "@acumen/dashboard-common";
import { getData } from "../../../services/fetch-helpers";
import { BaseCustomerApiClient } from "./base-customer-api-client";
import { qsStringify } from "../../../services/url-routes-helper";

export const PULL_REQUESTS_ROUTE = "pull-requests";

export class PullRequestsApiClient extends BaseCustomerApiClient {
	public fetchPullRequests = async (teamId?: string, dataContributorIds?: string[],
		gitRepositories?: string[], statuses?: AcumenPullRequestStatus[], excludeDraft?: boolean, prIds?: string[],
		staticGroupingLimit?: number, staticGrouping?: DashboardPullRequestStaticGroupingOption,
		dynamicGroupingLimit?: number, dynamicGrouping?: DashboardPullRequestDynamicGroupingOption,
		startTime?: Date, endTime?: Date, sortField?: DashboardPullRequestSortOption,
		sortOrder?: DashboardSortOrder, expand?: DashboardPullRequestExpandOption[]) => {
		const query: { [label: string]: string | string[] | number | Date | boolean } = {};

		if (teamId) {
			query.teamId = teamId;
		}

		if (dataContributorIds) {
			query["dataContributorIds[]"] = dataContributorIds;
		}
		if (gitRepositories) {
			query["gitRepositories[]"] = gitRepositories;
		}
		if (statuses) {
			query["statuses[]"] = statuses;
		}
		if (prIds) {
			query["prIds[]"] = prIds;
		}
		if (startTime) {
			query.startTime = startTime;
		}
		if (endTime) {
			query.endTime = endTime;
		}
		if (staticGrouping) {
			query.staticGrouping = staticGrouping;
		}
		if (dynamicGrouping) {
			query.dynamicGrouping = dynamicGrouping;
		}
		if (sortField) {
			query.sortField = sortField;
		}
		if (sortOrder) {
			query.sortOrder = sortOrder;
		}
		if (excludeDraft) {
			query.excludeDraft = excludeDraft;
		}
		if (expand) {
			query["expand[]"] = expand;
		}
		if (staticGroupingLimit !== undefined) {
			query.staticGroupingLimit = staticGroupingLimit;
		}
		if (dynamicGroupingLimit !== undefined) {
			query.dynamicGroupingLimit = dynamicGroupingLimit;
		}

		return getData<IDashboardDetailedPullRequestResponse, any>(
			this.createCustomerEntityRoute(`${PULL_REQUESTS_ROUTE}?${qsStringify(query)}`),
			this.token
		);
	}
}
