import { AcumenTaskStatus, AcumenTaskStatusGroup } from "@acumen/dashboard-common";

export const AcumenStatusToClassName: Record<AcumenTaskStatus | AcumenTaskStatusGroup, string> = {
	[AcumenTaskStatus.Backlog]: "status-backlog",
	[AcumenTaskStatus["Selected for Development"]]: "status-selected-for-development",
	[AcumenTaskStatus["In Progress"]]: "status-in-progress",
	[AcumenTaskStatus.Blocked]: "status-blocked",
	[AcumenTaskStatus["Code Review"]]: "status-code-review",
	[AcumenTaskStatus["To Test"]]: "status-to-test",
	[AcumenTaskStatus.Testing]: "status-qa-in-progress",
	[AcumenTaskStatus["Client Review"]]: "status-in-review",
	[AcumenTaskStatus.Done]: "status-done",
	[AcumenTaskStatus.Closed]: "status-closed",
	[AcumenTaskStatus.Discarded]: "status-discarded",

	[AcumenTaskStatusGroup.Backlog]: "status-backlog",
	[AcumenTaskStatusGroup.InProgress]: "status-in-progress",
	[AcumenTaskStatusGroup.Done]: "status-done",
};
