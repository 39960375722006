import React, { useEffect } from "react";
import { createPortal } from "react-dom";

const Portal = ({
	children,
	wrapWithModal = false,
	onClose,
	scrollLock = false,
}) => {
	const mount = document.getElementById("portal-root");
	const el = document.createElement("div");

	useEffect(() => {
		mount.appendChild(el);
		const scrollTop = document.scrollingElement.scrollTop;
		document.scrollingElement.scrollTop = 0;
		if (scrollLock) {
			document.body.style.overflow = "hidden";
		}
		return () => {
			document.scrollingElement.scrollTop = scrollTop;
			if (scrollLock) {
				document.body.style.overflow = "unset";
			}
			mount.removeChild(el);
		};
	}, [el, mount, scrollLock]);

	return createPortal(
		wrapWithModal ? (
			<div
				className="ui dimmer modals visible active"
				style={{ display: "flex !important" }}
				onClick={onClose}
			>
				<div
					className="ui modal large visible active front"
					style={{ display: "block !important" }}
					// eslint-disable-next-line no-sequences
					onClick={(e) => (e.stopPropagation(), false)}
				>
					<i
						className="close inverted icon"
						onClick={onClose}
						style={{ cursor: "pointer" }}
					/>
					{children}
				</div>
			</div>
		) : (
			children
		),
		el
	);
};

export default Portal;
