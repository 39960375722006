import { IDashboardAcumenMetricDataResponse, IDashboardSprint, isIntervalInSprintCategory,
	MetricInterval, metricStringDateToUnixTime } from "@acumen/dashboard-common";
import assert from "assert";
import _ from "lodash";
import { round } from "@acumen/common";

const SECONDS_IN_HOUR = 3600;
type GeneralTransform = (seriesData: [number | string, number], categoryValue: string) => [number | string, number];
type IdTransform = (seriesData: [string, number]) => [string, number];
export type ChartSeriesData = Array<[string | number, number]>;
export interface CategoryChartSeriesData { [x: string]: ChartSeriesData; }
export type SprintByIdMap = _.Dictionary<IDashboardSprint>;
export interface StackedChartData {
	categories: string[];
	series: Array<{name: string, data: number[]}>;
}

export function prepareGroupedCategorySeriesDataByDate(categoryMetric: IDashboardAcumenMetricDataResponse,
	timezone: string, transform: GeneralTransform = (seriesData) => seriesData): CategoryChartSeriesData {

	return _.mapValues(categoryMetric.values, values =>
		Object.keys(values).map(dateAsString =>
			dateValueTuple(dateAsString, values[dateAsString], timezone, transform)
		)
	);
}

export function prepareGroupedCategoryHourSeriesDataByDate(categoryMetric: IDashboardAcumenMetricDataResponse, timezone: string) {
	return prepareGroupedCategorySeriesDataByDate(categoryMetric, timezone, ([date, count]) => {
		const hoursInDate = (count / SECONDS_IN_HOUR);
		const roundHoursInDate = round(hoursInDate);
		return [date, roundHoursInDate];
	});
}

export function prepareGroupedCategoryHourSeriesDataBySprint(categoryMetric: IDashboardAcumenMetricDataResponse,
	sprintById: SprintByIdMap): CategoryChartSeriesData {
	return _.mapValues(categoryMetric.values, values => {
		const sortedSprintIds = sortSprintIds(Object.keys(values), sprintById);
		return sortedSprintIds.map(sprintIdAsString => sprintValueTuple(sprintIdAsString, values[sprintIdAsString], sprintById, ([category, count]) => {
			const hoursInDate = (count / SECONDS_IN_HOUR);
			const roundHoursInDate = round(hoursInDate);
			return [category, roundHoursInDate];
		}));
	});
}

export function prepareCategorySeriesDataByDate(categoryMetric: IDashboardAcumenMetricDataResponse,
	timezone: string, transform: GeneralTransform = (seriesData) => seriesData): ChartSeriesData {
	const categoryMetricValues = Object.values(categoryMetric.values);

	assert(categoryMetricValues.length === 1, "Metric should have no group values");
	return Object.keys(categoryMetricValues[0])
		.map(dateAsString => dateValueTuple(dateAsString, categoryMetricValues[0][dateAsString], timezone, transform));
}

function dateValueTuple(dateAsString: string, value: number | null, timezone: string, transform: GeneralTransform) {
	const date = metricStringDateToUnixTime(dateAsString, timezone);
	return transform([date, value || 0], dateAsString);
}

export function prepareGroupedCategorySeriesDataById(categoryMetric: IDashboardAcumenMetricDataResponse,
	transform: IdTransform = (seriesData) => seriesData) {

	return _.mapValues(categoryMetric.values, values =>
		Object.keys(values).map(idAsString =>
			idValueTuple(idAsString, values[idAsString], transform)
		)
	);
}

function idValueTuple(idAsString: string, value: number | null, transform: IdTransform) {
	return transform([idAsString, value || 0]);
}

export function prepareCategoryHourSeriesDataByDate(categoryMetric: IDashboardAcumenMetricDataResponse, timezone: string) {
	return prepareCategorySeriesDataByDate(categoryMetric, timezone, ([date, count]) => {
		const hoursInDate = (count / SECONDS_IN_HOUR);
		const roundHoursInDate = round(hoursInDate);
		return [date, roundHoursInDate];
	});
}

export function sortSprintIds(sprintIds: string[], sprintById: SprintByIdMap): string[] {
	return _.sortBy(sprintIds, key => {
		const sprintDetails = sprintById[key];
		if (!sprintDetails) {
			return key;
		}
		return [sprintDetails.startDate, sprintDetails.endDate, sprintDetails.name];
	});
}

export function sortSprintIdsTuple(seriesData: Array<[string, number]>, sprintById: SprintByIdMap): Array<[string, number]> {
	return _.sortBy(seriesData, key => {
		const sprintDetails = sprintById[key[0]];
		if (!sprintDetails) {
			return key;
		}
		return [sprintDetails.startDate, sprintDetails.endDate, sprintDetails.name];
	});
}

export function prepareGroupedCategorySeriesDataBySprint(categoryMetric: IDashboardAcumenMetricDataResponse,
	sprintById: SprintByIdMap, transform: GeneralTransform = (seriesData) => seriesData): CategoryChartSeriesData {

	return _.mapValues(categoryMetric.values, values => {
		const sortedSprintIds = sortSprintIds(Object.keys(values), sprintById);
		return sortedSprintIds.map(sprintIdAsString => sprintValueTuple(sprintIdAsString, values[sprintIdAsString], sprintById, transform));
	});
}

export function prepareCategorySeriesDataBySprint(categoryMetric: IDashboardAcumenMetricDataResponse,
	sprintById: SprintByIdMap, transform: GeneralTransform = (seriesData) => seriesData): ChartSeriesData {
	const categoryMetricValues = Object.values(categoryMetric.values);

	assert(categoryMetricValues.length === 1, "Metric should have no group values");

	const sprintIds = Object.keys(categoryMetricValues[0]);
	const sortedSprintIds = sortSprintIds(sprintIds, sprintById);
	return sortedSprintIds.map(sprintIdAsString => sprintValueTuple(sprintIdAsString, categoryMetricValues[0][sprintIdAsString], sprintById, transform));
}

function sprintValueTuple(sprintIdAsString: string, value: number | null, sprintById: SprintByIdMap, transform: GeneralTransform) {
	const category = metricKeyToSprintCategory(sprintIdAsString, sprintById);
	return transform([category, value || 0], sprintIdAsString);
}

export function prepareCategoryHourSeriesDataBySprint(categoryMetric: IDashboardAcumenMetricDataResponse, sprintById: SprintByIdMap) {
	return prepareCategorySeriesDataBySprint(categoryMetric, sprintById, ([category, count]) => {
		const hoursInDate = (count / SECONDS_IN_HOUR);
		const roundHoursInDate = round(hoursInDate);
		return [category, roundHoursInDate];
	});
}

export function isValidChartData<T extends object>(data?: object): data is T {
	return !_.isEmpty(data);
}

export function metricKeyToSprintCategory(key: string, sprintById: SprintByIdMap) {
	const sprintDetails = sprintById[key];
	return (sprintDetails ? `${sprintDetails.name}` : `Sprint Id ${key}`);
}

export function packSprintByIdIfNeeded(interval?: MetricInterval, customerSprints?: IDashboardSprint[]) {
	let sprintById: SprintByIdMap | undefined;
	if (interval && isIntervalInSprintCategory(interval) && customerSprints && customerSprints.length > 0) {
		sprintById = _.keyBy(customerSprints, "id");
	}
	return sprintById;
}

export function isSameIntervalCategory(left?: MetricInterval, right?: MetricInterval) {
	if (isIntervalInSprintCategory(left) && isIntervalInSprintCategory(right)) {
		return true;
	}

	return (left === right);
}

function cleanMetricData(metricData: IDashboardAcumenMetricDataResponse[]) {
	return metricData.filter(status => _.sum(_.values(status.values).map(x => _.entries(x).map(y => y[1])).map(y => y[0])) > 0);
}

export function preparedStackedCategorySeriesDataSum(metricData: IDashboardAcumenMetricDataResponse[],
	filterField: string): StackedChartData {

	// NOTE: this is done to filter out series with no data
	const filteredMetricData = cleanMetricData(metricData);

	const series = filteredMetricData.length > 0 ? Object.keys(filteredMetricData[0].values).map(issueType => {
		return {
			name: issueType,
			data: []
		};
	}) : [];

	const dataToReturn: StackedChartData = {
		categories: [],
		series
	};

	filteredMetricData.forEach(status => {
		const issueStatus = status.filter![filterField][0] as string;
		dataToReturn.categories.push(issueStatus);

		_.keys(status.values).forEach(key => {
			const totalHours = _.sum(_.values(status.values[key])) / SECONDS_IN_HOUR;
			const roundedHours = round(totalHours);
			dataToReturn.series.find(x => x.name === key)?.data.push(roundedHours);
		});
	});

	return dataToReturn;
}
