import { DashboardIntegrationType } from "@acumen/dashboard-common";
import { integrationTypesInfo, IProviderData } from "../../mobx-stores/new-integrations-store";
import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import "./style.scss";

interface IChooseIntegration {
	onChoose: (integrationTypeId: DashboardIntegrationType) => void;
	isActive: boolean;
}

// tslint:disable-next-line: variable-name
const EmptyState = (props: IChooseIntegration) => {
	const { onChoose, isActive = true } = { ...props };

	const getIntegrationCard = (providers: IProviderData[]) => {
		return providers.map((pro, i) => (
			<div
				className="ui card raised"
				onClick={() => {
					if (isActive) {
						onChoose(pro.type);
					}
				}}
				key={i}
			>
				{isActive ? <Link to="/my-account/integrations/add">
					<div className="integration-button">
						<img className="integration-logo" src={pro.logoSrc} alt=""/>
						<span>{pro.displayName}</span>
					</div>
				</Link>
				: <div className={classNames("integration-button", "disabled")}>
					<img className="integration-logo" src={pro.logoSrc} alt="" />
					<span>{pro.displayName}</span>
				</div>}
			</div>
		));
	};

	return (
		<div className="show-background-image"
			style={{ backgroundImage: `url(/assets/images/empty-state-background.svg)`}}>
			<div id="empty-state-page">
				<img className="acumen-logo" src="/assets/images/logo-white.svg" alt="Acumen" />
				<div className="empty-state-page-title">Let’s get started!</div>
				<div className="empty-state-page-line">Integrate a data source to get started with Acumen.</div>
				<div className="empty-state-page-line extra-margin-title">Choose an integration.</div>
				<div className="ui cards all-integrations">
					{getIntegrationCard(Object.values(integrationTypesInfo))}
					<div className="underline-integrations"/>
				</div>
				{!isActive &&
					<div className="empty-state-page-line cant-integrate">Notify your administrator to start the integration.</div>
				}
			</div>
		</div>
	);
};
export default EmptyState;
