import _ from "lodash";
import { DeveloperStats } from "../types/workforce-health";
import { MetricData } from "../pages/team-comparison/types";
import { WorkforceHealthMetrics } from "@acumen/database-types";

export const calculateAvgByMetric = (
	metricData: MetricData,
	period: "current" | "previous",
	developerIds?: string[],
) => {
	const ids = developerIds ?? metricData.ids();
	const averages = Object.keys(WorkforceHealthMetrics).map(metricName => {
		if (ids.length === 0) {
			return 0;
		}

		const values = ids.map(id => metricData.get(id, metricName as WorkforceHealthMetrics, period));
		const sum = _.sum(values);

		return {
			[metricName]: sum / ids.length,
		};
	});

	return Object.assign({}, ...Object.values(averages)) as DeveloperStats;
};
