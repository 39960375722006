import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Switch, Route, Redirect, RouteComponentProps, useLocation } from "react-router-dom";
import Chart from "./pages/external/charts";
import SprintSelector from "./pages/external/sprint-selector";
import apiContextProvider, { TokenType } from "../services/api-context-provider";
import JiraIntegrationFlow from "./pages/external";
import GoRetroConfiguration from "./pages/external/go-retro-configuration";
import { GoRetroSprintRetro } from "./pages/external/go-retro-sprint-review";
import { ToastContainer } from "react-toastify";
import { useStores } from "./mobx-stores";
import { GoRetroMessages, sendMessageToGoRetro } from "./pages/external/go-retro-messages";
import { MixpanelTrackingService } from "../services/mixpanel-tracking-service";
import { Meta } from "./components/Meta";

export const BASE_EXTERNAL_ROUTE = "/external/:customerId/:token";
export const ACUMEN_SUPPORT_IN_GO_RETRO_EMAIL = "support@goretro.ai";

interface TokenAuthProps {
	customerId: string;
	token: string;
}

export const mixpanelService = new MixpanelTrackingService();

// tslint:disable-next-line: variable-name
const ExternalApp = observer((props: RouteComponentProps<TokenAuthProps>) => {
	apiContextProvider.setContext({ customerId: props.match.params.customerId, token: props.match.params.token, tokenType: TokenType.AcumenToken });

	const { fetcherStore } = useStores();
	const location = useLocation();

	async function onTokenError() {
		sendMessageToGoRetro(GoRetroMessages.RefreshHandshake);
	}

	useEffect(() => {
		if (fetcherStore.hasTokenError) {
			// tslint:disable-next-line: no-floating-promises
			onTokenError();
		}
	}, [fetcherStore.hasTokenError]);

	useEffect(() => {
		if (mixpanelService) {
			const currentQsParams = new URLSearchParams(location.search);
			const userEmail = currentQsParams.get("email");
			if (userEmail) {
				mixpanelService.identify(userEmail);
			}
		}
	}, [mixpanelService]);

	return (
		<div>
			<Meta includeCustomFonts={true}/>
			<div className="fixed-toaster-container">
				<ToastContainer
					autoClose={false}
				/>
			</div>
			<Switch>
				<Route path={BASE_EXTERNAL_ROUTE + "/charts"} component={Chart} />
				<Route path={BASE_EXTERNAL_ROUTE + "/sprint-selector"} component={SprintSelector} />
				<Route path={BASE_EXTERNAL_ROUTE + "/configuration"} component={GoRetroConfiguration} />
				<Route path={BASE_EXTERNAL_ROUTE + "/sprint-summary"} component={GoRetroSprintRetro} />
				<Route path={BASE_EXTERNAL_ROUTE + "/sprint-monitoring"} component={GoRetroSprintRetro} />
				<Route path={BASE_EXTERNAL_ROUTE + "/data-fetch"} component={JiraIntegrationFlow} />
				<Route path={BASE_EXTERNAL_ROUTE + "/team-members"} render={() => (
					<JiraIntegrationFlow isTeamOwner={false} />
				)} />
				{/* All missed paths will need login */}
				<Redirect to="/" />
			</Switch>
		</div>
	);
});

export default ExternalApp;
