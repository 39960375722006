import {
	ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState
} from "react";

import classnames from "classnames";
import { useOnClickOutside } from "usehooks-ts";

import { IPlanningPokerDeck, PokerGameMetricSystem } from "@acumen/dashboard-common";
import React from "react";
import { usePoker } from "../adapters/poker";
import CloseButton from "../svg-assets/DialogCloseButton";
import LoadingIndicator from "../svg-assets/LoadingIndicator";
import { observer } from "mobx-react";

export interface GameSettingsModalProps {
	currentGameName: string;
	currentGameDeck: IPlanningPokerDeck;
	currentGameMetricSystem: PokerGameMetricSystem;
	isCurrentGameStarted: boolean;
	onCancel: () => void;
	onSave: (
		name: string,
		deckUuid: string,
		metric: PokerGameMetricSystem
	) => Promise<void>;
}

export const GameSettingsModal = observer(({
	currentGameName: gameName,
	currentGameDeck: gameDeck,
	currentGameMetricSystem: gameMetricSystem,
	isCurrentGameStarted,
	onCancel,
	onSave,
}: GameSettingsModalProps) => {

	const poker = usePoker();

	const [updatedGameName, setGameName] = useState<string>(gameName);
	const [updatedGameDeckUuid, setUpdatedGameDeckUuid] = useState<string>(gameDeck.xid);
	const [updatedGameMetric, setGameMetric] =
		useState<PokerGameMetricSystem>(gameMetricSystem);
	const [isSaving, setIsSaving] = useState<boolean>(false);

	const ref = useRef(null);

	useEffect(() => {
		if (poker && poker.service && (!poker.service.decks.loaded && !poker.service.decks.loading)) {
			poker.service.listDecks().catch();
		}
	}, [poker]);

	useOnClickOutside(ref, () => onCancel());

	const onEscape = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === "Escape" && onCancel) {
				onCancel();
			}
		},
		[onCancel]
	);

	useEffect(() => {
		document.addEventListener("keydown", onEscape, false);

		return () => {
			document.removeEventListener("keydown", onEscape, false);
		};
	}, [onEscape]);

	return (
		<div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 backdrop-brightness-50 bg-black/30">
			<div
				className="relative rounded-lg bg-[#FFFEFE]/95 border border-[#CED5DF]"
				ref={ref}
			>
				<button
					type="button"
					disabled={isSaving}
					className={classnames(
						"absolute top-3 right-2.5 ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-base text-gray-400",
						{
							"hover:bg-gray-200": !isSaving,
						}
					)}
					onClick={(event: FormEvent) => {
						if (isSaving) {
							return;
						}
						event.preventDefault();
						onCancel();
					}}
				>
					<CloseButton className="h-5 w-5" />
				</button>
				<div className="p-6">
					<h3 className="mb-2 text-2xl font-bold tracking-wider text-[#399CFF] capitalize">
						Game Settings
					</h3>
					<div className="space-y-4">
						<div>
							<label
								htmlFor="gameName"
								className="block text-base text-[#8791A2]"
							>
								Game Name
								<span className="text-xs text-[#8791A2] ml-1">(required)</span>
							</label>
							<input
								type="text"
								name="gameName"
								id="gameName"
								disabled={isSaving}
								className={classnames(
									"block w-full px-2 py-1 text-base font-medium text-gray-700 placeholder-[#9FA7B4] bg-white shadow-sm border border-[#1B3F7B] rounded-md",
									{
										"focus:ring-gray-300/90 focus:outline-none focus:ring focus:ring-opacity-20":
											!isSaving,
									}
								)}
								placeholder="e.g. Sprint #50 🦁 Team"
								required={true}
								defaultValue={gameName}
								onChange={(event: ChangeEvent<HTMLInputElement>) => {
									if (isSaving) {
										return;
									}
									setGameName(event.target.value);
								}}
							/>
						</div>
						<div>
							<label
								htmlFor="gameType"
								className="block text-base text-[#8791A2]"
							>
								Voting System
							</label>

							<select
								name="gameType"
								id="gameType"
								disabled={isSaving || isCurrentGameStarted}
								className={classnames(
									"block w-full px-2 py-3 text-base font-medium text-gray-700 placeholder-[#9FA7B4] bg-white shadow-sm border border-[#1B3F7B] rounded-md",
									{
										"focus:ring-gray-300/90 focus:outline-none focus:ring focus:ring-opacity-20":
											!isSaving,
									},
									isCurrentGameStarted ? "bg-gray-200" : "bg-white"
								)}
								defaultValue={gameDeck.xid}
								onChange={(event: ChangeEvent<HTMLSelectElement>) => {
									if (isSaving || isCurrentGameStarted) {
										return;
									}
									setUpdatedGameDeckUuid(event.target.value);
								}}
							>
								{[gameDeck, ...poker!.service!.decks!.data!
									.filter((x) => x.xid !== gameDeck.xid)]
									.map((deck, i) => {
										return <option value={deck.xid} key={i}>
											{deck.name} {"("}{deck.cards.map(x => x.displayValue).join(", ")}{")"}
										</option>;
									})}
							</select>
						</div>
						<div>
							<label
								htmlFor="metricSystem"
								className="block text-base text-[#8791A2]"
							>
								Metric System
							</label>
							<select
								name="metricSystem"
								id="metricSystem"
								disabled={isSaving}
								className={classnames(
									"block w-full px-2 py-3 text-base font-medium text-gray-700 placeholder-[#9FA7B4] bg-white shadow-sm border border-[#1B3F7B] rounded-md",
									{
										"focus:ring-gray-300/90 focus:outline-none focus:ring focus:ring-opacity-20":
											!isSaving,
									}
								)}
								defaultValue={gameMetricSystem}
								onChange={(event: ChangeEvent<HTMLSelectElement>) => {
									if (isSaving) {
										return;
									}
									setGameMetric(event.target.value as PokerGameMetricSystem);
								}}
							>
								<option value={PokerGameMetricSystem.Average}>Average</option>
								<option value={PokerGameMetricSystem.Median}>Median</option>
								<option value={PokerGameMetricSystem.Consensus}>Consensus</option>
							</select>
						</div>
					</div>
					<div className="mt-12 flex flex-row justify-center space-x-4">
						<button
							type="button"
							disabled={isSaving}
							className={classnames(
								"w-36 py-3 border border-[#399CFF] text-xl rounded font-normal text-[#399CFF]",
								{
									"hover:bg-gray-200 transition duration-300 ease-in-out":
										!isSaving,
								}
							)}
							onClick={async (event: FormEvent) => {
								if (isSaving) {
									return;
								}
								event.preventDefault();
								onCancel();
							}}
						>
							Cancel
						</button>
						<button
							type="button"
							disabled={isSaving}
							className={classnames(
								"w-36 py-3 bg-[#399CFF] border border-[#399CFF] text-xl rounded font-normal text-white",
								{
									"hover:border-[#338CE5] hover:bg-[#338CE5] transition duration-300 ease-in-out ":
										!isSaving && updatedGameName && updatedGameName.trim().length > 0,
								}, {
									"cursor-not-allowed": isSaving || !updatedGameName || updatedGameName.trim().length === 0
								}
							)}
							onClick={async (event: FormEvent) => {
								if (isSaving) {
									return;
								}
								event.preventDefault();
								if (
									!updatedGameName ||
									updatedGameName.trim().length === 0
								) {
									return;
								}
								setIsSaving(true);
								await onSave(
									updatedGameName,
									updatedGameDeckUuid,
									updatedGameMetric
								);
							}}
						>
							{!isSaving && "Save"}
							{isSaving && (
								<LoadingIndicator className="inline mr-3 w-4 h-4 text-white animate-spin" />
							)}
							{isSaving && "Saving..."}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
});
