import {
	useState,
	ChangeEvent,
	FormEvent,
	useEffect,
	useCallback,
	useRef,
} from "react";

import classnames from "classnames";
import { useOnClickOutside } from "usehooks-ts";
import React from "react";
import CloseButton from "../svg-assets/DialogCloseButton";
import LoadingIndicator from "../svg-assets/LoadingIndicator";

export interface ChangeDisplayNameModalProps {
	playerName: string;
	onCancel: () => void;
	onSave: (newName: string) => Promise<void>;
}

export function ChangeDisplayNameModal({
	playerName,
	onCancel,
	onSave,
}: ChangeDisplayNameModalProps) {
	const [updatedPlayerName, setPlayerName] = useState<string>(playerName);
	const [isSaving, setIsSaving] = useState<boolean>(false);

	const ref = useRef(null);

	useOnClickOutside(ref, () => onCancel());

	const onEscape = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === "Escape" && onCancel) {
				onCancel();
			}
		},
		[onCancel]
	);

	useEffect(() => {
		document.addEventListener("keydown", onEscape, false);

		return () => {
			document.removeEventListener("keydown", onEscape, false);
		};
	}, [onEscape]);

	return (
		<div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 backdrop-brightness-50 bg-black/30">
			<div
				className="relative rounded-lg bg-[#FFFEFE]/95 border border-[#CED5DF]"
				ref={ref}
			>
				<button
					type="button"
					disabled={isSaving}
					className={classnames(
						"absolute top-3 right-2.5 ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-base text-gray-400",
						{
							"hover:bg-gray-200": !isSaving,
						}
					)}
					onClick={(event: FormEvent) => {
						if (isSaving) {
							return;
						}
						event.preventDefault();
						onCancel();
					}}
				>
					<CloseButton className="h-5 w-5" />
				</button>
				<div className="p-6">
					<h3 className="mb-2 text-2xl font-bold tracking-wider text-[#399CFF] capitalize">
						Change Your Name
					</h3>
					<div className="space-y-4">
						<div>
							<label
								htmlFor="gameName"
								className="block text-base text-[#8791A2]"
							>
								Player Name
								<span className="text-xs text-[#8791A2] ml-1">(required)</span>
							</label>
							<input
								type="text"
								name="gameName"
								id="gameName"
								disabled={isSaving}
								className={classnames(
									"block w-full px-2 py-1 text-base font-medium text-gray-700 placeholder-[#9FA7B4] bg-white shadow-sm border border-[#1B3F7B] rounded-md",
									{
										"focus:ring-gray-300/90 focus:outline-none focus:ring focus:ring-opacity-20":
											!isSaving,
									}
								)}
								placeholder={`e.g. Chuck Norris`}
								required={true}
								defaultValue={playerName}
								onChange={(event: ChangeEvent<HTMLInputElement>) => {
									if (isSaving) {
										return;
									}
									setPlayerName(event.target.value);
								}}
							/>
						</div>
					</div>
					<div className="mt-12 flex flex-row justify-center space-x-4">
						<button
							type="button"
							disabled={isSaving}
							className={classnames(
								"w-36 py-3 border border-[#399CFF] text-xl rounded font-normal text-[#399CFF]",
								{
									"hover:bg-gray-200 transition duration-300 ease-in-out":
										!isSaving,
								}
							)}
							onClick={async (event: FormEvent) => {
								if (isSaving) {
									return;
								}
								event.preventDefault();
								onCancel();
							}}
						>
							Cancel
						</button>
						<button
							type="button"
							disabled={isSaving}
							className={classnames(
								"w-36 py-3 bg-[#399CFF] border border-[#399CFF] text-xl rounded font-normal text-white",
								{
									"hover:border-[#338CE5] hover:bg-[#338CE5] transition duration-300 ease-in-out ":
										!isSaving && updatedPlayerName && updatedPlayerName.trim().length > 0,
								}, {
									"cursor-not-allowed": isSaving || !updatedPlayerName || updatedPlayerName.trim().length === 0
								}
							)}
							onClick={async (event: FormEvent) => {
								if (isSaving) {
									return;
								}
								event.preventDefault();
								if (
									!updatedPlayerName ||
									updatedPlayerName.trim().length === 0
								) {
									return;
								}
								if (updatedPlayerName === playerName) {
									onCancel();
									return;
								}
								setIsSaving(true);
								await onSave(updatedPlayerName);
							}}
						>
							{!isSaving && "Save"}
							{isSaving && (
								<LoadingIndicator className="inline mr-3 w-4 h-4 text-white animate-spin" />
							)}
							{isSaving && "Saving..."}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
