import React, { useState } from "react";
import { Icon, Label, Popup, Modal, Button, Segment } from "semantic-ui-react";
import { GA_EVENT_ACTION, GA_EVENT_CATEGORY, clickEvent } from "../analytics-events";
import { AcumenTaskType, DateRangeType, IDashboardSprint, MetricInterval } from "@acumen/dashboard-common";
import classNames from "classnames";

export interface IChartCard {
	chartData: {
		title: string,
		description: string,
		titleSuffixByFilters?: (filters: IChartProps) => string
	};
	children: React.ReactChild | React.ReactChild[];
	eventCategory: GA_EVENT_CATEGORY;
	filters?: IChartProps;
	supportExpand?: boolean;
	className?: string;
	onExportRequest?: () => Promise<void>;
	onEmbedRequest?: ()  => Promise<string>;
}
export interface IChartProps {
	teamId?: string;
	dataContributorIds?: string[];
	startTime?: Date;
	endTime?: Date;
	interval?: Exclude<MetricInterval, MetricInterval.SPRINT>;
	issueTypes?: AcumenTaskType[];
	projectIds?: string[];
	boardIds?: string[];
	gitRepositoryIds?: string[];
	baseIsDefaultBranch?: boolean;
	excludeDraft?: boolean;
	customerSprints?: IDashboardSprint[];
	dateRange?: DateRangeType;
	timezone: string;
}

function ChartCard(props: IChartCard) {
	const [fullScreen, setFullScreen] = useState(false);
	const { chartData, children, filters, eventCategory, supportExpand, className } = { ...props };
	const [disableFullScreen, setDisableFullScreen] = useState(false);
	const [iFrameContent, setIFrameData] = useState<string | undefined>(undefined);
	const [iFrameDataCopied, setIFrameDataCopied] = useState<boolean>(false);
	const [isDownloadingExportData, setIsDownloadingExportData] = useState(false);

	const chart =  React.Children.map(children, child => {
		if (React.isValidElement(child)) {
		  return React.cloneElement(child, { setDisableFullScreen });
		}
		return child;
	  });

	const fullScreenChart = React.Children.map(children, child => {
		if (React.isValidElement(child)) {
		  return React.cloneElement(child, { isFullScreen: true, setDisableFullScreen });
		}
		return child;
	  });

	const chartHeader = (
		<h4 className="ui header">
			{chartData.title}
			<Popup
				hoverable={true}
				wide={true}
				position="top center"
				content={chartData.description}
				trigger={
					<i className="chart-tooltip info icon" />
				}
			/>
			{(supportExpand !== false && !fullScreen) && (
				<i {...clickEvent(eventCategory, GA_EVENT_ACTION.Select, `FullScreen - ${chartData.title}`)}
					className="fullscreen-button expand alternate icon"
					style={{cursor: disableFullScreen ? "default" : "pointer"}}
					onClick={() => {
						if (!disableFullScreen) {
							setFullScreen(true);
						}
					}} />
			)}
		</h4>
	);

	let chartTitle = chartData.title;
	if (chartData.titleSuffixByFilters !== undefined && filters) {
		chartTitle += " " + chartData.titleSuffixByFilters(filters);
	}

	return (
		<div className={classNames("ui raised card full-width", className)}>
			<div className="reports content">
				{chartHeader}
				{chart}
			</div>
			<Modal
				open={fullScreen}
				onClose={() => {
					setFullScreen(false);
				}}
				className="fs-chart-modal"
			>
				<div className="ui expanded-chart container">
					<i className="icon thin times close-icon" onClick={() => {
						setFullScreen(false);
					}} />
					<h2 className="ui header">
						{chartTitle}
						{props.onExportRequest !== undefined && (
							<span>
								<Popup
									hoverable={true}
									wide={true}
									position="top center"
									content="Download chart data as CSV"
									trigger={
										<Label className="chart-export" as="a" basic={true} onClick={async (e) => {
											if (isDownloadingExportData) {
												return;
											}
											e.stopPropagation();

											setIsDownloadingExportData(true);

											try {
												await props.onExportRequest!();
											} finally {
												setIsDownloadingExportData(false);
											}
										}}><Icon name={(isDownloadingExportData) ? "hourglass half" : "download"} />Export</Label>
									}
								/>
							</span>
						)}
						{props.onEmbedRequest !== undefined && (
							<span>
							<Modal
								open={iFrameContent !== undefined}
								closeIcon={true}
								onClose={() => setIFrameData(undefined)}
							>
								<Modal.Header icon="paper plane outline" content="Embed as HTML" />
								<Modal.Content>
									<Segment raised={true} padded={"very"}>
										<div className="text-word-break">{iFrameContent}</div>
									</Segment>
								</Modal.Content>
								<Modal.Actions>
									<Button
										color="green"
										onClick={() =>  navigator.clipboard.writeText(iFrameContent!).then(() => {
											setIFrameDataCopied(true);
											setTimeout(_x => setIFrameDataCopied(false), 1000);
									})}
									>
									 {(iFrameDataCopied ? "Copied ✔️" : "Copy to clipboard")}
									</Button>
								</Modal.Actions>
							</Modal>
							<Popup
								hoverable={true}
								wide={true}
								position="top center"
								content="Embed chart"
								trigger={
									<Label className="chart-export" as="a" basic={true} onClick={async (e) => {
										e.stopPropagation();

										setIFrameData(await props.onEmbedRequest!());
									}}><Icon name="paper plane outline" />Embed</Label>
								}
							/>
							</span>
						)}
					</h2>
					<p className="gray-text-font">
						{chartData.description}
					</p>
					{fullScreenChart}
				</div>
			</Modal>
		</div>
	);
}

export default ChartCard;
