import { CommonEndPoints, IDashboardDataContributorEpic } from "@acumen/dashboard-common";
import { postData } from "../../../services/fetch-helpers";
import { BaseCustomerApiClient } from "./base-customer-api-client";
import urlJoin from "url-join";

export const DATA_CONTRIBUTORS_EPICS_ROUTE = "/epics";

export interface IDashboardDataContributorEpicRequestParams {
	dataContributors: number[];
	startDate: string;
	endDate: string;
}

export class DataContributorEpicsApiClient extends BaseCustomerApiClient {
	async findAll(params: IDashboardDataContributorEpicRequestParams) {
		return await postData<IDashboardDataContributorEpicRequestParams, IDashboardDataContributorEpic[], any>(
			this.createCustomerEntityRoute(urlJoin(DATA_CONTRIBUTORS_EPICS_ROUTE, CommonEndPoints.FIND_ALL)),
			this.token,
			params,
		);
	}
}
