import React, {
	createContext,
	ReactNode,
	useContext,
} from "react";
import { sendMessageToGoRetroFromPokerPlanning } from "..";

interface IIntercomContext {
	track: (event: string, properties?: Record<string, any>) => void;
}

const IntercomContext = createContext<IIntercomContext | undefined>(undefined);

interface Props {
	children: ReactNode;
}

interface IntercomEvent {
	event: string;
	properties?: Record<string, any>;
}

export default function IntercomProvider(props: Props) {
	const { children } = props;

	const track = (event: string, properties?: Record<string, any>) => {
		const dataPack: IntercomEvent = {
			event,
			properties
		};

		sendMessageToGoRetroFromPokerPlanning("track-intercom", dataPack);
	};

	return (
		<IntercomContext.Provider
			value={{
				track
			}}
		>
			{children}
		</IntercomContext.Provider>
	);
}

export function useIntercom() {
	return useContext(IntercomContext);
}
