import React, { useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import classnames from "classnames";
import Select from "react-select";
import { IPlanningPokerIssue, MixpanelEventType } from "@acumen/database-types";
import { Player } from "@lottiefiles/react-lottie-player";
import { ProgressBar } from "react-bootstrap";
import CloseButton from "../svg-assets/DialogCloseButton";
import LoadingLottie from "../json-assets/99297-loading-files.json";
import DoneLottie from "../json-assets/676-done.json";
import ErrorLottie from "../json-assets/113888-errorfailure.json";
import { useMixpanel } from "../adapters/mixpanel";
import { FAKE_ISSUE_KEY_PREFIX } from "./AddIssueModal";

export interface ExportToJiraModalProps {
	issues: IPlanningPokerIssue[];
	jiraFieldsList: any;
	integrationId: string;
	onCancel: () => void;
	onUpdateJiraEstimate: (integrationId: string, key: string, field: string, type: "string" | "number", value: string) => Promise<any>;
	onAddJiraComment: (integrationId: string, key: string, comment: string) => Promise<any>;
}

enum UIState {
	FieldSelection,
	Updating,
	UpdateFinished
}

let isCancelled = false;

function createFieldListOption(name: string, customId?: string, custom?: string, system?: string) {
	return {
		value: system ?? `customfield_${customId}`,
		label: name + (customId ? ` (${customId})` : "")
	};
}

export function ExportToJiraModal({
	issues,
	jiraFieldsList,
	integrationId,
	onCancel,
	onUpdateJiraEstimate,
	onAddJiraComment
}: ExportToJiraModalProps) {
	const mixpanel = useMixpanel();

	const ref = useRef(null);
	const [selectJiraField, setSelectedJiraField] = useState("");

	const [uiState, setUIState] = useState<UIState>(UIState.FieldSelection);
	const [updateState, setUpdateState] = useState<{
		updated: IPlanningPokerIssue[], failed: IPlanningPokerIssue[],
		noKey: IPlanningPokerIssue[], nothingToUpdate: IPlanningPokerIssue[]
	}>({updated: [], failed: [], noKey: [], nothingToUpdate: []});
	const [errorDictionary, setErrorDictionary] = useState<Array<{key: string, message: string}>>([]);

	const [disableExportButton, setDisableExportButton] = useState(true);

	const cancelAction = () => {
		isCancelled = true;

		if (onCancel) {
			onCancel();
		}
	};

	useOnClickOutside(ref, () => cancelAction());

	const onEscape = (event: KeyboardEvent) => {
			if (event.key === "Escape") {
				cancelAction();
			}
		};

	useEffect(() => {
		document.addEventListener("keydown", onEscape, false);

		return () => {
			document.removeEventListener("keydown", onEscape, false);
		};
	}, []);

	const options = jiraFieldsList.map((x: { name: string; customId?: string; custom?: string; system?: string }) =>
		createFieldListOption(x.name, x.customId, x.custom, x.system));

	const onUserClickedExport = async () => {
		isCancelled = false;
		mixpanel?.track(MixpanelEventType.UserExportedIssuesFromPoker);
		setUIState(UIState.Updating);

		for (const issue of issues) {
			if (isCancelled) {
				break;
			}

			try {
				if (issue.key.length === 0 || issue.key.startsWith(FAKE_ISSUE_KEY_PREFIX)) {
					setUpdateState((currState) => ({
						updated: currState.updated.slice(0), failed: currState.failed.slice(0),
						noKey: currState.noKey.concat(issue), nothingToUpdate: currState.nothingToUpdate.slice(0)
					}));
					continue;
				}

				if (!issue.estimation && !issue.note) {
					setUpdateState((currState) => ({
						updated: currState.updated.slice(0), failed: currState.failed.slice(0),
						noKey: currState.noKey.slice(0), nothingToUpdate: currState.nothingToUpdate.concat(issue)
					}));
					continue;
				}

				if (issue.estimation) {
					await onUpdateJiraEstimate(integrationId, issue.key, selectJiraField,
						isNaN(parseInt(issue.estimation, 10)) ? "string" : "number", issue.estimation);
				}

				if (issue.note) {
					await onAddJiraComment(integrationId, issue.key, issue.note);
				}

				setUpdateState((currState) => ({
					updated: currState.updated.concat(issue), failed: currState.failed.slice(0),
					noKey: currState.noKey.slice(0), nothingToUpdate: currState.nothingToUpdate.slice(0)
				}));
			} catch (e: any) {
				setUpdateState((currState) => ({
					updated: currState.updated.slice(0), failed: currState.failed.concat(issue),
					noKey: currState.noKey.slice(0), nothingToUpdate: currState.nothingToUpdate.slice(0)
				}));
				setErrorDictionary((currDict) => (currDict.concat({ key: issue.key, message: e.message })));
			}
		}

		setUIState(UIState.UpdateFinished);
	};

	return (
		<div
			className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 backdrop-blur-sm backdrop-brightness-50 bg-black/30"
			onClick={(e)=> {
				e.stopPropagation();
			}}
		>
			<div className="relative h-full w-full max-w-2xl p-4 md:h-auto">
				<div
					className="relative bg-[#FFFFFF] w-[673px] h-[483px] border border-[#CED5DF] rounded-lg shadow"
					ref={ref}
				>
					<button
						type="button"
						className="absolute top-3 right-2.5 ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
						onClick={() => cancelAction()}
					>
						<CloseButton className="h-5 w-5" />
					</button>
					<div className="my-8 mx-12">
					{(uiState === UIState.FieldSelection) && (
						<div>
							<div
								className="text-[32px] text-semibold"
							>
								Just one more step...
							</div>
							<hr
								className="mt-4 mb-4"
							/>
							<div>
								<div
									className="block text-base text-semibold mb-3"
								>
									Choose the estimation field you're using in Jira
								</div>
								<Select
									options={options}
									onChange={(selection) => {
										setSelectedJiraField(selection!.value);
										setDisableExportButton(false);
									}}
								/>
							</div>
							<div
								className="mt-16 flex items-center justify-between"
							>
								<button
									type="button"
									className={classnames(
										"text-base",
										"items-center",
										"justify-center",
										"rounded transition duration-200 ease",
										"font-semibold",
										"w-[268px]",
										"h-[50px]",
										"bg-gradient-to-r from-[#16375E] to-[#ED51A3] text-white p-0.5",
									)}
									onClick={(e)=> {
										e.stopPropagation();
										cancelAction();
									}}
								>
									<div className=" h-full w-full bg-white text-black flex items-center justify-center">
										<div
											className="text-transparent bg-clip-text bg-gradient-to-r from-[#16375E] to-[#ED51A3]"
										>
											Cancel
										</div>
									</div>
								</button>
								<button
									type="button"
									className={classnames(
										"text-base",
										"items-center",
										"justify-center",
										"rounded transition duration-200 ease",
										"font-semibold",
										"w-[268px]",
										"h-[50px]",
										"bg-gradient-to-r from-[#16375E] to-[#ED51A3] text-white",
									)}
									onClick={async (e)=> {
										e.stopPropagation();
										await onUserClickedExport();
									}}
									disabled={disableExportButton}
								>
									Save and continue
								</button>
							</div>
						</div>
					)}
					{ uiState === UIState.Updating && (
						<div>
							<div
								className="text-[32px] text-semibold"
							>
								Exporting estimations
							</div>
							<hr
								className="mt-4 mb-4"
							/>
							<Player
								autoplay={true}
								loop={true}
								src={LoadingLottie}
							/>
							<div>
								<ProgressBar
									min={0}
									max={100}
									now={updateState.failed.length + updateState.updated.length/issues.length}
								/>
							</div>
							<div
								className="flex text-center items-center justify-center mt-4 text-base font-semibold"
							>
								{updateState.failed.length + updateState.updated.length + updateState.noKey.length + updateState.nothingToUpdate.length}/{issues.length} complete
							</div>
						</div>
					)}
					{uiState === UIState.UpdateFinished && updateState.updated.length > 0 && (
						<div>
							<div
								className="text-[32px] text-semibold"
							>
								Export completed!
							</div>
							<hr
								className="mt-4"
							/>
							<div
								className="flex text-center items-center justify-center"
							>
								<Player
									autoplay={true}
									loop={true}
									src={DoneLottie}
									style={{height: "170px"}}
								/>
							</div>
							<div
								className="text-[32px] flex text-center items-center justify-center text-[#417CE2]"
							>
								{updateState.updated.length} out of {issues.length} issues updated
							</div>
							{updateState.failed.length > 0 && (
								errorDictionary.map(x =>
									<div
										className="flex text-center items-center justify-center"
										key={x.key}
									>
										{x.message}
									</div>)
								)}
						</div>
					)}
					{uiState === UIState.UpdateFinished && updateState.updated.length === 0 && (
						<div>
							<div
								className="text-[32px] text-semibold"
							>
								Export failed
							</div>
							<hr
								className="mt-4"
							/>
							<div
								className="flex text-center items-center justify-center"
							>
								<Player
									autoplay={true}
									loop={true}
									src={ErrorLottie}
								/>
							</div>
							{errorDictionary.map(x =>
								<div
									className="flex text-center items-center justify-center"
									key={x.key}
								>
									{x.message}
								</div>)
							}
						</div>
					)}
					{uiState === UIState.UpdateFinished && (updateState.noKey.length > 0 || updateState.nothingToUpdate.length > 0) && (
						<div>
							<div className="flex text-center items-center justify-center">
								Pay attention:&nbsp;
								{updateState.noKey.length > 0 && (
									<div>{updateState.noKey.length} issues have no key,&nbsp;</div>
								)}
								{updateState.nothingToUpdate.length > 0 && (
									<div>{updateState.nothingToUpdate.length} issues have no estimate/note</div>
								)}
							</div>
						</div>

					)}
					</div>
				</div>
			</div>
		</div>
	);
}
