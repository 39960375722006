import React from "react";
import classNames from "classnames";
import { Popup } from "semantic-ui-react";

import { TaskEstimationMethod } from "@acumen/database-types";
import infoIcon from "../../../components/svg-assets/info-icon.svg";
import { Formatters } from "../../../components/dashboard-task/formatters";
import { TASK_ESTIMATION_METHOD_SHORT_DISPLAY_NAMES } from "../../../../localization/text-records";

interface TabCardProps {
	title: string;
	tooltip?: string;
	value: number | string;
	estimationMethod?: TaskEstimationMethod;
	isActive?: boolean;
	isDisabled?: boolean;
	isLoading?: boolean;
	percentage?: boolean;
}

const TabCard = (props: TabCardProps) => {
	const { title, tooltip, value, estimationMethod, isDisabled = false, isActive = true, percentage = false, isLoading = false} = props;

	return (
		isLoading
		?
			<div className="ui active loader"/>
		:
			<div className={classNames("card-body", {"active-card": isActive})} >
				<div className="spaced">
					<div className="card-text acu-capitalize" dangerouslySetInnerHTML={{ __html: title}}/>
					{tooltip &&
						<Popup
							hoverable={true}
							wide={true}
							position="top center"
							content={tooltip}
							trigger={
								<img src={infoIcon} className="info-icon" alt="" />
							}
						/>
					}
				</div>
				<div className="flex-row card-data">
					<div className={classNames("colored-data card-value", { "disabled-value": isDisabled, "active": isActive })}>
						{estimationMethod ? Formatters.estimationValue(value, estimationMethod, null, true, false) : value}
					</div>
					{estimationMethod &&
						<span className="ellipsis">
							{TASK_ESTIMATION_METHOD_SHORT_DISPLAY_NAMES[estimationMethod]}
						</span>
					}
					{percentage &&
						<span className="ellipsis">
							%
						</span>
					}
				</div>
			</div>
	);
};

export default TabCard;
