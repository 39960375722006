import _ from "lodash";
import React, { useMemo } from "react";
import { IPRData } from "../type";
import { PRFilterItem } from "./pr-filter-item/pr-filter-item";
import "./pr-filter.scss";

export enum PRFilterType {
	NoReviewTime = "NoReviewTime",
	LongestPRs = "LongestPRs",
	ShortestPRs = "ShortestPRs"
}

const MAX_ITEM_TO_CAP_ON = 10;

const FILTER_TYPE_TO_LABEL: Record<PRFilterType, string> = {
	[PRFilterType.NoReviewTime]: "No review time",
	[PRFilterType.LongestPRs]: "Longest PRs",
	[PRFilterType.ShortestPRs]: "Shortest PRs"
};

export function filterPRsByType(prs: IPRData[], filter: PRFilterType | undefined): IPRData[] {
	if (!filter) {
		return prs;
	}

	if (filter === PRFilterType.LongestPRs || filter === PRFilterType.ShortestPRs) {
		const sortedByCycleTime = prs.sort((a, b) => b["cycle time (open-to-merge in hours)"] - a["cycle time (open-to-merge in hours)"]);

		if (filter === PRFilterType.LongestPRs) {
			return sortedByCycleTime.slice(0, MAX_ITEM_TO_CAP_ON);
		}
		return sortedByCycleTime.slice(sortedByCycleTime.length - MAX_ITEM_TO_CAP_ON);
	}
	if (filter === PRFilterType.NoReviewTime) {
		return _.filter(prs, d => {
			const reviewInHours = d["in review (in hours)"];
			return (!reviewInHours || reviewInHours === 0);
		});
	}
	return prs;
}

export interface IPRFilterProps {
	prs: IPRData[];
	selectedFilterItem: PRFilterType | undefined;
	onSelectedFilterItem: (filter: PRFilterType | undefined) => void;
}

export const PRFilter = (props: IPRFilterProps) => {
	const { onSelectedFilterItem, selectedFilterItem, prs } = props;
	const filterToValue = useMemo<Record<PRFilterType, number>>(() => {
		const mapping: Record<PRFilterType, number> = {
			[PRFilterType.NoReviewTime]: 0,
			[PRFilterType.LongestPRs]: 0,
			[PRFilterType.ShortestPRs]: 0,
		};
		prs.forEach(pr => {
			const reviewTimeHours = pr["in review (in hours)"];
			if (reviewTimeHours === null || reviewTimeHours === 0) {
				mapping.NoReviewTime += 1;
			}
		});
		mapping.LongestPRs = Math.min(prs.length, MAX_ITEM_TO_CAP_ON);
		mapping.ShortestPRs = Math.min(prs.length, MAX_ITEM_TO_CAP_ON);
		return mapping;
	}, [prs]);

	return (
		<div className="pr-filter">
			{Object.keys(PRFilterType).map((t, index) => {
				const type = t as PRFilterType;
				const name = FILTER_TYPE_TO_LABEL[type];
				const value = filterToValue ? filterToValue[type] : 0;
				const isSelected = selectedFilterItem !== undefined && selectedFilterItem === type;
				return <PRFilterItem
					key={`pr-filter-item-${index}`}
					name={name}
					value={value}
					isSelected={isSelected}
					onClick={() => {
						if (!isSelected) {
							onSelectedFilterItem(type);
						} else {
							onSelectedFilterItem(undefined);
						}
					}} />;
			})}
		</div>
	);
};
