import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import ReactSelect, {
	components,
	ControlProps,
	IndicatorProps,
	MenuProps,
	OptionProps,
	OptionTypeBase,
	ValueContainerProps,
} from "react-select";
import classNames from "classnames";
import _ from "lodash";
import checkedCheckbox from "../svg-assets/checkbox.svg";
import uncheckedCheckbox from "../svg-assets/bg-checkbox.svg";
import "./chip-multiselect.scss";
import TriggerOnClickOutside from "../ux-effects/trigger-on-click-outside";
import { createPortal } from "react-dom";
import { useTopPosition } from "../../../hooks/useTopPosition";
import { useLeftPosition } from "../../../hooks/useLeftPosition";

export interface ChipMultiselectProps<T> {
	showSearchIcon?: boolean;
	searchPlaceholder?: string;
	addOptionsLabel: string;
	staticValues?: string[];
	value: Array<ChipMultiselectOption<T>> | ReadonlyArray<ChipMultiselectOption<T>>;
	options: Array<ChipMultiselectOption<T>> | ReadonlyArray<ChipMultiselectOption<T>>;
	allowEmpty?: boolean;
	onChange: (value: Array<ChipMultiselectOption<T>>) => void;
}

export interface ChipMultiselectOption<T> {
	label: React.ReactNode;
	value: T;
	key: string;
}

const ChipMultiselectContext = React.createContext({
	showSearchIcon: false,
	searchPlaceholder: "Search...",
	addOptionsLabel: "Select",
	searchTerm: "",
	targetRef: { current: null as HTMLDivElement | null },
	staticValues: [] as string[],
	onSearchTermChange: (value: string): void => void 0,
	onOpen: (): void => void 0,
	onClose: (): void => void 0,
});

const ChipMultiselectIndicatorContainerContext = React.createContext(null as React.ReactElement | null);

const ChipMultiselect = <T extends any>(props: ChipMultiselectProps<T>) => {
	const { staticValues = [], showSearchIcon = false, addOptionsLabel, value, options, allowEmpty = false, onChange } = props;
	const { searchPlaceholder = "Search..." } = props;
	const [searchTerm, onSearchTermChange] = useState("");
	const [isOpen, setIsOpen] = useState(false);
	const open = useCallback(() => setIsOpen(true), []);
	const close = useCallback(() => setIsOpen(false), []);
	const targetRef = useRef<HTMLDivElement>(null);
	const context = useMemo(() => ({
		staticValues,
		showSearchIcon,
		searchPlaceholder,
		addOptionsLabel,
		searchTerm,
		onSearchTermChange,
		onOpen: open,
		onClose: close,
		targetRef,
	}), [staticValues, showSearchIcon, searchPlaceholder, addOptionsLabel, searchTerm, onSearchTermChange, open]);
	const filteredOptions = useFilteredOptions({ options, searchTerm });
	const styles = useMemo(() => createStyles({
		hideChipRemovalButton: !allowEmpty && (!value || !_.isArray(value) || value.length < 2),
	}), [allowEmpty, value]);
	const handleChange = useCallback((selection: unknown) => {
		if (selection && _.isArray(selection) && (allowEmpty || selection.length > 0)) {
			onChange(selection);
		}
	}, [value, allowEmpty, onChange]);

	return (
		<ChipMultiselectContext.Provider value={context}>
			<ReactSelect
				className={classNames("form-control is-valid chip-multiselect")}
				isDisabled={false}
				isClearable={false}
				components={selectComponents}
				multiValueRemove={false}
				isMulti={true}
				hideSelectedOptions={false}
				closeMenuOnSelect={false}
				controlShouldRenderValue={true}
				options={filteredOptions}
				value={value}
				onChange={handleChange}
				placeholder=""
				styles={styles}
				isSearchable={false}
				openMenuOnClick={false}
				menuIsOpen={isOpen}
			/>
		</ChipMultiselectContext.Provider>
	);
};

const createStyles = ({ hideChipRemovalButton }: {
	hideChipRemovalButton: boolean
}) => ({
	control: (provided: any) => ({
		...provided,
		outline: "none",
		boxShadow: "none",
		border: "none",
		width: "auto",
		justifyContent: "flex-start",
		minHeight: 0,
	}),
	multiValue: (provided: any) => ({
		...provided,
		border: "1px solid #142E5A",
		boxSizing: "border-box",
		borderRadius: "2px",
		background: "white",
	}),
	multiValueLabel: (provided: any) => ({
		...provided,
		background: "white",
		color: "#142E5A",
		fontSize: "14px"
	}),
	multiValueRemove: (provided: any) => ({
		...provided,
		"color": "#142E5A",
		"&:hover": {
			background: "white",
			color: "#ED51A3"
		},
		"> svg": {
			display: hideChipRemovalButton ? "none" : "inline-block",
		},
	}),
	menu: (provided: any) => ({
		...provided,
		minWidth: "320px",
		width: "max-content",
		right: "0px",
		background: "white",
		border: "1px solid #CED5DC",
		boxShadow: "0px 4px 16px rgba(20, 46, 90, 0.25)",
		borderRadius: "4px",
	}),
	menuList: (provided: any) => ({
		...provided,
		maxHeight: "270px",
	}),
	valueContainer: (provided: any) => ({
		...provided,
		"padding": 0,
		"flexGrow": "initial",
		"flexShrink": "initial",
		"flexBasis": "auto",
		"> input": {
			maxWidth: 0,
			maxHeight: 0,
			position: "fixed",
			left: "-100vw",
		}
	}),
	indicatorsContainer: (provided: any) => ({
		...provided,
		"alignSelf": "center",
		" > div": {
			padding: 0,
		}
	}),
	option: (provided: any) => ({
		...provided,
		"padding": "10.5px 16px",
		"background": "white",
		"color": "#596566",
		"fontStyle": "normal",
		"fontWeight": "400",
		"fontSize": "16px",
		"lineHeight": "19px",
		"&:hover": {
			background: "#F0F3F4"
		}
	})
});

const DropdownIndicator = <T extends any>(props: IndicatorProps<ChipMultiselectOption<T>, true>) => {
	const { addOptionsLabel, targetRef, onOpen } = useContext(ChipMultiselectContext);

	return (
		<components.DropdownIndicator {...props}>
			<p className="clickable chip-multiselect-dropdown-button" ref={targetRef} onClick={onOpen}>
				<span className="chip-multiselect-dropdown-button-icon">+</span>
				<span className="chip-multiselect-dropdown-button-label">{addOptionsLabel}</span>
			</p>
		</components.DropdownIndicator>
	);
};

const Option = <T extends any>(props: OptionProps<ChipMultiselectOption<T>, true>) => {
	const { staticValues } = useContext(ChipMultiselectContext);
	const iconSrc = props.isSelected ? checkedCheckbox : uncheckedCheckbox;
	const isStaticOption = useMemo(() => staticValues.includes(props.data.value), [staticValues]);

	return (
		<components.Option
			{...props}
			className={classNames(props.className, "chip-multiselect-option-wrapper")}
			innerProps={{
				...props.innerProps,
				onClick: isStaticOption ? noop : props.innerProps.onClick,
			}}
		>
			<div className="chip-multiselect-option">
				<img className="chip-multiselect-option-icon" src={iconSrc} alt=""/>
				<span className="chip-multiselect-option-label">{props.label}</span>
			</div>
		</components.Option>
	);
};

const IndicatorSeparator = () => null;

const noop = () => null;

const DROPDOWN_TOP_MARGIN_FROM_TARGET = 0;
const DROPDOWN_BOTTOM_MARGIN_FROM_TARGET = 20;
const PREFERRED_DROPDOWN_MIN_X_MARGIN = 32;
const Menu = <T extends any>(props: MenuProps<ChipMultiselectOption<T>, true>) => {
	const { innerProps, ...restProps } = props;
	const { onMouseDown, ...restInnerProps } = innerProps as Record<string, unknown>;
	const {
		showSearchIcon,
		searchPlaceholder,
		targetRef,
		searchTerm,
		onSearchTermChange,
		onClose,
	} = useContext(ChipMultiselectContext);
	const handleSearchTermChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => onSearchTermChange(event.currentTarget.value),
		[]
	);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const searchInputRef = useRef<HTMLInputElement>(null);
	const top = useTopPosition({
		elementRef: dropdownRef,
		targetRef,
		topMarginFromTarget: DROPDOWN_TOP_MARGIN_FROM_TARGET,
		bottomMarginFromTarget: DROPDOWN_BOTTOM_MARGIN_FROM_TARGET,
	});
	const left = useLeftPosition({
		elementRef: dropdownRef,
		targetRef,
		preferredMinimumMargin: PREFERRED_DROPDOWN_MIN_X_MARGIN,
		align: "left",
	});

	const stopPropagation = (e: React.MouseEvent | React.KeyboardEvent) => e.stopPropagation();

	useEffect(() => {
		searchInputRef.current?.focus();
	}, []);

	return createPortal((
		<components.Menu
			{...restProps}
			innerRef={dropdownRef}
			innerProps={{
				...restInnerProps,
				ref: dropdownRef,
				style: { top, left, visibility: top && left ? "visible" : "hidden" },
			}}
			className={classNames(restProps.className, "chip-multiselect-dropdown")}
		>
			<TriggerOnClickOutside onTrigger={onClose}>
				<div>
					<div className="chip-multiselect-search-field">
						{showSearchIcon && <span className="search-icon"/>}
						<input
							ref={searchInputRef}
							type="search"
							placeholder={searchPlaceholder}
							className="chip-multiselect-search-input"
							value={searchTerm}
							onChange={handleSearchTermChange}
							onKeyDownCapture={stopPropagation}
						/>
					</div>
					{props.children}
				</div>
			</TriggerOnClickOutside>
		</components.Menu>
	), document.body);
};

const MultiValueLabel = (props: React.ComponentProps<typeof components.MultiValueLabel>) => {
	const { staticValues } = useContext(ChipMultiselectContext);
	const isStaticOption = useMemo(() => staticValues.includes(props.data.value), [staticValues]);

	return (
		<components.MultiValueLabel
			{...props}
			innerProps={{
				...props.innerProps,
				className: classNames(
					props.innerProps.className,
					isStaticOption && "chip-multiselect-static-value-label"
				)
			}}
		>
			{props.children}
		</components.MultiValueLabel>
	);
};

const MultiValueRemove = (props: React.ComponentProps<typeof components.MultiValueRemove>) => {
	const { staticValues } = useContext(ChipMultiselectContext);
	const isStaticOption = useMemo(() => staticValues.includes(props.data.value), [staticValues]);

	return (
		<>
			{!isStaticOption && (
				<components.MultiValueRemove {...props}>
					{props.children}
				</components.MultiValueRemove>
			)}
		</>
	);
};

const Control = <T extends OptionTypeBase>(props: ControlProps<T, true>) => {
	const children = React.Children.toArray(props.children);
	const indicatorContainer = children.find(child => React.isValidElement(child) && child.type === components.IndicatorsContainer);
	const restChildren = children.filter(child => child !== indicatorContainer);

	return (
		<ChipMultiselectIndicatorContainerContext.Provider value={indicatorContainer as React.ReactElement}>
			<components.Control {...props}>
				{restChildren}
			</components.Control>
		</ChipMultiselectIndicatorContainerContext.Provider>
	);
};

const ValueContainer = <T extends OptionTypeBase>(props: ValueContainerProps<T, true>) => {
	const indicatorContainer = useContext(ChipMultiselectIndicatorContainerContext);

	return (
		<components.ValueContainer {...props}>
			{props.children}
			{indicatorContainer}
		</components.ValueContainer>
	);
};

const selectComponents = {
	Control,
	DropdownIndicator,
	IndicatorSeparator,
	Option,
	Menu,
	MultiValueRemove,
	MultiValueLabel,
	ValueContainer,
};

const useFilteredOptions = <T extends any>(props: {
	options: ReadonlyArray<ChipMultiselectOption<T>>
	searchTerm: string;
}): Array<ChipMultiselectOption<T>> => {
	const { options, searchTerm } = props;

	return useMemo(() => {
		const lowerCaseSearchTerm = searchTerm.toLowerCase();

		return options.filter(option => option.label?.toString().toLowerCase().includes(lowerCaseSearchTerm));
	}, [options, searchTerm]);
};

export default ChipMultiselect;
