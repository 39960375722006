import React from "react";
import GitHubIntegrationFlow, { IProps as IGitHubProps } from "./integration-flows/github-integration-flow";
import SlackIntegrationFlow, { IProps as ISlackProps } from "./integration-flows/slack-integration-flow";
import JiraIntegrationFlow, { IProps as IJiraProps, JiraIntegrationType } from "./integration-flows/jira-integration-flow";
import { DashboardIntegrationType } from "@acumen/dashboard-common";

export interface IDashboardIntegrationProvider {
	key: string;
	logo: string;
	title: string;
	active: boolean;
	integrationType: DashboardIntegrationType | null;
	component?: (props: any) => JSX.Element;
}

// tslint:disable-next-line:variable-name
export const IntegrationProviders: IDashboardIntegrationProvider[] = [
	{
		key: "github",
		logo: "/assets/images/integration-provider-images/logo-github.svg",
		title: "GitHub",
		active: true,
		integrationType: DashboardIntegrationType.GitHub,
		component: (props: IGitHubProps) => <GitHubIntegrationFlow {...props} />
	},
	{
		key: "jira-cloud",
		logo: "/assets/images/integration-provider-images/logo-jira-cloud.svg",
		title: "JIRA Cloud",
		active: true,
		integrationType: DashboardIntegrationType.JIRACloud,
		component: (props: IJiraProps) => <JiraIntegrationFlow {...props} jiraIntegrationType={JiraIntegrationType.Cloud} />
	},
	{
		key: "jira-server",
		logo: "/assets/images/integration-provider-images/logo-jira-server.svg",
		title: "JIRA Server",
		active: true,
		integrationType: DashboardIntegrationType.JIRAServer,
		component: (props: IJiraProps) => <JiraIntegrationFlow {...props} jiraIntegrationType={JiraIntegrationType.Server} />
	},
	{
		key: "slack",
		logo: "/assets/images/integration-provider-images/logo-slack.svg",
		title: "Slack",
		active: true,
		integrationType: DashboardIntegrationType.Slack,
		component: (props: ISlackProps) => <SlackIntegrationFlow {...props} />
	},
	{
		key: "bitbucket",
		logo: "/assets/images/integration-provider-images/logo-bitbucket.svg",
		title: "BitBucket",
		integrationType: null,
		active: false
	},
	{
		key: "gitlab",
		logo: "/assets/images/integration-provider-images/logo-gitlab.svg",
		title: "GitLab",
		integrationType: null,
		active: false
	},
	{
		key: "trello",
		logo: "/assets/images/integration-provider-images/logo-trello.svg",
		title: "Trello",
		integrationType: null,
		active: false
	},
	{
		key: "generic",
		logo: "/assets/images/integration-provider-images/logo-generic-repository.svg",
		title: "Generic Repository",
		integrationType: null,
		active: false
	}
];

export default IntegrationProviders;
