import { getData } from "../../../services/fetch-helpers";
import { BaseCustomerApiClient } from "./base-customer-api-client";
import { DashboardSortOrder, IDashboardComponent, ITeamComponents } from "@acumen/dashboard-common";
import { qsStringify } from "../../../services/url-routes-helper";

export const COMPONENTS_ROUTE = "components";

export interface IComponentFilter {
	teamId?: string;
}

export interface IComponentSort {
	order: DashboardSortOrder;
}

export class ComponentsApiClient extends BaseCustomerApiClient {
	public fetchComponents = async () => {
		return await getData<IDashboardComponent[], any>(
			this.createCustomerEntityRoute(COMPONENTS_ROUTE),
			this.token
		);
	}

	public fetchComponentsByFilter = async (filter?: IComponentFilter, sort?: IComponentSort) => {
		const query: Record<string, string> = {};
		if (filter?.teamId) {
			query.teamId = filter?.teamId;
		}

		if (sort?.order) {
			query.sortOrder = sort.order;
		}

		return await getData<ITeamComponents, any>(
			this.createCustomerEntityRoute(`${COMPONENTS_ROUTE}?${qsStringify(query)}`),
			this.token
		);
	}
}
