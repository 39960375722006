import React from "react";
import DeveloperBadgeBreakdownEmptyStateImage from "./../../../../components/svg-assets/developer-badge-breakdown-empty-state.svg";
import "./developer-badge-breakdown-empty-state.scss";

export const DeveloperBadgeBreakdownEmptyState = () => {
	return (
		<div className="developer-badge-breakdown-empty-state">
			<div className="developer-badge-breakdown-empty-state-image">
				<img className="emptyStateIcon" src={DeveloperBadgeBreakdownEmptyStateImage} alt="No badges to display" />
			</div>
			<div className="developer-badge-breakdown-empty-state-text">
				This team member doesn't have any badges yet
			</div>
		</div>
	);
};
