import React from "react";
import InfoIconThin from "v2/components/svg-assets/info-icon-thin.svg";
import "./info-icon.scss";

export interface InfoIconProps extends React.ComponentProps<"img"> {
}

export const InfoIcon = (props: InfoIconProps) => {
	return (
		<img
			className="info-icon"
			src={InfoIconThin}
			alt="Info"
			{...props}
		/>
	);
};
