import { round } from "@acumen/common";
import React, { useMemo } from "react";
import { TeamViewModel } from "../../types";
import {
	PrCycleTimeForTeamBreakdownItem
} from "../pr-cycle-time-for-team-breakdown-item/pr-cycle-time-for-team-breakdown-item";
import "./pr-cycle-time-for-team.scss";

export interface PrCycleTimeForTeamProps {
	team: TeamViewModel;
	longestCycleTimeInSeconds: number;
}

export const PrCycleTimeForTeam = (props: PrCycleTimeForTeamProps) => {
	const { team, longestCycleTimeInSeconds } = props;
	const totalCycleTimeInSeconds = useMemo(() => team.cycleTimeBreakdown
		.reduce((sum, item) => sum + item.totalSeconds, 0), [team]);
	const totalCycleTimeInHours = round(totalCycleTimeInSeconds / 3600);

	return (
		<div className="pr-cycle-time-for-team">
			<div className="pr-cycle-time-for-team-info">
				<div className="pr-cycle-time-for-team-info-name">
					{team.info.name}
				</div>
				<div className="pr-cycle-time-for-team-info-members-count">
					{team.info.members.length} members
				</div>
			</div>

			<div className="pr-cycle-time-for-team-breakdown">
				<div className="pr-cycle-time-for-team-breakdown-total-time">
					{totalCycleTimeInHours} hours
				</div>
				<div className="pr-cycle-time-for-team-breakdown-items">
					{team.cycleTimeBreakdown.map((item, index) => (
						<PrCycleTimeForTeamBreakdownItem
							key={index}
							item={item}
							longestCycleTimeInSeconds={longestCycleTimeInSeconds}
						/>
					))}
				</div>
			</div>
		</div>
	);
};
