import { AcumenIdentityType, IDashboardDataContributorLink, IDashboardDetailedDataContributor, IdentityType } from "@acumen/dashboard-common";
import classNames from "classnames";
import { Avatar } from "../../../components/avatar";
import { columnSortStates, TwoWaysSortButton } from "../../../components/buttons/icon-buttons";
import _ from "lodash";
import { ContributorsTableColumnType, ContributorTableFilterType } from "../../../mobx-stores/data-contributors-store";
import moment from "moment";
import React, { useState } from "react";
import { Pagination } from "semantic-ui-react";
import { IContributorsActions } from ".";

interface IContributorsTable {
	contributors: IDashboardDetailedDataContributor[];
	action?: IContributorsActions;
	page: number;
	itemsPerPage?: number;
	setPage?: React.Dispatch<React.SetStateAction<number>>;
	sortDataContributors: (filterBy: ContributorsTableColumnType, sortAscending?: boolean) => void;
}

const sortColumnsDefaultState: Record<ContributorsTableColumnType, columnSortStates> = {
	[ContributorTableFilterType.lastActivity]: undefined,
	[ContributorTableFilterType.contributorsName]: true,
	[IdentityType.ACUMEN]: undefined,
	[IdentityType.GITHUB_COLLABORATOR]: undefined,
	[IdentityType.GITHUB_CONTRIBUTOR]: undefined,
	[IdentityType.JIRA]: undefined,
	[IdentityType.SLACK]: undefined
};

// tslint:disable-next-line: variable-name
const ContributorsTable = (props: IContributorsTable) => {

	const { contributors, action, page, setPage, sortDataContributors, itemsPerPage = 100 } = { ...props };
	const [expandedRows, setExpandedRows] = useState<Map<string, boolean>>(new Map());
	const [columnSortedState, setSortedColumns] = useState<Record<ContributorsTableColumnType, columnSortStates>>(sortColumnsDefaultState);

	const setRow = (contributorId: string, state: boolean) => {
		expandedRows.set(contributorId, state);
		setExpandedRows(new Map(expandedRows));
	};

	const toggleRow = (contributorId: string) => {
		setRow(contributorId, !expandedRows.get(contributorId));
	};

	const sortOnColumnClick = (column: ContributorsTableColumnType) => {
		setSortedColumns({
			...sortColumnsDefaultState,
			[ContributorTableFilterType.contributorsName]: undefined,
			[column]: !columnSortedState[column]
		});
		sortDataContributors(column, !columnSortedState[column]);
	};

	const getColumnHeader = (column: AcumenIdentityType) => (
		<th className="two wide clickable"
			onClick={() => sortOnColumnClick(column)}
		>
			<div className="clickable flex-spaced">
				<span>
					{identityTypeDisplayName[column]}
				</span>
				<TwoWaysSortButton
					columnSortedUp={columnSortedState[column]}
				/>
			</div>
		</th>
	);

	const from = page === 1 ? 0 : ((page - 1) * itemsPerPage);
	const to = (((page) * itemsPerPage));
	return (
		<table className="ui very compact single line small stackable table set-page-z-index">
			<thead className="full-width">
				<tr className="un-selectable">
					<th className="table-actions " />
					<th className="clickable"
						onClick={() => sortOnColumnClick(ContributorTableFilterType.contributorsName)}>
						<div className="clickable flex-spaced">
							<span className="acu-capitalize">contributors</span>
							<TwoWaysSortButton
								columnSortedUp={columnSortedState[ContributorTableFilterType.contributorsName]}
							/>
						</div>
					</th>
					{getColumnHeader(IdentityType.ACUMEN)}
					{getColumnHeader(IdentityType.GITHUB_CONTRIBUTOR)}
					{getColumnHeader(IdentityType.JIRA)}
					{getColumnHeader(IdentityType.SLACK)}
					<th className="two wide clickable"
						onClick={() => sortOnColumnClick(ContributorTableFilterType.lastActivity)}>
						<div className="clickable flex-spaced">
							<span className="acu-capitalize">last activity</span>
							<TwoWaysSortButton
								columnSortedUp={columnSortedState[ContributorTableFilterType.lastActivity]}
							/>
						</div>
					</th>
				</tr>
			</thead>
			{contributors.slice(from, to).map((row: IDashboardDetailedDataContributor, i: number) => {
				const isRowExpanded = !!expandedRows.get(row.id);
				return (
					<tbody key={i}>
						<tr className="hoverable-container clickable" onClick={() => toggleRow(row.id)}>
							<td >
								{sideArrow(isRowExpanded)}
								{action &&
									<button
										style={{ margin: "auto 10px", padding: "10px", minWidth: "35px" }}
										className={classNames("ui button tiny", action.style,
											{ hoverable: !isRowExpanded })}
										onClick={(e) => {
											e.stopPropagation();
											setRow(row.id, false);
											action.action(row);
										}}
									>
										{action.type}
									</button>}
							</td>
							<td>
								<h4 className="ui image header no-wrap">
									<Avatar
										className="avatar acu-size"
										user={{
											displayName: row.primaryDisplayName ?? row.title,
											pictureUrl: row.primaryAvatarUrl
										}}
									/>
									<div className="content light">
										{row.primaryDisplayName}
									</div>
								</h4>
							</td>
							<td>
								{getIntegrationIcon(IdentityType.ACUMEN, row.links)}
							</td>
							<td>
								{getIntegrationIcon(IdentityType.GITHUB_COLLABORATOR, row.links)}
							</td>
							<td>
								{getIntegrationIcon(IdentityType.JIRA, row.links)}
							</td>
							<td>
								{getIntegrationIcon(IdentityType.SLACK, row.links)}
							</td>
							<td>
								{!_.isEmpty(row.lastActivity) && moment(row.lastActivity!).fromNow()}
							</td>
						</tr>
						<tr>
							{isRowExpanded && (
								<th className="no-padding" colSpan={8}>
									<table className="ui very single line small stackable inner table">
										<tbody>
											<tr className="thead _full-width" >
												<td className="one wide" />
												<td className="wide">Name</td>
												<td className="wide">Platform</td>
												<td className="eight wide">Email</td>
											</tr>
											{row.links.map((link, y: number) => (
												<tr key={y}>
													<td />
													<td>{link.displayName}</td>
													<td>{identityTypeDisplayName[link.identityType]}</td>
													<td>{link.emails.join(",")}</td>
												</tr>)
											)}
										</tbody>
									</table>
								</th>
							)}
						</tr>
					</tbody>
				);
			})}
			{itemsPerPage && setPage && contributors.length > itemsPerPage && (
				<tfoot>
					<tr>
						<td colSpan={8}>
							<div className="ui basic center aligned segment">
								<Pagination
									boundaryRange={0}
									defaultActivePage={page}
									ellipsisItem={null}
									firstItem={null}
									lastItem={null}
									siblingRange={1}
									totalPages={Math.ceil(contributors.length / itemsPerPage)}
									onPageChange={(_e, { activePage }) => setPage(activePage as number ?? 1)}
								/>
							</div>
						</td>
					</tr>
				</tfoot>
			)}
		</table>
	);
};
export default ContributorsTable;

const getIntegrationIcon = (identity: AcumenIdentityType, contributorLinks: IDashboardDataContributorLink[] = []) => {
	const matchingIdentities = identityTypeToCategoryIdentities[identity];
	const hasLink = (contributorLinks.find(cl => matchingIdentities.indexOf(cl.identityType) > -1) !== undefined);

	if (identity === IdentityType.ACUMEN) {
		return (
			<div className={classNames("icon acumen-icon", {
				active: hasLink,
			})} >
				<img src="/assets/images/acumen-logo-small.png" alt="" />
			</div>
		);
	}

	return (
		<i className={classNames("icon active-only", {
			active: hasLink,
			slack: identity === IdentityType.SLACK,
			jira: identity === IdentityType.JIRA,
			github: (identity === IdentityType.GITHUB_COLLABORATOR || identity === IdentityType.GITHUB_CONTRIBUTOR),
		})}
		/>
	);
};

const sideArrow = (isActive: boolean) => {
	return (
		isActive ? <i aria-hidden="true" className="chevron down icon tiny" /> :
			<i aria-hidden="true" className="chevron right icon tiny" />
	);
};

const identityTypeDisplayName: Record<AcumenIdentityType, string> = {
	[IdentityType.GITHUB_COLLABORATOR]: "GitHub",
	[IdentityType.GITHUB_CONTRIBUTOR]: "GitHub",
	[IdentityType.SLACK]: "Slack",
	[IdentityType.JIRA]: "Jira",
	[IdentityType.ACUMEN]: "Acumen"
};

export const identityTypeToCategoryIdentities: Record<AcumenIdentityType, AcumenIdentityType[]> = {
	[IdentityType.GITHUB_COLLABORATOR]: [IdentityType.GITHUB_COLLABORATOR, IdentityType.GITHUB_CONTRIBUTOR],
	[IdentityType.GITHUB_CONTRIBUTOR]: [IdentityType.GITHUB_COLLABORATOR, IdentityType.GITHUB_CONTRIBUTOR],
	[IdentityType.SLACK]: [IdentityType.SLACK],
	[IdentityType.JIRA]: [IdentityType.JIRA],
	[IdentityType.ACUMEN]: [IdentityType.ACUMEN]
};
