import React, { useState } from "react";
import { observer } from "mobx-react";
import "./style.scss";
import { useStores } from "v2/mobx-stores";
import MyUser from "v2/layout/navigation/my-user";
import {
	ConfigurationEntityType,
	CustomizableConfigurationCategories
} from "@acumen/dashboard-common";
import CustomizationFields from "v2/components/customize-configurations/customization-fields";
import classNames from "classnames";
import { IDashboardCustomizableValue } from "v2/mobx-stores/customization-store";
import _ from "lodash";
import { Menu } from "semantic-ui-react";

interface IBadgeConfigCategoryProps {
	category: CustomizableConfigurationCategories;
}

const BadgeConfigCategory = (props: IBadgeConfigCategoryProps) => {
	const {
		customizationStore: {
			updateConfigurationByCategory,
		}
	} = useStores();
	const { category } = props;

	const [changedFormFields, setChangedFormFields] = useState<Partial<IDashboardCustomizableValue>>({});
	const [isFormDirty, setFormDirty] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const saveCustomConfiguration = async () => {
		if (_.isEmpty(changedFormFields)) {
			return;
		}
		setIsLoading(true);
		// tslint:disable-next-line: no-floating-promises
		updateConfigurationByCategory(
			{ category, configs: changedFormFields },
			ConfigurationEntityType.CustomerDefault)
			.then(() => {
				setIsLoading(false);
				setFormDirty(false);
			});
	};
	return (
		<div className={classNames("ui segment raised")}>
			<div className={classNames("ui", "wide column", "form-padding")}>
				<div className={classNames("ui", "wide column", "form-padding")}>
					<CustomizationFields
						category={category}
						entityType={ConfigurationEntityType.CustomerDefault}
						onChange={(values: Partial<IDashboardCustomizableValue>) => {
							setFormDirty(true);
							setChangedFormFields(values);
						}}
						refreshPage={() => {
							return;
						}}
						showLoadingIndicator={true}
					/>
					<div className="full-width pull-right">
						<button
							type="submit"
							className={classNames("ui button",
								{ loading: isLoading, disabled: !isFormDirty }
							)}
							onClick={async (e) => {
								e.preventDefault();
								return saveCustomConfiguration();
							}}
						>Save</button>
					</div>
				</div>
			</div>
		</div>
	);
};

enum BadgeConfigLevel {
	DailyDevStats = "DailyDevStats",
	TeamBadges = "TeamBadges",
}

// tslint:disable-next-line: variable-name
const BadgeConfig = () => {
	const {
		developerStatsStore: {
			rerunBadgeCalculationTask
		}
	} = useStores();

	const [isRequestingBadgeRerun, setIsRequestingBadgeRerun] = useState<boolean>(false);
	const [activeTab, setActiveTab] = useState<BadgeConfigLevel>(BadgeConfigLevel.TeamBadges);

	return (
		<div className="badge-config-container ui fluid container">
			<div className="header-container">
				<div className="ui fluid grid">
					<div className="eight wide column">
						<h1 className="page-title">Badge Configuration</h1>
					</div>
					<div className="eight wide right aligned column">
						<MyUser />
					</div>
				</div>
			</div>

			<Menu secondary={true}>
				<Menu.Item
					name={"Daily developer stats"}
					active={activeTab === BadgeConfigLevel.DailyDevStats}
					onClick={() => setActiveTab(BadgeConfigLevel.DailyDevStats)}
				/>
				<Menu.Item
					name={"Team badges"}
					active={activeTab === BadgeConfigLevel.TeamBadges}
					onClick={() => setActiveTab(BadgeConfigLevel.TeamBadges)}
				/>
				<Menu.Menu position="right">
					<Menu.Item>
						<button
							type="submit"
							className={classNames("ui button",
								{ loading: isRequestingBadgeRerun, disabled: isRequestingBadgeRerun }
							)}
							onClick={async (e) => {
								e.preventDefault();
								if (isRequestingBadgeRerun) {
									return;
								}
								setIsRequestingBadgeRerun(true);
								try {
									await rerunBadgeCalculationTask();
								} finally {
									setIsRequestingBadgeRerun(false);
								}
							}}
						>Propagate changes</button>
					</Menu.Item>
				</Menu.Menu>
			</Menu>
			{activeTab === BadgeConfigLevel.DailyDevStats && <div className="ui grid">
				<div className="ui eight wide column">
					<BadgeConfigCategory category={CustomizableConfigurationCategories.DailyDevStatsBadgeGeneral} />
				</div>
				<div className="ui eight wide column">
					<BadgeConfigCategory category={CustomizableConfigurationCategories.DailyDevStatsBadgeVelocity} />
				</div>
				<div className="ui eight wide column">
					<BadgeConfigCategory category={CustomizableConfigurationCategories.DailyDevStatsBadgeHighPriorityTasks} />
				</div>
				<div className="ui eight wide column">
					<BadgeConfigCategory category={CustomizableConfigurationCategories.DailyDevStatsBadgePRCycleTime} />
				</div>
				<div className="ui eight wide column">
					<BadgeConfigCategory category={CustomizableConfigurationCategories.DailyDevStatsBadgePRSize} />
				</div>
				<div className="ui eight wide column">
					<BadgeConfigCategory category={CustomizableConfigurationCategories.DailyDevStatsBadgeReviewedPRs} />
				</div>
			</div>}

			{activeTab === BadgeConfigLevel.TeamBadges && <div className="ui grid">
				<div className="ui eight wide column">
					<BadgeConfigCategory category={CustomizableConfigurationCategories.TeamBadgesGeneral} />
				</div>
				<div className="ui eight wide column">
					<BadgeConfigCategory category={CustomizableConfigurationCategories.TeamBadgesVelocity} />
				</div>
				<div className="ui eight wide column">
					<BadgeConfigCategory category={CustomizableConfigurationCategories.TeamBadgesAvgPRAwaitingReviewTime} />
				</div>
				<div className="ui eight wide column">
					<BadgeConfigCategory category={CustomizableConfigurationCategories.TeamBadgesAvgPRSize} />
				</div>
				<div className="ui eight wide column">
					<BadgeConfigCategory category={CustomizableConfigurationCategories.TeamBadgesPRReviewersPercentBadge} />
				</div>
				<div className="ui eight wide column">
					<BadgeConfigCategory category={CustomizableConfigurationCategories.TeamBadgesWorkDistributionBugsPercentBadge} />
				</div>
				<div className="ui eight wide column">
					<BadgeConfigCategory category={CustomizableConfigurationCategories.TeamBadgesMTTRBadge} />
				</div>
			</div>}
		</div>
	);
};
export default observer(BadgeConfig);
