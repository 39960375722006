import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import "./style.scss";
import { useStores } from "v2/mobx-stores";
import { CONFIGURATION_KEYS, DashboardSortOrder, DateRangeType, getDatesFromTimeSpan, MetricInterval, SubtaskSprintStrategy, TimeUnitMetricInterval } from "@acumen/dashboard-common";
import { IChartScope } from "./charts/chart-scope";
import _ from "lodash";
import DropdownListSelector from "v2/components/form/dropdown-list-selector";
import MyUser from "v2/layout/navigation/my-user";
import MTTRByPriorityChart from "./charts/mttr-priority";
import IssueCycleTimeByTypeChart from "./charts/issue-cycle-time-by-type";
import ThroughputChart, { ThroughputSum } from "./charts/throughput";
import GitHubReportedDeploymentFrequency from "./charts/github-reported-deployment-frequency";
import FiltersSelector from "v2/components/filters-selector/filters-selector";
import { IDropdownOption, TimeFramesSelector, TIME_INTERVAL_OPTIONS_TO_LABEL } from "v2/components/filters-selector/sections/time-frames-selector";
import { clickEvent, GA_EVENT_ACTION, GA_EVENT_CATEGORY } from "v2/analytics-events";
import filtersSlice from "./filters-slice";
import { useHistory, useLocation } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { PrCycleTimeInPhases } from "./components/pr-cycle-time-in-phases/pr-cycle-time-in-phases";
import { OrgMetricsCard } from "./components/org-metrics-card/org-metrics-card";
import { createSearchParamsForPage } from "./pr-breakdown";
import { ROUTES } from "v2/routing";
import urlJoin from "url-join";
import JiraStatusCycleTimeChart from "./charts/jira-status-cycle-time";

const CHART_ROW_SIZE = {
	mainChartHeight: 286,
	summaryPieChartHeight: 198,
};

const metricIntervalTypes: TimeUnitMetricInterval[] = [
	MetricInterval.DAY, MetricInterval.WEEK, MetricInterval.MONTH
];

const INTERVAL_DATE_OPTIONS: IDropdownOption[] =
	Object.values(metricIntervalTypes).map((v: TimeUnitMetricInterval) => {
		return {
			key: v,
			value: v,
			label: TIME_INTERVAL_OPTIONS_TO_LABEL[v],
			isDisabled: false,
		};
	});

const monthlyInterval = INTERVAL_DATE_OPTIONS.find((opt) => opt.key === MetricInterval.MONTH);

interface ISelectionState {
	interval?: string;
	dateRange?: string;
	teams?: string[];
	repositories?: string[];
	projects?: string[];
	ranOnStart: boolean;
}

const DEFAULT_DATE_RANGE = DateRangeType.LastMonth;
const DEFAULT_INTERVAL = MetricInterval.WEEK;

const QS_PARAM_TEAM = "team";
const QS_PARAM_REPO = "repo";
const QS_PARAM_PROJ = "proj";
const QS_PARAM_INTERVAL = "interval";
const QS_PARAM_DATE_RANGE = "range";

// tslint:disable-next-line: variable-name
const OrgMetrics = () => {
	const { show } = useIntercom();
	const currentQsParams = new URLSearchParams(useLocation().search);
	const startState: ISelectionState = {
		teams: currentQsParams.getAll(`${QS_PARAM_TEAM}[]`) ?? [],
		repositories: currentQsParams.getAll(`${QS_PARAM_REPO}[]`) ?? [],
		projects: currentQsParams.getAll(`${QS_PARAM_PROJ}[]`) ?? [],
		interval: currentQsParams.get(QS_PARAM_INTERVAL) ?? DEFAULT_INTERVAL,
		dateRange: currentQsParams.get(QS_PARAM_DATE_RANGE) ?? DEFAULT_DATE_RANGE,
		ranOnStart: false
	};
	const [selectedState, changeState] = useState<ISelectionState>(startState);

	const {
		teamsStore: {
			fetchAllTeams,
			allTeams
		},
		teamMembersStore: {
			fetchTeamMembersForAllTeams,
			teamMembersForAllTeams
		},
		projectsStore: {
			projectOptionsFlattened,
			fetchData: fetchProjectsData,
			isLoading: isLoadingProjects
		},
		repositoriesStore: {
			fetchData: fetchGitRepoData,
			isLoading: isLoadingGitRepos,
			gitRepositoriesOptionsFlattened
		},
		configurationStore,
		authStore
	} = useStores();

	const [chartScope, setChartScope] = useState<IChartScope | undefined>();
	const [subtaskStrategy, setSubtaskStrategy] = useState<SubtaskSprintStrategy | undefined>();
	const [includeDraftPRs, setIncludeDraftPRs] = useState<boolean>(false);
	const [includeAggregatedPRs, setIncludeAggregatedPRs] = useState<boolean>(true);
	const [includeInternalPRs, setIncludeInternalPRs] = useState<boolean>(true);

	const onStateChange = (newState: Partial<ISelectionState>) => {
		changeState(x => {
			const res = Object.assign({}, x, newState);

			return res;
		});
	};

	const urlForPRBreakdown = () => {
		const searchParams = createSearchParamsForPage(
				selectedState.teams ?? [],
				selectedState.repositories ?? [],
				selectedState.dateRange as DateRangeType ?? DEFAULT_DATE_RANGE);
		return urlJoin(ROUTES.PR_BREAKDOWN, `?${searchParams.toString()}`);
	};

	useEffect(() => {
		let isMounted = true;

		async function fetchConfiguration() {
			return configurationStore.fetch([
				CONFIGURATION_KEYS.retroReport.logic.subtaskSprintStrategy,
				CONFIGURATION_KEYS.dailyDevStats.metrics.filters.includePrDrafts,
				CONFIGURATION_KEYS.dailyDevStats.metrics.filters.includeAggregatedPrs,
				CONFIGURATION_KEYS.dailyDevStats.metrics.filters.includeInternalPrs
			], {});
		}

		if (isMounted) {
			// tslint:disable-next-line: no-floating-promises
			fetchConfiguration()
				.then((configurations) => {
					let strategy: SubtaskSprintStrategy | undefined;
					if (configurations) {
						const strategyObj = configurations[CONFIGURATION_KEYS.retroReport.logic.subtaskSprintStrategy.name];
						if (strategyObj) {
							strategy = strategyObj as SubtaskSprintStrategy;
						}

						const shouldIncludeDraftPRS = configurations[
							CONFIGURATION_KEYS.dailyDevStats.metrics.filters.includePrDrafts.name];
						if (shouldIncludeDraftPRS !== undefined) {
							setIncludeDraftPRs(shouldIncludeDraftPRS as boolean);
						}

						const shouldIncludeAggregatedPRs = configurations[
							CONFIGURATION_KEYS.dailyDevStats.metrics.filters.includeAggregatedPrs.name];
						if (shouldIncludeAggregatedPRs !== undefined) {
							setIncludeAggregatedPRs(shouldIncludeAggregatedPRs as boolean);
						}

						const shouldIncludeInternalPRs = configurations[
							CONFIGURATION_KEYS.dailyDevStats.metrics.filters.includeInternalPrs.name];
						if (shouldIncludeInternalPRs !== undefined) {
							setIncludeInternalPRs(shouldIncludeInternalPRs as boolean);
						}
					}

					if (!strategy) {
						strategy = SubtaskSprintStrategy.IGNORE_PARENTS;
					}
					setSubtaskStrategy(strategy);
				});
		}

		return () => { isMounted = false; };
	}, []);

	useEffect(() => {
		async function fetchFiltersOptions() {
			// tslint:disable-next-line: no-floating-promises
			fetchGitRepoData({}, { order: DashboardSortOrder.Ascending });
			// tslint:disable-next-line: no-floating-promises
			fetchProjectsData({ enforceProjectWhitelist: true }, { order: DashboardSortOrder.Ascending });
			// tslint:disable-next-line: no-floating-promises
			fetchTeamMembersForAllTeams();
			// tslint:disable-next-line: no-floating-promises
			return fetchAllTeams();
		}
		// tslint:disable-next-line: no-floating-promises
		fetchFiltersOptions();
	}, []);

	useEffect(() => {
		if (allTeams.data.length === 0) {
			return;
		}
		if (teamMembersForAllTeams.data.length === 0) {
			return;
		}
		if (!subtaskStrategy) {
			return;
		}

		const dateRange = (selectedState.dateRange ? (selectedState.dateRange as DateRangeType) : DEFAULT_DATE_RANGE);
		const interval = (selectedState.interval ? (selectedState.interval as MetricInterval) : DEFAULT_INTERVAL);

		const timezone = authStore.authUser.timezone;
		const { endTime, startTime } = getDatesFromTimeSpan(dateRange, timezone, interval);

		const teamIds = (!selectedState.teams || selectedState.teams.length === 0) ?
			allTeams.data.map(t => t.id) : selectedState.teams;
		const dataContributorIds = _.chain(teamIds).flatMap(teamId =>
			teamMembersForAllTeams.data.filter(member => member.teamId === teamId)
			.map(member => member.dataContributorId))
			.uniq().value();
		const scope = {
			startTime,
			endTime,
			timezone,
			dateRange,
			interval,
			dataContributorIds,
			repositoryIds: selectedState.repositories,
			projectIds: selectedState.projects,
			subtaskStrategy,
			includeDraftPRs,
			includeAggregatedPRs,
			includeInternalPRs
		};
		setChartScope(scope);
	}, [
		selectedState,
		allTeams.data,
		teamMembersForAllTeams.data,
		subtaskStrategy,
		includeDraftPRs,
		includeAggregatedPRs,
		includeInternalPRs]);

	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		updateQueryString();
	}, [selectedState]);

	const updateQueryString = () => {
		const params = new URLSearchParams({});
		if (selectedState.teams && selectedState.teams.length > 0) {
			selectedState.teams.forEach(x => params.append(`${QS_PARAM_TEAM}[]`, x));
		}

		if (selectedState.repositories && selectedState.repositories.length > 0) {
			selectedState.repositories.forEach(x => params.append(`${QS_PARAM_REPO}[]`, x));
		}

		if (selectedState.projects && selectedState.projects.length > 0) {
			selectedState.projects.forEach(x => params.append(`${QS_PARAM_PROJ}[]`, x));
		}

		if (selectedState.interval) {
			params.set(QS_PARAM_INTERVAL, selectedState.interval);
		}

		if (selectedState.dateRange) {
			params.set(QS_PARAM_DATE_RANGE, selectedState.dateRange);
		}

		history.replace({ pathname: location.pathname, search: params.toString() });
	};

	const checkInTimeUnitMetricInterval = (key: string | number): TimeUnitMetricInterval => {
		if (key === MetricInterval.DAY || key === MetricInterval.WEEK || key === MetricInterval.MONTH) {
			return key;
		}
		return MetricInterval.WEEK;
	};

	const {
		filtersDropdownDisplayName
	} =
		filtersSlice({
			selectedDateRange: selectedState.dateRange ? selectedState.dateRange as DateRangeType : DEFAULT_DATE_RANGE,
			selectedInterval: selectedState.interval ? selectedState.interval as MetricInterval : DEFAULT_INTERVAL,
			timezone: authStore.authUser.timezone
		});

	return (
		<div className="org-metrics-container ui fluid container">
			<div className="header-container">
				<div className="ui fluid grid">
					<div className="eight wide column">
						<h1 className="org-metrics-title">Org Metrics</h1>
					</div>
					<div className="eight wide right aligned column">
						<MyUser />
					</div>
				</div>
			</div>
			<div id="org-metrics" className="">
				<div className="flex-row">
					<div className="flex-gap full-width">
						<DropdownListSelector
							isLoading={_.isEmpty(allTeams.data)}
							title="Teams"
							placeholder="Select teams"
							values={selectedState.teams}
							isMulti={true}
							onChange={(selected: any) => {
								if (selected) {
									onStateChange({ teams: selected.map((opt: any) => opt.value) });
								}
							}}
							options={allTeams.data.map(team => {
								return {
									key: team.id,
									value: team.id,
									label: team.name,
								};
							})}
							customColors={selectorsTheme.colors}
							customSize={selectorsTheme.size}
						/>

						<DropdownListSelector
							isLoading={isLoadingGitRepos}
							title="Repos"
							placeholder="Select repos"
							values={selectedState.repositories}
							isMulti={true}
							onChange={(selected: any) => {
								if (selected) {
									onStateChange({ repositories: selected.map((opt: any) => opt.value) });
								}
							}}
							options={gitRepositoriesOptionsFlattened}
							customColors={selectorsTheme.colors}
							customSize={selectorsTheme.size}
						/>

						<DropdownListSelector
							isLoading={isLoadingProjects}
							title="Projects"
							placeholder="Select projects"
							values={selectedState.projects}
							isMulti={true}
							onChange={(selected: any) => {
								if (selected) {
									onStateChange({ projects: selected.map((opt: any) => opt.value) });
								}
							}}
							options={projectOptionsFlattened}
							customColors={selectorsTheme.colors}
							customSize={selectorsTheme.size}
						/>
					</div>
					<div className="right-side-controls">
						<div className="flex-row pull-row-right">
							<div className={"filters-navbar-wrapper"}>
								<FiltersSelector
									placeholder={"Select Filters"}
									value={filtersDropdownDisplayName}
									loading={isLoadingGitRepos || isLoadingProjects}
									disabled={isLoadingGitRepos || isLoadingProjects}
								>
									<TimeFramesSelector
										value={selectedState.dateRange}
										setValue={(value) => {
											if (monthlyInterval) {
												if (value === DateRangeType.LastWeek || value === DateRangeType.Last2Weeks) {
													monthlyInterval.isDisabled = true;
												} else {
													monthlyInterval.isDisabled = false;
												}
											}
											if (value === MetricInterval.MONTH) {
												onStateChange({ dateRange: value, interval: MetricInterval.WEEK });
											} else {
												onStateChange({ dateRange: value });
											}
										}}
										longTermDateRange={true}
										clickEvent={clickEvent(GA_EVENT_CATEGORY.OrgMetrics, GA_EVENT_ACTION.Filter, "date-selector")}
									/>
									<TimeFramesSelector
										label={"Interval"}
										value={selectedState.interval}
										setValue={(value) => {
											onStateChange({ interval: checkInTimeUnitMetricInterval(value) });
										}}
										options={INTERVAL_DATE_OPTIONS}
										clickEvent={clickEvent(GA_EVENT_CATEGORY.OrgMetrics,
											GA_EVENT_ACTION.Filter, "dropdown-date-interval-selector")}
									/>
								</FiltersSelector>
							</div>
						</div>
					</div>
				</div>

				<div className="org-metrics-cards">
					<PrCycleTimeInPhases
						chartScope={chartScope}
						chartHeight={CHART_ROW_SIZE.mainChartHeight}
						breakdownURL={urlForPRBreakdown()}
					/>

					{chartScope && (
						<>
							<MTTRByPriorityChart
								showExternalLegend={true}
								scope={chartScope}
								chartHeight={CHART_ROW_SIZE}
							/>

							<IssueCycleTimeByTypeChart
								showExternalLegend={true}
								scope={chartScope}
								chartHeight={CHART_ROW_SIZE}
							/>

							<ThroughputChart
								throughput={ThroughputSum.IssuesCount}
								scope={chartScope}
								chartHeight={CHART_ROW_SIZE}
							/>

							<JiraStatusCycleTimeChart
								scope={chartScope}
								chartHeight={CHART_ROW_SIZE}
							/>
						</>
					)}

					<OrgMetricsCard title="Deployment Frequency" tooltip="Shows the amount of successful GitHub deployments done by the teams per each environment.">
						{chartScope && (
							<GitHubReportedDeploymentFrequency
								showExternalLegend={true}
								scope={chartScope}
								chartHeight={CHART_ROW_SIZE}
								onClickEmptyStateMissingInfo={show}
							/>
						)}
					</OrgMetricsCard>
				</div>

				<div className="graphs-suggestion">
					<div className="graphs-suggestion-text">
						Want to discover more data insights about your engineers?
					</div>
					<button className="graphs-suggestion-button" onClick={show}>
						Contact us
					</button>
				</div>
			</div>
		</div>
	);
};
export default observer(OrgMetrics);

const selectorsTheme = {
	colors: {
		borderRadius: 1,
		colors: {
			text: "#925EFF",
			primary: "#C2C4C3",
			primary25: "#f3f6f4",
			neutral0: "#FFFFFF",
			neutral80: "#925EFF",
		},
	},
	size: {
		minHeight: 30,
		height: 30,
		minWidth: 200,
		fontSize: 15,
		alignContent: "space-around",
		border: 0,
		boxShadow: "none",
	}
};
