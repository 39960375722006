import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import "./style.scss";
import { Divider } from "semantic-ui-react";
import { useStores } from "../../../mobx-stores";
import { useLocalStorage } from "usehooks-ts";
import config from "utils/config";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import Jira3LOSelectProjects from "./jira-3LO-select-projects";

const { jira3loClientId, jiraPermissionsScope } = config();

enum AppButtonState {
	Loading,
	Connect,
	Connected
}

interface IWindowProps {
	url: string;
	title: string;
	width: number;
	height: number;
}

const DEFAULT_POPUP_WIDTH_SIZE = 1000;
const DEFAULT_POPUP_HEIGHT_SIZE = 700;

const GITHUB_INTEGRATION_ID_LOCAL_STORAGE_KEY = "connected_apps_github_integration_id";

const createPopup = ({
	url, title, height, width,
}: IWindowProps) => {
	const left = window.screenX + (window.outerWidth - width) / 2;
	const top = window.screenY + (window.outerHeight - height) / 2.5;
	const externalPopup = window.open(
		url,
		title,
		"toolbar=no,location=no,status=no,menubar=no," +
		`scrollbars=yes,resizable=yes,` +
		`width=${width},height=${height},left=${left},top=${top}`,
	);
	return externalPopup;
};

// tslint:disable-next-line: variable-name
const ConnectedAppButton = observer(({
	state,
	onClick
}: {
	state: AppButtonState;
	onClick: () => void;
}) => {
	return (<>
		{state === AppButtonState.Connected && (
			<div className="ui blue basic label cursor-checked">
				<i className="check icon" />
				Connected
			</div>
		)}
		{state === AppButtonState.Loading && (
			<div className="ui basic label cursor-wait">
				<i className="wait icon" />
				Checking...
			</div>
		)}
		{state === AppButtonState.Connect && (
			<div className="ui basic label green cursor-pointer" onClick={onClick}>
				<i className="add icon" />
				Connect
			</div>
		)}
	</>
	);
});

// tslint:disable-next-line: variable-name
const ConnectedApps = () => {
	const {
		authStore,
		connectedAppsStore: {
			refreshIntegrationState,
			allIntegrations,
			githubActiveEnabledIntegration,
			jiraActiveEnabledIntegration,
			createGitHubInstallLink,
			authorizeGitHubInstall,
			createJira3loURL,
			listAvailableJira3LOProjects,
			updateSelectedJira3LOProjectKeys
		},
	} = useStores();

	const currentQsParams = new URLSearchParams(useLocation().search);
	const installationId = currentQsParams.get("installation_id") ?? undefined;

	const [jiraIntegrationState, setJiraIntegrationState] = useState<AppButtonState>(AppButtonState.Loading);
	const [gitHubIntegrationState, setGitHubIntegrationState] = useState<AppButtonState>(AppButtonState.Loading);

	const match = useRouteMatch();

	const [githubIntegrationId, setGitHubIntegrationId] =
		useLocalStorage<string | undefined>(GITHUB_INTEGRATION_ID_LOCAL_STORAGE_KEY, undefined);

	const [externalWindow, setExternalWindow] = useState<Window | null>(null);
	const intervalRef = useRef<number>();

	const clearTimer = () => {
		window.clearInterval(intervalRef.current);
	};

	const onOpenOAuthPopup = (url: string, title: string, width: number = DEFAULT_POPUP_WIDTH_SIZE, height = DEFAULT_POPUP_HEIGHT_SIZE) => {
		setExternalWindow(createPopup({
			url, title, width, height,
		}));
	};

	useEffect(() => {
		const authorizeGitHub = async (pInstallationId: string, pGithubIntegrationId: string) => {
			await authorizeGitHubInstall({
				integrationId: pGithubIntegrationId,
				installationId: pInstallationId
			});
			setGitHubIntegrationId(undefined);
			// If we didn't fail on the authorize and we have a githubIntegrationId local variable.
			// this occurs when the callback URL from GitHub includes the installation_id, we know we're in a popup and we close ourselves
			if (window) {
				window.close();
			}
		};

		if (installationId && githubIntegrationId) {
			// tslint:disable-next-line: no-floating-promises
			authorizeGitHub(installationId, githubIntegrationId);
		}
	}, [installationId]);

	useEffect(() => {
		if (!externalWindow) {
			clearTimer();
			return;
		}

		intervalRef.current = window.setInterval(() => {
			if (!externalWindow || externalWindow.closed) {
				clearTimer();
				// tslint:disable-next-line: no-floating-promises
				refreshIntegrationState();
			}
		}, 1000);
	}, [externalWindow]);

	useEffect(() => {
		// tslint:disable-next-line: no-floating-promises
		refreshIntegrationState();
	}, []);

	useEffect(() => {
		if (allIntegrations === undefined) {
			return;
		}
		setGitHubIntegrationState(
			githubActiveEnabledIntegration ? AppButtonState.Connected : AppButtonState.Connect);
	}, [githubActiveEnabledIntegration, allIntegrations]);

	useEffect(() => {
		if (allIntegrations === undefined) {
			return;
		}
		setJiraIntegrationState(
			jiraActiveEnabledIntegration ? AppButtonState.Connected : AppButtonState.Connect);
	}, [jiraActiveEnabledIntegration, allIntegrations]);

	return (
		<div className="ui segment raised" style={{ minHeight: "75vh" }}>
			<div className="ui grid">
				<div className="ui eight wide column">
					<div className="ui wide column form-padding">
						<h1>Connected apps</h1>
						<div className="small-text gray-text">Integrate your favorite tools to transform your data into actionable insights and improve your team's performance!</div>
						<Divider section={true} />
						<div className="ui cards">
							<div className="card">
								<div className="content">
									<img className="left floated mini ui image" alt="GitHub" src="/assets/images/integration-provider-images/logo-github.svg" />

									<div className="header">
										GitHub
									</div>
									<div className="meta">
										Source Control
									</div>
									<div className="description">
										Code contributors, commits, branches, pull requests, code review activity and deployments.
									</div>
								</div>
								<div className="extra content">
									<ConnectedAppButton state={gitHubIntegrationState}
										onClick={async () => {
											const integrationWizard = await createGitHubInstallLink();
											if (!integrationWizard) {
												// tslint:disable-next-line: no-console
												console.error(`Could not find any valid integration wizard object`);
												return;
											}
											const { installLink, integrationId } = integrationWizard;
											if (!installLink || !integrationId) {
												// tslint:disable-next-line: no-console
												console.error(`Could not find any valid install link (${installLink}) or integration id (${integrationId})`);
												return;
											}
											setGitHubIntegrationId(integrationId);
											onOpenOAuthPopup(installLink, "Integrate with GitHub");
										}} />
								</div>
							</div>
							<div className="card">
								<div className="content">
									<img className="left floated mini ui image" alt="GitHub" src="/assets/images/integration-provider-images/logo-jira-cloud.svg" />
									<div className="header">
										Jira Cloud
									</div>
									<div className="meta">
										Issue management
									</div>
									<div className="description">
										Tasks, velocity, statuses, sprints, priorities, and versions
									</div>
								</div>
								<div className="extra content">
									<ConnectedAppButton state={jiraIntegrationState}
										onClick={async () => {
											if (!jira3loClientId) {
												// tslint:disable-next-line: no-console
												console.error(`Could not find any valid Jira 3LO client id`);
												return;
											}
											const currentUser = authStore.authUser;
											window.location.href = createJira3loURL(currentUser.customerId, currentUser.dataContributorDetails.externalId, jira3loClientId, jiraPermissionsScope);
										}} />
								</div>
							</div>
						</div>
					</div>
				</div>
				{jiraActiveEnabledIntegration && (<div className="ui eight wide column">
					<div className="ui wide column form-padding">
						<Switch>
							<Route exact={true} path={`${match?.url}/jira/post-auth:ok?:msg?`}>
								<Jira3LOSelectProjects
									listAvailableJira3LOProjects={listAvailableJira3LOProjects}
									updateSelectedJira3LOProjectKeys={updateSelectedJira3LOProjectKeys}
									/>
							</Route>
						</Switch>
					</div>
				</div>)}
			</div>
		</div>
	);
};
export default observer(ConnectedApps);
