import React from "react";
import { IDashboardDataContributor, IDashboardTeamMember } from "@acumen/dashboard-common";
import { ExternalImage } from "./external-image";
import classNames from "classnames";
import _ from "lodash";

const fallbackInitialsAvatarURL = (fullName?: string | null) => {
	fullName = fullName?.trim();
	if (!fullName) {
		return "";
	}
	const names = fullName.split(" ");
	let initials = names[0].substring(0, 1);

	if (names.length > 1) {
		initials += names[names.length - 1].substring(0, 1);
	}
	return `https://i0.wp.com/cdn.auth0.com/avatars/${initials.toLowerCase()}.png`;
};

interface IAvatarUser {
	displayName?: string;
	email?: string | null;
	pictureUrl?: string | null;
	avatarUrl?: string | null;
	firstName?: string | null;
	surname?: string | null;
}

// tslint:disable-next-line: variable-name
export const Avatar = ({ dataContributor, user, teamMember, className, src, ...props }:
	{
		dataContributor?: IDashboardDataContributor | null,
		user?: IAvatarUser | null,
		teamMember?: IDashboardTeamMember | null
	} & Parameters<typeof ExternalImage>[0]) => {

	let displayName: string | undefined;
	if (user) {
		if (user.displayName) {
			displayName = user.displayName;
		} else if (user.firstName || user.surname) {
			displayName  = _.compact([user.firstName, user.surname]).map(_.upperFirst).join(" ");
		} else if (user.email) {
			displayName = user.email;
		}
	} else if (teamMember) {
		displayName = teamMember.displayName;
	} else if (dataContributor) {
		displayName = dataContributor?.primaryDisplayName ?? undefined;
	}

	let avatarUrl = user?.pictureUrl
		?? teamMember?.avatarUrl
		?? user?.avatarUrl
		?? user?.pictureUrl
		?? dataContributor?.primaryAvatarUrl
		?? fallbackInitialsAvatarURL(displayName ?? null);

	if (!displayName && !avatarUrl) {
		avatarUrl = "/assets/images/broken-image.svg";
	}

	return (
		<ExternalImage
			src={avatarUrl}
			className={classNames(["ui", "image", "avatar", "white-bg", ...(className?.split(" ") ?? [])])}
			alt=""
			onError={
				i => i.currentTarget.src = fallbackInitialsAvatarURL(displayName ?? null) || "/assets/images/broken-image.svg"
			}
			{...props}
		/>
	);
};
