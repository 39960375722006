import React from "react";
import "./style.scss";

interface IMinimalButton {
	label?: string;
	children?: React.ReactNode;
	onClick?: () => void;
	loading?: boolean;
	disabled?: boolean;
}

// tslint:disable-next-line: variable-name
export const MinimalButton = ({label, children, onClick, loading, disabled}: IMinimalButton) => {
	return (
		<button className="minimal-button" onClick={onClick} disabled={disabled}>
			{label || children}
		</button>
	);
};

export default MinimalButton;
