import React, { useState } from "react";
import { DashboardTeamReportMetric, IDashboardTeamsReport } from "@acumen/dashboard-common";
import BarChart, { StackedSeries } from "../../../components/highchart-chart-components/bar-chart";
import Highcharts from "highcharts";
import { observer } from "mobx-react";
import { useStores } from "../../../mobx-stores";
import { ChartSeriesData } from "../../../pages/my-team/analytics/charts/charts";
import { IChartScope } from "../../../pages/org-analytics/charts/chart-scope";
import PieChart from "../../../components/highchart-chart-components/pie-chart";
import { Popup } from "semantic-ui-react";
import ChartMenuHeader from "./chart-menu-header";
import { round } from "@acumen/common";
import { prCycleDatesArray, REPORT_METRIC_TO_UNITS } from "../../../mobx-stores/new-teams-report-store";
import {
	ChartExternalLegend, ILegendList, ITeamsReportTrendCards, IHoverEffectProps, externalHoverType, HoverSelectionPart
} from "../particles";
import { numberShortened } from "../../../../utils/string";
import _ from "lodash";

interface IPRTimelineSegmentProps {
	chartData: IDashboardTeamsReport | undefined;
	chartScope: IChartScope;
	menuItems: ITeamsReportTrendCards[];
	trendsSeries?: Partial<Record<DashboardTeamReportMetric, ChartSeriesData | undefined>>;
	hoverEffect?: IHoverEffectProps;
}

// tslint:disable-next-line: variable-name
const PRCycleTimeSegment = (props: IPRTimelineSegmentProps) => {
	const { chartData, menuItems } = { ...props };
	const [hoveredMetric, setHoveredMetric] = useState<HoverSelectionPart>(externalHoverType);
	const [hoveredSummaryMetric, setHoveredSummaryMetric] = useState<undefined | string>(undefined);

	const {
		newTeamsReportStore: {
			prCycleChartsData,
			prCycleChartsTotalData
		},
	} = useStores();

	const dates = prCycleChartsData.get(prCycleDatesArray) ?? [];
	const safeMatchChartDataToDates: (series: number[]) => Array<[number, number]> = (
		(series: number[]) => {
			return series
				.filter((_a, i) => i < dates.length)
				.map(((metricData: number, i: number) => ([dates[i], metricData])));
		}
	);

	const [prCycleWIP, prCycleAwaitingReview, prCycleInReview, prCycleReviewed] = [
		prCycleChartsData.get(DashboardTeamReportMetric.PRCycleWorkInProgressHours),
		prCycleChartsData.get(DashboardTeamReportMetric.PRCycleAwaitingReviewHours),
		prCycleChartsData.get(DashboardTeamReportMetric.PRCycleInReviewHours),
		prCycleChartsData.get(DashboardTeamReportMetric.PRCycleReviewedHours)
	];

	const [prCycleTotalWIP, prCycleTotalAwaitingReview, prCycleTotalInReview, prCycleTotalReviewed] = [
		prCycleChartsTotalData.get(DashboardTeamReportMetric.PRCycleWorkInProgressHours),
		prCycleChartsTotalData.get(DashboardTeamReportMetric.PRCycleAwaitingReviewHours),
		prCycleChartsTotalData.get(DashboardTeamReportMetric.PRCycleInReviewHours),
		prCycleChartsTotalData.get(DashboardTeamReportMetric.PRCycleReviewedHours)
	];

	const trendsSeries: Partial<Record<HoverSelectionPart, ChartSeriesData>> = {
		[DashboardTeamReportMetric.PRCycleWorkInProgressHours]: prCycleWIP ?
			safeMatchChartDataToDates(prCycleWIP) : [],
		[DashboardTeamReportMetric.PRCycleAwaitingReviewHours]: prCycleAwaitingReview ?
			safeMatchChartDataToDates(prCycleAwaitingReview) : [],
		[DashboardTeamReportMetric.PRCycleInReviewHours]: prCycleInReview ?
			safeMatchChartDataToDates(prCycleInReview) : [],
		[DashboardTeamReportMetric.PRCycleReviewedHours]: prCycleReviewed ?
			safeMatchChartDataToDates(prCycleReviewed) : [],
	};
	const totalSeries: Partial<Record<HoverSelectionPart, ChartSeriesData>> = {
		[DashboardTeamReportMetric.PRCycleWorkInProgressHours]: prCycleTotalWIP ?
			safeMatchChartDataToDates(prCycleTotalWIP) : [],
		[DashboardTeamReportMetric.PRCycleAwaitingReviewHours]: prCycleTotalAwaitingReview ?
			safeMatchChartDataToDates(prCycleTotalAwaitingReview) : [],
		[DashboardTeamReportMetric.PRCycleInReviewHours]: prCycleTotalInReview ?
			safeMatchChartDataToDates(prCycleTotalInReview) : [],
		[DashboardTeamReportMetric.PRCycleReviewedHours]: prCycleTotalReviewed ?
			safeMatchChartDataToDates(prCycleTotalReviewed) : [],
	};
	const prCycleStackedData: StackedSeries = [];

	Object.entries(trendsSeries)
		.filter(series => series[0] !== externalHoverType)
		.forEach((entry) => {
			const name = entry[0];
			const menuItem = menuItems.find(item => item.metricName === name);
			if (entry[1]) {
				prCycleStackedData.unshift({
					data: entry[1],
					name: menuItem?.title ?? "Unknown",
					color: menuItem?.color
				});
			}
		});
	const chartsLegendList: ILegendList[] = [];
	const summaryChartData: ChartSeriesData = Object.entries(totalSeries)
		.filter(series => series[0] !== externalHoverType)
		.map((entry) => {
			const name = entry[0];
			const sum = entry[1]?.reduce((acc, series) => {
				return series[1] + acc;
			}, 0);
			const menuItem = menuItems.find(item => item.metricName === name);
			if (sum && menuItem?.title) {
				chartsLegendList.push({
					name: menuItem?.title,
					value: `${numberShortened(round(sum, 0))} ${REPORT_METRIC_TO_UNITS[name as DashboardTeamReportMetric]}`,
					color: menuItem?.color
				});
			}
			return [menuItem?.title ?? "", sum ?? 0];
		});
	const totalWork = round(summaryChartData.reduce((acc: number, data) => {
		return acc + data[1];
	}, 0), 0);
	const totalTimeStr = `${numberShortened(totalWork)} ${REPORT_METRIC_TO_UNITS[menuItems[0].metricName as DashboardTeamReportMetric]}`;

	return (
		<>
			<ChartMenuHeader
				hoverEffect={{
					hoveredMetric,
					setHoveredMetric
				}}
				chartData={chartData}
				menuItems={menuItems}
			/>

			<div className="pr-cycle-time-segment row charts-line-container underlined-row">
				<div className="ui grid">
					<div className="ui ten wide column">
						<h2 className="ui title acu-capitalize">
							averages over time
							<Popup
								hoverable={true}
								wide={true}
								className={"tiny-text"}
								position="top center"
								content={"Cycle phase breakdown for an average pull request. Data is aggregated for all selected teams and only merged pull requests are included in the calculation."}
								trigger={
									<i className="chart-tooltip info circle outline icon" />
								}
							/>
						</h2>
						<BarChart
							stackedSeries={(hoveredMetric === externalHoverType) ? prCycleStackedData : undefined}
							series={trendsSeries[hoveredMetric]}
							tooltip={{
								shared: true,
								split: true,
								pointFormat: (hoveredMetric === externalHoverType) ?
									`<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:,.0f} hr</b><br/>` :
									undefined,
								// tslint:disable-next-line: object-literal-shorthand
								formatter: (hoveredMetric === externalHoverType) ?
									undefined :
									function _formatter() {
										return `${Highcharts.dateFormat("%a, %d %b", this.x)}<br><span style="color: black"><b>${round(this.y, 0)} hours<b></span>`;
									}
							}}
							xAxis={{
								gridLineWidth: 1,
								type: "datetime",
								visible: true,
							}}
							yAxis={{
								min: 0,
								title: {
									text: undefined
								},
								labels: {
									// tslint:disable-next-line: object-literal-shorthand
									formatter: function _formatter() {
										if (_.isNumber(this.value)) {
											return `${numberShortened(this.value)} hrs`;
										}
										return this.value;
									}
								}
							}}
							height={"400px"}
							color={menuItems.find(item => item.metricName === hoveredMetric)?.color}
						/>
					</div>
					<div className="ui six wide column">
						<h2 className="ui title acu-capitalize">
							Totals for the time period
						</h2>
						<div className="ui grid full-height">
							<div className="ui eight wide column">
								<div className="pie-chart-container">
									<PieChart
										series={
											[{
												type: "pie",
												name: "PR Timeline",
												data: summaryChartData,
												colors: menuItems.map(item => item.color),
												showInLegend: false,
												tooltip: {
													pointFormat: "<b>{point.percentage:.1f}%</b>",
													headerFormat: "<small>{point.key}</small><br>",
												},
												point: {
													events: {
														mouseOver: function _mouseOver() {
															setHoveredSummaryMetric(this.name);
														},
														mouseOut: function _mouseOver() {
															setHoveredSummaryMetric(undefined);
														}
													}
												}
											}]
										}
										height={220}
										title={
											`<div class="small-text">
								<p>${totalTimeStr} in total</p>
								</div>`
										}
										triggerHover={hoveredSummaryMetric}
									/>
								</div>
							</div>
							<div className="ui eight wide column no-padding" >
								<div className="legend-container">
									<ChartExternalLegend
										legendList={chartsLegendList}
										hoveredEntry={hoveredSummaryMetric}
										setHoveredEntry={setHoveredSummaryMetric}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default observer(PRCycleTimeSegment);
