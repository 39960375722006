import React, { CSSProperties, useCallback, useMemo, useState } from "react";
import { Icon } from "semantic-ui-react";
import { DateRangeType, MetricInterval } from "@acumen/dashboard-common";
import { IClickEvent } from "../../../analytics-events";
import classNames from "classnames";

export interface IDropdownOption {
	key: string | number;
	value: string | number;
	label: string;
	isDisabled?: boolean;
	isActive?: boolean;
}

interface ITimeFramesSelector<OptionType extends IDropdownOption> {
	value?: string;
	setValue: (value: string, label?: string) => void;
	longTermDateRange?: boolean;
	options?: OptionType[];
	clickEvent?: IClickEvent;
	label?: string;
	isCustomDateRange?: boolean;
	customStyles?: CSSProperties;
	showSearchBar?: boolean;
}

export const MAP_DATE_RANGE_TO_VALUE: Record<DateRangeType, string> = {
	[DateRangeType.LastDay]: "Last day",
	[DateRangeType.Last3Days]: "Last 3 days",
	[DateRangeType.LastWeek]: "Last week",
	[DateRangeType.Last2Weeks]: "Last 2 weeks",
	[DateRangeType.LastMonth]: "Last month",
	[DateRangeType.Last3Months]: "Last 3 months",
	[DateRangeType.Last6Months]: "Last 6 months",
	[DateRangeType.LastYear]: "Last year",
	[DateRangeType.Last2Years]: "Last 2 years",
	[DateRangeType.Last3Years]: "Last 3 years"
};

export const TIME_INTERVAL_OPTIONS_TO_LABEL: Record<MetricInterval, string> = {
	[MetricInterval.DAY]: "Daily",
	[MetricInterval.WEEK]: "Weekly",
	[MetricInterval.MONTH]: "Monthly",
	[MetricInterval.SPRINT]: "Sprints",
	[MetricInterval.SPRINT_DATE]: "Sprints",
	[MetricInterval.DEV_CYCLE]: "Cycle",
	[MetricInterval.DEV_CYCLE_DATE]: "Cycle",
	[MetricInterval.DAYS_7]: "7 days",
	[MetricInterval.DAYS_30]: "30 days"
};

const LONG_RANGE_TYPES = [
	DateRangeType.LastWeek, DateRangeType.Last2Weeks, DateRangeType.LastMonth, DateRangeType.Last3Months,
	DateRangeType.Last6Months, DateRangeType.LastYear
];
const SHORT_RANGE_TYPES = [
	DateRangeType.LastDay, DateRangeType.Last3Days, DateRangeType.LastWeek, DateRangeType.Last2Weeks,
	DateRangeType.LastMonth, DateRangeType.Last3Months
];

const DEFAULT_DATE_RANGE_OPTIONS: IDropdownOption[] = Object.values(SHORT_RANGE_TYPES).map(v => ({
	key: v,
	value: v,
	label: MAP_DATE_RANGE_TO_VALUE[v]
}));

const LONG_TERM_DATE_RANGE_OPTIONS: IDropdownOption[] = Object.values(LONG_RANGE_TYPES).map(v => ({
	key: v,
	value: v,
	label: MAP_DATE_RANGE_TO_VALUE[v]
}));

// tslint:disable-next-line: variable-name
export const TimeFramesSelector =  <OptionType extends IDropdownOption>
	({value, setValue, longTermDateRange, clickEvent, options, label, isCustomDateRange, customStyles, showSearchBar }: ITimeFramesSelector<OptionType>) => {

	const [searchTerm, setSearchTerm] = useState("");
	const handleSearchTermChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value),
		[]
	);
	const dateRangeOption = options ? options :
		longTermDateRange ? LONG_TERM_DATE_RANGE_OPTIONS : DEFAULT_DATE_RANGE_OPTIONS;
	const filteredOptions = useMemo(() =>  {
		if (!searchTerm) {
			return dateRangeOption;
		}

		const lcSearchTerm = searchTerm.toLowerCase();

		return dateRangeOption.filter(option => option.label.toLowerCase().includes(lcSearchTerm));
	}, [dateRangeOption, searchTerm]);

	return (
		<div
			className={classNames(
				"acu-filters-selector-section",
				"time-frames-selector",
				showSearchBar && "time-frames-selector-with-search-bar"
			)}
			{...clickEvent}
			style={customStyles}
		>
			{showSearchBar && (
				<div className="items-search-field">
					<span className="search-icon"/>
					<input
						autoFocus={true}
						type="search"
						className="items-search-input"
						placeholder="Search sprint..."
						value={searchTerm}
						onChange={handleSearchTermChange}
					/>
				</div>
			)}
			<div className="time-frames-selector-content">
				{!showSearchBar && <div className="acu-filters-selector-title">{label ? label : "Time frames"}</div>}
				<div className="acu-filters-selector-options">
					{
						filteredOptions.map(selectorItem => {
							const selected = isCustomDateRange ? false : value === selectorItem.value;
							return (
								<div key={selectorItem.key}
									 onClick={() => !selectorItem.isDisabled && setValue(selectorItem.value.toString(), selectorItem.label)}
									 className={`acu-filters-selector-options-item ${selected ? "--selected" : ""} ${selectorItem.isDisabled ? "--disabled" : ""}`}
								>
									{selected && <Icon className="acu-filters-selector-options-checkmark" name="checkmark"/>}
									{selectorItem.label}
								</div>
							);
						})
					}
				</div>
			</div>
		</div>
	);
};
