import {
	FormEvent,
	useEffect,
	useCallback,
	useRef,
} from "react";

import classnames from "classnames";
import { useOnClickOutside } from "usehooks-ts";
import React from "react";
import CloseButton from "../svg-assets/DialogCloseButton";

export interface ImportCustomCSVInstructionsModalProps {
	onCancel: () => void;
	onImport: () => void;
}

export function ImportCustomCSVInstructionsModal({
	onCancel,
	onImport,
}: ImportCustomCSVInstructionsModalProps) {
	const ref = useRef(null);

	useOnClickOutside(ref, () => onCancel());

	const onEscape = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === "Escape" && onCancel) {
				onCancel();
			}
		},
		[onCancel]
	);

	useEffect(() => {
		document.addEventListener("keydown", onEscape, false);

		return () => {
			document.removeEventListener("keydown", onEscape, false);
		};
	}, [onEscape]);

	return (
		<div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 backdrop-brightness-50 bg-black/30">
			<div
				className="relative rounded-lg bg-[#FFFEFE]/95 border border-[#CED5DF]"
				ref={ref}
			>
				<button
					type="button"
					className={classnames(
						"absolute top-3 right-2.5 ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-base text-gray-400 hover:bg-gray-200",
					)}
					onClick={(event: FormEvent) => {
						event.preventDefault();
						onCancel();
					}}
				>
					<CloseButton className="h-5 w-5" />
				</button>
				<div className="p-6">
					<h3 className="mb-2 text-2xl font-bold tracking-wider text-[#399CFF] capitalize">
						🤔 Custom CSV Instructions
					</h3>
					<div className="block text-base text-[#8791A2]">
						<span>Upload a CSV file with your tasks using the following format:</span>
						<ul className="mt-2">
							<li>- Key (optional)</li>
							<li>- Title (required)</li>
							<li>- Description (optional)</li>
							<li>- Link (optional)</li>
						</ul>
					</div>
					<div className="mt-8 flex flex-row justify-center space-x-4">
						<button
							type="button"
							className={classnames(
								"w-36 py-3 border border-[#399CFF] text-xl rounded font-normal text-[#399CFF] hover:bg-gray-200 transition duration-300 ease-in-out",
							)}
							onClick={async (event: FormEvent) => {
								event.preventDefault();
								onCancel();
							}}
						>
							Cancel
						</button>
						<button
							type="button"
							className={classnames(
								"w-36 py-3 bg-[#399CFF] border border-[#399CFF] text-xl rounded font-normal text-white hover:border-[#338CE5] hover:bg-[#338CE5] transition duration-300 ease-in-out",
							)}
							onClick={async (event: FormEvent) => {
								event.preventDefault();
								onImport();
							}}
						>
							{"Import"}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
