import { ResolvedCustomTableColumnProps, SortState } from "../types";
import { useMemo } from "react";

export interface UseSortedItemsProps<T extends object> {
	columns: Array<ResolvedCustomTableColumnProps<T>>;
	items: T[];
	sortState: SortState<T> | null;
	onSortStateChange?: (state: SortState<T> | null) => void;
}

export const useSortedItems = <T extends object>(props: UseSortedItemsProps<T>) => {
	const { columns, items, sortState, onSortStateChange } = props;

	return useMemo(() => {
		if (!sortState) {
			return items;
		}

		const { key, direction } = sortState;
		const column = columns.find(c => c.key === key)!;

		return items.slice(0).sort((itemA, itemB) => {
			const sortBy = column.sortBy;
			const valueA = sortBy?.(itemA, column) ?? "";
			const valueB = sortBy?.(itemB, column) ?? "";

			return compareValues(direction, valueA, valueB);
		});
	}, [items, sortState, onSortStateChange]);
};

const compareValues = (direction: "asc" | "desc", valueA: string | number, valueB: string | number) => {
	if (direction === "asc") {
		return valueA < valueB ? -1 : (valueA > valueB ? 1 : 0);
	}

	return valueA > valueB ? -1 : (valueA < valueB ? 1 : 0);
};
