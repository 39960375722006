import React from "react";
import { IDashboardTeamReportMetric } from "@acumen/dashboard-common";
import { PrCycleTimeSummary } from "../pr-cycle-time-summary/pr-cycle-time-summary";
import { TrendItem } from "../../types";
import { TrendCard } from "../trend-card/trend-card";
import "./pr-cycle-time-trends.scss";

export interface PrCycleTimeTrendsProps {
	totalReportMetric: IDashboardTeamReportMetric | undefined;
	trends: TrendItem[] | undefined;
}

export const PrCycleTimeTrends = (props: PrCycleTimeTrendsProps) => {
	const { totalReportMetric, trends } = props;

	return (
		<div className="pr-cycle-time-trends">
			{totalReportMetric && (
				<PrCycleTimeSummary
					totalReportMetric={totalReportMetric}
					unit="hours"
				/>
			)}

			<div className="pr-cycle-time-trends-cards">
				{trends?.map(trend => (
					<TrendCard
						key={trend.clientName}
						trend={trend}
					/>
				))}
			</div>
		</div>
	);
};
