import React, { useState, createRef } from "react";
import classNames from "classnames";
import { Popup, Dropdown } from "semantic-ui-react";

import "./filters.scss";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ taskCount = 0 }) => {
	const [filterCount] = useState(4);
	const [openSearch, toggleSearch] = useState(false);
	const searchInput = createRef();
	return (
		<div className="filter-dropdown">
			<div className="ui transparent input">
				<i
					className="icon search"
					onClick={() => {
						if (!openSearch) {
							searchInput.current.focus();
						}
						toggleSearch(!openSearch);
					}}
				/>
				<input
					ref={searchInput}
					className={classNames("search-input", { visible: openSearch })}
					type="text"
					placeholder="Search"
				/>
			</div>
			<Popup
				pinned={true}
				hideOnScroll={true}
				on="click"
				offset="-11px, 0"
				wide={true}
				position="bottom left"
				trigger={<i className="icon outline filter" />}
				className="filters-form"
			>
				<form className="ui form">
					<div className="field">
						<label>text input</label>
						<input type="text" name="text-input" placeholder="text input" />
					</div>
					<div className="field">
						<label>Dropdown</label>
						<Dropdown
							placeholder="dropdown"
							fluid={true}
							selection={true}
							options={[
								{ key: 1, value: 1, text: "one" },
								{ key: 2, value: 2, text: "two" },
							]}
						/>
					</div>
					<div className="equal width fields">
						<div className="field">
							<label>range</label>
							<div className="ui fluid input">
								<input type="text" name="range-start" placeholder="start" />
							</div>
						</div>
						<div className="field">
							<label>&nbsp;</label>
							<div className="ui fluid input">
								<input type="text" name="range-end" placeholder="end" />
							</div>
						</div>
					</div>
					<div className="equal width fields">
						<div className="field">
							<label>date range</label>
							<div className="ui fluid input">
								<input type="text" name="range-start" placeholder="start" />
							</div>
						</div>
						<div className="field">
							<label>&nbsp;</label>
							<div className="ui fluid input">
								<input type="text" name="range-end" placeholder="end" />
							</div>
						</div>
					</div>
					<div className="ui divider"></div>
					<div className="ui right floated primary button">Apply</div>
				</form>
			</Popup>
			{filterCount > 0 && (
				<div className="filter-count">{filterCount} filters</div>
			)}
			{taskCount > 0 && <div className="task-count">{taskCount} tasks</div>}
		</div>
	);
};
