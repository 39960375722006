import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { Accordion, Menu, Sidebar } from "semantic-ui-react";
import { useStores } from "../../mobx-stores";
import { observer } from "mobx-react";
import settings from "../../components/svg-assets/menu-icons/settings.svg";
import orgMetrics from "../../components/svg-assets/menu-icons/org-metrics.svg";
import teamComparison from "../../components/svg-assets/menu-icons/team-comparison.svg";
import workforceHealth from "./../../components/svg-assets/menu-icons/workforce-health.svg";
import "./style.scss";

enum PARENT_ITEMS {
	SETTINGS = "SETTINGS",
}

interface NavigationParentItemProps { [key: string]: { key: PARENT_ITEMS, childPath: string[] }; }

const navigationParentItems: NavigationParentItemProps = {
	settings: { key: PARENT_ITEMS.SETTINGS, childPath: ["/my-account/account", "/my-account/users", "/my-account/teams", "/my-account/contributors", "/my-account/connected-apps", "/my-account/insights", "/my-account/general-settings"] },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default observer(() => {
	const { width } = useWindowDimensions();
	const {
		featureFlagsStore,
		authStore: { isUserAdmin },
	} = useStores();
	const { pathname } = useLocation();
	const isTeamComparisonPageEnabled = featureFlagsStore.isFeatureEnabled("ui-team-comparison");

	const sideArrow = (isActive: boolean) => {
		return (
			isActive ? <i aria-hidden="true" className="chevron down icon" /> :
				<i aria-hidden="true" className="chevron right icon" />
		);
	};

	const innerLink = (title: string, path: string) => (
		<NavLink to={path} className="link item">
			<span>{title}</span>
		</NavLink>
	);

	const [activeItems, setActiveItems] = useState<PARENT_ITEMS[]>([PARENT_ITEMS.SETTINGS]);

	const handleToggleMenu = (menuKey?: PARENT_ITEMS) => {
		if (menuKey === undefined) {
			return;
		}
		const updatedActiveItems = [...activeItems];
		const index = updatedActiveItems.indexOf(menuKey);

		if (index === -1) {
			updatedActiveItems.push(menuKey);
		} else {
			updatedActiveItems.splice(index, 1);
		}
		setActiveItems(updatedActiveItems);

	};

	useEffect(() => {
		const updatedActiveItems = [];
		for (const field of Object.keys(navigationParentItems)) {
			const navItem = navigationParentItems[field];
			if (navItem.childPath.includes(pathname)) {
				updatedActiveItems.push(navItem.key);
			}
		}
		setActiveItems(updatedActiveItems);
	}, []);

	return (
		<Sidebar
			as={Menu}
			visible={(width ? (width > 1000) : false)}
			className="main-sidebar push"
			inverted={true}
			vertical={true}
		>
			<div className="logo-header">
				<Link to="/">
					<img className="acumen logo" src="/assets/images/logo-purple.svg" alt="Acumen" />
				</Link>
			</div>
			<Accordion exclusive={false}>
				<Accordion.Title>
					<NavLink to="/workforce-health" exact={true} className="item link">
						<img className="menu-icon" src={workforceHealth} alt="" />
						<span>Workforce Health</span>
					</NavLink>
				</Accordion.Title>
				{isTeamComparisonPageEnabled && (
					<Accordion.Title>
						<NavLink to="/team-metrics" exact={true} className="item link">
							<img className="menu-icon" src={teamComparison} alt="" />
							<span>Team Metrics</span>
						</NavLink>
					</Accordion.Title>
				)}
				<Accordion.Title>
					<NavLink to="/org-metrics" exact={true} className="item link">
						<img className="menu-icon" src={orgMetrics} alt="" />
						<span>Org Metrics</span>
					</NavLink>
				</Accordion.Title>
				<Accordion.Title
					onClick={() => handleToggleMenu(navigationParentItems.settings.key)}
				>
					<div className="item link">
						<img className="menu-icon" src={settings} alt="" />
						<span>Settings</span>
						{sideArrow(activeItems.includes(navigationParentItems.settings.key))}
					</div>
				</Accordion.Title>
				<Accordion.Content active={activeItems.includes(navigationParentItems.settings.key)}>
					{isUserAdmin && (innerLink("General", "/my-account/general-settings"))}
					{isUserAdmin && (innerLink("Users", "/my-account/users"))}
					{innerLink("Teams", "/my-account/teams")}
					{isUserAdmin && (innerLink("Contributors", "/my-account/contributors"))}
					{isUserAdmin && (innerLink("Connected Apps", "/my-account/connected-apps"))}
					{innerLink("Account", "/my-account/account")}
					{innerLink("Jira statuses", "/my-account/jira-statuses")}
				</Accordion.Content>
			</Accordion>
		</Sidebar >
	);
});
