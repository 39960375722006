import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useState } from "react";
import { useStores } from "../../../mobx-stores";
import { v4 as generateUUID } from "uuid";
import LoadingIndicator from "../../../components/loader/loader";
import { IChartScope } from "./chart-scope";
import useDeepCompareEffect from "use-deep-compare-effect";
import { Icon, Label, Popup } from "semantic-ui-react";
import { ChartType, DashboardExportFormat, IChartResponse, IDashboardTeamReportMetric, pullRequestDashboardStatuses } from "@acumen/dashboard-common";
import { ChartSeriesData } from "v2/helpers/charts";
import colorScheme from "./chart-color-scheme";
import { ITrendCardData, TrendItem } from "../types";
import { PrCycleTimeTrends } from "../components/pr-cycle-time-trends/pr-cycle-time-trends";
import { InfoIcon } from "../components/info-icon/info-icon";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";

interface IProps {
	scope: IChartScope;
	chartHeight?: {
		mainChartHeight: number
	};
	breakdownURL: string;
}

interface ITrendProps {
	series: ChartSeriesData;
	cardData: ITrendCardData;
}

function PRCycleTime(props: IProps) {
	const { metricOrgStore, featureFlagsStore } = useStores();
	const [summarizedChartOptions, setSummarizedChartOptions] = useState<Highcharts.Options | undefined>(undefined);
	const [isDownloadingExportData, setIsDownloadingExportData] = useState(false);
	const [totalReportMetric, setTotalReportMetric] = useState<IDashboardTeamReportMetric | undefined>(undefined);
	const [trends, setTrends] = useState<TrendItem[] | undefined>(undefined);

	const showCharts = featureFlagsStore.isFeatureEnabled("org-metrics-show-pr-cycle-time-charts");
	const allowPRBreakdown = featureFlagsStore.isFeatureEnabled("org-metrics-allow-pr-breakdown-navigation");
	const { scope } = { ...props };

	function splitTrend(seriesKey: string, data: IChartResponse): ITrendProps | undefined {
		const series = data[seriesKey];
		if (!series) {
			return undefined;
		}

		const seriesDetails = CHART_PR_STATUS_GROUPS.find(prs => prs.seriesKey === seriesKey);
		if (!seriesDetails) {
			return undefined;
		}

		return {
			cardData: {
				color: seriesDetails.seriesColorHex,
				title: seriesDetails.seriesName,
				secondaryTitle: "Avg per PR",
				tooltipText: seriesDetails.seriesTooltip,
				units: "hours"
			},
			series: series.map(x => [x.category, x.value])
		};
	}

	function buildSummarizedChartOptions(data: IChartResponse): Highcharts.Options {

		const series = CHART_PR_STATUS_GROUPS.map(displayProperties => {
			const statusData = data[displayProperties.seriesKey] ?? [];
			const seriesOptions: Highcharts.SeriesColumnOptions = {
				id: generateUUID(),
				name: displayProperties.seriesName,
				color: displayProperties.seriesColorHex,
				visible: (statusData.some(sd => sd.value > 0)),
				index: displayProperties.zOrder,
				legendIndex: displayProperties.sortOrder,
				data: statusData.map(x => [x.category, x.value]),
				stack: "pr",
				stacking: "normal",
				type: "column",
			};

			return seriesOptions;
		});
		const options: Highcharts.Options = {
			chart: {
				type: "area",
				zoomType: "xy",
				height: 301,
			},
			title: undefined,
			tooltip: {
				split: true,
				pointFormat: `<span style="color:{point.color}">\u25CF</span> {series.name} ({point.percentage:.1f}%): <b>{point.y:,.0f} hr </b><br/>`
			},
			xAxis: {
				gridLineWidth: 1,
				type: "datetime",
				uniqueNames: false,
				minPadding: 0,
				maxPadding: 0,
			},
			yAxis: {
				min: 0,
				title: {
					text: null,
				},
				labels: {
					// tslint:disable-next-line: object-literal-shorthand
					formatter: function _formatter() {
						return `${this.value}h`;
					}
				},
				minPadding: 0,
				maxPadding: 0,
			},
			plotOptions: {
				area: {
					stacking: "normal",
				}
			},
			credits: {
				enabled: false
			},
			legend: {
				enabled: false
			},
			series
		};

		return options;
	}

	useDeepCompareEffect(() => {
		let isMounted = true;
		async function fetchChartData() {
			const trendData = await metricOrgStore.fetchPRCycleTime(scope.interval, scope.dateRange, scope.timezone,
				undefined, scope.dataContributorIds, scope.repositoryIds, !scope.includeDraftPRs,
				!scope.includeInternalPRs, scope.includeAggregatedPRs);

			if (isMounted && trendData) {
				setSummarizedChartOptions(buildSummarizedChartOptions(trendData));
			}
			if (isMounted && showCharts && trendData) {
				const metricsDictionary: Record<string, IDashboardTeamReportMetric | undefined> = {};
				const trendPropsDictionary: Record<string, ITrendProps | undefined> = {};

				pullRequestDashboardStatuses.forEach(status => {
					trendPropsDictionary[status.clientName] = splitTrend(status.clientName, trendData);
				});
				const prBreakdown = await metricOrgStore.fetchPRCycleBreakdownData(scope.startTime, scope.endTime,
					scope.timezone, scope.interval, undefined, scope.repositoryIds, scope.dataContributorIds,
					scope.includeDraftPRs, scope.includeAggregatedPRs, scope.includeInternalPRs);
				if (prBreakdown) {
					setTotalReportMetric(prBreakdown.prCycleTotalMetric);
					metricsDictionary[pullRequestDashboardStatuses[0].clientName] = prBreakdown.prCycleWIPMetric;
					metricsDictionary[pullRequestDashboardStatuses[1].clientName] = prBreakdown.prCycleAwaitingReviewMetric;
					metricsDictionary[pullRequestDashboardStatuses[2].clientName] = prBreakdown.prCycleInReviewMetric;
					metricsDictionary[pullRequestDashboardStatuses[3].clientName] = prBreakdown.prCycleReviewedMetric;
				}

				const trendCardItems: TrendItem[] = pullRequestDashboardStatuses.map(status => ({
					clientName: status.clientName,
					metric: metricsDictionary[status.clientName],
					series: trendPropsDictionary[status.clientName]?.series,
					cardData: trendPropsDictionary[status.clientName]?.cardData,
				}));

				setTrends(trendCardItems);
			}
		}

		// We need to clear those options as we might have a different count of columns which will
		// cause to a crash in highcharts
		setSummarizedChartOptions(undefined);
		setTotalReportMetric(undefined);
		setTrends(undefined);
		// tslint:disable-next-line: no-floating-promises
		fetchChartData().then(() => {
			isMounted = false;
		});
	}, [props]);

	const chartExport = async (): Promise<void> => {
		return metricOrgStore
			.exportPRCycleChart(DashboardExportFormat.CSV, scope.interval!, scope.dateRange!, scope.timezone,
				scope.dataContributorIds, scope.repositoryIds,
				scope.includeDraftPRs, scope.includeAggregatedPRs, scope.includeInternalPRs)
			.then(exportData => {
				if (exportData) {
					const dateFormat = "YY-MM-DD-HH-mm";
					const element = document.createElement("a");
					const file = new Blob([exportData], { type: "text/csv" });
					element.href = URL.createObjectURL(file);
					element.download = `${_.kebabCase(ChartType.PRCycleTime)}_${scope.interval}_${moment(scope.startTime).format(dateFormat)}--${moment(scope.endTime).format(dateFormat)}.csv`;
					document.body.appendChild(element); // Required for this to work in FireFox
					element.click();
					document.body.removeChild(element);
				}
			});
	};
	return (
		<LoadingIndicator local={true} isActive={summarizedChartOptions === undefined}>
			<div className="pr-cycle-time-chart">
				{showCharts && <div>
					<LoadingIndicator local={true} isActive={!totalReportMetric || !trends}>
						{totalReportMetric && (
							<PrCycleTimeTrends
								totalReportMetric={totalReportMetric}
								trends={trends}
							/>
						)}
					</LoadingIndicator>
				</div>}
				<div>
					<div className="pr-cycle-time-chart-title-container">
						<h3 className="pr-cycle-time-chart-title">
							<span>Averages over time</span>
							<Popup
								hoverable={true}
								wide={true}
								className={"tiny-text"}
								position="top center"
								content="Cycle phase breakdown for an average pull request. Data is aggregated for all selected teams and only merged pull requests are included in the calculation."
								trigger={<InfoIcon/>}
							/>
							{!metricOrgStore.isLoadingPRCycleTimeChartData && allowPRBreakdown && <span className="right">
								<Link to={props.breakdownURL} className="pr-cycle-time-see-breakdown">
									<span>See PR drilldown</span>
								</Link>
							</span>}
							{!metricOrgStore.isLoadingPRCycleTimeChartData && !allowPRBreakdown && <span className="right">
								<Popup
									hoverable={true}
									wide={true}
									position="top center"
									content="Download chart data as CSV"
									trigger={
										<Label className="chart-export" as="a"
											basic={true} onClick={async (e) => {
												if (isDownloadingExportData) {
													return;
												}
												e.stopPropagation();

												setIsDownloadingExportData(true);

												try {
													await chartExport();
												} finally {
													setIsDownloadingExportData(false);
												}
											}}><Icon name={(isDownloadingExportData) ? "hourglass half" : "download"} />Export</Label>
									}
								/>
							</span>}

						</h3>
					</div>

					{summarizedChartOptions && (
						<HighchartsReact
							highcharts={Highcharts}
							options={summarizedChartOptions}
						/>
					)}
				</div>
			</div>
		</LoadingIndicator>
	);
}

export default PRCycleTime;

interface ISeriesProps {
	zOrder: number;
	sortOrder: number;
	seriesKey: string;
	seriesName: string;
	seriesTooltip: string;
	seriesColorHex: string;
}

const CHART_PR_STATUS_GROUPS: ISeriesProps[] = [
	{
		zOrder: 400,
		sortOrder: 1,
		seriesKey: pullRequestDashboardStatuses[0].clientName,
		seriesName: "Coding",
		seriesColorHex: colorScheme.prStatus.WIP,
		seriesTooltip: "The average time spent between the initial commit and the first review request. Only merged pull requests are included in the calculation"
	},
	{
		zOrder: 300,
		sortOrder: 2,
		seriesKey: pullRequestDashboardStatuses[1].clientName,
		seriesName: "Awaiting review",
		seriesColorHex: colorScheme.prStatus.reviewedRequested,
		seriesTooltip: "The average time spent between the first review request to when the pull request went into actual review. Only merged pull requests are included in the calculation"
	},
	{
		zOrder: 200,
		sortOrder: 3,
		seriesKey: pullRequestDashboardStatuses[2].clientName,
		seriesName: "In review",
		seriesColorHex: colorScheme.prStatus.inReview,
		seriesTooltip: "The average time spent between beginning a review until the first approval. Only merged pull requests are included in the calculation"
	},
	{
		zOrder: 100,
		sortOrder: 4,
		seriesKey: pullRequestDashboardStatuses[3].clientName,
		seriesName: "Pending merge",
		seriesColorHex: colorScheme.prStatus.pendingMerge,
		seriesTooltip: "The average time spent between the first review approval until the pull request is actually merged"
	}
];
