import React from "react";
import { Popup } from "semantic-ui-react";
import classNames from "classnames";
import { PrCycleTimeBreakdownItem } from "../../types";
import "./pr-cycle-time-for-team-breakdown-item.scss";
import { round } from "@acumen/common";

export interface PrCycleTimeForTeamBreakdownItemProps {
	item: PrCycleTimeBreakdownItem;
	longestCycleTimeInSeconds: number;
}

export const PrCycleTimeForTeamBreakdownItem = (props: PrCycleTimeForTeamBreakdownItemProps) => {
	const { item, longestCycleTimeInSeconds } = props;
	const tooltipClassName = classNames(
		"cycle-time-status",
		"variant-color",
		`variant-${item.status}`,
	);
	const triggerClassName = classNames(
		"pr-cycle-time-for-team-breakdown-item",
		"cycle-time-status",
		"variant-bg",
		`variant-${item.status}`,
	);
	const width = `${item.totalSeconds / longestCycleTimeInSeconds * 100}%`;
	const flexRatio = item.totalSeconds == null ? 1 : undefined;
	const durationInHours = round(item.totalSeconds / 3600);

	return (
		<Popup
			hoverable={true}
			className="cycle-time-status-tooltip"
			position="top center"
			content={
				<div className={tooltipClassName}>
					{item.name}&nbsp;
					<span className="cycle-time-status-data">
						{durationInHours< 1 && <span>Less than</span>}
						<span className={"cycle-time-status-data-value"}>{durationInHours}h</span>
					</span>
				</div>
			}
			trigger={
				<div
					className={triggerClassName}
					style={{ width, flex: flexRatio }}
				/>
			}
		/>
	);
};
