import React from "react";
import classNames from "classnames";
import { AcumenTaskStatus, IDashboardCycleTimeBreakdown, } from "@acumen/dashboard-common";
import { formatTotalCycleTime } from "../utils";
import { AcumenStatusToClassName } from "./acumen-status-to-class-name";
import CycleTimeBreakdownProgress from "./cycle-time-breakdown-progress";

interface CycleTimeBreakdownItemProps {
	cycleTimeBreakdown: IDashboardCycleTimeBreakdown;
	label: React.ReactNode;
	index?: number;
	currentStatus?: AcumenTaskStatus;
	durationInSeconds: number | null;
	durationInBold?: boolean;
	standalone?: boolean;
}

const CycleTimeBreakdownItem = (props: CycleTimeBreakdownItemProps) => {
	const { cycleTimeBreakdown, label, index, currentStatus, durationInSeconds, durationInBold, standalone } = props;
	const Element = standalone ? "div" : "li";
	const statusClassName = classNames(
		"cycle-time-breakdown-status",
		"status-color",
		currentStatus && AcumenStatusToClassName[currentStatus],
	);
	const durationClassName = classNames(
		"cycle-time-breakdown-duration",
		durationInBold && "strong",
	);

	return (
		<Element className="cycle-time-breakdown-item">
			<div className={statusClassName}>
				{label}
			</div>

			<CycleTimeBreakdownProgress
				cycleTimeBreakdown={cycleTimeBreakdown}
				index={index}
				currentStatus={currentStatus}
			/>

			<div className={durationClassName}>
				{formatTotalCycleTime(durationInSeconds)}
			</div>
		</Element>
	);
};

export default CycleTimeBreakdownItem;
