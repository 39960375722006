import Papa from "papaparse";
import { IPlanningPokerIssue } from "@acumen/dashboard-common";
import { NewPlanningPokerIssue } from "../../../services/crud/go-retro-planning-poker-api-client";

export enum FileSource {
	Jira = "Jira",
	AzureDevOps = "AzureDevOps",
	Custom = "Custom",
}

interface IJiraIssue {
	"issue key": string;
	summary: string;
	description: string;
	link?: string;
}

interface IAzureDevOpsIssue {
	id: string;
	title: string;
	"work item type": string;
	description: string;
	link?: string;
}

interface ICustomIssue {
	key: string;
	title: string;
	description: string;
	link: string;
}

interface IExportIssue extends ICustomIssue {
	estimation: string;
}

async function parse<T>(rawFile: File): Promise<T[] | undefined> {
	return new Promise((resolve) => {
		Papa.parse<T>(rawFile, {
			header: true,
			skipEmptyLines: true,
			transformHeader: (h) => {
				return h.toLowerCase();
			},
			complete: (results) => {
				resolve(results.data);
			},
			error: () => {
				resolve(undefined);
			},
		});
	});
}

async function convertFromJira(csvFile: File): Promise<NewPlanningPokerIssue[] | undefined> {
	let input = await parse<IJiraIssue>(csvFile);
	if (!input) {
		return undefined;
	}

	if (input.length === 0) {
		return [];
	}
	input = input.filter((i) => i.summary && i.summary.length > 0);
	if (input.length === 0) {
		return undefined;
	}
	return input.map((i) => ({
		title: i.summary,
		description: i.description,
		key: i["issue key"],
		link: i.link ?? null,
		estimation: null,
		note: null
	}));
}

async function convertFromAzureDevOps(
	csvFile: File
): Promise<NewPlanningPokerIssue[] | undefined> {
	let input = await parse<IAzureDevOpsIssue>(csvFile);
	if (!input) {
		return undefined;
	}

	if (input.length === 0) {
		return [];
	}
	input = input.filter((i) => i.title && i.title.length > 0);
	if (input.length === 0) {
		return undefined;
	}
	return input.map((i) => ({
		title: i.title,
		description: i.description,
		key: i["work item type"] ? `${i["work item type"]}-${i.id}` : i.id,
		link: i.link ?? null,
		estimation: null,
		note: null
	}));
}

async function convertFromCustom(csvFile: File): Promise<NewPlanningPokerIssue[] | undefined> {
	let input = await parse<ICustomIssue>(csvFile);
	if (!input) {
		return undefined;
	}

	if (input.length === 0) {
		return [];
	}
	input = input.filter((i) => i.title && i.title.length > 0);
	if (input.length === 0) {
		return undefined;
	}
	return input.map((i) => ({
		title: i.title,
		description: i.description,
		key: i.key,
		link: i.link,
		estimation: null,
		note: null
	}));
}

export async function convertFromCSV(
	csvFile: File,
	source: FileSource
): Promise<NewPlanningPokerIssue[] | undefined> {
	switch (source) {
		case FileSource.Jira: {
			return convertFromJira(csvFile);
		}
		case FileSource.AzureDevOps: {
			return convertFromAzureDevOps(csvFile);
		}
		case FileSource.Custom: {
			return convertFromCustom(csvFile);
		}
		default:
	}
	return undefined;
}

export function exportToCSV(issues: IPlanningPokerIssue[]): Blob {
	const exportIssues: IExportIssue[] = issues.map((i) => ({
		title: i.title,
		description: i.description ?? "",
		key: i.key,
		link: i.link ?? "",
		estimation: i.estimation ?? "",
		note: i.note ?? ""
	}));
	const data = Papa.unparse(exportIssues);
	const blob = new Blob([data], { type: "csv" });
	return blob;
}
