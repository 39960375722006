import React from "react";

export function labeledHeader(columnName: string) {
	if (columnName.length === 0) {
		return;
	}
	return (
		<div className="labeled-header" >
			<span >{columnName}</span>
			<div className="label-margin">
				<span className="ui label tiny">beta</span>
			</div>
		</div>);
}
