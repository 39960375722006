export const areSelectionsEqual = (a: Set<unknown> | null | undefined, b: Set<unknown> | null | undefined) => {
	const a1 = new Set(a);
	const b1 = new Set(b);

	if (a1.size !== b1.size) {
		return false;
	}

	for (const item of Array.from(a1)) {
		if (!b1.has(item)) {
			return false;
		}
	}

	return true;
};
