import { getData } from "../../../services/fetch-helpers";
import { BaseCustomerApiClient } from "./base-customer-api-client";
import { IDashboardStatus } from "@acumen/dashboard-common";

export const ROUTE = "statuses";

export class TaskStatusesApiClient extends BaseCustomerApiClient {
	public fetchStatuses = async () => {
		return await getData<IDashboardStatus[], any>(
			this.createCustomerEntityRoute(`${ROUTE}`),
			this.token,
			this.tokenType
		);
	}
}
