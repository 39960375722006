import React from "react";
import { MobXProviderContext } from "mobx-react";
import { RouterStore } from "mobx-react-router";
import AuthStore from "./auth-store";
import DataContributorsStore from "./data-contributors-store";
import TeamMembersStore from "./team-members-store";
import TeamsStore from "./teams-store";
import UsersStore from "./users-store";
import ProjectsStore from "./projects-store";
import GitRepositoriesStore from "./git-repositories-store";
import BoardsStore from "./boards-store";
import FetcherStore from "./fetcher-store";
import ComponentsStore from "./components-store";
import CustomizationStore from "./customization-store";
import JiraStatusMappingStore from "./jira-status-mapping-store";
import ConnectedAppStore from "./connected-apps-store";
import DeveloperStatsStore from "./developer-stats-store";
import PrioritiesStore from "./priorities-store";
import MetricOrgStore from "./metric-org-store";
import SprintsStore from "./sprints-store";
import MetricTesterStore from "./metric-tester-store";
import TasksStore from "./tasks-store";
import PullRequestsStore from "./pull-requests-store";
import FeatureFlagStore from "./feature-flag-store";
import DataContributorLabelsStore from "./data-contributor-labels-store";
import ConfigurationStore from "./configuration-store";
import SSOStore from "./sso-store";
import DataContributorEpicsStore from "./data-contributor-epics-store";

export class RootStore {
	public static fetcherStore: FetcherStore = new FetcherStore();
	public static routingStore: RouterStore = new RouterStore();
	public static featureFlagsStore: FeatureFlagStore = new FeatureFlagStore();
	public static dataContributorsStore: DataContributorsStore = new DataContributorsStore();
	public static teamsStore: TeamsStore = new TeamsStore();
	public static teamMembersStore: TeamMembersStore = new TeamMembersStore();
	public static usersStore: UsersStore = new UsersStore();
	public static projectsStore: ProjectsStore = new ProjectsStore();
	public static repositoriesStore: GitRepositoriesStore = new GitRepositoriesStore();
	public static boardsStore: BoardsStore = new BoardsStore();
	public static componentsStore: ComponentsStore = new ComponentsStore();
	public static authStore: AuthStore = new AuthStore(RootStore.fetcherStore, RootStore.featureFlagsStore);
	public static customizationStore: CustomizationStore = new CustomizationStore(RootStore.authStore);
	public static statusMappingStore: JiraStatusMappingStore = new JiraStatusMappingStore();
	public static connectedAppsStore: ConnectedAppStore = new ConnectedAppStore();
	public static developerStatsStore: DeveloperStatsStore = new DeveloperStatsStore();
	public static priorityStore: PrioritiesStore = new PrioritiesStore();
	public static metricOrgStore: MetricOrgStore = new MetricOrgStore();
	public static sprintsStore: SprintsStore = new SprintsStore();
	public static metricTesterStore: MetricTesterStore = new MetricTesterStore();
	public static tasksStore: TasksStore = new TasksStore();
	public static pullRequestsStore: PullRequestsStore = new PullRequestsStore();
	public static dataContributorLabelsStore: DataContributorLabelsStore = new DataContributorLabelsStore();
	public static dataContributorEpicsStore: DataContributorEpicsStore = new DataContributorEpicsStore();
	public static configurationStore: ConfigurationStore = new ConfigurationStore();
	public static ssoStore: SSOStore = new SSOStore();
}

export function useStores(): typeof RootStore {
	return React.useContext(MobXProviderContext).rootStore as typeof RootStore;
}
