import BaseStore, { ILoadable } from "./base-store";
import apiContextProvider from "../../services/api-context-provider";
import { SlackNotificationsApiClient, SLACK_NOTIFICATIONS_ROUTE } from "../services/crud/slack-notifications-api-client";
import {
	IDashboardSlackNotification, IDashboardAddSlackNotification,
	IDashboardUpdateSlackNotification
} from "@acumen/dashboard-common";

import { action, observable } from "mobx";
import { FetchLatestRequest } from "../../services/fetch-helpers";

const defaults = {
	allSlackNotificationsByDC: BaseStore.initLoadable([])
};
export default class SlackNotificationsStore extends BaseStore<{}>  {
	private readonly apiClient: SlackNotificationsApiClient = new SlackNotificationsApiClient(apiContextProvider);

	@observable
	public allSlackNotificationsByDC: ILoadable<IDashboardSlackNotification[]> = defaults.allSlackNotificationsByDC;

	fetchLatestAllSlackNotifications = new FetchLatestRequest<IDashboardSlackNotification[], any>(SLACK_NOTIFICATIONS_ROUTE);
	@action.bound
	public async findAll() {
		this.allSlackNotificationsByDC.loading = true;

		const result = await this.fetchLatestAllSlackNotifications.fetchLatest(this.apiClient.findAll());
		const data = result?.data ?? [];
		this.allSlackNotificationsByDC = {
			data,
			metadata: null,
			loaded: true,
			loading: false
		};

		return data;
	}

	@action.bound
	public async create(task: IDashboardAddSlackNotification): Promise<IDashboardSlackNotification | undefined> {
		const result = await this.apiClient.create(task);
		return result?.data ?? undefined;
	}

	@action.bound
	public async update(task: IDashboardUpdateSlackNotification): Promise<void | undefined> {
		const result = await this.apiClient.update(task);
		return result?.data ?? undefined;
	}
	@action.bound
	public async delete(taskId: string): Promise<void | undefined> {
		const result = await this.apiClient.delete(taskId);
		return result?.data ?? undefined;
	}
	@action.bound
	public resetAllSlackNotifications() {
		this.allSlackNotificationsByDC = defaults.allSlackNotificationsByDC;
	}
}
