import React from "react";
import classNames from "classnames";
import { Dimmer, Loader, Transition } from "semantic-ui-react";
import { Container, Col, Row, Image } from "react-bootstrap";
import IntegrationProviders, { IDashboardIntegrationProvider } from "../integration-providers";
import { STRINGS } from "../../../../../../../localization";

import "./select-integration-provider.scss";

interface IProps {
	onSelect?: (provider: IDashboardIntegrationProvider) => void;
	isLoading?: boolean;
}

export default function SelectIntegrationProvider(props: IProps) {
	const [selected, setSelected] = React.useState("");
	const [shake, setShake] = React.useState("");

	const rows: IDashboardIntegrationProvider[][] = [
		IntegrationProviders.filter(p => p.active),
		IntegrationProviders.filter(p => !p.active)
	];

	const onSelect = (provider: IDashboardIntegrationProvider) => {
		if (!provider.active) {
			setShake(provider.key);
			return;
		}
		setSelected(provider.key);
		if (props.onSelect) { props.onSelect(provider); }
	};

	return (
		<Container className="select-integration-provider-root">
			<p>
				<br/>{STRINGS.INTEGRATION_SELECT_PROVIDER_GET_STARTED}
			</p>
			{rows.map((row: IDashboardIntegrationProvider[], i: number) => (
				<Row key={i}>
					{row.map((provider: IDashboardIntegrationProvider, j: number) => (
						<Col
							key={j}
							className={
								classNames(
									"integration-provider-image-container",
									{inactive: !provider.active},
									{selected: selected === provider.key}
								)}
							data-alt={provider.title}
							onClick={() => onSelect(provider)}
						>
							{props.isLoading && provider.key === selected ? (
								<div className="loading-indicator">
									<Dimmer active={true} inverted={true}>
										<Loader inverted={true} />
									</Dimmer>
								</div>
							) : (
								<Transition
									visible={provider.key === shake}
									animation="shake"
									duration={700}
									onComplete={() => setShake("")}
									mountOnShow={false}
								>
									<Image
										className="integration-provider-image"
										src={provider.logo}
									/>
								</Transition>
							)}
							<div className="coming-soon-ribbon" hidden={provider.active}>
								<i>{STRINGS.INTEGRATION_SELECT_PROVIDER_COMING_SOON}</i>
							</div>
						</Col>
					))}
				</Row>
			))}
		</Container>
	);
}
