import React from "react";
import NoPreview from "../../components/svg-assets/no-preview.svg";
import moment from "moment";

interface IPreviewNotification {
	title?: string;
	teamMembers: string[];
	developer?: string;
	showUpcomingTasks?: boolean;
	showCompletedTasks?: boolean;
	showPrReviews?: boolean;
	showReviewedPRs?: boolean;
	showNeedsAttention?: boolean;
	showTeamUpdate?: boolean;
	showDeveloperStats?: boolean;
	showTodayInsights?: boolean;
	showActiveBranches?: boolean;
}

// tslint:disable-next-line: variable-name
export const PreviewNotification = ({
	title, teamMembers, developer, showNeedsAttention, showCompletedTasks, showPrReviews, showReviewedPRs, showTeamUpdate,
	showUpcomingTasks, showDeveloperStats, showTodayInsights, showActiveBranches }: IPreviewNotification) => {

	const nothingToShow = !showCompletedTasks && !showNeedsAttention && !showPrReviews && !showReviewedPRs && !showTeamUpdate
	&& !showUpcomingTasks && !showDeveloperStats && !showTodayInsights && !showActiveBranches;

	if (nothingToShow) {
		return (
			<div className="slack-notification-preview">
				<div className="notification-preview-title">
					Notification preview
				</div>
				<div className="notification-preview-nothing-to-show-text">
					<i className="arrow left icon" />
					Select notification type and section to see an example
				</div>
				<div className="notification-preview-nothing-to-show">
					<img src={NoPreview} alt="" />
				</div>
			</div>
		);
	}

	return (
		<div className="slack-notification-preview">
			<div className="notification-preview-title">
				Notification preview
			</div>
			<div className="notification-preview">
				<div className="notification-preview-time">
					9:00
				</div>
				<div className="notification-preview-header">
					<div className="notification-preview-header-title">{title}</div>
					<div className="info-section">
						<i className="calendar icon" style={{ verticalAlign: "middle" }} />
						<div className="info-section-text bold">
							{moment.utc(new Date()).format("MMM Do YYYY")}
						</div>
						<div className="info-section-text">
							MTS Team
						</div>
						<div className="info-section-text">
							Daily work digest
						</div>
					</div>
				</div>
				<div className="notification-preview-body">
					{showDeveloperStats && <div className="notification-section-box">
						<div className="notification-preview-body-title">
							<div className="icon-font">👏</div> Kudos
						</div>
						<div className="notification-preview-body-text">
							{developer ? developer : "Developer"}  has worked on 5 tasks and 3 PRs
						</div>
					</div>}
					{showNeedsAttention && <div className="notification-section-box">
						<div className="notification-preview-body-title">
							<div className="icon-font">🔥</div> Need Attention
						</div>
						<div className="notification-preview-body-text">
							<b>New task</b>
							<div className="notification-preview-fake-url"> (DEV-2553 Allow editing of ownable entities in the team level) </div>
							<b> was added to an already active sprint DEV Sprint 13</b>
						</div>
					</div>}
					{(showTodayInsights || showTeamUpdate) && <div className="notification-section-box">
						<div className="notification-preview-body-title">
							<div className="icon-font">👏</div> Kudos
						</div>
						<div className="notification-preview-body-text">
							The team has worked on 19 tasks and 12 PRs
						</div>
					</div>}
					{showTodayInsights && <div className="notification-section-box">
						<div className="notification-preview-body-title">
							<div className="icon-font">🔥</div> Mind the following insights for today
						</div>
						<div className="notification-preview-plain-text">
							No insights detected.
						</div>
					</div>}
					{showTeamUpdate && teamMembers.length > 0 && <div className="notification-section-box">
						<div className="notification-preview-body-title">
							<div className="icon-font">💪</div> Team Updates
						</div>
						{teamMembers.map((member, index) => {
							return (
								<div key={index} className="notification-preview-section">
									<div className="notification-preview-row">
										<div className="notification-preview-body-text">
											<b>{member}</b> <div className="notification-preview-fake-url">DEV-3376</div>
										</div>
										<div className="notification-preview-body-line" />
									</div>
									<div className="notification-preview-row">
										<div className="notification-preview-body-line" />
									</div>
								</div>
							);
						})}
					</div>}
					{showPrReviews && <div className="notification-section-box">
						<div className="notification-preview-body-title">
							<div className="icon-font">💡</div> Pull Requests, multiple reviewers
						</div>
						<div className="notification-preview-section">
							<div className="notification-preview-row">
								<div className="notification-preview-body-text">
									<div className="ticket-number text-blue">
										pr#1966
									</div>
									<div className="notification-preview-tag mr-8">
										Code Review
									</div>
								</div>
								<div className="notification-preview-body-line" />
								<div className="notification-preview-tag ml-8">
									18h ago
								</div>
							</div>
						</div>
					</div>}
					{showUpcomingTasks && teamMembers.length > 0 && <div className="notification-section-box">
						<div className="notification-preview-body-title">
							Tasks in the works, assigned to {teamMembers[0]} (3)
						</div>
						<div className="notification-preview-section">
							<div className="notification-preview-row">
								<div className="notification-preview-body-text">
									<span className="ticket-number">
										<u>DEV-3321</u>
									</span>
									<div className="notification-preview-tag mr-8">
										Code Review
									</div>
								</div>
								<div className="notification-preview-body-line" />
							</div>
							<div className="notification-preview-row">
								<div className="notification-preview-body-line" />
								<div className="notification-preview-tag ml-8">
									12h
								</div>
							</div>
						</div>
						<div className="notification-preview-section">
							<div className="notification-preview-row">
								<div className="notification-preview-body-text">
								<span className="ticket-number">
										<u>DEV-3327</u>
									</span>
									<div className="notification-preview-tag mr-8">
										Code Review
									</div>
								</div>
								<div className="notification-preview-body-line" />
							</div>
							<div className="notification-preview-row">
								<div className="notification-preview-body-line" />
								<div className="notification-preview-tag ml-8">
									15h
								</div>
							</div>
						</div>
						<div className="notification-preview-section">
							<div className="notification-preview-row">
								<div className="notification-preview-body-text">
									<span className="ticket-number">
										<u>DEV-3332</u>
									</span>
									<div className="notification-preview-tag mr-8">
										Code Review
									</div>
								</div>
								<div className="notification-preview-body-line" />
							</div>
							<div className="notification-preview-row">
								<div className="notification-preview-body-line" />
								<div className="notification-preview-tag ml-8">
									17h
								</div>
							</div>
						</div>
					</div>}
					{showReviewedPRs && <div className="notification-section-box">
						<div className="notification-preview-body-title">
							<div className="icon-font">💡</div> Pull Requests, multiple reviewers
						</div>
						<div className="notification-preview-section">
							<div className="notification-preview-row">
								<div className="notification-preview-body-text">
									<div className="ticket-number text-blue">
										pr#1966
									</div>
									<div className="notification-preview-tag mr-8">
										Code Review
									</div>
								</div>
								<div className="notification-preview-body-line" />
								<div className="notification-preview-tag ml-8">
									12h ago
								</div>
							</div>
						</div>
					</div>}
					{showCompletedTasks && <div className="notification-section-box">
						<div className="notification-preview-body-title">
							<div className="icon-font">💪</div> Developer Updates
						</div>
						<div className="notification-preview-section">
							<div className="notification-preview-row">
								<div className="notification-preview-body-text">
									<b>{developer ? developer : "Developer"}</b> <div className="notification-preview-fake-url">DEV-3989</div>
								</div>
								<div className="notification-preview-body-line" />
							</div>
							<div className="notification-preview-row">
								<div className="notification-preview-body-line" />
							</div>
						</div>
						<div className="notification-preview-section">
							<div className="notification-preview-row">
								<div className="notification-preview-body-text">
									<b>{developer ? developer : "Developer"}</b> <div className="notification-preview-fake-url">DEV-4023</div>
								</div>
								<div className="notification-preview-body-line" />
							</div>
							<div className="notification-preview-row">
								<div className="notification-preview-body-line" />
							</div>
						</div>
					</div>}
					{showActiveBranches && <div className="notification-section-box">
						<div className="notification-preview-body-title">
							<div className="icon-font" />Active Branches
						</div>
						<div className="notification-preview-section">
							<div className="notification-preview-row">
								<div className="notification-preview-body-text">
									<span className="ticket-number">
										<u>DEV-3398</u>
									</span>
								</div>
								<div className="notification-preview-body-line" />
							</div>
						</div>
						<div className="notification-preview-section">
							<div className="notification-preview-row">
								<div className="notification-preview-body-text">
									<span className="ticket-number">
										<u>DEV-3401</u>
									</span>
								</div>
								<div className="notification-preview-body-line" />
							</div>
						</div>
					</div>}
				</div>
			</div>
		</div>
	);
};

export default PreviewNotification;
