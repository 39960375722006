import {
	AcumenMetricGroupType, CommonEndPoints, ConfigurationEntityType, DevStatsMetricNameInDatabase,
	IDashboardAcumenMetricDataRequest, IDashboardAcumenMetricDataResponse, IDashboardResponse,
	MetricInterval, TUPLE_SEPARATOR, WorkforceHealthMetrics
} from "@acumen/dashboard-common";
import _ from "lodash";
import moment from "moment-timezone";
import { qsStringify } from "services/url-routes-helper";
import { getData, postData } from "../../../services/fetch-helpers";
import { DevStatColumn } from "v2/pages/workforce-health/dev-stat-columns";
import { DeveloperBadgesData } from "../../types/workforce-health";
import { BaseCustomerApiClient } from "./base-customer-api-client";
import urlJoin from "url-join";
import { TeamsBadgesData } from "v2/types/team-comparison";

const MULTI_METRICS_ROUTE = "multiple-metrics";
const DEVELOPER_BADGES_ROUTE = "developer-badges";
const TEAM_BADGES_ROUTE = "team-badges";
const CONFIGURATION_ROUTE = "configuration";
const TASKS_SUB_ROUTE = "/task";
export class DeveloperStatsApiClient extends BaseCustomerApiClient {
	async getDeveloperBadgesData(dataContributorIds: string[]) {
		return await postData<any, DeveloperBadgesData, any>(
			this.createCustomerEntityRoute(DEVELOPER_BADGES_ROUTE),
			this.token,
			{ dataContributorIds },
			this.tokenType
		);
	}

	async getTeamBadgesData(teamIds: string[]) {
		return await postData<any, TeamsBadgesData, any>(
			this.createCustomerEntityRoute(TEAM_BADGES_ROUTE),
			this.token,
			{ teamIds },
			this.tokenType
		);
	}

	async getDevStatMetrics(selectedMetrics: DevStatColumn[],
		interval: MetricInterval,
		startTime: Date, endTime: Date,
		timezone: string,
		filters: {"team_id": string[] | undefined, "data_contributor_id": string[] | undefined},
		groupBy: string | null = null)
			: Promise<IDashboardResponse<IDashboardAcumenMetricDataResponse[], any> | null> {
		const metricsToCalculate = _.uniq(_.flatten(selectedMetrics.map(m => m.dependencies)));

		const defaultMetricInput: Omit<IDashboardAcumenMetricDataRequest, "label"> = {
			metricsName: AcumenMetricGroupType.DailyDevStats,
			interval,
			startTime: moment.tz(startTime, timezone).toISOString(true),
			endTime: moment.tz(endTime, timezone).toISOString(true),
			timezone,
			groupBy
		};

		const filterData = [
			...(this.createDimensions("data_contributor_id", filters.data_contributor_id) || []),
			...(this.createDimensions("team_id", filters.team_id) || [])
		];

		if (filterData) {
			defaultMetricInput.dimensions = filterData;
		}

		const metricsRequestParams: IDashboardAcumenMetricDataRequest[] = metricsToCalculate
			.map((metricName: DevStatsMetricNameInDatabase) => Object.assign({label: metricName}, defaultMetricInput)
		);

		return await postData<any, IDashboardAcumenMetricDataResponse[], any>(
			this.createCustomerEntityRoute(MULTI_METRICS_ROUTE),
			this.token,
			{ metricsParams: metricsRequestParams },
			this.tokenType
		);
	}

	async getTeamComparisonSelectedMetrics(dcId: string) {
		const query: Record<string, string | string[]> = {};

		query["keys[]"] = "teamComparison.selectedMetrics";
		query[`context[${ConfigurationEntityType.DataContributor}]`] = dcId;

		return await getData<Record<string, WorkforceHealthMetrics[]>, any>(
			this.createCustomerEntityRoute(`${CONFIGURATION_ROUTE}?${qsStringify(query)}`),
			this.token);
	}

	async getWorkForceSelectedMetrics(dcId: string) {
		const query: Record<string, string | string[]> = {};

		query["keys[]"] = "dailyDevStats.selectedMetrics";
		query[`context[${ConfigurationEntityType.DataContributor}]`] = dcId;

		return await getData<Record<string, WorkforceHealthMetrics[]>, any>(
			this.createCustomerEntityRoute(`${CONFIGURATION_ROUTE}?${qsStringify(query)}`),
			this.token);
	}

	public rerunBadgeCalculationTask = async () => {
		return await postData<any, void, any>(
			this.createCustomerEntityRoute(urlJoin(DEVELOPER_BADGES_ROUTE, TASKS_SUB_ROUTE, CommonEndPoints.UPDATE)),
			this.token
		);
	}

	private createDimensions(dimension: "data_contributor_id" | "team_id", values: string[] = []) {
		if (values && values.length > 0) {
			const dimensions: string[] = [];
			dimensions.push(`${dimension}=${values.join(TUPLE_SEPARATOR)}`);
			return dimensions;
		}
		return undefined;
	}
}
