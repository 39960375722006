import { MixpanelEventType } from "@acumen/dashboard-common";
import React, {
	createContext,
	ReactNode,
	useContext,
} from "react";
import { sendMessageToGoRetroFromPokerPlanning } from "..";

interface IMixpanelContext {
	track: (event: MixpanelEventType, properties?: Record<string, any>) => void;
}

const MixpanelContext = createContext<IMixpanelContext | undefined>(undefined);

interface Props {
	children: ReactNode;
}

interface MixpanelEvent {
	event: string;
	properties?: Record<string, any>;
}

export default function MixpanelProvider(props: Props) {
	const { children } = props;

	const track = (event: MixpanelEventType, properties?: Record<string, any>) => {
		const dataPack: MixpanelEvent = {
			event,
			properties
		};

		sendMessageToGoRetroFromPokerPlanning("track-mixpanel", dataPack);
	};

	return (
		<MixpanelContext.Provider
			value={{
				track
			}}
		>
			{children}
		</MixpanelContext.Provider>
	);
}

export function useMixpanel() {
	return useContext(MixpanelContext);
}
