import * as Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import React, { useState } from "react";
import { useStores } from "../../../mobx-stores";
import moment from "moment-timezone";
import _ from "lodash";
import {
	DashboardProjectEntityType, DashboardPRSizeToMergeTimeBucket, GitRepositoryIntegrationType,
	IDashboardPRSizeToMergeTime, IDashboardPullRequestEntity
} from "@acumen/dashboard-common";
import LoadingIndicator from "../../../components/loader";
import { inflect } from "@acumen/common";
import { IChartScope } from "./chart-scope";
import { observer } from "mobx-react";
import useDeepCompareEffect from "use-deep-compare-effect";
import colorScheme from "../../../pages/my-team/analytics/charts/chart-color-scheme";

HighchartsMore(Highcharts);

interface IProps {
	scope: Omit<IChartScope, "teamIds">;
	teamIds?: string[];
	dataContributorsIds?: string[];
	onClick?: (pr: IDashboardPullRequestEntity) => void;
}

const DEFAULT_MAX_DAYS = 20;
const DEFAULT_MAX_SIZE = 1000;
const minMonthsForMonthlyTickInterval = 6;

function PRSizeToMergeTimeChart(props: IProps) {
	const { chartsStore, authStore } = useStores();
	const [chartOptions, setChartOptions] = useState<Highcharts.Options | undefined>(undefined);
	const [previousProps, setPreviousProps] = useState<IProps | undefined>(undefined);
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const minDateForMonthlyTickInterval: moment.Moment = moment().subtract(minMonthsForMonthlyTickInterval, "month").startOf("day");

	const getTickIntervalxAxis = (startTime: Date): number => {
		if (minDateForMonthlyTickInterval.isSameOrAfter(moment(startTime).startOf("day"))) {
			return moment.duration(1, "month").asMilliseconds();
		}
		return moment.duration(1, "day").asMilliseconds();
	};

	function buildChartOptions(data: IDashboardPRSizeToMergeTime): Highcharts.Options {
		const {
			display: {
				maxDays = DEFAULT_MAX_DAYS,
				maxSize = DEFAULT_MAX_SIZE
			} = {}
		} = data ?? {};

		const timezone = authStore.authUser.timezone;

		const seriesData = _.mapValues(data.pullRequestData,
			(prData, type) => {
				return prData.map<Highcharts.PointOptionsObject>(({ pullRequest, mergedAtDate, mergeTimeMs, size, additions, deletions }) => {
					const mergeTimeInDays = Number(moment.duration(mergeTimeMs, "milliseconds").asDays().toFixed(2));
					const mergeTimeInHours = Number(moment.duration(mergeTimeMs, "milliseconds").asHours().toFixed(2));

					/*
						 Highcharts requires the x-axis be a number, and since we want the values in the chart
						 to be "rounded" to the day (as higher resolution might not be as useful)
						 we're zeroing out the time portion of our merged date and leaving only the date
					*/
					return {
						x: moment.tz(mergedAtDate, timezone)
							.hour(0)
							.minute(0)
							.second(0)
							.millisecond(0)
							.valueOf(),
						y: mergeTimeInDays > maxDays
							? maxDays + 1
							: Math.floor(mergeTimeInDays),
						z: Math.min(size, maxSize),
						// Custom Properties
						size,
						additions,
						deletions,
						mergeTime: mergeTimeInDays < 1
							? inflect(mergeTimeInHours, "hour", "hours")
							: inflect(mergeTimeInDays, "day", "days"),
						id: pullRequest.entityId,
						type,
						mergedAtDate: moment.tz(mergedAtDate, timezone).format("L"),
						prNumber: pullRequest.publicIdentifier,
						prTitle: pullRequest.title,
						prUrl: pullRequest.publicHtmlUrl ?? "",
						pr: pullRequest,
						prRepoName: pullRequest.repositoryName ?? ""
					};
				});
			});

		const series: Highcharts.SeriesBubbleOptions[] = [
			{
				data: seriesData[DashboardPRSizeToMergeTimeBucket.Slow],
				color: colorScheme.prTimeToMerge.slow,
				name: "Slow",
				visible: seriesData[DashboardPRSizeToMergeTimeBucket.Slow]?.length > 0,
				type: "bubble"
			},
			{
				data: seriesData[DashboardPRSizeToMergeTimeBucket.Normal],
				color: colorScheme.prTimeToMerge.normal,
				name: "Normal",
				visible: seriesData[DashboardPRSizeToMergeTimeBucket.Normal]?.length > 0,
				type: "bubble"
			},
			{
				data: seriesData[DashboardPRSizeToMergeTimeBucket.Fast],
				color: colorScheme.prTimeToMerge.fast,
				name: "Fast",
				visible: seriesData[DashboardPRSizeToMergeTimeBucket.Fast]?.length > 0,
				type: "bubble"
			}
		];
		const options: Highcharts.Options = {
			chart: {
				type: "bubble",
				zoomType: "xy"
			},
			title: undefined,
			legend: {
				alignColumns: false
			},
			xAxis: {
				type: "datetime",
				tickInterval: getTickIntervalxAxis(props.scope.startTime),
				gridLineWidth: 1,
				labels: {
					// tslint:disable-next-line: object-literal-shorthand
					formatter: function _formatter() {
						return moment.tz(this.value, timezone).format("ddd, MMM Do");
					},
					style: {
						color: "gray",
						textOverflow: "none",
					}
				},
			},
			tooltip: {
				useHTML: true,
				pointFormat: `<a target="_blank" rel="noopener noreferrer" href="{point.prUrl}">{point.prRepoName}#{point.prNumber}</a> {point.prTitle}<br />
			Changes: +{point.additions}, -{point.deletions}, total {point.size} lines of code<br />
			Merge time: {point.mergeTime}<br />
			Merge date: {point.mergedAtDate}`,
				followPointer: true,
				style: {
					pointerEvents: "auto"
				}
			},
			yAxis: {
				startOnTick: false,
				endOnTick: false,
				title: {
					text: "Cycle time (days)"
				},
				labels: {
					formatter: function _formatter() {
						if (this.value === 11) {
							return "> 11d";
						}
						return `${this.value}d`;
					}
				}
			},
			plotOptions: {
				bubble: {
					crisp: true,
					events: {
						click: function _click(e: Highcharts.SeriesClickEventObject) {
							if (props.onClick) {
								const extendedPoint = e.point as Highcharts.Point & { pr: IDashboardPullRequestEntity };
								props.onClick(extendedPoint.pr);
							}
						}
					}
				},
			},
			credits: {
				enabled: false
			},
			series
		};
		return options;
	}

	async function fetchData(): Promise<IDashboardPRSizeToMergeTime | undefined> {
		const scope = props.scope;
		let repositoryIds: string[] | undefined;
		if (scope.repositoryIds && scope.repositoryIds.length > 0) {
			repositoryIds = scope.repositoryIds.map(r => `${r},${GitRepositoryIntegrationType.GITHUB}`);
		}
		let projectIds: string[] | undefined;
		if (scope.projectIds && scope.projectIds.length > 0) {
			projectIds = scope.projectIds.map(p => `${p},${DashboardProjectEntityType.JIRA}`);
		}
		const chartData = await chartsStore.fetchPRSizeToMergeTimeData(props.teamIds,
			props.dataContributorsIds, projectIds, scope.startTime, scope.endTime, repositoryIds);

		return chartData;
	}

	useDeepCompareEffect(() => {
		let isMounted = true;
		async function fetchChartData() {
			if (!previousProps || !_.isEqual(
				_.omit(previousProps, "onClick"),
				_.omit(props, "onClick"),
			)) {
				setIsFetching(true);
				if (!isMounted) {
					return;
				}
				setPreviousProps(props);
				const data = await fetchData();
				if (isMounted && data) {
					setChartOptions(buildChartOptions(data));
					setIsFetching(false);
				}
			}
		}
		// tslint:disable-next-line: no-floating-promises
		fetchChartData();
		return () => { isMounted = false; };
	}, [_.omit(props, "onClick")]);

	return (
		<LoadingIndicator local={true} isActive={chartOptions === undefined || isFetching}>
			{chartOptions && <HighchartsReact
				highcharts={Highcharts}
				options={chartOptions}
			/>}
		</LoadingIndicator>
	);
}

export default observer(PRSizeToMergeTimeChart);
