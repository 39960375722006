import React, { useMemo } from "react";
import { AcumenTaskStatus } from "@acumen/database-types";
import { IDashboardCycleTimeBreakdown } from "@acumen/dashboard-common";
import CycleTimeBreakdownPhase from "./cycle-time-breakdown-phase";

interface CycleTimeBreakdownProgressProps {
	cycleTimeBreakdown: IDashboardCycleTimeBreakdown;
	index?: number;
	currentStatus?: AcumenTaskStatus;
}

const CycleTimeBreakdownProgress = (props: CycleTimeBreakdownProgressProps) => {
	const { cycleTimeBreakdown, index, currentStatus } = props;
	const statuses = cycleTimeBreakdown.details.statuses;
	const totalCycleTimeSeconds = useMemo(() => statuses.reduce((sum, status) => sum + status.totalSeconds!, 0), [statuses]);

	return (
		<div className="cycle-time-breakdown-progress">
			<div className="cycle-time-breakdown-progress-list">
				{statuses.map((status, offset) => {
					const width = status.totalSeconds! / totalCycleTimeSeconds * 100;

					return (
						<CycleTimeBreakdownPhase
							key={`${status.acumenTaskStatus}${offset}`}
							status={status}
							highlight={!currentStatus || (currentStatus === status.acumenTaskStatus && index === offset)}
							width={width}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default CycleTimeBreakdownProgress;
