import { CommitEventType, IssueEventType, PullRequestEventType } from "@acumen/dashboard-common";

export const TIMELINE_EVENTS_FILTER = [
	CommitEventType.CommitAuthored,
	PullRequestEventType.PullRequestAssigned,
	PullRequestEventType.PullRequestClosed,
	PullRequestEventType.PullRequestComment,
	PullRequestEventType.PullRequestDeployed,
	PullRequestEventType.PullRequestLabeled,
	PullRequestEventType.PullRequestMerged,
	PullRequestEventType.PullRequestOpened,
	PullRequestEventType.PullRequestReadyForReview,
	PullRequestEventType.PullRequestRenamed,
	PullRequestEventType.PullRequestReopened,
	PullRequestEventType.PullRequestReviewDismissed,
	PullRequestEventType.PullRequestReviewRequestRemoved,
	PullRequestEventType.PullRequestReviewRequested,
	PullRequestEventType.PullRequestReviewedApproved,
	PullRequestEventType.PullRequestReviewedChangesRequested,
	PullRequestEventType.PullRequestReviewedCommented,
	PullRequestEventType.PullRequestUnassigned,
	PullRequestEventType.PullRequestUnlabeled,
	IssueEventType.IssueAssigned,
	IssueEventType.IssueAttachment,
	IssueEventType.IssueCommentDeleted,
	IssueEventType.IssueComponentChanged,
	IssueEventType.IssueCustomFieldChange,
	IssueEventType.IssueDescriptionChanged,
	IssueEventType.IssueDueDateChanged,
	IssueEventType.IssueEnvironmentChanged,
	IssueEventType.IssueFixVersionChanged,
	IssueEventType.IssueLabelsChanged,
	IssueEventType.IssueOpened,
	IssueEventType.IssueOriginalTimeEstimateChanged,
	IssueEventType.IssuePriorityChanged,
	IssueEventType.IssueProjectChanged,
	IssueEventType.IssueReporterChanged,
	IssueEventType.IssueResolutionChanged,
	IssueEventType.IssueStatusChanged,
	IssueEventType.IssueSummaryChanged,
	IssueEventType.IssueTypeChanged,
	IssueEventType.IssueVersionsChanged
];
