import React from "react";
import { Popup, PopupContentProps, SemanticShorthandItem } from "semantic-ui-react";
import "./graphs.scss";

interface IPieChart {
	percentileRank?: number;
	animate?: boolean;
	color?: string;
	width?: number;
	tooltip?: SemanticShorthandItem<PopupContentProps>;
}

const ARCH_MAX = 50;
const BOX_SIZE = 32;
const circleParams = {
	r: BOX_SIZE / 2,
	cx: BOX_SIZE / 2,
	cy: BOX_SIZE / 2
};
const indicatorParams = {
	r: BOX_SIZE / 8,
	cx: BOX_SIZE / 2,
	cy: BOX_SIZE / 2
};

// tslint:disable-next-line: variable-name
const PieChart = (props: IPieChart) => {
	const { percentileRank, animate, color, width = BOX_SIZE, tooltip } = { ...props };
	const pieRatio: string = Math.min((percentileRank ? (percentileRank * 100) / 2 : 0), ARCH_MAX).toString(10);

	const getChart = () =>
		(
			<div className="pie-chart">
				<svg className="pie" style={{ width, top: width / 4 }} viewBox={"0 0 " + BOX_SIZE + " " + BOX_SIZE}>
					<circle className={"pie-background " + color} {...circleParams} strokeDasharray="50 100" />
					<circle className={"pie-fill " + color + (animate ? " animate" : "")} {...circleParams} strokeDasharray={pieRatio + " 100"} />

					<circle className={"pie-indicator " + color} {...indicatorParams} strokeDasharray="50 100" />
					<rect className="pie-indicator-h" x="0" y="0" width={BOX_SIZE} height={BOX_SIZE / 2} />
				</svg>
			</div >
		);

	return (
		tooltip ?
			(
				<Popup
					content={tooltip}
					basic={true}
					hoverable={true}
					trigger={
						(getChart())
					}
				/>
			)
			:
			getChart()
	);
};
export default PieChart;
