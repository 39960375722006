import React, { useCallback } from "react";
import { Checkbox } from "semantic-ui-react";
import classNames from "classnames";
import { ResolvedFilterOption } from "./types";

interface FilterDropdownItemProps {
	item: ResolvedFilterOption;
	isSelected: boolean;
	onToggle: (value: string | number) => void;
}

const FilterDropdownItem = (props: FilterDropdownItemProps) => {
	const { item, isSelected, onToggle } = { ...props };
	const { value, label } = item;
	const handleToggle = useCallback(() => onToggle(value), [value, onToggle]);
	const className = classNames("item", isSelected && "selected");

	return (
		<li className={className} onClick={handleToggle}>
			<Checkbox checked={isSelected}/>
			<span className="item-text">{label}</span>
		</li>
	);
};

export default FilterDropdownItem;
