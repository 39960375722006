import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./style.scss";
import { useStores } from "../../mobx-stores";
import { observer } from "mobx-react";
import { useInterval } from "usehooks-ts";
import SprintSelector from "./sprint-selector";
import LoadingPage from "./loading-page";
import boardsAnimation from "../../components/animations/boards_animation.json";
import sprintsAnimation from "../../components/animations/sprints_animation.json";
import usersAnimation from "../../components/animations/users_animation.json";
import secondFetchLoadingAnimation from "../../components/animations/second_fetch_loading_animation.json";
import {
	ConfigurationEntityType, CustomizableConfigurationCategories, DevelopmentMethodology, MixpanelEventType
} from "@acumen/dashboard-common";
import { useHistory } from "react-router-dom";
import { getAutoDetectParams, ProgressUpdate } from "../../mobx-stores/go-retro-sprint-review-store";
import GoRetroConfiguration from "./go-retro-configuration";
import { sleep } from "@acumen/common";
import { mixpanelService } from "../../external-app";
import "moment/min/locales";
import { GoRetroMessages, sendMessageToGoRetro } from "./go-retro-messages";
import { Loader } from "semantic-ui-react";

interface IExternalPage {
	type: ExternalPageEnum;
	title?: string;
	animationSrc?: any;
	isLastFetch?: boolean;
}

enum ExternalPageEnum {
	DataFetcher = "DataFetcher",
	SprintSelector = "SprintSelector",
	CustomConfigurations = "CustomConfigurations"
}

const PAGES_DATA: IExternalPage[] = [
	{ title: "Fetching Boards...", animationSrc: boardsAnimation, type: ExternalPageEnum.DataFetcher },
	{ title: "Fetching Sprints...", animationSrc: sprintsAnimation, type: ExternalPageEnum.DataFetcher },
	{ title: "Fetching Users...", animationSrc: usersAnimation, type: ExternalPageEnum.DataFetcher },
	{ type: ExternalPageEnum.SprintSelector },
	{ type: ExternalPageEnum.CustomConfigurations },
	{ animationSrc: secondFetchLoadingAnimation, type: ExternalPageEnum.DataFetcher, isLastFetch: true }
];

const FIRST_FETCH_FIRST_LOADING_INDEX = PAGES_DATA.findIndex((p) => p.type === ExternalPageEnum.DataFetcher);
const SPRINT_SELECTOR_INDEX = PAGES_DATA.findIndex((p) => p.type === ExternalPageEnum.SprintSelector);
const SECOND_FETCH_INDEX = PAGES_DATA.findIndex((p) => p.isLastFetch);
const CUSTOM_CONFIGURATIONS_INDEX = PAGES_DATA.findIndex((p) => p.type === ExternalPageEnum.CustomConfigurations);

const FIRST_FETCH_INTERVAL_MS = 8000;
export const SECOND_FETCH_INTERVAL_MS = 10000;
export const SLEEP_DURATION_MS = 5000;

enum FetchPhase {
	FirstFetch,
	SecondFetch
}

// tslint:disable-next-line: variable-name
const GoRetroIntegrationFlow = observer((props: { isTeamOwner?: boolean }) => {
	const { isTeamOwner = true } = props;
	const [index, setIndex] = useState<number>(0);
	const [isStarted, setIsStarted] = useState<boolean>(false);
	const [waitingFor, setWaitingFor] = useState<FetchPhase | null>(null);
	const history = useHistory();

	const {
		goRetroSprintSummaryStore: {
			fetchIntegrationProgress,
			progressUpdate,
			setSprintSelection,
			fetchAutoDetectedConfigurations
		},
		customizationStore: {
			updateConfigurationByCategory,
		},
		sprintsStore
	} = useStores();

	const hasError = useMemo(() => {
		if (progressUpdate === undefined) {
			return false;
		}

		const isFirstFetch = PAGES_DATA[index].type === ExternalPageEnum.DataFetcher && !PAGES_DATA[index].isLastFetch;
		return !progressUpdate.hasActiveJIRAIntegration ||
			(isFirstFetch && !progressUpdate.fastFetchingFinished && !!progressUpdate.fastFetchingError);
	}, [progressUpdate, index]);

	function notifyIntegrationDone() {
		sendMessageToGoRetro(GoRetroMessages.JiraIntegrationCompleted);
	}

	async function redirectToSprintSelector() {
		return sprintsStore.fetchData(DevelopmentMethodology.Scrum, { excludeSprintsWithFutureStartDate: true }).then(() => {
			setIndex(SPRINT_SELECTOR_INDEX);
		});
	}

	async function handleJiraIntegrationFlow(progress: ProgressUpdate | undefined) {
		if (canBeRedirectedToSprintSummary(progress)) {
			if (isTeamOwner) {
				notifyIntegrationDone();
			}
			redirectToSprintRetro();
			return;
		}

		if (isTeamOwner) {
			await handleOwnerFlow(progress);
			setIsStarted(true);
			return;
		}

		setIndex(SECOND_FETCH_INDEX);
		setIsStarted(true);
	}

	async function handleOwnerFlow(progress: ProgressUpdate | undefined) {
		if (progress?.fastFetchingFinished) {
			if (progress.sprintsSelectedPerformed && progress.goRetroConfigurationPerformed) {
				setIndex(SECOND_FETCH_INDEX);
				return;
			}
			await redirectToSprintSelector();
		}

		setWaitingFor(FetchPhase.FirstFetch);
	}

	useEffect(() => {
		fetchIntegrationProgress().then((progress) => {
			handleJiraIntegrationFlow(progress).then().catch();
		}).catch();
	}, []);

	useEffect(() => {
		if (hasError) {
			sleep(SLEEP_DURATION_MS).then(() => {
				sendMessageToGoRetro(GoRetroMessages.ExitIFrame);
			}).catch();
		}
	}, [hasError]);

	useEffect(() => {
		if (index === SECOND_FETCH_INDEX) {
			setWaitingFor(FetchPhase.SecondFetch);
		}
	}, [index]);

	useInterval(async () => {
		let fetchingIntegrationProgress = false;
		if (PAGES_DATA[index].type === ExternalPageEnum.DataFetcher && !PAGES_DATA[index].isLastFetch && !hasError && !fetchingIntegrationProgress) {
			fetchingIntegrationProgress = true;
			const progress = await fetchIntegrationProgress();
			fetchingIntegrationProgress = false;
			if (progress?.fastFetchingFinished) {
				sendMessageToGoRetro(GoRetroMessages.FffFetchingFinished);
				await redirectToSprintSelector();
				return;
			}
			if (index === SPRINT_SELECTOR_INDEX - 1) {
				setIndex(FIRST_FETCH_FIRST_LOADING_INDEX);
			} else {
				setIndex(index + 1);
			}
		}
	}, waitingFor === FetchPhase.FirstFetch ? FIRST_FETCH_INTERVAL_MS: null);

	useInterval(() => {
		let fetchingIntegrationProgress = false;
		if (index === SECOND_FETCH_INDEX && !fetchingIntegrationProgress) {
			fetchingIntegrationProgress = true;
			// tslint:disable-next-line: no-floating-promises
			fetchIntegrationProgress().then((progress) => {
				fetchingIntegrationProgress = false;
				if (canBeRedirectedToSprintSummary(progress)) {
					redirectToSprintRetro();
					setWaitingFor(null);
				}
			});
		}
	}, waitingFor === FetchPhase.SecondFetch ? SECOND_FETCH_INTERVAL_MS : null);

	function canBeRedirectedToSprintSummary(progress: ProgressUpdate | undefined) {
		return progress?.fastFetchingFinished && progress.sprintsSelectedPerformed
			&& progress.goRetroConfigurationPerformed && progress.secondFetchingFinished;
	}

	function redirectToSprintRetro() {
		if (window.location === window.parent.location) {
			history.push("sprint-monitoring");
		} else {
			sendMessageToGoRetro(GoRetroMessages.RedirectToSprintSummary);
		}
	}

	const setUserConfigurations = async (sprintIds: string[], defaultConfig: boolean) => {
		const res: any = await fetchAutoDetectedConfigurations(getAutoDetectParams(sprintIds));

		const configs: any = {};
		Object.entries(res.data).forEach(([key, value]) => {
			configs[key as any as string] = {
				single: value
			};
		});

		await updateConfigurationByCategory(
			{
				category: CustomizableConfigurationCategories.GoRetro,
				configs
			},
			ConfigurationEntityType.CustomerDefault,
			undefined, true
		);

		if (defaultConfig) {
			setIndex(SECOND_FETCH_INDEX);
		} else {
			setIndex(CUSTOM_CONFIGURATIONS_INDEX);
		}
	};

	const onNext = useCallback(async (sprintIds: string[], defaultConfig: boolean, onFinally: () => void) => {
		mixpanelService.track(MixpanelEventType.SprintSelection, {
			numberOfSprints: sprintIds.length,
			typeOfConfiguration: defaultConfig ? "default" : "custom"
		});

		await setSprintSelection(sprintIds);
		await setUserConfigurations(sprintIds, defaultConfig);

		onFinally();
	}, []);

	function getStepPage() {
		switch (PAGES_DATA[index].type) {
			case (ExternalPageEnum.DataFetcher):
				return (
					<LoadingPage
						text={PAGES_DATA[index].title ?? ""}
						animationSrc={PAGES_DATA[index].animationSrc}
						isFinished={PAGES_DATA[index].isLastFetch
							? progressUpdate?.secondFetchingFinished
							: progressUpdate?.fastFetchingFinished
						}
						hasError={hasError}
						isLastFetch={PAGES_DATA[index].isLastFetch ?? false}
					/>
				);

			case (ExternalPageEnum.SprintSelector):
				return (
					<SprintSelector
						sprints={sprintsStore.sprintData}
						onNext={onNext}
					/>
				);
			case (ExternalPageEnum.CustomConfigurations):
				return (
					<GoRetroConfiguration
						onNext={() => {
							setIndex(SECOND_FETCH_INDEX);
						}}
						referrer="IntegrationSetUp"
					/>
				);
		}
	}

	return isStarted ? getStepPage() : (
		<div className="external-page loading-page">
			<Loader className="loading-indicator" active={true} inline={true} />
		</div>
	);
});

export default GoRetroIntegrationFlow;
