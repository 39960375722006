import { EventType, IDashboardEvent, OriginatingEntityType, PullRequestEventType } from "@acumen/dashboard-common";
import classNames from "classnames";
import React from "react";

export const eventsFormatting: Partial<Record<EventType, (event: IDashboardEvent) => any>> = {
	[EventType.PullRequestOpened]: (event) => linkedEntityEvent(event, "Pull request", "opened"),
	[EventType.PullRequestMerged]: (event) => linkedEntityEvent(event, "Pull request", "merged"),
	[EventType.PullRequestClosed]: (event) => linkedEntityEvent(event, "Pull request", "closed"),
	[EventType.PullRequestAssigned]: (event) => linkedEntityEvent(event, "Pull request", "assigned"),
	[EventType.PullRequestAssignedByUser]: (event) => linkedEntityEvent(event, "Pull request", "user"),
	[EventType.PullRequestUnassigned]: (event) => linkedEntityEvent(event, "Pull request", "unassigned"),
	[EventType.PullRequestUnassignedByUser]: (event) => linkedEntityEvent(event, "Pull request", "unassigned by user"),
	[EventType.PullRequestComment]: (event) => linkedEntityEvent(event, "Pull request", "comment"),
	[EventType.PullRequestMention]: (event) => linkedEntityEvent(event, "Pull request", "mention"),
	[EventType.PullRequestReviewRequested]: (event) => linkedEntityEvent(event, "Pull request", "review requested"),
	[EventType.PullRequestReviewRequestedByUser]: (event) => linkedEntityEvent(event, "Pull request", "review requested by user"),
	[EventType.PullRequestReviewRequestRemoved]: (event) => linkedEntityEvent(event, "Pull request", "review request removed"),
	[EventType.PullRequestReviewRequestRemovedByUser]: (event) => linkedEntityEvent(event, "Pull request", "review request removed by user"),
	[EventType.PullRequestReviewedCommented]: (event) => linkedEntityEvent(event, "Pull request", "review comment added"),
	[EventType.PullRequestReviewedChangesRequested]: (event) => linkedEntityEvent(event, "Pull request", "changes requested by reviewer"),
	[EventType.PullRequestReviewedApproved]: (event) => linkedEntityEvent(event, "Pull request", "review approved"),
	[EventType.PullRequestMentionedByUser]: (event) => linkedEntityEvent(event, "Pull request", "mentioned by user"),
	[EventType.PullRequestSubscribed]: (event) => linkedEntityEvent(event, "Pull request", "subscribed"),
	[EventType.PullRequestUnlabeled]: (event) => linkedEntityEvent(event, "Pull request", "unlabeled"),
	[EventType.PullRequestLabeled]: (event) => linkedEntityEvent(event, "Pull request", "labeled"),
	[EventType.PullRequestUnsubscribed]: (event) => linkedEntityEvent(event, "Pull request", "unsubscribed"),
	[EventType.PullRequestReferenced]: (event) => linkedEntityEvent(event, "Pull request", "referenced"),
	[EventType.PullRequestReopened]: (event) => linkedEntityEvent(event, "Pull request", "reopened"),
	[EventType.PullRequestRenamed]: (event) => linkedEntityEvent(event, "Pull request", "renamed"),
	[EventType.PullRequestDeployed]: (event) => linkedEntityEvent(event, "Pull request", "deployed"),
	[EventType.PullRequestReadyForReview]: (event) => linkedEntityEvent(event, "Pull request", "ready for review"),
	[EventType.PullRequestReviewDismissed]: (event) => linkedEntityEvent(event, "Pull request", "review dismissed"),
	[EventType.PullRequestCommentDeleted]: (event) => linkedEntityEvent(event, "Pull request", "comment deleted"),
	[EventType.PullRequestCommitCommented]: (event) => linkedEntityEvent(event, "Pull request", "commit comment added"),
	[EventType.PullRequestLineCommented]: (event) => linkedEntityEvent(event, "Pull request", "line comment added"),
	[EventType.PullRequestCrossReferenced]: (event) => linkedEntityEvent(event, "Pull request", "cross referenced"),
	[EventType.PullRequestUnknown]: (event) => linkedEntityEvent(event, "Pull request", "unknown event"),
	[EventType.CommitCommitted]: (event) => linkedEntityEvent(event, "Commit", "committed"),
	[EventType.CommitAuthored]: (event) => linkedEntityEvent(event, "Commit", "authored"),
	[EventType.IssueOpened]: (event) => linkedEntityEvent(event, "Issue", "opened"),
	[EventType.IssueAssigned]: (event) => linkedEntityEvent(event, "Issue", "assigned", true),
	[EventType.IssueAssignedByUser]: (event) => linkedEntityEvent(event, "Issue", "assigned by user"),
	[EventType.IssueStatusChanged]: (event) => linkedEntityEvent(event, "Issue", "status changed", true),
	[EventType.IssueAttachment]: (event) => linkedEntityEvent(event, "Issue", "attachment"),
	[EventType.IssueCommentDeleted]: (event) => linkedEntityEvent(event, "Issue", "comment deleted"),
	[EventType.IssueComponentChanged]: (event) => linkedEntityEvent(event, "Issue", "component changed", true),
	[EventType.IssueDescriptionChanged]: (event) => linkedEntityEvent(event, "Issue", "description changed"),
	[EventType.IssueDueDateChanged]: (event) => linkedEntityEvent(event, "Issue", "due date changed", true),
	[EventType.IssueEnvironmentChanged]: (event) => linkedEntityEvent(event, "Issue", "environment changed", true),
	[EventType.IssueFixVersionChanged]: (event) => linkedEntityEvent(event, "Issue", "fix version changed", true),
	[EventType.IssueTypeChanged]: (event) => linkedEntityEvent(event, "Issue", "type changed", true),
	[EventType.IssueLabelsChanged]: (event) => linkedEntityEvent(event, "Issue", "labels changed", true),
	[EventType.IssuePriorityChanged]: (event) => linkedEntityEvent(event, "Issue", "priority changed", true),
	[EventType.IssueProjectChanged]: (event) => linkedEntityEvent(event, "Issue", "project changed", true),
	[EventType.IssueReporterChanged]: (event) => linkedEntityEvent(event, "Issue", "reporter changed", true),
	[EventType.IssueResolutionChanged]: (event) => linkedEntityEvent(event, "Issue", "resolution changed", true),
	[EventType.IssueResolutionDateChanged]: (event) => linkedEntityEvent(event, "Issue", "resolution date changed", true),
	[EventType.IssueSummaryChanged]: (event) => linkedEntityEvent(event, "Issue", "summary changed", true),
	[EventType.IssueOriginalTimeEstimateChanged]: (event) => linkedEntityEvent(event, "Issue", "original time estimate changed", true),
	[EventType.IssueTimeEstimateChanged]: (event) => linkedEntityEvent(event, "Issue", "time estimate changed", true),
	[EventType.IssueTimeSpentChanged]: (event) => linkedEntityEvent(event, "Issue", "time spent changed", true),
	[EventType.IssueVersionsChanged]: (event) => linkedEntityEvent(event, "Issue", "versions changed", true),
	[EventType.IssueSprintChanged]: (event) => linkedEntityEvent(event, "Issue", "sprint changed", true),
	[EventType.IssueUnknownFieldChange]: (event) => linkedEntityEvent(event, "Issue", `field ${event.changelog?.type ?? ""} changed`, true),
	[EventType.IssueCustomFieldChange]: (event) => linkedEntityEvent(event, "Issue", "custom field changed", true)
};

const linkedEntityEvent = (event: IDashboardEvent, prefix?: string, suffix?: string, showChangeLog?: boolean) => {
	const formatPublicIdentifier = (_event: IDashboardEvent) => {
		switch (_event.entityType) {
			case OriginatingEntityType.GitHubCommit:
				return _event.entity?.publicIdentifier?.toString().substring(0, 7);
			default:
				return _event.entity?.publicIdentifier?.toString();
		}
	};

	return (
		<div style={{ whiteSpace: "break-spaces" }}>
			{prefix}
			<a
				href={event.entity?.publicHtmlUrl ?? ""}
				className={classNames(event.entity?.publicHtmlUrl && "acu-link")}
				target="_blank"
				rel="noopener noreferrer"
				style={{ whiteSpace: "break-spaces" }}
			>
				{Object.values(PullRequestEventType).find(t => t === event.eventType) && "#"}
				{formatPublicIdentifier(event)}
			</a>
			{suffix}
			{showChangeLog &&
				<>
					{event.changelog?.from ?
						<span style={{ whiteSpace: "break-spaces" }}>{` from `}<strong>{event.changelog?.from}</strong></span> : <></>
					}
					{event.changelog?.to &&
						<span>{` to `}<strong>{event.changelog?.to}</strong></span>
					}
				</>
			}
		</div>
	);
};
