import React from "react";
import { inflect, round } from "@acumen/common";

const DAY_IN_HOURS = 24;

// if (!this.y || this.y === 0) {
// 	return "-";
// }
// if (this.y < 1) {
// 	return `Less than 1 hour<br/>`;
// }
// if (this.y === 1) {
// 	return `1 hour<br/>`;
// }
// if (this.y < DAY_IN_HOURS) {
// 	return `${this.y} hours<br/>`;
// }
// const inDays = round(this.y / DAY_IN_HOURS, 1);
// return `${inDays} days<br/>`;

// tslint:disable-next-line: variable-name
export const Formatters = {
	cycleTime(timeDurationHours: number | undefined): string {
		if (!timeDurationHours || timeDurationHours === 0) {
			return "-";
		}
		if (timeDurationHours < 1) {
			return `Less than 1 hour`;
		}
		if (timeDurationHours < DAY_IN_HOURS) {
			return `${timeDurationHours} hours`;
		}
		const inDays = round(timeDurationHours / DAY_IN_HOURS, 1);
		return inflect(inDays, "day", "days");
	},
	richFormatCycleTime(props: {
		durationInHours: number | undefined;
		shortUnits?: boolean;
		rootClassName?: string;
		prefixClassName?: string;
		valueClassName?: string;
		unitClassName?: string;
	}): React.ReactElement {
		const {
			durationInHours,
			shortUnits = false,
			prefixClassName,
			rootClassName,
			valueClassName,
			unitClassName
		} = props;

		if (!durationInHours || durationInHours === 0) {
			return <span className={rootClassName}>-</span>;
		}

		if (durationInHours < DAY_IN_HOURS) {
			return (
				<span className={rootClassName}>
					{durationInHours < 1 && <span className={prefixClassName}>Less than</span>}
					<span className={valueClassName}>{durationInHours}</span>
					<span className={unitClassName}>
						{shortUnits ? "h" : (durationInHours === 1 ? " h" : " hours")}
					</span>
				</span>
			);
		}

		const inDays = round(durationInHours / DAY_IN_HOURS, 1);

		return (
			<span className={rootClassName}>
				<span className={valueClassName}>{inDays}</span>
				<span className={unitClassName}>
					{shortUnits ? "d" : (inDays === 1 ? " day" : " days")}
				</span>
			</span>
		);
	},
};
