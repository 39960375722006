import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { CustomTableFilter, ResolvedCustomTableColumnProps, SortState } from "../../types";
import { CustomSelect } from "../../../custom-select";
import Funnel from "../../../svg-assets/funnel.svg";
import { CustomTableSortControls } from "../custom-table-sort-controls/custom-table-sort-controls";
import { useResolvedFilterOptions } from "../../hooks/useResolvedFilterOptions";
import "./custom-table-header-cell.scss";
import Tooltip from "v2/components/tooltip/tooltip";

export interface CustomTableHeaderCellProps<T extends object> {
	column: ResolvedCustomTableColumnProps<T>;
	sortable: boolean;
	items: T[];
	sortState: SortState<T> | null;
	filters: Array<CustomTableFilter<T>>;
	onSortStateChange: (state: SortState<T> | null) => void;
	onFiltersChange: (filters: Array<CustomTableFilter<T>>) => void;
}

export const CustomTableHeaderCell = <T extends object>(props: CustomTableHeaderCellProps<T>) => {
	const { column, sortable, items, sortState, filters, onSortStateChange, onFiltersChange } = props;
	const { key, title, subtitle, headerCellClassName, sticky, filterable, tooltip } = column;
	const filterableProps = typeof filterable === "object" ? filterable : { disabled: !filterable };
	const className = classNames(
		headerCellClassName,
		"custom-table-cell",
		"custom-table-cell-header",
		sticky && "variant-sticky",
	);
	const triggerClass = classNames(
		"custom-table-filter-select-trigger",
		filterableProps && !filterableProps.disabled && filters.length > 0 && "variant-active"
	);
	const columnFilter = useMemo(() => filters.find(filter => filter.key !== key), [key, filters]);
	const filterValues = useMemo(() => columnFilter?.selectedValues ?? [], [columnFilter]);
	const resolvedFilterOptions = useResolvedFilterOptions({
		column,
		items,
		filterable: filterableProps,
	});

	const handleFiltersChange = useCallback((filterSelectedValues: Array<string | number>, groupName?: string) => {
		const newFilters = filters.filter(filter => filter.key !== key && groupName === filter.groupName);

		if (filterSelectedValues.length > 0) {
			newFilters.push({ key, selectedValues: filterSelectedValues, groupName });
		}

		onFiltersChange(newFilters);
	}, [filters, onFiltersChange]);

	return (
		<th className={className}>
			<div className="custom-table-cell-content-container">
				<div className="custom-table-cell-content">
					<Tooltip className="custom-table-header-tooltip" text={tooltip}>
					<div className="custom-table-cell-content-title-container">
						{title && (
							<div className="custom-table-cell-content-title">
								{title}
							</div>
						)}

						{subtitle && (
							<div className="custom-table-cell-content-subtitle">
								{subtitle}
							</div>
						)}
						</div>
					</Tooltip>

					<div className="custom-table-cell-content-controls">
						{(column.sortable || (sortable && column.sortable !== false)) && (
							<CustomTableSortControls
								className="custom-table-cell-content-sort-controls"
								column={column}
								sortState={sortState}
								onSortStateChange={onSortStateChange}
							/>
						)}

						{!filterableProps.disabled && (
							<CustomSelect
								className="custom-table-filter-select"
								value={filterValues}
								options={resolvedFilterOptions}
								onChange={handleFiltersChange}
								trigger={
									<button type="button" className={triggerClass}>
										<img src={Funnel} alt="Filter select trigger"/>
									</button>
								}
								shouldGroup={true}
							/>
						)}
					</div>
				</div>
			</div>
		</th>
	);
};
