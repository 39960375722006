import _ from "lodash";
import { ConfigValueOptionsPair, ObjectKey, ObjectWithKeys, SingleValueConfig } from "./types";

export function isObjectWithKeys<Keys extends ObjectKey[]>(
	value: unknown,
	...keys: Keys
): value is ObjectWithKeys<Keys> {
	return _.isObject(value) && keys.every(key => value.hasOwnProperty(key));
}

export function isSingleValueConfig(value: unknown): value is SingleValueConfig {
	return isObjectWithKeys(value, "single");
}

export function isConfigValueOptionsPair(value: unknown): value is ConfigValueOptionsPair {
	return isObjectWithKeys(value, "value", "possibleValues") && _.isArray(value.possibleValues);
}
