import React, { useCallback, useEffect, useRef } from "react";
import classnames from "classnames";
import { useOnClickOutside } from "usehooks-ts";

export interface DeleteNoteModelProps {
	onCancel: () => void;
	onDelete: () => Promise<void>;
}

export function DeleteNoteModal({
	onCancel,
	onDelete
}: DeleteNoteModelProps) {

	const ref = useRef(null);

	useOnClickOutside(ref, () => onCancel());

	const onEscape = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === "Escape" && onCancel) {
				onCancel();
			}
		},
		[onCancel]
	);

	useEffect(() => {
		document.addEventListener("keydown", onEscape, false);

		return () => {
			document.removeEventListener("keydown", onEscape, false);
		};
	}, [onEscape]);

	return (
		<div
			className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 backdrop-blur-sm backdrop-brightness-50 bg-black/30"
			onClick={(e)=> {
				e.stopPropagation();
			}}
		>
			<div className="relative h-full w-full max-w-2xl p-4 md:h-auto">
				<div
					className="relative rounded-lg bg-[#FFFFFF] w-3/4 h-5/6 border border-[#CED5DF] rounded-lg shadow flex justify-center items-center text-center"
					ref={ref}
				>
					<div>
						<div
							className="text-2xl flex justify-center items-center text-center mt-8 mb-8 w-[360px]"
						>
							Are you sure you want to delete this note?
						</div>
						<div className="mb-4">
							<button
								type="button"
								className={classnames(
									"text-base",
									"text-sm",
									"items-center",
									"justify-center",
									"rounded transition duration-200 ease",
									"font-bold",
									"w-24",
									"h-8",
									"bg-white hover:bg-[#CED5DC38] text-[#676E71]",
									"mr-1"
								)}
								onClick={(e)=> {
									e.stopPropagation();
									onCancel();
									// noteTextArea.focus();
								}}
							>
								Cancel
							</button>
							<button
								type="button"
								className={classnames(
									"text-base",
									"text-sm",
									"items-center",
									"justify-center",
									"rounded transition duration-200 ease",
									"font-bold",
									"w-24",
									"h-8",
									"bg-[#ED51A3] text-white hover:bg-[#d54892]",
									"ml-1"
								)}
								onClick={async (e)=> {
									e.stopPropagation();
									await onDelete();
									// noteTextArea.focus();
								}}
							>
								Delete
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
