import * as React from "react";
export const ExportIcon = (props: React.SVGProps<SVGSVGElement>) => (
<svg
	width="20"
	height="20"
	viewBox="0 0 20 20"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
	{...props}
>
	<path d="M10.5535 0.493923C10.4114 0.338518 10.2106 0.25 10 0.25C9.78943 0.25 9.58857 0.338518 9.44648 0.493923L5.44648 4.86892C5.16698 5.17462 5.18822 5.64902 5.49392 5.92852C5.79963 6.20802 6.27402 6.18678 6.55352 5.88108L9.25 2.9318V14C9.25 14.4142 9.58579 14.75 10 14.75C10.4142 14.75 10.75 14.4142 10.75 14V2.9318L13.4465 5.88108C13.726 6.18678 14.2004 6.20802 14.5061 5.92852C14.8118 5.64902 14.833 5.17462 14.5535 4.86892L10.5535 0.493923Z" fill="#1B3F7B"/>
	<path d="M1.75 13C1.75 12.5858 1.41422 12.25 1 12.25C0.585788 12.25 0.250001 12.5858 0.250001 13V13.0549C0.24998 14.4225 0.249964 15.5248 0.366524 16.3918C0.487541 17.2919 0.746434 18.0497 1.34835 18.6516C1.95027 19.2536 2.70814 19.5125 3.60825 19.6335C4.47522 19.75 5.57754 19.75 6.94513 19.75H13.0549C14.4225 19.75 15.5248 19.75 16.3918 19.6335C17.2919 19.5125 18.0497 19.2536 18.6517 18.6516C19.2536 18.0497 19.5125 17.2919 19.6335 16.3918C19.75 15.5248 19.75 14.4225 19.75 13.0549V13C19.75 12.5858 19.4142 12.25 19 12.25C18.5858 12.25 18.25 12.5858 18.25 13C18.25 14.4354 18.2484 15.4365 18.1469 16.1919C18.0482 16.9257 17.8678 17.3142 17.591 17.591C17.3142 17.8678 16.9257 18.0482 16.1919 18.1469C15.4365 18.2484 14.4354 18.25 13 18.25H7C5.56459 18.25 4.56347 18.2484 3.80812 18.1469C3.07435 18.0482 2.68577 17.8678 2.40901 17.591C2.13225 17.3142 1.9518 16.9257 1.85315 16.1919C1.75159 15.4365 1.75 14.4354 1.75 13Z" fill="#1B3F7B"/>
</svg>
);
export default ExportIcon;
