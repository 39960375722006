import _ from "lodash";
import { action, computed, observable } from "mobx";
import apiContextProvider from "../../services/api-context-provider";
import { PrioritiesApiClient, PRIORITIES_ROUTE } from "../services/crud/priorities-api-client";
import {
	IDashboardJiraPriority
} from "@acumen/dashboard-common";
import BaseStore from "./base-store";
import { FetchLatestRequest } from "../../services/fetch-helpers";

export default class PrioritiesStore extends BaseStore<{}> {
	private readonly priorityClient: PrioritiesApiClient = new PrioritiesApiClient(apiContextProvider);

	@observable
	public priorities: IDashboardJiraPriority[] | undefined = undefined;

	@observable
	public isLoadingPriorities: boolean = false;

	private fetchLatestPriorities = new FetchLatestRequest<IDashboardJiraPriority[], any>(PRIORITIES_ROUTE);
	@action.bound
	async fetchJiraPriorities(limit?: number) {
		this.isLoadingPriorities =  true;
		const prioritiesResult = await this.fetchLatestPriorities.fetchLatest(this.priorityClient.fetchPriorities(undefined, limit));
		this.isLoadingPriorities = false;
		this.priorities = prioritiesResult?.data ?? [] as IDashboardJiraPriority[];

		return this.priorities;
	}

	@computed
	public get prioritiesByOrder() {
		if (this.priorities === undefined) {
			return undefined;
		}

		return _.keyBy(this.priorities, p => p.order);
	}
}
