import React, { useState } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

function SSOEmailForm() {
	const [email, setEmail] = useState("");
	const history = useHistory();

	return (
		<div>
			<form
				className="ui form"
				onSubmit={e => {
					e.preventDefault();
					history.push(`/sso/${email}`);
				}}
			>
				<div className="field">
					<label>
						Email
					</label>
					<input
						type="text"
						name="email"
						placeholder="email@domain.com"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</div>
				<button
					className="ui button"
					type="submit"
				>
					Submit
				</button>
			</form>
		</div>
	);
}

export default observer(SSOEmailForm);
