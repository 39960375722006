import moment from "moment";
import { DateFormat, dateFormatByLocale } from "@acumen/common";

export class DateFormatters {
	private readonly isRangeSelector: boolean;
	private readonly locale: string;

	constructor(isRangeSelector: boolean = true, locale: string = "en") {
		this.isRangeSelector = isRangeSelector;
		this.locale = locale;
	}

	private longWithDayNoYearDateTimeFormat() {
		return 	dateFormatByLocale(DateFormat.LongWithDayNoYear, this.locale);
	}

	private longWithDayDateTimeFormat() {
		return 	dateFormatByLocale(DateFormat.LongWithDay, this.locale);
	}

	public dateRangeLabelFormat(start: Date | undefined, end: Date | undefined) {
		if (!start) {
			return "";
		}
		const date = moment(start);
		return (this.isRangeSelector && end !== undefined) && !moment(start).isSame(end, "days") ?
			`${date.format(this.longWithDayNoYearDateTimeFormat())} - ${moment(end).format(this.longWithDayNoYearDateTimeFormat())}`
			: date.format(this.longWithDayDateTimeFormat());
	}
}

export const DEFAULT_DATE_RANGE_IN_DAYS = 1;
export const MAX_DAYS_RANGE = 21;
