import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

interface IPieChart {
	series?: Highcharts.SeriesOptionsType[];
	title?: string;
	height?: number;
	triggerHover?: string;
}

// tslint:disable-next-line: variable-name
const PieChart = (props: IPieChart) => {
	const { series, height, title, triggerHover } = { ...props };

	const [chartRef, setChartRef] = useState<undefined | Highcharts.Chart>(undefined);
	const chartOptions: Highcharts.Options = {
		chart: {
			type: "pie",
			margin: -2,
			height
		},
		series,
		title: {
			text: title ? `<div class="small-text"><p>${title}</p></div>` : undefined,
			useHTML: true,
			verticalAlign: "middle",
			align: "center",
		},
		subtitle: {
			text: undefined
		},
		plotOptions: {
			column: {
				groupPadding: 0,
			},
			pie: {
				innerSize: "80%",
				dataLabels: {
					enabled: false,
				}
			},
		},
		legend: {
			enabled: false,
		},
		tooltip: {
			pointFormat: "<b>{point.y}</b>"
		},
		responsive: {
			rules: [{
				condition: {
					maxWidth: 500
				},
				chartOptions: {
					legend: {
						layout: "horizontal",
						align: "center",
						verticalAlign: "bottom"
					}
				}
			}]
		},
		credits: {
			enabled: false
		},
	};

	useEffect(() => {
		const seriesByName = chartRef?.series[0].points
			.find((point: Highcharts.Point) => point.name === triggerHover);
		if (seriesByName) {
			seriesByName.setState("hover");
		}
	}, [triggerHover]);

	return (
		<HighchartsReact
			highcharts={Highcharts}
			options={chartOptions}
			allowChartUpdate={true}
			callback={setChartRef}
		/>
	);
};
export default PieChart;
