import React, { useCallback, useEffect, useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";
import CloseButton from "../svg-assets/DialogCloseButton";
import classnames from "classnames";
import { ConnectionRequestType } from "..";
import { useMixpanel } from "../adapters/mixpanel";
import { useIntercom } from "../adapters/intercom";
import { IntercomEventType, MixpanelEventType } from "@acumen/dashboard-common";

export interface ReconnectToJiraModalProps {
	onCancel: () => void;
	onConnectToJira: (type: ConnectionRequestType) => void;
}

export function ReconnectToJiraModal({
	onCancel,
	onConnectToJira
}: ReconnectToJiraModalProps) {
	const mixpanel = useMixpanel();
	const intercom = useIntercom();

	const ref = useRef(null);

	useOnClickOutside(ref, () => onCancel());

	const onEscape = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === "Escape" && onCancel) {
				onCancel();
			}
		},
		[onCancel]
	);

	useEffect(() => {
		document.addEventListener("keydown", onEscape, false);

		return () => {
			document.removeEventListener("keydown", onEscape, false);
		};
	}, [onEscape]);

	return (
		<div
			className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 backdrop-blur-sm backdrop-brightness-50 bg-black/30"
			onClick={(e)=> {
				e.stopPropagation();
			}}
		>
			<div className="relative h-full w-full max-w-2xl p-4 md:h-auto">
				<div
					className="relative bg-[#FFFFFF] w-[673px] h-[280px] border border-[#CED5DF] rounded-lg shadow flex justify-center items-center text-center"
					ref={ref}
				>
					<button
						type="button"
						className="absolute top-3 right-2.5 ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
						onClick={() => onCancel()}
					>
						<CloseButton className="h-5 w-5" />
					</button>
					<div className={classnames(
						"flex", "items-center", "justify-end", "mt-2"
						)
					}>
						<div className="m-8">
							<div className="text-2xl font-bold text-left">
								Approve write permissions
							</div>
							<div
								className="text-base text-left mt-4"
							>
								To export from Planning poker to Jira you need to approve write permissions.<br/> When you click on 'Continue' we will redirect you to Jira to approve.
							</div>
							<div className="mb-4 mt-16 flex justify-end">
								<button
									type="button"
									className={classnames(
										"text-base",
										"items-center",
										"justify-center",
										"rounded transition duration-200 ease",
										"font-semibold",
										"w-52",
										"h-12",
										"bg-gradient-to-r from-[#16375E] to-[#ED51A3] text-white p-0.5",
										"mr-6"
									)}
									onClick={(e)=> {
										e.stopPropagation();
										onCancel();
									}}
								>
									<div className=" h-full w-full bg-white text-black flex items-center justify-center">
										<div
											className="text-transparent bg-clip-text bg-gradient-to-r from-[#16375E] to-[#ED51A3]"
										>
											Cancel
										</div>
									</div>
								</button>
								<button
									type="button"
									className={classnames(
										"text-base",
										"rounded transition duration-200 ease",
										"font-semibold",
										"w-52",
										"h-12",
										"bg-gradient-to-r from-[#16375E] to-[#ED51A3] text-white",
									)}
									onClick={async (e)=> {
										e.stopPropagation();
										onConnectToJira("request-write");
										mixpanel?.track(MixpanelEventType.JiraReconnectClicked);
										intercom?.track(IntercomEventType.JiraReconnectClicked);
									}}
								>
									Continue
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
