export function capitalizeName(name: string) {
	return name.toLocaleLowerCase().split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
}

export function numberShortened(value: number): string {
	if (value < 0) {
		return `-${calculateShortenedValue(value)}`;
	}
	return calculateShortenedValue(value);
}

function calculateShortenedValue(value: number): string {
	const positiveValue = Math.abs(value);
	const ranges = [
		{ divider: 1e18, suffix: "E" },
		{ divider: 1e15, suffix: "P" },
		{ divider: 1e12, suffix: "T" },
		{ divider: 1e9, suffix: "G" },
		{ divider: 1e6, suffix: "M" },
		{ divider: 1e3, suffix: "k" }
	];

	for (const range of ranges) {
		if (positiveValue >= range.divider) {
			return (positiveValue / range.divider).toFixed(1).toString() + range.suffix;
		}
	}
	return positiveValue.toString();
}
