export enum GA_EVENT_CATEGORY {
	Users = "Users",
	UserEdit = "UserEdit",
	UserInvite = "UserInvite",
	Teams = "Teams",
	TeamEdit = "TeamEdit",
	TeamAdd = "TeamAdd",
	Navigation = "Navigation",
	Contributors = "Contributors",
	Customization = "Customization",
	OrgMetrics = "OrgMetrics",
	PRBreakdown = "PRBreakdown"
}

export enum GA_EVENT_ACTION {
	Delete = "Delete",
	Edit = "Edit",
	Add = "Add",
	Select = "Select",
	Cancel = "Cancel",
	Close = "Close",
	Save = "Save",
	Filter = "Filter",
	Duplicate = "Duplicate",
}

export function clickEvent(category: GA_EVENT_CATEGORY, action: GA_EVENT_ACTION, label?: string) {
	return {
		"ga-on": "click",
		"ga-event-category": category,
		"ga-event-action": action,
		"ga-event-label": label
	};
}

export interface IClickEvent {
	"ga-on": string;
	"ga-event-category": GA_EVENT_CATEGORY;
	"ga-event-action": GA_EVENT_ACTION;
	"ga-event-label"?: string;
}
