import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import Linkify from "linkify-react";
import toast from "react-hot-toast";
import {
	convertFromCSV,
	FileSource,
	exportToCSV,
} from "../service/issue-convertor";
import { IDashboardIntegration, IPlanningPokerDeck, IPlanningPokerIssue, MixpanelEventType, PokerIssuesSortOption } from "@acumen/dashboard-common";
import { AddIssueModal, FAKE_ISSUE_KEY_PREFIX } from "./AddIssueModal";
import Dropdown from "./Dropdown";
import IssuesEmptyState from "../svg-assets/IssuesPickerEmptyState";
import LoadingIndicator from "../svg-assets/LoadingIndicator";
import IssuesMoreMenuIcon from "../svg-assets/IssuesMoreMenu";
import IssuesDeleteIcon from "../svg-assets/IssuesDelete";
import AddIssueIcon from "../svg-assets/AddIssueMenu";
import { useMixpanel } from "../adapters/mixpanel";
import { ImportCustomCSVInstructionsModal } from "./ImportCustomCSVInstructionsModal";
import { NewPlanningPokerIssue } from "../../../services/crud/go-retro-planning-poker-api-client";
import IssuesSearch from "../svg-assets/IssuesSearch";
import _ from "lodash";
import NotesIcon from "../svg-assets/NotesIcon";
import NotesArrowRight from "../svg-assets/NotesArrowRight";
import NotesArrowDown from "../svg-assets/NotesArrowDown";
import VerticalEllipsis from "../svg-assets/VerticalEllipsis";
import EditIcon from "../svg-assets/EditIcon";
import DeleteIcon from "../svg-assets/DeleteIcon";
import { DeleteNoteModal } from "./DeleteNoteModal";
import { useIntercom } from "../adapters/intercom";
import { IntegrationStatus, IntercomEventType } from "@acumen/database-types";
import ExportIcon from "../svg-assets/ExportIcon";
import { ConnectToJiraModal } from "./ConnectToJiraModal";
import { ConnectionRequestType, sendMessageToGoRetroFromPokerPlanning } from "..";
import { ExportToJiraModal } from "./ExportToJiraModal";
import { ReconnectToJiraModal } from "./ReconnectToJiraModal";
import { ImportJiraJqlModal } from "./ImportJiraCsvModal";

const VALID_LINK_PREFIXES_REGEXP = "(http://|https://|//).*";

const NOTES_BUTTON_TEXT_COLOR = "#7D8CA2";

const getValidLink = (link: string) => {
	if (link.match(VALID_LINK_PREFIXES_REGEXP)) {
		return link;
	}
	return `//${link}`;
};

const LinkifyOptions = {
	className: "text-blue-500 hover:underline break-all",
	rel: "noopener noreferrer",
	target: "_blank",
	validate: true,
};
interface IEmptyStateProps {
	isModerator: boolean;
	shouldShowCreateIssue: boolean;
	onCreateIssueClick: () => void;
}

function requestJiraConnection(type: ConnectionRequestType) {
	sendMessageToGoRetroFromPokerPlanning("jira-connection-request", type);
}

const EmptyState: React.FC<IEmptyStateProps> = ({
	isModerator,
	shouldShowCreateIssue,
	onCreateIssueClick,
}) => {
	return (
		<div className="flex flex-col justify-center items-center px-4 pt-4">
			<IssuesEmptyState className="w-3/5"/>
			{(isModerator && shouldShowCreateIssue) && (
				<h2
					className="text-xl font-normal text-[#004BA6] mt-4 border-b-2 border-[#004BA6] cursor-pointer"
					onClick={onCreateIssueClick}
				>
					Create your first issue
				</h2>

			)}
			{(!isModerator || !shouldShowCreateIssue) && (
				<h2 className="text-xl font-normal text-[#004BA6] mt-4">
					No issues found
				</h2>
			)}
		</div>
	);
};

interface IIssueItem  {
	issue: IPlanningPokerIssue;
	votingOnIssueId?: string;
	onDeleteClick: () => void;
	onVoteClick: () => void;
	isModerator: boolean;
	onSaveEstimation: (issue: IPlanningPokerIssue, estimation: string) => Promise<void>;
	currentGameDeck: IPlanningPokerDeck;
	onSaveNote: (issue: IPlanningPokerIssue, note?: string | null) => Promise<void>;
}

const IssueCardItem: React.FC<IIssueItem> = ({
	issue,
	onDeleteClick,
	onVoteClick,
	votingOnIssueId,
	isModerator,
	onSaveEstimation,
	currentGameDeck,
	onSaveNote,
}) => {

	const voteButtonText = () => {
		if (votingOnIssueId && votingOnIssueId === issue.xid) {
			if (!isModerator) {
				return "Voting now";
			}
			return "Vote again";
		}
		return "Vote now";
	};

	const [isDeleting, setOnDeleting] = useState<boolean>(false);
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const [isNoteAreaExpanded, setIsNoteAreaExpanded] = useState<boolean>(!!issue.note);
	const [isEstimationChangeDropdownOpen, setIsEstimationChangeDropdownOpen] = useState<boolean>(false);
	const [isNoteInEditMode, setIsNoteInEditMode] = useState<boolean>(!issue.note);
	const [textAreaValue, setTextAreaValue] = useState<string | undefined>(issue.note ?? (!isModerator ? " " : undefined));
	const [showOnHoverNoteEditModeButtons, setShowOnHoverNoteEditModeButtons] = useState<boolean>(false);
	const [showDeleteNoteModal, setShowDeleteNoteModal] = useState<boolean>(false);

	const currNoteRef = React.createRef<HTMLTextAreaElement>();

	const mixpanel = useMixpanel();
	const intercom = useIntercom();

	return (
		<div
			onClick={() => {
				if (isEstimationChangeDropdownOpen) {
					setIsEstimationChangeDropdownOpen(false);
				}
				setIsExpanded(!isExpanded);
				if (isExpanded && !!issue.note) {
					setIsNoteAreaExpanded(true);
				}
			}}
			className={classnames(
				"rounded-lg",
				"bg-white",
				"ml-4",
				"mr-4",
				"mt-4",
				"p-4",
				"justify-between",
				"shadow-md",
				`${isExpanded ? "flex-col" : "flex-row"}`,
				{
					"border-[#949DAC]": !votingOnIssueId || votingOnIssueId !== issue.xid,
				},
				{
					"border-2 border-[#399CFF]":
						votingOnIssueId && votingOnIssueId === issue.xid,
				},
			)}
			key={issue.xid}
		>
			<div className="flex items-center">
				<div>
					<span
						className={classnames(
							`text-[#004BA6] bg-[#E4ECF9] text-lg font-bold px-2.5 py-0.5 rounded mr-2 flex relative
							items-center justify-center border border-[#9AAFB7] shadow-inner w-11 h-14`,
							{
								"hover:cursor-pointer hover:shadow-indigo-500/40":
								isModerator,
							}
						)}
						onClick={(e) => {
							e.stopPropagation();
							setIsEstimationChangeDropdownOpen(!isEstimationChangeDropdownOpen);
						}}
						>
						{issue.estimation || "?"}
					</span>
					{isModerator && (
						<div className="flex items-center">
							<div>
								<div className={`${isEstimationChangeDropdownOpen
										? `absolute right-auto z-50 transition duration-200 ease border border-[#949DAC] bg-[#FFFEFE]
											my-22 right-4 w-64 rounded drop-shadow-lg overflow-hidden w-48 p-4 flex
											flex-wrap overflow-hidden items-center justify-center space-around space-4`
										: "hidden"
									}`}>
									{currentGameDeck.cards.map((card, i) => {
										return (
											<div
												className="w-12 h-12 flex align-center justify-center hover:cursor-pointer hover:bg-gray-200 rounded-lg"
												onClick={(e) => {
													e.stopPropagation();
													try {
														onSaveEstimation(issue, card.displayValue).catch();
														setIsEstimationChangeDropdownOpen(false);
													} catch (error) {
														toast.error("Could not save estimation");
													}
												}}
												key={i}
												>
												{card.displayValue}
											</div>
										);
									})}
								</div>
							</div>
						</div>
					)}
				</div>
				<div className="w-full">
					<div className="flex items-center justify-between">
							{issue.link && (
								<a
									href={getValidLink(issue.link)}
									className="text-xs font-light text-[#230B33] hover:underline hover:text-[#1F092D] hover:font-medium"
									target="_blank"
									rel="noreferrer"
								>
									{issue.key}
								</a>
							)}
							{!issue.link && (
								<span className="text-sm font-light text-[#230B33]">{issue.key}</span>
							)}
					{isModerator && isExpanded && (
						<button
							className={classnames(
								"inline-block rounded-lg text-sm text-gray-500",
								{
									"hover:ring-2 focus:outline-none focus:ring-gray-200 transition duration-200 ease":
										!isDeleting,
								}
							)}
							type="button"
							onClick={() => {
								if (isDeleting) {
									return;
								}
								if (!isModerator) {
									return;
								}
								setOnDeleting(true);
								onDeleteClick();
							}}
						>
							{isDeleting && (
								<LoadingIndicator className="inline w-4 h-4 text-white animate-spin" />
							)}
							{!isDeleting && (
								<IssuesDeleteIcon className={classnames(
									"w-4 h-4",
								)} />
							)}
						</button>
					)}
					</div>
					<h2 className="text-sm tracking-tight line-clamp-2 hover:line-clamp-none hover:font-medium">
						{issue.title}
					</h2>
				</div>
			</div>
			{issue.description && isExpanded && (
				<div>
					<Linkify
						as="article"
						options={LinkifyOptions}
						className="mb-3 mt-2 text-[#7D8CA2] text-sm"
					>
						{issue.description}
					</Linkify>
				</div>
			)}
				<div
					className={classnames(
						"flex", "items-center", "justify-between",
						{
							"mt-4": isExpanded,
						})
					}
				>
					{isExpanded && (
						<button
							className={classnames(
								"flex", "items-center"
							)}
							onClick={(e) => {
								e.stopPropagation();
								setIsNoteAreaExpanded(!isNoteAreaExpanded);
								if (!textAreaValue && !isNoteInEditMode) {
									setIsNoteInEditMode(!isNoteInEditMode);
								}
							}}
						>
							<NotesIcon/>
							<div
							className={classnames(
								"ml-2", "text-sm", "font-bold", `text-[${NOTES_BUTTON_TEXT_COLOR}]`
							)}
							>
								Notes
							</div>
							{!isNoteAreaExpanded && (
								<NotesArrowRight className="ml-2"/>
							)}
							{isNoteAreaExpanded && (
								<NotesArrowDown className="ml-2"/>
							)}
						</button>
					)}
					<button
						className={classnames(
							"text-base",
							"text-sm",
							"flex",
							"items-center",
							"justify-center",
							"rounded transition duration-200 ease",
							"font-bold",
							"w-24",
							"h-8",
							{
								"bg-white text-[#96B5E8] hover:bg-[#e3edfc] border border-[#96B5E8]":
								!isExpanded && isModerator && votingOnIssueId && votingOnIssueId === issue.xid,
							},
							{
								"bg-[#96B5E8] text-white hover:bg-[#6b91cf]":
								!isExpanded && isModerator && (!votingOnIssueId || votingOnIssueId !== issue.xid),
							},
							{
								"bg-[#ED51A3] text-white hover:bg-[#d54892]":
								isExpanded,
							},
							{
								invisible: !isModerator,
							},
						)}
						onClick={(e) => {
							e.stopPropagation();

							if (isDeleting) {
								return;
							}

							if (!isModerator) {
								return;
							}

							if (votingOnIssueId === issue.xid) {
								return;
							}

							onVoteClick();
						}}
						>
							{voteButtonText()}
						</button>
				</div>
				{isExpanded && isNoteAreaExpanded && (
					<div
					onMouseOver={() => {
						setShowOnHoverNoteEditModeButtons(true);
					}}
					onMouseLeave={() => {
						if (showOnHoverNoteEditModeButtons) {
							setShowOnHoverNoteEditModeButtons(false);
						}
					}}
					>
						<div
							className={classnames(
								"relative"
							)}
						>
							<textarea
								className={classnames(
									"w-full", "h-full", "mt-3", "block p-2.5", "rounded-md", "p-4", "text-sm", "bg-[#E4ECF98A]",
									"caret-[#608EDC]", "resize-none"
								)}
								id="note"
								name="note"
								ref={currNoteRef}
								rows={6}
								placeholder=" Start writing your note..."
								autoFocus={true}
								onClick={(e) => {
									e.stopPropagation();
								}}
								onChange={(e) => {
									setTextAreaValue(e.target.value);
								}}
								readOnly={!isModerator || (isModerator && !isNoteInEditMode)}
								value={textAreaValue}
							/>
							{isModerator && !isNoteInEditMode && showOnHoverNoteEditModeButtons &&(
								<div
									className={classnames(
										"absolute top-0 right-0", "flex", "delay-1000"
									)}
								>
									<div>
										<button
											className={classnames(
												"flex", "items-center", "text-[11px]",
												"justify-start",
												"transition duration-200 ease",
												"bg-white hover:bg-[#CED5DC38] text-[#676E71]",
												"w-[75px]", "h-[29px]", "mt-2", "rounded-t"
											)}
											onClick={(e) => {
												e.stopPropagation();
												setShowOnHoverNoteEditModeButtons(!showOnHoverNoteEditModeButtons);
												setIsNoteInEditMode(!isNoteInEditMode);
												currNoteRef.current?.focus();
												// NOTE: this is done so the cursor will always be at the end of the text on focus
												currNoteRef.current!.selectionStart = textAreaValue?.length ?? 0;
												currNoteRef.current!.selectionEnd = textAreaValue?.length ?? 0;
											}}
										>
											<EditIcon className="ml-1 mr-1"/>
											Edit
										</button>
										<button
											className={classnames(
												"flex", "items-center", "text-[11px]",
												"justify-start",
												"transition duration-200 ease",
												"bg-white hover:bg-[#CED5DC38] text-[#676E71]",
												"w-[75px]", "h-[29px]", "rounded-b"
											)}
											onClick={(e) => {
												e.stopPropagation();
												setShowOnHoverNoteEditModeButtons(!showOnHoverNoteEditModeButtons);
												setShowDeleteNoteModal(true);
											}}
										>
											<DeleteIcon className="ml-1 mr-1"/>
											Delete
										</button>
									</div>
									<VerticalEllipsis className="mt-1"/>
								</div>
							)}
							{!isModerator && !issue.note && (
							<div
								className={classnames(
									"absolute", "flex justify-center items-center", "top-0 right-0", "w-full", "h-full",
								)}
							>
								<div
									className={classnames(
										"text-center", "w-60"
									)}
								>
									<div
										className={classnames(
											"text-sm", "font-semibold"
										)}
									>
										This task has no notes
									</div>
									<div
										className={classnames(
											"text-sm"
										)}
									>
										Once the game owner adds notes, you'll see them right here.
									</div>
								</div>
							</div>
						)}
						</div>
						{isModerator && isNoteInEditMode && (
							<div
							className={classnames(
								"flex", "items-center", "justify-end", "mt-2"
								)
							}
						>
							<button
								className={classnames(
									"text-base",
									"text-xs",
									"flex",
									"items-center",
									"justify-center",
									"w-12",
									"h-6",
									"rounded transition duration-200 ease",
									"bg-[#F0F3F7] text-[#676E71]",
									"mr-1",
									"mt-1"
								)}
								onClick={(e) => {
									e.stopPropagation();
									setIsNoteInEditMode(!isNoteInEditMode);
									if (!issue.note || issue.note.length === 0) {
										setTextAreaValue(undefined);
										setIsNoteAreaExpanded(!isNoteAreaExpanded);
									} else {
										setTextAreaValue(issue.note);
									}
								}}
							>
								Cancel
							</button>
							<button
								className={classnames(
									"text-base",
									"text-xs",
									"flex",
									"items-center",
									"justify-center",
									"rounded transition duration-200 ease",
									"w-10",
									"h-6",
									"bg-[#ED51A3] text-white hover:bg-[#d54892]",
									"mt-1"
								)}
								onClick={async (e) => {
									e.stopPropagation();
									try {
										if (textAreaValue) {
											await onSaveNote(issue, textAreaValue);
											if (!issue.note) {
												mixpanel?.track(MixpanelEventType.UserAddedCommentToPlanningPokerTask);
											}
											setIsNoteInEditMode(!isNoteInEditMode);
											issue.note = textAreaValue;
										}
									} catch (error) {
										if (!issue.note) {
											mixpanel?.track(MixpanelEventType.UserFailedToAddACommentToPlanningPokerTask);
											intercom?.track(IntercomEventType.UserFailedToAddACommentToPlanningPokerTask);
										}
										toast.error("Could not save note");
									}
								}}
							>
								Save
							</button>
						</div>
						)}
					</div>
				)}
			{showDeleteNoteModal && (
				<DeleteNoteModal
					onCancel={() => {
						setShowDeleteNoteModal(false);
					}}
					onDelete={async () => {
						currNoteRef.current?.focus();
						try {
							setShowDeleteNoteModal(false);
							await onSaveNote(issue, null);
							setTextAreaValue("");
							issue.note = null;
							setIsNoteInEditMode(true);
							mixpanel?.track(MixpanelEventType.UserDeletedCommentOfPlanningPokerTask);
						} catch (error) {
							toast.error("Could not delete note");
						}
					}}
				/>
			)}
		</div>
	);
};

export interface IssuePickerProps {
	gameName?: string;
	isModerator: boolean;
	votingOnIssueId?: string;
	issues: IPlanningPokerIssue[];
	currentGameDeck: IPlanningPokerDeck;
	integration?: IDashboardIntegration | null;
	onNewIssues: (issuesToAdd: NewPlanningPokerIssue[]) => void;
	onDeleteIssues: (issuesToDelete: IPlanningPokerIssue[]) => void;
	onVoteIssue: (issueToVote: IPlanningPokerIssue) => void;
	onSortIssues: (option: PokerIssuesSortOption) => void;
	onFilterIssueByKeywords: (filterKeywords: string) => void;
	onSaveEstimation: (issue: IPlanningPokerIssue, estimation: string) => Promise<void>;
	onSaveNote: (issue: IPlanningPokerIssue, note?: string | null) => Promise<void>;
	onGetJiraFields: (integrationId: string) => Promise<any>;
	onUpdateJiraEstimate: (integrationId: string, key: string, field: string, type: "string" | "number", value: string) => Promise<any>;
	onAddJiraComment: (integrationId: string, key: string, comment: string) => Promise<any>;
	isJiraExportEnabled: boolean;
	modalToOpenOnReturnFromJira: ImportExportDialogOptions | null;
	editQsParams: (queryParams: Record<string, string | null>) => void;
}

export const MODAL_TO_OPEN_ON_RETURN_FROM_JIRA = "modalToOpenOnReturnFromJira";

enum AddIssueDialogOption {
	AddManual = "AddManual",
	ImportJiraCSV = "ImportJiraCSV",
	ImportAzureDevOpsCSV = "ImportAzureDevOpsCSV",
	ImportCustomCSV = "ImportCustomCSV",
}

enum MoreSettingsDialogOption {
	DeleteAllIssues = "DeleteAllIssues",
	SortByKey = "SortByKey",
	SortByTitle = "SortByTitle",
	SortByEstimatedFirst = "SortByEstimatedFirst",
	SortByEstimatedLast = "SortByEstimatedLast",
}

export enum ImportExportDialogOptions {
	ExportToJira = "ExportToJira",
	ExportAsCSV = "ExportAsCSV",
	ImportJiraJQL = "ImportJiraJQL",
}

enum JiraConnectionDialogOptions {
	ConnectToJira = "ConnectToJira",
	ReconnectToJira = "ReconnectToJira",
}

type ModalOptions = ImportExportDialogOptions | JiraConnectionDialogOptions;

const IssuePicker: React.FC<IssuePickerProps> = ({
	gameName,
	votingOnIssueId,
	issues,
	onNewIssues,
	onDeleteIssues,
	onVoteIssue,
	isModerator,
	onSortIssues,
	onFilterIssueByKeywords,
	onSaveEstimation,
	currentGameDeck,
	onSaveNote,
	integration,
	onGetJiraFields,
	onUpdateJiraEstimate,
	onAddJiraComment,
	// Disabled in AIO
	// isJiraExportEnabled,
	modalToOpenOnReturnFromJira,
	editQsParams,
}) => {
	const mixpanel = useMixpanel();
	const intercom = useIntercom();

	const [addIssueDialogSelectedOption, setAddIssueDialogSelectedOption] =
		useState<AddIssueDialogOption | undefined>();
	const [uploading, setUploading] = useState(false);
	const [filterIssuesByKeyword, setFilterIssuesByKeyword] =
		useState<string | undefined>();
	const [toggleImportDropdown, setToggleImportDropdown] = useState(false);
	const [toggleThreeDotsDropdown, setToggleThreeDotsDropdown] = useState(false);
	const [nextFakeIssueKey, setNextFakeIssueKey] = useState<number | undefined>();
	const [toggleExportDropDown, setToggleExportDropDown] = useState(false);

	const inputRef = useRef<HTMLInputElement | null>(null);
	const handleTriggerInputUpload = () => inputRef.current?.click();
	const [currentOpenModal, setCurrentOpenModal] = useState<ModalOptions | null>(modalToOpenOnReturnFromJira);
	const [jiraFieldsList, setJiraFieldsList] = useState<any>();

	useEffect(() => {
		if (!modalToOpenOnReturnFromJira || !integration || !integration.hasWritePermissions) {
			return;
		}

		if (modalToOpenOnReturnFromJira === ImportExportDialogOptions.ExportToJira) {
			openExportToJiraModal().catch();
			return;
		}

	}, [integration?.id]);

	useEffect(() => {
		const justNumbers = issues.map(i => {
			let p: number | undefined;
			if (i.key.startsWith(FAKE_ISSUE_KEY_PREFIX)) {
				const justFigure = Number(i.key.replace(FAKE_ISSUE_KEY_PREFIX, ""));
				if (!isNaN(justFigure)) {
					p = justFigure;
				}
			}
			return p;
		}).filter((i): i is NonNullable<typeof i> => !!i);

		const lastFakeIssue = _.max(justNumbers) ?? 0;
		setNextFakeIssueKey(lastFakeIssue + 1);
	}, [issues]);

	const handleUploadCSV = async (input: FileList | null) => {
		if (uploading) {
			toast.error(
				`Uploading is already in progress. Please wait till it will complete and try again`
			);
			return;
		}
		if (!input || !input || input.length === 0) {
			toast.error(`Please select CSV file to import from`);
			return;
		}
		if (input.length > 1) {
			toast.error(`Please select a SINGLE CSV file to import from`);
			return;
		}
		const item = input[0];
		if (!item) {
			toast.error(`Please select CSV file to import from`);
			return;
		}
		setUploading(true);

		let source = FileSource.Custom;
		switch (addIssueDialogSelectedOption) {
			case AddIssueDialogOption.ImportJiraCSV: {
				source = FileSource.Jira;
				break;
			}
			case AddIssueDialogOption.ImportAzureDevOpsCSV: {
				source = FileSource.AzureDevOps;
				break;
			}
			case AddIssueDialogOption.AddManual:
			case AddIssueDialogOption.ImportCustomCSV:
			default: {
				source = FileSource.Custom;
				break;
			}
		}

		if (mixpanel) {
			mixpanel.track(MixpanelEventType.UserImportedIssuesToPoker, {
				importSource: source
			});
		}

		const items = await convertFromCSV(item, source);
		setUploading(false);

		if (source === FileSource.Custom && (!items || items.length === 0)) {
			toast.error(
				`Failed to convert to issues. Please check the make sure your CSV format is (key, title, description, link)`
			);
			return;
		}
		if (!items) {
			toast.error(
				`Failed to convert to issues. Please check the CSV format and try again`
			);
			return;
		}
		if (items.length === 0) {
			toast.error(
				`Could not find any issues to convert. Please check the CSV format and try again`
			);
			return;
		}

		onNewIssues(items);
		setAddIssueDialogSelectedOption(undefined);
	};

	const exportToCsv = () => {
		if (mixpanel) {
			mixpanel.track(MixpanelEventType.UserExportedIssuesFromPoker);
		}

		const blob = exportToCSV(issues);

		const a = document.createElement("a");
		a.download = `export_${new Date()
			.toJSON()
			.slice(0, 10)}.csv`;
		a.href = window.URL.createObjectURL(blob);
		const clickEvt = new MouseEvent("click", {
			view: window,
			bubbles: true,
			cancelable: true,
		});
		a.dispatchEvent(clickEvt);
		a.remove();
	};

	const openExportToJiraModal = async () => {
		try {
			const list = await onGetJiraFields(integration!.id);
			if (list && list.length > 0) {
				setJiraFieldsList(list);
				setCurrentOpenModal(ImportExportDialogOptions.ExportToJira);
			} else {
				toast.error("Could not fetch jira fields list");
			}
		} catch (error: any) {
			toast.error(error.message);
		}
	};

	const exportToJira = async () => {

		if (!integration) {
			editQsParams({ modalToOpenOnReturnFromJira: ImportExportDialogOptions.ExportToJira });
			setCurrentOpenModal(JiraConnectionDialogOptions.ConnectToJira);
			return;
		}

		if (integration.state !== IntegrationStatus.ACTIVE) {
			requestJiraConnection("reconnect");
			mixpanel?.track(MixpanelEventType.JiraReconnectClicked);
			intercom?.track(IntercomEventType.JiraReconnectClicked);
		}

		if (!integration.hasWritePermissions) {
			editQsParams({ modalToOpenOnReturnFromJira: ImportExportDialogOptions.ExportToJira });
			setCurrentOpenModal(JiraConnectionDialogOptions.ReconnectToJira);
			return;
		}

		if (integration.hasWritePermissions) {
			await openExportToJiraModal();
			return;
		}
	};

	const importJiraJql = () => {
		if (!integration) {
			editQsParams({ modalToOpenOnReturnFromJira: ImportExportDialogOptions.ImportJiraJQL });
			setCurrentOpenModal(JiraConnectionDialogOptions.ConnectToJira);
			return;
		}

		setCurrentOpenModal(ImportExportDialogOptions.ImportJiraJQL);
	};

	return (
		<div className="flex flex-col overflow-y-auto min-h-85% mt-4 pb-2.5">
			<input
				accept=".csv"
				type="file"
				ref={inputRef}
				multiple={false}
				hidden={true}
				onChange={async (e) => {
					e.persist();
					await handleUploadCSV(e.currentTarget.files);
					e.target.value = "";
				}}
			/>
			<div className="flex items-end ml-4 mr-4 place-self-stretch">
				<div className="ml-4 text-sm leading-none text-[#230B33] basis-1/2 flex-1">
					{gameName && (
						<span className="block line-clamp-1 font-bold text-[#1B3F7B] mb-1">
							{_.truncate(gameName, { length: 30 })}
						</span>
					)}
					{issues.length} Issues <span className="inline">•</span>{" "}
					<span className="inline">
						{
							issues
								.map((issue) => issue.estimation)
								.filter((i): i is NonNullable<typeof i> => !!i).length
						}{" "}
						Estimated
					</span>
				</div>

				{isModerator && (
					<div className="flex items-center">
						<Dropdown
							open={toggleImportDropdown}
							items={[
								{
									title: "Add New Issue",
									itemKey: AddIssueDialogOption.AddManual,
								},
								{
									title: "Import JIRA CSV",
									itemKey: AddIssueDialogOption.ImportJiraCSV,
								},
								{
									title: "Import Azure DevOps CSV",
									itemKey: AddIssueDialogOption.ImportAzureDevOpsCSV,
								},
								{
									title: "Import Custom CSV",
									itemKey: AddIssueDialogOption.ImportCustomCSV,
								},
							]}
							toggle={setToggleImportDropdown}
							onClickItem={(itemKey: string) => {
								const option = itemKey as AddIssueDialogOption;
								setAddIssueDialogSelectedOption(option);
								switch (option) {
									case AddIssueDialogOption.ImportJiraCSV:
									case AddIssueDialogOption.ImportAzureDevOpsCSV: {
										handleTriggerInputUpload();
										break;
									}
									default:
								}
							}}
							button={
								<button
									className="bg-[#1B3F7B] hover:bg-[#18386E] text-sm text-white font-medium hover:text-white px-4 py-1 rounded transition duration-200 ease inline-flex items-center"
									onClick={(e) => {
										e.preventDefault();
										setToggleImportDropdown(true);
									}}
								>
									Add issue
									<AddIssueIcon className="ml-2 -mr-1 w-5 h-5" />
								</button>
							}
						/>

						<Dropdown
							open={toggleExportDropDown}
							items={[
								// Disabled in AIO
								// {
								// 	title: "Export to Jira",
								// 	itemKey: ImportExportDialogOptions.ExportToJira,
								// 	isDisabled: issues.length === 0 || isJiraExportEnabled === false,
								// },
								{
									title: "Export issues as CSV",
									itemKey: ImportExportDialogOptions.ExportAsCSV,
									isDisabled: issues.length === 0,
								},
								// ACM-7854: un-comment this when BE endpoint is implemented
								// {
								// 	title: "Import Jira JQL",
								// 	itemKey: ImportExportDialogOptions.ImportJiraJQL,
								// 	isDisabled: false,
								// },
							]}
							toggle={setToggleExportDropDown}
							onClickItem={async (itemKey: string) => {
								const option = itemKey as ImportExportDialogOptions;
								switch (option) {
									case ImportExportDialogOptions.ExportToJira: {
										await exportToJira();
										break;
									}

									case ImportExportDialogOptions.ExportAsCSV: {
										exportToCsv();
										break;
									}
									case ImportExportDialogOptions.ImportJiraJQL: {
										importJiraJql();
										break;
									}
									default: {
										break;
									}
								}
							}}
							button={
								<button
									className="hover:bg-[#B4D0F1] px-4 py-2 rounded transition duration-200 ease inline-flex items-center ml-1"
									onClick={(e) => {
										e.preventDefault();
										setToggleExportDropDown(true);
									}}
								>
									<ExportIcon/>
								</button>
							}
						/>

						<Dropdown
							open={toggleThreeDotsDropdown}
							items={[
								{
									title: "😬 Delete All Issues",
									itemKey: MoreSettingsDialogOption.DeleteAllIssues,
									isDisabled: issues.length === 0,
									isDanger: true,
								},
							]}
							toggle={setToggleThreeDotsDropdown}
							onClickItem={(itemKey: string) => {
								const option = itemKey as MoreSettingsDialogOption;
								switch (option) {
									case MoreSettingsDialogOption.SortByEstimatedLast: {
										onSortIssues(PokerIssuesSortOption.EstimatedLast);
										break;
									}
									case MoreSettingsDialogOption.SortByEstimatedFirst: {
										onSortIssues(PokerIssuesSortOption.EstimatedFirst);
										break;
									}
									case MoreSettingsDialogOption.SortByTitle: {
										onSortIssues(PokerIssuesSortOption.Title);
										break;
									}
									case MoreSettingsDialogOption.SortByKey: {
										onSortIssues(PokerIssuesSortOption.Key);
										break;
									}
									case MoreSettingsDialogOption.DeleteAllIssues: {
										onDeleteIssues(issues);
										break;
									}
									default: {
										break;
									}
								}
							}}
							button={
								<button
									className="hover:bg-[#B4D0F1] px-4 py-2 rounded transition duration-200 ease inline-flex items-center ml-1"
									onClick={(e) => {
										e.preventDefault();
										setToggleThreeDotsDropdown(true);
									}}
								>
									<IssuesMoreMenuIcon className="h-4" />
								</button>
							}
						/>
					</div>
				)}
			</div>
			{(issues.length > 0 || (filterIssuesByKeyword && filterIssuesByKeyword.length > 0)) &&
				<div className="flex items-end mt-4 ml-8 mr-8 place-self-stretch">
					<div className="relative border flex items-center py-1 w-full focus-within:shadow-lg bg-white overflow-hidden">
						<i
							className="px-2 py-1 inline-block rounded-lg text-gray-500"
						>
							<IssuesSearch className="w-5 h-5" />
						</i>
						<input
							className="w-full py-1 text-sm font-medium text-gray-700 placeholder-gray-400 bg-white focus:ring-blue-400 focus:outline-none"
							type="text"
							id="search"
							placeholder="Search Issues..."
							onChange={(e) => {
								setFilterIssuesByKeyword(e.target.value);
								onFilterIssueByKeywords(e.target.value);
							}}
						/>
					</div>
				</div>
			}
			{issues.length === 0 && (
				<div className="place-self-center">
					<EmptyState
						isModerator={isModerator}
						shouldShowCreateIssue={issues.length === 0 && (!filterIssuesByKeyword || filterIssuesByKeyword.length === 0)}
						onCreateIssueClick={() => {
							setAddIssueDialogSelectedOption(AddIssueDialogOption.AddManual);
						}}
					/>
				</div>
			)}
			{issues.length > 0 && (
				<div className="flex-grow overflow-y-auto">
					{issues.map((item) => (
						<IssueCardItem
							key={item.xid}
							issue={item}
							isModerator={isModerator}
							onDeleteClick={() => onDeleteIssues([item])}
							onVoteClick={() => onVoteIssue(item)}
							votingOnIssueId={votingOnIssueId}
							onSaveEstimation={onSaveEstimation}
							currentGameDeck={currentGameDeck}
							onSaveNote={onSaveNote}
						/>
					))}
				</div>
			)}
			{addIssueDialogSelectedOption === AddIssueDialogOption.ImportCustomCSV && (
				<ImportCustomCSVInstructionsModal
					onCancel={() => {
						setAddIssueDialogSelectedOption(undefined);
					}}
					onImport={() => {
						handleTriggerInputUpload();
					}}
					/>
			)}
			{addIssueDialogSelectedOption === AddIssueDialogOption.AddManual && (
				<AddIssueModal
					onClose={(addedIssue) => {
						if (addedIssue) {
							onNewIssues([addedIssue]);
						}
						setAddIssueDialogSelectedOption(undefined);
					}}
					nextFakeIssueKey={nextFakeIssueKey || 1}
				/>
			)}
			{currentOpenModal === JiraConnectionDialogOptions.ConnectToJira && (
				<ConnectToJiraModal
					onCancel={() => {
						editQsParams({ modalToOpenOnReturnFromJira: null });
						setCurrentOpenModal(null);
					}}
					onConnectToJira={requestJiraConnection}
					modalText={modalToOpenOnReturnFromJira === ImportExportDialogOptions.ExportToJira
						? ["Export your ", <span key={ImportExportDialogOptions.ExportToJira} className="text-transparent bg-clip-text bg-gradient-to-r from-[#16375E] to-[#ED51A3]">planning poker estimations</span>, " in a single click!"]
						: ["Import your ", <span key={ImportExportDialogOptions.ImportJiraJQL} className="text-transparent bg-clip-text bg-gradient-to-r from-[#16375E] to-[#ED51A3]">Jira issues</span>, " in a single click!"]
					}
				/>
			)}
			{currentOpenModal === JiraConnectionDialogOptions.ReconnectToJira && (
				<ReconnectToJiraModal
					onCancel={() => {
						editQsParams({ modalToOpenOnReturnFromJira: null });
						setCurrentOpenModal(null);
					}}
					onConnectToJira={requestJiraConnection}
				/>
			)}
			{integration && jiraFieldsList && currentOpenModal === ImportExportDialogOptions.ExportToJira && (
				<ExportToJiraModal
					issues={issues}
					jiraFieldsList={jiraFieldsList}
					integrationId={integration!.id}
					onCancel={() => {
						editQsParams({ modalToOpenOnReturnFromJira: null });
						setCurrentOpenModal(null);
					}}
					onUpdateJiraEstimate={onUpdateJiraEstimate}
					onAddJiraComment={onAddJiraComment}
				/>
			)}
			{/* ACM-7854: remove falsey condition when BE endpoint is implemented */}
			{false && integration && currentOpenModal === ImportExportDialogOptions.ImportJiraJQL && (
				<ImportJiraJqlModal
					onCancel={() => {
						editQsParams({ modalToOpenOnReturnFromJira: null });
						setCurrentOpenModal(null);
					}}
				/>
			)}
		</div>
	);
};

export { IssuePicker };
