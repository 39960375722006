import BaseStore, { IBaseStore, ILoadable } from "./base-store";
import { action, observable } from "mobx";

import apiContextProvider from "../../services/api-context-provider";
import { InsightRulesApiClient, INSIGHTS_CATEGORIES_ROUTE, INSIGHT_RULES_ROUTE } from "../services/crud/insight-rules-api-client";

import {
	DeepPartial,
	IDashboardInsightRule, IDashboardInsightsCategory, IDashboardResponse
} from "@acumen/dashboard-common";
import { FetchLatestRequest } from "../../services/fetch-helpers";

export interface IInsightRulesState {
	allInsightRules: ILoadable<IDashboardInsightRule[]>;
	allInsightsCategories: ILoadable<IDashboardInsightsCategory[]>;
	singleInsightRule: ILoadable<IDashboardInsightRule>;
}

export interface IInsightRulesStore extends IInsightRulesState, IBaseStore<IInsightRulesState> {
	fetchAllInsightRules: () => Promise<void>;
	fetchAllInsightsCategories: () => Promise<void>;
	updateInsightRule: (insightRule: Partial<IDashboardInsightRule>) => Promise<void>;
	resetInsightRules: () => void;
	resetInsightsCategories: () => void;
	setSingleInsightRule: (insightRule: IDashboardInsightRule) => void;
	resetSingleInsightRule: () => void;
}

const defaults = {
	allInsightRules: BaseStore.initLoadable([]),
	allInsightsCategories: BaseStore.initLoadable([]),
	singleInsightRule: BaseStore.initLoadable({})
};

export default class InsightRulesStore extends BaseStore<IInsightRulesState> implements IInsightRulesStore {
	private readonly apiClient: InsightRulesApiClient = new InsightRulesApiClient(apiContextProvider);

	@observable
	public allInsightRules: ILoadable<IDashboardInsightRule[]> = defaults.allInsightRules;
	@observable
	public allInsightsCategories: ILoadable<IDashboardInsightsCategory[]> = defaults.allInsightsCategories;
	@observable
	public singleInsightRule: ILoadable<IDashboardInsightRule> = defaults.singleInsightRule;

	fetchLatestAllInsights = new FetchLatestRequest<IDashboardInsightRule[], any>(INSIGHT_RULES_ROUTE);
	@action
	public async fetchAllInsightRules() {
		this.allInsightRules.loading = true;

		const result = await this.fetchLatestAllInsights.fetchLatest(this.apiClient.findAll());

		if (result) {
			const { data } = result;

			this.allInsightRules = {
				data: data as IDashboardInsightRule[],
				metadata: null,
				loaded: true,
				loading: false
			};
		}
	}

	fetchLatestAllInsightsCategories = new FetchLatestRequest<IDashboardInsightsCategory[], any>(INSIGHTS_CATEGORIES_ROUTE);
	@action.bound
	public async fetchAllInsightsCategories() {
		this.allInsightsCategories.loading = true;

		const result = await this.fetchLatestAllInsightsCategories.fetchLatest(this.apiClient.fetchCategories());

		if (result) {
			const { data } = result;

			this.allInsightsCategories = {
				data: data as IDashboardInsightsCategory[],
				metadata: null,
				loaded: true,
				loading: false
			};
		}
	}

	@action
	public async updateInsightRule(insightRule: DeepPartial<IDashboardInsightRule>) {
		let result: IDashboardResponse<IDashboardInsightRule> | null;

		this.singleInsightRule.loading = true;

		result = await this.apiClient.update(insightRule);

		if (result) {
			const { data } = result;

			this.singleInsightRule = {
				data: data as IDashboardInsightRule,
				metadata: null,
				loaded: true,
				loading: false
			};

			const old = this.allInsightRules.data.find(t => t.id === insightRule.id);

			if (old) {
				const idx = this.allInsightRules.data.indexOf(old);
				this.allInsightRules.data[idx] = this.singleInsightRule.data;
			}
		}
	}

	@action
	public resetInsightRules() {
		this.allInsightRules = defaults.allInsightRules;
	}

	@action
	public resetInsightsCategories() {
		this.allInsightsCategories = defaults.allInsightsCategories;
	}

	@action
	public setSingleInsightRule(insightRule: IDashboardInsightRule) {
		this.singleInsightRule.data = insightRule;
	}

	@action
	public resetSingleInsightRule() {
		this.singleInsightRule = defaults.singleInsightRule;
	}
}
