import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import "./style.scss";
import { useStores } from "v2/mobx-stores";
import MyUser from "v2/layout/navigation/my-user";
import { CustomTable } from "v2/components/custom-table/custom-table";
import { CustomTableColumnProps, CustomTableRenderProps, ResolvedCustomTableColumnProps } from "v2/components/custom-table/types";
import { Header, Icon, Label, Popup, Segment } from "semantic-ui-react";
import { ChartType, DashboardExportFormat, DashboardSortOrder, DateRangeType, MetricInterval, getDatesFromTimeSpan } from "@acumen/dashboard-common";
import moment from "moment";
import _ from "lodash";
import { IChartScope } from "../charts/chart-scope";
import DropdownListSelector from "v2/components/form/dropdown-list-selector";
import FiltersSelector from "v2/components/filters-selector/filters-selector";
import { TimeFramesSelector } from "v2/components/filters-selector/sections/time-frames-selector";
import { useHistory, useLocation } from "react-router-dom";
import filtersSlice from "../filters-slice";
import { GA_EVENT_ACTION, GA_EVENT_CATEGORY, clickEvent } from "v2/analytics-events";
import Papa from "papaparse";
import { IPRData, PRColumns } from "./type";
import LoadingIndicator from "v2/components/loader/loader";
import { round } from "@acumen/common";
import { PRFilter, PRFilterType, filterPRsByType } from "./pr-filter/pr-filter";
import { PRTableAggregatedDataRow } from "./pr-table-aggregated-data-row/pr-table-aggregated-data-row";

interface ISelectionState {
	interval?: string;
	dateRange?: string;
	teams?: string[];
	repositories?: string[];
	ranOnStart: boolean;
}

const DEFAULT_DATE_RANGE = DateRangeType.LastMonth;

const QS_PARAM_TEAM = "team";
const QS_PARAM_REPO = "repo";
const QS_PARAM_DATE_RANGE = "range";

export const createSearchParamsForPage = (teamIds: string[], repoIds: string[], dateRange: DateRangeType) => {
	const params = new URLSearchParams({});
	if (repoIds && repoIds.length > 0) {
		repoIds.forEach(x => params.append(`${QS_PARAM_REPO}[]`, x));
	}
	if (teamIds && teamIds.length > 0) {
		teamIds.forEach(x => params.append(`${QS_PARAM_TEAM}[]`, x));
	}
	params.append(QS_PARAM_DATE_RANGE, dateRange);

	return params;
};

const NoPRsPlaceholder = () => (
	<Segment placeholder={true}>
		<Header icon={true}>
			<Icon name="hand spock" />
			We're sorry, but we couldn't find any pull requests that match your search criteria.
		</Header>
	</Segment>
);
const PRInfoCellContent = observer((props: CustomTableRenderProps<IPRData>) => {
	const { item: pr } = props;

	return (
		<div className="pr-info-cell-content">
			<a href={pr["pull request url"] ?? undefined} target="_blank" rel="noopener noreferrer" className="acu-link">
				{pr["repo name"] ?? ""}#{pr["pull request number"]}
			</a>
		</div>
	);
});

const valueByColumn = (column: PRColumns, pr: IPRData): string | number | Date | null => {
	switch (column) {
		case PRColumns.Number: {
			return pr["pull request number"];
		}
		case PRColumns.Title: {
			return pr["pull request title"];
		}
		case PRColumns.CreationTime: {
			return pr["creation time"];
		}
		case PRColumns.MergeTime: {
			return pr["merge time"];
		}
		case PRColumns.CloseTime: {
			return pr["close time"];
		}
		case PRColumns.Opener: {
			return pr.opener;
		}
		case PRColumns.CodingInHours: {
			return pr["coding (in hours)"];
		}
		case PRColumns.WaitingForReviewInHours: {
			return pr["waiting for review (in hours)"];
		}
		case PRColumns.InReviewInHours: {
			return pr["in review (in hours)"];
		}
		case PRColumns.PendingMergeInHours: {
			return pr["pending merge (in hours)"];
		}
		case PRColumns.TotalCycleTimeInHours: {
			const totalInHours =
				[
					PRColumns.CodingInHours,
					PRColumns.WaitingForReviewInHours,
					PRColumns.InReviewInHours,
					PRColumns.PendingMergeInHours
				].map(x => valueByColumn(x, pr))
				.filter(_.isNumber)
				.reduce((acc, curr) => acc + curr, 0);

			return totalInHours;
		}
		default: {
			return null;
		}
	}
};

const createPRCellContent = (column: PRColumns) => {
	return (props: CustomTableRenderProps<IPRData>) => {
		const { item: pr } = props;
		let value = valueByColumn(column, pr);
		if (_.isNumber(value)) {
			value = round(value);
		}
		return (
			<div className="pr-info-cell-content">
				{!value && ""}
				{value && `${value}`}
			</div>
		);
	};
};

// tslint:disable-next-line: variable-name
const PRBreakdown = () => {
	const {
		teamsStore: {
			fetchAllTeams,
			allTeams
		},
		teamMembersStore: {
			fetchTeamMembersForAllTeams,
			teamMembersForAllTeams
		},
		repositoriesStore: {
			fetchData: fetchGitRepoData,
			isLoading: isLoadingGitRepos,
			gitRepositoriesOptionsFlattened
		},
		metricOrgStore,
		authStore
	} = useStores();
	const history = useHistory();
	const location = useLocation();
	const currentQsParams = new URLSearchParams(location.search);

	const startState: ISelectionState = {
		teams: currentQsParams.getAll(`${QS_PARAM_TEAM}[]`) ?? [],
		repositories: currentQsParams.getAll(`${QS_PARAM_REPO}[]`) ?? [],
		dateRange: currentQsParams.get(QS_PARAM_DATE_RANGE) ?? DEFAULT_DATE_RANGE,
		ranOnStart: false
	};
	const [selectedState, changeState] = useState<ISelectionState>(startState);
	const [isDownloadingExportData, setIsDownloadingExportData] = useState(false);
	const [chartScope, setChartScope] = useState<IChartScope | undefined>();
	const [exportedData, setExportedData] = useState<string | undefined>();
	const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
	const [items, setItems] = useState<IPRData[]>([]);
	const [selectedFilterType, setSelectedFilterType] = useState<PRFilterType | undefined>();
	useEffect(() => {
		updateQueryString();
	}, [selectedState]);

	const updateQueryString = () => {
		const params = new URLSearchParams({});
		if (selectedState.teams && selectedState.teams.length > 0) {
			selectedState.teams.forEach(x => params.append(`${QS_PARAM_TEAM}[]`, x));
		}

		if (selectedState.repositories && selectedState.repositories.length > 0) {
			selectedState.repositories.forEach(x => params.append(`${QS_PARAM_REPO}[]`, x));
		}

		if (selectedState.dateRange) {
			params.set(QS_PARAM_DATE_RANGE, selectedState.dateRange);
		}

		history.replace({ pathname: location.pathname, search: params.toString() });
	};

	const compareStatBy = (pr: IPRData, c: ResolvedCustomTableColumnProps<IPRData>) => {
		const v = valueByColumn(c.key as PRColumns, pr);
		if (v === null) {
			return "";
		}
		if (_.isDate(v)) {
			return v.toISOString();
		}
		return v;
	};

	const columnItems = useMemo<Array<CustomTableColumnProps<IPRData>>>(() => {
		return [
			{
				key: PRColumns.Number,
				title: "PR number",
				sticky: true,
				component: PRInfoCellContent,
				sortBy: compareStatBy,
				compareBy: compareStatBy,
			},
			{
				key: PRColumns.Title,
				title: "Title",
				component: createPRCellContent(PRColumns.Title),
				sortBy: compareStatBy,
				compareBy: compareStatBy,
			},
			{
				key: PRColumns.Opener,
				title: "Opener",
				component: createPRCellContent(PRColumns.Opener),
				sortBy: compareStatBy,
				compareBy: compareStatBy,
			},
			{
				key: PRColumns.TotalCycleTimeInHours,
				title: "Total cycle time (in hours)",
				component: createPRCellContent(PRColumns.TotalCycleTimeInHours),
				sortBy: compareStatBy,
				compareBy: compareStatBy,
			},
			{
				key: PRColumns.CodingInHours,
				title: "Coding (in hours)",
				component: createPRCellContent(PRColumns.CodingInHours),
				sortBy: compareStatBy,
				compareBy: compareStatBy,
			},
			{
				key: PRColumns.WaitingForReviewInHours,
				title: "Waiting for review (in hours)",
				component: createPRCellContent(PRColumns.WaitingForReviewInHours),
				sortBy: compareStatBy,
				compareBy: compareStatBy,
			},
			{
				key: PRColumns.InReviewInHours,
				title: "In review (in hours)",
				component: createPRCellContent(PRColumns.InReviewInHours),
				sortBy: compareStatBy,
				compareBy: compareStatBy,
			},
			{
				key: PRColumns.PendingMergeInHours,
				title: "Pending merge (in hours)",
				component: createPRCellContent(PRColumns.PendingMergeInHours),
				sortBy: compareStatBy,
				compareBy: compareStatBy,
			},
			{
				key: PRColumns.CreationTime,
				title: "Creation time",
				component: createPRCellContent(PRColumns.CreationTime),
				sortBy: compareStatBy,
				compareBy: compareStatBy,
			},
			{
				key: PRColumns.MergeTime,
				title: "Merge time",
				component: createPRCellContent(PRColumns.MergeTime),
				sortBy: compareStatBy,
				compareBy: compareStatBy,
			},
			{
				key: PRColumns.CloseTime,
				title: "Close time",
				component: createPRCellContent(PRColumns.CloseTime),
				sortBy: compareStatBy,
				compareBy: compareStatBy,
			}
		];
	}, []);

	useEffect(() => {
		async function fetchFiltersOptions() {
			// tslint:disable-next-line: no-floating-promises
			fetchGitRepoData({}, { order: DashboardSortOrder.Ascending });
			// tslint:disable-next-line: no-floating-promises
			fetchTeamMembersForAllTeams();
			// tslint:disable-next-line: no-floating-promises
			return fetchAllTeams();
		}
		// tslint:disable-next-line: no-floating-promises
		fetchFiltersOptions();
	}, []);

	const onStateChange = (newState: Partial<ISelectionState>) => {
		changeState(x => {
			const res = Object.assign({}, x, newState);

			return res;
		});
	};

	const downloadAsCSV = (exportData: string, scope: IChartScope) => {
		const dateFormat = "YY-MM-DD-HH-mm";
		const element = document.createElement("a");
		const file = new Blob([exportData], { type: "text/csv" });
		element.href = URL.createObjectURL(file);
		element.download = `${_.kebabCase(ChartType.PRCycleTime)}_${scope.interval}_${moment(scope.startTime).format(dateFormat)}--${moment(scope.endTime).format(dateFormat)}.csv`;
		document.body.appendChild(element); // Required for this to work in FireFox
		element.click();
		document.body.removeChild(element);
	};

	const {
		getFilteredValueLabel
	} =
		filtersSlice({
			selectedDateRange: selectedState.dateRange ? selectedState.dateRange as DateRangeType : DEFAULT_DATE_RANGE,
			// We don't really need the selected interval, it is just a value that is passed
			selectedInterval: MetricInterval.DAY,
			timezone: authStore.authUser.timezone
		});

	useEffect(() => {
		if (allTeams.data.length === 0) {
			return;
		}
		const dateRange = (selectedState.dateRange ? (selectedState.dateRange as DateRangeType) : DEFAULT_DATE_RANGE);
		const interval = MetricInterval.DAY;

		const timezone = authStore.authUser.timezone;
		const { endTime, startTime } = getDatesFromTimeSpan(dateRange, timezone, interval);

		const teamIds = (!selectedState.teams || selectedState.teams.length === 0) ?
			allTeams.data.map(t => t.id) : selectedState.teams;
		const dataContributorIds = _.chain(teamIds).flatMap(teamId =>
			teamMembersForAllTeams.data.filter(member => member.teamId === teamId)
				.map(member => member.dataContributorId))
			.uniq().value();
		const scope = {
			startTime,
			endTime,
			timezone,
			dateRange,
			interval,
			dataContributorIds,
			repositoryIds: selectedState.repositories,
			includeAggregatedPRs: false,
			includeDraftPRs: false,
			includeInternalPRs: false
		};
		setChartScope(scope);
	}, [
		selectedState,
		allTeams.data,
		teamMembersForAllTeams.data
	]);

	useEffect(() => {
		if (!chartScope) {
			return;
		}
		// tslint:disable-next-line: no-floating-promises
		metricOrgStore
			.exportPRCycleChart(DashboardExportFormat.CSV, chartScope.interval!, chartScope.dateRange!, chartScope.timezone,
				chartScope.dataContributorIds, chartScope.repositoryIds,
				chartScope.includeDraftPRs, chartScope.includeAggregatedPRs, chartScope.includeInternalPRs)
			.then(exportData => {
				if (exportData) {
					setExportedData(exportData);
				}
			});
	}, [chartScope]);

	useEffect(() => {
		if (!exportedData) {
			return;
		}

		Papa.parse<IPRData>(exportedData, {
			header: true,
			dynamicTyping: true,
			skipEmptyLines: true,
			transformHeader: (h) => {
				return h.toLowerCase();
			},
			complete: (results) => {
				setItems(results.data);
				setIsDataLoaded(true);
			},
			error: (e: any) => {
				// tslint:disable-next-line: no-console
				console.error("Failed to parse the CSV with error " + e);
				setItems([]);
			},
		});
	}, [exportedData]);

	return (
		<div className="pr-breakdown-container ui fluid container">
			<div className="header-container">
				<div className="ui fluid grid">
					<div className="eight wide column">
						<h1 className="pr-breakdown-title">PR Cycle Time Breakdown<span className="export-button"><Popup
							hoverable={true}
							wide={true}
							position="top center"
							content="Download as CSV"
							trigger={
								<Label className="chart-export" as="a"
									basic={true} onClick={async (e) => {
										if (isDownloadingExportData || !exportedData || !chartScope) {
											return;
										}
										e.stopPropagation();

										setIsDownloadingExportData(true);

										try {
											downloadAsCSV(exportedData, chartScope);
										} finally {
											setIsDownloadingExportData(false);
										}
									}}><Icon name={(isDownloadingExportData) ? "hourglass half" : "download"} />Export</Label>
							}
						/></span>

						</h1>
					</div>
					<div className="eight wide right aligned column">
						<MyUser />
					</div>
				</div>
			</div>
			<div id="pr-breakdown">
				<div className="flex-row">
					<div className="flex-gap full-width filter-controls">
						<DropdownListSelector
							isLoading={_.isEmpty(allTeams.data)}
							title="Teams"
							placeholder="Select teams"
							values={selectedState.teams}
							isMulti={true}
							onChange={(selected: any) => {
								if (selected) {
									onStateChange({ teams: selected.map((opt: any) => opt.value) });
								}
							}}
							options={allTeams.data.map(team => {
								return {
									key: team.id,
									value: team.id,
									label: team.name,
								};
							})}
							customColors={selectorsTheme.colors}
							customSize={selectorsTheme.size}
						/>

						<DropdownListSelector
							isLoading={isLoadingGitRepos}
							title="Repos"
							placeholder="Select repos"
							values={selectedState.repositories}
							isMulti={true}
							onChange={(selected: any) => {
								if (selected) {
									onStateChange({ repositories: selected.map((opt: any) => opt.value) });
								}
							}}
							options={gitRepositoriesOptionsFlattened}
							customColors={selectorsTheme.colors}
							customSize={selectorsTheme.size}
						/>
					</div>
					<div className="right-side-controls">
						<div className="flex-row pull-row-right  filter-controls">
							<div className={"filters-navbar-wrapper  filter-controls"}>
								<FiltersSelector
									placeholder={"Select Filters"}
									value={getFilteredValueLabel()}
									loading={isLoadingGitRepos}
									disabled={isLoadingGitRepos}
								>
									<TimeFramesSelector
										value={selectedState.dateRange}
										setValue={(value) => {
											onStateChange({ dateRange: value });
										}}
										longTermDateRange={false}
										clickEvent={clickEvent(GA_EVENT_CATEGORY.PRBreakdown, GA_EVENT_ACTION.Filter, "date-selector")}
									/>
								</FiltersSelector>
							</div>
						</div>
					</div>
				</div>

				{isDataLoaded && items && items.length > 0 && <div className="pr-filter-container">
					<h3 className="pr-filter-title">
						Advanced filtering
					</h3>
					<PRFilter onSelectedFilterItem={setSelectedFilterType} selectedFilterItem={selectedFilterType} prs={items} />
				</div>}
				<div className="pr-table-container">
					{isDataLoaded && items.length > 0 && <CustomTable
						columns={columnItems}
						items={filterPRsByType(items, selectedFilterType)}
						stickyHeader={true}
						sortable={true}
						topBody={
							<PRTableAggregatedDataRow
								columnItems={columnItems}
								items={filterPRsByType(items, selectedFilterType)}
								valueByColumn={valueByColumn}
							/>
						}
					/>}
					{isDataLoaded && items.length === 0 && <NoPRsPlaceholder />}
					{!isDataLoaded && <LoadingIndicator isActive={!isDataLoaded} local={true} />}
				</div>
			</div>
		</div>
	);
};
export default observer(PRBreakdown);

const selectorsTheme = {
	colors: {
		borderRadius: 1,
		colors: {
			text: "#925EFF",
			primary: "#C2C4C3",
			primary25: "#f3f6f4",
			neutral0: "#FFFFFF",
			neutral80: "#925EFF",
		},
	},
	size: {
		minHeight: 30,
		height: 30,
		minWidth: 200,
		fontSize: 15,
		alignContent: "space-around",
		border: 0,
		boxShadow: "none",
	}
};
