import * as React from "react";
import { useRef, useEffect } from "react";

interface IProps {
	callback: any;
	click?: boolean;
}

function useOutsideAlerter(ref: any, callback: any, click?: boolean) {
	function handleHoverOutside(event: any) {
		if (ref.current && !ref.current.contains(event.target)) {
			callback(event);
		}
	}

	useEffect(() => {
		const event = click ? "mousedown" : "mouseover";

		// Bind the event listener
		document.addEventListener(event, handleHoverOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener(event, handleHoverOutside);
		};
	});
}

/**
 * Component that alerts if you click outside of it
 */
function OutsideAlerter(props: React.PropsWithChildren<IProps>) {
	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef, props.callback, props.click);

	return (
		<div
			style={{ height: "100%", width: "100%" }}
			ref={wrapperRef}
		>
			{props.children}
		</div>
	);
}

export default OutsideAlerter;
