import React, { useState } from "react";
import { IChartScope } from "./charts/chart-scope";
import { observer } from "mobx-react";
import { DashboardTeamMetrics, DashboardTeamReportMetric } from "@acumen/dashboard-common";
import { TrendCard, ITeamsReportTrendCards, HoverSelectionPart, externalHoverType, IHoverEffectProps } from "./particles";
import BugRaisedVsFixedChart from "./charts/bugs-raised-vs-fixed";
import DefectDensityChart from "./charts/defect-density";
import StackedSingleBarChart from "../../components/graphs/stacked-single-bar-chart";
import { Loader, Popup } from "semantic-ui-react";
import MTTRByPriorityChart from "./charts/mttr-priority";
import { TeamsReportStrings } from "../../pages/org-analytics/tooltips";
import { round } from "@acumen/common";
import { CHART_ROW_SIZE } from ".";
import { REPORT_METRIC_TO_UNITS } from "../../mobx-stores/new-teams-report-store";
import { useStores } from "../../mobx-stores";
import useDeepCompareEffect from "use-deep-compare-effect";

// tslint:disable-next-line: variable-name
const QualityBoard = (props: {
	chartScope: IChartScope;
}) => {
	const {
		newTeamsReportStore: {
			teamsReportQualityData: qualityData,
			getTeamsReportQualityData
		}
	} = useStores();
	const { chartScope } = { ...props };
	const [hoveredMetric, setHoveredMetric] = useState<HoverSelectionPart>(externalHoverType);

	useDeepCompareEffect(() => {
		let isMounted = true;
		async function retrieveQualityData(scope: IChartScope) {
			return getTeamsReportQualityData(scope.startTime, scope.endTime, scope.teamIds, {
				gitRepositoryIds: scope.repositoryIds,
				projectIds: scope.projectIds
			});
		}

		if (!isMounted) {
			return;
		}
		// tslint:disable-next-line: no-floating-promises
		retrieveQualityData(chartScope);
		return () => { isMounted = false; };
	}, [chartScope]);

	return (
		<div className={"teams-report-container set-page-z-index"}>
			<h1 className="ui header ">Quality</h1>
			<div className="pr-cycle-top ui centered grid">
				<div className="row cards-row">
					<div className="two wide column"/>
					<div className="fourteen wide column">
						<div className="flex-row quality-cards">
							{qualityTrendCards.slice(0, 4).map(
								(chartData) => <TrendCard
									cardData={chartData}
									metricData={qualityData?.average.metrics[chartData.metricName as DashboardTeamReportMetric]}
									hoverEffect={{ hoveredMetric, setHoveredMetric }}
									key={chartData.metricName}
									bigFont={true}
									units={chartData.units}
								/>
							)}
						</div>
					</div>
				</div>

				<TeamsQualityTable
					teamsData={qualityData?.teams}
					hoverEffect={{ hoveredMetric, setHoveredMetric }}
				/>

				<div className="velocity-charts-header row">
					<div className="sixteen wide column">
						<div className="acu-card">
							<div className="acu-card">
								<div className="row charts-line-container underlined-row">
									<div className="ui grid">
										<BugRaisedVsFixedChart
											mainTitle={{
												title: "bugs filed vs. fixed",
												tooltip: "Shows the amount of bugs filed vs the amount of bugs that were marked as fixed."
											}}
											showExternalLegend={true}
											scope={chartScope}
											chartHeight={CHART_ROW_SIZE}
										/>
									</div>
								</div>
							</div>

							<div className="sixteen wide column">
								<div className="acu-card">
									<div className="row charts-line-container underlined-row">
										<h2 className="ui title acu-capitalize">Defect density
											<Popup
												hoverable={true}
												wide={true}
												className={"tiny-text"}
												position="top center"
												content={"Shows the ratio between the amount of bugs opened vs the amount of pull requests merged or the amount of lines of code changed."}
												trigger={<i className="chart-tooltip info circle outline icon" />}
											/>
										</h2>
										<DefectDensityChart scope={props.chartScope} />
									</div>
								</div>
							</div>

							<div className="acu-card">
								<div className="row charts-line-container underlined-row">
									<div className="ui grid">
										<MTTRByPriorityChart
											mainTitle={{
												title: "mean time to resolve by priority",
												tooltip: "This metric measures the amount of time spent from a bug being opened until it is ultimately resolved. Bugs are split by priority."
											}}
											showExternalLegend={true}
											scope={chartScope}
											chartHeight={CHART_ROW_SIZE}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default observer(QualityBoard);

export const qualityTrendCards: ITeamsReportTrendCards[] = [
	{
		metricName: DashboardTeamReportMetric.BugsCount,
		title: "Bugs",
		secondaryTitle: "Total sum",
		color: "#0F2345",
		tooltipText: TeamsReportStrings.HEADER_BUGS_TOOLTIP,
		units: REPORT_METRIC_TO_UNITS[DashboardTeamReportMetric.BugsCount]
	},
	{
		metricName: DashboardTeamReportMetric.MTTR,
		title: "MTTR",
		secondaryTitle: "Avg per bug",
		color: "#0F2345",
		tooltipText: TeamsReportStrings.HEADER_MTTR_TOOLTIP,
		units: REPORT_METRIC_TO_UNITS[DashboardTeamReportMetric.MTTR]
	},
	{
		metricName: DashboardTeamReportMetric.BugsFixedToBugsOpenedRate,
		title: "Bugs fix rate",
		secondaryTitle: "Total avg",
		color: "#0F2345",
		tooltipText: TeamsReportStrings.HEADER_BUG_FIX_RATIO_TOOLTIP,
		units: REPORT_METRIC_TO_UNITS[DashboardTeamReportMetric.BugsFixedToBugsOpenedRate]
	},
	{
		metricName: DashboardTeamReportMetric.AveragePRSizeLOC,
		title: "Pull request size",
		secondaryTitle: "Total avg",
		color: "#0F2345",
		tooltipText: TeamsReportStrings.HEADER_PR_SIZE_TOOLTIP,
		units: REPORT_METRIC_TO_UNITS[DashboardTeamReportMetric.AveragePRSizeLOC]
	}
];

// tslint:disable-next-line: variable-name
const TeamsQualityTable = (
	props: {
		teamsData: DashboardTeamMetrics[] | undefined,
		hoverEffect: IHoverEffectProps
	}
) => {
	const { teamsData, hoverEffect } = { ...props };
	const { hoveredMetric, setHoveredMetric } = { ...hoverEffect };
	const teamsPRCycle = teamsData?.map(team => {
		return {
			cycleStageArr: [
				{
					value: team.metrics.BugsCount.currentPeriodValue,
					name: DashboardTeamReportMetric.BugsCount
				},
				{
					value: team.metrics.MTTR.currentPeriodValue,
					name: DashboardTeamReportMetric.MTTR
				},
				{
					value: team.metrics.BugsFixedToBugsOpenedRate.currentPeriodValue,
					name: DashboardTeamReportMetric.BugsFixedToBugsOpenedRate
				},
				{
					value: team.metrics.AveragePRSizeLOC.currentPeriodValue,
					name: DashboardTeamReportMetric.AveragePRSizeLOC
				}
			],
			total: team.metrics.PRCycleTotalTimeHours
		};
	});

	const getTeamMetricTotal = (teamMetric: DashboardTeamReportMetric) =>
		Math.max(...teamsData?.map((team) => Math.abs(team.metrics[teamMetric].currentPeriodValue)) ?? []);

	return (
		<div className="row">
			<div className="two wide column ">
				<div className="ui grid">
					<div className="sixteen wide column only-side-padding" >
						{teamsData && teamsData.map((w, i) => (
							<div className="underlined-row hide-overflow" style={{ paddingTop: "1.5rem" }} key={i}> {w.teamName} </div>
						))}
					</div>
				</div>
			</div>

			<div className="fourteen wide column">
				{teamsPRCycle ?
					<div className="acu-card">
						<div className="ui grid">

							<div className="sixteen wide column only-side-padding" >
								{teamsData && teamsData.map((teamData, i) => {
									return (
										<div key={i} className="ui grid underlined-row">
											<div className="four wide column stack-bar-centered">
												<StackedSingleBarChart
													barData={[{
														value: round(teamData.metrics.BugsCount.currentPeriodValue),
														name: DashboardTeamReportMetric.BugsCount
													}]}
													relativeTotal={getTeamMetricTotal(DashboardTeamReportMetric.BugsCount)}
													hoverEffect={{
														hoveredMetric,
														setHoveredMetric
													}}
													label={{
														value: round(teamData.metrics.BugsCount.currentPeriodValue),
														reaction: teamData.metrics.BugsCount.reaction,
														change: round(teamData.metrics.BugsCount.changeFromPreviousPeriod * 100, 0)
													}}
													colorScheme={["#1E4066"]}
												/>
											</div>
											<div className="four wide column stack-bar-centered">
												<StackedSingleBarChart
													barData={[{
														value: round(teamData.metrics.MTTR.currentPeriodValue),
														name: DashboardTeamReportMetric.MTTR
													}]}
													relativeTotal={getTeamMetricTotal(DashboardTeamReportMetric.MTTR)}
													hoverEffect={{
														hoveredMetric,
														setHoveredMetric
													}}
													label={{
														value: round(teamData.metrics.MTTR.currentPeriodValue),
														reaction: teamData.metrics.MTTR.reaction,
														change: round(teamData.metrics.MTTR.changeFromPreviousPeriod * 100, 0)
													}}
													colorScheme={["#1E4066"]}
												/>
											</div>
											<div className="four wide column stack-bar-centered">
												<StackedSingleBarChart
													barData={[{
														value: round(teamData.metrics.BugsFixedToBugsOpenedRate.currentPeriodValue),
														name: DashboardTeamReportMetric.BugsFixedToBugsOpenedRate
													}
													]}
													relativeTotal={getTeamMetricTotal(DashboardTeamReportMetric.BugsFixedToBugsOpenedRate)}
													hoverEffect={{
														hoveredMetric,
														setHoveredMetric
													}}
													label={{
														value: round(teamData.metrics.BugsFixedToBugsOpenedRate.currentPeriodValue),
														reaction: teamData.metrics.BugsFixedToBugsOpenedRate.reaction,
														change: round(teamData.metrics.BugsFixedToBugsOpenedRate.changeFromPreviousPeriod * 100, 0)
													}}
													colorScheme={["#1E4066"]}
												/>
											</div>
											<div className="four wide column stack-bar-centered">
												<StackedSingleBarChart
													barData={[{
														value: round(teamData.metrics.AveragePRSizeLOC.currentPeriodValue),
														name: DashboardTeamReportMetric.AveragePRSizeLOC
													}
													]}
													relativeTotal={getTeamMetricTotal(DashboardTeamReportMetric.AveragePRSizeLOC)}
													hoverEffect={{
														hoveredMetric,
														setHoveredMetric
													}}
													label={{
														value: round(teamData.metrics.AveragePRSizeLOC.currentPeriodValue),
														reaction: teamData.metrics.AveragePRSizeLOC.reaction,
														change: round(teamData.metrics.AveragePRSizeLOC.changeFromPreviousPeriod * 100, 0)
													}}
													colorScheme={["#1E4066"]}
												/>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
					:
					<div style={{ width: "100%", height: "5vh" }}>
						<Loader active={true} size="large" />
					</div>
				}
			</div>
		</div >
	);
};
