import * as React from "react";
const PlayerDoneCheckmark = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 40 40"
		width="16px"
		height="16px"
		{...props}>
		<path fill="#bae0bd" d="M20,38.5C9.8,38.5,1.5,30.2,1.5,20S9.8,1.5,20,1.5S38.5,9.8,38.5,20S30.2,38.5,20,38.5z" />
		<path fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3" d="M11.2,20.1l5.8,5.8l13.2-13.2" />
	</svg>
);
export default PlayerDoneCheckmark;
