import React from "react";
import { observer } from "mobx-react";
import {
	NavLink,
	Switch,
	Route,
	useRouteMatch,
	Redirect,
} from "react-router-dom";
import Statuses from "./statuses";
import Filters from "./filters";
import Graphs from "./graphs";
import ChartColors from "./chart-color";

const Page = observer(() => {
	const match = useRouteMatch();
	return (
		<div className="my-team-page ui fluid container">
			<div className="ui grid">
				<div className="sixteen wide column">
					<div className="header">Kitchen Sink</div>
				</div>
				<div className="sixteen wide column">
					<div className="tabs ui pointing secondary menu">
						<NavLink to={`${match.url}/basic`} className="item">
							Basic
						</NavLink>
						<NavLink to={`${match.url}/statuses`} className="item">
							Statuses
						</NavLink>
						<NavLink to={`${match.url}/chart-colors`} className="item">
							Chart-Colors
						</NavLink>
						<NavLink to={`${match.url}/async`} className="item">
							Async
						</NavLink>
						<NavLink to={`${match.url}/graphs`} className="item">
							graphs components
						</NavLink>
					</div>
				</div>
				<div className="sixteen wide column">
					<div className="ui segment">
						<Switch>
							<Route path={`${match.url}/basic`}>
								<Filters />
							</Route>
							<Route path={`${match.url}/statuses`} component={Statuses} />
							<Route
								path={`${match.url}/chart-colors`}
								component={ChartColors}
							/>
							<Route path={`${match.url}/async`}>async</Route>
							<Route path={`${match.url}/graphs`}
								component={Graphs} />
							<Route>
								<Redirect to={`${match.url}/basic`} />
							</Route>
						</Switch>
					</div>
				</div>
			</div>
		</div >
	);
});

export default Page;
