import { DashboardTeamMetrics, IDashboardTeamsReport, DashboardTeamReportMetric } from "@acumen/dashboard-common";
import StackedSingleBarChart from "../../components/graphs/stacked-single-bar-chart";
import { IChartScope } from "../../pages/org-analytics/charts/chart-scope";
import React, { useState } from "react";
import { Loader, Popup } from "semantic-ui-react";
import { TrendCard, ITeamsReportTrendCards, externalHoverType, HoverSelectionPart, IHoverEffectProps } from "./particles";
import { observer } from "mobx-react";
import ThroughputChart, { ThroughputSum } from "../../pages/org-analytics/charts/throughput";
import TicketsByTypeChart, { CycleState } from "../../pages/org-analytics/charts/tickets-by-type";
import IssueCycleTimeByType from "../../pages/org-analytics/charts/issue-cycle-time-by-type";
import AbandonedWorkChart from "../../pages/org-analytics/charts/abandoned-work";
import WastedWorkChart from "../../pages/org-analytics/charts/closed-and-unmerged-prs";
import { TeamsReportStrings } from "../../pages/org-analytics/tooltips";
import { round } from "@acumen/common";
import { REPORT_METRIC_TO_UNITS } from "../../mobx-stores/new-teams-report-store";
import DistributionClassification from "./charts/distribution-classification";
import TraceabilityOverTime from "./charts/traceability-over-time";
import { useStores } from "../../mobx-stores";
import useDeepCompareEffect from "use-deep-compare-effect";

// tslint:disable-next-line: variable-name
const OutputBoard = (props: {
	chartScope: IChartScope;
	shouldShowMakeTimeCharts: boolean;
}) => {
	const {
		newTeamsReportStore: {
			teamsReportOutputData: outputData,
			getTeamsReportOutputData
		}
	} = useStores();

	const { shouldShowMakeTimeCharts, chartScope } = { ...props };
	const [hoveredMetric, setHoveredMetric] = useState<HoverSelectionPart>(externalHoverType);

	useDeepCompareEffect(() => {
		let isMounted = true;
		async function retrieveOutputData(scope: IChartScope) {
			return getTeamsReportOutputData(scope.startTime, scope.endTime, scope.teamIds, {
				gitRepositoryIds: scope.repositoryIds,
				projectIds: scope.projectIds
			});
		}

		if (!isMounted) {
			return;
		}
		// tslint:disable-next-line: no-floating-promises
		retrieveOutputData(chartScope);
		return () => { isMounted = false; };
	}, [chartScope]);

	return (
		<div className={"teams-report-container set-page-z-index"}>
			<h1 className="ui header ">Output</h1>
			<div className="ui centered grid">

				<div className="row cards-row">
					<div className="two wide column margin-auto"/>
					<div className="fourteen wide column">
						<div className="flex-row output-cards">
							{outputTrendCards.map(
								(chartData) => <TrendCard
									cardData={chartData}
									metricData={outputData?.average.metrics[chartData.metricName as DashboardTeamReportMetric]}
									key={chartData.metricName}
									bigFont={true}
									hoverEffect={{ hoveredMetric, setHoveredMetric }}
									units={chartData.units}
								/>
							)}
						</div>
					</div>
				</div>

				<TeamsOutputTable
					teamsData={outputData?.teams}
					hoverEffect={{ hoveredMetric, setHoveredMetric }}
				/>

				<OutputCharts
					chartData={outputData}
					chartScope={chartScope}
					shouldShowMakeTimeCharts={shouldShowMakeTimeCharts}
				/>

			</div>
		</div>
	);
};
export default observer(OutputBoard);

// tslint:disable-next-line: variable-name
const TeamsOutputTable = (
	props: {
		teamsData: DashboardTeamMetrics[] | undefined,
		hoverEffect: IHoverEffectProps
	}
) => {
	const { teamsData, hoverEffect } = { ...props };
	const { hoveredMetric, setHoveredMetric } = { ...hoverEffect };

	const resolvedTicketsTotal = Math.max(...teamsData?.map((team) => Math.abs(team.metrics.CompletedTicketsCount.currentPeriodValue)) ?? []);
	const traceabilityTotal = Math.max(...teamsData?.map((team) => Math.abs(team.metrics.PRLinkedToTasksOutOfPRs.currentPeriodValue)) ?? []);
	const reviewedPRsTotal = Math.max(...teamsData?.map((team) => Math.abs(team.metrics.PRsReviewedCount.currentPeriodValue)) ?? []);
	const mergedPRsTotal = Math.max(...teamsData?.map((team) => Math.abs(team.metrics.MergedPRsCount.currentPeriodValue)) ?? []);

	return (
		<div className="row">
			<div className="two wide column ">
				<div className="ui grid">
					<div className="sixteen wide column only-side-padding" >
						{teamsData && teamsData.map((w, i) => (
							<div className="underlined-row hide-overflow" style={{ paddingTop: "1.5rem" }} key={i}> {w.teamName} </div>
						))}
					</div>
				</div>
			</div>

			<div className="fourteen wide column">
				{teamsData ?
					<div className="acu-card">
						<div className="ui grid">
							<div className="sixteen wide column only-side-padding __no-right-padding" >
								{teamsData && teamsData.map((w, i) => {
									return (
										<div key={i} className="ui grid underlined-row">
											<div className="four wide column stack-bar-centered">
												<StackedSingleBarChart
													barData={[{
														value: round(w.metrics.CompletedTicketsCount.currentPeriodValue),
														name: DashboardTeamReportMetric.CompletedTicketsCount
													}]}
													hoverEffect={{
														hoveredMetric,
														setHoveredMetric
													}}
													relativeTotal={resolvedTicketsTotal}
													label={{
														value: round(w.metrics.CompletedTicketsCount.currentPeriodValue),
														reaction: w.metrics.CompletedTicketsCount.reaction,
														change: round(w.metrics.CompletedTicketsCount.changeFromPreviousPeriod * 100, 0)
													}}
													colorScheme={["#1E4066"]}
												/>
											</div>
											<div className="four wide column stack-bar-centered">
												<StackedSingleBarChart
													barData={[{
														value: round(w.metrics.PRLinkedToTasksOutOfPRs.currentPeriodValue),
														name: DashboardTeamReportMetric.PRLinkedToTasksOutOfPRs
													}]}
													hoverEffect={{
														hoveredMetric,
														setHoveredMetric
													}}
													relativeTotal={traceabilityTotal}
													label={{
														value: round(w.metrics.PRLinkedToTasksOutOfPRs.currentPeriodValue),
														reaction: w.metrics.PRLinkedToTasksOutOfPRs.reaction,
														change: round(w.metrics.PRLinkedToTasksOutOfPRs.changeFromPreviousPeriod * 100, 0)
													}}
													colorScheme={["#1E4066"]}
												/>
											</div>
											<div className="four wide column stack-bar-centered">
												<StackedSingleBarChart
													barData={[{
														value: round(w.metrics.PRsReviewedCount.currentPeriodValue),
														name: DashboardTeamReportMetric.PRsReviewedCount
													}]}
													hoverEffect={{
														hoveredMetric,
														setHoveredMetric
													}}
													relativeTotal={reviewedPRsTotal}
													label={{
														value: round(w.metrics.PRsReviewedCount.currentPeriodValue),
														reaction: w.metrics.PRsReviewedCount.reaction,
														change: round(w.metrics.PRsReviewedCount.changeFromPreviousPeriod * 100, 0)
													}}
													colorScheme={["#1E4066"]}
												/>
											</div>
											<div className="four wide column stack-bar-centered">
												<StackedSingleBarChart
													barData={[{
														value: round(w.metrics.MergedPRsCount.currentPeriodValue),
														name: DashboardTeamReportMetric.MergedPRsCount
													}]}
													hoverEffect={{
														hoveredMetric,
														setHoveredMetric
													}}
													relativeTotal={mergedPRsTotal}
													label={{
														value: round(w.metrics.MergedPRsCount.currentPeriodValue),
														reaction: w.metrics.MergedPRsCount.reaction,
														change: round(w.metrics.MergedPRsCount.changeFromPreviousPeriod * 100, 0)
													}}
													colorScheme={["#1E4066"]}
												/>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
					:
					<div style={{ width: "100%", height: "5vh" }}>
						<Loader active={true} size="large" />
					</div>
				}
			</div>
		</div >
	);
};

// tslint:disable-next-line: variable-name
const OutputCharts = ((
	props: {
		chartData: IDashboardTeamsReport | undefined,
		chartScope: IChartScope,
		shouldShowMakeTimeCharts: boolean
	}
) => {
	return (
		<div className="output-charts-header row">
			<div className="sixteen wide column">
				<div className="acu-card">
					<div className="row charts-line-container underlined-row">
						<h2 className="ui title acu-capitalize">Throughput
						<Popup
								hoverable={true}
								wide={true}
								className={"tiny-text"}
								position="top center"
								content={"Shows the amount of pull requests merged, the amount of story points for issues marked done for the given period."}
								trigger={<i className="chart-tooltip info circle outline icon" />}
							/>
						</h2>
						<ThroughputChart throughput={ThroughputSum.IssuesCount} scope={props.chartScope} />
					</div>
				</div>
			</div>
			<div className="sixteen wide column">
				<div className="acu-card">
					<div className="row charts-line-container underlined-row">
						<div className="ui grid">
							<TicketsByTypeChart
								cycle={CycleState.End}
								scope={props.chartScope}
								mainTitle={{
									title: "Tickets resolved by ticket type",
									tooltip: "Shows the amount of tickets resolved during the specified time period. The data series is split by the ticket type."
								}}
								showExternalLegend={true}
							/>
						</div>
					</div>
				</div>
			</div>
			{props.shouldShowMakeTimeCharts &&
				<div className="sixteen wide column">
					<div className="acu-card">
						<div className="row charts-line-container underlined-row">
							<div className="ui grid">
								<DistributionClassification
									scope={props.chartScope}
									mainTitle={{
										title: "Maker time split by type",
										tooltip: "Shows the amount of maker time spent on each task type."
									}}
									showExternalLegend={true}
								/>
							</div>
						</div>
					</div>
				</div>
			}

			<div className="sixteen wide column">
				<div className="acu-card">
					<div className="row charts-line-container underlined-row">
						<div className="ui grid">
							<IssueCycleTimeByType
								cycle={CycleState.End}
								scope={props.chartScope}
								mainTitle={{
									title: "Task cycle time by task type",
									tooltip: "Shows the amount of cycle time (how long each ticket was in progress) spent on each task type."
								}}
								showExternalLegend={true}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="sixteen wide column">
				<div className="acu-card">
					<div className="row charts-line-container underlined-row">
						<div className="ui grid">
							<TraceabilityOverTime
								scope={props.chartScope}
								mainTitle={{
									title: "Traceability over time",
									tooltip: TeamsReportStrings.TRACEABILITY_OVER_TIME_TOOLTIP
								}}
								showExternalLegend={true}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="sixteen wide column">
				<div className="acu-card">
					<div className="row charts-line-container underlined-row">
						<h2 className="ui title acu-capitalize">Unmerged commits
							<Popup
								hoverable={true}
								wide={true}
								className={"tiny-text"}
								position="top center"
								content={TeamsReportStrings.ABANDONED_WORK_TOOLTIP}
								trigger={<i className="chart-tooltip info circle outline icon" />}
							/>
						</h2>
						<AbandonedWorkChart scope={props.chartScope} />
					</div>
				</div>
			</div>
			<div className="sixteen wide column">
				<div className="acu-card">
					<div className="row charts-line-container underlined-row">
						<h2 className="ui title acu-capitalize">Closed and unmerged PRs
							<Popup
								hoverable={true}
								wide={true}
								className={"tiny-text"}
								position="top center"
								content={TeamsReportStrings.CLOSED_AND_UNMERGED_PR_TOOLTIP}
								trigger={<i className="chart-tooltip info circle outline icon" />}
							/>
						</h2>
						<WastedWorkChart scope={props.chartScope} />
					</div>
				</div>
			</div>
		</div>
	);
});

export const outputTrendCards: ITeamsReportTrendCards[] = [
	{
		metricName: DashboardTeamReportMetric.CompletedTicketsCount,
		title: "Resolved tickets",
		secondaryTitle: "Total sum",
		color: "#0F2345",
		tooltipText: TeamsReportStrings.HEADER_RESOLVED_TICKETS_SUM_TOOLTIP,
		units: REPORT_METRIC_TO_UNITS[DashboardTeamReportMetric.CompletedTicketsCount]
	},
	{
		metricName: DashboardTeamReportMetric.PRLinkedToTasksOutOfPRs,
		title: "Traceability",
		secondaryTitle: "Total avg",
		color: "#0F2345",
		tooltipText: TeamsReportStrings.HEADER_TRACEABILITY_TOOLTIP,
		units: REPORT_METRIC_TO_UNITS[DashboardTeamReportMetric.PRLinkedToTasksOutOfPRs]
	},
	{
		metricName: DashboardTeamReportMetric.PRsReviewedCount,
		title: "Reviewed PRs",
		secondaryTitle: "Total sum",
		color: "#0F2345",
		tooltipText: TeamsReportStrings.HEADER_REVIEWED_PRS_TOOLTIP,
		units: REPORT_METRIC_TO_UNITS[DashboardTeamReportMetric.PRsReviewedCount]
	},
	{
		metricName: DashboardTeamReportMetric.MergedPRsCount,
		title: "Merged PRs",
		secondaryTitle: "Total sum",
		color: "#0F2345",
		tooltipText: TeamsReportStrings.HEADER_MERGED_PRS_SUM_TOOLTIP,
		units: REPORT_METRIC_TO_UNITS[DashboardTeamReportMetric.MergedPRsCount]
	}
];
