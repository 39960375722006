import _ from "lodash";
import {
	IDashboardObstacle,
	IDashboardPullRequest,
	IDashboardTask,
	ObstacleEffect,
	ObstacleSubCategory,
	IDashboardSprint,
	MetricInterval,
	TaskEstimationMethod,
	IDashboardRetroReportIssuesData,
	IDashboardBurndownData,
	IDashboardBurndownDataDay,
	SubtaskSprintStrategy,
	SprintType,
	DevelopmentMethodology,
	CONFIGURATION_KEYS,
	IDashboardRetroReportData,
	IDashboardAcumenMetricDataResponse,
	ConfigurationValue,
	IDashboardRetroReportEpics,
	IDashboardDataContributor,
	IRetroReportTasksStateResponse,
	IRetroReportTasksStateBodyParameters,
	DashboardRetroReportTaskData,
	IEpicTasks,
	AcumenMetricGroupType,
	AcumenTaskType, IDashboardJiraPriority
} from "@acumen/dashboard-common";
import { DashboardRetroReportExpandOptions, IDashboardRetroHighlight } from "@acumen/dashboard-common";
import { action, computed, observable } from "mobx";
import apiContextProvider from "../../services/api-context-provider";
import { MetricApiClient, METRIC_ROUTE } from "../services/crud/metric-api-client";
import {
	BURNDOWN_RETRO_ROUTE, ISprintRetroRequestScope, REPORT_ROUTE, SprintRetroApiClient, ISSUES_SUB_ROUTE,
	TASKS_STATE_IN_SPRINT_ROUTE, PARTICIPANTS_IN_SPRINT_ROUTE,
	EPICS_SUB_ROUTE, IEpicsRetroRequestScope, ISprintIssuesRequestScope, IBurndownRequestScope
} from "../services/crud/sprint-retro-api-client";
import BaseStore from "./base-store";
import Highcharts from "highcharts";
import moment from "moment";
import { IChartScope } from "../pages/org-analytics/charts/chart-scope";
import { ConfigurationApiClient, CONFIGURATION_ROUTE } from "../services/crud/configuration-api-client";
import { FetchLatestRequest } from "../../services/fetch-helpers";
import {
	UNMAPPED_COMPONENT_SERIES_NAME,
	UNMAPPED_PRIORITY_NAME
} from "../components/dashboard-task/task-type-series-prop";
import { MakeOptional, round } from "@acumen/common";
import colorFunction from "../pages/my-team/analytics/charts/chart-color-scheme";
import { createSubtaskStrategyDimensions } from "./planned-vs-actual-store";

export interface IChartData extends Partial<Highcharts.Point> {
	tooltip?: string | number;
	type?: string;
}

export interface IBurndownChartData {
	dataByDay: IDashboardBurndownDataDay[];
	estimationMethod: TaskEstimationMethod;
}

interface ISprintRetroObstaclesData {
	taskActualCount: number;
	highlights: IDashboardRetroHighlight[];
	obstacles: IDashboardObstacle[];
	obstacleTopTasks: IDashboardTask[];
	obstacleTopPRs: IDashboardPullRequest[];
	previousSprint?: IDashboardSprint;
}

export enum IssueMetricLabel {
	Sprint = "sprint",
	DevCycle = "dev_cycle",
	ClosureSprint = "closure_sprint",
	ClosureDevCycle = "closure_dev_cycle",
}

export const DEFAULT_CHART_TIME_SPAN_MONTHS = 3;
export default class SprintRetroStore extends BaseStore<{}> {
	private readonly sprintRetroApiClient: SprintRetroApiClient = new SprintRetroApiClient(apiContextProvider);
	private readonly metricApiClient: MetricApiClient = new MetricApiClient(apiContextProvider);
	private readonly configurationApiClient: ConfigurationApiClient = new ConfigurationApiClient(apiContextProvider);

	private endDate = new Date();
	private startDate = moment(this.endDate).subtract(DEFAULT_CHART_TIME_SPAN_MONTHS, "month").toDate();

	@observable
	public sprintRetroObstaclesData: ISprintRetroObstaclesData | undefined = undefined;

	@observable
	public isLoadingSprintRetroObstaclesData: boolean = false;

	@observable
	public estimationMethod: TaskEstimationMethod | undefined;

	@observable
	public developmentMethodology: DevelopmentMethodology | undefined;

	@observable
	public subtaskSprintStrategy: SubtaskSprintStrategy | undefined;

	@observable
	public excludeTasksWithNonTeamDCs: boolean | undefined;

	@observable
	public timezone: string | undefined;

	@observable
	public countryCode: string | undefined;

	@observable
	public unplannedTasksGraceHours: number | undefined;

	@observable
	public workSplitByType: IChartData[] | undefined;

	@observable
	public isLoadingWorkSplitByTypeData: boolean = false;

	@observable
	public workSplitByComponent: IChartData[] | undefined;

	@observable
	public isLoadingWorkSplitByComponentData: boolean = false;

	@observable
	public isLoadingBugsAddedMidSprintData: boolean = false;

	@observable
	public bugsAddedMidSprint: IChartData[] | undefined;

	@observable
	public burndownChartData: IDashboardBurndownData | undefined;

	@observable
	public isLoadingBurndownChartData: boolean = false;

	@observable
	public issues: DashboardRetroReportTaskData[] | undefined = undefined;

	@observable
	public epicsTasks: IEpicTasks[] | undefined = undefined;

	@observable
	public isLoadingIssuesData: boolean = false;

	@observable
	issueIdsByCategory: IRetroReportTasksStateResponse | undefined = undefined;

	@observable
	isLoadingIssueIdsByCategory: boolean = false;

	@observable
	participants: IDashboardDataContributor[] | undefined = undefined;

	@computed
	public get isLoadingReportData() {
		return (
			this.isLoadingSprintRetroObstaclesData
			|| this.isLoadingWorkSplitByTypeData
			|| this.isLoadingBurndownChartData
			|| this.isLoadingIssuesData
			|| this.isLoadingWorkSplitByComponentData
		);
	}

	@action.bound
	public resetAllPageData = () => {
		this.sprintRetroObstaclesData = undefined;
		this.estimationMethod = undefined;
		this.developmentMethodology = undefined;
		this.subtaskSprintStrategy = undefined;
		this.excludeTasksWithNonTeamDCs = undefined;
		this.timezone = undefined;
		this.countryCode = undefined;
		this.workSplitByType = undefined;
		this.workSplitByComponent = undefined;
		this.burndownChartData = undefined;
		this.issues = undefined;
		this.isLoadingSprintRetroObstaclesData = false;
		this.isLoadingBurndownChartData = false;
		this.isLoadingIssuesData = false;
	}

	fetchLatestConfiguration = new FetchLatestRequest<Record<string, ConfigurationValue>, any>(CONFIGURATION_ROUTE);
	@action.bound
	public fetchConfiguration = async (teamId: string) => {
		const result = await this.fetchLatestConfiguration.fetchLatest(this.configurationApiClient.fetchConfiguration([
			CONFIGURATION_KEYS.retroReport.logic.subtaskSprintStrategy,
			CONFIGURATION_KEYS.global.preferences.issueTracking.developmentMethodology,
			CONFIGURATION_KEYS.global.preferences.issueTracking.estimationMethod,
			CONFIGURATION_KEYS.global.preferences.issueTracking.excludeTasksWithNonTeamDCs,
			CONFIGURATION_KEYS.global.preferences.timezone,
			CONFIGURATION_KEYS.global.preferences.countryCode,
			CONFIGURATION_KEYS.retroReport.sprints.unplannedTasksGraceHours
		], { teamId }));
		if (result) {
			const [
				subtaskSprintStrategy,
				developmentMethodology,
				estimationMethod,
				excludeTasksWithNonTeamDCs,
				timezone,
				countryCode,
				unplannedTasksGraceHours
			] = Object.values(result.data);

			this.subtaskSprintStrategy = subtaskSprintStrategy as SubtaskSprintStrategy;
			this.developmentMethodology = developmentMethodology as DevelopmentMethodology;
			this.estimationMethod = estimationMethod as TaskEstimationMethod;
			this.excludeTasksWithNonTeamDCs = excludeTasksWithNonTeamDCs as boolean;
			this.timezone = timezone as string;
			this.countryCode = countryCode as string;
			this.unplannedTasksGraceHours = unplannedTasksGraceHours as number;

			return {
				subtaskSprintStrategy,
				estimationMethod,
				excludeTasksWithNonTeamDCs,
				developmentMethodology,
				timezone,
				countryCode,
				unplannedTasksGraceHours
			};
		}
	}

	fetchLatestBurndownRequest = new FetchLatestRequest<IDashboardBurndownData, void>(BURNDOWN_RETRO_ROUTE);
	@action.bound
	public getBurndownData = async (scope: IBurndownRequestScope) => {
		this.isLoadingBurndownChartData = true;
		try {
			this.burndownChartData = undefined;
			const result = await this.fetchLatestBurndownRequest.fetchLatest(this.sprintRetroApiClient.fetchBurndownData(scope));

			if (result?.data.data) {
				this.burndownChartData = result?.data;
			}
		} finally {
			this.isLoadingBurndownChartData = false;
		}
	}

	fetchLatestReportDataRequest = new FetchLatestRequest<IDashboardRetroReportData, void>(REPORT_ROUTE);
	@action.bound
	public getReportData = async (scope: ISprintRetroRequestScope) => {
		this.isLoadingSprintRetroObstaclesData = true;

		try {
			scope.expandedFields = [DashboardRetroReportExpandOptions.Obstacles, DashboardRetroReportExpandOptions.Highlights];
			this.sprintRetroObstaclesData = undefined;
			const result = await this.fetchLatestReportDataRequest.fetchLatest(this.sprintRetroApiClient.fetchSprintRetroData(scope));

			if (result && result.data) {
				this.sprintRetroObstaclesData = {
					taskActualCount: result.data.report.taskActualCount,
					highlights: result.data.highlights ?? [],
					obstacles: result.data.obstacles ?? [],
					obstacleTopTasks: result.data.obstacleTopTasks?.data ?? [],
					obstacleTopPRs: result.data.obstacleTopPRs?.data ?? [],
					previousSprint: result.data.previousSprint
				};
			}
		} finally {
			this.isLoadingSprintRetroObstaclesData = false;
		}
	}

	fetchLatestIssuesRequest = new FetchLatestRequest<IDashboardRetroReportIssuesData, void>(`${REPORT_ROUTE}/${ISSUES_SUB_ROUTE}`);
	@action.bound
	public getIssues = async (scope: ISprintIssuesRequestScope) => {
		this.isLoadingIssuesData = true;
		try {
			const result = await this.fetchLatestIssuesRequest.fetchLatest(this.sprintRetroApiClient.fetchIssues(scope));

			if (result?.data) {
				this.issues = result.data.tasks;
			}

			return this.issues;
		} finally {
			this.isLoadingIssuesData = false;
		}
	}

	fetchLatestEpicsRequest = new FetchLatestRequest<IDashboardRetroReportEpics, void>(`${REPORT_ROUTE}/${EPICS_SUB_ROUTE}`);
	@action.bound
	public getEpics = async (scope: IEpicsRetroRequestScope) => {
		this.isLoadingIssuesData = true;
		try {
			this.epicsTasks = undefined;
			const result = await this.fetchLatestEpicsRequest.fetchLatest(this.sprintRetroApiClient.fetchEpics(scope));

			if (result?.data) {
				this.epicsTasks = result.data.epics;
			}
		} finally {
			this.isLoadingIssuesData = false;
		}

	}

	@computed
	public get internalObstacleData() {
		return this.sprintRetroObstaclesData?.obstacles?.filter(obs => obs.subCategory === ObstacleSubCategory.Internal)
			.sort(this.sortObstacleByEffect);
	}

	@computed
	public get externalObstacleData() {
		return this.sprintRetroObstaclesData?.obstacles?.filter(obs => obs.subCategory === ObstacleSubCategory.External)
			.sort(this.sortObstacleByEffect);
	}

	public getSprintRetroTimeScope: () => Omit<IChartScope, "timezone" | "teamIds"> = () => {
		return {
			endTime: this.endDate,
			startTime: this.startDate,
			interval: MetricInterval.SPRINT,
		};
	}

	private sortObstacleByEffect = (a: IDashboardObstacle, b: IDashboardObstacle) => {
		const getScore = (effect: ObstacleEffect) => {
			switch (effect) {
				case ObstacleEffect.Critical:
					return 4;
				case ObstacleEffect.High:
					return 3;
				case ObstacleEffect.Medium:
					return 2;
				case ObstacleEffect.Low:
					return 1;
				default:
					return 1;
			}
		};
		return getScore(b.effect) - getScore(a.effect);
	}

	fetchLatestIssuesCountDoneByType = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(METRIC_ROUTE + "/IssuesCountDoneByType");
	@action.bound
	public async getIssuesCountDoneByTypeData(
		scope: ISprintRetroRequestScope,
		activeSprint: IDashboardSprint,
		timezone: string | undefined = Highcharts.getOptions().time?.timezone,
	) {
		if (!activeSprint || !activeSprint.startDate || !activeSprint.endDate || !timezone) {
			return;
		}
		this.isLoadingWorkSplitByTypeData = true;

		try {
			const dimensions = createDimensionsFromScope(scope, false);
			const subtaskStrategyDimensions = createSubtaskStrategyDimensions(scope.subtaskStrategy);
			const closureIterationIdDimension = createClosureIterationIdDimension(scope.sprint);
			const { boardOwnedByTeamDimensions } = createBoardOwnedByTeamDimensions(scope);

			const issueMetricLabel = (scope.sprint.type === SprintType.Sprint)
				? IssueMetricLabel.Sprint
				: IssueMetricLabel.DevCycle;
			const issueMetricInterval = (scope.sprint.type === SprintType.Sprint)
				? MetricInterval.SPRINT
				: MetricInterval.DEV_CYCLE;

			const workSplitByTypeDimensions = [
				...dimensions,
				...boardOwnedByTeamDimensions,
				...closureIterationIdDimension,
				...subtaskStrategyDimensions.subtaskDimensions
			];

			this.workSplitByType = this.workSplitByType?.map(w => { w.y = 0; return w; });
			const issueMetric = mapEstimationMethodToMetric(scope.estimationMethod);

			const response = await this.fetchLatestIssuesCountDoneByType.fetchLatest(
				this.metricApiClient.fetchMetric(
					issueMetric, issueMetricLabel, issueMetricInterval, timezone, new Date(activeSprint.startDate),
					new Date(activeSprint.endDate), workSplitByTypeDimensions, "acumen_task_type",
					subtaskStrategyDimensions.subtaskNegationDimensions
				)
			);

			if (response && response.data.values) {
				const estimationValueByType: Record<string, number> = {};
				let totalEstimationValuesForActiveSprint = 0;

				for (const [issueType, estimationValueBySprint] of Object.entries(response.data.values)) {
					const curEstimationValue = adjustValueForTimeEstimation(
						scope.estimationMethod, estimationValueBySprint?.[activeSprint.id] ?? 0
					);
					estimationValueByType[issueType] = curEstimationValue;
					totalEstimationValuesForActiveSprint += curEstimationValue;
				}

				const tooltipSuffix = mapEstimationMethodToUnit(scope.estimationMethod);

				this.workSplitByType = Object.entries(estimationValueByType).map(([issuesType, estimationValue]) => {
					return {
						name: (issuesType === "null") ? "Other" : issuesType,
						tooltip: `${estimationValue}${tooltipSuffix}`,
						value: estimationValue,
						y: (totalEstimationValuesForActiveSprint > 0)
							? (100 / totalEstimationValuesForActiveSprint) * estimationValue
							: 0,
					};
				});
			}
		} finally {
			this.isLoadingWorkSplitByTypeData = false;
		}
	}

	fetchLatestIssuesDoneByComponent = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(METRIC_ROUTE + "/IssuesCountByComponent");
	@action.bound
	public async getIssuesDoneByComponentData(
		scope: ISprintRetroRequestScope,
		activeSprint: IDashboardSprint,
		timezone: string | undefined = Highcharts.getOptions().time?.timezone,
		componentsById: Record<string, { id: string, name: string | null }> = {}
	) {
		if (!activeSprint || !activeSprint.startDate || !activeSprint.endDate || !timezone) {
			return;
		}

		this.isLoadingWorkSplitByComponentData = true;
		try {
			const dimensions = createDimensionsFromScope(scope, false);
			const closureIterationIdDimension = createClosureIterationIdDimension(scope.sprint);
			const { boardOwnedByTeamDimensions } = createBoardOwnedByTeamDimensions(scope);

			const issueMetricInterval = scope.sprint.type === SprintType.Sprint
				? MetricInterval.SPRINT
				: MetricInterval.DEV_CYCLE;

			const issueMetricLabel: any = scope.sprint.type === SprintType.Sprint
				? IssueMetricLabel.Sprint
				: IssueMetricLabel.DevCycle;

			const issueMetric = mapEstimationMethodToMetric(this.estimationMethod);
			const tooltipSuffix = mapEstimationMethodToUnit(this.estimationMethod);
			const workSplitByComponentDimensions = [...dimensions, ...boardOwnedByTeamDimensions, ...closureIterationIdDimension];

			this.workSplitByComponent = this.workSplitByComponent?.map(w => { w.y = 0; return w; });

			const response = await this.fetchLatestIssuesDoneByComponent.fetchLatest(this.metricApiClient.fetchMetric(
				issueMetric, issueMetricLabel,
				issueMetricInterval, timezone, new Date(activeSprint.startDate),
				new Date(activeSprint.endDate), workSplitByComponentDimensions, "component_id"));

			if (response && response.data.values) {
				const total = _.sumBy(Object.values(response.data.values), value => value?.[activeSprint.id] ?? 0);
				this.workSplitByComponent = Object.entries(response.data.values)
					.map(([key, value]) => {
						const name = (componentsById)[key]?.name ?? UNMAPPED_COMPONENT_SERIES_NAME;
						const typeSum = value?.[activeSprint.id] ?? 0;

						return {
							name,
							tooltip: `${round(typeSum, 1)}${tooltipSuffix}`,
							value: typeSum,
							y: total > 0 ? (100 / total) * typeSum : 0,
						};
					});
			}
		} finally {
			this.isLoadingWorkSplitByComponentData = false;
		}
	}

	fetchBugsAddedMidSprintData = new FetchLatestRequest<IDashboardAcumenMetricDataResponse, any>(METRIC_ROUTE + "/IssuesCountByComponent");
	@action.bound
	public async getBugsAddedMidSprintData(
		scope: ISprintRetroRequestScope,
		activeSprint: IDashboardSprint,
		timezone: string | undefined = Highcharts.getOptions().time?.timezone,
		priorities: IDashboardJiraPriority[] = [],
	) {
		const endDate = activeSprint.completeDate ?? activeSprint.endDate;

		if (!activeSprint || !activeSprint.startDate || !endDate || !timezone) {
			return;
		}

		this.isLoadingBugsAddedMidSprintData = true;

		try {
			const dimensions = [
				...createDimensionsFromScope(scope, false),
				`acumen_task_type=${AcumenTaskType.Bug}`,
				`priority_order=0,1,2`,
			];

			const metricInterval = scope.sprint.type === SprintType.Sprint
				? MetricInterval.SPRINT_DATE
				: MetricInterval.DEV_CYCLE;
			const response = await this.fetchBugsAddedMidSprintData.fetchLatest(
				this.metricApiClient.fetchMetric(
					AcumenMetricGroupType.JiraIssueCount,
					"created",
					metricInterval,
					timezone,
					new Date(activeSprint.startDate),
					new Date(endDate),
					dimensions,
					"priority_order"
				)
			);

			if (!response || !response.data) {
				this.bugsAddedMidSprint = [];
				this.isLoadingBugsAddedMidSprintData = false;
				return undefined;
			}

			const total = _.sumBy(Object.values(response.data.values), value => value?.[activeSprint.id] ?? 0);
			this.bugsAddedMidSprint = Object.entries(response.data.values)
				.map(([key, value]) => {
					const order = Number(key);
					const priority = priorities.find(p => p.order === order);
					const typeSum = value?.[activeSprint.id] ?? 0;
					const color = priority?.name
						? colorFunction.acumenTaskPriority[priority.order as 0 | 1 | 2] ?? priority.statusColor
						: undefined;

					return {
						name: priority?.name ?? UNMAPPED_PRIORITY_NAME,
						color,
						tooltip: round(typeSum, 1),
						value: typeSum,
						y: total > 0 ? (100 / total) * typeSum : 0,
					};
				});

			return this.bugsAddedMidSprint;
		} finally {
			this.isLoadingBugsAddedMidSprintData = false;
		}
	}

	private fetchLatestTasksStateInSprint = new FetchLatestRequest<IRetroReportTasksStateResponse, any>(`${REPORT_ROUTE}/${TASKS_STATE_IN_SPRINT_ROUTE}`);
	@action.bound
	async fetchTasksStateInSprint(props: IRetroReportTasksStateBodyParameters) {
		this.isLoadingIssueIdsByCategory = true;
		const result = await this.fetchLatestTasksStateInSprint.fetchLatest(this.sprintRetroApiClient.fetchTasksState(props));
		this.issueIdsByCategory = result?.data;
		this.isLoadingIssueIdsByCategory = false;
		return result?.data;
	}

	private fetchLatestParticipantsInSprint = new FetchLatestRequest<IDashboardDataContributor[], any>(PARTICIPANTS_IN_SPRINT_ROUTE);
	@action.bound
	async fetchParticipantsInSprint(sprintId: string) {
		const result = await this.fetchLatestParticipantsInSprint.fetchLatest(this.sprintRetroApiClient.fetchParticipants(sprintId));
		this.participants = result?.data;
		return result?.data;
	}
}

function mapEstimationMethodToMetric(estimationMethod: TaskEstimationMethod | undefined) {
	switch (estimationMethod) {
		case TaskEstimationMethod.TimeBased:
			return AcumenMetricGroupType.JiraIssuesActualEffortTimeEstimate;

		case TaskEstimationMethod.StoryPoints:
			return AcumenMetricGroupType.JiraIssuesActualEffortStoryPoints;

		case TaskEstimationMethod.None:
		default:
			return AcumenMetricGroupType.JiraIssuesActualEffortNoneEstimation;
	}
}

export function mapEstimationMethodToUnit(estimationMethod: TaskEstimationMethod | undefined) {
	switch (estimationMethod) {
		case TaskEstimationMethod.TimeBased:
			return "hr";

		case TaskEstimationMethod.StoryPoints:
			return "sp";

		case TaskEstimationMethod.None:
		default:
			return "";
	}
}

export function createDimensionsFromScope(scope: MakeOptional<ISprintRetroRequestScope, "teamId">, addSprintDimension = true, planned = false) {
	const dimensions: string[] = [];

	if (scope.teamId) {
		if (planned || scope.excludeTasksWithNonTeamDCs) {
			// Note: planned - for unplanned grace time period
			dimensions.push(`team_id=${scope.teamId}`);
		}

		if (planned && scope.excludeTasksWithNonTeamDCs) {
			dimensions.push(`planned_assignee_team_id=${scope.teamId}`);
		}
	}

	if (scope.dataContributorIds && scope.dataContributorIds.length > 0) {
		const dcDimension = planned
			? `planned_assignee_dc_id=${scope.dataContributorIds.join(",")}`
			: `data_contributor_id=${scope.dataContributorIds.join(",")}`;
		dimensions.push(dcDimension);
	}

	if (addSprintDimension && scope.sprint) {
		switch (scope.sprint.type) {
			case SprintType.Sprint:
				dimensions.push(`sprint_id=${scope.sprint.id}`);
				break;
			case SprintType.DevCycle:
				dimensions.push(`dev_cycle_id=${scope.sprint.id}`);
				break;
		}
	}

	return dimensions;
}

export function createClosureIterationIdDimension(sprint: IDashboardSprint) {
	switch (sprint.type) {
		case SprintType.Sprint:
			return [`closure_sprint_id=${sprint.id}`];
		case SprintType.DevCycle:
			return [`closure_dev_cycle_id=${sprint.id}`];
		default:
			return [];
	}
}

export function createBoardOwnedByTeamDimensions(scope: MakeOptional<ISprintRetroRequestScope, "teamId">) {
	const boardOwnedByTeamDimensions: string[] = [];
	if (scope.sprint.type === SprintType.Sprint && scope.teamId) {
		const boardOwnedByTeam = [scope.teamId];
		if (scope.excludeTasksWithNonTeamDCs) {
			boardOwnedByTeam.push("null");
		}

		boardOwnedByTeamDimensions.push(`board_owned_by_team=${boardOwnedByTeam.join(",")}`);
	}

	return { boardOwnedByTeamDimensions };
}

export function adjustValueForTimeEstimation(estimationMethod: TaskEstimationMethod | undefined, estimationValue: number): number {
	return (estimationMethod === TaskEstimationMethod.TimeBased)
		? round(estimationValue / 3600)  // Note: seconds to hours
		: estimationValue;
}
