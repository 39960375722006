export const BLANK_LINK = "#!";

export const INSIGHT_RULE_ID_PARAMETER = ":insightRuleId";
export const KEY_PARAMETER = ":key";
export const TYPE_PARAMETER = ":type";
export const ID_PARAMETER = ":id";

export const ROUTES = {
	ROOT: "/",
	LOGIN: "/login",
	MY_TEAM: "/my-team",
	DAILY_DETAILED_REPORT: "/daily-report/detailed",
	METRIC_TESTER: "/metric-tester",
	SETTINGS_USERS: "/settings/users",
	SETTINGS_CONTRIBUTORS: "/settings/contributors",
	SETTINGS_INSIGHTS: "/settings/insights",
	SETTINGS_TEAMS: "/settings/teams",
	SETTINGS_INTEGRATIONS: "/settings/integrations",
	NOT_FOUND: "/404",
	SETTINGS_INSIGHTS_INVOLVEMENT: `/settings/insights/${INSIGHT_RULE_ID_PARAMETER}/involvements/${TYPE_PARAMETER}/${ID_PARAMETER}`,
	NOTIFICATION_MORE_INFO: "/notification/:customerId/:notificationId/:token",
	MY_ACCOUNT_USERS: "/my-account/users"
};
