import {
	EventType,
	TaskEstimationMethod
} from "@acumen/dashboard-common";

export const EVENTS_FRIENDLY_NAMES: Partial<Record<EventType, string>> = {
	[EventType.PullRequestOpened]: "Pull request opened",
	[EventType.PullRequestMerged]: "Pull request merged",
	[EventType.PullRequestClosed]: "Pull request closed",
	[EventType.PullRequestAssigned]: "Pull request assigned",
	[EventType.PullRequestAssignedByUser]: "Pull request assigned by user",
	[EventType.PullRequestUnassigned]: "Pull request unassigned",
	[EventType.PullRequestUnassignedByUser]: "Pull request unassigned by user",
	[EventType.PullRequestComment]: "Pull request comment",
	[EventType.PullRequestMention]: "Pull request comment with user mention",
	[EventType.PullRequestReviewRequested]: "Pull request review requested",
	[EventType.PullRequestReviewRequestedByUser]: "Pull request review requested by user",
	[EventType.PullRequestReviewRequestRemoved]: "Pull request review request removed",
	[EventType.PullRequestReviewRequestRemovedByUser]: "Pull request review request removed by user",
	[EventType.PullRequestReviewedCommented]: "Pull request review comment added",
	[EventType.PullRequestReviewedChangesRequested]: "Pull request review has changes requested",
	[EventType.PullRequestReviewedApproved]: "Pull request review approved",
	[EventType.PullRequestMentionedByUser]: "Pull request mentioned by user",
	[EventType.PullRequestSubscribed]: "Pull request subscription added",
	[EventType.PullRequestUnlabeled]: "Pull request label removed",
	[EventType.PullRequestLabeled]: "Pull request label added",
	[EventType.PullRequestUnsubscribed]: "Pull request subscription removed",
	[EventType.PullRequestReferenced]: "Pull request referenced",
	[EventType.PullRequestReopened]: "Pull request reopened",
	[EventType.PullRequestRenamed]: "Pull request renamed",
	[EventType.PullRequestDeployed]: "Pull request deployed",
	[EventType.PullRequestReadyForReview]: "Pull request ready for review",
	[EventType.PullRequestReviewDismissed]: "Pull request review dismissed",
	[EventType.PullRequestCommentDeleted]: "Pull request comment deleted",
	[EventType.PullRequestCommitCommented]: "Pull request commit commented",
	[EventType.PullRequestLineCommented]: "Pull request line commented",
	[EventType.PullRequestCrossReferenced]: "Pull request cross referenced",
	[EventType.PullRequestUnknown]: "Pull request unknown event",
	[EventType.CommitCommitted]: "Commit committed",
	[EventType.CommitAuthored]: "Commit authored",
	[EventType.IssueOpened]: "Issue opened",
	[EventType.IssueAssigned]: "Issue assigned",
	[EventType.IssueAssignedByUser]: "Issue assigned by user",
	[EventType.IssueStatusChanged]: "Issue status changed",
	[EventType.IssueAttachment]: "Issue attachment added",
	[EventType.IssueCommentDeleted]: "Issue comment deleted",
	[EventType.IssueComponentChanged]: "Issue component changed",
	[EventType.IssueDescriptionChanged]: "Issue description changed",
	[EventType.IssueDueDateChanged]: "Issue due date changed",
	[EventType.IssueEnvironmentChanged]: "Issue environment changed",
	[EventType.IssueFixVersionChanged]: "Issue fix version changed",
	[EventType.IssueTypeChanged]: "Issue type changed",
	[EventType.IssueLabelsChanged]: "Issue labels changed",
	[EventType.IssuePriorityChanged]: "Issue priority changed",
	[EventType.IssueProjectChanged]: "Issue project changed",
	[EventType.IssueReporterChanged]: "Issue reporter changed",
	[EventType.IssueResolutionChanged]: "Issue resolution changed",
	[EventType.IssueResolutionDateChanged]: "Issue resolution date changed",
	[EventType.IssueSummaryChanged]: "Issue summary changed",
	[EventType.IssueOriginalTimeEstimateChanged]: "Issue original time estimate changed",
	[EventType.IssueTimeEstimateChanged]: "Issue time estimate changed",
	[EventType.IssueTimeSpentChanged]: "Issue time spent updated",
	[EventType.IssueVersionsChanged]: "Issue versions changed",
	[EventType.IssueSprintChanged]: "Issue sprint changed",
	[EventType.IssueStoryPointsChanged]: "Issue story points changed",
	[EventType.IssueTShirtSizeChanged]: "Issue t-shirt size changed",
	[EventType.IssueUnknownFieldChange]: "Issue field changed",
	[EventType.IssueCustomFieldChange]: "Issue custom field changed"
};

export const TASK_ESTIMATION_METHOD_FRIENDLY_NAMES: Record<TaskEstimationMethod, string> = {
	[TaskEstimationMethod.None]: "None",
	[TaskEstimationMethod.StoryPoints]: "Story points",
	[TaskEstimationMethod.TShirtSize]: "T-shirt size",
	[TaskEstimationMethod.TimeBased]: "Time estimation"
};

export const TASK_ESTIMATION_METHOD_DISPLAY_NAMES: Record<TaskEstimationMethod, string> = {
	[TaskEstimationMethod.None]: "Issues",
	[TaskEstimationMethod.StoryPoints]: "Story points",
	[TaskEstimationMethod.TShirtSize]: "T-shirt size",
	[TaskEstimationMethod.TimeBased]: "Time estimation"
};

export const TASK_ESTIMATION_METHOD_SHORT_DISPLAY_NAMES: Record<TaskEstimationMethod, string> = {
	[TaskEstimationMethod.None]: "Issues",
	[TaskEstimationMethod.StoryPoints]: "SP",
	[TaskEstimationMethod.TShirtSize]: "",
	[TaskEstimationMethod.TimeBased]: "Hours"
};
