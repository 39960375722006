import * as React from "react";
import { ValidationForm, BaseFormControl } from "react-bootstrap4-form-validation";
import _ from "lodash";
import ConfirmationPrompt from "../components/modals/confirmation-prompt/confirmation-prompt";
import { STRINGS } from "../../localization";

export const DEFAULT_AUTO_SAVE_DELAY = 1000;
export const IS_VALID_CLASS = "is-valid";

interface IProps<T> {
	className?: string;
	onAutoSaveTriggered?: (dataChange: Partial<T>) => void;
	onValueChanged?: (key: keyof T, value: T[keyof T]) => void;
	autoSaveDelay?: number;
	promptBeforeValueChange?: {
		[key in keyof T]?: {
			message: string;
			valueToRevertOnCancel: T[keyof T];
		};
	};
}

export interface IChangeEvent<T> {
	readonly target: {
		name: keyof T;
		className: string;
		value?: T[keyof T];
		disabled?: boolean;
	};
	readonly value?: T[keyof T];
}

export interface IState<T> {
	activePromptFieldData: { key: keyof T, value: T[keyof T], oldValue?: T[keyof T] } | null;
}

export default class AutoSaveForm<T> extends React.Component<IProps<T>, IState<T>> {
	private _changedFields: Partial<T> | null = null;
	private _saveTimeoutId: NodeJS.Timeout | null = null;

	public state: IState<T> = {
		activePromptFieldData: null
	};

	private readonly _autoSaveDelay: number = DEFAULT_AUTO_SAVE_DELAY;

	constructor(props: IProps<T>) {
		super(props);

		if (props.autoSaveDelay) {
			this._autoSaveDelay = props.autoSaveDelay;
		}
	}

	private triggerAutoSave = () => {
		if (this._changedFields) {
			if (this._saveTimeoutId) {
				clearTimeout(this._saveTimeoutId);
				this._saveTimeoutId = null;
			}
			this._saveTimeoutId = setTimeout(() => {
				if (this.props.onAutoSaveTriggered && this._changedFields
					&& !_.isEmpty(this._changedFields)) {
					this.props.onAutoSaveTriggered(this._changedFields);
				}
				this._saveTimeoutId = null;
				this._changedFields = null;
			}, this._autoSaveDelay);
		}
	}

	private onInputChanged = (e: IChangeEvent<T>) => {
		// In some cases (like in multi select Input) the value comes separately, and we'll prefer to use it.
		// Otherwise, we'll use the e.target.value
		let value = e.value;
		const target = e.target;

		if (!value) {
			if (target.value === undefined) {
				throw new Error("Received an input change event with undefined value");
			}
			value = target.value;
		}
		const { name, disabled } = target;

		// We preserve the old value in case the user will cancel the change later
		let oldValue: T[keyof T] | undefined;
		if (this.props.promptBeforeValueChange && this.props.promptBeforeValueChange[name]) {
			oldValue = this.props.promptBeforeValueChange[name]!.valueToRevertOnCancel;
		}

		if (this.props.onValueChanged) {
			this.props.onValueChanged(name, value);
		}

		// Triggering AutoSave must be done with a timeout to ensure there's no race-condition with the validation process.
		setTimeout(() => {
			const className = target.className;
			if (name && value !== undefined && !disabled) {
				const isValid = className.includes(IS_VALID_CLASS);
				if (isValid) {
					if (this.props.promptBeforeValueChange && this.props.promptBeforeValueChange[name]) {
						this.promptValueChange(name, value, oldValue);
					} else {
						this.updateChangedFieldsAndTriggerAutoSave(name, value);
					}
				} else {
					// If the value is not valid now, but was valid before (and wasn't saved yet), we don't want it anymore.
					if (this._changedFields && this._changedFields[name]) {
						delete this._changedFields[name];
					}
				}
			}
		});
	}

	private updateChangedFieldsAndTriggerAutoSave = (name: keyof T, value: T[keyof T]) => {
		if (this._changedFields) {
			this._changedFields = { ...this._changedFields, [name]: value };
		} else {
			// tslint:disable-next-line: no-object-literal-type-assertion
			this._changedFields = { [name]: value } as Partial<T>;
		}
		this.triggerAutoSave();
	}

	private promptValueChange = (key: keyof T, value: T[keyof T], oldValue?: T[keyof T]) => {
		this.setState({ activePromptFieldData: { key, value, oldValue } });
	}

	private onValueChangePromptCancelled = () => {
		if (!this.state.activePromptFieldData) {
			return;
		}

		// Revert to the old value
		if (this.props.onValueChanged) {
			this.props.onValueChanged(this.state.activePromptFieldData.key, this.state.activePromptFieldData.oldValue!);
		}

		this.setState({ activePromptFieldData: null });
	}

	private onValueChangePromptConfirmed = () => {
		if (!this.state.activePromptFieldData) {
			return;
		}

		const { key, value } = this.state.activePromptFieldData;
		this.updateChangedFieldsAndTriggerAutoSave(key, value);

		this.setState({ activePromptFieldData: null });
	}

	private onFormSubmit = (e: any) => {
		e.preventDefault();
	}

	public render() {
		const children = registerToChildrenOnChange(this.props.children, this.onInputChanged);

		return (
			<>
				{(this.state.activePromptFieldData !== null && this.props.promptBeforeValueChange) && (
					<ConfirmationPrompt
						show={!!this.state.activePromptFieldData}
						headerText={STRINGS.PLEASE_CONFIRM}
						bodyText={this.props.promptBeforeValueChange[this.state.activePromptFieldData.key]!.message}
						onCancel={this.onValueChangePromptCancelled}
						onConfirm={this.onValueChangePromptConfirmed}
					/>
				)}
				<ValidationForm className={this.props.className} onSubmit={this.onFormSubmit}>
					{children}
				</ValidationForm>
			</>
		);
	}
}

function registerToChildrenOnChange<T>(
	children: React.ReactNode,
	onChangeFn: (e: IChangeEvent<T>) => void): React.ReactNode[] {

	return recursiveMap(children, c => {
		const element = c as React.ReactElement;
		if (BaseFormControl.isPrototypeOf(element.type)) {
			return React.cloneElement(c as any, { onChange: onChangeFn });
		} else {
			return c;
		}
	});
}

function recursiveMap(children: React.ReactNode, fn: (child: React.ReactNode) => React.ReactNode): React.ReactNode[] {
	// @ts-ignore
	return React.Children.map(children, child => {
		if (!React.isValidElement(child)) {
			return child;
		}

		if (child.props.children) {
			child = React.cloneElement(child, {
				children: recursiveMap(child.props.children, fn)
			});
		}

		return fn(child as React.ReactNode);
	});
}
