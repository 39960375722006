import React, { FormEvent, useEffect, useState } from "react";
import classnames from "classnames";
import { ICard } from "../types/card";
import { PokerGameMetricSystem, IPlanningPokerIssue } from "@acumen/dashboard-common";
import LoadingIndicator from "../svg-assets/LoadingIndicator";
import NextIssueIcon from "../svg-assets/NextIssue";
import RevoteIssueIcon from "../svg-assets/RevoteIssues";
import { QUESTION_MARK_CARD_DISPLAY_VALUE, QUESTION_MARK_CARD_VALUE } from "../adapters/cards";

const CARD_TABLE_LOADING_FILL_COLOR = "#F5F5F5";
const CARD_TABLE_VOTING_FILL_COLOR = "#E4ECF9";
const CARD_TABLE_REVEAL_FILL_COLOR = "#399CFF";
const CARD_TABLE_SHOW_VOTES_FILL_COLOR = "#1B3F7B";

interface CardsPlayTableProps {
	className?: string;
	fillColor: string;
}

const CardsPlayTable: React.FC<CardsPlayTableProps> = ({
	className,
	fillColor,
}) => {
	return (
		<svg
			viewBox="0 0 396 165"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g filter="url(#filter0_bd_315_3204)">
				<rect x="8" y="4" width="380" height="149" rx="74.5" fill={fillColor} />
				<rect
					x="10.5"
					y="6.5"
					width="375"
					height="144"
					rx="72"
					stroke="#F5F5F5"
					strokeWidth="5"
				/>
			</g>
			<defs>
				<filter
					id="filter0_bd_315_3204"
					x="0"
					y="0"
					width="396"
					height="165"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
					<feComposite
						in2="SourceAlpha"
						operator="in"
						result="effect1_backgroundBlur_315_3204"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="4" />
					<feGaussianBlur stdDeviation="4" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
					/>
					<feBlend
						mode="normal"
						in2="effect1_backgroundBlur_315_3204"
						result="effect2_dropShadow_315_3204"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect2_dropShadow_315_3204"
						result="shape"
					/>
				</filter>
			</defs>
		</svg>
	);
};

export enum ActivationState {
	Loading = "Loading",
	SelectIssueToVote = "SelectIssueToVote",
	Voting = "Voting",
	ShowRevealCardsOption = "ShowRevealCardsOption",
	ShowVotes = "ShowVotes",
}
export interface GameTableProps {
	activation: ActivationState;
	currentIssue?: IPlanningPokerIssue;
	nextIssue?: IPlanningPokerIssue;
	isModerator: boolean;
	hasAnyIssues: boolean;
	gameMetric: PokerGameMetricSystem;
	onClickRevealCards: () => Promise<void>;
	onVoteIssue: (issueToVote: IPlanningPokerIssue) => Promise<void>;
	getClosestCardAccordingToMetric: (
		metric: PokerGameMetricSystem
	) => ICard | undefined;
	onFinishedVoting: () => void;
	onSaveEstimation: (issue: IPlanningPokerIssue, estimation: string) => Promise<void>;
}
function fillColorByState(state: ActivationState): string {
	switch (state) {
		case ActivationState.Voting: {
			return CARD_TABLE_VOTING_FILL_COLOR;
		}
		case ActivationState.ShowRevealCardsOption: {
			return CARD_TABLE_REVEAL_FILL_COLOR;
		}
		case ActivationState.ShowVotes: {
			return CARD_TABLE_SHOW_VOTES_FILL_COLOR;
		}
		case ActivationState.SelectIssueToVote:
		case ActivationState.Loading:
		default: {
			return CARD_TABLE_LOADING_FILL_COLOR;
		}
	}
}
export const GameTable: React.FC<GameTableProps> = ({
	activation,
	onClickRevealCards,
	getClosestCardAccordingToMetric,
	onVoteIssue,
	onFinishedVoting,
	currentIssue,
	nextIssue,
	isModerator,
	hasAnyIssues,
	onSaveEstimation,
	gameMetric,
}) => {
	const [isRevotingOnIssueXid, setIsRevotingOnIssueXid] = useState<string | undefined>();
	const [isRevealingCardsOnIssueXid, setIsRevealingCardsOnIssueXid] = useState<string | undefined>();
	const [votingCard, setVotingCard] = useState<ICard | undefined>();
	const [isVotingOnIssueXid, setIsVotingOnIssueXid] = useState<string | undefined>();
	const [reportedEstimationOnIssueXid, seReportedEstimationOnIssueXid] = useState<string | undefined>();

	useEffect(() => {
		let isMounted = true;
		async function saveEstimation(vc: ICard, ci: IPlanningPokerIssue) {
			if (isMounted) {
				await onSaveEstimation(ci, vc.displayValue);
			}
		}

		if (activation !== ActivationState.ShowVotes) {
			return;
		}
		const card = getClosestCardAccordingToMetric(gameMetric);
		if (card) {
			setVotingCard(card);

			// Persist estimation one time per round, after we calculated the card!
			if (!currentIssue) {
				return;
			}
			if (reportedEstimationOnIssueXid !== undefined && currentIssue.xid === reportedEstimationOnIssueXid) {
				return;
			}
			seReportedEstimationOnIssueXid(currentIssue.xid);

			// tslint:disable-next-line: no-floating-promises
			saveEstimation(card, currentIssue);
		}
		return () => { isMounted = false; };
	}, [activation, getClosestCardAccordingToMetric, gameMetric]);

	useEffect(() => {
		if (nextIssue && currentIssue && isVotingOnIssueXid && nextIssue.xid !== currentIssue.xid && currentIssue.xid === isVotingOnIssueXid) {
			setIsVotingOnIssueXid(undefined);
		}
	}, [nextIssue, currentIssue, isVotingOnIssueXid]);

	return (
		<div className="flex flex-row gap-5 py-4">
			{isModerator && activation === ActivationState.ShowVotes && (
				<div className="self-center">
					<button
						disabled={currentIssue !== undefined && isRevotingOnIssueXid !== undefined && isRevotingOnIssueXid === currentIssue.xid}
						type="button"
						className={classnames(
							"text-[#1B3F7B] bg-[#F5F5F5] font-bold rounded text-base px-8 py-2 text-center inline-flex items-center",
							{
								"hover:bg-[#DCDCDC]": !(currentIssue !== undefined && isRevotingOnIssueXid !== undefined && isRevotingOnIssueXid === currentIssue.xid),
							}
						)}
						onClick={async (event: FormEvent) => {
							event.preventDefault();
							if (currentIssue && onVoteIssue) {
								setIsRevotingOnIssueXid(currentIssue.xid);
								await onVoteIssue(currentIssue);
								setIsRevealingCardsOnIssueXid(undefined);
								setIsVotingOnIssueXid(undefined);
							}
						}}
					>
						<RevoteIssueIcon className={classnames("mr-2 -ml-1 w-5 h-5")} />
						{currentIssue !== undefined && (isRevotingOnIssueXid === undefined || isRevotingOnIssueXid !== currentIssue.xid) && "Revote"}
						{currentIssue !== undefined && isRevotingOnIssueXid !== undefined && isRevotingOnIssueXid === currentIssue.xid && "Updating..."}
					</button>
				</div>
			)}
			<div className="relative">
				<CardsPlayTable
					className="w-full"
					fillColor={fillColorByState(activation)}
				/>
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  whitespace-nowrap">
					{activation === ActivationState.Voting && (
						<h1 className="text-4xl font-bold text-[#399CFF]">Voting...</h1>
					)}
					{activation === ActivationState.Loading && (
						<h1 className="text-4xl font-bold text-[#399CFF] whitespace-nowrap">
							Loading game
						</h1>
					)}
					{isModerator && activation === ActivationState.SelectIssueToVote && (
						<div className="flex flex-col justify-center items-center justify-self-center text-black font-light text-base">
							<h2 className="text-xl font-semibold text-[#1B3F7B] whitespace-nowrap">
								To start voting
							</h2>

							<h2 className="text-xl font-normal text-[#FC09B8] whitespace-nowrap">
								{hasAnyIssues && "Select an issue to vote 👉"}
								{!hasAnyIssues && "Create your first issue 👉"}
							</h2>
						</div>
					)}
					{!isModerator && activation === ActivationState.SelectIssueToVote && (
						<div className="flex flex-col justify-center items-center justify-self-center text-black font-light text-base">
							<h2 className="text-xl font-normal text-[#1B3F7B] whitespace-nowrap">
								Waiting for the facilitator
							</h2>
							<h2 className="text-xl font-normal text-center text-[#1B3F7B] whitespace-nowrap">
								{hasAnyIssues && <span>to <span className="font-semibold text-[#FC09B8]">pick</span> an issue</span>}
								{!hasAnyIssues && <span>to <span className="font-semibold text-[#FC09B8]">create</span> an issue</span>}
							</h2>
						</div>
					)}
					{activation === ActivationState.ShowRevealCardsOption && (
						<button
							type="button"
							disabled={currentIssue !== undefined && isRevealingCardsOnIssueXid !== undefined && isRevealingCardsOnIssueXid === currentIssue.xid}
							className={classnames(
								"text-2xl text-white bg-[#399CFF] font-medium rounded-2xl p-4 whitespace-nowrap",
								{
									"transition duration-200 ease hover:bg-[#338CE5]":
										(currentIssue !== undefined && (isRevealingCardsOnIssueXid === undefined || isRevealingCardsOnIssueXid !== currentIssue.xid)),
								}
							)}
							onClick={async (event: FormEvent) => {
								if (isRevealingCardsOnIssueXid && currentIssue && currentIssue.xid === isRevealingCardsOnIssueXid) {
									return;
								}
								event.preventDefault();
								if (currentIssue && onClickRevealCards) {
									setIsRevealingCardsOnIssueXid(currentIssue.xid);
									await onClickRevealCards();
									setIsRevotingOnIssueXid(undefined);
								}
							}}
						>
							{currentIssue !== undefined && (isRevealingCardsOnIssueXid === undefined || isRevealingCardsOnIssueXid !== currentIssue.xid) && "Reveal Cards!"}
							{currentIssue !== undefined && isRevealingCardsOnIssueXid !== undefined && isRevealingCardsOnIssueXid === currentIssue.xid && <LoadingIndicator className="inline mr-3 w-4 h-4 text-white animate-spin" loadingfillcolor="#338CE5"/>}
							{currentIssue !== undefined && isRevealingCardsOnIssueXid !== undefined && isRevealingCardsOnIssueXid === currentIssue.xid && "Revealing..."}
						</button>
					)}
					{activation === ActivationState.ShowVotes && (
						<div className="flex flex-col items-start">
							<span className="text-white font-medium text-xl whitespace-nowrap flex-1">
								{gameMetric === PokerGameMetricSystem.Average && "Average"}
								{gameMetric === PokerGameMetricSystem.Consensus && "Consensus"}
								{gameMetric === PokerGameMetricSystem.Median && "Median"}
							</span>
							<span className="text-white font-medium text-4xl self-center whitespace-nowrap flex-1">
								{(votingCard && gameMetric === PokerGameMetricSystem.Consensus) ?
									(votingCard.value === QUESTION_MARK_CARD_VALUE ? "None" : votingCard.displayValue) :
									(votingCard ? votingCard.displayValue : QUESTION_MARK_CARD_DISPLAY_VALUE)}
							</span>
						</div>
					)}
				</div>
			</div>

			{isModerator && activation === ActivationState.ShowVotes && (
				<div className="self-center">
					<button
						disabled={nextIssue !== undefined && isVotingOnIssueXid !== undefined && isVotingOnIssueXid === nextIssue.xid}
						type="button"
						className={classnames(
							"text-white bg-[#399CFF] font-bold rounded text-base px-8 py-2 text-center inline-flex items-center",
							{
								"hover:bg-[#338CE5]": !(nextIssue !== undefined && isVotingOnIssueXid !== undefined && isVotingOnIssueXid === nextIssue.xid),
							}
						)}
						onClick={async (event: FormEvent) => {
							event.preventDefault();

							if (nextIssue && onVoteIssue) {
								setIsVotingOnIssueXid(nextIssue.xid);
								await onVoteIssue(nextIssue);
								setIsRevealingCardsOnIssueXid(undefined);
								setIsRevotingOnIssueXid(undefined);
							} else if (nextIssue === undefined && onFinishedVoting) {
								onFinishedVoting();
							}
						}}
					>
						<NextIssueIcon className={classnames("mr-2 -ml-1 w-5 h-5", {
							"animate-bounce": nextIssue !== undefined && isVotingOnIssueXid !== undefined && isVotingOnIssueXid === nextIssue.xid,
						})} />
						{nextIssue && (isVotingOnIssueXid === undefined || isVotingOnIssueXid !== nextIssue.xid) && "Next issue"}
						{nextIssue !== undefined && isVotingOnIssueXid !== undefined && isVotingOnIssueXid === nextIssue.xid && "Updating..."}
						{!nextIssue && "Finish"}
					</button>
				</div>
			)}
		</div>
	);
};
