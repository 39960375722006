import {
	IDashboardPullRequest, IDashboardTask, IDashboardObstacle, ObstacleDetailsType, ObstacleEffect, IDashboardPullRequestEntity
} from "@acumen/dashboard-common";
import classNames from "classnames";
import { Avatar } from "../../../components/avatar";
import AvatarsGroup from "../../../components/avatars-group";
import { ExternalImage } from "../../../components/external-image";
import React, { useState } from "react";
import { Pagination, Popup } from "semantic-ui-react";
import { capitalizeName } from "../../../../utils/string";
import LoadingIndicator from "../../../components/loader";
import TaskIconGroup from "../../../components/dashboard-task/task-icon-group";
import { useStores } from "../../../mobx-stores";
import { observer } from "mobx-react";

interface IObstaclePanel {
	internalObstacleData?: IDashboardObstacle[];
	externalObstacleData?: IDashboardObstacle[];
	setSelectedPullRequestInPanel: React.Dispatch<React.SetStateAction<IDashboardPullRequest | IDashboardPullRequestEntity | undefined>>;
	setSelectedTaskInPanel: React.Dispatch<React.SetStateAction<IDashboardTask | undefined>>;
	selectedTaskIdInPanel?: string;
	selectedPrIdInPanel?: string;
	isLoading: boolean;
	pageError: boolean;
}

interface IObstacleTable {
	obstacles?: IDashboardObstacle[];
	title: string;
	itemsPerPage?: number;
	isLoading: boolean;
	setSelectedPullRequestInPanel: React.Dispatch<React.SetStateAction<IDashboardPullRequest | IDashboardPullRequestEntity | undefined>>;
	setSelectedTaskInPanel: React.Dispatch<React.SetStateAction<IDashboardTask | undefined>>;
	selectedTaskIdInPanel?: string;
	selectedPrIdInPanel?: string;
	pageError?: boolean;
}

interface IObstacleTopTasks {
	title: string;
	isLoading: boolean;
	obstacleTasks?: IDashboardTask[];
	setSelectedTaskInPanel: React.Dispatch<React.SetStateAction<IDashboardTask | undefined>>;
	selectedTaskIdInPanel?: string;
	pageError: boolean;
}

interface IObstacleTopPrs {
	title: string;
	obstaclePrs?: IDashboardPullRequest[];
	isLoading: boolean;
	setSelectedPullRequestInPanel: React.Dispatch<React.SetStateAction<IDashboardPullRequest | IDashboardPullRequestEntity | undefined>>;
	selectedPrIdInPanel?: string;
	pageError: boolean;
}

// tslint:disable-next-line: variable-name
export const TopObstacleTasks = (props: IObstacleTopTasks) => {
	return (
		<div className="ui full-width raised card">
			<h4 className="ui header">{props.title}</h4>
			<LoadingIndicator local={true} isActive={props.isLoading && !props.pageError}>
				<table className="ui very compact small fixed single line unstackable table min-width">
					<thead className="full-width">
						<tr>
							<th className="three wide" />
							<th className="">Title</th>
						</tr>
					</thead>
					{props.obstacleTasks && props.obstacleTasks.map((task: IDashboardTask, i: number) => (
						<tbody key={i}>
							<tr
								className={classNames("hoverable-container", "clickable", { selected: props.selectedTaskIdInPanel === task.entityId })}
								onClick={() => props.setSelectedTaskInPanel(task)}
							>
								<td>
									<div className="pr-icon-group">
										<Popup
											size="mini"
											basic={true}
											content={task.type.internalType}
											trigger={
												<ExternalImage src={task.type.iconUrl ?? ""} className="ui micro image" />
											}
										/>
										{(task.priority.internalPriority !== null && task.priority.internalPriority.trim().length > 0) && (
											<Popup
												size="mini"
												basic={true}
												content={task.priority.internalPriority}
												trigger={
													<ExternalImage src={task.priority.iconUrl ?? ""} className="ui micro image" />
												}
											/>
										)}
										<Popup
											size="mini"
											basic={true}
											content={task.assignee?.primaryDisplayName}
											trigger={(
												<Avatar
													dataContributor={task.assignee}
												/>
											)}
										/>
									</div>
								</td>
								<td >
									<div className="hover-ellipsis-new-line">
										{task.publicHtmlUrl
											? <a href={task.publicHtmlUrl} target="_blank" rel="noopener noreferrer" className="acu-link">{task.publicIdentifier}</a>
											: task.publicIdentifier
										}
										<span className="task-title after-space">{task.title}</span>
									</div>
								</td>
							</tr>
						</tbody>
					))}
				</table>
			</LoadingIndicator>
		</div>
	);
};

// tslint:disable-next-line: variable-name
export const TopObstaclePRs = (props: IObstacleTopPrs) => {
	return (
		<div className="ui full-width raised card">
			<h4 className="ui header">{props.title}</h4>
			<LoadingIndicator local={true} isActive={props.isLoading && !props.pageError}>
				<table className="ui very compact small fixed single line unstackable table min-width">
					<thead>
						<tr>
							<th className="two wide single line">
								<Popup
									size="mini"
									basic={true}
									content={"Opener"}
									trigger={
										<span>Opener</span>
									}
								/>
							</th>
							<th className="three wide single line">
								<Popup
									size="mini"
									basic={true}
									content={"Reviewer(s)"}
									trigger={
										<span>Reviewer(s)</span>
									}
								/>
							</th>
							<th />
						</tr>
					</thead>
					{props.obstaclePrs && props.obstaclePrs.map((pr: IDashboardPullRequest, i: number) => (
						<tbody key={i}>
							<tr className={classNames("hoverable-container", "clickable", { selected: props.selectedPrIdInPanel === pr.entityId })}
								onClick={() => props.setSelectedPullRequestInPanel(pr)}>
								<td>
									<div >
										<Popup
											size="mini"
											basic={true}
											style={{ textTransform: "capitalize" }}
											content={pr.creator?.primaryDisplayName}
											trigger={
												<Avatar dataContributor={pr.creator} />
											}
										/>
									</div>
								</td>
								<td>
									<Popup
										size="mini"
										basic={true}
										style={{ textTransform: "capitalize" }}
										content={pr.reviewers.map(r => r.primaryDisplayName).join(", ")}
										trigger={
											(
												<AvatarsGroup
													usersList={pr.reviewers.map(r => ({
														id: r.id,
														displayName: r.primaryDisplayName || "",
														avatarUrl: r.primaryAvatarUrl || ""
													}))}
													maxUsersOnView={3}
												/>
											)
										}
									/>
								</td>
								<td >
									<div className="hover-ellipsis">
										{pr.publicHtmlUrl
											? <a href={pr.publicHtmlUrl} target="_blank" rel="noopener noreferrer" className="acu-link">{`#${pr.publicIdentifier}`}</a>
											: pr.publicIdentifier
										}
										<span className="pr-title after-space">{pr.title}</span>
									</div>
								</td>
							</tr>
						</tbody>
					))}
				</table>
			</LoadingIndicator>
		</div>
	);
};

// tslint:disable-next-line: variable-name
export const ObstaclePanel = observer((props: IObstaclePanel) => {

	const {
		sprintRetroStore: { internalObstacleData, externalObstacleData }
	} = useStores();

	const {
		isLoading, setSelectedPullRequestInPanel, setSelectedTaskInPanel,
		selectedTaskIdInPanel, selectedPrIdInPanel, pageError
	} = { ...props };
	return (
		<div className="full-width ui raised card">
			<ObstaclesTable
				title={"Internal productivity obstacles"}
				obstacles={internalObstacleData}
				isLoading={isLoading}
				setSelectedPullRequestInPanel={setSelectedPullRequestInPanel}
				setSelectedTaskInPanel={setSelectedTaskInPanel}
				selectedTaskIdInPanel={selectedTaskIdInPanel}
				selectedPrIdInPanel={selectedPrIdInPanel}
				pageError={pageError}
			/>
			<ObstaclesTable
				title={"External productivity obstacles"}
				obstacles={externalObstacleData}
				isLoading={isLoading}
				setSelectedPullRequestInPanel={setSelectedPullRequestInPanel}
				setSelectedTaskInPanel={setSelectedTaskInPanel}
				selectedTaskIdInPanel={selectedTaskIdInPanel}
				selectedPrIdInPanel={selectedPrIdInPanel}
				pageError={pageError}
			/>
		</div>
	);
});
export default ObstaclePanel;

// tslint:disable-next-line: variable-name
const ObstaclesTable = (props: IObstacleTable) => {
	const {
		obstacles, isLoading, itemsPerPage = 100, setSelectedPullRequestInPanel, setSelectedTaskInPanel,
		selectedTaskIdInPanel, selectedPrIdInPanel, pageError
	} = { ...props };
	const [page, setPage] = useState<number>(1);
	const [expandedRows, setExpandedRows] = useState<boolean[]>([]);

	const toggleRow = (i: number) => {
		const expended = expandedRows;
		expended[i] = !expandedRows[i];
		setExpandedRows([...expended]);
	};

	const from = page === 1 ? 0 : ((page - 1) * itemsPerPage);
	const to = (((page) * itemsPerPage));

	const getDetailsTable = (details: IDashboardObstacle["details"],
		onPRClick: React.Dispatch<React.SetStateAction<IDashboardPullRequest | IDashboardPullRequestEntity | undefined>>,
		onTaskClick: React.Dispatch<React.SetStateAction<IDashboardTask | undefined>>) => {
		if (details?.type === ObstacleDetailsType.PullRequest) {
			return (details.details.map((pr, i) => (
				<tr className={classNames("clickable", { selected: selectedPrIdInPanel === pr.entityId })} key={i} onClick={() => onPRClick(pr)}>
					<td className="half wide" />
					<td>
						<Popup
							size="mini"
							basic={true}
							style={{ textTransform: "capitalize" }}
							content={pr.creator?.primaryDisplayName}
							trigger={
								<Avatar dataContributor={pr.creator} />
							}
						/>
					</td>
					<td>
						<AvatarsGroup
							usersList={pr.reviewers.map(r => ({
								id: r.id,
								displayName: r.primaryDisplayName || "",
								avatarUrl: r.primaryAvatarUrl || ""
							}))}
							maxUsersOnView={3}
						/>
					</td>
					<td className="fourteen wide">
						<div>
							{pr.publicHtmlUrl
								? <a href={pr.publicHtmlUrl} target="_blank" rel="noopener noreferrer" className="acu-link">{`${pr.repositoryName ?? ""}#${pr.publicIdentifier}`}</a>
								: `${pr.repositoryName ?? ""}#${pr.publicIdentifier}`
							}
							<span>{pr.title}</span>
						</div>
					</td>
				</tr>
			)));
		}
		if (details?.type === ObstacleDetailsType.Task) {
			return (
				details.details.map((task: IDashboardTask, i: number) => (
					<tr className={classNames("clickable", { selected: selectedTaskIdInPanel === task.entityId })} key={i} onClick={() => onTaskClick(task)}>
						<td className="half wide" />
						<td>
							<TaskIconGroup task={task} />
						</td>
						<td className="fourteen wide">
							<div>
								{task.publicHtmlUrl
									? <a href={task.publicHtmlUrl} target="_blank" rel="noopener noreferrer" className="acu-link">
										{task.publicIdentifier}
									</a> : task.publicIdentifier
								}
								<span>{task.title}</span>
							</div>
						</td>
					</tr >
				)));
		}
	};

	return (
		<>
			<h4 className="ui header">{props.title}</h4>
			<LoadingIndicator local={true} isActive={isLoading && !pageError}>
				<table className="ui very compact small fixed single line unstackable table min-width">
					<thead>
						<tr>
							<th className="half wide single line" />
							<th className="two wide single line">
								<Popup
									size="mini"
									basic={true}
									content={"Obstacles"}
									trigger={
										<span>Obstacles</span>
									}
								/>
							</th>
							<th className="single line">Details</th>
							<th className="two wide single line">Effect</th>
						</tr>
					</thead>
					{obstacles && obstacles.slice(from, to).map((row: IDashboardObstacle, i: number) => {
						const isRowExpanded = !!expandedRows[i];
						return (
							<tbody key={i}>
								<tr className={classNames("hoverable-container", { clickable: (row.details && row.details.details) })} onClick={(e) => { e.stopPropagation(); toggleRow(i); }} >
									<td className="half wide">
										{row.details && row.details.details && sideArrow(isRowExpanded)}
									</td>
									<td>
										<div className="hover-ellipsis">
											<span className="after-space">{row.name}</span>
										</div>
									</td>
									<td>
										<div>
											{row.text}
											<div className="ui small gray label" style={{ marginLeft: "1rem" }}>{row.category}</div>
										</div>
									</td>
									<td >
										<span className={classNames("colored-text", row.effect.toLocaleLowerCase())}>
											{row.effect === ObstacleEffect.NotAvailable ? "N/A" : capitalizeName(row.effect)}
										</span>
									</td>
								</tr>
								<tr>
									{isRowExpanded && row.details && row.details.details && (
										<th className="no-padding" colSpan={4}>
											<table className="ui inner table fixed very line small stackable clickable ">
												<tbody>
													{getDetailsTable(row.details, setSelectedPullRequestInPanel, setSelectedTaskInPanel)}
												</tbody>
											</table>
										</th>
									)}
								</tr>
							</tbody>
						);
					})
					}
					{itemsPerPage > 0 && obstacles && obstacles.length > itemsPerPage && (
						<tfoot>
							<tr>
								<td colSpan={8}>
									<div className="ui basic center aligned segment">
										<Pagination
											boundaryRange={0}
											defaultActivePage={page}
											ellipsisItem={null}
											firstItem={null}
											lastItem={null}
											siblingRange={1}
											totalPages={Math.ceil(obstacles.length / itemsPerPage)}
											onPageChange={(_e, { activePage }) => setPage(activePage as number ?? 1)}
										/>
									</div>
								</td>
							</tr>
						</tfoot>
					)}
				</table>
			</LoadingIndicator>
		</>
	);
};

const sideArrow = (isActive: boolean) => {
	return (
		isActive ? <i aria-hidden="true" className="chevron down icon tiny" /> :
			<i aria-hidden="true" className="chevron right icon tiny" />
	);
};
