import moment from "moment";
import { ITaskHighlight, HighlightType, IDashboardTaskDetailed, TaskEstimationMethod } from "@acumen/dashboard-common";
import _ from "lodash";
import { inflect, round } from "@acumen/common";
import {
	TASK_ESTIMATION_METHOD_DISPLAY_NAMES,
	TASK_ESTIMATION_METHOD_SHORT_DISPLAY_NAMES
} from "../../../localization/text-records";

const HOUR_IN_MS = 3600000;

// tslint:disable-next-line: variable-name
export const Formatters = {
	workIntervals(intervalMs: number | null, shortForm: boolean = true) {
		if (intervalMs === null) {
			return "";
		}
		if (intervalMs === 0) {
			return "0";
		}
		return (
			intervalMs <= HOUR_IN_MS / 2 ? `<1${(shortForm ? "H" : " hour")}` :
				intervalMs <= HOUR_IN_MS ? `~1${(shortForm ? "H" : " hour")}` :
					`~${Math.ceil(intervalMs / HOUR_IN_MS)}${(shortForm ? "H" : " hours")}`
		);
	},
	itemsRange(length: number, perPage: number, total: number) {
		if (!length) {
			return "";
		}
		return `1-${length > perPage ? perPage : length} of ${total}`;
	},
	toHours(timeDurationMs: number, includeUnits: boolean = true): string {
		if (timeDurationMs === 0) {
			return "0";
		}

		const hours = round(moment.duration(timeDurationMs).asHours());
		return includeUnits ? `${hours}h` : `${hours}`;
	},
	cycleTime(timeDurationMs: number): number | string {
		const duration = moment.duration(timeDurationMs);
		const days = duration.asDays();

		if (days > 2) {
			if (days % 1 > 0.5) {
				return `${round(days, 0)} days`;
			}

			return `${_.ceil(days * 2) / 2} days`;
		}

		const hours = round(duration.asHours(), 0);
		if (hours > 0) {
			return inflect(hours, "hour", "hours");
		}

		const minutes = round(duration.asMinutes(), 0);
		if (minutes > 0) {
			return inflect(minutes, "minute", "minutes");
		}

		return "1 minute";
	},
	estimationValueFromTask(task: IDashboardTaskDetailed, estimationMethod: TaskEstimationMethod | null = null, defaultValue: string | null = null): string | null {
		if (!estimationMethod) {
			return defaultValue;
		}
		switch (estimationMethod) {
			case TaskEstimationMethod.StoryPoints: {
				return this.estimationValue(task.storyPoints, estimationMethod, defaultValue);
			}
			case TaskEstimationMethod.TShirtSize: {
				return this.estimationValue(task.tshirtSizeName, estimationMethod, defaultValue);
			}
			case TaskEstimationMethod.TimeBased: {
				return this.estimationValue(task.timeEstimateSeconds, estimationMethod, defaultValue);
			}
			default:
		}
		return defaultValue;
	},
	estimationValue(estimationValue: number | string | null, estimationMethod: TaskEstimationMethod | null = null, defaultValue: string | null = null,
		noneEstimationAsTask = false, includeTimeEstimationUnits = true): string | null {
		if (!estimationMethod) {
			return defaultValue;
		}

		switch (estimationMethod) {
			case TaskEstimationMethod.StoryPoints: {
				if (estimationValue === null) {
					return defaultValue;
				}
				if (_.isString(estimationValue)) {
					return estimationValue;
				}
				return `${round(estimationValue, 1)}`;
			}
			case TaskEstimationMethod.TShirtSize: {
				if (estimationValue === null) {
					return defaultValue;
				}

				if (_.isString(estimationValue)) {
					const trimmedTShirtSize = estimationValue.trim();
					return (trimmedTShirtSize.length === 0 ? defaultValue : trimmedTShirtSize);
				}
				return defaultValue;
			}
			case TaskEstimationMethod.TimeBased: {
				if (estimationValue === null) {
					return defaultValue;
				}

				if (_.isNumber(estimationValue)) {
					const estimate = Formatters.toHours(estimationValue * 1000, includeTimeEstimationUnits);
					return (estimate.length === 0 ? defaultValue : estimate);
				}
				return defaultValue;
			}
			case TaskEstimationMethod.None: {
				if (noneEstimationAsTask !== true || estimationValue === null) {
					return defaultValue;
				}

				if (_.isString(estimationValue)) {
					return estimationValue;
				}
				return `${round(estimationValue, 1)}`;
			}
		}
		return defaultValue;
	},
	getEstimationTitle(estimationMethod?: TaskEstimationMethod): string {
		if (!estimationMethod) {
			return "Estimation";
		}

		return TASK_ESTIMATION_METHOD_DISPLAY_NAMES[estimationMethod];
	},
	getEstimationShortTitle(estimationMethod?: TaskEstimationMethod): string {
		if (!estimationMethod) {
			return "Estimation";
		}

		return TASK_ESTIMATION_METHOD_SHORT_DISPLAY_NAMES[estimationMethod];
	}

};

export const filterTaskDisplayData = (highlights: ITaskHighlight[], filterTypes = [HighlightType.Property]) =>
	highlights.filter((highlight) =>
		!filterTypes.includes(highlight.type)
		&& highlight.weight > 0);
