import { vsprintf } from "sprintf-js";

export interface ILocalizedStrings {
	readonly ACUMEN: string;
	readonly MEMBER_DELETE_CONFIRM_MESSAGE: string;
	readonly PLEASE_TRY_AGAIN: string;
	readonly OOPS_REQUEST_FAILED: string;
	readonly REQUEST_TIMEOUT: string;
	readonly INVALID_TEAM_NAME_ALREADY_EXISTS: string;
	readonly ADD_TEAM: string;
	readonly TEAM_MANAGEMENT: string;
	readonly DISCARD: string;
	readonly ADD_MEMBER: string;
	readonly CONTRIBUTOR: string;
	readonly EDIT_MEMBER_TEMPLATE: string;
	readonly DONE: string;
	readonly SELECT_A_MEMBERSHIP_TYPE: string;
	readonly MEMBER: string;
	readonly NA: string;
	readonly ROLE: string;
	readonly TITLE: string;
	readonly NONE: string;
	readonly SELECT_A_TEAM_LEAD: string;
	readonly TEAMS: string;
	readonly MEMBERS: string;
	readonly TEAM_LEAD: string;
	readonly LINK_IDS_TEMPLATE: string;
	readonly ANONYMOUS: string;
	readonly USERNAME_NA: string;
	readonly EMAIL_NA: string;
	readonly CALCULATION: string;
	readonly VALUE: string;
	readonly PULL_REQUESTS: string;
	readonly NOTIFICATION_TARGET: string;
	readonly MORE_INFO_RATING_TITLE: string;
	readonly MORE_INFO_RATING_REVIEW_HEADER: string;
	readonly MORE_INFO_RATING_REVIEW_PLACEHOLDER: string;
	readonly MORE_INFO_RATING_SUBMIT_BUTTON: string;
	readonly MORE_INFO_ERROR_EXPIRED_TOKEN_MESSAGE: string;
	readonly MORE_INFO_ERROR_BAD_PARAMETERS_MESSAGE: string;
	readonly CLIENT_ERROR_BAD_REQUEST: string;
	readonly MORE_INFO_NOTIFICATIONS_ENABLED_FOR_RULE: string;
	readonly SLACK: string;
	readonly JIRA: string;
	readonly EMAIL: string;
	readonly API: string;
	readonly PULL_REQUEST: string;
	readonly DAYS: string;
	readonly HOURS: string;
	readonly SELECT_ONE_ROLE: string;
	readonly SELECT_A_STATUS: string;
	readonly ENTER_A_VALID_EMAIL: string;

	readonly USER_DISABLE_CONFIRM_MESSAGE: string;
	readonly USER_ENABLE_CONFIRM_MESSAGE: string;
	readonly USER_DELETE_CONFIRM_MESSAGE: string;
	readonly USER_CHANGE_STATUS_CONFIRM_MESSAGE: string;

	readonly PLEASE_CONFIRM: string;
	readonly CONFIRM: string;
	readonly CANCEL: string;
	readonly ARE_YOU_SURE: string;
	readonly CONFIRM_BTN_TEXT: string;

	readonly INSIGHTS: string;
	readonly SETTINGS: string;

	readonly USERS: string;
	readonly CONTRIBUTORS: string;

	readonly NAME: string;
	readonly DESCRIPTION: string;
	readonly NOTIFICATION_CHANNELS: string;
	readonly NOTIFICATION_MESSAGE: string;
	readonly RUN_ON: string;
	readonly AND_IT_RELATES_TO: string;
	readonly EVALUATION_CRITERIA: string;

	readonly COMMIT: string;
	readonly TASK: string;

	readonly COMMITS: string;
	readonly TASKS: string;

	readonly CREATED: string;
	readonly UPDATED: string;
	readonly DELETED: string;
	readonly TIME_INTERVAL: string;

	readonly SELECT_A_SUBSCRIPTION_TYPE: string;
	readonly SELECT_AN_ENTITY_RELATION: string;
	readonly DETAILS: string;
	readonly PARAMETERS: string;
	readonly NOTIFICATIONS: string;
	readonly INSIGHT_FORMULA: string;
	readonly EVERY: string;
	readonly ACTIVE: string;
	readonly IS_INVALID: string;

	readonly GLOBAL_DATE_FORMAT: string;
	readonly GLOBAL_DURATION_FORMAT: string;

	readonly INTEGRATION_INTEGRATIONS: string;
	readonly INTEGRATION_ADD_INTEGRATION: string;
	readonly INTEGRATION_MANAGEMENT: string;
	readonly INTEGRATION_GRID_ID: string;
	readonly INTEGRATION_GRID_CUSTOMER_ID: string;
	readonly INTEGRATION_GRID_PROVIDER: string;
	readonly INTEGRATION_GRID_STATE: string;
	readonly INTEGRATION_GRID_STATUS: string;

	readonly INTEGRATION_SELECT_PROVIDER_GET_STARTED: string;
	readonly INTEGRATION_SELECT_PROVIDER_COMING_SOON: string;

	readonly INTEGRATION_FLOW_INIT_TEXT: string;
	readonly INTEGRATION_FLOW_SUCCESS_STEP_TITLE: string;
	readonly INTEGRATION_FLOW_SUCCESS_STEP_ADD_ANOTHER: string;

	readonly INTEGRATION_GITHUB_FLOW_TITLE: string;
	readonly INTEGRATION_GITHUB_FLOW_INSTALL_STEP_TEXT: string;
	readonly INTEGRATION_GITHUB_FLOW_INSTALL_STEP_NOTE: string;
	readonly INTEGRATION_GITHUB_FLOW_INSTALL_STEP_NOTE_TEXT: string;
	readonly INTEGRATION_GITHUB_FLOW_INSTALL_STEP_BUTTON: string;
	readonly INTEGRATION_GITHUB_FLOW_ENTER_DATA_STEP_TEXT: string;
	readonly INTEGRATION_GITHUB_FLOW_ENTER_DATA_STEP_REDIRECTED_URL: string;
	readonly INTEGRATION_GITHUB_FLOW_ENTER_DATA_STEP_PASTE_URL_PLACEHOLDER: string;
	readonly INTEGRATION_GITHUB_FLOW_ENTER_DATA_URL_STEP_VALIDATION: string;
	readonly INTEGRATION_GITHUB_FLOW_ENTER_DATA_URL_STEP_SUBMIT: string;

	readonly INTEGRATION_SLACK_FLOW_TITLE: string;
	readonly INTEGRATION_SLACK_FLOW_INSTALL_STEP_TEXT: string;
	readonly INTEGRATION_SLACK_FLOW_INSTALL_STEP_BUTTON: string;
	readonly INTEGRATION_SLACK_FLOW_ENTER_DATA_STEP_TEXT: string;
	readonly INTEGRATION_SLACK_FLOW_ENTER_DATA_STEP_REDIRECTED_URL: string;
	readonly INTEGRATION_SLACK_FLOW_ENTER_DATA_STEP_PASTE_URL: string;
	readonly INTEGRATION_SLACK_FLOW_ENTER_DATA_URL_STEP_VALIDATION: string;
	readonly INTEGRATION_SLACK_FLOW_ENTER_DATA_URL_STEP_SUBMIT: string;
	readonly INTEGRATION_SLACK_FLOW_LINKING_STEP_TEXT: string;
	readonly INTEGRATION_SLACK_FLOW_SUCCESS_STEP_TEXT: string;

	readonly INTEGRATION_JIRA_CLOUD_FLOW_TITLE: string;
	readonly INTEGRATION_JIRA_ON_PREMISE_FLOW_TITLE: string;
	readonly INTEGRATION_JIRA_FLOW_ENTER_INSTANCE_STEP_TEXT: string;
	readonly INTEGRATION_JIRA_FLOW_ENTER_INSTANCE_STEP_NOTE: string;
	readonly INTEGRATION_JIRA_FLOW_ENTER_INSTANCE_STEP_NOTE_TEXT: string;
	readonly INTEGRATION_JIRA_FLOW_ENTER_INSTANCE_STEP_PLACEHOLDER: string;
	readonly INTEGRATION_JIRA_FLOW_ENTER_INSTANCE_STEP_VALIDATION: string;
	readonly INTEGRATION_JIRA_FLOW_ENTER_INSTANCE_STEP_SUBMIT: string;
	readonly INTEGRATION_JIRA_FLOW_ENTER_INSTANCE_STEP_SUBMIT_LOADING: string;

	readonly INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_TEXT: string;
	readonly INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_1: string;
	readonly INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_OPEN_LINKS_BUTTON: string;
	readonly INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_2: string;
	readonly INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_3: string;
	readonly INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4: string;
	readonly INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_A: string;
	readonly INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_B: string;
	readonly INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_C: string;
	readonly INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_D: string;
	readonly INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_E: string;
	readonly INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_F: string;
	readonly INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_G: string;
	readonly INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_H: string;
	readonly INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_I: string;
	readonly INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_J: string;
	readonly INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_5: string;
	readonly INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_5_A: string;
	readonly INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_5_B: string;
	readonly INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_5_C: string;
	readonly INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_5_D: string;
	readonly INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SUBMIT: string;
	readonly INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_COPY_BUTTON: string;
	readonly INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_COPY_BUTTON_COPIED: string;

	readonly INTEGRATION_JIRA_FLOW_VERIFY_STEP_TEXT: string;
	readonly INTEGRATION_JIRA_FLOW_VERIFY_STEP_VALIDATION_URL: string;
	readonly INTEGRATION_JIRA_FLOW_VERIFY_STEP_OPEN_IN_BROWSER: string;
	readonly INTEGRATION_JIRA_FLOW_VERIFY_STEP_VERIFICATION_CODE: string;
	readonly INTEGRATION_JIRA_FLOW_VERIFY_STEP_VERIFICATION_CODE_PLACEHOLDER: string;
	readonly INTEGRATION_JIRA_FLOW_VERIFY_STEP_VERIFICATION_CODE_VALIDATION: string;
	readonly INTEGRATION_JIRA_FLOW_VERIFY_STEP_SUBMIT: string;
	readonly INTEGRATION_JIRA_FLOW_CREATING_AUTH_URL_STEP_TEXT: string;
	readonly INTEGRATION_JIRA_FLOW_LINKING_STEP_TEXT: string;
	readonly INTEGRATION_JIRA_FLOW_SUCCESS_STEP_TEXT: string;

	readonly MY_TEAM: string;

	readonly MY_TEAM_TASKS_TABLE_COLUMN_TYPE: string;
	readonly MY_TEAM_TASKS_TABLE_COLUMN_TASK_KEY: string;
	readonly MY_TEAM_TASKS_TABLE_COLUMN_TITLE: string;
	readonly MY_TEAM_TASKS_TABLE_COLUMN_ASSIGNEE: string;
	readonly MY_TEAM_TASKS_TABLE_COLUMN_STATUS: string;
	readonly MY_TEAM_TASKS_TABLE_COLUMN_STARTED_ON: string;
	readonly MY_TEAM_TASKS_TABLE_COLUMN_MAKER_TIME: string;

	readonly DETAILED_DAILY_REPORT_TITLE: string;
	readonly DETAILED_DAILY_REPORT_TABLE_COLUMN_DC_NAME: string;
	readonly DETAILED_DAILY_REPORT_TABLE_COLUMN_TASK_ID: string;
	readonly DETAILED_DAILY_REPORT_TABLE_COLUMN_TASK_TITLE: string;
	readonly DETAILED_DAILY_REPORT_TABLE_COLUMN_FROM: string;
	readonly DETAILED_DAILY_REPORT_TABLE_COLUMN_TO: string;

	readonly MY_TEAM_TAB_TEAM_WORK: string;
	readonly MY_TEAM_TAB_WORK_DIGEST: string;
	readonly MY_TEAM_TAB_ANALYTICS: string;
}

export function format(str: string, ...params: string[]) {
	return vsprintf(str, params);
}
