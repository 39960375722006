import { ConfigurationEntityType, CustomizableConfiguration, CustomizableConfigurationCategories, DetectedDeploymentType, SINGLE_CONFIG_KEY, SubtaskSprintStrategy, TaskEstimationMethod } from "@acumen/dashboard-common";
import classNames from "classnames";
import TriggerOnClickOutside from "../../components/ux-effects/trigger-on-click-outside";
import { TASK_ESTIMATION_METHOD_FRIENDLY_NAMES } from "../../../localization/text-records";
import { useStores } from "../../mobx-stores";
import { IConfigFields, IDashboardCustomizableValue } from "../../mobx-stores/customization-store";
import React, { useCallback, useEffect, useState } from "react";
import ConfigurationsForm from "./customization-form";

export interface IConfigurationsCard {
	teamId?: string;
	category: CustomizableConfigurationCategories;
	entityType: ConfigurationEntityType;
	configurationsAccessType?: ConfigurationsAccessType;
	toggleModuleState?: () => void;
	refreshPage?: () => void;
	iconSize?: ConfigurationsIconSize;
	showTitle?: boolean;
}

export interface IConfigurationForm extends IConfigurationsCard {
	configFields: IConfigFields;
}

export enum ConfigurationsIconSize {
	small = "small",
	medium = "medium"
}

export enum ConfigurationsAccessType {
	acumen = "acumen",
	external = "external"
}

// tslint:disable-next-line: variable-name
const CustomizeConfiguration = (props: IConfigurationsCard) => {
	const {
		customizationStore: {
			getConfigurationByCategory,
			userContexts,
		}
	} = useStores();

	const { category, teamId, entityType, refreshPage, iconSize = ConfigurationsIconSize.medium } = { ...props };
	const [configFields, setConfigFields] = useState<undefined | IConfigFields>(undefined);
	const [isModuleOpen, setIsModuleOpen] = useState<boolean>(false);

	const context = userContexts(teamId).find(ctx => ctx === entityType);
	useEffect(() => {
		if (context) {
			// tslint:disable-next-line: no-floating-promises
			getConfigurationByCategory(category, teamId).then(res => {
				setConfigFields(res);
			});
		}
		return () => setConfigFields(undefined);
	}, [teamId]);

	const toggleModuleState = useCallback(() => {
		setIsModuleOpen(!isModuleOpen);
		// tslint:disable-next-line: no-floating-promises
		getConfigurationByCategory(category, teamId).then(res => {
			setConfigFields(res);
		});
	}, [isModuleOpen, category, teamId]);

	return (context && configFields) ? (
		<>
			<i
				className={classNames("cog", "icon", "thin outline", "clickable", "padded-icon",
					{ disabled: !context },
					{ large: iconSize === ConfigurationsIconSize.medium }
				)}
				onClick={toggleModuleState}
			/>
			{(isModuleOpen && configFields) &&
				<TriggerOnClickOutside onTrigger={toggleModuleState} excludedClassName="configurations-form chip-multiselect-dropdown">
					<ConfigurationsForm
						{...props}
						configFields={configFields}
						toggleModuleState={toggleModuleState}
						refreshPage={refreshPage}
					/>
				</TriggerOnClickOutside>
			}
		</>
	) : <></>;
};
export default CustomizeConfiguration;

export const getFieldName = (configField: IDashboardCustomizableValue) => configField.configParams[SINGLE_CONFIG_KEY] ?
	configField.configParams[SINGLE_CONFIG_KEY]?.name : configField.configParams.name;

export const getFieldDescription = (configField: IDashboardCustomizableValue) =>
	configField.configParams[SINGLE_CONFIG_KEY]?.description;

export const getValueSuffix = (configField: IDashboardCustomizableValue) => {
	return FIELDS_WITH_SUFFIXES[configField.name];
};

export const getFriendlyNamesRecord = (typeName: string, groupName?: string) => {
	switch (typeName) {
		case "EstimationMethod":
			return getTaskEstimationMethodFriendlyNames;
		case "SubtaskSprintStrategy":
			return getSubtaskSprintStrategyFriendlyNames;
		case "type":
			if (groupName === CustomizableConfiguration.DeploymentDetection) {
				return getDetectedDeploymentTypeFriendlyNames;
			} else {
				return (name: string) => name;
			}
		default:
			return (name: string) => name;
	}
};

const getTaskEstimationMethodFriendlyNames = (name: string) => {
	switch (name) {
		case TaskEstimationMethod.None:
			return TASK_ESTIMATION_METHOD_FRIENDLY_NAMES[TaskEstimationMethod.None];
		case TaskEstimationMethod.StoryPoints:
			return TASK_ESTIMATION_METHOD_FRIENDLY_NAMES[TaskEstimationMethod.StoryPoints];
		case TaskEstimationMethod.TShirtSize:
			return TASK_ESTIMATION_METHOD_FRIENDLY_NAMES[TaskEstimationMethod.TShirtSize];
		case TaskEstimationMethod.TimeBased:
			return TASK_ESTIMATION_METHOD_FRIENDLY_NAMES[TaskEstimationMethod.TimeBased];
	}
};

const getSubtaskSprintStrategyFriendlyNames = (name: string) => {
	switch (name) {
		case SubtaskSprintStrategy.IGNORE_NONE:
			return "sum of both parent and subtasks";
		case SubtaskSprintStrategy.IGNORE_PARENTS:
			return "use subtask estimate";
		case SubtaskSprintStrategy.IGNORE_SUBTASKS:
			return "use parent estimate";
	}
};

const getDetectedDeploymentTypeFriendlyNames = (name: string) => {
	switch (name) {
		case DetectedDeploymentType.COMMIT_ON_BRANCH:
			return "Commit on branch";
		case DetectedDeploymentType.PR_TO_BRANCH:
			return "Pull request to branch";
	}
};

const FIELDS_WITH_SUFFIXES: Record<string, string> = {
	durationMs: "days",
	[CustomizableConfiguration.PlannedGracePeriod]: "hours",
	[CustomizableConfiguration.PrSizeToMergeTimeFastThreshold]: "minutes",
	[CustomizableConfiguration.PrSizeToMergeTimeSlowThreshold]: "days",
};
