import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";
import { useStores } from "../../../../mobx-stores";
import LoadingIndicator from "../../../../components/loader";
import { MetricInterval, IDashboardAcumenMetricDataResponse, IDashboardSprint, metricStringDateToUnixTime, AcumenTaskType } from "@acumen/dashboard-common";
import { IWorkClassificationData } from "../../../../mobx-stores/metric-store";
import colorScheme from "./chart-color-scheme";
import {
	ChartSeriesData, isSameIntervalCategory, isValidChartData, metricKeyToSprintCategory,
	packSprintByIdIfNeeded, sortSprintIdsTuple, SprintByIdMap
} from "./charts";
import _ from "lodash";
import { IChartProps } from "../../../../components/chart-card";
import { v4 as generateUUID } from "uuid";
import { round } from "@acumen/common";

interface IWorkClassificationProps {
	isFullScreen?: boolean;
	setDisableFullScreen?: (disable: boolean) => void;
	filters: {
		teamId?: string;
		dataContributorIds?: string[];
		startTime?: Date;
		endTime?: Date;
		interval?: MetricInterval;
		projectIds?: string[];
		boardIds?: string[];
		gitRepositoryIds?: string[];
		baseIsDefaultBranch?: boolean;
		excludeDraft?: boolean;
		customerSprints?: IDashboardSprint[];
		issueTypes?: AcumenTaskType[];
		timezone: string;
	};
}

export const WORK_CLASSIFICATION_DATA = {
	title: "Team work classification",
	description: `Shows the amount of maker time worked on by the team, percentage-wise, split into four work categories.
	External work refers to work done on external tasks (projects, components, epics etc.) while internal describes the opposite.`,
	titleSuffixByFilters: (filters: IChartProps) => {
		return filters.teamId
			? "(External vs Internal)"
			: "";
	}
};

function extractData(values: { [ts: string]: number | null; }): Array<[string, number]> {
	return Object.keys(values).map(valueKey => {
		const secondsInDate = values[valueKey];
		return [valueKey, secondsInDate || 0];
	});
}

function sortSeriesData(data: Array<[string, number]>): Array<[string, number]> {
	return _.sortBy(data);
}

function mergeDataAddition(data: Array<[string, number]>): Array<[string, number]> {
	const merged: { [key: string]: number } = {};
	data.forEach((dataSlot) => {
		const key = dataSlot[0];
		const value: number = dataSlot[1];
		const currentValue = merged[key] || 0;
		merged[key] = currentValue + value;
	});

	return Object.keys(merged).map((key) => [key, merged[key]]);
}

function extractTeamIdMetricData(metricResponse: IDashboardAcumenMetricDataResponse, teamId: string) {
	const values = metricResponse.values[teamId];
	return (values ? extractData(values) : []);
}

function extractNonTeamIdMetricData(metricResponse: IDashboardAcumenMetricDataResponse, teamId?: string) {
	const data: Array<[string, number]> = [];
	Object.keys(metricResponse.values).forEach(key => {
		if (teamId && teamId === key) {
			return;
		}

		const values = metricResponse.values[key];
		const groupData = extractData(values);
		data.push(...groupData);
	});
	return data;
}

function convertSeriesToLocal(seriesData: Array<[string, number]>, timezone: string, sprintById?: SprintByIdMap): ChartSeriesData {
	let sortedSeriesData = seriesData;
	if (sprintById) {
		sortedSeriesData = sortSprintIdsTuple(seriesData, sprintById);
	}
	return sortedSeriesData.map((dataSlot) => {
		const key = dataSlot[0];
		const value: number = dataSlot[1];
		let category: string | number | undefined;
		if (sprintById) {
			category = metricKeyToSprintCategory(key, sprintById);
		} else {
			category = metricStringDateToUnixTime(key, timezone);
		}
		return [category, value];
	});
}

function prepareExternalVsInternal(issuesWorkIntervalSumMetric: IDashboardAcumenMetricDataResponse,
	commitWorkIntervalSum: IDashboardAcumenMetricDataResponse, pullRequestWorkIntervalSum: IDashboardAcumenMetricDataResponse,
	teamId: string, timezone: string, sprintById?: SprintByIdMap): Highcharts.SeriesColumnOptions[] {

	const reviewWorkInternalData =
		sortSeriesData(mergeDataAddition(extractTeamIdMetricData(pullRequestWorkIntervalSum, teamId)));
	const reviewWorkExternalData =
		sortSeriesData(mergeDataAddition(extractNonTeamIdMetricData(pullRequestWorkIntervalSum, teamId)));

	const devWorkCommitInternalData = extractTeamIdMetricData(commitWorkIntervalSum, teamId);
	const devWorkCommitExternalData = extractNonTeamIdMetricData(commitWorkIntervalSum, teamId);
	const devWorkTaskInternalData = extractTeamIdMetricData(issuesWorkIntervalSumMetric, teamId);
	const devWorkTaskExternalData = extractNonTeamIdMetricData(issuesWorkIntervalSumMetric, teamId);
	const devWorkInternalData = sortSeriesData(mergeDataAddition([
		...devWorkCommitInternalData,
		...devWorkTaskInternalData
	]));
	const devWorkExternalData = sortSeriesData(mergeDataAddition([
		...devWorkCommitExternalData,
		...devWorkTaskExternalData
	]));

	const categorySeries: Highcharts.SeriesColumnOptions[] = [
		{
			id: generateUUID(),
			name: "External Dev Work",
			visible: true,
			data: convertSeriesToLocal(devWorkExternalData, timezone, sprintById),
			type: "column",
			stack: "effort",
			color: colorScheme.commitLight
		},
		{
			id: generateUUID(),
			name: "External Review Work",
			visible: true,
			data: convertSeriesToLocal(reviewWorkExternalData, timezone, sprintById),
			type: "column",
			stack: "effort",
			color: colorScheme.prLight
		},
		{
			id: generateUUID(),
			name: "Internal Dev Work",
			visible: true,
			data: convertSeriesToLocal(devWorkInternalData, timezone, sprintById),
			type: "column",
			stack: "effort",
			color: colorScheme.commit
		},
		{
			id: generateUUID(),
			name: "Internal Review Work",
			visible: true,
			data: convertSeriesToLocal(reviewWorkInternalData, timezone, sprintById),
			type: "column",
			stack: "effort",
			color: colorScheme.pr
		}
	];

	return categorySeries;
}

function prepareReviewVsDev(issuesWorkIntervalSumMetric: IDashboardAcumenMetricDataResponse,
	commitWorkIntervalSum: IDashboardAcumenMetricDataResponse,
	pullRequestWorkIntervalSum: IDashboardAcumenMetricDataResponse, timezone: string, sprintById?: SprintByIdMap):
	Highcharts.SeriesColumnOptions[] {

	const reviewWorkExternalData = sortSeriesData(
		mergeDataAddition(extractNonTeamIdMetricData(pullRequestWorkIntervalSum))
	);
	const devWorkCommitExternalData = extractNonTeamIdMetricData(commitWorkIntervalSum);
	const devWorkTaskExternalData = extractNonTeamIdMetricData(issuesWorkIntervalSumMetric);
	const devWorkData = sortSeriesData(mergeDataAddition([...devWorkCommitExternalData, ...devWorkTaskExternalData]));
	const categorySeries: Highcharts.SeriesColumnOptions[] = [
		{
			id: generateUUID(),
			name: "Dev work",
			type: "column",
			stack: "effort",
			visible: true,
			data: convertSeriesToLocal(devWorkData, timezone, sprintById),
			color: colorScheme.commit
		},
		{
			id: generateUUID(),
			name: "Review work",
			visible: true,
			data: convertSeriesToLocal(reviewWorkExternalData, timezone, sprintById),
			type: "column",
			stack: "effort",
			color: colorScheme.pr
		},
	];

	return categorySeries;
}

function WorkClassificationChart(props: IWorkClassificationProps) {
	const { metricStore } = useStores();
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const { fetchData } = metricStore.workClassificationData();
	const { workClassificationDataStored } = metricStore;
	const [chartData, setChartData] = useState<IWorkClassificationData | undefined>(workClassificationDataStored);

	useEffect(() => {
		if (props.isFullScreen) {
			return;
		}
		if (Object.keys(props.filters).length === 0) {
			return;
		}

		let isMounted = true;
		async function fetch() {
			setIsFetching(true);
			if (props.setDisableFullScreen) {
				props.setDisableFullScreen(true);
			}
			const data = await fetchData(props.filters.teamId,
				props.filters.dataContributorIds,
				props.filters.startTime,
				props.filters.endTime,
				props.filters.projectIds,
				props.filters.interval,
				props.filters.timezone,
				props.filters.gitRepositoryIds,
				props.filters.baseIsDefaultBranch,
				props.filters.excludeDraft,
				props.filters.boardIds,
				props.filters.issueTypes
			);

			if (isMounted && data) {
				setIsFetching(false);
				setChartData(data);
			}
			if (props.setDisableFullScreen) {
				props.setDisableFullScreen(false);
			}
		}

		// tslint:disable-next-line: no-floating-promises
		fetch().then(() => {
			isMounted = false;
		});
	}, [props.filters]);

	let series: Highcharts.SeriesColumnOptions[] | undefined;
	if (isValidChartData<IWorkClassificationData>(chartData) &&
		isSameIntervalCategory(chartData.issuesWorkIntervalSum.interval, props.filters.interval)) {
		const sprintById = packSprintByIdIfNeeded(props.filters.interval, props.filters.customerSprints);
		if (props.filters.teamId) {
			series = prepareExternalVsInternal(chartData.issuesWorkIntervalSum,
				chartData.commitWorkIntervalSum, chartData.pullRequestWorkIntervalSum, props.filters.teamId, props.filters.timezone, sprintById);
		} else {
			series = prepareReviewVsDev(chartData.issuesWorkIntervalSum,
				chartData.commitWorkIntervalSum, chartData.pullRequestWorkIntervalSum, props.filters.timezone, sprintById);
		}
	} else {
		series = [
			{
				id: generateUUID(),
				data: [],
				name: "Work Classification",
				visible: false,
				type: "column",
				stack: "effort",
			}
		];
	}

	const options: Highcharts.Options = {
		chart: {
			type: "column",
			zoomType: "xy"
		},
		legend: {
			alignColumns: false
		},
		title: undefined,
		xAxis: {
			gridLineWidth: 1,
			type: (props.filters.interval === MetricInterval.SPRINT_DATE ? "category" : "datetime"),
			uniqueNames: false
		},
		yAxis: {
			min: 0,
			title: {
				text: undefined
			},
			labels: {
				// tslint:disable-next-line: object-literal-shorthand
				formatter: function _formatter() {
					return `${this.value}%`;
				}
			}
		},
		tooltip: {
			useHTML: true,
			pointFormatter: function _pointFormatter() {
				const hours = this.y ? Math.ceil(this.y / 3600) : 0;
				const workPrcnt = this.percentage ? round(this.percentage, 0) : 0;
				return `<span style="color:${this.color}">\u25CF </span><strong>${this.series.name}</strong><br/><strong>${workPrcnt}%</strong><br/><strong>${hours} hours</strong>`;
			},
			split: true
		},
		plotOptions: {
			column: {
				pointPadding: 0.2,
				borderWidth: 0,
				stacking: "percent",
				dataLabels: {
					enabled: true,
					// tslint:disable-next-line: object-literal-shorthand
					formatter: function _formatter() {
						if (!this.y || !this.total || this.y === 0 || this.total === 0) {
							return "0%";
						}
						return `${round(100 * this.y / this.total, 0)}%`;
					},
				},
			},
			series: {
				dataLabels: {
					enabled: true,
					// tslint:disable-next-line: object-literal-shorthand
					formatter: function _formatter() {
						if (!this.y) {
							return "0%";
						}
						return `${round(this.y)}%`;
					}
				}
			},
		},
		credits: {
			enabled: false
		},
		series
	};

	return (
		<div className="description">
			<LoadingIndicator local={true} isActive={!isValidChartData(chartData) || isFetching}>
				<HighchartsReact
					highcharts={Highcharts}
					options={options}
				/>
			</LoadingIndicator>
		</div>
	);
}

export default WorkClassificationChart;
