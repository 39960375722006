import React, { useMemo } from "react";
import { Formatters } from "../../../components/dashboard-task/formatters";
import { formatTotalCycleTime } from "./utils";
import { DashboardRetroReportTaskData, TaskEstimationMethod, SubtaskSprintStrategy } from "@acumen/dashboard-common";
import _ from "lodash";
import { mapEstimationMethodToUnit } from "../../../mobx-stores/sprint-retro-store";

interface TasksTableFooterProps {
	tasks: DashboardRetroReportTaskData[];
	getCellWidthAt: (index: number) => number;
	estimationMethod?: TaskEstimationMethod;
	subtaskStrategy?: SubtaskSprintStrategy;
}

export const TasksTableFooter = (props: TasksTableFooterProps) => {
	const { tasks, getCellWidthAt, estimationMethod, subtaskStrategy } = props;
	const shouldDisplaySubtaskStrategyNote = !!subtaskStrategy && subtaskStrategy !== SubtaskSprintStrategy.IGNORE_NONE;
	const totalEstimation = useMemo(() => _.sum(tasks.map(task => task.estimationByStrategy)), [tasks]);
	const totalActualCycleTime = useMemo(() => _.sum(tasks.map(task => task.cycleTimeByStrategy)), [tasks]);
	const tooltipSuffix = mapEstimationMethodToUnit(estimationMethod);

	return (
		<div className="tasks-table-footer-container">
			<div className="tasks-table-footer">
				<div className="tasks-table-footer-cell strong" style={{ width: getCellWidthAt(0) + getCellWidthAt(1) + getCellWidthAt(2) }}>
					Total
				</div>
				<div className="tasks-table-footer-cell" style={{ width: getCellWidthAt(3) }}>{tasks.length} Tasks</div>
				<div className="tasks-table-footer-cell" style={{ width: getCellWidthAt(4) }}/>
				<div className="tasks-table-footer-cell" style={{ width: getCellWidthAt(5) }}>
					{`${Formatters.estimationValue(totalEstimation, estimationMethod, "N/A", true, false)}${tooltipSuffix}`}
				</div>
				<div className="tasks-table-footer-cell" style={{ width: getCellWidthAt(6)  }}>
					{formatTotalCycleTime(totalActualCycleTime)}
				</div>
				<div className="tasks-table-footer-notice" style={{ width: getCellWidthAt(7) + getCellWidthAt(8) + getCellWidthAt(9) }}>
					{shouldDisplaySubtaskStrategyNote ?
						`* Showing tasks based on ${subtaskStrategy === SubtaskSprintStrategy.IGNORE_PARENTS ? "subtask" : "parent"} estimate` : ``}
				</div>
			</div>
		</div>
	);
};
