import React, { FormEvent } from "react";
import IntegrationFlowBase, { IProps as IIntegrationFlowBaseProps } from "./integration-flow-base";
import { Loader, Transition } from "semantic-ui-react";
import IntegrationProviders from "../integration-providers";
import { Button, Carousel, Col, Container, Form, Image } from "react-bootstrap";
import { STRINGS } from "../../../../../../../localization";
import { IIntegrationsStore, JiraIntegrationType } from "../../../../../../mobx-stores/old-integrations-store";
import { observer } from "mobx-react";
import { DashboardIntegrationType } from "@acumen/dashboard-common";
export { JiraIntegrationType };

export interface IProps extends IIntegrationFlowBaseProps {
	oldIntegrationsStore: IIntegrationsStore;
	jiraIntegrationType: JiraIntegrationType;
}

enum JiraIntegrationFlowSteps {
	EnterInstanceUrl,
	InstanceConfiguration,
	PendingCreateAuthorizationUrlStep,
	VerifyAcumenAppInJiraStep,
	PendingLinkingStep,
	SuccessLinkingStep
}

@observer
export default class JiraIntegrationFlow extends IntegrationFlowBase<IProps> {
	private _timeout: number = 0;

	protected initialState = {
		internalStep: JiraIntegrationFlowSteps.EnterInstanceUrl,
		formValidated: false,
		inputInstanceUrl: "",
		shakeButton: false,
		isGettingKeyPair: false,
		copiedIndex: undefined,
		inputVerificationCode: undefined
	};

	public state = {...this.initialState};

	protected initFlow(): void {
		if (this.props.jiraIntegrationType === JiraIntegrationType.Cloud) {
			this.props.onInit(STRINGS.INTEGRATION_JIRA_CLOUD_FLOW_TITLE);
		} else if (this.props.jiraIntegrationType === JiraIntegrationType.Server) {
			this.props.onInit(STRINGS.INTEGRATION_JIRA_ON_PREMISE_FLOW_TITLE);
		}
	}

	public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {
		const { integrationId, jiraAuthorizationUrl, jiraVerificationSuccess } = this.props;

		if (integrationId && integrationId !== prevProps.integrationId) {
			this.setState({internalStep: JiraIntegrationFlowSteps.InstanceConfiguration});
		}

		if (jiraAuthorizationUrl && jiraAuthorizationUrl !== prevProps.jiraAuthorizationUrl) {
			this.setState({internalStep: JiraIntegrationFlowSteps.VerifyAcumenAppInJiraStep});
		}

		if (jiraVerificationSuccess && jiraVerificationSuccess !== prevProps.jiraVerificationSuccess) {
			this.setState({internalStep: JiraIntegrationFlowSteps.SuccessLinkingStep});
		}
	}

	private validateForm(e: FormEvent<HTMLFormElement>): boolean {
		const form: HTMLFormElement = e.currentTarget;

		if (!form.checkValidity()) {
			e.preventDefault();
			e.stopPropagation();
			this.setState({formValidated: true, shakeButton: true});
			return false;
		}

		return true;
	}

	private onCopyClick(copiedIndex: number, value: string) {
		this.setState({copiedIndex});
		clearTimeout(this._timeout);
		this._timeout = window.setTimeout(() => this.setState({copied: undefined}), 60 * 1000);

		const el: HTMLTextAreaElement = document.createElement("textarea");

		el.value = value;
		el.setAttribute("readonly", "");
		el.style.position = "absolute";
		el.style.left = "-9999px";
		document.body.appendChild(el);
		el.select();
		document.execCommand("copy");
		document.body.removeChild(el);
	}

	private onInstanceUrlChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		this.setState({inputInstanceUrl: e.target.value});
	}

	private onInstanceUrlSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!this.validateForm(e)) { return; }

		const { oldIntegrationsStore } = this.props;
		const { inputInstanceUrl } = this.state;
		const jiraHostUrl = inputInstanceUrl.replace(/\/$/, "");

		this.setState({isGettingKeyPair: true});

		await oldIntegrationsStore.initializeJiraInstanceUrl({ jiraHostUrl }, this.props.jiraIntegrationType);

		const { integrationWizard } = oldIntegrationsStore;
		const { integrationId } = integrationWizard;

		if (integrationId) {
			this.setState({internalStep: JiraIntegrationFlowSteps.InstanceConfiguration});
		}
	}

	private onInstanceConfigurationSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const { oldIntegrationsStore } = this.props;
		this.setState({internalStep: JiraIntegrationFlowSteps.PendingCreateAuthorizationUrlStep});

		await oldIntegrationsStore.fetchJiraAuthorizationUrl({
			integrationId: oldIntegrationsStore.integrationWizard.integrationId
		}, this.props.jiraIntegrationType);

		if (oldIntegrationsStore.integrationWizard.jiraAuthorizationUrl) {
			this.setState({internalStep: JiraIntegrationFlowSteps.VerifyAcumenAppInJiraStep});
		}
	}

	private onVerificationCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({inputVerificationCode: e.target.value});
	}

	private onVerificationCodeSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!this.validateForm(e)) { return; }

		const { oldIntegrationsStore } = this.props;
		const { inputVerificationCode } = this.state;

		this.setState({internalStep: JiraIntegrationFlowSteps.PendingLinkingStep});

		await oldIntegrationsStore.verifyJiraInstance({
			integrationId: oldIntegrationsStore.integrationWizard.integrationId,
			jiraVerificationCode: inputVerificationCode
		}, this.props.jiraIntegrationType);

		if (oldIntegrationsStore.integrationWizard.jiraAuthorizationUrl) {
			this.setState({internalStep: JiraIntegrationFlowSteps.SuccessLinkingStep});
		}
	}

	private renderEnterInstanceUrlStep() {
		const { formValidated, shakeButton, isGettingKeyPair, inputInstanceUrl } = this.state;

		return (
			<>
				<p>
					{STRINGS.INTEGRATION_JIRA_FLOW_ENTER_INSTANCE_STEP_TEXT}
				</p>
				<br/>
				<p>
					<b><u>{STRINGS.INTEGRATION_JIRA_FLOW_ENTER_INSTANCE_STEP_NOTE}</u></b>
					{" "}{STRINGS.INTEGRATION_JIRA_FLOW_ENTER_INSTANCE_STEP_NOTE_TEXT}
				</p>
				<br/>
				<Form
					noValidate={true}
					validated={formValidated}
					onSubmit={this.onInstanceUrlSubmit}
				>
					<Form.Row>
						<Form.Group as={Col} md="4">
							<Form.Control
								className="integration-flow-step-text-input"
								type="url"
								placeholder={STRINGS.INTEGRATION_JIRA_FLOW_ENTER_INSTANCE_STEP_PLACEHOLDER}
								required={true}
								onChange={this.onInstanceUrlChange}
								autoComplete="off"
								defaultValue={inputInstanceUrl}
							/>
							<Form.Control.Feedback type="invalid">
								{STRINGS.INTEGRATION_JIRA_FLOW_ENTER_INSTANCE_STEP_VALIDATION}
							</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>
					<br/>
					<Form.Row>
						<Transition
							visible={shakeButton}
							animation="shake"
							duration={800}
							onComplete={() => this.setState({shakeButton: false})}
							mountOnShow={false}
						>
							<Button
								className="btn-rounded install-button"
								size="lg"
								type="submit"
								disabled={isGettingKeyPair}
							>
								{isGettingKeyPair ? (
									<>
										<Loader active={true} inline={true} inverted={true} size="small" />
										{STRINGS.INTEGRATION_JIRA_FLOW_ENTER_INSTANCE_STEP_SUBMIT_LOADING}
									</>
								) : (
									<>{STRINGS.INTEGRATION_JIRA_FLOW_ENTER_INSTANCE_STEP_SUBMIT}</>
								)}
							</Button>
						</Transition>
					</Form.Row>
				</Form>
			</>
		);
	}

	private renderInstanceConfigurationStep() {
		const { formValidated } = this.state;
		const { oldIntegrationsStore } = this.props;
		const { integrationWizard } = oldIntegrationsStore;
		const { guide, publicKey } = integrationWizard;

		if (!guide || !publicKey) { return; }

		return (
			<>
				<p>
					{STRINGS.INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_TEXT}
				</p>
				<br/>
				<Form
					noValidate={true}
					validated={formValidated}
					onSubmit={this.onInstanceConfigurationSubmit}
					className="jira-form"
				>
					<Form.Row>
						<Form.Group as={Col} md="12">
							<Form.Label>
								{STRINGS.INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_1}
							</Form.Label>
							<br/>
							<div className="indent-block">
								<a href={guide.appLinkInternalUrl} target="_blank" rel="noopener noreferrer">
									<Button variant="outline-primary" size="sm">
										<i className="feather icon-external-link" />
										{STRINGS.INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_OPEN_LINKS_BUTTON}
									</Button>
								</a>
							</div>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="12">
							<Form.Label>
								{STRINGS.INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_2}
							</Form.Label>
							<br/>
							{this.renderCopyableTextInput(1, guide.consumerName)}
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="12">
							<Form.Label>
								{STRINGS.INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_3}
							</Form.Label>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="12">
							<Form.Label>
								{STRINGS.INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4}
							</Form.Label>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="12">
							<div className="indent-block">
								<Form.Label>
									{STRINGS.INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_A}
								</Form.Label>
								<br/>
								{this.renderCopyableTextInput(2, guide.applicationName)}
							</div>
						</Form.Group>
					</Form.Row>

					<div className="indent-block">
						<Form.Label>
							{STRINGS.INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_B}
						</Form.Label>
					</div>

					<Form.Row>
						<Form.Group as={Col} md="12">
							<div className="indent-block">
								<Form.Label>
									{STRINGS.INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_C}
								</Form.Label>
								<br/>
								{this.renderCopyableTextInput(3, guide.serviceProviderName)}
							</div>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="12">
							<div className="indent-block">
								<Form.Label>
									{STRINGS.INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_D}
								</Form.Label>
								<br/>
								{this.renderCopyableTextInput(4, guide.consumerKey)}
							</div>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="12">
							<div className="indent-block">
								<Form.Label>
									{STRINGS.INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_E}
								</Form.Label>
								<br/>
								{this.renderCopyableTextInput(5, guide.sharedSecret)}
							</div>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="12">
							<div className="indent-block">
								<Form.Label>
									{STRINGS.INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_F}
								</Form.Label>
								<br/>
								{this.renderCopyableTextInput(6, guide.requestTokenUrl)}
							</div>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="12">
							<div className="indent-block">
								<Form.Label>
									{STRINGS.INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_G}
								</Form.Label>
								<br/>
								{this.renderCopyableTextInput(7, guide.accessTokenUrl)}
							</div>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="12">
							<div className="indent-block">
								<Form.Label>
									{STRINGS.INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_H}
								</Form.Label>
								<br/>
								{this.renderCopyableTextInput(8, guide.requestTokenUrl)}
							</div>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="12">
							<div className="indent-block">
								<Form.Label>
									{STRINGS.INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_I}
								</Form.Label>
								<br/>
								<Form.Label>
									{STRINGS.INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_4_J}
								</Form.Label>
							</div>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="12">
							<Form.Label>
								{STRINGS.INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_5}
							</Form.Label>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="12">
							<div className="indent-block">
								<Form.Label>
									{STRINGS.INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_5_A}
								</Form.Label>
								<br/>
								{this.renderCopyableTextInput(9, guide.consumerKey)}
							</div>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="12">
							<div className="indent-block">
								<Form.Label>
									{STRINGS.INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_5_B}
								</Form.Label>
								<br/>
								{this.renderCopyableTextInput(10, guide.consumerName)}
							</div>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="12">
							<div className="indent-block">
								<Form.Label>
									{STRINGS.INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_5_C}
								</Form.Label>
								<br/>
								{this.renderCopyableTextInput(11, publicKey, true)}
							</div>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="12">
							<div className="indent-block">
								<Form.Label>
									{STRINGS.INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SECTION_5_D}
								</Form.Label>
							</div>
						</Form.Group>
					</Form.Row>

					<br/>
					<Form.Row className="align-center">
						<Button className="btn-rounded install-button" size="lg" type="submit">
							{STRINGS.INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_SUBMIT}
						</Button>
					</Form.Row>
				</Form>
			</>
		);
	}

	private renderCopyableTextInput(index: number, inputValue: string, renderAsTextArea: boolean = false) {
		const { copiedIndex } = this.state;

		return (
			<div className="indent-block">
				<Form.Control
					className="jira-spec-text-input"
					type="text"
					as={renderAsTextArea ? "textarea" : "input"}
					size="sm"
					disabled={true}
					defaultValue={inputValue}
				/>
				<Button
					size="sm"
					variant={copiedIndex === index ? "primary" : "outline-primary"}
					onClick={() => this.onCopyClick(index, inputValue)}
				>
					<i className="feather icon-paperclip" />
					{copiedIndex !== index ?
						STRINGS.INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_COPY_BUTTON
						:
						STRINGS.INTEGRATION_JIRA_FLOW_CONFIGURATION_STEP_COPY_BUTTON_COPIED
					}
				</Button>
			</div>
		);
	}

	private renderVerifyAcumenAppInJiraStep() {
		const { formValidated, shakeButton } = this.state;
		const { oldIntegrationsStore } = this.props;
		const { integrationWizard } = oldIntegrationsStore;

		return (
			<>
				<p>
					{STRINGS.INTEGRATION_JIRA_FLOW_VERIFY_STEP_TEXT}
				</p>
				<br/>
				<Form
					noValidate={true}
					validated={formValidated}
					onSubmit={this.onVerificationCodeSubmit}
				>
					<Form.Row>
						<Form.Group as={Col} md="12">
							<Form.Label>
								{STRINGS.INTEGRATION_JIRA_FLOW_VERIFY_STEP_VALIDATION_URL}
							</Form.Label>
							<Form.Control
								className="jira-spec-text-input"
								type="text"
								size="sm"
								disabled={true}
								defaultValue={integrationWizard.jiraAuthorizationUrl}
							/>
							<br/>
							<a href={integrationWizard.jiraAuthorizationUrl} target="_blank" rel="noopener noreferrer">
								<Button
									size="sm"
									variant="outline-primary"
								>
									<i className="feather icon-external-link" />
									{STRINGS.INTEGRATION_JIRA_FLOW_VERIFY_STEP_OPEN_IN_BROWSER}
								</Button>
							</a>
						</Form.Group>
					</Form.Row>
					<br/><br/>
					<Form.Row className="align-center">
						<Form.Group as={Col} md="4">
							<Form.Label>
								{STRINGS.INTEGRATION_JIRA_FLOW_VERIFY_STEP_VERIFICATION_CODE}
							</Form.Label>
							<Form.Control
								className="integration-flow-step-text-input"
								type="text"
								placeholder={STRINGS.INTEGRATION_JIRA_FLOW_VERIFY_STEP_VERIFICATION_CODE_PLACEHOLDER}
								required={true}
								onChange={this.onVerificationCodeChange}
								autoComplete="off"
							/>
							<Form.Control.Feedback type="invalid">
								{STRINGS.INTEGRATION_JIRA_FLOW_VERIFY_STEP_VERIFICATION_CODE_VALIDATION}
							</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>
					<br/><br/>
					<Form.Row className="align-center">
						<Transition
							visible={shakeButton}
							animation="shake"
							duration={800}
							onComplete={() => this.setState({shakeButton: false})}
							mountOnShow={false}
						>
							<Button className="btn-rounded install-button" size="lg" type="submit">
								{STRINGS.INTEGRATION_JIRA_FLOW_VERIFY_STEP_SUBMIT}
							</Button>
						</Transition>
					</Form.Row>
				</Form>
			</>
		);
	}

	public render() {
		const { internalStep } = this.state;
		const provider = IntegrationProviders.find(p => p.integrationType === DashboardIntegrationType.JIRACloud || p.integrationType === DashboardIntegrationType.JIRAServer);

		if (!provider) { return null; }

		return (
			<Container className="integration-flow-step-root">
				<div className="integration-flow-step-logo-header">
					<Image src={provider.logo} alt={provider.title} />
					<hr/>
				</div>
				<div>
					<Carousel activeIndex={internalStep} controls={false} indicators={false} onSelect={(() => false)}>
						<Carousel.Item>
							{this.renderEnterInstanceUrlStep()}
						</Carousel.Item>
						<Carousel.Item>
							{this.renderInstanceConfigurationStep()}
						</Carousel.Item>
						<Carousel.Item>
							{this.renderPendingStep(STRINGS.INTEGRATION_JIRA_FLOW_CREATING_AUTH_URL_STEP_TEXT)}
						</Carousel.Item>
						<Carousel.Item>
							{this.renderVerifyAcumenAppInJiraStep()}
						</Carousel.Item>
						<Carousel.Item>
							{this.renderPendingStep(STRINGS.INTEGRATION_JIRA_FLOW_LINKING_STEP_TEXT)}
						</Carousel.Item>
						<Carousel.Item>
							{this.renderSuccessStep(STRINGS.INTEGRATION_JIRA_FLOW_SUCCESS_STEP_TEXT)}
						</Carousel.Item>
					</Carousel>
				</div>
				<br/>
			</Container>
		);
	}
}
