import { observer } from "mobx-react";
import { useStores } from "../../../../mobx-stores";
import { integrationTypesInfo, StepsActionType } from "../../../../mobx-stores/new-integrations-store";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import IntegrationFlowStep from "./integration-flow-step";

interface ISetupAuthorization {
	integrationId: string;
	postInstallLink?: string;
}

// tslint:disable-next-line: variable-name
const SetupIntegration = () => {
	const [currentStep, setCurrentStep] = useState<number | undefined>(undefined);
	const [authorization, setAuthorization] = useState<ISetupAuthorization | undefined>(undefined);
	const [message, setMessage] = useState<{ msg: string, isError: boolean } | undefined>(undefined);
	const searchParams = new URLSearchParams(useLocation().search);
	const url = useLocation().pathname.split("/");
	const code = searchParams.get("code") ?? undefined;
	const state = searchParams.get("slack-state") ?? undefined;
	const gitInstallId = searchParams.get("installation_id") ?? undefined;

	const { integrationsStore: {
		setIntegrationType,
		getCurrentIntegrationFlowByType,
		lastCreatedIntegration,
	}
	} = useStores();

	const clearState = () => {
		setMessage(undefined);
		setIntegrationType(undefined);
	};

	useEffect(() => {
		if (code || state || gitInstallId) {
			const int = Object.values(integrationTypesInfo).find(flow => flow.tokenUrl === url[url.length - 1]);
			if (!int) {
				setMessage({ msg: `Bad link`, isError: true });
				return;
			}
			setMessage({ msg: int.type.toString(), isError: false });
			setIntegrationType(int.type);
			const stepFromUrl = getCurrentIntegrationFlowByType(int?.type).steps.find(step => step.actionType === StepsActionType.GetToken);
			const stepIndex = getCurrentIntegrationFlowByType(int?.type).steps.findIndex(step => step.actionType === StepsActionType.GetToken);
			setCurrentStep(stepIndex);
			if (stepFromUrl && lastCreatedIntegration && !authorization) {
				setAuthorization({ integrationId: lastCreatedIntegration.id });
				stepFromUrl.action({
					code,
					state,
					gitInstallId,
					integrationId: lastCreatedIntegration.id
				})
					.then((res: any) => {
						if (res && res.integrationId) {
							setAuthorization({ ...res });
							res.postInstallLink ? setMessage({ msg: `Visit your new app page ${res.postInstallLink}`, isError: false }) :
								setMessage({ msg: `Done`, isError: false });
							window.close();
						} else {
							setMessage({ msg: `Couldn't validate your token`, isError: true });
						}
					});
			}
		}
	}, [lastCreatedIntegration]);
	return (
		<div>
			{ currentStep &&
				<IntegrationFlowStep
					currentStepNumber={currentStep}
					onNext={() => {
						clearState();
					}}
					message={message}
					onClose={clearState}
				/>}
		</div>
	);
};
export default observer(SetupIntegration);
