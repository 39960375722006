import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";
import { useStores } from "../../../../mobx-stores";
import LoadingIndicator from "../../../../components/loader";
import { MetricInterval, IDashboardSprint } from "@acumen/dashboard-common";
import { IIssuesCycleTimeByComponentData as IIssueCycleTimeByComponentData } from "../../../../mobx-stores/metric-store";
import {
	CategoryChartSeriesData, isSameIntervalCategory, isValidChartData, packSprintByIdIfNeeded,
	prepareGroupedCategoryHourSeriesDataByDate, prepareGroupedCategoryHourSeriesDataBySprint
} from "./charts";
import { v4 as generateUUID } from "uuid";
import { round } from "@acumen/common";
import _ from "lodash";
import { seriesByComponent } from "../../../../components/dashboard-task/task-type-series-prop";

interface IIssueCycleTimeByComponentProps {
	isFullScreen?: boolean;
	setDisableFullScreen?: (disable: boolean) => void;
	filters: {
		teamId?: string;
		dataContributorIds?: string[];
		startTime?: Date;
		endTime?: Date;
		interval?: MetricInterval;
		projectIds?: string[];
		boardIds?: string[];
		customerSprints?: IDashboardSprint[];
		timezone: string;
	};
}

export const ISSUES_CYCLE_TIME_BY_COMPONENT_DATA = {
	title: "Task cycle time by component",
	description: `Shows the amount of cycle time (how long each ticket was in progress) spent on each component.`
};

function IssueCycleTimeByComponent(props: IIssueCycleTimeByComponentProps) {
	const { metricStore, componentsStore } = useStores();
	const { componentsById } = componentsStore;
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const { fetchData } = metricStore.issuesCycleTimeByComponentData();
	const { issuesCycleTimeByComponentDataStored } = metricStore;
	const [chartData, setChartData] = useState<IIssueCycleTimeByComponentData | undefined>(issuesCycleTimeByComponentDataStored);

	useEffect(() => {
		if (props.isFullScreen) {
			return;
		}
		if (Object.keys(props.filters).length === 0) {
			return;
		}

		let isMounted = true;
		async function fetch() {
			setIsFetching(true);
			if (props.setDisableFullScreen) {
				props.setDisableFullScreen(true);
			}
			const data = await fetchData(props.filters.teamId,
				props.filters.dataContributorIds,
				props.filters.projectIds,
				props.filters.startTime,
				props.filters.endTime,
				props.filters.interval,
				props.filters.timezone,
				props.filters.boardIds);
			await componentsStore.fetchData();

			if (isMounted && data) {
				setIsFetching(false);
				setChartData(data);
			}
			if (props.setDisableFullScreen) {
				props.setDisableFullScreen(false);
			}
		}

		// tslint:disable-next-line: no-floating-promises
		fetch();
		return () => { isMounted = false; };
	}, [props.filters]);

	let series: Highcharts.SeriesColumnOptions[] | undefined;

	if (isValidChartData<IIssueCycleTimeByComponentData>(chartData) &&
		isSameIntervalCategory(chartData.issuesCycleTimeByComponent.interval, props.filters.interval)) {
		let seriesData: CategoryChartSeriesData;
		const sprintById = packSprintByIdIfNeeded(props.filters.interval, props.filters.customerSprints);

		if (sprintById) {
			seriesData = prepareGroupedCategoryHourSeriesDataBySprint(chartData.issuesCycleTimeByComponent, sprintById);
		} else {
			seriesData = prepareGroupedCategoryHourSeriesDataByDate(chartData.issuesCycleTimeByComponent, props.filters.timezone);
		}

		series = Object
			.keys(chartData.issuesCycleTimeByComponent.values)
			.map((key, idx) => {
				const displayProperties = seriesByComponent(key, componentsById, idx);
				const seriesOptions: Highcharts.SeriesColumnOptions = {
					id: generateUUID(),
					name: displayProperties.seriesName,
					index: displayProperties.sortOrder,
					legendIndex: displayProperties.sortOrder,
					color: displayProperties.seriesColorHex,
					data: seriesData[key],
					visible: (seriesData[key].length > 0 && _.findIndex(seriesData[key], i => i[1] > 0) >= 0),
					type: "column",
					stack: "issue-type"
				};
				return seriesOptions;
			});
	} else {
		series = [
			{
				id: generateUUID(),
				data: [],
				name: "Component",
				type: "column",
				visible: false,
				stack: "issue-type"
			}
		];
	}

	const options: Highcharts.Options = {
		chart: {
			type: "column",
			zoomType: "xy"
		},
		title: undefined,
		tooltip: {
			headerFormat: '<span style="color:{point.color}">\u25CF</span><b> {series.name}</b>:<br>',
			pointFormat: `{point.y:,.0f} hr<br/>`
		},
		xAxis: {
			gridLineWidth: 1,
			type: (props.filters.interval === MetricInterval.SPRINT_DATE ? "category" : "datetime"),
			uniqueNames: false
		},
		yAxis: [
			{
				min: 0,
				title: {
					text: "% Hours"
				}
			}
		],
		plotOptions: {
			column: {
				pointPadding: 0.2,
				borderWidth: 0,
				stacking: "percent",
				dataLabels: {
					enabled: true,
					// tslint:disable-next-line: object-literal-shorthand
					formatter: function _formatter() {
						if (!this.y || !this.total || this.y === 0 || this.total === 0) {
							return "0%";
						}
						return `${round(100 * this.y / this.total, 0)}%`;
					},
				},
			}
		},
		credits: {
			enabled: false
		},
		series
	};

	return (
		<div className="description">
			<LoadingIndicator local={true} isActive={!isValidChartData(chartData) || isFetching}>
				<HighchartsReact
					highcharts={Highcharts}
					options={options}
				/>
			</LoadingIndicator>
		</div>
	);
}

export default IssueCycleTimeByComponent;
