import React from "react";
import classNames from "classnames";
import "./pr-filter-item.scss";

export interface IPRFilterItemProps {
	name: string;
	value: number;
	isSelected: boolean;
	onClick: () => void;
}

export const PRFilterItem = (props: IPRFilterItemProps) => {
	const { name, value, isSelected, onClick } = props;
	return (
		<div className={classNames("pr-filter-item", { selected: isSelected })} onClick={(e) => {
			e.preventDefault();
			onClick();
		 }}>{name}
			<div className="pr-filter-item-figure">{value}</div>
		</div>
	);
};
