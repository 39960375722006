import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";
import { useStores } from "../../../../mobx-stores";
import LoadingIndicator from "../../../../components/loader";
import { MetricInterval, IDashboardAcumenMetricDataResponse, IDashboardSprint, AcumenTaskType, metricStringDateToUnixTime } from "@acumen/dashboard-common";
import { IDefectDensityData } from "../../../../mobx-stores/metric-store";
import assert from "assert";
import colorScheme from "./chart-color-scheme";
import {
	isValidChartData, ChartSeriesData, metricKeyToSprintCategory,
	packSprintByIdIfNeeded, SprintByIdMap, sortSprintIds, isSameIntervalCategory
} from "./charts";
import { v4 as generateUUID } from "uuid";
import { round } from "@acumen/common";

interface IDefectDensityProps {
	isFullScreen?: boolean;
	setDisableFullScreen?: (disable: boolean) => void;
	filters: {
		teamId?: string;
		dataContributorIds?: string[];
		startTime?: Date;
		endTime?: Date;
		interval?: MetricInterval;
		projectIds?: string[];
		boardIds?: string[];
		gitRepositoryIds?: string[];
		baseIsDefaultBranch?: boolean;
		excludeDraft?: boolean;
		customerSprints?: IDashboardSprint[];
		issueTypes?: AcumenTaskType[];
		timezone: string;
	};
}

export const DEFECT_DENSITY_DATA = {
	title: "Defect density",
	description: `Shows the ratio between the amount of bugs opened vs the amount of pull requests merged or the amount of lines of code changed.`
};

function prepareBugsToLOCSeriesData(issueBugCountMetric: IDashboardAcumenMetricDataResponse,
	locPRChangeMetric: IDashboardAcumenMetricDataResponse, timezone: string, sprintById?: SprintByIdMap): ChartSeriesData {
	const issueBugCountMetricValues = Object.values(issueBugCountMetric.values);
	assert(issueBugCountMetricValues.length === 1, "PR Count metric should have no group values");
	const locPRChangeMetricValues = Object.values(locPRChangeMetric.values);
	assert(locPRChangeMetricValues.length === 1, "LOC for PR change metric should have no group values");
	let keys = Object.keys(issueBugCountMetricValues[0]);
	if (sprintById) {
		keys = sortSprintIds(keys, sprintById);
	}
	return keys.map(valueKey => {
		let category: string | number | undefined;
		if (sprintById) {
			category = metricKeyToSprintCategory(valueKey, sprintById);
		} else {
			category = metricStringDateToUnixTime(valueKey, timezone);
		}
		const bugsCountInDate = issueBugCountMetricValues[0][valueKey];
		let bugsToLOCRatio = 0;
		if (bugsCountInDate && bugsCountInDate > 0) {
			const locPRChangeInDate = locPRChangeMetricValues[0][valueKey];
			if (locPRChangeInDate && locPRChangeInDate > 0) {
				bugsToLOCRatio = (bugsCountInDate / (locPRChangeInDate / 1000));
				bugsToLOCRatio = round(bugsToLOCRatio);
			}
		}
		return [category, bugsToLOCRatio];
	});
}

function prepareBugsToPRSeriesData(issueBugCountMetric: IDashboardAcumenMetricDataResponse,
	prCountMetric: IDashboardAcumenMetricDataResponse, timezone: string, sprintById?: SprintByIdMap): ChartSeriesData {
	const issueBugCountMetricValues = Object.values(issueBugCountMetric.values);
	assert(issueBugCountMetricValues.length === 1, "Issue count metric should have no group values");
	const prCountMetricValues = Object.values(prCountMetric.values);
	assert(prCountMetricValues.length === 1, "PR Count metric should have no group values");
	let keys = Object.keys(prCountMetricValues[0]);
	if (sprintById) {
		keys = sortSprintIds(keys, sprintById);
	}
	return keys.map(valueKey => {
		let category: string | number | undefined;
		if (sprintById) {
			category = metricKeyToSprintCategory(valueKey, sprintById);
		} else {
			category = metricStringDateToUnixTime(valueKey, timezone);
		}
		const prCount = prCountMetricValues[0][valueKey];
		let bugsToPRRatio = 0;
		if (prCount && prCount > 0) {
			const bugsCountInDate = issueBugCountMetricValues[0][valueKey];
			if (bugsCountInDate && bugsCountInDate > 0) {
				bugsToPRRatio = (bugsCountInDate / prCount);
				bugsToPRRatio = round(bugsToPRRatio);
			}
		}

		return [category, bugsToPRRatio];
	});
}

function DefectDensity(props: IDefectDensityProps) {
	const { metricStore } = useStores();
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const { fetchData } = metricStore.defectDensityData();
	const { defectDensityDataStored } = metricStore;
	const [chartData, setChartData] = useState<IDefectDensityData | undefined>(defectDensityDataStored);

	useEffect(() => {
		if (props.isFullScreen) {
			return;
		}
		if (Object.keys(props.filters).length === 0) {
			return;
		}

		let isMounted = true;
		async function fetch() {
			setIsFetching(true);
			if (props.setDisableFullScreen) {
				props.setDisableFullScreen(true);
			}
			const data = await fetchData(props.filters.teamId,
				props.filters.dataContributorIds,
				props.filters.projectIds,
				props.filters.startTime,
				props.filters.endTime,
				props.filters.interval,
				props.filters.timezone,
				props.filters.gitRepositoryIds,
				props.filters.baseIsDefaultBranch,
				props.filters.excludeDraft,
				props.filters.boardIds,
				props.filters.issueTypes
			);
			if (isMounted && data) {
				setIsFetching(false);
				setChartData(data);
			}
			if (props.setDisableFullScreen) {
				props.setDisableFullScreen(false);
			}
		}

		// tslint:disable-next-line: no-floating-promises
		fetch();
		return () => { isMounted = false; };
	}, [props.filters]);

	let prDefectDensitySeries: ChartSeriesData = [];
	let locDefectDensitySeries: ChartSeriesData = [];
	if (isValidChartData<IDefectDensityData>(chartData) &&
		isSameIntervalCategory(chartData.issueBugCount.interval, props.filters.interval)) {
		const sprintById = packSprintByIdIfNeeded(props.filters.interval, props.filters.customerSprints);
		prDefectDensitySeries = prepareBugsToPRSeriesData(chartData.issueBugCount, chartData.prCount, props.filters.timezone, sprintById);
		locDefectDensitySeries = prepareBugsToLOCSeriesData(chartData.issueBugCount, chartData.pullRequestLinesOfCodeChanged, props.filters.timezone, sprintById);
	}

	const series: Highcharts.SeriesSplineOptions[] = [
		{
			id: generateUUID(),
			type: "spline",
			color: colorScheme.pr,
			name: "Pull request defect density",
			data: prDefectDensitySeries,
			marker: {
				symbol: "circle"
			},
			visible: prDefectDensitySeries.length > 0,
		},
		{
			id: generateUUID(),
			type: "spline",
			color: colorScheme.issueType.bug,
			name: "Lines of code (K) defect density",
			data: locDefectDensitySeries,
			marker: {
				symbol: "circle"
			},
			visible: locDefectDensitySeries.length > 0,
		}
	];

	const options: Highcharts.Options = {
		chart: {
			type: "spline",
			zoomType: "xy"
		},
		title: undefined,
		tooltip: {
			split: true,
		},
		xAxis: {
			gridLineWidth: 1,
			type: (props.filters.interval === MetricInterval.SPRINT_DATE ? "category" : "datetime"),
			uniqueNames: false
		},
		yAxis: {
			min: 0,
			title: {
				text: "Ratio"
			}
		},
		credits: {
			enabled: false
		},
		series
	};

	return (
		<div className="description">
			<LoadingIndicator local={true} isActive={!isValidChartData(chartData) || isFetching}>
				<HighchartsReact
					highcharts={Highcharts}
					options={options}
				/>
			</LoadingIndicator>
		</div>
	);
}

export default DefectDensity;
