import { ConfigurationEntityType, CustomizableConfigurationCategories } from "@acumen/dashboard-common";
import { clickEvent, GA_EVENT_ACTION, GA_EVENT_CATEGORY } from "../../../analytics-events";
import classNames from "classnames";
import CustomizationFields from "../../../components/customize-configurations/customization-fields";
import _ from "lodash";
import { useStores } from "../../../mobx-stores";
import { IDashboardCustomizableValue } from "../../../mobx-stores/customization-store";
import React, { useState } from "react";

// tslint:disable-next-line: variable-name
const GeneralSettings = () => {
	const {
		customizationStore: {
			updateConfigurationByCategory,
		}
	} = useStores();

	const [changedFormFields, setChangedFormFields] = useState<Partial<IDashboardCustomizableValue>>({});
	const [isFormDirty, setFormDirty] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const saveCustomConfiguration = async () => {
		if (_.isEmpty(changedFormFields)) {
			return;
		}
		setIsLoading(true);
		// tslint:disable-next-line: no-floating-promises
		updateConfigurationByCategory(
			{ category: CustomizableConfigurationCategories.Global, configs: changedFormFields },
			ConfigurationEntityType.CustomerDefault)
			.then(() => {
				setIsLoading(false);
				setFormDirty(false);
			});
	};

	return (
		<div className="ui grid" id="edit-team">
			<div className="ui six wide column" >
				<div className={classNames("ui segment raised")}				>
					<div className={classNames("ui", "wide column", "form-padding")}>
						<CustomizationFields
							category={CustomizableConfigurationCategories.Global}
							entityType={ConfigurationEntityType.CustomerDefault}
							onChange={(values: Partial<IDashboardCustomizableValue>) => {
								setFormDirty(true);
								setChangedFormFields(values);
							}}
							refreshPage={() => {
								return;
							}}
							showLoadingIndicator={true}
						/>

						<div className="full-width pull-right">
							<button
								type="submit"
								className={classNames("ui button",
									{ loading: isLoading, disabled: !isFormDirty }
								)}
								onClick={async (e) => {
									e.preventDefault();
									return saveCustomConfiguration();
								}}
								{...clickEvent(GA_EVENT_CATEGORY.Customization, GA_EVENT_ACTION.Save, CustomizableConfigurationCategories.Global)}
							>Save</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default GeneralSettings;
