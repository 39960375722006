import BaseStore from "./base-store";
import apiContextProvider from "../../services/api-context-provider";
import { DigestApiClient, DIGEST_ROUTE } from "../services/crud/digest-api-client";
import { action, computed, observable } from "mobx";
import {
	taskStatusToGroupMapping, AcumenTaskStatus, DashboardTaskWorkStatusGrouping,
	IDashboardTaskDigest, CommitEventType, GroupedDashboardTaskDigest
} from "@acumen/dashboard-common";
import { FetchLatestRequest } from "../../services/fetch-helpers";
export type FilterName = "assigneeDataContributorIds" | "projects" | "repositories" | "statuses" | "types" | "sprints" | "prEvents" | "jiraEvents";
export type UIFilters = "jiraEvents" | "prEvents";

export type DashboardTaskDigestCard = IDashboardTaskDigest & { movementRanking: number, onClick?: () => void };
export default class DigestStore extends BaseStore<{}> {
	private readonly apiClient: DigestApiClient = new DigestApiClient(apiContextProvider);

	@observable
	public allTasks: Record<DashboardTaskWorkStatusGrouping, DashboardTaskDigestCard[]> = {
		Backlog: [],
		Done: [],
		InProgress: []
	};

	@observable
	public isLoading: boolean = false;

	@computed
	public get hasTasksData() {
		return this.allTasks &&
			(this.allTasks.hasOwnProperty("Backlog") || this.allTasks.hasOwnProperty("InProgress") || this.allTasks.hasOwnProperty("Done"));
	}

	fetchLatestDigest = new FetchLatestRequest<GroupedDashboardTaskDigest, void>(DIGEST_ROUTE);
	@action.bound
	public async getDigest(teamId: string, filters: Record<FilterName, string[]>, dateRange: { startTime: Date, endTime: Date }) {
		const { prEvents, jiraEvents, ...queryFiltersParam } = filters;
		this.isLoading = true;
		this.allTasks = {
			Backlog: [],
			Done: [],
			InProgress: []
		};

		const events = filters.prEvents.concat(filters.jiraEvents);

		// `CommitCommitted` events are add by default, because commits sum is always shown.
		events.push(CommitEventType.CommitCommitted);
		const result = await this.fetchLatestDigest.fetchLatest(this.apiClient.fetchDigest({ ...queryFiltersParam, events, teamId: [teamId] }, dateRange));

		const sortTasks = (taskArr: IDashboardTaskDigest[]) => taskArr
			.map(task => this.addMovementRanking(task))
			.sort((a, b) => (b.movementRanking || 0) - (a.movementRanking || 0))
			.sort((a, b) =>
				(parseInt(a.task.priority.internalPriorityId || "0", 10) - parseInt(b.task.priority.internalPriorityId || "0", 10)));

		const matchedTasks = result?.data;
		this.allTasks = {
			Backlog: sortTasks(matchedTasks?.Backlog || []),
			Done: sortTasks(matchedTasks?.Done || []),
			InProgress: sortTasks(matchedTasks?.InProgress || [])
		};
		this.isLoading = false;
	}

	private addMovementRanking(task: IDashboardTaskDigest): DashboardTaskDigestCard {
		const { statusHistory } = { ...task };
		let movementRanking = 0;
		const getStatusGroupMovesScore = (status: AcumenTaskStatus) => {
			const statusMovesScore: Record<DashboardTaskWorkStatusGrouping | string, number> = {
				NoMoves: 0,
				[DashboardTaskWorkStatusGrouping.Backlog]: 1,
				[DashboardTaskWorkStatusGrouping.InProgress]: 2,
				[DashboardTaskWorkStatusGrouping.Done]: 3
			};
			return statusMovesScore[taskStatusToGroupMapping[status] || "NoMoves"];
		};

		if (statusHistory && statusHistory.length) {
			const fromStatus = statusHistory[0].status.status;
			const toStatus = statusHistory[statusHistory.length - 1].status.status;
			if (fromStatus && toStatus) {
				const fromScore: number = getStatusGroupMovesScore(fromStatus);
				const toScore: number = getStatusGroupMovesScore(toStatus);
				movementRanking = toScore - fromScore;
			}
		}
		return { ...task, movementRanking };
	}
}
