import { IDashboardSprint } from "@acumen/dashboard-common";
import { IClickEvent } from "../../analytics-events";
import React, { CSSProperties, ReactNode } from "react";
import Select, { Theme, ValueType, } from "react-select";

export interface IDropdownOption {
	key: string | number;
	value: string | number | IDashboardSprint;
	label?: string;
	isDisabled?: boolean;
}

export interface IDropdownSelectorProps<OptionType extends IDropdownOption> {
	onChange?: (opt: ValueType<OptionType, false>) => void;
	options: OptionType[];
	value?: string;
	name?: string;
	placeholder?: string;
	isLoading?: boolean;
	clickEvent?: IClickEvent;
	isDisabled?: boolean;
	customColors?: Partial<Theme>;
	customSize?: CSSProperties;
	formatOptionLabel?: (option: OptionType) => ReactNode;
}

// tslint:disable-next-line: variable-name
const DropdownSelector = <OptionType extends IDropdownOption>(props: IDropdownSelectorProps<OptionType>) => {
	const {
		options, value, onChange, name, placeholder, isLoading, formatOptionLabel,
		isDisabled, clickEvent, customColors, customSize
	} = { ...props };

	return (
		<div className="ui section control control-width small"
			{...clickEvent}
		>
			<Select
				name={name}
				isLoading={isLoading}
				classNamePrefix="ui section"
				isMulti={false}
				active={true}
				open={true}
				isDisabled={isDisabled}
				visible={true}
				closeOnBlur={false}
				closeMenuOnSelect={true}
				formatOptionLabel={formatOptionLabel}
				value={options.find(opt => opt.key === value)}
				options={options}
				onChange={onChange}
				placeholder={placeholder}
				styles={{
					control: (base) => ({
						...base,
						...customSize,
						cursor: "pointer",
					}),
					option: (base) => ({
						...base,
						cursor: "pointer",
					})
				}}
				theme={(theme) => ({
					...theme,
					...customColors,
					cursor: "pointer",
					colors: {
						...theme.colors,
						...customColors?.colors,
					}
				})}
			/>
		</div>
	);
};

export default DropdownSelector;
