import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import Loader from "../../../components/loader";
import { useStores } from "../../../mobx-stores";
import _ from "lodash";
import { Pagination } from "semantic-ui-react";
import moment from "moment";
import IntegrationProviders from "./single-integration/add-integration/integration-providers";
import AddIntegrationPane from "./single-integration/add-integration";
import Portal from "../../../components/portal";
import { Route, useRouteMatch, Link, useHistory } from "react-router-dom";
import { IntegrationStatus } from "@acumen/dashboard-common";

const ROWS_PER_PAGE = 15;
const integrationsTable = observer(() => {
	const {
		oldIntegrationsStore: {
			allIntegrations,
			fetchAllIntegrations,
			resetIntegrations,
		}
	} = useStores();

	const match = useRouteMatch();
	const history = useHistory();
	const [page, setPage] = useState(0);
	const [rows, setRows] = useState([]);
	const [total, setTotal] = useState(0);

	useEffect(() => {
		// tslint:disable-next-line: no-floating-promises
		fetchAllIntegrations();
		return resetIntegrations;
	}, [match]);

	useEffect(() => {
		if (!allIntegrations.loading) {
			const integrationsWithoutDestroyed = allIntegrations.data.filter(i => i.state !== IntegrationStatus.DESTROYED);
			setTotal(integrationsWithoutDestroyed.length);
			setPage(1);
		}

		return () => {
			setPage(0);
			setTotal(0);
		};
	}, [allIntegrations]);

	useEffect(() => {
		const result: any = _.chain(allIntegrations.data)
			.filter(i => i.state !== IntegrationStatus.DESTROYED)
			.drop(ROWS_PER_PAGE * (page - 1))
			.take(ROWS_PER_PAGE)
			.map(({ id, state, status, type, createdUtcMs: created }) => ({
				id,
				created,
				provider: IntegrationProviders.find(
					({ key }) => key === type.toLowerCase()
				),
				state,
				status,
			}))
			.value();
		setRows(result);

		return () => {
			setRows([]);
		};
	}, [page]);

	const { loading, loaded } = allIntegrations;
	return (
		<Loader isActive={!loaded || loading}>
			<Route exact={true} path={"/my-account/on-prem-integrations/add"}>
				<Portal onClose={() => history.push("/my-account/on-prem-integrations")}>
					<div
						className="ui dimmer _modals page transition visible active"
						onClick={() => history.push("/my-account/on-prem-integrations")}
					>
						<div className="ui standard test modal front transition visible active with-bootstrap">
							<AddIntegrationPane
								show={true}
								onDismiss={() => history.push("/my-account/on-prem-integrations")}
							/>
						</div>
					</div>
				</Portal>
			</Route>
			<Link to={`${match?.url}/add`}>
				<button className="ui button">Add integration</button>
			</Link>
			<div className="ui segment raised">
				<table className="ui very compact single line small fixed table">
					<thead>
						<tr>
							<th className="one wide">Id</th>
							<th className="two wide">Created</th>
							<th className="two wide">Provider</th>
							<th>State</th>
							<th className="two wide">Status</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{rows.map(
							({
								id,
								created,
								provider: { logo, title } = { logo: "", title: "" },
								state,
								status,
							}) => (
								<tr key={id}>
									<td>{id}</td>
									<td>{moment(created).fromNow()}</td>
									<td>
										<img
											src={logo}
											title={title}
											alt=""
											className="ui mini rounded image"
										/>
									</td>
									<td>{state}</td>
									<td>{status}</td>
									<td />
								</tr>
							)
						)}
					</tbody>
					{total > ROWS_PER_PAGE && (
						<tfoot>
							<tr>
								<td colSpan={6}>
									<div className="ui basic center aligned segment">
										<Pagination
											boundaryRange={0}
											defaultActivePage={page}
											ellipsisItem={null}
											firstItem={null}
											lastItem={null}
											siblingRange={1}
											totalPages={Math.ceil(total / ROWS_PER_PAGE)}
											onPageChange={(e, { activePage }) => setPage(activePage as number)}
										/>
									</div>
								</td>
							</tr>
						</tfoot>
					)}
				</table>
			</div>
		</Loader>
	);
});
export default integrationsTable;
