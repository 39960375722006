import moment from "moment";

export function dateRelativeToNow(format: string, msTime: number, maxDays: number = 7): string {
	const diffDays = moment.duration(moment().diff(msTime)).asDays();

	if (diffDays > (maxDays + 0.49)) {
		return moment(msTime).format(format);
	} else {
		return moment(msTime).fromNow();
	}
}
