import * as React from "react";

export const DEFAULT_ARROW_GREEN_COLOR = "#32B195";
export const DEFAULT_ARROW_RED_COLOR = "#E24B63";

interface IProps extends React.SVGProps<SVGSVGElement> {
	indicationfillcolor?: string;
}
const ArrowUpIcon = (props: IProps) => (
	<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path d="M12.5 11H3.5L8 6L12.5 11Z" fill={props.indicationfillcolor ? props.indicationfillcolor : DEFAULT_ARROW_GREEN_COLOR} />
	</svg>
);

export default ArrowUpIcon;
