import React from "react";
import Modal from "../modal";
import { Button } from "react-bootstrap";
import { STRINGS } from "../../../../localization";

interface IProps {
	show: boolean;
	bodyText: string;
	headerText: string;
	onConfirm: () => void;
	onCancel: () => void;
}

export default function ConfirmationPrompt(props: IProps) {
	return (
		<Modal
			show={props.show}
			headerText={props.headerText}
			onHide={props.onCancel}
			actionButtons={(
				<>
					<Button className="btn-rounded btn-danger" onClick={props.onConfirm}>{STRINGS.CONFIRM}</Button>
					<Button className="btn-rounded btn-secondary" onClick={props.onCancel}>{STRINGS.CANCEL}</Button>
				</>
			)}
		>
			{props.bodyText}
		</Modal>
	);
}
