import React from "react";
import "./trend-total.scss";
import _ from "lodash";
import classNames from "classnames";

export interface TrendTotalProps {
	size?: "medium" | "large";
	currentPeriodValue: number | null | undefined;
	unit: string | null | undefined;
}

export const TrendTotal = (props: TrendTotalProps) => {
	const { size = "medium", currentPeriodValue, unit } = props;
	const className = classNames("trend-total", `variant-size-${size}`);

	return (
		<div className={className}>
			<div className="trend-total-value">
				{currentPeriodValue && _.round(currentPeriodValue, 1)}
			</div>

			{unit && (
				<div className="trend-total-unit">
					{unit}
				</div>
			)}
		</div>
	);
};
