import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../../mobx-stores";
import LoadingIndicator from "../../../../components/loader";
import { AcumenTaskType, DateRangeType, IDashboardSprint, MetricInterval } from "@acumen/dashboard-common";

interface IClosedAndUnmergedPRsProps {
	isFullScreen?: boolean;
	setDisableFullScreen?: (disable: boolean) => void;
	filters: {
		teamId?: string;
		dataContributorIds?: string[];
		startTime?: Date;
		endTime?: Date;
		interval?: MetricInterval;
		projectIds?: string[];
		boardIds?: string[];
		gitRepositoryIds?: string[];
		baseIsDefaultBranch?: boolean;
		excludeDraft?: boolean;
		customerSprints?: IDashboardSprint[];
		issueTypes?: AcumenTaskType[];
		timezone: string;
		dateRange: DateRangeType;
	};
}

function ClosedAndUnmergedPRsChart(props: IClosedAndUnmergedPRsProps) {
	const { chartsStore: { closedUnmergedPrsChart, refreshClosedUnmergedPrsChart } } = useStores();

	useEffect(() => {
		if (props.isFullScreen) {
			return;
		}
		if (Object.keys(props.filters).length === 0) {
			return;
		}

		// tslint:disable-next-line: no-floating-promises
		refreshClosedUnmergedPrsChart(props.filters.interval!,
			props.filters.dateRange,
			props.filters.timezone,
			(props.filters.teamId) ? [props.filters.teamId!] : [],
			props.filters.dataContributorIds ?? [],
			props.filters.boardIds ?? [],
			props.filters.projectIds,
			props.filters.gitRepositoryIds ?? [],
			props.filters.issueTypes ?? [],
			props.filters.excludeDraft,
			props.filters.baseIsDefaultBranch,
		);
	}, [props.filters]);

	return (
		<div className="description">
			<LoadingIndicator local={true} isActive={closedUnmergedPrsChart === undefined}>
				<HighchartsReact
					highcharts={Highcharts}
					options={closedUnmergedPrsChart}
				/>
			</LoadingIndicator>
		</div>
	);
}

export default observer(ClosedAndUnmergedPRsChart);
