import {
	ConfigurationEntityType,
	CustomizableConfiguration,
	CustomizableConfigurationCategories,
	DevStatsMetricNameInDatabase,
	IDailyDevStatsJobConfig,
	IDashboardAcumenMetricDataResponse,
	MetricInterval,
	SINGLE_CONFIG_KEY,
	WorkforceHealthMetrics
} from "@acumen/dashboard-common";
import _ from "lodash";
import { action, computed, observable } from "mobx";
import { CustomizationApiClient, IConfigurationsUpdateParams } from "../services/crud/customization-api-client";
import { DevStatColumn } from "../pages/workforce-health/dev-stat-columns";
import apiContextProvider from "../../services/api-context-provider";
import { FetchLatestRequest } from "../../services/fetch-helpers";
import { calculateAvgByMetric } from "../helpers/calculate-average-by-metric";
import { DeveloperStatsApiClient } from "../services/crud/developer-stats-api-client";
import { DeveloperBadgesData, DeveloperStatsChartData, DeveloperStatsDictionary, DeveloperWeeklyData } from "../types/workforce-health";
import assert from "assert";
import moment from "moment";
import BaseStore from "./base-store";
import { IMetricValue, MetricData } from "../pages/team-comparison/types";
import { TeamsBadgesData } from "v2/types/team-comparison";
import { extractSortedPeriodsFromMetric } from "v2/pages/workforce-health/helpers/dev-stats-metric-calculators";

export interface DeveloperStatsStoreState {
	isLoading: boolean;
	currentStats: DeveloperStatsDictionary | null;
	previousStats: DeveloperStatsDictionary | null;
}

export type IdToMetricValues = Map<string, IMetricValue>;

export type DbMetricToIdMetricValues = { [metricName in DevStatsMetricNameInDatabase]?: IdToMetricValues };
type DashboardMetricToIdMetricValues = { [metricName in WorkforceHealthMetrics]?: IdToMetricValues };

const DAYS_IN_SINGLE_PERIOD = 30;

export const getMetricDatesForDevStatsPeriod = (timezone: string, periodDays: number) => {
	const now = new Date();
	const endOfPeriodTime = moment.tz(now, timezone)
		.subtract(1, "day")
		.endOf("day");
	const startOfPeriodTime = endOfPeriodTime.clone()
		.subtract(periodDays, "days")
		.add(1, "day")
		.startOf("day");

	return {
		endTime: endOfPeriodTime.toDate(),
		startTime: startOfPeriodTime.toDate()
	};
};

const extractDcIdsFromMetricValues = (dashboardMetricValues: DashboardMetricToIdMetricValues): string[] => {
	const dcIds = [];
	for (const dcIdToMetricValues of Object.values(dashboardMetricValues)) {
		if (dcIdToMetricValues) {
			const curDcIds = Array.from(dcIdToMetricValues.keys());
			dcIds.push(...curDcIds);
		}
	}

	return _.uniq(dcIds);
};

function calculateDashboardMetricsFromAcumenMetrics(dashboardMetricsToDisplay: DevStatColumn[],
		metricResponseData: IDashboardAcumenMetricDataResponse[],
		configs?: IDailyDevStatsJobConfig): DashboardMetricToIdMetricValues {
	const calculatedDashboardMetrics: DashboardMetricToIdMetricValues = {};
	const estimationMethod = configs?.devStats.estimationMethod;

	for (const metric of dashboardMetricsToDisplay) {
		const orderedDependencies: IDashboardAcumenMetricDataResponse[] = metric.dependencies
			.map((dependencyName: DevStatsMetricNameInDatabase) => metricResponseData.find(m => m.label === dependencyName)!);
		assert(orderedDependencies.length === metric.dependencies.length,
			`Metric ${metric.dashboardMetricName} has dependencies ${metric.dependencies} - instead got ${orderedDependencies.map(x => x.label)}`
		);
		calculatedDashboardMetrics[metric.dashboardMetricName] =
			metric.calculateColumn(orderedDependencies, estimationMethod);
	}

	return calculatedDashboardMetrics;
}

function calculateMetricData(
	dashboardMetricsToDisplay: DevStatColumn[],
	metricResponseData: IDashboardAcumenMetricDataResponse[],
	configs?: IDailyDevStatsJobConfig
): MetricData {
	assert(metricResponseData && metricResponseData.length > 0, "this method expects at least one metric");
	assert(metricResponseData[0].groupBy, "this method only handles metrics with group by");

	const metricData = new MetricData();
	const calculatedDashboardMetrics = calculateDashboardMetricsFromAcumenMetrics(dashboardMetricsToDisplay,
		metricResponseData, configs);
	const dcIds = extractDcIdsFromMetricValues(calculatedDashboardMetrics);

	for (const dcId of dcIds) {
		for (const metric of dashboardMetricsToDisplay) {
			const metricName = metric.dashboardMetricName;
			const metricValuesForDc = calculatedDashboardMetrics[metricName]!.get(dcId)!;
			metricData.set(dcId, metricName, "current", metricValuesForDc?.current ?? 0);
			metricData.set(dcId, metricName, "previous", metricValuesForDc?.previous ?? 0);
		}
	}

	return metricData;
}

const devStatsMetricResponseToDevStatsChart = (
	dashboardMetricsToDisplay: DevStatColumn[], metricResponseData: IDashboardAcumenMetricDataResponse[],
	configs?: IDailyDevStatsJobConfig,
): DeveloperStatsChartData => {
	const estimationMethod = configs?.devStats.estimationMethod;
	const calculatedDashboardChartMetrics: DeveloperStatsChartData = {};

	for (const metric of dashboardMetricsToDisplay) {
		const orderedDependencies: IDashboardAcumenMetricDataResponse[] = metric.dependencies
			.map((dependencyName: DevStatsMetricNameInDatabase) => metricResponseData.find(m => m.label === dependencyName)!);
		calculatedDashboardChartMetrics[metric.dashboardMetricName] =
			metric.calculateChart(orderedDependencies, estimationMethod);
	}

	return calculatedDashboardChartMetrics;
};

const DEVELOPER_BADGES_ROUTE = "developer-stats/developer-badges";
const TEAM_BADGES_ROUTE = "developer-stats/developer-badges";
const DEVELOPER_STATS_DATA_ROUTE = "developer-stats/stats/data";
const DEVELOPER_STATS_CHARTS_ROUTE = "developer-stats/stats/charts";

export default class DeveloperStatsStore extends BaseStore<DeveloperStatsStoreState> {
	private readonly apiClient: DeveloperStatsApiClient = new DeveloperStatsApiClient(apiContextProvider);
	private readonly customizationApiClient: CustomizationApiClient = new CustomizationApiClient(apiContextProvider);

	@observable isLoadingDeveloperBadgesData = false;
	@observable developerBadgesData: DeveloperBadgesData | null = null;

	@observable isLoadingTeamBadgesData = false;
	@observable teamBadgesData: TeamsBadgesData | null = null;

	@observable isLoadingDevStatChartData = false;
	@observable devStatChartData: DeveloperStatsChartData | null = null;
	@observable isLoadingTeamChartData = false;
	@observable teamChartData: DeveloperStatsChartData | null = null;

	@observable isLoadingDeveloperStatsData = false;
	@observable developerStats: MetricData | null = null;

	@observable isLoadingSingleDeveloperWeeklyData = false;
	@observable developerWeeklyStats: DeveloperWeeklyData | null = null;

	@observable isLoadingTeamStatsData = false;
	@observable teamStats: MetricData | null = null;

	@observable isLoadingWorkForceSelectedMetrics = false;
	@observable workForceSelectedMetrics: WorkforceHealthMetrics[] | null = null;

	@observable isLoadingTeamComparisonSelectedMetrics = false;
	@observable teamComparisonSelectedMetrics: WorkforceHealthMetrics[] | null = null;

	fetchLatestDeveloperBadgesData = new FetchLatestRequest<DeveloperBadgesData, null>(DEVELOPER_BADGES_ROUTE);
	fetchLatestTeamBadgesData = new FetchLatestRequest<TeamsBadgesData, null>(TEAM_BADGES_ROUTE);
	fetchLatestDevStatsChartData = new FetchLatestRequest<IDashboardAcumenMetricDataResponse[], null>(DEVELOPER_STATS_CHARTS_ROUTE);
	fetchLatestDevStatsData = new FetchLatestRequest<IDashboardAcumenMetricDataResponse[], null>(DEVELOPER_STATS_DATA_ROUTE);
	fetchLatestTeamStatsData = new FetchLatestRequest<IDashboardAcumenMetricDataResponse[], null>(DEVELOPER_STATS_DATA_ROUTE);
	fetchSelectedMetricsData = new FetchLatestRequest<Record<string, WorkforceHealthMetrics[]>, null>();
	fetchLatestTeamStatsChartData = new FetchLatestRequest<IDashboardAcumenMetricDataResponse[], null>("team-comparison/chars/data");

	@action.bound
	async getDeveloperBadgesData(dataContributorIds: string[]) {
		this.isLoadingDeveloperBadgesData = true;
		const DeveloperBadgesResponse = await this.fetchLatestDeveloperBadgesData.fetchLatest(
			this.apiClient.getDeveloperBadgesData(dataContributorIds)
		);

		if (DeveloperBadgesResponse) {
			this.developerBadgesData = DeveloperBadgesResponse.data;
		}

		this.isLoadingDeveloperBadgesData = false;
	}

	@action.bound
	async getTeamBadgesData(teamIds: string[]) {
		this.isLoadingTeamBadgesData = true;
		const teamBadgesResponse = await this.fetchLatestTeamBadgesData.fetchLatest(
			this.apiClient.getTeamBadgesData(teamIds)
		);

		if (teamBadgesResponse) {
			this.teamBadgesData = teamBadgesResponse.data;
		}

		this.isLoadingTeamBadgesData = false;
	}

	@action.bound
	async getDeveloperStatsChartData(timezone: string, dataContributorIds: string[], dashboardMetricsToDisplay: DevStatColumn[]) {
		this.isLoadingDevStatChartData = true;
		const { startTime, endTime } = getMetricDatesForDevStatsPeriod(timezone, DAYS_IN_SINGLE_PERIOD * 2);
		const chartDataResponse = await this.fetchLatestDevStatsChartData.fetchLatest(
			this.apiClient.getDevStatMetrics(dashboardMetricsToDisplay,
				MetricInterval.DAYS_7,
				startTime, endTime, timezone,
				{ data_contributor_id: dataContributorIds, team_id: undefined })
		);

		if (chartDataResponse) {
			this.devStatChartData = devStatsMetricResponseToDevStatsChart(dashboardMetricsToDisplay, chartDataResponse.data);
		}

		this.isLoadingDevStatChartData = false;
	}

	@action.bound
	async getTeamStatsChartData(timezone: string, teamIds: string[], dashboardMetricsToDisplay: DevStatColumn[]) {
		this.isLoadingTeamChartData = true;

		const { startTime, endTime } = getMetricDatesForDevStatsPeriod(timezone, DAYS_IN_SINGLE_PERIOD * 2);
		const chartDataResponse = await this.fetchLatestTeamStatsChartData.fetchLatest(
			this.apiClient.getDevStatMetrics(dashboardMetricsToDisplay,
				MetricInterval.DAYS_7,
				startTime, endTime, timezone,
				{ data_contributor_id: undefined, team_id: teamIds })
		);

		if (chartDataResponse) {
			this.teamChartData = devStatsMetricResponseToDevStatsChart(dashboardMetricsToDisplay, chartDataResponse.data);
		}

		this.isLoadingTeamChartData = false;
	}

	@action.bound
	async getDeveloperStatsData(timezone: string, dataContributorIds: string[], dashboardMetricsToDisplay: DevStatColumn[]) {
		this.isLoadingDeveloperStatsData = true;
		const { startTime, endTime } = getMetricDatesForDevStatsPeriod(timezone, DAYS_IN_SINGLE_PERIOD * 2);
		const developerStatsResponse = await this.fetchLatestDevStatsData.fetchLatest(
			this.apiClient.getDevStatMetrics(dashboardMetricsToDisplay,
				MetricInterval.DAYS_30,
				startTime, endTime, timezone,
				{ data_contributor_id: dataContributorIds, team_id: undefined },
				"data_contributor_id")
		);

		if (developerStatsResponse && developerStatsResponse.data) {
			this.developerStats = calculateMetricData(dashboardMetricsToDisplay, developerStatsResponse.data,
				this.developerBadgesData?.config?.configs);
		}

		this.isLoadingDeveloperStatsData = false;
	}

	@action.bound
	async getSingleDeveloperWeeklyStats(timezone: string, dataContributorId: string,
		metricColumn: DevStatColumn) {
		this.isLoadingSingleDeveloperWeeklyData = true;
		const { startTime, endTime } = getMetricDatesForDevStatsPeriod(timezone, DAYS_IN_SINGLE_PERIOD * 3);
		const response = await this.fetchLatestDevStatsData.fetchLatest(
			this.apiClient.getDevStatMetrics([metricColumn],
				MetricInterval.WEEK,
				startTime, endTime, timezone,
				{ data_contributor_id: [dataContributorId], team_id: undefined },
				"data_contributor_id")
		);

		if (response && response.data) {
			const metrics = response.data.filter(m =>
				metricColumn.dependencies.includes(m.label as DevStatsMetricNameInDatabase));
			assert(metrics.length === metricColumn.dependencies.length,
				`Dependency metric mismatch: Expected ${metricColumn.dependencies.join(",")} in response ${JSON.stringify(response.data)}`);

			this.developerWeeklyStats = {
				chartData: devStatsMetricResponseToDevStatsChart([metricColumn], response.data),
				dates: metrics.length === 0 ? [] : extractSortedPeriodsFromMetric(metrics[0])
			};
		}

		this.isLoadingSingleDeveloperWeeklyData = false;
	}

	@action.bound
	async rerunBadgeCalculationTask() {
		return this.apiClient.rerunBadgeCalculationTask();
	}

	@computed
	public get currentDevStatsAvgByMetric() {
		if (!this.developerStats) {
			return null;
		}

		return calculateAvgByMetric(this.developerStats, "current");
	}

	@computed
	public get previousDevStatsAvgByMetric() {
		if (!this.developerStats) {
			return null;
		}

		return calculateAvgByMetric(this.developerStats, "previous");
	}

	@computed
	public get currentTeamStatsAvgByMetric() {
		if (!this.teamStats) {
			return null;
		}

		return calculateAvgByMetric(this.teamStats, "current");
	}

	@computed
	public get previousTeamStatsAvgByMetric() {
		if (!this.teamStats) {
			return null;
		}

		return calculateAvgByMetric(this.teamStats, "previous");
	}

	@action.bound
	async getWorkForceSelectedMetrics(dataContributorId: string) {
		this.isLoadingWorkForceSelectedMetrics = true;
		const selectedMetricsResponse = await this.fetchSelectedMetricsData.fetchLatest(
			this.apiClient.getWorkForceSelectedMetrics(dataContributorId)
		);

		if (selectedMetricsResponse && selectedMetricsResponse.data) {
			this.workForceSelectedMetrics = Object.values(selectedMetricsResponse.data)[0] as WorkforceHealthMetrics[];
		}

		this.isLoadingWorkForceSelectedMetrics = false;
	}

	@action.bound
	async getTeamComparisonSelectedMetrics(dataContributorId: string) {
		this.isLoadingTeamComparisonSelectedMetrics = true;
		const selectedMetricsResponse = await this.fetchSelectedMetricsData.fetchLatest(
			this.apiClient.getTeamComparisonSelectedMetrics(dataContributorId)
		);

		if (selectedMetricsResponse && selectedMetricsResponse.data) {
			this.teamComparisonSelectedMetrics = Object.values(selectedMetricsResponse.data)[0] as WorkforceHealthMetrics[];
		}

		this.isLoadingTeamComparisonSelectedMetrics = false;
	}

	private updateSelectedMetricsConfig = async (
		category: CustomizableConfigurationCategories,
		configName: CustomizableConfiguration,
		dataContributorId: string,
		selectedMetrics: WorkforceHealthMetrics[],
	): Promise<void> => {
		const updateParams: IConfigurationsUpdateParams = {
			category,
			configs: {
				[configName]: {
					[ConfigurationEntityType.DataContributor]: [{
						id: dataContributorId,
						configs: {
							[SINGLE_CONFIG_KEY]: selectedMetrics
						}
					}]
				}
			}
		};

		await this.customizationApiClient.updateCustomization(updateParams);
	}

	@action.bound
	public updateWorkForceSelectedMetrics = async (dataContributorId: string, selectedMetrics: WorkforceHealthMetrics[]) => {
		await this.updateSelectedMetricsConfig(
			CustomizableConfigurationCategories.DailyDevStats,
			CustomizableConfiguration.WorkforceHealthSelectedMetrics,
			dataContributorId,
			selectedMetrics,
		);
		this.workForceSelectedMetrics = selectedMetrics;
	}

	@action.bound
	public updateTeamComparisonSelectedMetrics = async (dataContributorId: string, selectedMetrics: WorkforceHealthMetrics[]) => {
		await this.updateSelectedMetricsConfig(
			CustomizableConfigurationCategories.TeamComparison,
			CustomizableConfiguration.TeamComparisonSelectedMetrics,
			dataContributorId,
			selectedMetrics,
		);
		this.teamComparisonSelectedMetrics = selectedMetrics;
	}

	@action.bound
	async getTeamStatsData(timezone: string, teamIds: string[], dashboardMetricsToDisplay: DevStatColumn[]) {
		this.isLoadingTeamStatsData = true;
		const { startTime, endTime } = getMetricDatesForDevStatsPeriod(timezone, DAYS_IN_SINGLE_PERIOD * 2);
		const teamStatsResponse = await this.fetchLatestTeamStatsData.fetchLatest(
			this.apiClient.getDevStatMetrics(dashboardMetricsToDisplay, MetricInterval.DAYS_30,
				startTime, endTime, timezone,
				{ data_contributor_id: undefined, team_id: teamIds },
				"team_id")
		);

		if (teamStatsResponse && teamStatsResponse.data) {
			this.teamStats = calculateMetricData(dashboardMetricsToDisplay, teamStatsResponse.data, this.developerBadgesData?.config?.configs);
		}

		this.isLoadingTeamStatsData = false;
	}
}
