import React from "react";
import {
	DashboardTeamReportMetricChangeReaction, IDashboardTeamReportMetric
} from "@acumen/dashboard-common";
import classNames from "classnames";
import AreaChart from "../../components/highchart-chart-components/area-chart";
import { Loader, Popup } from "semantic-ui-react";
import { round } from "@acumen/common";
import { ChartSeriesData } from "../../pages/my-team/analytics/charts/charts";
import { columnSortStates, TwoWaysSortButton } from "../../components/buttons/icon-buttons";

export const DEFAULT_CARD_COLOR = "#8791A2";
type ExternalHoverType = "EXTERNAL_HOVER_TYPE";
export const externalHoverType: ExternalHoverType = "EXTERNAL_HOVER_TYPE";
export type HoverSelectionPart = string | ExternalHoverType;
export interface IHoverEffectProps {
	hoveredMetric: HoverSelectionPart;
	setHoveredMetric: React.Dispatch<React.SetStateAction<HoverSelectionPart>>;
	elementKey?: string;
	withLabel?: boolean;
}

export interface ITeamsReportTrendCards {
	metricName: string;
	title: string;
	secondaryTitle?: string;
	color: string;
	tooltipText?: string;
	units?: string;
}

// tslint:disable-next-line: variable-name
export const TrendCard = (props: {
	series?: ChartSeriesData,
	cardData?: ITeamsReportTrendCards,
	metricData?: IDashboardTeamReportMetric,
	hoverEffect?: IHoverEffectProps,
	index?: number,
	bigFont?: boolean,
	units?: string,
	sortState?: columnSortStates,
	onSort?: () => void
}) => {

	const { series, cardData, metricData, hoverEffect, bigFont, units, sortState, onSort } = { ...props };
	const change = metricData ? round(metricData.changeFromPreviousPeriod * 100, 0) : undefined;
	const reaction = metricData?.reaction;
	const { hoveredMetric, setHoveredMetric } = { ...hoverEffect };
	const {
		title = "",
		secondaryTitle = "",
		color = DEFAULT_CARD_COLOR,
		metricName,
		tooltipText
	} = { ...cardData };

	const getCardOutline = () => (hoveredMetric === metricName) ? `1px solid ${color}` : undefined;

	return (
		<div className="acu-card trend-card"
			style={{
				outline: getCardOutline()
			}}
			onMouseOver={setHoveredMetric ? (e) => {
				e.stopPropagation();
				if (hoverEffect && metricName) {
					setHoveredMetric(metricName);
				}
			} : undefined}
			onMouseOut={setHoveredMetric ? () => {
				if (hoverEffect) {
					setHoveredMetric(externalHoverType);
				}
			} : undefined}
		>
			<div className="trend-card-header">
				<div className="clickable flex-spaced" style={{ width: "90%" }} onClick={onSort}>
					<span className="medium-text ">
						{title ? tooltipText ?
							<Popup
								hoverable={true}
								wide={true}
								className={"tiny-text"}
								position="top center"
								content={tooltipText}
								trigger={
									<span>{title}</span>
								}
							/> : <span>{title}</span>
							: <Loader active={true} inline={true} inverted={true} size="small" />
						}
					</span>
					{onSort && <TwoWaysSortButton
						columnSortedUp={sortState}
					/>}
				</div>
				<p className="small-text secondary-title">{secondaryTitle}</p>
				<p className="value">
					<span className={classNames("bold-text", bigFont ? "huge-text" : "big-text")} style={{ color }}>
						{metricData && round(metricData.currentPeriodValue, 1)}
					</span>
					{(change !== undefined && units) && <span className="gray-text small-text">{units}</span>}
					{(change !== undefined) &&
						<span
							className={classNames("change",
								"small-text semi-bold-text",
								{
									"gray-text": (change === 0 || reaction === DashboardTeamReportMetricChangeReaction.Neutral),
									"success-text": (change !== 0 && reaction === DashboardTeamReportMetricChangeReaction.Positive),
									"warning-text": (change !== 0 && reaction === DashboardTeamReportMetricChangeReaction.Negative),
								})}>
							{change > 0 && <i className="caret up icon" />}
							{change < 0 && <i className="caret down icon" />}
							{change === 0 && <i className="icon" />}
							{`${change}%`}
						</span>}
				</p>
			</div>

			<div className="trend-chart">
				<AreaChart
					color={color}
					height={65}
					opacity={(hoveredMetric === metricName) ? 1 : 0.4}
					series={series}
				/>
			</div>
		</div>
	);
};

const DEFAULT_COLOR_LIGHT_BLUE = "#e0eaf6";

export interface ILegendList {
	name: string;
	value: string | number;
	color?: string;
	valueSuffix?: string;
}
// tslint:disable-next-line: variable-name
export const ChartExternalLegend = (props: {
	legendList?: ILegendList[];
	legendTitle?: string;
	hoveredEntry?: string;
	setHoveredEntry?: React.Dispatch<React.SetStateAction<string | undefined>>
}) => {
	const { legendList, hoveredEntry, setHoveredEntry, legendTitle } = { ...props };
	return (
		<div className="acu-legend no-padding">
			{legendTitle && <span className="acu-legend-item"><b>{legendTitle}</b></span>}
			{legendList?.map((item, i) => (
				<div
					key={i}
					className={classNames({ hovered: hoveredEntry === item.name }, "acu-legend-item")}
					onMouseOver={() => setHoveredEntry && setHoveredEntry(item.name)}
					onMouseOut={() => setHoveredEntry && setHoveredEntry(undefined)}
				>
					<div className="circle" style={{ backgroundColor: item.color ?? DEFAULT_COLOR_LIGHT_BLUE }} />
					<p>
						<span>{item.name}</span>
						<span className="legend-item-value"> {item.valueSuffix ? `${item.value} ${item.valueSuffix}` : item.value}</span>
					</p>
				</div>))
			}
		</div >
	);
};
