import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../mobx-stores";
import { Status } from "@acumen/dashboard-common";
import { useRouteMatch, useHistory, Link } from "react-router-dom";
import Loader from "../../../components/loader";
import { Avatar } from "../../../components/avatar";
import { GA_EVENT_ACTION, GA_EVENT_CATEGORY, clickEvent } from "../../../analytics-events";

const allUsersTable = observer(() => {
	const match = useRouteMatch();
	const history = useHistory();

	const {
		usersStore: { allUsers, fetchAllUsers, resetUsers },
		authStore
	} = useStores();

	const getUserName = (id?: string) => {
		const capitalize = (str: string | undefined | null) => str ? str.charAt(0).toUpperCase() + str.slice(1) : "";
		const user = allUsers.data.find(u => u.id === id);
		return user ? `${capitalize(user.firstName)} ${capitalize(user.surname)}` : "";
	};

	useEffect(() => {
		const retrieveUsers = async () => {
			if (!allUsers.loading) {
				await fetchAllUsers();
			}
		};
		// tslint:disable-next-line: no-floating-promises
		retrieveUsers();
		return () => resetUsers();
	}, []);

	const url = match ? match.url.split("/").splice(0, 3).join("/") : "";

	return (
		<Loader isActive={!allUsers.loaded}>
			{(authStore.isUserAdmin && authStore.isUserShouldShowInviteUsers) && (
				<Link to={`${match!.url}/invite`}>
					<button className="ui button" {...clickEvent(GA_EVENT_CATEGORY.Users, GA_EVENT_ACTION.Add)}>Invite User</button>
				</Link>
			)}
			<div className="ui segment raised">
				<table className="ui very compact single line small stackable clickable table">
					<thead>
						<tr>
							<th className="four wide">Name</th>
							<th>Email</th>
							<th>Role</th>
							<th>Status</th>
						</tr>
					</thead>
					<tbody>
						{allUsers.data.map(
							({
								id,
								email,
								title,
								roles,
								status,
								pictureUrl,
								firstName
							}) => (

									<tr key={id} className={status === Status.Disabled ? "disabled-user" : ""}
										{...clickEvent(GA_EVENT_CATEGORY.Users, GA_EVENT_ACTION.Edit)} onClick={() => history.push(`${url}/edit?id=${id}`)}>
										<td>
											<h4 className="ui image header no-wrap">
												<Avatar
													className="medium"
													user={{
														email,
														pictureUrl,
														firstName
													}}
												/>
												<div className="content">
													{getUserName(id)}
													<div className="sub header">{title}</div>
												</div>
											</h4>
										</td>
										<td>{email}</td>
										<td>{roles.join(", ")}</td>
										<td>{status}</td>
									</tr>
								)
						)}
					</tbody>
				</table>
			</div>
		</Loader >
	);
});
export default allUsersTable;
