import React, { createContext, useContext, useEffect, useState } from "react";
import { getSelfUser } from "services/auth";
import { useAuth0 } from "@auth0/auth0-react";
import { AcumenUiVersion } from "@acumen/dashboard-common";
import { routesWithAllowedParams } from "config";
import { useStores } from "../v2/mobx-stores";

/**
 * The main configuration to instantiate the `VersionSwitcherProvider`.
 */
export interface VersionSwitcherProviderOptions {
	/**
	 * The child nodes your Provider has wrapped
	 */
	children?: React.ReactNode;
	appByVersion: Record<AcumenUiVersion, JSX.Element>;
}
const VersionSwitchContext = createContext(undefined);

const VersionSwitchProvider = (opts: VersionSwitcherProviderOptions): JSX.Element => {
	const {
		children,
		appByVersion,
	} = opts;

	const [app, setApp] = useState<JSX.Element>(<></>);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const { authStore } = useStores();
	const {
		getAccessTokenSilently,
		isAuthenticated,
		loginWithRedirect,
		error,
		isLoading: isLoadingAuth
	} = useAuth0();

	const allowedRoute = routesWithAllowedParams.find(r => window.location.pathname.match(r.route));
	let returnTo = window.location.pathname;
	let params = window.location.search;
	if (allowedRoute) {
		allowedRoute.modifyParams?.forEach(modify => {
			const [incomingName, newParamName] = [...modify];
			if (incomingName && newParamName) {
				params = params.replace(incomingName, newParamName);
			}
		});
		returnTo += params;
	}
	async function handleUnAuthorizedUser() {
		if (authStore.getCachedJWT()) {
			return;
		}

		if (!isLoadingAuth && !isAuthenticated) {
			return loginWithRedirect({
				appState: { returnTo },
			});
		}
	}

	useEffect(() => {
		// tslint:disable-next-line: no-floating-promises
		handleUnAuthorizedUser();
	}, [isLoadingAuth, isAuthenticated, error]);

	const setAppByVersion = async () => {
		try {
			const cachedToken = authStore.getCachedJWT();

			if (isAuthenticated || cachedToken) {
				setIsLoading(true);
				const token = cachedToken ? cachedToken : await getAccessTokenSilently({});
				const self = await getSelfUser(token);

				if (self && self.acumenUiVersion) {
					setApp(appByVersion[self.acumenUiVersion]);
				}
			}
		} catch (error) {
			setApp(appByVersion[AcumenUiVersion.V1]);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		// tslint:disable-next-line: no-floating-promises
		setAppByVersion();
	}, [isAuthenticated]);

	return (
		<VersionSwitchContext.Provider value={undefined}>
			{!isLoading && app}
			{children}
		</VersionSwitchContext.Provider>
	);
};

export function useVersionSwitch() {
	return useContext(VersionSwitchContext);
}

export default VersionSwitchProvider;
