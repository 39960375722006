export class TeamsReportStrings {
	public static HEADER_LEAD_TIME_TOOLTIP = "Average amount of time that has passed between ticket creation and ticket resolution for all selected teams. Tasks that were discarded are excluded from the calculation.";
	public static HEADER_PR_CYCLE_TIME_TOOLTIP = "Average amount of time from the creation of the first commit in a pull request, until that pull request is merged. Only merged pull requests from the selected teams are included in the calculation.";
	public static HEADER_MERGED_PRS_SUM_TOOLTIP = "Total amount of merged pull requests in the time period for all selected teams.";
	public static HEADER_MERGED_PRS_AVG_TOOLTIP = "The average amount of merged pull requests in the time period per each developer.";
	public static HEADER_REVIEWED_PRS_TOOLTIP = "Total amount of reviewed pull requests in the time period for all selected teams.";
	public static HEADER_REVIEWED_DEV_STATS_PRS_TOOLTIP = "The average amount of pull requests reviewed per developer in the time period for all selected teams.";
	public static HEADER_PR_MERGED_FREQ_TOOLTIP = "Average amount of merged pull requests per a given day for all selected teams.";
	public static HEADER_PR_CYCLE_WIP_TOOLTIP = "The \"work in progress\" phase of the pull request cycle time. Shows the average time spent between the initial commit and the first review request. Only merged pull requests from the selected teams are included in the calculation.";
	public static HEADER_PR_CYCLE_AWAITING_REVIEW_TOOLTIP = "The \"pending review\" phase of the pull request cycle time. Shows the average time spent between the first review request to when the pull request went into actual review. Only merged pull requests from the selected teams are included in the calculation.";
	public static HEADER_PR_CYCLE_IN_REVIEW_TOOLTIP = "The \"actual review\" phase of the pull request cycle time. Shows the average time spent between beginning a review until it is approved (the first approval is the one taken into account). Only merged pull requests from the selected teams are included in the calculation.";
	public static HEADER_PR_CYCLE_REVIEWED_TOOLTIP = "The \"awaiting to be merged\" phase of the pull request cycle time. Shows the average time spent between the first review approval until the pull request is actually merged. Only merged pull requests from the selected teams are included in the calculation.";
	public static TEAM_LEAD_TIME_TOOLTIP = "Average amount of time that has passed between ticket creation and ticket resolution for all selected teams. Tasks that were discarded are excluded from the calculation.";
	public static TEAM_PR_CYCLE_TIME_TOOLTIP = "Average amount of time from the creation of the first commit in a pull request, until that pull request is merged. Only merged pull requests from the specified team are included in the calculation.";
	public static TEAM_PR_MERGED_FREQ_TOOLTIP = "Average amount of merged pull requests per a given day for all selected teams.";
	public static TEAM_PR_CYCLE_WIP_TOOLTIP = "The \"work in progress\" phase of the pull request cycle time. Shows the average time spent between the initial commit and the first review request. Only merged pull requests from the specified team are included in the calculation.";
	public static TEAM_PR_CYCLE_AWAITING_REVIEW_TOOLTIP = "The \"pending review\" phase of the pull request cycle time. Shows the average time spent between the first review request to when the pull request went into actual review. Only merged pull requests from the specified team are included in the calculation.";
	public static TEAM_PR_CYCLE_IN_REVIEW_TOOLTIP = "The \"actual review\" phase of the pull request cycle time. Shows the average time spent between beginning a review until it is approved (the first approval is the one taken into account). Only merged pull requests from the specified team are included in the calculation.";
	public static TEAM_PR_CYCLE_REVIEWED_TOOLTIP = "The \"awaiting to be merged\" phase of the pull request cycle time. Shows the average time spent between the first review approval until the pull request is actually merged. Only merged pull requests from the specified team are included in the calculation.";
	public static HEADER_BUGS_TOOLTIP = "The amount of bugs opened during the selected time period for all teams. Only bugs that are assigned to personnel for the selected teams are included.";
	public static HEADER_MTTR_TOOLTIP = "\"Mean time to resolve\". This metric measures the amount of time spent from a bug being opened until it is ultimately resolved. Only bugs with the top two highest priority levels are taken into account. The average is calculated for tickets assigned to personnel in the selected teams.";
	public static HEADER_BUG_FIX_RATIO_TOOLTIP = "The ratio between the amount of resolved bugs to the amount of all new bugs opened in the selected time period. This is calculated for all the selected teams via the assignee field.";
	public static HEADER_PR_SIZE_TOOLTIP = "Shows the average size (amount of changes in lines of code, includes additions and deletions) for all pull requests merged during the selected time period for all teams.";
	public static TEAM_BUGS_TOOLTIP = "The amount of bugs opened during the selected time period for the specified team. Only bugs that are assigned to personnel for the selected teams are included.";
	public static TEAM_MTTR_TOOLTIP = "\"Mean time to resolve\". This metric measures the amount of time spent from a bug being opened until it is ultimately resolved. Only bugs with the top two highest priority levels are taken into account. The average is calculated for tickets assigned to personnel in the selected teams.";
	public static TEAM_BUG_FIX_RATIO_TOOLTIP = "The ratio between the amount of resolved bugs to the amount of all new bugs opened in the selected time period. This is calculated for all the selected teams via the assignee field.";
	public static TEAM_PR_SIZE_TOOLTIP = "Shows the average size (amount of changes in lines of code, includes additions and deletions) for all pull requests merged during the selected time period for the specified team.";
	public static HEADER_RESOLVED_TICKETS_SUM_TOOLTIP = "The total amount of all tickets resolved during the selected time period for all teams.Tickets are chosen per team via the assignee field, only tickets that are assigned to personnel of the selected teams are included.";
	public static HEADER_RESOLVED_TICKETS_AVG_TOOLTIP = "The average amount of tickets resolved per developer during the selected time period. Tickets are chosen per team via the assignee field, only tickets that are assigned to personnel of the selected teams are included.";
	public static HEADER_TRACEABILITY_TOOLTIP = "The ratio between the amount of pull requests created that have a link to a ticket and the total amount of pull requests created within the selected time period. The ratio includes data from all selected teams.";
	public static HEADER_MAKER_TIME_TOOLTIP = "The average amount of time per developer invested into coding, reviewing and handling of team tasks (this metric is calculated by Acumen).";
	public static CLOSED_AND_UNMERGED_PR_TOOLTIP = "Total amount of pull requests that have been closed without being merged.";
	public static ABANDONED_WORK_TOOLTIP = "Shows the amount of abandoned commits (not merged to the default branch and under one year old).";
	public static PR_SIZE_TO_MERGE_TIME_TOOLTIP = "Shows the relation between pull request sizes and their corresponding time (open to merge). Circles indicate size in lines of code, date shows when the pull request was merged.";
	public static HEADER_ACTIVE_DAYS_TOOLTIP = "Shows the number of active days where the user had some kind of work related event";
	public static TRACEABILITY_OVER_TIME_TOOLTIP = "Shows the ratio between the amount of merged pull requests that have a link to a ticket vs the ones that don’t. Links are ascertained from the commits or the pull request title/body. Only merged pull requests for the selected teams are included.";
}
