import { DashboardUserRoleType, IDashboardDetailedDataContributor } from "@acumen/dashboard-common";
import { useStores } from "../../../mobx-stores";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useForm, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { GA_EVENT_ACTION, GA_EVENT_CATEGORY, clickEvent } from "../../../analytics-events";
import StringInput from "../../../components/form/string-input";
import "./style.scss";
import _ from "lodash";
import classNames from "classnames";

enum UserInviteFormFields {
	Role = "role",
	Email = "email",
	Member = "member"
}

interface IDropdownOption<T> {
	key: string;
	value: T;
	label?: string;
}

const USER_ROLE_OPTIONS: Array<IDropdownOption<DashboardUserRoleType>> = [
	{
		key: DashboardUserRoleType.Engineer,
		label: "Engineer",
		value: DashboardUserRoleType.Engineer,
	},
	{
		key: DashboardUserRoleType.Manager,
		label: "Tech Lead",
		value: DashboardUserRoleType.Manager,
	},
	{
		key: DashboardUserRoleType.Executive,
		label: "Director/Executive",
		value: DashboardUserRoleType.Executive,
	},
	{
		key: DashboardUserRoleType.Administrator,
		label: "Administrator",
		value: DashboardUserRoleType.Administrator,
	},
];

const INVITE_USER_SUCCESS_TOAST_ID = "invited_user_successfully";
const INVITE_USER_MESSAGE = "Success: Invited the user successfully.";
const AUTO_DISMISS_TOAST_MS = 5000;

// tslint:disable-next-line: variable-name
const UserInvitePage = (() => {
	const history = useHistory();
	const [isInvitingInProgress, setIsInvitingInProgress] = useState<boolean>(false);
	const [selectedDC, setSelectedDC] = useState<IDashboardDetailedDataContributor | undefined>(undefined);
	const [isFormValid, setIsFormValid] = useState<boolean>(false);

	const {
		register,
		control,
		setValue,
		getValues
	} = useForm({
		defaultValues: {},
		reValidateMode: "onBlur"
	});

	const {
		usersStore: {
			inviteUser
		},
		dataContributorsStore: {
			fetchNonAcumenRelatedDataContributors,
			nonAcumenDataContributors,
			resetDataContributors
		}
	} = useStores();

	useEffect(() => {
		const retrieveDataContributors = async () => {
			if (!nonAcumenDataContributors.loading) {
				await fetchNonAcumenRelatedDataContributors();
			}
		};
		// tslint:disable-next-line: no-floating-promises
		retrieveDataContributors();
		return () => resetDataContributors();
	}, []);

	useEffect(() => {
		// Auto select email if possible
		const availableEmails = availableEmailsForSelectedDC(selectedDC);
		setValue(UserInviteFormFields.Member, optionFromDataContributor(selectedDC));
		setValue(UserInviteFormFields.Email, (availableEmails.length === 1 ? availableEmails[0] : null));
		setValue(UserInviteFormFields.Role, USER_ROLE_OPTIONS.find(ur => ur.value === DashboardUserRoleType.Executive));
		setIsFormValid(validateForm());
	}, [selectedDC]);

	const onSuccess = (success: string, toastId?: string) => {
		toast.success(success, {
			toastId,
			autoClose: AUTO_DISMISS_TOAST_MS
		});
	};

	const onError = (error: string, toastId?: string) => {
		toast.error(error, {
			toastId,
			autoClose: AUTO_DISMISS_TOAST_MS
		});
	};

	const getFormValues = (formValues = getValues()) => {
		let emailValue;
		if (formValues[UserInviteFormFields.Email]) {
			if (_.isString(formValues[UserInviteFormFields.Email])) {
				emailValue = formValues[UserInviteFormFields.Email] as string;
			} else if (_.isObject(formValues[UserInviteFormFields.Email])) {
				emailValue = formValues[UserInviteFormFields.Email] as IDropdownOption<string>;
			}
		}

		return {
			[UserInviteFormFields.Member]: formValues[UserInviteFormFields.Member] as IDropdownOption<IDashboardDetailedDataContributor>,
			[UserInviteFormFields.Email]: emailValue,
			[UserInviteFormFields.Role]: formValues[UserInviteFormFields.Role] as IDropdownOption<DashboardUserRoleType>,
		};
	};

	const availableEmailsForSelectedDC = (selectedMemberToInvite?: IDashboardDetailedDataContributor) => {
		if (!selectedMemberToInvite) {
			return [];
		}

		return _.chain(selectedMemberToInvite.links).flatMap(lnk => lnk.emails).uniq().value();
	};
	const optionFromDataContributor = (dc?: IDashboardDetailedDataContributor) => {
		if (dc && dc.id && dc.primaryDisplayName) {
			return {
				key: dc.id,
				value: dc,
				label: dc.primaryDisplayName,
			};
		} else {
			return null;
		}
	};

	const validateForm = (fieldName?: UserInviteFormFields) => {
		const formValues = getFormValues();

		const validateFormField = (field: UserInviteFormFields) => {
			const value = formValues[field];
			if (!value) {
				return false;
			} else {
				switch (field) {
					case UserInviteFormFields.Email:
						if (_.isString(formValues[field])) {
							return (value as string).length > 0;
						} else if (_.isObject(formValues[field])) {
							return true;
						}
						return false;
					case UserInviteFormFields.Member:
					default:
						return formValues[field] !== undefined;
				}
			}
		};
		if (fieldName) {
			return validateFormField(fieldName);
		}

		return [
			validateFormField(UserInviteFormFields.Email),
			validateFormField(UserInviteFormFields.Member),
		].reduce((isAllValid, isValid) => {
			return isValid && isAllValid;
		}, true) ?? false;
	};

	const availableEmailsToPresent = availableEmailsForSelectedDC(selectedDC);
	return (
		<div className="ui grid" id="invite-user">
			<div className="six wide column user-form">
				<div className={classNames("ui segment raised", { loading: (nonAcumenDataContributors.loading || !nonAcumenDataContributors.data) })}>
					<form className="ui form user-edit">
						<div className="ui grid">
							<div className="sixteen wide column acu-capitalize">
								<h2>Invite user</h2>
							</div>
							<div className="sixteen wide column">
								<label className="flex-spaced">Organization members
									{!selectedDC && <span className="message required">
										<i className="tiny icon asterisk" style={{ verticalAlign: "text-top" }} />
										required
									</span>}
								</label>
								<Controller
									className={"clickable"}
									control={control}
									name={UserInviteFormFields.Member}
									label={UserInviteFormFields.Member}
									defaultValue={getValues(UserInviteFormFields.Member)}
									render={({ value }) => (
										<ReactSelect
											className={classNames("form-control is-valid clickable", { required: !validateForm(UserInviteFormFields.Member) })}
											isClearable={false}
											multiValueRemove={true}
											isMulti={false}
											value={value}
											controlShouldRenderValue={true}
											options={
												_.chain(nonAcumenDataContributors.data)
													.map((dc) => optionFromDataContributor(dc))
													.compact()
													.value()
											}
											styles={{
												control: (base) => ({
													...base,
													cursor: "pointer",
												}),
												option: (base) => ({
													...base,
													cursor: "pointer",
												})
											}}
											onChange={((selectedOption) => {
												const selectedMemberToInvite = (selectedOption as IDropdownOption<IDashboardDetailedDataContributor>).value;

												// This will reset the form and set the member correctly
												setSelectedDC(selectedMemberToInvite);
											})}
											{...clickEvent(GA_EVENT_CATEGORY.UserInvite, GA_EVENT_ACTION.Select, "MemberToInvite")}
										/>
									)}
								/>
							</div>

							{(availableEmailsToPresent.length === 1 || availableEmailsToPresent.length === 0) && (
								<div className="sixteen wide column">
									<StringInput
										label={"Email"}
										name={UserInviteFormFields.Email}
										defaultValue={availableEmailsToPresent.length === 1 ? availableEmailsToPresent[0] : undefined}
										registerInput={register}
										required={true}
										placeholder={availableEmailsToPresent.length === 1 ? availableEmailsToPresent[0] : "example@domain.com"}
										disabled={availableEmailsToPresent.length === 1 || selectedDC === undefined}
										onError={(str) => onError(str)}
										onSave={(value) => {
											setValue(UserInviteFormFields.Email, value);
											setIsFormValid(validateForm());
										}}
										{...clickEvent(GA_EVENT_CATEGORY.UserInvite, GA_EVENT_ACTION.Select, "Email")}
										showRequired={!validateForm(UserInviteFormFields.Email)}
									/>
								</div>
							)}

							{(availableEmailsToPresent.length > 1) &&
								<div className="sixteen wide column">
									<label className="flex-spaced">Email
										<span className="message required">
											<i className="tiny icon asterisk" style={{ verticalAlign: "text-top" }} />
											required
										</span>
									</label>
									<Controller
										className={"clickable"}
										control={control}
										name={UserInviteFormFields.Email}
										label={UserInviteFormFields.Email}
										defaultValue={getValues(UserInviteFormFields.Email)}
										render={({ value }) => (
											<ReactSelect
												className={classNames("form-control is-valid clickable", { required: !validateForm(UserInviteFormFields.Email) })}
												isClearable={false}
												multiValueRemove={true}
												isMulti={false}
												controlShouldRenderValue={true}
												options={availableEmailsToPresent.map((email, i) => ({
													key: email,
													value: email,
													label: email,
												}))}
												value={value}
												styles={{
													control: (base) => ({
														...base,
														cursor: "pointer",
													}),
													option: (base) => ({
														...base,
														cursor: "pointer",
													})
												}}
												onChange={(selectedOption: IDropdownOption<string>) => {
													setValue(UserInviteFormFields.Email, selectedOption);
													setIsFormValid(validateForm());
												}}
												{...clickEvent(GA_EVENT_CATEGORY.UserInvite, GA_EVENT_ACTION.Select, "Email")}
											/>
										)}
									/>
								</div>
							}

							<div className="sixteen wide column">
								<label>Role</label>
								<Controller
									className={"clickable"}
									control={control}
									name={UserInviteFormFields.Role}
									label={UserInviteFormFields.Role}
									defaultValue={getValues(UserInviteFormFields.Role)}
									render={({ value }) => (
										<ReactSelect
											className={classNames("form-control is-valid clickable")}
											isClearable={false}
											multiValueRemove={true}
											isMulti={false}
											controlShouldRenderValue={true}
											options={USER_ROLE_OPTIONS}
											value={value}
											styles={{
												control: (base) => ({
													...base,
													cursor: "pointer",
												}),
												option: (base) => ({
													...base,
													cursor: "pointer",
												})
											}}
											onChange={(selectedOption) => {
												setValue(UserInviteFormFields.Role, selectedOption);
												setIsFormValid(validateForm());
											}}
											{...clickEvent(GA_EVENT_CATEGORY.UserInvite, GA_EVENT_ACTION.Select, "MemberRole")}
										/>
									)}
								/>
							</div>
							<div className="full-width pull-right">
								<button
									type="submit"
									className={classNames("ui button", { disabled: !isFormValid }, { loading: isInvitingInProgress })}
									onClick={async (e) => {
										e.preventDefault();
										const formValue = getFormValues();
										let emailValue: string | undefined;
										if (_.isString(formValue.email)) {
											emailValue = formValue.email;
										} else if (_.isObject(formValue.email)) {
											emailValue = formValue.email.value;
										} else {
											return;
										}

										setIsInvitingInProgress(true);
										const updatedUser = await inviteUser(selectedDC?.id!, emailValue, formValue.role.value as DashboardUserRoleType);
										if (updatedUser) {
											onSuccess(INVITE_USER_MESSAGE, INVITE_USER_SUCCESS_TOAST_ID);
											history.push(`/my-account/users`);
										}
										setIsInvitingInProgress(false);
									}}
									{...clickEvent(GA_EVENT_CATEGORY.UserInvite, GA_EVENT_ACTION.Add, "Invite User")}
								>Invite</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
});
export default observer(UserInvitePage);
