import React from "react";
import { render } from "react-dom";
import { Provider as Mobx } from "mobx-react";
import { createBrowserHistory } from "history";
import { syncHistoryWithStore } from "mobx-react-router";
import { Route, Router, Switch } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { AnalyticsProvider } from "use-analytics";
import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";
import TagManager from "react-gtm-module";
import { IntercomProvider } from "react-use-intercom";

import config from "./utils/config";

// V1 imports
import AppV1 from "./v1/app";
import { RootStore as RootStoreV1 } from "./v1/mobx-stores";
import ExternalApp, { BASE_EXTERNAL_ROUTE } from "./v1/external-app";
import PokerApp, { BASE_POKER_ROUTE } from "./v1/pages/poker";

// V2 imports
import AppV2 from "./v2/app";
import { RootStore as RootStoreV2 } from "./v2/mobx-stores";
import VersionSwitchProvider from "adapters/version-switcher";
import { AcumenUiVersion } from "@acumen/database-types";
import SSOApp, { BASE_SSO_ROUTE } from "v2/sso-app";

const { intercomAppId, auth0, trackingId, tagId } = config();

const tagManagerArgs = {
	gtmId: tagId
};

TagManager.initialize(tagManagerArgs);

/* initialize analytics and load plugin */
const analytics = Analytics({
	app: "AcumenDashboard",
	// If google analytics ID set attach plugin
	plugins: trackingId
		? [
			googleAnalytics({
				trackingId,
				customDimensions: {
					customerId: "dimension1",
					userId: "dimension2",
					userName: "dimension3",
				},
				customScriptSrc: "/events.js"
			}),
		]
		: [],
});

const history = syncHistoryWithStore(
	createBrowserHistory(),
	RootStoreV1.routingStore
);

render(
	<AnalyticsProvider instance={analytics}>
		<Router history={history}>
			<Switch>
				<Route path={BASE_EXTERNAL_ROUTE} render={(props) => <External {...props} />} />
				<Route path={BASE_POKER_ROUTE} render={(props) => <Poker {...props} />} />
				<Route path={BASE_SSO_ROUTE} render={(props) => <SSO {...props} />} />
				<Route path="/" component={MainApp} />
			</Switch>
		</Router>
	</AnalyticsProvider>,
	document.getElementById("root")
);

function External(props: any) {
	return (
		<Mobx rootStore={RootStoreV1}>
				<ExternalApp {...props} />
		</Mobx>
	);
}

function Poker(props: any) {
	return (
		<Mobx rootStore={RootStoreV1}>
				<PokerApp {...props} />
		</Mobx >
	);
}

function SSO(props: any) {
	return (
		<Mobx rootStore={RootStoreV2}>
			<SSOApp {...props} />
		</Mobx>
	);
}

function MainApp() {
	return (
		<Auth0Provider onRedirectCallback={(appState) => {
			history.push(
				appState && appState.returnTo ? appState.returnTo : window.location.pathname
			);
		}} {...auth0}>
			<IntercomProvider appId={intercomAppId} autoBoot={true}>
				<Mobx rootStore={RootStoreV2}>
					<VersionSwitchProvider appByVersion={getMainAppByVersion()}/>
				</Mobx>
			</IntercomProvider>
		</Auth0Provider>
	);
}

function getMainAppByVersion(): Record<AcumenUiVersion, JSX.Element> {
	return {
		[AcumenUiVersion.V1]: MainAppV1(),
		[AcumenUiVersion.V2]: MainAppV2()
	};
}

function MainAppV1() {
	return (
		<Mobx rootStore={RootStoreV1}>
			<Router history={history}>
				<AppV1 />
			</Router>
		</Mobx>
	);
}

function MainAppV2() {
	return (
		<Mobx rootStore={RootStoreV2}>
			<AppV2 />
		</Mobx>
	);
}
