import {
	IntercomEventType, IPlanningPokerDeck, IPlanningPokerGame, MixpanelEventType, PokerGameMetricSystem
} from "@acumen/dashboard-common";
import _ from "lodash";
import { observer } from "mobx-react";
import moment from "moment";
import React, { ChangeEvent, FormEvent, Fragment, ReactElement, useCallback, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getRandomDisplayName } from "../adapters/data";
import { useIntercom } from "../adapters/intercom";
import { useMixpanel } from "../adapters/mixpanel";
import { usePoker } from "../adapters/poker";
import { CreateCustomDeckModal } from "../partials/CreateCustomDeckModal";
import { CreateGameMenuBar, CreateMenuItem } from "../partials/CreateGameMenuBar";
import { Meta } from "../partials/Meta";
import { PokerPage } from "../service/internal-router";
import Hero from "../svg-assets/CreateGameHero";
import LoadingIndicator from "../svg-assets/LoadingIndicator";

const GAME_DATE_TIME_FORMAT = "DD/MMM/YYYY hh:mm A";
const DEFAULT_SPRINT_NAME = "My sprint";
const DEFAULT_DISPLAY_NAME = getRandomDisplayName() || "Chuck Norris";
const CREATE_YOUR_OWN_DECK_OPTION = "CREATE_YOUR_OWN_DECK_OPTION";

interface ICreateNewGameProps {
	defaultDisplayName: string;
	startGame: (name: string, metricSystem: PokerGameMetricSystem, deckUuid: string,
		creatorPlayerName: string) => Promise<void>;
	createCustomDeck: (deckName: string, cardsDisplayValues: string[]) => Promise<IPlanningPokerDeck | null>;
}

const CreateNewGame = observer((props: ICreateNewGameProps): ReactElement => {
	const location = useLocation();
	const poker = usePoker();

	const [gameName, setGameName] = useState<string | undefined>(
		DEFAULT_SPRINT_NAME
	);
	const [createdBy, setCreatedBy] = useState<string | undefined>(
		props.defaultDisplayName
	);

	const [gameDeckUuid, setGameDeckUuid] = useState<string | undefined>();
	const [showError, setShowError] = useState<boolean>(false);
	const [showCreateCustomDeckDialog, setShowCreateCustomDeckDialog] = useState<boolean>(false);

	useEffect(() => {
		if (poker && poker.service && (!poker.service.decks.loaded && !poker.service.decks.loading)) {
			poker.service.listDecks().catch();
		}
	}, [poker]);

	useEffect(() => {
		if (poker && poker.service && poker.service.decks.loaded
				&& poker.service.decks.data
				&& !gameDeckUuid) {
			setGameDeckUuid(poker.service.decks.data[0].xid);
		}
	}, [poker?.service?.decks.data]);

	const startGame = useCallback(
		async (event: FormEvent) => {
			event.preventDefault();
			if (
				createdBy &&
				createdBy.trim().length > 0 &&
				gameName &&
				gameName.trim().length > 0
			) {
				setShowError(false);
				await props.startGame(gameName!, PokerGameMetricSystem.Average, gameDeckUuid!, createdBy);
			} else {
				setShowError(true);
			}
		},
		[createdBy, gameName, gameDeckUuid]
	);
	return (
		<Fragment>
			{!(poker?.service?.decks?.loaded) &&
			<div className="w-full flex justify-center items-stretch">
				<div className="py-3 px-5 mr-2 text-2xl font-medium text-gray-900 inline-flex items-center">
					<LoadingIndicator className="inline mr-4 w-6 h-6 text-white animate-spin" />
					Loading new game settings, please wait...
				</div>
			</div>}

			{poker?.service?.decks?.loaded && !(poker.service.decks.data?.length) &&
			<div className="w-full flex justify-center items-stretch">
				<div className="py-3 px-5 mr-2 text-2xl font-medium text-gray-900 inline-flex items-center">
					Oh no! Seems like something's wrong. Please try and refresh the page.
				</div>
			</div>}

			{poker?.service?.decks?.loaded && poker.service.decks.data?.length &&
			<form className="grid grid-cols-1 gap-6 mt-8 md:grid-cols-1">
				<div>
					<label className="block mb-2 text-base text-gray-600">
						Game's name
					</label>
					<input
						type="text"
						placeholder={`e.g. ${DEFAULT_SPRINT_NAME}`}
						className="block w-full px-5 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white shadow-sm border border-gray-300 rounded-md focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
						required={true}
						maxLength={120}
						onChange={(event: ChangeEvent<HTMLInputElement>) =>
							setGameName(event.target.value)
						}
					/>

					{showError && gameName === undefined && (
						<span className="block text-base tracking-wide text-red-500 mt-1">
							Please name this session
						</span>
					)}
				</div>

				<div>
					<label className="block mb-2 text-base text-gray-600">
						Your display name
					</label>
					<input
						type="text"
						placeholder={`e.g. ${DEFAULT_DISPLAY_NAME}`}
						className="block w-full px-5 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white shadow-sm border border-gray-300 rounded-md focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
						required={true}
						onChange={(event: ChangeEvent<HTMLInputElement>) =>
							setCreatedBy(event.target.value)
						}
						defaultValue={props.defaultDisplayName}
					/>

					{showError && createdBy === undefined && (
						<span className="block text-base tracking-wide text-red-500 mt-1">
							Please enter your display name
						</span>
					)}
				</div>

				<div>
					<label className="block mb-2 text-base text-gray-600">
						Voting system
					</label>
					<select
						className="block w-full px-5 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white shadow-sm border border-gray-300 rounded-md focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
						onChange={(event: ChangeEvent<HTMLSelectElement>) => {
							if (event.target.value === CREATE_YOUR_OWN_DECK_OPTION) {
								setShowCreateCustomDeckDialog(true);
							} else {
								setGameDeckUuid(event.target.value);
							}
						}}
					>
						{poker.service.decks.data.map((deck, i) => {
							return <option
								selected={deck.xid === gameDeckUuid}
								value={deck.xid}
								key={i}
								>
								{deck.name} {"("}{deck.cards.map(x => x.displayValue).join(", ")}{")"}
							</option>;
						})}
						<option value={CREATE_YOUR_OWN_DECK_OPTION}>
							Create your own deck
						</option>;
					</select>
				</div>

				<button
					onClick={startGame}
					className="inline-block py-2 px-4 text-lg font-bold text-center text-white transition duration-200 bg-[#399CFF] rounded-lg hover:bg-[#338CE5] ease"
				>
					Start game
				</button>

				<p className="text-sm font-light text-gray-800">
					Want to join an existing game?{" "}
					<Link
						to={{
							pathname: PokerPage.JoinGame,
							search: location.search
						}}
					>
						<span className="font-medium cursor-pointer text-[#399CFF] hover:underline hover:text-[#338CE5]">
							Join here
						</span>
					</Link>
					.
				</p>
			</form>}
			{showCreateCustomDeckDialog && (
				<CreateCustomDeckModal
					createCustomDeck={props.createCustomDeck}
					onClose={() => {
						setGameDeckUuid(poker!.service!.decks!.data![poker!.service!.decks!.data!.length - 1].xid);
						setShowCreateCustomDeckDialog(false);
					}}
				/>
		)}
		</Fragment>
	);
});

interface ILoadedGameProps {
	game: IPlanningPokerGame;
	onJoinGame: (gameXid: string) => void;
}

const LoadedGameItem: React.FC<ILoadedGameProps> = ({
	game,
	onJoinGame,
}) => {
	return (
		<div
			className="border w-full block rounded-lg bg-[#FEFFFF] p-6 drop-shadow-lg hover:drop-shadow-xl cursor-pointer"
			key={game.xid}
			onClick={() => onJoinGame(game.xid)}
		>
			<h5 className="mb-2 text-2xl font-bold tracking-tight text-[#0F2345] hover:underline">{_.truncate(game.name, { length: 30 })}</h5>
			{game.lastUpdateTimeMillis && <p className="font-normal text-[#9AAFB7]">{moment(new Date(game.lastUpdateTimeMillis)).format(GAME_DATE_TIME_FORMAT)}</p>}
		</div>
	);
};

interface IPreviousGamesProps {
	joinGame: (gameXid: string) => Promise<void>;
	listGames: () => Promise<IPlanningPokerGame[] | null>;
}

function PreviousGames(props: IPreviousGamesProps): ReactElement {
	const [loadedGames, setLoadedGames] = useState<IPlanningPokerGame[]>([]);
	const [isLoadingGames, setIsLoadingGames] = useState<boolean>(false);

	useEffect(() => {
		let isSubscribed = true;
		const fetchData = async () => {
			if (isLoadingGames) {
				return;
			}
			setIsLoadingGames(true);
			const listedGames = await props.listGames();
			if (isSubscribed) {
				setLoadedGames(listedGames === null ? [] : listedGames);
				setIsLoadingGames(false);
			}
		};

		fetchData().catch();
		return () => { isSubscribed = false; };
	}, []);

	return (
		<Fragment>
			<div className="mt-8">
				{isLoadingGames === true &&
					<div className="w-full flex justify-center items-stretch">
						<div className="py-3 px-5 mr-2 text-2xl font-medium text-gray-900 inline-flex items-center">
							<LoadingIndicator className="inline mr-4 w-6 h-6 text-white animate-spin" />
							Loading your previous games...
						</div>
					</div>}
				{isLoadingGames === false && loadedGames.length === 0 &&
					<div className="w-full flex justify-center items-stretch">
						<div className="py-3 px-5 mr-2 text-2xl font-medium text-gray-900 items-center">
							Sorry. Unfortunately, we couldn't find any previous games!
						</div>
					</div>}
				{isLoadingGames === false && loadedGames.length > 0 &&
					<div className="grid grid-cols-1 gap-6 md:grid-cols-1 content-center">
						{isLoadingGames === false && loadedGames.length > 0 &&
							<div className="flex flex-col space-y-4">
								{loadedGames.map((lg) => (
									<LoadedGameItem
										key={lg.xid}
										game={lg}
										onJoinGame={props.joinGame}
									/>
								))}
							</div>
						}
					</div>}
			</div>
		</Fragment>
	);
}

const CreateGamePage = () => {
	const location = useLocation();
	const history = useHistory();
	const poker = usePoker();
	const mixpanel = useMixpanel();
	const intercom = useIntercom();

	const currentQsParams = new URLSearchParams(location.search);
	const defaultDisplayName = decodeURIComponent(currentQsParams.get("name") || DEFAULT_DISPLAY_NAME);
	const invitationUrl = decodeURIComponent(currentQsParams.get("invite-url") || `https://example.com/${PokerPage.Game}`);
	const goRetroTeamUuid = currentQsParams.get("goRetroTeamUuid");

	const [selectedMenuItem, setSelectedMenuItem] = useState<CreateMenuItem>(CreateMenuItem.NewGame);

	const createGame = useCallback(
		async (gameName: string, metricSystem: PokerGameMetricSystem, deckUuid: string,
			createdBy: string) => {
			if (poker && poker.service) {
				const createdGame = await poker.service.createGame(
					gameName,
					metricSystem,
					deckUuid,
					createdBy,
					goRetroTeamUuid,
				);

				if (!createdGame) {
					return;
				}

				const trackerEventProperties = {
					gameName,
					displayName: createdBy,
					votingSystem: createdGame.deck.name,
					isDefaultDeck: createdGame.deck.isDefaultDeck,
				};
				if (mixpanel) {
					mixpanel.track(MixpanelEventType.UserStartedPokerGame, trackerEventProperties);
				}
				if (intercom) {
					intercom.track(IntercomEventType.UserStartedPokerGame, trackerEventProperties);
				}
				history.push(`${PokerPage.Game}?id=${createdGame.xid}&invite-url=${invitationUrl}&goRetroTeamUuid=${(goRetroTeamUuid) ? goRetroTeamUuid.toString() : ""}`);
			}
		},
		[history, poker, mixpanel]
	);

	const joinGame = useCallback(
		async (gameXid: string) => {
			if (poker && poker.service) {
				history.push(`${PokerPage.Game}?id=${gameXid}&invite-url=${invitationUrl}&goRetroTeamUuid=${(goRetroTeamUuid) ? goRetroTeamUuid.toString() : ""}`);
			}
		},
		[history, poker, mixpanel]
	);

	const createCustomDeck = useCallback(
		async (deckName: string, cardsDisplayValues: string[]) => {
			if (poker && poker.service) {
				return await poker.service.createDeck(deckName, cardsDisplayValues);
			}
			return null;
		},
		[history, poker, mixpanel]
	);

	const listGames = useCallback(
		async () => {
			if (poker && poker.service) {
				return poker.service.listGames(goRetroTeamUuid);
			}
			return null;
		},
		[history, poker, mixpanel]
	);

	return (
		<div className="antialiased text-gray-600">
			<Meta />
			<section className="bg-white">
				<div className="flex justify-center min-h-screen">
					<div className="flex items-center w-full h-full max-w-3xl p-8 mx-auto lg:px-12 lg:w-3/5">
						<div className="w-full">
							<CreateGameMenuBar
								selectedMenuItem={selectedMenuItem}
								onSelectedMenuItem={setSelectedMenuItem}
							/>
							{selectedMenuItem === CreateMenuItem.NewGame && <CreateNewGame defaultDisplayName={defaultDisplayName} startGame={createGame} createCustomDeck={createCustomDeck} />}
							{selectedMenuItem === CreateMenuItem.PreviousGames && <PreviousGames joinGame={joinGame} listGames={listGames} />}
						</div>
					</div>
					<div className="hidden bg-cover lg:block lg:w-2/5 bg-gradient-to-br from-[#FF77D9] to-[#0049C2]">
						<div className="flex items-center justify-center h-screen">
							<Hero />
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default CreateGamePage;
