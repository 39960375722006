import React, { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import _ from "lodash";
import MyUser from "v2/layout/navigation/my-user";
import { useStores } from "v2/mobx-stores";
import { WorkforceHealthMetrics } from "@acumen/database-types";
import { STATUS_LOADING, useIsAsyncDataLoaded } from "../../hooks/async-state";
import {
	DevStatsColumnConfigModal
} from "./components/developer-stats-column-config-modal/developer-stats-column-config-modal";
import { DeveloperStatsTable } from "./components/developer-stats-table/developer-stats-table";
import { WorkforceHealthHeader } from "./components/workforce-health-header/workforce-health-header";
import { DevStatColumn, devStatColumns, WHF_METRIC_NAMES_BY_CATEGORIES } from "./dev-stat-columns";
import {
	useBadgesState,
	useDeveloperSelectedMetricsState,
	useDeveloperStatsChartData,
	useDeveloperStatsState,
	useTeamMembersState,
	useTeamsState
} from "./hooks/fetchers";
import { useDevelopersState } from "./hooks/useDevelopersState";
import {
	WorkforceHealthExportButton
} from "./components/workforce-health-export-button/workforce-health-export-button";
import "./workforce-health.scss";
import { IDeveloperInfo } from "./types";
import { DeveloperMetricBadgeFilter, DevStatsFilterType, filterDevelopersByType } from "./components/developer-metric-badge-filter/developer-metric-badge-filter";
import LoadingIndicator from "v2/components/loader/loader";
import { useHistory, useLocation } from "react-router-dom";
import { MetricModal } from "./components/developer-metric-modal/developer-metric-modal";

const MAX_MEMBERS_TO_SHOW_IN_HEADER = 9;

// Note: while the user may change columns, we currently do not allow them to change charts.
const DEFAULT_CHARTS_METRICS: WorkforceHealthMetrics[] = [
	WorkforceHealthMetrics.ActiveDays,
	WorkforceHealthMetrics.CompletedHighPriorityTasks,
	WorkforceHealthMetrics.AveragePRCodingAndReviewTime,
	WorkforceHealthMetrics.Velocity,
	WorkforceHealthMetrics.ReviewedPRs,
	WorkforceHealthMetrics.AveragePRSize
];

const defaultChartsMetrics = devStatColumns.filter(devStatsColumn => (
	DEFAULT_CHARTS_METRICS.includes(devStatsColumn.dashboardMetricName)
));

const QS_PARAM_TEAM = "team";

export const createSearchParamsForPage = (teamIds: string[]) => {
	const params = new URLSearchParams({});
	if (teamIds && teamIds.length > 0) {
		teamIds.forEach(x => params.append(`${QS_PARAM_TEAM}[]`, x));
	}

	return params;
};

export const WorkforceHealth = observer(() => {
	const { developerStatsStore, featureFlagsStore } = useStores();
	const location = useLocation();
	const history = useHistory();
	const currentQsParams = new URLSearchParams(location.search);
	const [selectedTeamIds, setSelectedTeamIds] = useState<string[]>(currentQsParams.getAll(`${QS_PARAM_TEAM}[]`) ?? []);
	const teamsState = useTeamsState();
	const teamMembersState = useTeamMembersState();

	const [selectedMetricColumn, setSelectedMetricColumn] = useState<DevStatColumn | null>(null);
	const [selectedMetricDcId, setSelectedMetricDcId] = useState<string | null>(null);

	const selectedContributors = useMemo(() => {
		const teams = teamsState?.data ?? [];
		const teamMembers = teamMembersState?.data.members;
		const dcLabels = teamMembersState?.data.labels;

		let teamToFilter = selectedTeamIds;
		if (teams.length === selectedTeamIds.length || selectedTeamIds.length === 0) {
			teamToFilter = teams.map(team => team.id);
		}

		const contributors: IDeveloperInfo[] = _.uniqBy(teamMembers.map(tm => ({
			dataContributorId: tm.dataContributorId,
			avatarUrl: tm.avatarUrl,
			displayName: tm.displayName,
			labels: dcLabels.get(tm.dataContributorId) ?? []
		})), tm => tm.dataContributorId);
		return contributors.filter(contributor => {
			const contributorTeamIds = teamMembers
				.filter(membership => membership.dataContributorId === contributor.dataContributorId)
				.map(membership => membership.teamId);
			return contributorTeamIds.some(teamId => teamToFilter.includes(teamId));
		});
	}, [selectedTeamIds, teamMembersState, teamsState]);

	const selectedContributorsIds = useMemo(
		() => selectedContributors.map(dev => dev.dataContributorId), [selectedContributors]);

	const [configModalOpenState, setConfigModalOpenState] = useState<boolean>(false);
	const [metricModalOpenState, setMetricModalOpenState] = useState<boolean>(false);
	const handleAddColumnClick = useCallback(() => setConfigModalOpenState(true), []);
	const handleCellOnClick = (dcId: string, metricColumn: DevStatColumn) => {
		setSelectedMetricColumn(metricColumn);
		setSelectedMetricDcId(dcId);
		setMetricModalOpenState(true);
	};
	const isAddRemoveColumnEnabled = featureFlagsStore.isFeatureEnabled("ui-show-add-remove-column");

	const selectedMetricsState = useDeveloperSelectedMetricsState();
	const [selectedFilterType, setSelectedFilterType] = useState<DevStatsFilterType | undefined>();

	const selectedDevStatsColumns = useMemo(() => {
		return devStatColumns.filter(devStatsColumn => (
			developerStatsStore.workForceSelectedMetrics?.includes(devStatsColumn.dashboardMetricName)
		));
	}, [developerStatsStore.workForceSelectedMetrics]);

	const devStatsDataToFetch = useMemo(() => {
		return devStatColumns.filter(devStatsColumn => (
			_.uniq(DEFAULT_CHARTS_METRICS.concat(developerStatsStore.workForceSelectedMetrics ?? []))
				.includes(devStatsColumn.dashboardMetricName)
		));
	}, [developerStatsStore.workForceSelectedMetrics]);

	const developerStatsState = useDeveloperStatsState(selectedContributorsIds, devStatsDataToFetch);
	const developerStatsChartDataState = useDeveloperStatsChartData(selectedContributorsIds, defaultChartsMetrics);
	const badgesDataState = useBadgesState(selectedContributorsIds);
	const developersState = useDevelopersState(teamsState, badgesDataState, teamMembersState);
	const isDataLoaded = useIsAsyncDataLoaded(
		selectedMetricsState,
		developerStatsState,
		developerStatsChartDataState,
		badgesDataState,
		developersState,
	);

	const selectedDevelopers = useMemo(() => {
		if (developersState.status === STATUS_LOADING) {
			return [];
		}

		return developersState.data.filter(developer => selectedContributorsIds.includes(developer.info.dataContributorId));
	}, [developersState, selectedContributorsIds]);

	const updateQueryString = (teamIds: string[]) => {
		const params = createSearchParamsForPage(teamIds);
		history.replace({ pathname: location.pathname, search: params.toString() });
	};
	return (
		<div className="workforce-health">
			<div className="header-container">
				<div className="ui fluid grid">
					<div className="eight wide column">
						<h1 className="workforce-health-title">
							<span>Workforce Health</span>
							<WorkforceHealthExportButton
								developers={developersState.data ?? []}
								metricData={developerStatsState.data}
								disabled={!isDataLoaded}
							/>
						</h1>
						<span className="workforce-last-generated">
							{badgesDataState.data.config?.startTime
								&& `Last generated at ${moment.tz(badgesDataState.data.config?.startTime, badgesDataState.data.config?.configs.devStats.timezone).format("MMM Do YYYY, HH:mm zz")}`}
						</span>
					</div>
					<div className="eight wide right aligned column">
						<MyUser/>
					</div>
				</div>
			</div>
			<div className="workforce-health-note">
				Based on data from the past 30 days
			</div>
			<div className="developer-stats-cards-container">
				<WorkforceHealthHeader
					selectedDashboardMetrics={defaultChartsMetrics}
					teams={teamsState.data}
					contributors={selectedContributors}
					maxMembersToShow={MAX_MEMBERS_TO_SHOW_IN_HEADER}
					developerBadgesData={badgesDataState.data}
					developerStatsChartData={developerStatsChartDataState.data}
					selectedTeamIds={selectedTeamIds}
					onSelectedTeamsChange={(sti) => {
						setSelectedTeamIds(sti);
						updateQueryString(sti);
					}}
				/>
			</div>

			<div>
				<MetricModal
					isOpen={metricModalOpenState}
					setIsOpen={setMetricModalOpenState}
					selectedMetricColumn={selectedMetricColumn!}
					dcId={selectedMetricDcId!}
				/>
			</div>

			{isAddRemoveColumnEnabled && (
				<div>
					<DevStatsColumnConfigModal
						isOpen={configModalOpenState}
						setIsOpen={setConfigModalOpenState}
						selectedMetrics={developerStatsStore.workForceSelectedMetrics ?? []}
						updateSelectedMetrics={async (dcId, modalSelectedMetrics) => {
							await developerStatsStore.updateWorkForceSelectedMetrics(dcId, modalSelectedMetrics);
						}}
						metricNamesByDisplayCategories={WHF_METRIC_NAMES_BY_CATEGORIES}
					/>
				</div>
			)}
			{selectedDevelopers && selectedDevelopers.length > 0 && <div className="developer-metric-badge-filter-container">
				<h3 className="developer-metric-badge-filter-title">
					Breakdown
				</h3>
				<DeveloperMetricBadgeFilter onSelectedFilterItem={setSelectedFilterType} selectedFilterItem={selectedFilterType} developers={selectedDevelopers} />
			</div>}
			<div className="developer-stats-table-container">
				<DeveloperStatsTable
					selectedDashboardMetrics={selectedDevStatsColumns}
					developers={filterDevelopersByType(selectedDevelopers, selectedFilterType)}
					metricData={developerStatsState.data}
					onAddColumnClick={isAddRemoveColumnEnabled ? handleAddColumnClick : undefined}
					onCellClick={handleCellOnClick}
					estimationMethod={badgesDataState.data.config?.configs.devStats.estimationMethod}
				/>
				{!isDataLoaded && <LoadingIndicator isActive={!isDataLoaded} local={true}/>}
			</div>
		</div>
	);
});
