import React from "react";
import { observer } from "mobx-react";
import "./sso.scss";
import { OktaAuth } from "@okta/okta-auth-js";

export interface ISSOPageProps {
	oktaAuth: OktaAuth;
	email: string;
	customerId: string;
}

function SSOAuthPage(props: ISSOPageProps) {
	return (
		<div>
			<div>
				SSO Page
			</div>
			<div>
				<button
					onClick={async () => props.oktaAuth.signInWithRedirect({
						state: JSON.stringify({
							email: props.email,
							customerId: props.customerId
						})
					})}
				>
					Okta auth
				</button>
			</div>
		</div>
	);
}

export default observer(SSOAuthPage);
