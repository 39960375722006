import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { Accordion, Menu, Sidebar } from "semantic-ui-react";
import { useStores } from "../../mobx-stores";
import { observer } from "mobx-react";
import code from "./../../components/svg-assets/menu-icons/code.svg";
import analytics from "./../../components/svg-assets/menu-icons/analytics.svg";
import slack from "./../../components/svg-assets/menu-icons/slack.svg";
import settings from "../../components/svg-assets/menu-icons/settings.svg";
import retrospective from "../../components/svg-assets/menu-icons/sprint-retro.svg";
import "./style.scss";
import { FeatureFlags } from "../../feature-flags";

enum PARENT_ITEMS {
	ONGOING = "ONGOING",
	RETROSPECTIVE = "RETROSPECTIVE",
	ANALYTICS = "ANALYTICS",
	SLACK_NOTIFICATION = "SLACK_NOTIFICATION",
	SETTINGS = "SETTINGS",
	ORG_ANALYSIS = "ORG_ANALYSIS",
}

interface NavigationParentItemProps { [key: string]: { key: PARENT_ITEMS, childPath: string[] }; }

const navigationParentItems: NavigationParentItemProps = {
	ongoing: { key: PARENT_ITEMS.ONGOING, childPath: ["/", "/my-team", "/my-team/dashboard", "/my-team/views/tasks", "/my-team/views/pull-requests"] },
	retrospective: { key: PARENT_ITEMS.RETROSPECTIVE, childPath: ["/my-team/iteration-review", "/my-team/work-diff"] },
	analytics: { key: PARENT_ITEMS.ANALYTICS, childPath: ["/my-team/analytics", "/org-analytics/velocity", "/org-analytics/quality", "/org-analytics/output", "/org-analytics/dev-stats"] },
	slackNotification: { key: PARENT_ITEMS.SLACK_NOTIFICATION, childPath: ["/slack-notifications"] },
	settings: { key: PARENT_ITEMS.SETTINGS, childPath: ["/my-account/account", "/my-account/users", "/my-account/teams", "/my-account/contributors", "/my-account/integrations", "/my-account/insights", "/my-account/general-settings"] },
	orgAnalysis: { key: PARENT_ITEMS.ORG_ANALYSIS, childPath: ["/org-analytics/velocity", "/org-analytics/quality", "/org-analytics/output", "/org-analytics/dev-stats"] },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default observer(() => {
	const { width } = useWindowDimensions();
	const {
		authStore: { isUserAdmin, isUserManagerOrAbove, isUserExecutiveOrAbove, isUserShouldExpandDevStats },
		featureFlagStore: {
			isInitialized: isFeatureFlagStoreInitialized,
			isFeatureEnabled
		}
	} = useStores();
	const { pathname } = useLocation();

	const sideArrow = (isActive: boolean) => {
		return (
			isActive ? <i aria-hidden="true" className="chevron down icon" /> :
				<i aria-hidden="true" className="chevron right icon" />
		);
	};

	const innerLink = (title: string, path: string) => (
		<NavLink to={path} className="link item">
			<span>{title}</span>
		</NavLink>
	);

	const [activeItems, setActiveItems] = useState<PARENT_ITEMS[]>([PARENT_ITEMS.ONGOING]);

	const handleToggleMenu = (menuKey?: PARENT_ITEMS) => {
		if (menuKey === undefined) {
			return;
		}
		const updatedActiveItems = [...activeItems];
		const index = updatedActiveItems.indexOf(menuKey);

		if (index === -1) {
			updatedActiveItems.push(menuKey);
		} else {
			updatedActiveItems.splice(index, 1);
		}
		setActiveItems(updatedActiveItems);

	};

	useEffect(() => {
		const updatedActiveItems = [];
		for (const field of Object.keys(navigationParentItems)) {
			const navItem = navigationParentItems[field];
			if (navItem.childPath.includes(pathname)) {
				updatedActiveItems.push(navItem.key);
			}
		}
		setActiveItems(updatedActiveItems);
	}, []);

	return (
		<Sidebar
			as={Menu}
			visible={(width ? (width > 1000 && isFeatureFlagStoreInitialized) : false)}
			className="main-sidebar push"
			inverted={true}
			vertical={true}
		>
			<div className="logo-header">
				<Link to="/">
					<img className="acumen logo" src="/assets/images/logo-white.svg" alt="Acumen" />
				</Link>
			</div>
			<Accordion exclusive={false}>
				{
					isFeatureEnabled(FeatureFlags.AcumenDashboardPage) && (
						<Accordion.Title
							onClick={() => handleToggleMenu(navigationParentItems.ongoing.key)}
						>
							<div className="item link">
								<img className="menu-icon" src={code} alt="" />
								<span>Ongoing</span>
								{sideArrow(activeItems.includes(navigationParentItems.ongoing.key))}
							</div>
						</Accordion.Title>
					)
				}
				{
					isFeatureEnabled(FeatureFlags.AcumenDashboardPage) && (
						<Accordion.Content active={activeItems.includes(navigationParentItems.ongoing.key)}>
							<NavLink to="/my-team/dashboard" exact={true} className="item link">
								<span>Dashboard</span>
							</NavLink>
							<NavLink to="/my-team/views/tasks" exact={true} className="item link">
								<span>Tasks</span>
							</NavLink>
							<NavLink to="/my-team/views/pull-requests" exact={true} className="item link">
								<span>Pull requests</span>
							</NavLink>
						</Accordion.Content>
					)
				}
				{
					isFeatureEnabled(FeatureFlags.AcumenRetrospectivePage) && (
						<Accordion.Title
							onClick={() => handleToggleMenu(navigationParentItems.retrospective.key)}
						>
							<div className="item link">
								<img className="menu-icon" src={retrospective} alt="" />
								<span>Retrospective</span>
								{sideArrow(activeItems.includes(navigationParentItems.retrospective.key))}
							</div>
						</Accordion.Title>
					)
				}
				{
					isFeatureEnabled(FeatureFlags.AcumenRetrospectivePage) && (
						<Accordion.Content active={activeItems.includes(navigationParentItems.retrospective.key)}>
							<NavLink to="/my-team/iteration-review" exact={true} className="item link">
								<span>Iteration review</span>
							</NavLink>
							<NavLink to="/my-team/work-diff" exact={true} className="item link">
								<span>Work diff</span>
							</NavLink>
						</Accordion.Content>
					)
				}

				{isUserExecutiveOrAbove &&
					isFeatureEnabled(FeatureFlags.AcumenTeamAnalyticsPage) &&
					isFeatureEnabled(FeatureFlags.AcumenOrganizationAnalyticsPage) && (
						<Accordion.Title
							onClick={() => handleToggleMenu(navigationParentItems.analytics.key)}
						>
							<div className="item link" >
								<img className="menu-icon" src={analytics} alt="" />
								<span>Analytics</span>
								{sideArrow(activeItems.includes(navigationParentItems.analytics.key))}
							</div>
						</Accordion.Title>
					)}
				{isUserExecutiveOrAbove &&
					isFeatureEnabled(FeatureFlags.AcumenTeamAnalyticsPage) &&
					isFeatureEnabled(FeatureFlags.AcumenOrganizationAnalyticsPage) && (
						<Accordion.Content active={activeItems.includes(navigationParentItems.analytics.key)}>
							<NavLink to="/my-team/analytics" exact={true} className="item link"
							>
								<span>Team analytics</span>
							</NavLink>
							<span>
								<div className="item link"
									onClick={() => {
										handleToggleMenu(navigationParentItems.orgAnalysis.key);
									}}
								>
									<span>Org. analytics</span>
									{sideArrow(activeItems.includes(navigationParentItems.orgAnalysis.key))}
								</div>
								{activeItems.includes(navigationParentItems.orgAnalysis.key) &&
									<div className="org-analytics-menu">
										<NavLink to="/org-analytics/velocity" exact={true}
											className={"item link"}>
											<span>Velocity</span>
										</NavLink>
										<NavLink to="/org-analytics/quality" exact={true}
											className="item link">
											<span>Quality</span>
										</NavLink>
										<NavLink to="/org-analytics/output" exact={true}
											className="item link">
											<span>Output</span>
										</NavLink>
										{isUserShouldExpandDevStats && (
											<NavLink to="/org-analytics/dev-stats" exact={true}
												className="item link">
												<span>Dev Stats</span>
											</NavLink>
										)}
									</div>
								}
							</span>
						</Accordion.Content>
					)}
				{!isUserExecutiveOrAbove && isFeatureEnabled(FeatureFlags.AcumenTeamAnalyticsPage) && (
					<Accordion.Title>
						<NavLink to="/my-team/analytics" exact={true} className="item link">
							<img className="menu-icon" src={analytics} alt="" />
							<span>Analytics</span>
						</NavLink>
					</Accordion.Title>
				)}
				{isUserExecutiveOrAbove &&
					!isFeatureEnabled(FeatureFlags.AcumenTeamAnalyticsPage) &&
					isFeatureEnabled(FeatureFlags.AcumenOrganizationAnalyticsPage) && (
						<Accordion.Title>
							<NavLink to="/org-analytics" exact={true} className="item link">
								<img className="menu-icon" src={analytics} alt="" />
								<span>Org. Analytics</span>
							</NavLink>
						</Accordion.Title>
					)}
				{isFeatureEnabled(FeatureFlags.AcumenSlackNotificationsPage) && isUserManagerOrAbove && (
					<Accordion.Title>
						<NavLink to="/slack-notifications" exact={true} className="item link">
							<img className="menu-icon" src={slack} alt="" />
							<span>Slack notifications</span>
						</NavLink>
					</Accordion.Title>
				)}
				<Accordion.Title
					onClick={() => handleToggleMenu(navigationParentItems.settings.key)}
				>
					<div className="item link">
						<img className="menu-icon" src={settings} alt="" />
						<span>Settings</span>
						{sideArrow(activeItems.includes(navigationParentItems.settings.key))}
					</div>
				</Accordion.Title>
				<Accordion.Content active={activeItems.includes(navigationParentItems.settings.key)}>
					{isUserAdmin && (innerLink("General", "/my-account/general-settings"))}
					{isUserAdmin && (innerLink("Users", "/my-account/users"))}
					{innerLink("Teams", "/my-account/teams")}
					{isUserAdmin && (innerLink("Contributors", "/my-account/contributors"))}
					{isUserAdmin && (innerLink("Integrations", "/my-account/integrations"))}
					{isUserAdmin && (innerLink("Insights", "/my-account/insights"))}
					{innerLink("Account", "/my-account/account")}
					{innerLink("Jira statuses", "/my-account/jira-statuses")}
				</Accordion.Content>
			</Accordion>
		</Sidebar >
	);
});
