import { round } from "@acumen/common";
import {
	DashboardRetroReportTaskData,
	IDashboardStatus,
	IDashboardTaskDetailed,
	IDashboardTaskStatusChange,
	IRetroReportTasksStateResponse,
	TaskEstimationMethod
} from "@acumen/dashboard-common";
import moment from "moment";
import TaskEndStatus from "../../enums/TaskEndStatus";

export enum TaskCategory {
	CarryOver = "CarryOver",
	Planned = "Planned",
	Unplanned = "Unplanned",
}

export const taskCategoryToLabel: Record<TaskCategory, string> = {
	[TaskCategory.CarryOver]: "Carry Over",
	[TaskCategory.Planned]: "Planned",
	[TaskCategory.Unplanned]: "Unplanned",
};

export const taskCategoryToColor: Record<TaskCategory, string> = {
	[TaskCategory.CarryOver]: "purple",
	[TaskCategory.Planned]: "blue",
	[TaskCategory.Unplanned]: "red",
};

export const createTaskStartStatusGetter = (
	isExtended: boolean,
	statusDiff: Record<string, IDashboardTaskStatusChange> | undefined,
	statusIdToStatus: Record<string, IDashboardStatus>
) => {
	return (item: DashboardRetroReportTaskData | IDashboardTaskDetailed) => {
		const taskStatus = isExtended && statusDiff ? statusDiff[item.entityId] : undefined;
		const startStatus = taskStatus?.startStatus ? statusIdToStatus[taskStatus?.startStatus] : null;

		return startStatus?.customerStatusName ?? startStatus?.acumenStatus ?? "";
	};
};

export const createTaskEndStatusGetter = (
	isExtended: boolean,
	statusDiff: Record<string, IDashboardTaskStatusChange> | undefined,
	statusIdToStatus: Record<string, IDashboardStatus>,
	issueIdsByCategory: IRetroReportTasksStateResponse | undefined
) => {
	return (item: DashboardRetroReportTaskData | IDashboardTaskDetailed) => {
		if (isTaskRemoved(item, issueIdsByCategory)) {
			return TaskEndStatus.Removed;
		}

		const taskStatus = isExtended && statusDiff ? statusDiff[item.entityId] : undefined;
		const endStatus = taskStatus?.endStatus ? statusIdToStatus[taskStatus?.endStatus] : null;

		return endStatus?.customerStatusName ?? endStatus?.acumenStatus ?? "";
	};
};

export const isTaskRemoved = (
	task: DashboardRetroReportTaskData | IDashboardTaskDetailed,
	issueIdsByCategory: IRetroReportTasksStateResponse | undefined
) => {
	return issueIdsByCategory && issueIdsByCategory.removed.includes(task.entityId);
};

const SECONDS_IN_HOUR = 3600;

export const IterationReview = {
	TasksTable: {
		// Note: Takes issues, grouped by categories, and a property name which will be used to retrieve task ID from an entity.
		// Note: Returns a category getter function.
		createTaskStateInSprintGetter<TaskIdPropertyName extends string>(
			issueIdsByCategory: IRetroReportTasksStateResponse | undefined,
			taskIdPropertyName: TaskIdPropertyName
		) {
			// Note: Takes an entity with task ID and returns its' category.
			return (entity: { [Key in TaskIdPropertyName]: string }) => {
				const taskCategory = this.findTaskCategory(issueIdsByCategory, entity[taskIdPropertyName]);

				return !taskCategory ? "" : taskCategoryToLabel[taskCategory];
			};
		},

		findTaskCategory(
			issueIdsByCategory: IRetroReportTasksStateResponse | undefined,
			taskId: string
		) {
			if (!issueIdsByCategory) {
				return undefined;
			}

			if (issueIdsByCategory.carryOver.includes(taskId)) {
				return TaskCategory.CarryOver;
			}

			if (issueIdsByCategory.planned.includes(taskId)) {
				return TaskCategory.Planned;
			}

			return TaskCategory.Unplanned;
		},
	},

	Charts: {
		ToolTip: {
			getFormattedColumnTooltip(seriesName: string, firstColor: string, secondColor: string,
				decimalPlaces: string, chartDataSuffix: string) {
				return {
					pointFormat: (
						`<p class="tooltip-row">
						<span class="bullet" style="background: linear-gradient(${firstColor}, ${secondColor})"></span>
						<span class="series-name">${seriesName}</span>
						<span class="right"><b>{point.y:.${decimalPlaces}f} ${chartDataSuffix}</b></span><br/>
					</p>`
					)
				};
			},

			getSimpleColumnTooltip(seriesName: string, color: string, value: number | undefined) {
				return (
					`<p class="tooltip-row">
					<span class="bullet" style="background-color:${color}"></span>
					<span class="series-name">${seriesName}</span>
					<span class="right"><b>${value}</b></span><br/>
				</p>`
				);
			},
		},

		Formatters: {
			metricKeyToTime(key: string, timezone: string) {
				return moment.tz(key, "L", timezone).startOf("day").toDate().getTime();
			},

			transformChartValue(value: number | string, estimationMethod: TaskEstimationMethod) {
				if (!value) {
					return 0;
				}

				const valueAsNumber = typeof value === "string" ? parseInt(value, 10) : value;
				switch (estimationMethod) {
					case TaskEstimationMethod.TimeBased: {
						const hoursInDate = (valueAsNumber / SECONDS_IN_HOUR);
						return round(hoursInDate);
					}
					case TaskEstimationMethod.StoryPoints:
					default: {
						return valueAsNumber;
					}
				}
			},

			dateValueTuple(dateAsString: string, value: number, timezone: string, estimationMethod: TaskEstimationMethod) {
				return [this.metricKeyToTime(dateAsString, timezone), this.transformChartValue(value, estimationMethod)];
			},
		}
	},
};
