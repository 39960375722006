import { BaseCustomerApiClient } from "./base-customer-api-client";
import { postData } from "../../../services/fetch-helpers";
import { CommonEndPoints } from "@acumen/dashboard-common";
import urlJoin from "url-join";

export const OWNABLE_ENTITIES_ROUTE = "/ownable-entities";

export class OwnableEntitiesClient extends BaseCustomerApiClient {

	public update = async (entity: any) => {
		return await postData<any, void, any>(
			this.createCustomerEntityRoute(urlJoin(OWNABLE_ENTITIES_ROUTE, entity.id, CommonEndPoints.UPDATE)),
			this.token,
			entity
		);
	}

}
