import React from "react";

import { Avatar } from "../../../avatar";
import { capitalizeName } from "../../../../../utils/string";
import BaseDropdownOption from "./base-dropdown-option";
import { UserOption } from "../types";

interface UsersDropdownOptionProps {
	user: UserOption;
	isSelected: boolean;
	onToggle: (id: string) => void;
}

const UsersDropdownOption = (props: UsersDropdownOptionProps) => {
	const { user, isSelected, onToggle } = props;

	return (
		<BaseDropdownOption
			value={user.id}
			avatar={<Avatar className="avatar-selector-item-avatar" user={user}/>}
			label={user.displayName && capitalizeName(user.displayName)}
			isSelected={isSelected}
			onToggle={onToggle}
		/>
	);
};

export default UsersDropdownOption;
