import React, { useCallback } from "react";
import classNames from "classnames";
import { CustomSelectOption, CustomSelectValue } from "../types";
import Checkbox from "../../checkbox/checkbox";
import "./custom-select-dropdown-item.scss";

export interface CustomSelectDropdownItemProps<T extends CustomSelectValue> {
	option: CustomSelectOption<T>;
	isSelected: boolean;
	showCheckbox?: boolean;
	highlightSelected?: boolean;
	onClick: (value: T | null) => void;
}

export const CustomSelectDropdownItem = <T extends CustomSelectValue>(props: CustomSelectDropdownItemProps<T>) => {
	const { option, isSelected, showCheckbox, highlightSelected, onClick } = props;
	const { value } = option;
	const handleClick = useCallback(() => onClick(value), [value, onClick]);
	const className = classNames(
		"custom-select-dropdown-item",
		isSelected && highlightSelected && "variant-selected",
	);

	return (
		<li className={className} onClick={handleClick}>
			{showCheckbox && (
				<Checkbox
					className="custom-select-dropdown-item-checkbox"
					checked={isSelected}
					onChange={noop}
				/>
			)}
			<span className="custom-select-dropdown-item-text">{option.label ?? value}</span>
		</li>
	);
};

const noop = () => void 0;
