import React from "react";
import { Button, Confirm } from "semantic-ui-react";

interface IConfirmationModalProps {
	header?: string;
	content?: string;
	open: boolean;
	onConfirm: () => void;
	onCancel: () => void;
	confirmButton?: string;
	className?: string;
	cancelButton?: string;
	showLoading?: boolean;
}

// tslint:disable-next-line: variable-name
const ConfirmationModal = (props: IConfirmationModalProps) => {

	const { header, content, open, onCancel, onConfirm, cancelButton, confirmButton, showLoading } = { ...props };

	return (
		<Confirm
			data-testid="confirmation-modal"
			className={"warning"}
			open={open}
			cancelButton={cancelButton}
			confirmButton={
				<Button
					loading={showLoading}
				>
				{confirmButton}
				</Button>
			}
			header={header}
			content={content}
			onCancel={onCancel}
			onConfirm={onConfirm}
		/>
	);
};
export default ConfirmationModal;
