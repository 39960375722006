import _ from "lodash";
import React, { CSSProperties, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import TriggerOnClickOutside from "../../ux-effects/trigger-on-click-outside";
import { CustomSelectDropdownItem } from "../custom-select-dropdown-item/custom-select-dropdown-item";
import { CustomSelectOption, CustomSelectValue } from "../types";
import "./custom-select-dropdown.scss";

export interface CustomSelectDropdownProps<T extends CustomSelectValue> {
	triggerRef: React.RefObject<HTMLElement>;
	options: Array<CustomSelectOption<T>>;
	value: T[];
	multiple?: boolean;
	showSelectAllOption: boolean;
	selectAllOptionText: string;
	isAllSelected: boolean;
	shouldGroup?: boolean;
	onChange?: (value: T[], groupName?: string) => void;
	onToggleSelectAll: () => void;
	onClose: () => void;
}

const NO_CATEGORY_VALUE = "no_category";

export const CustomSelectDropdown = <T extends CustomSelectValue>(props: CustomSelectDropdownProps<T>) => {
	const {
		triggerRef,
		options,
		value,
		multiple,
		isAllSelected,
		showSelectAllOption,
		selectAllOptionText,
		onChange,
		onToggleSelectAll,
		onClose,
		shouldGroup
	} = props;
	const selectAllOption = useMemo(() => ({ value: "", label: selectAllOptionText, shouldGroup: undefined }), [selectAllOptionText]);
	const dropdownRef = useRef<HTMLUListElement | null>(null);
	const [styles, setStyles] = useState<CSSProperties>({
		visibility: "hidden",
	});

	useEffect(() => {
		const trigger = triggerRef.current;
		const dropdown = dropdownRef.current;

		if (trigger && dropdown) {
			const { bottom, right } = trigger.getBoundingClientRect();

			setStyles({
				top: bottom + window.scrollY,
				left: right - dropdown.getBoundingClientRect().width,
			});
		}
	}, []);

	const groupedOptions = useMemo(() => {
		if (!shouldGroup) {
			return undefined;
		}
		const grouped = _.groupBy(options, item => {
			return _.get(item, "group", NO_CATEGORY_VALUE);
		});
		if (Object.entries(grouped).length === 1) {
			return undefined;
		}
		return grouped;
	}, [options, shouldGroup]);

	function renderDropdownOptions(o: Array<CustomSelectOption<T>>, groupName?: string) {
		return (
			<>
				{o.map(option => (
					<CustomSelectDropdownItem
						key={option.value ?? ""}
						option={option}
						isSelected={option.value !== null && value.includes(option.value)}
						showCheckbox={multiple}
						highlightSelected={!multiple}
						onClick={(newValue: T | null) => {
							if (newValue === null) {
								onChange?.([], groupName);
							} else if (multiple) {
								if (value.includes(newValue)) {
									onChange?.(value.filter(v => v !== newValue));
								} else {
									onChange?.([...value, newValue], groupName);
								}
							} else {
								onChange?.([newValue], groupName);
							}

							if (!multiple) {
								onClose();
							}
						}}
					/>
				))}
			</>
		);
	}

	return createPortal((
		<TriggerOnClickOutside onTrigger={onClose}>
			<ul ref={dropdownRef} style={styles} className="custom-select-dropdown">
				{showSelectAllOption && (
					<CustomSelectDropdownItem
						option={selectAllOption}
						isSelected={isAllSelected}
						showCheckbox={multiple}
						onClick={onToggleSelectAll}
					/>
				)}
				{groupedOptions && <div>
					{Object.entries(groupedOptions).map((g) => (
						<div key={g[0]}>
							{g[0] !== NO_CATEGORY_VALUE && <li className="group-title">
								<span className="custom-select-dropdown-item-text">{g[0]}</span>
							</li>}
							{renderDropdownOptions(g[1], g[0] === NO_CATEGORY_VALUE ? undefined : g[0])}
						</div>
					))}
				</div>}
				{!groupedOptions && renderDropdownOptions(options)}
			</ul>
		</TriggerOnClickOutside>
	), document.body);
};
