import { DependencyList, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { STATUS_FAILURE, STATUS_LOADING, STATUS_SUCCESS } from "./constants";
import { AggregatedAsyncState, AsyncState } from "./types";

// NOTE: calls the given async function and provides an up-to-date { data, isLoading, error} state
export function useAsyncState<T>(asyncFn: () => Promise<T>, params: {
	initialState: T
	disabled?: boolean;
	deps?: DependencyList;
}): AsyncState<Exclude<T, null>, Exclude<T, null>>;
export function useAsyncState<T>(asyncFn: () => Promise<T>, params?: {
	disabled?: boolean;
	deps?: DependencyList
}): AsyncState<T, null>;
export function useAsyncState<T>(asyncFn: () => Promise<T>, params: {
	initialState?: T | null;
	disabled?: boolean;
	deps?: DependencyList;
} = {}): AsyncState<T, T | null> {
	const { initialState = null, disabled = false, deps = [] } = params;
	const [status, setStatus] = useState<typeof STATUS_LOADING | typeof STATUS_SUCCESS | typeof STATUS_FAILURE>(STATUS_LOADING);
	const [data, setData] = useState<T | null>(initialState);
	const [error, setError] = useState<unknown>(null);

	useEffect(() => {
		if (disabled) {
			return;
		}

		setStatus(STATUS_LOADING);

		asyncFn()
			.then(result => {
				setData(_.cloneDeep(result));
				setError(null);
				setStatus(STATUS_SUCCESS);
			})
			.catch(reason => {
				setData(null);
				setError(reason);
				setStatus(STATUS_FAILURE);
			});
	}, [disabled, ...deps]);

	// tslint:disable-next-line: no-object-literal-type-assertion
	return useMemo(() => ({
		status,
		data,
		error,
	}) as AsyncState<T>, [status, data, error]);
}

export const useAggregatedAsyncState = <State extends Array<AsyncState<unknown>>>(...data: State): AggregatedAsyncState<State> => {
	return useMemo(() => {
		const failures = data.filter(state => state.status === STATUS_FAILURE);

		if (failures.length > 0) {
			return {
				status: STATUS_FAILURE,
				data: null,
				error: {
					aggregatedError: failures.map(state => {
						if (state.error instanceof Error) {
							return `${state.error.name}: ${state.error.message}`;
						}

						return (state.error as any)?.toString();
					})
				}
			};
		}

		if (data.some(state => state.status === STATUS_LOADING)) {
			return {
				status: STATUS_LOADING,
				data: null,
				error: null,
			};
		}

		return {
			status: STATUS_SUCCESS,
			data: data.map(state => state.data),
			error: null,
		};
	}, data) as AggregatedAsyncState<State>;
};

export const useAggregatedAsyncStatus = (...data: Array<AsyncState<unknown>>) => {
	return useAggregatedAsyncState(...data).status;
};

export const useIsAsyncDataLoading = (...data: Array<AsyncState<unknown>>) => {
	return useAggregatedAsyncStatus(...data) === STATUS_LOADING;
};

export const useIsAsyncDataLoaded = (...data: Array<AsyncState<unknown>>) => {
	return useAggregatedAsyncStatus(...data) === STATUS_SUCCESS;
};

export const useIsAsyncDataLoadingFailed = (...data: Array<AsyncState<unknown>>) => {
	return useAggregatedAsyncStatus(...data) === STATUS_FAILURE;
};
