import React from "react";
import CogIcon from "../svg-assets/CogIcon";

const Cog: React.FC = (props) => {
	return (
		<CogIcon/>
	);
};

export { Cog };
