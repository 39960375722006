import React from "react";
import CheckboxInput from "./../components/form/checkbox-input";

type columnKey<T> = Extract<keyof T, string>;

export enum IColumnTypes {
	text = "text",
	stringInput = "stringInput",
	checkboxInput = "checkboxInput"
}

export interface IColumnDefinition<T> {
	key: columnKey<T>;
	headerText: string;
	type: IColumnTypes;
	onChangeAction?: (insightId: string, isActive: boolean) => void;
}

interface ITableMenuProps<T> {
	data: T[];
	columnsDef: Array<IColumnDefinition<T>>;
	onRowClicked?: (row: T) => void;
	rowIdKey: string;
}

export default function TableMenu<T>(props: ITableMenuProps<T>) {
	const { columnsDef, data } = { ...props };
	const onClick = props.onRowClicked ? props.onRowClicked : (a: T) => a;

	const getColumnCellByType = (colDef: IColumnDefinition<T>, lineData: any, index: number) => {
		switch (colDef.type) {
			case (IColumnTypes.stringInput):
				return <td key={"tr" + index}>{lineData[colDef.key]} </td>;
			case (IColumnTypes.checkboxInput):
				const action = colDef.onChangeAction ? colDef.onChangeAction : () => undefined;
				return (
					<td key={"tr" + index}>
						<CheckboxInput
							value={lineData[colDef.key]}
							name={"tdData"}
							onChange={(value => action(lineData[props.rowIdKey], value))}
							isToggle={true}
						/>
					</td>
				);
			case (IColumnTypes.text):
				return <td key={"tr" + index}>{lineData[colDef.key]} </td>;
		}
	};

	return (
		<table className="ui very compact small fixed single line selectable table">
			<thead>
				<tr>
					{columnsDef.map(col => (
						<th key={col.key}>{col.headerText}</th>
					))}
				</tr>
			</thead>
			<tbody>
				{data.map((d, i) => (
					<tr key={"tr" + i} onClick={() => onClick(d)}>
						{columnsDef.map((tdDefinition, y) => (
							getColumnCellByType(tdDefinition, d, y)
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
}
