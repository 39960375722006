import BaseStore from "./base-store";
import { action, computed, observable } from "mobx";
import apiContextProvider from "../../services/api-context-provider";
import { IntegrationsApiClient, IntegrationWizardState, JiraIntegrationType, INTEGRATIONS_ROUTE } from "../services/crud/integrations-api-client";
import { DashboardIntegrationType, IDashboardIntegration, IDashboardResponse } from "@acumen/dashboard-common";
import { STRINGS } from "../../localization";
import config from "../../utils/config";
import urlJoin from "url-join";
import _ from "lodash";
import { FetchLatestRequest } from "../../services/fetch-helpers";

export enum StepsActionType {
	ChooseProvider,
	FetchInstallData,
	VerifyLink,
	UserInput,
	GoToRedirect,
	GetToken,
	Success
}

export interface IIntegrationFlow {
	name: string;
	iconSrc: string;
	length: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8";
	type: DashboardIntegrationType | "Connect your services";
	steps: Array<{
		name: string,
		description: string,
		actionType: StepsActionType,
		action: ((data: IActionData) => any | undefined),
		isVisible?: boolean;
		userInput?: {
			placeholder: string;
			type: "password" | "text";
		}
	}>;
}

export interface IActionData {
	integrationId?: string;
	code?: string;
	state?: string;
	gitInstallId?: string;
	integrationType?: DashboardIntegrationType;
	userInput?: string;
}
export const NEW_INTEGRATION_URL = "my-account/integrations";

export default class IntegrationsStore extends BaseStore<{}> {
	private readonly apiClient: IntegrationsApiClient = new IntegrationsApiClient(apiContextProvider);
	private readonly appRedirectBaseUri = config().redirectUri;

	@observable
	public allIntegrations: IDashboardIntegration[] | undefined = undefined;

	@observable
	public hasActiveIntegrations: boolean | undefined = undefined;

	@observable
	currentIntegrationType: DashboardIntegrationType | undefined = undefined;

	@action.bound
	setIntegrationType(integrationType?: DashboardIntegrationType) {
		this.currentIntegrationType = integrationType;
	}

	@computed
	public get lastCreatedIntegration() {
		return this.allIntegrations?.slice().sort((a, b) => (a.createdUtcMs < b.createdUtcMs ? 1 : -1))[0];
	}

	@computed
	public get sortedIntegrationByCreation() {
		return this.allIntegrations?.slice().sort((a, b) => (a.createdUtcMs < b.createdUtcMs ? 1 : -1));
	}

	@computed
	public get currentIntegrationFlow() {
		if (this.currentIntegrationType) {
			return this.getCurrentIntegrationFlowByType(this.currentIntegrationType);
		} else {
			return this.getCurrentIntegrationFlowByType("Choose Provider");
		}
	}

	@action.bound
	public getCurrentIntegrationFlowByType(integrationType: DashboardIntegrationType | "Choose Provider") {
		switch (integrationType) {
			case DashboardIntegrationType.Slack:
				return this.slackIntegrationFlow;
			case DashboardIntegrationType.JIRACloud:
				return this.jiraCloudIntegrationFlow;
			case DashboardIntegrationType.JIRAServer:
				return this.jiraServerIntegrationFlow;
			case DashboardIntegrationType.GitHub:
				return this.githubIntegrationFlow;
			default:
				return this.chooseProviderFlow;
		}
	}

	fetchLatestAllIntegrations = new FetchLatestRequest<IDashboardIntegration[], any>(INTEGRATIONS_ROUTE);
	@action.bound
	public async fetchAllIntegrations() {
		const result = await this.fetchLatestAllIntegrations.fetchLatest(this.apiClient.findAll());
		if (result) {
			const { data } = result;
			this.allIntegrations = data;
			return data;
		}
	}

	fetchLatestActiveIntegrationCount = new FetchLatestRequest<{ activeIntegrationCount: number}, any>(INTEGRATIONS_ROUTE);
	@action.bound
	public async fetchActiveIntegrationsCount() {
		const result = await this.fetchLatestActiveIntegrationCount.fetchLatest(
			this.apiClient.fetchActiveIntegrationsCount()
		);
		if (result) {
			const { activeIntegrationCount } = result.data;
			this.hasActiveIntegrations = activeIntegrationCount > 0;
			return activeIntegrationCount;
		}
	}

	@action.bound
	public async createSlackInstallLink() {
		const redirectUri = this.getRedirectUrl();
		const result = await this.apiClient.createSlackInstallLink(redirectUri);
		if (result) {
			const { data } = result;
			return data;
		}
	}

	@action.bound
	public async updateSlackInstallData(slackData?: IActionData) {
		if (!slackData || !slackData.integrationId) {
			return;
		}
		const redirectUri = this.getRedirectUrl();
		const result = await this.apiClient.updateSlackInstallData({
			slackInstallCode: slackData.code,
			slackInstallState: slackData.state,
			integrationId: slackData.integrationId
		}, redirectUri);
		if (result) {
			const { data } = result;
			return this.fetchAllIntegrations().then(() => data);
		}
	}

	@action.bound
	public async createGitHubInstallLink() {
		let result: IDashboardResponse<any> | null;
		result = await this.apiClient.createGitHubInstallLink();
		if (result) {
			const { data } = result;
			return data;
		}
	}

	@action.bound
	public async updateGitHubInstallId(githubData?: IActionData) {
		if (!githubData || !githubData.gitInstallId || !githubData.integrationId) {
			return;
		}

		const gitInstallId = parseInt(githubData.gitInstallId, 10);
		if (isNaN(gitInstallId)) {
			return;
		}

		const result = await this.apiClient.updateGitHubInstallId({
			gitInstallId,
			integrationId: githubData.integrationId
		});
		if (result) {
			const { data } = result;
			return this.fetchAllIntegrations().then(() => data);
		}
	}

	@action.bound
	public async initializeJiraInstanceUrl(jiraData: any, type: JiraIntegrationType) {
		if (!jiraData || _.isEmpty(jiraData.userInput)) {
			return {
				error: "Invalid input"
			};
		}
		const result = await this.apiClient.initializeJiraInstanceUrl({ jiraHostUrl: jiraData.userInput }, type);
		if (result) {
			const { data } = result;
			return { ...data, moveStep: true };
		}
		return {
			error: "Request could not be completed."
		};
	}

	fetchLatestJiraAuthorizationUrl = new FetchLatestRequest<IntegrationWizardState, any>(INTEGRATIONS_ROUTE);
	@action.bound
	public async fetchJiraAuthorizationUrl(jiraData: IActionData, type: JiraIntegrationType) {
		const result = await this.fetchLatestJiraAuthorizationUrl.fetchLatest(this.apiClient.fetchJiraAuthorizationUrl(jiraData, type));
		if (result && result.data) {
			const { data } = result;
			return data;
		}
	}

	@action.bound
	public async verifyJiraInstance(jiraData: IActionData, type: JiraIntegrationType) {
		const jiraState: IntegrationWizardState = {
			integrationId: jiraData.integrationId,
			jiraVerificationCode: jiraData.userInput
		};
		let result: IDashboardResponse<any> | null | undefined;
		result = await this.apiClient.verifyJiraInstance(jiraState, type);

		if (result) {
			const { data } = result;
			return { ...data, moveStep: true };
		}
	}

	@action.bound
	public async getIntegrationGuide(integrationId: string) {
		let result: IDashboardResponse<any> | null | undefined;

		const type = this.allIntegrations?.find(int => int.id === integrationId)?.type;
		if (type === DashboardIntegrationType.Slack) {
			result = await this.apiClient.getSlackGuide(integrationId);
		} else if (type === DashboardIntegrationType.JIRACloud) {
			result = await this.apiClient.getJiraGuide(integrationId);
		} else if (type === DashboardIntegrationType.GitHub) {
			result = await this.apiClient.getGithubGuide(integrationId);
		}
		if (result) {
			const { data } = result;
			this.setIntegrationType(type as DashboardIntegrationType);
			return data;
		}
	}

	private getRedirectUrl = () => urlJoin(this.appRedirectBaseUri, NEW_INTEGRATION_URL, "add/setup-slack");

	public chooseProviderFlow: IIntegrationFlow = {
		type: "Connect your services",
		name: "",
		iconSrc: "/assets/images/integration-provider-images/logo-generic-repository.svg",
		length: "1",
		steps: [
			{
				name: "Choose a service",
				description: "",
				actionType: StepsActionType.ChooseProvider,
				action: (data) => this.setIntegrationType(data?.integrationType),
				isVisible: false,
			}
		]
	};

	public slackIntegrationFlow: IIntegrationFlow = {
		name: "Slack",
		type: DashboardIntegrationType.Slack,
		iconSrc: "/assets/images/integration-provider-images/logo-slack.svg",
		length: "5",
		steps: [
			{
				name: "Creating new integration",
				description: "Creating a new integration...Hang on!",
				actionType: StepsActionType.FetchInstallData,
				action: this.createSlackInstallLink,
				isVisible: false
			},
			{
				name: "Slack",
				description: "You'll be redirected to Slack for authorization. Please note that Slack requires that you have the \"admin\" role in the workspace in order authorize Acumen.",
				actionType: StepsActionType.GoToRedirect,
				action: (data) => this.setIntegrationType(data?.integrationType),
				isVisible: true
			},
			{
				name: "Verification",
				description: STRINGS.INTEGRATION_SLACK_FLOW_LINKING_STEP_TEXT,
				actionType: StepsActionType.GetToken,
				action: this.updateSlackInstallData,
				isVisible: true
			},
			{
				name: "Success",
				description: "Integrated successfully",
				actionType: StepsActionType.Success,
				action: () => {
					return "ok";
				},
				isVisible: true
			}
		]
	};

	public githubIntegrationFlow: IIntegrationFlow = {
		name: "GitHub",
		type: DashboardIntegrationType.GitHub,
		iconSrc: "/assets/images/integration-provider-images/logo-github.svg",
		length: "5",
		steps: [
			{
				name: "Creating new integration",
				description: "Creating a new integration...Hang on!",
				actionType: StepsActionType.FetchInstallData,
				action: this.createGitHubInstallLink,
				isVisible: false
			},
			{
				name: "GitHub",
				description: "You'll be redirected to GitHub for authorization. GitHub requires that you have the \"owner\" role within the organization in order to integrate. You can choose which repos you'd like to authorize to Acumen inside GitHub's page",
				actionType: StepsActionType.GoToRedirect,
				action: (data => this.setIntegrationType(data?.integrationType)),
				isVisible: true
			},
			{
				name: "Verification",
				description: STRINGS.INTEGRATION_GITHUB_FLOW_INSTALL_STEP_TEXT,
				actionType: StepsActionType.GetToken,
				action: this.updateGitHubInstallId,
				isVisible: true
			},
			{
				name: "Success",
				description: "Integrated successfully",
				actionType: StepsActionType.Success,
				action: () => {
					return "ok";
				},
				isVisible: true
			}
		]
	};

	public jiraCloudIntegrationFlow: IIntegrationFlow = {
		name: "Jira Cloud",
		type: DashboardIntegrationType.JIRACloud,
		iconSrc: "/assets/images/integration-provider-images/logo-jira-cloud.svg",
		length: "7",
		steps: [
			{
				name: "Jira Cloud",
				description: `Enter the URL address for your Jira Cloud instance. Be sure to include the protocol (https://) and the the full domain address. Please note that you'll need admin access in order to authorize Acumen.`,
				actionType: StepsActionType.UserInput,
				action: (data) => this.initializeJiraInstanceUrl(data, JiraIntegrationType.Cloud),
				isVisible: false,
				userInput: {
					placeholder: STRINGS.INTEGRATION_JIRA_FLOW_ENTER_INSTANCE_STEP_PLACEHOLDER,
					type: "text"
				}
			},
			{
				name: "Creating the application link",
				description: "Copy the generated url to the clipboard, and then click \"Connect\". Paste the URL in the application link text box inside Jira and then click on \"Create new link\". Confirm the dialog and wait until Jira returns to the application links page. Return here for verification once completed.",
				actionType: StepsActionType.GoToRedirect,
				action: (data) => this.fetchJiraAuthorizationUrl(data, JiraIntegrationType.Cloud),
				isVisible: true
			},
			{
				name: "Verification",
				description: "Verifying the connection",
				actionType: StepsActionType.VerifyLink,
				action: (data) => this.fetchJiraAuthorizationUrl(data, JiraIntegrationType.Cloud),
				isVisible: true
			},
			{
				name: "Permission grant",
				description: "Click \"Connect\" to finalize permissions for Acumen. You'll be directed to Jira for the permission grants, click \"Allow\" to authorize Acumen. Once approved, copy the resulting verification that Jira generates and paste it back here.",
				actionType: StepsActionType.GoToRedirect,
				action: (data) => this.fetchJiraAuthorizationUrl(data, JiraIntegrationType.Cloud),
				isVisible: true
			},
			{
				name: "Verification code",
				description: "Once permissions have been granted, copy the verification from Jira and paste it below.",
				actionType: StepsActionType.UserInput,
				action: (data) => this.verifyJiraInstance(data, JiraIntegrationType.Cloud),
				isVisible: false,
				userInput: {
					placeholder: "Verification code",
					type: "password"
				}
			},
			{
				name: "Success",
				description: "Integrated successfully",
				actionType: StepsActionType.Success,
				action: () => {
					return "ok";
				},
				isVisible: true
			}
		]
	};

	public jiraServerIntegrationFlow: IIntegrationFlow = {
		name: "Jira Server",
		type: DashboardIntegrationType.JIRAServer,
		iconSrc: "/assets/images/integration-provider-images/logo-jira-server.svg",
		length: "7",
		steps: [
			{
				name: "Jira Server",
				description: `Enter the URL address for your Jira Cloud instance. Be sure to include the protocol (https://) and the the full domain address. Please note that you'll need admin access in order to authorize Acumen.`,
				actionType: StepsActionType.UserInput,
				action: (data) => this.initializeJiraInstanceUrl(data, JiraIntegrationType.Server),
				isVisible: false,
				userInput: {
					placeholder: STRINGS.INTEGRATION_JIRA_FLOW_ENTER_INSTANCE_STEP_PLACEHOLDER,
					type: "text"
				}
			},
			{
				name: "Creating the application link",
				description: "Copy the generated url to the clipboard, and then click \"Connect\". Paste the URL in the application link text box inside Jira and then click on \"Create new link\". Confirm the dialog and wait until Jira returns to the application links page. Return here for verification once completed.",
				actionType: StepsActionType.GoToRedirect,
				action: (data) => this.fetchJiraAuthorizationUrl(data, JiraIntegrationType.Server),
				isVisible: true
			},
			{
				name: "Verification",
				description: "Verifying the connection",
				actionType: StepsActionType.VerifyLink,
				action: (data) => this.fetchJiraAuthorizationUrl(data, JiraIntegrationType.Server),
				isVisible: true
			},
			{
				name: "Permission grant",
				description: "Click \"Connect\" to finalize permissions for Acumen. You'll be directed to Jira for the permission grants, click \"Allow\" to authorize Acumen. Once approved, copy the resulting verification that Jira generates and paste it back here.",
				actionType: StepsActionType.GoToRedirect,
				action: (data) => this.fetchJiraAuthorizationUrl(data, JiraIntegrationType.Server),
				isVisible: true
			},
			{
				name: "Verification code",
				description: "Once permissions have been granted, copy the verification from Jira and paste it below.",
				actionType: StepsActionType.UserInput,
				action: (data) => this.verifyJiraInstance(data, JiraIntegrationType.Server),
				isVisible: false,
				userInput: {
					placeholder: "Verification code",
					type: "password"
				}
			},
			{
				name: "Success",
				description: "Integrated successfully",
				actionType: StepsActionType.Success,
				action: () => {
					return "ok";
				},
				isVisible: true
			}
		]
	};
}

export interface IProviderData {
	type: DashboardIntegrationType;
	displayName: string;
	group: string;
	integrationId: string;
	logoSrc: string;
	tokenUrl: string;
}

export const integrationTypesInfo: Record<DashboardIntegrationType, IProviderData> = {
	[DashboardIntegrationType.GitHub]: {
		type: DashboardIntegrationType.GitHub,
		displayName: "GitHub",
		group: "Source Control",
		integrationId: "1",
		logoSrc: "/assets/images/integration-provider-images/logo-github.svg",
		tokenUrl: "setup-github"
	},
	[DashboardIntegrationType.Slack]: {
		type: DashboardIntegrationType.Slack,
		displayName: "Slack",
		group: "Communication",
		integrationId: "2",
		logoSrc: "/assets/images/integration-provider-images/logo-slack.svg",
		tokenUrl: "setup-slack"
	},
	[DashboardIntegrationType.JIRACloud]: {
		type: DashboardIntegrationType.JIRACloud,
		displayName: "Jira Cloud",
		group: "Task Management",
		integrationId: "3",
		logoSrc: "/assets/images/integration-provider-images/logo-jira-cloud.svg",
		tokenUrl: "setup-jira-cloud"
	},
	[DashboardIntegrationType.JIRAServer]: {
		type: DashboardIntegrationType.JIRAServer,
		displayName: "Jira Server",
		group: "Task Management",
		integrationId: "4",
		logoSrc: "/assets/images/integration-provider-images/logo-jira-server.svg",
		tokenUrl: "setup-jira-server"
	},
};

export const CONNECT_BUTTON_TEXT = "Connect";
