import React from "react";
import { Popup } from "semantic-ui-react";
import { BadgeOwner } from "../../../../types/badges";
import { CustomTableRenderProps } from "../../../../components/custom-table/types";
import { DeveloperViewModel } from "../../types";
import { DataContributorProfilePicture } from "../data-contributor-profile-picture/data-contributor-profile-picture";
import { Badge, BadgeType } from "../../../../components/badge/badge";
import "./developer-info-cell-content.scss";

export const DeveloperInfoCellContent = (props: CustomTableRenderProps<DeveloperViewModel>) => {
	const { item: developer } = props;
	const labels = developer.info.labels.length === 0 ? "Developer" : developer.info.labels.map(l => l.name).join(", ");
	const shouldShowLabel = labels.length > 30;

	return (
		<div className="developer-info-cell-content">
			<DataContributorProfilePicture contributor={developer.info} size={34}/>

			<div className="developer-info-cell-content-summary">
				<div className="developer-info-cell-content-summary-name">{developer.info.displayName}</div>

				<Popup
					size="mini"
					basic={true}
					content={labels}
					disabled={!shouldShowLabel}
					trigger={
						<div className="developer-info-cell-content-summary-role">{labels}</div>
					}
				/>
			</div>

			<div className="developer-info-cell-content-badges">
				{developer.badges.aggregatedBadges.map(badge => (
					<Badge
						key={badge}
						badge={badge}
						badgeOwner={BadgeOwner.Developer}
						badgeType={BadgeType.AggregatedBadge}
					/>
				))}
			</div>
		</div>
	);
};
