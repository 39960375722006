import seedrandom from "seedrandom";

const TEAM_NAMES = [
	"Diva Code",
	"Trojan Hash Defiance",
	"The Bug Stops Here",
	"Creep Access",
	"The Bug Slayers",
	"Gopher Hoppers",
	"Bit Rebels",
	"Smash Hack Gremlins",
	"Daemon Byte Divas",
	"Bits N’ Bytes",
	"Hack O’ Holics",
	"Java Mayhem",
	"Ether Bots",
	"Bright Byte Domain",
	"Pac Man Bytes",
	"Hack Elite",
	"Cyber Bot Demons",
	"Byte Hogs",
	"Pentium Predators",
	"Poker Foobars",
	"Tragic Bytes",
	"Red Hat Zombies",
	"Grep Tribe",
	"405 Found",
	"The Hip Script",
	"Pings with Things",
	"Hugs for Bugs",
	"Kick Bit Fever",
	"The Hip Trojans",
	"Babes Got Byte",
	"System in Bits",
	"Mac Brood Alliance",
	"Bootstrap Paradox",
	"Hackover Ooze",
	"Trojan Hex",
	"Spam Bytes",
	"Python’s Got Ping",
	"The Suave Swing",
	"Bug Hunter Squad",
	"Oracle Nerd Squad",
	"Trojan Scum Riders",
	"Compress Zip Bots",
	"Bot Systematic",
	"Odd Bit Loaders",
	"Love Bot Conspiracy",
	"Hack Alternative",
	"Troff Junkies",
	"Pine Hack Salvation",
	"Opaque Soul Virus",
	"The Zip Tomcats",
	"Hack Linguist",
	"Bit Ends",
	"Virus Vixens",
	"Bug Byte League",
	"Flow Bit Theory",
	"Sinister Ping Bot",
	"Cookie Army",
	"Space Reboot Chronicles",
	"Gopher Bots",
	"Code Linguists",
	"Bro Code Clan",
	"Plugin Immortals",
	"Kerberos Clan",
	"Stellar Hack Mercenaries",
	"Bots With Dots",
	"Zip Virus Latte",
	"Purple Byte Riot",
	"Ether Bug Demons",
	"Babes With Bot Hacks",
	"Lunatic Byte",
	"Zip Breed Theory",
	"Cryptic Parallelization",
	"Binary Bombers",
	"Trojan Wave",
	"Ctrl Alt Elite",
	"Sinister Alphanumeric Protocol",
	"Abstract Byte Class",
	"Slick Worminators",
	"Grey Cell Interface",
	"Bitbucker Magnets",
	"The Mac Hackers",
	"Bit Architecture",
	"Code Her Thing",
	"Mechanical Bit Souls",
	"The Hack Backpackers",
	"Shareware Magnets",
	"Mind Hack Zombies",
	"Compatible in Bits",
	"The Still Variables",
	"Alpha Interface",
	"The Apache Vibe",
	"We Do It With Cookies",
	"End Abend",
	"Byte Mechanics",
	"Unscript The Hack",
	"Avidemux Gremlins",
	"Code Stomp",
	"Space Penguin Hackover",
	"Abstract Evolution",
	"More Hackathon Team Names",
	"Byte For Bait",
	"App Alpha Clan",
	"Boolean Pundits",
	"Soul Celestia",
	"Mind Install",
	"Ode To Code",
	"Bit Lords",
	"The Pseudo Bug Epic",
	"Beta Up Your Mind",
	"Purple Bit Logic",
	"Debug Thugs",
	"Imagery of Markup",
	"Byte System",
	"The Tag Ends",
	"Pandora Reeboot",
	"Plug Mind Configuration",
	"Data Poltergeists",
	"Ibot Interface",
	"Binary Tree Nodes",
	"Debug Starts Here",
	"Monad Amry",
	"Entropy Crusade",
	"The Epic Byte",
	"Loader Logic",
	"The Link Clowns",
	"Geek Navi Hex",
	"Compiler Charisma",
	"Profiler Divas",
	"Hat Static",
	"Binary Fetch",
	"The Roto Command",
	"The Whack Hack",
	"Open Class System",
	"Parallel Transcend",
	"Mind Mine Data",
	"Nano Zillas",
	"Monster Memory Markup",
	"Stimulation Access Zombies",
	"Mind Map Cache",
	"Byte Karma",
	"Crack Em’ Young",
	"Abstract Connoisseurs",
	"Hypertext Assassins",
	"Callback Cats",
	"Boolean Autocrats",
	"Runtime Terror",
	"CAMA Chronicles",
	"FrontPage Freebirds",
	"Hoard Warriors",
	"Daemon Demons",
	"Thrift Torrent Talisman",
	"Software Chasers",
	"Gob Geeklords",
	"Celestial Interface",
	"Open Source Pundits",
	"Data Pirates",
	"FastCAD Stormtroopers",
	"Goo Goo Gnomes",
	"DBMS Hoodlums",
	"Firmware Rebels",
	"Multiprocessing Moguls",
	"Identity Imbalance",
	"Emulation Nerds",
	"Hex Clan",
	"Code Poltergeists",
	"Robust Routine",
	"Debug Entity",
	"Glueware Gremlins",
	"Epic Virtual Boost",
	"Mind Optimizers",
	"Sweep Collider",
	"Query Language Spies",
	"Zip Demons",
	"Peephole Slayers",
	"Shoutcast Hoppers",
	"Lint Whoppers",
	"Elementary Power Hack",
	"Trigger Mindscape",
	"Retrieval Sages",
	"Sinister Rewind",
	"Paradox Code Synchronicity",
	"Java Dalia",
	"Static Startup",
	"Indie Profilers",
	"Search Engine Bandits",
	"Mindwrap Error",
	"Life Cycle Thugs",
	"Karma Passion Molecules",
	"Sequel Extract",
	"Memory Inject Lamas",
	"Integrated Mind Herd",
	"Pipeline Predators",
	"Binary Beasts",
	"Hack Inversion",
	"Rational Register",
	"Data Structure Deadheads",
	"Spiral Forge",
	"Keygen Catastrophe",
	"Port Manifest",
	"Smalltalk Dribble",
	"App Monsters",
	"Fourier Mirage",
	"Ghost Zen",
	"Second Generation Technerds",
	"Light Wave Empire",
	"Parallel Inertia",
	"Kylix Medusa",
	"Pseudo Program Nerds",
	"Server Monks",
	"Adaptive Moorheads",
	"Bytecode Velocity",
	"Outsource Magnets",
	"Jade Magnesia",
	"Heterogeneous Raiders",
	"Acrobat Aztecs",
	"Script Railoth",
	"Cyclomatic Cylops",
	"Lan Blast",
	"Joomla Germicide",
	"Dread Goto",
	"Terragen Trip",
	"Explode Legacy",
	"Hyper Hydro Breed",
	"Kazaa Conquerors",
	"Bypass Space",
	"Oath Tentacles",
	"Mind Map Orbs",
	"Scheduling Drones",
	"Bot Interpreters",
	"Adobe Priests",
	"Turbo Dynamics",
	"Forbidden Linkers",
	"Interprocedural Hybrids",
	"Knobot Roadies",
	"Algo Messiahs",
	"Javadoc Juveniles",
	"Lava Matter",
	"Kernel Ponies",
	"Algorithm Unlock",
	"Object Grind",
	"Symbolic Share Demons",
	"Error By Night",
	"Bit Legion",
	"Ctrl Intelligence",
	"System React",
	"Fuzz Exception",
	"Goto Hub Gargoyle",
	"Byte Panache",
	"Flip Framework",
	"Syntax Terminators",
	"Twister Boot",
	"Swift Script Doozies",
	"Github Architects",
	"Angular Ajax",
	"Lamp Stack Siesta",
	"Patch Demons",
	"Backend Backboners",
	"X Code Whiplash",
	"Pseudocode Harbingers",
	"Overflow Archives",
	"Regex Natives",
	"Cyber Command Aliens",
	"Assembler Haven",
	"Base Bombers",
	"Mind Units",
	"Kludge Democracy",
	"Reverse Pointers",
	"Zip Haulers",
	"Logic Tenacity",
	"Djanjo Data Warriors",
	"Pico Zen",
	"Terminal Stack",
	"Buffer Mindhogs",
	"Visual Velocity",
	"Snip Stimulation",
	"Zool Spoolers",
	"Chi Monsters",
	"Underflow Reactor",
	"Deep Matrix",
	"Quick Time Syntax",
	"Lock Infinity",
	"Decode Apocalypse",
	"Pascal Maximus",
	"Hyper Grind Terminal",
	"Karel Optimum",
	"System Zombies",
	"Portal Pirates",
	"Demonic Transcompiler",
	"Front End Hack",
	"Control Flow Puppets",
	"Pythonic Lava",
	"Binary Matter",
	"Classpath Boolean",
	"Escape Data Chain",
	"Helm Lexicon",
	"Vaporware Nemesis",
	"Notation Nodes",
	"Theatrical Debuggers",
	"Source Code Cannibals",
	"Codepage Cynic",
	"Metaclass Lua",
	"Sandbox Resistance",
	"Logo Loopsly",
	"Dense Eclipse",
	"Ruthless Web Hogs",
	"Hex Hypercity",
	"Ctrl Dream Interface",
	"Evolution Hack",
	"Heuristic Solaris",
	"Bug Busters",
	"Mercurial Misfits",
	"The Epic Objective",
	"Raid Upgrade",
	"Invalid Markup",
	"Polygon Pride",
	"Endless Embed",
	"Genetic Pyramid",
	"Heap Magnolia",
	"Valgrind Warriors",
	"Scraper Nerds",
	"Loon Bit Loop",
	"Vertical Vendetta",
	"Random Scala",
	"Podcast Giants",
	"Contagious Module",
	"Agile Applet",
	"Floating Encapsulation",
	"Babel Frontfire",
	"Dump Domain",
	"Conditional Bug Demons",
	"Fido Splash",
	"Para Reconstruct",
	"Datalog Mindhack",
	"Embed Loop Hounds",
	"Delimiter Dungeon",
	"Exit Dump",
	"Flipper Glob",
	"Polyfont Metrics",
	"Vector Static",
	"Overload Oblivion",
	"Bug-Byte League",
	"Bits Magnet",
	"It’s not a bug, it’s a feature",
	"Ctrl Alt Del bitch",
	"Cunning Linguists",
	"Conway’s Alternative Hacks",
	"Jenkins Spice Latte",
	"Kick the BitBucket",
	"HackOverflow",
	"Baby Got Hack",
	"404 Not Found",
	"Stuck Between Pixels",
	"Java the Hutt",
	"Access Denied",
	"Pixie Chicks",
	"Skyhook Dimension",
	"System Obsessed",
	"Intelligent System Squad",
	"Rebooting Rebels",
	"Turbo Dynamics Team",
	"Spell Shine Riot",
	"The Dirty Bits",
	"Brewing Java",
	"Code Brigade",
	"Trojan Horses",
	"Tiny Coders",
	"Caffeinated fast fingers",
	"Tech Phantoms",
	"Bark Technology",
	"Trojan Hammer",
	"Reboot Rebels",
	"Spiral Massacre",
	"The ERROR List",
	"Noob Entity",
	"Chronicles of Java",
	"Master’s of Algo Lands",
	"Project Awesome",
	"Ultra Laser",
	"Ping Intelligence",
	"Scared to Compile",
	"The Code of Duty",
	"Bug Squashers",
	"Soul Glory",
	"Alter Dime",
	"Digibyte Autocrats",
	"Norse Demons",
	"Mudpie Theatricals",
	"Grapevine Squad",
	"Artful Maneuvers",
	"Tonal Science",
	"Hackstreet Boys",
	"The Tact Cool",
	"The Alter Ridge",
	"Crash Test Dummies",
	"Byte Crunchers",
	"Exterminators",
	"Optimize Prime",
	"Code Warriors",
	"Wrecking Ball",
	"Free Thinkers",
	"Breed Burial",
	"The Hit Blunders",
	"Star Tech And Beyond",
	"Digital Destroyers",
	"Prime Calculus",
	"BugSquashers",
	"Keyboard Crackers",
	"Pivotal Scatterbrain",
	"Code Fellas",
	"Tech Army",
	"Zeb Aftermath",
	"Beige Panache",
	"Hack Frenzy",
	"Beyond Infinity",
	"Ink Canvas",
	"Scrum Lords",
	"Optimal Circuits",
	"Misfit Mannequins",
	"Splash Aztecs",
	"Geek Speak Velocity",
	"Shadow Barbarians",
	"Nerds of a Feather",
	"Panoramic Views",
	"Tech Pirates",
	"Technical Wizards",
	"Cobra Tech team",
	"Brooner Stomp",
	"Remote Controllers",
	"Dirty Bits",
	"Control Gods",
	"Venture Vultures",
	"Memory Leak",
	"That Alpha Thing",
	"Cyber Knights",
	"Purely Original",
	"Skill Serenade",
	"Sacred Stomp",
	"Brew Alley",
	"Virtual Velocity",
	"Deadline Tech team",
	"Mining for Bits",
	"Arctic Dynamite",
	"Kil-A-Bytes",
	"Ideas R Us",
	"Astonishing Giants",
	"The Spammers",
	"Webologic Solutions",
	"Binary Bosses",
	"Beta Tech",
	"Fully Developed",
	"Genesis Systems",
	"Making Updates",
	"Green Touch Solutions",
	"Computer Crew",
	"Innova Solutions",
	"Mighty Pythons",
	"Neptune Technologies",
	"The Errors",
	"Computer Whizzes",
	"Split Bends",
	"Brainy Fools",
	"Mind Bogglers",
	"Software Giant",
	"White Hats",
	"Systematic Squad",
	"Data Generation",
	"Data Driven Insights",
	"Analytical Powers",
	"Formidable Skills",
	"Data Wave Warriors",
	"Going Data Way",
	"Consistent Conclusions",
	"Function Force",
	"Analysis Systems",
	"Metadata Attached",
	"Research Engines",
	"Storage Society",
	"Collectors Edition",
	"Data Trends",
	"Ideal Data Collection",
	"Truthful Details",
	"Let’s Get Information",
	"Tech Connect",
	"Did It All for the Cookies",
	"Bro Code",
	"Hash it Out",
	"Cyber Creepers",
	"Coding Team Names",
	"Bluesprint",
	"Honorable Ducks",
	"Bunking Rascals",
	"Friendly Ass-Kickers",
	"Player Machines",
	"Easy Scrum Easy Go",
	"Team Of The Gods",
	"Sly Pretenders",
	"Let Them Loose",
	"Handsome Buzzers",
	"French Toast",
	"Dreadful Dragons",
	"Scrum and Coke",
	"One Shot Killers",
	"Skull Crushers",
	"Stone Crushers",
	"Trigger Happy",
	"No Chance",
	"Power Brokers",
	"The Ringleaders",
	"Your Bosses",
	"Fast Talkers",
	"Honey Bees",
	"The Teddy Bears",
	"Double Vision",
	"Scrummy Bears",
	"Scrumbledores",
	"Scrumbledore’s Army",
	"BoolMeOvers",
	"Air Conditionals",
	"cOver Lords",
	"Your time is gonna Scrum",
	"Here comes the Scrum Little Darling",
	"Don’t Scrum Around Here No More",
	"Scrumglasses at Night",
	"Don’t let the Scrum Go Down On Me",
	"The House of the Rising Scrum",
	"Easy like ScrumDay Morning",
	"Walking on Scrumshine",
	"Scrum Like It Hot",
	"ScrumDay, ScrumHow",
	"Scrum As You Are",
	"Scrumshine of your Love",
	"Coverage Drop and Rolls",
	"Scrum At Me Bro",
	"Sweet Scrumshine",
	"Scrum On Hear the Noise",
	"Johnny Scrum Lately",
	"Scrum On Eileen",
	"The Apple Scrumpling Gang",
	"Scrum Kind of Wonderful",
	"Scrumbody That I Used To Know",
	"Scrumdog Millionaires",
	"Pour Scrum Sugar On Me",
	"Black Holed Scrum",
	"Perfect Hoopers",
	"Playing by Boos",
	"ReSprint",
	"Scrum and Scrummer",
	"Scrum of the Earth",
	"Soaking Screwers",
	"Someone Else Broke it",
	"Sprint Chocolate Chip",
	"Sprint Wireless",
	"Supremacy Addicts",
	"The Iterations",
	"The MisSprints",
	"Basket Tossers",
	"Victorious Secret",
	"Masters of Scrum",
	"Boston Flaming Goes",
	"Brogrammers",
	"Essentials",
	"Leaders Of The Future",
	"Like Hamburgers",
	"Swinging Supermen",
	"Iconic Crew",
	"Team Awesome",
	"Calm and Sprint On",
	"Keep Scrum",
	"Iteration Station",
	"The Brave Schemers",
	"The Forfeiters",
	"The Sprinters",
	"Two Weeks’ Notice",
	"Wishing Juniors",
	"Accomplished",
	"Adaptive",
	"Adroit",
	"Advanced",
	"Advocates",
	"Agile",
	"Alliance",
	"Allied",
	"Alpha",
	"Array",
	"Assemblage",
	"Avengers",
	"Awesome",
	"Blueprints",
	"Bruisers",
	"Byte",
	"Chargers",
	"Clever",
	"Codes",
	"Collective",
	"Committed",
	"Complex",
	"Coordinated",
	"Diplomats",
	"Dominators",
	"Efficient",
	"Elite",
	"Empowered",
	"Enlivened",
	"Enterprisers",
	"Everest",
	"Extraordinary",
	"Fixers",
	"Focused",
	"Forecasters",
	"Formation",
	"Foundations",
	"Goalkeepers",
	"Hive",
	"Impressive",
	"Incisive",
	"Ingenious",
	"Integrated",
	"Intuition",
	"Inventive",
	"Keen",
	"Kingpins",
	"Knights",
	"Lineup",
	"Logical",
	"Machinations",
	"Masterful",
	"Maximizers",
	"Obnoxious",
	"One",
	"Optimizers",
	"Outstanding",
	"Parallel",
	"Perceptive",
	"Perspicacious",
	"Phenoms",
	"Polished",
	"Preeminent",
	"Priority",
	"Refined",
	"Sapient",
	"SCRUMptious",
	"Sharp",
	"Shrewd",
	"Skillful",
	"Solvers",
	"Sprinters",
	"Strategists",
	"Streamliners",
	"Strikers",
	"Structured",
	"Sustainable",
	"Talent",
	"Titans",
	"Trailblazers",
	"Tribe",
	"Troupe",
	"Undefined",
	"United",
	"Unlimited",
	"Unpredictable",
	"Velocity",
	"Vigilantes",
	"Visionaries",
	"Wired",
	"Wireless",
	"The Rubix Cube",
	"Pony Boy",
	"That IT Guy",
	"Phantoms",
	"Win and then Win Some More",
	"Enemy of Syntax",
	"Boolean Hooligans",
	"Code Wars",
	"Star Techies",
	"Generic Programs",
	"Pythons and Pythonistas",
	"The Programming Gang",
	"Web of Lies",
	"Solution Makers",
	"One and Zero",
	"Disciples of Programming Gods",
	"Comp Crew",
	"Updated to Pro",
	"Innovators",
	"Need more Space",
	"Memory Space Makers",
	"Developer by Developers",
	"Error Makers",
	"The Comp Whizzkids",
	"Binary Brains",
	"Pursuing FAANG Jobs",
	"Starter Pack Makers",
	"Google Devs",
	"Driven By Data",
	"Analyzing Anarchists",
	"Decision Makers",
	"The Systems Squads",
	"The DIGI Life",
	"Autocrats of Data",
	"Morse Coders",
	"Puppeteers",
	"Tech Savy Youth",
	"Skyscrapers of Tech World",
	"Jump of Roofs",
	"Coding Brigaders",
	"Bit by Bit",
	"Ultra Hackers",
	"THE Ultra Scientific Bunch",
	"Hackerstreet Boys",
	"99 Problems but Coding Aint One",
	"The Alter of Classes",
	"Mapping through the Edge of World",
	"Open Source Messiahs",
	"Prime Calculus Curators",
	"Digitally Destroyed",
	"Sore Eyes and Tired Fingers",
	"Bug Squash Gang",
	"Terminate the Bug",
	"The Aftermath of Stack Overflow",
	"Surviving with Google",
	"Coding Academics",
	"Star Fleet to Bit Land",
	"Inky Pixel Canvas",
];

export const getRandomDisplayName = () => {
	const rng = seedrandom();
	return TEAM_NAMES[Math.floor(rng() * TEAM_NAMES.length)];
};
