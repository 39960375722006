import * as React from "react";
const Unicorn = (props: React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fill="#FFF"
			d="m23.754 12.362 1.667 7.167-5.333 5.333-8.334 14.333 1 4.667 2.167 1.333 4-.167 3.5-3.333 6.833-1.833s1.334 1.5 2.167 3 3.667 4.167 3.667 4.167l.5 6-1.833 6.166-2 2.833s22 9.5 33.166-7l-.5-6-1.833-5-3.333-5.166-1-1.5-.167-5.167-2.833-5.333-5-3-2.667-4.5-5.167-4.167-6.5-1.5-5.666 1-4.167-2.167-2.334-.166z"
		/>
		<path
			fill="#EA5A47"
			d="m50.671 23.155 5.208 4.095s5.564 8.218-.325 17.82c-7.05 11.492 0 0 0 0-1.619 3.475-2.315 6.742-1.739 9.722l-5.31-4.417V34.292l2.166-11.137zM25.898 19.271l-15.113-7.25 5.166 6.119 5.224 5.855 4.723-4.724"
		/>
		<path
			fill="#92D3F5"
			d="m29.737 13.631 10.767.136s9.238 4.066 10.536 11.816l.687 8.957c-2.633 6.539-3.056 14.127 2.089 20.834 0 0-7.145 1.321-9.895-7.11L42.338 43.5l.325-6.034 1.417-5.643-.283-4.893-2.276-4.312-3.532-2.841-5.792-2.08-2.46-4.066"
		/>
		<path
			fill="#61B2E4"
			d="M58.455 36.75s5.52 6.407 6.998 15.12a8.02 8.02 0 0 1-.138 3.258c-.431 1.752-.817 4.998.146 7.383.469 1.161-.562 2.365-1.789 2.111-3.71-.768-9.243-3.647-10.256-8.087a1.826 1.826 0 0 1-.042-.321l-.266-5.746c-.013-.286.052-.57.187-.821l3.692-6.836c.067-.123.117-.255.149-.392l1.319-5.669"
		/>
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M58.455 37.783C60.223 40.144 65 44.465 64.5 54.02M32.5 41.885s8.478 6.783 0 18.765M24.809 19.134 10.25 11.75l10.925 12.245"
		/>
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M35.196 30.87c.55 8.355-9.322 9.703-11.954 10.334-.332.08-.632.25-.873.492l-2.223 2.223c-.35.35-.824.546-1.318.546h-3.512a2.795 2.795 0 0 1-2.651-1.911l-.531-1.593a2.795 2.795 0 0 1 .255-2.322l8.786-14.644 4.724-4.724-2.116-6.905s7.803-.699 8.414 5.33c0 0 16.928 2.442 10.553 19.384 0 0-1.625 5.949 2.375 11.184"
		/>
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M30.917 14.02S53.16 10 50.875 33.98"
		/>
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M49.919 23.155s14.766 6.587 5.456 22.22c0 0-5.375 6.563.625 13.604"
		/>
		<circle cx={24.417} cy={28.93} r={2} />
	</svg>
);
export default Unicorn;
