import { useEffect, useState } from "react";

export const useTopPosition = (props: {
	elementRef: React.RefObject<HTMLElement | null>;
	targetRef: React.RefObject<HTMLElement | null>;
	marginFromTarget?: number;
	topMarginFromTarget?: number;
	bottomMarginFromTarget?: number;
}) => {
	const {
		elementRef,
		targetRef,
		marginFromTarget = 0,
		topMarginFromTarget = marginFromTarget,
		bottomMarginFromTarget = marginFromTarget
	} = props;
	const [top, setTop] = useState<number | undefined>(undefined);

	useEffect(() => {
		if (!elementRef.current || !targetRef.current) {
			return undefined;
		}

		const element = elementRef.current;
		const relative = targetRef.current;
		const targetOffsetTop = relative.getBoundingClientRect().top + window.scrollY;

		const above = targetOffsetTop - element.offsetHeight - bottomMarginFromTarget;
		const below = targetOffsetTop + relative.offsetHeight + topMarginFromTarget;
		const spaceLeftAbove = above - window.scrollY;
		const spaceLeftBelow = (window.scrollY + window.innerHeight) - (below + element.offsetHeight);

		if (spaceLeftBelow > 0 || spaceLeftBelow > spaceLeftAbove) {
			// NOTE: If the dropdown fits below the filter button or at least there is more available space then above it - show dropdown at the bottom
			setTop(below);
		} else {
			// NOTE: Only show dropdown above the filter button as a fallback solution
			setTop(above);
		}
	}, []);

	return top;
};
