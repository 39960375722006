export const BLANK_LINK = "#!";

export const KEY_PARAMETER = ":key";
export const TYPE_PARAMETER = ":type";
export const ID_PARAMETER = ":id";

export const ROUTES = {
	ROOT: "/",
	LOGIN: "/login",
	MY_TEAM: "/my-team",
	SETTINGS_USERS: "/settings/users",
	SETTINGS_CONTRIBUTORS: "/settings/contributors",
	SETTINGS_TEAMS: "/settings/teams",
	SETTINGS_INTEGRATIONS: "/settings/connected-apps",
	NOT_FOUND: "/404",
	MY_ACCOUNT_USERS: "/my-account/users",
	WORKFORCE_HEALTH: "/workforce-health",
	ORG_METRICS: "/org-metrics",
	TEAM_METRICS: "/team-metrics",
	PR_BREAKDOWN: "/pr-breakdown",
};
