import {
	ConfigParamKeyType, CustomizableConfigInputType, IDashboardCustomizableSelectStaticConfig, SINGLE_CONFIG_KEY,
} from "@acumen/dashboard-common";
import { Checkbox, Input, Modal } from "semantic-ui-react";
import "./style.scss";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useStores } from "../../mobx-stores";
import {
	customizableConfigurationFromString, customizationValue, formatValuesToDbByFieldName, formatValuesToUiByFieldName,
	getCustomizationCategoryTitle, IConfigParamsTypes, IDashboardCustomizableValue
} from "../../mobx-stores/customization-store";
import { Select as SelectInput } from "../../components/select";
import { clickEvent, GA_EVENT_ACTION, GA_EVENT_CATEGORY } from "../../analytics-events";
import DatePicker from "react-datepicker";
import moment, { Moment } from "moment";
import _ from "lodash";
import { getFieldName, getFriendlyNamesRecord, getValueSuffix, IConfigurationForm } from ".";

const VM_REFRESH_MSG = "Some changes would take approximately 30 minutes to propagate.";
const FILL_GROUP_MSG = "You must fill in all of the fields.";

// tslint:disable-next-line: variable-name
const ConfigurationsForm = (props: IConfigurationForm) => {
	const {
		customizationStore: {
			updateConfigurationByCategory,
			getCategoryDefaultValues,
			getCategoriesCurrentValues
		}
	} = useStores();

	const { teamId, configFields, category, entityType, refreshPage, toggleModuleState } = { ...props };
	const [isFormDirty, setFormDirty] = useState<boolean>(false);
	const [isUpdating, setIsUpdating] = useState<boolean>(false);
	const [changedFormFields, setChangedFormFields] = useState<Partial<IDashboardCustomizableValue>>({});
	const [defaultValues, setDefaultValues] = useState<Partial<IDashboardCustomizableValue>>({});
	const [currentValues, setCurrentValues] = useState<Partial<IDashboardCustomizableValue>>({});

	const topMouldedRef = useRef<HTMLInputElement | null>(null);
	const title = getCustomizationCategoryTitle[category];
	const fieldsCount = configFields && configFields?.length ? configFields?.length : 0;

	useEffect(() => {
		if (configFields) {
			setDefaultValues(getCategoryDefaultValues(configFields));
			setCurrentValues(getCategoriesCurrentValues(configFields));
		}
	}, []);

	useEffect(() => {
		if (topMouldedRef && topMouldedRef.current) {
			topMouldedRef.current?.scrollIntoView();
		}
	}, [topMouldedRef.current]);

	const shouldRefreshPage = Object.values(configFields).find((field: IDashboardCustomizableValue | undefined) => {
		return field && field.refreshPage;
	});

	const shouldWaitForChanges = Object.values(configFields).find((field: IDashboardCustomizableValue | undefined) => {
		return field && !field.refreshPage;
	});

	const getCurrentValueOfField = (configField: IDashboardCustomizableValue) => {
		let current;
		if (configField.key === SINGLE_CONFIG_KEY) {
			const fieldChanges = _.clone(changedFormFields[configField.name]);
			if ((fieldChanges && fieldChanges[configField.key] !== undefined)) {
				current = fieldChanges[configField.key];
			} else {
				const fieldCurrent = currentValues[configField.name];
				current = (fieldCurrent) ? fieldCurrent[configField.key].value : undefined;
			}
		} else {
			const fieldName = configField.fieldName;
			if (!fieldName) {
				return;
			}
			if (changedFormFields[fieldName]) {
				const fieldChanges = _.clone(changedFormFields[fieldName]);
				if (fieldChanges) {
					current = fieldChanges[configField.key];
				}
			} else {
				const fieldChanges = currentValues[fieldName];
				if (fieldChanges) {
					current = fieldChanges[configField.key].value;
				}
			}
		}
		return current;
	};

	const getConfigInputFieldByType = (_configField: IDashboardCustomizableValue) => {
		const validName = customizableConfigurationFromString(_configField.name);
		if (!validName) {
			return;
		}
		const singleConfig = _configField.configParams[SINGLE_CONFIG_KEY];
		const inputType = singleConfig ?
			singleConfig.inputType :
			_configField.configParams.inputType;

		const configField: IDashboardCustomizableValue = singleConfig ?
			_configField :
			{
				..._configField,
				name: _configField.key,
				fieldName: _configField.name,
			};

		const onFieldChange = (selected: null | boolean | string | number | string[] | number[]) => {
			if (configField.key === SINGLE_CONFIG_KEY) {
				const newValue: Partial<IDashboardCustomizableValue> = changedFormFields[validName] ?? {};
				newValue[configField.key] = selected;
				setChangedFormFields(Object.assign(changedFormFields, { [configField.name]: newValue }));
				setFormDirty(true);
			} else {
				const newValue = changedFormFields[validName] ? changedFormFields[validName] : {};
				newValue[configField.key] = selected;
				setChangedFormFields(Object.assign(changedFormFields, { [validName]: newValue }));
				const fieldsGroup = Object.values(configFields).find((field) => field && field.name === validName);

				if (fieldsGroup) {
					const isValid = validateFieldGroup(fieldsGroup);
					setFormDirty(isValid);
				}
			}
		};

		const current = getCurrentValueOfField(configField);

		switch (inputType) {
			case CustomizableConfigInputType.MultiSelectStaticValues:
				return SelectStaticValuesInputField(configField, inputType, onFieldChange, current);
			case CustomizableConfigInputType.Number:
				return SingleFreeInputField(configField, "number", onFieldChange, current);
			case CustomizableConfigInputType.SingleSelectStaticValues:
				return SelectStaticValuesInputField(configField, inputType, onFieldChange, current);
			case CustomizableConfigInputType.Date:
				return <DateInput
					value={current}
					configField={configField}
					onChange={onFieldChange}
				/>;
			case CustomizableConfigInputType.Boolean:
				return CheckboxInputInputField(configField, onFieldChange, current);
			default:
				return <></>;
		}
	};

	const getConfigInputField = (configField: IDashboardCustomizableValue) => {
		if (!configField.configParams[SINGLE_CONFIG_KEY]) {
			return getMultiFieldsInputType(configField);
		}
		return getConfigInputFieldByType(configField);
	};

	const getMultiFieldsInputType = (configField: IDashboardCustomizableValue) => {
		const validFields: boolean = validateFieldGroup(configField);
		return (
			<div className="field-group">
				<h3 className="ui header acu-capitalize">{(configField.groupName)}
					{!validFields && < div className="message">
						{FILL_GROUP_MSG}
					</div>}
				</h3>
				{
					Object.entries(configField.configParams).map((([name, params], i) => {
						const _val = configField.currentValue;
						const value = _val ? Object.entries(_val).find(([key, ]) => key === name) : [];
						const current = {
							[name]: value as customizationValue
						};
						return (
							<div key={i}>
								{getConfigInputFieldByType({
									name: configField?.name,
									configParams: params as Partial<IConfigParamsTypes>,
									key: name as ConfigParamKeyType,
									currentValue: current
								})}
							</div>
						);
					}))
				}
			</div >
		);
	};

	const validateChangedForm = (values: Partial<IDashboardCustomizableValue>) => {
		if (!values) {
			return false;
		}
		const fieldsGroup = Object.entries(values).filter(([k, v]) => {
			if (!v) {
				return [false];
			}
			return !!Object.keys(v).find(type => type !== SINGLE_CONFIG_KEY);
		}).map(arr => arr[1]);
		return fieldsGroup.reduce((acc, val) => acc && !!val, true);
	};

	const validateFieldGroup = (configField: IDashboardCustomizableValue) => {
		return !!(configField.configParams &&
			_.isEqual(Object.keys(changedFormFields[configField.name] ?? {}).sort(), Object.keys(configField.configParams).sort()));
	};

	return (
		<Modal
			open={true}
			className={classNames("configurations-form", { large: fieldsCount > 3 })}>
			<Modal.Content >
				<div className={classNames("ui form grid no-margin")}>
					<h2 className="card-header full-width acu-capitalize">{title}
						{shouldWaitForChanges &&
							<div className="message">
								{VM_REFRESH_MSG}
							</div>
						}
					</h2>
					<div className="acu-scroll scrolling full-width">
						<div ref={topMouldedRef} />
						{configFields && Object.values(configFields)
							.map((_field: IDashboardCustomizableValue | undefined, i: number) => {
								return (
									<div key={i} >
										{_field && getConfigInputField(_field)}
									</div>
								);
							})}
						<div className="padding-bottom" />
					</div>
				</div>
			</Modal.Content>

			<Modal.Actions>
				<div className="flex-row spaced buttons-row">
					<button
						type="submit"
						className={classNames("ui button link-button", "acu-capitalize",
							{ loading: false, disabled: false })
						}
						onClick={
							() => {
								setCurrentValues(_.cloneDeep(defaultValues));
								setChangedFormFields(_.cloneDeep(defaultValues));
								setFormDirty(validateChangedForm(defaultValues));
							}
						}
					>reset to defaults</button>

					<div className="right-buttons">
						<button
							className={classNames("ui apply primary button", "acu-capitalize",
								{ loading: isUpdating, disabled: !isFormDirty || !teamId })
							}
							onClick={() => {
								if (teamId) {
									setIsUpdating(true);
									// tslint:disable-next-line: no-floating-promises
									updateConfigurationByCategory({ category, configs: changedFormFields }, entityType, teamId).then(() => {
										setFormDirty(false);
										setIsUpdating(false);
										if (toggleModuleState) { toggleModuleState(); }
										if (shouldRefreshPage) {
											if (refreshPage) {
												refreshPage();
											} else {
												window.location.reload();
											}
										}
									});
								}
							}
							}
						>apply</button>
						<button
							type="submit"
							className={classNames("ui secondary button", "acu-capitalize",
								{ loading: false })}
							onClick={() => {
								setChangedFormFields({});
								setCurrentValues(getCategoriesCurrentValues(configFields));
								if (toggleModuleState) { toggleModuleState(); }
							}}
						>cancel</button>
					</div>
				</div>
			</Modal.Actions >
		</Modal >);
};
export default ConfigurationsForm;

// tslint:disable-next-line: variable-name
export const SelectStaticValuesInputField = (configField: IDashboardCustomizableValue, inputType: CustomizableConfigInputType,
	onChange: (selected: string | number | string[] | number[]) => void, currentValue?: string | string[] | number) => {

	const isMultiSelect = inputType === CustomizableConfigInputType.MultiSelectStaticValues;
	const friendlyNames = getFriendlyNamesRecord(configField.name);
	const singleValue = configField.configParams[SINGLE_CONFIG_KEY];
	const possibleValues = singleValue ?
		(singleValue as IDashboardCustomizableSelectStaticConfig).possibleValues :
		(configField.configParams).possibleValues;
	const [_currentValue, setCurrentValue] = useState<any>(currentValue);

	useEffect(() => {
		setCurrentValue(currentValue);
	}, [currentValue]);

	const _onChange = (val: string | number | string[] | number[]) => {
		setCurrentValue(val);
		onChange(val);
	};

	return (
		<div className="acu-input full-width flex-row spaced">
			<div className="side-label acu-capitalize">
				<span>
					{getFieldName(configField)}
				</span>
			</div>
			<div className="input-field acu-capitalize" >
				<SelectInput
					required={true}
					multiselect={isMultiSelect}
					searchable={possibleValues!.length > 4 ?? false}
					options={possibleValues?.map((value: string, i: number) => ({
						value,
						key: value,
						text: friendlyNames ? friendlyNames(value) : value
					})) ?? []}
					onChange={_onChange}
					value={_.isArray(_currentValue) ? undefined : _currentValue}
					selected={_.isArray(_currentValue) ? [..._currentValue] : undefined}
					{...clickEvent(GA_EVENT_CATEGORY.Customization, GA_EVENT_ACTION.Select, configField.name)}
					error={!currentValue}
				/>
			</div>
		</div>
	);
};

// tslint:disable-next-line: variable-name
export const DateInput = (props: { configField: IDashboardCustomizableValue, onChange: (value: string) => void, value: string }) => {
	const { configField, onChange, value } = { ...props };
	const [date, setDate] = useState<Moment | undefined>(moment(value));

	useEffect(() => {
		if (value) {
			setDate(moment(value));
		} else {
			setDate(undefined);
		}
	}, [value]);

	return (
		<div className="acu-input full-width flex-row spaced bottom-margin">
			<div className="side-label acu-capitalize">
				<span>
					{getFieldName(configField)}
				</span>
			</div>
			<div className="date-input">
				<div className="styled-date-picker" >
					<DatePicker
						selected={date?.toDate()}
						onChange={(val: Date | null) => {
							if (val) {
								setDate(val ? moment(val) : undefined);
								onChange(val.toISOString());
							}
						}}
						required={true}
					/>
				</div>
			</div>
		</div>);
};

// tslint:disable-next-line: variable-name
export const SingleFreeInputField = (configField: IDashboardCustomizableValue, inputType: "text" | "number", onChange: (value: string | number) => void, current?: string) => {
	const [_currentValue, setCurrentValue] = useState<string | number | undefined>(undefined);
	const suffix = getValueSuffix(configField);

	useEffect(() => {
		if (current !== undefined) {
			setValue(current);
		}
	}, [current]);

	const setValue = (value: string | number) => {
		if (inputType === "number") {
			setCurrentValue(_.toNumber(value));
		} else {
			setCurrentValue(_.toString(value) ?? "");
		}
	};

	const _onChange = (val: string | number) => {
		setValue(val);
		onChange(val);
	};

	return (
		<div className="acu-select-input-container">
			<TextInput
				type={inputType}
				label={getFieldName(configField)}
				suffix={suffix}
				value={formatValuesToUiByFieldName(_currentValue, configField.name)}
				onChange={_onChange}
				fieldName={configField.key === SINGLE_CONFIG_KEY ? configField.name : configField.key}
				hasError={(_currentValue === 0 ? false : !_currentValue)}
			/>
		</div>
	);
};

interface ITextInput {
	fieldName: string;
	suffix?: string;
	value?: string | number;
	placeholder?: string;
	onChange?: (value: string | number) => void;
	error?: boolean;
	required?: boolean;
	loading?: boolean;
	autoFocus?: boolean;
	label?: string;
	type?: "number" | "text" | "time";
	pattern?: "string";
	hasError?: boolean;
}

// tslint:disable-next-line: variable-name
export const TextInput = ({ value, label, suffix, placeholder, onChange, type = "text", pattern, fieldName, hasError = false }: ITextInput) => {

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (onChange) {
			const formattedValue = formatValuesToDbByFieldName(e.target.value, fieldName);
			onChange(formattedValue);
		}
	};

	return (
		<div className="acu-input flex-row full-width spaced">
			{label && <div className="side-label acu-capitalize configuration-name">
				<span>
					{label}
				</span>
			</div>}

			<div className={classNames("ui input", { "labeled": suffix, "acu-labeled-input right": suffix })}>
				<Input
					className={classNames({ "--error": hasError })}
					type={type}
					placeholder={placeholder}
					value={_.toString(value)}
					onChange={handleChange}
					pattern={pattern}
					min={0}
				/>
				{suffix && <div className="ui basic label acu-capitalize" >
					<span>
						{suffix}
					</span>
				</div>}
			</div>
		</div>
	);
};

// tslint:disable-next-line: variable-name
export const CheckboxInputInputField = (configField: IDashboardCustomizableValue, onFieldChange: (value: boolean) => void, value: boolean) => {

	const [currentValue, setValue] = useState<boolean>(!!value);

	useEffect(() => {
		setValue(value);
	}, [value]);

	return (
		<div className="acu-input flex-row full-width spaced">
			<div className="side-label acu-capitalize">
				<span>
					{getFieldName(configField)}
				</span>
			</div>
			<div className="float-right">
				<Checkbox
					name={configField.name}
					onChange={(e, data) => {
						e.stopPropagation();
						e.preventDefault();
						setValue(!!data.checked);
						onFieldChange(!!data.checked);
					}}
					checked={!!currentValue}
					toggle={true}
				/>
			</div>
		</div>
	);
};
