import React from "react";
import { IDashboardTeam } from "@acumen/dashboard-common";
import {
	DeveloperStatsTeamTrendsBreakdown
} from "../developer-stats-team-trends-breakdown/developer-stats-team-trends-breakdown";
import {
	DeveloperStatsTeamTrendsCardHeader
} from "../developer-stats-team-trends-card-header/developer-stats-team-trends-card-header";
import { DeveloperBadgesData } from "../../../../types/workforce-health";
import "./developer-stats-team-trends-card.scss";
import { IDeveloperInfo } from "../../types";

export interface DeveloperStatsTeamTrendsCardProps {
	teams: IDashboardTeam[];
	contributors: IDeveloperInfo[];
	maxMembersToShow: number;
	developerBadgesData: DeveloperBadgesData;
	selectedTeamIds: string[];
	onSelectedTeamsChange: (team: string[]) => void;
}

export const DeveloperStatsTeamTrendsCard = (props: DeveloperStatsTeamTrendsCardProps) => {
	const {
		teams,
		contributors,
		maxMembersToShow,
		selectedTeamIds,
		developerBadgesData,
		onSelectedTeamsChange,
	} = props;

	return (
		<div className="developer-stats-team-trends-card">
			<DeveloperStatsTeamTrendsCardHeader
				teams={teams}
				contributors={contributors}
				maxMembersToShow={maxMembersToShow}
				selectedTeamIds={selectedTeamIds}
				onSelectedTeamsChange={onSelectedTeamsChange}
			/>

			<div className="developer-stats-team-trends-card-content">
				<div className="developer-stats-team-trends-card-content-title">
					Team trend
				</div>

				<DeveloperStatsTeamTrendsBreakdown
					numberOfContributors={contributors.length}
					developerBadgesData={developerBadgesData}
				/>
			</div>
		</div>
	);
};
