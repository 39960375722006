import { CustomTableColumnKey, CustomTableColumnProps } from "./types";

export const getCompareByFunction = <T extends object>(
	column: CustomTableColumnProps<T>,
	fallback: string | number = ""
) => {
	const { key, compareBy } = column;

	if (compareBy) {
		return compareBy;
	}

	return (item: T) => {
		if (!item.hasOwnProperty(key)) {
			return fallback;
		}

		const value = item[key as keyof T] as any;

		return typeof value === "string" || typeof value === "number"
			? value
			: value?.toString();
	};
};

export const getItemPropertyValueAsString = <T extends object>(item: T, key: CustomTableColumnKey<T>) => {
	return item.hasOwnProperty(key)
		? (item[key as keyof T] as any)?.toString()
		: "";
};
