import React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

interface IHCDonut {
	chartData: IDonutDataEntry;
}

interface IDonutDataEntry {
	data: React.ReactText[][];
	sizePx: number;
	name?: string;
	title?: string;
	size?: string;
	tooltip?: boolean;
	innerSize?: string;
	showInLegend?: boolean;
	colors?: string[];
	dataLabels?: {
		enabled: boolean
	};
}

const DEFAULT_COLORS = ["#00bc95", "#ced5dc"];

// tslint:disable-next-line: variable-name
const HCDonut = (props: IHCDonut) => {

	const { name, data, colors, size, innerSize, showInLegend, sizePx, title, tooltip } = { ...props.chartData };
	const chartDataSeries = {
		name: name ? name : undefined,
		colors: colors ? colors : DEFAULT_COLORS,
		size: size ? size : "100%",
		innerSize: innerSize ? innerSize : "72.5%",
		showInLegend: showInLegend ? showInLegend : false,
		dataLabels: {
			enabled: false
		},
		data
	};
	const options = {
		chart: {
			type: "pie",
			height: sizePx,
			width: sizePx,
			backgroundColor: "transparent",
		},
		plotOptions: {
			pie: {
				shadow: false
			}
		},
		tooltip: {
			enabled: tooltip || false,
		},
		credits: {
			enabled: false
		},
		series: [{ ...chartDataSeries }],
		title
	};

	return (
		<div style={{ overflow: "hidden" }}>
			<HighchartsReact
				highcharts={Highcharts}
				options={options}
			/>
		</div>
	);
};
export default HCDonut;
