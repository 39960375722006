import { IDashboardAcumenMetricDataResponse } from "@acumen/dashboard-common";
import { DevStatsMetricNameInDatabase, TaskEstimationMethod, WorkforceHealthMetrics } from "@acumen/database-types";
import { IMetricDisplayValues } from "./components/workforce-health-header/workforce-health-header";
import {
	calcMetricsAverageForChart, calcMetricsPercentForChart, calcPeriodicalMetricsAverage, calcPeriodicalMetricsPercentage, convertChartMetricValuesByEstimationMethod,
	convertMetricValuesToChartValues, convertMetricValuesToPeriodicalValues,
	convertPeriodicalMetricValuesByEstimationMethod, divideMetricChartValues,
	dividePeriodicalMetricValues, IdToMetricValues, SECONDS_TO_DAYS_MULTIPLIER, SECONDS_TO_HOURS_MULTIPLIER
} from "./helpers/dev-stats-metric-calculators";

export type IMetricDisplayValuesCreationParams = Pick<IMetricDisplayValues, "title" | "subtitle" | "isIncreasePositive" | "unit" | "devTooltip" | "teamTooltip">;
export class DevStatColumn {
	constructor(
		public dashboardMetricName: WorkforceHealthMetrics,
		public dependencies: DevStatsMetricNameInDatabase[],
		public calculateColumn:
			(metricData: IDashboardAcumenMetricDataResponse[], estimationMethod?: TaskEstimationMethod) => IdToMetricValues,
		public calculateChart:
			(metricData: IDashboardAcumenMetricDataResponse[], estimationMethod?: TaskEstimationMethod) => number[],
		public displayValues: IMetricDisplayValuesCreationParams,
	) { }
}

export const ESTIMATION_UNITS = "ESTIMATION_UNITS";

export const devStatColumns: DevStatColumn[] = [
	new DevStatColumn(
		WorkforceHealthMetrics.ActiveDays,
		[DevStatsMetricNameInDatabase.active_days],
		(metricData) => convertMetricValuesToPeriodicalValues(metricData[0]),
		(metricData) => convertMetricValuesToChartValues(metricData[0]),
		{
			title: "Active days",
			subtitle: "Days",
			unit: "days",
			isIncreasePositive: true,
			devTooltip: `The number of active days where a team member had any type of work related event in Jira or Git`,
			teamTooltip: ``
		},
	),

	new DevStatColumn(
		WorkforceHealthMetrics.Velocity,
		[DevStatsMetricNameInDatabase.velocity],
		(metricData, estimationMethod) => convertPeriodicalMetricValuesByEstimationMethod(metricData[0], estimationMethod),
		(metricData, estimationMethod) => convertChartMetricValuesByEstimationMethod(metricData[0], estimationMethod),
		{
			title: "Velocity",
			// TODO(ACM-7253): not always sps
			subtitle: "Story points",
			isIncreasePositive: true,
			// ACM-7002 Note: velocity unit is calculated by estimation method and cannot have a default value
			unit: "",
			devTooltip: `Total amount of ${ESTIMATION_UNITS} which were completed during the selected time period`,
			teamTooltip: `Total amount of ${ESTIMATION_UNITS} which were completed by the team members during the selected time period`
		},
	),

	new DevStatColumn(
		WorkforceHealthMetrics.BugsVelocity,
		[DevStatsMetricNameInDatabase.bugs_velocity],
		(metricData, estimationMethod) => convertPeriodicalMetricValuesByEstimationMethod(metricData[0], estimationMethod),
		(metricData, estimationMethod) => convertChartMetricValuesByEstimationMethod(metricData[0], estimationMethod),
		{
			title: "Bugs velocity",
			// TODO(ACM-7253): not always sps
			subtitle: "Story points",
			isIncreasePositive: true,
			// ACM-7002 Note: velocity unit is calculated by estimation method and cannot have a default value
			unit: "",
			devTooltip: `Total amount of bug type issues which were completed during the selected time period in ${ESTIMATION_UNITS}`,
			teamTooltip: ``
		},
	),

	new DevStatColumn(
		WorkforceHealthMetrics.AveragePRCodingAndReviewTime,
		[DevStatsMetricNameInDatabase.pr_coding_and_review_time_sum_seconds, DevStatsMetricNameInDatabase.merged_prs],
		(metricData) => {
			const [codingAndReviewTime, mergedPrs] = metricData;
			return calcPeriodicalMetricsAverage(
				dividePeriodicalMetricValues(convertMetricValuesToPeriodicalValues(codingAndReviewTime), SECONDS_TO_HOURS_MULTIPLIER),
				convertMetricValuesToPeriodicalValues(mergedPrs),
			);
		},
		(metricData) => {
			const [codingAndReviewTime, mergedPrs] = metricData;
			return calcMetricsAverageForChart(
				divideMetricChartValues(convertMetricValuesToChartValues(codingAndReviewTime), SECONDS_TO_HOURS_MULTIPLIER),
				convertMetricValuesToChartValues(mergedPrs),
			);
		},
		{
			title: "Avg. PR coding & review time",
			subtitle: "Hours",
			isIncreasePositive: false,
			unit: "hours",
			devTooltip: `Average coding time and review time of all the PRs which were opened by the team member and were merged during the selected time period`,
			teamTooltip: ``
		},
	),

	new DevStatColumn(
		WorkforceHealthMetrics.CompletedHighPriorityTasks,
		[DevStatsMetricNameInDatabase.completed_high_priority_tasks],
		(metricData) => convertMetricValuesToPeriodicalValues(metricData[0]),
		(metricData) => convertMetricValuesToChartValues(metricData[0]),
		{
			title: "Resolved high priority tasks",
			subtitle: "Tasks",
			isIncreasePositive: true,
			unit: "tasks",
			devTooltip: `The total amount of issues that are marked as one of the two highest priorities and were completed during the selected time period`,
			teamTooltip: ``
		}
	),

	new DevStatColumn(
		WorkforceHealthMetrics.ReviewedPRs,
		[DevStatsMetricNameInDatabase.reviewed_prs],
		(metricData) => convertMetricValuesToPeriodicalValues(metricData[0]),
		(metricData) => convertMetricValuesToChartValues(metricData[0]),
		{
			title: "Reviewed PRs",
			subtitle: "Percentage",
			isIncreasePositive: true,
			unit: "PRs",
			devTooltip: `The total amount of pull requests reviewed by the team member`,
			teamTooltip: ``
		}
	),

	new DevStatColumn(
		WorkforceHealthMetrics.AveragePRSize,
		[DevStatsMetricNameInDatabase.lines_of_code, DevStatsMetricNameInDatabase.merged_prs],
		(metricData) => {
			const [linesOfCode, mergedPrs] = metricData;
			return calcPeriodicalMetricsAverage(
				convertMetricValuesToPeriodicalValues(linesOfCode),
				convertMetricValuesToPeriodicalValues(mergedPrs),
			);
		},
		(metricData) => {
			const [linesOfCode, mergedPrs] = metricData;
			return calcMetricsAverageForChart(
				convertMetricValuesToChartValues(linesOfCode),
				convertMetricValuesToChartValues(mergedPrs),
			);
		},
		{
			title: "Avg. PR size",
			subtitle: "Lines of code",
			isIncreasePositive: false,
			unit: "LOC",
			devTooltip: `Average PR size of all the PRs which were opened by the team member and were merged during the selected time period`,
			teamTooltip: `Average size of all PRs which were opened by the team members and were merged during the selected time period.`
		}
	),

	new DevStatColumn(
		WorkforceHealthMetrics.MergedPRs,
		[DevStatsMetricNameInDatabase.merged_prs],
		(metricData) => convertMetricValuesToPeriodicalValues(metricData[0]),
		(metricData) => convertMetricValuesToChartValues(metricData[0]),
		{
			title: "Merged PRs",
			subtitle: "Pull requests",
			isIncreasePositive: true,
			unit: "PRs",
			devTooltip: `The total amount of merged PRs, which were opened by the team member and were merged during the selected time period`,
			teamTooltip: `The total amount of PRs which were merged during the selected time period with an PR owner from the selected team. `
		},
	),

	new DevStatColumn(
		WorkforceHealthMetrics.LinesOfCode,
		[DevStatsMetricNameInDatabase.lines_of_code],
		(metricData) => convertMetricValuesToPeriodicalValues(metricData[0]),
		(metricData) => convertMetricValuesToChartValues(metricData[0]),
		{
			title: "Lines of code",
			subtitle: "",
			isIncreasePositive: false,
			unit: "lines",
			devTooltip: `Amount of new and changed lines of code written by the team member during the selected time period`,
			teamTooltip: ``
		},
	),

	new DevStatColumn(
		WorkforceHealthMetrics.ClosedUnmergedPRs,
		[DevStatsMetricNameInDatabase.closed_unmerged_prs],
		(metricData) => convertMetricValuesToPeriodicalValues(metricData[0]),
		(metricData) => convertMetricValuesToChartValues(metricData[0]),
		{
			title: "Closed unmerged PRs",
			subtitle: "Pull requests",
			isIncreasePositive: false,
			unit: "PRs",
			devTooltip: `Total amount of pull requests that have been closed without being merged`,
			teamTooltip: ``
		},
	),

	new DevStatColumn(
		WorkforceHealthMetrics.ResolvedTasks,
		[DevStatsMetricNameInDatabase.resolved_tasks],
		(metricData) => convertMetricValuesToPeriodicalValues(metricData[0]),
		(metricData) => convertMetricValuesToChartValues(metricData[0]),
		{
			title: "Resolved tasks",
			subtitle: "Percentage",
			isIncreasePositive: true,
			unit: "Tasks",
			devTooltip: `Total amount of issues which were assigned to the team member and were completed during the selected time period`,
			teamTooltip: ``
		},
	),

	new DevStatColumn(
		WorkforceHealthMetrics.OpenedPRs,
		[DevStatsMetricNameInDatabase.opened_prs],
		(metricData) => convertMetricValuesToPeriodicalValues(metricData[0]),
		(metricData) => convertMetricValuesToChartValues(metricData[0]),
		{
			title: "Opened PRs",
			subtitle: "Pull requests",
			isIncreasePositive: true,
			unit: "PRs",
			devTooltip: `The total amount of PRs which were opened by the team member during the selected time period`,
			teamTooltip: ``
		},
	),

	new DevStatColumn(
		WorkforceHealthMetrics.AveragePRCodingTime,
		[DevStatsMetricNameInDatabase.pr_coding_time_sum_seconds, DevStatsMetricNameInDatabase.merged_prs],
		(metricData) => {
			const [codingTime, mergedPrs] = metricData;
			return calcPeriodicalMetricsAverage(
				dividePeriodicalMetricValues(convertMetricValuesToPeriodicalValues(codingTime), SECONDS_TO_HOURS_MULTIPLIER),
					convertMetricValuesToPeriodicalValues(mergedPrs),
				);
		},
		(metricData) => {
			const [prCodingTime, mergedPrs] = metricData;
			return calcMetricsAverageForChart(
				divideMetricChartValues(convertMetricValuesToChartValues(prCodingTime), SECONDS_TO_HOURS_MULTIPLIER),
				convertMetricValuesToChartValues(mergedPrs),
			);
		},
		{
			title: "Avg. PR coding time",
			isIncreasePositive: false,
			unit: "hours",
			subtitle: "Hours",
			devTooltip: `The average time spent between the initial commit and the first review request. Only merged pull requests are included in the calculation`,
			teamTooltip: ``
		},
	),

	new DevStatColumn(
		WorkforceHealthMetrics.AveragePRCycleTime,
		[DevStatsMetricNameInDatabase.pr_cycle_time_sum_seconds, DevStatsMetricNameInDatabase.merged_prs],
		(metricData) => {
			const [cycleTime, mergedPrs] = metricData;
			return calcPeriodicalMetricsAverage(
				dividePeriodicalMetricValues(convertMetricValuesToPeriodicalValues(cycleTime), SECONDS_TO_HOURS_MULTIPLIER),
					convertMetricValuesToPeriodicalValues(mergedPrs),
				);
		},
		(metricData) => {
			const [prCycleTime, mergedPrs] = metricData;
			return calcMetricsAverageForChart(
				divideMetricChartValues(convertMetricValuesToChartValues(prCycleTime), SECONDS_TO_HOURS_MULTIPLIER),
				convertMetricValuesToChartValues(mergedPrs),
			);
		},
		{
			title: "Avg. PR cycle time",
			isIncreasePositive: false,
			unit: "hours",
			subtitle: "Hours",
			devTooltip: `The average time spent between the initial commit until the pull request is actually merged. Only merged pull requests are included in the calculation`,
			teamTooltip: ``
		},
	),

	new DevStatColumn(
		WorkforceHealthMetrics.AveragePRPendingMergeTime,
		[DevStatsMetricNameInDatabase.pr_pending_merge_time_sum_seconds, DevStatsMetricNameInDatabase.merged_prs],
		(metricData) => {
			const [pendingMergeTime, mergedPrs] = metricData;
			return calcPeriodicalMetricsAverage(
				dividePeriodicalMetricValues(convertMetricValuesToPeriodicalValues(pendingMergeTime), SECONDS_TO_HOURS_MULTIPLIER),
					convertMetricValuesToPeriodicalValues(mergedPrs),
				);
		},
		(metricData) => {
			const [prPendingMergeTime, mergedPrs] = metricData;
			return calcMetricsAverageForChart(
				divideMetricChartValues(convertMetricValuesToChartValues(prPendingMergeTime), SECONDS_TO_HOURS_MULTIPLIER),
				convertMetricValuesToChartValues(mergedPrs),
			);
		},
		{
			title: "Avg. PR pending merge time",
			isIncreasePositive: false,
			unit: "hours",
			subtitle: "Hours",
			devTooltip: `The average time spent between the first review approval until the pull request is actually merged`,
			teamTooltip: ``
		},
	),

	new DevStatColumn(
		WorkforceHealthMetrics.AveragePRPendingReviewTime,
		[DevStatsMetricNameInDatabase.pr_pending_review_time_sum_seconds, DevStatsMetricNameInDatabase.merged_prs],
		(metricData) => {
			const [pendingReviewTime, mergedPrs] = metricData;
			return calcPeriodicalMetricsAverage(
				dividePeriodicalMetricValues(convertMetricValuesToPeriodicalValues(pendingReviewTime), SECONDS_TO_HOURS_MULTIPLIER),
					convertMetricValuesToPeriodicalValues(mergedPrs),
				);
		},
		(metricData) => {
			const [prPendingReviewTime, mergedPrs] = metricData;
			return calcMetricsAverageForChart(
				divideMetricChartValues(convertMetricValuesToChartValues(prPendingReviewTime), SECONDS_TO_HOURS_MULTIPLIER),
				convertMetricValuesToChartValues(mergedPrs),
			);
		},
		{
			title: "Avg. PR pending review time",
			isIncreasePositive: false,
			unit: "hours",
			subtitle: "Hours",
			devTooltip: `The average time spent between the first review request to when the pull request went into actual review. Only merged pull requests are included in the calculation`,
			teamTooltip: ``
		},
	),

	new DevStatColumn(
		WorkforceHealthMetrics.AveragePRReviewTime,
		[DevStatsMetricNameInDatabase.pr_review_time_sum_seconds, DevStatsMetricNameInDatabase.merged_prs],
		(metricData) => {
			const [reviewTime, mergedPrs] = metricData;
			return calcPeriodicalMetricsAverage(
				dividePeriodicalMetricValues(convertMetricValuesToPeriodicalValues(reviewTime), SECONDS_TO_HOURS_MULTIPLIER),
					convertMetricValuesToPeriodicalValues(mergedPrs),
				);
		},
		(metricData) => {
			const [prPendingReviewTime, mergedPrs] = metricData;
			return calcMetricsAverageForChart(
				divideMetricChartValues(convertMetricValuesToChartValues(prPendingReviewTime), SECONDS_TO_HOURS_MULTIPLIER),
				convertMetricValuesToChartValues(mergedPrs),
			);
		},
		{
			title: "Avg. PR review time",
			isIncreasePositive: false,
			unit: "hours",
			subtitle: "Hours",
			devTooltip: `The average time spent between beginning a review until the first approval. Only merged pull requests are included in the calculation`,
			teamTooltip: ``
		},
	),

	new DevStatColumn(
		WorkforceHealthMetrics.LeadTime,
		[DevStatsMetricNameInDatabase.lead_time_seconds, DevStatsMetricNameInDatabase.resolved_tasks],
		(metricData) => {
			const [leadTime, doneTasks] = metricData;
			return calcPeriodicalMetricsAverage(
				dividePeriodicalMetricValues(convertMetricValuesToPeriodicalValues(leadTime), SECONDS_TO_DAYS_MULTIPLIER),
				convertMetricValuesToPeriodicalValues(doneTasks),
			);
		},
		(metricData) => {
			const [leadTime, doneTasks] = metricData;
			return calcMetricsAverageForChart(
				divideMetricChartValues(convertMetricValuesToChartValues(leadTime), SECONDS_TO_DAYS_MULTIPLIER),
				convertMetricValuesToChartValues(doneTasks),
			);
		},
		{
			title: "Lead time",
			isIncreasePositive: false,
			unit: "days",
			subtitle: "Days",
			devTooltip: `Average lead time (time between opened until done) of the issues which were assigned to the team member and were completed during the selected time period`,
			teamTooltip: `Average lead time (the time from open until done) of all issues assigned to the team, which were completed during the time period. `
		},
	),

	new DevStatColumn(
		WorkforceHealthMetrics.MTTR,
		[DevStatsMetricNameInDatabase.highest_priority_bugs_lead_time_seconds, DevStatsMetricNameInDatabase.completed_highest_priority_bugs_count],
		(metricData) => {
			const [highestPriorityBugsLeadTimeSeconds, completedHighestPriorityBugsCount] = metricData;
			return calcPeriodicalMetricsAverage(
				dividePeriodicalMetricValues(convertMetricValuesToPeriodicalValues(highestPriorityBugsLeadTimeSeconds), SECONDS_TO_HOURS_MULTIPLIER),
				convertMetricValuesToPeriodicalValues(completedHighestPriorityBugsCount),
			);
		},
		(metricData) => {
			const [highestPriorityBugsLeadTimeSeconds, completedHighestPriorityBugsCount] = metricData;
			return calcMetricsAverageForChart(
				divideMetricChartValues(convertMetricValuesToChartValues(highestPriorityBugsLeadTimeSeconds), SECONDS_TO_HOURS_MULTIPLIER),
				convertMetricValuesToChartValues(completedHighestPriorityBugsCount),
			);
		},
		{
			title: "MTTR",
			isIncreasePositive: false,
			unit: "hours",
			subtitle: "Hours",
			devTooltip: `Average time to resolve bugs (time from open until done) of the two highest priorities, of bugs which were resolved during the selected time period`,
			teamTooltip: `Average time to resolve bugs of the two highest priorities, from open until resolved. A bug is linked with  the team based on its assignee.`
		}
	),

	new DevStatColumn(
		WorkforceHealthMetrics.ResolvedBugs,
		[DevStatsMetricNameInDatabase.resolved_bugs],
		(metricData) => convertMetricValuesToPeriodicalValues(metricData[0]),
		(metricData) => convertMetricValuesToChartValues(metricData[0]),
		{
			title: "Resolved bugs",
			subtitle: "Issues",
			isIncreasePositive: true,
			unit: "Bugs",
			devTooltip: `The total amount of bugs the team member resolved during the selected time period`,
			teamTooltip: ``
		},
	),

	new DevStatColumn(
		WorkforceHealthMetrics.AveragePrComments,
		[DevStatsMetricNameInDatabase.pr_comments, DevStatsMetricNameInDatabase.merged_prs],
		(metricData) => {
			const [prComments, mergedPrs] = metricData;
			return calcPeriodicalMetricsAverage(
					convertMetricValuesToPeriodicalValues(prComments),
					convertMetricValuesToPeriodicalValues(mergedPrs),
				);
		},
		(metricData) => {
			const [prComments, mergedPrs] = metricData;
			return calcMetricsAverageForChart(
				convertMetricValuesToChartValues(prComments),
				convertMetricValuesToChartValues(mergedPrs),
			);
		},
		{
			title: "Avg. number of comments in PRs",
			isIncreasePositive: false,
			unit: "comment",
			subtitle: "Comments",
			devTooltip: `Avg. number of comments in all the PRs which were opened by the team member and merged during the selected time period. Includes comments made by the PR creator`,
			teamTooltip: ``
		},
	),

	new DevStatColumn(
		WorkforceHealthMetrics.WorkDistributionBugsPercent,
		[DevStatsMetricNameInDatabase.bugs_velocity, DevStatsMetricNameInDatabase.velocity],
		(metricData, estimationMethod) => {
			const [bugsVelocity, velocity] = metricData;
			return calcPeriodicalMetricsPercentage(
				convertPeriodicalMetricValuesByEstimationMethod(bugsVelocity, estimationMethod),
				convertPeriodicalMetricValuesByEstimationMethod(velocity, estimationMethod),
			);
		},
		(metricData, estimationMethod) => {
			const [bugsVelocity, velocity] = metricData;
			return calcMetricsPercentForChart(
				convertChartMetricValuesByEstimationMethod(bugsVelocity, estimationMethod),
				convertChartMetricValuesByEstimationMethod(velocity, estimationMethod),
			);
		},
		{
			title: "Work distribution",
			isIncreasePositive: false,
			unit: "percent",
			subtitle: "Percentage",
			devTooltip: "",
			teamTooltip: `The percent of completed work that is "bug" type`
		},
	),
];

export const WHF_METRICS_TO_COLUMNS: Record<WorkforceHealthMetrics, DevStatColumn> = Object
	.assign({}, ...devStatColumns.map(x => ({[x.dashboardMetricName]: x})));

export const WHF_METRIC_NAMES_BY_CATEGORIES: Record<string, WorkforceHealthMetrics[]> = {
	performance: [
		WorkforceHealthMetrics.Velocity,
		WorkforceHealthMetrics.BugsVelocity,
		WorkforceHealthMetrics.MergedPRs,
		WorkforceHealthMetrics.MTTR,
		WorkforceHealthMetrics.ResolvedBugs,
	],
	activity: [
		WorkforceHealthMetrics.ActiveDays,
		WorkforceHealthMetrics.OpenedPRs,
		WorkforceHealthMetrics.LinesOfCode,
	],
	collaboration: [
		WorkforceHealthMetrics.ReviewedPRs,
		WorkforceHealthMetrics.AveragePRPendingReviewTime,
		WorkforceHealthMetrics.CompletedHighPriorityTasks,
		WorkforceHealthMetrics.AveragePrComments
	],
	efficiency: [
		WorkforceHealthMetrics.AveragePRSize,
		WorkforceHealthMetrics.AveragePRCycleTime,
		WorkforceHealthMetrics.AveragePRCodingTime,
		WorkforceHealthMetrics.AveragePRCodingAndReviewTime,
		WorkforceHealthMetrics.AveragePRPendingMergeTime,
		WorkforceHealthMetrics.ClosedUnmergedPRs,
		WorkforceHealthMetrics.ResolvedTasks,
		WorkforceHealthMetrics.LeadTime
	]
};

export const TEAM_COMPARISON_METRIC_NAMES_BY_CATEGORIES: Record<string, WorkforceHealthMetrics[]> = {
	performance: [
		WorkforceHealthMetrics.Velocity,
		WorkforceHealthMetrics.MergedPRs,
		WorkforceHealthMetrics.MTTR,
		WorkforceHealthMetrics.ResolvedBugs,
		WorkforceHealthMetrics.WorkDistributionBugsPercent,
	],
	activity: [
		WorkforceHealthMetrics.ActiveDays,
		WorkforceHealthMetrics.OpenedPRs,
		WorkforceHealthMetrics.LinesOfCode,
	],
	collaboration: [
		WorkforceHealthMetrics.AveragePRPendingReviewTime,
	],
	efficiency: [
		WorkforceHealthMetrics.AveragePRSize,
		WorkforceHealthMetrics.AveragePRCycleTime,
		WorkforceHealthMetrics.AveragePRCodingTime,
		WorkforceHealthMetrics.AveragePRPendingMergeTime,
		WorkforceHealthMetrics.ClosedUnmergedPRs,
		WorkforceHealthMetrics.LeadTime,
	]
};
