import { AcumenTaskType, DashboardSortOrder, DashboardTaskExpandOption, DashboardTaskSortOption, DashboardTaskTimeContext, IDashboardTaskDetailed } from "@acumen/dashboard-common";
import classNames from "classnames";
import { Avatar } from "../../../components/avatar";
import { ExternalImage } from "../../../components/external-image";
import { TaskStatus } from "../../../components/formatters";
import LoadingIndicator from "../../../components/loader";
import { observer } from "mobx-react";
import { useStores } from "../../../mobx-stores";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Popup } from "semantic-ui-react";

const NO_BUGS_PLACEHOLER = "No bugs";

interface INewBugsTable {
	setSelectedTaskInPanel: (item: IDashboardTaskDetailed) => void;
	selectedTaskInPanel?: IDashboardTaskDetailed;
	searchedTeamId: string | undefined;
	dataContributorFilter: string[] | undefined;
	pageError: boolean | undefined;
}

// tslint:disable-next-line: variable-name
const NewBugsTable = observer((props: INewBugsTable) => {
	const {
		setSelectedTaskInPanel,
		selectedTaskInPanel,
		searchedTeamId,
		dataContributorFilter,
		pageError
	} = { ...props };

	const {
		tasksStore: {
			fetchTasksByOptions
		}
	} = useStores();

	const [newBugs, setNewBugs] = useState<IDashboardTaskDetailed[] | undefined>(undefined);

	useEffect(() => {
		function fetchNewBugs() {
			if (!searchedTeamId) {
				return;
			}

			// tslint:disable-next-line: no-floating-promises
			fetchTasksByOptions({
				filter: {
					teamId: searchedTeamId,
					dataContributorIds: dataContributorFilter,
					startTime: moment.utc(new Date()).subtract(2, "weeks").toDate(),
					timeContext: DashboardTaskTimeContext.CreatedDate,
					types: [AcumenTaskType.Bug]
				},
				staticGroupingLimit: 0,
				sortField: DashboardTaskSortOption.CreatedDate,
				sortOrder: DashboardSortOrder.Descending,
				expand: [DashboardTaskExpandOption.Highlights, DashboardTaskExpandOption.Progress, DashboardTaskExpandOption.RelatedPRs]
			}).then(response => {
				if (!mounted) {
					return;
				}

				if (!response || !Array.isArray(response.tasks)) {
					return;
				}

				setNewBugs(response.tasks);
			});
		}
		let mounted = true;

		if (!searchedTeamId || !dataContributorFilter) {
			return;
		}

		setNewBugs(undefined);
		fetchNewBugs();

		return () => { mounted = false; };
	}, [dataContributorFilter]);

	return (
		<div className="ui full-width card team-dashboard-table">
			<LoadingIndicator local={true} isActive={!newBugs}>
				{
					newBugs?.length === 0 || pageError
					? NO_BUGS_PLACEHOLER
					: <div className="acu-scroll">
						<table className="ui very compact small fixed single line sticky-table unstackable table min-width">
							<thead>
								<tr>
									<th className="left aligned two wide" />
									<th className="ten wide">Title</th>
									<th className="five wide">Status</th>
								</tr>
							</thead>
							<tbody>
								{newBugs && newBugs.map((task, i: number) => {
									return (
										<tr
											className={classNames("hoverable-container", "clickable", { active: selectedTaskInPanel?.entityId === task.entityId })}
											key={i} onClick={() => setSelectedTaskInPanel(task)}>
											<td>
												<div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
													{(task.priority.internalPriority !== null && task.priority.internalPriority.trim().length > 0) && (
														<Popup
															size="mini"
															basic={true}
															content={task.priority.internalPriority}
															trigger={
																<ExternalImage src={task.priority.iconUrl ?? ""} className="ui micro image" />
															}
														/>
													)}
													<Popup
														size="mini"
														basic={true}
														content={task.assignee?.primaryDisplayName}
														trigger={(
															<Avatar dataContributor={task.assignee} />
														)}
													/>
												</div>
											</td>
											<td>
												<div className="hover-ellipsis">
													{task.publicHtmlUrl
														? <a href={task.publicHtmlUrl} target="_blank" rel="noopener noreferrer" className="acu-link">{task.publicIdentifier}</a>
														: task.publicIdentifier
													}
													<span className="task-title after-space">{task.title}</span>
												</div>
											</td>
											<td>
												<TaskStatus taskStatus={task.status.status} internalStatus={task.status.internalStatus}/>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				}
			</LoadingIndicator>
		</div>
	);
});

export default NewBugsTable;
