import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useState } from "react";
import { useStores } from "../../../mobx-stores";
import { CategoryChartSeriesData } from "../../../pages/my-team/analytics/charts/charts";
import LoadingIndicator from "../../../components/loader";
import { IChartScope } from "./chart-scope";
import useDeepCompareEffect from "use-deep-compare-effect";
import _ from "lodash";
import { Grid, Popup } from "semantic-ui-react";
import PieChart from "../../../components/highchart-chart-components/pie-chart";
import { ChartExternalLegend, ILegendList } from "../../../pages/org-analytics/particles";
import uniqolor from "../../../../utils/color";

interface IProps {
	scope: IChartScope;
	mainTitle?: { title: string, tooltip?: string };
	showExternalLegend?: boolean;
	showDataLabels?: boolean;
	chartHeight: {
		mainChartHeight: number,
		summaryPieChartHeight: number
	};
}

function GitHubReportedDeploymentFrequency(props: IProps) {
	const { chartsStore } = useStores();
	const [expandedChartOptions, setExpandedChartOptions] = useState<Highcharts.Options | undefined>(undefined);
	const [summarizedChartOptions, setSummarizedChartOptions] = useState<Highcharts.Options | undefined>(undefined);
	const [summarizedLegendList, setSummarizedLegendList] = useState<{ data: ILegendList[], title?: string } | undefined>(undefined);
	const [hoveredSummaryMetric, setHoveredSummaryMetric] = useState<undefined | string>(undefined);

	const { scope, mainTitle, showDataLabels, showExternalLegend, chartHeight } = { ...props };

	function buildExpandedChartOptions(data: CategoryChartSeriesData): Highcharts.Options {
		let series: Highcharts.SeriesColumnOptions[] | undefined;
		series = Object
			.keys(data)
			.filter(key => _.sumBy(data[key], d => d[1]) > 0)
			.map(key => {
				const seriesOptions: Highcharts.SeriesColumnOptions = {
					name: key,
					color: uniqolor(key),
					data: _.cloneDeep(data[key]),
					visible: (data[key].length > 0 && _.findIndex(data[key], i => i[1] > 0) >= 0),
					tooltip: {
						headerFormat: '<span style="color:{point.color}">\u25CF</span><b> {series.name}</b>:<br>',
						pointFormatter: function _pointFormatter() {
							return `${this.y} deployments<br />`;
						},
						followPointer: true
					},
					showInLegend: showExternalLegend ? false : true,
					type: "column"
				};
				return seriesOptions;
			});
		const options: Highcharts.Options = {
			title: undefined,
			chart: chartHeight ? { height: chartHeight?.mainChartHeight } : {},
			xAxis: {
				gridLineWidth: 1,
				type: "datetime"
			},
			tooltip: {
				useHTML: true,
				style: {
					pointerEvents: "auto"
				}
			},
			yAxis: [
				{
					min: 0,
					title: {
						text: "Deployments"
					},
					labels: {
						formatter: function _formatter() {
							return `${this.value}`;
						}
					}
				}
			],
			credits: {
				enabled: false
			},
			series
		};

		return options;
	}

	function buildSummarizedChartOptions(data: CategoryChartSeriesData): Highcharts.Options {
		const piePoints = Object
			.keys(data)
			.map(key => {
				const totalValues = _.sumBy(data[key], d => d[1]);

				const seriesOptions: Highcharts.PointOptionsObject = {
					name: key,
					color: uniqolor(key),
					y: totalValues,
				};
				return seriesOptions;
			})
			.filter(point => point.y && point.y > 0);
		setSummarizedLegendList({
			data: ((array: Highcharts.PointOptionsObject[]): ILegendList[] => {
				return array.map(point => {
					return {
						name: point.name?.toString() ?? "",
						value: point.y ?? 0,
						color: point.color?.toString(),
					};
				});
			})(piePoints),
			title: "Total deployments"
		});

		const pieSeries: Highcharts.SeriesPieOptions = {
			type: "pie",
			data: piePoints,
			innerSize: "80%",
			tooltip: {
				pointFormat: "<b>{point.percentage:.1f}%</b>"
			}
		};
		const options: Highcharts.Options = {
			chart: {
				plotBackgroundColor: undefined,
				plotBorderWidth: undefined,
				plotShadow: false
			},
			title: {
				text: `% out of total deployments`,
				verticalAlign: "middle",
				floating: true
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: "pointer",
					dataLabels: showDataLabels ? {
						enabled: true,
						format: "<b>{point.name}</b>: {point.y} hr"
					} : {
						enabled: false
					}
				}
			},
			credits: {
				enabled: false
			},
			series: [pieSeries]
		};

		return options;
	}

	async function fetchData(): Promise<CategoryChartSeriesData | undefined> {
		return chartsStore.fetchGitHubReportedDeploymentFrequency(scope.interval, scope.startTime,
			scope.endTime, scope.timezone, scope.teamIds, undefined, scope.repositoryIds, ["Production", "Staging"]);
	}

	function shouldFetchData(): boolean {
		return (!chartsStore.isLoadingGitHubReportedDeploymentFrequencyChartData);
	}

	useDeepCompareEffect(() => {
		let isMounted = true;
		async function fetchChartData() {
			const data = await fetchData();
			if (isMounted && data) {
				setExpandedChartOptions(buildExpandedChartOptions(data));
				setSummarizedChartOptions(buildSummarizedChartOptions(data));
			}
		}

		if (shouldFetchData()) {
			// We need to clear those options as we might have a different count of columns which will cause to a crash in highcharts
			setExpandedChartOptions(undefined);
			// tslint:disable-next-line: no-floating-promises
			fetchChartData().then(() => {
				isMounted = false;
			});
		}
	}, [props]);

	return (
		<LoadingIndicator local={true} isActive={expandedChartOptions === undefined && summarizedChartOptions === undefined}>
			<Grid.Column width={10}>
				{mainTitle &&
					<h2 className="ui title acu-capitalize">{mainTitle.title}
						{mainTitle.tooltip && <Popup
							hoverable={true}
							wide={true}
							className={"tiny-text"}
							position="top center"
							content={mainTitle.tooltip}
							trigger={
								<i className="chart-tooltip info circle outline icon" />
							}
						/>}
					</h2>}
				{expandedChartOptions && <HighchartsReact
					highcharts={Highcharts}
					options={expandedChartOptions}
				/>}
			</Grid.Column>
			<Grid.Column width={6}>
				{mainTitle &&
					<h2 className="ui title acu-capitalize">{summarizedLegendList?.title}</h2>
				}
				{showExternalLegend &&
					<div className="ui grid full-height">
						<Grid.Column width={8}>
							{summarizedChartOptions &&
								<div className="pie-chart-container">
									<PieChart
										series={summarizedChartOptions?.series}
										triggerHover={hoveredSummaryMetric}
										height={chartHeight ? chartHeight?.summaryPieChartHeight : undefined}
									/>
								</div>
							}
						</Grid.Column>
						<Grid.Column width={8}>
							<div className="legend-container">
								{summarizedChartOptions?.series !== undefined &&
									<ChartExternalLegend
										legendList={summarizedLegendList?.data}
										hoveredEntry={hoveredSummaryMetric}
										setHoveredEntry={setHoveredSummaryMetric}
									/>}
							</div>
						</Grid.Column>
					</div>
				}
				{summarizedChartOptions && !showExternalLegend && <HighchartsReact
					highcharts={Highcharts}
					options={summarizedChartOptions}
				/>}
			</Grid.Column>
		</LoadingIndicator>
	);
}

export default GitHubReportedDeploymentFrequency;
