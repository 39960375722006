import urlJoin from "url-join";
import { BASE_ROUTE } from "../api-routes";
import { APIContextProvider } from "../../../services/api-context-provider";

export abstract class BaseCustomerApiClient {
	constructor(private readonly _apiContextProvider: APIContextProvider) {
	}

	protected createCustomerEntityRoute(url: string): string {
		return urlJoin(BASE_ROUTE, this.customerId.toString(), url);
	}

	protected get token() {
		const context = this._apiContextProvider.getContext();

		if (!context.token) {
			throw new Error("JWT token does not exist in API context");
		}

		return context.token;
	}

	protected get customerId() {
		const context = this._apiContextProvider.getContext();

		if (!context || !context.customerId) {
			throw new Error("Customer ID doesn't exist in the API context.");
		}

		return context.customerId;
	}

	protected get tokenType() {
		const context = this._apiContextProvider.getContext();

		if (!context.token) {
			throw new Error("JWT token does not exist in API context");
		}

		return context.tokenType;
	}
}
