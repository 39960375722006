import * as React from "react";
import "./developer-stats-table-aggregated-data-header-cell.scss";

export const DeveloperStatsTableAggregatedDataHeaderCell = () => {
	return (
		<th className="developer-stats-table-aggregated-data-header-cell">
			<div className="developer-stats-table-aggregated-data-header-cell-content">
				Average
			</div>
		</th>
	);
};
