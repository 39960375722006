import React from "react";
import {
	AcumenTaskStatus,
	DashboardPullRequestStatus,
} from "@acumen/dashboard-common";
import { PullRequestStatus, TaskStatus } from "../../components/formatters";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
	<>
		<h3 className="ui header">Tasks</h3>
		<div className="ui relaxed divided list">
			{Object.values(AcumenTaskStatus).map((status, index) => (
				<div className="item" key={index}>
					<div className="content">
						<TaskStatus status={status} />
					</div>
				</div>
			))}
		</div>
		<h3 className="ui header">Pull requests</h3>
		<div className="ui relaxed divided list">
			{Object.values(DashboardPullRequestStatus).map((status, index) => (
				<div className="item" key={index}>
					<div className="content">
						<PullRequestStatus status={status} />
					</div>
				</div>
			))}
		</div>
	</>
);
