import React, { useCallback } from "react";
import classNames from "classnames";
import { ResolvedCustomTableColumnProps } from "../../types";
import { getItemPropertyValueAsString } from "../../utils";
import "./custom-table-data-cell.scss";

export interface CustomTableDataCellProps<T extends object> {
	column: ResolvedCustomTableColumnProps<T>;
	item: T;
	index: number;
	items: T[];
}

export const CustomTableDataCell = <T extends object>(props: CustomTableDataCellProps<T>) => {
	const { column, item, index, items } = props;
	const { key, dataCellClassName, sticky, component: Component, select, onClick } = column;
	const className = classNames(
		dataCellClassName,
		"custom-table-cell",
		"custom-table-cell-data",
		sticky && "variant-sticky",
	);
	const handleClick = useCallback(() => onClick?.(item, column), [item, column, onClick]);

	return (
		<td className={className} onClick={handleClick}>
			<div className="custom-table-cell-content">
				{Component
					? (
						<Component
							column={column}
							item={item}
							index={index}
							items={items}
						/>
					)
					: (select
						? select(item, column, index, items)
						: getItemPropertyValueAsString(item, key))}
			</div>
		</td>
	);
};
