import { ISeriesProps } from "../../../components/dashboard-task/task-type-series-prop";
import _ from "lodash";
import { useStores } from "../../../mobx-stores";
import React, { useState } from "react";
import { CategoryChartSeriesData } from "../../../services/crud/charts-api-client";
import { IChartScope } from "./chart-scope";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import PieChart from "../../../components/highchart-chart-components/pie-chart";
import { round } from "@acumen/common";
import { ChartExternalLegend, ILegendList } from "../particles";
import useDeepCompareEffect from "use-deep-compare-effect";
import LoadingIndicator from "../../../components/loader";
import { Grid, Popup } from "semantic-ui-react";
import colorScheme from "../../../pages/my-team/analytics/charts/chart-color-scheme";
import { inflect } from "@acumen/common";

const SERIES_BY_LINK_STATUS: { [key: string]: ISeriesProps } = {
	true: {
		seriesName: "Linked",
		seriesColorHex: colorScheme.genericScheme[3],
		sortOrder: 1
	},
	false: {
		seriesName: "Unlinked",
		seriesColorHex: colorScheme.genericScheme[10],
		sortOrder: 0
	}
};

interface IProps {
	scope: IChartScope;
	mainTitle?: { title: string, tooltip?: string };
	showDataLabels?: boolean;
	showExternalLegend?: boolean;
	chartHeight?: {
		mainChartHeight: number,
		summaryPieChartHeight: number
	};
}

function TraceabilityOverTime(props: IProps) {
	const { teamsReportStore } = useStores();
	const [expandedChartOptions, setExpandedChartOptions] = useState<Highcharts.Options | undefined>(undefined);
	const [summarizedChartOptions, setSummarizedChartOptions] = useState<Highcharts.Options | undefined>(undefined);
	const [summarizedLegendList, setSummarizedLegendList] = useState<{ data: ILegendList[], title?: string } | undefined>(undefined);
	const [hoveredSummaryMetric, setHoveredSummaryMetric] = useState<undefined | string>(undefined);

	const { scope, mainTitle, showDataLabels, showExternalLegend, chartHeight } = { ...props };

	function buildExpandedChartOptions(data: CategoryChartSeriesData): Highcharts.Options {
		let series: Highcharts.SeriesColumnOptions[] | undefined;
		series = Object
			.keys(data)
			.map(key => {
				const displayProperties = SERIES_BY_LINK_STATUS[key];
				const seriesOptions: Highcharts.SeriesColumnOptions = {
					name: displayProperties.seriesName,
					index: displayProperties.sortOrder,
					legendIndex: displayProperties.sortOrder,
					color: displayProperties.seriesColorHex,
					data: _.cloneDeep(data[key]),
					visible: (data[key].length > 0 && _.findIndex(data[key], i => i[1] > 0) >= 0),
					type: "column",
					showInLegend: showExternalLegend ? false : true,
					stack: "link-type"
				};
				return seriesOptions;
			});

		const options: Highcharts.Options = {
			title: undefined,
			chart: chartHeight ? { height: chartHeight?.mainChartHeight } : {},
			tooltip: {
				headerFormat: '<span style="color:{point.color}">\u25CF</span><b> {series.name}</b>:<br>',
				pointFormatter: function _pointFormatter() {
					return inflect(this.y ?? 0, " merged PR", " merged PRs");
				},
			},
			xAxis: {
				gridLineWidth: 1,
				type: "datetime"
			},
			yAxis: [
				{
					min: 0,
					title: {
						text: "%"
					}
				}
			],
			plotOptions: {
				column: {
					pointPadding: 0.2,
					borderWidth: 0,
					stacking: "percent",
					dataLabels: {
						enabled: true,
						// tslint:disable-next-line: object-literal-shorthand
						formatter: function _formatter() {
							if (!this.y || !this.total || this.y === 0 || this.total === 0) {
								return "0%";
							}
							return `${round(100 * this.y / this.total, 0)}%`;
						},
					},
				}
			},
			credits: {
				enabled: false
			},
			series
		};

		return options;
	}
	type EnrichedPointOptions = Highcharts.PointOptionsObject & {
		totalCountPerLinkStatus: number;
	};
	function buildSummarizedChartOptions(data: CategoryChartSeriesData): Highcharts.Options {
		const totalCount = _.sum(Object.keys(data).map(key => _.sumBy(data[key], d => d[1])));

		const piePoints = Object.keys(data)
			.map(key => {
				const displayProperties = SERIES_BY_LINK_STATUS[key];
				const totalCountPerLinkStatus = _.sumBy(data[key], d => d[1]);
				const seriesOptions: EnrichedPointOptions = {
					name: displayProperties.seriesName,
					color: displayProperties.seriesColorHex,
					y: totalCount === 0 ? 0 : round(100 * totalCountPerLinkStatus / totalCount, 1),
					totalCountPerLinkStatus
				};
				return seriesOptions;
			})
			.filter(point => point.y && point.y > 0);
		setSummarizedLegendList({
			data: ((array: EnrichedPointOptions[]): ILegendList[] => {
				return array.map(point => {
					return {
						name: point.name?.toString() ?? "",
						value: point.y ?? 0,
						color: point.color?.toString(),
						valueSuffix: "%"
					};
				});
			})(piePoints),
			title: "Traceability totals"
		});
		const pieSeries: Highcharts.SeriesPieOptions = {
			type: "pie",
			data: piePoints,
			innerSize: "80%",
			tooltip: {
				pointFormat: "<b>{point.totalCountPerLinkStatus:.1f} pull requests merged</b> ({point.y}%)"
			},
		};
		const options: Highcharts.Options = {
			chart: {
				plotBackgroundColor: undefined,
				plotBorderWidth: undefined,
				plotShadow: false
			},
			title: {
				verticalAlign: "middle",
				floating: true
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: "pointer",
					dataLabels: showDataLabels ? {
						enabled: true,
						format: "<b>{point.name}</b>: {point.y}"
					} : {
						enabled: false,
					}
				}
			},
			credits: {
				enabled: false
			},
			series: [pieSeries]
		};

		return options;
	}

	async function fetchData(): Promise<CategoryChartSeriesData | undefined> {
		const data = await teamsReportStore.traceabilityOverTime(scope.startTime, scope.endTime, scope.timezone, scope.interval,
			scope.teamIds, scope.repositoryIds);
		return data;
	}

	function shouldFetchData(): boolean {
		return (!teamsReportStore.isLoadingTraceabilityOverTimeData);
	}

	useDeepCompareEffect(() => {
		let isMounted = true;
		async function fetchChartData() {
			const data = await fetchData();
			if (isMounted && data) {
				setExpandedChartOptions(buildExpandedChartOptions(data));
				setSummarizedChartOptions(buildSummarizedChartOptions(data));
			}
		}

		if (shouldFetchData()) {
			// We need to clear those options as we might have a different count of columns which will cause to a crash in highcharts
			setExpandedChartOptions(undefined);
			setSummarizedChartOptions(undefined);
			// tslint:disable-next-line: no-floating-promises
			fetchChartData().then(() => {
				isMounted = false;
			});
		}
	}, [props]);

	return (
		<LoadingIndicator local={true} isActive={expandedChartOptions === undefined && summarizedChartOptions === undefined}>
			<Grid.Column width={10}>
				{mainTitle &&
					<h2 className="ui title acu-capitalize">{mainTitle.title}
						{mainTitle.tooltip && <Popup
							hoverable={true}
							wide={true}
							className={"tiny-text"}
							position="top center"
							content={mainTitle.tooltip}
							trigger={
								<i className="chart-tooltip info circle outline icon" />
							}
						/>}
					</h2>}
				{expandedChartOptions && <HighchartsReact
					highcharts={Highcharts}
					options={expandedChartOptions}
				/>}
			</Grid.Column>
			<Grid.Column width={6}>
				{mainTitle &&
					<h2 className="ui title acu-capitalize">{summarizedLegendList?.title}</h2>
				}
				{showExternalLegend &&
					<div className="ui grid full-height">
						<Grid.Column width={8}>
							{summarizedChartOptions &&
								<div className="pie-chart-container">
									<PieChart
										series={summarizedChartOptions?.series}
										triggerHover={hoveredSummaryMetric}
										height={chartHeight ? chartHeight?.summaryPieChartHeight : undefined}
									/>
								</div>
							}
						</Grid.Column>
						<Grid.Column width={8}>
							<div className="legend-container">
								{summarizedChartOptions?.series !== undefined &&
									<ChartExternalLegend
										legendList={summarizedLegendList?.data}
										hoveredEntry={hoveredSummaryMetric}
										setHoveredEntry={setHoveredSummaryMetric}
									/>}
							</div>
						</Grid.Column>
					</div>
				}
				{summarizedChartOptions && !showExternalLegend &&
					<HighchartsReact
						highcharts={Highcharts}
						options={summarizedChartOptions}
					/>}
			</Grid.Column>
		</LoadingIndicator>
	);
}

export default TraceabilityOverTime;
