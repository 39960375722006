import { WorkforceHealthMetrics } from "@acumen/database-types";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Grid, HeaderContent, Modal } from "semantic-ui-react";
import { Checkbox } from "v2/components/checkbox/checkbox";
import { useStores } from "v2/mobx-stores";
import { devStatColumns } from "../../dev-stat-columns";
import "./developer-stats-column-config-modal.scss";

export interface DevStatsColumnConfigModalProps {
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	selectedMetrics: WorkforceHealthMetrics[];
	updateSelectedMetrics: (dcId: string, modalSelectedMetrics: WorkforceHealthMetrics[]) => Promise<void>;
	metricNamesByDisplayCategories: Record<string, WorkforceHealthMetrics[]>;
}

export const DevStatsColumnConfigModal = (props: DevStatsColumnConfigModalProps) => {
	const {
		authStore: { authUser: { dataContributorDetails: { id: dcId } } },
	} = useStores();

	const {
		setIsOpen: setOpen,
		isOpen,
		selectedMetrics,
		updateSelectedMetrics,
		metricNamesByDisplayCategories,
	} = props;

	const [modalSelectedMetrics, setModalSelectedMetrics] = useState<WorkforceHealthMetrics[]>([]);
	const [isSavingInProgress, setIsSavingInProgress] = useState<boolean>(false);

	useEffect(() => {
		if (isOpen) {
			return;
		}

		setModalSelectedMetrics(selectedMetrics);
	}, [selectedMetrics, isOpen]);

	const metricDashboardNameToDisplayTitle =
		devStatColumns.reduce((inner, metric) => {
			return {
				...inner,
				[metric.dashboardMetricName]: metric.displayValues.title
			};
	}, {}) as Record<WorkforceHealthMetrics, string>;

	const generateMetricCell = (categoryName: string | null, metricNames: WorkforceHealthMetrics[]) => {
		return (!categoryName) ? (<Grid.Column/>)
		: (
			<Grid.Column>
				<HeaderContent className="category-title">{_.upperCase(categoryName)}</HeaderContent>
				<div>
				{metricNames.map((metricName: WorkforceHealthMetrics) => {
				return (
					<div key={metricName} className="metric-checkbox">
						<Checkbox
							name={metricName}
							label={metricDashboardNameToDisplayTitle[metricName]}
							onChange={() => (modalSelectedMetrics.includes(metricName))
								? setModalSelectedMetrics(modalSelectedMetrics.filter(x => x !== metricName))
								: setModalSelectedMetrics([...modalSelectedMetrics, metricName])
								}
							checked={modalSelectedMetrics.includes(metricName)}
							disabled={isSavingInProgress
								|| (modalSelectedMetrics.length === 1 && modalSelectedMetrics.includes(metricName))}
						/>
					</div>
				);
				})}
				</div>
			</Grid.Column>);
	};

	return (
		<Modal open={isOpen}
			onOpen={() => setModalSelectedMetrics(selectedMetrics!)}
			className="dev-stats-column-config-modal"
			onClose={() => setOpen(false)}
		>
			<Modal.Header>Select columns</Modal.Header>
			<Modal.Content>
				<Modal.Description>
				<Grid centered={true} columns={2} stretched={true} verticalAlign="top">
						{_.chunk(Object.entries(metricNamesByDisplayCategories), 2)
							.map((input, ind) => {
								const [leftColumnCategoryName, leftColumnMetricNames] = input[0];
								const [rightColumnCategoryName, rightColumnMetricNames] = (input.length > 1) ? input[1] : [null, []];

								return (
									<Grid.Row key={ind}>
										{generateMetricCell(leftColumnCategoryName, leftColumnMetricNames)}
										{generateMetricCell(rightColumnCategoryName, rightColumnMetricNames)}
									</Grid.Row>
								);
						})}
				</Grid>
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button
					disabled = {isSavingInProgress}
					color="grey"
					onClick={() => {
						if (isSavingInProgress) {
							return;
						}

						setOpen(false);
				}}>
					Cancel
				</Button>
				<Button
					content="Save"
					labelPosition="right"
					icon="checkmark"
					primary={true}
					className="ui primary button"
					loading={isSavingInProgress}
					onClick={async () => {
						if (isSavingInProgress) {
							return;
						}

						setIsSavingInProgress(true);
						if (!_.isEqual((selectedMetrics).sort(), modalSelectedMetrics.sort())) {
							await updateSelectedMetrics(dcId, modalSelectedMetrics);
						}

						setOpen(false);
						setIsSavingInProgress(false);
					}}
				/>
			</Modal.Actions>
		</Modal>
	);
};
