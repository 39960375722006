import React, { useCallback, useMemo } from "react";
import { Avatar } from "../../avatar";
import { capitalizeName } from "../../../../utils/string";
import { UserOption } from "./types";

interface UserAvatarItemProps {
	option: UserOption;
	isSelected: boolean;
	disabled?: boolean;
	onToggle: (id: string) => void;
}

const UserAvatarItem = (props: UserAvatarItemProps) => {
	const { option, isSelected, disabled, onToggle } = props;
	const id = option.id;
	const displayName = option.displayName;
	const capitalizedDisplayName = useMemo(() => displayName && capitalizeName(displayName), [displayName]);
	const className = "avatar-icon-btn " + (isSelected ? "selected" : "");
	const handleToggle = useCallback(() => !disabled && onToggle(id), [id, onToggle]);

	return (
		<React.Fragment>
			{option.displayName ? (
				<div className={className} onClick={handleToggle}>
					<Avatar user={option} className="mini clickable"/>
					<div className="avatar-user-name">
						{capitalizedDisplayName}
					</div>
				</div>
			) : (
				<Avatar user={option} className="mini"/>
			)}
		</React.Fragment>
	);
};

export default UserAvatarItem;
