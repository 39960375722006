import React from "react";
import ExceededBarChart from "./../../components/graphs/bar-chart";
import Donut from "./../../components/graphs/hc-donut";
import PieChart from "./../../components/graphs/pie-chart";

interface IGraphs {
	number?: number;
}

// tslint:disable-next-line: variable-name
const Graphs = (props: IGraphs) => {
	return (
		<div>
			<h1 className="ui title">Charts</h1>
			<div className="ui card">
				<div className="ui content">
					<ExceededBarChart />
				</div>
			</div>
			<div className="ui card">
				<div className="ui content">
					<ExceededBarChart
						percents={25}
						animate={false}
					/>
				</div>
			</div>
			<div className="ui card">
				<div className="ui content">
					<ExceededBarChart percents={50} />
				</div>
			</div>
			<div className="ui card">
				<div className="ui content">
					<ExceededBarChart
						percents={66.6}
						animate={true}
					/>
				</div>
			</div>

			<div className="ui card">
				<div className="ui content">
					<ExceededBarChart
						percents={75}
						tooltip={"This is a temp tooltip"}
						animate={false}
					/>
				</div>
			</div>

			<div className="ui card">
				<div className="ui content">
					<ExceededBarChart
						percents={100}
						animate={true}
						tooltip={"This is a temp tooltip"}
					/>
				</div>
			</div>

			<div className="ui card">
				<div className="ui content">
					<ExceededBarChart
						percents={110}
						tooltip={"No NO NO!"}
					/>
				</div>
			</div>

			<div className="ui card">
				<div className="ui content">
					<ExceededBarChart
						percents={111}
						tooltip={"No NO NO!"}
					/>
				</div>
			</div>

			<div className="ui card">
				<div className="ui content">
					<ExceededBarChart
						percents={150}
						tooltip={"This is a temp tooltip"}
					/>
				</div>
			</div>

			<div className="ui card">
				<div className="ui content">
					<PieChart />
				</div>
			</div>

			<div className="ui card">
				<div className="ui content">
					<PieChart percentileRank={0.25} color={"orange"} width={100} />
				</div>
			</div>

			<div className="ui card">
				<div className="ui content">
					<Donut chartData={donutData}/>
				</div>
			</div>

			<PieChart percentileRank={0.5} animate={true} color={"green"} width={32} />
			<PieChart percentileRank={0.5} color={"blue"} width={32} />
			<PieChart percentileRank={0.66} animate={false} />
			<PieChart percentileRank={0.75} animate={true} width={300} />

		</div>
	);
};
export default Graphs;

const donutData = {
	name: "Browsers",
	data: [["Firefox", 25], ["Chrome", 35], ["trees", 40]],
	sizePx: 340,
	colors: ["blue", "pink", "green"]
};
