import { AcumenTaskStatus, AcumenTaskStatusGroup, IDashboardStatus } from "@acumen/dashboard-common";
import React from "react";
import { Popup } from "semantic-ui-react";
import Backlog from "../svg-assets/backlog.svg";
import Closed from "../svg-assets/closed.svg";
import Discarded from "../svg-assets/discarded.svg";
import InProgress from "../svg-assets/in-progress.svg";
import SelectedForDevelopment from "../svg-assets/selected-for-dev.svg";
import Blocked from "../svg-assets/blocked.svg";
import ToTest from "../svg-assets/to-test.svg";
import classNames from "classnames";
import {
	AcumenStatusToClassName
} from "../../pages/external/go-retro-sprint-review/cycle-time-breakdown/acumen-status-to-class-name";

interface ITaskStatusProps {
	taskStatus: AcumenTaskStatus | null;
	internalDashboardStatus?: IDashboardStatus | null | undefined;
	internalStatus?: string | null;
	hasTooltip?: boolean;
	showIcon?: boolean;
	isRemoved?: boolean;
}

// tslint:disable-next-line: variable-name
const TaskStatus = (props: ITaskStatusProps) => {
	const { taskStatus, internalStatus, hasTooltip = false, internalDashboardStatus, showIcon = true, isRemoved = false } = { ...props };

	const statsLabel = () => (
		<div className={classNames("status-color", taskStatusIconsColor(internalDashboardStatus?.acumenStatus ?? taskStatus), "icon-label")} >
			{showIcon && <img className="status-icon"
				src={(internalDashboardStatus === null || internalDashboardStatus === undefined || (internalDashboardStatus?.iconUrl ?? "").endsWith("/")) ?
					taskStatusIcons(taskStatus, "") : internalDashboardStatus!.iconUrl!}
				alt=""
			/>}
			<span className="ellipsis">{internalDashboardStatus?.customerStatusName ?? taskStatusText(taskStatus, internalStatus)}</span>
		</div>
	);

	return (
		isRemoved
			? <span className="status-color status-removed">Removed</span>
			: hasTooltip ?
				(
					<Popup
						size="small"
						basic={true}
						content={internalDashboardStatus?.customerStatusName ?? taskStatusText(taskStatus, internalStatus)}
						trigger={statsLabel()}
					/>
				) :
				statsLabel()
	);
};
export default TaskStatus;

const DEFAULT_MISSING_STATUS_VALUE = "N/A";

export const taskStatusIcons = (
	taskStatus: AcumenTaskStatus | null,
	defaultValue = DEFAULT_MISSING_STATUS_VALUE
) => {
	if (!taskStatus) {
		return defaultValue;
	}

	switch (taskStatus) {
		case AcumenTaskStatus.Testing:
		case AcumenTaskStatus["To Test"]:
			return ToTest;
		case AcumenTaskStatus.Closed:
		case AcumenTaskStatus.Done:
		case AcumenTaskStatus["Client Review"]:
			return Closed;
		case AcumenTaskStatus["Selected for Development"]:
			return SelectedForDevelopment;
		case AcumenTaskStatus.Blocked:
			return Blocked;
		case AcumenTaskStatus.Discarded:
			return Discarded;
		case AcumenTaskStatus.Backlog:
			return Backlog;
		case AcumenTaskStatus["In Progress"]:
		case AcumenTaskStatus["Code Review"]:
			return InProgress;
		default:
			return defaultValue;
	}
};

export const taskStatusIconsColor = (
	taskStatus: AcumenTaskStatus | AcumenTaskStatusGroup | null,
) => {

	if (taskStatus && AcumenStatusToClassName[taskStatus]) {
		return AcumenStatusToClassName[taskStatus];
	}

	return "status-default";
};

export const taskStatusText = (
	taskStatus: AcumenTaskStatus | null,
	internalStatus: string | null | undefined,
	defaultValue = DEFAULT_MISSING_STATUS_VALUE
) => {
	if (internalStatus) {
		return internalStatus;
	}
	switch (taskStatus) {
		case AcumenTaskStatus.Closed:
			return "closed";
		case AcumenTaskStatus.Done:
			return "done";
		case AcumenTaskStatus["Client Review"]:
			return "client review";
		case AcumenTaskStatus.Blocked:
			return "blocked";
		case AcumenTaskStatus.Testing:
			return "testing";
		case AcumenTaskStatus["To Test"]:
			return "to test";
		case AcumenTaskStatus.Discarded:
			return "discarded";
		case AcumenTaskStatus.Backlog:
			return "backlog";
		case AcumenTaskStatus["Selected for Development"]:
			return "selected for dev.";
		case AcumenTaskStatus["Code Review"]:
			return "code review";
		case AcumenTaskStatus["In Progress"]:
			return "in progress";
		default:
			return defaultValue;
	}
};
