import BaseStore, { IBaseStore, ILoadable } from "./base-store";
import { action, observable } from "mobx";
import apiContextProvider from "../../services/api-context-provider";
import { PullRequestsApiClient, PULL_REQUESTS_ROUTE } from "../services/crud/pull-requests-api-client";
import {
	IDashboardDetailedPullRequestResponse, DashboardPullRequestSortOption, DashboardSortOrder, AcumenPullRequestStatus,
	DashboardPullRequestStaticGroupingOption, DashboardPullRequestDynamicGroupingOption, DashboardPullRequestExpandOption,
	IDashboardEntityWorkIntervals, IDashboardEvent, DashboardPullRequestWorkStatusGrouping,
	IPullRequestHighlight, IDashboardTask, IDashboardPullRequestEntity, IDashboardPullRequestDetails
} from "@acumen/dashboard-common";
import _ from "lodash";
import { FetchLatestRequest } from "../../services/fetch-helpers";

export interface IPullRequestsState {
	allPullRequests: ILoadable<IDashboardDetailedPullRequestResponse>;
	workIntervals: ILoadable<IDashboardEntityWorkIntervals>;
	dismissHighlights: ILoadable<void>;
}

interface IFindPullRequestsOptions {
	sortField?: DashboardPullRequestSortOption;
	sortOrder?: DashboardSortOrder;
	filter: {
		teamId: string;
		dataContributorIds?: string[];
		gitRepositories?: string[];
		statuses?: AcumenPullRequestStatus[];
		startTime?: Date;
		endTime?: Date;
		excludeDraft?: boolean;
		prIds?: string[];
	};
	staticGroupingLimit?: number;
	staticGrouping?: DashboardPullRequestStaticGroupingOption;
	dynamicGroupingLimit?: number;
	dynamicGrouping?: DashboardPullRequestDynamicGroupingOption;
	expand?: DashboardPullRequestExpandOption[];
}

export interface IPullRequestsStore extends IPullRequestsState, IBaseStore<IPullRequestsState> {
	fetchAllPullRequests: (options: IFindPullRequestsOptions) => Promise<IDashboardDetailedPullRequestResponse>;
	resetAllPullRequests: () => void;
	fetchWorkIntervals: (pr: IDashboardPullRequestEntity) => Promise<IDashboardEntityWorkIntervals>;
	resetWorkIntervals: () => void;
	dismissPRHighlights: (prs: IDashboardPullRequestEntity) => Promise<boolean>;
	resetDismissPRHighlights: () => void;
}

const defaults = {
	allPullRequests: BaseStore.initLoadable({}),
	workIntervals: BaseStore.initLoadable({}),
	dismissHighlights: BaseStore.initLoadable(undefined),
};

type AcumenKnownPullRequestStatus = Exclude<AcumenPullRequestStatus, AcumenPullRequestStatus.Unknown>;
type DashboardPullRequestWorkStatusGroupingFilterOptions = Exclude<DashboardPullRequestWorkStatusGrouping, DashboardPullRequestWorkStatusGrouping.Reviewed | DashboardPullRequestWorkStatusGrouping.InReviewProcess>;

const STATUS_GROUPING_TO_VALUE: Record<DashboardPullRequestWorkStatusGroupingFilterOptions, string> = {
	[DashboardPullRequestWorkStatusGrouping.Open]: "Haven’t Started",
	[DashboardPullRequestWorkStatusGrouping.InReview]: "In the Works",
	[DashboardPullRequestWorkStatusGrouping.Done]: "Done"
};

const MAP_SECTION_TO_PR_STATUS: Record<DashboardPullRequestWorkStatusGroupingFilterOptions, AcumenKnownPullRequestStatus[]> = {
	[DashboardPullRequestWorkStatusGrouping.Open]: [
		AcumenPullRequestStatus.Open
	],
	[DashboardPullRequestWorkStatusGrouping.InReview]: [
		AcumenPullRequestStatus.AwaitingReview,
		AcumenPullRequestStatus.InReview,
		AcumenPullRequestStatus.Reviewed
	],
	[DashboardPullRequestWorkStatusGrouping.Done]: [
		AcumenPullRequestStatus.Closed,
		AcumenPullRequestStatus.Merged
	]
};

const MAP_PR_STATUS_TO_VALUE: Record<AcumenKnownPullRequestStatus, string> = {
	[AcumenPullRequestStatus.AwaitingReview]: "Awaiting Review",
	[AcumenPullRequestStatus.Closed]: "Closed",
	[AcumenPullRequestStatus.InReview]: "In Review",
	[AcumenPullRequestStatus.Merged]: "Merged",
	[AcumenPullRequestStatus.Open]: "Open",
	[AcumenPullRequestStatus.Reviewed]: "Pending Merge",
};

export default class PullRequestsStore extends BaseStore<IPullRequestsState> implements IPullRequestsStore {
	private readonly apiClient: PullRequestsApiClient = new PullRequestsApiClient(apiContextProvider);

	@observable
	public allPullRequests: ILoadable<IDashboardDetailedPullRequestResponse> = defaults.allPullRequests;

	@observable
	public workIntervals: ILoadable<IDashboardEntityWorkIntervals> = defaults.workIntervals;

	@observable
	public dismissHighlights: ILoadable<void> = defaults.dismissHighlights;

	@observable pullRequestEvents: IDashboardEvent[] = [];

	fetchLatestPullRequestsEvents = new FetchLatestRequest<IDashboardEvent[], any>(PULL_REQUESTS_ROUTE + "/Events");
	@action.bound
	public async fetchPullRequestsEvents(pr: IDashboardPullRequestEntity) {
		this.pullRequestEvents = [];
		const res = await this.fetchLatestPullRequestsEvents.fetchLatest(this.apiClient.fetchPREvents(pr));
		this.pullRequestEvents = res?.data.sort((a: IDashboardEvent, b: IDashboardEvent) => {
			return a.eventTimeMs < b.eventTimeMs ? 1 : -1;
		}) ?? [];
		return this.pullRequestEvents;
	}

	fetchLatestAllPullRequests = new FetchLatestRequest<IDashboardDetailedPullRequestResponse, void>(PULL_REQUESTS_ROUTE);
	@action.bound
	public async fetchAllPullRequests(options: IFindPullRequestsOptions): Promise<IDashboardDetailedPullRequestResponse> {
		this.allPullRequests.loading = true;
		const filter = options.filter;
		const result = await this.fetchLatestAllPullRequests.fetchLatest(this.apiClient.fetchPullRequests(filter.teamId, filter.dataContributorIds,
			filter.gitRepositories, filter.statuses, filter.excludeDraft, filter.prIds, options.staticGroupingLimit, options.staticGrouping,
			options.dynamicGroupingLimit, options.dynamicGrouping, options.filter.startTime, options.filter.endTime,
			options.sortField, options.sortOrder, options.expand));

		if (result) {
			const { data } = result;

			this.allPullRequests = {
				data,
				metadata: null,
				loaded: true,
				loading: false
			};
			return data as IDashboardDetailedPullRequestResponse;
		}

		return {
			pullRequests: {},
			total: 0
		};
	}

	fetchLatestPullRequestsWorkIntervals = new FetchLatestRequest<IDashboardEntityWorkIntervals, any>(PULL_REQUESTS_ROUTE + "/WorkInterval");
	@action.bound
	public async fetchWorkIntervals(pr: IDashboardPullRequestEntity): Promise<IDashboardEntityWorkIntervals> {
		this.workIntervals.loading = true;

		const result = await this.fetchLatestPullRequestsWorkIntervals.fetchLatest(this.apiClient.fetchWorkIntervals(pr));
		if (result) {
			const { data } = result;
			const intervals = data as IDashboardEntityWorkIntervals;
			this.workIntervals = {
				data: intervals,
				metadata: [],
				loaded: true,
				loading: false
			};
			return intervals;
		}

		return {
			entityId: pr.entityId,
			entityType: pr.entityType,
			totalWorkIntervalMs: 0,
			workIntervalsByDataContributor: []
		};
	}

	fetchLatestPullRequestsHighlights = new FetchLatestRequest<{ highlights: IPullRequestHighlight[] }, any>(PULL_REQUESTS_ROUTE + "/Highlights");
	@action.bound
	public async getPRHighlights(pr: IDashboardPullRequestEntity): Promise<IPullRequestHighlight[] | undefined> {
		const result = await this.fetchLatestPullRequestsHighlights.fetchLatest(this.apiClient.fetchPRHighlights(pr));
		return result?.data!.highlights;
	}

	fetchLatestPRRelatedTasks = new FetchLatestRequest<IDashboardTask[], any>(PULL_REQUESTS_ROUTE+ "/RelatedTasks");
	@action.bound
	public async getPRRelatedTasks(pr: IDashboardPullRequestEntity): Promise<IDashboardTask[] | undefined> {
		const result = await this.fetchLatestPRRelatedTasks.fetchLatest(this.apiClient.fetchRelatedTasks(pr));
		return result?.data!;
	}

	fetchLatestPRDetails = new FetchLatestRequest<IDashboardPullRequestDetails, any>(PULL_REQUESTS_ROUTE + "/Details");
	@action.bound
	public async getPRDetails(pr: IDashboardPullRequestEntity): Promise<IDashboardPullRequestDetails | undefined> {
		const result = await this.fetchLatestPRDetails.fetchLatest(this.apiClient.fetchPRDetails(pr));
		return result?.data!;
	}

	@action.bound
	public async dismissPRHighlights(pr: IDashboardPullRequestEntity): Promise<boolean> {
		this.dismissHighlights.loading = true;
		const result = await this.apiClient.dismissPRHighlights(pr).then(res => {
			return res;
		}).finally(() => {
			this.dismissHighlights.loading = false;
		});
		return result !== null ? true : false;
	}

	@action.bound
	public resetWorkIntervals() {
		this.workIntervals = defaults.workIntervals;
	}

	@action.bound
	public resetAllPullRequests() {
		this.allPullRequests = defaults.allPullRequests;
	}
	@action.bound
	public resetDismissPRHighlights() {
		this.dismissHighlights = defaults.dismissHighlights;
	}

	public prStatusesOptions = Object.entries(STATUS_GROUPING_TO_VALUE).map(([sgType, sgLabel]) => ({
		label: sgLabel,
		options:
			_.sortBy(MAP_SECTION_TO_PR_STATUS[sgType as DashboardPullRequestWorkStatusGroupingFilterOptions], s => s.toString())
				.map(v => {
					return {
						key: v,
						value: v,
						label: MAP_PR_STATUS_TO_VALUE[v]
					};
				})
	}));
}
