import { useStores } from "../../../mobx-stores";
import React, { useEffect, useState } from "react";
import { Link, Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import Loader from "../../../components/loader";
import IntegrationFlow from "./integration-flow";
import SetupIntegration from "./integration-flow/setup-integration";
import NothingFoundIcon from "../../../components/svg-assets/no-content-icons/empty_state_icon_5.svg";
import { observer } from "mobx-react";
import moment from "moment";
import { integrationTypesInfo } from "../../../mobx-stores/new-integrations-store";
import { dateFormatByLocale, DateFormat } from "@acumen/common";
import { Header, Image, Pagination } from "semantic-ui-react";
import { Avatar } from "../../../components/avatar";
import { Status } from "@acumen/dashboard-common";
import { IntegrationStatus } from "@acumen/database-types";
import classNames from "classnames";
import _ from "lodash";

const ROWS_PER_PAGE = 15;
// tslint:disable-next-line: variable-name
const AllIntegrations = () => {
	const {
		authStore,
		integrationsStore: {
			sortedIntegrationByCreation,
			fetchAllIntegrations
		},
	} = useStores();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [editIntegrationId, setEditIntegrationId] = useState<string | undefined>(undefined);
	const [page, setPage] = useState(1);
	const history = useHistory();

	const match = useRouteMatch();

	const DATE_TIME_FORMAT = dateFormatByLocale(DateFormat.LongWithTime, authStore.authUser.locale);

	useEffect(() => {
		if (!isLoading) {
			setIsLoading(true);
			// tslint:disable-next-line: no-floating-promises
			fetchAllIntegrations().then(() => {
				setIsLoading(false);
			});
		}
	}, []);

	const now = moment();
	return (
		<Loader isActive={isLoading}>
			<Switch>
				<Route exact={true} path={`${match?.url}/add/setup-slack:code?:state?:installation_id?:setup_action?`}>
					<SetupIntegration />
				</Route>
				<Route exact={true} path={`${match?.url}/add/setup-github/:installation_id?`}>
					<SetupIntegration />
				</Route>
				<Route exact={true} path={`${match?.url}/add`}>
					<IntegrationFlow
						editIntegrationId={editIntegrationId}
						onClose={() => {
							// tslint:disable-next-line: no-floating-promises
							fetchAllIntegrations();
							setEditIntegrationId(undefined);
						}}
					/>
				</Route>
			</Switch>
			<Link to={`${match?.url}/add`}>
				<button className="ui button">Add integration</button>
			</Link>
			<div className="ui segment raised all-integrations" style={{ minHeight: "75vh" }}>
				{sortedIntegrationByCreation ? (
					<table className="ui table single line small fixed">
						<thead>
							<tr>
								<th className="three wide" >Type</th>
								<th className="three wide">Creator</th>
								<th className="left aligned">Created</th>
								<th className="one wide left aligned">State</th>
								<th className="half wide" />
							</tr>
						</thead>
						<tbody>
							{sortedIntegrationByCreation?.slice((page - 1) * ROWS_PER_PAGE, page * ROWS_PER_PAGE).map((int, i) => (
								<tr key={i} onClick={(e) => {
									if (int.state === IntegrationStatus.CREATED&& int.status === Status.Enabled) {
										e.stopPropagation();
										setEditIntegrationId(int.id);
										history.push(`${match?.url}/add`);
									}
								}}
									className={int.state === IntegrationStatus.CREATED && int.status === Status.Enabled ? "clickable" : ""}
								>
									<td >
										<Header as="h4" className="no-wrap hidden-overflow"  image={true}>
											<Image src={integrationTypesInfo[int.type]!.logoSrc} rounded={true} size="mini" />
											<Header.Content style={{ whiteSpace: "nowrap"}}>
												{integrationTypesInfo[int.type]!.displayName}
												<Header.Subheader>{integrationTypesInfo[int.type]!.group}</Header.Subheader>
											</Header.Content>
										</Header>
									</td>
									<td>
										<div className="creator-cell">
											<Header as="h4" className="no-wrap hidden-overflow" image={true}>
												<Avatar
													key={i}
													user={{
														firstName: int.creatorUserFirstName,
														surname: int.creatorUserSurname,
														email: int.creatorUserEmail,
														avatarUrl: int.creatorUserPictureUrl,
													}}
													className="mini in-table"
												/>
												<Header.Content>
													{_.compact([int.creatorUserFirstName, int.creatorUserSurname]).map(_.upperFirst).join(" ")}
													<Header.Subheader>{int.creatorUserEmail}</Header.Subheader>
												</Header.Content>
											</Header>
										</div>
									</td>
									<td className="dark-blue-text">{moment(int?.createdUtcMs).format(DATE_TIME_FORMAT)}</td>
									<td className="left aligned">
										<div className={classNames("ui small basic label ", {
											primary: int.state === IntegrationStatus.ACTIVE,
											red: int.state === IntegrationStatus.DESTROYED || int.state === IntegrationStatus.INACTIVE,
											blue: int.state === IntegrationStatus.CREATED
										})}>
											{moment.duration(now.diff(moment(int?.createdUtcMs))).asMinutes() <= 5 &&
												<i className="sparkles alternate yellow icon" />
											}
											{int.state}
										</div>
									</td>
									<td />
								</tr>
							))}
						</tbody>
						{sortedIntegrationByCreation.length > ROWS_PER_PAGE && (
							<tfoot>
								<tr>
									<td colSpan={4}>
										<div className="ui basic center aligned segment">
											<Pagination
												boundaryRange={0}
												defaultActivePage={page}
												ellipsisItem={null}
												firstItem={null}
												lastItem={null}
												siblingRange={1}
												totalPages={Math.ceil(sortedIntegrationByCreation.length / ROWS_PER_PAGE)}
												onPageChange={(_e, { activePage }) => { setPage(activePage as number); }}
											/>
										</div>
									</td>
								</tr>
							</tfoot>
						)}
					</table>
				) :
					<span className="img-container" style={{ margin: "auto", paddingTop: "10rem" }}>
						<img className="status-icon" src={NothingFoundIcon} alt="" />
						<p className="gray-font" style={{ paddingRight: "10px" }}>{"No Integrations"}</p>
					</span>
				}
			</div>
		</Loader >
	);
};
export default observer(AllIntegrations);
