import React, { useState } from "react";
import { TabModel, Tabs } from "../../../../components/tabs";
import { BadgeBreakdown } from "../../../../components/badge-breakdown/badge-breakdown";
import "./team-performance-breakdown.scss";
import { TeamViewModel } from "../../../team-comparison/types";
import { BadgeOwner } from "../../../../types/badges";

export interface TeamPerformanceBreakdownProps {
	team: TeamViewModel;
}

const BADGE_BREAKDOWN_TAB = "badge-breakdown";

// tslint:disable-next-line:variable-name
export const TeamPerformanceBreakdown = (props: TeamPerformanceBreakdownProps) => {
	const { team } = props;
	const [activeTabName, setActiveTabName] = useState(BADGE_BREAKDOWN_TAB);

	return (
		<div className="team-performance-breakdown">
			<Tabs
				tabs={tabs}
				activeTabName={activeTabName}
				onActiveTabChange={setActiveTabName}
			/>

			{activeTabName === BADGE_BREAKDOWN_TAB && (
				<BadgeBreakdown
					badges={team.badges.metricBadges}
					badgeOwner={BadgeOwner.Team}
				/>
			)}
		</div>
	);
};

const tabs: TabModel[] = [
	{ name: BADGE_BREAKDOWN_TAB, title: "Badge Breakdown" }
];
