import React, { useEffect, useLayoutEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "./mobx-stores";
import { useAuth0 } from "@auth0/auth0-react";
import { Redirect, Route, Switch } from "react-router-dom";
import BasicLayout from "./layout";
import MyAccount from "./pages/my-account";
import Loader from "./components/loader/loader";
import { ToastContainer } from "react-toastify";
import useAnalytics from "../hooks/analytics";
import { routesWithAllowedParams } from "./config";
import config from "../utils/config";
import "./components/style.scss";
import { useIntercom } from "react-use-intercom";
import { Meta } from "./components/Meta";
import { ROUTES } from "./routing";
import OrgMetricsPage from "./pages/org-metrics";
import { WorkforceHealth } from "./pages/workforce-health";
import { TeamMetrics } from "./pages/team-comparison";
import MetricTester from "./pages/metric-tester";
import BadgeConfig from "./pages/badge-config";
import PRBreakdown from "./pages/org-metrics/pr-breakdown";

// tslint:disable-next-line: variable-name
const App = observer(() => {
	const [hasLoadedAssets, setHasLoadedAssets] = useState<boolean>(false);
	const { update } = useIntercom();
	const {
		getAccessTokenSilently,
		isAuthenticated,
		loginWithRedirect,
		isLoading,
		error,
		logout
	} = useAuth0();
	const {
		authStore,
		routingStore,
		featureFlagsStore,
		fetcherStore,
		connectedAppsStore: {
			refreshIntegrationState
		},
	} = useStores();

	if (process.env.NODE_ENV !== "development") {
		useAnalytics(authStore.authUser);
	}

	const allowedRoute = routesWithAllowedParams.find(r => window.location.pathname.match(r.route));
	let returnTo = window.location.pathname;
	const params = window.location.search;
	if (allowedRoute) {
		returnTo += params;
	}

	async function auth() {
		const cachedToken = authStore.getCachedJWT();
		if (cachedToken) {
			return authStore.handleAuthUserResponse(cachedToken).then(refreshIntegrationState);
		}

		if (!isLoading) {
			if (isAuthenticated) {
				const accessToken = await getAccessTokenSilently({});
				return authStore.handleAuthUserResponse(accessToken).then(refreshIntegrationState);
			} else {
				return loginWithRedirect({
					appState: { returnTo },
				});
			}
		}
	}

	useEffect(() => {
		update({
			name: authStore.authUser.dataContributorDetails.primaryDisplayName ?? undefined,
			email: authStore.authUser.email ?? undefined,
			userId: authStore.authUser.id ?? undefined,
			userHash: authStore.authUser.intercomHash,
			createdAt: authStore.authUser.createdAt.toString() ?? undefined,
			company: {
				name: authStore.authUser.companyName,
				companyId: authStore.authUser.customerId
			},
			avatar: {
				type: "avatar",
				imageUrl: authStore.authUser.dataContributorDetails.primaryAvatarUrl ?? undefined
			},
			customAttributes: {
				acumenRoles: authStore.authUser.roles.join(","),
				teamId: authStore.authUser.teamMemberships.map(t => t.teamId).join(","),
			}
		});
	}, [routingStore.location?.pathname, authStore.authUser]);

	useEffect(() => {
		// tslint:disable-next-line: no-floating-promises
		auth();
	}, [isLoading, isAuthenticated, authStore, error]);

	async function onTokenError() {
		const {
			auth0: { logoutReturnTo },
		} = config();
		await authStore.handleLogout(opts => logout(opts), logoutReturnTo);
	}

	useEffect(() => {
		if (fetcherStore.hasTokenError) {
			// tslint:disable-next-line: no-floating-promises
			onTokenError();
		}
	}, [fetcherStore.hasTokenError]);

	function defaultRoute(): string {
		return ROUTES.WORKFORCE_HEALTH;
	}

	useLayoutEffect(() => {
		const styleSrc = (process.env.NODE_ENV === "production") ? `/assets/style/v2/semantic.min.css` : `/assets/style/v2/semantic.css`;
		const existingStyleTag = document.querySelector(
			`link[href="${styleSrc}"]`
		);
		if (existingStyleTag) {
			return;
		}
		const styleTag = document.createElement("link");
		styleTag.type = "text/css";
		styleTag.rel = "stylesheet";
		styleTag.onload = () => { setHasLoadedAssets(true); };
		styleTag.href = styleSrc;
		document.head.appendChild(styleTag);
	}, []);

	// NOTE: redirects below are for backwards-compatibility with old links generated by our system
	return (
		<>
			<Meta />
			<Loader isActive={authStore.authUser.customerId === "" || !hasLoadedAssets || !featureFlagsStore.isInitialized}>
				<div className="fixed-toaster-container">
					<ToastContainer
						autoClose={false}
					/>
				</div>
				{hasLoadedAssets && (
					<BasicLayout>
						<div className="ui basic segment">
							<Switch>
								<Route path="/my-account" component={MyAccount} />
								<Route path={ROUTES.ORG_METRICS} component={OrgMetricsPage} />
								<Route path={ROUTES.WORKFORCE_HEALTH} component={WorkforceHealth} />
								<Route path={ROUTES.TEAM_METRICS} component={TeamMetrics} />
								{authStore.isUserAdmin && <Route path="/metric-tester" exact={true}><MetricTester /></Route>}
								{authStore.isUserAdmin && <Route path="/badge-config" exact={true}><BadgeConfig /></Route>}
								<Route path={ROUTES.PR_BREAKDOWN} exact={true}><PRBreakdown /></Route>
								<Route>
									<Redirect to={defaultRoute()} />
								</Route>
							</Switch>
						</div>
					</BasicLayout>
				)}
			</Loader>
		</>
	);
});
export default App;
