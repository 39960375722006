import * as React from "react";
const Cat = (props: React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fill="#F4AA41"
			d="M58.628 48.844c-3.595 9.11-12.621 15.143-23.19 15.143-9.11 0-19.24-5.843-22.835-14.953l-.127-.313c-1.14-2.857-.955-6.355-.955-9.625l3.664-32.345 10.22 10.07a24.145 24.145 0 0 1 9.502-1.936h1.063c3.37 0 6.583.69 9.503 1.937L56.693 5.75l3.72 33.85c0 3.27-.629 6.392-1.77 9.25"
		/>
		<path
			fill="#E27022"
			d="M35.439 63.987c10.6.342 20.338-6 23.933-15.11l.111-.23c1.14-2.858 1.956-6.892 1.956-10.162L56.693 5.75"
		/>
		<path
			fill="#D22F27"
			d="M23.882 31.304c-1.364-3.616-6.944-3.848-6.594 1.42.046.7.303 1.372.691 1.955l5.733 6.924 5.63-6.755c.624-.75.994-1.701.975-2.68-.093-4.691-5.445-4.35-6.606-.866"
		/>
		<path
			fill="#EA5A47"
			d="M25.386 48.639s7.794 4.156 10.392-3.118c2.598 7.274 10.797 3.068 10.797 3.068s-2.692 4.377-3.485 5.23c-4.014 4.31-9.394 4.05-13.661.116-.935-.861-4.043-5.296-4.043-5.296z"
		/>
		<path
			fill="#D22F27"
			d="M48.604 31.304c-1.364-3.616-6.943-3.848-6.594 1.42.047.7.303 1.372.692 1.955l5.733 6.924 5.629-6.755c.624-.75.994-1.701.975-2.68-.093-4.691-5.445-4.35-6.606-.866"
		/>
		<g
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={2}
		>
			<path d="m62.019 54.059-9.47-4.981M57.803 59.363l-9.352-5.196M13.29 48.043a23.828 23.828 0 0 1-1.731-8.945l3.637-32.735 10.97 10.707a23.849 23.849 0 0 1 9.294-1.874h1.04c3.296 0 6.437.668 9.294 1.874l10.97-10.707 3.637 32.735c0 3.162-.614 6.182-1.73 8.945M22.816 59.385A23.786 23.786 0 0 0 35.46 63H36.5c4.643 0 8.977-1.323 12.645-3.615M10 54.167l9.353-5.196M14.157 59.363l9.353-5.196" />
			<path d="M23.882 31.397c-1.364-3.616-6.944-3.847-6.594 1.42.046.7.303 1.372.691 1.956l5.733 6.923h0l5.63-6.755c.624-.75.994-1.701.975-2.68-.093-4.691-5.445-4.35-6.606-.866M48.604 31.397c-1.364-3.616-6.943-3.847-6.594 1.42.047.7.303 1.372.692 1.956l5.733 6.923h0l5.629-6.755c.624-.75.994-1.701.975-2.68-.093-4.691-5.445-4.35-6.606-.866M31.637 45.466h8.321M25.398 48.586s7.8 4.16 10.4-3.12c2.6 7.28 10.804 3.07 10.804 3.07s-2.693 4.381-3.487 5.234c-4.017 4.313-9.4 4.053-13.672.116-.935-.862-4.046-5.3-4.046-5.3z" />
		</g>
	</svg>
);
export default Cat;
