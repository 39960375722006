import React from "react";
import { DevStatColumn, devStatColumns, WHF_METRIC_NAMES_BY_CATEGORIES } from "../../dev-stat-columns";
import { DeveloperMetricsTabTableRow } from "../developer-metrics-tab-table-row/developer-metrics-tab-table-row";
import { DeveloperViewModel } from "../../types";
import { MetricData } from "../../../team-comparison/types";
import { WorkforceHealthMetrics } from "@acumen/database-types";
import "./developer-metrics-tab-table.scss";

export interface DeveloperMetricsTabTableProps {
	developer: DeveloperViewModel;
	metricData: MetricData;
}

const CATEGORIES = Object.entries(WHF_METRIC_NAMES_BY_CATEGORIES) as Array<[string, WorkforceHealthMetrics[]]>;
const CATEGORY_COLUMNS = CATEGORIES.map<[string, DevStatColumn[]]>(([category, metricNames]) => [
	category,
	metricNames.map(metricName => devStatColumns.find(column => column.dashboardMetricName === metricName)!),
]);

// tslint:disable-next-line:variable-name
export const DeveloperMetricsTabTable = (props: DeveloperMetricsTabTableProps) => {
	const { developer, metricData } = props;

	return (
		<div className="developer-metrics-tab-wrapper">
			{CATEGORY_COLUMNS.map(([category, columns]) => (
				<div key={category}>
					<div className="content category-title">{category}</div>
					{columns.map(column => {
						const currentStat = metricData.get(developer.info.dataContributorId, column.dashboardMetricName, "current");
						const previousStat = metricData.get(developer.info.dataContributorId, column.dashboardMetricName, "previous");

						return (
							<DeveloperMetricsTabTableRow
								key={column.dashboardMetricName}
								statName={column.displayValues.title}
								isIncreasePositive={column.displayValues.isIncreasePositive}
								currentStat={currentStat}
								previousStat={previousStat}
							/>
						);
					})}
				</div>
			))}
		</div>
	);
};
