import React, { useCallback } from "react";
import classNames from "classnames";
import { TaskEstimationMethod } from "@acumen/database-types";

import { Formatters } from "../../../../components/dashboard-task/formatters";
import { TASK_ESTIMATION_METHOD_SHORT_DISPLAY_NAMES } from "../../../../../localization/text-records";
import { TaskHighlights } from "../enums";
import "./task-category-card.scss";

interface TaskCategoryCardProps<Name extends TaskHighlights> {
	name: Name;
	title: string;
	estimationValue: string | number | null;
	estimationMethod: TaskEstimationMethod | undefined;
	sprintComparisonValue?: string | number | null;
	sprintComparisonDirection?: "up" | "down" | null;
	isActive: boolean;
	onClick: (name: Name) => void;
}

const TaskCategoryCard = <Name extends TaskHighlights>(props: TaskCategoryCardProps<Name>) => {
	const { name, title, estimationValue, estimationMethod, sprintComparisonValue, sprintComparisonDirection, isActive, onClick } = props;
	const handleClick = useCallback(() => onClick(name), [name, onClick]);

	return (
		<div className={classNames("task-category-card", isActive && "task-category-card-active")} onClick={handleClick}>
			<div className="task-category-card-title">{title}</div>
			<div className="task-category-footer">
				<div className="task-category-estimation">
					<span className="task-category-estimation-value">
						{estimationMethod
							? Formatters.estimationValue(estimationValue, estimationMethod, null, true, false)
							: estimationValue}
					</span>
					<span className="task-category-estimation-unit">
						{estimationMethod ? TASK_ESTIMATION_METHOD_SHORT_DISPLAY_NAMES[estimationMethod] : ""}
					</span>
				</div>

				{sprintComparisonValue && sprintComparisonDirection && (
					<div
						className={
							classNames(
								"task-category-sprint-comparison",
								sprintComparisonDirection === "up"
									? "task-category-sprint-comparison-up"
									: "task-category-sprint-comparison-down"
							)
						}
					>
						<span className="task-category-sprint-comparison-icon"/>
						<span className="task-category-sprint-comparison-value">{sprintComparisonValue}</span>
					</div>
				)}
			</div>
		</div>
	);
};

export default TaskCategoryCard;
