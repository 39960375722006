import { FormEvent, useState } from "react";

import classnames from "classnames";

import { ICard } from "../types/card";
import React from "react";

interface CardItemProps {
	value: string;
	isSelected: boolean;
	isDisabled: boolean;
	onClick: () => void;
}
const CardItem: React.FC<CardItemProps> = ({
	value,
	isSelected,
	onClick,
	isDisabled,
}) => {
	return (
		<div
			onClick={(event: FormEvent) => {
				if (isDisabled) {
					return;
				}
				event.preventDefault();
				onClick();
			}}
			className={classnames(
				"relative",
				"w-20",
				"h-28",
				"m-2",
				"border",
				"border-1",
				"cursor-pointer",
				"rounded-lg",
				{
					"cursor-pointer": !isDisabled,
				},
				{
					"bg-white": !isSelected,
				},
				{
					"bg-opacity": isSelected,
				},
				{
					"bg-[#399CFF]": isSelected && !isDisabled,
				},
				{
					"border-[#8791A2]": isSelected && !isDisabled,
				},
				{
					"bg-[#399CFF]/30": isSelected && isDisabled,
				},
				{
					"border-[#8791A2]/30": isSelected && isDisabled,
				}
			)}
		>
			<div className="w-full h-full flex justify-center items-center">
				<h3
					className={classnames(
						"text-4xl",
						"font-bold",
						{
							"text-[#1B3F7B]": !isSelected && !isDisabled,
						},
						{
							"text-[#1B3F7B]/30": !isSelected && isDisabled,
						},
						{
							"text-white": isSelected,
						}
					)}
				>
					{value}
				</h3>
			</div>
		</div>
	);
};

export interface CardPickerProps {
	hasSelectedIssue: boolean;
	hasVotedOnIssue: boolean;
	selectedCard: ICard | undefined;
	cards: ICard[];
	onSelectedCard?: (card: ICard) => void;
}
const CardPicker: React.FC<CardPickerProps> = ({
	hasSelectedIssue,
	hasVotedOnIssue,
	cards,
	selectedCard,
	onSelectedCard,
}) => {
	const [showSelectedConfirmDialogCard, setShowSelectedConfirmDialogCard] =
		useState<ICard | undefined>();
	return (
		<div className="grid container mx-auto">
			<div className="col-start-1 row-start-1 self-center ">
				<div className="grid grid-cols-1 gap-6 mt-8 md:grid-cols-1 w-full">
					{!hasVotedOnIssue && (
						<div className="justify-self-center text-center">
							<h1 className="text-[#004BA6] font-bold text-3xl">Cast your vote</h1>
							<h2 className="text-3xl mt-1">👇</h2>
						</div>
					)}
					{hasVotedOnIssue && (
						<div className="justify-self-center text-center">
							<h1 className="text-[#004BA6] font-bold text-3xl">
								Final Voting Is Done
							</h1>
							<h2 className="text-3xl mt-1">🤘</h2>
						</div>
					)}

					<div className=" mt-8 flex flex-wrap overflow-hidden items-center justify-center space-4">
						{cards.map((card, index) => {
							return (
								<CardItem
									key={index}
									isSelected={card.value === selectedCard?.value}
									isDisabled={hasVotedOnIssue}
									value={card.displayValue}
									onClick={() => {
										setShowSelectedConfirmDialogCard(card);
									}}
								/>
							);
						})}
					</div>
				</div>
			</div>
			{showSelectedConfirmDialogCard && (
				<div className="col-start-1 row-start-1 self-center backdrop-blur-sm bg-white/1 w-full h-full flex justify-center">
					<div className="bg-[#FFFEFE]/95 w-3/4 h-5/6 border-4 border-[#399CFF] rounded-lg mt-20">
						<div className="flex flex-col h-full items-center">
							<div className="flex flex-col flex-1 justify-center">
								<h3 className="text-[7rem] font-bold text-[#1B3F7B]">{showSelectedConfirmDialogCard.displayValue}</h3>
							</div>
							<div className="flex-none container mx-auto flex flex-row items-center justify-center space-x-4 mb-4">
								<button
									type="button"
									className="bg-white hover:bg-[#FAD8E3] hover:text-[#ED51A3] text-base text-[#ED51A3] font-medium py-1 px-4 border border-[#ED51A3] rounded transition duration-200 ease"
									onClick={() => setShowSelectedConfirmDialogCard(undefined)}
								>
									Cancel
								</button>
								<button
									type="button"
									className="bg-[#ED51A3] hover:bg-[#D54892] text-base text-white font-medium py-1 px-6 border border-[#ED51A3] rounded transition duration-200 ease"
									onClick={() => {
										if (showSelectedConfirmDialogCard && onSelectedCard) {
											onSelectedCard(showSelectedConfirmDialogCard);
											setShowSelectedConfirmDialogCard(undefined);
										}
									}}
								>
									Vote!
								</button>
							</div>
						</div>
					</div>
				</div>
			)}

			{!showSelectedConfirmDialogCard && hasSelectedIssue === false && (
				<div className="col-start-1 row-start-1 self-center backdrop-blur-sm bg-white/1 w-full h-full flex justify-center">
					<div className="bg-[#FFFEFE]/95 w-3/4 h-5/6 border-4 border-[#8791A2] rounded-lg mt-20">
						<div className="w-full h-full flex justify-center items-center">
							<h3 className="text-4xl font-bold text-[#1B3F7B] text-center pl-2 pr-2">
								Please select an issue to vote on first 🤷
							</h3>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export { CardPicker };
