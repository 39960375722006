import React from "react";
import { DashboardInsightRuleInvolvements, IDashboardInsightRuleInvolvementData } from "@acumen/dashboard-common";
import ActionableTable, { IColumnDefinition } from "../../../../components/actionable-table/actionable-table";
import { STRINGS } from "../../../../../localization";
import { useStores } from "../../../../mobx-stores";
import { observer } from "mobx-react";

interface IProps {
	insightRuleInvolvements: DashboardInsightRuleInvolvements;
	insightRuleId: string;
}

interface IIndexedInsightRuleInvolvement extends IDashboardInsightRuleInvolvementData {
	involvementKey: string;
}

const COLUMNS_DEF: Array<IColumnDefinition<IIndexedInsightRuleInvolvement>> = [
	{
		key: "isActive",
		headerText: STRINGS.ACTIVE
	},
	{
		key: "name",
		headerText: STRINGS.NAME
	},
	{
		key: "description",
		headerText: STRINGS.DESCRIPTION
	}
];

function InsightRuleInvolvementsPane(props: IProps) {
	const { insightRulesStore } = useStores();

	async function onRowToggled(involvementKey: string, isActive: boolean) {
		const indexedInvolvements = transformToIndexed(props.insightRuleInvolvements);
		const involvement = indexedInvolvements.find((inv) => inv.involvementKey === involvementKey);

		if (!involvement) {
			throw new Error(`Insight rule involvement row toggled but unable find involvement by involvement key - ${involvementKey}`);
		}

		await insightRulesStore.updateInsightRule({
			id: props.insightRuleId,
			involvements: [{
				id: involvement.id,
				type: involvement.type,
				isActive
			}]
		});
	}

	return (
		<ActionableTable
			columnsDef={COLUMNS_DEF}
			data={transformToIndexed(props.insightRuleInvolvements)}
			rowIdKey="involvementKey"
			isRowActiveKey="isActive"
			showRowToggle={true}
			onRowToggled={onRowToggled}
			pagination={{ show: true }}
		/>
	);
}
export default observer(InsightRuleInvolvementsPane);

function transformToIndexed(insightRuleInvolvements: DashboardInsightRuleInvolvements):
	IIndexedInsightRuleInvolvement[] {

	return insightRuleInvolvements.map((data) => {
		return {
			involvementKey: `${data.type}-${data.id}`,
			...data!
		};
	});
}
