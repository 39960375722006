import BaseStore, { IBaseStore, ILoadable } from "./base-store";
import { action, observable } from "mobx";

import apiContextProvider from "../../services/api-context-provider";
import { IIntegrationWizardJira, IntegrationsApiClient, IntegrationWizardState, JiraIntegrationType } from "../services/crud/integrations-api-client";
import {
	IDashboardIntegration, IDashboardResponse
} from "@acumen/dashboard-common";

export { JiraIntegrationType };
export interface IIntegrationsState {
	allIntegrations: ILoadable<IDashboardIntegration[]>;
	singleIntegration: ILoadable<IDashboardIntegration>;
	integrationWizard: IntegrationWizardState;
}

export interface IIntegrationsStore extends IIntegrationsState, IBaseStore<IIntegrationsState> {
	fetchAllIntegrations: () => Promise<void>;
	updateIntegration: (integration: Partial<IDashboardIntegration>) => Promise<void>;
	createGitHubInstallLink: () => Promise<void>;
	updateGitHubInstallId: (data: Partial<IntegrationWizardState>) => Promise<void>;
	createSlackInstallLink: () => Promise<void>;
	updateSlackInstallData: (data: Partial<IntegrationWizardState>) => Promise<void>;
	initializeJiraInstanceUrl: (data: Partial<IntegrationWizardState>, type: JiraIntegrationType) => Promise<void>;
	fetchJiraAuthorizationUrl: (data: Partial<IntegrationWizardState>, type: JiraIntegrationType) => Promise<void>;
	verifyJiraInstance: (data: Partial<IntegrationWizardState>, type: JiraIntegrationType) => Promise<void>;
	resetIntegrations: () => void;
	setSingleIntegration: (integration: IDashboardIntegration) => void;
	resetSingleIntegration: () => void;
}

const defaults = {
	allIntegrations: BaseStore.initLoadable([]),
	singleIntegration: BaseStore.initLoadable({}),
	integrationWizard: {}
};

export default class OldIntegrationsStore extends BaseStore<IIntegrationsState> implements IIntegrationsStore {
	private readonly apiClient: IntegrationsApiClient = new IntegrationsApiClient(apiContextProvider);

	@observable
	public allIntegrations: ILoadable<IDashboardIntegration[]> = defaults.allIntegrations;
	@observable
	public singleIntegration: ILoadable<IDashboardIntegration> = defaults.singleIntegration;
	@observable
	public integrationWizard: IntegrationWizardState = defaults.integrationWizard;

	@action.bound
	public async fetchAllIntegrations() {

		this.allIntegrations.loading = true;

		const result = await this.apiClient.findAll();

		if (result) {
			const { data } = result;

			this.allIntegrations = {
				data,
				metadata: null,
				loaded: true,
				loading: false
			};
		}
	}

	@action.bound
	public async updateIntegration(integration: Partial<IDashboardIntegration>) {
		let result: IDashboardResponse<IDashboardIntegration> | null;

		this.singleIntegration.loading = true;

		result = await this.apiClient.update(integration);

		if (result) {
			const { data } = result;

			this.singleIntegration = {
				data: data as IDashboardIntegration,
				metadata: null,
				loaded: true,
				loading: false
			};

			const old = this.allIntegrations.data.find(t => t.id === integration.id);

			if (old) {
				const idx = this.allIntegrations.data.indexOf(old);
				this.allIntegrations.data[idx] = this.singleIntegration.data;
			}
		}
	}

	@action.bound
	public async createGitHubInstallLink() {
		let result: IDashboardResponse<any> | null;

		result = await this.apiClient.createGitHubInstallLink();

		if (result) {
			const { data } = result;

			this.integrationWizard.integrationId = data.integrationId;
			this.integrationWizard.installLink = data.installLink;
		}
	}

	@action.bound
	public async updateGitHubInstallId(data: Partial<IntegrationWizardState>) {
		await this.apiClient.updateGitHubInstallId(data);
		this.integrationWizard.gitInstallId = data.gitInstallId;
	}

	@action.bound
	public async createSlackInstallLink() {
		let result: IDashboardResponse<any> | null;

		result = await this.apiClient.createSlackInstallLink();

		if (result) {
			const { data } = result;
			this.integrationWizard.integrationId = data.integrationId;
			this.integrationWizard.installLink = data.installLink;
		}
	}

	@action.bound
	public async updateSlackInstallData(data: Partial<IntegrationWizardState>) {
		await this.apiClient.updateSlackInstallData(data);
		this.integrationWizard.slackInstallCode = data.slackInstallCode;
		this.integrationWizard.slackInstallState = data.slackInstallState;
	}

	@action.bound
	public async initializeJiraInstanceUrl(data: Partial<IntegrationWizardState>, type: JiraIntegrationType) {
		const result: IDashboardResponse<IIntegrationWizardJira> | null | undefined =
			await this.apiClient.initializeJiraInstanceUrl(data, type);

		if (result && result.data) {
			this.integrationWizard = result.data;
		}
	}

	@action.bound
	public async fetchJiraAuthorizationUrl(data: Partial<IntegrationWizardState>, type: JiraIntegrationType) {
		let result: IDashboardResponse<any> | null;

		result = await this.apiClient.fetchJiraAuthorizationUrl(data, type);
		if (result && result.data) {
			this.integrationWizard.jiraAuthorizationUrl = result.data.integrationAuthUrl;
		}
	}

	@action.bound
	public async verifyJiraInstance(data: Partial<IntegrationWizardState>, type: JiraIntegrationType) {
		let result: IDashboardResponse<any> | null | undefined;

		result = await this.apiClient.verifyJiraInstance(data, type);

		if (result && result.data) {
			this.integrationWizard.jiraVerificationSuccess = true;
		}
	}

	@action.bound
	public resetIntegrations() {
		this.allIntegrations = defaults.allIntegrations;
	}

	@action.bound
	public setSingleIntegration(integration: IDashboardIntegration) {
		this.singleIntegration.data = integration;
	}

	@action.bound
	public resetSingleIntegration() {
		this.singleIntegration = defaults.singleIntegration;
	}

	@action.bound
	public resetIntegrationWizard() {
		this.integrationWizard = defaults.integrationWizard;
	}
}
