import {
	DevelopmentMethodology,
	SubtaskSprintStrategy,
	TaskEstimationMethod,
	CustomizableConfigurationCategories,
	isINonObjectConfigContextValues,
	SINGLE_CONFIG_KEY,
	CustomizableConfiguration,
	IDashboardTaskStatusChangeResponse,
	SprintState,
	MixpanelEventType,
	IDashboardSprint,
	SprintSortField,
	DashboardSortOrder,
	INonObjectConfigContextValuesWithOptions,
	IDashboardCycleTimeBreakdown,
} from "@acumen/dashboard-common";
import { GA_EVENT_CATEGORY } from "../../../analytics-events";
import LightNavBar from "../../../components/filters-top-navbar/filters-navbar";
import { IterationPeriodSelector } from "../../../pages/my-team/sprint-retro";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Input, Popup, Tab } from "semantic-ui-react";
import infoIcon from "../../../components/svg-assets/info-icon.svg";
import premiumIcon from "../../../components/svg-assets/billing-icons/premium.svg";
import emptySprintChart from "../../../components/svg-assets/empty-sprint-chart.svg";
import emptyFilteredSprintChart from "../../../components/svg-assets/empty-filtered-sprint-chart.svg";
import { useStores } from "../../../mobx-stores";
import "./style.scss";
import { observer } from "mobx-react";
import GoRetroSprintSummaryConfiguration from "./configurations/sprint-review-configuration";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import { mixpanelService } from "../../../external-app";
import moment from "moment";
import BurndownChart from "../../../pages/my-team/sprint-retro/burndown-chart";
import classNames from "classnames";
import { sendMessageToGoRetroWithParam, GoRetroMessagesWithParam, sendMessageToGoRetro, GoRetroMessages } from "../go-retro-messages";
import TabCard from "./tab-card";
import SprintDates from "./sprint-dates";
import { ProgressUpdate } from "../../../mobx-stores/go-retro-sprint-review-store";
import PlanVsActualChart from "../../../pages/my-team/sprint-retro/plan-vs-actual-chart";
import { IPlannedVsActualRequestScope } from "../../../mobx-stores/planned-vs-actual-store";
import { FeatureFlags } from "../../../feature-flags";
import SummaryPieChart from "./summary-pie-chart";
import { round } from "@acumen/common";
import _ from "lodash";
import TaskCategoryCard from "./task-category-card/task-category-card";
import { TaskHighlights } from "./enums";
import TasksTableContainer from "./tasks-table-container";
import EmptySprintState from "./empty-sprint-state";
import { useBugsAddedMidSprint, useDoneTasksByType } from "./hooks";
import SprintReviewContributorsSelector from "./sprint-review-contributors-selector/sprint-review-contributors-selector";
import { ContributorFilterModel } from "../../../types/contributor-filter.model";

const HIGHLIGHTS_TOOLTIPS = {
	completedTasksEstimation: "Total sum of all issues completed in the sprint. Issues that were removed, discarded, or reopened are not included",
	plannedVsActualHighlight: "Shows the ratio between the amount of planned work vs the amount of work completed. Completed work includes unplanned tasks",
	completed: "A breakdown of tasks that were added to the sprint during planning and were completed",
	notCompleted: "A breakdown of tasks that were added to the sprint during planning and were not completed",
	midSprint: "A breakdown of tasks that were added to the sprint after it has already started",
	carryOver: "A breakdown of tasks that were carried over to the sprint from the previous one and were not completed"
};

const SPRINT_OVERVIEW_TOOLTIP = `An overview of the selected sprint. Use this for discussions
								about estimation accuracy and sprint performance`;
const BURNDOWN_CHART_TOOLTIP = `The amount of work that has been completed in the sprint, and the total amount remaining`;

const PLANNED_VS_ACTUAL_TOOLTIP = `A comparison of the amount of tasks planned versus the amount of tasks completed in each sprint`;

const ALL_TASKS_TOOLTIP = `An overview of all the tasks in this sprint. Use this to see where each task started and ended,
						and what was the actual time each task was "in progress"`;

const DEFAULT_ESTIMATION_SUM = "00.00";

const NO_SPRINTS_DETECTED_MESSAGE = `No closed or active sprints detected. Click on the settings icon to select additional sprints.`;

const PLANNED_VS_ACTUAL_MONTHS = 3;

const SUMMARY_HIGHLIGHT_TEXT_BY_ESTIMATION_METHOD: Record<TaskEstimationMethod, string> = {
	[TaskEstimationMethod.None]: "issues done",
	[TaskEstimationMethod.StoryPoints]: "story points done",
	[TaskEstimationMethod.TimeBased]: "estimation total (completed)",
	[TaskEstimationMethod.TShirtSize]: "issues done",
};

interface IGoRetroSprintRetroConfiguration {
	countryCode: string;
	locale: string;
	timezone: string;
	subtaskStrategy: SubtaskSprintStrategy;
	devMethodology: DevelopmentMethodology;
	estimationMethod: TaskEstimationMethod;
	plannedGracePeriodHours: number;
	boardIds: string[];
	sprintIds: string[];
	doneStatuses: string[];
	contributorFilters: ContributorFilterModel[];
}

export const GoRetroSprintRetro = observer((props: RouteComponentProps<{ customerId: string }>) => {
	const { customerId } = props.match.params;
	const {
		sprintsStore: {
			fetchData: fetchSprints,
			isLoading: isLoadingSprints
		},
		goRetroSprintSummaryStore: {
			selectedSprint,
			setSelectedSprint,
			fetchIntegrationProgress,
			subscriptionData,
			fetchSubscriptionDetails
		},
		sprintRetroStore: {
			fetchTasksStateInSprint,
			issueIdsByCategory,
			isLoadingIssueIdsByCategory,
			fetchParticipantsInSprint,
			participants,
			getBurndownData,
			burndownChartData,
			getIssues,
			issues,
			isLoadingIssuesData,
		},
		tasksStore: {
			statusDiff,
			fetchStatusDiff,
			getCycleTimeBreakdown,
		},
		customizationStore: {
			getConfigurationByCategory,
		},
		planVsActualStore: {
			resetPlannedVsActualData,
			planVsActualData,
			currentSprintActual,
			currentSprintPlannedVsActualPercentage
		},
		featureFlagStore: {
			isInitialized: isFeatureFlagStoreInitialized,
			initialize: initializeFeatureFlagStore,
			isFeatureEnabled,
		},
		priorityStore: {
			priorities,
			isLoadingPriorities,
			fetchJiraPriorities,
		},
	} = useStores();
	const [totalEstimation, setTotalEstimation] = useState<number | undefined>(undefined);
	const [completedEstimation, setCompletedEstimation] = useState<number | undefined>(undefined);
	const [notCompletedEstimation, setNotCompletedEstimation] = useState<number | undefined>(undefined);
	const [addedMidSprintEstimation, setAddedMidSprintEstimation] = useState<number | undefined>(undefined);
	const [carryOverEstimation, setCarryOverEstimation] = useState<number | undefined>(undefined);
	const [selectedContributorIds, setSelectedContributorIds] = useState<string[]>([]);
	const [selectedContributorFilterIndices, setSelectedContributorFilterIndices] = useState<number[]>([]);
	const [allStatusDiff, setAllStatusDiff] = useState<IDashboardTaskStatusChangeResponse | undefined>(undefined);
	const [configFields, setConfigFields] = useState<undefined | IGoRetroSprintRetroConfiguration>(undefined);
	const [sprintsForSelector, setSprintsForSelector] = useState<IDashboardSprint[] | undefined>(undefined);
	const [progressUpdate, setProgressUpdate] = useState<undefined | ProgressUpdate>(undefined);
	const [pageErrorMessage, setPageErrorMessage] = useState<string | undefined>(undefined);
	const [activeTaskCategory, setActiveTaskCategory] = useState<TaskHighlights | null>(TaskHighlights.All);
	const [cycleTimeBreakdown, setCycleTimeBreakdown] = useState<IDashboardCycleTimeBreakdown[] | null>(null);
	const isMountedRef = useRef(false);
	const [dataContributorFilter, setDataContributorFilter] = useState<string[]>([]);
	const [sprintFilter, setSprintFilter] = useState<string | null>(null);

	const history = useHistory();
	const location = useLocation();

	const isLoading = isLoadingSprints || isLoadingIssuesData || isLoadingIssueIdsByCategory || statusDiff.loading;
	const isTableDisabled = issues && issues.length === 0 && !isLoading;
	const { estimationMethod } = configFields || {};

	const handleTaskCategoryClick = useCallback((name: TaskHighlights) => setActiveTaskCategory(name), []);
	const completedTasks = useMemo(() => {
		return issues
			&& issueIdsByCategory?.completed
			&& !(isLoadingIssuesData || isLoadingIssueIdsByCategory)
			? issues.filter(issue => issueIdsByCategory.completed.includes(issue.entityId))
			: null;
	}, [issues, issueIdsByCategory, isLoadingIssuesData, isLoadingIssueIdsByCategory]);
	const isLoadingChartRelatedData = isLoadingIssuesData || isLoadingPriorities || isLoadingIssueIdsByCategory;
	const bugsAddedMidSprint = useBugsAddedMidSprint({ issues, priorities, issueIdsByCategory, isLoadingData: isLoadingChartRelatedData });
	const workDoneByType = useDoneTasksByType({ issues, issueIdsByCategory, estimationMethod, isLoadingData: isLoadingChartRelatedData });
	const contributors = useMemo(() => participants?.map(user => ({
		id: user.id,
		displayName: user.primaryDisplayName ?? "",
		avatarUrl: user.primaryAvatarUrl ?? undefined
	})) ?? [], [participants]);
	const rawContributorFilters = configFields?.contributorFilters;
	const contributorFilters = useMemo(() => rawContributorFilters ?? [], [rawContributorFilters]);
	const isFilteredBurnDownEmpty = useMemo(() => {
		if (selectedContributorIds.length === 0) {
			return false;
		}

		return burndownChartData?.data.every(item => {
			return item.planned === 0
				&& item.unplanned === 0
				&& item.carryOver === 0
				&& item.completedTasks.length === 0
				&& item.removedTasks.length === 0;
		});
	}, [selectedContributorIds, burndownChartData]);
	const getConfigurations = useCallback(() => {
		// tslint:disable-next-line: no-floating-promises
		getConfigurationByCategory(CustomizableConfigurationCategories.GoRetro).then(res => {
			if (res) {
				const conf: Partial<IGoRetroSprintRetroConfiguration> = {};
				Object.values(res).forEach((field) => {
					if (field) {
						const valueForUpdate = isINonObjectConfigContextValues(field.currentValue)
							? (field.currentValue as INonObjectConfigContextValuesWithOptions)[SINGLE_CONFIG_KEY].value
							: undefined;
						// At the moment we only support DevelopmentMethodology.Scrum for Go-Retro.
						conf.devMethodology = DevelopmentMethodology.Scrum;
						switch (field.name) {
							case CustomizableConfiguration.CountryCode:
								conf.countryCode = valueForUpdate as string;
								break;
							case CustomizableConfiguration.Locale:
								conf.locale = valueForUpdate as string;
								break;
							case CustomizableConfiguration.TimeZone:
								conf.timezone = valueForUpdate as string;
								break;
							case CustomizableConfiguration.SubtaskSprintStrategy:
								conf.subtaskStrategy = valueForUpdate as SubtaskSprintStrategy;
								break;
							case CustomizableConfiguration.GoretroEstimationMethod:
								conf.estimationMethod = valueForUpdate as TaskEstimationMethod;
								break;
							case CustomizableConfiguration.PlannedGracePeriod:
								conf.plannedGracePeriodHours = valueForUpdate as number;
								break;
							case CustomizableConfiguration.BoardIds:
								conf.boardIds = valueForUpdate as string[];
								break;
							case CustomizableConfiguration.SprintIds:
								conf.sprintIds = valueForUpdate as string[];
								break;
							case CustomizableConfiguration.DevelopmentMethodology:
								conf.devMethodology = valueForUpdate as DevelopmentMethodology;
								break;
							case CustomizableConfiguration.DoneStatuses:
								conf.doneStatuses = valueForUpdate as string[];
								break;
							case CustomizableConfiguration.ContributorFilters:
								conf.contributorFilters = (valueForUpdate ?? []) as ContributorFilterModel[];
								break;
						}
					}
				});
				const hasUndefinedProperty = Object.values(conf).every(el => el === undefined);
				if (!hasUndefinedProperty) {
					setConfigFields(conf as IGoRetroSprintRetroConfiguration);
				}
			}
		});
	}, []);

	const handleConfigurationSaved = useCallback(() => {
		getConfigurations();
	}, [getConfigurations]);

	useEffect(() => {
		if (!isMountedRef.current) {
			isMountedRef.current = true;
		} else {
			const filteredContributorIds = selectedContributorFilterIndices
				.map(index => contributorFilters[index].contributorIds)
				.reduce((acc, cur) => [...acc, ...cur], []);

			const contributorIds = _.uniq([
				...filteredContributorIds,
				...selectedContributorIds,
			]);
			setDataContributorFilter(contributorIds);
			mixpanelService.track(MixpanelEventType.SprintReviewAvatarSelection, {
				selectionCount: contributorIds.length
			});
		}
	}, [selectedContributorFilterIndices, selectedContributorIds]);

	useEffect(() => {
		// tslint:disable-next-line: no-floating-promises
		fetchIntegrationProgress().then(res => setProgressUpdate(res));
		getConfigurations();
		// tslint:disable-next-line: no-floating-promises
		fetchSubscriptionDetails();
		fetchJiraPriorities().catch();
		return () => setConfigFields(undefined);
	}, [getConfigurations]);

	// NOTE: Waiting for ACM-4735, after which launch darkly initialization shall be moved to auth-store.
	useEffect(() => {
		if (customerId != null) {
			initializeFeatureFlagStore(customerId).catch();
		}
	}, [customerId]);

	useEffect(() => {
		if (!configFields || !progressUpdate || !subscriptionData?.isActive) {
			if (subscriptionData && !subscriptionData.isActive) {
				// NOTE: the iframe access should be blocked from within goretro, but this is here to redirect users
				// just in case
				sendMessageToGoRetro(GoRetroMessages.SubscriptionInactive);
			}

			return;
		}

		// tslint:disable-next-line: no-floating-promises
		fetchSprints(configFields.devMethodology, {
			sprintStates: [SprintState.Active, SprintState.Closed],
			excludeSprintsWithFutureStartDate: true,
			boardIds: configFields.boardIds,
			sprintStartTimeFrom: progressUpdate.jira3loFetchingPeriodStart
		}, {
			field: SprintSortField.StartDate,
			order: DashboardSortOrder.Descending
		}).then((sprints) => {
			setSprintsForSelector(sprints);

			const sprintId = getQueryProp("sprint");

			if (sprints.length === 0) {
				setPageErrorMessage(NO_SPRINTS_DETECTED_MESSAGE);
				return;
			}

			const defaultSprint = sprints.find(sprint => sprint.id === sprintId)
				|| sprints.find(sprint => sprint.isActive)
				|| sprints[0];

			setSelectedSprint(defaultSprint);
			setPageErrorMessage(undefined);

		});
	}, [configFields, progressUpdate]);

	useEffect(() => {
		const fetchStart = Date.now();
		updateQueryString();
		if (!selectedSprint || !selectedSprint?.id || !configFields) {
			return;
		}

		// tslint:disable-next-line: no-floating-promises
		fetchParticipantsInSprint(selectedSprint.id).then((participantsRes) => {
			if (!participantsRes) {
				setDataContributorFilter([]);
				return;
			}

			const participantIds = participantsRes.map(p => p.id);
			const participantsToRemove = _.difference(dataContributorFilter, participantIds);
			if (participantsToRemove.length > 0) {
				setDataContributorFilter(_.difference(dataContributorFilter, participantsToRemove));
			}
		});

		mixpanelService.track(MixpanelEventType.SprintReviewSelect, {
			minutesToFetchData: moment.duration(moment().diff(fetchStart)).asMinutes(),
			sprintIsCurrentSprint: selectedSprint.endDate ? moment(moment(selectedSprint.endDate).utc()).isSameOrAfter(moment().utc())
				&& !selectedSprint.completeDate : undefined
		});

	}, [selectedSprint]);

	useEffect(() => {
		if (!participants || !selectedSprint || !configFields) {
			return;
		}

		const dataContributorIds = getDataContributorIds();
		// tslint:disable-next-line: no-floating-promises
		getIssues({
			sprint: selectedSprint,
			dataContributorIds,
			subtaskStrategy: configFields.subtaskStrategy,
			estimationMethod: configFields.estimationMethod,
			developmentMethodology: configFields.devMethodology,
			includeUnassigned: dataContributorFilter.length === 0,
		});

		// tslint:disable-next-line: no-floating-promises
		getBurndownData({
			sprint: selectedSprint,
			estimationMethod: configFields.estimationMethod,
			timezone: configFields.timezone,
			subtaskStrategy: configFields.subtaskStrategy,
			countryCode: configFields.countryCode,
			developmentMethodology: configFields.devMethodology,
			dataContributorIds,
			boardIds: configFields.boardIds,
			includeUnassigned: dataContributorFilter.length === 0,
		});
	}, [dataContributorFilter, participants, configFields]);

	useEffect(() => {
		if (!issues || !configFields || !selectedSprint ||
			!selectedSprint.startDate || !selectedSprint.endDate || !participants) {
			return;
		}

		const taskIds = issues.map(task => task.entityId);

		// tslint:disable-next-line: no-floating-promises
		fetchTasksStateInSprint({
			sprintId: selectedSprint.id, taskIds, allowedAssignees: [],
			includeUnassigned: true, boardIds: configFields.boardIds,
			developmentMethodology: configFields.devMethodology
		});

		// tslint:disable-next-line: no-floating-promises
		fetchStatusDiff(
			taskIds,
			new Date(selectedSprint.startDate),
			selectedSprint.completeDate ? new Date(selectedSprint.completeDate) : new Date(),
			false
		).then((statuses) => {
			if (statuses) {
				setAllStatusDiff(statuses);
			}
		});

	}, [issues]);

	const getQueryProp = (propName: string) => {
		const currentQsParams = new URLSearchParams(location.search);
		return currentQsParams.get(propName);
	};

	const updateQueryString = () => {
		if (selectedSprint?.id) {
			sendMessageToGoRetroWithParam(GoRetroMessagesWithParam.Sprint, selectedSprint.id);
			const params = new URLSearchParams(location.search);
			params.set("sprint", selectedSprint.id);
			history.replace({ search: params.toString() });
		}
	};

	useEffect(() => {
		if (issues && issueIdsByCategory) {
			setTotalEstimation(
				issues.reduce((sum, issue) => sum + issue.estimationByStrategy, 0)
			);

			setCompletedEstimation(
				issues
					.filter(issue => issueIdsByCategory.completed.includes(issue.entityId))
					.reduce((sum, issue) => sum + issue.estimationByStrategy, 0)
			);

			setNotCompletedEstimation(
				issues
					.filter(issue => !issueIdsByCategory.completed.includes(issue.entityId))
					.reduce((sum, issue) => sum + issue.estimationByStrategy, 0)
			);

			setAddedMidSprintEstimation(
				issues
					.filter(issue => !issueIdsByCategory.planned.includes(issue.entityId))
					.reduce((sum, issue) => sum + issue.estimationByStrategy, 0)
			);

			setCarryOverEstimation(
				issues
					.filter(issue => issueIdsByCategory.carryOver.includes(issue.entityId))
					.reduce((sum, issue) => sum + issue.estimationByStrategy, 0)
			);
		}
	}, [issueIdsByCategory]);

	useEffect(() => {
		if (isFeatureFlagStoreInitialized
			&& isFeatureEnabled(FeatureFlags.CycleTimeBreakdown)
			&& completedTasks
			&& completedTasks.length > 0) {
			getCycleTimeBreakdown(completedTasks.map(issue => issue.entityId)).then(items => {
				setCycleTimeBreakdown(items);
			}).catch();
		}
	}, [isFeatureFlagStoreInitialized, completedTasks]);

	const getDataContributorIds = (addNullToIncludeUnassigned = false): string[] => {
		if (!participants) {
			return dataContributorFilter;
		}

		const allDcIds = participants.map(participant => participant.id)
			.concat(addNullToIncludeUnassigned ? ["null"] : []);
		return dataContributorFilter.length > 0
			? dataContributorFilter
			: allDcIds;
	};

	const showCog = useMemo(() => {
		return getQueryProp("showCog")?.toLowerCase() === "true";
	}, [location.search]);

	const plannedVsActualScope = useMemo((): IPlannedVsActualRequestScope | undefined => {
		resetPlannedVsActualData();
		if (selectedSprint && configFields) {
			const sprintStartTime = selectedSprint.startDate ? moment(selectedSprint.startDate).toDate().getTime() : Infinity;
			const chartScopeStartTime = moment().subtract(PLANNED_VS_ACTUAL_MONTHS, "months").toDate().getTime();
			const startDate = new Date(Math.min(sprintStartTime, chartScopeStartTime));
			const endDate = moment(startDate).add(PLANNED_VS_ACTUAL_MONTHS, "months").toDate();
			const dataContributorIds = dataContributorFilter.length === 0 ? [] : getDataContributorIds(true);

			return {
				sprint: selectedSprint,
				dataContributorIds,
				estimationMethod: configFields.estimationMethod,
				subtaskStrategy: configFields.subtaskStrategy,
				developmentMethodology: configFields.devMethodology,
				excludeTasksWithNonTeamDCs: false,
				startDate,
				endDate,
				boardIds: configFields.boardIds
			};
		}

		return undefined;
	}, [dataContributorFilter, configFields, selectedSprint]);

	return (
		<div className="ui basic segment">
			<div className="ui grid go-retro">
				<LightNavBar
					className="go-retro-top-navbar"
					popupSelectFields={[]}
					clickEventPage={GA_EVENT_CATEGORY.SprintReport}
				>
					<div className="flex-row center-align full-width spaced">
						<div className="flex-row">
							<h1 className="page-title no-margin">Sprint Monitoring</h1>
							<img src={premiumIcon} className="premium-icon" alt="" />
						</div>
						{pageErrorMessage &&
							<div className="page-error-message">
								<i aria-hidden="true" className="outline triangle exclamation icon" />
								{pageErrorMessage}
							</div>
						}
						<div className="flex-row center-align">
							<SprintReviewContributorsSelector
								showCreateFilterButton={showCog}
								contributors={contributors}
								selectedContributorIds={selectedContributorIds}
								maxUsersOnView={4}
								showArrow={true}
								multiselect={true}
								className="go-retro-avatar-selector"
								contributorFilters={contributorFilters}
								selectedContributorFilterIndices={selectedContributorFilterIndices}
								onSelectedContributorsChange={setSelectedContributorIds}
								onSelectedContributorFiltersChange={setSelectedContributorFilterIndices}
								onFilterCreated={getConfigurations}
							/>
							<div className="go-retro-sprint-selector">
								<IterationPeriodSelector
									sprint={selectedSprint}
									onChange={(s) => {
										setSelectedSprint(s.value);
									}}
									devMethodology={DevelopmentMethodology.Scrum}
									showArrow={true}
									sprints={sprintsForSelector}
								/>
							</div>
							{(configFields && showCog) && (
								<GoRetroSprintSummaryConfiguration
									selectedSprints={configFields.sprintIds}
									onSubmit={handleConfigurationSaved}
								/>
							)}
						</div>
					</div>
				</LightNavBar>
				<div className="ui full-width">
					<ComponentHeader
						title="Sprint overview"
						tooltip={SPRINT_OVERVIEW_TOOLTIP}
					/>
					<div className="flex-row full-width tabs-and-table-grid go-retro-table">
						<div className="sprint-summary-tabs">
							<SprintDates sprint={selectedSprint} timezone={configFields?.timezone} />
								<div className={classNames("ui card header tabs", "table-shadow", { disabled: isTableDisabled })}>
									<TabCard
										title={"Planned vs. Actual"}
										tooltip={HIGHLIGHTS_TOOLTIPS.plannedVsActualHighlight}
 										value={_.isNil(currentSprintPlannedVsActualPercentage) ? "N/A" : round(currentSprintPlannedVsActualPercentage!)}
										percentage={currentSprintPlannedVsActualPercentage !== null}
										isActive={true}
										isDisabled={isTableDisabled}
										isLoading={planVsActualData === undefined}
									/>
								</div>
							<div className={classNames("ui card header tabs", "table-shadow", { disabled: isTableDisabled })}>
								<TabCard
									title={configFields?.estimationMethod ? SUMMARY_HIGHLIGHT_TEXT_BY_ESTIMATION_METHOD[configFields?.estimationMethod] : ""}
									tooltip={HIGHLIGHTS_TOOLTIPS.completedTasksEstimation}
									value={currentSprintActual ?? DEFAULT_ESTIMATION_SUM}
									estimationMethod={configFields?.estimationMethod}
									isActive={true}
									isDisabled={isTableDisabled}
									isLoading={planVsActualData === undefined}
								/>
							</div>
						</div>
						<Tab.Pane attached={false} className="burndown-container table-container table-shadow border-rounded height-container burndown"
							loading={!burndownChartData || !configFields}>
							{(isTableDisabled || isFilteredBurnDownEmpty)
								? <EmptySprintState
									imageSrc={selectedContributorIds.length > 0
										? emptyFilteredSprintChart
										: emptySprintChart
									}
									text={selectedContributorIds.length > 0
										? "The applied filter is not relevant for the sprint"
										: "Looks like this particular sprint is currently empty"
									}
								/>
								: <>
									<ComponentHeader
										title="Burndown chart (end of day)"
										tooltip={BURNDOWN_CHART_TOOLTIP}
									/>
									{(configFields && burndownChartData) &&
										<BurndownChart
											removeTitle={true}
											timezone={configFields.timezone}
											devMethodology={configFields.devMethodology}
											pageError={false}
											estimationMethod={configFields.estimationMethod}
											subtaskStrategy={configFields.subtaskStrategy}
											burndownChartData={burndownChartData}
											legendPosition="top"
											height={340}
											sprint={selectedSprint}
										/>
									}
								</>
							}
						</Tab.Pane>
					</div>

					{isFeatureEnabled(FeatureFlags.SprintSummaryPieCharts) && (
						<div className="charts-row">
							<div className="size-1">
								<Tab.Pane
									attached={false}
									className={classNames("no-border", "border-rounded", "full-width", "table-shadow", "no-padding")}
									loading={!issues}
								>
									<SummaryPieChart
										title="Bugs added mid sprint"
										description="Bugs that were created during the sprint."
										chartSeries={bugsAddedMidSprint}
										supportExpand={false}
										legendClassName="bugs-added-mid-sprint-chart-legend"
										legendItemFormat="<span>{name}</span><b>{y:.1f}%</b><span>({tooltip} Bugs)</span>"
										countLabel="Total number<br/>of bugs"
									/>
								</Tab.Pane>
							</div>

							<div className="size-2">
								<Tab.Pane
									attached={false}
									className={classNames("no-border", "border-rounded", "full-width", "table-shadow", "no-padding")}
									loading={!issues}
								>
									<SummaryPieChart
										title="Work done by type"
										description="Issues that have completed their cycle (done) in this sprint, split by issue type."
										chartSeries={workDoneByType}
										supportExpand={false}
										legendClassName="issues-done-by-type-chart-legend"
										legendItemFormat="<span>{name}</span><b>{tooltip} <span>({y:.1f}%)</span></b>"
										countLabel={mapEstimationMethodToSuffix(estimationMethod)}
									/>
								</Tab.Pane>
							</div>
						</div>
					)}

					{isFeatureEnabled(FeatureFlags.PlannedVsActual) && (
						<React.Fragment>
							<ComponentHeader
								title="Planned vs. actual"
								tooltip={PLANNED_VS_ACTUAL_TOOLTIP}
							/>
							<div style={{ display: "flex" }}>
								<div style={{ flex: "1" }}/>
									<Input
										placeholder="Sprint filter"
										style={{ flexBasis: "25%", width: "100%", paddingBottom: "5px"}}
										onChange={e => (e && e.target.value && e.target.value.trim())
											? setSprintFilter(e.target.value)
											: setSprintFilter(null)}
									/>
							</div>
							<Tab.Pane attached={false} className={classNames("table-container", "table-shadow", "border-rounded",
								"height-container planned-vs-actual")} loading={!planVsActualData}>
								<div className="planned-vs-actual-padding">
									{plannedVsActualScope &&
										<PlanVsActualChart
											plannedVsActualScope={plannedVsActualScope!}
											timezone={configFields?.timezone}
											height={500}
											legendPosition="top"
											pageError={false}
											showLoadingIndicator={false}
											sprintFilter={sprintFilter}
										/>
									}
								</div>
							</Tab.Pane>
						</React.Fragment>
					)}

					<ComponentHeader
						title="Issues breakdown"
						tooltip={ALL_TASKS_TOOLTIP}
					/>
					<div className="tab-category-cards">
						<TaskCategoryCard
							name={TaskHighlights.All}
							title="All Issues"
							estimationValue={totalEstimation ?? DEFAULT_ESTIMATION_SUM}
							estimationMethod={configFields?.estimationMethod}
							isActive={activeTaskCategory === TaskHighlights.All}
							onClick={handleTaskCategoryClick}
						/>
						<TaskCategoryCard
							name={TaskHighlights.Completed}
							title="Completed"
							estimationValue={completedEstimation ?? DEFAULT_ESTIMATION_SUM}
							estimationMethod={configFields?.estimationMethod}
							isActive={activeTaskCategory === TaskHighlights.Completed}
							onClick={handleTaskCategoryClick}
						/>
						<TaskCategoryCard
							name={TaskHighlights.NotCompleted}
							title="Not Completed"
							estimationValue={notCompletedEstimation ?? DEFAULT_ESTIMATION_SUM}
							estimationMethod={configFields?.estimationMethod}
							isActive={activeTaskCategory === TaskHighlights.NotCompleted}
							onClick={handleTaskCategoryClick}
						/>
						<TaskCategoryCard
							name={TaskHighlights.AddedMidSprint}
							title="Added Mid Sprint"
							estimationValue={addedMidSprintEstimation ?? DEFAULT_ESTIMATION_SUM}
							estimationMethod={configFields?.estimationMethod}
							isActive={activeTaskCategory === TaskHighlights.AddedMidSprint}
							onClick={handleTaskCategoryClick}
						/>
						<TaskCategoryCard
							name={TaskHighlights.CarryOver}
							title="Carry Over"
							estimationValue={carryOverEstimation ?? DEFAULT_ESTIMATION_SUM}
							estimationMethod={configFields?.estimationMethod}
							isActive={activeTaskCategory === TaskHighlights.CarryOver}
							onClick={handleTaskCategoryClick}
						/>
					</div>
					<Tab.Pane attached={false} className={classNames("height-container all-tasks", "no-border", "border-rounded",
						"full-width", "table-shadow", "no-padding")}
						loading={!issues}>
						<TasksTableContainer
							isTableDisabled={!!isTableDisabled}
							textTypeOfTasks=""
							tasks={issues}
							estimationMethod={configFields?.estimationMethod}
							subtaskStrategy={configFields?.subtaskStrategy}
							className="extended-table"
							statusDiff={allStatusDiff}
							doneStatuses={configFields?.doneStatuses}
							isRaisedRows={false}
							isExtended={true}
							isFilteringEnabled={true}
							taskCategory={activeTaskCategory}
							cycleTimeBreakdown={cycleTimeBreakdown}
							onTaskCategoryChange={setActiveTaskCategory}
						/>
					</Tab.Pane>
				</div>
			</div>
		</div>
	);
});

function mapEstimationMethodToSuffix(estimationMethod: TaskEstimationMethod | undefined) {
	switch (estimationMethod) {
		case TaskEstimationMethod.TimeBased:
			return "Hours of work<br/>completed";

		case TaskEstimationMethod.StoryPoints:
			return "Story points<br/>completed";

		case TaskEstimationMethod.None:
		default:
			return "Issues<br/>completed";
	}
}

const ComponentHeader = (props: { title: string, tooltip: string }) => {
	const { title, tooltip } = props;
	return (
		<div className="large-text --bold acu-capitalize">
			<span>{title}</span>
			<Popup
				hoverable={true}
				wide={true}
				position="top center"
				content={tooltip}
				trigger={
					<img src={infoIcon} className="info-icon" alt="" />
				}
			/>
		</div>
	);
};
