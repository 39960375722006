import classnames from "classnames";
import React from "react";

export enum CreateMenuItem {
	NewGame = "New Game",
	PreviousGames = "Previous Games",
}

interface CreateMenuItemProps {
	item: CreateMenuItem;
	isSelected: boolean;
	onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const getMenuItemText = (item: CreateMenuItem): string => {
	switch (item) {
		case CreateMenuItem.NewGame: {
			return "New game";
		}
		case CreateMenuItem.PreviousGames: {
			return "Previous games";
		}
	}
};

const CreateMenuSingleItem: React.FC<CreateMenuItemProps> = ({
	item,
	isSelected,
	onClick,
}) => {
	return (
		<div
			onClick={onClick}
			className={classnames(
				"flex",
				"items-center",
				"px-4",
				"py-2",
				"border-b-4",
				"cursor-pointer",
				{
					"border-[#ED51A3]": isSelected,
				}
			)}
		>
			<span
				className={classnames(
					"text-xl font-semibold",
					{
						"text-[#ED51A3]": isSelected,
					},
					{
						"text-[#949DAC]": !isSelected,
					}
				)}
			>
				{getMenuItemText(item)}
			</span>
		</div>
	);
};

export interface CreateMenuBarProps {
	selectedMenuItem: CreateMenuItem;
	onSelectedMenuItem?: (value: CreateMenuItem) => void;
}

const CreateGameMenuBar: React.FC<CreateMenuBarProps> = ({
	selectedMenuItem,
	onSelectedMenuItem
}) => {
	return (
		<div className="px-4 py-4 sm:px-8">
			<div className="container mx-auto flex flex-row items-center justify-center space-x-7">
				<CreateMenuSingleItem
					isSelected={selectedMenuItem === CreateMenuItem.NewGame}
					item={CreateMenuItem.NewGame}
					onClick={() => {
						if (onSelectedMenuItem) {
							onSelectedMenuItem(CreateMenuItem.NewGame);
						}
					}}
				/>
				{<CreateMenuSingleItem
					isSelected={selectedMenuItem === CreateMenuItem.PreviousGames}
					item={CreateMenuItem.PreviousGames}
					onClick={() => {
						if (onSelectedMenuItem) {
							onSelectedMenuItem(CreateMenuItem.PreviousGames);
						}
					}}
				/>}
			</div>
		</div>
	);
};

export { CreateGameMenuBar };
