
import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../mobx-stores";
import DigestTaskCard from "./digest-task-card";
import { ISelectOptionBase } from "../../../components/form/option-select";
import {
	DashboardSortOrder, DashboardTaskWorkStatusGrouping, PullRequestEventType, IssueEventType, AcumenTaskStatusGroup, SprintSortField, DevelopmentMethodology
} from "@acumen/dashboard-common";
import { EVENTS_FRIENDLY_NAMES } from "../../../../localization/text-records";
import { DashboardTaskDigestCard, FilterName } from "../../../mobx-stores/digest-store";
import { Loader } from "semantic-ui-react";
import TaskDetailsDisplayPanel from "../../../components/dashboard-task/task-details-display-panel";
import moment from "moment";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { useLocation } from "react-router-dom";
import { assertExhaustiveSwitch } from "@acumen/common";
import NothingFoundIcon from "../../../components/svg-assets/no-content-icons/empty_state_icon_1.svg";
import FiltersTopNavbar from "../../../components/filters-top-navbar";
import { GA_EVENT_ACTION, GA_EVENT_CATEGORY, clickEvent } from "../../../analytics-events";
import classNames from "classnames";

const WORK_TASK_FILTER_LOCAL_STORAGE_KEY = "task_digest_filters";

// tslint:disable-next-line: interface-over-type-literal
type Filters = {
	assigneeDataContributorIds: string[];
	projects: string[];
	repositories: string[];
	statuses: string[];
	types: string[];
	sprints: string[];
	prEvents: string[];
	jiraEvents: string[];
};

const defaultFilters: Filters = {
	assigneeDataContributorIds: [],
	projects: [],
	repositories: [],
	statuses: [],
	types: [],
	sprints: [],
	prEvents: [PullRequestEventType.PullRequestMerged, PullRequestEventType.PullRequestDeployed],
	jiraEvents: [
		IssueEventType.IssueAssigned, IssueEventType.IssueStatusChanged, IssueEventType.IssueFixVersionChanged,
		IssueEventType.IssueTypeChanged, IssueEventType.IssuePriorityChanged, IssueEventType.IssueSprintChanged
	]
};
const HEADER_TITLES = {
	[DashboardTaskWorkStatusGrouping.Backlog]: "Haven't started",
	[DashboardTaskWorkStatusGrouping.InProgress]: "In the Works",
	[DashboardTaskWorkStatusGrouping.Done]: "Done"
};
const DEFAULT_DATE_RANGE_IN_DAYS = 1;

// tslint:disable-next-line: variable-name
const DigestPage = observer(() => {
	const {
		tasksStore,
		digestStore,
		teamsStore,
		teamMembersStore,
		projectsStore,
		sprintsStore,
		authStore
	} = useStores();

	const { fetchData: fetchSprintsData, sprintData, isLoading: sprintsLoading } = sprintsStore;
	const { fetchData: fetchProjectsData, projectOptions, isLoading: projectsLoading } = projectsStore;
	const { taskTypeOptions, taskStatusesOptions, isLoading: tasksLoading } = tasksStore;
	const { isLoading } = digestStore;

	const searchParams = new URLSearchParams(useLocation().search);
	const [searchedTeamId, setSearchedTeamId] = useState<string | undefined>(undefined);
	const [endTime, setEndTime] = useState<Date | undefined>(moment().toDate());
	const [startTime, setStartTime] = useState<Date | undefined>(moment(endTime).subtract((DEFAULT_DATE_RANGE_IN_DAYS), "days").startOf("day").toDate());
	const [previousStartTime, setPreviousStartTime] = useState<Date>(startTime!);
	const [previousEndTime, setPreviousEndTime] = useState<Date>(endTime!);

	const [storedFilters, setStoredFilters] = useLocalStorage(WORK_TASK_FILTER_LOCAL_STORAGE_KEY, defaultFilters);
	const [filters, setFilters] = useState<Filters>(storedFilters);
	const [selectedTaskInPanel, setSelectedTaskInPanel] = useState<[DashboardTaskWorkStatusGrouping, number]>([DashboardTaskWorkStatusGrouping.Backlog, -1]);
	const [updatePage, setUpdatePage] = useState<boolean>(false);

	const tasksColumn = (taskGroup: DashboardTaskWorkStatusGrouping, digestItemArray: DashboardTaskDigestCard[]) => {
		return (
			<div className="ui column">
				<div className="tasks-column">
					<h3 className="ui title">{`${HEADER_TITLES[taskGroup]} (${digestItemArray.length})`}</h3>
					{digestItemArray.map((digestItem, i) => {
						digestItem.onClick = () => {
							setSelectedTaskInPanel([taskGroup, i]);
						};
						return (
							<DigestTaskCard
								card={digestItem}
								locale={authStore.authUser.locale} key={i}
								className={classNames({ selected: (selectedTaskInPanel[0] === taskGroup && selectedTaskInPanel[1] === i) })}
							/>
						);
					})}
				</div>
				{digestItemArray.length === 0 && <div className="img-container">
					<img className="status-icon" src={NothingFoundIcon} alt="" />
					<p className="gray-font" style={{ paddingRight: "10px" }}>{"No Tasks Found"}</p>
				</div>}
			</div>
		);
	};

	function setAndStoreFilters(newFilters: Filters) {
		setStoredFilters(newFilters);
		setFilters(newFilters);
	}

	function setFilterByLabel(values: string[], filterLabel: FilterName) {
		const newFilters = filters;
		newFilters[filterLabel] = values;
		setAndStoreFilters(newFilters);
		setUpdatePage(true);
	}

	function applyFilters(formData: Partial<Filters>) {
		setAndStoreFilters(Object.assign(filters, formData));
		setUpdatePage(true);
	}

	function getFiltersFromURL() {
		const filtersToSet = { ...defaultFilters };
		const teamIdFromSearchParams = searchParams.get("filters[teamId]");
		if (!teamIdFromSearchParams || teamIdFromSearchParams !== searchedTeamId) {
			return;
		}

		searchParams.forEach((_value: string, key: any) => {
			const filterType: FilterName | "startTime" | "endTime" | "teamId" =
				key.replace("filters[", "").replace("]", "");
			const extractFilterPairs = (filterValues: string, valuePrefix: string) => {
				const regex = new RegExp(`${valuePrefix},`, "gi");
				return filterValues.replace(regex, `${valuePrefix}|`).split("|");
			};

			switch (filterType) {
				case "teamId":
				case "startTime":
				case "endTime":
					break;
				case "projects":
					const _projects = searchParams.get(key);
					if (_projects) {
						filtersToSet.projects = extractFilterPairs(_projects, "JIRA") ?? [];
					}
					break;
				case "repositories":
					filtersToSet.repositories = searchParams.getAll(key);
					break;
				case "prEvents":
					filtersToSet.prEvents = searchParams.getAll(key);
					break;
				case "jiraEvents":
					filtersToSet.jiraEvents = searchParams.getAll(key);
					break;
				case "sprints":
					const values = searchParams.get(key);
					if (values) {
						filtersToSet.sprints = extractFilterPairs(values, "JIRA") ?? [];
					}
					break;
				case "statuses":
					filtersToSet.statuses = searchParams.getAll(key);
					break;
				case "types":
					filtersToSet.types = searchParams.getAll(key);
					break;
				case "assigneeDataContributorIds":
					const assignees = searchParams.getAll(key);
					if (teamsStore.singleTeam.data.id && teamsStore.singleTeam.data.id !== "") {
						filtersToSet.assigneeDataContributorIds = assignees.filter(assignee => {
							return teamMembersStore.allTeamMembers.data.find(teamMember => teamMember.id === assignee);
						});
					}
					break;
				default:
					assertExhaustiveSwitch(filterType);
			}
		});
		return filtersToSet;
	}

	useEffect(() => {
		async function retrieveFiltersData(selectedTeamId: string) {
			// tslint:disable-next-line: no-floating-promises
			setUpdatePage(false);
			// tslint:disable-next-line: no-floating-promises
			fetchProjectsData({ teamId: selectedTeamId }, { order: DashboardSortOrder.Ascending });
			// Note: currently the endpoint does not support dev cycles.
			// tslint:disable-next-line: no-floating-promises
			fetchSprintsData(DevelopmentMethodology.Scrum, { teamId: selectedTeamId }, { order: DashboardSortOrder.Descending, field: SprintSortField.EndDate });
			return await teamMembersStore.fetchAllTeamMembers(selectedTeamId);
		}
		if (searchedTeamId) {
			// tslint:disable-next-line: no-floating-promises
			retrieveFiltersData(searchedTeamId).then(() => {
				const urlFilters = getFiltersFromURL();
				setFilters(urlFilters || storedFilters);
				setUpdatePage(true);
			});
		}
	}, [searchedTeamId]);

	useEffect(() => {
		if (teamsStore.singleTeam.data.id && teamsStore.singleTeam.data.id !== searchedTeamId) {
			setSearchedTeamId(teamsStore.singleTeam.data.id);
			if (searchedTeamId !== undefined) {
				setFilters(Object.assign(filters, { assigneeDataContributorIds: [] }));
			}
		}
	}, [teamsStore.singleTeam.data.id]);

	useEffect(() => {
		if (startTime && endTime && moment(startTime).isBefore(endTime)) {
			setUpdatePage(true);
		}
	}, [previousEndTime]);

	useEffect(() => {
		let isMounted = true;
		async function retrieveTasksDigest() {
			if (isMounted && searchedTeamId && updatePage && endTime !== undefined && startTime !== undefined) {
				// tslint:disable-next-line: no-floating-promises
				digestStore.getDigest(searchedTeamId, filters as Record<FilterName, string[]>, { startTime, endTime })
					.then(() => {
						if (isMounted) {
							setUpdatePage(false);
						}
					});
			}
		}
		if (!searchedTeamId) {
			return;
		}
		// tslint:disable-next-line: no-floating-promises
		retrieveTasksDigest();
		return () => { isMounted = false; };
	}, [updatePage]);

	const tasksCount = Object.keys(DashboardTaskWorkStatusGrouping).reduce((count, tasksGroup) => {
		return count + digestStore.allTasks[tasksGroup as AcumenTaskStatusGroup].length;
	}, 0);

	const popupSelectFields: ISelectOptionBase[] = [
		{
			name: "projects",
			label: "projects",
			isLoading: projectsLoading,
			options: projectOptions
		},
		{
			name: "statuses",
			label: "Statuses",
			isLoading: false,
			options: taskStatusesOptions
		},
		{
			name: "types",
			label: "Issue Types",
			isLoading: tasksLoading,
			options: taskTypeOptions
		},
		{
			name: "sprints",
			label: "Sprints",
			isLoading: sprintsLoading,
			options: sprintData.map(sprint => ({
				key: sprint.id,
				value: `${sprint.id},${sprint.integrationType}`,
				label: sprint.name
			}))
		},
		{
			name: "prEvents",
			label: "Pull Request events",
			isLoading: false,
			options: Object.entries(PullRequestEventType)
				.filter(prEvent => EVENTS_FRIENDLY_NAMES[prEvent[1]])
				.map(prEvent => {
					return {
						key: prEvent[1],
						value: prEvent[1] as string,
						label: EVENTS_FRIENDLY_NAMES[prEvent[1]] as string
					};
				})
				.sort((a, b) => a.label.localeCompare(b.label))
		},
		{
			name: "jiraEvents",
			label: "Issue events",
			isLoading: false,
			options: Object.entries(IssueEventType)
				.filter(jiraIssue => EVENTS_FRIENDLY_NAMES[jiraIssue[1]])
				.map(jiraIssue => {
					return {
						key: jiraIssue[1],
						value: jiraIssue[1] as string,
						label: EVENTS_FRIENDLY_NAMES[jiraIssue[1]] as string
					};
				})
				.sort((a, b) => a.label.localeCompare(b.label))
		}
	];
	const selectedTask = digestStore.allTasks[selectedTaskInPanel[0]][selectedTaskInPanel[1]];
	const handleTaskInPanelDismiss = useCallback(
		() => setSelectedTaskInPanel([DashboardTaskWorkStatusGrouping.Backlog, -1]),
		[],
	);

	return (
		<div className="ui sixteen column centered">
			{digestStore.hasTasksData && selectedTaskInPanel[1] >= 0 && (
				<TaskDetailsDisplayPanel
					task={selectedTask.task}
					onDismiss={handleTaskInPanelDismiss}
					onDismissTaskHighlights={() => { return; }}
					outsidePanelClassName={"digest-side-panel"}
				/>
			)}

			<FiltersTopNavbar
				filters={filters}
				applyFilters={applyFilters}
				resetFilters={defaultFilters}
				popupSelectFields={popupSelectFields}
				avatarSelectorField={{
					usersList: teamMembersStore.allTeamMembers.data.map(user => { user.id = user.dataContributorId; return user; }),
					singleUser: true,
					onUsersSelected: (value) => setFilterByLabel(value, "assigneeDataContributorIds"),
					initialSelectedUsers: filters?.assigneeDataContributorIds as string[] || []
				}}
				dateSelector={{
					locale: authStore.authUser.locale,
					setStartDate: (t) => setStartTime(t),
					startDate: startTime,
					setEndDate: (t, previousStart?: Date) => {
						if (t && startTime) {
							setPreviousStartTime(previousStart ?? startTime);
							setPreviousEndTime(t);
						}
						setEndTime(t);
					},
					endDate: endTime,
					isRangeSelector: true,
					earliestDayOfReport: moment().subtract(30, "days").toDate(),
					...clickEvent(GA_EVENT_CATEGORY.TaskDigest, GA_EVENT_ACTION.Filter, "TimeRange")
				}}
				counterLabel={!isLoading ? `${tasksCount} Tasks` : undefined}
				clickEventPage={GA_EVENT_CATEGORY.TaskDigest}
				onCloseDateSelector={() => {
					if (!endTime) {
						setStartTime(previousStartTime);
						setEndTime(previousEndTime);
					}
				}}
			/>

			<div className="main-table acu-scroll hide-horizontal-overflow">
				<div className="ui grid">

					{!digestStore.isLoading ? (
						<div className="ui content three column row form digest-side-panel set-page-z-index">
							{tasksColumn(DashboardTaskWorkStatusGrouping.Backlog, digestStore.allTasks.Backlog)}
							{tasksColumn(DashboardTaskWorkStatusGrouping.InProgress, digestStore.allTasks.InProgress)}
							{tasksColumn(DashboardTaskWorkStatusGrouping.Done, digestStore.allTasks.Done)}
						</div>
					) : (
						<div style={{ width: "100%", height: "65vh" }}>
							<Loader active={true} size="large" />
						</div>
					)}
				</div>
			</div >
		</div>
	);
});
export default DigestPage;
