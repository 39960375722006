import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";
import colorScheme from "../my-team/analytics/charts/chart-color-scheme";

function flatNestedMap(obj, innerKey = undefined) {
	let response = {};
	for (let [key, value] of Object.entries(obj)) {
		const rKey = innerKey ? `${key}.${innerKey}` : key;
		if (_.isObject(value)) {
			const innerResponse = flatNestedMap(value, rKey);
			response = _.merge(response, innerResponse);
		} else {
			response[rKey] = value;
		}
	}

	return response;
}

export function colorSchemePieChartOptions() {
	const colors = Object.entries(flatNestedMap(colorScheme));
	const data = colors.map(([key, value]) => ({
		name: key,
		color: value,
		y: 100 / colors.length,
		label: key,
	}));

	return {
		chart: {
			type: "pie",
		},
		title: undefined,
		tooltip: {
			pointFormat: "{series.name}",
		},
		plotOptions: {
			pie: {
				allowPointSelect: true,
				cursor: "pointer",
				dataLabels: {
					enabled: true,
					format: "<b>{point.name}</b>",
				},
			},
		},
		credits: {
			enabled: false,
		},
		series: [
			{
				name: "",
				data,
			},
		],
	};
}
const colorFunction = () => (
	<>
		<h3 className="ui header">Chart colors</h3>
		<div className="ui relaxed divided list">
			<div className="item" key="chart-colors">
				<div className="content">
					<HighchartsReact
						highcharts={Highcharts}
						options={colorSchemePieChartOptions()}
					/>
				</div>
			</div>
		</div>
	</>
);
export default colorFunction;
